import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/config.service';

@Injectable({
  providedIn: 'root'
})
export class PushToWalletService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  retrieveInfo(continueFlow: boolean): Observable<PushToWalletModel> {
    let params = new HttpParams();
    if (continueFlow) {
      params = params.append('cf', 'true');
    }

    return this.http.get(this.configService.PushToWalletUrl, {params: params}).pipe(
      map((response: any) => response.pushToWallet)
    );
  }

  selectJourneys(selectedJourneysSellKeys: string[]): Observable<any> {
    return this.http.post(this.configService.PushToWalletUrl, {
      pushToWallet: {
        action: 'SelectJourneys',
        selectedJourneys: selectedJourneysSellKeys
      }
    }).pipe(map((response: any) => response.pushToWallet));
  }

  sendCode(details: PushToWalletDetails): Observable<any> {
    return this.http.post(this.configService.PushToWalletUrl, {
      pushToWallet: {
        action: 'SendCode',
        details: details
      }
    }).pipe(map((response: any) => response.pushToWallet));
  }

  confirmWithCode(code: string) {
    return this.http.post(this.configService.PushToWalletUrl, {
      pushToWallet: {
        action: 'ConfirmWithCode',
        confirmationCode: code
      }
    }).pipe(map((response: any) => response.pushToWallet));
  }
}

export class PushToWalletItem {
  sellKey: string;
  departureStation: string;
  arrivalStation: string;
  departureDate: string;
  arrivalDate: string;
  amount: number;
  nonStandardAmount: number;
  isSelected: boolean;
  isNotEligibleForRefund: boolean;
  isModified: boolean;
  enableRefund: boolean;
  bonusAmount: number;
  bonusNonStandardAmount: number;
}

export class PushToWalletModel {
  items: PushToWalletItem[];
  details: PushToWalletDetails;
  selectedJourneys: string[];
  errors: string[];
  isConfirmed: boolean;
}

export class PushToWalletDetails {
  name: string;
  phoneNumber: string;
  emailAddress: string;
}
