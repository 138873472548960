import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ShoppingCartFlightModel } from '../../../core/models/shopping-cart-models';
import * as moment from 'moment';

@Component({
// tslint:disable-next-line: component-selector
  selector: 'shopping-cart-flight-details',
  templateUrl: './shopping-cart-flight-details.component.html',
  styleUrls: ['./shopping-cart-flight-details.component.scss']
})
export class ShoppingCartFlightDetailsComponent implements OnInit, OnChanges {

  @Input() flight: ShoppingCartFlightModel;

  arrivalDateDaysDifference = 0;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flight) {
      if (this.flight && this.flight.segments.length) {
        this.arrivalDateDaysDifference = moment(this.flight.segments[this.flight.segments.length - 1].arrivalDate).startOf('day')
          .diff(moment(this.flight.segments[0].departureDate).startOf('day'), 'days');
      }
    }
  }
}
