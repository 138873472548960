<div class="month-item-details fare-calendar-wrapper">
  <div class="month-name">
    <span class="fare-month">{{ currentMonthName }}</span><span class="fare-year year">{{ currentYear }}</span>
  </div>
  <button type="button" class="slick-arrow slick-prev fare-prev" (click)="goToPreviousMonth()"><span class="sr-only">{{ 'Previous' | translate: 'calendar' }}</span></button>
  <button type="button" class="slick-arrow slick-next fare-next" (click)="goToNextMonth()"><span class="sr-only">{{ 'Next' | translate: 'calendar' }}</span></button>
  <div class="month-wrap">
    <div class="fare-calendar fc-calendar-container js-fare-to-calendar" calendar-selection="inboundDate" outbound-date="outboundDate"
      #calendar></div>
    <div class="gestures-wrap">
      <div class="left-arrow"></div>
      <div class="center-arrow">
        <div class="circle"></div><div class="circle"></div><div class="circle"></div>
      </div>
      <div class="right-arrow"></div>
    </div>
  </div>
</div>