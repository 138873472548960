import { Component, OnInit, AfterViewInit } from '@angular/core';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { Router } from '@angular/router';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';

@Component({
  selector: 'check-in-session-empty-warning',
  templateUrl: './session-empty-warning.component.html',
  styleUrls: ['./session-empty-warning.component.scss']
})
export class SessionEmptyWarningComponent implements OnInit, AfterViewInit {

  modalId = 'sessionEmptyModal';

  constructor(
    private modalService: BlueModalService,
    private translateService: TranslateService,
    private router: Router) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.open(this.modalId);
  }

  startOver() {

    const baseUrl = "https://www.aeroitalia.com/";
    // window.location.href = window.origin;
    window.location.href = this.translateService.culture.indexOf("en") != -1 ? `${baseUrl}en` : `${baseUrl}`;
    //
    // this.router.navigate(['/'], { replaceUrl: true});
  }
}
