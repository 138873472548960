import * as tslib_1 from "tslib";
import { OnInit, OnChanges, SimpleChanges, EventEmitter, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { Constants } from '../../constants';
import { BlueRenderer } from '../../common-modules/blue-air-common/services/blue-renderer.service';
import { DomHelper } from '../dom-helper';
var StationPickerComponent = /** @class */ (function () {
    function StationPickerComponent(renderer, blueRenderer) {
        this.renderer = renderer;
        this.blueRenderer = blueRenderer;
        this.select = new EventEmitter();
        /** Search field of station picker */
        this.searchField = new FormControl();
        /** Determines wheter the width matches the mobile width */
        this.mobileMatches = false;
        this.countries = [];
        this.countriesMobile = [];
        this.stations = [];
        this.stationsByCountry = {};
        this.isOpened = false;
        this.updateWindowWith(window.innerWidth);
    }
    StationPickerComponent.prototype.onResize = function (event) {
        this.updateWindowWith(event.target.innerWidth);
    };
    StationPickerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchField.valueChanges.subscribe(function (term) {
            _this.searchTerm = term;
            _this.updateStations();
        });
    };
    StationPickerComponent.prototype.ngAfterViewInit = function () {
        this.updateWindowWith(window.innerWidth);
    };
    StationPickerComponent.prototype.ngOnChanges = function (changes) {
        if (changes.superStations) {
            this.updateSuperStations(changes.superStations.currentValue || []);
            this.updateSelectedInfo();
        }
        if (changes.departure || changes.superStations) {
            this.updateDepartureStation();
            this.updateCountries();
            this.updateStations();
        }
        if (changes.departure) {
            this.updateDepartureStation();
        }
    };
    /**
     * Sets the selected country
     * @param {string} country
     */
    StationPickerComponent.prototype.selectCountry = function (country, isSelected) {
        if (country) {
            if (this.selectedCountry === country) {
                country.isSelected = isSelected === undefined ? !country.isSelected : isSelected;
                return;
            }
            else {
                this.selectedCountry = country;
                this.countries.forEach(function (c) { return c.isSelected = false; });
                country.isSelected = true;
            }
        }
        else {
            this.countries.forEach(function (c) { return c.isSelected = false; });
            this.selectedCountry = null;
        }
        this.updateStations();
    };
    /**
     * Sets the selected station and open the station picker pair if any
     * @param {StationViewModel} newStation
     */
    StationPickerComponent.prototype.selectStation = function (newStation) {
        var _this = this;
        this.model = newStation ? newStation.code : null;
        this.selectedStation = newStation;
        this.onChangeCb(this.model);
        this.select.next(this.model);
        this.toggleOpened(false);
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
        this.selectCountry(newStation ? newStation.country : null, true);
        this.searchTerm = null;
        this.updateSearchField();
        if (!this.departureStation && this.stationPickerPair) {
            // using setTimeout because sometimes the other station picker doesn't close
            setTimeout(function () { return _this.stationPickerPair.focusIn(); });
        }
    };
    StationPickerComponent.prototype.toggleOpened = function (value) {
        if (value === void 0) { value = !this.isOpened; }
        this.isOpened = value;
    };
    /**
     * Opens this station picker and close the station picker pair if any
     */
    StationPickerComponent.prototype.focusIn = function () {
        var _this = this;
        if (this.stationPickerPair) {
            // using setTimeout because sometimes the other station picker doesn't close
            setTimeout(function () { return _this.stationPickerPair.toggleOpened(false); });
        }
        this.toggleOpened(true);
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
        if (this.mobileMatches) {
            if (this.searchInputMobile) {
                setTimeout(function () {
                    _this.searchInputMobile.nativeElement.focus();
                });
            }
        }
        else {
            if (this.searchInput) {
                this.searchInput.nativeElement.focus();
            }
        }
        var header = document.getElementsByClassName('header');
        if (header.length) {
            this.renderer.addClass(header[0], 'lower');
        }
        DomHelper.IncreaseAppHeight(this.renderer);
    };
    /**
     * Closes this station picker
     * @param {boolean} [selectFirstAvailableStation=false] If [true] also select the first available station. Defaults to [false]
     */
    StationPickerComponent.prototype.focusOut = function (selectFirstAvailableStation) {
        if (selectFirstAvailableStation === void 0) { selectFirstAvailableStation = false; }
        if (selectFirstAvailableStation) {
            this.selectStation(this.stations.length ? this.stations[0] : null);
        }
        this.toggleOpened(false);
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
        var header = document.getElementsByClassName('header');
        if (header.length) {
            this.renderer.removeClass(header[0], 'lower');
        }
        DomHelper.ResetAppHeight(this.renderer);
    };
    /**
     * Halfs an integer
     * @param n - The integer
     * @returns Math.ceil(n / 2)
     */
    StationPickerComponent.prototype.halfInt = function (n) {
        return Math.ceil(n / 2);
    };
    /**
     * Updates window with
     * @param {number} [newWidth] - The new width of the window
     */
    StationPickerComponent.prototype.updateWindowWith = function (newWidth) {
        if (this.currentWindowWidth === newWidth) {
            return;
        }
        this.currentWindowWidth = newWidth;
        this.mobileMatches = newWidth < Constants.mobileScreenWith;
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
    };
    /**
    * Updates super stations internal array base on super stations input
    * @param superStationsInput
    */
    StationPickerComponent.prototype.updateSuperStations = function (superStationsInput) {
        var _this = this;
        this.countries = [];
        this._superStations = superStationsInput.reduce(function (acc, item) {
            var c = new CountryViewModel(item.code, item.value);
            _this.countries.push(c);
            var stations = item.stations.map(function (s) { return new StationViewModel(s.code, s.value, c); });
            acc.push.apply(acc, tslib_1.__spread(stations));
            return acc;
        }, []);
        superStationsInput.forEach(function (ss) {
            ss.stations.forEach(function (s) {
                var station = _this._superStations.find(function (thisS) { return thisS.code === s.code; });
                station.connections = _this._superStations.filter(function (thisS) { return s.markets.indexOf(thisS.code) !== -1; });
            });
        });
    };
    /**
     * Updates departure station based on the input [departure]
     */
    StationPickerComponent.prototype.updateDepartureStation = function () {
        var _this = this;
        this.departureStation = this.getSuperStation(this.departure);
        if (!this.ignoreConnectionErrors && this.selectedStation && this.departureStation &&
            this.departureStation.connections.indexOf(this.selectedStation) === -1) {
            this.selectStation(null);
        }
        if (!this.ignoreConnectionErrors && this.selectedCountry && this.departureStation &&
            this.departureStation.connections.findIndex(function (conn) { return conn.country === _this.selectedCountry; }) === -1) {
            this.selectCountry(null);
        }
    };
    /**
     * Updates visible countries. Enables countries that can be selected.
     */
    StationPickerComponent.prototype.updateCountries = function () {
        var _this = this;
        this.countries.forEach(function (c) {
            c.isActive = !_this.departureStation || _this.departureStation.connections.some(function (conn) { return conn.country === c; });
        });
    };
    /**
     * Updates visible stations
     * @param filter - The filter to apply on station names and codes. If missing stations are filtered by country
     */
    StationPickerComponent.prototype.updateStations = function () {
        var _this = this;
        var filter = this.searchTerm;
        var ssArray = this._superStations.slice();
        // the user should first type something or select a country or have the departure already selected
        if (!filter && !this.selectedCountry && !this.departureStation) {
            this.stations.length = 0;
            this.updateStationsByCountry();
            return;
        }
        if (!filter && this.selectedCountry) {
            ssArray = ssArray.filter(function (ss) { return ss.country === _this.selectedCountry; });
        }
        if (this.departureStation) {
            ssArray = ssArray.filter(function (ss) { return _this.departureStation.connections.indexOf(ss) !== -1; });
        }
        if (filter) {
            var filterRegx_1 = new RegExp(filter, 'ig');
            ssArray = ssArray.filter(function (sfc) { return (sfc.name + " (" + sfc.code + ")").search(filterRegx_1) >= 0; });
            this.countriesMobile = ssArray.map(function (ss) { return ss.country; });
        }
        this.stations = ssArray;
        this.updateStationsByCountry(filter);
    };
    /**
     * Updates stations by country list
     */
    StationPickerComponent.prototype.updateStationsByCountry = function (filter) {
        var _this = this;
        var ssArray = this._superStations.slice();
        if (this.departureStation) {
            ssArray = ssArray.filter(function (ss) { return _this.departureStation.connections.indexOf(ss) !== -1; });
        }
        if (filter) {
            // if country matches the filter => show all stations for that country
            // else show only stations that match the filter
            var filterRegx_2 = new RegExp(filter, 'ig');
            this.stationsByCountry = ssArray.reduce(function (group, station) {
                if (station.country.name.search(filterRegx_2) >= 0 || (station.name + " (" + station.code + ")").search(filterRegx_2) >= 0) {
                    (group[station.country.code] = group[station.country.code] || []).push(station);
                }
                return group;
            }, {});
        }
        else {
            // show all countries
            this.stationsByCountry = ssArray.reduce(function (group, station) {
                (group[station.country.code] = group[station.country.code] || []).push(station);
                return group;
            }, {});
        }
        this.countriesMobile = [];
        var _loop_1 = function (countryCode) {
            if (this_1.stationsByCountry.hasOwnProperty(countryCode)) {
                var country = this_1.countries.find(function (c) { return c.code === countryCode; });
                this_1.countriesMobile.push(country);
            }
        };
        var this_1 = this;
        for (var countryCode in this.stationsByCountry) {
            _loop_1(countryCode);
        }
        if (this.countriesMobile.length === 1) {
            this.countries.forEach(function (c) { return c.isSelected = false; });
            this.countriesMobile[0].isSelected = true;
        }
    };
    /**
     * Updates selected info base on the model
     */
    StationPickerComponent.prototype.updateSelectedInfo = function () {
        this.selectedStation = this.getSuperStation(this.model);
        this.selectCountry(this.selectedStation ? this.selectedStation.country : null);
        this.updateSearchField();
    };
    /**
     * Updates search field value based on the station selection
     */
    StationPickerComponent.prototype.updateSearchField = function () {
        this.searchField.setValue(this.selectedStation ? this.selectedStation.name : '', { emitEvent: false });
    };
    /**
     * Gets super station by station code (IATA)
     * @param {string} [stationCode] - The station code (IATA) to search for
     * @returns {StationViewModel}
     */
    StationPickerComponent.prototype.getSuperStation = function (stationCode) {
        return this._superStations.find(function (ss) { return ss.code === stationCode; });
    };
    StationPickerComponent.prototype.writeValue = function (obj) {
        this.model = obj;
        this.updateSelectedInfo();
    };
    StationPickerComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCb = fn;
    };
    StationPickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCb = fn;
    };
    StationPickerComponent.prototype.setDisabledState = function (isDisabled) {
        // do nothing;
    };
    return StationPickerComponent;
}());
export { StationPickerComponent };
var StationViewModel = /** @class */ (function () {
    function StationViewModel(code, name, country) {
        this.code = code;
        this.name = name;
        this.country = country;
        this.connections = [];
    }
    return StationViewModel;
}());
var CountryViewModel = /** @class */ (function () {
    function CountryViewModel(code, name, isActive, isSelected) {
        if (isActive === void 0) { isActive = true; }
        if (isSelected === void 0) { isSelected = false; }
        this.code = code;
        this.name = name;
        this.isActive = isActive;
        this.isSelected = isSelected;
    }
    return CountryViewModel;
}());
