import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { BlueModal } from '../../common-modules/blue-air-common/blue-modal/blue-modal';
var BlueModal2Component = /** @class */ (function (_super) {
    tslib_1.__extends(BlueModal2Component, _super);
    function BlueModal2Component(modalService, el, render) {
        var _this = _super.call(this) || this;
        _this.modalService = modalService;
        _this.el = el;
        _this.render = render;
        _this.cssClass = 'edit-popup';
        return _this;
    }
    BlueModal2Component.prototype.ngOnInit = function () {
        this.modalService.add(this);
    };
    BlueModal2Component.prototype.ngOnDestroy = function () {
        this.modalService.remove(this.id);
    };
    BlueModal2Component.prototype.open = function (closeCallback) {
        if (closeCallback === void 0) { closeCallback = null; }
        this.render.setStyle(document.body, 'overflow', 'hidden');
        document.body.insertAdjacentElement('afterbegin', this.el.nativeElement);
        document.body.insertAdjacentHTML('afterbegin', '<div class="mfp-bg white mfp-ready"></div>');
        this.closeCallback = closeCallback;
        this.isOpened = true;
    };
    BlueModal2Component.prototype.close = function () {
        if (!this.isOpened) {
            return;
        }
        this.isOpened = false;
        var popupbgs = document.body.querySelectorAll('.mfp-bg');
        for (var i = 0; i < popupbgs.length; i++) {
            document.body.removeChild(popupbgs[i]);
        }
        this.render.removeStyle(document.body, 'overflow');
        if (this.closeCallback) {
            this.closeCallback();
        }
    };
    return BlueModal2Component;
}(BlueModal));
export { BlueModal2Component };
