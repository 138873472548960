import * as i0 from "@angular/core";
var BookingFareSelectSyncService = /** @class */ (function () {
    function BookingFareSelectSyncService() {
        this.isBlueBenefitsSelected = false;
        this.useSamePriorityBoardingOptionForAllPassengers = false;
        this.passengersInitialPriorityBoardingChoice = {};
        this.flights = null;
    }
    BookingFareSelectSyncService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BookingFareSelectSyncService_Factory() { return new BookingFareSelectSyncService(); }, token: BookingFareSelectSyncService, providedIn: "root" });
    return BookingFareSelectSyncService;
}());
export { BookingFareSelectSyncService };
