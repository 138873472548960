import { DateRange } from './../../../common-modules/blue-air-common/date-range.model';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { BookingStatusEnum } from '../../booking-status.enum';
import { FindMyBookingsService } from '../../find-my-bookings.service';
import { FilterByEnum } from '../filter-by.enum';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import * as moment from 'moment';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'check-in-filter-bookings',
  templateUrl: './filter-bookings.component.html',
  styleUrls: ['./filter-bookings.component.scss']
})
export class FilterBookingsComponent implements OnInit {
  private noBookingErrorCode: string = 'NoMatchingBookingFound';
  private validFormStatus: string = 'VALID';
  public format: string = 'YYYY-MM-DD';
  public spinnerId: string = "loadMyBookingsSpinner";

  public flightDateRange: DateRange;
  public departureDateRange: DateRange;
  public bookingDateRange: DateRange;

  public documentTypesItems: { text: string, value: string }[] = [];

  public filterByEnum = FilterByEnum;
  public filterByItems: { text: string, value: FilterByEnum }[] = [];
  public filterBy: FilterByEnum;

  public filterByRecordLocatorData: RetrieveBookingsByRecordLocator;
  public filterByBookingDateData: RetrieveBookingsByUtc;
  public fitlerByEmailAddressData: RetrieveBookingsByEmailAddress;
  public filterByDepartureDateData: FindBookingByBookingDepartureDate;

  public bookingStatuses: string[] = Object.keys(BookingStatusEnum).filter((v) => isNaN(Number(v)) && !v.match('Archive') && !v.match('Refunded') && !v.match('Closed') && !v.match('Default'));

  public filterBookingsForm: FormGroup;

  @Output() bookingDataEventEmmiter: EventEmitter<BookingData[]> = new EventEmitter<BookingData[]>();
  @Output() errorsEventEmitter: EventEmitter<Error[]> = new EventEmitter<Error[]>();

  private allBookingData: any;

  constructor(private resourceService: ResourceService, private findMyBookingsService: FindMyBookingsService, private loadingSpinnerService: LoadingSpinnerService) {
    this.resourceService.getDocumentTypes(true).subscribe(docTypes => this.documentTypesItems = docTypes);

    this.findMyBookingsService.RetrieveBookings().then(data => {
      this.allBookingData = data;

      this.parseAndEmitBookings(data);
    });
  }

  ngOnInit() {
    const pastYears = moment().year() - 2004;
    this.bookingDateRange = new DateRange(moment());
    this.bookingDateRange.generateYearsForAge(-2, pastYears + 1);

    this.flightDateRange = new DateRange(moment());
    this.flightDateRange.generateYearsForAge(-2, 1);

    this.departureDateRange = new DateRange(moment());
    this.departureDateRange.generateYearsForAge(-2, 1);

    this.filterByItems.push({
      text: this.filterByEnum[this.filterByEnum.RecordLocator],
      value: this.filterByEnum.RecordLocator
    });
    this.filterByItems.push({
      text: this.filterByEnum[this.filterByEnum.BookingDate],
      value: this.filterByEnum.BookingDate
    });
    this.filterByItems.push({
      text: this.filterByEnum[this.filterByEnum.DepartureDate],
      value: this.filterByEnum.DepartureDate
    });
    this.filterByItems.push({
      text: this.filterByEnum[this.filterByEnum.EmailAddress],
      value: this.filterByEnum.EmailAddress
    });
    this.filterByItems.push({
      text: this.filterByEnum[this.filterByEnum.BookingStatus],
      value: this.filterByEnum.BookingStatus
    });

    this.filterBy = this.filterByEnum.RecordLocator;

    this.filterByRecordLocatorData = new RetrieveBookingsByRecordLocator();
    this.filterByBookingDateData = new RetrieveBookingsByUtc();
    this.fitlerByEmailAddressData = new RetrieveBookingsByEmailAddress();
    this.filterByDepartureDateData = new FindBookingByBookingDepartureDate();

    this.filterBookingsForm = new FormGroup({
      emailAddressForm: new FormGroup({
        emailAddressForm_emailAddress: new FormControl('', Validators.required),
        emailAddressForm_agentId: new FormControl(''),
        emailAddressForm_flightNumber: new FormControl(''),
        emailAddressForm_flightDate: new FormControl(''),
        emailAddressForm_flightOrigin: new FormControl(''),
        emailAddressForm_flightDestination: new FormControl('')
      }),
      departureDateForm: new FormGroup({
        departureDateForm_flightDate: new FormControl('', Validators.required)
      }),
      bookingStatusForm: new FormGroup({
        bookingStatusForm_Default: new FormControl(false, Validators.required),
        bookingStatusForm_Hold: new FormControl(false, Validators.required),
        bookingStatusForm_Confirmed: new FormControl(false, Validators.required),
        bookingStatusForm_Closed: new FormControl(false, Validators.required),
        bookingStatusForm_HoldCanceled: new FormControl(false, Validators.required),
        bookingStatusForm_PendingArchive: new FormControl(false, Validators.required),
        bookingStatusForm_Archived: new FormControl(false, Validators.required),
        bookingStatusForm_Disrupted: new FormControl(false, Validators.required),
        bookingStatusForm_RefundedIntoWallet: new FormControl(false, Validators.required),
        bookingStatusForm_PartiallyRefunded: new FormControl(false, Validators.required),
        bookingStatusForm_Canceled: new FormControl(false, Validators.required)
      }),
      bookingDateForm: new FormGroup({
        bookingDateForm_startUtc: new FormControl('', Validators.required),
        bookingDateForm_flightNumber: new FormControl(''),
        bookingDateForm_flightDate: new FormControl(''),
        bookingDateForm_flightOrigin: new FormControl(''),
        bookingDateForm_flightDestination: new FormControl('')
      }),
      recordLocatorForm: new FormGroup({
        recordLocatorForm_recordLocator: new FormControl('', Validators.required)
      })
    });
  }

  public search() {
    switch (this.filterBy) {
      case this.filterByEnum.BookingDate: {
        if (this.filterBookingsForm.controls.bookingDateForm.status === this.validFormStatus ) {
          this.loadingSpinnerService.showSpinnerById(this.spinnerId);

          this.filterByBookingDateData.StartUtc = this.filterBookingsForm.controls.bookingDateForm.value.bookingDateForm_startUtc;
          this.filterByBookingDateData.FlightNumber = this.filterBookingsForm.controls.bookingDateForm.value.bookingDateForm_flightNumber;
          this.filterByBookingDateData.FlightDestination = this.filterBookingsForm.controls.bookingDateForm.value.bookingDateForm_flightDestination;
          this.filterByBookingDateData.FlightDate = this.filterBookingsForm.controls.bookingDateForm.value.bookingDateForm_flightDate;
          this.filterByBookingDateData.FlightOrigin = this.filterBookingsForm.controls.bookingDateForm.value.bookingDateForm_flightOrigin;

          this.findMyBookingsService.RetrieveBookingsByBookingDate(this.filterByBookingDateData)
            .then(data => {
              this.parseAndEmitBookings(data);
            });
        }
        break;
      }
      case this.filterByEnum.DepartureDate: {
        if (this.filterBookingsForm.controls.departureDateForm.status === this.validFormStatus) {
          this.loadingSpinnerService.showSpinnerById(this.spinnerId);
          this.filterByDepartureDateData.FlightDate = this.filterBookingsForm.controls.departureDateForm.value.departureDateForm_flightDate;
          this.findMyBookingsService.RetrieveBookingsByDepartureDate(this.filterByDepartureDateData)
            .then(data => {
              this.parseAndEmitBookings(data);
            });
        }
        break;
      }
      case this.filterByEnum.EmailAddress: {
        if (this.filterBookingsForm.controls.emailAddressForm.status === this.validFormStatus) {
          this.loadingSpinnerService.showSpinnerById(this.spinnerId);

          this.fitlerByEmailAddressData.EmailAddress = this.filterBookingsForm.controls.emailAddressForm.value.emailAddressForm_emailAddress;
          this.fitlerByEmailAddressData.FlightDate = this.filterBookingsForm.controls.emailAddressForm.value.emailAddressForm_flightDate;
          this.fitlerByEmailAddressData.FlightDestination = this.filterBookingsForm.controls.emailAddressForm.value.emailAddressForm_flightDestination;
          this.fitlerByEmailAddressData.FlightNumber = this.filterBookingsForm.controls.emailAddressForm.value.emailAddressForm_flightNumber;
          this.fitlerByEmailAddressData.FlightOrigin = this.filterBookingsForm.controls.emailAddressForm.value.emailAddressForm_flightOrigin;
          this.fitlerByEmailAddressData.AgentId = this.filterBookingsForm.controls.emailAddressForm.value.emailAddressForm_agentId;

          this.findMyBookingsService.RetrieveBookingsByEmailAddress(this.fitlerByEmailAddressData)
            .then(data => {
              this.parseAndEmitBookings(data);
            });
        }
        break;
      }
      case this.filterByEnum.BookingStatus: {
        if (this.filterBookingsForm.controls.bookingStatusForm.status === this.validFormStatus) {
          this.loadingSpinnerService.showSpinnerById(this.spinnerId);

          let filterStatus: string[] = [];

          this.bookingStatuses.forEach(status => {
            if(this.filterBookingsForm.controls.bookingStatusForm.value["bookingStatusForm_" + status]){
              filterStatus.push(status);
            }
          })

          let filteredItems = [];

          filterStatus.forEach(status => {
            filteredItems = filteredItems.concat(this.allBookingData.blueBookings.bookingDetails.filter( i => i.bookingStatus == status));
          })

          let bookingData: BookingData[] = [];

          filteredItems.forEach(item => {
            bookingData.push(new BookingData(item.agentBooking, item.bookingInstance.currencyCode, item.bookingStatus));
          });

          this.bookingDataEventEmmiter.emit(bookingData);

          this.errorsEventEmitter.emit([]);

          this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
        }
        break;
      }
      default: {
        if (this.filterBookingsForm.controls.recordLocatorForm.status === this.validFormStatus) {
          this.loadingSpinnerService.showSpinnerById(this.spinnerId);
          this.filterByRecordLocatorData.RecordLocator = this.filterBookingsForm.controls.recordLocatorForm.value.recordLocatorForm_recordLocator;
          this.findMyBookingsService.RetrieveBookingsByRecordLocator(this.filterByRecordLocatorData)
            .then(data => {
              this.parseAndEmitBookings(data);
            });
        }
      }
    }
  }

  private parseAndEmitBookings(data) {
    if (data && data.blueBookings && data.blueBookings.bookingData && data.blueBookings.bookingData.items && data.blueBookings.bookingData.items.length > 0) {
      let bookingData: BookingData[] = [];

      data.blueBookings.bookingDetails.forEach(item => {
        bookingData.push(new BookingData(item.agentBooking, item.bookingInstance.currencyCode, item.bookingStatus));
      });

      this.bookingDataEventEmmiter.emit(bookingData);

      this.errorsEventEmitter.emit([]);
    }

    if (data && data.errors && data.errors.length > 0) {
      let errors: Error[] = [];

      data.errors.forEach(item => {
        errors.push(new Error(item));
        if (item.errorCode == this.noBookingErrorCode) {
          this.bookingDataEventEmmiter.emit([]);
        }
      });

      this.errorsEventEmitter.emit(errors);
    }

    this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
  }

  public changeCheckbox(status: string){
    this.filterBookingsForm.controls.bookingStatusForm.value["bookingStatusForm_" + status] = !this.filterBookingsForm.controls.bookingStatusForm.value["bookingStatusForm_" + status];
  }

  public getCheckbox(status){
    return this.filterBookingsForm.controls.bookingStatusForm.value["bookingStatusForm_" + status];
  }
}

export class Error {
  public ErrorCode: string;
  public ErrorMessage: string;

  constructor(item) {
    this.ErrorCode = item.errorCode;
    this.ErrorMessage = item.errorMessage;
  }
}

export class RetrieveBookingsByRecordLocator {
  public RecordLocator: string;
}

export class FindBookingByBookingDepartureDate {
  public FlightDate: Date;
}

export class RetrieveBookingsByUtc {
  public StartUtc: Date;
  public EndUtc: Date;
  public FlightNumber: string;
  public FlightDate: Date;
  public FlightDestination: string;
  public FlightOrigin: string;
}

export class RetrieveBookingsByEmailAddress {
  public EmailAddress: string;
  public AgentId: number;
  public FlightNumber: string;
  public FlightDate: Date;
  public FlightDestination: string;
  public FlightOrigin: string;
}

export class BookingData {
  public AllowedToModifyGdsBooking: boolean;
  public BookingId: number;
  public BookingStatus: BookingStatusEnum;
  public ChannelType: string;
  public Editable: boolean;
  public ExpiredDate: Date;
  public FlightDate: Date;
  public FlightNumber: string;
  public FromCity: string;
  public PassengerId: number;
  public RecordLocator: string;
  public SourceAgentCode: string;
  public SourceDomainCode: string;
  public SourceOrganizationCode: string;
  public SystemCode: string;
  public ToCity: string;
  public Name: Name;
  public BookingExpired: boolean;

  public Email: string;
  public AmountDue: number;
  public TotalCost: number;
  public CurrencyCode: string;
  public BookingDate: Date;

  constructor(item: any, currencyCode: string, bookingStatus: any) {
    this.AllowedToModifyGdsBooking = item.allowedToModifyGdsBooking;
    this.BookingId = item.bookingId;
    this.ChannelType = item.channelType;
    this.Editable = item.editable;
    this.ExpiredDate = item.expiredDate;
    this.FlightDate = item.flightDate;
    this.FlightNumber = item.flightNumber;
    this.FromCity = item.fromCity;
    this.PassengerId = item.passengerId;
    this.RecordLocator = item.recordLocator;
    this.SourceAgentCode = item.sourceAgentCode;
    this.SourceDomainCode = item.sourceDomainCode;
    this.SourceOrganizationCode = item.SourceOrganizationCode;
    this.SystemCode = item.systemCode;
    this.ToCity = item.toCity;
    this.Name = new Name(item.name);
    this.BookingExpired = moment().add(4, 'hour').isAfter(moment(this.FlightDate));
    this.CurrencyCode = currencyCode;
    this.BookingStatus = bookingStatus;
  }

  public setBookingData(email: string, amountDue: number, totalCost: number, bookingDate: Date, bookingStatus: any) {
    this.Email = email;
    this.AmountDue = amountDue;
    this.TotalCost = totalCost;
    this.BookingDate = bookingDate;

    if(bookingStatus != ""){
      this.BookingStatus = bookingStatus;
    }
  }
}

export class Name {
  public First: string;
  public Last: string;
  public Middle: string;
  public Suffix: string;
  public Title: string;

  constructor(item) {
    this.First = item.first;
    this.Last = item.last;
    this.Middle = item.middle;
    this.Suffix = item.suffix;
    this.Title = item.title;
  }
}
