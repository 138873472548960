import { Constants } from '../../constants';
import { PassengerSelectionType } from './pasenger-selection-type-enum';
import { IPassengerSearch } from './flight-search-model';

export type PassengerTypes = 'ADT' | 'YTH' | 'CLD' | 'CHD' | 'INL';

export class PassengerTypeSelectionViewModel {
  adults: number;
  youngAdults: number;
  teens: number;
  children: number;
  infants: number;

  get count(): number {
    //todo: check if infants are added to total pax
    return this.adults + this.youngAdults + this.teens + this.children;
  }

  constructor() {
    this.adults = 0;
    this.youngAdults = 0;
    this.teens = 0;
    this.children = 0;
    this.infants = 0;
  }

  initFromPassengersSearch(passengersSearch: IPassengerSearch[]): PassengerTypeSelectionViewModel {
    this.adults = this.getCountFromPassengerSearch(passengersSearch, Constants.AdultPaxType);
    this.youngAdults = this.getCountFromPassengerSearch(passengersSearch, Constants.YoungAdultPaxType);
    this.teens = this.getCountFromPassengerSearch(passengersSearch, Constants.TeenPaxType);
    this.children = this.getCountFromPassengerSearch(passengersSearch, Constants.ChildrenPaxType);
    this.infants = this.getCountFromPassengerSearch(passengersSearch, Constants.InfantPaxType);

    return this;
  }

  initFromModel(otherModel: PassengerTypeSelectionViewModel) {
    this.adults = otherModel.adults;
    this.youngAdults = otherModel.youngAdults;
    this.teens = otherModel.teens;
    this.children = otherModel.children;
    this.infants = otherModel.infants;

  }

  private getCountFromPassengerSearch(passengersSearch: IPassengerSearch[], paxType: PassengerTypes): number {
    const s = passengersSearch.find(p => p.paxType.substring(0,3) === paxType);
    return s ? s.count : 0;
  }

  getProperty(paxType: PassengerSelectionType): string {
    switch (paxType) {
      case PassengerSelectionType.Adult:
        return 'adults';
      case PassengerSelectionType.YoungAdult:
        return 'youngAdults';
      case PassengerSelectionType.Teen:
        return 'teens';
      case PassengerSelectionType.Children:
        return 'children';
      case PassengerSelectionType.Infant:
        return 'infants';
    }
  }

  getPassengerSearch(): IPassengerSearch[] {
    const result: IPassengerSearch[] = [];
    result.push({ paxType: Constants.AdultPaxType, count: this.adults });
    result.push({ paxType: Constants.YoungAdultPaxType, count: this.youngAdults });
    result.push({ paxType: Constants.TeenPaxType, count: this.teens });
    result.push({ paxType: Constants.ChildrenPaxType, count: this.children });
    result.push({ paxType: Constants.InfantPaxType, count: this.infants });
    return result;
  }
}
