import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { timeStamp } from 'console';
import { PassengerModel } from 'src/app/core/models/passenger-model';
import { PassengerSsrModel } from '../passenger-ssr-model';
import { SsrType } from '../ssr-type.enum';
import { FlightModel } from '../../core/models/flight-model';
import { BookingFareSelectSyncService } from '../../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { Subscription } from 'rxjs';
import { BookingSelectionService } from 'src/app/core/booking-selection.service';
import { SsrsService } from '../ssrs.service';
import { debug } from 'util';

@Component({
  selector: 'check-in-ssr-passenger-picker',
  templateUrl: './ssr-passenger-picker.component.html',
  styleUrls: ['./ssr-passenger-picker.component.scss']
})
export class SsrPassengerPickerComponent {
  private passengersSubscription: Subscription;
  SsrTypeEnum = SsrType;
  uniqueId: number;
  isFreePriority: boolean;
  isPaidPriority: boolean;

  constructor(public syncService: BookingFareSelectSyncService, private bookingSelectionService: BookingSelectionService, private ssrsService: SsrsService) {
    this.allPassengersVisible = true;
    this.uniqueId = Math.floor(Math.random() * 1000) + 1;
  }

  get sameOptionsForAllFlights(): boolean {
    return this.syncService.useSamePriorityBoardingOptionForAllPassengers;
  }
  set sameOptionsForAllFlights(value: boolean) {
    this.syncService.useSamePriorityBoardingOptionForAllPassengers = value;
  }

  private _passengers: PassengerSsrModel[] = [];
  get passengers(): PassengerSsrModel[] {
    return this._passengers;
  }
  @Input() set passengers(value: PassengerSsrModel[]) {
    this._passengers = value;
    this.setPassengersNameByType();
  }

  getPriorityBoardingPrice(passenger: PassengerModel): number {
    const priorityBoardingSSR = this.ssrsService.availableSsrs.find(ssr => ssr.key === SsrType.PriorityBoarding);
    if (!priorityBoardingSSR) {
      return 0;
    }

    if (!passenger.flight || !passenger.flight.segments) return 0;

    for (let segmentIndex = 0; segmentIndex < passenger.flight.segments.length; segmentIndex++) {
      const segment = passenger.flight.segments[segmentIndex];
      for (let i = 0; i < priorityBoardingSSR.value.length; i++) {
        if (priorityBoardingSSR.value[i].key === segment.departureStation.iataCode + '_' + segment.arrivalStation.iataCode) {
          return priorityBoardingSSR.value[i].value[0].price;
        }
      }
    }
    return 0;
  }

  private _currentFlights: FlightModel[] = [];
  get currentFlights(): FlightModel[] {
    return this._currentFlights;
  }
  @Input() set currentFlights(value: FlightModel[]) {
    this._currentFlights = value;
  }

  get allPassengersSelected(): boolean {
    const selectedPassenters = this.passengers.filter(p => p.passenger.hasPriorityBoarding);
    return selectedPassenters.length === this.passengers.length;
  }

  @Input() icon: string;
  @Input() ssrType: SsrType;
  @Input() allPassengersVisible: boolean;
  @Input() allPassengersEnabled: boolean;
  @Input() disableRemoveButton: boolean;
  @Input() selectedPassenger: number;
  @Input() showPassengerNames = true;
  @Input() flightCodes: string = '';
  @Input() enableSameOptionsForAllFlights = false;

  @Output() selectPassenger: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectSsrEvent = new EventEmitter<number>();
  @Output() removeSsrEvent = new EventEmitter<PassengerModel[]>();
  @Output() removeSsr: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetSsr: EventEmitter<any> = new EventEmitter<any>();
  @Output() sameOptionsForAllFlightsEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnDestroy() {
    if (this.passengersSubscription) {
      this.passengersSubscription.unsubscribe();
    }
  }

  allPassengersChanged() {
    if (this.allPassengersEnabled) {
      this.selectPassenger.next(this.allPassengersSelected ? 0 : -1);
    }
  }

  changeSelectedPassenger(passengerIndex: number): void {
    this.selectPassenger.next(passengerIndex);
  }

  selectSinglePassengerPriorityBoardingOption(passengerUniqueId: string, hasPrioritySelected: boolean, event: any) {

    this.passengers.forEach((passenger) => {
      if (passenger.passenger.passengerUniqueId === passengerUniqueId) {

        this.syncService.passengersInitialPriorityBoardingChoice[passengerUniqueId] = true;
        if (passenger.passenger.hasPriorityBoarding === hasPrioritySelected) return;
        if (event) {
          event.preventDefault();
        }
        if (passenger.passenger.hasPriorityBoarding) {
          if (this.sameOptionsForAllFlights) {
            const arr = this.currentFlights.reduce((allPassengers, flight) => allPassengers.concat(flight.passengers), []);
            arr.forEach(p => this.syncService.passengersInitialPriorityBoardingChoice[p.passengerUniqueId] = true);
            this.removeSsrEvent.emit(arr);
          } else {
            this.removeSsrEvent.emit([passenger.passenger]);
          }
        } else {
          this.selectSsrEvent.emit(passenger.passenger.passengerNumber);
        }
      }
    });
  }

  toggleAllPassengersPriorityBoardingSelection() {
    this.passengers.forEach(p => this.syncService.passengersInitialPriorityBoardingChoice[p.passenger.passengerUniqueId] = true);
    if (this.allPassengersSelected === true) {
      const passengersToRemoveSSRFor = this.passengers.filter(p => p.passenger.hasPriorityBoarding).map(p => p.passenger);
      this.removeSsrEvent.emit(passengersToRemoveSSRFor);
    } else {
      const passengersToAddSSRFor = this.passengers.filter(p => !p.passenger.hasPriorityBoarding).map(p => p.passenger);
      passengersToAddSSRFor.forEach(p => {
        this.selectSsrEvent.emit(p.passengerNumber);
      });
    }
  }

  checkSameSsrOptionsForAllFlights() {
    this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
    this.sameOptionsForAllFlightsEvent.emit(this.sameOptionsForAllFlights);
  }

  setPassengersNameByType() {
    this.passengersSubscription = this.bookingSelectionService.passengersObs.subscribe(passengersByType => {
      if (passengersByType && passengersByType.length > 0) {

        this.passengers.forEach(p => {

          const name = passengersByType.filter(pax => pax.passengerNumber === p.passenger.passengerNumber)
            .map(p => p.name.first + ' ' + p.name.last);

          if (!p.passenger.name.first || !p.passenger.name.last) {
            let defaultPassager: PassengerModel = p.passenger;
            defaultPassager.name.first = name[0];
            p.passenger = defaultPassager;
          }
        });
      }
    });
  }

  checkNoBagsSelected(passengerIndex: number): boolean {
    if (passengerIndex == undefined && this.selectedPassenger != undefined) {
      passengerIndex = this.selectedPassenger
    }

    let noBag = this.ssrsService.noBagsSync.find(n => n.flight.split('~')[4] == this.flightCodes.split('_')[0] && n.passengerIndex == passengerIndex);

    return noBag ? noBag.noBags : false;
  }
}
