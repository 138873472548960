/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passenger-bundle.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../checkin-accordion/checkin-accordion.component.ngfactory";
import * as i4 from "../checkin-accordion/checkin-accordion.component";
import * as i5 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component.ngfactory";
import * as i6 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component";
import * as i7 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i8 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i9 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i10 from "../ssr-translate.pipe";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../translation-innerHtml.pipe";
import * as i13 from "../currency-display.pipe";
import * as i14 from "../../core/flow-manager.service";
import * as i15 from "../../common-modules/blue-air-common/custom-date.pipe";
import * as i16 from "../default-modal/default-modal.component.ngfactory";
import * as i17 from "../default-modal/default-modal.component";
import * as i18 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i19 from "../../extras/ssrs.service";
import * as i20 from "./passenger-bundle.component";
import * as i21 from "../bundle.service";
import * as i22 from "../../core/booking-steps.service";
import * as i23 from "../../core/booking.service";
import * as i24 from "../e-commerce/ecommerce.service";
var styles_PassengerBundleComponent = [i0.styles];
var RenderType_PassengerBundleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PassengerBundleComponent, data: {} });
export { RenderType_PassengerBundleComponent as RenderType_PassengerBundleComponent };
function View_PassengerBundleComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["role", "presentation"]], [[8, "className", 0]], null, null, null, null)), i1.ɵppd(1, 1), i1.ɵppd(2, 1), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(3, "ssr-icon bundle-upgrade white font2-ico-bundle-ssrs-", i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 1), _v.parent.context.$implicit.bundleCode)), "-", i1.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 1), _v.parent.context.$implicit.ssrCode)), " font2-ico-ssrs-", i1.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 1), _v.parent.context.$implicit.ssrCode)), " font-ico-checked-sign"); _ck(_v, 0, 0, currVal_0); }); }
function View_PassengerBundleComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 2), _v.parent.context.$implicit.ssrCode, _v.parent.context.$implicit.bundleCode, _co.flights[0].departureStation.date)); _ck(_v, 0, 0, currVal_0); }); }
function View_PassengerBundleComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [["role", "menuitem"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_6)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.ssrCode !== "CKN"); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.ssrCode !== "CKN") && (_co.flights.length > 0)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PassengerBundleComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [["class", "bundle-checked-box"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_5)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bannerBundleSsrList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PassengerBundleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.bannerBundleSsrList.length > 0); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), "Upgrade your flights", "bundle")); _ck(_v, 1, 0, currVal_0); }); }
function View_PassengerBundleComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "bundle-updated-max-flight"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "bundle-updated-max-stations"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), i1.ɵppd(3, 2), i1.ɵpad(4, 1), i1.ɵppd(5, 2), i1.ɵpad(6, 1), i1.ɵppd(7, 4), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "bundle-updated-max-bundles"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(9, 2), i1.ɵpad(10, 1), i1.ɵppd(11, 2), i1.ɵpad(12, 1), i1.ɵppd(13, 4)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), "{0} to {1}:", "", _ck(_v, 4, 0, i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit.departureStation, "station.name"))), _ck(_v, 6, 0, i1.ɵunv(_v, 2, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit.arrivalStation, "station.name"))))); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), "{0} to {1} upgrade", "bundle", _ck(_v, 10, 0, i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent.parent.parent, 3), _v.context.$implicit.initialBundle, "bundle.name"))), _ck(_v, 12, 0, i1.ɵunv(_v, 8, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent.parent.parent.parent, 3), _v.context.$implicit.selectedBundle, "bundle.name"))))); _ck(_v, 8, 0, currVal_1); }); }
function View_PassengerBundleComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h3", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 3, "ul", [["class", "bundle-checked-box"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "bundle-updated-max-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_9)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.upgradedFlightsList; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), "Flight Upgrade", "bundle")); _ck(_v, 1, 0, currVal_0); }); }
function View_PassengerBundleComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "price"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 4), (_co.startingFromPrice * _co.paxCount))); _ck(_v, 1, 0, currVal_0); }); }
function View_PassengerBundleComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "right-inner default-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_11)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "btn btn-primary btn-big btn-bundle-upgrade"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleExpanded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(8, null, ["", " "])), i1.ɵppd(9, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.startingFromPrice; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), "All passengers from", "bundle")); _ck(_v, 3, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent.parent, 0), "Upgrade", "bundle")); _ck(_v, 8, 0, currVal_2); }); }
function View_PassengerBundleComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "flight-items"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(2, 4), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "single-bundle-upgrade"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 2), i1.ɵpad(5, 1), i1.ɵppd(6, 2), i1.ɵpad(7, 1), i1.ɵppd(8, 4)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), "{0} to {1}", "", _v.context.$implicit.departureStation, _v.context.$implicit.arrivalStation)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), "{0} to {1} upgrade", "bundle", _ck(_v, 5, 0, i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 3), _v.context.$implicit.initialBundle, "bundle.name"))), _ck(_v, 7, 0, i1.ɵunv(_v, 3, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 3), _v.context.$implicit.selectedBundle, "bundle.name"))))); _ck(_v, 3, 0, currVal_1); }); }
function View_PassengerBundleComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "single-bundle-upgrade-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_14)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.upgradedFlightsList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PassengerBundleComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "right modified"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 18, "div", [["class", "right-inner default-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "button", [["class", "remove-from-cart"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sellBundles(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "font-ico-close-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 0, "span", [["class", "font-ico-checked-sign"], ["role", "presentation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_13)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "price"]], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵppd(15, 1), (_l()(), i1.ɵeld(16, 0, null, null, 3, "button", [["class", "btn btn-secondary"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleExpanded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(17, 2), (_l()(), i1.ɵted(18, null, [" ", " "])), i1.ɵppd(19, 2)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.bannerBundleSsrList.length; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), "Click to remove from cart", "extras")); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent.parent, 0), "Added to cart", "extras")); _ck(_v, 9, 0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", (_co.temporaryTotalPrice + "Romanian Leu"), ""); _ck(_v, 13, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent.parent.parent, 4), (_co.temporaryTotalPrice * _co.paxCount))); _ck(_v, 14, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent.parent.parent, 0), "Click to modify", "extras")), ""); _ck(_v, 16, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v.parent.parent.parent, 0), "Modify", "extras")); _ck(_v, 18, 0, currVal_6); }); }
function View_PassengerBundleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "checkin-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "bundle-options-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_8)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_10)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["modified", 2]], null, 0, null, View_PassengerBundleComponent_12))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.bannerBundleSsrList.length > 0); _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.bannerBundleSsrList.length; _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.temporaryTotalPrice == 0); var currVal_3 = i1.ɵnov(_v, 9); _ck(_v, 8, 0, currVal_2, currVal_3); }, null); }
function View_PassengerBundleComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " - ", ""])), i1.ɵppd(2, 4), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), "{0} to {1}", "", _co.flights[1].departureStation.name, _co.flights[1].arrivalStation.name)); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 5), _co.flights[1].departureStation.date, "fullDate")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_PassengerBundleComponent_20(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["role", "presentation"]], [[8, "className", 0]], null, null, null, null)), i1.ɵppd(1, 1), i1.ɵppd(2, 1), i1.ɵppd(3, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(3, "ssr-icon bundle-upgrade font2-ico-bundle-ssrs-", i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 1), _v.parent.parent.context.$implicit.serviceBundleCode)), "-", i1.ɵunv(_v, 0, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 1), _v.parent.context.$implicit.ssrCode)), " blue-dark font2-ico-ssrs-", i1.ɵunv(_v, 0, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 1), _v.parent.context.$implicit.ssrCode)), " font-ico-checked-sign"); _ck(_v, 0, 0, currVal_0); }); }
function View_PassengerBundleComponent_21(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent.parent, 2), _v.parent.context.$implicit.ssrCode, _v.parent.parent.context.$implicit.serviceBundleCode, _co.flights[0].departureStation.date)); _ck(_v, 0, 0, currVal_0); }); }
function View_PassengerBundleComponent_19(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_20)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_21)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.ssrCode !== "CKN"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.ssrCode !== "CKN"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_PassengerBundleComponent_22(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "price"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "])), i1.ɵppd(6, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), "Upgrade", "bundle")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 4), ((_v.parent.context.$implicit.price * _co.paxCount) * _co.flights.length))); _ck(_v, 5, 0, currVal_1); }); }
function View_PassengerBundleComponent_23(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "message message-single"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), "Selected", "bundle")); _ck(_v, 1, 0, currVal_0); }); }
function View_PassengerBundleComponent_18(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "button", [["class", "checkin-bundle-offer-wrapper"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectBundle(_v.context.index, 0, _v.context.$implicit.price) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "checkin-bundle-offer-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h5", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 2, "ul", [["class", "bundle-offer-checked-box"], ["role", "menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_19)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "checkin-bundle-offer-button"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_22)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["selected", 2]], null, 0, null, View_PassengerBundleComponent_23))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "checkin-bundle-offer-wrapper"; var currVal_1 = _co.getCustomClasses(_v.context.index, 0); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = _v.context.$implicit.serviceBundleSsrs; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_v.context.$implicit.isSelected; var currVal_5 = i1.ɵnov(_v, 12); _ck(_v, 11, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent.parent.parent, 2), _v.context.$implicit.serviceBundleCode)); _ck(_v, 4, 0, currVal_2); }); }
function View_PassengerBundleComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "checkin-bundle-options-buttons-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_18)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.journeyBundles[0].bundleList; _ck(_v, 3, 0, currVal_0); }, null); }
function View_PassengerBundleComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 34, "div", [["class", "checkin-box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "checkin-options-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(4, 2), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "strapline"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 3, "button", [["aria-hidden", "true"], ["class", "btn btn-secondary blue collapse-item"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelChanges() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(8, 2), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 23, "div", [["class", "checkin-bundle-options-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 11, "check-in-accordion", [], null, null, null, i3.View_CheckinAccordionComponent_0, i3.RenderType_CheckinAccordionComponent)), i1.ɵdid(13, 114688, null, 0, i4.CheckinAccordionComponent, [], null, null), (_l()(), i1.ɵeld(14, 0, null, 0, 7, "div", [["class", "trip-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", " - ", ""])), i1.ɵppd(17, 4), i1.ɵppd(18, 2), (_l()(), i1.ɵeld(19, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_16)), i1.ɵdid(21, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_PassengerBundleComponent_17)), i1.ɵdid(23, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 10, "div", [["class", "checkin-total-options-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 5, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 1, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(27, 2), (_l()(), i1.ɵeld(28, 0, null, null, 2, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(29, null, ["", ""])), i1.ɵppd(30, 1), (_l()(), i1.ɵeld(31, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["type", "button"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmBundle(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(32, 2), (_l()(), i1.ɵted(33, null, ["", ""])), i1.ɵppd(34, 2)], function (_ck, _v) { var _co = _v.component; _ck(_v, 13, 0); var currVal_6 = _co.flights[1]; _ck(_v, 21, 0, currVal_6); var currVal_7 = _co.journeyBundles[0]; _ck(_v, 23, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), "Upgrade your flights", "bundle")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), "Upgrade your fare(s) for all passengers", "bundle")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 0), "Click to cancel", "extras")), ""); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent.parent, 0), "Cancel", "extras")); _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent.parent, 0), "{0} to {1}", "", _co.flights[0].departureStation.name, _co.flights[0].arrivalStation.name)); var currVal_5 = i1.ɵunv(_v, 16, 1, _ck(_v, 18, 0, i1.ɵnov(_v.parent.parent, 5), _co.flights[0].departureStation.date, "fullDate")); _ck(_v, 16, 0, currVal_4, currVal_5); var currVal_8 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 26, 0, _ck(_v, 27, 0, i1.ɵnov(_v.parent.parent, 0), "<span class='hide-on-mobile'>Ancillary</span> Total:", "extras")), ""); _ck(_v, 26, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 29, 0, _ck(_v, 30, 0, i1.ɵnov(_v.parent.parent, 4), (_co.temporaryTotalPrice * _co.paxCount))); _ck(_v, 29, 0, currVal_9); var currVal_10 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 31, 0, _ck(_v, 32, 0, i1.ɵnov(_v.parent.parent, 0), "Click to add to cart", "extras")), ""); _ck(_v, 31, 0, currVal_10); var currVal_11 = i1.ɵunv(_v, 33, 0, _ck(_v, 34, 0, i1.ɵnov(_v.parent.parent, 0), "Confirm", "extras")); _ck(_v, 33, 0, currVal_11); }); }
function View_PassengerBundleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "checkin-extra-options"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "blue-loading-spinner", [], null, null, null, i5.View_LoadingSpinnerComponent_0, i5.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 4964352, null, 0, i6.LoadingSpinnerComponent, [i7.LoadingSpinnerService, i1.ElementRef, i1.Renderer2], { id: [0, "id"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_15)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spinnerId; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.showHeader && !_co.isExpanded); _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.showHeader || _co.isExpanded); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_PassengerBundleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.TranslatePipe, [i9.TranslateService]), i1.ɵpid(0, i2.LowerCasePipe, []), i1.ɵpid(0, i10.SsrTranslatePipe, [i9.TranslateService, i11.DomSanitizer]), i1.ɵpid(0, i12.TranslationWithHtml, [i9.TranslateService, i11.DomSanitizer]), i1.ɵpid(0, i13.CurrencyDisplayPipe, [i14.FlowManagerService, i9.TranslateService]), i1.ɵpid(0, i15.CustomDatePipe, []), i1.ɵqud(402653184, 1, { bundleForBothInfoModal: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerBundleComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 12, "check-in-default-modal", [["cancelButtonText", ""], ["modalId", "bundleForBothInfoModal"], ["modalTitle", ""], ["okButtonText", ""]], null, null, null, i16.View_DefaultModalComponent_0, i16.RenderType_DefaultModalComponent)), i1.ɵdid(10, 114688, [[1, 4], ["bundleForBothInfoModal", 4]], 0, i17.DefaultModalComponent, [i18.BlueModalService, i19.SsrsService], { modalId: [0, "modalId"], modalTitle: [1, "modalTitle"], cancelButtonText: [2, "cancelButtonText"], okButtonText: [3, "okButtonText"] }, null), (_l()(), i1.ɵeld(11, 0, null, 0, 0, "div", [["class", "warning-sign"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, 0, 1, "div", [["class", "section-SubTitle"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(13, 2), (_l()(), i1.ɵeld(14, 0, null, 0, 7, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "button", [["class", "btn btn-primary btn-small btn-left inverted"], ["type", "submit"]], [[1, "aria-label", 0], [8, "innerHTML", 1]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closebundleForBothInfoModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(16, 2), i1.ɵppd(17, 2), (_l()(), i1.ɵeld(18, 0, null, null, 3, "button", [["class", "btn btn-primary btn-small btn-right"], ["type", "submit"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sellBundles(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(19, 2), (_l()(), i1.ɵted(20, null, ["", ""])), i1.ɵppd(21, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible && !_co.hasCheckedInPax); _ck(_v, 8, 0, currVal_0); var currVal_1 = "bundleForBothInfoModal"; var currVal_2 = ""; var currVal_3 = ""; var currVal_4 = ""; _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), "The following bundles will be applied for both inbound and outbound flights", "shared")); _ck(_v, 12, 0, currVal_5); var currVal_6 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i1.ɵnov(_v, 0), "Click to dismiss", "shared")), ""); var currVal_7 = i1.ɵunv(_v, 15, 1, _ck(_v, 17, 0, i1.ɵnov(_v, 0), "Reconsider", "extras")); _ck(_v, 15, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i1.ɵnov(_v, 0), "Click to dismiss", "shared")), ""); _ck(_v, 18, 0, currVal_8); var currVal_9 = i1.ɵunv(_v, 20, 0, _ck(_v, 21, 0, i1.ɵnov(_v, 0), "Confirm", "extras")); _ck(_v, 20, 0, currVal_9); }); }
export function View_PassengerBundleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "passenger-bundle", [], null, null, null, View_PassengerBundleComponent_0, RenderType_PassengerBundleComponent)), i1.ɵdid(1, 245760, null, 0, i20.PassengerBundleComponent, [i21.BundleService, i14.FlowManagerService, i7.LoadingSpinnerService, i19.SsrsService, i22.BookingStepsService, i23.BookingService, i24.ECommerceService, i9.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassengerBundleComponentNgFactory = i1.ɵccf("passenger-bundle", i20.PassengerBundleComponent, View_PassengerBundleComponent_Host_0, { pbagModal: "pbagModal", showHeader: "showHeader" }, { bundleChanged: "bundleChanged" }, []);
export { PassengerBundleComponentNgFactory as PassengerBundleComponentNgFactory };
