import { Injectable, RendererFactory2, Inject } from '@angular/core';

@Injectable()
export class CurrencyManagerService {
  currencyList: string[];
  private currencyListDOM: string[] = [];
  private currentCurrency: string;

  constructor() { }

  /** Init currency manager */
  init() {
    this.tryInitDOMCurrencies();

    this.currencyList = this.currencyListDOM.length ? this.currencyListDOM : this.getDefaultList();
  }

  /** Get default currency list */
  private getDefaultList() {
    return ['EUR', 'RON', 'GBP', 'USD', 'SEK', 'DKK'];
  }

  /** Update current currency from within the app */
  public updateCurrentCurrency(newCurrency: string) {
    // nothing to do if there is now change
    if (this.currentCurrency === newCurrency) {
      return;
    }
    this.currentCurrency = newCurrency;
    // if we loaded currencies from DOM - update the current currency in DOM
    if (this.currencyListDOM.length) {
      this.updateCurrencyInDOM(newCurrency);
    }
  }

  /** Init and setup currencies using DOM (corporate website) */
  private tryInitDOMCurrencies(): void {
    const currencyDOMElements = this.getCurrencyDOMElements();
    if (currencyDOMElements.allElementsFound) {
      currencyDOMElements.selectList.classList.add('disabled');
      this.updateCurrencyListDOM(currencyDOMElements.selectList);
    }
  }

  /** Get Currencies list from DOM (corporate website) */
  private updateCurrencyListDOM(currenciesSelectList: Element): void {
    this.currencyListDOM = [];
    const currencies = currenciesSelectList.querySelectorAll('.currency-item');
    if (currencies) {
      Array.from(currencies).forEach(c => {
        const value = c.getAttribute('data-value');
        this.currencyListDOM.push(value);
      });
    }
  }

  /** update the current currency in DOM (corporate website)*/
  private updateCurrencyInDOM(newCurrency: string): void {
    const currencyDOMElements = this.getCurrencyDOMElements();

    if (currencyDOMElements.allElementsFound) {
      const newCurrencyElem = currencyDOMElements.selectList.querySelector(`.currency-item[data-value="${newCurrency}"]`);
      const selectedInfo = currencyDOMElements.selectList.querySelector('.selected');
      if (newCurrencyElem && selectedInfo) {
        const currencyText = newCurrencyElem.textContent;
        selectedInfo.textContent = currencyText;

        const currentInfoElem = currencyDOMElements.container.querySelector('.current-selected-info');
        if (currentInfoElem && currentInfoElem.nextSibling) {
          currentInfoElem.nextSibling.textContent = `(${currencyText.split('-')[0]})`;
        }
      }

      const selectedCurrencyElem = document.getElementById('selectedCurrency') as HTMLInputElement;
      if (selectedCurrencyElem) {
        selectedCurrencyElem.value = newCurrency;
      }
    }
  }

  /** Get the necessary DOM elements (corporate website)  */
  private getCurrencyDOMElements(): { container: Element, selectList: Element, allElementsFound: boolean } {
    const container = document.getElementById('language-currency-selector');
    if (container) {
      const currenciesSelectList = container.querySelector('.currency-select-list');
      if (currenciesSelectList) {
        return {
          container: container,
          selectList: currenciesSelectList,
          allElementsFound: true
        };
      }
    }

    return {
      container: null,
      selectList: null,
      allElementsFound: false
    };
  }

}
