import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SeatComponent } from './seat/seat.component';
import { SeatRoutes, BookingSeatRoutes } from './seat-routes';
import { SharedModule } from '../shared/shared.module';
import { FormWrapperComponent } from '../shared/form-wrapper/form-wrapper.component';
import { SeatMapService } from './seat-map.service';
import { CanActivateSeatMapService } from './can-activate-seat-map.service';
import { SeatMapLegendComponent } from './seat-map-legend/seat-map-legend.component';
import { SeatMapComponent } from './seat-map/seat-map.component';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { environment } from '../../environments/environment';
import { CanDeactivateComponentService } from '../core/can-deactivate-component.service';
import { CanActivateSeatMapOnBookingService } from './can-activate-seat-map-on-booking.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    // RouterModule.forRoot(
    //  environment.flow == 0 ? SeatRoutes :BookingSeatRoutes
    // ),
    BlueAirCommonModule
  ],
  declarations: [SeatComponent, SeatMapLegendComponent, SeatMapComponent],
  providers:[CanActivateSeatMapService,SeatMapService, CanDeactivateComponentService, CanActivateSeatMapOnBookingService]
})
export class SeatModule { }
