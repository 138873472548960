export interface ICheckinValidationError {
    errorType: CheckinValidationErrorType;
    errorData?: any;
}

export enum CheckinValidationErrorType {
    mandatoryFields = 1,
    bookingNotFound = 2,
    pendingPayments = 3,
    checkinClosed = 4,
    checkinNotOpen = 5,
    checkinClosedOnMarket = 6,
    checkinClosedForCodeshare = 7,
    checkinNotOpenedNoUpgrade = 8,
    checkinNotOpenedUpgradeAvailable = 9,
    checkinClosedForChildrenNoSeatsSelected = 10,
    checkinRestrictedForGroups = 11
}
