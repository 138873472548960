import { BlueModalService } from '../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

export abstract class ProfileInfoBox {
    editModalId: string;
    editSpinnerId: string;

    @ViewChild('editForm', { static: true }) editForm: NgForm;

    constructor(protected modalService: BlueModalService) {

    }

    resetForm() {
        this.editForm.reset();
    }

    abstract edit();
    abstract save();

    cancel(event: any) {
        this.closeModal();
    }

    openModal(modalId: string = null) {
        this.modalService.open(modalId || this.editModalId);
    }

    closeModal(modalId: string = null) {
        this.modalService.close(modalId || this.editModalId);
    }

    findTextByValue(arr: any[], value: string) {
        for (const i of arr) {
            if (i.value === value) {
                return i.text;
            }
        }
        return value;
    }
}
