import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { CheckinSteps, BookingStepsService } from '../../core/booking-steps.service';
import { ConfigService } from '../../core/config.service';
import { SeatMapService } from '../seat-map.service';
import { LoggerService } from '../../common-modules/log4ts/logger.service';
import { JourneyViewModel, SeatMap } from '../models/seat-models';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BookingService } from '../../core/booking.service';
import { SeatSelectedNotification } from '../../shared/seat-selected-notification/seat-selected-notification.component';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../../constants';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { environment } from '../../../environments/environment';
import { ICanDeactivateComponent } from '../../core/can-deactivate-component.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { SsrType } from '../../extras/ssr-type.enum';
import { IHasPendingChanges } from '../../core/has-pending-changes.interface';
import { PassengerModel } from '../../core/models/passenger-model';
import * as moment from 'moment'
import { SelectionsClearWarningModalComponent } from '../../shared/selections-clear-warning-modal/selections-clear-warning-modal.component';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { BookingFareSelectSyncService } from '../../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { BookingFlowService } from 'src/app/core/booking-flow.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';

@Component({
  selector: 'check-in-seat',
  templateUrl: './seat.component.html',
  styleUrls: ['./seat.component.scss'],
  //  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SeatComponent implements OnInit, ICanDeactivateComponent, IHasPendingChanges {
  private currentStep = CheckinSteps.seat;
  private booking: any;
  private selectSeat: boolean = false;
  private flightsSubscription: Subscription;
  public SsrTypeEnum = SsrType;
  viewstep: any;
  journeys: Array<any> = [];
  flights: Array<any> = [];
  seatMap: SeatMap = new SeatMap();// ={};
  selectedPassenger: any;
  passengerNotifier: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  errorMessage: Array<any> = [];
  selectedJourneyPassenger: any;
  currentMap: any;
  isModalOpened: boolean;
  isCheckinFlow: boolean = false;
  connectedJourney: Array<string> = [];
  filterClickNotifier: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  seatDeselectNotifier: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  _canDeactivate: boolean = false;
  modalId = 'seatSelecModal';
  selectedSeats: { [flightKey: string]: string; } = {};
  passengers: Array<any> = [];
  pendingSsrsList: SsrType[] = [];
  isPromotion: boolean = false;
  isBlueBenefit: boolean = false;
  noSeatSelectedModalId = 'noSeatSelectedModal';
  assetsPath: string;

  @ViewChild('noSeatSelectedModal', { static: true }) noSeatSelectedModal: DefaultModalComponent;
  @ViewChild('noSeatSelectedChildsModal', { static: true }) noSeatSelectedChildsModal: DefaultModalComponent;
  @ViewChild(SeatSelectedNotification, { static: true }) pendingChangesModal: SeatSelectedNotification;
  @ViewChild('changeFlightsWarningModal', { static: true }) clearSelectionsModal: SelectionsClearWarningModalComponent;
  @Output() seatFilter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public config: ConfigService,
    private seatMapService: SeatMapService,
    private bookingSteps: BookingStepsService,
    private bookingService: BookingService,
    private loadingSpinnerService: LoadingSpinnerService,
    private flowManager: FlowManagerService,
    private translateService: TranslateService,
    private elRef: ElementRef,
    private ecommerce: ECommerceService,
    private syncService: BookingFareSelectSyncService,
    private bookingFlowService: BookingFlowService) {
    this.assetsPath = environment.assetsPath;
    this.isCheckinFlow = environment.flow === 0;
    this.bookingSteps.currentStep.next(this.currentStep);
    this.bookingService.bookingObs.subscribe(data => {
      if (data) {
        //only selected seats
        //data.assignedSeats.journeys.items.map(j=>j.segments).reduce((a,b)=>a.concat(b),[]).map(p=>p.items).reduce((a,b)=>a.concat(b),[]).map(p=>p.paxSeats).reduce((a,b)=>a.concat(b),[]).map(p=>p.items).reduce((a,b)=>a.concat(b),[])
        this.booking = data;
        this.isBlueBenefit = this.booking.passengers.items.find(pax => pax.typeInfo.paxType.length > 3);
        let typeCounter = {};

        this.passengers = data.passengers.items.map(p => new PassengerModel(p, typeCounter, this.syncService));

        this.passengers.forEach(p => {
          if (PassengerModel.checkNameFormat(p.name.first) && PassengerModel.checkNameFormat(p.name.first)) {
            p.name.first = '';
            p.name.last = '';
          }

          p.assignedSeat = {}
        });

        this.remapSelectedSeats();
      }
    });

    this.seatMapService.errorMessage.subscribe(data => {
      if (Array.isArray(data))
        this.errorMessage = data as Array<any>
    });

    this.viewstep = translateService.instant('Step 5 Check-in form', 'seat');
  }

  ngOnInit() {
    // if (!localStorage.getItem("pageState")) {
    //   localStorage.setItem("pageState", "reloaded");
    //   window.location.reload();
    // }

    this.loadData();
  }

  ngOnDestroy() {
    localStorage.removeItem("pageState");
  }
  refresh() {
    this.loadData();
  }

  checkSeatPromotion() {
    const configPromo = this.config.config.promo as any[];
    if (configPromo && configPromo.length) {
      const promoForSsrType = configPromo.find(p => p.ssrType === SsrType.Seat);
      const now = moment();
      if (promoForSsrType && promoForSsrType.blueBenefits &&
        now.isSameOrAfter(moment.utc(promoForSsrType.fromDate, 'YYYY-MM-DD HH:mm')) &&
        now.isSameOrBefore(moment.utc(promoForSsrType.toDate, 'YYYY-MM-DD HH:mm'))) {
        return true;
      }
    }
  }

  loadData() {
    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
    this.isPromotion = this.checkSeatPromotion() && this.isBlueBenefit;

    this.flowManager.applicationFlowService.loadPriceBreakdown();

    this.seatMapService.refreshSeatMap()
      .then(_ => {
        this.journeys = this.seatMapService.journeys;
        this.seatMap = this.seatMapService.seatMap;
      })
      .then(_ => {
        this.seatMap.seatAssignment.journeys.forEach(j => {
          if (j.seatSegmentKeys.length > 1) {
            this.connectedJourney.push(this.translateService.instant(j.seatSegmentKeys[0].departureStation, 'station.name')
              + ' - ' + this.translateService.instant(j.seatSegmentKeys[j.seatSegmentKeys.length - 1].arrivalStation, 'station.name'));
          }
        });

        this.checkSelectedSeats();

        this.remapSelectedSeats();

        this.bookingFlowService.loadFlowInfo().then(booking => {
          this.flights = booking.convertedJourneys.journeys;
        });

        setTimeout(_ => {
          if (this.seatMap.seatMaps.length > 0) {
            this.selectMap(this.seatMap.seatMaps[0]);
          }
        }, 100);

      })
      .then(_ => this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId));
  }

  getAncillaryPrice() {
    let total = 0;
    this.seatMap.seatMaps.forEach(sm => {
      total += this.getPriceForMap(sm);
    });
    return total;
  }

  continue() {
    this.goToNextStep();
  }

  getPriceForMaps(journey) {
    if (!journey.currentSeatSelection) {
      let total = 0;

      journey.seatmaps.forEach(s => {
        total += this.getPriceForMap(s);
      });

      return total;
    }

    return journey.currentSeatSelection.reduce((a, b) => a = a > b.price ? a : b.price, 0);
  }

  getPriceForMap(map) {
    let sum = this.booking.assignedSeats.passengers.items
      .map(p => p.fees).reduce((a, b) => a.concat(b), [])
      .map(p => p.items).reduce((a, b) => a.concat(b), []).filter(p => p.flightReference == map.flightKey)
      .map(p => p.serviceCharges)
      .map(p => p.items).reduce((a, b) => a.concat(b), []).filter(p => p.chargeType == "ServiceCharge" && p.chargeCode == "XLR")
      .map(p => p.amount).reduce((a, b) => a + b, 0);

    let discount = this.booking.assignedSeats.passengers.items
      .map(p => p.fees).reduce((a, b) => a.concat(b), [])
      .map(p => p.items).reduce((a, b) => a.concat(b), []).filter(p => p.flightReference == map.flightKey)
      .map(p => p.serviceCharges)
      .map(p => p.items).reduce((a, b) => a.concat(b), []).filter(p => p.chargeType == "Discount" && p.chargeCode == "XLR")
      .map(p => p.amount).reduce((a, b) => a + b, 0);
    return sum - discount;
  }

  getPaxForJourney(map) {
    let journey = this.seatMap.seatAssignment.journeys.find(p => p.sellKey == map.sellKey);
    let pax = journey.seatSegmentKeys.find(p => p.flightKey == map.flightKey).passengers;

    this.flowManager.selectionService.passengersObs.subscribe(passengersByType => {
      if (passengersByType.length > 0) {
        pax.forEach((passenger) => {
          if (!passenger.first && !passenger.last) {
            passenger.first = passengersByType.filter(pax => pax.passengerNumber === passenger.number).map(p => p.name.first + ' ' + p.name.last);
          }
        });
      }
    });

    return pax;
  }

  selectMap(map) {
    this.currentMap = map;

    map.ariaHidden = !map.ariaHidden;
    map.displayMode = map.ariaHidden ? 'block' : "";
    map.isExpanded = !map.isExpanded;

    this.seatMap.seatMaps.filter(p => p.sellKey != map.sellKey).forEach(p => {
      p.ariaHidden = false;
      p.displayMode = '';
      p.isExpanded = false;
    });

    setTimeout(
      () => {
        const selector = 'button[data-key="' + map.flightKey + '"]';

        var e = this.elRef.nativeElement.querySelector(selector);
        if (e) {
          e.scrollIntoView({ behavior: 'smooth' });
        }
      },
      500);

    //select first pax
    this.selectedPax(this.passengers[0]);
  }

  removeSeat(pax, map) {
    let journey = this.seatMap.journeys.find(p => p.seatmaps.some(s => s.flightKey == map.flightKey));
    journey.removeSeat(pax.passengerNumber, map.flightKey);
    pax.unassigSeats = pax.unassigSeats || {};

    pax.unassigSeats[map.flightKey] = pax.assignedSeat[map.flightKey];

    delete pax.assignedSeat[map.flightKey]
    let unit = map.decks.find(p => p.number == pax.unassigSeats[map.flightKey].deck).compartments.find(p => p.compartmentDesignator == pax.unassigSeats[map.flightKey].compartment).units.find(p => p.unitDesignator == pax.unassigSeats[map.flightKey].unit);
    this.filterClickNotifier.next(5);

    unit.decorator = unit.baseDecorator || unit.masterDecorator;
    unit.passengerNumber = "";

    const ecommerceCartItems = new Array();
    const item = new ECommerceCartItem('Seat', this.ecommerce.getFlightInfo(journey.journeyIndex) + pax.passengerNumber,
      unit.price, ECommerceService.BrandName, 'Seat', unit.unitDesignator, 1, null,
      this.passengers.find(p => p.passengerNumber === pax.passengerNumber).paxType,
      this.ecommerce.getUserData()).getItem(false);
    ecommerceCartItems.push(item);
    this.ecommerce.RemoveFromCart(ecommerceCartItems);

    this.selectedPax(pax);
    this.seatDeselectNotifier.next({ unitDesignator: unit.unitDesignator, passsenger: pax.passengerNumber });

    this.addToCart();
  }

  selectedPax(pax) {
    // this.passengers.forEach(o=>o.selected = false);
    // pax.selected = true;
    let p = this.booking.assignedSeats.passengers.items.find(x => x.passengerNumber == pax.passengerNumber);
    this.passengerNotifier.next(pax);
    this.selectedJourneyPassenger = pax;
    this.selectedPassenger = p;

    if (this.selectedPassenger.fees.items.findIndex(x => x.code === Constants.InfantSsrCode) !== -1) {
      this.selectedPassenger.hasInfant = true;
    }
  }

  seatSelected(event) {
    let journey = this.seatMap.journeys.find(p => p.seatmaps.some(s => s.flightKey == event.flightKey));
    let pax = this.passengers.find(p => p.passengerNumber == this.selectedJourneyPassenger.passengerNumber);
    journey.addSeat(event, pax);

    this.selectSeat = true;
    const ecommerceCartItems = [];
    const item = new ECommerceCartItem('Seat', this.ecommerce.getFlightInfo(journey.journeyIndex) + pax.passengerNumber,
      event.price, 'BlueAir', 'Seat', event.unitDesignator, 1, 1, null,
      this.ecommerce.getUserData()).getItem(true);
    ecommerceCartItems.push(item);
    this.ecommerce.ProductClicks(ecommerceCartItems);

    //select next pax
    if (this.booking.assignedSeats.passengers.items.length > 1) {
      let passengerIndex = this.passengers.findIndex(p => p.passengerNumber == this.selectedPassenger.passengerNumber);

      if (passengerIndex < this.passengers.length - 1) {
        this.selectedPax(this.passengers[passengerIndex + 1]);
      }
    }
    this.checkSelectedSeats();
  }

  addToCart() {

    let deSelectedSeats = this.seatMap.journeys.map(p => p.currentSeatsToDelete).reduce((a, b) => a.concat(b), [])
      .reduce((a, b) => a.concat(b), [])
      .map(p => '' + p.passengerNumber + '|' + p.flightKey + '|' + p.deck + '|' + p.compartment + '|' + p.unit + '|xx');

    let selectedSegmentsSeats =
      this.passengers.map(p => p.assignedSeat).map(m => ({ flightKey: Object.keys(m), seat: m })).reduce((a, b) => a.concat(b), [])
        .map(p => p.flightKey.map(f => (
          {
            flightKey: f,
            number: p.seat[f].passengerNumber,
            deck: p.seat[f].deck,
            compartment: p.seat[f].compartment,
            unit: p.seat[f].unit
          }))).reduce((a, b) => a.concat(b), [])
        .map(p => '' + p.number + '|' + p.flightKey + '|' + p.deck + '|' + p.compartment + '|' + p.unit);

    this.selectSeat = false;
    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
    var sell = [];

    if (deSelectedSeats.length > 0) {
      sell = sell.concat(deSelectedSeats);
    }

    if (selectedSegmentsSeats.length > 0) {
      sell = sell.concat(selectedSegmentsSeats);
    }

    this.seatMapService.sellSeat(sell).then(() => {
      this.bookingService.refresh(true).then(data => {
        const ecommerceCartItems = new Array();
        this.seatMap.journeys.forEach(j => {
          j.currentSeatSelection.forEach(cs => {
            const item = new ECommerceCartItem('Seat', this.ecommerce.getFlightInfo(j.journeyIndex) + cs.passengerNumber,
              cs.price, 'BlueAir', 'Seat', cs.unit, 1, null,
              this.passengers.find(pax => pax.passengerNumber === cs.passengerNumber).paxType,
              this.ecommerce.getUserData()).getItem(false);
            ecommerceCartItems.push(item);
          });
        });
        this.ecommerce.AddToCart(ecommerceCartItems);

        this.seatMap.journeys.forEach(p => { p.currentSeatsToDelete = []; p.currentSeatSelection = [] });
        this.booking = data;
        this.journeys = this.seatMapService.journeys;
        this.seatMap = this.seatMapService.seatMap;
        this.remapSelectedSeats();
        this.checkSelectedSeats();
        this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
      })
    });
  }

  remapSelectedSeats() {
    this.seatMap.seatMaps.forEach(m => {
      let selectedPassengerSeats = this.seatMap.seatAssignment.journeys
        .map(p => p.seatSegmentKeys).reduce((a, b) => a.concat(b), []).filter(p => p.flightKey == m.flightKey)
        .map(s => (s.passengers.map(p => ({ unit: p.assignedSeat, pax: p.number, flightKey: m.flightKey })))).reduce((a, b) => a.concat(b), []);
      let journeys = this.seatMap.journeys;
      let currentJourney = this.journeys.find(p => p.flightKey == m.flightKey);

      selectedPassengerSeats.forEach(ps => {
        if (ps.unit.deck) {
          let unit = currentJourney.decks.find(p => p.number == ps.unit.deck).compartments
            .find(p => p.compartmentDesignator == ps.unit.compartment).units.find(u => u.unitDesignator == ps.unit.unit);
          journeys.find(p => p.seatmaps.some(s => s.flightKey == ps.flightKey)).addSeat(unit, this.passengers.find(p => p.passengerNumber == ps.pax));
        }
      });
    });
  }

  checkSelectedSeats() {
    this.seatMap.seatMaps.forEach(m => {
      this.selectedSeats[m.flightKey] = this.seatMap.seatAssignment.journeys.map(p => p.seatSegmentKeys).reduce((a, b) => a.concat(b), [])
        .filter(f => f.flightKey === m.flightKey).map(s => (s.passengers.map(p => (p.assignedSeat.unit))))
        .reduce((a, b) => a.concat(b), []).filter(p => p).join(', ');
    });
  }

  filterSelected(filter) {
    this.filterClickNotifier.next(filter);
  }

  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    let nextStep = this.bookingSteps.extractStep(nextState);
    let redirectingToOtherBookingStep = true;

    if (nextStep === null) {
      redirectingToOtherBookingStep = false;
      nextStep = CheckinSteps.search;
    }

    if (this.clearSelectionsModal.shouldShowWarning(this.currentStep, nextStep)) {
      return this.clearSelectionsModal.showWarningAndNavigate(
        this.currentStep,
        redirectingToOtherBookingStep ? nextStep : null,
        nextState.url);
    }
    if (!this._canDeactivate) {
      this.goToNextStep(nextStep);
    }

    return this._canDeactivate;
  }

  hasPendingChanges(): boolean {
    let ssrsOk = true;
    this.pendingSsrsList = [];

    if (this.selectSeat) {
      this.pendingSsrsList.push(SsrType.Seat);
    }

    if (this.pendingSsrsList.length) {
      ssrsOk = false;
    }

    this.seatMap.seatMaps.forEach(m => {
      let flight = this.seatMap.journeys.find(x => x.flightKey === m.flightKey);

      if (!this.selectedSeats[m.flightKey] && !this.isCanceledFlight(flight)) {
        ssrsOk = false;
      }
    });

    return ssrsOk;
  }

  goToNextStep(nextStep?: CheckinSteps) {
    this._canDeactivate = this.hasPendingChanges();

    if (!this._canDeactivate && this.checkChildPassengersSeat()) {
      this.noSeatSelectedChildsModal.openPopup((isOk) => {
        this._canDeactivate = isOk;
        if (isOk) {
          if (nextStep !== null && nextStep !== undefined) {
            this.checkoutEcommerce();
            this.bookingSteps.goToStep(nextStep);
          } else {
            this.checkoutEcommerce();
            this.bookingSteps.goToNextStepFrom(this.currentStep);
          }
        }
      });
    } else if (!this._canDeactivate) {
      this.noSeatSelectedModal.openPopup((isOk) => {
        this._canDeactivate = isOk;
        if (isOk) {
          if (nextStep !== null && nextStep !== undefined) {
            this.checkoutEcommerce();
            this.bookingSteps.goToStep(nextStep);
          } else {
            this.checkoutEcommerce();
            this.bookingSteps.goToNextStepFrom(this.currentStep);
          }
        }
      });
    } else {
      if (nextStep !== null && nextStep !== undefined) {
        this.checkoutEcommerce();
        this.bookingSteps.goToStep(nextStep);
      } else {
        this.checkoutEcommerce();
        this.bookingSteps.goToNextStepFrom(this.currentStep);
      }
    }
  }

  checkoutEcommerce() {
    let ecommerceCartItems = [];
    this.flowManager.applicationFlowService.loadPriceBreakdown().then(breakdown => {
      if (breakdown) {
        ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown);
        this.ecommerce.Checkout(ecommerceCartItems, 3, 'Seats step');
      }
    });
  }

  isCanceledFlight(flight: JourneyViewModel) {
    for (let j = 0; j < this.seatMap.seatAssignment.journeys.length; j++) {
      if (flight && flight.journeyIndex == j) {
        for (const f of this.flights) {
          if (this.seatMap.seatAssignment.journeys[j].sellKey == f.sellKey) {
            for (const s of f.segments) {
              for (const l of s.legs) {
                if (l.legInfo.status === "Canceled") {
                  return true;
                }
              }
            }
          }
        }
      }
    }

    return false;
  }

  checkChildPassengersSeat(): boolean {
    return (this.passengers.filter(p => p.hasInfant || p.paxType == 'CHD')).length > 0;
  }
}
