import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { PassengerTypeSelectionViewModel } from '../models/passenger-selection-view-model';
import { BookingFlightSelectService } from '../booking-flight-select.service';
import { Subscription } from 'rxjs';
import { IPassengerSearch } from '../models/flight-search-model';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import {
  ShoppingCartExtrasModel, ShoppingCartExtrasFlightModel,
  ShoppingCartExtrasSsrItemModel, ShoppingCartExtrasPassengerModel
} from 'src/app/core/models/shopping-cart-models';
import { SsrType } from 'src/app/extras/ssr-type.enum';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';

@Component({
  selector: 'check-in-bluebenefits-select',
  templateUrl: './bluebenefits-select.component.html',
  styleUrls: ['./bluebenefits-select.component.scss']
})
export class BluebenefitsSelectComponent implements OnInit, OnDestroy {
  private promoSubscription: Subscription;

  @Output() goBackToFlightSelection: EventEmitter<any> = new EventEmitter();
  @Input() passengers: IPassengerSearch[];
  @Input() currency: string;

  paxCount: number = 0;
  selectedBenefitType: string;
  promoItems: PromoInfoListItem[];
  currentBalanceDue: number;

  constructor(private flightService: BookingFlightSelectService, private ecommerce: ECommerceService,
    public flowManager: FlowManagerService) {
    this.currentBalanceDue = this.flowManager.applicationFlowService.shoppingCartBreakdown.value.currentShoppingCart.balanceDue;
  }

  ngOnInit() {
    if (this.passengers) {
      this.paxCount = new PassengerTypeSelectionViewModel().initFromPassengersSearch(this.passengers).count;
    }
    this.promoSubscription = this.flightService.getAvailableBenefitPromos(this.currency).subscribe(promos => {
      this.promoItems = promos.filter(promo => promo.maxPaxCount >= this.paxCount);
    });

    const ecommerceCartItems = new Array();
    const item = new ECommerceCartItem('Bluebenefits selection view', this.ecommerce.getFlightInfo(0),
      '0', 'BlueAir', 'Bluebenefits selection', this.ecommerce.getFlightType(), 1, 0).getItem(true);
    ecommerceCartItems.push(item);
    this.ecommerce.ProductViews(ecommerceCartItems);
  }

  ngOnDestroy(): void {
    if (this.promoSubscription) {
      this.promoSubscription.unsubscribe();
    }
  }

  goBackToFlightSelect() {
    const sc = this.flowManager.applicationFlowService.shoppingCartBreakdown;
    sc.value.overrideBalanceDue(null);
    sc.value.currentShoppingCart.extras = sc.value.currentShoppingCart.extras.filter(sp => sp.ssrType !== SsrType.BlueBenefits);
    sc.next(sc.value);
    this.goBackToFlightSelection.emit();
  }

  setupBenefitSelection(benefit: any) {
    const shoppingCartExtras = new ShoppingCartExtrasModel();
    shoppingCartExtras.ssrType = SsrType.BlueBenefits;
    shoppingCartExtras.amount = benefit.price;

    const shoppingCartExtrasFlight = new ShoppingCartExtrasFlightModel();
    shoppingCartExtrasFlight.hasItems = true;

    const shoppingCartExtrasFlightModel = new ShoppingCartExtrasFlightModel();
    shoppingCartExtrasFlightModel.passengers = [];

    const shoppingCartExtrasSsrItemModel = new ShoppingCartExtrasSsrItemModel();
    shoppingCartExtrasSsrItemModel.amount = benefit.price;
    shoppingCartExtrasSsrItemModel.quantity = 1;
    shoppingCartExtrasSsrItemModel.ssrCode = benefit.promoId;

    const shoppingCartExtrasPassengerModel = new ShoppingCartExtrasPassengerModel();
    shoppingCartExtrasPassengerModel.items = [];
    shoppingCartExtrasPassengerModel.items.push(shoppingCartExtrasSsrItemModel);

    shoppingCartExtrasFlightModel.passengers.push(shoppingCartExtrasPassengerModel);
    shoppingCartExtrasFlight.segments.push(shoppingCartExtrasFlightModel);
    shoppingCartExtras.flights.push(shoppingCartExtrasFlight);
    shoppingCartExtras.flightsWithItems.push(shoppingCartExtrasFlight);
    return shoppingCartExtras;
  }

  selectBenefit(benefit: any) {
    this.selectedBenefitType = benefit.promoId;

    const sc = this.flowManager.applicationFlowService.shoppingCartBreakdown;
    sc.value.overrideBalanceDue(this.currentBalanceDue + benefit.price);
    sc.value.currentShoppingCart.extras = sc.value.currentShoppingCart.extras.filter(sp => sp.ssrType !== SsrType.BlueBenefits);
    sc.value.currentShoppingCart.extras.push(this.setupBenefitSelection(benefit));
    sc.next(sc.value);

    const ecommerceCartItems = new Array();
    const item = new ECommerceCartItem('Bluebenefits selection click', this.ecommerce.getFlightInfo(0),
      benefit.price.toString(), 'BlueAir', 'Bluebenefits selection', benefit.promoId, 1, 0).getItem(true);
    ecommerceCartItems.push(item);
    this.ecommerce.ProductClicks(ecommerceCartItems);
  }
}

export class PromoInfoListItem {
  promoId: string;
  maxPaxCount: number;
  price: number;
  currencyCode: string;
}
