import { Directive, Input, Output, EventEmitter, OnChanges, Renderer2, ElementRef, OnDestroy, SimpleChange, SimpleChanges, Host, Self, Optional } from '@angular/core';
import { NgModel, NgControl } from '@angular/forms';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { DatePickerRestriction } from '../../common-modules/blue-air-common/blue-date-picker/date-picker-restriction.enum';
import { BlueDatePickerComponent } from '../../common-modules/blue-air-common/blue-date-picker/blue-date-picker.component';

@Directive({
  selector: '[my-error]'
})
export class ValidationDirective implements OnDestroy, OnChanges  {
  @Input('my-error') myError: boolean;
  @Input() pattern: string;
  @Input() patternError: string;
  @Input() required: any;

  private statusChangesSubscription: any;
  private enter: boolean = false;
  private style: string ="color:#be0000;font-size:17px;font-weight:300;letter-spacing:0px;line-height:25.5px;float:left";

  constructor(private control: NgControl, private el: ElementRef,  private renderer: Renderer2, 
    private translateService: TranslateService, @Host() @Self() @Optional() public datePickerComponent?: BlueDatePickerComponent) {
    this.statusChangesSubscription = this.control.statusChanges.subscribe(s => {
   this.validate();
    });
   }

   validate() {
    if (this.control.invalid && (this.myError)) {
      this.showError();
    } else {
      this.hideError('pattern');
      this.hideError('validation');
    }
   }

   showError() {
      if (!this.control.value) {
        const htmlel =  this.el.nativeElement as HTMLElement;
        this.hideError('validation');
        const text = this.processRestriction();
        htmlel.insertAdjacentHTML('afterend', 
          `<span class=\'validation\' style="` + this.style + `">${text}</span>`);
          this.hideError('pattern');
      } else {
        if(!this.enter){
          const htmlel =  this.el.nativeElement as HTMLElement;
          this.hideError('pattern');
          htmlel.insertAdjacentHTML('afterend', 
            `<span class=\'pattern\' style="` + this.style + `">` + this.patternError+  `</span>`);
            this.hideError('validation');
            this.enter = true;
        }
     }
   }

   hideError(className : string) {
    this.enter= false;
    const htmlel =  this.el.nativeElement as HTMLElement;
    const errors = htmlel.parentElement.getElementsByClassName(className);
    for(let i  = 0; i < errors.length; i++) {
      this.renderer.removeChild(htmlel.parentElement, errors[i]);
    
  }
   }
   ngOnDestroy() {
    if (this.statusChangesSubscription) {
      this.statusChangesSubscription.unsubscribe();
    }
}
ngOnChanges(param : SimpleChanges) {
  if(param.myError){
    this.validate();
  }
}

  processRestriction(): string {
    let text = this.translateService.instant('Field required', 'validation');
    if (this.datePickerComponent && this.datePickerComponent.restriction != null) {
      text = this.translateService.instant(DatePickerRestriction[this.datePickerComponent.restriction], 'validation');
    }

    return text;
  }
}
