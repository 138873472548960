import * as tslib_1 from "tslib";
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { OnInit } from '@angular/core';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';
var SsrBoxContentPriorityBagComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsrBoxContentPriorityBagComponent, _super);
    function SsrBoxContentPriorityBagComponent(ssrsService) {
        var _this = _super.call(this, ssrsService) || this;
        _this.ssrsService = ssrsService;
        _this.ssrType = SsrType.PriorityBag;
        return _this;
    }
    SsrBoxContentPriorityBagComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.flight && this.flight.passengers.length > 0) {
            this.allPassengersEnabled = this.allPassengersEnabledFunc();
            if (!this.allPassengersEnabled) {
                this.selectedPassenger = 0;
            }
        }
    };
    SsrBoxContentPriorityBagComponent.prototype.allPassengersEnabledFunc = function () {
        if (this.passengers.length < 2) {
            return false;
        }
        if (this.availableSsrs[0]) {
            for (var property in this.availableSsrs[0].limitPerPassenger) {
                if (this.availableSsrs[0].limitPerPassenger.hasOwnProperty(property)) {
                    if (this.availableSsrs[0].limitPerPassenger[property] === 0) {
                        return false;
                    }
                }
            }
        }
        return true;
    };
    return SsrBoxContentPriorityBagComponent;
}(SsrBoxContentBase));
export { SsrBoxContentPriorityBagComponent };
