<div class="form-content content-banner" [style.backgroundImage]="'url(' + imagesPath + '15.jpg)'">
  <blue-loading-spinner [activationRoute]="route" class="search-spinner"></blue-loading-spinner>
  <div class="wrapper" *ngIf="isLoaded">
    <div class="banner-inner text-holder">
      <div class="narrow-wrap">
        <h1 class="white-txt h2">{{'Check booking status'|translate}}</h1>
        <form role="form" method="POST" (keyup.enter)="getBooking()">
          <div class="form-row">
            <div class="one-field">
              <div class="switch-name" [ngClass]="{'hidden':!retrieveMethodByLastName}">
                <label for="last-name">{{'Last name'|translate}} *</label>
                <input type="text" id="last-name" placeholder="Last name" name="lastName" [(ngModel)]="lastName"
                  maxlength="64" (ngModelChange)="resetErrors()" />
              </div>
              <div class="switch-email " [ngClass]="{'hidden':retrieveMethodByLastName }">
                <label for="email-address">{{'Email address'|translate}} *</label>
                <input type="text" id="email-address" placeholder="Email address" name='emailAddress'
                  [(ngModel)]="emailAddress" maxlength="64" (ngModelChange)="resetErrors()" />
              </div>
              <div class="link-arrows js-switch-user-info" (click)="changeRetrieveMethod()"
                *ngIf="retrieveMethodByLastName">
                {{'Use email address'|translate}}
              </div>
              <div class="link-arrows js-switch-user-info" (click)="changeRetrieveMethod()"
                *ngIf="!retrieveMethodByLastName">
                {{'Use last name'|translate}}
              </div>
            </div>
            <div class="one-field">
              <div class="tooltip">
                <span class="overlay-bg"></span>
                <div class="tooltip-inner">
                  <a href="#" class="close"><span class="sr-only">{{'close help popup'|translate}}</span></a>
                  <p>{{'You can find your booking reference in the confirmation mail we sent you.'|translate}}</p>
                  <a href="#" class="btn btn-secondary blue">{{'Send it again?'|translate}}</a>
                </div>
              </div>
              <label for="booking-reference">{{'Booking reference'|translate}} *</label>
              <input type="text" id="booking-reference" placeholder="eg. XM10208" [(ngModel)]="recordLocator"
                name="recordLocator" maxlength="6" (ngModelChange)="resetErrors()" />
            </div>

          </div>

          <div class="form-row">
            <div class="error-summary">
              <span class="error-txt white-txt invalid-parameters" *ngIf="missingParams">
                {{"Fields marked with an asterisk (*) are mandatory" | translate}}
              </span>
              <span class="error-txt white-txt invalid-parameters-email" *ngIf="bookingNotFound">
                {{"The booking was not found. Please check if email address or last name and booking reference are
                correct" | translate}}
              </span>
              <span class="error-txt white-txt invalid-parameters-email" *ngIf="wrongMemberRetrieve">
                {{"The user trying to retrieve the booking doesn`t match with the one that created the it." | translate:
                'push-to-wallet'}}
              </span>
              <span class="error-txt white-txt" *ngIf="bookingStatus == 'Closed'">
                {{"The booking was already completed" | translate}}
              </span>
              <span *ngIf="memberNotLoggedIn" class="error-txt white-txt invalid-parameters-email"
                innerHtml="{{ 'The user is not logged in for booking {0}. Please go to the loggin section <a>here</a>' | translate:'': recordLocatorCopy }}">
              </span>
            </div>
          </div>
          <div class="form-row btn-center">
            <button type="submit" class="btn btn-primary btn-white wide" (click)="getBooking()">
              {{'Find my booking'|translate}}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>