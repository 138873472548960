import { Component, OnInit, AfterViewInit, NgZone, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, Output, EventEmitter, AfterContentInit, AfterViewChecked } from '@angular/core';
import * as jQuery from 'jquery';
import * as moment from 'moment';
import { CalendarSelection } from '../calendar-selection';
import { ConfigService } from '../../core/config.service';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'low-fare-calendar-view',
  templateUrl: './low-fare-calendar-view.component.html',
  styleUrls: ['./low-fare-calendar-view.component.scss']
})
export class LowFareCalendarViewComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('calendar', { static: true }) calendarElement: ElementRef;
  private $calendar: JQuery;

  private calendarData: any;

  currentMonthName: string;
  currentMonth: number;
  currentYear: number;

  @Input() initialDate: string;
  @Input() origin: string;
  @Input() destination: string;

  private _minDate: moment.Moment;
  @Input() minDate: string;

  private selection: CalendarSelection;
  @Output() dayChange: EventEmitter<CalendarSelection> = new EventEmitter<CalendarSelection>();

  @Input() isOutbound: boolean;

  constructor(private zone: NgZone, private http: HttpClient, private configService: ConfigService) {
    this.selection = new CalendarSelection();
    this.selection.format = 'YYYY/MM/DD';
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialDate) {
      this.updateSelection(changes.initialDate.currentValue);
      this.dayChange.next(this.selection);

      this.currentMonth = this.selection.dateMoment.month() + 1;
      this.currentMonthName = moment.months()[this.currentMonth - 1];
      this.currentYear = this.selection.dateMoment.year();

      this.goToMonth(this.selection.dateMoment.month() + 1, this.selection.dateMoment.year());
    }

    if (changes.minDate) {
      this._minDate = moment(changes.minDate.currentValue, this.selection.format);
      if (this._minDate.isSameOrAfter(moment([this.currentYear, this.currentMonth, 1]))) {
        this.goToMonth(this._minDate.month() + 1, this._minDate.year());
      } else {
        this.updateCalendarData();
      }
    }

    if (this.initialDate && this.origin && this.destination) {
      this.reloadCalendarData();
    }
  }

  initialize(): void {
    this.zone.runOutsideAngular(() => {
      this.$calendar = $(this.calendarElement.nativeElement);

      this.$calendar.on('shown.calendar.calendario', () => {
        const $cal = this.$calendar;
        // $('.fc-today', this.$calendar).removeClass('fc-today');

        $('div.fc-row > div.fc-content', $cal).each((index, item) => {
          const dateProp = $(item).data('bz.calendario.dateprop');
          const date = moment([dateProp.year, dateProp.month - 1, dateProp.day]);

          if (this._minDate && this._minDate.isValid() && date.isBefore(this._minDate)) {
            $(item).addClass('fc-disabled');
          } else if (date.isSame(this.selection.dateMoment)) {
            $(item).addClass('fc-selected-date');
          }
        });

        $('div.fc-row > div.fc-content:not(.fc-past,.fc-previous-month,.fc-next-month,.fc-disabled)', this.$calendar)
          .on('onDayClick.calendario', (e: any, dateprop: any) => {
            $('.fc-selected-date', $cal).removeClass('fc-selected-date');
            $(e.target).addClass('fc-selected-date');

            const newDate = moment([dateprop.year, dateprop.month - 1, dateprop.day]);
            this.zone.run(() => {
              this.updateSelection(null, newDate);
              this.dayChange.next(this.selection);
            });
          });

          this.autoScroll();
      });

      this.$calendar.calendario({
        months: moment.months(),
        weekabbrs: moment.weekdaysShort(),
        displayWeekAbbr: true,
        events: ['click', 'focus']
      });
      this.$calendar.calendario('setData', {});
      if (this.selection.dateMoment.isValid()) {
        this.$calendar.calendario('gotoMonth', this.selection.dateMoment.month() + 1, this.selection.dateMoment.year());
      }

      // this.updateCurrentInfo();
    });
  }

  changeMonth(action: string): void {
    switch (action) {
      case 'gotoPreviousMonth':
        if (moment([this.currentYear, this.currentMonth - 1, 1]).isBefore(moment().date(1))) {
          break;
        }
      /* falls through */
      case 'gotoNextMonth':
        this.$calendar.calendario(action, () => {
          this.zone.run(() => {
            this.updateCurrentInfo();
            this.reloadCalendarData();
          });
        });
        break;
      default:
        // don't know what to do :)
        break;
    }
  }

  goToPreviousMonth() {
    this.changeMonth('gotoPreviousMonth');
  }

  goToNextMonth() {
    this.changeMonth('gotoNextMonth');
  }

  goToMonth(month: number, year: number) {
    if (this.$calendar) {
      this.$calendar.calendario('gotoMonth', month, year,() => {
        this.zone.run(() => {
          this.updateCurrentInfo();
          this.reloadCalendarData();
        });
      });
    }
  }

  private autoScroll() {
    const selectedDate = $('.fc-selected-date', this.$calendar);
    let scrollElement: any;
    if (selectedDate.length) {
      scrollElement = selectedDate[0];
    } else {
      const firstAvailable = $(".fc-content:not(.fc-disabled):first", this.$calendar);
      if (firstAvailable.length) {
        scrollElement = firstAvailable[0];
      }
    }

    if (scrollElement) {
      scrollElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
    }
  }

  private updateCurrentInfo() {
    this.currentMonthName = this.$calendar.calendario('getMonthName');
    this.currentMonth = this.$calendar.calendario('getMonth');
    this.currentYear = this.$calendar.calendario('getYear');
  }

  private reloadCalendarData(): void {
    const params = new HttpParams()
      .set('month', this.currentMonth.toString())
      .set('year', this.currentYear.toString())
      .set('culture', 'en-GB');

    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');

    this.http
      .get(`${this.configService.LowFareUrl}/${this.origin}/${this.destination}`, { headers: headers, params: params })
      .subscribe(lowFareData => {
        this.calendarData = lowFareData;
        this.updateCalendarData();
      });
  }

  private updateCalendarData(): void {
    if (!this.calendarData) {
      return;
    }
    let filtered = {};

    if (this._minDate && this._minDate.isValid()) {
      for (const d in this.calendarData) {
        if (this.calendarData.hasOwnProperty(d) && this._minDate.isSameOrBefore(moment(d, 'MM-DD-YYYY'))) {
          filtered[d] = this.calendarData[d];
        }
      }
    } else {
      filtered = this.calendarData;
    }

    this.$calendar.calendario('setData', this.calendarData, true);
  }

  private updateSelection(date?: string, dateMoment?: moment.Moment) {
    if (date) {
      this.selection.date = date;
    }
    if (dateMoment) {
      this.selection.dateMoment = dateMoment;
    }

    this.selection.farePrice = 0;
    if (this.calendarData) {
      const calDataKey = this.selection.dateMoment.format('MM-DD-YYYY');
      if (this.calendarData.hasOwnProperty(calDataKey)) {
        this.selection.farePrice = this.calendarData[calDataKey];
      }
    }
  }
}
