<div class="section grey-light-bg">
  <div class="wrapper">
    <div class="settings-holder">
      <div class="row">
        <div class="left-col">
          <h2 class="desktop-only">{{ title }}</h2>
          <button class="link-plus blue" (click)="addItemBtnClick.next($event)" *ngIf="addItemBtnText">{{ addItemBtnText }}</button>
        </div>
        <div class="right-col">
          <ng-content select=".profile-layout-body"></ng-content>
        </div>
      </div>
      <ng-content select=".profile-layout-other-rows"></ng-content>
    </div>
  </div>
</div>