import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef, Renderer2, HostListener,
  AfterViewInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { Constants } from '../../constants';
import { BlueRenderer } from '../../common-modules/blue-air-common/services/blue-renderer.service';
import { DomHelper } from '../dom-helper';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'station-picker',
  templateUrl: './station-picker.component.html',
  styleUrls: ['./station-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: StationPickerComponent, multi: true },
    BlueRenderer
  ]
})
export class StationPickerComponent implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor {

  private _superStations: StationViewModel[];
  @Input() superStations: any[];
  /** The departure station */
  private departureStation: StationViewModel;
  @Input() departure: string;
  @Input() isDestination: boolean;
  @Input() stationPickerPair: StationPickerComponent;
  @Input() placeholder: string;
  @Input() ignoreConnectionErrors: boolean;
  @Output() select: EventEmitter<string> = new EventEmitter<string>();
  
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('searchInputMobile', { static: false }) searchInputMobile: ElementRef;

  /** Visible countries  of station picker*/
  countries: CountryViewModel[];

  /** Visible countries of station picker on mobile viewport */
  countriesMobile: CountryViewModel[];

  /** Visible  stations  of station picker*/
  stations: StationViewModel[];

  /** Visible stations by country */
  stationsByCountry: ICountryStations;

  /** Search field of station picker */
  searchField: FormControl = new FormControl();

  /** Search term of station picker */
  private searchTerm: string;

  /** The model - the selected station code (IATA) */
  model: string;

  /** The selected station */
  selectedStation: StationViewModel;

  /** The selected country */
  selectedCountry: CountryViewModel;

  /** Determines whether the station picker is opened */
  isOpened: boolean;

  /** Determines wheter the width matches the mobile width */
  mobileMatches: boolean = false;

  /** The current window width */
  private currentWindowWidth: number;

  private onTouchedCb: () => void;
  private onChangeCb: (_: any) => void;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWindowWith(event.target.innerWidth);
  }

  constructor(private renderer: Renderer2, private blueRenderer: BlueRenderer) {
    this.countries = [];
    this.countriesMobile = [];
    this.stations = [];
    this.stationsByCountry = {};
    this.isOpened = false;

    this.updateWindowWith(window.innerWidth);
  }

  ngOnInit() {
    this.searchField.valueChanges.subscribe(term => {
      this.searchTerm = term;
      this.updateStations();
    });
  }

  ngAfterViewInit() {
    this.updateWindowWith(window.innerWidth);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.superStations) {
      this.updateSuperStations(changes.superStations.currentValue || []);

      this.updateSelectedInfo();
    }

    if (changes.departure || changes.superStations) {
      this.updateDepartureStation();
      this.updateCountries();
      this.updateStations();
    }

    if (changes.departure) {
      this.updateDepartureStation();
    }
  }

  /**
   * Sets the selected country
   * @param {string} country
   */
  selectCountry(country: CountryViewModel, isSelected?: boolean): void {
    if (country) {
      if (this.selectedCountry === country) {
        country.isSelected = isSelected === undefined ? !country.isSelected : isSelected;
        return;
      } else {
        this.selectedCountry = country;
        this.countries.forEach(c => c.isSelected = false);
        country.isSelected = true;
      }
    } else {
      this.countries.forEach(c => c.isSelected = false);
      this.selectedCountry = null;
    }
    this.updateStations();
  }

  /**
   * Sets the selected station and open the station picker pair if any
   * @param {StationViewModel} newStation
   */
  selectStation(newStation: StationViewModel) {
    this.model = newStation ? newStation.code : null;
    this.selectedStation = newStation;
    this.onChangeCb(this.model);
    this.select.next(this.model);
    this.toggleOpened(false);
    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);

    this.selectCountry(newStation ? newStation.country : null, true);

    this.searchTerm = null;
    this.updateSearchField();

    if (!this.departureStation && this.stationPickerPair) {
      // using setTimeout because sometimes the other station picker doesn't close
      setTimeout(() => this.stationPickerPair.focusIn());
    }
  }

  toggleOpened(value: boolean = !this.isOpened): void {
    this.isOpened = value;
  }

  /**
   * Opens this station picker and close the station picker pair if any
   */
  focusIn(): void {
    if (this.stationPickerPair) {
      // using setTimeout because sometimes the other station picker doesn't close
      setTimeout(() => this.stationPickerPair.toggleOpened(false));
    }

    this.toggleOpened(true);
    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
    if (this.mobileMatches) {
      if (this.searchInputMobile) {
        setTimeout(() => {
          this.searchInputMobile.nativeElement.focus();
        });
      }
    } else {
      if (this.searchInput) {
        this.searchInput.nativeElement.focus();
      }
    }

    const header = document.getElementsByClassName('header');
    if (header.length) {
      this.renderer.addClass(header[0], 'lower');
    }

    DomHelper.IncreaseAppHeight(this.renderer);
  }

  /**
   * Closes this station picker
   * @param {boolean} [selectFirstAvailableStation=false] If [true] also select the first available station. Defaults to [false]
   */
  focusOut(selectFirstAvailableStation: boolean = false): void {
    if (selectFirstAvailableStation) {
      this.selectStation(this.stations.length ? this.stations[0] : null);
    }
    this.toggleOpened(false);
    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);

    const header = document.getElementsByClassName('header');
    if (header.length) {
      this.renderer.removeClass(header[0], 'lower');
    }
    DomHelper.ResetAppHeight(this.renderer);
  }

  /**
   * Halfs an integer
   * @param n - The integer
   * @returns Math.ceil(n / 2)
   */
  halfInt(n: number): number {
    return Math.ceil(n / 2);
  }

  /**
   * Updates window with
   * @param {number} [newWidth] - The new width of the window
   */
  private updateWindowWith(newWidth: number) {
    if (this.currentWindowWidth === newWidth) {
      return;
    }
    this.currentWindowWidth = newWidth;

    this.mobileMatches = newWidth < Constants.mobileScreenWith;
    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
  }

   /**
   * Updates super stations internal array base on super stations input
   * @param superStationsInput 
   */
  updateSuperStations(superStationsInput: any[]) {
    this.countries = [];
    this._superStations = superStationsInput.reduce((acc, item) => {
      const c = new CountryViewModel(item.code, item.value);
      this.countries.push(c);

      const stations = item.stations.map(s => new StationViewModel(s.code, s.value, c));
      acc.push(...stations);
      return acc;
    }, []);

    superStationsInput.forEach(ss => {
      ss.stations.forEach(s => {
        const station = this._superStations.find(thisS => thisS.code === s.code);
        station.connections = this._superStations.filter(thisS => s.markets.indexOf(thisS.code) !== -1);
      });
    });
  }

  /**
   * Updates departure station based on the input [departure]
   */
  private updateDepartureStation(): void {
    this.departureStation = this.getSuperStation(this.departure);

    if (!this.ignoreConnectionErrors && this.selectedStation && this.departureStation &&
       this.departureStation.connections.indexOf(this.selectedStation) === -1 ) {
      this.selectStation(null);
    }

    if (!this.ignoreConnectionErrors && this.selectedCountry && this.departureStation &&
      this.departureStation.connections.findIndex(conn => conn.country === this.selectedCountry) === -1) {
      this.selectCountry(null);
    }
  }

  /**
   * Updates visible countries. Enables countries that can be selected.
   */
  private updateCountries(): void {
    this.countries.forEach(c => {
      c.isActive = !this.departureStation || this.departureStation.connections.some(conn => conn.country === c);
    });
  }

  /**
   * Updates visible stations
   * @param filter - The filter to apply on station names and codes. If missing stations are filtered by country
   */
  private updateStations(): void {
    const filter = this.searchTerm;
    let ssArray: StationViewModel[] = this._superStations.slice();

    // the user should first type something or select a country or have the departure already selected
    if (!filter && !this.selectedCountry && !this.departureStation) {
      this.stations.length = 0;
      this.updateStationsByCountry();
      return ;
    }

    if (!filter && this.selectedCountry) {
      ssArray = ssArray.filter(ss => ss.country === this.selectedCountry);
    }

    if (this.departureStation) {
      ssArray = ssArray.filter(ss => this.departureStation.connections.indexOf(ss) !== -1);
    }

    if (filter) {
      const filterRegx = new RegExp(filter, 'ig');
      ssArray = ssArray.filter(sfc => `${sfc.name} (${sfc.code})`.search(filterRegx) >= 0);

      this.countriesMobile = ssArray.map(ss => ss.country);
    }

    this.stations = ssArray;
    this.updateStationsByCountry(filter);
  }

  /**
   * Updates stations by country list
   */
  private updateStationsByCountry(filter?: string): void {
    let ssArray = this._superStations.slice();

    if (this.departureStation) {
      ssArray = ssArray.filter(ss => this.departureStation.connections.indexOf(ss) !== -1);
    }

    if (filter) {
      // if country matches the filter => show all stations for that country
      // else show only stations that match the filter
      const filterRegx = new RegExp(filter, 'ig');
      this.stationsByCountry = ssArray.reduce((group: ICountryStations, station) => {
        if (station.country.name.search(filterRegx) >= 0 || `${station.name} (${station.code})`.search(filterRegx) >= 0) {
          (group[station.country.code] = group[station.country.code] || []).push(station);
        }
        return group;
      }, {});
    } else {
      // show all countries
      this.stationsByCountry = ssArray.reduce((group: ICountryStations, station) => {
        (group[station.country.code] = group[station.country.code] || []).push(station);
        return group;
      }, {});
    }

    this.countriesMobile = [];
    for (const countryCode in this.stationsByCountry) {
      if (this.stationsByCountry.hasOwnProperty(countryCode)) {
        const country = this.countries.find(c => c.code === countryCode);
        this.countriesMobile.push(country);
      }
    }

    if (this.countriesMobile.length === 1) {
      this.countries.forEach(c => c.isSelected = false);
      this.countriesMobile[0].isSelected = true;
    }
  }

  /**
   * Updates selected info base on the model
   */
  private updateSelectedInfo(): void {
    this.selectedStation = this.getSuperStation(this.model);

    this.selectCountry(this.selectedStation ? this.selectedStation.country : null);
    this.updateSearchField();
  }

  /**
   * Updates search field value based on the station selection
   */
  private updateSearchField(): void {
    this.searchField.setValue(this.selectedStation ? this.selectedStation.name : '', { emitEvent: false });
  }

  /**
   * Gets super station by station code (IATA)
   * @param {string} [stationCode] - The station code (IATA) to search for
   * @returns {StationViewModel}
   */
  private getSuperStation(stationCode: string): StationViewModel {
    return this._superStations.find(ss => ss.code === stationCode);
  }

  writeValue(obj: string): void {
    this.model = obj;
    this.updateSelectedInfo();
  }
  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // do nothing;
  }
}

class StationViewModel {
  country: CountryViewModel;
  code: string;
  name: string;
  connections: StationViewModel[];

  constructor(code: string, name: string, country: CountryViewModel) {
    this.code = code;
    this.name = name;
    this.country = country;
    this.connections = [];
  }
}

class CountryViewModel {
  code: string;
  name: string;
  isActive: boolean;
  isSelected: boolean;

  constructor(code: string, name: string, isActive: boolean = true, isSelected: boolean = false) {
    this.code = code;
    this.name = name;
    this.isActive = isActive;
    this.isSelected = isSelected;
  }
}

interface ICountryStations {
  [countryCode: string]: StationViewModel[];
}
