import { ShoppingCartFlightModel } from './shopping-cart-flight-model';
import { ShoppingCartExtrasModel, ShoppingCartExtrasPassengerModel } from './shopping-cart-extras-model';
import { SsrType } from '../../../extras/ssr-type.enum';


export class ShoppingCartBreakdownItem {
    flights: ShoppingCartFlightModel[];
    extras: ShoppingCartExtrasModel[];

    isBalanceDueOverridden: boolean;
    private initialBalanceDue: number;
    private _balanceDue: number;
    get balanceDue() { return this._balanceDue; }
    set balanceDue(value: number) {
        this.initialBalanceDue =  Math.max(value, 0);
        if (!this.isBalanceDueOverridden) {
            this._balanceDue = this.initialBalanceDue;
        }
    }

    constructor() {
        this.flights = [];
        this.extras = [];
        this.balanceDue = 0;
    }

  private _isBundleUpgrade(extra): boolean {
    return (extra.ssrTypeCode === 'BundleUpgrade');
  }

    init(journeys: any[], extras: any, balanceDue: number): ShoppingCartBreakdownItem {
        this.flights = journeys.map(j => new ShoppingCartFlightModel().init(j));
        this.extras = extras.filter(e => !this._isBundleUpgrade(e)).map(e => new ShoppingCartExtrasModel().init(e));
        this.balanceDue = balanceDue;
        this._applyBundleUpgrades(this.flights, extras);
        return this;
    }

    private _groupPassengersUpgradeBundlesBySegment(upgradeBundle): Record<string, any[]> {
      const result: Record<string, any[]> = {};
      upgradeBundle.journeys.forEach(journey => {
        journey.segments.forEach(segment => {
          const segmentKey = segment.departureStation + segment.arrivalStation;
          result[segmentKey] = segment.passengers;
        });
      });
      return result;
    }

    private _applyBundleUpgrades(journeys, extras) {
      const upgradeBundle = extras.filter(e => this._isBundleUpgrade(e))[0];
      if (!upgradeBundle) {
        return;
      }

      const bundleUpgradesBySegment = this._groupPassengersUpgradeBundlesBySegment(upgradeBundle);

      journeys.forEach(journey => {
        journey.segments.forEach(segment => {
          const segmentKey = segment.departureStation + segment.arrivalStation;
          const segmentBundleUpgrade = bundleUpgradesBySegment[segmentKey];
          segment.passengers.forEach(passengerType => {
            for (let i = 1; i <= passengerType.count; i++) {
              const bundleUpgrade = segmentBundleUpgrade.shift();
              if (bundleUpgrade) {
                passengerType.amount += bundleUpgrade.totalAmount;
                segment.amount += bundleUpgrade.totalAmount;
                journey.amount += bundleUpgrade.totalAmount;
              }
            }
          });
        });
      });
    }

    overrideBalanceDue(newBalanceDue: number): void {
        if (newBalanceDue == null) {
            this._balanceDue = this.initialBalanceDue;
            this.isBalanceDueOverridden = false;
        } else {
            this._balanceDue = newBalanceDue;
            this.isBalanceDueOverridden = true;
        }
    }

    checkExtrasForSsrOrFeeCode(ssrType: SsrType, ssrCode: string): boolean {
        const val = this.extras
            .some(e => e.ssrType === ssrType && e.flightsWithItems
                .some(f => f.segments
                    .some(s => s.passengers
                        .some(p => p.items
                            .some(i => i.ssrCode === ssrCode)
                        )
                    )
                )
            );
            // another way to do it
            // this.extras
            // .filter(e => e.ssrType === ssrType)
            // .reduce((flights, e) => flights.concat(e.flightsWithItems), [])
            // .reduce((segments, f) => segments.concat(f.segments), [])
            // .reduce((passengers, s) => passengers.concat(s.passengers), [])
            // .some((p: ShoppingCartExtrasPassengerModel) => p.items.some(i => i.ssrCode === ssrCode));

            return val;
    }

}
