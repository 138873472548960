/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./loading-spinner.component";
import * as i4 from "../loading-spinner.service";
var styles_LoadingSpinnerComponent = [i0.styles];
var RenderType_LoadingSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingSpinnerComponent, data: {} });
export { RenderType_LoadingSpinnerComponent as RenderType_LoadingSpinnerComponent };
function View_LoadingSpinnerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "preloader_container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "preloader-active": 0, "full-page": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "content-preloader content-preloader-primary"], ["style", "height: 48px;width: 48px; margin-left:-24px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "md-preloader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, ":svg:svg", [["class", "spinner"], ["viewBox", "0 0 50 50"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, ":svg:circle", [["class", "path"], ["cx", "25"], ["cy", "25"], ["fill", "none"], ["r", "20"], ["stroke-width", "5"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.class, ""); var currVal_1 = _ck(_v, 3, 0, _co.spinnerActive, _co.fullPage); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LoadingSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoadingSpinnerComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.spinnerVisible; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "blue-loading-spinner", [], null, null, null, View_LoadingSpinnerComponent_0, RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 4964352, null, 0, i3.LoadingSpinnerComponent, [i4.LoadingSpinnerService, i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingSpinnerComponentNgFactory = i1.ɵccf("blue-loading-spinner", i3.LoadingSpinnerComponent, View_LoadingSpinnerComponent_Host_0, { id: "id", activationRoute: "activationRoute", fullPage: "fullPage", showSpinner: "showSpinner", class: "class" }, {}, []);
export { LoadingSpinnerComponentNgFactory as LoadingSpinnerComponentNgFactory };
