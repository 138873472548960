<div *ngIf="!isPassengerEligibleToSubmit && !showErrorMessage" >
  <div *ngIf="memberIsLoggedIn">
    <div class="consent-main-header flex-wrapper">
      <h1>
        {{ 'Booking reference' | translate: 'find-my-bookings' }}
      </h1>
      <h1 class="h1-light-color">
        {{ recordLocator | translate: 'find-my-bookings' }}
      </h1>
    </div>
    <div class="show-consent-options text-align-center">
      <p>
        {{ 'It seems that you already requested assistance for this booking.' | translate: 'find-my-bookings' }}
      </p>

      <p>
        {{ 'If this is not the case, please contact' | translate: 'find-my-bookings' }}
      </p>

      <p>
        {{ 'our Customer Serverice Department for support.' | translate: 'find-my-bookings' }}
      </p>

      <p class="show-consent-options-url">
        <a href={{dynamicUrl}} type="button" class="underline-url" (click)="goToCustomerSupportCenter()">
          {{ 'Support Center (flyblueair.com)' | translate: 'find-my-bookings' }}
        </a>
      </p>
    </div>
    <div class="banner-wrapper">
      <img src="https://corporate-admin.blueair.aero/media/default/content/fleet/boeing-max-1.png" alt="Blue Air aircraft livery">
    </div>
  </div>

</div>

<div *ngIf="!memberIsLoggedIn && !showErrorMessage" class="banner-wrapper">
  <img src="https://corporate-admin.blueair.aero/media/default/content/fleet/boeing-max-1.png"
    alt="Blue Air aircraft livery">
</div>

<div *ngIf="isPassengerEligibleToSubmit && !showErrorMessage && memberIsLoggedIn" class="flex-wrapper">
  <div>
    <div class="consent-main-header">
      <h1>
          {{ 'Booking reference' | translate: 'find-my-bookings' }}
      </h1>
      <h1 class="h1-light-color">
          {{ recordLocator | translate: 'find-my-bookings' }}
      </h1>
    </div>

    <!-- <div *ngIf="!isConsentYes && !isRefundToCash" class="toggle-buttons">
      <a type="button" class="btn btn-primary gray" (click)="enableConsentOptions()" [ngClass]="{ 'blue': !isButtonSelected, 'gray': isButtonSelected }">
        {{ 'Change your flight' | translate: 'find-my-bookings' }}
      </a>

      <a type="button" class="btn btn-primary gray" (click)="checkConsent(false);" [ngClass]="{ 'blue': isButtonSelected, 'gray': !isButtonSelected }">
        {{ 'Cancel your trip' | translate: 'find-my-bookings' }}
      </a>
    </div> -->
    <div *ngIf="!isConsentYes && !isRefundToCash" class="tabs-container">
      <ul class="tabs-list" role="tablist">
        <li class="tab-item " data-tab="tab1" role="tab" aria-controls="tab1" aria-selected="true" (click)="enableConsentOptions()" [ngClass]="{ 'selected': !isButtonSelected, '': isButtonSelected }">
          <span><strong>{{ 'Change your flight' | translate: 'find-my-bookings' }} </strong></span>
        </li>
        <li class="tab-item" data-tab="tab2" role="tab" aria-controls="tab2" (click)="checkConsent(false);" [ngClass]="{ 'selected': isButtonSelected, '': !isButtonSelected }">
          <span><strong>{{ 'Cancel your trip' | translate: 'find-my-bookings' }}</strong></span>
        </li>
      </ul>
    </div>

    <div class="show-consent-options" *ngIf="showConsentOptions">
      <p>
        {{ 'You can change your flight for another one that meets your needs, keeping all the services you had for your original booking,
            at no extra cost.' | translate: 'find-my-bookings' }}
      <p>

      <p>
        {{ 'Here is our proposal:' | translate: 'find-my-bookings' }}
      </p>
    </div>

    <div *ngIf="isConsentYes" class="tabs-container one-tab-only">
      <ul class="tabs-list " role="tablist">
        <li class="tab-item selected" data-tab="tab1" role="tab" aria-controls="tab1" aria-selected="true">
          <span><strong>{{ 'Flight changed' | translate: 'find-my-bookings' }}</strong></span>
        </li>
      </ul>
    </div>

    <div *ngIf="isRefundToCash" class="tabs-container one-tab-only">
      <ul class="tabs-list " role="tablist">
        <li class="tab-item selected" data-tab="tab1" role="tab" aria-controls="tab1" aria-selected="true">
          <span><strong>{{ 'Refund to cash confirmed' | translate: 'find-my-bookings' }}</strong></span>
        </li>
      </ul>
    </div>
  </div>

  <div>
    <div>
      <div class="proposed-flight-wrapper" *ngIf="!isConsentNo">
        <div class="is-consent-or-refund-yes" *ngIf="isConsentYes">
          <div class="checkmark-image">
            <svg id="success-green-check-mark" xmlns="http://www.w3.org/2000/svg" width="89.037" height="89.03" viewBox="0 0 89.037 89.03">
              <path id="Path_1968" data-name="Path 1968" d="M44.515,0A44.373,44.373,0,0,1,63.792,4.379c-2.39,1.891-4.606,3.706-6.67,5.453a36.854,36.854,0,1,0,23.611,27.6q2.87-3.744,5.876-7.449A44.532,44.532,0,1,1,44.515,0ZM23.778,37.2l10.86-.144.809.212a41.893,41.893,0,0,1,6.186,4.341,42.491,42.491,0,0,1,3.986,3.857A156.6,156.6,0,0,1,58.052,28.149,148.353,148.353,0,0,1,73.306,12.282l1.059-.408H86.216l-2.39,2.655A253.263,253.263,0,0,0,63.8,39.818,246.523,246.523,0,0,0,47.608,66.757l-1.49,2.874L44.749,66.7a71.055,71.055,0,0,0-9.159-14.846,59.971,59.971,0,0,0-12.7-11.753l.885-2.9Z" transform="translate(0 0)" fill="#01a601"/>
            </svg>
          </div>
          <h3>
            {{ 'Thank you for the confirmation!' | translate: 'find-my-bookings' }}
          </h3>
          <h3>
            {{ 'We wish you a pleasant flight!' | translate: 'find-my-bookings' }}
          </h3>
        </div>

        <div *ngIf="isDirectFlight">
          <!-- DIRECT FLIGHT -->
          <div class="journeys-wrapper" *ngFor="let journey of journeys; let i = index">
            <div *ngIf="journey.segments[0].paxSegments[0].timeChanged === true || journey.segments[0].legs[0].legInfo.status === 'Canceled'">
              <div class="journey-segment" *ngFor="let segment of journey.segments; let j = index">
                <h3 class="journey-segment-title" *ngIf="!journey.hasCancelLeg; else canceled">
                  {{ 'NEW FLIGHT DETAILS' | translate: 'find-my-bookings' }}
                </h3>
                <ng-template #canceled>
                  <h3 class="journey-segment-title">
                    {{ 'CANCELED FLIGHT' | translate: 'find-my-bookings' }}
                  </h3>
                </ng-template>
                <div class="journey-segment-wrapper">
                  <h3 class="journey-segment-date">
                    {{ formatDate(journey.segments[0].sTD, 'dddd, DD MMM YYYY') }}
                  </h3>
                  <div class="journey-segment-flight-details">
                    <div class="journey-segment-location">
                      <div class="journey-segment-left-details" >
                        <div class="location">{{ getStation(0, journey) | translate: 'station.name' }}</div>
                        <div class="location">{{ segment.departureStation }}</div>
                      </div>
                      <div class="journey-segment-middle-details">
                        <span class="font-ico-plane-runway">
                        </span>
                      </div>
                      <div class="journey-segment-right-details">
                        <div class="location">{{ getStation(1, journey) | translate: 'station.name' }}</div>
                        <div class="location">{{ segment.arrivalStation }}</div>
                      </div>
                    </div>
                    <div class="connection-detail">
                      {{ 'Direct flight' | translate: 'find-my-bookings' }}
                    </div>
                    <div class="journey-segment-time-and-duration">
                      <div class="journey-segment-left-details" >
                        <time class="time-big">{{ formatDate(segment.sTD, 'HH:mm') }}</time>
                      </div>
                      <div class="journey-segment-middle-details">
                        <div class="journey-segment-time-and-duration-plane-icon">
                          <span class="font-ico-plane-colored"></span>
                        </div>

                      </div>
                      <div class="journey-segment-right-details">
                          <time class="time-big">{{ formatDate(segment.sTA,"HH:mm") }}</time>
                      </div>
                    </div>
                    <div class="flight-duration">
                      {{ subtractTimezoneOffset(segment.sTD, segment.legs[0].legInfo.deptLTV) | duration: subtractTimezoneOffset(segment.sTA, segment.legs[segment.legs.length - 1].legInfo.arrvLTV) : false }}
                    </div>
                    <div *ngIf="isNextDay(journey.segments[0].sTD, journey.segments[journey.segments.length - 1].sTA)" class="operating-carier-details">
                      {{ 'Arrives next day!' | translate: 'find-my-bookings' }}
                    </div>
                  </div>
                  <div class="journey-segment-flight-number">
                    <div class="journey-segment-flight-number-plane-icon">
                      <span class="font-ico-plane-colored">
                      </span>
                    </div>
                    <div class="flight-number">
                      {{ 'Flight number:' | translate: 'find-my-bookings' }}
                    </div>

                    <div class="carrier-code">
                      {{ segment.flightDesignator.carrierCode }} {{ segment.flightDesignator.flightNumber }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isIndirectFlight">
          <!-- INDIRECT FLIGHT -->
          <h3 class="journey-segment-title">
            {{ 'NEW FLIGHT DETAILS' | translate: 'find-my-bookings' }}
          </h3>
          <div class="journeys-wrapper indirect-flight" *ngFor="let journey of journeys; let i = index">
            <div class="journey-segment-wrapper"
              *ngIf="journey.segments[0].paxSegments[0].timeChanged === true || journey.segments[1].paxSegments[0].timeChanged === true ||
                     journey.segments[0].legs[0].legInfo.status === 'Canceled' || journey.segments[1].legs[0].legInfo.status === 'Canceled'">
              <div class="journey-segment" *ngFor="let segment of journey.segments; let j = index">
                <div >
                  <div *ngIf="journey.segments.length - j >= 2" class="journeys-stations">
                    <span class="location">{{ getStation(0, journey) | translate: 'station.name' }}</span>
                    -
                    <span class="location">{{ getStation(1, journey) | translate: 'station.name' }}</span>
                  </div>
                  <h3 *ngIf="j == 0" class="journey-segment-date">
                    {{ formatDate(journey.segments[0].sTD, 'dddd, DD MMM YYYY') }}
                  </h3>
                  <div *ngIf="journey.segments.length - j >= 2" class="connection-detail">
                    {{ '1 Stop' | translate: 'find-my-bookings' }}
                  </div>
                  <div class="journey-segment-flight-details">
                    <div class="journey-segment-location">
                      <div class="journey-segment-left-details" >
                        <div class="location">{{ getStation(0, journey) | translate: 'station.name' }}</div>
                        <div class="location">{{ segment.departureStation }}</div>
                      </div>
                      <div class="journey-segment-middle-details">
                        <span class="font-ico-plane-runway">
                        </span>
                      </div>
                      <div class="journey-segment-right-details">
                        <div class="location">{{ getStation(1, journey) | translate: 'station.name' }}</div>
                        <div class="location">{{ segment.arrivalStation }}</div>
                      </div>
                    </div>
                    <div class="journey-segment-time-and-duration">
                      <div class="journey-segment-left-details" >
                        <time class="time-big">{{ formatDate(segment.sTD, 'HH:mm') }}</time>
                      </div>
                      <div class="journey-segment-middle-details">
                        <div class="journey-segment-time-and-duration-plane-icon">
                          <span class="font-ico-plane-colored"></span>
                        </div>

                      </div>
                      <div class="journey-segment-right-details">
                          <time class="time-big">{{ formatDate(segment.sTA,"HH:mm") }}</time>
                      </div>
                    </div>
                    <div class="flight-duration">
                      {{ subtractTimezoneOffset(segment.sTD, segment.legs[0].legInfo.deptLTV) | duration: subtractTimezoneOffset(segment.sTA, segment.legs[segment.legs.length - 1].legInfo.arrvLTV) : false }}
                    </div>
                    <div *ngIf="j != 0 && isNextDay(journey.segments[0].sTD, journey.segments[journey.segments.length - 1].sTA)" class="operating-carier-details">
                      {{ 'Arrives next day!' | translate: 'find-my-bookings' }}
                    </div>
                    <div class="journey-segment-flight-number">
                      <div class="journey-segment-flight-number-plane-icon">
                        <span class="font-ico-plane-colored">
                        </span>
                      </div>
                      <div class="flight-number">
                        {{ 'Flight number:' | translate: 'find-my-bookings' }}
                      </div>

                      <div class="carrier-code">
                        {{ segment.flightDesignator.carrierCode }} {{ segment.flightDesignator.flightNumber }}
                      </div>
                    </div>

                    <div *ngIf="journey.segments.length - j >= 2" class="connection-detail-label">
                      <span>
                        {{ calculateWaitingTimeBetweenFLights(journey.segments[j].sTA, journey.segments[j+1].sTD) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="isCommercialFlight">
          <!-- COMMERCIAL FLIGHT -->
          <h3 class="journey-segment-title">
            {{ 'NEW FLIGHT DETAILS' | translate: 'find-my-bookings' }}
          </h3>
          <div class="journeys-wrapper indirect-flight" *ngFor="let journey of journeys; let i = index">
            <div class="journey-segment-wrapper" >
              <div class="journey-segment" *ngFor="let segment of journey.segments; let j = index">
                <div *ngFor="let leg of segment.legs; let k = index">
                  <div *ngIf="segment.legs.length - k >= 2" class="journeys-stations">
                    <span class="location">{{ getStation(0, journey) | translate: 'station.name' }}</span>
                    -
                    <span class="location">{{ getStation(1, journey) | translate: 'station.name' }}</span>
                  </div>
                  <h3 *ngIf="k == 0" class="journey-segment-date">
                    {{ formatDate(segment.legs[0].sTD, 'dddd, DD MMM YYYY') }}
                  </h3>
                  <div *ngIf="segment.legs.length - k >= 2" class="connection-detail">
                    {{ '1 Stop' | translate: 'find-my-bookings' }}
                  </div>
                  <div class="journey-segment-flight-details">
                    <div class="journey-segment-location">
                      <div class="journey-segment-left-details" >
                        <div class="location">{{ getStation(0, journey) | translate: 'station.name' }}</div>
                        <div class="location">{{ leg.departureStation }}</div>
                      </div>
                      <div class="journey-segment-middle-details">
                        <span class="font-ico-plane-runway">
                        </span>
                      </div>
                      <div class="journey-segment-right-details">
                        <div class="location">{{ getStation(1, journey) | translate: 'station.name' }}</div>
                        <div class="location">{{ leg.arrivalStation }}</div>
                      </div>
                    </div>
                    <div class="journey-segment-time-and-duration">
                      <div class="journey-segment-left-details" >
                        <time class="time-big">{{ formatDate(leg.sTD, 'HH:mm') }}</time>
                      </div>
                      <div class="journey-segment-middle-details">
                        <div class="journey-segment-time-and-duration-plane-icon">
                          <span class="font-ico-plane-colored"></span>
                        </div>

                      </div>
                      <div class="journey-segment-right-details">
                          <time class="time-big">{{ formatDate(leg.sTA,"HH:mm") }}</time>
                      </div>
                    </div>
                    <div class="flight-duration">
                      {{ subtractTimezoneOffset(segment.sTD, segment.legs[0].legInfo.deptLTV) | duration: subtractTimezoneOffset(segment.sTA, segment.legs[segment.legs.length - 1].legInfo.arrvLTV) : false }}
                    </div>
                    <div *ngIf="k != 0 && isNextDay(segment.legs[0].sTD, segment.legs[segment.legs.length - 1].sTA)" class="operating-carier-details">
                      {{ 'Arrives next day!' | translate: 'find-my-bookings' }}
                    </div>
                    <div class="journey-segment-flight-number">
                      <div class="journey-segment-flight-number-plane-icon">
                        <span class="font-ico-plane-colored">
                        </span>
                      </div>
                      <div class="flight-number">
                        {{ 'Flight number:' | translate: 'find-my-bookings' }}
                      </div>

                      <div class="carrier-code">
                        {{ segment.flightDesignator.carrierCode }} {{ segment.flightDesignator.flightNumber }}
                      </div>
                    </div>

                    <div *ngIf="segment.legs.length - k >= 2" class="connection-detail-label">
                      <span>
                        {{ calculateWaitingTimeBetweenFLights(segment.legs[k].sTA, segment.legs[k+1].sTD) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="showConsentOptions">
          <div class="consent-options-buttons">
            <h3 *ngIf="!hasCanceledFlights">
              {{ 'Do you accept the flight change we offer?' | translate: 'find-my-bookings' }}
            </h3>

            <a type="button" class="btn btn-primary" (click)="checkConsent(true);" *ngIf="!hasCanceledFlights">
              {{ 'Accept proposed flight' | translate: 'find-my-bookings' }}
            </a>

            <a type="button" class="btn btn-primary" (click)="manageMyBooking();" [ngClass]="{'disable-refund': disableRefund}" >
              {{ 'Choose another flight' | translate: 'find-my-bookings' }}
            </a>
          </div>
          <div class="consent-notes">
              <span class="error-txt">
                {{ 'Please note that by accepting the new flight schedule, you will no longer be eligible for a refund of a free rebooking.' | translate: 'find-my-bookings' }}
              </span>

              <span class="error-txt">
                {{ 'Please be advised that you will have to check in online for the new flights.
                    Check-in online available in accordance with your ticket conditions.' | translate: 'find-my-bookings' }}
              </span>

              <span class="error-txt">
                <span>
                  {{ 'If you already checked-in online please contact our' | translate: 'find-my-bookings' }}
                  <a href={{dynamicUrl}} type="button" class="underline-url" (click)="goToCustomerSupportCenter()">
                    {{ 'Customer Support' | translate: 'find-my-bookings' }}
                  </a>
                  {{ 'team before proceeding with your selection.' | translate: 'find-my-bookings' }}
                </span>
              </span>

              <span class="error-txt">
                {{ 'ChangeTimeMinHours' | translate: 'find-my-bookings' }}
              </span>

              <span class="error-txt">
                {{ 'ChangeTimeDateInterval' | translate: 'find-my-bookings' }}
              </span>
          </div>
        </div>

        <span class="error-txt">
          <div *ngIf="hasInsuranceFee || hasInsuranceQueueCode" class="text-paragraph">
            <span>
              {{ 'Your booking contains Travel Protection with COVID19 Cover for the travel period originally booked. If the dates of your trip have changed, please contact the insurance provider' | translate: 'find-my-bookings' }}
            </span>

            <a href="https://www.xcover.com/en/login" class="underline-url">
              {{ 'here' | translate: 'find-my-bookings' }}
            </a>

            <span>
              {{ 'to update your policy. If the new schedule accepted doesn`t affect the travel dates (departure and arrival are still on the same date), you don`t need to update the policy.' | translate: 'find-my-bookings' }}
            </span>
          </div>
        </span>
      </div>
    </div>

    <div class="cancel-your-trip" *ngIf="isConsentNo && !isRefundToCash">
      <div class="paragraph">
        {{ 'You can cancel your booking free of charge and request a full refund in the form of an electronic Wallet,
            which is the fastest and easiest payment method for future bookings.' | translate: 'find-my-bookings' }}
      </div>

      <div class="paragraph">
        {{ 'These are the benefits of your Wallet:' | translate: 'find-my-bookings' }}
      </div>

      <div class="paragraph checkbox-row">
        <span class="font-ico-checked-sign">
        </span>

        <span>
          {{ 'Instant - you will receive it on the spot, ready to use' | translate: 'find-my-bookings' }}
        </span>
      </div>

      <div class="paragraph checkbox-row">
        <span class="font-ico-checked-sign">
        </span>

        <span>
          {{ 'Easy to use - available when you enter FlyBlueAir.com or our mobile app' | translate: 'find-my-bookings' }}
        </span>
      </div>

      <div class="paragraph checkbox-row">
        <span class="font-ico-checked-sign">
        </span>

        <span>
          {{ 'Multiple uses - you can use your wallet on as many Blue Air bookings as you want, until you have spent the entire balance' | translate: 'find-my-bookings' }}
        </span>
      </div>

      <div class="paragraph checkbox-row">
        <span class="font-ico-checked-sign">
        </span>

        <span>
          {{ 'Complete flexibility - you can also use your wallet for bookings in another persons name' | translate: 'find-my-bookings' }}
        </span>
      </div>

      <div class="paragraph button">
        <a type="button" class="btn btn-primary" (click)="refundToWallet()">
          {{ 'Refund to wallet' | translate: 'find-my-bookings' }}
        </a>
      </div>

      <div class="paragraph">
        {{ 'Wallet acceptance is full and final refund, so it cannot be exchanged for cash.' | translate: 'find-my-bookings' }}
      </div>

      <div class="paragraph">
        {{ 'We are also offering you the option of requesting a cash refund to original payment method. To request this, please' | translate: 'find-my-bookings' }}

        <a class="underline-url" (click)="whereToRefund()">
          {{ 'clickk here' | translate: 'find-my-bookings' }}
        </a>
      </div>

      <div class="paragraph">
        {{ 'Please note that, in view of the current exceptional situation, this process may take longer than usual.' | translate: 'find-my-bookings' }}
      </div>

      <div class="paragraph">
        {{ 'If your booking or part of it was paid with funds from an electronic Wallet, these will be refunded to the account
            so you can use them again for future bookings.' | translate: 'find-my-bookings' }}
      </div>
    </div>

    <div *ngIf="isRefundToCash" class="is-consent-or-refund-yes">
      <div class="checkmark-image">
        <svg id="success-green-check-mark" xmlns="http://www.w3.org/2000/svg" width="89.037" height="89.03" viewBox="0 0 89.037 89.03">
          <path id="Path_1968" data-name="Path 1968" d="M44.515,0A44.373,44.373,0,0,1,63.792,4.379c-2.39,1.891-4.606,3.706-6.67,5.453a36.854,36.854,0,1,0,23.611,27.6q2.87-3.744,5.876-7.449A44.532,44.532,0,1,1,44.515,0ZM23.778,37.2l10.86-.144.809.212a41.893,41.893,0,0,1,6.186,4.341,42.491,42.491,0,0,1,3.986,3.857A156.6,156.6,0,0,1,58.052,28.149,148.353,148.353,0,0,1,73.306,12.282l1.059-.408H86.216l-2.39,2.655A253.263,253.263,0,0,0,63.8,39.818,246.523,246.523,0,0,0,47.608,66.757l-1.49,2.874L44.749,66.7a71.055,71.055,0,0,0-9.159-14.846,59.971,59.971,0,0,0-12.7-11.753l.885-2.9Z" transform="translate(0 0)" fill="#01a601"/>
        </svg>
      </div>

      <br>

      <div>
        <div class="text-paragraph">
          {{ 'Your refund request has been successfully registered! Following the refund completion, you will be notified via email.' | translate: 'find-my-bookings' }}
        </div>

        <div *ngIf="hasInsuranceFee || hasInsuranceQueueCode" class="text-paragraph">
          {{ 'Your booking contains Travel Protection with COVID19 Cover. To request the refund of the amount paid for the travel insurance, you must submit the request directly to the insurance provider' | translate: 'find-my-bookings' }}
          <a href="https://www.xcover.com/en/login" class="underline-url">
            {{'here' | translate: 'find-my-bookings'}}
          </a>
        </div>
  
        <div class="text-paragraph">
          {{ 'Please note that the refund process may take longer than usual due to the high number of requests. We appreciate your patience and assure you we will review your request at the earliest possible time.' | translate: 'find-my-bookings' }}
        </div>
  
        <div class="text-paragraph">
          {{ 'If, at any time, you change your mind and would like to transfer the funds in the electronic wallet, please contact our customer service center.' | translate: 'find-my-bookings' }}
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="showErrorMessage">
  <div class="flex-wrapper">
    <div class="consent-main-header">
      <h1>
        {{ 'Booking reference' | translate: 'find-my-bookings' }}
      </h1>
      <h1 class="h1-light-color">
        {{ recordLocator | translate: 'find-my-bookings' }}
      </h1>
    </div>
    <div class="show-consent-options text-align-center">
      <p>
        {{ 'It seems that something went wrong when requesting assistance for this booking. Please contact our Customer Service Department for support. Thank you!' | translate: 'find-my-bookings' }}
      </p>

      <p class="show-consent-options-url">
        <a href={{dynamicUrl}} type="button" class="underline-url" (click)="goToCustomerSupportCenter()">
          {{ 'Support Center (flyblueair.com)' | translate: 'find-my-bookings' }}
        </a>
      </p>
    </div>
  </div>
  <div class="banner-wrapper">
    <img src="https://corporate-admin.blueair.aero/media/default/content/fleet/boeing-max-1.png"
      alt="Blue Air aircraft livery">
  </div>
</div>

<div>
  <check-in-default-modal #refundModal modalId="refundModal"
    modalTitle="" modalText="" okButtonText="" cancelButtonText="" [okRequired]="" [cancelRequired]="">

    <div class="bags-wrapper modal">
      <div class="section-text">
        <h3 class="text">
          {{ 'Please note that, in view of the current exceptional situation, this process may take longer than usual.' | translate: 'find-my-bookings' }}
        </h3>

        <h3 class="text">
          {{ 'It is still ok to change your mind. Remember that, if you prefer, you can request the refund in a form of a electronic Wallet,
              the fastest and easiest payment method for future bookings.' | translate: 'find-my-bookings' }}
        </h3>

        <h3 class="text question-strong">
          {{ 'Are you sure you would like to proceed with cash refund?' | translate: 'find-my-bookings' }}
        </h3>

        <div class="modal-buttons">
          <a type="button" class="btn btn-primary" (click)="refundToWallet()">
            {{ 'Accept Wallet' | translate: 'find-my-bookings' }}
          </a>

          <a type="button" class="btn btn-primary inverted" (click)="refundToCash()">
            {{ 'Request Cash' | translate: 'find-my-bookings' }}
          </a>
        </div>
      </div>
    </div>
  </check-in-default-modal>
</div>

<div>
  <check-in-default-modal
    #insuranceModal modalId="insuranceModal"
    modalTitle="{{ 'Important information about Travel Insurance' | translate: 'itinerary' }}"
    modalText=""
    okButtonText=""
    cancelButtonText=""
    [okRequired]=""
    [cancelRequired]="">

    <div class="default-modal half-split asd">
      <div class="section-text padding-bottom">
        <span class="text-color-red">
          {{ 'You`ve purchased Travel Insurance for this booking. Please be aware that you should contact our insurance provider to update your policy in case the names of the passengers from your trip change.' | translate: 'itinerary' }}
        </span>
      </div>
    </div>

    <div class="modal-buttons">
      <a type="button" class="btn btn-primary" style="width: 100%;" (click)="refundToWalletProceed()">
        {{ 'I acknowledge' | translate: 'itinerary' }}
      </a>
    </div>
  </check-in-default-modal>
</div>

<button #loginModalId (click)="openLoginPopup()">
</button>
