
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { SsrType } from './ssr-type.enum';
import { FlightModel, FlightType } from '../core/models/flight-model';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { SsrModel } from './ssr-model';
import { SeatMapService } from '../seat/seat-map.service';
import { BookingService } from '../core/booking.service';
import { Constants } from '../constants';
import { FlowManagerService } from '../core/flow-manager.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { ProfileService } from '../core/profile.service';
import { ECommerceService } from '../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../shared/e-commerce/models/e-commerce-cart-item.model';

@Injectable()
export class SsrsService {
  public ssrs: any;
  public availableSsrs: any[];
  public nestInfo: any[];
  public availableSsrsObs: BehaviorSubject<any[]> = new BehaviorSubject(null);
  public reloadingSsrsObs: Subject<{ ssrType: SsrType, isLoading: boolean }> = new Subject();

  public isExtrasStep: boolean;
  public isLuggageStep: boolean;

  public isDisplayedBagDetailsModal: boolean;
  public isDisplayedBagConfirmationModal: boolean;

  public isDisplayedPriorityDetailsFreeModal: boolean;
  public isDisplayedPriorityDetailsPaidModal: boolean;
  public isDisplayedPriorityConfirmationModal: boolean;
  public priorityPassengersMap = new Map();
  public luggagePassengersMap = new Map();

  public isPrioritySsrSelected: boolean;
  public isBagSsrSelected: boolean;

  public memberPromotions: any;

  private _ssrsToRemove: SsrsState = {};
  private _ssrsToAdd: SsrsState = {};
  private ssrInfoByTypeObs = {};
  public noBagsSync: NoBagsModel[] = [];

  public spinnerIdPrefix: string = 'extras-spinner';

  public defaultDisplayOrder: SsrType[] = [
    SsrType.Baggage, SsrType.PriorityBag, SsrType.Meal, SsrType.Meal, SsrType.Pet, SsrType.SpecialEquipment, SsrType.Lounge,
    SsrType.SpecialAssistance, SsrType.PriorityBoarding, SsrType.FastTrack, SsrType.Insurance
  ];

  public actualDisplayOrder: SsrType[] = [];

  constructor(private http: HttpClient, private configService: ConfigService, private flowManager: FlowManagerService,
    private bookingService: BookingService, private ecommerce: ECommerceService, private translateService: TranslateService,
    private profileService: ProfileService) {
    for (let ssrType in SsrType) {
      if (Number(ssrType) >= 0) {
        this._ssrsToAdd[ssrType] = [];
        this._ssrsToRemove[ssrType] = [];
      }
    }
  }

  /** Toggles loading spinners for specific ssr type boxes */
  toggleLoadingForSsrType(isLoading: boolean, ...ssrTypes: SsrType[]): Promise<void> {
    ssrTypes.forEach(s => this.reloadingSsrsObs.next({ ssrType: s, isLoading }));
    return Promise.resolve();
  }

  getSsrsInfo(): Observable<any> {
    const params = new HttpParams()
      .set('IsCheckinFlow', environment.flow === 0 ? 'true' : 'false');

    return this.http.get(this.configService.SsrsInfoUrl, { params: params })
      .pipe(
        map((data: any) => {
          this.ssrs = data;
          this.availableSsrs = data.ssrsInfo.availableSsrs as any[];

          this.actualDisplayOrder.length = 0;

          this.defaultDisplayOrder.forEach((ssrType) => {
            const avSsr = this.availableSsrs.find(av => av.key === ssrType);
            if (avSsr) {
              for (let j_ix = 0; j_ix < avSsr.value.length; j_ix++) {
                const journeyOrSegment = avSsr.value[j_ix];
                if (journeyOrSegment && journeyOrSegment.value && journeyOrSegment.value.length) {
                  this.actualDisplayOrder.push(ssrType);
                  break;
                }
              }
            }
          });

          this.memberPromotions = data.memberPromotionDetails;
          this.setOriginalPricesForPromotion();
          this.nestInfo = data.ssrsInfo.nestInfo as any[];
          this.availableSsrsObs.next(this.availableSsrs);
          return data;
        }),
        // tap(c => console.log(JSON.stringify(c))),
        shareReplay(1)
      );
  }

  private setOriginalPricesForPromotion() {
    // if member has active promotion
    if (this.memberPromotions.ssrSegmentPromotionList.length > 0) {
      this.memberPromotions.ssrSegmentPromotionList.forEach(segment => {
        segment.value.forEach(s => {
          const ssr = this.availableSsrs.map(s => s.value).reduce((a, b) => a.concat(b), [])
            .filter(x => x.key === segment.key)
            .map(x => x.value).reduce((a, b) => a.concat(b), [])
            .find(ssr => ssr.ssrCode == s.ssrCode);
          if (ssr && s.discount > 0) {
            ssr.originalPrice = ssr.price + s.discount;
          }
        });
      });
    }
  }

  resetSsrs(ssrType: SsrType): void {
    this._ssrsToAdd[ssrType].length = 0;
    this._ssrsToRemove[ssrType].length = 0;
  }

  applySsrs(ssrType: SsrType): Promise<any> {
    let applySsrsPromise: Promise<any> = Promise.resolve();
    let mustRefreshData = false;
    const toRemove = [];
    const toAdd = [];
    if (this._ssrsToRemove[ssrType].length) {
      applySsrsPromise = this.http
        .post(this.configService.SsrsApplyUrl, { 'ssr': { 'selectedJourneySsrs': this._ssrsToRemove[ssrType] } }).toPromise()
        .then(() => {
          this._ssrsToRemove[ssrType].forEach(key => {
            toRemove.push(new EcommerceSsr(key));
          });
          this._ssrsToRemove[ssrType].length = 0
        });
      mustRefreshData = true;
    }
    if (this._ssrsToAdd[ssrType].length) {
      applySsrsPromise = applySsrsPromise
        .then(() =>
          this.http.post(this.configService.SsrsApplyUrl, { 'ssr': { 'selectedJourneySsrs': this._ssrsToAdd[ssrType] } }).toPromise())
        .then(() => {
          this._ssrsToAdd[ssrType].forEach(key => {
            toAdd.push(new EcommerceSsr(key));
          });
          this._ssrsToAdd[ssrType].length = 0
        });
      mustRefreshData = true;
    }

    if (mustRefreshData) {
      return applySsrsPromise.then(() => {
        const applicationFlowService = this.flowManager.applicationFlowService;
        return applicationFlowService.loadPriceBreakdown(true).then(() => {
          const ecommerceToAddCartItems = this.getEcommerceItems(toAdd, ssrType);
          if (ecommerceToAddCartItems.length > 0) {
            this.ecommerce.AddToCart(ecommerceToAddCartItems);
          }
          const ecommerceToRemoveCartItems = this.getEcommerceItems(toRemove, ssrType);
          if (ecommerceToRemoveCartItems.length > 0) {
            this.ecommerce.RemoveFromCart(ecommerceToRemoveCartItems);
          }
        }).then(() => {
          return applicationFlowService.loadFlowInfo(true);
        });
      });
    }
    return applySsrsPromise;
  }

  getEcommerceItems(items: any[], ssrType: SsrType): any[] {
    const data = this.bookingService.bookingObs.value;
    const ecommerceItems = [];
    items.forEach(ssr => {
      let item = ecommerceItems.find(e => {
        // const id = e.item_id.split(' ')[e.item_id.split(' ').length - 1];
        return e.item_id.split(' ')[e.item_id.split(' ').length - 1] === ssr.passengerNumber
          && e.variant === ssr.ssrCode
          && e.item_id.split(' ')[0].concat(e.item_id.split(' ')[1]) === ssr.flightInfo.split(' ')[1].concat(ssr.flightInfo.split(' ')[2])
      });
      const availableSsr = this.availableSsrs.find(s => s.key === ssrType);
      const flightCode = ssr.flightInfo.slice(-6);
      const price = availableSsr.value.find(s => s.key.replace('_', '') === flightCode).value.find(s => s.ssrCode === ssr.ssrCode).price;
      if (item) {
        item.quantity++;
      } else {
        item = new ECommerceCartItem(
          this.translateService.instant(ssr.ssrCode, 'ssr.name'),//name
          this.ecommerce.getFlightInfoByStations(ssr.flightInfo.slice(-6).substring(0, 3), ssr.flightInfo.slice(-6).substring(3, 6)) + ssr.passengerNumber,//id
          price,//price
          'Aeroitalia', //brand
          SsrType[ssrType], ssr.ssrCode, 1, null,
          data.passengers.items.find(p => p.passengerNumber.toString() === ssr.passengerNumber).typeInfo.paxType,
          this.ecommerce.getUserData()).getItem(false);
        ecommerceItems.push(item);
      }
    });
    return ecommerceItems;
  }

  removeSsr(
    ssrType: SsrType, ssrCode: string, ssrNumber: number,
    passengerNumber: number, flight: FlightModel, bySegment: boolean = false) {
    const changes: any[] = this._ssrsToRemove[ssrType];

    if (bySegment && flight.segments.length) {
      flight.segments.forEach(seg => this.removeSsr(ssrType, ssrCode, ssrNumber, passengerNumber, seg, false));
      return;
    }

    // if srr was just added but not applied remove the added ssr
    const ssrToAddKey = this.getSsrKey(ssrCode, flight, passengerNumber);
    const addedSsrIndex = this._ssrsToAdd[ssrType].indexOf(ssrToAddKey);
    if (addedSsrIndex >= 0) {
      this._ssrsToAdd[ssrType].splice(addedSsrIndex, 1);
      return;
    }

    const ssrToRemoveKey = this.getSsrKey(ssrCode, flight, passengerNumber, ssrNumber);
    // if not already removed or if ssr is bought => it can be removed
    if (changes.indexOf(ssrToRemoveKey) < 0 && flight.ssrs[passengerNumber].indexOf(ssrCode) >= 0) {
      changes.push(ssrToRemoveKey);
    }
  }

  addSsr(ssrType: SsrType, ssrCode: string, passengerNumber: number, flight: FlightModel, bySegment: boolean = false): void {
    const changes: any[] = this._ssrsToAdd[ssrType];

    if (bySegment && flight.segments.length) {
      flight.segments.forEach(segment => this.addSsr(ssrType, ssrCode, passengerNumber, segment, false));
      return;
    }

    changes.push(this.getSsrKey(ssrCode, flight, passengerNumber));
  }

  getPendingSsrs(): SsrType[] {
    const pendingSsrsList: SsrType[] = [];

    for (const ssrType in SsrType) {
      if (Number(ssrType)
        && ((this._ssrsToAdd[ssrType] && this._ssrsToAdd[ssrType].length)
          || (this._ssrsToRemove[ssrType] && this._ssrsToRemove[ssrType].length))) {
        pendingSsrsList.push(+ssrType);
      }
    }
    return pendingSsrsList;
  }

  getSsrKey(ssrCode: string, flight: FlightModel, passengerNumber: number, ssrNumber: number = null) {
    if (flight.type === FlightType.Journey) {
      //  return String.Format("{0}|{1}|{2}", passengerNumber, ssrCode, journey.SellKey);
      return `${passengerNumber}|${ssrCode}|${flight.sellKey}`;
    } else {
      // var date = segment.Std.Year + (segment.Std.Month < 10 ? "0" + segment.Std.Month.ToString() : segment.Std.Month.ToString()) + (segment.Std.Day < 10 ? "0" + segment.Std.Day.ToString() : segment.Std.Day.ToString());
      //   var flight = segment.FlightDesignator.CarrierCode.Trim() + segment.FlightDesignator.FlightNumber;

      // return String.Format("|{0}|{1}|{2} {3} {4}{5}||", passengerNumber, ssrCode, date, flight, segment.DepartureStation, segment.ArrivalStation);

      const std = moment(flight.departureStation.date);

      return `${ssrNumber ? ssrNumber : ''}|${passengerNumber}|${ssrCode}|${std.format('YYYYMMDD')} ${flight.carrierCode.trim()}${flight.number} ${flight.departureStation.iataCode}${flight.arrivalStation.iataCode}||1`;
    }
  }

  updatePriorityBag(flights: FlightModel[]): boolean {
    const bagaggeSsrsList = this.availableSsrs.find(lists => lists.key === SsrType.Baggage);
    const baggageCodesList = bagaggeSsrsList ? bagaggeSsrsList.value.map(s => s.value).reduce((a: number[], b: number[]) => a.concat(b), []).map(s => s.ssrCode as string) : [];

    let showPriorityPopup = false;
    flights.forEach(f => {
      for (let passengerNumber in f.ssrs) {
        if (f.ssrs.hasOwnProperty(passengerNumber)) {
          const passengerSsrs: string[] = f.ssrs[passengerNumber].filter(s => baggageCodesList.indexOf(s) >= 0);
          let bags = passengerSsrs.concat(f.paidSsrs[passengerNumber].filter(s => baggageCodesList.indexOf(s) >= 0));
          if (bags.length === 0 && f.ssrs[passengerNumber].filter(s => s === 'PBAG').length > 0) {
            this.removeSsr(SsrType.PriorityBag, 'PBAG', 1, parseInt(passengerNumber), f, true);
            showPriorityPopup = true;
          }
        }
      }
    });
    return showPriorityPopup;
  }

  setupSpecialAssistanceCustomIcons(availableSsrs: SsrModel[]): { [ssrCode: string]: string } {
    let ssrsCustomIcons: { [ssrCode: string]: string } = {};

    availableSsrs.forEach(ssr => {
      let icon: string;

      switch (ssr.ssrCode) {
        case 'WCHC':
          icon = 'font-ico-spec-assistance-cabin';
          break;
        case 'PREG':
        case 'DPNA':
        case 'DEAF':
        case 'BLD':
          icon = `font-ico-spec-assistance-${ssr.ssrCode.toLowerCase()}`;
          break;
        case 'UMNR':
        case 'UMFR':
        case 'UM':
          icon = 'font-ico-spec-assistance-um';
          break;
        case 'WCHS':
        case 'WCHR':
        default:
          icon = 'font-ico-spec-assistance';
          break;
      }

      ssrsCustomIcons[ssr.ssrCode] = icon;
    });

    return ssrsCustomIcons;
  }



  /** Check if there are conflicting seats with the newly added ssrs
   * @returns array of seats to be removed
   */
  getSeatsToBeRemoved(ssrType: SsrType, flights: FlightModel[], newSsrsInfo: any[], passengerNumber: number = -1): any[] {
    if (!newSsrsInfo.length) {
      return [];
    }

    const restrictedSeats = this.getSeatsWithoutSsrRestriction(ssrType, flights);
    return restrictedSeats.filter(seat =>
      newSsrsInfo.some(info =>
        info.passengerNumber === seat.passengerNumber &&
        (passengerNumber === -1 || info.passengerNumber === passengerNumber) &&
        info.flightSellKey === seat.flightSellKey
      )
    );
  }

  getSeatsWithoutSsrRestriction(ssrType: SsrType, flights: FlightModel[]): any[] {
    let typeCode: string;
    switch (ssrType) {
      case SsrType.SpecialAssistance:
        typeCode = Constants.seatTypeDISABIL;
        break;
      case SsrType.Pet:
        typeCode = Constants.seatTypePET;
        break;
      default:
        // nothing to do
        return [];
    }

    const seatsWOrestrictions = [];
    const data = this.bookingService.bookingObs.value;

    data.assignedSeats.journeys.items.forEach(flight => {
      const assignedSeats = flight.segments.items
        .map(p => p.paxSeats).reduce((a, b) => a.concat(b), []).map(p => p.items).reduce((a, b) => a.concat(b), []);

      if (assignedSeats) {
        // tslint:disable-next-line: max-line-length
        const filteredSeats = assignedSeats.filter(x => !x.passengerSeatInfo.propertyList.find(x => x.typeCode === typeCode && x.value === 'TRUE'));
        if (filteredSeats.length) {
          filteredSeats.forEach((seat) => {
            const currentFlight = flights.find(f => f.sellKey === flight.sellKey);
            if (currentFlight) {
              seatsWOrestrictions.push({
                passengerNumber: seat.passengerNumber,
                flightKey: currentFlight.segments
                  .find(s => s.departureStation.iataCode === seat.departureStation &&
                    s.arrivalStation.iataCode === seat.arrivalStation).referenceKey,
                flightSellKey: flight.sellKey,
                deck: seat.passengerSeatInfo.deck,
                compartment: seat.compartmentDesignator,
                unit: seat.unitDesignator,
                departureStation: seat.departureStation,
                arrivalStation: seat.arrivalStation
              });
            }
          });
        }
      }
    });
    return seatsWOrestrictions;
  }

  getPromoDiscount(ssrType: SsrType) {
    const configPromo = this.configService.config.promo as any[];
    if (configPromo && configPromo.length) {
      const promoForSsrType = configPromo.find(p => p.ssrType === ssrType);
      const now = moment();
      if (promoForSsrType &&
        now.isSameOrAfter(moment.utc(promoForSsrType.fromDate, 'YYYY-MM-DD HH:mm')) &&
        now.isSameOrBefore(moment.utc(promoForSsrType.toDate, 'YYYY-MM-DD HH:mm')) &&
        promoForSsrType.discount > 0) {
        return promoForSsrType.discount;
      }
    }
  }
}

export interface SsrsState { [s: number]: string[]; }

export class EcommerceSsr {
  passengerNumber: string;
  ssrCode: string;
  flightInfo: string;

  constructor(key: string) {
    const data = key.split('|');
    this.setParams(data[1], data[2], data[3]);

  }
  public setParams(passengerNumber: string, ssrCode: string, flightInfo: string) {
    this.passengerNumber = passengerNumber;
    this.ssrCode = ssrCode;
    this.flightInfo = flightInfo;
  }
}

interface NoBagsModel {
  flight: string,
  passengerIndex: number,
  noBags: boolean
}
