import * as tslib_1 from "tslib";
import { TranslateService } from './../common-modules/blue-air-common/translator/translate.service';
import { PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
var CultureFormatNumberPipe = /** @class */ (function (_super) {
    tslib_1.__extends(CultureFormatNumberPipe, _super);
    function CultureFormatNumberPipe(translateService) {
        return _super.call(this, translateService.culture) || this;
    }
    CultureFormatNumberPipe.prototype.transform = function (value, digits) {
        return _super.prototype.transform.call(this, value, digits);
    };
    return CultureFormatNumberPipe;
}(DecimalPipe));
export { CultureFormatNumberPipe };
