import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { tap, switchMap, map, share } from 'rxjs/operators';
import { PassengerJourneyBags, JourneyBags } from '../core/models/passenger-journey-bags';
import { ApplicationFlowService } from './application-flow.service';
import { CurrencyManagerService } from './currency-manager.service';
import { CheckinRestrictionModel, CheckinClosedReason } from './checkin-validation/checkin-restriction-model';
import { CheckinValidationErrorType } from './checkin-validation/checkin-validation-error';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { BookingService } from './booking.service';
import { BookingFlowService } from './booking-flow.service';
var CheckinService = /** @class */ (function (_super) {
    tslib_1.__extends(CheckinService, _super);
    function CheckinService(http, configService, currencyManager, translateService, bookingService, bookingFlowService) {
        var _this = _super.call(this, http, configService, currencyManager) || this;
        _this.translateService = translateService;
        _this.bookingService = bookingService;
        _this.bookingFlowService = bookingFlowService;
        _this.isCheckinFlow = true;
        _this.checkInInfoSub = new BehaviorSubject(null);
        _this.canContinue = new BehaviorSubject(null);
        _this.passengers = [];
        _this.boardingPassesObs = new Observable(function (observer) { return _this.boardingPassOberserver = observer; });
        return _this;
    }
    CheckinService.prototype.loadFlowInfo = function (forced) {
        this.bookingService.refresh(forced);
        return this.loadCheckInInfo();
    };
    CheckinService.prototype.loadCheckInInfo = function (loadNotEligibleJourneys) {
        var _this = this;
        if (loadNotEligibleJourneys === void 0) { loadNotEligibleJourneys = false; }
        var params = new HttpParams();
        if (loadNotEligibleJourneys) {
            params = params.set('starterCheckIn.loadCheckInNotEligibleJourneys', 'true');
        }
        return this.http.get(this.configService.CheckinInfo, { params: params })
            .toPromise()
            .then(function (data) {
            _this.checkinInfo = data['starterCheckIn'];
            _this.currency = _this.checkinInfo['currencyCode'];
            _this.updatePassengerJourneyBags();
            _this.checkInInfoSub.next(_this.checkinInfo);
            return _this.checkinInfo;
        });
    };
    CheckinService.prototype.loadCheckInInput = function () {
        var _this = this;
        return this.http.get(this.configService.CheckinInput)
            .toPromise()
            .then(function (data) {
            _this.checkInInput = data['checkInInput'];
            return data;
        });
    };
    CheckinService.prototype.sendPassengerSelection = function (passengerJourneyBags) {
        return this.http.post(this.configService.CheckinInput, { 'checkInInput': { 'passengerJourneyBags': passengerJourneyBags }, 'starterCheckIn': { 'passengerJourneyBags': passengerJourneyBags } })
            .toPromise();
    };
    CheckinService.prototype.updatePassengerJourneyBags = function () {
        this.passengerJourneyBags = this.checkinInfo.passengerJourneyBags.map(function (pjb) {
            var model = new PassengerJourneyBags();
            model.passengerNumber = pjb.passengerNumber;
            model.journeyBags = pjb.journeyBags.map(function (jb) {
                var modelJourneyBag = new JourneyBags();
                modelJourneyBag.bags = jb.bags;
                modelJourneyBag.sellKey = jb.sellKey;
                return modelJourneyBag;
            });
            return model;
        });
    };
    CheckinService.prototype.finalizeCheckin = function () {
        return this.http.post(this.configService.FinalizeCheckinUrl, {}).toPromise();
    };
    CheckinService.prototype.loadBoardingPasses = function () {
        var _this = this;
        var pjbs = this.passengerJourneyBags
            .reduce(function (a, b) {
            a.push.apply(a, tslib_1.__spread(b.journeyBags.map(function (jb) { return ({ pn: b.passengerNumber, sk: jb.sellKey }); })));
            return a;
        }, []);
        var doneCounter = 0;
        pjbs.forEach(function (pjb) {
            var params = new HttpParams()
                .set('pn', pjb.pn + '')
                .set('sk', pjb.sk);
            _this.loadBoardingPass(pjb.pn, pjb.sk)
                .then(function (boardingPasses) {
                if (boardingPasses && boardingPasses.length) {
                    boardingPasses.forEach(function (bp) { return _this.boardingPassOberserver.next(bp); });
                }
                if (++doneCounter === pjbs.length) {
                    _this.boardingPassOberserver.complete();
                }
            });
        });
    };
    CheckinService.prototype.loadBoardingPass = function (requestedPassengerNumber, flightSellKey) {
        var _this = this;
        if (requestedPassengerNumber === void 0) { requestedPassengerNumber = null; }
        if (flightSellKey === void 0) { flightSellKey = null; }
        var params = new HttpParams();
        if (requestedPassengerNumber != null && flightSellKey != null) {
            params = new HttpParams()
                .set('pn', requestedPassengerNumber + '')
                .set('sk', flightSellKey);
        }
        this.bookingFlowService.loadFlowInfo().then(function (booking) {
            if (booking && booking.passengers && booking.passengers.items && booking.passengers.items.length > 0) {
                _this.passengers = booking.passengers.items;
            }
        });
        return this.http.get(this.configService.BoardingPassUrl, { params: params })
            .toPromise()
            .then(function (bp) {
            if (bp && bp.printBoardingPasses && bp.printBoardingPasses.items && bp.printBoardingPasses.items.length > 0) {
                var passengersDetails = bp.printBoardingPasses.passengersDetails.reduce(function (accumulator, passDetails) {
                    accumulator[passDetails.passengerNumber] = passDetails;
                    return accumulator;
                }, {});
                return bp.printBoardingPasses.items.map(function (item, index) {
                    if (item) {
                        return _this.getPassengerTravelInformation(item);
                    }
                });
            }
            return [];
        });
    };
    CheckinService.prototype.getPassengerTravelInformation = function (item) {
        var pax = this._isInfant(item) ? this.passengers.find(function (p) { return p.infant.name.first === item.name.first; }) : this.passengers.find(function (p) { return p.name.first === item.name.first; });
        if (!pax) {
            return;
        }
        if (pax.travelDocuments && pax.travelDocuments.items && pax.travelDocuments.items.length > 0) {
            item.documentType = pax.travelDocuments.items[0].docTypeCode;
            item.documentNumber = pax.travelDocuments.items[0].docNumber;
            item.documentExpirationDate = pax.travelDocuments.items[0].expirationDate;
            item.nationality = pax.travelDocuments.items[0].nationality;
        }
        else if (pax.info) {
            item.nationality = pax.info.nationality;
        }
        return item;
    };
    CheckinService.prototype._isInfant = function (boardingPassItem) {
        if (boardingPassItem.isoInfantName.first === boardingPassItem.isoName.first
            && boardingPassItem.isoInfantName.last === boardingPassItem.isoName.last) {
            this.clearPassengerSSRs(boardingPassItem);
            return true;
        }
        return false;
    };
    CheckinService.prototype.clearPassengerSSRs = function (item) {
        item.sSR = [];
        item.segments.forEach(function (segment) {
            segment.legs.forEach(function (leg) {
                leg.ssrs = [];
            });
        });
        return item;
    };
    CheckinService.prototype.sendBoardingPassOnEmail = function (cultureCode, email, passengerNumber, sellKey) {
        var body = {
            email: email,
            culture: cultureCode
        };
        if (passengerNumber != null && sellKey != null) {
            body.pn = passengerNumber;
            body.sk = sellKey;
        }
        return this.http.post(this.configService.SendBoardingPassUrl, body).toPromise();
    };
    CheckinService.prototype.retrieveBooking = function (recordLocator, lastName, email) {
        var data = {
            id: recordLocator,
            ln: lastName || '',
            email: email || ''
        };
        return this.http.post(this.configService.CheckinRetrieveUrl, data);
    };
    CheckinService.prototype.retrieveCheckinRestrictions = function () {
        return this.http.get(this.configService.CheckinRetrieveRestrictionsUrl);
    };
    CheckinService.prototype.getCheckinRestrictions = function (forced, flight) {
        var _this = this;
        if (forced === void 0) { forced = false; }
        if (flight === void 0) { flight = null; }
        if (forced || !this.checkinRestrictionsObx) {
            this.checkinRestrictionsObx = this.retrieveCheckinRestrictions().pipe(map(function (r) { return (r && r.checkinRestrictions && r.checkinRestrictions.items || [])
                .map(function (i) { return new CheckinRestrictionModel().initFromRestriction(i); }); }), tap(function (checkinRestrictions) { return _this.checkinRestrictionsObx = observableOf(checkinRestrictions); }), share());
        }
        return this.checkinRestrictionsObx.pipe(map(function (r) { return _this.validateCheckinRestrictions(r, flight); }));
    };
    CheckinService.prototype.retrieveAndValidateBooking = function (recordLocator, retrieveMethodByLastName, lastName, emailAddress) {
        var _this = this;
        if (!recordLocator || retrieveMethodByLastName && !lastName || !retrieveMethodByLastName && !emailAddress) {
            return observableOf([{ errorType: CheckinValidationErrorType.mandatoryFields }]);
        }
        return this.retrieveBooking(recordLocator, lastName, emailAddress).pipe(map(function (r) { return r.checkinValidation; }), switchMap(function (checkinValidation) {
            var errors = _this.validateCheckinBooking(checkinValidation);
            if (errors.length) {
                return observableOf(errors);
            }
            return _this.getCheckinRestrictions(true);
        }));
    };
    CheckinService.prototype.validateCheckinBooking = function (checkinValidation) {
        var errors = [];
        if (!checkinValidation.canCheckIn && checkinValidation.groupRestriction) {
            errors.push({ errorType: CheckinValidationErrorType.checkinRestrictedForGroups });
        }
        else if (!checkinValidation.isValid) {
            errors.push({ errorType: CheckinValidationErrorType.bookingNotFound });
        }
        if (checkinValidation.checkInClosed) {
            errors.push({ errorType: CheckinValidationErrorType.checkinClosed });
        }
        else if (checkinValidation.checkInNotOpen) {
            errors.push({ errorType: CheckinValidationErrorType.checkinNotOpen });
        }
        if (!checkinValidation.checkInClosed && checkinValidation.hasPendingPayments) {
            errors.push({ errorType: CheckinValidationErrorType.pendingPayments });
        }
        return errors;
    };
    CheckinService.prototype.validateCheckinRestrictions = function (checkinRestrictions, flight) {
        var _this = this;
        if (checkinRestrictions === void 0) { checkinRestrictions = []; }
        if (flight === void 0) { flight = null; }
        var errors = [];
        // if we are not filtering by specific flight and there is at least one journey that can checkin ignore other errors
        if (!flight && checkinRestrictions.some(function (r) { return r.checkinOpened; })) {
            return [];
        }
        checkinRestrictions.filter(function (r) { return !r.checkinOpened && (!flight || flight.sellKey === r.journeySellKey); }).forEach(function (r) {
            r.routeName = _this.translateService.instant('{0} to {1}', null, [
                _this.translateService.instant(r.departureStation, 'station.name'),
                _this.translateService.instant(r.arrivalStation, 'station.name')
            ]);
            var errorType;
            switch (r.checkinClosedReason) {
                case CheckinClosedReason.ClosedForBundle:
                    if (r.upgradeBundle) {
                        r.currentBundle = _this.translateService.instant(r.currentBundle, 'Booking');
                        r.upgradeBundle = _this.translateService.instant(r.upgradeBundle, 'Booking');
                        errorType = CheckinValidationErrorType.checkinNotOpenedUpgradeAvailable;
                    }
                    else {
                        errorType = CheckinValidationErrorType.checkinNotOpenedNoUpgrade;
                    }
                    break;
                case CheckinClosedReason.ClosedForMarket:
                    errorType = CheckinValidationErrorType.checkinClosedOnMarket;
                    break;
                case CheckinClosedReason.ClosedForCodeshare:
                    errorType = CheckinValidationErrorType.checkinClosedForCodeshare;
                    break;
                default:
                    return;
            }
            errors.push({
                errorType: errorType,
                errorData: r
            });
        });
        return errors;
    };
    return CheckinService;
}(ApplicationFlowService));
export { CheckinService };
