import { OnInit, Renderer2 } from '@angular/core';
import { PassengerTypeSelectionViewModel } from '../models/passenger-selection-view-model';
import { ControlValueAccessor } from '@angular/forms';
import { PassengerSelectionType } from '../models/pasenger-selection-type-enum';
import { Constants } from '../../constants';
import { BlueRenderer } from '../../common-modules/blue-air-common/services/blue-renderer.service';
import { DomHelper } from '../dom-helper';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';
var PassengerTypePickerComponent = /** @class */ (function () {
    function PassengerTypePickerComponent(renderer, blueRenderer, modalService) {
        this.renderer = renderer;
        this.blueRenderer = blueRenderer;
        this.modalService = modalService;
        this.PassengerSelectionType = PassengerSelectionType;
        this.totalPaxCount = 9;
        this.modalId = 'numberOfPassengersModal';
        this.tempModel = new PassengerTypeSelectionViewModel();
        this.updateWindowWith(window.innerWidth);
    }
    Object.defineProperty(PassengerTypePickerComponent.prototype, "totalAllowedPassengers", {
        set: function (value) {
            this.totalPaxCount = value;
        },
        enumerable: true,
        configurable: true
    });
    PassengerTypePickerComponent.prototype.onResize = function (event) {
        this.updateWindowWith(event.target.innerWidth);
    };
    PassengerTypePickerComponent.prototype.ngOnInit = function () {
    };
    PassengerTypePickerComponent.prototype.toggleOpened = function (newValue) {
        if (newValue === void 0) { newValue = !this.isOpened; }
        this.isOpened = newValue;
    };
    PassengerTypePickerComponent.prototype.focusIn = function () {
        this.toggleOpened(true);
        this.tempModel = Object.assign(new PassengerTypeSelectionViewModel(), this.model);
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
        DomHelper.IncreaseAppHeight(this.renderer);
    };
    PassengerTypePickerComponent.prototype.focusOut = function (applySelection) {
        if (applySelection === void 0) { applySelection = false; }
        this.toggleOpened(false);
        if (applySelection) {
            this.model = Object.assign(new PassengerTypeSelectionViewModel(), this.tempModel);
            this.onChangeCb(this.model);
        }
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
        DomHelper.ResetAppHeight(this.renderer);
    };
    /**
     * Makes updates to the component based on the new window width
     * @param newWidth - the new width of the window
     */
    PassengerTypePickerComponent.prototype.updateWindowWith = function (newWidth) {
        if (this.currentWindowWidth === newWidth) {
            return;
        }
        this.currentWindowWidth = newWidth;
        this.isOnMobile = newWidth < Constants.mobileScreenWith;
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
    };
    PassengerTypePickerComponent.prototype.update = function (paxType, value) {
        var modelProperty = this.tempModel.getProperty(paxType);
        var oldVal = this.tempModel[modelProperty];
        var newVal = this.tempModel[modelProperty] + value;
        var isExceededInfants = false;
        if (newVal < 0) {
            newVal = 0;
        }
        switch (paxType) {
            case PassengerSelectionType.Adult:
                this.tempModel.adults = newVal;
                if (this.tempModel.infants > this.tempModel.adults) {
                    this.tempModel.infants = this.tempModel.adults;
                }
                break;
            case PassengerSelectionType.YoungAdult:
            case PassengerSelectionType.Teen:
            case PassengerSelectionType.Children:
                this.tempModel.children = newVal;
                break;
            case PassengerSelectionType.Infant:
                if (newVal > this.tempModel.adults) {
                    isExceededInfants = true;
                    break;
                }
                this.tempModel.infants = newVal;
                break;
            default:
                this.tempModel[modelProperty] = newVal;
                break;
        }
        // this needs group booking
        if (this.tempModel.count > this.totalPaxCount && oldVal < this.tempModel.count) {
            // todo: show popup error
            this.modalService.open("numberOfPaxExcededModal");
            this.tempModel[modelProperty] = oldVal;
        }
        if (this.tempModel.adults <= 0) {
            this.resetSelectedPassengersChoices();
            if (paxType == PassengerSelectionType.Children) {
                this.modalMessage = "Not without at least one adult";
                this.displayNumberOfPassengersModal();
            }
            if (paxType == PassengerSelectionType.Infant || isExceededInfants) {
                this.modalMessage = "One infant per adult";
                this.displayNumberOfPassengersModal();
            }
        }
        if (isExceededInfants) {
            this.modalMessage = "One infant per adult";
            this.displayNumberOfPassengersModal();
        }
    };
    PassengerTypePickerComponent.prototype.resetSelectedPassengersChoices = function () {
        this.tempModel.adults = 0;
        this.tempModel.youngAdults = 0;
        this.tempModel.teens = 0;
        this.tempModel.children = 0;
        this.tempModel.infants = 0;
    };
    PassengerTypePickerComponent.prototype.writeValue = function (obj) {
        if (!obj) {
            this.model = new PassengerTypeSelectionViewModel();
            this.model.adults = 1;
        }
        else {
            this.model = new PassengerTypeSelectionViewModel();
            this.model.initFromModel(obj);
        }
    };
    PassengerTypePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCb = fn;
    };
    PassengerTypePickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCb = fn;
    };
    PassengerTypePickerComponent.prototype.setDisabledState = function (isDisabled) {
        // nothing to do
    };
    PassengerTypePickerComponent.prototype.displayNumberOfPassengersModal = function () {
        this.numberOfPassengersModal.openPopup(this.modalId);
    };
    return PassengerTypePickerComponent;
}());
export { PassengerTypePickerComponent };
