import { Component, OnInit } from '@angular/core';
import { CheckinService } from '../../core/checkin.service';
import { BookingStepsService, CheckinSteps, ApplicationFlowEnum } from '../../core/booking-steps.service';
import { PaymentsService } from '../payments.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../../constants';
import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'check-in-verify-payment',
  templateUrl: './verify-payment.component.html',
  styleUrls: ['./verify-payment.component.scss']
})
export class VerifyPaymentComponent implements OnInit {
  constructor(private paymentsService: PaymentsService, private bookingSteps: BookingStepsService,
    private checkinService: CheckinService, private loadingSpinnerService: LoadingSpinnerService,
    private appFlowSteps: BookingStepsService) {
    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
  }

  ngOnInit() {
    this.paymentsService.hasAppliedPayment = true;

    this.paymentsService.applyPayment().then(paymentSuccessful => {
      if (!paymentSuccessful) {
        return !paymentSuccessful;
      }
      if (this.appFlowSteps.flow === ApplicationFlowEnum.LockFare) {
        return false;
      }
      return false;
      // return this.paymentsService.hasBalanceDue(true);
    }).then((invalidPayment: boolean) => {
      if (invalidPayment) {
        this.bookingSteps.goToStep(CheckinSteps.summary, { error: true });
      } else {
        if (environment.flow === 0) {
          this.checkinService.finalizeCheckin().then(() => {
            this.bookingSteps.goToStep(CheckinSteps.confirmation);
          });
        } else {
          this.bookingSteps.goToNextStepFrom(CheckinSteps.summary);
        }
      }
    });
  }
}
