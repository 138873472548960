<blue-modal [id]="sendEmailModalId" closeBtnLabel="{{ 'Close send email popup' | translate:'shared' }}" 
  class="checked-in-summary-popup">
  <ng-container *ngIf="isOpened">
    <blue-loading-spinner [id]="sendEmailSpinnerId"></blue-loading-spinner>
    
    <h2 *ngIf="passengerName">{{ 'Send boarding pass on email for {0}&nbsp;{1}' | translate:'shared':passengerName.first:passengerName.last }}</h2>
    <h2 *ngIf="!passengerName">{{ 'Send boarding passes on email' | translate:'shared' }}</h2>
    <form  ngForm="sendEmailForm" #sendEmailForm="ngForm" *ngIf="!responseOk"
      (ngSubmit)="sendBoardingPassOnEmail(sendEmailForm)" 
      attr.aria-label="{{ 'Form for adding email for sending via email' | translate:'shared' }}">
        <div class="number-wrapper">
          <label for="boardingPassEmail">{{ 'Email'| translate:'shared' }}</label>
          <input type="email" id="boardingPassEmail" attr.placeholder="{{ 'Fill in email' |translate:'shared' }}" email [(ngModel)]="email" name="boardingPassEmail" />
          <div>
            <span class="error-txt" [style.display]="submitted && (sendEmailForm.invalid || !valid) ? 'block' : ''">
              {{ 'Please fill in a valid email address' | translate:'shared' }}
            </span>
            <span class="error-txt" [style.display]="responseReceived && !responseOk ? 'block' : ''">
              {{ 'Email sending failed. Please try again.' | translate:'shared'}}
            </span>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" attr.aria-label="{{ 'Click to send' | translate:'shared' }}" *ngIf="!responseOk">
            {{'Send' | translate:'shared' }}
        </button>
    </form>
    <div class="checkin-cart-message checkin-successful" role="alert" *ngIf="responseOk">
      <span class="icon" role="presentation"></span>
      <span class="message-text">{{ 'Email sent successfully'| translate:'shared'}}</span>
    </div>
  </ng-container>
</blue-modal>