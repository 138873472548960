import { Constants } from "../../constants";
import { RowCells } from "./row-cell-model";
import { Unit } from "./seat-models";
var UnitRow = /** @class */ (function () {
    /**
     *
     */
    function UnitRow(unit, cell) {
        this.units = new Array();
        this.rowCells = new RowCells();
        this.hasDecoratorStyle = false;
        this.y = unit.y;
        this.addCell(cell, unit);
        this.units.push(unit);
    }
    Object.defineProperty(UnitRow.prototype, "cellGroups", {
        get: function () {
            return this.rowCells.groups;
        },
        enumerable: true,
        configurable: true
    });
    ;
    UnitRow.prototype.addCell = function (cell, unit) {
        this.rowCells.addCell(cell, unit);
    };
    UnitRow.prototype.fillDecorator = function (seatProperties, soldSeats) {
        var _this = this;
        this.units.forEach(function (u) {
            var decorator = "";
            if (u.seatProperties)
                u.seatProperties.forEach(function (p) {
                    decorator = _this.checkCellDecorator(decorator, seatProperties[p]);
                });
            if (!u.assignable) {
                u.baseDecorator = "legend-not-available";
            }
            // if (u.unitAvailability == "ReservedForPNR" || u.unitAvailability =="HeldForThisSession")
            //   u.decorator = "legend-selected";
            if (decorator == Constants.seatTypeLEGROOM) {
                u.baseDecorator = "legend-leg-room";
                u.mainColorClass = "legend-leg-room-color";
            }
            else if (decorator == "FRONT") {
                u.baseDecorator = "legend-front";
                u.mainColorClass = "legend-front-color";
            }
            else if (decorator == "REAR") {
                u.baseDecorator = "legend-back";
                u.mainColorClass = "legend-back-color";
            }
            if (u.unitAvailability == "Reserved") {
                u.baseDecorator = "legend-not-available";
            }
            if (u.unitAvailability != "Open"
                && u.unitAvailability != "ReservedForPNR"
                && u.unitAvailability != "HeldForThisSession") {
                //if it is restricted for all passengers
                if (u.unitAvailabilityByPax && u.unitAvailabilityByPax.every(function (x) { return x != "Open"; })) {
                    u.baseDecorator = "legend-not-available";
                }
                else {
                    u.masterDecorator = u.baseDecorator;
                    u.baseDecorator = "";
                }
            }
            u.decorator = u.baseDecorator;
            // if(soldSeats.findIndex(p=>p.unitDesignator == u.unitDesignator )>=0){
            //   u.decorator ="legend-selected";
            // }
        });
    };
    UnitRow.prototype.checkCellDecorator = function (_old, _new) {
        if (_old && _old.length == 0)
            return _new.typeCode;
        if (_old == Constants.seatTypeLEGROOM)
            return _old;
        if (_new.typeCode == Constants.seatTypeLEGROOM)
            return _new.typeCode;
        if (_new.typeCode == Constants.seatTypeLOCATION)
            return _new.subProperty.typeCode;
        return _old;
    };
    UnitRow.prototype.fillSlot = function (index, indexes, maxPerRow) {
        var _this = this;
        if (maxPerRow === void 0) { maxPerRow = 0; }
        var a = this.units[0].unitDesignator.split('');
        a.pop();
        var rowNumber = a.join("");
        indexes.forEach(function (i) {
            if (_this.units.findIndex(function (p) { return p.x == i; }) < 0) {
                {
                    _this.units.splice(indexes.indexOf(i), 0, new Unit(' '));
                }
            }
        });
        this.units.splice(index + 1, 0, new Unit(a.join("")));
    };
    UnitRow.prototype.orderUnits = function () {
        this.units =
            this.units.sort(function (a, b) {
                if (a.x < b.x) {
                    return -1;
                }
                else if (a.x > b.x) {
                    return 1;
                }
                else {
                    return 0;
                }
            });
    };
    UnitRow.prototype.reArrangeunits = function (index) {
        var numberUnit = this.units.find(function (p) { return p.x < 0; });
        if (numberUnit) {
            var oldIndexOnunit = this.units.findIndex(function (p) { return p === numberUnit; });
            this.units.splice(oldIndexOnunit, 1);
        }
        else {
            numberUnit = new Unit();
        }
        this.units.splice(index, 0, numberUnit);
    };
    return UnitRow;
}());
export default UnitRow;
