import { Injectable } from '@angular/core';
import { BlueModalComponent } from './blue-modal.component';
import { BlueModal } from './blue-modal';

@Injectable()
export class BlueModalService {
  private modals: BlueModal[] = [];

  constructor() { }

  add(modal: BlueModal) {
      // add modal to array of active modals
      this.modals.push(modal);
  }

  remove(id: string) {
    // remove modal from array of active modals
    const modalToRemove = this.modals.findIndex(m => m.id === id);
    if (modalToRemove >= 0) {
      this.modals.splice(modalToRemove, 1);
    }
  }

  open(id: string, closeCallback: () => void = null) {
      // open modal specified by id
      const modal = this.modals.find(m => m.id === id);
      if (modal) {
        modal.open(closeCallback);
      }
  }

  close(id: string) {
      // close modal specified by id
      const modal = this.modals.find(m => m.id === id);
      if (modal) {
        modal.close();
      }
  }
}
