import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, NavigationExtras } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { environment } from '../../environments/environment';

@Injectable()
export class BookingStepsService {
    public routes: BehaviorSubject<ApplicationStepInfo[]> = new BehaviorSubject<ApplicationStepInfo[]>([]);
    public currentStep: BehaviorSubject<CheckinSteps> = new BehaviorSubject(CheckinSteps.search);
    public blueBenefitObs: Subject<any> = new Subject();

    public flow: ApplicationFlowEnum;

    constructor(private translationService: TranslateService, private router: Router) {
        this.setFlow(environment.flow === 0 ? ApplicationFlowEnum.Checkin : ApplicationFlowEnum.Booking);
    }

    setFlow(flow: ApplicationFlowEnum): BookingStepsService {
        if (flow !== this.flow) {
            this.flow = flow;

            switch (this.flow) {
                case ApplicationFlowEnum.Checkin:
                    this.routes.next(BookingStepsService.getCheckinRoutes(this.translationService));
                    break;
                case ApplicationFlowEnum.Booking:
                    this.routes.next(BookingStepsService.getBookingRoutes(this.translationService));
                    break;
                case ApplicationFlowEnum.ManageBooking:
                    this.routes.next(BookingStepsService.getChangeBookingRoutes(this.translationService));
                    break;
                case ApplicationFlowEnum.LockFare:
                    this.routes.next(BookingStepsService.getLockFareRoutes(this.translationService));
                    break;
                case ApplicationFlowEnum.FinalizeLockFare:
                    this.routes.next(BookingStepsService.getLockFareFinalizeRoutes(this.translationService));
                    break;
            }
        }
        return this;
    }

    goToNextStepFrom(step: CheckinSteps, queryParams = {}): void {
        const routeIndex = this.findStepIndex(step);
        this.router.navigate([this.routes.value[routeIndex + 1].route], { queryParams: queryParams });
    }

    goToStep(step: CheckinSteps, queryParams?: {}): void {
        const routeIndex = this.findStepIndex(step);
        this.router.navigate([this.routes.value[routeIndex].route], { queryParams: queryParams });
    }

    getNextStep(step: CheckinSteps): CheckinSteps {
        const routeIndex = this.findStepIndex(step);
        const searchedRoute = this.routes.value[routeIndex + 1];
        return searchedRoute ? searchedRoute.step : null;
    }

    getPreviousStep(step: CheckinSteps) {
        const routeIndex = this.findStepIndex(step);
        const searchedRoute = this.routes.value[routeIndex - 1];
        return searchedRoute ? searchedRoute.step : null;
    }

    isBefore(stepA: CheckinSteps, stepB: CheckinSteps): boolean {
        return this.findStepIndex(stepA) < this.findStepIndex(stepB);
    }
    isSameOrBefore(stepA: CheckinSteps, stepB: CheckinSteps): boolean {
        return stepA === stepB || this.isBefore(stepA, stepB);
    }

    extractStep(nextState?: RouterStateSnapshot): CheckinSteps {
        if (nextState) {
            const routeUrl = nextState.url.substring(1, nextState.url.length);
            const route = this.routes.value.find(r => r.route === routeUrl);

            if (route) {
                return route.step;
            } else if (routeUrl.startsWith('agent/')) {
                return null;
            } else {
                return CheckinSteps.search;
            }
        }

        return null;
    }

    canNavigateToStep(toStep: CheckinSteps): boolean {
        if (toStep == null) {
            return false;
        }
        const fromStep = this.currentStep.getValue();

        switch (this.flow) {
            case ApplicationFlowEnum.Checkin:
                return this.canNavigateToOnCheckin(fromStep, toStep);
            default:
                return this.canNavigateToOnBooking(fromStep, toStep);
        }
    }

    findStepIndex(step: CheckinSteps): number {
        return this.routes.value.findIndex(r => r.step === step);
    }

    navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
        return this.router.navigate(commands, extras);
    }

    private canNavigateToOnCheckin(fromStep: CheckinSteps, toStep: CheckinSteps): boolean {

        switch (toStep) {
            case fromStep:
            case CheckinSteps.confirmation:
                return false;
        }

        switch (fromStep) {
            case CheckinSteps.flights:
            case CheckinSteps.documents:
                return toStep < fromStep;
            default:
                return true;
        }
    }

    private canNavigateToOnBooking(fromStep: CheckinSteps, toStep: CheckinSteps): boolean {
        // TODO check rules for navigation
        switch (fromStep) {
            case CheckinSteps.search:
                return false;
            case CheckinSteps.flights:
                return toStep === CheckinSteps.search;
            case CheckinSteps.itinerary:
                return false;
            case CheckinSteps.passengers:
                return false;
            case CheckinSteps.luggage:
            case CheckinSteps.extras:
            case CheckinSteps.seat:
                return this.isBefore(toStep, fromStep) || this.findStepIndex(toStep) < this.findStepIndex(CheckinSteps.summary);
            case CheckinSteps.changes:
                return this.findStepIndex(toStep) <= this.findStepIndex(CheckinSteps.summary);
        }

        return this.findStepIndex(toStep) < this.findStepIndex(fromStep);
    }

    // tslint:disable: member-ordering
    // tslint:disable: max-line-length
    // tslint:disable: no-use-before-declare
    private static getCheckinRoutes(t: TranslateService): ApplicationStepInfo[] {
        const prefix = environment.routePrefix.checkin ? environment.routePrefix.checkin : '';
        return [
            new ApplicationStepInfo(prefix).init('', '', CheckinSteps.search, '', false, false),
            new ApplicationStepInfo(prefix).init(t.instant('Step 1', 'navigation'), t.instant('Select passengers', 'navigation'), CheckinSteps.flights, 'flights'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 2', 'navigation'), t.instant('Add ID document', 'navigation'), CheckinSteps.documents, 'documents'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 3', 'navigation'), t.instant('Seat selection', 'navigation'), CheckinSteps.seat, 'seat'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 4', 'navigation'), t.instant('Luggage & Extras', 'navigation'), CheckinSteps.luggage, 'luggage'),
            // new ApplicationStepInfo(prefix).init(t.instant('Step 5', 'navigation'), t.instant('Extras selection', 'navigation'), CheckinSteps.extras, 'extras'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 6', 'navigation'), t.instant('Summary &amp; payment', 'navigation'), CheckinSteps.summary, 'summary'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 7', 'navigation'), t.instant('Check-in complete', 'navigation'), CheckinSteps.confirmation, 'confirmation')
        ];
    }

    private static getBookingRoutes(t: TranslateService): ApplicationStepInfo[] {
        const prefix = environment.routePrefix.booking ? environment.routePrefix.booking : '';
        return [
            new ApplicationStepInfo(prefix).init('', '', CheckinSteps.pushToWallet, '', false, false),
            new ApplicationStepInfo(prefix).init(t.instant('Step 1', 'navigation'), t.instant('Flights', 'navigation'), CheckinSteps.flights, 'flights'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 2', 'navigation'), t.instant('Passengers', 'navigation'), CheckinSteps.passengers, 'passengers'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 3', 'navigation'), t.instant('Seat selection', 'navigation'), CheckinSteps.seat, 'seat'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 4', 'navigation'), t.instant('Luggage & Extras', 'navigation'), CheckinSteps.luggage, 'luggage'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 5', 'navigation'), t.instant('Payments', 'navigation'), CheckinSteps.summary, 'summary'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 5', 'navigation'), t.instant('Payments', 'navigation'), CheckinSteps.itinerary, 'thank-you', true, false)
        ];
    }

    private static getChangeBookingRoutes(t: TranslateService): ApplicationStepInfo[] {
        const prefix = environment.routePrefix.booking ? environment.routePrefix.booking : '';
        return [
            new ApplicationStepInfo(prefix).init(t.instant('Step 1', 'navigation'), t.instant('Change flight', 'navigation'), CheckinSteps.search, '', false, false),
            new ApplicationStepInfo(prefix).init(t.instant('Step 2', 'navigation'), t.instant('Flights', 'navigation'), CheckinSteps.flights, 'flights'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 3', 'navigation'), t.instant('Passengers', 'navigation'), CheckinSteps.passengers, 'passengers'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 4', 'navigation'), t.instant('Seat selection', 'navigation'), CheckinSteps.seat, 'seat'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 5', 'navigation'), t.instant('Luggage & Extras', 'navigation'), CheckinSteps.luggage, 'luggage'),
            // new ApplicationStepInfo(prefix).init(t.instant('Step 6', 'navigation'), t.instant('Extras', 'navigation'), CheckinSteps.extras, 'extras'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 7', 'navigation'), t.instant('Booking changes', 'navigation'), CheckinSteps.changes, 'changes'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 8', 'navigation'), t.instant('Payments', 'navigation'), CheckinSteps.summary, 'summary'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 7', 'navigation'), t.instant('Payments', 'navigation'), CheckinSteps.itinerary, 'thank-you', true, false)
        ];
    }

    private static getLockFareRoutes(t: TranslateService): ApplicationStepInfo[] {
        const prefix = environment.routePrefix.booking ? environment.routePrefix.booking : '';
        return [
            new ApplicationStepInfo(prefix).init(t.instant('Step 2', 'navigation'), t.instant('Flights', 'navigation'), CheckinSteps.flights, 'flights'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 6', 'navigation'), t.instant('Passengers', 'navigation'), CheckinSteps.passengers, 'passengers/lockfare'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 8', 'navigation'), t.instant('Payments', 'navigation'), CheckinSteps.summary, 'summary/lockfare'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 7', 'navigation'), t.instant('Payments', 'navigation'), CheckinSteps.itinerary, 'thank-you/lockfare', true, false)
        ];
    }

    private static getLockFareFinalizeRoutes(t: TranslateService): ApplicationStepInfo[] {
        const prefix = environment.routePrefix.booking ? environment.routePrefix.booking : '';
        return [
            new ApplicationStepInfo(prefix).init(t.instant('Step 3', 'navigation'), t.instant('Luggage', 'navigation'), CheckinSteps.luggage, 'luggage'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 4', 'navigation'), t.instant('Extras', 'navigation'), CheckinSteps.extras, 'extras'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 5', 'navigation'), t.instant('Seat selection', 'navigation'), CheckinSteps.seat, 'seat'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 6', 'navigation'), t.instant('Passengers', 'navigation'), CheckinSteps.passengers, 'passengers'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 7', 'navigation'), t.instant('Payments', 'navigation'), CheckinSteps.summary, 'summary'),
            new ApplicationStepInfo(prefix).init(t.instant('Step 7', 'navigation'), t.instant('Payments', 'navigation'), CheckinSteps.itinerary, 'thank-you', true, false)
        ];
    }
    // tslint:enable: member-ordering
    // tslint:enable: max-line-length
    // tslint:enable: no-use-before-declare
}

export enum CheckinSteps {
    search,
    flights,
    documents,
    passengers,
    luggage,
    extras,
    seat,
    changes,
    summary,
    confirmation,
    itinerary,
    pushToWallet,
    bundles
}

export interface IKeyedCollection<T> {
    Add(key: string, value: T);
    ContainsKey(key: string): boolean;
    Count(): number;
    Item(key: string): T;
    Keys(): string[];
    Remove(key: string): T;
    Values(): T[];
}

export class KeyedCollection<T> implements IKeyedCollection<T> {
    private items: { [index: string]: T } = {};

    private count: number = 0;

    public ContainsKey(key: string): boolean {
        return this.items.hasOwnProperty(key);
    }

    public Count(): number {
        return this.count;
    }

    public Add(key: string, value: T) {
        if (!this.items.hasOwnProperty(key))
            this.count++;

        this.items[key] = value;
    }

    public Remove(key: string): T {
        var val = this.items[key];
        delete this.items[key];
        this.count--;
        return val;
    }

    public Item(key: string): T {
        return this.items[key];
    }

    public Keys(): string[] {
        var keySet: string[] = [];

        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                keySet.push(prop);
            }
        }

        return keySet;
    }

    public Values(): T[] {
        var values: T[] = [];

        for (var prop in this.items) {
            if (this.items.hasOwnProperty(prop)) {
                values.push(this.items[prop]);
            }
        }

        return values;
    }

}

export class ApplicationStepInfo {
    navigationLabel: string;
    navigationText: string;
    step: CheckinSteps;
    route: string;
    showNavigation?= true;
    visible?= true;
    // dubioase
    isActive?= false;
    isDisabled?= false;

    routePrefix: string;

    constructor(routePrefix?: string) {
        this.routePrefix = routePrefix;
    }

    init(label: string, text: string, step: CheckinSteps, route?: string, showNavigation: boolean = this.showNavigation,
        visible: boolean = this.visible): ApplicationStepInfo {
        this.navigationLabel = label;
        this.navigationText = text;
        this.step = step;
        this.route = route != null ? route : CheckinSteps[this.step];
        if (this.routePrefix) {
            this.route = this.route ? `${this.routePrefix}/${this.route}` : this.routePrefix;
        }
        this.showNavigation = showNavigation;
        this.visible = visible;

        return this;
    }
}

export enum ApplicationFlowEnum {
    Checkin = 100,
    Booking = 200,
    ManageBooking = 300,
    LockFare = 400,
    FinalizeLockFare = 500
}
