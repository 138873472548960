<check-in-form-wrapper [step]="'itinerary-ptw'" [showContinue]="false" [showPriceDisplay]="false">
 
  <div class="form-wrapper-top-body">
    <blue-loading-spinner showSpinner [id]="ptwSpinnerId"></blue-loading-spinner>
    <div class="push-to-wallet-header">
      <h1>
        {{ 'Push to wallet title' | translate:'push-to-wallet' }}
      </h1>
      <h2 >
        {{ 'Fast and Easy' | translate:'push-to-wallet' }}
      </h2>
    </div>
    <ng-container *ngIf="!pushToWalletData && errors">
      <span class="error-txt" [style.display]="'block'" *ngFor="let error of errors" [attr.data-error-id]="error">
        {{ 'Error-' + error | translate:'push-to-wallet' }}
      </span>
    </ng-container>

    <ng-container *ngIf="genericErrors && genericErrors.length">
      <span class="error-txt" [style.display]="'block'" *ngFor="let error of genericErrors">
        {{ error }}
      </span>
    </ng-container>

    <ng-container *ngIf="pushToWalletData">
      <ng-container [ngSwitch]="ptwStep">
        <booking-ptw-flight-selection [items]="pushToWalletData.items" *ngSwitchCase="PushToWalletStepsEnum.FlightSelect"
          (continue)="selectJourneys($event)"[errors]="errors"
        ></booking-ptw-flight-selection>
        
        <booking-ptw-authenticate *ngSwitchCase="PushToWalletStepsEnum.Login"
          (continue)="loginStatusChanged($event)"
        ></booking-ptw-authenticate>
        
        <booking-ptw-details-form [details]="pushToWalletData.details" *ngSwitchCase="PushToWalletStepsEnum.DetailsForm"
          (continue)="submitDetailsForm($event)" [errors]="errors"
        ></booking-ptw-details-form>

        <booking-ptw-confirmation [confirmed]="confirmedByCode" *ngSwitchCase="PushToWalletStepsEnum.Confirmation" 
          (continue)="submitCode($event)" [errors]="errors"
        ></booking-ptw-confirmation>

      </ng-container>

    </ng-container>
  </div>
</check-in-form-wrapper>