import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookingInfoComponent } from './booking-info/booking-info.component';
import { environment } from '../../environments/environment';
import { CheckinRoutes,BookingRoutes } from './itinerary-routes';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { SharedModule } from '../shared/shared.module';
import { BookingFlightSelectModule } from '../booking-flight-select/booking-flight-select.module';
import { AccomodationComponent } from './accomodation/accomodation.component';
import { CarHireComponent } from './car-hire/car-hire.component';
import { CanActivateItineraryService } from './can-activate-itinerary.service';
import { BlueairProductsComponent } from './blueair-products/blueair-products.component';
import { PtwFlightSelectionComponent } from './push-to-wallet/ptw-flight-selection/ptw-flight-selection.component';
import { PushToWalletContainerComponent } from './push-to-wallet/push-to-wallet-container/push-to-wallet-container.component';
import { PtwDetailsFormComponent } from './push-to-wallet/ptw-details-form/ptw-details-form.component';
import { PtwConfirmationComponent } from './push-to-wallet/ptw-confirmation/ptw-confirmation.component';
import { PtwAuthenticateComponent } from './push-to-wallet/ptw-authenticate/ptw-authenticate.component';


@NgModule({
  imports: [
    CommonModule, 
    FormsModule,
    BlueAirCommonModule,
    SharedModule,
    BookingFlightSelectModule,
    RouterModule
    // RouterModule.forRoot(
    //   environment.flow == 0 ? CheckinRoutes : BookingRoutes
    //  ), 
     
  ],
  
  declarations: [ BookingInfoComponent, AccomodationComponent, CarHireComponent, BlueairProductsComponent, PtwFlightSelectionComponent, PushToWalletContainerComponent, PtwDetailsFormComponent, PtwConfirmationComponent, PtwAuthenticateComponent],
  providers: [CanActivateItineraryService]
})
export class ItineraryModule { }
