/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blueair-products.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./blueair-products.component";
var styles_BlueairProductsComponent = [i0.styles];
var RenderType_BlueairProductsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlueairProductsComponent, data: {} });
export { RenderType_BlueairProductsComponent as RenderType_BlueairProductsComponent };
function View_BlueairProductsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "banner-desktop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[8, "href", 4]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["alt", "Blue Benefits"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(4, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "thankyou.upsellUrl", "rainbow.upsell")), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), "thankyou.upsellBanner.desktopM", "rainbow.upsell")), ""); _ck(_v, 3, 0, currVal_1); }); }
function View_BlueairProductsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "banner-tablet"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[8, "href", 4]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["alt", "Blue Benefits"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(4, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "thankyou.upsellUrl", "rainbow.upsell")), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), "thankyou.upsellBanner.desktopS", "rainbow.upsell")), ""); _ck(_v, 3, 0, currVal_1); }); }
function View_BlueairProductsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "banner-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [], [[8, "href", 4]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "img", [["alt", "Blue Benefits"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(4, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "thankyou.upsellUrl", "rainbow.upsell")), ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), "thankyou.upsellBanner.mobile", "rainbow.upsell")), ""); _ck(_v, 3, 0, currVal_1); }); }
export function View_BlueairProductsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "itineraryBox_benefits_banners"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i4.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueairProductsComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueairProductsComponent_2)), i1.ɵdid(6, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueairProductsComponent_3)), i1.ɵdid(8, 278528, null, 0, i4.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i4.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displaySize; _ck(_v, 2, 0, currVal_0); var currVal_1 = "tablet"; _ck(_v, 6, 0, currVal_1); var currVal_2 = "mobile"; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_BlueairProductsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-blueair-products", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BlueairProductsComponent_0, RenderType_BlueairProductsComponent)), i1.ɵdid(1, 114688, null, 0, i5.BlueairProductsComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlueairProductsComponentNgFactory = i1.ɵccf("app-blueair-products", i5.BlueairProductsComponent, View_BlueairProductsComponent_Host_0, {}, {}, []);
export { BlueairProductsComponentNgFactory as BlueairProductsComponentNgFactory };
