import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot, PRIMARY_OUTLET } from '@angular/router';
import { ProfileService } from '../profile.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private profileService: ProfileService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {

    const parse = this.router.parseUrl(state.url).root.children[PRIMARY_OUTLET];
    const segments =  parse ? parse.segments : [];
    let si = 0;
    let r1 = route;
    let r2 = route;

    while (si < segments.length && r2) {
      r1 = r2;
      r2 = r1.children.find(c => c.routeConfig.path === segments[si].path);
      si++;
    }

    if ((r2 || r1).data.skipAuth === true) {
      return true;
    }

    return this.profileService.refreshProfile().then(p => {
      if (p != null) {
        return true;
      }

      this.router.navigate(['agent', 'authenticate'], { queryParams: { returnUrl: state.url } });
      return false;
    });
  }
}
