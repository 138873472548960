import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { FlightSelectionComponent } from './flight-selection/flight-selection.component';
import { PassengerSelectionComponent } from './passenger-selection/passenger-selection.component';
import { FlightsComponent } from './flights/flights.component';
import { BookingRoutes, CheckinRoutes } from './flights-routes';
import { SharedModule } from '../shared/shared.module';
import { CanActivateFlights } from './can-activate-flights.service';
import { FlightsService } from './flights.service';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BlueAirCommonModule,
    SharedModule,
    // RouterModule.forRoot(
    //   environment.flow === 0 ? CheckinRoutes : BookingRoutes
    // )
  ],
  declarations: [FlightSelectionComponent, PassengerSelectionComponent, FlightsComponent],
  providers: [FlightsService, CanActivateFlights]
})

export class FlightsModule { }
