import * as tslib_1 from "tslib";
import { Constants } from '../../constants';
import * as moment from 'moment';
var FlightModel = /** @class */ (function () {
    function FlightModel() {
        this.isInternational = false;
        this.legs = [];
        this.typeCounter = null;
        this.paxFareByType = {};
        this.passengers = [];
        this.segments = [];
        this.ssrs = {};
        this.paidSsrs = {};
    }
    Object.defineProperty(FlightModel.prototype, "flightUniqueId", {
        get: function () {
            return this.departureStation.iataCode + this.arrivalStation.iataCode;
        },
        enumerable: true,
        configurable: true
    });
    FlightModel.sortFn = function (a, b) {
        return a.departureStation.dateUtc > b.departureStation.dateUtc ? 1 :
            a.departureStation.dateUtc === b.departureStation.dateUtc ? 0 : -1;
    };
    FlightModel.calculateFares = function (journey, ignoreDiscounts, bundleDetails, oldJourneyFares, journeyIndex) {
        var groupedByPaxType = journey.segments
            .map(function (s) { return s.fares; }).reduce(function (a, b) { return a.concat(b); }, [])
            .map(function (f) { return f.paxFares; }).reduce(function (a, b) { return a.concat(b); }, [])
            // group by paxType
            .reduce(function (group, fare) {
            var total = fare.serviceCharges.reduce(function (t, sc) {
                switch (sc.chargeType) {
                    case 'FarePrice':
                    case 'FareSurcharge':
                    case 'ConnectionAdjustmentAmount':
                        t += sc.amount;
                        break;
                    case 'PromotionDiscount':
                        t -= sc.amount;
                        break;
                    case 'Discount':
                        if (!ignoreDiscounts || fare.paxDiscountCode) {
                            t -= sc.amount;
                        }
                        break;
                }
                return t;
            }, 0);
            var oldFareIndex = journeyIndex >= 0 ? journeyIndex : journey.journeySequenceNumber - 1;
            var oldFare = oldJourneyFares && oldJourneyFares[oldFareIndex] ? oldJourneyFares[oldFareIndex].value : 0;
            var groupKey = !fare.paxDiscountCode ? fare.paxType : fare.paxType + "_" + fare.paxDiscountCode;
            group[groupKey] = (group[groupKey] || 0) + (bundleDetails[fare.paxType] || 0) + total - oldFare;
            return group;
        }, {});
        return groupedByPaxType;
    };
    FlightModel.prototype.isFlightInPastOrNearDeparture = function () {
        return this.isInThePast;
    };
    FlightModel.prototype.addPassenger = function (passenger, journey) {
        var _this = this;
        this.passengers.push(passenger);
        this.paidSsrs[passenger.passengerNumber] = [];
        this.ssrs[passenger.passengerNumber] = [];
        var paid = true;
        if (this.type === FlightType.Journey) {
            var groupBy_1 = function (group, ssr) {
                (group[ssr] = group[ssr] || []).push(ssr);
                return group;
            };
            // add ssrs only if not already present on journey
            var fillSsrsForJourney_1 = function (thisProp, seg, isPaid) {
                var _a;
                var list = _this.getSsrs(seg, passenger.passengerNumber, isPaid);
                var myGroup = list.reduce(groupBy_1, {});
                for (var ssrCode in myGroup) {
                    if (myGroup.hasOwnProperty(ssrCode)) {
                        if (_this[thisProp][passenger.passengerNumber].indexOf(ssrCode) < 0) {
                            (_a = _this[thisProp][passenger.passengerNumber]).push.apply(_a, tslib_1.__spread(myGroup[ssrCode]));
                        }
                    }
                }
            };
            journey.segments.forEach(function (seg) {
                fillSsrsForJourney_1('paidSsrs', seg, paid);
                fillSsrsForJourney_1('ssrs', seg, !paid);
            });
        }
        else {
            var segment = journey.segments.find(function (seg) {
                return seg.departureStation === _this.departureStation.iataCode &&
                    seg.arrivalStation === _this.arrivalStation.iataCode;
            });
            this.paidSsrs[passenger.passengerNumber] = this.getSsrs(segment, passenger.passengerNumber, paid);
            this.ssrs[passenger.passengerNumber] = this.getSsrs(segment, passenger.passengerNumber, !paid);
        }
        this.segments.forEach(function (seg) { return seg.addPassenger(passenger, journey); });
    };
    FlightModel.prototype.initFromJourney = function (journey, translateService) {
        this.type = FlightType.Journey;
        this.sellKey = journey.sellKey;
        // use first segment
        this.departureStation = this.getFlightStationInfo(journey.segments[0], true, translateService);
        // use last segment
        this.arrivalStation = this.getFlightStationInfo(journey.segments[journey.segments.length - 1], false, translateService);
        this.arrivalStation.setNextDay(this.departureStation.date);
        this.stops = this.getNumberOfStops(journey);
        this.segments = journey.segments.map(function (segment, index) {
            var segmentModel = new FlightModel();
            segmentModel.initFromSegment(segment, translateService);
            segmentModel.legs = segment.legs;
            return segmentModel;
        });
        this.isInternational = this.segments.findIndex(function (s) { return s.isInternational; }) >= 0;
        return this;
    };
    FlightModel.prototype.initFromSegment = function (segment, translateService) {
        this.type = FlightType.Segment;
        this.sellKey = segment.sellKey;
        this.departureStation = this.getFlightStationInfo(segment, true, translateService);
        this.arrivalStation = this.getFlightStationInfo(segment, false, translateService);
        this.arrivalStation.setNextDay(this.departureStation.date);
        this.stops = 0;
        this.number = segment.flightDesignator !== undefined ? segment.flightDesignator.flightNumber : '';
        this.carrierCode = segment.flightDesignator !== undefined ? segment.flightDesignator.carrierCode : '';
        this.isInternational = !this.isInternational && segment.international;
        // tslint:disable-next-line: max-line-length
        this.referenceKey = this.getFlightReference();
    };
    FlightModel.prototype.calculateFares = function (journey, bundleDetails, oldJourneyFares) {
        var _this = this;
        // TODO add INFANT price
        this.paxFareByType = FlightModel.calculateFares(journey, true, bundleDetails, oldJourneyFares);
        this.totalFareModifyBooking = this.passengers.map(function (p) { return _this.getPassengerFare(p); }).reduce(function (a, b) { return a + b; }, 0);
        this.totalFare = this.totalFareModifyBooking < 0 ? 0 : this.totalFareModifyBooking;
    };
    FlightModel.prototype.calculateInfantFare = function (passengers, isManageBooking) {
        var _this = this;
        var e_1, _a;
        var adtPax = passengers.filter(function (p) { return p.typeInfo.paxType === Constants.AdultPaxType; });
        var singleInfantFare = 0;
        var totalInfantFare = 0;
        if (adtPax.length > 0) {
            try {
                for (var adtPax_1 = tslib_1.__values(adtPax), adtPax_1_1 = adtPax_1.next(); !adtPax_1_1.done; adtPax_1_1 = adtPax_1.next()) {
                    var adult = adtPax_1_1.value;
                    var fee = adult.fees.items.find(function (f) { return f.code === Constants.InfantSsrCode && _this.getFlightReferences().indexOf(f.flightReference) > -1; });
                    if (fee && fee.serviceCharges && fee.serviceCharges.items) {
                        var charge = fee.serviceCharges.items.find(function (sc) { return sc.chargeCode === Constants.InfantSsrCode; });
                        if (charge) {
                            if (singleInfantFare === 0) {
                                singleInfantFare = charge.amount;
                            }
                            totalInfantFare += charge.amount;
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (adtPax_1_1 && !adtPax_1_1.done && (_a = adtPax_1.return)) _a.call(adtPax_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
        this.paxFareByType[Constants.InfantSsrCode] = isManageBooking ? 0 : singleInfantFare;
        this.totalFare += isManageBooking ? 0 : totalInfantFare;
    };
    FlightModel.prototype.getPassengerFare = function (passenger) {
        if (passenger.isInfant) {
            return this.paxFareByType[Constants.InfantSsrCode] || 0;
        }
        return this.paxFareByType[passenger.paxType];
    };
    FlightModel.prototype.setFlightInThePast = function (cancelDepartureTimeLimit) {
        var timeLimit = cancelDepartureTimeLimit.split(':');
        this.isInThePast = moment().add(Number(timeLimit[0]), 'hour')
            .add(Number(timeLimit[1]), 'minute')
            .add(Number(timeLimit[2]), 'seconds')
            .isAfter(moment(this.departureStation.date));
    };
    FlightModel.prototype.getFlightStationInfo = function (segment, isDeparture, translateService) {
        var legIndex = isDeparture ? 0 : segment.legs.length - 1;
        var leg = segment.legs[legIndex];
        var st = new FlightStationInfo(isDeparture ? leg.departureStation : leg.arrivalStation, isDeparture ? leg.sTD : leg.sTA, isDeparture ? leg.legInfo.deptLTV : leg.legInfo.arrvLTV);
        st.name = translateService.instant(st.iataCode, 'station.name');
        return st;
    };
    FlightModel.prototype.getNumberOfStops = function (journey) {
        var count = 0;
        for (var i = 0; i < journey.segments.length; i++) {
            count += journey.segments[i].legs.length;
        }
        return count - 1;
    };
    FlightModel.prototype.getSsrs = function (segment, passengerNumber, paid) {
        if (paid) {
            return segment.paxSSRs
                .filter(function (s) { return s.passengerNumber === passengerNumber && (s.actionStatusCode === 'HK' || s.isInServiceBundle); })
                .map(function (s) { return s.sSRCode; });
        }
        else {
            return segment.paxSSRs
                .filter(function (s) { return s.passengerNumber === passengerNumber && !s.isInServiceBundle && (s.actionStatusCode.match(/.{1,2}/g).includes('KK') || s.actionStatusCode.match(/.{1,2}/g).includes('KU')); })
                .map(function (s) { return s.sSRCode; });
        }
    };
    FlightModel.prototype.getFlightReferences = function () {
        return this.segments.map(function (segment) { return segment.referenceKey; });
    };
    FlightModel.prototype.getFlightReference = function (segment) {
        if (segment === void 0) { segment = this; }
        var flightNumber = ("    " + segment.number).slice(-4);
        // tslint:disable-next-line: max-line-length
        return moment(segment.departureStation.dateUtc).format('YYYYMMDD') + " " + segment.carrierCode.trim() + flightNumber + " " + segment.departureStation.iataCode + segment.arrivalStation.iataCode;
    };
    return FlightModel;
}());
export { FlightModel };
var FlightStationInfo = /** @class */ (function () {
    function FlightStationInfo(iataCode, dateStr, ltv) {
        this.iataCode = iataCode;
        var momentDate = moment(dateStr);
        this.date = momentDate.toDate();
        this.dateUtc = momentDate.subtract(ltv, 'minutes').toDate();
        this.name = this.iataCode;
    }
    FlightStationInfo.prototype.setNextDay = function (comparisonDay) {
        this.nextDay = this.date.getDay() !== comparisonDay.getDay();
    };
    return FlightStationInfo;
}());
export { FlightStationInfo };
export var FlightType;
(function (FlightType) {
    FlightType[FlightType["Journey"] = 0] = "Journey";
    FlightType[FlightType["Segment"] = 1] = "Segment";
})(FlightType || (FlightType = {}));
