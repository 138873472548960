<div class="passenger-selection">
  <div class="checkbox-wrap"
    *ngIf="passengers.length > 1 && ssrType != SsrTypeEnum.PriorityBoarding && ssrType != SsrTypeEnum.Flex && ssrType != SsrTypeEnum.Baggage">
    <input type="checkbox" id="all-passengers-{{uniqueId}}" [ngModel]="allPassengersSelected"
      [disabled]="!allPassengersEnabled" (change)="allPassengersChanged($event)" />
    <label for="all-passengers-{{uniqueId}}">{{ 'All Passengers' | translate: 'shared' }}</label>
  </div>

  <div class="select-passenger-box"
    [ngClass]="{ 'all-passengers-selected': this.selectedPassenger == -1 || passengers.length == 1}">
    <div class="select-passenger-item"
      [ngClass]="{'populated-item' : passengerSsr.ssrs.length, 'selected': passengers.length > 1 && selectedPassenger === i }"
      *ngFor="let passengerSsr of passengers; let i = index;">

      <ng-container [ngSwitch]="ssrType">
        <span *ngSwitchCase="SsrTypeEnum.Baggage" class="icon font-ico-luggage" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.Meal" class="icon font-ico-meal" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.Lounge" class="icon font2-ico-lounge" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.Pet" class="icon font-ico-dog" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.SpecialEquipment" class="icon font-ico-sport-eq" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.SpecialAssistance" class="icon font-ico-spec-assistance" role="presentation">
        </span>

        <!-- <span *ngSwitchCase="SsrTypeEnum.PriorityBoarding" class="icon font-ico-priority-boarding-2bags" role="presentation">
        </span> -->

        <span *ngSwitchCase="SsrTypeEnum.PriorityBag" class="icon font-ico-priority-bag" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.FastTrack" class="icon font-ico-fast-track" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.AirportCheckin" class="icon font-ico-airport-checkin" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.EarlyCheckin" class="icon font-ico-early-checkin" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.BusTransfer" class="icon font-ico-shuttle" role="presentation">
        </span>

        <span *ngSwitchCase="SsrTypeEnum.Flex" class="icon font2-ico-ssrs-flx" role="presentation">
        </span>
      </ng-container>

      <div class="checkin-luggage-options big-header">
        <!-- <ng-container *ngIf="mobileMatches && ssrType == SsrTypeEnum.PriorityBoarding">
          <ng-container *ngTemplateOutlet="ssrsMobileView"></ng-container>
        </ng-container> -->

        <ng-container *ngIf="ssrType == SsrTypeEnum.PriorityBoarding">
          <div class="luggage-left">
            <div class="luggage-table">
              <div class="luggage-row">
                <div class="row-inner">
                  <div class="cell-body first">
                    <span class="cell-label">
                      <check-in-passenger-type-display *ngIf="passengerSsr.passenger.name.first == ''"
                        [cssClass]="'name'" [passenger]="passengerSsr.passenger">
                      </check-in-passenger-type-display>
                      <check-in-passenger-name-display *ngIf="passengerSsr.passenger.name.first != ''" cssClass="name"
                        [passenger]="passengerSsr.passenger"></check-in-passenger-name-display>
                      <!-- <ng-container *ngIf="ssrType == SsrTypeEnum.PriorityBoarding">{{ 'Choose priority boarding' | translate: 'extras' }}</ng-container> -->
                    </span>
                  </div>
                  <div class="cell-body">
                    <input type="radio" [id]="passengerSsr.passenger.passengerUniqueId+'noPriority'"
                      *ngIf="!passengerSsr.passenger.hasPriorityBoardingIncludedInTheBundle"
                      [checked]="passengerSsr.passenger.notHasPriorityBoarding"
                      (click)="selectSinglePassengerPriorityBoardingOption(passengerSsr.passenger.passengerUniqueId, false, $event);" />
                    <label [for]="passengerSsr.passenger.passengerUniqueId+'noPriority'">
                      <div>
                        {{ '1 Small Cabin Bag only' | translate: 'extras' }}
                      </div>
                    </label>
                  </div>
                  <div class="cell-body">
                    <input type="radio" [id]="passengerSsr.passenger.passengerUniqueId+'priority'"
                      [checked]="passengerSsr.passenger.notHasPriorityBoarding != null && !passengerSsr.passenger.notHasPriorityBoarding"
                      (click)="selectSinglePassengerPriorityBoardingOption(passengerSsr.passenger.passengerUniqueId, true, $event);" />
                    <label [for]="passengerSsr.passenger.passengerUniqueId+'priority'"
                      *ngIf="!passengerSsr.passenger.hasPriorityBoardingIncludedInTheBundle">
                      <div>
                        {{ ('Add for ' | translate: 'extras') + (getPriorityBoardingPrice(passengerSsr.passenger) |
                        currencyDisplay) }}
                      </div>
                      <div>
                        {{ 'Per person, per flight' | translate: 'extras' }}
                      </div>
                    </label>

                    <label [for]="passengerSsr.passenger.passengerUniqueId+'priority'"
                      *ngIf="passengerSsr.passenger.hasPriorityBoardingIncludedInTheBundle">
                      <div>
                        {{ 'Included' | translate:'extras' }}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <!-- <check-in-ssr-same-options-for-return-flight [visible]="enableSameOptionsForAllFlights" [(isSelected)]="sameOptionsForAllFlights"></check-in-ssr-same-options-for-return-flight> -->
          </div>
        </ng-container>
      </div>

      <check-in-passenger-type-display
        *ngIf="passengerSsr.passenger.name.first == '' && ssrType != SsrTypeEnum.PriorityBoarding" [cssClass]="'name'"
        [passenger]="passengerSsr.passenger"></check-in-passenger-type-display>
      <check-in-passenger-name-display
        *ngIf="passengerSsr.passenger.name.first != '' && ssrType != SsrTypeEnum.PriorityBoarding" cssClass="name"
        [passenger]="passengerSsr.passenger"></check-in-passenger-name-display>

      <span *ngIf="ssrType != SsrTypeEnum.PriorityBoarding" class="choose-text" [ngSwitch]="ssrType">

        <ng-container *ngSwitchCase="SsrTypeEnum.Baggage">
          <span *ngIf="!checkNoBagsSelected(i)">
            {{ 'Choose luggage' | translate: 'extras' }}
          </span>
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.Meal">
          {{ 'Choose a meal' | translate: 'extras' }}
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.Lounge">
          {{ 'Choose a lounge' | translate: 'extras' }}
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.Pet">
          {{ 'Choose a pet' | translate: 'extras' }}
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.SpecialEquipment">
          {{ 'Choose equipment' | translate: 'extras' }}
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.SpecialAssistance">
          {{ 'Choose special assistance' | translate: 'extras' }}
        </ng-container>

        <!-- <ng-container  *ngSwitchCase="SsrTypeEnum.PriorityBoarding">
          {{ 'Choose priority boarding' | translate: 'extras' }}
        </ng-container> -->

        <ng-container *ngSwitchCase="SsrTypeEnum.PriorityBag">
          {{ 'Choose priority bag' | translate: 'extras' }}
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.FastTrack">
          {{ 'Choose fast track' | translate: 'extras' }}
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.AirportCheckin">
          {{ 'Choose airport checkin' | translate: 'extras' }}
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.EarlyCheckin">
          {{ 'Choose early checkin' | translate: 'extras' }}
        </ng-container>


        <ng-container *ngSwitchCase="SsrTypeEnum.BusTransfer">
          {{ 'Choose bus transfer' | translate: 'extras' }}
        </ng-container>

        <ng-container *ngSwitchCase="SsrTypeEnum.Flex">
          {{ 'Choose flex' | translate: 'extras' }}
        </ng-container>
      </span>

      <ng-container *ngIf="ssrType == SsrTypeEnum.Baggage && checkNoBagsSelected(i)">
        {{ 'No Bags Option Selected' | translate: 'extras' }}
      </ng-container>

      <ng-container *ngFor="let ssr of passengerSsr.ssrs" [ngSwitch]="ssr.ssrCode">

        <span class="selected-item">
          <ng-container *ngIf="ssr.selectedQuantity > 1">
            {{ ssr.selectedQuantity }}&nbsp;x&nbsp;
          </ng-container>

          <ng-container *ngSwitchCase="'PETC'">
            {{ 'Pet in cabin' | translate: 'extras' }}
          </ng-container>

          <ng-container *ngSwitchCase="'AVIH'">
            {{ 'Pet in hold' | translate: 'extras' }}
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container [ngSwitch]="ssrType">
              <ng-container *ngSwitchCase="SsrTypeEnum.Meal">
                {{ ssr.name }}
              </ng-container>

              <ng-container *ngSwitchCase="SsrTypeEnum.Baggage">
                {{ ssr.ssrCode + '-size' | translate: 'extras.bag-size-' + flightCodes }}
              </ng-container>

              <ng-container *ngSwitchDefault>
                {{ ssr.ssrCode + '-title' | translate: 'extras' }}
              </ng-container>
            </ng-container>
          </ng-container>
        </span>
      </ng-container>

      <ng-container [ngSwitch]="ssrType">

        <button type="button" *ngSwitchCase="SsrTypeEnum.Meal" (click)="changeSelectedPassenger(i)" class="choose-btn"
          attr.aria-label="{{ 'Click to choose a meal' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.Lounge" (click)="changeSelectedPassenger(i)" class="choose-btn"
          attr.aria-label="{{ 'Click to choose a lounge' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.Pet" (click)="changeSelectedPassenger(i)" class="choose-btn"
          attr.aria-label="{{ 'Click to choose a pet' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.SpecialEquipment" (click)="changeSelectedPassenger(i)"
          class="choose-btn" attr.aria-label="{{ 'Click to choose equipment' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.SpecialAssistance" (click)="changeSelectedPassenger(i)"
          class="choose-btn" attr.aria-label="{{ 'Click to choose special assistance' | translate: 'extras' }}">
        </button>

        <!-- <button type="button" *ngSwitchCase="SsrTypeEnum.PriorityBoarding" (click)="changeSelectedPassenger(i)" class="choose-btn" attr.aria-label="{{ 'Click to choose priority boarding' | translate: 'extras' }}">
        </button> -->

        <button type="button" *ngSwitchCase="SsrTypeEnum.PriorityBag" (click)="changeSelectedPassenger(i)"
          class="choose-btn" attr.aria-label="{{ 'Click to choose priority bag' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.FastTrack" (click)="changeSelectedPassenger(i)"
          class="choose-btn" attr.aria-label="{{ 'Click to choose fast track' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.AirportCheckin" (click)="changeSelectedPassenger(i)"
          class="choose-btn" attr.aria-label="{{ 'Click to choose airport checkin' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.EarlyCheckin" (click)="changeSelectedPassenger(i)"
          class="choose-btn" attr.aria-label="{{ 'Click to choose early checkin' | translate: 'extras' }}">
        </button>


        <button type="button" *ngSwitchCase="SsrTypeEnum.BusTransfer" (click)="changeSelectedPassenger(i)"
          class="choose-btn" attr.aria-label="{{ 'Click to choose bus transfer' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.Flex" (click)="changeSelectedPassenger(i)" class="choose-btn"
          attr.aria-label="{{ 'Click to choose flex' | translate: 'extras' }}">
        </button>

        <button type="button" *ngSwitchCase="SsrTypeEnum.Baggage" (click)="changeSelectedPassenger(i)"
          class="choose-btn" attr.aria-label="{{ 'Click to choose luggage' | translate: 'extras' }}">
        </button>
      </ng-container>

      <button *ngIf="ssrType != SsrTypeEnum.PriorityBoarding && !disableRemoveButton" type="button"
        class="remove-selected" (click)="removeSsr.next(i); resetSsr.next(i)">
        <span class="font-ico-close-menu">
          <span class="sr-only">
            {{ 'Click to remove selected meal' | translate: 'extras' }}
          </span>
        </span>
      </button>
    </div>
  </div>

  <div class="checkbox-wrap" *ngIf="false && enableSameOptionsForAllFlights">
    <input type="checkbox" id="return-flight-{{uniqueId}}" [ngModel]="sameOptionsForAllFlights"
      (click)="checkSameSsrOptionsForAllFlights()" />
    <label for="return-flight-{{uniqueId}}">{{ 'I choose the same options for return flight' | translate: 'extras'
      }}</label>
  </div>

  <div class="checkbox-wrap" *ngIf="false && passengers.length > 1 && ssrType == SsrTypeEnum.PriorityBoarding">
    <input type="checkbox" id="all-passengers-{{uniqueId}}" [ngModel]="allPassengersSelected"
      (click)="toggleAllPassengersPriorityBoardingSelection()" />
    <label for="all-passengers-{{uniqueId}}">{{ 'Add small cabin bag & trolley bag to all passengers' | translate:
      'shared' }}</label>
  </div>
</div>