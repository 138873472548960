import { BookingFareSelectComponent } from './../booking-fare-select/booking-fare-select.component';
import { OnInit, OnDestroy, QueryList } from '@angular/core';
import { BookingFlightSelectService } from '../booking-flight-select.service';
import { ApplicationFlowEnum, BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { BookingFlowService } from '../../core/booking-flow.service';
import { BookingSelectionService } from '../../core/booking-selection.service';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../../constants';
import { Router } from '@angular/router';
import { BluebenefitsSelectComponent } from '../bluebenefits-select/bluebenefits-select.component';
import { EnvHelper } from 'src/app/env-helper';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
var BookingFlightSelectComponent = /** @class */ (function () {
    function BookingFlightSelectComponent(flightSearch, bookingSteps, bookingFlowService, selectionService, spinnerService, bookingFlightSelectService, translateService, router, ecommerce) {
        var _this = this;
        this.flightSearch = flightSearch;
        this.bookingSteps = bookingSteps;
        this.bookingFlowService = bookingFlowService;
        this.selectionService = selectionService;
        this.spinnerService = spinnerService;
        this.bookingFlightSelectService = bookingFlightSelectService;
        this.translateService = translateService;
        this.router = router;
        this.ecommerce = ecommerce;
        this.autoFareSellTriggers = {};
        this.canContinue = false;
        this.showBlueBenefitsSelection = false;
        this.priceBreakdown = [];
        this.flights = [];
        this.ariaLabel = '';
        this.isCalendarView = false;
        this.notEnoughSelectedFlightsModalId = 'notEnoughSelectedFlightsModalId';
        this.bundleUpgradeModalId = 'bundleUpgradeModalId';
        this.warningModalId = 'warningModalId';
        this.warningFlightDatesModalId = 'warningFlightDatesModalId';
        this.bluebenefitsModalId = 'bluebenefitsModalId';
        this.lockFareEnabled = false;
        this.currentStep = CheckinSteps.flights;
        this.moveFlights = this.flightSearch.isMoveFlightsCriteria();
        this.isMessageOn = false;
        bookingSteps.currentStep.next(this.currentStep);
        this.flightSearch.getShowBlueBenefitsParam();
        this.flightSearchModel = this.flightSearch.searchModel;
        this.selectionFlightsSubscription = this.selectionService.flightsObs.subscribe(function (f) {
            _this.flights = f || [];
        });
    }
    Object.defineProperty(BookingFlightSelectComponent.prototype, "availability", {
        get: function () {
            return this._availability;
        },
        set: function (value) {
            var _this = this;
            this._availability = value;
            if (this._hasTrips) {
                this.autoFareSellTriggers = {};
                this._availability.trips.forEach(function (trip, index) {
                    _this.autoFareSellTriggers[index] = new Subject();
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    BookingFlightSelectComponent.prototype.ngOnDestroy = function () {
        if (this.selectionFlightsSubscription) {
            this.selectionFlightsSubscription.unsubscribe();
        }
    };
    BookingFlightSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
        this.showLockFare = !(EnvHelper.IsOnAgencyPortal());
        this.bookingFlightSelectService.loadFlightsPage().then(function (res) {
            if (res) {
                _this.bookingFlowService.getBooking().then(function (data) {
                    if (data) {
                        _this.isForChangingFlight = data.bookingDetail.recordLocator &&
                            data.bookingDetail.recordLocator !== '' ? true : false;
                    }
                    if (!_this.flightSearchModel || _this.moveFlights) {
                        var searchModelPromise = !_this.moveFlights ? _this.flightSearch.getFlightSearch() : _this.flightSearch.getRememberSearchModel();
                        searchModelPromise.then(function () {
                            _this.flightSearchModel = _this.flightSearch.searchModel;
                            if (_this.flightSearchModel) {
                                _this.callAvailability().then(function () {
                                    if (_this.moveFlights) {
                                        _this.isChangeSearchOpened = true;
                                    }
                                    _this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
                                });
                            }
                            else {
                                _this.bookingSteps.goToStep(CheckinSteps.search);
                            }
                        });
                    }
                    else {
                        _this.callAvailability().then(function () { return _this.spinnerService.hideSpinnerById(Constants.mainSpinnerId); });
                    }
                });
            }
        });
    };
    BookingFlightSelectComponent.prototype.applySelection = function () {
        var _this = this;
        if (this.flightSearch.canContinue === false) {
            this.notEnoughSelectedFlightsModal.openPopup(({}));
        }
        else if (this.bookingSteps.flow === ApplicationFlowEnum.Booking) {
            if (this.flightSearch.bundleCodes[0] === 'ABAS' && this.flightSearch.showBundleUpgrade) {
                this.createAllSsrsObject();
                this.flightSearch.showBundleUpgrade = false;
                this.bundleUpgradeModal.openPopup(function (isOk) {
                    if (isOk) {
                        _this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
                        _this.validateFlights();
                    }
                    else {
                        _this.bookingFareSelectComponents.first.upgradeBundleFromPopup(_this.getBundleUpgradeCode());
                        _this.validateFlights();
                    }
                });
            }
            else {
                this.validateFlights();
            }
        }
        else {
            this.validateFlights();
        }
    };
    BookingFlightSelectComponent.prototype.checkForSpoilageFeeModal = function (journeyFares) {
        var _this = this;
        // if theres a negative fare on the change flight flow -> show spoilage popup
        if (this.isForChangingFlight && journeyFares.findIndex(function (amount) { return amount < 0; }) >= 0) {
            this.spoilageFeeModal.openPopup(function (isOk) {
                // this popup has only 1 option (ok) no need for checking the value
                if (!_this.lockFareEnabled) {
                    _this.bookingFlowService.loadPriceBreakdown(true);
                    _this.bookingSteps.goToNextStepFrom(_this.currentStep);
                }
                else {
                    _this.router.navigate(['passengers', 'lockfare']);
                }
            });
            return;
        }
        if (!this.lockFareEnabled) {
            this.bookingFlowService.loadPriceBreakdown(true);
            this.bookingSteps.goToNextStepFrom(this.currentStep);
        }
        else {
            this.router.navigate(['passengers', 'lockfare']);
        }
    };
    BookingFlightSelectComponent.prototype.validationForModal = function () {
        // if (!moment(this.flightSearchModel.departureDate).isSame(moment(this.flights[0].departureStation.date), 'day')) {
        //   return true;
        // }
        // if (this.flightSearchModel.isRoundTrip) {
        //   if (this.flights.length < 2 ||
        //      !moment(this.flightSearchModel.returnDate).isSame(moment(this.flights[1].departureStation.date), 'day')) {
        //       return true;
        //   }
        // }
        return false;
    };
    BookingFlightSelectComponent.prototype.backToFlightSelection = function () {
        this.showBlueBenefitsSelection = false;
    };
    BookingFlightSelectComponent.prototype.callAvailability = function (tripIndex) {
        var _this = this;
        return this.flightSearch.getFlights().then(function (data) {
            // let a = this.flightSearch.availability;
            _this.availability = _this.flightSearch.availability;
            if (_this.availability.trips)
                _this.fares = _this.availability.trips
                    .map(function (p) { return p.flightDates; }).reduce(function (a, b) { return a.concat(b); }, [])
                    .map(function (p) { return p.flights; }).reduce(function (a, b) { return a.concat(b); }, [])
                    .map(function (p) { return p.fares; }).reduce(function (a, b) { return a.concat(b); }, [])
                    .map(function (p) { return p.defaultIncludedBundleCode; }).filter(function (v, i, a) { return a.indexOf(v) === i; });
            if (tripIndex == undefined) {
                _this.flightSearch.resetPriceItinerary();
                _this.bookingFlowService.updateShoppingCartFromItinerary(null, true);
            }
        });
    };
    BookingFlightSelectComponent.prototype.showCalendar = function () {
        this.isCalendarView = true;
    };
    // toggleSearch(newValue: boolean = !this.isChangeSearchOpened) {
    BookingFlightSelectComponent.prototype.toggleSearch = function () {
        //this.isChangeSearchOpened = newValue;
        this.startOver();
    };
    BookingFlightSelectComponent.prototype.startOver = function () {
        var baseUrl = "https://www.aeroitalia.com";
        // window.location.href = window.origin;
        window.location.href = this.translateService.culture.indexOf("it") != -1 ? "" + baseUrl : baseUrl + "/" + this.translateService.culture.substring(0, 2);
    };
    BookingFlightSelectComponent.prototype.goToNext = function (journeyFares) {
        this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
        if (this.bookingFlightSelectService.isBlueBenefitsSelected && !this.bluebenefitsSelectComponent) {
            this.showBlueBenefitsSelection = true;
        }
        else {
            if (this.bookingFlightSelectService.isBlueBenefitsSelected) {
                if (this.bluebenefitsSelectComponent.selectedBenefitType) {
                    this.bookingFlightSelectService.promoId = this.bluebenefitsSelectComponent.selectedBenefitType;
                    this.sellData(journeyFares);
                }
                else {
                    this.bluebenefitsModal.openPopup(({}));
                }
            }
            else {
                this.sellData(journeyFares);
            }
        }
        this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
    };
    BookingFlightSelectComponent.prototype.sellData = function (journeyFares) {
        var _this = this;
        this.isMessageOn = false;
        (this.moveFlights ? this.flightSearch.sellMovedFlight() : this.flightSearch.sellFare(journeyFares))
            .then(function () { return _this.lockFareEnabled && _this.bookingFlightSelectService.lockFare(_this.flights, _this.flightSearchModel.passengers)
            .catch(function (error) {
            _this.lockFareErrMessage = error.error.errors[0].errorMessage;
            _this.isMessageOn = true;
            return Promise.reject();
        }); })
            .then(function () { return _this.bookingFlowService.loadFlowInfo(true); })
            .then(function () {
            var ecommerceCartItems = [];
            _this.bookingFlowService.loadPriceBreakdown().then(function (breakdown) {
                if (breakdown && breakdown.currentShoppingCart.flights) {
                    ecommerceCartItems = _this.ecommerce.getFlightsForECommerce(breakdown.currentShoppingCart.flights, false, _this.lockFareEnabled);
                    _this.ecommerce.AddToCart(ecommerceCartItems);
                    _this.ecommerce.Checkout(ecommerceCartItems, 1, 'Flights step');
                }
            })
                .then(function () { return _this.checkForSpoilageFeeModal(journeyFares); });
        });
    };
    BookingFlightSelectComponent.prototype.getFlights = function (searchModel) {
        var _this = this;
        this.flightSearch.searchModel = searchModel;
        this.callAvailability().then(function () {
            _this.flightSearchModel = _this.flightSearch.searchModel;
        });
        //this.toggleSearch(false);
    };
    BookingFlightSelectComponent.prototype.sellBlueBenefits = function (isBlueBenefitsSelected) {
        // if roundtrip & selected prices are not the same (all of them with blue benefits or all without)
        // deselect and remove from cart previous selected flight
        if (this.bookingFlightSelectService.selectedFlights.length > 0) {
            this.bookingFareSelectComponents.forEach(function (component) {
                if (component.isBlueBenefitsSelected !== isBlueBenefitsSelected) {
                    //component.emptyCurrentSelection();
                }
            });
        }
    };
    Object.defineProperty(BookingFlightSelectComponent.prototype, "_hasTrips", {
        get: function () {
            return this.availability && Array.isArray(this.availability.trips);
        },
        enumerable: true,
        configurable: true
    });
    BookingFlightSelectComponent.prototype.onFareSoldHandler = function (eventData) {
        if (!this._hasTrips) {
            return;
        }
        for (var i = 0; i < this.availability.trips.length; i++) {
            if (eventData.tripIndex !== i) {
                if (this.autoFareSellTriggers[i]) {
                    this.autoFareSellTriggers[i].next(eventData.isBlueBenefits);
                }
            }
        }
    };
    BookingFlightSelectComponent.prototype.validateFlights = function () {
        var _this = this;
        var outboundFlight;
        var inboundFlight;
        var journeyFares = this.flights.map(function (flight) { return flight.totalFareModifyBooking; });
        if (this.flights.length > 1 && moment(this.flights[0].departureStation.dateUtc).isBefore(this.flights[1].departureStation.dateUtc)) {
            outboundFlight = this.flights[0];
            inboundFlight = this.flights[1];
        }
        else {
            outboundFlight = this.flights[1];
            inboundFlight = this.flights[0];
        }
        if (this.flights.length > 1 && (moment(inboundFlight.departureStation.date).isBefore(moment(outboundFlight.arrivalStation.date)) ||
            moment(inboundFlight.departureStation.date).diff(moment(outboundFlight.arrivalStation.date), 'minute') <= 45)) {
            this.warningFlightDatesModal.openPopup(({}));
        }
        else {
            if (this.validationForModal()) {
                this.warningModal.openPopup(function (isOk) {
                    if (isOk) {
                        _this.goToNext(journeyFares);
                    }
                });
            }
            else {
                this.goToNext(journeyFares);
            }
        }
    };
    BookingFlightSelectComponent.prototype.getCurrentBundleName = function () {
        if (this.flightSearch.bundleCodes[0] === 'ABAS') {
            return 'BASIC';
        }
        else {
            return 'CLASSIC';
        }
    };
    BookingFlightSelectComponent.prototype.getUpgradeBundleName = function () {
        if (this.flightSearch.bundleCodes[0] === 'ABAS') {
            return 'CLASSIC';
        }
        else {
            return 'FLEX';
        }
    };
    BookingFlightSelectComponent.prototype.getBundleUpgradeCode = function () {
        var _this = this;
        var upgradeBundleIndex = this.flightSearch.availability.trips[0].serviceBundleOffers.items.findIndex(function (bundle) { return bundle.serviceBundleCode === _this.flightSearch.bundleCodes[0]; }) + 1;
        return this.flightSearch.availability.trips[0].serviceBundleOffers.items[upgradeBundleIndex].serviceBundleCode;
    };
    BookingFlightSelectComponent.prototype.createAllSsrsObject = function () {
        var _this = this;
        this.allSSRS = [];
        var ssrs = ['STST', 'SCBG', 'PCKN', 'SBAG'];
        var currentBundleSsr = (this.flightSearch.availability.trips[0].serviceBundleOffers.items.find(function (bundle) { return bundle.serviceBundleCode === _this.flightSearch.bundleCodes[0]; })).serviceBundlePriceList[0].serviceBundleSsrPriceList;
        var upgradeBundleIndex = this.flightSearch.availability.trips[0].serviceBundleOffers.items.findIndex(function (bundle) { return bundle.serviceBundleCode === _this.flightSearch.bundleCodes[0]; }) + 1;
        var upgradeBunldeSsr = this.flightSearch.availability.trips[0].serviceBundleOffers.items[upgradeBundleIndex].serviceBundlePriceList[0].serviceBundleSsrPriceList;
        ssrs.forEach(function (ssrCode) {
            var currentHasSsr = false;
            if (currentBundleSsr.find(function (s) { return s.ssrCode === ssrCode; }) && ssrCode != 'FLX') {
                currentHasSsr = true;
            }
            var upgradeHasSsr = false;
            if (upgradeBunldeSsr.find(function (s) { return s.ssrCode === ssrCode; })) {
                upgradeHasSsr = true;
            }
            _this.allSSRS.push({
                ssrCode: ssrCode,
                currentHasSsr: currentHasSsr,
                upgradeHasSsr: upgradeHasSsr
            });
        });
    };
    return BookingFlightSelectComponent;
}());
export { BookingFlightSelectComponent };
