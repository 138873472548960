<li role="menuitem" class="language-selector">
  <a href="#language-popup" (click)="openModal($event)">
    <img src="assets/img/flags/{{selectedLang.flag}}">
    <span>{{ selectedLang.value | uppercase }}</span>
  </a>

  <blue-modal2 [id]="languageModalId" cssClass="">
    <!-- <span class="popup-title">Language</span> -->
    <form method="post" (submit)="apply()">
      <div class="form-row">
        <div class="label">{{ 'Language' | translate: 'profile-info' }}</div>
        <div class="select-list" [ngClass]="{'expanded': selectListExpanded}">
          <span class="overlay" [style.display]="!selectListExpanded  ? 'none' : 'block'"
            (click)="toggleSelectList(false)"></span>
          <span role="button" class="selected" (click)="toggleSelectList()">
            <img src="assets/img/flags/{{newSelectedLang.flag}}">{{newSelectedLang.text}}
          </span>
          <div class="dest-dropdown js-dest-overlay" style="opacity: 1; display: inline-block;"
            *ngIf="selectListExpanded">
            <ul role="menubar" aria-hidden="true" aria-label="Language menu">

              <li role="menuitem" *ngFor="let lang of languages" (click)="selectLang(lang)">
                <span class="primary"><img src="assets/img/flags/{{lang.flag}}">{{lang.text}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="form-row">
        <button type="submit" class="btn btn-primary btn-blue full-width">{{ 'Apply' | translate:'profile-info' }}</button>
      </div>
    </form>
  </blue-modal2>
</li>