<div #contactBox class="pax-container" [ngClass]="{'lockfare-contact-details': isLockedFare}" ngForm="passengerDocsForm"
    #passengerDocsForm="ngForm">
    <div class="pax-contact_title">
        {{'Contact Details' | translate:'passengers'}}
    </div>
    <div *ngIf="!isLockedFare else lockFareSubtitle" class="pax-container_subtitle">
        {{'We need your contact details for booking confirmation' | translate:'passengers'}}
    </div>
    <ng-template #lockFareSubtitle>
        {{'Lock-fare-subtitle' | translate:'lock-fare'}}
    </ng-template>

    <div class="checkbox-holder" *ngIf="!isLockedFare && !isRestricted && !isCanceledBookingStatus"
        style="display: inline;">
        <!-- <button type="checkbox" (click)="sameAsFirstPax($event)">Same as first pax</button> -->
        <input type="checkbox" id="same-as" [(ngModel)]="check" [ngModelOptions]="{standalone: true}"
            (ngModelChange)="firstPaxChanged()" />
        <label for="same-as" innerHtml="{{ 'Same as first pax' | translate:'documents' }}"></label>
    </div>

    &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

    <!-- <div class="checkbox-holder" *ngIf="!isLockedFare" style="display: inline;"> -->
    <!-- <button type="checkbox" (click)="sameAsFirstPax($event)">Same as first pax</button> -->
    <!-- <input type="checkbox" id="same-ass" />
    <label for="same-ass" innerHtml="{{ 'Same as second passenger' | translate:'documents' }}"></label>
  </div> -->

    <div class="checkbox-holder" *ngIf="isAgent">
        <input type="checkbox" id="agentData" [(ngModel)]="agentCheck" [ngModelOptions]="{standalone: true}"
            (ngModelChange)="completeAgentData()" />
        <label for="agentData" innerHtml="{{ 'Complete with agent data' | translate:'documents' }}"></label>
    </div>

    <div class="pax-container_row" *ngIf="!isLockedFare">
        <!-- <div class="pax-container_2-col">
        <div class="field-inner">
            <label for="title-contact">{{ 'Title' | translate:'documents' }}</label>
            <blue-select-list [(ngModel)]="contact.name.title" (ngModelChange)="contactChanged()" [items]="titleItems"
                [enableSearch]="false"
                required placeholder="{{ 'Select title' | translate:'documents' }}" [my-error]="titleContact.dirty" name="titleContact" #titleContact="ngModel"></blue-select-list>
        </div>
    </div> -->
    </div>
    <div class="pax-container_row">
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label for="firstName-contact">{{ 'First Name' | translate:'documents' }}</label>
                <input type="text" [disabled]="isRestricted || isCanceledBookingStatus" class="first-name"
                    id="firstName-contact" [(ngModel)]="contact.name.first" (ngModelChange)="contactChanged()"
                    name="firstNameContact" [my-error]="firstNameContact.dirty"
                    patternError="{{ 'Only letters' | translate:'documents' }}" required
                    placeholder="{{ 'e.g. Ionescu' | translate:'documents' }}" pattern="{{namePattern}}" maxlength="32"
                    #firstNameContact="ngModel" />
            </div>
        </div>
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label for="lastName-contact">{{ 'Last Name' | translate:'documents' }}</label>
                <input type="text" [disabled]="isRestricted || isCanceledBookingStatus" class="last-name"
                    id="last-contact" [(ngModel)]="contact.name.last" (ngModelChange)="contactChanged()"
                    name="lastNameContact" [my-error]="lastNameContact.dirty"
                    patternError="{{ 'Only letters' | translate:'documents' }}" required
                    placeholder="{{ 'e.g. Ion' | translate:'documents' }}" [pattern]="namePattern" maxlength="32"
                    #lastNameContact="ngModel" />

            </div>
        </div>
    </div>
    <div class="pax-container_row" *ngIf="!isLockedFare">
        <!-- <div class="pax-container_2-col">
        <div class="field-inner">
            <label for="address1-contact">{{ 'Address Line 1' | translate:'documents' }}</label>
            <input type="text" class="address-line1-name" id="address-line1-contact"
            [(ngModel)]="contact.addressLine1" name="addressLine1" [my-error]="addressLine1.dirty" patternError="{{ 'Field required' | translate:'documents' }}" required placeholder="{{ 'Address' | translate:'documents' }}" [pattern]="addressPattern" maxlength="52" #addressLine1="ngModel" />
        </div>
    </div>
    <div class="pax-container_2-col">
        <div class="field-inner">
            <label for="address2-contact">{{ 'Address Line 2' | translate:'documents' }}</label>
            <input type="text" class="address-line2-name" id="address-line2-contact"
            [(ngModel)]="contact.addressLine2" name="addressLine2" placeholder="{{ 'Address' | translate:'documents' }}" maxlength="52" />
        </div>
    </div> -->
    </div>
    <div class="pax-container_row" *ngIf="!isLockedFare">
        <!-- <div class="pax-container_2-col">
        <div class="field-inner">
            <label for="city-contact">{{ 'City' | translate:'documents' }}</label>
            <input type="text" class="contact-city" id="city-contact"
            [(ngModel)]="contact.city" name="city-contact" [my-error]="cityContact.dirty" required placeholder="{{ 'City' | translate:'documents' }}" maxlength="32" #cityContact="ngModel" />
        </div>
    </div> -->
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label for="phone-contact">{{ 'Phone Number' | translate:'documents' }}</label>
                <input type="tel" [disabled]="isRestricted || isCanceledBookingStatus" class="contact-homephone"
                    id="phone-contact" [(ngModel)]="contact.homePhone" name="phone-contact"
                    [my-error]="phoneContact.dirty" patternError="{{ 'Invalid phone number' | translate:'documents' }}"
                    required placeholder="{{ 'Phone Number' | translate:'documents' }}" pattern="{{phonePattern}}"
                    #phoneContact="ngModel" />
            </div>
        </div>
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label>{{ 'Country of residence' | translate:'documents' }}</label>
                <blue-select-list [disabled]="isRestricted || isCanceledBookingStatus" [(ngModel)]="contact.countryCode"
                    [my-error]="issueCountry.dirty" [items]="countries"
                    [enableSearch]="(!isRestricted && !isCanceledBookingStatus)" required
                    placeholder="{{ 'Select country' | translate:'documents' }}" name="issueCountry"
                    #issueCountry="ngModel"></blue-select-list>
            </div>
        </div>
    </div>
    <div class="pax-container_row">
        <!-- <div class="pax-container_2-col" *ngIf="!isLockedFare">
        <div class="field-inner">
            <label for="alter-phone-contact">{{ 'Alternative Emergency Phone Number' | translate:'documents' }}</label>
            <input type="tel" class="contact-otherPhone" id="alter-phone-contact"
            [(ngModel)]="contact.otherPhone" name="otherPhone-contact" [my-error]="alterPhoneContact.dirty" patternError="{{ 'Invalid phone number' | translate:'documents' }}" required placeholder="{{ 'Alternative Emergency Phone Number' | translate:'documents' }}" pattern="{{phonePattern}}" #alterPhoneContact="ngModel"/>
        </div>
    </div> -->
    </div>
    <div class="pax-container_row">
        <!-- <div class="pax-container_2-col" *ngIf="!isLockedFare">
        <div class="field-inner">
            <label for="postcod-contact">{{ 'Postcode' | translate:'documents' }}</label>
            <input type="text" class="contact-postcod" id="postcod-contact"
            [(ngModel)]="contact.postalCode" name="postcod-contact" [my-error]="postcodContact.dirty" [required]="isPostCodeRequired" placeholder="{{ 'Postcode' | translate:'documents' }}" maxlength="10" #postcodContact="ngModel"/>
        </div>
    </div> -->
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label for="email-contact">{{ 'Email address' | translate:'documents' }}</label>
                <input type="email" [disabled]="isRestricted || isCanceledBookingStatus" class="email-city"
                    id="email-contact" [(ngModel)]="contact.emailAddress" name="email-contact"
                    [my-error]="emailContact.dirty" patternError="{{ 'Invalid email address' | translate:'documents' }}"
                    required placeholder="{{ 'Email address' | translate:'documents' }}" pattern="{{emailPattern}}"
                    maxlength="266" #emailContact="ngModel" />
            </div>
        </div>
    </div>
    <div class="checkbox-holder">
        <div *ngIf="false">
            <input type="checkbox" id="subscribeNewsletter" [(ngModel)]="contact.subscribedToNewsletter" />
            <label for="subscribeNewsletter"
                innerHtml="{{ 'Subscribe to newsletter' | translate: 'passengers' }}"></label>
        </div>

        <div *ngIf="false">
            <input type="checkbox" name="agreeTermsAndCond" id="agreeTermsAndCond"
                [(ngModel)]="contact.agreeTermsAndCond" required #agreeTermsAndCond="ngModel" />
            <label for="agreeTermsAndCond"
                [ngClass]="{ 'text-error': (agreeTermsAndCond.dirty && agreeTermsAndCond.invalid) }"
                innerHtml="{{ 'Privacy-policy-agree-text' | translate: 'passengers' }}"></label>
            <div *ngIf="agreeTermsAndCond.dirty && agreeTermsAndCond.invalid" class="validation-error">
                {{ 'Field required' | translate: 'documents' }}
            </div>
            <!-- <label [ngClass]="{ 'text-error': isFieldRequired }" for="agreetermsandcond"
                innerHtml="{{ 'Privacy-policy-agree-text' | translate: 'passengers' }}"></label>

             -->

            <p innerHtml="{{ 'Privacy-policy-text' | translate: 'passengers' }}"></p>
        </div>
    </div>
</div>