import { Component, OnInit, ViewChild } from '@angular/core';
import { ProfileInfoBox } from '../../profile-info-box';
import { FormControl, FormGroup, NgForm, Validators, ValidatorFn } from '@angular/forms';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ProfileService } from 'src/app/core/profile.service';
import { ConfigService } from 'src/app/core/config.service';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import { ResetPasswordFormComponent } from '../../reset-password-form/reset-password-form.component';
import { tap, catchError } from 'rxjs/operators';

@Component({
  selector: 'profile-in-agent-settings',
  templateUrl: './agent-settings.component.html',
  styleUrls: ['./agent-settings.component.scss']
})
export class AgentSettingsComponent extends ProfileInfoBox implements OnInit {
  editModalId = 'profile-settings';
  passwordChangeModalId = 'profile-settings-password';

  route: string;
  passwordChangeRoute: string;
  profileSetings: any = {};
  model: any = {};
  errors: any[] = [];

  passwordChangeErrors: string[] = [];
  passwordChangePolicy: string;
  passwordChangePolicyRegex: string;

  @ViewChild('changePasswordForm', { static: true }) changePasswordForm: ResetPasswordFormComponent;

  constructor(modalService: BlueModalService, private profileService: ProfileService, private configService: ConfigService,
    private translate: TranslateService) {
    super(modalService);
    this.route = this.configService.SaveProfileInfoUrl;
    this.passwordChangeRoute = this.configService.ResetPasswordUrl;

    profileService.profile.subscribe((p: any) => {
      if (!p) {
        return;
      }

      this.profileSetings.agentId = p.agentId;
      this.profileSetings.username = p.username;
      this.profileSetings.organizationCode = p.identifier.organizationCode;

      this.passwordChangePolicyRegex = p.domain.passwordPolicy;
      this.passwordChangePolicy = p.domain.passwordPolicyText;
    });
  }

  ngOnInit() {
  }

  edit() { }
  save() { }

  openPasswordModal(event) {
    event.preventDefault();
    this.changePasswordForm.resetForm();
    this.openModal(this.passwordChangeModalId);
  }

  savePassword(passwordChangeModel: any) {
    this.profileService.resetPassword(this.profileSetings.agentId, this.profileSetings.username,
      passwordChangeModel.oldPassword, passwordChangeModel.newPassword, passwordChangeModel.newPasswordConf)
      .subscribe(result => {
        if (result.loginOk) {
          this.closeModal(this.passwordChangeModalId);
        } else {
          this.passwordChangeErrors = result.errors;
        }
      });
  }

  cancelPassword() {
    this.closeModal(this.passwordChangeModalId);
  }
}
