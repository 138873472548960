/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ssr-price-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/currency-display.pipe";
import * as i3 from "../../core/flow-manager.service";
import * as i4 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i5 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./ssr-price-display.component";
var styles_SsrPriceDisplayComponent = [i0.styles];
var RenderType_SsrPriceDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SsrPriceDisplayComponent, data: {} });
export { RenderType_SsrPriceDisplayComponent as RenderType_SsrPriceDisplayComponent };
function View_SsrPriceDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "price"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\u00A0", "\n"])), i1.ɵppd(2, 1), i1.ɵppd(3, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.currentPrice)); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 1), "each", "extras")); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_SsrPriceDisplayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "promo-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "promo-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "promo-text-old"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "\u00A0", ""])), i1.ɵppd(4, 1), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["class", "promo-text-new"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", "\u00A0", ""])), i1.ɵppd(8, 1), i1.ɵppd(9, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _co.originalPrice)); var currVal_1 = i1.ɵunv(_v, 3, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 1), "each", "extras")); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _co.currentPrice)); var currVal_3 = i1.ɵunv(_v, 7, 1, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 1), "each", "extras")); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
export function View_SsrPriceDisplayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyDisplayPipe, [i3.FlowManagerService, i4.TranslateService]), i1.ɵpid(0, i5.TranslatePipe, [i4.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsrPriceDisplayComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["promotionAvailable", 2]], null, 0, null, View_SsrPriceDisplayComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.originalPrice; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_SsrPriceDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-ssr-price-display", [], null, null, null, View_SsrPriceDisplayComponent_0, RenderType_SsrPriceDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i7.SsrPriceDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SsrPriceDisplayComponentNgFactory = i1.ɵccf("check-in-ssr-price-display", i7.SsrPriceDisplayComponent, View_SsrPriceDisplayComponent_Host_0, { originalPrice: "originalPrice", currentPrice: "currentPrice" }, {}, []);
export { SsrPriceDisplayComponentNgFactory as SsrPriceDisplayComponentNgFactory };
