import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'profile-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {

  constructor() { }

  @Input() customErrorText: boolean;
  @Input() reference: NgModel;
  @Input() isVisible = false;

  ngOnInit() {
  }

}
