<li role="menuitem" class="profile-menu-wrap">
    <a routerLink="/agent/authenticate" class="login-btn" *ngIf="!profile">{{'Login' | translate: 'profile-info'}}</a>

    <ng-container *ngIf="profile">
        <a href="#" class="login-btn logged-in" (click)="toggleMenu($event)">
            {{ 'Hi, {0} {1}' | translate:'profile-info':profile.name.first:profile.name.last }}</a>
        <div>{{organizationName}}</div>
        <div>{{ 'Amount available' | translate:'extras' }}: {{ amountAvailable | currency: amountAvailableCurrency }}
        </div>
        <ul role="menubar" class="profile-menu" [style.display]="menuExpanded ? 'block': ''">

            <li role="menuitem" (click)="toggleMenu()"><a routerLink="/">{{ 'Search flight' |
                    translate:'profile-info' }}</a></li>
            <li role="menuitem" (click)="toggleMenu()"><a routerLink="/agent/info">{{ 'Agent profile' |
                    translate:'profile-info' }}</a></li>
            <li role="menuitem" (click)="toggleMenu()"><a routerLink="/agent/bookings">Bookings</a></li>

            <li role="menuitem" class="logout" (click)="toggleMenu()">
                <a routerLink="/agent/logout">{{'Log out' | translate: 'profile-info'}}</a>
            </li>

            <li role="menuitem" class="close-arrow" (click)="toggleMenu()">
                <a role="button" href="#" (click)="preventDefault($event)">
                    <span class="sr-only">{{'Close' | translate: 'profile-info'}}</span>
                </a>
            </li>
        </ul>
    </ng-container>
</li>