import * as tslib_1 from "tslib";
import { Logger } from './logger';
var noop = function () { return undefined; };
var ɵ0 = noop;
var ConsoleLoggerService = /** @class */ (function (_super) {
    tslib_1.__extends(ConsoleLoggerService, _super);
    function ConsoleLoggerService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ConsoleLoggerService.prototype, "info", {
        get: function () {
            if (this.isDebugMode) {
                return console.info.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLoggerService.prototype, "warn", {
        get: function () {
            if (this.isDebugMode) {
                return console.warn.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLoggerService.prototype, "error", {
        get: function () {
            if (this.isDebugMode) {
                return console.error.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConsoleLoggerService.prototype, "log", {
        get: function () {
            if (this.isDebugMode) {
                return console.log.bind(console);
            }
            else {
                return noop;
            }
        },
        enumerable: true,
        configurable: true
    });
    ConsoleLoggerService.prototype.invokeConsoleMethod = function (type, args) {
        var logFn = (console)[type] || console.log || noop;
        logFn.apply(console, [args]);
    };
    return ConsoleLoggerService;
}(Logger));
export { ConsoleLoggerService };
export { ɵ0 };
