import { Component, OnInit, Input, SimpleChanges, SimpleChange, OnChanges } from '@angular/core';
import { SsrsService } from 'src/app/extras/ssrs.service';

@Component({
  selector: 'check-in-accordion-item',
  templateUrl: './checkin-accordion-item.component.html',
  styleUrls: ['./checkin-accordion-item.component.scss']
})
export class CheckinAccordionItemComponent implements OnInit, OnChanges {
  @Input() ariaLabel: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() price: string;
  @Input() disabledText: string;
  @Input() disabled: boolean;
  @Input() blockExpanded: boolean;
  @Input() isExpanded: boolean = false;

  constructor(private ssrsService: SsrsService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    const disabledChange = changes.disabled as SimpleChange;
    if (disabledChange && disabledChange.currentValue === false) {
      this.isExpanded = false;
    }

    if((changes.blockExpanded && this.blockExpanded) || this.ssrsService.isLuggageStep) {
      this.isExpanded = true;
    }
  }

  toggleExpanded() {
    if (this.blockExpanded) {
      return;
    }
    this.isExpanded = !this.isExpanded;
  }
}
