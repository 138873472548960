<section class="section">
  <div class="wrapper">
    <!-- find & filter agent's bookings -->
    <check-in-filter-bookings (bookingDataEventEmmiter)="bookingsFiltered($event)" (errorsEventEmitter)="setErrors($event)">      
    </check-in-filter-bookings>

    <!-- display errors -->
    <div class="errorSection" *ngFor="let error of errors">
      <div>
        {{ error.ErrorMessage }}
      </div>
    </div>
        
    <!-- display agent's bookings -->
    <span *ngIf="bookingData.length == 0">
      <div>
        {{ 'No bookings to display!' | translate: 'find-my-bookings' }}
      </div>
        
      <div>
        {{ 'If you have a PNR issued on GDS, please' | translate: 'find-my-bookings' }}
        <a href={{dynamicBookingStatusUrl}} target="_blank">
          {{ 'click here' | translate: 'find-my-bookings' }}
        </a>
      </div>
    </span>
    <check-in-display-bookings [bookingData]="bookingData">
    </check-in-display-bookings>
  </div>
</section>