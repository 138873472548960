import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { CheckinService } from '../../core/checkin.service';
import { ConfigService } from '../../core/config.service';

@Component({
  selector: 'check-in-print-boarding-pass',
  templateUrl: './print-boarding-pass.component.html',
  styleUrls: ['./print-boarding-pass.component.scss']
})
export class PrintBoardingPassComponent implements OnInit {
  boardingPassData: string;
  printUrl = '/documents/print';

  @ViewChild('boardingPassForm', { static: true }) boardingPassForm: ElementRef;

  private maxBag: string[] = ['BAG', '1FB', 'ABAG', 'BAG1', 'VBAG', 'GBAG'];
  private smallBag: string[] = ['SBAG', 'QBAG', 'AZBG'];
  private xsBag: string[] = ['XSBG'];
  private format: string;

  constructor(
    private configService: ConfigService,
    private checkinService: CheckinService,
    private translateService: TranslateService,
    private renderer: Renderer2) {
    this.printUrl = this.configService.config.printUrl;
  }

  ngOnInit() {
  }

  print(passengerNumber: number = null, flightSellKey: string = null) {
    const pageId = this.openNewWindow();

    this.checkinService.loadBoardingPass(passengerNumber, flightSellKey)
      .then(boardingPasses => {
        this.prepareBoardingPassData(boardingPasses);
        this._prepareCultureData(this.translateService.culture);
        this.submitForm(pageId);
      });
  }

  printBoardingPass(boardingPass: any) {
    this.prepareBoardingPassData(boardingPass);
    this._prepareCultureData(this.translateService.culture);
    this.submitForm(this.openNewWindow());
  }

  private _prepareCultureData(culture: string) {
    this.boardingPassForm.nativeElement.elements[1].value = culture;
  }

  private GetOperatedByText(segment: any): string {
    try {
      return segment.legs[0].operatedByText;
    }
    catch {
      return "";
    }
  }

  private prepareBoardingPassData(boardingPasses: any[]) {
    const dataArray: any[] = [];



    boardingPasses.forEach(boardingPass => {
      boardingPass.segments.forEach(seg => {
        const data: any = {
          FirstName: boardingPass.isoName.first,
          LastName: boardingPass.isoName.last,
          Title: this.GetOperatedByText(seg),
          DateOfBirth: boardingPass.dateOfBirth,
          RecordLocator: boardingPass.recordLocator,
          Nationality: boardingPass.nationality,
          TravelDocumentExpirationDate: boardingPass.documentExpirationDate,
          TravelDocumentNumber: boardingPass.documentNumber,
          TravelDocumentType: boardingPass.documentType,
          IsInfant: boardingPass.isInfant
        };

        if (boardingPass.isoInfantName.first.length >= 0) {
          data.InfantName = boardingPass.isoInfantName.first + ' ' + boardingPass.isoInfantName.last;
        }

        data.IsInfant = this.isInfantPax(data.DateOfBirth);
        data.Departure = seg.departureStationName;
        data.Arrival = seg.arrivalStationName;
        data.DepartureIata = seg.inventoryLegKey.departureStation;
        data.ArrivalIata = seg.inventoryLegKey.arrivalStation;
        data.DepartureDate = seg.departureTime;
        data.DepartureHourAndMinute = this._extractDepartureHourAndMinute(seg.departureTime);
        data.ArrivalDate = seg.arrivalTime;
        data.BarcodeData = seg.barcodes[0].barcodeData;
        data.Seat = seg.legs.map(p => p.seatInfo);
        data.BoardingDate = seg.departureTime;
        data.FlightNumber = seg.inventoryLegKey.carrierCode + seg.inventoryLegKey.flightNumber;
        data.Sequence = seg.legs.map(p => p.boardingSequence);

        if (data.IsInfant) {
          data.XsBagCount = 0;
          data.SmallBagCount = 0;
          data.BigCabinBag = 0;
          data.BagCount = 0;
          data.PriorityBoarding = 0;
          data.Ssrs = "";
        }
        else {

          data.XsBagCount = seg.legs.map(p => p.ssrs).reduce((a, b) => a.concat(b), []).filter(p => this.xsBag.indexOf(p.ssrCode) >= 0).length;
          data.SmallBagCount = seg.legs.map(p => p.ssrs).reduce((a, b) => a.concat(b), []).filter(p => this.smallBag.indexOf(p.ssrCode) >= 0).length;
          data.BigCabinBag = this._hasSsrs(seg, ['BCBG', 'PBRD']);
          data.BagCount = seg.legs.map(p => p.ssrs).reduce((a, b) => a.concat(b), []).filter(p => this.maxBag.indexOf(p.ssrCode) >= 0).length;
          data.PriorityBoarding = 1;
          data.Ssrs = seg.legs.map(p => p.ssrs).reduce((a, b) => a.concat(b), []).map(x => x.ssrCode).join(',');
        }

        dataArray.push(data);
      });
    });

    this.boardingPassForm.nativeElement.elements[0].value = JSON.stringify(dataArray);
  }

  isInfantPax(dob: any) {
    let paxDOB = new Date(dob);
    let today = moment().format(this.format);
    let interval = moment(today, this.format).subtract(2, "years").toDate();

    if (paxDOB >= interval) {
      return true;
    }

    return false;
  }

  private _extractDepartureHourAndMinute(departureTime: string): string {
    return departureTime.split(' ')[1].split(':').slice(0, 2).join(':');
  }

  private _hasSsrs(segment, ssrs: string[]) {
    for (let l = 0; l < segment.legs.length; l++) {
      const leg = segment.legs[l];
      for (let s = 0; s < leg.ssrs.length; s++) {
        const ssrCode = leg.ssrs[s].ssrCode;
        if (ssrs.includes(ssrCode)) {
          return 1;
        }
      }
    }
    return 0;
  }

  private openNewWindow(): string {
    const pageId = 'BoardingPass' + Math.floor(Math.random() * 1000) + 1;
    const page = window.open('about:blank', pageId);
    return pageId;
  }

  private submitForm(pageId: string) {
    this.renderer.setAttribute(this.boardingPassForm.nativeElement, 'target', pageId);
    this.boardingPassForm.nativeElement.submit();
  }
}
