<check-in-selections-clear-warning-modal #changeFlightsWarningModal></check-in-selections-clear-warning-modal>

<check-in-default-modal #subscribeTermsModal modalId="subscribeTermsModal"
                        modalTitle="{{'Privacy-policy-warning-title' | translate:'passengers' }}"
                        okButtonText="{{'Privacy-policy-warning-dismiss-btn' | translate:'passengers' }}"
                        cancelButtonText="{{'Privacy-policy-warning-agree-btn' | translate: 'passengers'}}" 
                        [okRequired]="true"
                        [cancelRequired]="true">
    <div innerHTML="{{'Privacy-policy-warning-content' | translate: 'passengers':['Privacy-policy-warning-agree-btn' | translate: 'passengers']}}">
    </div>
</check-in-default-modal>

<check-in-default-modal #samePassengersNameWarningModal modalId="samePassengersNameModalId"
                        modalTitle="{{'Passengers are not allowed to have the same first name and last name with others' | translate: 'passengers'}}"
                        modalText="{{'Two or more passengers have the same title,first name and last name!' | translate: 'passengers'}}"
                        okButtonText="{{'Ok' | translate: 'passengers'}}"
                        [okRequired]="true"
                        [cancelRequired]="false">
                        <ul class="pending-list">
                        <li *ngFor="let pax of duplicates">
                        <span>{{pax.name.title + ' ' + pax.name.first + ' ' + pax.name.last}} </span>
                        </li>
                        </ul>
</check-in-default-modal>

<check-in-form-wrapper  [step]="'step-5'" (continue)="goToNextStep()" [showPriceDisplay]="true">
    <div attr.aria-label="{{ 'Passengers details' | translate:'passengers' }}" >
      <h2 class="pageTitle">{{'Passenger Details'|translate}}</h2>
      <span class="error-txt" [style.display]="submitted && invalidForm ? 'block' : ''">
        {{ 'Please fill in all fields for this passenger' | translate:'documents' }}
      </span>
  </div>
  <div class="form-wrapper-top-body">
    <check-in-pax-contact-details [(ngModel)]="contact" [isLockedFare]="true" [titleItems]="titleItems"></check-in-pax-contact-details>

    <div class="lockfare-pax-wrap">
        <check-in-accordion>
            <check-in-accordion-item  title="{{passengers[i].displayName}}" subtitle="{{ 'lockfare-pax-details' | translate:'lock-fare' }}" *ngFor="let pax of passengers; let i = index">
            <check-in-pax-details
            [(ngModel)]="passengers[i]"
            [flights]="flights"
            [dateRanges]="dateRanges"
            [titleItems]="titleItems"
            [countries]="countries"
            [genderItems]="genderItems"
            [isLockedFare]="passengers[i].isLockedFare"></check-in-pax-details>
          </check-in-accordion-item>
          <ng-container *ngIf="infants">
            <check-in-accordion-item  title="{{infants[i].displayName}}" subtitle="{{ 'lockfare-pax-details' | translate:'lock-fare' }}" *ngFor="let inft of infants; let i = index" >
                <check-in-pax-details
                [(ngModel)]="infants[i]"
                [isInfant]="true"
                [flights]="flights"
                [dateRanges]="dateRanges"
                [titleItems]="titleItems"
                [countries]="countries"
                [genderItems]="genderItems"
                [isLockedFare]="infants[i].isLockedFare"></check-in-pax-details>
          </check-in-accordion-item>
          </ng-container>
        </check-in-accordion>
    </div>
  </div>
</check-in-form-wrapper>


		