<div class="mobile-cart-total" [ngClass]="{'open' : isExpanded['current']}">
  <button type="button" class="mobile-cart-toggle-btn" (click)="toggleExpanded('current')"  >
    <span class="checkin-total-label">{{ 'Your cart' | translate:'price-display' }}</span>
    <span class="checkin-total-value"><span attr.aria-label="{{total | currencyDisplay}}">{{ total | currencyDisplay }}</span></span>
    
  </button>
  <button type="button" class="mobile-cart-submit-btn" (click)="continue.next()">
  {{ 'Continue' | translate:'price-display' }}</button>
</div>

<div class="checkin-cart mobile-navigation" [ngClass]="{'open' : isExpanded['current']}" *ngIf="breakdown?.originalShoppingCart">
  <div class="toggleCart-wrap">
    <button class="btn btn-big btn-toggle-cart" [ngClass]="{'selected' : isExpanded['original']}" (click)="toggleExpanded('original')">
      {{ 'Original Itinerary' | translate: 'price-display' }}
    </button>
    <button class="btn btn-big btn-toggle-cart" [ngClass]="{'selected' : !isExpanded['original']}" (click)="toggleExpanded('original')">
      {{ 'New Itinerary' | translate: 'price-display' }}
    </button>
  </div>
</div>
<div class="checkin-cart multi-cart expandable" *ngIf="breakdown?.originalShoppingCart && !isRestricted" [style.display]="isExpanded['current'] ? 'block' : ''"  [ngClass]="{ 'open' : isExpanded['original']}" >
    <h3 (click)="toggleExpanded('original')">{{ 'Original Itinerary' | translate: 'price-display' }}</h3>

    <div class="cart-flight" *ngIf="isExpanded['original']" >
        <shopping-cart-flights [flights]="breakdown?.originalShoppingCart.flights"></shopping-cart-flights>
        <shopping-cart-extras [input]="breakdown?.originalShoppingCart.extras"></shopping-cart-extras>
    </div>

    <div class="checkin-cart-footer multi-cart-show-footer" >
      <div class="checkin-total">
        <span class="checkin-total-label">{{ 'Total' | translate:'price-display' }}</span>
        <span class="checkin-total-value" attr.aria-label="{{'Total {0}'|translate:'price-display':[total | currencyDisplay]}}">
          {{breakdown?.currentShoppingCart.balanceDue | currencyDisplay }}
        </span>
      </div>
    </div>
</div>

<div class="checkin-cart" [style.display]="isExpanded['current'] && !isExpanded['original'] ? 'block' : ''" [ngClass]="{ 'multi-cart' : breakdown && breakdown.originalShoppingCart, 'multi-cart-hide': isExpanded['original'] }">
  <blue-loading-spinner [activationRoute]="route"></blue-loading-spinner>

  <h3>{{ 'Your cart' | translate: 'price-display' }}</h3>
  <div class="cart-flight">
    <shopping-cart-flights [flights]="breakdown?.currentShoppingCart.flights" [titleUnknown]="flightTitleUnknown" *ngIf="!isRestricted"></shopping-cart-flights>
    <shopping-cart-extras [input]="breakdown?.currentShoppingCart.extras" *ngIf="showExtras"></shopping-cart-extras>
  </div>

  <div class="cart-flight terms">
    <ng-content select="termsAndConditions"></ng-content>
  </div>
  <ng-content></ng-content>

  <div class="checkin-cart-footer">
    <div class="checkin-total">
      <span class="checkin-total-label">{{ 'Total' | translate:'price-display' }}</span>
      <span class="checkin-total-value" attr.aria-label="{{'Total {0}'|translate:'price-display':[ breakdown?.currentShoppingCart.flights.length  ? (breakdown?.currentShoppingCart.balanceDue | currencyDisplay) : ( breakdown?.currentShoppingCart.balanceDue | cultureFormatNumber:'1.2-2' )]}}">
        {{ breakdown?.currentShoppingCart.flights.length  ? (breakdown?.currentShoppingCart.balanceDue | currencyDisplay) : ( breakdown?.currentShoppingCart.balanceDue | cultureFormatNumber:'1.2-2' ) }}
      </span>
    </div>
    <button type="button" class="btn btn-primary" attr.aria-label="{{'Click to continue'|translate}}" (click)="continue.next()">
      {{ 'Continue' | translate:'price-display' }}
    </button>
  </div>

  <div class="cart-flight promotion">
    <ng-content select="promo"></ng-content>
  </div>

  <booking-promo-code></booking-promo-code>
</div>
