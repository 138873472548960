import { CorporateheaderComponent } from './corporateheader.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { BlueAirCommonModule } from 'src/app/common-modules/blue-air-common/blue-air-common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    BlueAirCommonModule
  ],
  declarations: [CorporateheaderComponent],
  providers: []
})

export class CorporateheaderModule { }
