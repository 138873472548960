import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import { PaymentMethodModel } from './payment-method-model';
import { PaymentRequestDetails } from './payment-request-details';
import { LoadingSpinnerService } from '../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../constants';
import { SessionService } from '../common-modules/blue-air-common/session.service';
import { environment } from '../../environments/environment';
import { FlowManagerService } from '../core/flow-manager.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InvoicingData, InvoicingDataValidation } from './invoicing/models/invoicing.resources';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { AeroInvoicingData, AeroInvoicingDataValidation } from './invoicing-aero/models/invoicing-aero.resources';

@Injectable()
export class PaymentsService {
  paymentMethods: PaymentMethodModel[] = [];
  selectedPaymentMethod: string;
  hasAppliedPayment: boolean;
  paymentError: string;
  paymentOk: boolean;

  constructor(private http: HttpClient, private configService: ConfigService, private flowManager: FlowManagerService,
    private sessionService: SessionService, private loadingSpinnerService: LoadingSpinnerService,
    private translateService: TranslateService) {
  }

  shouldRedirectToPaymentPortal(): boolean {
    return environment.usePaymentPortal && this.configService.PaymentPortalUrl ? true : false;
  }

  loadPaymentMethods(bookingFlow: string):
    Observable<{
      paymentMethods: PaymentMethodModel[],
      invoicingEnabled: boolean,
      invoicingRequested: boolean
    }> {
    const params = new HttpParams()
      .set('bf', bookingFlow)
      .set('ts', new Date().getTime().toString());

    return this.http.get(this.configService.PaymentsManagerUrl, { params: params })
      .pipe(map((response: any) => {
        this.paymentMethods = response.paymentsManager.paymentMethods.map(item => {
          const x = new PaymentMethodModel();
          x.code = item.code;
          x.group = item.group;
          x.name = item.name;
          x.details = item.details;
          return x;
        });
        return {
          paymentMethods: this.paymentMethods,
          invoicingEnabled: response.paymentsManager.invoicingEnabled ? true : false,
          invoicingRequested: response.paymentsManager.invoicingRequested ? true : false
        };
      }));
  }

  /** Select payment method
   * @returns redirect url
   */
  selectPaymentMethod(paymentMethodCode: string, walletPaymentAmount: number,
    successUrl: string, cancelUrl: string, errorUrl: string, invoicingData: AeroInvoicingData): Observable<SelectPaymentMethodResponse> {
    const obj: any = {
      selectedPaymentMethod: paymentMethodCode,
      culture: this.translateService.culture,
      cancelUrl: cancelUrl,
      errorUrl: errorUrl,
      successUrl: successUrl,
      invoicingData: invoicingData || new AeroInvoicingData()
    };

    this.selectedPaymentMethod = paymentMethodCode;

    if (walletPaymentAmount) {
      obj.walletPaymentAmount = (walletPaymentAmount * 100).toFixed(0);
    }

    return this.http.post(this.configService.PaymentsManagerUrl, {
      paymentsManager: obj
    }).pipe(map((response: any) => {
      if (!response.paymentsManager.isSuccessful && !response.paymentsManager.errorCode && response.errors.length) {
        response.paymentsManager.errorMessage = response.errors[0].errorMessage;
      }
      return response.paymentsManager;
    }));
  }

  applyPayment(): Promise<boolean> {
    return this.http.patch(this.configService.PaymentsManagerUrl, {
      paymentsManagerExecutor: {
        applyPayment: true
      }
    }).pipe(
      map((response: any) => {
        this.paymentError = response.paymentsManagerExecutor.isSuccessful ?
          null : response.paymentsManagerExecutor.errorCode;
        return this.paymentError ? false : true;
      })
    ).toPromise();
  }

  loadInvoiceInfo(): Observable<any> {
    return this.http.get(this.configService.InvoicingInfoUrl);
  }

  validateInvoicingInfo(invoicingData: InvoicingData): Observable<AeroInvoicingDataValidation> {

    const vatRegistrationNo = (invoicingData.INVVATREGNO || '').startsWith(invoicingData.INVCOUNTRYCODE) ?
      invoicingData.INVVATREGNO.substring(2) :
      invoicingData.INVVATREGNO;
    return this.http.post(this.configService.InvoicingInfoUrl, {
      onlineInvoice: {
        invoicingValidationData: {
          'fiscalRepresentative': invoicingData.INVFISCALREPRESENTATIVE,
          'customerCountryCode': invoicingData.INVCOUNTRYGROUP,
          'countryAbbreviation': invoicingData.INVCOUNTRYCODE,
          'customerType': invoicingData.INVCUSTOMERTYPE === '1' ? 'company' : 'person',
          'taxNo': invoicingData.INVTAXNO,
          'vatRegistrationNo': vatRegistrationNo,
          'socialSecurityNo': invoicingData.INVSECURITYNO,
          'emailAddress': invoicingData.INVINVOICINGEMAIL,
          'lastName': invoicingData.INVLASTNAME,
          'firstName': invoicingData.INVFIRSTNAME,
          'dateOfBirth': invoicingData.INVDATEOFBIRTH,
          'placeOfBirth': invoicingData.INVPLACEOFBIRTH,
          'sex': invoicingData.INVSEX
        }
      }
    }).pipe(
      map((response: any) => response.onlineInvoice.validationResult),
    );
  }

  // getPaymentInfo(paymentMethod: PaymentMethodModel): Promise<PaymentRequestDetails> {
  //   const params = new HttpParams()
  //     .set('paymentType', paymentMethod.code)
  //     .set('type', paymentMethod.type)
  //     .set('feeCode', paymentMethod.feeCode);
  //   return this.http
  //     .post(this.configService.PaymentMethodInfoUrl, {}, { params: params })
  //     .toPromise()
  //     .then((response: any) => {
  //       const paymentRequestDetails = new PaymentRequestDetails(response);
  //       return paymentRequestDetails;
  //     });
  // }

  // beginPayment(paymentMethod: PaymentMethodModel): void {
  //   const params = new HttpParams()
  //     .set('paymentType', paymentMethod.code)
  //     .set('type', paymentMethod.type)
  //     .set('feeCode', paymentMethod.feeCode);
  //   this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
  //   const newUrl = `${this.configService.PaymentBeginUrl}/${this.sessionService.sessionId}?${params.toString()}`;
  //   window.location.href = newUrl;
  // }

  hasBalanceDue(forceRefresh?: boolean): Promise<boolean> {
    return this.flowManager.applicationFlowService.loadPriceBreakdown(forceRefresh).then(() => {
      return this.flowManager.applicationFlowService.shoppingCartBreakdown.value.currentShoppingCart.balanceDue > 0;
    });
  }

  getPaymentAccounts(paymentType: string, currency: string): Promise<any> {
    const params = new HttpParams().set('type', paymentType).set('bc', currency);
    return this.http.get(this.configService.PaymentAccountsUrl, { params: params }).toPromise();
  }

}

interface SelectPaymentMethodResponse {
  isSuccessful: boolean;
  errorCode: string;
  errorMessage?: string;
  redirectUrl: string;
}
function of(arg0: InvoicingDataValidation): Observable<InvoicingDataValidation> {
  throw new Error('Function not implemented.');
}

