import { SsrType } from '../../../extras/ssr-type.enum';
var ShoppingCartExtrasSsrItemModel = /** @class */ (function () {
    function ShoppingCartExtrasSsrItemModel() {
    }
    Object.defineProperty(ShoppingCartExtrasSsrItemModel.prototype, "totalAmount", {
        get: function () { return this._totalAmount; },
        set: function (value) { this._totalAmount = Math.max(value, 0); },
        enumerable: true,
        configurable: true
    });
    ShoppingCartExtrasSsrItemModel.prototype.init = function (extrasItem) {
        this.ssrCode = extrasItem.ssrCode;
        if (this.ssrCode) {
            this.ssrCode = this.ssrCode.trim();
        }
        this.quantity = extrasItem.quantity;
        this.amount = extrasItem.amount;
        this.totalAmount = this.quantity * this.amount;
        return this;
    };
    return ShoppingCartExtrasSsrItemModel;
}());
export { ShoppingCartExtrasSsrItemModel };
var ShoppingCartExtrasPassengerModel = /** @class */ (function () {
    function ShoppingCartExtrasPassengerModel() {
    }
    ShoppingCartExtrasPassengerModel.prototype.init = function (extrasPassenger) {
        this.passengerNumber = extrasPassenger.paxNumber;
        this.passengerType = extrasPassenger.paxTypeCode;
        var groupBySsrCodeAndPrice = extrasPassenger.items.reduce(function (group, item) {
            var key = item.ssrCode + "_" + item.amount;
            if (group[key]) {
                group[key].quantity += item.quantity;
                group[key].totalAmount += item.quantity * item.amount;
            }
            else {
                group[key] = new ShoppingCartExtrasSsrItemModel().init(item);
            }
            return group;
        }, {});
        this.items = [];
        for (var key in groupBySsrCodeAndPrice) {
            if (groupBySsrCodeAndPrice.hasOwnProperty(key)) {
                this.items.push(groupBySsrCodeAndPrice[key]);
            }
        }
        return this;
    };
    return ShoppingCartExtrasPassengerModel;
}());
export { ShoppingCartExtrasPassengerModel };
var ShoppingCartExtrasFlightModel = /** @class */ (function () {
    function ShoppingCartExtrasFlightModel() {
        this.segments = [];
        this.passengers = [];
        this.hasItems = false;
    }
    Object.defineProperty(ShoppingCartExtrasFlightModel.prototype, "amount", {
        get: function () { return this._amount; },
        set: function (value) { this._amount = Math.max(value, 0); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartExtrasFlightModel.prototype, "journeyPassengers", {
        get: function () {
            var firstSegment = this.segments.find(function (s) { return s.passengers.length > 0; });
            return firstSegment ? firstSegment.passengers : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartExtrasFlightModel.prototype, "isJourney", {
        get: function () {
            return this.segments.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartExtrasFlightModel.prototype, "isSegment", {
        get: function () {
            return this.segments.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    ShoppingCartExtrasFlightModel.prototype.init = function (extrasJourneyOrSegment) {
        this.departureStation = extrasJourneyOrSegment.departureStation;
        this.arrivalStation = extrasJourneyOrSegment.arrivalStation;
        this.amount = extrasJourneyOrSegment.totalAmount;
        this.segments = extrasJourneyOrSegment.segments && extrasJourneyOrSegment.segments.length ?
            extrasJourneyOrSegment.segments.map(function (s) { return new ShoppingCartExtrasFlightModel().init(s); }) :
            [];
        this.passengers = extrasJourneyOrSegment.passengers && extrasJourneyOrSegment.passengers.length ?
            extrasJourneyOrSegment.passengers.map(function (p) { return new ShoppingCartExtrasPassengerModel().init(p); }) :
            [];
        this.passengers = this.passengers.sort2(function (p) { return p.passengerNumber; });
        if (this.segments.length) {
            this.hasItems = this.segments.some(function (s) { return s.hasItems; });
        }
        else {
            this.hasItems = this.passengers.length > 0;
        }
        return this;
    };
    ShoppingCartExtrasFlightModel.prototype.initJourneyFromSegments = function (segments, initStations) {
        if (initStations === void 0) { initStations = false; }
        if (initStations) {
            this.departureStation = segments[0].departureStation;
            this.arrivalStation = segments[segments.length - 1].arrivalStation;
        }
        this.amount = segments.sum(function (s) { return s.amount; });
        this.hasItems = segments.some(function (s) { return s.hasItems; });
    };
    ShoppingCartExtrasFlightModel.prototype.initSegmentsFromPassengers = function (passengers) {
        this.amount = passengers.sum(function (p) { return p.items.sum(function (i) { return i.totalAmount; }); });
        this.hasItems = passengers.length > 0;
    };
    return ShoppingCartExtrasFlightModel;
}());
export { ShoppingCartExtrasFlightModel };
var ShoppingCartExtrasModel = /** @class */ (function () {
    function ShoppingCartExtrasModel() {
        this.flights = [];
        this.flightsWithItems = [];
    }
    Object.defineProperty(ShoppingCartExtrasModel.prototype, "amount", {
        get: function () { return this._amount; },
        set: function (value) { this._amount = Math.max(value, 0); },
        enumerable: true,
        configurable: true
    });
    ShoppingCartExtrasModel.prototype.init = function (shoppingCartExtras) {
        this.ssrType = this.parseSsrType(shoppingCartExtras.ssrTypeCode);
        this.flights = shoppingCartExtras.journeys.map(function (j) { return new ShoppingCartExtrasFlightModel().init(j); });
        this.initFromFlights(this.flights);
        return this;
    };
    ShoppingCartExtrasModel.prototype.initFromFlights = function (flights) {
        this.amount = this.flights.sum(function (f) { return f.amount; });
        this.flightsWithItems = this.flights.filter(function (f) { return f.hasItems; });
    };
    ShoppingCartExtrasModel.prototype.parseSsrType = function (initialSsrType) {
        switch (initialSsrType) {
            case 'ExtraLegRoom':
                return SsrType.Seat;
            case 'OTHER':
            case 'Others':
                return SsrType.Other;
            case 'ASIG':
                return SsrType.Insurance;
            default:
                var parsed = SsrType[initialSsrType];
                if (parsed) {
                    return parsed;
                }
                return SsrType.None;
        }
    };
    return ShoppingCartExtrasModel;
}());
export { ShoppingCartExtrasModel };
