import * as tslib_1 from "tslib";
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { OnInit, OnDestroy, QueryList } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { SsrsService } from '../ssrs.service';
import { SsrBoxComponent } from '../ssr-box/ssr-box.component';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { BookingFlowService } from 'src/app/core/booking-flow.service';
import { ApplicationFlowEnum, BookingStepsService } from 'src/app/core/booking-steps.service';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import * as moment from 'moment';
var SsrsComponent = /** @class */ (function () {
    function SsrsComponent(flowManager, ssrsService, bookingFlowService, appFlowSteps, resourcesService) {
        this.flowManager = flowManager;
        this.ssrsService = ssrsService;
        this.bookingFlowService = bookingFlowService;
        this.appFlowSteps = appFlowSteps;
        this.resourcesService = resourcesService;
        this.SsrTypeEnum = SsrType;
        this.flights = [];
        this.journeys = [];
        this.paxSegments = [];
        this.hasEarlyCheckin = false;
        if (this.appFlowSteps.flow === ApplicationFlowEnum.Booking) {
            this.isBookingFlow = true;
        }
    }
    SsrsComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var flexInactivePeriods, now;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.flightsSubscription = this.flowManager.selectionService.flightsObs.subscribe(function (flights) {
                            if (flights) {
                                _this.flights = flights;
                                _this.removeCanceledFlights();
                            }
                        });
                        this.bookingFlowService.loadFlowInfo().then(function (booking) {
                            if (booking &&
                                booking.convertedJourneys &&
                                booking.convertedJourneys.journeys &&
                                booking.convertedJourneys.journeys.length > 0) {
                                _this.journeys = booking.convertedJourneys.journeys;
                            }
                            if (booking &&
                                booking.bundleDetail &&
                                booking.bundleDetail.bundleCodes.find(function (el) { return el.find(function (item) { return item.value == "ABAS"; }); }) != undefined) {
                                _this.hasEarlyCheckin = true;
                            }
                        });
                        return [4 /*yield*/, this.resourcesService.getFlexInactivePeriodAsync()];
                    case 1:
                        flexInactivePeriods = _a.sent();
                        now = moment();
                        this.isInactiveFlex = flexInactivePeriods.some(function (p) { return now.isSameOrAfter(moment.utc(p.flexPeriodStartDate, 'YYYY-MM-DD HH:mm'))
                            && now.isSameOrBefore(moment.utc(p.flexPeriodEndDate, 'YYYY-MM-DD HH:mm')); });
                        return [2 /*return*/];
                }
            });
        });
    };
    SsrsComponent.prototype.ngOnDestroy = function () {
        if (this.flightsSubscription) {
            this.flightsSubscription.unsubscribe();
        }
    };
    SsrsComponent.prototype.getPendingSsrs = function () {
        this.ssrBoxes.forEach(function (b) { return b.applySsrs(); });
        return this.ssrsService.getPendingSsrs();
    };
    SsrsComponent.prototype.getComponentModals = function () {
        return this.ssrBoxes.filter(function (x) { return x.validationModalComponent
            && x.validationModalComponent.modalComponents.length > 0; })
            .map(function (x) { return x.validationModalComponent; });
    };
    SsrsComponent.prototype.selectMeal = function () {
        this.isMealSelected = true;
        this.isPetSelected = false;
        this.isSpecialEquipmentSelected = false;
        this.isSpecialAssistanceSelected = false;
    };
    SsrsComponent.prototype.selectPet = function () {
        this.isMealSelected = false;
        this.isPetSelected = true;
        this.isSpecialEquipmentSelected = false;
        this.isSpecialAssistanceSelected = false;
        this.checkPassengerLiftStatus();
    };
    SsrsComponent.prototype.selectSpecialEquipment = function () {
        this.isMealSelected = false;
        this.isPetSelected = false;
        this.isSpecialEquipmentSelected = true;
        this.isSpecialAssistanceSelected = false;
        this.checkPassengerLiftStatus();
    };
    SsrsComponent.prototype.selectSpecialAssistance = function () {
        this.isMealSelected = false;
        this.isPetSelected = false;
        this.isSpecialEquipmentSelected = false;
        this.isSpecialAssistanceSelected = true;
        this.checkPassengerLiftStatus();
    };
    SsrsComponent.prototype.checkPassengerLiftStatus = function () {
        var e_1, _a, e_2, _b;
        try {
            for (var _c = tslib_1.__values(this.journeys), _d = _c.next(); !_d.done; _d = _c.next()) {
                var j = _d.value;
                if (j.sellKey == this.flights[0].sellKey &&
                    j.segments &&
                    j.segments.length > 0 &&
                    j.segments[0].paxSegments &&
                    j.segments[0].paxSegments.length > 0) {
                    this.paxSegments = j.segments[0].paxSegments;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        try {
            for (var _e = tslib_1.__values(this.paxSegments), _f = _e.next(); !_f.done; _f = _e.next()) {
                var p = _f.value;
                if (p.liftStatus === "CheckedIn") {
                    this.isUnavailableDueToPreviouslyCheckedIn = true;
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    SsrsComponent.prototype.removeCanceledFlights = function () {
        var e_3, _a, e_4, _b;
        var flightsDepartureStations = [];
        for (var f = 0; f < this.flights.length; f++) {
            try {
                for (var _c = tslib_1.__values(this.flights[f].segments), _d = _c.next(); !_d.done; _d = _c.next()) {
                    var s = _d.value;
                    try {
                        for (var _e = tslib_1.__values(s.legs), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var l = _f.value;
                            if (l.legInfo.status === "Canceled") {
                                flightsDepartureStations.push(this.flights[f].departureStation.name);
                            }
                        }
                    }
                    catch (e_4_1) { e_4 = { error: e_4_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_4) throw e_4.error; }
                    }
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
        if (flightsDepartureStations.length > 0) {
            for (var f = 0; f < this.flights.length; f++) {
                for (var i = 0; i < flightsDepartureStations.length; i++) {
                    if (this.flights[f].departureStation.name === flightsDepartureStations[i]) {
                        this.flights.splice(f, 1);
                    }
                }
            }
        }
    };
    return SsrsComponent;
}());
export { SsrsComponent };
