import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { SeatFilterEnum } from '../seatmap.models';
import { BehaviorSubject } from 'rxjs';
import { SeatMapService } from '../seat-map.service';

@Component({
  selector: 'check-in-seat-map-legend',
  templateUrl: './seat-map-legend.component.html',
  styleUrls: ['./seat-map-legend.component.scss']
})
export class SeatMapLegendComponent implements OnInit {
    seatApplyEnforced:number[] = [];
 

  @Input() seatMap: any;

  @Input() 
    set seatFilterRestriction(p){
      this.seatApplyEnforced = p;
    }

  ngOnChanges(){
   // this.parseSeatMap(this.seatMap)
  
  }
  @Input()
  set selectPassenger(p){
    this.appliedFilters = [];

  }
  @Output() selectFilter:EventEmitter<any> = new EventEmitter<any>();
  
  priceGroups:any;

  constructor(private seatmapService:SeatMapService) { 

    this.appliedFilters = seatmapService.appliedFilters;
  }

  ngOnInit() {
  }

   appliedFilters :Array<number> =[];


  filterClick(f){
    let filters = this.seatmapService.appliedFilters;

    if(this.seatApplyEnforced.indexOf(f)>=0){
      return;
    }
   
    if(filters.indexOf(f) >=0){
      filters.splice(this.appliedFilters.indexOf(f), 1);
    }
    else
    {
      filters.push(f);
    }
    let currentFilteredMap = {map:this.seatMap.sellKey, filter:f}
    //check if can apply filter
    this.selectFilter.next(currentFilteredMap);
  }
 
}
