import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, skip } from 'rxjs/operators';

declare global {
  interface Window { appInsights: any; dataLayer: any; }
}

@Injectable()
export class UserActivityService implements OnDestroy {

  private navigationStartSubscription: Subscription;
  private navigationEndSubscription: Subscription;

  private applicationInsights: any = {
    trackPageView: (name?: string, url?: string,) => console.log('pageview', url),
    startTrackPage: (name: string) => console.log('start pageview', name),
    stopTrackPage: (name?: string, url?: string) => console.log('end pageview', name, url),
    trackException: (errorObj: any) => { }
  };

  constructor(private router: Router, private location: Location) {
    if (window.appInsights) {
      this.applicationInsights = window.appInsights;
    }
  }

  ngOnDestroy() {
    if (this.navigationStartSubscription) {
      this.navigationStartSubscription.unsubscribe();
    }
    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
    }
  }

  init() {

    if (this.navigationStartSubscription) {
      this.navigationStartSubscription.unsubscribe();
    }
    this.navigationStartSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      skip(1))
      .subscribe((event: NavigationStart) => this.startTrackPage(event.url));

    if (this.navigationEndSubscription) {
      this.navigationEndSubscription.unsubscribe();
    }
    this.navigationEndSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      skip(1))
      .subscribe((event: NavigationEnd) => {
        this.stopTrackPage(event.url, event.url);
      });
  }

  trackPageView(url: string) {
    this.applicationInsights.trackPageView(undefined, window.location.origin + this.location.prepareExternalUrl(url));
  }

  startTrackPage(name: string) {
    this.applicationInsights.startTrackPage(name);
  }

  stopTrackPage(name: string, url: string) {
    this.applicationInsights.stopTrackPage(name, window.location.origin + this.location.prepareExternalUrl(url));
  }

  trackException(error: Error): void {
    this.applicationInsights.trackException(error);
  }

  trackTrace(message: string, serverity: 'Verbose' | 'Information' | 'Warning' | 'Error' | 'Critical',
    properties?: { [key: string]: string }) {
    let newSeverity = 1;
    switch (serverity) {
      case 'Verbose':
        newSeverity = 0;
        break;
      case 'Information':
        newSeverity = 1;
        break;
      case 'Warning':
        newSeverity = 2;
        break;
      case 'Error':
        newSeverity = 3;
        break;
      case 'Critical':
        newSeverity = 4;
        break;
    }

    this.applicationInsights.trackTrace(
      message,
      properties || {},
      newSeverity
    );
  }

  pushToDatalayer(dataLayerItem: any) {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(dataLayerItem);
  }
}
