import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, map, catchError, tap, distinctUntilChanged } from 'rxjs/operators';
import { DateRange } from 'src/app/common-modules/blue-air-common/date-range.model';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import { BookingService } from 'src/app/core/booking.service';
import { ConfigService } from 'src/app/core/config.service';
import { PaymentsService } from '../payments.service';
import { AeroCustomerTypes, AeroInvoiceCountry, AeroInvoiceCountyRegion, AeroInvoiceFieldNames, AeroInvoicingData, AeroInvoicingDataValidation, IKeyValuePair } from './models/invoicing-aero.resources';
import { AeroInvoiceField, AeroInvoiceRule, AeroInvoiceRules } from './models/invoicing-aero.rules';
import { AeroInvoicingValidators } from './models/invoicing-aero.validators';
import { isNullOrUndefined, isNumber } from 'util';
import { REGIONS_WITH_CITIES } from './models/regions-with-cities.data';

const ITALY_CODE: string = "IT";
const NO_COUNTRY: string = "NO_COUNTRY";
const NON_ITALY_COUNTRY_CODE: string = NO_COUNTRY;

@Component({
  selector: 'invoicing-aero',
  templateUrl: './invoicing-aero.component.html',
  styleUrls: ['./invoicing-aero.component.scss']
})
export class AeroInvoicingComponent implements OnInit, OnDestroy {

  private sub: Subject<boolean> = new Subject();

  @Output() goBack: EventEmitter<any> = new EventEmitter();
  @Output() skipInvoicing: EventEmitter<any> = new EventEmitter();

  invoiceInfoUrl: string;
  countries: AeroInvoiceCountry[] = [];
  regions: AeroInvoiceCountyRegion[] = [];
  citiesForRegion: IKeyValuePair<string, string>[] = [];
  defaultDateRange: DateRange;

  formGroup: FormGroup = new FormGroup({})
  // formFields: AeroInvoiceField[];

  customerTypes: { text: string, value: AeroCustomerTypes }[] = [
    { text: 'IT_Company', value: AeroCustomerTypes.ItCompany },
    { text: 'IT_IndividualPerson', value: AeroCustomerTypes.ItIndividualPerson },
    { text: 'NON_IT_Customer', value: AeroCustomerTypes.NonItCustomer }
  ];


  invoicingData: AeroInvoicingData = <AeroInvoicingData>{};

  invoicingDataValidation: AeroInvoicingDataValidation;
  dataLoaded: boolean = false;
  submitted: boolean;
  private bookingContact: any = null;

  constructor(
    private fb: FormBuilder,
    private paymentsService: PaymentsService,
    private configService: ConfigService,
    private translateService: TranslateService,
    private bookingService: BookingService) {

    this.defaultDateRange = new DateRange(null);
    this.defaultDateRange.maximumDate = moment();
    this.defaultDateRange.generateYearsForAge(1, 120);

    this.invoiceInfoUrl = this.configService.InvoicingInfoUrl;
    //this.formGroup = new FormGroup({});
    //this.initForm2();
  }

  get CountryFormControl() {
    return this.formGroup.get(AeroInvoiceFieldNames.CountryCode) as FormControl;
  }

  get CustomerTypeFormControl() {
    return this.formGroup.get(AeroInvoiceFieldNames.CustomerType) as FormControl;
  }

  get regionsFormControl() {
    return this.formGroup.get(AeroInvoiceFieldNames.Region) as FormControl;
  }

  get cityCodeFormControl() {
    return this.formGroup.get(AeroInvoiceFieldNames.HeadquarterCity) as FormControl;
  }

  get isItalianCompany(): boolean {
    return this.CustomerTypeFormControl.value == AeroCustomerTypes.ItCompany;
  }

  get isItalianIndividual(): boolean {
    return this.CustomerTypeFormControl.value == AeroCustomerTypes.ItIndividualPerson;
  }
  get isNonItCustomer(): boolean {
    return this.CustomerTypeFormControl.value == AeroCustomerTypes.NonItCustomer;
  }

  get invoiceFormControls() {
    return this.formGroup.controls;
  }

  get defaultEmailAddress() {
    if (!this.bookingContact)
      return null;
    return this.bookingContact.emailAddress;
  }


  ngOnInit() {
    this.customerTypes = this.customerTypes.map(item => {
      return { ...item, text: this.translateService.instant(item.text, 'invoicing') };
    });
    // load data needed for the form
    this
      .paymentsService
      .loadInvoiceInfo()
      .pipe(
        takeUntil(this.sub)
      )
      .subscribe((invoiceInfo: any) => {
        const countriesList = <AeroInvoiceCountry[]>invoiceInfo.onlineInvoice.countries;
        this.countries = [];
        for (const country of countriesList) {
          if (country.code.toLowerCase() == "it") continue;
          const translated = this.translateService.instant(country.code, 'country.name');
          if (translated !== country.code) {
            country.name = translated;
          }
          this.countries.push(country);
        }

        this.countries.sort((a, b) => a.name.localeCompare(b.name));
        this.countries.unshift(<AeroInvoiceCountry>{
          code: NO_COUNTRY,
          name: this.translateService.instant('Please select country', 'country.name')
        });

        this.regions = REGIONS_WITH_CITIES.map(item => {
          return <AeroInvoiceCountyRegion>{
            name: item.code,
            code: item.code
          }
        });
        this.invoicingData = new AeroInvoicingData();

        // if (invoiceInfo.onlineInvoice.invoicingData) {
        //   for (const invoicingKey in invoiceInfo.onlineInvoice.invoicingData) {
        //     if (invoiceInfo.onlineInvoice.invoicingData.hasOwnProperty(invoicingKey)) {
        //       this.invoicingData[invoicingKey.toUpperCase()] = invoiceInfo.onlineInvoice.invoicingData[invoicingKey];
        //     }
        //   }

        // }
        this.initForm2();
        this.dataLoaded = true;

      });
  }

  private initForm2() {

    this.bookingService.refresh().then(
      booking => {
        this.bookingContact = booking.contact;
        this.formGroup = this.fb.group({
          customerType: [AeroCustomerTypes.ItCompany, Validators.required],
          companyName: ['', Validators.required],
          lastName: [],
          fiscalCode: [null, []],
          vat: [null],
          recipientCode: ['', [AeroInvoicingValidators.alphaNumericFix(7)]],
          emailPec: [this.defaultEmailAddress, [AeroInvoicingValidators.emailAddress()]],
          countryCode: [ITALY_CODE, AeroInvoicingValidators.customRequired(NO_COUNTRY)],
          addressNumber: [],
          headquarterAddress: ['', [Validators.required]],
          headquarterZipCode: ['', [Validators.required, AeroInvoicingValidators.headquarterZipCode()]],
          region: [],
          headquarterCity: [null, [Validators.required]]
        }, {
          validators: [this.oneOfRecipientCodeOrEmailPecRequired, this.oneOfVatOrFiscalCodeRequired, this.fiscalPersonValidator],
          //updateOn: 'blur'
        });

        this.regionsFormControl.valueChanges
          .pipe(
            takeUntil(this.sub),
            //distinctUntilChanged()
          ).subscribe(val => {
            this.regionChanged(val);
          });


        this.CustomerTypeFormControl.valueChanges
          .pipe(
            // tap(x => console.log(x)),
            takeUntil(this.sub)
          ).subscribe(val => {
            this.customerTypeChanged(val);
          });
      }
    )


  }
  ngOnDestroy() {
    if (this.sub) {
      this.sub.next(true);
      this.sub.complete();
    }
  }

  validateAndRetrieveInvoiceData(): Observable<AeroInvoicingDataValidation> {
    this.submitted = true;
    this.invoicingDataValidation = null;
    if (this.formGroup.valid) {
      let formGroupValue = this.formGroup.value as any;

      if (formGroupValue.customerType == AeroCustomerTypes.ItIndividualPerson) {
        formGroupValue = {
          ...formGroupValue,
          firstName: formGroupValue.companyName,
          companyName: ''
        };
      }

      this.storeInvoicingData();
      return of(this.invoicingDataValidation = <AeroInvoicingDataValidation>{
        formValid: true,
        dataValid: true,
        invoiceData: formGroupValue
      });
    }

    return of(this.invoicingDataValidation = <AeroInvoicingDataValidation>{ formValid: false, dataValid: false });
  }

  private regionChanged(newRegion: string): void {
    this.citiesForRegion = REGIONS_WITH_CITIES.filter(item => item.code == newRegion).reduce((acc, curVal) =>
      acc.concat(curVal.cities.map(item => <IKeyValuePair<string, string>>{ text: item, value: item })), []);
    this._resetSelectedCity();
  }

  private countryChanged(newCountryCode: string): void {
    this.storeInvoicingData();
    // const country = this.countries.find(c => c.code === newCountryCode);
    this.invoicingData.CountryCode = newCountryCode;

    //this.filteredRegions = this.allRegions.filter(r => r.countryCode === newCountryCode);
    this.updateFields();
  }

  private customerTypeChanged(newCustomerType: string): void {
    this.storeInvoicingData();
    // if the previous user was NonIt or new user is NonIt, we need to reset the current City
    if (this.invoicingData.CustomerType === AeroCustomerTypes.NonItCustomer
      || newCustomerType === AeroCustomerTypes.NonItCustomer) {
      this._resetSelectedCity();
    }

    this.invoicingData.CustomerType = newCustomerType;
    this.updateFields();

  }

  private _resetSelectedCity() {
    this.cityCodeFormControl.setValue(null);
    this.cityCodeFormControl.updateValueAndValidity();
  }

  private updateFields(): void {
    if (!this.invoicingData.CustomerType) {
      return;
    }
    this.formGroup.get(AeroInvoiceFieldNames.LastName).setValidators([]);
    if (!this.isItalianCompany) {
      this.invoicingData.EmailPec = this.formGroup.get(AeroInvoiceFieldNames.EmailPec).value;
      this.formGroup.get(AeroInvoiceFieldNames.EmailPec).reset(this.defaultEmailAddress);
      if (this.isItalianIndividual) {
        this.formGroup.get(AeroInvoiceFieldNames.RecipientCode).setValue('0000000');
        this.formGroup.get(AeroInvoiceFieldNames.CountryCode).setValue(ITALY_CODE);
        this.formGroup.get(AeroInvoiceFieldNames.LastName).setValidators(AeroInvoicingValidators.customRequired(NO_COUNTRY));
      }
      else {
        this.formGroup.get(AeroInvoiceFieldNames.CountryCode).reset(NON_ITALY_COUNTRY_CODE);
        // // this.formGroup.get(AeroInvoiceFieldNames.CountryCode).setValue(NON_ITALY_COUNTRY_CODE);
        this.formGroup.get(AeroInvoiceFieldNames.RecipientCode).setValue('XXXXXXX');
        this.formGroup.get(AeroInvoiceFieldNames.LastName).reset();

      }

    } else {
      this.formGroup.get(AeroInvoiceFieldNames.CountryCode).setValue(ITALY_CODE);
      this.formGroup.get(AeroInvoiceFieldNames.RecipientCode).reset();
      this.formGroup.get(AeroInvoiceFieldNames.LastName).reset();
    }
    this.submitted = false;
    this.formGroup.markAsUntouched();
    this.formGroup.updateValueAndValidity();
  }

  private storeInvoicingData(): void {
    const formGroupValue = this.formGroup.value;
    for (const key in formGroupValue) {
      if (formGroupValue.hasOwnProperty(key)) {
        this.invoicingData[key] = formGroupValue[key];
      }
    }
  }

  fiscalPersonValidator: (group: FormGroup) => { [s: string]: any } = (group: FormGroup) => {

    const customerTypeControl = group.get(AeroInvoiceFieldNames.CustomerType);
    const fiscalCodeCtrl = group.get(AeroInvoiceFieldNames.FiscalCode);
    AeroInvoicingComponent.removeCtrlErrors(['fiscalPersonValidator_ItCompany'], fiscalCodeCtrl);
    AeroInvoicingComponent.removeCtrlErrors(['fiscalCodeValidator_Individual'], fiscalCodeCtrl);

    switch (customerTypeControl.value) {
      case AeroCustomerTypes.ItCompany:
        if (!fiscalCodeCtrl.value) return null;
        // if the first char is number (digit) the validation: 11 digits
        if (!isNaN(+fiscalCodeCtrl.value.substring(0, 1))) {
          const result = !/^[0-9]{11,11}?$/i.test(fiscalCodeCtrl.value);
          if (result) {
            fiscalCodeCtrl.setErrors({ fiscalPersonValidator_ItCompany: { value: fiscalCodeCtrl.value } })
          }
          return null;
        }
      // else, validate as an ItIndividualPerson
      case AeroCustomerTypes.ItIndividualPerson:

        const validationResult = !/^[A-Za-z0-9]{16,16}?$/i.test(fiscalCodeCtrl.value);
        if (validationResult) {
          fiscalCodeCtrl.setErrors({ fiscalCodeValidator_Individual: { value: fiscalCodeCtrl.value } })
        }
        break;
      case AeroCustomerTypes.NonItCustomer:
        if (isNullOrUndefined(fiscalCodeCtrl.value)) {
          fiscalCodeCtrl.setErrors({ required: true });
        } else {
          const validationResult = !/^[A-Za-z0-9]+$/i.test(fiscalCodeCtrl.value);
          if (validationResult) {
            fiscalCodeCtrl.setErrors({ alphaNumeric: true })
          }
        }
        break;
      //   return validationResult ? { fiscalCodeValidator_Individual: { value: fiscalCodeCtrl.value } } : null;
      // default: // no validation for NON Italian
      //   return null;
    }
    return null;
  }

  // recipientCodeValidation(formControl: AbstractControl): { [s: string]: any } {


  //   return null;
  // }

  oneOfVatOrFiscalCodeRequired(group: FormGroup): { [s: string]: boolean } {
    if (!group) return null;
    const fiscalCodeCtrl = group.controls[AeroInvoiceFieldNames.FiscalCode];
    const vatCtrl = group.controls[AeroInvoiceFieldNames.Vat]

    AeroInvoicingComponent.removeCtrlErrors(['VatOrFiscalCodeRequired'], fiscalCodeCtrl);
    AeroInvoicingComponent.removeCtrlErrors(['VatOrFiscalCodeRequired'], vatCtrl);
    // fiscalCodeCtrl.setErrors(null);
    // vatCtrl.setErrors(null);
    if (group.controls[AeroInvoiceFieldNames.CustomerType].value !== AeroCustomerTypes.ItCompany) {
      return null;
    }

    if (fiscalCodeCtrl.value || vatCtrl.value) {
      return null;
    }
    fiscalCodeCtrl.setErrors({ 'VatOrFiscalCodeRequired': true });
    vatCtrl.setErrors({ 'VatOrFiscalCodeRequired': true });
    if (fiscalCodeCtrl.touched || vatCtrl.touched) {
      fiscalCodeCtrl.markAsTouched();
      vatCtrl.markAsTouched();
    }

    return { 'VatOrFiscalCodeRequired': true };

  }

  oneOfRecipientCodeOrEmailPecRequired(group: FormGroup): { [s: string]: boolean } {
    if (!group) return null;

    const emailPecCtrl = group.controls[AeroInvoiceFieldNames.EmailPec];
    const recipientCode = group.controls[AeroInvoiceFieldNames.RecipientCode]
    // emailPecCtrl.setErrors({ 'RecipientCodeOreEmailRequired': null });
    AeroInvoicingComponent.removeCtrlErrors(['RecipientCodeOreEmailRequired'], emailPecCtrl);
    AeroInvoicingComponent.removeCtrlErrors(['RecipientCodeOreEmailRequired'], recipientCode);

    // recipientCode.setErrors(null);
    if (group.controls[AeroInvoiceFieldNames.CustomerType].value !== AeroCustomerTypes.ItCompany) {
      return null;
    }

    if (emailPecCtrl.value || recipientCode.value) {
      return null;
    }
    emailPecCtrl.setErrors({ 'RecipientCodeOreEmailRequired': true });
    recipientCode.setErrors({ 'RecipientCodeOreEmailRequired': true });
    if (emailPecCtrl.touched || recipientCode.touched) {
      emailPecCtrl.markAsTouched();
      recipientCode.markAsTouched();
    }
    return { 'RecipientCodeOreEmailRequired': true };

  }

  public static removeCtrlErrors(keys: string[], control: AbstractControl) {
    if (!control || !keys || keys.length === 0) {
      return;
    }

    const remainingErrors = keys.reduce((errors, key) => {
      delete errors[key];
      return errors;
    }, { ...control.errors });
    control.setErrors(null);
    control.setErrors(remainingErrors);

    if (Object.keys(control.errors || {}).length === 0) {
      control.setErrors(null);
    }
  }

}
