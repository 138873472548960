import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


declare global {
  interface Window { blueAirSessionObj: any; }
}

@Injectable()
export class UserStateService {
  isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isUserLoginDisabled: BehaviorSubject<UserDisabledLoginInfo> = new BehaviorSubject({ isDisabled: false });
  userPromoDetails: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  activePromo: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    // window.blueAirSessionObj.subscribe((session) => {
    //   this.isLoading.next(false);
    //   this.isLoggedIn.next(session.User ? true : false);
    //   this.userPromoDetails.next(session.User);
    //   this.isUserLoginDisabled.next({
    //     isDisabled: session.UserDisabled,
    //     agent: session.Agent
    //   });
    //   this.activePromo.next(session.hasOwnProperty('ActivePromo') ? session.ActivePromo : false);
    // });
  }
}


export interface UserDisabledLoginInfo {
  isDisabled: boolean;
  agent?: string;
}
