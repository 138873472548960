import { Component, OnInit, Input, OnChanges, Renderer2, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { BookingFlowService } from 'src/app/core/booking-flow.service';

@Component({
  selector: 'blue-select-list',
  templateUrl: './blue-select-list.component.html',
  styleUrls: ['./blue-select-list.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: BlueSelectListComponent, multi: true }
  ]
})
export class BlueSelectListComponent implements OnInit, OnChanges, ControlValueAccessor {
  selectedItem: any;
  isDisabled: boolean;
  private value: any;
  private isItemSelected: boolean;
  private _items: any[] = [];

  private onTouchedCb = () => {};
  private onChangeCb =  (_: any) => {};

  @Input()
  set items(items: any[]) {
    this._items = items;
    if (this.value) {
      this.writeValue(this.value);
    }
    this.filterItems();
  }
  @Input() textProperty: string;
  @Input() valueProperty: string;
  @Input() placeholder: string;
  @Input() enableSearch: boolean;
  @Input() minimumSearchLength = 2;
  @Input() lazySearch: boolean;

  expanded = false;
  search: string;
  filteredItems: any[] = [];

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private bookingFlowService: BookingFlowService) {
    this.enableSearch = false;
    this.textProperty = 'text';
    this.valueProperty = 'value';
  }

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.filterItems();
  }

  searchChanged(newValue): void {
    this.search = newValue;
    this.selectedItem = null;
    this.filterItems();
  }

  selectItem($event: any, item: any): void {
    this.selectedItem = item;
    this.search = item[this.textProperty];
    this.value = item[this.valueProperty];
    this.onChangeCb(this.value);
    this.setExpanded(false);
  }

  setExpanded(value: boolean): void {
    this.expanded = value;
    this.onTouchedCb();
    this.filterItems(value);
  }

  focusOut(): void {
    if (!this.selectedItem && this.filteredItems.length) {
      this.selectItem(null, this.filteredItems[0]);
    } else {
      this.setExpanded(false);
    }
  }

  filterItems(reset = false): void {
    if (reset || !this.search || this.search.length < this.minimumSearchLength) {
      this.filteredItems = !this.lazySearch ? this._items : [];
      return;
    }
    const reg = new RegExp(this.search, 'i');
    this.filteredItems = this._items.filter(val => {
      return reg.test(val[this.textProperty]);
    });
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.value = undefined;
      this.search = undefined;
      return;
    }
    this.value = obj;
    if (this._items) {
      this.selectedItem = this._items.find(i => i[this.valueProperty] === obj);
    }
    if (this.textProperty && this.selectedItem) {
      this.search = this.selectedItem [this.textProperty];
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}