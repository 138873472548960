import * as tslib_1 from "tslib";
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { BookingStepsService } from '../core/booking-steps.service';
import { FlowManagerService } from '../core/flow-manager.service';
var CanActivateDocumentsService = /** @class */ (function (_super) {
    tslib_1.__extends(CanActivateDocumentsService, _super);
    function CanActivateDocumentsService(steps, flowManager) {
        return _super.call(this, steps, flowManager.applicationFlowService) || this;
    }
    CanActivateDocumentsService.prototype.canActivate = function (route, state) {
        return _super.prototype.canActivate.call(this, route, state);
    };
    return CanActivateDocumentsService;
}(BaseCanActivateService));
export { CanActivateDocumentsService };
