import { BundleService } from './bundle.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsActiveDirective } from './is-active.directive';
import { FormWrapperComponent } from './form-wrapper/form-wrapper.component';
import { DurationPipe } from './duration.pipe';
import { FormsModule } from '@angular/forms';
import { CheckinAccordionComponent } from './checkin-accordion/checkin-accordion.component';
import { CheckinAccordionItemComponent } from './checkin-accordion-item/checkin-accordion-item.component';
import { PassengerTypeDisplayComponent } from './passenger-type-display/passenger-type-display.component';
import { CurrencyDisplayPipe } from './currency-display.pipe';
import { PassengerNameDisplayComponent } from './passenger-name-display/passenger-name-display.component';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { SendBoardingPassOverEmailComponent } from './send-boarding-pass-over-email/send-boarding-pass-over-email.component';
import { PrintBoardingPassComponent } from './print-boarding-pass/print-boarding-pass.component';
import { SeatSelectedNotification } from './seat-selected-notification/seat-selected-notification.component';
import { PassengerBundleComponent } from './passenger-bundle/passenger-bundle.component';
import { DefaultModalComponent } from './default-modal/default-modal.component';
import { SplitCamelcaseStringPipe } from './split-camelcase-string.pipe';
import { ValidationDirective } from './validation-directive/validation.directive';
import { CultureFormatNumberPipe } from './culture-format-number.pipe';
import { DurationDisplayPipe } from './duration-display.pipe';
import { CheckinValidationErrorsDisplayComponent } from './checkin-validation-errors-display/checkin-validation-errors-display.component';
import { SessionWarningComponent } from './session-warning/session-warning.component';
import { SessionEmptyWarningComponent } from './session-empty-warning/session-empty-warning.component';
import {
  ShoppingCartComponent,
  ShoppingCartFlightsComponent,
  ShoppingCartExtrasComponent,
  ShoppingCartFlightDetailsComponent
} from './shopping-cart-components';
import { SelectionsClearWarningModalComponent } from './selections-clear-warning-modal/selections-clear-warning-modal.component';
import { SsrsSeatsRemovalNotificationComponent } from './ssrs-seats-removal-notification/ssrs-seats-removal-notification.component';
import { BlueModal2Component } from './blue-modal2/blue-modal2.component';
import { SsrTranslatePipe } from './ssr-translate.pipe';
import { PromoCodeComponent } from './promo-code/promo-code.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TranslationWithHtml } from './translation-innerHtml.pipe';
import { ECommerceService } from './e-commerce/ecommerce.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BlueAirCommonModule
  ],
  exports: [
    CommonModule,
    IsActiveDirective,
    FormWrapperComponent, CheckinAccordionComponent, CheckinAccordionItemComponent, PassengerTypeDisplayComponent,
    PassengerNameDisplayComponent, SendBoardingPassOverEmailComponent, PrintBoardingPassComponent,
    DurationPipe, DurationDisplayPipe, CurrencyDisplayPipe, SeatSelectedNotification, PassengerBundleComponent,
    DefaultModalComponent, SplitCamelcaseStringPipe, ValidationDirective, CultureFormatNumberPipe, CheckinValidationErrorsDisplayComponent,
    SessionWarningComponent, ShoppingCartComponent, SelectionsClearWarningModalComponent, SsrsSeatsRemovalNotificationComponent,
    BlueModal2Component, SsrTranslatePipe, PromoCodeComponent,
    SafeHtmlPipe,
    TranslationWithHtml
  ],
  declarations: [
    IsActiveDirective,
    FormWrapperComponent, CheckinAccordionComponent, CheckinAccordionItemComponent, PassengerTypeDisplayComponent,
    DurationPipe, DurationDisplayPipe, CurrencyDisplayPipe, PassengerNameDisplayComponent,
    SendBoardingPassOverEmailComponent, PrintBoardingPassComponent, SeatSelectedNotification, DefaultModalComponent,
    PassengerBundleComponent, SplitCamelcaseStringPipe, ValidationDirective, CultureFormatNumberPipe,
    CheckinValidationErrorsDisplayComponent, SessionWarningComponent, SessionEmptyWarningComponent, ShoppingCartComponent,
    ShoppingCartFlightsComponent, ShoppingCartExtrasComponent, ShoppingCartFlightDetailsComponent, SelectionsClearWarningModalComponent,
    SsrsSeatsRemovalNotificationComponent, BlueModal2Component, SsrTranslatePipe, PromoCodeComponent,
    SafeHtmlPipe,
    TranslationWithHtml
  ],
  providers: [
    BundleService,
    ECommerceService
  ]
})
export class SharedModule { }
