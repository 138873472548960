import * as tslib_1 from "tslib";
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { BookingStepsService } from '../core/booking-steps.service';
import { CheckinService } from '../core/checkin.service';
import { SsrsService } from './ssrs.service';
var CanActivateExtrasService = /** @class */ (function (_super) {
    tslib_1.__extends(CanActivateExtrasService, _super);
    function CanActivateExtrasService(bookingStepsService, checkinService, ssrsService) {
        var _this = _super.call(this, bookingStepsService, checkinService) || this;
        _this.ssrsService = ssrsService;
        return _this;
    }
    CanActivateExtrasService.prototype.canActivate = function (route, state) {
        var _this = this;
        var superPromise = _super.prototype.canActivate.call(this, route, state);
        return superPromise.then(function (superResponse) {
            if (!superResponse) {
                return false;
            }
            return Promise.all([
                _this.applicationFlowService.loadPriceBreakdown(),
                _this.ssrsService.getSsrsInfo().toPromise()
            ])
                .then(function (data) { return true; });
        });
    };
    return CanActivateExtrasService;
}(BaseCanActivateService));
export { CanActivateExtrasService };
