import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {
  constructor(private _translate: TranslateService) { }

  transform(value: any, scope: string, ...args: string[]): any {
    if (!value) {
      return '';
    }
    return this._translate.instant(value, scope, args);
  }
}
