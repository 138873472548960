<div class="moveFlight_wrapper">
  <blue-loading-spinner [id]="moveFlightSpinnerId" [showSpinner]="true"></blue-loading-spinner>
  <div class="changeFlight_wrapper_row">
    <div class="changeFlight_wrapper_col currency-wrap">
      <h1>{{ 'Move flight' | translate:'change-flight' }}</h1>
    </div>
  </div>
  <div class="changeFlight_wrapper_row">
    <div class="changeFlight_wrapper_col">
      <label>{{ 'From' | translate:'change-flight' }}</label>
      <ng-container *ngIf="departureStationOptions.length">
        <select [(ngModel)]="selectedDepartureStation" class="origin-destination">
          <option *ngFor="let s of departureStationOptions" [ngValue]="s"> {{ s | translate:'station.name'}}</option>
        </select>
      </ng-container>
      <ng-container *ngIf="!departureStationOptions.length">
        <div class="origin-destination">{{ departureStation | translate:'station.name'}}</div>
      </ng-container>
      
    </div>
    <div class="changeFlight_wrapper_col">
      <label>{{ 'To' | translate:'change-flight' }}</label>
      <ng-container *ngIf="arrivalStationOptions.length">
        <select [(ngModel)]="selectedArrivalStation" class="origin-destination">
          <option *ngFor="let s of arrivalStationOptions" [ngValue]="s"> {{ s | translate:'station.name'}}</option>
        </select>
      </ng-container>
      <ng-container *ngIf="!arrivalStationOptions.length">
        <div class="origin-destination">{{ arrivalStation | translate:'station.name'}}</div>
      </ng-container>
    </div>
  </div>

      <div class="changeFlight_wrapper_row calendar-date-picker-container">
        <div class="changeFlight_wrapper_col" [ngClass]="{'full-width':isOneWay}">
          <label class="">{{ 'calendarFrom' | translate:'change-flight' }}</label>
          <calendar-date-picker #outboundDatePicker
                                [(ngModel)]="outboundDate" 
                                [minDate]="minDate"
                                [maxDate]="maxDate"
                                [rangeStart]="outboundDate"
                                [rangeEnd]="inboundDate" 
                                [departure]="departureStation" 
                                [destination]="arrivalStation" 
                                [format]="format"
                                header="{{'Select you departure date' | translate:'change-flight' }}">
          </calendar-date-picker>
        </div>
        <div class="changeFlight_wrapper_col" *ngIf="!isOneWay">
          <label class="">{{ 'calendarTo' | translate:'change-flight' }}</label>
          <calendar-date-picker #inboundDatePicker 
                                [(ngModel)]="inboundDate"
                                [minDate]="minDate"
                                [maxDate]="maxDate"
                                [rangeStart]="outboundDate"
                                [rangeEnd]="inboundDate" 
                                [departure]="arrivalStation" 
                                [destination]="departureStation"
                                [format]="format" 
                                header="{{'Select you return date' | translate:'change-flight' }}">
          </calendar-date-picker>
        </div>
      </div>

  <div class="changeFlight_wrapper_row center">
    <ng-container *ngIf="!isForSearchingFlight else searchFlightTemplate">
      <button (click)="moveFlight()" class="btn btn-primary btn-big changeFlight_button" type="button">
        <span>{{ 'Move' | translate:'change-flight' }}</span>
      </button>
    </ng-container>
    <ng-template #searchFlightTemplate>
      <button (click)="searchFlight()" class="btn btn-primary btn-big changeFlight_button" type="button">
        <span>{{ 'Search' | translate:'change-flight' }}</span>
      </button>
    </ng-template>
  </div>
</div>