import { OnInit, EventEmitter } from '@angular/core';
import { SeatMapService } from '../seat-map.service';
import { LoggerService } from '../../common-modules/log4ts/logger.service';
var SeatMapComponent = /** @class */ (function () {
    function SeatMapComponent(seatmapService, logger) {
        this.seatmapService = seatmapService;
        this.logger = logger;
        this.passengerNumer = 0;
        this.passengerDysplayNumer = 0;
        this.appliedFilters = [];
        this.selectedSeats = [];
        this.addToCartEvent = new EventEmitter();
        this.setSelected = new EventEmitter();
        this.seatRows = [];
        this.style = 'none';
    }
    Object.defineProperty(SeatMapComponent.prototype, "selectPassenger", {
        set: function (pa) {
            var _this = this;
            if (pa) {
                this.passengerNumer = pa.passengerNumber;
                this.passengerDysplayNumer = pa.passengerNumber + 1;
                if (!pa.hasInfant && this.appliedFilters.indexOf(4) >= 0) {
                    this.logger.info("remove infant on pax change");
                    this.applyFilter({ map: this.mapKey, filter: 4 });
                }
                else if (pa.hasInfant && this.appliedFilters.indexOf(4) < 0) {
                    this.logger.info("add infant on pax change");
                    this.applyFilter({ map: this.mapKey, filter: 4 });
                }
                //todo: check other passenger restrictions
                this.applyFilter({ map: this.mapKey, filter: 5 });
                this.appliedFilters.forEach(function (f) {
                    _this.changeSeatDecoratorAccordingToFilter(f);
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    Object.defineProperty(SeatMapComponent.prototype, "filterApplied", {
        set: function (f) {
            var _this = this;
            f.subscribe(function (filter) {
                if (filter) {
                    _this.logger.info("input filter " + f);
                    _this.applyFilter(filter);
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SeatMapComponent.prototype, "seatDeselected", {
        set: function (event) {
            var _this = this;
            event.subscribe(function (u) {
                if (u) {
                    var unit = _this.seatRows.map(function (p) { return p.units; }).reduce(function (a, b) { return a.concat(b); }, []).find(function (p) { return p.unitDesignator == u.unitDesignator; });
                    var selectesIndex = _this.selectedSeats.findIndex(function (p) { return p.passengerNumber == u.passsenger; });
                    if (selectesIndex >= 0) {
                        _this.selectedSeats.splice(selectesIndex, 1);
                    }
                    unit.decorator = unit.baseDecorator || unit.masterDecorator;
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    SeatMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.logger.info(this.mapKey);
        this.logger.info(this.flightKey);
        //todo:filter flightkey
        this.thismap = this.seatmapService.seatMap.seatMaps.find(function (p) { return p.sellKey == _this.mapKey && p.flightKey == _this.flightKey; });
        this.rowLetters = this.thismap.rowLetters;
        this.seatRows = this.thismap.seatRows;
        this.seatmapService.seatMap.seatAssignment.journeys.find(function (p) { return p.sellKey == _this.mapKey; })
            .seatSegmentKeys.filter(function (p) { return p.flightKey == _this.flightKey; }).map(function (p) { return p.passengers; }).reduce(function (a, b) { return a.concat(b); }, [])
            .map(function (p) { return ({ passengerNumber: p.number, unit: p.assignedSeat }); })
            .forEach(function (s) {
            _this.passengerNumer = s.passengerNumber;
            var d = _this.thismap.decks.find(function (p) { return p.number == s.unit.deck; });
            if (d == null) {
                return;
            }
            _this.unitClicked(d
                .compartments.find(function (p) { return p.compartmentDesignator == s.unit.compartment; })
                .units.find(function (p) { return p.unitDesignator == s.unit.unit; }), false);
            //this.selectedSeats.push({passengerNumber:s.passengerNumber, unit: });
        });
    };
    SeatMapComponent.prototype.changeStyle = function ($event, cell) {
        cell.style = $event.type == 'mouseover' ? 'block' : 'none';
    };
    SeatMapComponent.prototype.unitClicked = function (unit, notify) {
        var _this = this;
        if (notify === void 0) { notify = true; }
        if (this.selectedSeats.length == 0)
            this.selectedSeats.push({ passengerNumber: this.passengerNumer, unit: unit });
        else {
            var oldSeatForCurrentPax = this.selectedSeats.find(function (p) { return p.passengerNumber == _this.passengerNumer; });
            if (oldSeatForCurrentPax) {
                oldSeatForCurrentPax.unit.decorator = oldSeatForCurrentPax.unit.baseDecorator;
                oldSeatForCurrentPax.unit.passengerNumber = "";
                this.selectedSeats.splice(this.selectedSeats.indexOf(oldSeatForCurrentPax), 1);
                this.selectedSeats.push({ passengerNumber: this.passengerNumer, unit: unit });
            }
            else {
                this.selectedSeats.push({ passengerNumber: this.passengerNumer, unit: unit });
            }
            ;
        }
        unit.decorator = "legend-selected";
        unit.passengerNumber = this.passengerNumer + 1;
        if (notify)
            this.setSelected.next(unit);
        this.addToCartEvent.emit();
    };
    SeatMapComponent.prototype.applyFilter = function (filter) {
        // if (this.mapKey != filter.map)
        //   return
        var _this = this;
        this.logger.info("apply filter" + filter.filter);
        //clear all filters
        this.seatRows.forEach(function (u) {
            u.units.forEach(function (uu) {
                if (uu) {
                    if (uu.baseDecorator && uu.baseDecorator.length == 0) {
                        //check by pax
                        var paxNumber = _this.passengerNumer;
                        //create a masterUnitAvailability property
                        if (uu.unitAvailabilityByPax) {
                            uu.decorator = uu.unitAvailabilityByPax[paxNumber] != "Open" ? "legend-not-available" : uu.masterDecorator;
                        }
                    }
                    else
                        uu.decorator = uu.baseDecorator;
                }
            });
        });
        //if filter applied remove it from list
        if (this.appliedFilters.indexOf(filter.filter) >= 0) {
            this.appliedFilters.splice(this.appliedFilters.indexOf(filter.filter), 1);
        }
        else {
            this.appliedFilters.push(filter.filter);
        }
        this.logger.info("remaining filters:" + this.appliedFilters);
        //apply all remaining filters
        this.appliedFilters.forEach(function (f) {
            _this.changeSeatDecoratorAccordingToFilter(f);
        });
        if (this.appliedFilters.length === 0) {
            this.changeSeatDecoratorAccordingToFilter(5);
        }
    };
    SeatMapComponent.prototype.changeSeatDecoratorAccordingToFilter = function (filter) {
        var _this = this;
        var applyFilterFunc = function (u, direction) {
            if (direction) {
                u.decorator = 'legend-not-available';
            }
            else {
                if (u.baseDecorator.length == 0) {
                }
                else {
                    u.decorator = u.baseDecorator;
                }
            }
            var ss = _this.selectedSeats;
            var pasSeat = _this.selectedSeats.find(function (pss) { return pss.unit.unitDesignator == u.unitDesignator && pss.unit.flightKey == u.flightKey; });
            if (pasSeat) {
                u.decorator = "legend-selected";
            }
        };
        var findAndApply = function (u, l) {
            if (l.findIndex(function (cs) { return cs.unitDesignator == u.unitDesignator; }) <= 0) {
                applyFilterFunc(u, true);
            }
        };
        var map = this.thismap; //this.seatmapService.seatMap.seatMaps.find(p => p.sellKey == this.mapKey);
        switch (filter) {
            case 1: //'filter child';
                this.seatRows.forEach(function (p) { return p.units.forEach(function (u) { return findAndApply(u, map.seatForChild); }); });
                this.logger.info("filter child");
                break;
            case 2: //filter disabled';
                this.seatRows.forEach(function (p) { return p.units.forEach(function (u) { return findAndApply(u, map.seatForDisabled); }); });
                this.logger.info("filter disabled");
                break;
            case 3: //'filter pet';
                this.seatRows.forEach(function (p) { return p.units.forEach(function (u) { return findAndApply(u, map.seatForPet); }); });
                this.logger.info("filter pet");
                break;
            case 4: //'filter infant';
                this.seatRows.forEach(function (p) { return p.units.forEach(function (u) { return findAndApply(u, map.seatForInfant); }); });
                this.logger.info("filter infant");
                break;
        }
        var units = this.selectedSeats.map(function (p) { return p.unit; });
        if (map) {
            map.decks.forEach(function (d) {
                return d.compartments.forEach(function (c) {
                    return c.units.forEach(function (u) {
                        if (units.findIndex(function (p) { return p.deck == d.number && p.compartmentDesignator == c.compartmentDesignator && p.unitDesignator == u.unitDesignator; }) >= 0) {
                            u.decorator = "legend-selected";
                        }
                    });
                });
            });
        }
    };
    SeatMapComponent.prototype.getClassForGroup = function (group, groups) {
        var classes = "row-info-wrapper ";
        // if (group && group.cells.length < 6) {
        if (group && group.cells.length < 6) {
            var length_1 = group.containsMiddle ? group.cells.length + 1 : group.cells.length;
            switch (length_1) {
                case 1:
                    classes += 'one-seat-space';
                    break;
                case 2:
                    classes += 'two-seat-space';
                    break;
                case 3:
                    classes += 'three-seat-space';
                    break;
                case 4:
                    classes += 'four-seat-space';
                    break;
                case 5:
                    classes += 'five-seat-space';
                    break;
                case 6:
                    classes += 'six-seat-space';
                    break;
            }
        }
        return classes;
    };
    return SeatMapComponent;
}());
export { SeatMapComponent };
