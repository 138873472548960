import * as tslib_1 from "tslib";
import { ErrorHandler, Injector } from '@angular/core';
import { UserActivityService } from './user-activity.service';
var ErrorsHandlerService = /** @class */ (function (_super) {
    tslib_1.__extends(ErrorsHandlerService, _super);
    function ErrorsHandlerService(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        return _this;
    }
    ErrorsHandlerService.prototype.handleError = function (error) {
        var userActivityService = this.injector.get(UserActivityService);
        userActivityService.trackException(error);
        _super.prototype.handleError.call(this, error);
    };
    return ErrorsHandlerService;
}(ErrorHandler));
export { ErrorsHandlerService };
