import { ShoppingCartBreakdownItem } from './shopping-cart-breakdown-item';
import { ShoppingCartFlightModel } from './shopping-cart-flight-model';
import * as moment from 'moment';
import { ShoppingCartPassengerModel } from './shopping-cart-passenger-model';
import { Constants } from '../../../constants';
import { FlightModel } from '../flight-model';
import { Helpers } from '../../../helpers';
import {
    ShoppingCartExtrasModel,
    ShoppingCartExtrasFlightModel,
    ShoppingCartExtrasPassengerModel,
    ShoppingCartExtrasSsrItemModel
} from './shopping-cart-extras-model';
import { SsrType } from '../../../extras/ssr-type.enum';

interface DateParts {
  year: string;
  month: string;
  day: string;
  hour: string;
  minute: string;
  second: string;
}

export class ShoppingCartBreakdownModel {
    originalShoppingCart: ShoppingCartBreakdownItem;
    currentShoppingCart: ShoppingCartBreakdownItem;

    constructor() {
        this.currentShoppingCart = new ShoppingCartBreakdownItem();
    }

    init(shoppingCartBreakdown: any): ShoppingCartBreakdownModel {
        this.currentShoppingCart = new ShoppingCartBreakdownItem().init(
            shoppingCartBreakdown.newJourneys,
            shoppingCartBreakdown.newExtras,
            shoppingCartBreakdown.newBookingBalanceDue
        );

        this.originalShoppingCart = shoppingCartBreakdown.originalJourneys && shoppingCartBreakdown.originalJourneys.length ?
            new ShoppingCartBreakdownItem().init(
                shoppingCartBreakdown.originalJourneys,
                shoppingCartBreakdown.originalExtras,
                shoppingCartBreakdown.oldBookingBalanceDue
            ) :
            null;

        return this;
    }

    private _groupBundlesPricesPerFlightAndPassengerType(itinerary: any): Record<string, Record<string, number>> {
      const result: Record<string, Record<string, number>> = {};
      itinerary.priceItinerary.passengers.forEach(passenger => {
        if (Array.isArray(passenger.fees.items)) {
          passenger.fees.items.forEach(fee => {
            if (fee.type === 'ServiceBundle') {
              if (!result[fee.flightReference]) {
                result[fee.flightReference] = {};
              }

              if (!result[fee.flightReference][passenger.typeInfo.paxType]) {
                result[fee.flightReference][passenger.typeInfo.paxType] = 0;
              }

              fee.serviceCharges.items.forEach(serviceCharge => {
                result[fee.flightReference][passenger.typeInfo.paxType] += serviceCharge.amount;
              });
            }
          });
        }
      });
      return result;
    }
    private _extractDateParts(dateAsString: string): DateParts {
      const [datePart, timePart] = dateAsString.split(' ');
      const [year, month, day] = datePart.split('-');
      const [hour, minute, second] = timePart.split(':');
      return {
        year: year,
        month: month,
        day: day,
        hour: hour,
        minute: minute,
        second: second
      };
    }
    private _getJourneyFlightReference(journey: any): string {
      const firstSegment = journey.segments.items[0];
      const dateParts = this._extractDateParts(firstSegment.std);
      const carrierCode = firstSegment.flightDesignator.carrierCode;
      const flightNumber = firstSegment.flightDesignator.flightNumber;
      const departureStation = firstSegment.departureStation;
      const arrivalStation = firstSegment.arrivalStation;
      return `${dateParts.year}${dateParts.month}${dateParts.day} ${carrierCode}${flightNumber} ${departureStation}${arrivalStation}`;
    }
    updateFromItinerary(itinerary: any, ignoreDiscounts: boolean): ShoppingCartBreakdownModel {
        const overriddenBalanceDue = this.currentShoppingCart && this.currentShoppingCart.isBalanceDueOverridden ?
            this.currentShoppingCart.balanceDue : null;
        this.currentShoppingCart = new ShoppingCartBreakdownItem();
        if (itinerary !== null) {

          const bundlePricesPerFlightAndPassengerType = this._groupBundlesPricesPerFlightAndPassengerType(itinerary);
          const infants = itinerary.priceItinerary.passengers
              .filter(p => p.fees.items.some(f => f.code === Constants.InfantSsrCode) || p.hasInfant)
              .map(() => {
                  const pax = new ShoppingCartPassengerModel();
                  pax.passengerType = Constants.InfantSsrCode;
                  return pax;
              });


          const paxList: ShoppingCartPassengerModel[] = [];
            itinerary.priceItinerary.passengers
                .map(p => {
                    const pax = new ShoppingCartPassengerModel();
                    pax.passengerType = p.typeInfo.paxType;
                    pax.discountCode = p.discountCode;
                    return pax;
                })
                .concat(infants)
                .forEach((p: ShoppingCartPassengerModel) => {
                    let pax = paxList.find(item => item.passengerType === p.passengerType && item.discountCode === p.discountCode);
                    if (!pax) {
                        pax = p;
                        pax.count = 0;
                        paxList.push(pax);
                    }
                    pax.count++;
                });



            this.currentShoppingCart.flights = itinerary.priceItinerary.journeys.map((journey, ji) => {
                const journeyFlightReference = this._getJourneyFlightReference(journey);
                let oldJourneyIndex: number;
                if (itinerary.convertedJourneys.journeys[ji].journeyTripType === 'OneWay' && itinerary.convertedJourneys.snapshotBooking) {
                    oldJourneyIndex = Number(sessionStorage.getItem(Constants.SelectedFlightIndexForChange));
                }
                const passengerFares = FlightModel.calculateFares(
                    itinerary.convertedJourneys.journeys[ji],
                    ignoreDiscounts,
                    {},
                    itinerary.convertedJourneys.oldJourneyFares,
                    oldJourneyIndex
                );

                if (infants.length && !itinerary.convertedJourneys.snapshotBooking) {
                    itinerary.priceItinerary.passengers.find(p => p.fees.items.some(fee => {
                        if (fee.code !== Constants.InfantSsrCode || fee.flightReference != journeyFlightReference) {
                            return false;
                        }
                        passengerFares[Constants.InfantSsrCode] = fee.serviceCharges.items
                            .filter(sc => sc.chargeCode === Constants.InfantSsrCode)
                            .reduce(Helpers.Sum('amount'), 0);
                        return true;
                    }));
                }

                const passengers: ShoppingCartPassengerModel[] = paxList.map(oldPax => {
                    const newPax = new ShoppingCartPassengerModel(oldPax);
                    const bundlePrice = (bundlePricesPerFlightAndPassengerType[journeyFlightReference]
                                        && bundlePricesPerFlightAndPassengerType[journeyFlightReference][newPax.passengerType]) || 0;
                    newPax.amount = newPax.count * (passengerFares[newPax.paxFareKey] || 0) + bundlePrice;
                    return newPax;
                }).reduce((list, pax) => {
                    const samePaxTypePax = list.find(p => p.passengerType === pax.passengerType);
                    if (samePaxTypePax && passengerFares[samePaxTypePax.paxFareKey] === passengerFares[pax.paxFareKey]) {
                        samePaxTypePax.count += pax.count;
                        samePaxTypePax.amount += pax.amount;
                    } else {
                        list.push(pax);
                    }
                    return list;
                }, <ShoppingCartPassengerModel[]>[]);

                const seg: ShoppingCartFlightModel[] = journey.segments.items.map(s => {
                    const leg = s.legs.items[0];
                    const newS = new ShoppingCartFlightModel();
                    newS.departureStation = s.departureStation;
                    newS.arrivalStation = s.arrivalStation;
                    newS.departureDate = moment(s.std, 'YYYY-MM-DD HH:mm:ss');
                    newS.departureDateUtc = moment(newS.departureDate).subtract(leg.legInfo.deptLtv, 'minutes').toDate();
                    newS.arrivalDate = moment(s.sta, 'YYYY-MM-DD HH:mm:ss');
                    newS.arrivalDateUtc = moment(newS.arrivalDate).subtract(leg.legInfo.arrvLtv, 'minutes').toDate();

                    newS.operatingCarrier = leg.flightDesignator.carrierCode;
                    newS.flightNumber = leg.flightDesignator.flightNumber;

                    newS.passengers = passengers;
                    newS.amount = passengers.sum(p => p.amount);
                    return newS;
                });

                const f = new ShoppingCartFlightModel()
                        .initFromSegments(seg, seg.slice(0, 1).sum(p => p.amount),
                        itinerary.priceItinerary.bundleCodes ? itinerary.priceItinerary.bundleCodes[ji] : null);
                return f;
            });

            this.currentShoppingCart.extras.push(...this.initExtrasFromItinerary(itinerary));

            this.currentShoppingCart.balanceDue =
                this.currentShoppingCart.flights.sum(f => f.amount) +
                this.currentShoppingCart.extras.sum(e => e.amount);
        }

        this.currentShoppingCart.overrideBalanceDue(overriddenBalanceDue);
        return this;
    }

    overrideBalanceDue(newBalanceDue: number): ShoppingCartBreakdownModel {
        this.currentShoppingCart = this.currentShoppingCart || new ShoppingCartBreakdownItem();
        this.currentShoppingCart.overrideBalanceDue(newBalanceDue);

        return this;
    }

    private initExtrasFromItinerary(itinerary: any): ShoppingCartExtrasModel[] {
        // to be generalized if we look for something more than DCH
        const penaltyFees = [];

        itinerary.passengers.items.forEach(pax => {
            pax.fees.items
                .filter(fee => fee.code === 'DCH')
                .forEach(fee => {
                    penaltyFees.push({
                        flightRef: fee.flightReference.slice(-6),
                        code: fee.code,
                        amount: (fee.serviceCharges.items as any[]).sum(sc => sc.amount)
                    });
                });
        });

        const otherExtrasModel = new ShoppingCartExtrasModel();
        otherExtrasModel.ssrType = SsrType.Other;

        const groupByFlightRef = penaltyFees.groupBy<string>(fee => fee.flightRef);
        groupByFlightRef
            .filter(group => group.key.length === 6)
            .forEach((group) => {
                const departure = group.key.slice(0, 3);
                const arrival = group.key.slice(3);

                const initialFlights = this.originalShoppingCart ?
                    this.originalShoppingCart.flights :
                    this.currentShoppingCart.flights;

                const flight = initialFlights.find(f =>
                    f.segments.some(s => s.departureStation === departure && s.arrivalStation === arrival));

                if (!flight) {
                    return;
                }
                let extrasFlight = otherExtrasModel.flights.find(f =>
                    f.departureStation === flight.departureStation && f.arrivalStation === flight.arrivalStation);

                if (!extrasFlight) {
                    extrasFlight = new ShoppingCartExtrasFlightModel();
                    extrasFlight.departureStation = flight.departureStation;
                    extrasFlight.arrivalStation = flight.arrivalStation;

                    otherExtrasModel.flights.push(extrasFlight);
                }

                let extrasSegment = extrasFlight.segments.find(s => s.departureStation === departure && s.arrivalStation === arrival);
                if (!extrasSegment) {
                    extrasSegment = new ShoppingCartExtrasFlightModel();
                    extrasSegment.departureStation = departure;
                    extrasSegment.arrivalStation = arrival;

                    const groupByFeeCode = group.values.groupBy<string>(fee => fee.code);

                    extrasSegment.passengers = groupByFeeCode.map(feeGroup => {
                        const extrasPassenger = new ShoppingCartExtrasPassengerModel();
                        const extrasItem = new ShoppingCartExtrasSsrItemModel();
                        extrasItem.quantity = 1;
                        extrasItem.ssrCode = feeGroup.key;
                        extrasItem.amount = feeGroup.values.sum(feeValue => feeValue.amount);
                        extrasItem.totalAmount = extrasItem.amount;
                        extrasPassenger.items = [extrasItem];

                        return extrasPassenger;
                    });

                    extrasFlight.segments.push(extrasSegment);
                }
            });

        otherExtrasModel.flights.forEach(f => {
            f.segments.forEach(s => s.initSegmentsFromPassengers(s.passengers));
            f.initJourneyFromSegments(f.segments);
        });
        otherExtrasModel.initFromFlights(otherExtrasModel.flights);

        return [otherExtrasModel];
    }
}
