import { SsrsService } from './../ssrs.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrType } from '../ssr-type.enum';
import { PassengerModel } from 'src/app/core/models/passenger-model';

@Component({
  selector: 'check-in-ssr-box-content-priority-boarding',
  templateUrl: './ssr-box-content-priority-boarding.component.html',
  styleUrls: ['./ssr-box-content-priority-boarding.component.scss']
})
export class SsrBoxContentPriorityBoardingComponent extends SsrBoxContentBase implements OnInit {
  uniqueId: number;
  private passengersSelection = new Map();

  @Output() saveChangesEvent = new EventEmitter();
  @Output() removeSsrEvent2 = new EventEmitter();

  constructor(private ssrsService: SsrsService) {
    super(ssrsService);
    this.ssrType = SsrType.PriorityBoarding;
    this.uniqueId = Math.floor(Math.random() * 1000) + 1;
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.flight && this.flight.passengers.length > 0) {
      this.allPassengersEnabled = this.allPassengersEnabledFunc();

      if (!this.allPassengersEnabled) {
        this.selectedPassenger = 0;
      }
    }

    this.allPassengersHaveTheSameSsrs();

    this.passengers.forEach(p => {
      this.passengersSelection.set(this.passengers.indexOf(p), this.hasPassengerBags(p));
    })
    this.setPriorityPassengerMap(this.passengers[0]);
  }

  allPassengersEnabledFunc(): boolean {
    if (this.passengers.length < 2) {
      return false;
    }

    if (this.availableSsrs[0]) {
      for (const property in this.availableSsrs[0].limitPerPassenger) {
        if (this.availableSsrs[0].limitPerPassenger.hasOwnProperty(property)) {
          if (this.availableSsrs[0].limitPerPassenger[property] === 0) {
            return false;
          }
        }
      }
    }

    return true;
  }

  showLuggagePriorityDetailsFreeOption() {
    this.ssrsService.isDisplayedPriorityDetailsFreeModal = true;
  }

  showLuggagePriorityDetailsPaidOption() {
    this.ssrsService.isDisplayedPriorityDetailsPaidModal = true;
  }

  selectSsrEvent(passengerIndex: number) {
    if (passengerIndex == null) return;
    this.ssrsService.isPrioritySsrSelected = true;
    this.selectSsr(passengerIndex, "PBRD", 1);
    this.saveChangesEvent.emit(passengerIndex);

    this.passengersSelection.set(passengerIndex, this.hasPassengerBags(this.passengers[passengerIndex]));
    this.setPriorityPassengerMap(this.passengers[passengerIndex]);
  }

  removeSsrEvent(passenger: PassengerModel | PassengerModel[]) {
    this.ssrsService.isPrioritySsrSelected = false;
    this.removeSsrEvent2.emit(passenger);

    //this.passengersSelection.set(this.passengers.indexOf(passenger[0]), this.hasPassengerBags(passenger[0]));
  }

  sameOptionsForAllFlightsEvent(sameOptionsForAllFlights: boolean) {
    this.sameOptionsForAllFlights = sameOptionsForAllFlights;
    this.selectSsrEvent(null);
  }

  hasPassengerBags(passenger) {
    return (passenger.ssrs.filter(s => s.ssrCode == 'PBRD'))[0] ? true : false;
  }

  checkAllPassengerBags() {
    let allPassengersHavePriority = true;
    for (let hasPriority of this.passengersSelection.values()) {
      if (!hasPriority) allPassengersHavePriority = false;
    }

    return allPassengersHavePriority;
  }

  setPriorityPassengerMap(passenger) {
    this.ssrsService.priorityPassengersMap.set(passenger.passenger.flightUniqueId, this.checkAllPassengerBags());
  }
}
