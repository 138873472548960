import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BookingSearchComponent } from './booking-search/booking-search.component';
import { CheckinRoutes } from './booking-search-routes';
import { FormsModule } from '@angular/forms';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { environment } from '../../environments/environment';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BlueAirCommonModule,
    // RouterModule.forRoot(
    //   environment.flow == 0 ? CheckinRoutes : []
    // ),
    SharedModule
  ],
  declarations: [BookingSearchComponent]
})
export class BookingSearchModule { }
