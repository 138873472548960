


<div class="checkin-plane-legend">
<div class="legend-list">
    <div class="legend-item">
      <span class="legend-icon legend-leg-room" role="presentation"></span>
      <span class="legend-text">{{'extra leg room'|translate:'seatmap'}} <span class="note" attr.aria-label="{{'From ' | translate: 'seatmap'}}"> 
        {{'From {0}'|translate:'seatmap': [seatMap.extraLegRoomPrice|currencyDisplay] }} 
      </span></span>
    </div>
    <div class="legend-item">
      <span class="legend-icon legend-front" role="presentation"></span>
      <span class="legend-text">{{'Front'|translate:'seatmap'}} <span class="note" attr.aria-label="{{'From ' | translate: 'seatmap'}}">
       {{'From {0}' |translate:'seatmap':[seatMap.frontLowPrice|currencyDisplay]}} </span></span>
    </div>
    <div class="legend-item">
      <span class="legend-icon legend-back" role="presentation"></span>
      <span class="legend-text">{{'Back'|translate:'seatmap'}} <span class="note" attr.aria-label="{{'From ' | translate: 'seatmap'}}">
        {{'From {0}'|translate:'seatmap':[seatMap.backLowPrice|currencyDisplay]}}</span></span>
    </div>
    <div class="legend-item">
      <span class="legend-icon legend-not-available" role="presentation"></span>
      <span class="legend-text">{{'not available'|translate:'seatmap'}}</span>
    </div>
    <div class="legend-item">
      <span class="legend-icon legend-selected" role="presentation"></span>
      <span class="legend-text">{{'Selected seat(s)'|translate:'seatmap'}}</span>
    </div>
  </div>
  <div class="legend-filter">
    <span class="filter-info">{{'Filter available seats suitable for...' | translate:'seatmap'}}</span>
    <div class="legend-filter-items">
      <!-- <div class="filter-item">
        <span class="font-ico-chair" role="presentation" (click)="selectFilter.next(0)"></span>
        <div class="filter-tooltip" >All passengers</div>
      </div> -->
      <div class="filter-item" [ngClass]="{'selected':appliedFilters.indexOf(1)>=0}" [ngClass]="{'selected':seatApplyEnforced.indexOf(1)>=0}" (click)="filterClick(1)">
        <span class="font-ico-head"  role="presentation" ></span>
        <div class="filter-tooltip" >{{'Good for children'|translate:'seatmap'}}</div>
      </div>
      <div class="filter-item" [ngClass]="{'selected':appliedFilters.indexOf(2)>=0}" [ngClass]="{'selected':seatApplyEnforced.indexOf(1)>=0}"(click)="filterClick(2)">
        <span class="font-ico-wheelchairs"   role="presentation" ></span>
        <div class="filter-tooltip">{{'Special assistance'|translate:'seatmap'}}</div>
      </div>
      <div class="filter-item" [ngClass]="{'selected':appliedFilters.indexOf(3) >=0}" [ngClass]="{'selected':seatApplyEnforced.indexOf(1)>=0}" (click)="filterClick(3)">
        <span class="font-ico-paws"   role="presentation" ></span>
        <div class="filter-tooltip" >{{'Good for pets'|translate:'seatmap'}}</div>
      </div>
    </div>
  </div>

</div>