<div class="booking-ptw-details-form-box">
<div class="pax-container" ngForm="confirmationForm" #confirmationForm="ngForm">
  <div class="pax-contact_title">{{'code-confirmation-title'|translate:'push-to-wallet'}}</div>

  <ng-container>

    <div class="field-wrap">
      <div class="field-inner">
        <label for="confirmation-code">{{ 'Confirmation code' | translate:'push-to-wallet' }}</label>
        <input type="text" id="confirmation-code" [(ngModel)]="code" name="confirmation-code" required [my-error]="confirmationCode.dirty" #confirmationCode="ngModel" 
          (ngModelChange)="confirmationCodeChanged()"/>
      </div>
    </div>

    <span class="error-txt" [style.display]="'block'" *ngFor="let error of errors"  [attr.data-error-id]="error">
      {{ 'Error-' + error | translate:'push-to-wallet' }}
    </span>


    <button type="button" class="btn btn-primary" attr.aria-label="{{ 'Click to select and continue' | translate }}"
      (click)="submit()">
      {{ 'Confirm' | translate:'push-to-wallet' }}
    </button>
  </ng-container>

  <check-in-default-modal #confirmedModal modalId="confirmedModalId"
      okButtonText="{{ 'Go to My Account' | translate: 'extras' }}" 
      [okRequired]="true">

      <div class="ptw-modal-content">
        <svg xmlns="http://www.w3.org/2000/svg" width="78.719" height="78.72" viewBox="0 0 78.719 78.72"><g transform="translate(-1787.281 -1741.037)"><path d="M57.164,54.925a2.226,2.226,0,0,1-4.087,1.767,13.928,13.928,0,0,0-12.93-8.173,13.812,13.812,0,0,0-13.005,8.167,2.226,2.226,0,1,1-4.113-1.707A18.249,18.249,0,0,1,40.147,44.066,18.375,18.375,0,0,1,57.164,54.925Z" transform="translate(1866.743 1845.757) rotate(180)" opacity="0.996"/><path d="M67.205,11.518a39.377,39.377,0,1,0,0,55.685A39.421,39.421,0,0,0,67.205,11.518Zm-4.3,51.391a33.3,33.3,0,1,1,0-47.1A33.342,33.342,0,0,1,62.908,62.909ZM24.671,28.7a4.6,4.6,0,1,1,4.6,4.6A4.6,4.6,0,0,1,24.671,28.7Zm20.869,0a4.6,4.6,0,1,1,4.6,4.6A4.6,4.6,0,0,1,45.54,28.7Z" transform="translate(1787.281 1741.037)" opacity="0.996"/></g></svg>
        <div class="title">{{ 'Congratulations' | translate: 'extras' }}</div>
        <p>{{ 'Your unique code was successfully validated' | translate: 'extras' }}</p>
        <p>{{ 'The amount is already available in your wallet.' | translate: 'extras' }}</p>
        
      </div>
  </check-in-default-modal>
</div>
<div class="image-box"> 
  <img [src]="assetsPath + 'img/refund-dog.png'" alt="Member of IATA" >
</div>
</div>
<div class="terms-box">
  <div class="title">
    <h3> {{ 'Terms and Conditions' | translate:'push-to-wallet' }}</h3>
  </div>
  <div class="content">
    {{ 'The funds remain available for 24 months in your Blue Wallet and you can rely on them to purchase new Blue Air flights and services in the future. The currency of the amount refunded in Blue Wallet is Euro (the amounts initially paid in other currencies are converted into Euros at the exchange rate available on the day of the refund request). Please note that, if you choose the refund in Blue Wallet, the same amount is not eligible for other form of refund. 

    The amount is visible in My Profile and can be used in the payment step.' | translate:'push-to-wallet' }}
  </div>
</div>