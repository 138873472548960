import * as tslib_1 from "tslib";
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { BookingStepsService } from '../core/booking-steps.service';
import { FlowManagerService } from '../core/flow-manager.service';
var CanActivateItineraryService = /** @class */ (function (_super) {
    tslib_1.__extends(CanActivateItineraryService, _super);
    function CanActivateItineraryService(flowManager, steps) {
        var _this = _super.call(this, steps, flowManager.applicationFlowService) || this;
        _this.forceFlowInfoRefresh = true;
        return _this;
    }
    return CanActivateItineraryService;
}(BaseCanActivateService));
export { CanActivateItineraryService };
