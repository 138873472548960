import { FlightModel } from './../core/models/flight-model';
import { Constants } from '../constants';
import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import { LoggerService } from '../common-modules/log4ts/logger.service';
import { of as observableOf, BehaviorSubject, Subscription, Subject, Observable } from 'rxjs';
import { refCount, publishReplay, switchMap, map, tap } from 'rxjs/operators';
import { AvailabilityModel } from '../core/models/availability-model';
import * as moment from 'moment';
import { BookingStepsService, ApplicationFlowEnum } from '../core/booking-steps.service';
import { CheckinService } from '../core/checkin.service';
import { BookingFlowService } from '../core/booking-flow.service';
import { BookingSelectionService } from '../core/booking-selection.service';
import { FlightSearchModel } from './models/flight-search-model';
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { HubResponseModel } from './models/hub-response-model';
import { UserActivityService } from '../core/user-activity.service';
import { BookingService } from '../core/booking.service';
import { UserStateService } from '../common-modules/blue-air-common/user-state.service';
import { ProfileService } from '../core/profile.service';
import { BookingFareSelectSyncService } from './booking-fare-select/booking-fare-select-sync-service.service';

@Injectable()
export class BookingFlightSelectService {
  private priceItinerarySubscription: Subscription;
  public selectedFlights = [];

  // hubConnection:HubConnection ;
  errorMessage: BehaviorSubject<object> = new BehaviorSubject({});
  availability: AvailabilityModel;
  searchModel: FlightSearchModel;
  canContinue: boolean = false;
  isInitialSearch: boolean = false;
  isPromotionObs: BehaviorSubject<any> = new BehaviorSubject(true);
  hubResponses: Array<HubResponseModel> = [];
  hubDisplayResponse: Array<any> = [];
  sellKeys: KeyValuePair[] = [];
  selectedFlightSubject: Subject<KeyValuePair[]> = new Subject();
  lockFareAvailability: Observable<any>;
  bundleCodes: string[] = [];
  showBundleUpgrade: boolean;

  isBlueBenefitsSelected: boolean;
  showBlueBenefits: boolean = false;
  paxSuffix: string = '';
  promoId: string = 'rainbow1and1';

  constructor(private http: HttpClient,
    private config: ConfigService,
    private logService: LoggerService,
    private bookingStepsService: BookingStepsService,
    private bookingFlowService: BookingFlowService,
    private selectionService: BookingSelectionService,
    private userActivityService: UserActivityService,
    private bookingService: BookingService,
    private userStateService: UserStateService,
    private configService: ConfigService,
    private profileService: ProfileService,
    private bookingFareSelectSyncService: BookingFareSelectSyncService

  ) {

    if (!(this.bookingStepsService.flow === ApplicationFlowEnum.ManageBooking)) {
      // this.lockFareAvailability = this.selectedFlightSubject.pipe(
      //   switchMap(flights => this.getLockFareDetails(flights, this.searchModel.passengers)),
      //   publishReplay(1),
      //   refCount());
    }

    // this.hubConnection =new HubConnectionBuilder().withUrl(this.config.HubServiceUrl).build();
    // this.hubConnection
    //   .start()
    //   .then(() => this.logService.log('Connection started!'))
    //   .catch(err =>{
    //     this.logService.log('Error while establishing connection :(')
    //   } );

    // this.hubConnection.on("how_searched_for", (currentSearch: string) =>{
    //     if(this.searchModel && this.searchModel.origin){
    //       if(this.searchModel.getHubSearch(0) == currentSearch || this.searchModel.getHubSearch(1) == currentSearch){
    //         this.hubConnection.invoke("IAlsoSearchedFor",currentSearch );
    //       }
    //     }

    // });
    // this.hubConnection.on("connections", (response:any)=>{

    //   let current = this.hubResponses.find(p=>p.route == response.searchedRoute);

    //   if(current){
    //     current.addConnectionCount(response.count);
    //   }else
    //   {
    //     current = new HubResponseModel(response.searchedRoute, response.count);
    //     this.hubResponses.push(current);
    //     current.emmiter.subscribe((t:HubResponseModel)=> {

    //       if (!t) {
    //         return;
    //       }

    //       let xx = this.hubDisplayResponse.find(p => p.searchedRoute == t.route);

    //       if (xx)
    //       {
    //         xx.count = t.cnt
    //       }
    //       else {
    //         xx = {"searchedRoute" :t.route, "count":t.cnt};
    //         this.hubDisplayResponse.push(xx);
    //       }

    //       //clean
    //       var garbage = this.hubDisplayResponse.filter(p=> p.searchedRoute != this.searchModel.getHubSearch(0)
    //           && p.searchedRoute!= this.searchModel.getHubSearch(1));
    //       garbage.forEach(g=>{
    //           this.hubConnection.invoke("ISearchFor", g.searchedRoute);
    //           this.hubDisplayResponse.splice(this.hubDisplayResponse.indexOf(g), 1);
    //       });


    //     });
    //   }
    //   this.logService.log( response);
    // })

    this.showBundleUpgrade = true;
  }
  public getPromo(): Observable<string> {
    return this.http.get(this.configService.PromoCodeUrl).pipe(
      map((data: any) => data.promotion.isInPromotion && data.promotion.promotionAllowed ? data.promotion.promotionCode : null)
    );
  }
  public applyPromo(promocode: string): Observable<boolean> {
    const queryParams = {
      'Promotion': {
        'PromotionCode': promocode
      }
    };

    return this.http.post(this.config.PromoCodeUrl, queryParams)
      .pipe(
        map((data: any) => data)
      );

  }
  public deletePromotion(): Observable<boolean> {
    const queryParams = new HttpParams()
      .set('pc', '');

    return this.http.delete(this.config.PromoCodeUrl, { params: queryParams })
      .pipe(
        map((data: any) => data)
      );

  }

  public getShowBlueBenefitsParam(): Observable<boolean> {
    // if (this.profileService.isAgent) {
    //   this.showBlueBenefits = false;
    // } else {
    //   // show blue benefits prices if pax is not logged in or it's logged in but has no
    //   // promotion bought AND not manage booking flow
    //   const isLoggedIn = this.userStateService.isLoggedIn.getValue();
    //   const userPromoDetails = this.userStateService.userPromoDetails.getValue();
    //   const activePromo = this.userStateService.activePromo.getValue();

    //   this.showBlueBenefits = !activePromo && (this.bookingStepsService.flow !== ApplicationFlowEnum.ManageBooking &&
    //     (!isLoggedIn || (isLoggedIn && userPromoDetails && !userPromoDetails.PromoActive)));
    // }
    this.showBlueBenefits = false;

    return observableOf(this.showBlueBenefits);
  }

  getFlightSearch(): Promise<any> {
    if (this.searchModel && this.searchModel.origin) {
      return Promise.resolve(this.searchModel);
    }

    return this.getRememberSearchModel();
  }

  public getRememberSearchModel() {
    return this.http.get(this.config.RememberSearch)
      .toPromise()
      .then(data => {
        const r = data as any;
        if (r.rememberSearch.origin) {
          this.searchModel = new FlightSearchModel();
          this.searchModel.origin = r.rememberSearch.origin;
          this.searchModel.destination = r.rememberSearch.destination;
          this.searchModel.departureDate = r.rememberSearch.departureDate;
          this.searchModel.returnDate = r.rememberSearch.returnDate;
          this.searchModel.isRoundTrip = r.rememberSearch.isRoundtrip;
          this.searchModel.currency = r.rememberSearch.currency;
          this.searchModel.passengers = r.rememberSearch.passengerCounts;
          this.searchModel.usePromo = r.rememberSearch.useActivePassengerPromotion;
          this.bookingFlowService.currency = this.searchModel.currency;

          const usePromotion = sessionStorage.getItem('usePromo');
          this.searchModel.usePromo = usePromotion === 'false' ? false : r.rememberSearch.useActivePassengerPromotion;
          if (usePromotion) {
            sessionStorage.removeItem('usePromo');
          }
        } else {
          this.searchModel = null;
        }


        // this.bookingFlowService.priceBreakdown  = [{
        //   'from':this.searchModel.origin,
        //   'to':this.searchModel.destination
        // }];
        // if(this.searchModel.isRoundTrip)
        // this.bookingFlowService.priceBreakdown.push({
        //   "from":this.searchModel.destination,
        //   "to":this.searchModel.origin
        // });

        return this.searchModel;
      });
  }


  public getAvailableBenefitPromos(currency: string): Observable<any[]> {
    const queryParams = new HttpParams()
      .set('currencyCode', currency)
      .set('list', 'true');

    return this.http.get(this.config.PromotionUrl, { params: queryParams })
      .pipe(
        map((data: any) => data && data.memberPromotionInfo && data.memberPromotionInfo.items)
      );
  }

  public isMoveFlightsCriteria(journeys?: any[]): boolean {

    var isStationMoveAvailable = false;
    var isSCHGMoveAvailable = false;
    var isTimeChangeMoveAvailable = false;

    if (journeys) {
      isStationMoveAvailable = journeys.some(j => j.segments && j.segments.some(s => s.legs && s.legs.some(l => l.legInfo.status == "Canceled")));

      isSCHGMoveAvailable = journeys.some(j => j.segments && j.segments.some(s => s.changeReasonCode && s.changeReasonCode == "SCHG" && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3));

      isTimeChangeMoveAvailable = journeys.some(j => j.segments && j.segments.some(s => (!s.changeReasonCode || s.changeReasonCode && s.changeReasonCode != "SCHG") && s.paxSegments.some(p => p.timeChanged) && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3));

      return isStationMoveAvailable || isSCHGMoveAvailable || isTimeChangeMoveAvailable;
    }

    return Boolean(sessionStorage.getItem(Constants.MoveFlightsFlow));
  }

  getFlights(): Promise<any> {

    // this.hubConnection.invoke('ISearchFor', this.searchModel.getHubSearch(0));

    // if (this.searchModel.isRoundTrip) {
    //   this.hubConnection.invoke('ISearchFor', this.searchModel.getHubSearch(1));
    // }
    const isMoveFlightsSearch = this.isMoveFlightsCriteria();
    const baseUrl = isMoveFlightsSearch ? this.config.MoveFlightSearch :
      (this.isInitialSearch ? this.config.FlightSearch : this.config.BookingFlightSearch);

    return this.http.get(baseUrl + '?' + this.searchModel.getQuery(
      this.showBlueBenefits,
      this.paxSuffix,
      isMoveFlightsSearch))
      .toPromise()
      .then((data: any) => {
        this.isInitialSearch = false;
        this.availability = (isMoveFlightsSearch ? data.moveAvailability : data.availability) as AvailabilityModel;
        this.bookingFlowService.currency = this.availability.bookingCurrencyCode;

        if (data.dataLayer && data.dataLayer.serialized) {
          try {
            const dataLayer = JSON.parse(data.dataLayer.serialized);
            this.userActivityService.pushToDatalayer(dataLayer);
          } catch (err) {
            this.userActivityService.trackException(new Error('Could not push to dataLayer: ' + err));
          }
        }

        // hack-ish way to calculate travelTime for segments.
        // (Will not work if more than 1 leg segment)
        if (this.availability && this.availability.trips) {
          this.availability.trips.forEach(t => {
            t.flightDates.forEach(fd => {
              fd.flights.forEach(f => f.segments.forEach((s, sindex) => {
                const leg = f.legs[sindex];
                const departureDate = moment(leg.std).subtract(leg.legInfo.deptLtv, 'minutes');
                const arrivalDate = moment(leg.sta).subtract(leg.legInfo.arrvLtv, 'minutes');
                const duration = moment.duration(arrivalDate.diff(departureDate));

                s.travelTime = `${duration.days()}.${duration.hours()}:${duration.minutes()}:${duration.seconds()}`;
              }));
            });
          });
        }
        // this.isPromotionObs.next(this.availability.isPromotion);
        this.isPromotionObs.next(true);
      });
  }


  resetPriceItinerary() {
    this.sellKeys = [];
    this.bundleCodes = [];
    this.canContinue = false;
    this.bookingFlowService.canContinue.next(this.canContinue);
    this.selectedFlightSubject.next([]);
  }

  updatePriceItinerary(flight, fare, tripIndex) {
    const index = this.sellKeys.findIndex(p => p.key === tripIndex)
    if ((flight == null || fare == null) && index >= 0) {
      this.sellKeys.splice(index, 1);
      this.bundleCodes.splice(index, 1);
    } else {
      if (index >= 0) {
        this.sellKeys[index] = { key: tripIndex, value: fare.sellKey + '|' + flight.sellKey };
      } else {
        this.sellKeys.splice(tripIndex, 0, { key: tripIndex, value: fare.sellKey + '|' + flight.sellKey });
      }
    }

    this.bundleCodes = [];
    this.sellKeys.forEach((sk, idx) => {
      this.bundleCodes[idx] = this.bookingFareSelectSyncService.currentBundleSelected;
    });
    if ((this.searchModel.isRoundTrip && this.sellKeys.length > 1) || (!this.searchModel.isRoundTrip && this.sellKeys.length === 1)) {
      this.canContinue = true;
    } else {
      this.canContinue = false;
    }

    if (!(this.bookingStepsService.flow === ApplicationFlowEnum.ManageBooking) && flight) {
      const currentFlight = this.selectedFlights.find(x => x.key === tripIndex);
      if (currentFlight) {
        currentFlight.value = flight;
      } else {
        this.selectedFlights.push({ key: tripIndex, value: flight });
      }

      this.selectedFlightSubject.next(this.selectedFlights);
    }

    this.bookingFlowService.canContinue.next(this.canContinue);

    const parameters: any = { 'priceItinerary': { 'SellKeys': this.sellKeys.map(p => p.value) } };

    if (this.bundleCodes.every(bundle => bundle !== null)) {
      parameters.priceItinerary.BundleCodes = this.bundleCodes;
    }

    if (this.sellKeys.length > 0) {
      if (this.priceItinerarySubscription) {
        this.priceItinerarySubscription.unsubscribe();
      }
      const priceItineraryRequest = this.bookingFlowService.loadPriceItinerary(this.sellKeys.map(s => s.value), this.bundleCodes);

      this.priceItinerarySubscription = priceItineraryRequest
        .subscribe(itinerary => {
          if (itinerary.passengers.items.length > 0) {
            itinerary.priceItinerary.passengers.forEach(p => {
              const pax = itinerary.passengers.items.find(i => i.passengerNumber === p.passengerNumber);
              if (!p.hasInfant && !p.infant && pax.hasInfant && pax.infant) {
                p.hasInfant = pax.hasInfant;
                p.infant = pax.infant;
                p.infant.attachedPassengerNumber = p.passengerNumber;
              }
            });
          }
          if (this.sellKeys.length > 0) {
            this.bookingFlowService.updateShoppingCartFromItinerary(itinerary, this.showBlueBenefits && !this.isBlueBenefitsSelected);
            this.selectionService.updateData(
              {
                items: itinerary.priceItinerary.passengers
              },
              itinerary.convertedJourneys, false);
          }
        });
      return priceItineraryRequest;
    } else {
      this.selectionService.updateData(null, null);
      this.bookingFlowService.updateShoppingCartFromItinerary(null, true);
      return new Observable<any>();
    }

    // this.checkinService.loadPriceBreakdown();
    // this.http.get(this.config.BookingPriceBrakedown, {params:parameters,observe: 'response'})
    //     .toPromise()
    //     .then(data=>{

    //           // this.checkinService.priceBreakdown = (data['body'] as any).priceBreakdown;
    //           // this.checkinService.priceBreakdownSub.next(this.checkinService.priceBreakdown);


    // });
  }

  sellFare(journeyFares) {
    const paxSuffix = this.isBlueBenefitsSelected ? this.paxSuffix : '';
    const sellPaxRequest = this.searchModel.passengers
      .filter(p => p.paxType !== Constants.InfantPaxType)
      .map(p => Object.assign({}, p, { paxType: p.paxType + paxSuffix }));

    const data: any = {
      'availability': {
        'marketFareKeys': this.sellKeys.map(p => p.value),
        'bundleCodes': this.bundleCodes,
        'journeyFares': journeyFares
      },
      'passengerCounts': sellPaxRequest
    };

    if (this.isBlueBenefitsSelected) {
      data.memberPromotionSell = { 'promoId': this.promoId };
    }

    return this.http.post(this.config.SellFlight, data).toPromise();
  }

  public sellMovedFlight(): Promise<any> {
    const data: any = {
      'moveFlightSell': {
        'marketFareKeys': this.sellKeys.map(p => p.value),
        'tripIndex': Number(sessionStorage.getItem(Constants.SelectedFlightIndexForChange))
      }
    };

    return this.http.post(this.config.SellMovedFlight, data).toPromise();
  }

  loadFlightsPage() {
    return this.http.get(this.config.Flights).toPromise();
  }

  getLockFareDetails(flights: any, passengerTypes: any): Observable<any> {
    if (!flights.length || this.searchModel.isRoundTrip && flights.length == 1 || this.isBlueBenefitsSelected) {
      return observableOf({ lockFare: { isAvailable: false } });
    }
    let params = new HttpParams();
    params = params.append('departureDateTime', flights[0].value.standardTimeOfDeparture);
    params = params.append('flightsCount', JSON.stringify(flights.map(flight => ({
      'tripIndex': flights.indexOf(flight),
      'segmentCount': flight.value.segments.length
    }))));
    params = params.append('passengerTypes',
      JSON.stringify(passengerTypes.filter(pax => pax.count > 0)
        .map(pax => (
          {
            'paxTypeCode': pax.paxType,
            'count': pax.count
          }))));

    return this.http.get(this.config.LockFareUrl, { params: params });
  }

  lockFare(flights: any, passengerTypes: any): Promise<any> {
    return this.http.post(this.config.LockFareUrl, {
      lockFare: {
        departureDateTime: flights[0].departureStation.dateUtc,
        passengerTypes: JSON.stringify(passengerTypes.filter(pax => pax.count > 0).map(pax => ({
          'paxTypeCode': pax.paxType,
          'count': pax.count
        }))),
        flightsCount: JSON.stringify(flights.map(flight => ({
          'tripIndex': flights.indexOf(flight),
          'segmentCount': flight.segments.length
        })))
      }
    }).toPromise();
  }

  resetSelectedFlightsList() {
    this.selectedFlights = [];
  }
}
export class KeyValuePair {
  key: any;
  value: any;
}
export class PassengerType {
  typeName: string;
  number: number
}


