import * as tslib_1 from "tslib";
import { BookingService } from './../../core/booking.service';
import { BookingStepsService } from './../../core/booking-steps.service';
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';
import { Constants } from '../../constants';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { CheckInLuggageService } from '../../core/check-in-luggage.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
var SsrBoxContentLuggageComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsrBoxContentLuggageComponent, _super);
    // bagsNumber: number = 1;
    // bagsNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(1);
    function SsrBoxContentLuggageComponent(ssrsService, bookingSteps, bookingService, router, luggageService, elRef) {
        var _this = _super.call(this, ssrsService) || this;
        _this.ssrsService = ssrsService;
        _this.bookingSteps = bookingSteps;
        _this.bookingService = bookingService;
        _this.router = router;
        _this.luggageService = luggageService;
        _this.elRef = elRef;
        _this.$unsubscribe = new Subject();
        _this.promoDiscount = null;
        _this.modalId = 'pbagModal';
        _this.onNeedSave$ = new Subject();
        _this.passengersSelection = new Map();
        _this.saveChangesEvent = new EventEmitter();
        _this.ssrType = SsrType.Baggage;
        _this.recordLocator = _this.bookingService.getBooking().bookingDetail.recordLocator;
        _this.promoDiscount = _this.ssrsService.getPromoDiscount(_this.ssrType);
        return _this;
    }
    SsrBoxContentLuggageComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        // this.luggageService.init(this.flight.flightUniqueId, this.passengers);
        // this.luggageService
        //   .$checkingLuggageChanged
        //   .pipe(
        //     filter(item => item.id === this.flight.flightUniqueId)
        //   )
        //   .subscribe(item => {
        //     this.selectSsr(
        //       item.index,
        //       item.ssrCode,
        //       item.qty,
        //       false
        //     );
        //     debugger;
        //     this.updateSummary();
        //     this.saveChangesEvent.emit();
        //     console.log(JSON.stringify(item));
        //   })
        //this.passengers.forEach( (p, pix) => this.selectSsr(pix, null, 0));
        this.passengers.forEach(function (p, index) {
            _this.passengersSelection.set(index, _this.hasPassengerBags(p));
            if (!_this.ssrsService.noBagsSync.find(function (b) { return b.passengerIndex == index && b.flight == _this.flight.sellKey; })) {
                _this.ssrsService.noBagsSync.push({
                    flight: _this.flight.sellKey,
                    passengerIndex: index,
                    noBags: true
                });
            }
        });
        this.setLuggagePassengerMap(this.passengers[0]);
        this.onNeedSave$
            .pipe(takeUntil(this.$unsubscribe), debounceTime(1000))
            .subscribe(function (_) {
            _this.saveChangesEvent.emit();
        });
    };
    SsrBoxContentLuggageComponent.prototype.ngOnDestroy = function () {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
    };
    SsrBoxContentLuggageComponent.prototype.onResize = function (event) {
        this.mobileMatches = event.target.innerWidth < Constants.mobileScreenWith;
    };
    SsrBoxContentLuggageComponent.prototype.onEnableSameOptionsForAllFlightsChanged = function (value) {
        _super.prototype.onEnableSameOptionsForAllFlightsChanged.call(this, value);
        if (!value)
            return;
        this.onNeedSave$.next();
    };
    // public set sameOptionsForAllFlights(val: boolean) {
    //   this._sameOptionsForAllFlights = val;
    //   if (val) {
    //     this.copySsrsToReturnFlight();
    //   }
    //   this.saveChangesEvent.emit();
    // }
    SsrBoxContentLuggageComponent.prototype.onSelectSameValueForAllFlights = function (selected) {
        if (selected) {
        }
        this.onNeedSave$.next();
    };
    SsrBoxContentLuggageComponent.prototype.increaseBagsNumber = function (index, ssrCode, newQty) {
        this.selectSsr(index, ssrCode, newQty);
        this.onNeedSave$.next();
    };
    SsrBoxContentLuggageComponent.prototype.decreaseBagsNumber = function (index, ssrCode, newQty) {
        this.selectSsr(index, ssrCode, newQty);
        this.onNeedSave$.next();
    };
    SsrBoxContentLuggageComponent.prototype.selectSsr = function (passengerIndex, ssrCode, newSsrQuantity, isExternalCall) {
        var _this = this;
        if (passengerIndex === void 0) { passengerIndex = null; }
        if (ssrCode === void 0) { ssrCode = null; }
        if (newSsrQuantity === void 0) { newSsrQuantity = 0; }
        if (isExternalCall === void 0) { isExternalCall = false; }
        if (!ssrCode && passengerIndex !== null && newSsrQuantity === 0 && !this.mobileMatches) {
            var passengers = this.getSelectedPassengers(passengerIndex);
            passengers.forEach(function (p) {
                var ssrs = p.getSelectedSsrs();
                ssrs.forEach(function (s) { return _super.prototype.selectSsr.call(_this, passengerIndex, s.ssrCode, newSsrQuantity, isExternalCall); });
            });
        }
        else {
            _super.prototype.selectSsr.call(this, passengerIndex, ssrCode, newSsrQuantity, isExternalCall);
        }
        this.updateSummary();
        if (passengerIndex == undefined && this.selectedPassenger != undefined) {
            passengerIndex = this.selectedPassenger;
        }
        this.noBags(passengerIndex, false);
        this.passengersSelection.set(passengerIndex, this.hasPassengerBags(this.passengers[passengerIndex]));
        this.setLuggagePassengerMap(this.passengers[passengerIndex]);
    };
    SsrBoxContentLuggageComponent.prototype.resetSsr = function (passengerIndex) {
        _super.prototype.selectSsr.call(this, passengerIndex, "SBAG", 0);
        _super.prototype.selectSsr.call(this, passengerIndex, "BAG", 0);
        _super.prototype.selectSsr.call(this, passengerIndex, "XSBG", 0);
        this.onNeedSave$.next();
    };
    SsrBoxContentLuggageComponent.prototype.afterSelectSsr = function () {
        _super.prototype.afterSelectSsr.call(this);
        this.updateSummary();
    };
    SsrBoxContentLuggageComponent.prototype.initFlightSsrList = function () {
        _super.prototype.initFlightSsrList.call(this);
        this.availableSsrs = this.availableSsrs.sort(function (a, b) { return a.price > b.price ? 1 : a.price === b.price ? 0 : -1; });
        this.updateSummary();
    };
    SsrBoxContentLuggageComponent.prototype.updateSummary = function () {
        var _this = this;
        var groupBy = this.passengers
            .map(function (p) { return p.getSelectedSsrs(); })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .reduce(function (group, b) {
            group[b.ssrCode] = group[b.ssrCode] || 0;
            group[b.ssrCode] += b.selectedQuantity;
            return group;
        }, {});
        this.summary = Object.keys(groupBy).map(function (ssrCode) { return ({ ssrCode: ssrCode, count: groupBy[ssrCode] }); });
        this.summary = this.summary.sort(function (a, b) {
            var ai = _this.availableSsrs.findIndex(function (s) { return s.ssrCode === a.ssrCode; });
            var bi = _this.availableSsrs.findIndex(function (s) { return s.ssrCode === b.ssrCode; });
            return ai > bi ? 1 : ai === bi ? 0 : -1;
        });
        this.summaryTotal = this.summary.reduce(function (a, b) { return a + b.count; }, 0);
    };
    SsrBoxContentLuggageComponent.prototype.isSsrQuantityAvailable = function (quantity, passengerSsr, ssr) {
        var selectedSsr = passengerSsr.getSelectedSsr(ssr);
        var selectedQuantity = 0;
        var paidQuantity = 0;
        if (selectedSsr) {
            selectedQuantity = selectedSsr.selectedQuantity;
            paidQuantity = selectedSsr.paidQuantity;
        }
        var isAvailablePerAlreadyPaid = selectedSsr ? quantity >= selectedSsr.paidQuantity : true;
        if (!isAvailablePerAlreadyPaid) {
            return false;
        }
        var totalQuantity = passengerSsr.ssrs.reduce(function (a, b) { return a + b.selectedQuantity; }, 0);
        var isAvailablePerPassenger = totalQuantity - selectedQuantity + quantity < 4;
        if (!isAvailablePerPassenger) {
            return false;
        }
        var selectedQuantityPerFlight = this.passengers
            .reduce(function (a, b) { return a.concat(b.ssrs); }, [])
            .filter(function (ps) { return ps.ssrCode === ssr.ssrCode; })
            .reduce(function (a, b) { return a + b.selectedQuantity; }, 0);
        var isAvailablePerNest = ssr.limitPerNest == null || selectedQuantityPerFlight - selectedQuantity + quantity <= ssr.limitPerNest;
        return isAvailablePerNest;
    };
    SsrBoxContentLuggageComponent.prototype.showLuggageBagDetails = function () {
        this.ssrsService.isDisplayedBagDetailsModal = true;
    };
    SsrBoxContentLuggageComponent.prototype.hasPassengerBags = function (passenger) {
        if (!passenger || !passenger.ssrs) {
            return false;
        }
        return true;
        var bagQty = (passenger.ssrs.filter(function (s) { return s.ssrCode == 'BAG'; }))[0] ? (passenger.ssrs.filter(function (s) { return s.ssrCode == 'BAG'; }))[0].selectedQuantity : 0;
        var sBagQty = (passenger.ssrs.filter(function (s) { return s.ssrCode == 'SBAG'; }))[0] ? (passenger.ssrs.filter(function (s) { return s.ssrCode == 'SBAG'; }))[0].selectedQuantity : 0;
        var xBagQty = (passenger.ssrs.filter(function (s) { return s.ssrCode == 'XSBG'; }))[0] ? (passenger.ssrs.filter(function (s) { return s.ssrCode == 'XSBG'; }))[0].selectedQuantity : 0;
        var noBagsSelected = this.checkNoBagsSelected(this.passengers.indexOf(passenger));
        return ((bagQty + sBagQty + xBagQty > 0) || noBagsSelected) ? true : false;
    };
    SsrBoxContentLuggageComponent.prototype.checkAllPassengerBags = function () {
        var e_1, _a;
        var allHaveOptionSelected = true;
        try {
            for (var _b = tslib_1.__values(this.passengersSelection.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var qty = _c.value;
                if (!qty) {
                    allHaveOptionSelected = false;
                    break;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return allHaveOptionSelected;
    };
    SsrBoxContentLuggageComponent.prototype.setLuggagePassengerMap = function (passenger) {
        if (!passenger || !passenger.passenger) {
            return;
        }
        this.ssrsService.luggagePassengersMap.set(passenger.passenger.flightUniqueId, this.checkAllPassengerBags());
    };
    SsrBoxContentLuggageComponent.prototype.noBags = function (passengerIndex, setBool) {
        var _this = this;
        if (passengerIndex == undefined && this.selectedPassenger != undefined) {
            passengerIndex = this.selectedPassenger;
        }
        var noBag = this.ssrsService.noBagsSync.find(function (n) { return n.flight == _this.flight.sellKey && n.passengerIndex == passengerIndex; });
        if (noBag && passengerIndex != undefined) {
            noBag.noBags = setBool;
        }
        if (setBool) {
            this.passengersSelection.set(passengerIndex, this.hasPassengerBags(this.passengers[passengerIndex]));
            this.setLuggagePassengerMap(this.passengers[passengerIndex]);
            this.resetSsr(passengerIndex);
        }
    };
    SsrBoxContentLuggageComponent.prototype.checkNoBagsSelected = function (passengerIndex) {
        var _this = this;
        if (passengerIndex == undefined && this.selectedPassenger != undefined) {
            passengerIndex = this.selectedPassenger;
        }
        var noBag = this.ssrsService.noBagsSync.find(function (n) { return n.flight == _this.flight.sellKey && n.passengerIndex == passengerIndex; });
        return noBag ? noBag.noBags : false;
    };
    SsrBoxContentLuggageComponent.prototype.getPassengerError = function (passengerIndex) {
        return !this.passengersSelection.get(passengerIndex);
    };
    SsrBoxContentLuggageComponent.prototype.scrollTo = function () {
        this.elRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
    };
    SsrBoxContentLuggageComponent.prototype.mobileShowAllOptionMessage = function (flightkey) {
        return !(this.ssrsService.luggagePassengersMap.get(flightkey));
    };
    return SsrBoxContentLuggageComponent;
}(SsrBoxContentBase));
export { SsrBoxContentLuggageComponent };
