import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrModel } from '../ssr-model';
import {SelectSsrEvent2Params} from './ssr-box-content-pets-events-params';


@Component({
  selector: 'check-in-ssr-box-content-pets',
  templateUrl: './ssr-box-content-pets.component.html',
  styleUrls: ['./ssr-box-content-pets.component.scss']
})
export class SsrBoxContentPetsComponent extends SsrBoxContentBase {
  carryOnSsrs: SsrModel[] = [];
  inHoldSsrs: SsrModel[] = [];
  uniqueId: number;

  @Output() selectSsrEvent2 = new EventEmitter<SelectSsrEvent2Params>();

  constructor(private ssrsService: SsrsService) {
    super(ssrsService);

    this.ssrType = SsrType.Pet;
    this.uniqueId = Math.floor(Math.random() * 1000) + 1;
  }

  protected initFlightSsrList() {
    super.initFlightSsrList();

    this.carryOnSsrs = this.availableSsrs.filter(s => s.isCarryOn);
    this.inHoldSsrs = this.availableSsrs.filter(s => !s.isCarryOn);
  }

  resetSsr() {
    this._removePetInCabin();
    this.selectSsrEvent(null);
  }
  private _removePetInCabin() {
    this.selectSsr(null, "PETC", 0);
  }

  addPetToCabin(passengerIndex: number) {
    this.selectSsr(passengerIndex, 'PETC', 1);
    this.selectSsrEvent({
      onCancelSeatRemoval: () => this.resetSsr()
    });
  }

  selectPetInHold() {
    this.selectSsrEvent({
      onCancelSeatRemoval: () => this.passengers.forEach((p, index) => this.removeAllSsrs(index))
    });
  }
  selectSsrEvent(params: SelectSsrEvent2Params) {
    this.selectSsrEvent2.emit(params || {});
  }

  checkSameSsrOptionsForAllFlights() {
    this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
    if (this.sameOptionsForAllFlights) {
      this.selectSsrEvent(null);
    }
  }
}
