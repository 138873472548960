import * as tslib_1 from "tslib";
import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { CheckinSteps, BookingStepsService } from './../../core/booking-steps.service';
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { BookingFlightSelectService } from './../booking-flight-select.service';
import { BookingService } from './../../core/booking.service';
import { ResourceService } from './../../common-modules/blue-air-common/resource.service';
import { OnInit, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { CalendarDatePickerComponent } from '../calendar-date-picker/calendar-date-picker.component';
import { BookingFlowService } from '../../core/booking-flow.service';
import { PassengerTypeSelectionViewModel } from '../models/passenger-selection-view-model';
import { PassengerTypePickerComponent } from '../passenger-type-picker/passenger-type-picker.component';
import { FlightSearchModel } from '../models/flight-search-model';
import { Constants } from '../../constants';
import { CurrencyManagerService } from '../../core/currency-manager.service';
import { ProfileService } from 'src/app/core/profile.service';
import { SessionStorage } from 'ngx-webstorage';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { switchMap, filter } from 'rxjs/operators';
import { ConfigService } from '../../core/config.service';
import { isNullOrUndefined, isUndefined } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
var ChangeFlightComponent = /** @class */ (function () {
    function ChangeFlightComponent(bookingFlowService, bookingService, bookingStepsService, currencyManager, flightSearch, loadingSpinnerService, profileService, resourceService, configService, translateService, modalService, userStateService, sanitizer) {
        var _this = this;
        this.bookingFlowService = bookingFlowService;
        this.bookingService = bookingService;
        this.bookingStepsService = bookingStepsService;
        this.currencyManager = currencyManager;
        this.flightSearch = flightSearch;
        this.loadingSpinnerService = loadingSpinnerService;
        this.profileService = profileService;
        this.resourceService = resourceService;
        this.configService = configService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.userStateService = userStateService;
        this.sanitizer = sanitizer;
        this.specialPriceModalId = 'specialPriceModalId';
        this.format = 'DD/MM/YYYY';
        this.changeFlightSpinnerId = 'changeFlightSpinnerId';
        this.selectedMarketName = "sardinia";
        this.showSpecialPrice = false;
        this._selectedSpecialMarketOption = 'noSpecialMarket';
        this.isPromoSelected = true;
        this.search = new EventEmitter();
        this.isProfilePromoActive = false;
        this.totalPaxCount = 9;
        this.totalPromoPaxCount = 9;
        this.showCmsView = false;
        this.priceOptionList = [
            {
                value: 'noSpecialMarket', text: 'Clear selection'
            },
            {
                value: 'specialPrice', text: this.getSpecialPriceCheckboxText()
            },
            {
                value: 'psoWorker', text: 'Are you a worker in Sardinia?'
            }
        ];
        this.selectedIndex = 0;
        this.today = moment().format(this.format);
        this.passengerSelection = new PassengerTypeSelectionViewModel();
        this.passengerSelection.adults = 1;
        this.superStations = [];
        this.bookingFlowService.loadSuperStations().then(function (s) {
            _this.superStations = s;
            _this.loadingSpinnerService.hideSpinnerById(_this.changeFlightSpinnerId);
        });
        this.bookingStepsSubscription = this.bookingStepsService.currentStep.subscribe(function (currentStep) {
            if (currentStep) {
                _this.isOnFlightsPage = currentStep === CheckinSteps.flights;
            }
        });
        this.availableCurrencies = this.currencyManager.currencyList.map(function (c) { return ({ text: c, value: c }); });
        this.currency = this.bookingFlowService.currency || 'EUR';
        if (profileService.isAgent) {
            //resourceService.getClassesOfService().pipe(take(1)).subscribe(cs => this.classesOfService = cs);
            this.classOfService = this.classOfService || 'Y';
            this.isAgent = true;
        }
        this.initUserData();
        this.bookingService.memberPromotionObs.subscribe(function (data) {
            if (data && data.memberPromotion.isActive) {
                _this.isProfilePromoActive = true;
                _this.totalPromoPaxCount = data.memberPromotion.paxCount;
                _this.totalPaxCount = _this.totalPromoPaxCount;
            }
        });
    }
    Object.defineProperty(ChangeFlightComponent.prototype, "selectedSpecialMarketOption", {
        get: function () {
            return this._selectedSpecialMarketOption;
        },
        set: function (value) {
            this._selectedSpecialMarketOption = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeFlightComponent.prototype, "isSpecialMarket", {
        get: function () {
            return this.selectedSpecialMarketOption == 'specialPrice';
        },
        set: function (value) {
            this.selectedSpecialMarketOption = "specialPrice";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeFlightComponent.prototype, "outbound", {
        get: function () {
            var v = moment(this.outboundDate, this.format);
            return v.isValid() ? v.toDate() : null;
        },
        set: function (value) {
            var v = moment(value);
            this.outboundDate = v.isValid() ? v.format(this.format) : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeFlightComponent.prototype, "inbound", {
        get: function () {
            var v = moment(this.inboundDate, this.format);
            return v.isValid() ? v.toDate() : null;
        },
        set: function (value) {
            if (value) {
                var v = moment(value);
                this.inboundDate = v.isValid() ? v.format(this.format) : null;
            }
            else {
                this.inboundDate = null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeFlightComponent.prototype, "departure", {
        get: function () {
            return this._departure;
        },
        set: function (value) {
            this._departure = value;
            this.checkIfIsSpecialPrice();
            // this.setSpecialPriceIframeUrl();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeFlightComponent.prototype, "destination", {
        get: function () {
            return this._destination;
        },
        set: function (value) {
            this._destination = value;
            this.checkIfIsSpecialPrice();
            // this.setSpecialPriceIframeUrl();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeFlightComponent.prototype, "tripType", {
        get: function () {
            return this.isOneWay ? Constants.OneWayTrip : Constants.RoundTrip;
        },
        set: function (value) {
            this.isOneWay = value === Constants.OneWayTrip;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ChangeFlightComponent.prototype, "flightSearchModel", {
        set: function (model) {
            if (model) {
                this.departure = model.origin;
                this.destination = model.destination;
                this.outboundDate = moment(model.departureDate).format(this.format);
                this.inboundDate = moment(model.returnDate).format(this.format);
                this.isOneWay = !model.isRoundTrip;
                this.passengerSelection = new PassengerTypeSelectionViewModel().initFromPassengersSearch(model.passengers);
                this.currency = model.currency;
            }
        },
        enumerable: true,
        configurable: true
    });
    ChangeFlightComponent.prototype.ngOnInit = function () {
    };
    ChangeFlightComponent.prototype.ngOnDestroy = function () {
        if (this.bookingStepsSubscription) {
            this.bookingStepsSubscription.unsubscribe();
        }
        if (this.flightsToChangeSubscription) {
            this.flightsToChangeSubscription.unsubscribe();
        }
        if (this.userStateSubscription) {
            this.userStateSubscription.unsubscribe();
        }
    };
    ChangeFlightComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.flightsToChangeSubscription = this.bookingService.flightsToChangeObs.subscribe(function (data) {
            if (data && data.length > 0) {
                _this.flightsToChange = data;
                sessionStorage.setItem(Constants.SelectedFlightIndexForChange, (_this.flightsToChange[0].journeySequenceNumber - 1).toString());
            }
        });
    };
    ChangeFlightComponent.prototype.changePriceOptionSelection = function (event, index) {
        this.selectedIndex = event.target.checked ? index : undefined;
        this.selectedSpecialMarketOption = this.priceOptionList[index].value;
    };
    ChangeFlightComponent.prototype.outboundDateSelected = function (newDate) {
        var outb = moment(this.outboundDate, this.format);
        var inb = moment(this.inboundDate, this.format);
        if (outb.isAfter(inb)) {
            this.inboundDate = '';
        }
        if (this.inboundDatePicker) {
            this.inboundDatePicker.open();
        }
        else {
            if (this.passengerTypePicker) {
                this.passengerTypePicker.focusIn();
            }
        }
    };
    ChangeFlightComponent.prototype.inboundDateSelected = function (newDate) {
        if (this.passengerTypePicker) {
            this.passengerTypePicker.focusIn();
        }
    };
    ChangeFlightComponent.prototype.destinationStationSelected = function (newStation) {
        // de-comment this if we need to go directly to date-picker
        // if (newStation && this.outboundDatePicker) {
        //   setTimeout(() => this.outboundDatePicker.open(), 5);
        // }
    };
    ChangeFlightComponent.prototype.oneWayChanged = function () {
        if (this.isOneWay) {
            this.inboundDate = '';
        }
        else {
            this.inboundDate = this.outboundDate;
        }
    };
    ChangeFlightComponent.prototype.searchFlight = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.departure || !this.destination || !this.outbound ||
                            (!this.isOneWay && (!this.inbound || this.inbound < this.outbound)) ||
                            this.passengerSelection.count === 0) {
                            return [2 /*return*/];
                        }
                        if (this.passengerSelection.count > this.totalPaxCount) {
                            this.modalService.open("numberOfPaxExcededModal");
                            return [2 /*return*/];
                        }
                        if (!this.isSpecialMarket) return [3 /*break*/, 1];
                        // this.specialPriceModal.openPopup(async (isOk) => {
                        //   if (!isOk) return;
                        //   await this.doSearchFlight();
                        // })
                        this.showCmsView = true;
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.doSearchFlight()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ChangeFlightComponent.prototype.continueSearchFlight = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.doSearchFlight()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ChangeFlightComponent.prototype.cancelSearchFlight = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.showCmsView = false;
                setTimeout(function () {
                    _this.loadingSpinnerService.hideSpinnerById(_this.changeFlightSpinnerId);
                }, 0);
                return [2 /*return*/];
            });
        });
    };
    ChangeFlightComponent.prototype.doSearchFlight = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var searchModel;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getFlightSearchModel(this.currency)];
                    case 1:
                        searchModel = _a.sent();
                        this.bookingFlowService.currency = this.currency;
                        this.search.next(searchModel);
                        this.flightSearch.showBundleUpgrade = true;
                        this.setFlightsAndNavigate(searchModel);
                        return [2 /*return*/];
                }
            });
        });
    };
    ChangeFlightComponent.prototype.changeFlight = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentBooking, changeFlight, showChangeBookingPopup, journeysCount;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                currentBooking = this.bookingService.getBooking();
                changeFlight = function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        // todo: modify this: send event to booking info component
                        //
                        this.loadingSpinnerService.showSpinnerById(this.changeFlightSpinnerId);
                        // const result = await this.bookingService.clearSnapshot();
                        this.bookingService.createSnapshot().then(function (data) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                            var searchModel_1;
                            var _this = this;
                            return tslib_1.__generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!data) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.getFlightSearchModel(currentBooking.convertedJourneys.currencyCode)];
                                    case 1:
                                        searchModel_1 = _a.sent();
                                        this.search.next(searchModel_1);
                                        this.bookingService.refresh(true).then(function (res) {
                                            if (res) {
                                                _this.bookingService.changeFlight(data.bookingDetail.recordLocator, searchModel_1, currentBooking.convertedJourneys.journeys, _this.flightsToChange).then(function (result) {
                                                    if (result && result.starterCancelRebook.canCancel) {
                                                        _this.setFlightsAndNavigate(searchModel_1);
                                                    }
                                                    else {
                                                        _this.ChangeBookingNotAllowed.openPopup({});
                                                    }
                                                    _this.loadingSpinnerService.hideSpinnerById(_this.changeFlightSpinnerId);
                                                });
                                            }
                                        });
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                    });
                }); };
                this.setPassengerCountForChangeFlight(currentBooking);
                showChangeBookingPopup = false;
                journeysCount = currentBooking.convertedJourneys.journeys.length;
                if (this.isOneWay && journeysCount > 1) {
                    showChangeBookingPopup = this.flightsToChange[0].journeySequenceNumber > 1 ?
                        moment(this.outboundDate, this.format).isBefore(moment(currentBooking.convertedJourneys.journeys[0].segments[0].sTD)) :
                        moment(this.outboundDate, this.format).isAfter(moment(currentBooking.convertedJourneys.journeys[journeysCount - 1].segments[0].sTD));
                }
                if (showChangeBookingPopup) {
                    this.ChangeFlightWarningModal.openPopup(function (okSelected) {
                        if (okSelected) {
                            changeFlight();
                        }
                    });
                }
                else {
                    changeFlight();
                }
                return [2 /*return*/];
            });
        });
    };
    ChangeFlightComponent.prototype.setPassengerCountForChangeFlight = function (booking) {
        this.passengerSelection.adults = booking.passengers.items.filter(function (pax) { return pax.typeInfo.paxType === Constants.AdultPaxType; }).length;
        this.passengerSelection.youngAdults = booking.passengers.items.filter(function (pax) { return pax.typeInfo.paxType === Constants.YoungAdultPaxType; }).length;
        this.passengerSelection.children = booking.passengers.items.filter(function (pax) { return pax.typeInfo.paxType === Constants.ChildrenPaxType; }).length;
        this.passengerSelection.teens = booking.passengers.items.filter(function (pax) { return pax.typeInfo.paxType === Constants.TeenPaxType; }).length;
        this.passengerSelection.infants = booking.passengers.infants.items.length;
    };
    ChangeFlightComponent.prototype.getFlightSearchModel = function (currencyCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var searchModel, currentClassOfService, hasSpecialClass, hasPsoWorkerClass;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchModel = new FlightSearchModel();
                        if (!this.isAgent) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getCurrentBookingClassOfService()];
                    case 1:
                        currentClassOfService = _a.sent();
                        console.log("currentClassOfService " + currentClassOfService);
                        hasSpecialClass = this.configService.config.classesOfService.special.indexOf(currentClassOfService) > -1;
                        hasPsoWorkerClass = this.configService.config.classesOfService.psoWorker.indexOf(currentClassOfService) > -1;
                        searchModel.classOfService = this.getClassesOfServiceBySpecialPriceOption(hasSpecialClass ?
                            'specialPrice' :
                            hasPsoWorkerClass ? 'psoWorker' : this.selectedSpecialMarketOption);
                        _a.label = 2;
                    case 2:
                        searchModel.currency = currencyCode;
                        searchModel.origin = this.departure;
                        searchModel.destination = this.destination;
                        searchModel.isRoundTrip = !this.isOneWay;
                        searchModel.passengers = this.passengerSelection.getPassengerSearch();
                        searchModel.setDepartureDate(moment(this.outboundDate, this.format).toDate());
                        searchModel.usePromo = this.isPromoSelected;
                        if (this.inbound) {
                            searchModel.setReturnDate(moment(this.inboundDate, this.format).toDate());
                        }
                        return [2 /*return*/, Promise.resolve(searchModel)];
                }
            });
        });
    };
    ChangeFlightComponent.prototype.setFlightsAndNavigate = function (searchModel) {
        this.flightSearch.searchModel = searchModel;
        this.bookingStepsService.goToStep(CheckinSteps.flights);
    };
    ChangeFlightComponent.prototype.getCurrentBookingClassOfService = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var currentBooking;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.bookingService.refresh(true)];
                    case 1:
                        currentBooking = _a.sent();
                        if (currentBooking.cancelFlight.journeys.items.length == 0)
                            return [2 /*return*/, null];
                        return [2 /*return*/, currentBooking.cancelFlight.journeys.items[0].segments.items[0].fares.items[0].classOfService];
                }
            });
        });
    };
    ChangeFlightComponent.prototype.getClassesOfServiceBySpecialPriceOption = function (specialMarketOption) {
        switch (specialMarketOption) {
            case 'noSpecialMarket':
                return this.configService.config.classesOfService.normal.join("_");
            case 'psoWorker':
                return this.configService.config.classesOfService.psoWorker.join('_');
            case 'specialPrice':
                return this.configService.config.classesOfService.special.join('_');
        }
    };
    ChangeFlightComponent.prototype.getSpecialPriceCheckboxText = function () {
        return "Are you a resident in " + this.selectedMarketName + "?";
    };
    ChangeFlightComponent.prototype.promoselect = function () {
        //this.isPromoSelected = !this.isPromoSelected;
        if (this.isPromoSelected) {
            this.totalPaxCount = this.totalPromoPaxCount;
        }
        else {
            this.totalPaxCount = 9;
        }
    };
    ChangeFlightComponent.prototype.ok = function () {
        this.modalService.close("numberOfPaxExcededModal");
    };
    ChangeFlightComponent.prototype.initUserData = function () {
        var _this = this;
        this.userStateSubscription = this.userStateService.isLoading
            .pipe(filter(function (isLoading) { return !isLoading; }), switchMap(function () { return _this.userStateService.isLoggedIn; }))
            .subscribe(function (isLoggedIn) { return isLoggedIn && _this.bookingService.getMemberDetails(); });
    };
    ChangeFlightComponent.prototype.checkIfIsSpecialPrice = function () {
        var _this = this;
        if (!isUndefined(this.isOnFlightsPage) && !this.isOnFlightsPage)
            return;
        if (!isNullOrUndefined(this.destination)
            && !isNullOrUndefined(this.departure)) {
            var specialMarkets = this.configService.config.markets;
            var selectedMarket = specialMarkets.find(function (item) { return _this.isDestinationOrDepartureInArray(item.routes); });
            if (!isNullOrUndefined(selectedMarket)) {
                this.showSpecialPrice = true;
                this.setSpecialPriceIframeUrl(selectedMarket);
                this.selectedMarketName = selectedMarket.marketName;
                this.psoWorkerEnabled = selectedMarket.psoWorkerEnabled;
                return;
            }
        }
        this.showSpecialPrice = false;
    };
    ChangeFlightComponent.prototype.setSpecialPriceIframeUrl = function (selectedMarket) {
        var cmsConfig = this.configService.config.cms;
        if (isNullOrUndefined(cmsConfig) || isNullOrUndefined(selectedMarket)) {
            this.urlSafe = "";
            return;
        }
        var language = this.translateService.culture.split("-")[0];
        var iframeUrl = cmsConfig.baseUrl + "/" + language + "/pso_" + selectedMarket.marketName;
        var urlBuilder = new URL(iframeUrl);
        urlBuilder.searchParams.append("antiCacheKey", Date.now().toString());
        this.urlSafe = this.sanitizer
            .bypassSecurityTrustResourceUrl(urlBuilder.href);
    };
    ChangeFlightComponent.prototype.isDestinationOrDepartureInArray = function (marketsList) {
        for (var i = 0; i < marketsList.length; i++) {
            var element_1 = marketsList[i];
            if ((element_1.indexOf(this.destination) !== -1)
                && element_1.indexOf(this.departure) !== -1) {
                return true;
            }
        }
        return false;
    };
    tslib_1.__decorate([
        SessionStorage(),
        tslib_1.__metadata("design:type", String)
    ], ChangeFlightComponent.prototype, "classOfService", void 0);
    return ChangeFlightComponent;
}());
export { ChangeFlightComponent };
