import * as tslib_1 from "tslib";
import { CheckinService } from '../core/checkin.service';
import { PassengerJourneyBags, JourneyBags } from '../core/models/passenger-journey-bags';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { FlightModel } from '../core/models/flight-model';
import { PassengerModel } from '../core/models/passenger-model';
import { BookingFareSelectSyncService } from '../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { isNullOrUndefined } from 'util';
var FlightsService = /** @class */ (function () {
    function FlightsService(checkinService, translateService, syncService) {
        var _this = this;
        this.checkinService = checkinService;
        this.translateService = translateService;
        this.syncService = syncService;
        this.flights = [];
        this.passengers = [];
        this.checkedInPassengers = {};
        this.checkInJourneyRestrictions = [];
        this.checkInJourneyData = [];
        this.paxCheckInAllowState = {};
        this.checkinService.checkInInfoSub.subscribe(function (checkinfo) { return checkinfo && _this.loadData(checkinfo); });
    }
    FlightsService.prototype.loadData = function (checkInInfo) {
        var _this = this;
        var _a;
        if (isNullOrUndefined(checkInInfo))
            return;
        this.checkInJourneyRestrictions = checkInInfo.checkInJourneyRestrictions;
        this.checkInJourneyData = checkInInfo.checkInJourneysData;
        var allFlights = {};
        var pjbList = checkInInfo.passengerJourneyBags;
        var typeCounter = { adtCount: 0, chdCount: 0 };
        checkInInfo.criteria.forEach(function (c) {
            var passenger = new PassengerModel(c.passenger, typeCounter, _this.syncService);
            _this.passengers.push(passenger);
            var pjb = pjbList.find(function (i) { return i.passengerNumber === passenger.passengerNumber; });
            passenger.isSelected = pjb ? true : false;
            _this.paxCheckInAllowState[passenger.passengerNumber] = c.passengerJourneys;
            c.passengerJourneys.forEach(function (passengerJourney) {
                // this.paxCheckInAllowState[passenger.passengerNumber] = passenger
                var j = passengerJourney.journey;
                if (passengerJourney.isCheckedIn) {
                    _this.checkedInPassengers[passenger.passengerNumber] = _this.checkedInPassengers[passenger.passengerNumber] || [];
                    _this.checkedInPassengers[passenger.passengerNumber].push(j.sellKey);
                }
                if (allFlights.hasOwnProperty(j.sellKey)) {
                    allFlights[j.sellKey].passengers.push(passenger);
                    return;
                }
                var flight = new FlightModel();
                flight.initFromJourney(j, _this.translateService);
                flight.passengers = [passenger];
                flight.carrierCode = passengerJourney.journey.segments
                    .map(function (p) { return p.legs; }).reduce(function (a, b) { return a.concat(b); }, [])
                    .map(function (p) { return p.legInfo; }).map(function (p) { return p.operatingCarrier; })
                    .reduce(function (a, b) {
                    if (b.length > 0 || b != '0B') {
                        return a.concat(b);
                    }
                    else {
                        return a;
                    }
                });
                allFlights[flight.sellKey] = flight;
                flight.isSelected = pjb && pjb.journeyBags.findIndex(function (jb) { return jb.sellKey === flight.sellKey; }) >= 0;
                return flight;
            });
        });
        this.flights = [];
        for (var key in allFlights) {
            if (allFlights.hasOwnProperty(key)) {
                this.flights.push(allFlights[key]);
            }
        }
        if (checkInInfo.checkInNotEligibleJourneys) {
            (_a = this.flights).push.apply(_a, tslib_1.__spread(checkInInfo.checkInNotEligibleJourneys.map(function (j) { return new FlightModel().initFromJourney(j, _this.translateService); })));
        }
        this.flights.sort(FlightModel.sortFn);
    };
    FlightsService.prototype.getPassengersForSelectedFlights = function (flights) {
        var e_1, _a, e_2, _b;
        var passengers = [];
        try {
            for (var _c = tslib_1.__values(this.flights), _d = _c.next(); !_d.done; _d = _c.next()) {
                var flight = _d.value;
                if (flights.indexOf(flight.sellKey) < 0) {
                    continue;
                }
                try {
                    for (var _e = tslib_1.__values(flight.passengers), _f = _e.next(); !_f.done; _f = _e.next()) {
                        var passenger = _f.value;
                        if (passengers.indexOf(passenger) >= 0) {
                            continue;
                        }
                        passengers.push(passenger);
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return passengers.sort(PassengerModel.sortFn);
    };
    FlightsService.prototype.selectPassengers = function (flights, passengers) {
        var list = passengers.map(function (p) {
            var pjb = new PassengerJourneyBags();
            pjb.passengerNumber = p.passengerNumber;
            pjb.journeyBags = flights
                .filter(function (f) { return f.passengers.indexOf(p) >= 0; })
                .map(function (f) {
                var jb = new JourneyBags();
                jb.sellKey = f.sellKey;
                return jb;
            });
            return pjb;
        });
        return this.checkinService.sendPassengerSelection(list);
    };
    return FlightsService;
}());
export { FlightsService };
