import { Constants } from '../../constants';
import { PassengerDocumentsModel } from './passenger-documents-model';
import { BehaviorSubject } from 'rxjs';
var Fee = /** @class */ (function () {
    function Fee() {
    }
    return Fee;
}());
export { Fee };
var PassengerModel = /** @class */ (function () {
    /**
     *
     */
    function PassengerModel(_passengerInfo, _typeCounter, syncService) {
        if (_typeCounter === void 0) { _typeCounter = {}; }
        if (syncService === void 0) { syncService = null; }
        this._passengerInfo = _passengerInfo;
        this._typeCounter = _typeCounter;
        this.syncService = syncService;
        this.fees = [];
        this.priorityBoardingSelection$ = new BehaviorSubject(null);
        this.documents = new PassengerDocumentsModel();
        if (_passengerInfo) {
            this.passengerNumber = _passengerInfo.passengerNumber;
            this.name = _passengerInfo.name;
            this.fees = this.setPassengerFees(_passengerInfo.fees);
            if (_passengerInfo.typeInfo) {
                this.paxType = _passengerInfo.typeInfo.paxType;
                _typeCounter[this.paxType] = (_typeCounter[this.paxType] || 0) + 1;
                this.passengerNumberByType = _typeCounter[this.paxType];
            }
            if (_passengerInfo.hasInfant && _passengerInfo.infant) {
                this.infant = new PassengerModel(_passengerInfo.infant, null, syncService);
                this.infant.documents.docSuffix = 'I';
                this.hasInfant = true;
                this.infant.paxType = Constants.InfantSsrCode;
                this.infant.documents.gender = _passengerInfo.infant.gender;
                this.infant.documents.dateOfBirth = _passengerInfo.infant.dateOfBirth;
                if (_passengerInfo.travelDocuments) {
                    this.infant.documents.initFromTravelDocuments(_passengerInfo.travelDocuments.items);
                }
            }
            this.sortByType = PassengerModel.initSortByType(this);
            if (_passengerInfo.travelDocuments) {
                this.documents.initFromTravelDocuments(_passengerInfo.travelDocuments.items);
                this.documents.passengerNumber = _passengerInfo.passengerNumber;
                this.documents.gender = _passengerInfo.info.gender;
                this.documents.dateOfBirth = _passengerInfo.typeInfo.dateOfBirth;
            }
        }
    }
    Object.defineProperty(PassengerModel.prototype, "isAdult", {
        get: function () { return this.paxType.substring(0, 3) === Constants.AdultPaxType; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassengerModel.prototype, "isYouth", {
        get: function () { return this.paxType.substring(0, 3) === Constants.YoungAdultPaxType; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassengerModel.prototype, "isTeen", {
        get: function () { return this.paxType.substring(0, 3) === Constants.TeenPaxType; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassengerModel.prototype, "isChild", {
        get: function () { return this.paxType.substring(0, 3) === Constants.ChildrenPaxType; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassengerModel.prototype, "isInfant", {
        get: function () { return this.paxType.substring(0, 3) === Constants.InfantSsrCode; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassengerModel.prototype, "passengerNumber", {
        get: function () {
            // console.log('Passenger number: ' + this._passengerNumber)
            return this._passengerNumber;
        },
        set: function (value) {
            this._passengerNumber = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassengerModel.prototype, "flightUniqueId", {
        get: function () {
            return (this.flight && this.flight.flightUniqueId) || '';
        },
        enumerable: true,
        configurable: true
    });
    PassengerModel.prototype._computeHasPriorityBoarding = function () {
        if (!this.flight) {
            return false;
        }
        if (!this.flight.segments) {
            return false;
        }
        if (this.hasPriorityBoardingIncludedInTheBundle) {
            return true;
        }
        var segmentsUniqueIDs = this.flight.segments.map(function (s) { return s.departureStation.iataCode + s.arrivalStation.iataCode; });
        return this._hasFee('PBRD', segmentsUniqueIDs);
    };
    Object.defineProperty(PassengerModel.prototype, "hasPriorityBoardingIncludedInTheBundle", {
        get: function () {
            var paidSsrs = (this.flight && this.flight.paidSsrs && this.flight.paidSsrs[this.passengerNumber]) || [];
            if (paidSsrs.includes('PBRD')) {
                return true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    PassengerModel.prototype._hasFee = function (feeCode, segmentsUniqueIds) {
        for (var feeIndex = 0; feeIndex < this.fees.length; feeIndex++) {
            var fee = this.fees[feeIndex];
            if (fee.code === feeCode) {
                for (var segmentIndex = 0; segmentIndex < segmentsUniqueIds.length; segmentIndex++) {
                    if (fee.flightReference && 0 < fee.flightReference.indexOf(segmentsUniqueIds[segmentIndex])) {
                        return true;
                    }
                }
            }
        }
        return false;
    };
    Object.defineProperty(PassengerModel.prototype, "hasPriorityBoarding", {
        get: function () {
            var hasPriorityBoarding = this._computeHasPriorityBoarding();
            if (hasPriorityBoarding) {
                return true;
            }
            if (this.syncService.passengersInitialPriorityBoardingChoice[this.passengerUniqueId]) {
                return false;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassengerModel.prototype, "notHasPriorityBoarding", {
        get: function () {
            var hasPriorityBoarding = this._computeHasPriorityBoarding();
            if (hasPriorityBoarding) {
                this.syncService.passengersInitialPriorityBoardingChoice[this.passengerUniqueId] = true;
                return false;
            }
            if (this.syncService.passengersInitialPriorityBoardingChoice[this.passengerUniqueId]) {
                return true;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PassengerModel.prototype, "passengerUniqueId", {
        get: function () {
            if (!this.flightUniqueId) {
                return String(this.passengerNumber);
            }
            return this.flightUniqueId + "_" + this.passengerNumber;
        },
        enumerable: true,
        configurable: true
    });
    PassengerModel.prototype.resetCounter = function () {
    };
    PassengerModel.prototype.clone = function (typeCounter) {
        return new PassengerModel(this._passengerInfo, typeCounter ? typeCounter : this._typeCounter, this.syncService);
    };
    PassengerModel.prototype.setPassengerFees = function (fees) {
        var feeList = [];
        if (fees && fees.items) {
            fees.items.forEach(function (f) {
                var fee = new Fee();
                fee.code = f.code;
                fee.note = f.note;
                fee.number = f.number;
                fee.serviceCharges = f.serviceCharges.items;
                fee.flightReference = f.flightReference;
                feeList.push(fee);
            });
        }
        return feeList;
    };
    PassengerModel.initSortByType = function (model) {
        if (model.paxType)
            switch (model.paxType.substring(0, 3)) {
                case Constants.AdultPaxType:
                    return 0;
                case Constants.YoungAdultPaxType:
                    return 5;
                case Constants.TeenPaxType:
                    return 10;
                case Constants.ChildrenPaxType:
                    return 15;
            }
        return 20;
    };
    PassengerModel.sortFn = function (a, b) {
        if (a.sortByType > b.sortByType) {
            return 1;
        }
        if (a.sortByType < b.sortByType) {
            return -1;
        }
        if (a.passengerNumberByType > b.passengerNumberByType) {
            return 1;
        }
        if (a.passengerNumberByType < b.passengerNumberByType) {
            return -1;
        }
        return 0;
    };
    PassengerModel.checkNameFormat = function (name) {
        var result = true;
        if (String(name).length < 3) {
            result = false;
        }
        else {
            for (var i = 0; i < 2; i++) {
                if (name[i] !== name[i + 1]) {
                    result = false;
                }
            }
        }
        return result;
    };
    PassengerModel.prototype.generatePassengerNameByType = function (translateService) {
        var defaultName = translateService.instant(this.paxType.substring(0, 3) + '-title', 'price-display');
        this.defaultName = defaultName + " " + this.passengerNumberByType;
        if ((!this.name.first && !this.name.last) || (this.name.first && this.name.last && PassengerModel.checkNameFormat(this.name.first)
            && PassengerModel.checkNameFormat(this.name.last))) {
            var passengerNameByType = new PassengerName();
            passengerNameByType.first = defaultName;
            passengerNameByType.last = this.passengerNumberByType.toString();
            this.name = passengerNameByType;
        }
    };
    return PassengerModel;
}());
export { PassengerModel };
var PassengerName = /** @class */ (function () {
    function PassengerName() {
    }
    return PassengerName;
}());
export { PassengerName };
