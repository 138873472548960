<div class="checkin-box" aria-haspopup="true" [ngClass]="{ 'expanded': isExpanded, 'added': isModified }"
  *ngIf="isAvailableForJourney(currentFlights)">
  <blue-loading-spinner [id]="spinnerId">
  </blue-loading-spinner>

  <check-in-ssrs-seats-removal-notification #seatRemovalModal [ssrType]="ssrType">
  </check-in-ssrs-seats-removal-notification>

  <div class="checkin-options-header" [ngClass]="{ 'open': isExpanded }">
    <div class="left">
      <div class="circle-wrap">
        <span class="font-ico-circle" role="presentation"></span>
        <i class="{{icon}}" role="presentation"></i>
      </div>

      <div class="text-holder">
        <ng-content>
        </ng-content>
      </div>
    </div>

    <div class="right" *ngIf="ssrType != SsrTypeEnum.AirportCheckin">
      <div class="right-inner default-view">
        <!-- if baggage promotion unavailable -->
        <span attr.aria-label="{{ 'Upgrade from {0} {1}' | translate:'extras':lowestPrice:'Romanian Leu' }}"
          *ngIf="!promoDiscount && !hasPromotion">{{ 'from' | translate:'extras' }} {{ lowestPrice | currencyDisplay
          }}</span>

        <!-- if baggage promotion available -->
        <div class="promo-wrapper" *ngIf="promoDiscount || hasPromotion">
          <div class="promo-text">
            <span class="promo-text-old">{{ 'from' | translate:'extras' }}
              <span *ngIf="promoDiscount && !hasPromotion" class="line-through">
                {{ lowestPrice*100/(100 - promoDiscount) | currencyDisplay }}
              </span>

              <span *ngIf="hasPromotion" class="line-through">
                {{ lowestOriginalPrice | currencyDisplay }}
              </span>
            </span>

            <span class="promo-text-new">
              {{ lowestPrice | currencyDisplay }}
            </span>
          </div>

          <div class="promo-img">
            <img [src]="assetsPath + 'svg/icon-promo_32x32.svg'">
          </div>
        </div>

        <button type="button" class="btn btn-primary btn-small"
          attr.aria-label="{{ 'Click to add {0}' | translate:'extras':addBtnText}}" (click)="expandBox()">
          {{ 'Add {0}' | translate:'extras':addBtnText}}
        </button>
      </div>

      <div class="right-inner added-view" *ngIf="isModified">
        <button *ngIf="ssrType !== SsrTypeEnum.PriorityBoarding" type="button" class="remove-from-cart"
          (click)="removeFromCart()">
          <span class="font-ico-close-menu">
            <span class="sr-only">{{ 'Click to remove from cart' | translate:'extras' }}</span>
          </span>
        </button>
        <span *ngIf="ssrType === SsrTypeEnum.PriorityBoarding"
          class="priority-boarding-ssr-selection-option-icon ssr-icon font-ico-checked-sign" role="presentation">
        </span>

        <span *ngIf="ssrType !== SsrTypeEnum.PriorityBoarding" class="font-ico-checked-sign" role="presentation"></span>
        <strong>{{ 'Added to cart' | translate:'extras' }}</strong>
        <span class="price" attr.aria-label="{{ totalPrice + 'Romanian Leu'}}">{{ totalPrice | currencyDisplay }}</span>
        <button type="button" class="btn btn-primary blue"
          attr.aria-label="{{ 'Click to modify' | translate:'extras' }}" (click)="expandBox()">{{ 'Modify' |
          translate:'extras' }}</button>
      </div>
    </div>
    <!-- <button *ngIf="ssrType !== SsrTypeEnum.Baggage && ssrType !== SsrTypeEnum.PriorityBoarding" type="button" class="btn btn-secondary blue collapse-item" aria-hidden="true" attr.aria-label="{{ 'Click to cancel' | translate:'extras' }}"
      (click)="cancelChanges()">{{ 'Cancel' | translate:'extras' }}
    </button>  -->
  </div>

  <div class="checkin-options-content" *ngIf="isExpanded" [style.display]="isExpanded ? 'block' : ''">
    <check-in-accordion>
      <ng-container *ngIf="false && ssrType === SsrTypeEnum.FastTrack">
        <check-in-accordion-item title="{{ 'View security Fast Track information' | translate:'extras'}}">
          <div class="checkin-pets-options">{{ 'Security Fast Track information Content' | translate:'extras' }}
            <div *ngFor="let flight of currentFlights">
              <a
                [attr.href]="'https://www.blueairweb.com/media/default/documents/fast%20track/fast%20track%20security%20info%20' + flight.departureStation.iataCode + '%20-%20' + pdfLanguage + '.pdf'">{{
                '{0} Security fast track info (pdf)' | translate: 'extras': flight.departureStation.iataCode}}</a>
            </div>
          </div>
        </check-in-accordion-item>
      </ng-container>

      <ng-container *ngFor="let flight of currentFlights;let i = index">
        <ng-container *ngIf="ssrType === SsrTypeEnum.Meal && !flight.isInThePast">
          <check-in-accordion-item *ngFor="let segment of flight.segments; let si = index"
            ariaLabel="{{ 'Click to open options for {0} to {1}' | translate:'extras':segment.departureStation.name:segment.arrivalStation.name"
            title="{{ '{0} to {1}' | translate:'extras':segment.departureStation.name:segment.arrivalStation.name}}"
            price="{{ (flightPrices ? flightPrices[i][si] : 0) | currencyDisplay }}"
            disabledText="{{ 'Not available' | translate:'extras' }}" [disabled]="!isAvailableForFlight(segment)">

            <check-in-ssr-box-content-meals [flight]="segment"
              (totalPriceChanged)="updateTotalPricePerFlight($event, i, si)"
              [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
              [allFlightBoxContents]="ssrBoxContentComponents" (selectSsrEvent2)=saveChanges($event)>
            </check-in-ssr-box-content-meals>
          </check-in-accordion-item>
        </ng-container>

        <ng-container *ngIf="ssrType !== SsrTypeEnum.Meal && !flight.isInThePast">
          <check-in-accordion-item
            ariaLabel="{{ 'Click to open options for {0} to {1}' | translate:'extras':flight.departureStation.name:flight.arrivalStation.name"
            title="{{ '{0} to {1}' | translate:'extras':flight.departureStation.name:flight.arrivalStation.name}}"
            price="{{ (flightPrices ? flightPrices[i][0] : 0) | currencyDisplay }}"
            disabledText="{{ 'Not available' | translate:'extras' }}"
            [disabled]="!isAvailableForFlight(flight) || (!isFlexAvailable(flight) && ssrType === SsrTypeEnum.Flex)">

            <ng-container [ngSwitch]="ssrType">
              <check-in-ssr-box-content-luggage *ngSwitchCase="SsrTypeEnum.Baggage" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                (saveChangesEvent)=saveChanges($event) [allFlightBoxContents]="ssrBoxContentComponents"
                flightKey="flight.referenceKey">
              </check-in-ssr-box-content-luggage>

              <check-in-ssr-box-content-pets *ngSwitchCase="SsrTypeEnum.Pet" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                [allFlightBoxContents]="ssrBoxContentComponents" (selectSsrEvent2)=saveChanges($event)>
              </check-in-ssr-box-content-pets>

              <check-in-ssr-box-content-special-equipment *ngSwitchCase="SsrTypeEnum.SpecialEquipment" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                [allFlightBoxContents]="ssrBoxContentComponents" (selectSsrEvent2)=saveChanges($event)
                [isUnavailableDueToPreviouslyCheckedIn]="isUnavailableDueToPreviouslyCheckedIn">
              </check-in-ssr-box-content-special-equipment>

              <check-in-ssr-box-content-lounge *ngSwitchCase="SsrTypeEnum.Lounge" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                [allFlightBoxContents]="ssrBoxContentComponents" (saveChangesEvent)=saveChanges($event)>
              </check-in-ssr-box-content-lounge>

              <check-in-ssr-box-content-special-assistance *ngSwitchCase="SsrTypeEnum.SpecialAssistance"
                [flight]="flight" (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                [allFlightBoxContents]="ssrBoxContentComponents" (selectSsrEvent2)=saveChanges($event)>
              </check-in-ssr-box-content-special-assistance>

              <check-in-ssr-box-content-priority-boarding *ngSwitchCase="SsrTypeEnum.PriorityBoarding" [flight]="flight"
                [currentFlights]="currentFlights" (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="false" [allFlightBoxContents]="ssrBoxContentComponents"
                (saveChangesEvent)=saveChanges($event) (removeSsrEvent2)=removeFromCart($event)>
              </check-in-ssr-box-content-priority-boarding>

              <check-in-ssr-box-content-priority-bag *ngSwitchCase="SsrTypeEnum.PriorityBag" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                [allFlightBoxContents]="ssrBoxContentComponents">
              </check-in-ssr-box-content-priority-bag>

              <check-in-ssr-box-content-fast-track *ngSwitchCase="SsrTypeEnum.FastTrack" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                [allFlightBoxContents]="ssrBoxContentComponents" (saveChangesEvent)=saveChanges($event)>
              </check-in-ssr-box-content-fast-track>

              <check-in-ssr-box-content-airport-checkin *ngSwitchCase="SsrTypeEnum.AirportCheckin" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                (saveChangesEvent)=saveChanges($event) [allFlightBoxContents]="ssrBoxContentComponents">
              </check-in-ssr-box-content-airport-checkin>


              <check-in-ssr-box-content-flex *ngSwitchCase="SsrTypeEnum.Flex" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                (saveChangesEvent)=saveChanges($event) [allFlightBoxContents]="ssrBoxContentComponents"
                [isFlexAvailable]="isFlexAvailable(flight)">
              </check-in-ssr-box-content-flex>

              <check-in-ssr-box-content-early-checkin *ngSwitchCase="SsrTypeEnum.EarlyCheckin" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                (saveChangesEvent)=saveChanges($event) [allFlightBoxContents]="ssrBoxContentComponents">
              </check-in-ssr-box-content-early-checkin>

              <check-in-ssr-box-content-bus-transfer *ngSwitchCase="SsrTypeEnum.BusTransfer" [flight]="flight"
                (totalPriceChanged)="updateTotalPricePerFlight($event, i)"
                [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights && i == 0"
                (saveChangesEvent)=saveChanges($event) [allFlightBoxContents]="ssrBoxContentComponents">
              </check-in-ssr-box-content-bus-transfer>
            </ng-container>
          </check-in-accordion-item>
        </ng-container>
      </ng-container>
    </check-in-accordion>

    <div class="checkin-total-options-bar">
      <span class="text">
        <span innerHtml="{{ '<span class=\'hide-on-mobile\'>Ancillary</span> Total:' | translate:'extras'}}">
        </span>

        <strong>
          {{ temporaryTotalPrice | currencyDisplay }}
        </strong>
      </span>

      <!-- <button type="button" class="btn btn-primary" attr.aria-label="{{ 'Click to add to cart' | translate:'extras' }}" (click)="saveChanges()">
        {{ 'Add to cart' | translate:'extras' }}
      </button> -->
    </div>
  </div>

  <check-in-validation-modal [ssrType]="ssrType" (validationAccepted)="validationModalAction($event)">
  </check-in-validation-modal>
</div>