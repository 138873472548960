<div class="checkin-luggage-options big-header priority-top-wrapper">
  <ng-container *ngIf="mobileMatches">
    <ng-container *ngTemplateOutlet="ssrsMobileView"></ng-container>
  </ng-container>

  <ng-container>
    <div class="luggage-left">
      <div class="luggage-table">
        <div class="luggage-row row-head">
          <div class="cell-head">
          </div>

          <div class="cell-head">
            <div class="image-wrap">
              <img [src]="assetsPath + 'img/ssrs/small-cabin-bag.png'"
                alt="{{ '1 SMALL CABIN BAG' | translate: 'extras' }}">
            </div>

            <span class="dimensions">
              {{ '40 x 30 x 20 cm' | translate: 'extras' }}
            </span>

            <span class="title">
              <span class="description" [innerHTML]="'1 SMALL CABIN BAG'  | translationWithHtml:'extras'">
              </span>
              <span (click)="showLuggagePriorityDetailsFreeOption()">
                <span class="ssr-icon font-ico-info" role="presentation"></span>
              </span>
            </span>

            <span class="sub-title" [innerHTML]="'that fits under seats' | translationWithHtml: 'extras'">
            </span>

            <span class="fixed-center-bottom" [innerHTML]="'Included' | translationWithHtml: 'extras'">
            </span>
          </div>

          <div class="cell-head">
            <div class="image-wrap">
              <img [src]="assetsPath + 'img/ssrs/small-cabin-bag-trolley.png'"
                alt="{{ 'SMALL CABIN BAG & TROLLEY BAG' | translate: 'extras' }} ">
            </div>

            <span [innerHTML]="'40 x 30 x 20 cm & 55 x 40 x 20 cm / max. 10KG' | translationWithHtml: 'extras'">
            </span>

            <span class="title">
              <span class="description" [innerHTML]="'SMALL CABIN BAG & TROLLEY BAG'  | translationWithHtml:'extras'">
              </span>

              <span (click)="showLuggagePriorityDetailsPaidOption()">
                <span class="ssr-icon font-ico-info" role="presentation"></span>
              </span>
            </span>

            <div class="higlighted">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 10"
                style="enable-background:new 0 0 12 10;" xml:space="preserve">
                <style type="text/css">
                  .st0 {
                    fill: #4EA046;
                  }
                </style>
                <g>
                  <ellipse class="st0" cx="9.5" cy="0.9" rx="0.9" ry="0.9" />
                  <path class="st0"
                    d="M7,9.4C6.9,9.6,7,9.9,7.2,10c0.2,0.1,0.5,0,0.6-0.3c0,0,0,0,0,0l0.7-2.1L7.8,6.7L7,9.4z" />
                  <path class="st0" d="M11.9,4.9l-1.3-1.2L10,2.7C9.9,2.6,9.8,2.4,9.5,2.4L8.9,2.2C8.5,2.1,8.2,2.1,8,2.2L6.9,2.9
                  C6.8,2.9,6.8,3,6.8,3L5.7,4.5C5.6,4.7,5.6,4.9,5.7,5.1L5.2,6.3c0,0-0.1-0.1-0.2-0.1L4.4,5.9c-0.3-0.1-0.6,0-0.8,0.3c0,0,0,0,0,0
                  L2.5,8.6c-0.1,0.3,0,0.6,0.3,0.7c0,0,0,0,0,0l0.4,0.2c0-0.4,0.4-0.7,0.8-0.7c0.1,0,0.3,0.1,0.4,0.1l0.2-0.4c0,0,0,0,0,0l1.6-3.3
                  c0.1,0,0.2-0.1,0.3-0.2l1-1.4l0.7-0.4L7.7,5.4C7.6,5.8,7.8,6.1,8,6.3l1.1,1.4l0.6,1.9c0.1,0.2,0.3,0.4,0.6,0.3
                  c0.2-0.1,0.4-0.3,0.3-0.6c0,0,0,0,0,0l-0.6-2c0-0.1,0-0.1-0.1-0.1L9.2,6.2l0.5-2.1l0.1,0.2c0,0.1,0.1,0.1,0.1,0.1l1.4,1.2
                  c0.2,0.2,0.5,0.1,0.6,0C12.1,5.4,12,5.1,11.9,4.9L11.9,4.9z" />
                  <ellipse class="st0" cx="3.9" cy="9.6" rx="0.4" ry="0.4" />
                  <path class="st0"
                    d="M0.6,3h2.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H0.6c-0.1,0-0.2,0.1-0.2,0.2C0.4,2.9,0.5,3,0.6,3z" />
                  <path class="st0"
                    d="M2.8,5.7H0.2C0.1,5.7,0,5.8,0,5.9C0,6,0.1,6.1,0.2,6.1h2.6C2.9,6.1,3,6,3,5.9C3,5.8,2.9,5.7,2.8,5.7z" />
                  <path class="st0"
                    d="M1.5,4.4c0,0.1,0.1,0.2,0.2,0.2h2.6c0.1,0,0.2-0.1,0.2-0.2S4.5,4.2,4.4,4.2H1.7C1.6,4.2,1.5,4.3,1.5,4.4z" />
                </g>
              </svg>
              <span [innerHTML]="'Priority and 2 Cabin Bags'  | translationWithHtml:'extras'">
              </span>
            </div>
          </div>
        </div>
      </div>
      <!-- <check-in-ssr-same-options-for-return-flight [visible]="enableSameOptionsForAllFlights" [(isSelected)]="sameOptionsForAllFlights"></check-in-ssr-same-options-for-return-flight> -->
    </div>
  </ng-container>
</div>
<div class="checkin-pets-options priority-row-wrapper" [(checkInSsrListDisplay)]="isSsrsPopupOpened">
  <check-in-ssr-passenger-picker [passengers]="passengers" [ssrType]="ssrType"
    [allPassengersEnabled]="allPassengersEnabled" [selectedPassenger]="selectedPassenger"
    [enableSameOptionsForAllFlights]="enableSameOptionsForAllFlights" [currentFlights]="currentFlights"
    (selectPassenger)="selectPassenger($event)" (selectSsrEvent)="selectSsrEvent($event)"
    (removeSsrEvent)="removeSsrEvent($event)" (removeSsr)="removeAllSsrs($event)"
    (sameOptionsForAllFlightsEvent)=sameOptionsForAllFlightsEvent($event)>
  </check-in-ssr-passenger-picker>

  <!-- <div class="pets-list js-ssr-list">
    <div class="pets-list-inner">
      <button type="button" class="close-popup" aria-hidden="true">
        <span class="font-ico-close-menu">
          <span class="sr-only">{{ 'Click to close priority boarding overlay' | translate: 'extras' }}</span>
        </span>
      </button>
      <h2 aria-hidden="true" class="js-ssr-list-title">{{ 'Choose priority boarding' | translate: 'extras' }}</h2>
      <div *ngIf="mobileMatches">
        <div *ngIf="selectedPassenger == -1 else singlePassengerName">{{ 'All passengers' | translate: 'extras' }}</div>
        <ng-template #singlePassengerName>{{ getSelectedPassengerName() }}</ng-template>
      </div>
      <ng-container *ngFor="let ssr of availableSsrs">
        <div class="pet-item" *ngIf="ssr.isVisible">
          <div class="header-wrap-item">
            <div class="circle-wrap"><span class="font-ico-circle"></span><i class="font-ico-priority-boarding-2bags"></i></div>
            <div class="pet-item-left">
              <h3>{{ ssr.ssrCode + '-title' | translate: 'extras' }}</h3>
              <check-in-ssr-price-display [originalPrice]="ssr.originalPrice" [currentPrice]="ssr.price"></check-in-ssr-price-display>
            </div>
          </div>
          <div class="pet-item-right">
            <button type="button" class="pet-number pet-number-single" [ngClass]="{ 'pet-number-selected' : ssr.selectedQuantity > 0 }"
              attr.aria-label="{{'Click to add priority boarding' | translate: 'extras'}}" (click)="selectSsr(null, ssr.ssrCode, 1); checkPriorityBoardingSsrSelection(ssr.paidQuantity)"
              [disabled]="ssr.paidQuantity == 1">
              <span class="pet-number-inner">
                <strong *ngIf="ssr.selectedQuantity == 0">{{ 'Add' | translate: 'extras' }}</strong>
                <strong *ngIf="ssr.selectedQuantity > 0">{{ 'Added' | translate: 'extras' }}</strong>
                <ng-container *ngIf="ssr.paidQuantity == 1"> {{'PAID' | translate: 'extras' }}</ng-container>
              </span>
            </button>
          </div>
        </div>
      </ng-container>
      <button type="button" class="btn btn-primary close-popup-bottom" aria-hidden="true">
        {{ 'Confirm and Continue' | translate: 'extras' }}
      </button>
    </div>
  </div> -->
  <!-- <div class="custom-columns mobile">
    <div class="custom-columns-inner">
      <div class="custom-columns-item">
        <div class="title-inner">{{ '1 bagaj de mana gratuit' | translate: 'extras' }}</div>
      </div>
      <div class="custom-columns-item">
        <div class="title-inner">{{ '1 bagaj mare de mana suplimentar' | translate: 'extras' }}</div>
      </div><div class="custom-columns-item">
        <div class="title-inner">{{ 'Imbarcare suplimentara' | translate: 'extras' }}</div>
      </div>
    </div>
    <div class="custom-columns-inner">
      <div class="custom-columns-item">
        <div class="image-wrapper">
          <div class="image-title">{{ 'inclus' | translate: 'extras' }}</div>
          <i class="font-ico-luggage-hand image" role="presentation"></i>
        </div>
      </div>
      <div class="custom-columns-item">
        <div class="image-wrapper">
          <i class="font-ico-luggage-additional image" role="presentation"></i>
        </div>
      </div>
      <div class="custom-columns-item">
        <div class="image-wrapper">
          <i class="font-ico-priority-boarding image" role="presentation"></i>
        </div>
      </div>
    </div>
    <div class="custom-columns-inner">
      <div class="custom-columns-item">
        <div class="description-inner">{{ '40 x 30 x 20 cm' | translate: 'extras' }}</div>
      </div>
      <div class="custom-columns-item">
        <div class="description-inner">{{ '55 x 40 x 20 cm' | translate: 'extras' }}</div>
      </div>
      <div class="custom-columns-item">
        <div class="description-inner">{{ 'Fii printre primii pasageri la bord' | translate: 'extras' }}</div>
      </div>
    </div>
  </div> -->

</div>