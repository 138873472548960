import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { SeatMapService } from '../seat-map.service';
import { Input } from '@angular/core';
import { LoggerService } from '../../common-modules/log4ts/logger.service';
import { BehaviorSubject } from 'rxjs';
import { Unit } from '../models/seat-models';
import { SeatSelectedNotification } from 'src/app/shared/seat-selected-notification/seat-selected-notification.component';
import { RowGroup } from '../models/row-cell-model';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';
import UnitRow from '../models/unit-row.model';

@Component({
  selector: 'check-in-seat-map',
  templateUrl: './seat-map.component.html',
  styleUrls: ['./seat-map.component.scss']
})
export class SeatMapComponent implements OnInit {
  private passengerNumer: number = 0;
  private passengerDysplayNumer: number = 0;
  private appliedFilters: Array<number> = [];
  private thismap: any;

  selectedSeats: Array<any> = [];
  @Output() addToCartEvent = new EventEmitter();
  @Input()
  mapKey: string;
  @Input()
  flightKey: string;

  @Input()
  set selectPassenger(pa) {
    if (pa) {

      this.passengerNumer = pa.passengerNumber;
      this.passengerDysplayNumer = pa.passengerNumber + 1;
      if (!pa.hasInfant && this.appliedFilters.indexOf(4) >= 0) {
        this.logger.info("remove infant on pax change")
        this.applyFilter({ map: this.mapKey, filter: 4 });
      } else if (pa.hasInfant && this.appliedFilters.indexOf(4) < 0) {
        this.logger.info("add infant on pax change")
        this.applyFilter({ map: this.mapKey, filter: 4 });
      }

      //todo: check other passenger restrictions
      this.applyFilter({ map: this.mapKey, filter: 5 });
      this.appliedFilters.forEach(f => {

        this.changeSeatDecoratorAccordingToFilter(f);
      });
    }

  };

  @Input()
  set filterApplied(f) {

    f.subscribe(filter => {
      if (filter) {
        this.logger.info("input filter " + f);
        this.applyFilter(filter);
      }

    });
  }

  @Output()
  setSelected: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set seatDeselected(event) {
    event.subscribe(u => {
      if (u) {

        let unit = this.seatRows.map(p => p.units).reduce((a, b) => a.concat(b), []).find(p => p.unitDesignator == u.unitDesignator);
        let selectesIndex = this.selectedSeats.findIndex(p => p.passengerNumber == u.passsenger);
        if (selectesIndex >= 0) {
          this.selectedSeats.splice(selectesIndex, 1);
        }
        unit.decorator = unit.baseDecorator || unit.masterDecorator;

      }
    })
  }
  rowLetters: any;
  seatRows: Array<UnitRow> = [];
  style: string = 'none';


  constructor(private seatmapService: SeatMapService, private logger: LoggerService) {

  }

  ngOnInit() {
    this.logger.info(this.mapKey);
    this.logger.info(this.flightKey);
    //todo:filter flightkey

    this.thismap = this.seatmapService.seatMap.seatMaps.find(p => p.sellKey == this.mapKey && p.flightKey == this.flightKey);
    this.rowLetters = this.thismap.rowLetters;
    this.seatRows = this.thismap.seatRows;

    this.seatmapService.seatMap.seatAssignment.journeys.find(p => p.sellKey == this.mapKey)
      .seatSegmentKeys.filter(p => p.flightKey == this.flightKey).map(p => p.passengers).reduce((a, b) => a.concat(b), [])
      .map(p => ({ passengerNumber: p.number, unit: p.assignedSeat }))
      .forEach(s => {
        this.passengerNumer = s.passengerNumber;

        let d = this.thismap.decks.find(p => p.number == s.unit.deck);
        if (d == null) {
          return;
        }


        this.unitClicked(
          d
            .compartments.find(p => p.compartmentDesignator == s.unit.compartment)
            .units.find(p => p.unitDesignator == s.unit.unit), false);
        //this.selectedSeats.push({passengerNumber:s.passengerNumber, unit: });

      });


  }
  changeStyle($event, cell) {

    cell.style = $event.type == 'mouseover' ? 'block' : 'none';
  }

  unitClicked(unit, notify = true) {
    if (this.selectedSeats.length == 0)
      this.selectedSeats.push({ passengerNumber: this.passengerNumer, unit: unit });
    else {
      let oldSeatForCurrentPax = this.selectedSeats.find(p => p.passengerNumber == this.passengerNumer);
      if (oldSeatForCurrentPax) {
        oldSeatForCurrentPax.unit.decorator = oldSeatForCurrentPax.unit.baseDecorator;
        oldSeatForCurrentPax.unit.passengerNumber = "";
        this.selectedSeats.splice(this.selectedSeats.indexOf(oldSeatForCurrentPax), 1);
        this.selectedSeats.push({ passengerNumber: this.passengerNumer, unit: unit });
      } else {
        this.selectedSeats.push({ passengerNumber: this.passengerNumer, unit: unit });
      };

    }

    unit.decorator = "legend-selected";
    unit.passengerNumber = this.passengerNumer + 1;
    if (notify)
      this.setSelected.next(unit);

    this.addToCartEvent.emit();
  }



  applyFilter(filter) {
    // if (this.mapKey != filter.map)
    //   return

    this.logger.info("apply filter" + filter.filter)
    //clear all filters
    this.seatRows.forEach(u => {
      u.units.forEach(uu => {
        if (uu) {
          if (uu.baseDecorator && uu.baseDecorator.length == 0) {
            //check by pax
            const paxNumber = this.passengerNumer;

            //create a masterUnitAvailability property
            if (uu.unitAvailabilityByPax) {
              uu.decorator = uu.unitAvailabilityByPax[paxNumber] != "Open" ? "legend-not-available" : uu.masterDecorator;
            }
          }
          else
            uu.decorator = uu.baseDecorator;
        }
      })
    });


    //if filter applied remove it from list
    if (this.appliedFilters.indexOf(filter.filter) >= 0) {

      this.appliedFilters.splice(this.appliedFilters.indexOf(filter.filter), 1);
    } else {
      this.appliedFilters.push(filter.filter);
    }
    this.logger.info("remaining filters:" + this.appliedFilters);
    //apply all remaining filters
    this.appliedFilters.forEach(f => {

      this.changeSeatDecoratorAccordingToFilter(f);
    });

    if (this.appliedFilters.length === 0) {
      this.changeSeatDecoratorAccordingToFilter(5);
    }

  }

  changeSeatDecoratorAccordingToFilter(filter) {

    let applyFilterFunc = (u, direction) => {


      if (direction) {
        u.decorator = 'legend-not-available';
      }

      else {
        if (u.baseDecorator.length == 0) {

        } else {
          u.decorator = u.baseDecorator;
        }

      }

      let ss = this.selectedSeats;

      let pasSeat = this.selectedSeats.find(pss => pss.unit.unitDesignator == u.unitDesignator && pss.unit.flightKey == u.flightKey);

      if (pasSeat) {

        u.decorator = "legend-selected";
      }

    };

    let findAndApply = (u, l) => {
      if (l.findIndex(cs => cs.unitDesignator == u.unitDesignator) <= 0) {
        applyFilterFunc(u, true);
      }
    }

    let map = this.thismap;//this.seatmapService.seatMap.seatMaps.find(p => p.sellKey == this.mapKey);


    switch (filter) {
      case 1://'filter child';
        this.seatRows.forEach(p => p.units.forEach(u => findAndApply(u, map.seatForChild)));
        this.logger.info("filter child");
        break;
      case 2://filter disabled';
        this.seatRows.forEach(p => p.units.forEach(u => findAndApply(u, map.seatForDisabled)));
        this.logger.info("filter disabled");
        break;
      case 3: //'filter pet';
        this.seatRows.forEach(p => p.units.forEach(u => findAndApply(u, map.seatForPet)));

        this.logger.info("filter pet");
        break;
      case 4://'filter infant';
        this.seatRows.forEach(p => p.units.forEach(u => findAndApply(u, map.seatForInfant)));
        this.logger.info("filter infant");
        break;

    }
    let units = this.selectedSeats.map(p => p.unit);

    if (map) {
      map.decks.forEach(d =>
        d.compartments.forEach(c =>
          c.units.forEach(u => {
            if (units.findIndex(p => p.deck == d.number && p.compartmentDesignator == c.compartmentDesignator && p.unitDesignator == u.unitDesignator) >= 0) {

              u.decorator = "legend-selected";
            }
          })));
    }
  }

  protected getClassForGroup(group: RowGroup, groups: RowGroup[]): string {
    let classes = "row-info-wrapper "
    // if (group && group.cells.length < 6) {
    if (group && group.cells.length < 6) {

      let length = group.containsMiddle ? group.cells.length + 1 : group.cells.length;

      switch (length) {
        case 1:
          classes += 'one-seat-space'
          break;
        case 2:
          classes += 'two-seat-space'
          break;
        case 3:
          classes += 'three-seat-space'
          break;
        case 4:
          classes += 'four-seat-space'
          break;
        case 5:
          classes += 'five-seat-space'
          break;
        case 6:
          classes += 'six-seat-space'
          break;
      }
    }
    return classes;
  }
}
