import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { BookingService } from './booking.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { CurrencyManagerService } from './currency-manager.service';
import { ApplicationFlowService } from './application-flow.service';
import { BookingStepsService, ApplicationFlowEnum } from './booking-steps.service';
import { LoggerService } from '../common-modules/log4ts/logger.service';
import { shareReplay } from 'rxjs/operators';
var BookingFlowService = /** @class */ (function (_super) {
    tslib_1.__extends(BookingFlowService, _super);
    function BookingFlowService(http, configService, currencyManager, bookingService, translate, bookingSteps, logService) {
        var _this = _super.call(this, http, configService, currencyManager) || this;
        _this.bookingService = bookingService;
        _this.translate = translate;
        _this.bookingSteps = bookingSteps;
        _this.logService = logService;
        _this.isCheckinFlow = false;
        _this.canContinue = new BehaviorSubject(null);
        _this.checkInInfoSub = new BehaviorSubject(null);
        _this.scrollToPassengersObs = new BehaviorSubject(null);
        return _this;
    }
    BookingFlowService.prototype.loadFlowInfo = function (forced) {
        var _this = this;
        if (forced === void 0) { forced = false; }
        return this.bookingService.refresh(forced).then(function (b) {
            if (b && b.convertedJourneys) {
                _this.currency = b.convertedJourneys.currencyCode;
            }
            return b;
        });
    };
    BookingFlowService.prototype.getPriceBreakdown = function () {
        var params = new HttpParams();
        if (this.bookingSteps.flow === ApplicationFlowEnum.LockFare) {
            params = params.set('lf', 'true');
        }
        return _super.prototype.getPriceBreakdown.call(this, params);
    };
    BookingFlowService.prototype.updateShoppingCartFromItinerary = function (itinerary, ignoreDiscounts) {
        this.shoppingCartBreakdown.next(this.shoppingCartBreakdown.value.updateFromItinerary(itinerary, ignoreDiscounts));
    };
    BookingFlowService.prototype.overrideShoppingCartBalanceDue = function (newBalanceDue) {
        this.shoppingCartBreakdown.next(this.shoppingCartBreakdown.value.overrideBalanceDue(newBalanceDue));
    };
    BookingFlowService.prototype.getBooking = function () {
        return this.loadFlowInfo();
    };
    BookingFlowService.prototype.loadPriceItinerary = function (sellKeys, bundles) {
        var reqObj = { 'priceItinerary': { 'SellKeys': sellKeys } };
        if (bundles.every(function (bundle) { return bundle !== null; })) {
            reqObj.priceItinerary.BundleCodes = bundles;
        }
        return this.http.post(this.configService.PriceItinerary, reqObj)
            .pipe(shareReplay(1));
    };
    BookingFlowService.prototype.loadSuperStations = function () {
        var _this = this;
        return this.http.get(this.configService.SuperStationsUrl).toPromise()
            .then(function (countries) {
            if (_this._availableStations) {
                try {
                    return _this._filterStations(countries);
                }
                catch (err) {
                    _this.logService.error('Failed to filter stations by available CMS stations', err);
                    return _this._filterStationsOldStyle(countries);
                }
            }
            else {
                //for backward compatibility if we don't have window.$availableStations we will work like before showing all countries/stations
                return _this._filterStationsOldStyle(countries);
            }
        });
    };
    //@TODO - this function and its usages should be removed after the support for filtering stations according to CMS will be deployed to production
    BookingFlowService.prototype._filterStationsOldStyle = function (countries) {
        var _this = this;
        countries.forEach(function (country) {
            country.value = _this.translate.instant(country.code, 'country.name');
            country.stations.forEach(function (station) {
                station.value = _this.translate.instant(station.code, 'station.name');
            });
        });
        return countries;
    };
    BookingFlowService.prototype._filterStations = function (countries) {
        var _this = this;
        return countries.map(function (country) {
            country.value = _this.translate.instant(country.code, 'country.name');
            country.stations = country.stations || [];
            country.stations = country.stations.filter(function (station) { return _this._availableStations[station.code]; })
                .map(function (station) {
                station.value = _this.translate.instant(station.code, 'station.name');
                station.markets = station.markets || [];
                station.markets = station.markets.filter(function (marketCode) { return _this._availableStations[marketCode]; });
                return station;
            })
                .filter(function (station) { return station.markets.length > 0; })
                .sort(function (s1, s2) { return s1.value.localeCompare(s2.value); });
            return country;
        })
            .filter(function (country) { return country.stations.length > 0; })
            .sort(function (c1, c2) { return c1.value.localeCompare(c2.value); });
    };
    Object.defineProperty(BookingFlowService.prototype, "_availableStations", {
        get: function () {
            return window['$availableStations'];
        },
        enumerable: true,
        configurable: true
    });
    return BookingFlowService;
}(ApplicationFlowService));
export { BookingFlowService };
