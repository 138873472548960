
  <input class="datepicker" attr.placeholder="{{ format | lowercase }}" type="text" #pickerInput (focus)="!isOnMobile && open()" (click)="isOnMobile && open()" (keypress)="keyPress($event)" [readonly]="isOnMobile" />
  <div class="date-picker" #pickerContainer [style.display]="isOpened ? 'block': ''">
    <a role="button" href="#" class="close js-close-datepicker" (click)="close($event)">
      <span class="sr-only">{{ 'Close date picker' | translate: 'change-flight' }}</span>
      <i class="font-ico-close-menu"></i>
    </a>
    <h3>{{ header }}</h3>
  </div>
  <span class="selected-icon"><span class="sr-only">{{'Calendar Icon' | translate: 'change-flight' }}</span></span>
