import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { FlightModel, FlightStationInfo } from '../../core/models/flight-model';
import { SsrModel } from '../ssr-model';

import { SsrsService } from '../ssrs.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrType } from '../ssr-type.enum';

@Component({
  selector: 'check-in-ssr-box-content-early-checkin',
  templateUrl: './ssr-box-content-early-checkin.component.html',
  styleUrls: ['./ssr-box-content-early-checkin.component.scss']
})
export class SsrBoxContentEarlyCheckinComponent extends SsrBoxContentBase implements OnInit {
  public ssr;
  notAvailableForSegments: FlightModel[];

  @Output() saveChangesEvent = new EventEmitter();

  constructor(private ssrsService: SsrsService, private translateService: TranslateService) {
    super(ssrsService);
    this.ssrType = SsrType.EarlyCheckin;
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.flight && this.flight.passengers.length > 0) {
      this.allPassengersEnabled = this.allPassengersEnabledFunc();

      if (!this.allPassengersEnabled) {
        this.selectedPassenger = 0;
      }
    }
  }

  protected initFlightSsrList() {
    super.initFlightSsrList();
    if (!this._fullSsrList || !this._flight) {
      return;
    }

    this.notAvailableForSegments = [];

    this._flight.segments.forEach(segment => {
      const flightKey = `${segment.departureStation.iataCode}_${segment.arrivalStation.iataCode}`;
      const avSsrList = this._fullSsrList.find(avSsr => avSsr.key === flightKey);
      if (!avSsrList || !avSsrList.value.length) {
        var seg = new FlightModel();
        seg.initFromSegment(segment, this.translateService);
        this.notAvailableForSegments.push(seg);
      }
    });

    this.ssr = this.availableSsrs[0];
  }

  selectSsrEvent(passengerIndex: number, ssrCode: string, newSsrQuantity: number) {
    this.selectSsr(passengerIndex, ssrCode, newSsrQuantity);
    this.saveChangesEvent.emit(passengerIndex);
  }

  resetSsr() {
    this.saveChangesEvent.emit();
  }

  onEnableSameOptionsForAllFlightsChanged(value: boolean) {
    super.onEnableSameOptionsForAllFlightsChanged(value);

    if (!value) return;
    this.saveChangesEvent.emit();
  }

  // override base method
  // for this special ssr (airport-checkin) add the ssr only for segments where it is available
  applySpecificSsr(oldQuantity: number, newSsrQuantity: number, ssr: SsrModel, passengerNumber: number): void {
    const paidSSRQuantityOverride = this._paidSSROverrides.getPaidQuantity(passengerNumber, ssr.ssrCode);
    let tempNewSsrQuantity = newSsrQuantity - paidSSRQuantityOverride;
    let tempOldSsrQuantity = oldQuantity - paidSSRQuantityOverride;

    while (tempNewSsrQuantity > oldQuantity - paidSSRQuantityOverride) {
      this.flight.segments.forEach(segment => {
        const flightKey = `${segment.departureStation.iataCode}_${segment.arrivalStation.iataCode}`;
        const avSsrList = this._fullSsrList.find(avSsr => avSsr.key === flightKey);
        if (avSsrList && avSsrList.value.find(avSsr => avSsr.ssrCode === ssr.ssrCode)) {
          this._ssrsService.addSsr(this.ssrType, ssr.ssrCode, passengerNumber, segment, false);
        }
      });
      tempNewSsrQuantity--;
    }

    while (newSsrQuantity - paidSSRQuantityOverride < tempOldSsrQuantity) {
      this._ssrsService.removeSsr(this.ssrType, ssr.ssrCode, tempOldSsrQuantity,
        passengerNumber, this.flight, this.applySsrsToSegments);
      tempOldSsrQuantity--;
    }
  }

  allPassengersEnabledFunc(): boolean {
    
    if (this.passengers.length < 2) {
      return false;
    }

    if (this.availableSsrs[0]) {
      for (const property in this.availableSsrs[0].limitPerPassenger) {
        if (this.availableSsrs[0].limitPerPassenger.hasOwnProperty(property)) {
          if (this.availableSsrs[0].limitPerPassenger[property] === 0) {
            return false;
          }
        }
      }
    }

    return true;
  }
}
