/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./low-fare-calendar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../low-fare-calendar-view/low-fare-calendar-view.component.ngfactory";
import * as i3 from "../low-fare-calendar-view/low-fare-calendar-view.component";
import * as i4 from "@angular/common/http";
import * as i5 from "../../core/config.service";
import * as i6 from "@angular/common";
import * as i7 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i8 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i9 from "../../shared/form-wrapper/form-wrapper.component.ngfactory";
import * as i10 from "../../shared/form-wrapper/form-wrapper.component";
import * as i11 from "@angular/router";
import * as i12 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i13 from "../../core/booking-steps.service";
import * as i14 from "../../core/booking.service";
import * as i15 from "../../shared/default-modal/default-modal.component.ngfactory";
import * as i16 from "../../shared/default-modal/default-modal.component";
import * as i17 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i18 from "../../extras/ssrs.service";
import * as i19 from "./low-fare-calendar.component";
import * as i20 from "../../booking-flight-select/booking-flight-select.service";
var styles_LowFareCalendarComponent = [i0.styles];
var RenderType_LowFareCalendarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LowFareCalendarComponent, data: {} });
export { RenderType_LowFareCalendarComponent as RenderType_LowFareCalendarComponent };
function View_LowFareCalendarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "return"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "flight-path"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), "Select your day of departure", "lowFareCalendar")); _ck(_v, 1, 0, currVal_0); }); }
function View_LowFareCalendarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "h1", [["class", "return-day"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "return"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "flight-path"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), "Select your day of return", "lowFareCalendar")); _ck(_v, 1, 0, currVal_0); }); }
function View_LowFareCalendarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "low-fare-calendar-view", [], null, [[null, "dayChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dayChange" === en)) {
        var pd_0 = (_co.calendarSelectionChanged(_v.parent.context.index, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_LowFareCalendarViewComponent_0, i2.RenderType_LowFareCalendarViewComponent)), i1.ɵdid(1, 4833280, null, 0, i3.LowFareCalendarViewComponent, [i1.NgZone, i4.HttpClient, i5.ConfigService], { initialDate: [0, "initialDate"], origin: [1, "origin"], destination: [2, "destination"], minDate: [3, "minDate"], isOutbound: [4, "isOutbound"] }, { dayChange: "dayChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.selectedDate; var currVal_1 = _v.parent.context.$implicit.origin; var currVal_2 = _v.parent.context.$implicit.destination; var currVal_3 = ((_v.parent.context.index > 0) ? _co.selections[(_v.parent.context.index - 1)].date : ""); var currVal_4 = (_v.parent.context.index == 0); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_LowFareCalendarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LowFareCalendarComponent_2)), i1.ɵdid(2, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LowFareCalendarComponent_3)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LowFareCalendarComponent_4)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.index == 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.index == 1); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_v.context.index == 0) || (_co.selections[(_v.context.index - 1)] && _co.selections[(_v.context.index - 1)].farePrice)); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_LowFareCalendarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.TranslatePipe, [i8.TranslateService]), i1.ɵqud(402653184, 1, { invalidDatesModal: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 7, "check-in-form-wrapper", [], null, [[null, "continue"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("continue" === en)) {
        var pd_1 = (_co.continue() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_FormWrapperComponent_0, i9.RenderType_FormWrapperComponent)), i1.ɵdid(3, 770048, null, 0, i10.FormWrapperComponent, [i11.Router, i12.LoadingSpinnerService, i13.BookingStepsService, i14.BookingService], { ariaLabel: [0, "ariaLabel"], step: [1, "step"], showPriceDisplay: [2, "showPriceDisplay"], showContinue: [3, "showContinue"] }, { continue: "continue" }), (_l()(), i1.ɵeld(4, 0, null, 6, 5, "div", [["class", "form-wrapper-middle-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "check-in-default-modal", [["modalId", "invalidDatesModalId"]], null, null, null, i15.View_DefaultModalComponent_0, i15.RenderType_DefaultModalComponent)), i1.ɵdid(6, 114688, [[1, 4]], 0, i16.DefaultModalComponent, [i17.BlueModalService, i18.SsrsService], { modalId: [0, "modalId"], modalText: [1, "modalText"], okRequired: [2, "okRequired"], cancelRequired: [3, "cancelRequired"] }, null), i1.ɵppd(7, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LowFareCalendarComponent_1)), i1.ɵdid(9, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = "calendar"; var currVal_2 = false; var currVal_3 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = "invalidDatesModalId"; var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 6, 1, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "The return flight date must be after the outbound flight date", "lowFareCalendar")), ""); var currVal_6 = false; var currVal_7 = false; _ck(_v, 6, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.journeys; _ck(_v, 9, 0, currVal_8); }, null); }
export function View_LowFareCalendarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "low-fare-calendar", [], null, null, null, View_LowFareCalendarComponent_0, RenderType_LowFareCalendarComponent)), i1.ɵdid(1, 114688, null, 0, i19.LowFareCalendarComponent, [i20.BookingFlightSelectService, i13.BookingStepsService, i11.Router, i17.BlueModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LowFareCalendarComponentNgFactory = i1.ɵccf("low-fare-calendar", i19.LowFareCalendarComponent, View_LowFareCalendarComponent_Host_0, {}, {}, []);
export { LowFareCalendarComponentNgFactory as LowFareCalendarComponentNgFactory };
