<profile-info-box class="two-cols-mob" title="{{ 'Contact infos' | translate:'profile-info' }}" (editClick)="edit()">
  <div class="border-row-wrap">
    <div class="left">
      <span>{{ 'Title' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{ contactInfos.titleName }}</span>
    </div>
  </div>
  <div class="border-row-wrap">
    <div class="left">
      <span>{{ 'First name' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{ contactInfos.firstName }}</span>
    </div>
    <div class="right">
      <span>{{ 'Last name' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{ contactInfos.lastName }} </span>
    </div>
  </div>
  <div class="border-row-wrap">
    <div class="left">
      <span>{{ 'Gender' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{ contactInfos.gender }}</span>
    </div>
    <div class="right">
      <span>{{ 'Date of birth' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{ contactInfos.dateOfBirth | customDate:'fullDateYear' }}</span>
    </div>
  </div>
  <div class="border-row-wrap">
    <div class="left">
      <span>{{ 'Country of residence' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.residentCountry | translate:'country.name'}}</span>
    </div>
    <div class="right">
      <span>{{ 'Nationality' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.nationality | translate:'country.name'}}</span>
    </div>
  </div>
  <div class="border-row-wrap">
    <div class="left">
      <span>{{ 'Email' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.emailAddress}}</span>
    </div>

  </div>

  <div class="border-row-wrap">
    <div class="left">
      <span>{{ 'Phone number' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.workPhoneNumber}}</span>
    </div>
    <div class="right">
      <span>{{ 'Mobile phone number' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.mobilePhoneNumber}}</span>
    </div>
  </div>

  <div class="border-row-wrap" *ngIf="contactInfos.homePhoneNumber || contactInfos.faxPhoneNumber">
    <div class="left">
      <span>{{ 'Evening phone number' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.homePhoneNumber}}</span>
    </div>
    <div class="right">
      <span>{{ 'Fax number' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.faxPhoneNumber}}</span>
    </div>
  </div>
  <div class="row-wrap">
    <div class="left">
      <span>{{ 'Language' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.cultureName}}</span>
    </div>
    <div class="right">
      <span>{{ 'Currency' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{contactInfos.currencyName}}</span>
    </div>
  </div>
  <div class="row-wrap">
    <!-- <div class="left">
                <span>{{ 'Phone number' | translate:'profile-info' }}</span>
                <span class="blue-txt">{{contactInfos.phoneNumber}}</span>
              </div> -->
    <!-- <div class="right">
                <span>{{ 'Address' | translate:'profile-info' }}</span>
                <span class="blue-txt" *ngIf="contactInfos.address?.addressLine1">{{contactInfos.address?.addressLine1}}</span>
                <span class="blue-txt" *ngIf="contactInfos.address?.addressLine2">{{contactInfos.address?.addressLine2}}</span>
                <span class="blue-txt" *ngIf="contactInfos.address?.addressLine3">{{contactInfos.address?.addressLine3}}</span>
                <span class="blue-txt">{{contactInfos.address?.city}}</span>
              </div> -->
  </div>
  <blue-modal2 [id]="editModalId">
    <blue-loading-spinner [id]="editSpinnerId" [activationRoute]="route"></blue-loading-spinner>
    <span class="popup-title">{{ 'Contact infos' | translate:'profile-info' }}</span>
    <form role="form" (ngSubmit)="save()" #editForm="ngForm">
      <div class="form-row">
        <div class="one-field">
            <label for="title-field">{{ 'Title' | translate:'profile-info' }}</label>
            <blue-select-list [(ngModel)]="model.title" [items]="titles" placeholder="{{ 'Title' | translate:'profile-info' }}" name="title">
            </blue-select-list>
        </div>
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="firstname-field">{{ 'First name' | translate:'profile-info' }}</label>
          <input id="firstname-field" type="text" placeholder="{{ 'First name' | translate:'profile-info' }}"
            aria-labelledby="firstname-field" maxlength="64" [(ngModel)]="model.firstName" name="firstName" required
            #firstName="ngModel" [pattern]="namePattern"/>
          <profile-form-error [reference]="firstName"></profile-form-error>
        </div>
        <div class="one-field">
          <label for="lastname-field">{{ 'Last name' | translate:'profile-info' }}</label>
          <input id="lastname-field" type="text" placeholder="{{ 'Last name' | translate:'profile-info' }}"
            aria-labelledby="lastname-field" maxlength="64" [(ngModel)]="model.lastName" name="lastName" required
            #lastName="ngModel" [pattern]="namePattern"/>
          <profile-form-error [reference]="lastName"></profile-form-error>
        </div>
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <div class="label">{{ 'Date of birth' | translate:'profile-info' }}</div>
          <blue-date-picker name="birthdate" [(ngModel)]="model.dateOfBirth" [dateRange]="datePickerRange"
            [dateFormat]="'YYYY-MM-DD'"></blue-date-picker>
        </div>
        <div class="one-field radios">
          <div class="label">{{ 'Gender' | translate:'profile-info' }}</div>
          <input id="gender-male" name="gender-name" type="radio" aria-labelledby="gender-male"
            [(ngModel)]="model.gender" value="Male" />
          <label for="gender-male">{{ 'Male' | translate:'profile-info' }}</label>
          <input id="gender-female" name="gender-name" type="radio" aria-labelledby="gender-female"
            [(ngModel)]="model.gender" value="Female" />
          <label for="gender-female">{{ 'Female' | translate:'profile-info' }}</label>
        </div>
      </div>

      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="country-field">{{ 'Country of residence' | translate:'profile-info' }}</label>
          <blue-select-list [(ngModel)]="model.residentCountry" [items]="countries" [enableSearch]="true"
            placeholder="{{ 'Country of residence' | translate:'profile-info' }}" name="countryOfResidence">
          </blue-select-list>
        </div>
        <div class="one-field">
          <label for="nationality">{{ 'Nationality' | translate:'profile-info' }}</label>
          <blue-select-list [(ngModel)]="model.nationality" [items]="countries" [enableSearch]="true"
            placeholder="{{ 'Nationality' | translate:'profile-info' }}" name="nationality">
          </blue-select-list>
        </div>
      </div>
      <div class="form-row">
        <div class="one-field">
          <label for="email-field">{{ 'Email' | translate:'profile-info' }}</label>
          <input id="email-field" type="email" placeholder="Email" aria-labelledby="email-field"
            [(ngModel)]="model.emailAddress" #emailAddress="ngModel" name="emailAddress" [pattern]="emailPattern"/>
          <profile-form-error [reference]="emailAddress"></profile-form-error>
        </div>
        <!-- <div class="one-field">
                    <label for="phone-number-field">{{ 'Phone number' | translate:'profile-info' }}</label>
                    <input id="phone-number-field" type="text" placeholder="Phone number" aria-labelledby="phone-number-field"
                      [(ngModel)]="model.phoneNumber" name="phoneNumber"
                    />
                  </div> -->
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="workPhoneNumber-field">{{ 'Phone number' | translate:'profile-info' }}</label>
          <input id="workPhoneNumber-field" type="text" placeholder="{{ 'Phone number' | translate:'profile-info' }}"
            aria-labelledby="workPhoneNumber-field" [(ngModel)]="model.workPhoneNumber" name="workPhoneNumber" required
            #workPhoneNumber="ngModel" [pattern]="phonePattern"/>
          <profile-form-error *ngIf="!workPhoneNumber.hasError('pattern')" [reference]="workPhoneNumber"></profile-form-error>
          <profile-form-error *ngIf="workPhoneNumber.hasError('pattern')" [reference]="workPhoneNumber" [customErrorText]="true">
            {{'Only numbers allowed!' | translate:'profile-info'}}
          </profile-form-error>
        </div>
        <div class="one-field">
          <label for="mobilePhoneNumber-field">{{ 'Mobile phone number' | translate:'profile-info' }}</label>
          <input id="mobilePhoneNumber-field" type="text"
            placeholder="{{ 'Mobile phone number' | translate:'profile-info' }}"
            aria-labelledby="mobilePhoneNumber-field" [(ngModel)]="model.mobilePhoneNumber" name="mobilePhoneNumber"
            #mobilePhoneNumber="ngModel" [pattern]="phonePattern"/>
            <profile-form-error *ngIf="mobilePhoneNumber.hasError('pattern')" [reference]="mobilePhoneNumber" [customErrorText]="true">
              {{'Only numbers allowed!' | translate:'profile-info'}}
            </profile-form-error>
        </div>
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="homePhoneNumber-field">{{ 'Evening phone number' | translate:'profile-info' }}</label>
          <input id="homePhoneNumber-field" type="text"
            placeholder="{{ 'Evening phone number' | translate:'profile-info' }}"
            aria-labelledby="homePhoneNumber-field" [(ngModel)]="model.homePhoneNumber" name="homePhoneNumber"
            #homePhoneNumber="ngModel" [pattern]="phonePattern"/>
            <profile-form-error *ngIf="homePhoneNumber.hasError('pattern')" [reference]="homePhoneNumber" [customErrorText]="true">
              {{'Only numbers allowed!' | translate:'profile-info'}}
            </profile-form-error>
        </div>
        <div class="one-field">
          <label for="faxPhoneNumber-field">{{ 'Fax number' | translate:'profile-info' }}</label>
          <input id="faxPhoneNumber-field" type="text" placeholder="{{ 'Fax number' | translate:'profile-info' }}"
            aria-labelledby="faxPhoneNumber-field" [(ngModel)]="model.faxPhoneNumber" name="faxPhoneNumber"
            #faxPhoneNumber="ngModel" [pattern]="phonePattern"/>
            <profile-form-error *ngIf="faxPhoneNumber.hasError('pattern')" [reference]="faxPhoneNumber" [customErrorText]="true">
              {{'Only numbers allowed!' | translate:'profile-info'}}
            </profile-form-error>
        </div>
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="country-field">{{ 'Language' | translate:'profile-info' }}</label>
          <blue-select-list [(ngModel)]="model.cultureCode" [items]="languages" [enableSearch]="true"
            placeholder="{{ 'Language' | translate:'profile-info' }}" name="language"></blue-select-list>
        </div>
        <div class="one-field">
          <label for="currency-field">{{ 'Currency' | translate:'profile-info' }}</label>
          <blue-select-list [(ngModel)]="model.currencyCode" [items]="currencies" [enableSearch]="true"
            placeholder="{{ 'Currency' | translate:'profile-info' }}" name="currency"></blue-select-list>
        </div>
      </div>
      <div class="form-row" *ngIf="errors.length">
          <profile-form-error *ngFor="let err of errors" [customErrorText]="true" [isVisible]="true">
            {{ err }}
          </profile-form-error>
        </div>

      <profile-info-box-actions (cancel)="cancel($event)"></profile-info-box-actions>
    </form>
  </blue-modal2>
</profile-info-box>