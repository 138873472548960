import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'profile-reset-password-form',
  templateUrl: './reset-password-form.component.html',
  styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent implements OnInit {
  passwordChangeSubmitted = false;
  passwordChangeModel: any = {};

  @Input()
  passwordChangePolicy: string;
  @Input()
  passwordChangePolicyRegex: string;
  @Input()
  passwordChangeErrors: any[] = [];

  oldPassword: FormControl;
  newPassword: FormControl;
  newPasswordConf: FormControl;
  passwordChangeFormGroup: FormGroup;
  @ViewChild('changePasswordForm', { static: true }) changePasswordForm: NgForm;

  @Output()
  savePassword: EventEmitter<any> = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit() {
    this.initPasswordChangeControls();
  }

  resetForm() {
    this.changePasswordForm.reset();
    this.passwordChangeModel = {};
    this.passwordChangeSubmitted = false;
  }

  submitForm() {
    this.passwordChangeSubmitted = true;
    if (!this.changePasswordForm.valid) {
      return;
    }

    this.savePassword.emit({...this.passwordChangeModel});
  }

  private initPasswordChangeControls() {
    this.oldPassword = new FormControl('', [
      Validators.required,
    ]);
    this.newPassword = new FormControl('', [
      Validators.required,
      Validators.pattern(this.passwordChangePolicyRegex)
    ]);
    this.newPasswordConf = new FormControl('', [
      Validators.required,
      sameAsValidator(this.newPassword)
    ]);

    this.passwordChangeFormGroup = new FormGroup({
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      newPasswordConf: this.newPasswordConf
    });
  }
}

export function sameAsValidator(otherControl: FormControl): ValidatorFn {
  let initialized = false;

  return (control: FormControl): { [key: string]: any } => {
    if (!initialized && otherControl) {
      otherControl.valueChanges.subscribe(() => control.updateValueAndValidity());
      initialized = true;
    }
    const notEqual = otherControl && otherControl.value !== control.value;
    return notEqual ? { 'sameAs': { value: control.value } } : null;
  };
}