<div class="display-bookings-table-overflow" *ngIf="allBookings.length">
  <blue-loading-spinner [id]="spinnerId"></blue-loading-spinner>
  <div class="display-bookings-table">
    <!-- title -->
    <div class="table-title">
      {{'Results' | translate:'find-my-booking'}}
      <div class="checkbox-wrap">
        <input type="checkbox" (change)="togglePastBookings()" name="pastBookings" id="pastBookings"
          [(ngModel)]="pastBookings">
        <label for="pastBookings">{{'Include Past Bookings' | translate:'find-my-booking'}}</label>

      </div>
    </div>
    <div class="display-bookings-table-wrapper">
      <!-- header -->
      <div class="row table-header">
        <div class=column>
          {{'Departure date' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Origin' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Destination' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Record locator' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Passenger' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Agent' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Booking date' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'E-mail' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Amount due' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Booking status' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Total cost' | translate:'find-my-booking'}}
        </div>
        <div class=column>
          {{'Actions' | translate:'find-my-booking'}}
        </div>
      </div>
      <!-- body -->
      <div class="row" *ngFor="let booking of filteredBookingData">
        <div class="column" *ngIf="checkDepartureDate(booking.FlightDate);else noDate">
          {{ booking.FlightDate | customDate:'date'}}
        </div>
        <ng-template #noDate>
          <div class="column">
          </div>
        </ng-template>
        <div class="column">
          {{ booking.FromCity }}
        </div>
        <div class="column">
          {{ booking.ToCity }}
        </div>
        <div class="column">
          {{ booking.RecordLocator }}
        </div>
        <div class="column">
          {{ booking.Name.First }} {{ booking.Name.Last }}
        </div>
        <div class="column">
          {{ booking.SourceAgentCode }}
        </div>
        <div class="column">
          <div *ngIf="booking.BookingDate">
            {{ booking.BookingDate | customDate:'date' }}
          </div>
        </div>
        <div class="column">
          {{ booking.Email }}
        </div>
        <div class="column">
          {{ booking.AmountDue | currency: booking.CurrencyCode }}
        </div>
        <div class="column">
          {{ booking.BookingStatus | translate: 'find-my-booking'}}
        </div>
        <div class="column">
          {{ booking.TotalCost | currency: booking.CurrencyCode }}
          <button class="more-info" (click)="retrieveBooking(booking.RecordLocator, booking.Name.Last,false, $event)">
            {{ 'Get more info' | translate:'find-my-booking' }}
          </button>
        </div>
        <div class="column action">
          <button class="status-action-btn" (click)="retrieveBooking(booking.RecordLocator, booking.Name.Last, true)">
            {{'Retrieve' | translate:'find-my-booking' }}</button>
          <button *ngIf="booking.AmountDue > 0" class="status-action-btn"
            (click)="payBooking(booking.RecordLocator, booking.Name.Last, true)" style="margin-top: 4px;">
            {{'Pay' | translate:'find-my-booking' }}</button>
          <a *ngIf="booking.AmountDue != undefined && booking.AmountDue == 0 && !booking.BookingExpired && booking.BookingStatus != bookingStatusEnum.Closed"
            class="status-action-btn forced-nowrap"
            href="https://checkin.aeroitalia.com/?culture=it-IT&rl={{booking.RecordLocator}}&ln={{booking.Name.Last}}"
            target="_blank">{{ 'Checkin' | translate:'find-my-booking' }}</a>
        </div>
      </div>
    </div>
  </div>
</div>