import { Constants } from '../../constants';
import { PassengerSelectionType } from './pasenger-selection-type-enum';
var PassengerTypeSelectionViewModel = /** @class */ (function () {
    function PassengerTypeSelectionViewModel() {
        this.adults = 0;
        this.youngAdults = 0;
        this.teens = 0;
        this.children = 0;
        this.infants = 0;
    }
    Object.defineProperty(PassengerTypeSelectionViewModel.prototype, "count", {
        get: function () {
            //todo: check if infants are added to total pax
            return this.adults + this.youngAdults + this.teens + this.children;
        },
        enumerable: true,
        configurable: true
    });
    PassengerTypeSelectionViewModel.prototype.initFromPassengersSearch = function (passengersSearch) {
        this.adults = this.getCountFromPassengerSearch(passengersSearch, Constants.AdultPaxType);
        this.youngAdults = this.getCountFromPassengerSearch(passengersSearch, Constants.YoungAdultPaxType);
        this.teens = this.getCountFromPassengerSearch(passengersSearch, Constants.TeenPaxType);
        this.children = this.getCountFromPassengerSearch(passengersSearch, Constants.ChildrenPaxType);
        this.infants = this.getCountFromPassengerSearch(passengersSearch, Constants.InfantPaxType);
        return this;
    };
    PassengerTypeSelectionViewModel.prototype.initFromModel = function (otherModel) {
        this.adults = otherModel.adults;
        this.youngAdults = otherModel.youngAdults;
        this.teens = otherModel.teens;
        this.children = otherModel.children;
        this.infants = otherModel.infants;
    };
    PassengerTypeSelectionViewModel.prototype.getCountFromPassengerSearch = function (passengersSearch, paxType) {
        var s = passengersSearch.find(function (p) { return p.paxType.substring(0, 3) === paxType; });
        return s ? s.count : 0;
    };
    PassengerTypeSelectionViewModel.prototype.getProperty = function (paxType) {
        switch (paxType) {
            case PassengerSelectionType.Adult:
                return 'adults';
            case PassengerSelectionType.YoungAdult:
                return 'youngAdults';
            case PassengerSelectionType.Teen:
                return 'teens';
            case PassengerSelectionType.Children:
                return 'children';
            case PassengerSelectionType.Infant:
                return 'infants';
        }
    };
    PassengerTypeSelectionViewModel.prototype.getPassengerSearch = function () {
        var result = [];
        result.push({ paxType: Constants.AdultPaxType, count: this.adults });
        result.push({ paxType: Constants.YoungAdultPaxType, count: this.youngAdults });
        result.push({ paxType: Constants.TeenPaxType, count: this.teens });
        result.push({ paxType: Constants.ChildrenPaxType, count: this.children });
        result.push({ paxType: Constants.InfantPaxType, count: this.infants });
        return result;
    };
    return PassengerTypeSelectionViewModel;
}());
export { PassengerTypeSelectionViewModel };
