import * as tslib_1 from "tslib";
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { BookingStepsService } from '../core/booking-steps.service';
import { FlowManagerService } from '../core/flow-manager.service';
var CanActivateBookingChangesService = /** @class */ (function (_super) {
    tslib_1.__extends(CanActivateBookingChangesService, _super);
    function CanActivateBookingChangesService(steps, flowManager) {
        return _super.call(this, steps, flowManager.applicationFlowService) || this;
    }
    return CanActivateBookingChangesService;
}(BaseCanActivateService));
export { CanActivateBookingChangesService };
