import { ConfigService } from '../core/config.service';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../core/config.service";
var FindMyBookingsService = /** @class */ (function () {
    function FindMyBookingsService(http, configService) {
        this.http = http;
        this.configService = configService;
    }
    FindMyBookingsService.prototype.RetrieveBookingsByEmailAddress = function (RetrieveBookingsByEmailAddress) {
        RetrieveBookingsByEmailAddress.FlightNumber = RetrieveBookingsByEmailAddress.FlightNumber ?
            RetrieveBookingsByEmailAddress.FlightNumber.padStart(4, ' ') : null;
        return this.RetrieveBookings({ RetrieveBookingsByEmailAddress: RetrieveBookingsByEmailAddress });
    };
    FindMyBookingsService.prototype.RetrieveBookingsByRecordLocator = function (RetrieveBookingsByRecordLocator) {
        return this.RetrieveBookings({ RetrieveBookingsByRecordLocator: RetrieveBookingsByRecordLocator });
    };
    FindMyBookingsService.prototype.RetrieveBookingsByBookingDate = function (RetrieveBookingsByUtc) {
        RetrieveBookingsByUtc.EndUtc = RetrieveBookingsByUtc.StartUtc;
        RetrieveBookingsByUtc.FlightNumber = RetrieveBookingsByUtc.FlightNumber ?
            RetrieveBookingsByUtc.FlightNumber.padStart(4, ' ') : null;
        return this.RetrieveBookings({ RetrieveBookingsByUtc: RetrieveBookingsByUtc });
    };
    FindMyBookingsService.prototype.RetrieveBookingsByDepartureDate = function (FindBookingByBookingDepartureDate) {
        return this.RetrieveBookings({ FindBookingByBookingDepartureDate: FindBookingByBookingDepartureDate });
    };
    FindMyBookingsService.prototype.RetrieveBookings = function (model) {
        return !model ? this.http.get(this.configService.RetrieveAgentBookings).toPromise() :
            this.http.post(this.configService.RetrieveAgentBookings, model).toPromise();
    };
    FindMyBookingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FindMyBookingsService_Factory() { return new FindMyBookingsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: FindMyBookingsService, providedIn: "root" });
    return FindMyBookingsService;
}());
export { FindMyBookingsService };
