import * as tslib_1 from "tslib";
import { TranslateService } from './../common-modules/blue-air-common/translator/translate.service';
import { PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { FlowManagerService } from '../core/flow-manager.service';
var CurrencyDisplayPipe = /** @class */ (function (_super) {
    tslib_1.__extends(CurrencyDisplayPipe, _super);
    function CurrencyDisplayPipe(flowManager, translateService) {
        var _this = _super.call(this, translateService.culture) || this;
        _this.flowManager = flowManager;
        return _this;
    }
    CurrencyDisplayPipe.prototype.transform = function (value, symbolDisplay) {
        return _super.prototype.transform.call(this, value, this.flowManager.applicationFlowService.currency, symbolDisplay);
    };
    return CurrencyDisplayPipe;
}(CurrencyPipe));
export { CurrencyDisplayPipe };
