import * as tslib_1 from "tslib";
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateRange } from 'src/app/common-modules/blue-air-common/date-range.model';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import { BookingService } from 'src/app/core/booking.service';
import { ConfigService } from 'src/app/core/config.service';
import { PaymentsService } from '../payments.service';
import { AeroInvoicingData } from './models/invoicing-aero.resources';
import { AeroInvoicingValidators } from './models/invoicing-aero.validators';
import { isNullOrUndefined } from 'util';
import { REGIONS_WITH_CITIES } from './models/regions-with-cities.data';
var ITALY_CODE = "IT";
var NO_COUNTRY = "NO_COUNTRY";
var NON_ITALY_COUNTRY_CODE = NO_COUNTRY;
var AeroInvoicingComponent = /** @class */ (function () {
    function AeroInvoicingComponent(fb, paymentsService, configService, translateService, bookingService) {
        this.fb = fb;
        this.paymentsService = paymentsService;
        this.configService = configService;
        this.translateService = translateService;
        this.bookingService = bookingService;
        this.sub = new Subject();
        this.goBack = new EventEmitter();
        this.skipInvoicing = new EventEmitter();
        this.countries = [];
        this.regions = [];
        this.citiesForRegion = [];
        this.formGroup = new FormGroup({});
        // formFields: AeroInvoiceField[];
        this.customerTypes = [
            { text: 'IT_Company', value: "PGIT" /* ItCompany */ },
            { text: 'IT_IndividualPerson', value: "PFIT" /* ItIndividualPerson */ },
            { text: 'NON_IT_Customer', value: "NONIT" /* NonItCustomer */ }
        ];
        this.invoicingData = {};
        this.dataLoaded = false;
        this.bookingContact = null;
        this.fiscalPersonValidator = function (group) {
            var customerTypeControl = group.get("customerType" /* CustomerType */);
            var fiscalCodeCtrl = group.get("fiscalCode" /* FiscalCode */);
            AeroInvoicingComponent.removeCtrlErrors(['fiscalPersonValidator_ItCompany'], fiscalCodeCtrl);
            AeroInvoicingComponent.removeCtrlErrors(['fiscalCodeValidator_Individual'], fiscalCodeCtrl);
            switch (customerTypeControl.value) {
                case "PGIT" /* ItCompany */:
                    if (!fiscalCodeCtrl.value)
                        return null;
                    // if the first char is number (digit) the validation: 11 digits
                    if (!isNaN(+fiscalCodeCtrl.value.substring(0, 1))) {
                        var result = !/^[0-9]{11,11}?$/i.test(fiscalCodeCtrl.value);
                        if (result) {
                            fiscalCodeCtrl.setErrors({ fiscalPersonValidator_ItCompany: { value: fiscalCodeCtrl.value } });
                        }
                        return null;
                    }
                // else, validate as an ItIndividualPerson
                case "PFIT" /* ItIndividualPerson */:
                    var validationResult = !/^[A-Za-z0-9]{16,16}?$/i.test(fiscalCodeCtrl.value);
                    if (validationResult) {
                        fiscalCodeCtrl.setErrors({ fiscalCodeValidator_Individual: { value: fiscalCodeCtrl.value } });
                    }
                    break;
                case "NONIT" /* NonItCustomer */:
                    if (isNullOrUndefined(fiscalCodeCtrl.value)) {
                        fiscalCodeCtrl.setErrors({ required: true });
                    }
                    else {
                        var validationResult_1 = !/^[A-Za-z0-9]+$/i.test(fiscalCodeCtrl.value);
                        if (validationResult_1) {
                            fiscalCodeCtrl.setErrors({ alphaNumeric: true });
                        }
                    }
                    break;
                //   return validationResult ? { fiscalCodeValidator_Individual: { value: fiscalCodeCtrl.value } } : null;
                // default: // no validation for NON Italian
                //   return null;
            }
            return null;
        };
        this.defaultDateRange = new DateRange(null);
        this.defaultDateRange.maximumDate = moment();
        this.defaultDateRange.generateYearsForAge(1, 120);
        this.invoiceInfoUrl = this.configService.InvoicingInfoUrl;
        //this.formGroup = new FormGroup({});
        //this.initForm2();
    }
    Object.defineProperty(AeroInvoicingComponent.prototype, "CountryFormControl", {
        get: function () {
            return this.formGroup.get("countryCode" /* CountryCode */);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AeroInvoicingComponent.prototype, "CustomerTypeFormControl", {
        get: function () {
            return this.formGroup.get("customerType" /* CustomerType */);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AeroInvoicingComponent.prototype, "regionsFormControl", {
        get: function () {
            return this.formGroup.get("region" /* Region */);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AeroInvoicingComponent.prototype, "cityCodeFormControl", {
        get: function () {
            return this.formGroup.get("headquarterCity" /* HeadquarterCity */);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AeroInvoicingComponent.prototype, "isItalianCompany", {
        get: function () {
            return this.CustomerTypeFormControl.value == "PGIT" /* ItCompany */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AeroInvoicingComponent.prototype, "isItalianIndividual", {
        get: function () {
            return this.CustomerTypeFormControl.value == "PFIT" /* ItIndividualPerson */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AeroInvoicingComponent.prototype, "isNonItCustomer", {
        get: function () {
            return this.CustomerTypeFormControl.value == "NONIT" /* NonItCustomer */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AeroInvoicingComponent.prototype, "invoiceFormControls", {
        get: function () {
            return this.formGroup.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AeroInvoicingComponent.prototype, "defaultEmailAddress", {
        get: function () {
            if (!this.bookingContact)
                return null;
            return this.bookingContact.emailAddress;
        },
        enumerable: true,
        configurable: true
    });
    AeroInvoicingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customerTypes = this.customerTypes.map(function (item) {
            return tslib_1.__assign({}, item, { text: _this.translateService.instant(item.text, 'invoicing') });
        });
        // load data needed for the form
        this
            .paymentsService
            .loadInvoiceInfo()
            .pipe(takeUntil(this.sub))
            .subscribe(function (invoiceInfo) {
            var e_1, _a;
            var countriesList = invoiceInfo.onlineInvoice.countries;
            _this.countries = [];
            try {
                for (var countriesList_1 = tslib_1.__values(countriesList), countriesList_1_1 = countriesList_1.next(); !countriesList_1_1.done; countriesList_1_1 = countriesList_1.next()) {
                    var country = countriesList_1_1.value;
                    if (country.code.toLowerCase() == "it")
                        continue;
                    var translated = _this.translateService.instant(country.code, 'country.name');
                    if (translated !== country.code) {
                        country.name = translated;
                    }
                    _this.countries.push(country);
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (countriesList_1_1 && !countriesList_1_1.done && (_a = countriesList_1.return)) _a.call(countriesList_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            _this.countries.sort(function (a, b) { return a.name.localeCompare(b.name); });
            _this.countries.unshift({
                code: NO_COUNTRY,
                name: _this.translateService.instant('Please select country', 'country.name')
            });
            _this.regions = REGIONS_WITH_CITIES.map(function (item) {
                return {
                    name: item.code,
                    code: item.code
                };
            });
            _this.invoicingData = new AeroInvoicingData();
            // if (invoiceInfo.onlineInvoice.invoicingData) {
            //   for (const invoicingKey in invoiceInfo.onlineInvoice.invoicingData) {
            //     if (invoiceInfo.onlineInvoice.invoicingData.hasOwnProperty(invoicingKey)) {
            //       this.invoicingData[invoicingKey.toUpperCase()] = invoiceInfo.onlineInvoice.invoicingData[invoicingKey];
            //     }
            //   }
            // }
            _this.initForm2();
            _this.dataLoaded = true;
        });
    };
    AeroInvoicingComponent.prototype.initForm2 = function () {
        var _this = this;
        this.bookingService.refresh().then(function (booking) {
            _this.bookingContact = booking.contact;
            _this.formGroup = _this.fb.group({
                customerType: ["PGIT" /* ItCompany */, Validators.required],
                companyName: ['', Validators.required],
                lastName: [],
                fiscalCode: [null, []],
                vat: [null],
                recipientCode: ['', [AeroInvoicingValidators.alphaNumericFix(7)]],
                emailPec: [_this.defaultEmailAddress, [AeroInvoicingValidators.emailAddress()]],
                countryCode: [ITALY_CODE, AeroInvoicingValidators.customRequired(NO_COUNTRY)],
                addressNumber: [],
                headquarterAddress: ['', [Validators.required]],
                headquarterZipCode: ['', [Validators.required, AeroInvoicingValidators.headquarterZipCode()]],
                region: [],
                headquarterCity: [null, [Validators.required]]
            }, {
                validators: [_this.oneOfRecipientCodeOrEmailPecRequired, _this.oneOfVatOrFiscalCodeRequired, _this.fiscalPersonValidator],
            });
            _this.regionsFormControl.valueChanges
                .pipe(takeUntil(_this.sub)).subscribe(function (val) {
                _this.regionChanged(val);
            });
            _this.CustomerTypeFormControl.valueChanges
                .pipe(
            // tap(x => console.log(x)),
            takeUntil(_this.sub)).subscribe(function (val) {
                _this.customerTypeChanged(val);
            });
        });
    };
    AeroInvoicingComponent.prototype.ngOnDestroy = function () {
        if (this.sub) {
            this.sub.next(true);
            this.sub.complete();
        }
    };
    AeroInvoicingComponent.prototype.validateAndRetrieveInvoiceData = function () {
        this.submitted = true;
        this.invoicingDataValidation = null;
        if (this.formGroup.valid) {
            var formGroupValue = this.formGroup.value;
            if (formGroupValue.customerType == "PFIT" /* ItIndividualPerson */) {
                formGroupValue = tslib_1.__assign({}, formGroupValue, { firstName: formGroupValue.companyName, companyName: '' });
            }
            this.storeInvoicingData();
            return of(this.invoicingDataValidation = {
                formValid: true,
                dataValid: true,
                invoiceData: formGroupValue
            });
        }
        return of(this.invoicingDataValidation = { formValid: false, dataValid: false });
    };
    AeroInvoicingComponent.prototype.regionChanged = function (newRegion) {
        this.citiesForRegion = REGIONS_WITH_CITIES.filter(function (item) { return item.code == newRegion; }).reduce(function (acc, curVal) {
            return acc.concat(curVal.cities.map(function (item) { return ({ text: item, value: item }); }));
        }, []);
        this._resetSelectedCity();
    };
    AeroInvoicingComponent.prototype.countryChanged = function (newCountryCode) {
        this.storeInvoicingData();
        // const country = this.countries.find(c => c.code === newCountryCode);
        this.invoicingData.CountryCode = newCountryCode;
        //this.filteredRegions = this.allRegions.filter(r => r.countryCode === newCountryCode);
        this.updateFields();
    };
    AeroInvoicingComponent.prototype.customerTypeChanged = function (newCustomerType) {
        this.storeInvoicingData();
        // if the previous user was NonIt or new user is NonIt, we need to reset the current City
        if (this.invoicingData.CustomerType === "NONIT" /* NonItCustomer */
            || newCustomerType === "NONIT" /* NonItCustomer */) {
            this._resetSelectedCity();
        }
        this.invoicingData.CustomerType = newCustomerType;
        this.updateFields();
    };
    AeroInvoicingComponent.prototype._resetSelectedCity = function () {
        this.cityCodeFormControl.setValue(null);
        this.cityCodeFormControl.updateValueAndValidity();
    };
    AeroInvoicingComponent.prototype.updateFields = function () {
        if (!this.invoicingData.CustomerType) {
            return;
        }
        this.formGroup.get("lastName" /* LastName */).setValidators([]);
        if (!this.isItalianCompany) {
            this.invoicingData.EmailPec = this.formGroup.get("emailPec" /* EmailPec */).value;
            this.formGroup.get("emailPec" /* EmailPec */).reset(this.defaultEmailAddress);
            if (this.isItalianIndividual) {
                this.formGroup.get("recipientCode" /* RecipientCode */).setValue('0000000');
                this.formGroup.get("countryCode" /* CountryCode */).setValue(ITALY_CODE);
                this.formGroup.get("lastName" /* LastName */).setValidators(AeroInvoicingValidators.customRequired(NO_COUNTRY));
            }
            else {
                this.formGroup.get("countryCode" /* CountryCode */).reset(NON_ITALY_COUNTRY_CODE);
                // // this.formGroup.get(AeroInvoiceFieldNames.CountryCode).setValue(NON_ITALY_COUNTRY_CODE);
                this.formGroup.get("recipientCode" /* RecipientCode */).setValue('XXXXXXX');
                this.formGroup.get("lastName" /* LastName */).reset();
            }
        }
        else {
            this.formGroup.get("countryCode" /* CountryCode */).setValue(ITALY_CODE);
            this.formGroup.get("recipientCode" /* RecipientCode */).reset();
            this.formGroup.get("lastName" /* LastName */).reset();
        }
        this.submitted = false;
        this.formGroup.markAsUntouched();
        this.formGroup.updateValueAndValidity();
    };
    AeroInvoicingComponent.prototype.storeInvoicingData = function () {
        var formGroupValue = this.formGroup.value;
        for (var key in formGroupValue) {
            if (formGroupValue.hasOwnProperty(key)) {
                this.invoicingData[key] = formGroupValue[key];
            }
        }
    };
    // recipientCodeValidation(formControl: AbstractControl): { [s: string]: any } {
    //   return null;
    // }
    AeroInvoicingComponent.prototype.oneOfVatOrFiscalCodeRequired = function (group) {
        if (!group)
            return null;
        var fiscalCodeCtrl = group.controls["fiscalCode" /* FiscalCode */];
        var vatCtrl = group.controls["vat" /* Vat */];
        AeroInvoicingComponent.removeCtrlErrors(['VatOrFiscalCodeRequired'], fiscalCodeCtrl);
        AeroInvoicingComponent.removeCtrlErrors(['VatOrFiscalCodeRequired'], vatCtrl);
        // fiscalCodeCtrl.setErrors(null);
        // vatCtrl.setErrors(null);
        if (group.controls["customerType" /* CustomerType */].value !== "PGIT" /* ItCompany */) {
            return null;
        }
        if (fiscalCodeCtrl.value || vatCtrl.value) {
            return null;
        }
        fiscalCodeCtrl.setErrors({ 'VatOrFiscalCodeRequired': true });
        vatCtrl.setErrors({ 'VatOrFiscalCodeRequired': true });
        if (fiscalCodeCtrl.touched || vatCtrl.touched) {
            fiscalCodeCtrl.markAsTouched();
            vatCtrl.markAsTouched();
        }
        return { 'VatOrFiscalCodeRequired': true };
    };
    AeroInvoicingComponent.prototype.oneOfRecipientCodeOrEmailPecRequired = function (group) {
        if (!group)
            return null;
        var emailPecCtrl = group.controls["emailPec" /* EmailPec */];
        var recipientCode = group.controls["recipientCode" /* RecipientCode */];
        // emailPecCtrl.setErrors({ 'RecipientCodeOreEmailRequired': null });
        AeroInvoicingComponent.removeCtrlErrors(['RecipientCodeOreEmailRequired'], emailPecCtrl);
        AeroInvoicingComponent.removeCtrlErrors(['RecipientCodeOreEmailRequired'], recipientCode);
        // recipientCode.setErrors(null);
        if (group.controls["customerType" /* CustomerType */].value !== "PGIT" /* ItCompany */) {
            return null;
        }
        if (emailPecCtrl.value || recipientCode.value) {
            return null;
        }
        emailPecCtrl.setErrors({ 'RecipientCodeOreEmailRequired': true });
        recipientCode.setErrors({ 'RecipientCodeOreEmailRequired': true });
        if (emailPecCtrl.touched || recipientCode.touched) {
            emailPecCtrl.markAsTouched();
            recipientCode.markAsTouched();
        }
        return { 'RecipientCodeOreEmailRequired': true };
    };
    AeroInvoicingComponent.removeCtrlErrors = function (keys, control) {
        if (!control || !keys || keys.length === 0) {
            return;
        }
        var remainingErrors = keys.reduce(function (errors, key) {
            delete errors[key];
            return errors;
        }, tslib_1.__assign({}, control.errors));
        control.setErrors(null);
        control.setErrors(remainingErrors);
        if (Object.keys(control.errors || {}).length === 0) {
            control.setErrors(null);
        }
    };
    return AeroInvoicingComponent;
}());
export { AeroInvoicingComponent };
