import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';
import { BookingService } from 'src/app/core/booking.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { PushToWalletItem } from '../push-to-wallet.service';

@Component({
  selector: 'booking-ptw-flight-selection',
  templateUrl: './ptw-flight-selection.component.html',
  styleUrls: ['./ptw-flight-selection.component.scss'],
  styles: [`
        :host {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    `]
})
export class PtwFlightSelectionComponent implements OnInit, OnChanges {
  public totalSelectedAmount: number;
  public invalidSelection = false;
  public displayedErrors: string[] = [];
  public format = 'DD/MM/YYYY';
  public isRoundTrip: boolean;
  public isNonStandard: boolean;
  public showNonStandardAmount: boolean = false;
  public isB2BBooking: boolean;

  journeys: Array<any> = [];

  @Input() items: PushToWalletItem[];

  @Input() errors: string[];
  @Output() continue: EventEmitter<any> = new EventEmitter();

  constructor(private bookingService: BookingService, private flowManager: FlowManagerService) {
    this.flowManager.selectionService.flightsObs.pipe(filter(p => p ? true : false), take(1)).subscribe(flights => {
      this.isRoundTrip = flights.length > 1;
    });

    let booking = this.bookingService.getBooking();
    this.journeys = booking.convertedJourneys.journeys;

    this.isNonStandard = booking.passengers.items.some(p => p.fees &&
      (p.fees.items.some(f => f.code === 'NCH' ||
        f.code === 'SPL' ||
        f.code === 'DCH')));

    if(!this.bookingService.isB2CBooking()){
      this.isB2BBooking = true;
    }
  }

  async ngOnInit() {

    //this.items.sort((a, b) => a.departureDate.localeCompare(b.departureDate));
    this.checkRefundToWalletEligibility();
    this.setEnableRefund();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.errors && this.errors.length) {
      this.displayedErrors.length = 0;
      for (const err of this.errors) {
        if (err === 'InvalidSelection') {
          this.invalidSelection = true;
        } else {
          this.displayedErrors.push(err);
        }
      }
    }
  }

  submit() {
    const selectedJourneys = this.items.filter(x => x.isSelected).map(x => x.sellKey);
    if (selectedJourneys.length) {
      this.invalidSelection = false;
      var selection = {
        selectedJourneys: selectedJourneys
      }
      this.continue.next(selection);
    } else {
      this.invalidSelection = true;
    }
  }

  changeSelection(item: PushToWalletItem) {
    item.isSelected = !item.isSelected;
    this.invalidSelection = false;

    this.enableJourneyRefund();

    setTimeout(() => {
      this.computeTotalSelected();
    }, 0);
  }

  private computeTotalSelected() {
    let total = 0;


    if(this.isNonStandard && this.items.every(j => j.isSelected)){
      this.items.forEach(element => {
        if (element.isSelected) {
          total += element.nonStandardAmount;
          total += element.bonusNonStandardAmount > 0 ? element.bonusNonStandardAmount : 0;
        }
      });

      this.showNonStandardAmount = true;
    }else{
      this.items.forEach(element => {
        if (element.isSelected) {
          total += element.amount;
          total += element.bonusAmount > 0 ? element.bonusAmount : 0;
        }
      });

      this.showNonStandardAmount = false;
    }

    this.totalSelectedAmount = total;
  }

  checkHours(value: string): boolean {
    let date = moment(value);

    const currentDate = moment(new Date());

    let tempHours: number = date.diff(currentDate, 'hours');

    return tempHours >= 4 ? true : false;
  }

  checkRefundToWalletEligibility() {
    for (const j of this.journeys) {
      for (const s of j.segments) {
        for (const p of s.paxSegments) {
          if (p.liftStatus === 'CheckedIn' || p.liftStatus === 'Boarded') {
            for (const i of this.items) {
              if (i.sellKey.includes(s.sellKey)) {
                i.isNotEligibleForRefund = true;
              }
            }
          }
        }
      }
    }
  }

  enableJourneyRefund() {
    if (this.items.some(i => i.isModified && i.isSelected)) {
      this.items.forEach(i => {
        if (!i.isNotEligibleForRefund) {
          if (!i.isModified) {
            i.enableRefund = true;
          }
        } else {
          i.enableRefund = false;
        }
      })
    } else {
      this.items.forEach(i => {
        if (!i.isNotEligibleForRefund) {
          if (!i.isModified) {
            i.enableRefund = false;
            i.isSelected = false;
          }
        } else {
          i.enableRefund = false;
        }
      })
    }
  }

  setEnableRefund() {
    this.items.forEach(i => {
      if (!i.isNotEligibleForRefund) {
        if (i.isModified) {
          i.enableRefund = true;
        }
      } else {
        i.enableRefund = false;
      }
    })
  }

}
