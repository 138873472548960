import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { BookingChanges, InsuranceItinerary } from "../../core/models/booking-changes-model";
import { OnInit, Component, ElementRef, Renderer2, HostListener, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { BookingChangesService } from "../../core/booking-changes.service";
import { BookingFlowService } from "../../core/booking-flow.service";
import { CheckinSteps, BookingStepsService } from "../../core/booking-steps.service";
import { Constants } from "../../constants";
import { environment } from "../../../environments/environment";
import { BookingService } from 'src/app/core/booking.service';

@Component({
  selector: 'check-in-booking-changes',
  templateUrl: './booking-changes.component.html',
  styleUrls: ['./booking-changes.component.scss']
})

export class BookingChangesComponent implements OnInit, OnDestroy {
  private currentStep: CheckinSteps = CheckinSteps.changes;

  public assetsPath = environment.assetsPath;
  public segmentsNumber: number;
  public ssrChanges: BookingChanges[] = [];
  public seatChanges: BookingChanges[] = [];
  public passengerChanges: any;
  public insuranceChanges: InsuranceItinerary[] = [];
  public flightChanges: any;
  public journeys: any[] = [];
  public passengers: any;
  public mobileMatches: boolean;

  public showSeatChanges: boolean;
  public showExtrasChanges: boolean;
  public showFlightChanges: boolean;
  public showPassengerChanges: boolean;
  recordLocators: any[] = [];
  isRestricted: boolean;

  private changesSubscription: Subscription;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWindowWidth(event.target.innerWidth);
  }

  constructor(private bookingChangesService: BookingChangesService,
    private bookingFlowService: BookingFlowService,
    private bookingStepsService: BookingStepsService,
    private loadingSpinnerService: LoadingSpinnerService,
    private renderer: Renderer2,
    private bookingService: BookingService) {

    this.updateWindowWidth(window.innerWidth);
    this.bookingStepsService.currentStep.next(this.currentStep);

    this.changesSubscription = this.bookingChangesService.changesObs.subscribe((changes: any) => {
      if (changes) {
        this.ssrChanges = this.bookingChangesService.ssrChanges;
        this.seatChanges = this.bookingChangesService.seatChanges;
        this.insuranceChanges = this.bookingChangesService.insuranceChanges;
        this.flightChanges = this.bookingChangesService.flightChanges;
        this.passengerChanges = this.bookingChangesService.passengerChanges;
        this.setChangesVisibility();
      }
    });

    this.bookingFlowService.loadPriceBreakdown();
  }

  ngOnInit() {
    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
    this.bookingFlowService.loadFlowInfo().then(bookingResponse => {
      this.journeys = bookingResponse.convertedJourneys.journeys;
      this.passengers = bookingResponse.passengers.items;
      this.bookingChangesService.getSsrChanges(this.journeys, this.passengers, bookingResponse.convertedJourneys.snapshotBooking);
      this.segmentsNumber = this.journeys.map(p => p.segments).reduce((a, b) => a.concat(b), []).length;
      this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
      
      if (bookingResponse && bookingResponse.convertedJourneys && bookingResponse.convertedJourneys.snapshotBooking && 
          bookingResponse.convertedJourneys.snapshotBooking.recordLocators && bookingResponse.convertedJourneys.snapshotBooking.recordLocators.length > 0) {
        this.recordLocators = bookingResponse.convertedJourneys.snapshotBooking.recordLocators;
        this.isRestricted = this.isRestrictedBooking();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.changesSubscription) {
      this.changesSubscription.unsubscribe();
    }
  }

  toggleVisibility(extrasToggle: ElementRef) {
    if ((extrasToggle as any).classList.contains('show')) {
      this.renderer.removeClass(extrasToggle, 'show');
    } else {
      this.renderer.addClass(extrasToggle, 'show');
    }
  }

  // to do: add this in a service
  private updateWindowWidth(width) {
    this.mobileMatches = width < Constants.mobileScreenWith;
  }

  private setChangesVisibility() {
    if (this.flightChanges && this.flightChanges.oldJourneys && this.flightChanges.newJourneys &&
      this.flightChanges.oldJourneys.length > 0 && this.flightChanges.newJourneys.length > 0) {
      this.showFlightChanges = true;
    }

    if (this.ssrChanges) {
      this.showExtrasChanges = true;
    }

    if (this.insuranceChanges) {
      const boughtOnChangeFlow = this.insuranceChanges.find(ins => ins.boughtOnChangeFlow === true) ? true : false;
      this.showExtrasChanges = boughtOnChangeFlow || this.showExtrasChanges;
    }

    if (this.seatChanges) {
      this.showSeatChanges = this.checkIfNewValues(this.seatChanges);
    }

    if (this.passengerChanges) {
      this.showPassengerChanges = this.passengerChanges.length > 0 && this.passengerChanges.some(pax => pax.modifiedFields.length > 0);
    }
  }

  private checkIfNewValues(array) {
    return array.map(s => s.passengerSsrsByType)
      .reduce((a, b) => a.concat(b), [])
      .map(s => s.ssrCountOnSegment)
      .reduce((a, b) => a.concat(b), [])
      .map(s => s.ssrCount)
      .reduce((a, b) => a.concat(b), [])
      .some(s => s.oldValue !== s.newValue);
  }

  confirmChanges() {
    this.bookingStepsService.goToNextStepFrom(this.currentStep);
  }

  isRestrictedBooking() {
    return (this.recordLocators.some(r => r.owningSystemCode !== null && r.owningSystemCode !== undefined)) ? true : false;
  }
}
