<div class="mfp-wrap mfp-align-top mfp-close-btn-in mfp-auto-cursor mfp-ready" tabindex="-1" style="overflow-x: hidden; overflow-y: auto;"
  *ngIf="isOpened">
  <div class="mfp-container mfp-inline-holder full-bg-popup">
    <div class="mfp-content">
      <div class="popup-window {{cssClass}}">
        <ng-content></ng-content>
        <button title="{{closeBtnLabel}}" type="button" class="mfp-close" (click)="close()">×</button>
      </div>
    </div>
  </div>
</div>