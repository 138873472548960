import * as tslib_1 from "tslib";
import { SeatMapService } from './../../seat/seat-map.service';
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { SsrBoxContentPriorityBagComponent } from './../ssr-box-content-priority-bag/ssr-box-content-priority-bag.component';
import { SsrBoxContentFastTrackComponent } from './../ssr-box-content-fast-track/ssr-box-content-fast-track.component';
import { OnInit, OnChanges, SimpleChanges, OnDestroy, QueryList, ElementRef, AfterViewInit } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { SsrsService } from '../ssrs.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SsrBoxContentLuggageComponent } from '../ssr-box-content-luggage/ssr-box-content-luggage.component';
import { SsrBoxContentMealsComponent } from '../ssr-box-content-meals/ssr-box-content-meals.component';
import { SsrBoxContentPetsComponent } from '../ssr-box-content-pets/ssr-box-content-pets.component';
import { SsrBoxContentSpecialEquipmentComponent } from '../ssr-box-content-special-equipment/ssr-box-content-special-equipment.component';
// tslint:disable-next-line:max-line-length
import { SsrBoxContentSpecialAssistanceComponent } from '../ssr-box-content-special-assistance/ssr-box-content-special-assistance.component';
import { ConfigService } from '../../core/config.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { SsrBoxContentPriorityBoardingComponent } from '../ssr-box-content-priority-boarding/ssr-box-content-priority-boarding.component';
import { ValidationModalComponent } from '../validation-modal/validation-modal.component';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { SsrBoxContentLoungeComponent } from '../ssr-box-content-lounge/ssr-box-content-lounge.component';
// tslint:disable-next-line: max-line-length
import { SsrsSeatsRemovalNotificationComponent } from '../../shared/ssrs-seats-removal-notification/ssrs-seats-removal-notification.component';
import { SsrSellingTypeHelper } from '../ssr-selling-type.enum';
import { environment } from '../../../environments/environment';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { SsrBoxContentAirportCheckinComponent } from '../ssr-box-content-airport-checkin/ssr-box-content-airport-checkin.component';
import { ProfileService } from 'src/app/core/profile.service';
import { BookingFareSelectSyncService } from '../../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { isArray } from 'util';
import { SsrBoxContentFlexComponent } from '../ssr-box-content-flex/ssr-box-content-flex.component';
import * as moment from 'moment';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';
import { SsrBoxContentEarlyCheckinComponent } from '../ssr-box-content-early-checkin/ssr-box-content-early-checkin.component';
import { SsrBoxContentBusTransfer } from '../ssr-box-content-bus-transfer/ssr-box-content-bus-transfer.component';
var SsrBoxComponent = /** @class */ (function () {
    function SsrBoxComponent(ssrsService, flowManager, config, loadingSpinnerService, translateService, elRef, seatmapService, ecommerce, profileService, syncService, resourcesService) {
        var _this = this;
        this.ssrsService = ssrsService;
        this.flowManager = flowManager;
        this.config = config;
        this.loadingSpinnerService = loadingSpinnerService;
        this.translateService = translateService;
        this.elRef = elRef;
        this.seatmapService = seatmapService;
        this.ecommerce = ecommerce;
        this.profileService = profileService;
        this.syncService = syncService;
        this.resourcesService = resourcesService;
        this.flightsObs = new BehaviorSubject(null);
        this.currentFlights = null;
        this.SsrTypeEnum = SsrType;
        this.isExpanded = false;
        this.isModified = false;
        this.pdfLanguage = 'en';
        this.enableSameOptionsForAllFlights = false;
        this.ssrBoxContentComponents = [];
        this.promoDiscount = null;
        this.minHoursToPurchaseBeforeFlight = null;
        this.ssrsAvailabilityByFlight = {};
        this.afterSaveChanges = [];
        this.assetsPath = environment.assetsPath;
        this.shoppingCartExtrasSubscription = this.flowManager.applicationFlowService.shoppingCartBreakdown.pipe(filter(function (b) { return b && b.currentShoppingCart ? true : false; }))
            .subscribe(function (b) {
            _this.shoppingCartExtras = b.currentShoppingCart.extras;
            if (!_this.isExpanded) {
                _this.updateTotalPrice(true);
            }
        });
        this.reloadSsrSubscription = this.ssrsService.reloadingSsrsObs.pipe(filter(function (loadingInfo) { return loadingInfo.ssrType === _this.ssrType; }))
            .subscribe(function (loadingInfo) {
            if (loadingInfo.isLoading) {
                _this.loadingSpinnerService.showSpinnerById(_this.spinnerId);
            }
            else {
                _this.loadingSpinnerService.hideSpinnerById(_this.spinnerId);
            }
        });
        if (this.translateService.culture) {
            this.pdfLanguage = this.translateService.culture.toLowerCase().includes('ro') ? 'ro' : 'en';
        }
        this.enableFlightsUpdates();
    }
    Object.defineProperty(SsrBoxComponent.prototype, "flights", {
        set: function (value) {
            this.flightsObs.next(value);
        },
        enumerable: true,
        configurable: true
    });
    SsrBoxComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _a;
            return tslib_1.__generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.resourcesService.getMinHoursToPurchaseBeforeFlightAsync()];
                    case 1:
                        _a.minHoursToPurchaseBeforeFlight = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SsrBoxComponent.prototype.ngOnDestroy = function () {
        if (this.shoppingCartExtrasSubscription) {
            this.shoppingCartExtrasSubscription.unsubscribe();
        }
        if (this.availableSsrsSubscription) {
            this.availableSsrsSubscription.unsubscribe();
        }
        if (this.reloadSsrSubscription) {
            this.reloadSsrSubscription.unsubscribe();
        }
        if (this.flightsSub) {
            this.flightsSub.unsubscribe();
        }
    };
    SsrBoxComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var components;
        switch (this.ssrType) {
            case SsrType.Baggage:
                components = this.luggageComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                this.afterSaveChanges.push(function () {
                    _this.ssrsService.toggleLoadingForSsrType(true, SsrType.PriorityBag);
                    var showPriorityPopup = _this.ssrsService.updatePriorityBag(_this.currentFlights);
                    if (showPriorityPopup) {
                        _this.pbagModal.openPopup(function () { });
                        return _this.ssrsService.applySsrs(SsrType.PriorityBag);
                    }
                    return Promise.resolve();
                });
                this.afterSaveChanges.push(function () { return _this.ssrsService.getSsrsInfo().toPromise(); });
                this.afterSaveChanges.push(function () { return _this.ssrsService.toggleLoadingForSsrType(false, SsrType.PriorityBag); });
                break;
            case SsrType.Meal:
                components = this.mealComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                this.availableSsrsSubscription = this.ssrsService.availableSsrsObs.subscribe(function (avSsrs) {
                    if (avSsrs) {
                        _this.setLowestPrice(_this.ssrType);
                    }
                });
                break;
            case SsrType.Lounge:
                components = this.loungeComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
            case SsrType.Pet:
                components = this.petComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
            case SsrType.SpecialEquipment:
                components = this.specialEquipmentComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
            case SsrType.SpecialAssistance:
                components = this.specialAssistanceComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                // this.getSeatsWithoutSsrRestriction();
                break;
            case SsrType.PriorityBoarding:
                components = this.priorityBoardingComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
            case SsrType.PriorityBag:
                components = this.priorityBagComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                this.availableSsrsSubscription = this.ssrsService.availableSsrsObs.subscribe(function (avSsrs) {
                    if (avSsrs) {
                        _this.cancelChanges();
                        _this.updateSsrAvailabilityByFlight();
                        _this.setLowestPrice(_this.ssrType);
                    }
                });
                break;
            case SsrType.FastTrack:
                components = this.fastTrackComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
            case SsrType.AirportCheckin:
                components = this.airportCheckinComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
            case SsrType.EarlyCheckin:
                components = this.earlyCheckinComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
            case SsrType.BusTransfer:
                components = this.busTransferComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
            case SsrType.Flex:
                components = this.flexComponents.changes.subscribe(function (r) { return _this.setReturnFlightComponent(r); });
                break;
        }
        this.expandBox();
    };
    SsrBoxComponent.prototype.setLowestPrice = function (ssrType) {
        var _this = this;
        var ssrsList = this.ssrsService.availableSsrs.find(function (lists) { return lists.key === ssrType; });
        if (ssrsList) {
            var ssrsArray = ssrsList.value.map(function (s) { return s.value; }).reduce(function (a, b) { return a.concat(b); }, []);
            var prices = ssrsArray.map(function (s) { return s.price; });
            this.lowestPrice = Math.min.apply(Math, tslib_1.__spread(prices));
            this.ssrCodesList = ssrsArray.map(function (s) { return s.ssrCode; });
            if (this.ssrsService.memberPromotions.ssrSegmentPromotionList.length > 0) {
                var originalPrices_1 = [];
                ssrsArray.forEach(function (ssr) {
                    if (ssr.originalPrice) {
                        originalPrices_1.push(ssr.originalPrice);
                        _this.hasPromotion = true;
                    }
                    else {
                        originalPrices_1.push(ssr.price);
                    }
                });
                if (this.hasPromotion) {
                    this.lowestOriginalPrice = Math.min.apply(Math, tslib_1.__spread(originalPrices_1));
                    this.hasPromotion = this.lowestOriginalPrice !== this.lowestPrice;
                }
            }
        }
    };
    SsrBoxComponent.prototype.setReturnFlightComponent = function (components) {
        var _this = this;
        setTimeout(function () {
            _this.ssrBoxContentComponents = components ? components.toArray() : [];
        });
    };
    SsrBoxComponent.prototype.ngOnChanges = function (changes) {
        if (changes.ssrType) {
            this.setLowestPrice(changes.ssrType.currentValue);
            this.ssrsService.resetSsrs(this.ssrType);
            this.updateSsrAvailabilityByFlight();
            this.spinnerId = 'extras-spinner' + this.ssrType;
            this.promoDiscount = this.ssrsService.getPromoDiscount(this.ssrType);
        }
    };
    SsrBoxComponent.prototype.expandBox = function () {
        if (this.flightsSub) {
            this.flightsSub.unsubscribe();
        }
        this.isExpanded = true;
        var ecommerceCartItems = [];
        var item = new ECommerceCartItem(SsrType[this.ssrType], this.ecommerce.getFlightInfo(0), '0', 'BlueAir', SsrType[this.ssrType] + ' click', SsrType[this.ssrType], 1, this.ssrsService.actualDisplayOrder.indexOf(this.ssrType) + 1, null, this.ecommerce.getUserData())
            .getItem(true);
        ecommerceCartItems.push(item);
        this.ecommerce.ProductClicks(ecommerceCartItems);
    };
    SsrBoxComponent.prototype.enableFlightsUpdates = function () {
        var _this = this;
        if (!this.flightsSub || this.flightsSub.closed) {
            this.flightsSub = this.flightsObs.subscribe(function (newFlights) {
                _this.currentFlights = newFlights;
                try {
                    if (_this.syncService && isArray(newFlights)) {
                        _this.syncService.flights = tslib_1.__spread(_this.currentFlights);
                    }
                }
                catch (ex) {
                    console.log(ex.message);
                }
                _this.updateSsrAvailabilityByFlight();
                _this.updateTotalPrice(true);
                _this.enableSameOptionsForAllFlights =
                    _this.currentFlights && _this.currentFlights.length === 2 &&
                        _this.currentFlights[0].departureStation.iataCode === _this.currentFlights[1].arrivalStation.iataCode &&
                        _this.currentFlights[0].arrivalStation.iataCode === _this.currentFlights[1].departureStation.iataCode;
            });
        }
    };
    SsrBoxComponent.prototype.saveChanges = function (params) {
        var _this = this;
        this.ssrsService.resetSsrs(this.ssrType);
        var newSsrs = [];
        this.ssrBoxContentComponents.forEach(function (c) {
            newSsrs.push.apply(newSsrs, tslib_1.__spread(c.applySsrs()));
        });
        var seatsToBeRemoved = this.ssrsService.getSeatsToBeRemoved(this.ssrType, this.currentFlights, newSsrs);
        if (!seatsToBeRemoved.length) {
            this.completeSaveChanges();
        }
        else {
            this.seatRemovalModal.openPopup(function (userAgreed) {
                if (userAgreed) {
                    _this.loadingSpinnerService.showSpinnerById(_this.spinnerId);
                    _this.seatmapService.removeSeats(seatsToBeRemoved).then(function () { return _this.completeSaveChanges(); });
                }
                else {
                    if (params && params.onCancelSeatRemoval) {
                        params.onCancelSeatRemoval();
                    }
                }
            }, seatsToBeRemoved);
        }
    };
    SsrBoxComponent.prototype.completeSaveChanges = function () {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        this.ssrsService.applySsrs(this.ssrType)
            .then(function () { return _this.enableFlightsUpdates(); })
            .then(function () {
            // chain all after save changes promises
            return _this.afterSaveChanges.reduce(function (chain, current) { return chain.then(current); }, Promise.resolve());
        })
            .then(function () {
            // this.closeBox();
            _this.loadingSpinnerService.hideSpinnerById(_this.spinnerId);
        });
    };
    SsrBoxComponent.prototype.cancelChanges = function () {
        this.ssrsService.resetSsrs(this.ssrType);
        this.enableFlightsUpdates();
        this.closeBox();
    };
    SsrBoxComponent.prototype.closeBox = function () {
        this.isExpanded = false;
    };
    SsrBoxComponent.prototype._isPassengersArray = function (passenger) {
        if (Array.isArray(passenger)) {
            return true;
        }
        return false;
    };
    SsrBoxComponent.prototype._removeFromCartForOnePassenger = function (flight, passenger) {
        var _this = this;
        for (var passengerNumber in flight.ssrs) {
            if (!(passenger && passenger.passengerNumber.toString() !== passengerNumber)) {
                if (flight.ssrs.hasOwnProperty(passengerNumber)) {
                    var passengerSsrs = flight.ssrs[passengerNumber].filter(function (s) { return _this.ssrCodesList.indexOf(s) >= 0; });
                    var paidSsrs = flight.paidSsrs[passengerNumber].filter(function (s) { return _this.ssrCodesList.indexOf(s) >= 0; });
                    var ssrGroupBy = passengerSsrs.reduce(function (group, ssr) {
                        (group[ssr] = group[ssr] || []).push(ssr);
                        return group;
                    }, {});
                    var _loop_1 = function (ssrCode) {
                        if (ssrGroupBy.hasOwnProperty(ssrCode)) {
                            var count = ssrGroupBy[ssrCode].length;
                            while (count > 0) {
                                var paidSsrsCount = paidSsrs.filter(function (ssr) { return ssr === ssrCode; }).length;
                                if (paidSsrsCount > 0) {
                                    this_1.ssrsService.removeSsr(this_1.ssrType, ssrCode, count + paidSsrsCount, +passengerNumber, flight, true);
                                }
                                else {
                                    this_1.ssrsService.removeSsr(this_1.ssrType, ssrCode, count, +passengerNumber, flight, true);
                                }
                                count--;
                            }
                        }
                    };
                    var this_1 = this;
                    for (var ssrCode in ssrGroupBy) {
                        _loop_1(ssrCode);
                    }
                }
            }
        }
    };
    SsrBoxComponent.prototype.removeFromCart = function (passengers) {
        var _this = this;
        passengers = passengers || [];
        this.currentFlights.forEach(function (f) {
            if (passengers.length === 0) {
                _this._removeFromCartForOnePassenger(f);
            }
            else {
                passengers.forEach(function (p) {
                    if (p.flightUniqueId === f.flightUniqueId) {
                        _this._removeFromCartForOnePassenger(f, p);
                    }
                });
            }
        });
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        this.ssrsService.applySsrs(this.ssrType)
            .then(function () { return _this.enableFlightsUpdates(); })
            .then(function () {
            return _this.afterSaveChanges.reduce(function (chain, current) { return chain.then(current); }, Promise.resolve());
        })
            .then(function () {
            _this.flightPrices.forEach(function (f, i) {
                f.fill(0);
            });
            _this.loadingSpinnerService.hideSpinnerById(_this.spinnerId);
        });
    };
    SsrBoxComponent.prototype.getIsModified = function () {
        switch (this.ssrType) {
            case SsrType.SpecialAssistance:
            case SsrType.Meal:
            case SsrType.Baggage:
            case SsrType.SpecialEquipment: {
                return this.areSsrsSelected();
            }
            default: {
                return this.totalPrice > 0;
            }
        }
    };
    SsrBoxComponent.prototype.areSsrsSelected = function () {
        var _this = this;
        return this.shoppingCartExtras.some(function (x) { return x.ssrType === _this.ssrType && x.flightsWithItems.length > 0; });
    };
    SsrBoxComponent.prototype.updateTotalPrice = function (forced) {
        var _this = this;
        if (forced === void 0) { forced = false; }
        if (!this.shoppingCartExtras || !this.ssrType) {
            return;
        }
        var shoppingCartItem = this.shoppingCartExtras.find(function (e) { return e.ssrType === _this.ssrType; });
        this.totalPrice = shoppingCartItem ? shoppingCartItem.amount : 0;
        this.isModified = this.getIsModified();
        this.temporaryTotalPrice = this.totalPrice;
        if (this.currentFlights && (!this.flightPrices || forced)) {
            this.flightPrices = this.currentFlights.map(function (f) {
                var prices = [];
                prices.length = f.segments.length;
                prices.fill(0);
                if (shoppingCartItem) {
                    var shoppingCartSegments_1 = shoppingCartItem.flights
                        .filter(function (sf) { return sf.departureStation === f.departureStation.iataCode && sf.arrivalStation === f.arrivalStation.iataCode; })
                        .reduce(function (segments, sf) { return segments.concat(sf.segments); }, []);
                    f.segments.forEach(function (seg, i) {
                        prices[i] = shoppingCartSegments_1
                            .filter(function (ss) { return ss.departureStation === seg.departureStation.iataCode && ss.arrivalStation === seg.arrivalStation.iataCode; })
                            .sum(function (ss) { return ss.amount; });
                    });
                    if (SsrSellingTypeHelper.IsSsrTypeSoldByJourney(_this.ssrType)) {
                        prices = [prices.sum(function (p) { return p; })];
                    }
                }
                return prices;
            });
        }
    };
    SsrBoxComponent.prototype.updateTotalPricePerFlight = function (price, flightIndex, segmentIndex) {
        if (segmentIndex === void 0) { segmentIndex = 0; }
        if (!this.flightPrices) {
            this.flightPrices = this.currentFlights.map(function (f) {
                return f.segments.map(function (s) { return 0; });
            });
        }
        this.flightPrices[flightIndex][segmentIndex] = price;
        this.temporaryTotalPrice = this.flightPrices
            .reduce(function (a, b) { return a.concat(b); }, [])
            .reduce(function (a, b) { return a + b; });
    };
    SsrBoxComponent.prototype.updateSsrAvailabilityByFlight = function () {
        var _this = this;
        if (!this.currentFlights || !this.ssrType) {
            return;
        }
        this.ssrsAvailabilityByFlight = {};
        var ssrList = this.ssrsService.availableSsrs.find(function (lists) { return lists.key === _this.ssrType; });
        if (ssrList && ssrList.value && ssrList.value.length > 0) {
            var allSegments = this.currentFlights.map(function (f) { return f.segments; }).reduce(function (a, b) { return a.concat(b); }, []);
            this.currentFlights.concat(allSegments).forEach(function (segOrFlight) {
                var flightKey = segOrFlight.departureStation.iataCode + "_" + segOrFlight.arrivalStation.iataCode;
                var ssrs = ssrList.value.find(function (s) { return s.key === flightKey; });
                _this.ssrsAvailabilityByFlight[flightKey] = ssrs && ssrs.value && ssrs.value.some(function (i) { return i.limitPerPassenger.some(function (p) { return p.value > 0; }); });
            });
        }
    };
    SsrBoxComponent.prototype.isAvailableForFlight = function (flight) {
        var _this = this;
        if (this.ssrType == this.SsrTypeEnum.FastTrack) {
            var segmentsNotAvailable_1 = 0;
            flight.segments.forEach(function (segment) {
                var flightKey = segment.departureStation.iataCode + "_" + segment.arrivalStation.iataCode;
                if (!_this.ssrsAvailabilityByFlight[flightKey]) {
                    segmentsNotAvailable_1++;
                }
                ;
            });
            if (segmentsNotAvailable_1 == flight.segments.length) {
                return false;
            }
            return true;
        }
        var flightKey = flight.departureStation.iataCode + "_" + flight.arrivalStation.iataCode;
        return this.ssrsAvailabilityByFlight[flightKey] && this.ssrsAvailabilityByFlight[flightKey] === true;
    };
    SsrBoxComponent.prototype.isAvailableForJourney = function (flights) {
        var _this = this;
        if (flights.map(function (f) { return _this.isAvailableForFlight(f); }).every(function (v) { return v == false; })) {
            return false;
        }
        return true;
    };
    SsrBoxComponent.prototype.applySsrs = function () {
        this.ssrsService.resetSsrs(this.ssrType);
        this.ssrBoxContentComponents.forEach(function (c) {
            c.applySsrs();
        });
    };
    /**
     *
     * @param sellDefaultSsr if TRUE, user has decided to buy default meal ssr
     * else user wants to add meal ssr for each passenger
     */
    SsrBoxComponent.prototype.validationModalAction = function (sellDefaultSsr) {
        if (!sellDefaultSsr) {
            this.elRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
            //this.expandBox();
        }
    };
    SsrBoxComponent.prototype.isFlexAvailable = function (flight) {
        if (this.currentFlights.every(function (f) { return f.paidSsrs[0] && f.paidSsrs[0].some(function (s) { return s === "FLX"; }); })) {
            return true;
        }
        var date = moment(flight.departureStation.dateUtc);
        var currentDate = moment(new Date());
        var tempHours = date.diff(currentDate, 'hours');
        return tempHours >= this.minHoursToPurchaseBeforeFlight ? true : false;
    };
    SsrBoxComponent.prototype.showLuggageErrors = function () {
        this.luggageComponents.forEach(function (c) {
            c.passengers.forEach(function (p) {
                if (!c.ssrsService.luggagePassengersMap.get(c.flight.departureStation.iataCode + c.flight.arrivalStation.iataCode)) {
                    c.scrollTo();
                }
            });
        });
    };
    return SsrBoxComponent;
}());
export { SsrBoxComponent };
