import { ThrowStmt } from "@angular/compiler";

export interface ICartItem {
  item_name: string;
  item_id: string;
  price?: string;
  affiliation?: string,
  item_category: string;
  item_variant: string;
  quantity: number;
  index: number;
  item_category2: string;
  item_category3: string;
}

export class ECommerceCartItem implements ICartItem {
  item_name: string;
  item_id: string;
  price?: string;
  affiliation: string = "AeroItalia";
  item_category: string;
  item_variant: string;
  quantity: number;
  index: number | null = null;
  item_category2: string;
  item_category3: string;

  constructor(name?: string, id?: string, price?: string, brand?: string, category?: string, variant?: string, quantity?: number,
    position?: number, dimension1?: string, dimension2?: string) {
    this.item_name = name;
    this.item_id = id;
    this.price = price;
    this.affiliation = brand;
    this.item_category = category;
    this.item_variant = variant;
    this.quantity = quantity;
    this.item_category2 = dimension1;
    this.item_category3 = dimension2;
    this.index = position;
  }

  public getItem(hasPosition: boolean): Partial<ICartItem> {
    const result: Partial<ICartItem> = {
      item_name: this.item_name,
      item_id: this.item_id,
      price: this.price,
      affiliation: this.affiliation,
      item_category: this.item_category,
      item_variant: this.item_variant,
      quantity: this.quantity,
      item_category2: this.item_category2,
      item_category3: this.item_category3,
      index: this.index
    };

    if (hasPosition) {
      result.index = this.index;
    }
    return result;
  }
}
