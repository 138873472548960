<check-in-form-wrapper [ariaLabel]="''" [step]="'calendar'" [showContinue]="true" [showPriceDisplay]="false" (continue)="continue()">
    <div class="form-wrapper-middle-body">
        <check-in-default-modal 
            modalId="invalidDatesModalId" 
            modalText="{{ 'The return flight date must be after the outbound flight date' | translate:'lowFareCalendar' }}"
            [okRequired]="false" 
            [cancelRequired]="false">
        </check-in-default-modal>
        <ng-container *ngFor="let item of journeys; let i = index">
                <h1 *ngIf="i==0">
                    {{'Select your day of departure'|translate:'lowFareCalendar'}}
                    <span class="return"><span class="flight-path"></span></span>
                </h1>
                <h1 class="return-day" *ngIf="i==1">
                    {{'Select your day of return'|translate:'lowFareCalendar'}}
                    <span class="return"><span class="flight-path"></span></span>
                </h1>
                <low-fare-calendar-view [origin]="item.origin" [destination]="item.destination" [initialDate]="item.selectedDate" [isOutbound]="i == 0"
                    [minDate]="i > 0 ? selections[i-1].date : ''" (dayChange)="calendarSelectionChanged(i, $event)" *ngIf="i == 0  || selections[i-1] && selections[i-1].farePrice"></low-fare-calendar-view>
        </ng-container>
    </div>
</check-in-form-wrapper>