<div class="select-box birthday-options-select">
    <div class="select-wrap">
      <select [disabled]="isDisabled" id="day-{{uniqueId}}"[(ngModel)]="selectDay"   (change)="onChangeDay($event.target.value)">
        <option *ngFor="let day of [defaultStrDay].concat(dateRange.daysArray); let ind = index" [value]="ind">
            {{day}}
        </option>
      </select>
    </div>
    <div class="select-wrap">
      <select [disabled]="isDisabled" id="month-{{uniqueId}}"[(ngModel)]="selectMonth"  (change)="onChangeMonth($event.target.value)">
          <option *ngFor="let month of [defaultStrMonth].concat(dateRange.monthsArray)" [value]="month">
              {{ month | titlecase }}
          </option>
      </select>
    </div>
    <div class="select-wrap">
      <select [disabled]="isDisabled" id="year-{{uniqueId}}" [(ngModel)]="selectYear" (change)="onChangeYear($event.target.value)">
        <option *ngFor="let year of [defaultStr].concat(dateRange.yearsArray)" [value]="year">
              {{year}}
          </option>
      </select>
    </div>
  </div>