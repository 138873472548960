export enum BookingStatusEnum {
    Default = 0,
    Hold = 1,
    Confirmed = 2,
    Closed = 3,
    HoldCanceled = 4,
    PendingArchive = 5,
    Archived = 6,
    Disrupted = 7,
    RefundedIntoWallet = 8,
    PartiallyRefunded = 9,
    Canceled = 10
}
