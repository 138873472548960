import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
} from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";
import { ShoppingCartBreakdownModel } from "../../../core/models/shopping-cart-models";
import { ConfigService } from "../../../core/config.service";
import {
  ApplicationFlowEnum,
  BookingStepsService,
  CheckinSteps,
} from "../../../core/booking-steps.service";
import { environment } from "../../../../environments/environment";
import { FlowManagerService } from "src/app/core/flow-manager.service";
import { BookingFlightSelectService } from "src/app/booking-flight-select/booking-flight-select.service";
import { ApplicationFlowService } from "src/app/core/application-flow.service";
import { ProfileService } from "src/app/core/profile.service";
import { SsrsService } from "src/app/extras/ssrs.service";
import { BookingService } from "src/app/core/booking.service";
import { BookingFlowService } from "src/app/core/booking-flow.service";

@Component({
  // tslint:disable-next-line: component-selector
  selector: "shopping-cart",
  templateUrl: "./shopping-cart.component.html",
  styleUrls: ["./shopping-cart.component.scss"],
})
export class ShoppingCartComponent implements OnInit, OnDestroy {
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  isExpanded: { [cart: string]: boolean } = {};
  route: string[] = [];
  total: number;

  breakdown: ShoppingCartBreakdownModel;
  showExtras = false;
  flightTitleUnknown = false;

  recordLocators: any[] = [];
  isRestricted: boolean;

  private shoppingCartSubscription: Subscription;
  private bookingStepSubscription: Subscription;
  private bookingSubscription: Subscription;

  constructor(
    private flowManager: FlowManagerService,
    configService: ConfigService,
    bookingStepsService: BookingStepsService,
    private bookingFlightSelectService: BookingFlightSelectService,
    public ssrsService: SsrsService,
    private bookingService: BookingService,
    private bookingFlowService: BookingFlowService,
  ) {
    this.route = [
      configService.ShoppingCartBreakdownUrl,
      configService.PriceItinerary,
    ];

    this.shoppingCartSubscription =
      flowManager.applicationFlowService.shoppingCartBreakdown
        .pipe(filter((b) => (b ? true : false)))
        .subscribe((value) => {
          this.breakdown = value;

          this.showExtras =
            environment.flow === 0 ||
            this.breakdown.currentShoppingCart.extras.length > 0;
        });

    this.bookingStepSubscription = bookingStepsService.currentStep.subscribe(
      (currentStep) => {
        this.flightTitleUnknown =
          environment.flow !== 0 && currentStep === CheckinSteps.flights;
      }
    );

    this.bookingFlowService.loadFlowInfo().then(booking => {
      if (booking && booking.convertedJourneys && booking.convertedJourneys.snapshotBooking && 
          booking.convertedJourneys.snapshotBooking.recordLocators && booking.convertedJourneys.snapshotBooking.recordLocators.length > 0) {
            this.recordLocators = booking.convertedJourneys.snapshotBooking.recordLocators;
            this.isRestricted = this.isRestrictedBooking();
      }
    });
  }

  private distinctItems(value, index, self) {
    return self.indexOf(value) === index;
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.shoppingCartSubscription) {
      this.shoppingCartSubscription.unsubscribe();
    }
    if (this.bookingStepSubscription) {
      this.bookingStepSubscription.unsubscribe();
    }
    if (this.bookingSubscription) {
      this.bookingSubscription.unsubscribe();
    }
  }

  toggleExpanded(cart: string) {
    this.isExpanded[cart] = !this.isExpanded[cart];
    if (cart == "current" && !this.isExpanded[cart]) {
      Object.keys(this.isExpanded).forEach((f) => (this.isExpanded[f] = false));
    }
  }

  goToNextStep() {
    this.continue.next();
  }

  isRestrictedBooking() {
    return (this.recordLocators.some(r => r.owningSystemCode !== null && r.owningSystemCode !== undefined)) ? true : false;
  }
}
