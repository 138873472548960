import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckinSteps, BookingStepsService } from './booking-steps.service';
import { CheckinService } from './checkin.service';
import { BookingService } from './booking.service';
import { environment } from '../../environments/environment';
import { ApplicationFlowService } from './application-flow.service';


@Injectable()
export class BaseCanActivateService implements CanActivate {
  public booking: any;
  protected forceFlowInfoRefresh = false;

  constructor(public steps: BookingStepsService, public applicationFlowService: ApplicationFlowService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
      return environment.flow === 0 ? this.validateCheckinSession() : this.validateBookingSession();
    // return this.bookingService.refresh().then(()=>{
    //   this.booking = this.bookingService.getBooking();
    //   if(this.booking)
    //    return this.booking.bookingDetail.recordLocator.length == 6
    //    else
    //    return false;
    // }).catch(_=>{
    //   return false;
    // });
  }

  private validateCheckinSession(): Promise<boolean> {
    return this.applicationFlowService.loadFlowInfo().then(checkinInfo => {
      if (checkinInfo.criteria && checkinInfo.criteria.length &&
        checkinInfo.passengerJourneyBags && checkinInfo.passengerJourneyBags.length) {
        return true;
      }

      this.steps.goToStep(CheckinSteps.search);
      return false;
    });
  }

  private validateBookingSession(): Promise<boolean> {
    return this.applicationFlowService.loadFlowInfo(this.forceFlowInfoRefresh).then(booking => {
      if (booking.convertedJourneys.journeys.length) {
        return true;
      }

      this.steps.navigate(['no-session'], { replaceUrl: true });
      return false;
    });
  }
}
