
import { BehaviorSubject, Subscription } from 'rxjs';
import { ICanDeactivateComponent } from '../core/can-deactivate-component.service';
import { SeatSelectedNotification } from '../shared/seat-selected-notification/seat-selected-notification.component';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { BookingStepsService, CheckinSteps } from '../core/booking-steps.service';
import { LoadingSpinnerService } from '../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../constants';
// import { SsrsService } from '../ssrs.service';
import { SsrsComponent } from '../extras/ssrs/ssrs.component';
import { InsuranceComponent } from '../extras/insurance/insurance.component';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DefaultModalComponent } from '../shared/default-modal/default-modal.component';
import { SsrType } from '../extras/ssr-type.enum';
import { PassengerBundleComponent } from '../shared/passenger-bundle/passenger-bundle.component';
// import { IHasPendingChanges } from '../../core/has-pending-changes.interface';
import { SelectionsClearWarningModalComponent } from '../shared/selections-clear-warning-modal/selections-clear-warning-modal.component';
import { ApplicationFlowService } from '../core/application-flow.service';
import { filter, take } from 'rxjs/operators';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { environment } from 'src/environments/environment';
import { ProfileService } from 'src/app/core/profile.service';
import { BookingService } from '../core/booking.service';
import { FlightModel } from '../core/models/flight-model';
import { ShoppingCartExtrasFlightModel, ShoppingCartExtrasModel } from '../core/models/shopping-cart-models';
import { SsrSellingTypeHelper } from '../extras/ssr-selling-type.enum';
import { SsrsService } from '../extras/ssrs.service';
import { SsrBoxComponent } from '../extras/ssr-box/ssr-box.component';
import { BookingFareSelectSyncService } from '../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { ECommerceService } from '../shared/e-commerce/ecommerce.service';


@Component({
  selector: 'check-in-luggage',
  templateUrl: './luggage.component.html',
  styleUrls: ['./luggage.component.scss']
})
export class LuggageComponent implements OnInit, ICanDeactivateComponent {
  _canDeactivate = false;
  currentStep: CheckinSteps = CheckinSteps.luggage;
  applicationFlowService: ApplicationFlowService;
  modalId = 'pbagModal';
  priorityDetailsFreeModalId = 'priorityDetailsFreeModal';
  priorityDetailsPaidModalId = 'priorityDetailsPaidModal';
  ssrBoxId = 'ssrBox';
  editModalId: string;

  public insuranceEnabled = false; // disable insurance as requested
  bookingServiceSubscription: any;
  hasSpoilageFees: boolean;
  hasRecordLocator: boolean;
  pendingSsrsList: SsrType[] = [];
  assetsPath: string;
  showPriorityBags: boolean;
  showLuggageBag: boolean;
  priorityPopupImg: string;
  twoCabinBagsPopupImg: string;

  flightsSub: Subscription;
  shoppingCartExtrasSubscription: Subscription;
  flightsObs: BehaviorSubject<FlightModel[]> = new BehaviorSubject<FlightModel[]>(null);
  currentFlights: FlightModel[] = null;
  enableSameOptionsForAllFlights = false;
  totalPrice: number;
  isModified = false;
  temporaryTotalPrice: number;
  shoppingCartExtras: ShoppingCartExtrasModel[];
  flightPrices: number[][];
  flights: FlightModel[];
  @Input() ssrType: SsrType;
  flightsSubscription: Subscription;
  isRestricted: boolean;
  isCanceledBookingStatus: boolean;
  recordLocators: any[] = [];

  @ViewChild(InsuranceComponent, { static: false }) insuranceComponent: InsuranceComponent;
  @ViewChild(PassengerBundleComponent, { static: true }) passengerBundleComponent: PassengerBundleComponent;
  @ViewChild(SsrsComponent, { static: true }) ssrsComponent: SsrsComponent;
  @ViewChild(SeatSelectedNotification, { static: true }) ssrSelectedModal: SeatSelectedNotification;
  // TODO
  @ViewChild('pbagModal', { static: true }) pbagModal: DefaultModalComponent;
  @ViewChild('priorityDetailsFreeModal', { static: true }) priorityDetailsFreeModal: DefaultModalComponent;
  @ViewChild('priorityDetailsPaidModal', { static: true }) priorityDetailsPaidModal: DefaultModalComponent;
  @ViewChild('prioritySsrAllSelectionsShouldBeSetForPriorityBoardingModal', { static: true }) prioritySsrAllSelectionsShouldBeSetForPriorityBoardingModal: DefaultModalComponent;
  @ViewChild('prioritySsrAllPriorityBoardingShouldBeSelectedWarningModal', { static: true }) prioritySsrAllPriorityBoardingShouldBeSelectedWarningModal: DefaultModalComponent;
  @ViewChild('priorityConfirmationModal', { static: true }) priorityConfirmationModal: DefaultModalComponent;
  @ViewChild('changeFlightsWarningModal', { static: true }) clearSelectionsModal: SelectionsClearWarningModalComponent;
  @ViewChild('ssrBox', { static: true }) ssrBox: SsrBoxComponent;
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  /*
  This gets if the client chose at least one option for the priority boarding,
  no matter if is is "no priority boarding" or with "priority boarding"
  If this returns false, a modal with a validation will be shown and the user CANNOT PROCEED TILL HE CHOOSES AN OPTION
  */
  get hasAllPriorityBoardingOptionsSet(): boolean {
    if (!this.syncService.flights) return false;
    const result = this.syncService.flights
      .filter(flight => !flight.isInThePast)
      .every(flights => {
        return flights.passengers.every(passenger => this.syncService.passengersInitialPriorityBoardingChoice[passenger.passengerUniqueId] ? true : false)
      });

    return result;
  }

  /*
  This get if all the passengers are with priority boarding or not.
  If at least one of the passengers is without priority boarding, this is false and a warning modal should be shown.
  That warning has the option to move further as this is only an warning.
  */
  get shouldHaveAllPriorityBoardingAdded(): boolean {
    if (!this.syncService.flights) return false;
    const result = this.syncService.flights
      .filter(flight => !flight.isInThePast)
      .every(flights => {
        return flights.passengers.every(passenger => passenger.hasPriorityBoarding)
      });
    return result;
  }

  constructor(private steps: BookingStepsService, private loadingSpinnerService: LoadingSpinnerService,
    private flowManager: FlowManagerService, private ecommerce: ECommerceService,
    private bookingService: BookingService, private profileService: ProfileService, public ssrsService: SsrsService,
    private syncService: BookingFareSelectSyncService, private translateService: TranslateService) {
    this.applicationFlowService = flowManager.applicationFlowService;

    // disable insurance as requested
    // this.insuranceEnabled = !this.profileService.isAgent;

    this.assetsPath = environment.assetsPath;

    this.steps.currentStep.next(this.currentStep);

    this.applicationFlowService.shoppingCartBreakdown
      .pipe(
        filter(b => b && b.currentShoppingCart ? true : false),
        take(1))
      .subscribe(breakdown => this.hasSpoilageFees = breakdown.currentShoppingCart.checkExtrasForSsrOrFeeCode(SsrType.Other, 'SPL'));

    this.bookingServiceSubscription = this.bookingService.bookingObs.subscribe(result => {
      if (result) {
        if (result.bookingDetail && result.bookingDetail.recordLocator !== null && result.bookingDetail.recordLocator !== undefined && result.bookingDetail.recordLocator !== "") {
          this.hasRecordLocator = true;
        }

        if (result.bookingDetail && result.bookingDetail.recordLocators && result.bookingDetail.recordLocators.length > 0) {
          this.recordLocators = result.bookingDetail.recordLocators;
          this.isRestricted = this.isRestrictedBooking();
        }
      }
    });

    this.enableFlightsUpdates();

    if (this.translateService.culture.match('en-GB')) {
      this.priorityPopupImg = 'img/ssrs/pop-up_priority_en.jpg';
      this.twoCabinBagsPopupImg = 'img/ssrs/pop-up_twocabinbags_en.jpg';
    } else {
      this.priorityPopupImg = 'img/ssrs/pop-up_priority_ro.png';
      this.twoCabinBagsPopupImg = 'img/ssrs/pop-up_twocabinbags_ro.png';
    }

  }

  ngOnInit() {
    this.ssrsService.isLuggageStep = true;
    this.ssrsService.isExtrasStep = false;

    this.syncService.passengersInitialPriorityBoardingChoice = {};

    // if (!localStorage.getItem("pageState")) {
    //   localStorage.setItem("pageState", "reloaded");
    //   window.location.reload();
    // }
    this.ssrsService.ssrs
    this.profileService.refreshProfile();

    this.flightsSubscription = this.flowManager.selectionService.flightsObs.subscribe(flights => {
      if (flights) {
        this.flights = flights;
        this.isCanceledBookingStatus = this.checkBookingStatus();
      }
    });
  }

  ngOnDestroy() {
    this.ssrsService.isLuggageStep = false;

    if (this.bookingServiceSubscription) {
      this.bookingServiceSubscription.unsubscribe();
    }

    if (this.flightsSubscription) {
      this.flightsSubscription.unsubscribe();
    }

    localStorage.removeItem("pageState");
  }

  skipAndComplete() {
    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
    this.steps.goToStep(CheckinSteps.summary);
  }

  goToNextStep(checkinStep?: CheckinSteps) {
    const components = this.ssrsComponent.getComponentModals();

    if (this.insuranceComponent) {
      components.push(this.insuranceComponent);
    }

    for (let allHaveSelectedOption of this.ssrsService.luggagePassengersMap.values()) {
      this.ssrsComponent.ssrBoxes.find(x => x.ssrType === SsrType.Baggage).showLuggageErrors();

      if (!allHaveSelectedOption) {
        return;
      }
    }

    const myCallback = (isOkCallback: boolean, index: number): void => {
      if (isOkCallback) {
        if (components.length === 0) {
          this._canDeactivate = true;

          if (checkinStep !== null && checkinStep !== undefined) {
            this.steps.goToStep(checkinStep);
          }
          else {
            let ecommerceCartItems = [];

            this.applicationFlowService.loadPriceBreakdown().then(breakdown => {
              if (breakdown) {
                ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown);
                this.ecommerce.Checkout(ecommerceCartItems, 2, 'Extras step');
              }
            });

            this.steps.goToNextStepFrom(this.currentStep);
          }
        }

        do {
          index++;
        }
        while (index < components.length && !components[index].openModal((isOk: boolean) => myCallback(isOk, index)));

        if (index === components.length) {
          this._canDeactivate = true;

          if (checkinStep !== null && checkinStep !== undefined) {
            this.steps.goToStep(checkinStep);
          }
          else {
            let ecommerceCartItems = [];

            this.applicationFlowService.loadPriceBreakdown().then(breakdown => {
              if (breakdown) {
                ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown);
                this.ecommerce.Checkout(ecommerceCartItems, 2, 'Extras step');
              }
            });

            this.steps.goToNextStepFrom(this.currentStep);
          }
        }
      }
    };

    // this._canDeactivate = this.hasPendingChanges();

    if (checkinStep == CheckinSteps.seat || checkinStep == CheckinSteps.passengers) {
      myCallback(true, -1);
    } else if (!this.hasAllPriorityBoardingOptionsSet) {
      this.prioritySsrAllSelectionsShouldBeSetForPriorityBoardingModal.openPopup((isOk) => {
      })
    } else if (!this.shouldHaveAllPriorityBoardingAdded) {
      this.prioritySsrAllPriorityBoardingShouldBeSelectedWarningModal.openPopup((isOk) => {
        if (!isOk) {
          this.scrollIntoView();
        } else {
          myCallback(isOk, -1);
        }
      })
    } else {
      myCallback(true, -1);
    }
  }

  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    let checkinStep = this.steps.extractStep(nextState);
    let redirectingToOtherBookingStep = true;

    if (checkinStep === null) {
      redirectingToOtherBookingStep = false;
      checkinStep = CheckinSteps.search;
    }

    if (this.clearSelectionsModal.shouldShowWarning(this.currentStep, checkinStep)) {
      return this.clearSelectionsModal.showWarningAndNavigate(
        this.currentStep,
        redirectingToOtherBookingStep ? checkinStep : null,
        nextState.url
      );
    }

    if (!this._canDeactivate) {
      this.goToNextStep(checkinStep);
    }

    return this._canDeactivate;
  }

  hasPendingChanges() {
    let ssrsOk = true;
    this.showPriorityBags = false;
    this.showLuggageBag = true;

    //check passengers priority boarding
    for (let atLeastOneHasLugg of this.ssrsService.luggagePassengersMap.values()) {
      if (atLeastOneHasLugg) {
        this.showLuggageBag = false;
        break;
      }
    }

    //check passengers priority boarding
    for (let hasPBRD of this.ssrsService.priorityPassengersMap.values()) {
      if (!hasPBRD) {
        ssrsOk = false;
        this.showPriorityBags = true;
      }
    }

    if (this.showLuggageBag) {
      ssrsOk = false;
    }

    return ssrsOk;
  }

  scrollIntoView() {
    if (this.pendingSsrsList.length) {
      const firstPendingSsrType = this.pendingSsrsList[0];

      if (firstPendingSsrType === SsrType.Insurance) {
        this.insuranceComponent.scrollToView();
      }
      else {
        this.ssrsComponent.ssrBoxes.find(x => x.ssrType === firstPendingSsrType).validationModalAction(false);
      }
    }
  }

  enableFlightsUpdates() {
    if (!this.flightsSub || this.flightsSub.closed) {
      this.flightsSub = this.flightsObs.subscribe(newFlights => {
        this.currentFlights = newFlights;

        this.updateSsrAvailabilityByFlight();
        this.updateTotalPrice(true);

        this.enableSameOptionsForAllFlights =
          this.currentFlights && this.currentFlights.length === 2 &&
          this.currentFlights[0].departureStation.iataCode === this.currentFlights[1].arrivalStation.iataCode &&
          this.currentFlights[0].arrivalStation.iataCode === this.currentFlights[1].departureStation.iataCode;
      });
    }
  }

  updateSsrAvailabilityByFlight() {
    if (!this.currentFlights || !this.ssrType) {
      return;
    }
  }

  updateTotalPrice(forced = false) {
    if (!this.shoppingCartExtras || !this.ssrType) {
      return;
    }

    const shoppingCartItem = this.shoppingCartExtras.find(e => e.ssrType === this.ssrType);
    this.totalPrice = shoppingCartItem ? shoppingCartItem.amount : 0;
    this.isModified = this.getIsModified();
    this.temporaryTotalPrice = this.totalPrice;

    if (this.currentFlights && (!this.flightPrices || forced)) {
      this.flightPrices = this.currentFlights.map(f => {
        let prices = [];
        prices.length = f.segments.length;
        prices.fill(0);

        if (shoppingCartItem) {
          const shoppingCartSegments: ShoppingCartExtrasFlightModel[] = shoppingCartItem.flights
            .filter(sf => sf.departureStation === f.departureStation.iataCode && sf.arrivalStation === f.arrivalStation.iataCode)
            .reduce((segments, sf) => segments.concat(sf.segments), []);

          f.segments.forEach((seg, i) => {
            prices[i] = shoppingCartSegments
              .filter(ss => ss.departureStation === seg.departureStation.iataCode && ss.arrivalStation === seg.arrivalStation.iataCode)
              .sum(ss => ss.amount);
          });

          if (SsrSellingTypeHelper.IsSsrTypeSoldByJourney(this.ssrType)) {
            prices = [prices.sum(p => p)];
          }
        }
        return prices;
      });
    }
  }

  getIsModified() {
    switch (this.ssrType) {
      case SsrType.SpecialAssistance:
      case SsrType.Meal:
      case SsrType.Baggage:
      case SsrType.SpecialEquipment: {
        return this.areSsrsSelected();
      }
      default: {
        return this.totalPrice > 0;
      }
    }
  }

  areSsrsSelected() {
    return this.shoppingCartExtras.some(x => x.ssrType === this.ssrType && x.flightsWithItems.length > 0);
  }

  toggleLuggagePriorityDetailsFreeOptionModal() {
    this.priorityDetailsFreeModal.openPopup(this.priorityDetailsFreeModalId);
    this.ssrsService.isDisplayedPriorityDetailsFreeModal = false;
  }

  toggleLuggagePriorityDetailsPaidOptionModal() {
    this.priorityDetailsPaidModal.openPopup(this.priorityDetailsPaidModalId);
    this.ssrsService.isDisplayedPriorityDetailsPaidModal = false;
  }

  toggleLuggageBagDetailsModal() {
    this.pbagModal.openPopup(this.modalId);
    this.ssrsService.isDisplayedBagDetailsModal = false;
  }

  checkBookingStatus() {
    for (const f of this.flights) {
      for (const s of f.segments) {
        for (const l of s.legs) {
          if (l.legInfo.status === "Canceled") {
            return true;
          }
        }
      }
    }

    return false;
  }

  isRestrictedBooking() {
    return (this.recordLocators.some(r => r.owningSystemCode !== null && r.owningSystemCode !== undefined)) ? true : false;
  }
}
