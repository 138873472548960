import * as tslib_1 from "tslib";
import { Constants } from '../../constants';
import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { PaxInsurance } from './../../core/models/insurance-model';
import { TranslateService } from './../../common-modules/blue-air-common/translator/translate.service';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { InsuranceService } from './../insurance.service';
import { OnInit, ElementRef, QueryList } from '@angular/core';
import { Insurance } from '../../core/models/insurance-model';
import { PassengerModel } from '../../core/models/passenger-model';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { environment } from '../../../environments/environment';
import { CurrencyDisplayPipe } from '../../shared/currency-display.pipe';
import { BookingService } from '../../core/booking.service';
import * as moment from 'moment';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { SsrType } from '../ssr-type.enum';
import { SsrsService } from '../ssrs.service';
import { ConfigService } from 'src/app/core/config.service';
import { ProfileService } from 'src/app/core/profile.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';
var InsuranceComponent = /** @class */ (function () {
    function InsuranceComponent(insuranceService, loadingSpinnerService, translateService, flowManager, bookingService, ecommerce, ssrsService, configService, profileService) {
        var _this = this;
        this.insuranceService = insuranceService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.translateService = translateService;
        this.flowManager = flowManager;
        this.bookingService = bookingService;
        this.ecommerce = ecommerce;
        this.ssrsService = ssrsService;
        this.configService = configService;
        this.profileService = profileService;
        this.loadingSpinnerId = 'insurance-preloader';
        this.insuranceModalId = 'insuranceModalId';
        this.insuranceNoConsentModalId = 'insuranceNoConsentModalId';
        this.allPassengersSelected = true;
        this.paxInsuranceList = [];
        this.filteredInsuranceList = [];
        this.allInsuranceList = [];
        this.toDeleteInsuranceList = [];
        this.oldPaxInsuranceList = [];
        this.insuranceItems = [];
        this.insuranceObs = new BehaviorSubject(null);
        this.snapshotPassengers = [];
        this.passengers = [];
        this.infants = [];
        this.selectedPassengerNumber = -1;
        this.passengersInsuranceSnapshot = [];
        this.applicationFlowService = flowManager.applicationFlowService;
        this.selectionService = flowManager.selectionService;
        this.mobileMatches = window.innerWidth < Constants.mobileScreenWith;
        this.assetsPath = environment.assetsPath;
        this.currencyDisplay = new CurrencyDisplayPipe(this.flowManager, this.translateService);
        if (this.mobileMatches) {
            this.selectedPassengerNumber = 0;
            this.allPassengersSelected = false;
        }
        this.selectionService.flightsObs.pipe(filter(function (f) { return f ? true : false; }), take(1)).subscribe(function (flights) {
            _this.flights = flights;
            _this.isInternational = flights.findIndex(function (f) { return f.isInternational; }) >= 0;
            _this.insuranceDays = _this.getInsuranceDays();
            _this.isRoundTrip = flights.length > 1;
            if (_this.isInternational) {
                insuranceService.getInsuranceDetails().then(function (insuranceDetails) {
                    _this.allInsuranceList = insuranceDetails.insurance.insurances.map(function (ins) { return new Insurance(ins); });
                    _this.insideEU = insuranceDetails.insurance.insideEU;
                    _this.insuranceObs.next(_this.allInsuranceList);
                    _this.filterInsurance();
                    _this.setFeeList();
                    _this.loadingSpinnerService.hideSpinnerById(_this.loadingSpinnerId);
                }).then(function () { return _this.isReady = true; });
            }
        });
        this.selectionService.passengersObs.subscribe(function (passengers) {
            _this.passengers = passengers;
            _this.infants = _this.passengers.map(function (p) { return p.infant; }).filter(function (obj) { return obj != undefined; });
        });
        this.applicationFlowService.loadFlowInfo().then(function (flowInfo) {
            if ((flowInfo && flowInfo.bookingDetail && flowInfo.bookingDetail.recordLocator !== '') || environment.flow === 0) {
                _this.isOnBookingChangeFlow = true;
                _this.bookingService.getSnapshot().then(function (response) {
                    if (response && response.convertedJourneys.snapshotBooking && response.convertedJourneys.snapshotBooking.passengers) {
                        _this.snapshotPassengers = response.convertedJourneys.snapshotBooking.passengers;
                        if (_this.snapshotPassengers.some(function (x) { return x.passengerFees.some(function (y) { return y.feeCode === Constants.InsuranceFeeCode; }); })) {
                            _this.allPassengersDisabled = true;
                            _this.allPassengersSelected = false;
                        }
                        _this.setChangeBookingInsuranceDetails();
                    }
                });
            }
        });
    }
    InsuranceComponent.prototype.onResize = function (event) {
        this.mobileMatches = event.target.innerWidth < Constants.mobileScreenWith;
    };
    InsuranceComponent.prototype.ngOnInit = function () { };
    InsuranceComponent.prototype.scrollToView = function () {
        this.insuranceBox.nativeElement.scrollIntoView({ behavior: 'smooth' });
        if (!this.isExpanded) {
            this.expandBox();
        }
    };
    InsuranceComponent.prototype.expandBox = function () {
        this.isExpanded = true;
        this.setFeeList();
        this.updateInsuranceOptions();
        var ecommerceCartItems = new Array();
        var item = new ECommerceCartItem('Insurance', this.ecommerce.getFlightInfo(0).toString(), this.lowestPrice.toString(), 'Omniasig', 'Insurance', '1', this.ecommerce.getPassengersCount(), this.ssrsService.actualDisplayOrder.length + 1, null, this.ecommerce.getUserData()).getItem(true);
        ecommerceCartItems.push(item);
        this.ecommerce.ProductClicks(ecommerceCartItems);
        this.oldPaxInsuranceList = [];
    };
    InsuranceComponent.prototype.cancelChanges = function () {
        this.setFeeList();
        this.oldPaxInsuranceList = [];
        this.toDeleteInsuranceList = [];
        this.isExpanded = false;
    };
    InsuranceComponent.prototype.filterInsurance = function () {
        var _this = this;
        if (this.allInsuranceList !== null && this.insuranceDays) {
            var journeyType_1 = this.isRoundTrip ? Constants.RoundTrip : Constants.OneWayTrip;
            this.filteredInsuranceList =
                this.allInsuranceList.filter(function (ins) { return ins.isActive && ins.insideEU === _this.insideEU && ins.journeyType === journeyType_1; });
            if (this.isRoundTrip) {
                this.filteredInsuranceList = this.filteredInsuranceList.filter(function (ins) { return ins.days === _this.insuranceDays; });
            }
            this.filteredInsuranceList = this.filteredInsuranceList.sort(function (a, b) { return a.price > b.price ? 1 : -1; });
            if (this.filteredInsuranceList.length > 0) {
                this.lowestPrice = this.filteredInsuranceList[0].price;
            }
        }
    };
    InsuranceComponent.prototype.setFeeList = function () {
        var _this = this;
        this.passengers = this.selectionService.passengers.sort(function (pax1, pax2) { return (pax1.passengerNumber > pax2.passengerNumber) ? 1 : -1; });
        this.infants = this.passengers.filter(function (p) { return p.hasInfant; }).map(function (p) { return p.infant; });
        this.paxInsuranceList = [];
        this.passengers.forEach(function (pax) {
            var fees = pax.fees.filter(function (f) { return f.code === Constants.InsuranceFeeCode; });
            if (fees) {
                fees.forEach(function (f) {
                    var paxIns = new PaxInsurance();
                    var paxType = f.note.split('|')[0].trim() === Constants.InfantSsrCode ? Constants.InsuranceInfantCode : '';
                    var days = f.note.split('|')[f.note.split('|').length - 1].trim();
                    paxIns.isPaid = true;
                    paxIns.isInfant = paxType === Constants.InsuranceInfantCode ? true : false;
                    paxIns.passengerNumber = pax.passengerNumber;
                    paxIns.selectedInsurance = _this.allInsuranceList.find(function (i) { return i.days.toString() === days && i.passengerType === paxType; });
                    _this.paxInsuranceList.push(paxIns);
                });
            }
        });
        this.isModified = this.paxInsuranceList.find(function (i) { return i.isPaid; }) ? true : false;
        this.updateStatus();
    };
    InsuranceComponent.prototype.getInsuranceName = function (insurance) {
        var translateKey = insurance.days + '-insurance';
        return this.isRoundTrip ? this.currencyDisplay.transform(insurance.price) : this.translateService.instant(translateKey, 'insurance.days').concat(' - ', this.currencyDisplay.transform(insurance.price));
    };
    InsuranceComponent.prototype.getInsuranceDays = function () {
        var daysToStay = moment.duration(moment(this.flights[this.flights.length - 1].arrivalStation.dateUtc).diff(moment(this.flights[0].departureStation.dateUtc))).asDays();
        // tslint:disable: curly
        if (daysToStay <= 7)
            return 7;
        else if (daysToStay <= 14)
            return 14;
        else if (daysToStay <= 31)
            return 31;
        else if (daysToStay <= 120)
            return 120;
        // tslint:enable: curly
    };
    InsuranceComponent.prototype.changeSelectedPassenger = function (paxNr, isInfant, popupOpen) {
        this.selectedPassengerNumber = paxNr;
        this.isInfant = isInfant;
        this.selectedPassenger = this.getSelectedPassenger();
        this.allPassengersSelected = false;
        this.updateInsuranceOptions();
        this.selectedInsuranceDays = this.getSelectedInsurancePerPax();
        if (popupOpen === false) {
            this.isPopupOpened = false;
        }
        else {
            this.isPopupOpened = this.mobileMatches ? true : false;
        }
        this.updateStatus();
    };
    InsuranceComponent.prototype.getSelectedInsurancePerPax = function () {
        var _this = this;
        var selectedInsurance = this.paxInsuranceList.find(function (i) {
            return i.passengerNumber === _this.selectedPassengerNumber &&
                i.isInfant === _this.isInfant;
        });
        return selectedInsurance ? selectedInsurance.selectedInsurance.days : 0;
    };
    InsuranceComponent.prototype.enableAllPassengers = function () {
        this.isPopupOpened = this.mobileMatches ? true : false;
        this.allPassengersSelected = !this.allPassengersSelected;
        if (this.allPassengersSelected) {
            this.selectedPassengerNumber = -1;
        }
        else {
            this.changeSelectedPassenger(0, false);
        }
        this.updateInsuranceOptions();
    };
    InsuranceComponent.prototype.isPaxSelected = function (paxNum, isInfant) {
        return this.selectedPassengerNumber === paxNum && this.isInfant === isInfant;
    };
    InsuranceComponent.prototype.removeFromList = function (passengerNumber, isInfant) {
        this.paxInsuranceList
            .splice(this.paxInsuranceList
            .indexOf(this.paxInsuranceList
            .find(function (p) { return p.passengerNumber === passengerNumber && p.isInfant === isInfant; })), 1);
    };
    InsuranceComponent.prototype.remove = function (passengerNumber, isInfant) {
        if (this.paxHasInsurance(passengerNumber, isInfant) !== undefined && this.paxHasInsurance(passengerNumber, isInfant).isPaid) {
            var paxInsurance = this.paxInsuranceList.find(function (x) { return x.passengerNumber === passengerNumber && x.isInfant === isInfant && x.isPaid; });
            if (!this.toDeleteInsuranceList.find(function (x) { return x.passengerNumber === passengerNumber && x.isInfant === isInfant && x.isPaid; })) {
                this.toDeleteInsuranceList.push(paxInsurance);
            }
        }
        this.removeFromList(passengerNumber, isInfant);
        this.changeSelectedPassenger(passengerNumber, isInfant, false);
        this.updateStatus();
    };
    InsuranceComponent.prototype.deleteInsurance = function (toDeleteInsuranceList) {
        var _this = this;
        var promiseArray = [];
        toDeleteInsuranceList.forEach(function (paxInsurance) {
            var feeNumber = null;
            var feesAsig = [];
            if (paxInsurance.isInfant) {
                feesAsig = _this.passengers.find(function (p) { return p.passengerNumber === paxInsurance.passengerNumber; }).fees
                    .filter(function (x) { return x.code === Constants.InsuranceFeeCode && x.note.includes(Constants.InfantSsrCode); });
            }
            else {
                feesAsig = _this.passengers.find(function (p) { return p.passengerNumber === paxInsurance.passengerNumber; }).fees
                    .filter(function (x) { return x.code === Constants.InsuranceFeeCode && !x.note.includes(Constants.InfantSsrCode); });
            }
            if (feesAsig.length > 0) {
                feeNumber = feesAsig[0].number;
            }
            if (feeNumber !== undefined && feeNumber !== null) {
                var currentPromise = _this.insuranceService.deleteInsurance(paxInsurance.passengerNumber, Constants.InsuranceFeeCode, feeNumber);
                promiseArray.push(currentPromise);
            }
        });
        return promiseArray;
    };
    InsuranceComponent.prototype.paxHasInsurance = function (passengerNumber, isInfant) {
        if (isInfant === void 0) { isInfant = false; }
        return this.paxInsuranceList.find(function (p) { return p.passengerNumber === passengerNumber && p.isInfant === isInfant; });
    };
    InsuranceComponent.prototype.selectInsurance = function (insuranceDays) {
        var _this = this;
        if (this.selectedPassengerNumber === -1) {
            if (this.paxInsuranceList.filter(function (i) { return i.isPaid; }).length === 0) {
                this.paxInsuranceList = [];
                this.passengers.forEach(function (pax) {
                    var paxInsurance = new PaxInsurance();
                    paxInsurance.isInfant = false;
                    paxInsurance.passengerNumber = pax.passengerNumber;
                    paxInsurance.selectedInsurance = _this.getInsuranceByPaxType('').find(function (i) { return i.days === insuranceDays; });
                    _this.paxInsuranceList.push(paxInsurance);
                    if (pax.infant !== undefined) {
                        var infInsurance = new PaxInsurance();
                        infInsurance.isInfant = true;
                        infInsurance.passengerNumber = pax.passengerNumber;
                        infInsurance.selectedInsurance = _this.getInsuranceByPaxType(Constants.InsuranceInfantCode).find(function (i) { return i.days === insuranceDays; });
                        _this.paxInsuranceList.push(infInsurance);
                    }
                });
            }
            else {
                this.oldPaxInsuranceList = this.paxInsuranceList.map(function (x) { return x.clone(); });
                this.paxInsuranceList.forEach(function (paxIns) {
                    var paxType = paxIns.isInfant ? Constants.InsuranceInfantCode : '';
                    paxIns.selectedInsurance = _this.getInsuranceByPaxType(paxType).find(function (i) { return i.days === insuranceDays; });
                    paxIns.isPaid = false;
                });
            }
        }
        else {
            var paxType = this.isInfant ? Constants.InsuranceInfantCode : '';
            var insurance = this.getInsuranceByPaxType(paxType).find(function (i) { return i.days === insuranceDays; });
            var paxInsIndex_1 = this.paxInsuranceList
                .findIndex(function (i) { return i.isInfant === _this.isInfant && i.passengerNumber === _this.selectedPassengerNumber; });
            if (paxInsIndex_1 !== -1) {
                var oldPaxIndex = this.oldPaxInsuranceList
                    .findIndex(function (x) { return x.passengerNumber === _this.paxInsuranceList[paxInsIndex_1].passengerNumber
                    && x.isInfant === _this.paxInsuranceList[paxInsIndex_1].isInfant; });
                if (oldPaxIndex !== -1) {
                    this.oldPaxInsuranceList.splice(oldPaxIndex, 1);
                }
                this.oldPaxInsuranceList.push(this.paxInsuranceList[paxInsIndex_1].clone());
                this.paxInsuranceList[paxInsIndex_1].selectedInsurance = insurance;
                this.paxInsuranceList[paxInsIndex_1].isPaid = false;
            }
            else {
                var paxInsurance = new PaxInsurance();
                paxInsurance.isInfant = this.isInfant;
                paxInsurance.passengerNumber = this.selectedPassengerNumber;
                paxInsurance.selectedInsurance = insurance;
                this.paxInsuranceList.push(paxInsurance);
            }
        }
        this.updateStatus();
    };
    InsuranceComponent.prototype.saveChanges = function () {
        var _this = this;
        if (this.showInsuranceConsent && this.isExpanded && !this.consentAllPax) {
            this.modals.find(function (x) { return x.modalId === _this.insuranceNoConsentModalId; }).openPopup(function () { });
        }
        else {
            var deletePromiseArray = [];
            this.loadingSpinnerService.showSpinnerById(this.loadingSpinnerId);
            deletePromiseArray = this.deleteInsurance(this.toDeleteInsuranceList);
            if (this.oldPaxInsuranceList.length > 0) {
                deletePromiseArray = deletePromiseArray.concat(this.deleteInsurance(this.oldPaxInsuranceList));
            }
            Promise.all(deletePromiseArray).then(function () {
                var promiseArray = [];
                var data = {
                    applyFees: {
                        comment: '',
                        passengerNumber: 0,
                        amount: 0,
                        currencyCode: '',
                        feeCode: '',
                        applyToAll: false
                    }
                };
                if (_this.paxInsuranceList.filter(function (i) { return !i.isPaid; }).length > 0) {
                    var insuranceSoldNr_1 = 0;
                    var nrOfInsurances_1 = _this.paxInsuranceList.filter(function (i) { return !i.isPaid; }).length;
                    _this.paxInsuranceList.forEach(function (paxIns) {
                        if (!paxIns.isPaid) {
                            data.applyFees.passengerNumber = paxIns.passengerNumber;
                            data.applyFees.feeCode = Constants.InsuranceFeeCode;
                            data.applyFees.applyToAll = false;
                            data.applyFees.amount = paxIns.selectedInsurance.price;
                            data.applyFees.currencyCode = _this.currencyCode;
                            data.applyFees.comment = _this.getComment(paxIns);
                            var currentPromise = _this.insuranceService.sellInsurance(data).then(function (x) {
                                paxIns.isPaid = true;
                                insuranceSoldNr_1 += 1;
                                if (insuranceSoldNr_1 === nrOfInsurances_1) {
                                    _this.isModified = true;
                                }
                            });
                            promiseArray.push(currentPromise);
                        }
                    });
                }
                Promise.all(promiseArray).then(function () {
                    _this.updateStatus();
                    _this.applicationFlowService.loadFlowInfo(true);
                    _this.applicationFlowService.loadPriceBreakdown(true);
                    _this.loadingSpinnerService.hideSpinnerById(_this.loadingSpinnerId);
                    if (_this.totalPrice === 0) {
                        _this.isModified = false;
                    }
                    _this.ecommerce.AddToCart(_this.prepareEcommerceItems(_this.paxInsuranceList));
                    _this.isExpanded = false;
                });
            });
        }
    };
    InsuranceComponent.prototype.prepareEcommerceItems = function (paxInsuranceList) {
        var _this = this;
        var ecommerceCartItems = new Array();
        this.paxInsuranceList.forEach(function (p) {
            var item = new ECommerceCartItem('Insurance', _this.ecommerce.getFlightInfo(0).toString() + p.passengerNumber, p.selectedInsurance.price.toString(), 'Omniasig', 'Insurance', p.selectedInsurance.days.toString() + ' days', 1, null, p.isInfant ? 'INFT' : _this.passengers.find(function (pax) { return pax.passengerNumber === p.passengerNumber; }).paxType, _this.ecommerce.getUserData()).getItem(false);
            ecommerceCartItems.push(item);
        });
        return ecommerceCartItems;
    };
    // comment value for infant: INF | InventoryFirstLegId-Random6DigitsNr | PassengerNumber | AsigDaysNr
    // comment value for passenger: InventoryFirstLegId-Random6DigitsNr | PassengerNumber | AsigDaysNr
    InsuranceComponent.prototype.getComment = function (paxIns) {
        var randomNr = Math.floor(Math.random() * 900000) + 100000;
        var inventoryFirstLegId = this.flights[0].segments[0].legs[0].inventoryLegID;
        var commentADT = inventoryFirstLegId.toString()
            .concat('-', randomNr.toString(), ' | ', paxIns.passengerNumber.toString(), ' | ', paxIns.selectedInsurance.days.toString());
        var commentINF = Constants.InfantSsrCode.concat(' | ', commentADT);
        return paxIns.isInfant ? commentINF : commentADT;
    };
    // current paxTypes for insurance:
    // - if infant => Constants.InsuranceInfantCode
    // - else => ''
    InsuranceComponent.prototype.getInsuranceByPaxType = function (paxType) {
        var copy = JSON.parse(JSON.stringify(this.filteredInsuranceList));
        return copy.filter(function (ins) { return ins.passengerType === paxType; });
    };
    InsuranceComponent.prototype.getTemporaryPrice = function () {
        return this.paxInsuranceList
            .map(function (i) { return i.selectedInsurance; })
            .map(function (i) { return i.price; })
            .reduce(function (a, b) { return a + b; }, 0);
    };
    InsuranceComponent.prototype.updateStatus = function () {
        var _this = this;
        this.totalPrice = this.paxInsuranceList
            .filter(function (i) { return !_this.passengersInsuranceSnapshot.some(function (sp) { return sp.paxNr === i.passengerNumber && sp.hasBought; }); })
            .map(function (i) { return i.selectedInsurance.price; })
            .reduce(function (a, b) { return a + b; }, 0);
        var numberOfPassengers = this.passengers.length + this.infants.length;
        this.allPassengersDisabled =
            this.paxInsuranceList.length > 0 && this.paxInsuranceList.length !== numberOfPassengers ||
                this.paxInsuranceList.some(function (ins) { return ins.selectedInsurance.days !== _this.paxInsuranceList[0].selectedInsurance.days; }) ||
                this.snapshotPassengers.length > 0;
        this.isInsuranceSelected =
            this.allPassengersSelected ? this.paxInsuranceList.length === numberOfPassengers &&
                this.paxInsuranceList.every(function (ins) { return ins.selectedInsurance.days === _this.paxInsuranceList[0].selectedInsurance.days; }) :
                this.paxInsuranceList.some(function (ins) { return ins.passengerNumber === _this.selectedPassengerNumber && ins.isInfant === _this.isInfant; });
        this.showInsuranceConsent = this.paxInsuranceList.length > 0;
    };
    InsuranceComponent.prototype.updateInsuranceOptions = function () {
        var _this = this;
        var paxInsurance;
        if (this.allPassengersSelected) {
            paxInsurance = tslib_1.__spread(this.getInsuranceByPaxType(''), this.getInsuranceByPaxType(Constants.InsuranceInfantCode));
        }
        else {
            paxInsurance = this.getInsuranceByPaxType(this.isInfant ? Constants.InsuranceInfantCode : '');
        }
        var tempPaxInsurance = paxInsurance.map(function (ins) {
            var priceMultiplier = 1;
            if (_this.allPassengersSelected) {
                priceMultiplier = ins.passengerType === Constants.InsuranceInfantCode ? _this.infants.length : _this.passengers.length;
            }
            return {
                'text': '',
                'days': ins.days,
                'price': ins.price * priceMultiplier
            };
        });
        // calculate total price per insurance length
        // duplicates will be found if there are infants on booking
        // sort of a group by 'days' => sum(price)
        var duplicateFound = false;
        tempPaxInsurance.forEach(function (ins, index) {
            var searchIndex = tempPaxInsurance.findIndex(function (i) { return i.days === ins.days; });
            if (searchIndex !== index) {
                tempPaxInsurance[searchIndex].price += ins.price;
                duplicateFound = true;
            }
        });
        this.insuranceItems = tempPaxInsurance.slice(0, duplicateFound ? tempPaxInsurance.length / 2 : tempPaxInsurance.length);
        this.insuranceItems.forEach(function (ins) { return ins.text = _this.getInsuranceName(ins); });
    };
    InsuranceComponent.prototype.openModal = function (callback) {
        var _this = this;
        if (this.isReady && this.isInternational && !this.paxInsuranceList.length && this.insuranceDays
            && this.configService.config.pushSsr.some(function (p) { return p.ssrType === SsrType.Insurance; })) {
            this.modals.find(function (x) { return x.modalId === _this.insuranceModalId; }).openPopup(function (isOk) {
                if (!isOk) {
                    _this.scrollToView();
                }
                callback(isOk);
            });
            return true;
        }
        return false;
    };
    InsuranceComponent.prototype.removeFromCart = function () {
        var _this = this;
        var e_1, _a;
        var promiseArray = [];
        this.loadingSpinnerService.showSpinnerById(this.loadingSpinnerId);
        this.ecommerce.RemoveFromCart(this.prepareEcommerceItems(this.paxInsuranceList));
        this.paxInsuranceList = [];
        this.toDeleteInsuranceList = [];
        var _loop_1 = function (passenger) {
            var e_2, _a;
            var insuranceItemsToDelete = this_1.passengers.find(function (p) { return p.passengerNumber === passenger.passengerNumber; }).fees
                .filter(function (x) { return x.code === Constants.InsuranceFeeCode; });
            try {
                for (var insuranceItemsToDelete_1 = tslib_1.__values(insuranceItemsToDelete), insuranceItemsToDelete_1_1 = insuranceItemsToDelete_1.next(); !insuranceItemsToDelete_1_1.done; insuranceItemsToDelete_1_1 = insuranceItemsToDelete_1.next()) {
                    var item = insuranceItemsToDelete_1_1.value;
                    var currentPromise = this_1.insuranceService.deleteInsurance(passenger.passengerNumber, Constants.InsuranceFeeCode, item.number);
                    promiseArray.push(currentPromise);
                }
            }
            catch (e_2_1) { e_2 = { error: e_2_1 }; }
            finally {
                try {
                    if (insuranceItemsToDelete_1_1 && !insuranceItemsToDelete_1_1.done && (_a = insuranceItemsToDelete_1.return)) _a.call(insuranceItemsToDelete_1);
                }
                finally { if (e_2) throw e_2.error; }
            }
        };
        var this_1 = this;
        try {
            for (var _b = tslib_1.__values(this.passengers), _c = _b.next(); !_c.done; _c = _b.next()) {
                var passenger = _c.value;
                _loop_1(passenger);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        Promise.all(promiseArray).then(function () {
            _this.setFeeList();
            _this.consentAllPax = false;
            _this.isModified = false;
            _this.applicationFlowService.loadFlowInfo(true);
            _this.applicationFlowService.loadPriceBreakdown(true);
            _this.loadingSpinnerService.hideSpinnerById(_this.loadingSpinnerId);
        });
    };
    InsuranceComponent.prototype.shouldShowRemoveButton = function (passengerNumber, isInfant) {
        return this.paxInsuranceList.find(function (x) { return x.passengerNumber === passengerNumber && x.isInfant === isInfant; })
            && !this.hasPreviouslyBoughtInsurance(passengerNumber, isInfant)
            ? true : false;
    };
    InsuranceComponent.prototype.hasPreviouslyBoughtInsurance = function (passengerNumber, isInfant) {
        var prevBought = false;
        if (this.snapshotPassengers.length > 0) {
            prevBought = isInfant ?
                this.snapshotPassengers[passengerNumber].passengerFees
                    .find(function (x) { return x.feeCode === Constants.InsuranceFeeCode && isInfant && x.note.includes(Constants.InfantSsrCode); }) != null :
                this.snapshotPassengers[passengerNumber].passengerFees
                    .find(function (x) { return x.feeCode === Constants.InsuranceFeeCode && !isInfant && !x.note.includes(Constants.InfantSsrCode); }) != null;
        }
        return prevBought && this.isOnBookingChangeFlow;
    };
    InsuranceComponent.prototype.getSelectedPassenger = function () {
        var _this = this;
        var selectedPax = this.isInfant ?
            this.infants.find(function (inf) { return (inf.passengerNumberByType - 1) === _this.selectedPassengerNumber; }) :
            this.passengers.find(function (pax) { return pax.passengerNumber === _this.selectedPassengerNumber; });
        return selectedPax;
    };
    InsuranceComponent.prototype.checkForPendingInsurances = function () {
        return this.paxInsuranceList.some(function (x) { return !x.isPaid; }) || this.toDeleteInsuranceList.length;
    };
    InsuranceComponent.prototype.setChangeBookingInsuranceDetails = function () {
        this.passengersInsuranceSnapshot = tslib_1.__spread(this.snapshotPassengers.map(function (x) { return ({
            paxNr: x.passengerNumber,
            paxName: x.names[0].firstName + ' ' + x.names[0].lastName,
            isInfant: false,
            hasBought: x.passengerFees.some(function (y) { return y.feeCode === Constants.InsuranceFeeCode
                && !y.note.includes(Constants.InfantSsrCode); })
        }); }), this.snapshotPassengers.filter(function (x) { return x.passengerFees.some(function (y) { return y.feeCode === Constants.InfantSsrCode; }); })
            .map(function (x) { return ({
            paxNr: x.passengerNumber,
            paxName: x.passengerInfants[0].names[0].firstName + ' ' + x.passengerInfants[0].names[0].lastName,
            isInfant: true,
            hasBought: x.passengerFees.some(function (y) { return y.feeCode === Constants.InsuranceFeeCode
                && y.note.includes(Constants.InfantSsrCode); })
        }); }));
        var firstPaxWO = this.passengersInsuranceSnapshot.find(function (x) { return !x.hasBought; });
        if (firstPaxWO) {
            this.changeSelectedPassenger(firstPaxWO.paxNr, firstPaxWO.isInfant);
        }
        this.allPaxHaveBoughtInsurance = this.passengersInsuranceSnapshot.filter(function (p) { return p.hasBought; }).length === this.passengers.length + this.infants.length;
        this.paxNamesWithInsurance = this.passengersInsuranceSnapshot.filter(function (p) { return p.hasBought; }).map(function (p) { return p.paxName; }).join(', ');
    };
    return InsuranceComponent;
}());
export { InsuranceComponent };
