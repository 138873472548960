import * as tslib_1 from "tslib";
import { BaseCanActivateService } from 'src/app/core/base-can-activate.service';
import { BookingStepsService } from 'src/app/core/booking-steps.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
var CanActivateBundlesService = /** @class */ (function (_super) {
    tslib_1.__extends(CanActivateBundlesService, _super);
    function CanActivateBundlesService(flowManager, steps) {
        return _super.call(this, steps, flowManager.applicationFlowService) || this;
    }
    return CanActivateBundlesService;
}(BaseCanActivateService));
export { CanActivateBundlesService };
