import * as moment from 'moment';
var CalendarSelection = /** @class */ (function () {
    function CalendarSelection() {
    }
    Object.defineProperty(CalendarSelection.prototype, "format", {
        get: function () {
            return this._format || 'YYYY-MM-DD';
        },
        set: function (value) {
            this._format = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CalendarSelection.prototype, "date", {
        get: function () {
            if (!this._date) {
                return null;
            }
            return this._date.format(this.format);
        },
        set: function (value) {
            this._date = moment(value, this.format);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CalendarSelection.prototype, "dateMoment", {
        get: function () {
            return this._date;
        },
        set: function (value) {
            this._date = moment(value);
        },
        enumerable: true,
        configurable: true
    });
    return CalendarSelection;
}());
export { CalendarSelection };
