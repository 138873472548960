import { Injectable, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { ICartItem } from './models/e-commerce-cart-item.model';
import { count } from 'console';
import { FlowManagerService } from '../../core/flow-manager.service';
import { BookingFlightSelectService } from '../../booking-flight-select/booking-flight-select.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { UserStateService } from '../../common-modules/blue-air-common/user-state.service';
import { Constants } from '../../constants';
import { BookingSelectionService } from '../../core/booking-selection.service';
import { BookingStepsService } from '../../core/booking-steps.service';
import { ShoppingCartBreakdownModel } from '../../core/models/shopping-cart-models';
import { ProfileService } from '../../core/profile.service';
import { SsrType } from '../../extras/ssr-type.enum';

@Injectable()
export class ECommerceService implements OnDestroy {
  public static BrandName: string = 'AeroItalia';

  private flightsServiceSubscription: Subscription;
  private passengersServiceSubscription: Subscription;
  private flightDetailsSubscription: Subscription;
  private flightInfo: any[] = [];
  private flights: any[] = [];
  private isRoundTrip: boolean;
  private passengersCount: number;
  private lockFarePrice: any;

  constructor(private flowManager: FlowManagerService, private steps: BookingStepsService,
    private flightSelectService: BookingFlightSelectService, private selectionService: BookingSelectionService,
    private translateService: TranslateService, private profileService: ProfileService,
    private userStateService: UserStateService) {
    // this.flightDetailsSubscription = this.flightSelectService.lockFareAvailability.subscribe((data) => {
    //   this.lockFarePrice = data.lockFare.price;
    // });

    this.flightsServiceSubscription = this.selectionService.flightsObs.subscribe(data => {
      this.flightInfo = [];
      if (data) {
        this.flights = data;
        this.isRoundTrip = data.length > 1 ? true : false;
        data.forEach(f => {
          let id = '';
          f.segments.forEach(s => {
            id = id + s.carrierCode + ' ' + s.number + ' ' + moment(s.departureStation.date).format('DD-MM-YYYY HH:mm:ss') + ' ';
          });
          this.flightInfo.push(id);
        });
      }
    });

    this.passengersServiceSubscription = this.selectionService.passengersObs.subscribe(data => {
      if (data) {
        this.passengersCount = data.length + data.filter(d => d.hasInfant === true).length;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.flightsServiceSubscription) {
      this.flightsServiceSubscription.unsubscribe();
    }
    if (this.passengersServiceSubscription) {
      this.passengersServiceSubscription.unsubscribe();
    }
    if (this.flightDetailsSubscription) {
      this.flightDetailsSubscription.unsubscribe();
    }
  }

  public getFlightInfo(flightIndex: number) {
    return this.flightInfo[flightIndex] ? this.flightInfo[flightIndex] : null;
  }

  public getFlightInfoByStations(departure: string, arrival: string) {
    let flight = this.flights.find(f => f.arrivalStation.iataCode === arrival && f.departureStation.iataCode === departure);
    let id = '';
    if (flight) {
      flight.segments.forEach(s => {
        id = id + s.carrierCode + s.number + ' ' + moment(s.departureStation.date).format('DD-MM-YYYY HH:mm:ss') + ' ';
      });
    }
    return id;
  }

  public getFlightType() {
    return this.isRoundTrip ? Constants.RoundTrip : Constants.OneWayTrip;
  }

  public getPassengersCount() {
    return this.passengersCount;
  }

  public AddToCart(cartItems: any[]) {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        'event': 'addToCart',
        'ecommerce': {
          'currencyCode': this.flowManager.applicationFlowService.currency ? this.flowManager.applicationFlowService.currency : 'EUR',
          'add': {
            'products': cartItems
          }
        }
      });
    }
  }

  public RemoveFromCart(cartItems: any[]) {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        'event': 'removeFromCart',
        'ecommerce': {
          'remove': {
            'products': cartItems
          }
        }
      });
    }
  }

  //pass position
  public ProductImpressions(cartItems: any[]) {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': this.flowManager.applicationFlowService.currency ? this.flowManager.applicationFlowService.currency : 'EUR',                       // Local currency is optional.
          'impressions': cartItems
        }
      });
    }
  }

  //pass position
  public ProductClicks(cartItems: any[]) {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
          'click': {
            'actionField': { 'list': 'Search Results' },      // Optional list property.
            'products': cartItems
          }
        }
      });
    }
  }

  public ProductViews(cartItems: any[]) {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        'ecommerce': {
          'detail': {
            'actionField': { 'list': 'Apparel Gallery' },    // 'detail' actions have an optional list property.
            'products': cartItems
          }
        }
      });
    }
  }

  public Checkout(cartItems: any[], stepNumber: number, option: string) {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        'event': 'begin_checkout',
        'ecommerce': {
          'checkout': {
            'actionField': { 'step': stepNumber, 'option': option },
            'products': cartItems
          }
        }
      });
    }
  }

  public Purchases(cartItems: any[], transaction: any) {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        'event': 'purchase',
        'transaction_id': transaction.id,                         // Transaction ID. Required for purchases and refunds.
        'affiliation': transaction.affiliation,
        'value': transaction.revenue,                     // Total transaction value (incl. tax and shipping)
        'tax': transaction.tax,
        'shipping': transaction.shipping,
        'coupon': transaction.coupon,
        'currency': this.flowManager.applicationFlowService.currency ? this.flowManager.applicationFlowService.currency : 'EUR',
        'items': cartItems
      });
    }
  }



  public getFlightsForECommerce(flights: any, hasPosition?: boolean, lockFareEnabled?: boolean): ICartItem[] {
    let ecommerceCartItems = [];
    flights.forEach((f, index) => {
      let id = '';
      f.segments.forEach((s: { operatingCarrier: string; flightNumber: string; departureDate: moment.MomentInput; }) => {
        id = id + s.operatingCarrier + ' ' + s.flightNumber + ' ' + moment(s.departureDate).format('DD-MM-YYYY HH:mm:ss') + ' ';
      });
      f.segments[0]._passengers.forEach(p => {
        const item = <Partial<ICartItem>>{
          name: f.arrivalStation + '-' + f.departureStation,
          item_id: id,
          price: lockFareEnabled ? this.lockFarePrice : (p._amount / p.count).toString(),
          affiliation: ECommerceService.BrandName,
          item_category: f.selectedBundleCode,
          item_variant: flights.length > 1 ? Constants.RoundTrip : Constants.OneWayTrip,
          index,
          quantity: p.count,
          item_category2: p.passengerType,
          item_category3: this.getUserData()
          // f.arrivalStation + '-' + f.departureStation, id,
          // lockFareEnabled? this.lockFarePrice : (p._amount / p.count).toString(), 'BlueAir',
          //   f.selectedBundleCode, flights.length > 1 ? Constants.RoundTrip : Constants.OneWayTrip,
          //   p.count, index, p.passengerType, this.getUserData())
        };
        ecommerceCartItems.push(item);
      });
    });
    return ecommerceCartItems;
  }

  public getExtrasForECommerce(extras: any): ICartItem[] {
    const ecommerceCartItems = [];
    extras.forEach(ssrType => {
      ssrType.flightsWithItems.forEach(flight => {
        const flightIndex = ssrType.flights.findIndex(f => f.arrivalStation === flight.arrivalStation && f.departureStation === flight.departureStation);
        const flightKey = this.getFlightInfo(flightIndex);
        flight.segments[0].passengers.forEach(pax => {
          pax.items.forEach((i: { amount: number; ssrCode: any; quantity: any; }) => {
            if (i.amount !== 0) {
              const item: Partial<ICartItem> = {
                item_name: ssrType.ssrType === SsrType.Insurance ?
                  this.translateService.instant(i.ssrCode, 'ssr.name') + ' insurance days' :
                  this.translateService.instant(i.ssrCode, 'ssr.name'),
                item_id: flightKey + pax.passengerNumber,
                price: i.amount.toString(),
                affiliation: ECommerceService.BrandName,
                item_category: SsrType[ssrType.ssrType].toString(),
                item_variant: i.ssrCode,
                quantity: i.quantity,
                item_category2: pax.passengerType,
                item_category3: this.getUserData()
              };
              ecommerceCartItems.push(item);
            }
          });
        });
      });
    });
    return ecommerceCartItems;
  }

  public getAllCartDataForECommerce(breakdown: ShoppingCartBreakdownModel, lockFareEnabled?: boolean): any[] {
    return this.getFlightsForECommerce(breakdown.currentShoppingCart.flights, null, lockFareEnabled)
      .concat(this.getExtrasForECommerce(breakdown.currentShoppingCart.extras));
  }

  public getUserData(): string {
    if (this.profileService.isAgent) {
      return 'Agent';
    }

    if (!this.userStateService.isLoggedIn.getValue()) {
      const disabledLoginInfo = this.userStateService.isUserLoginDisabled.getValue();

      if (!disabledLoginInfo.isDisabled || !disabledLoginInfo.agent) {
        return 'anonymous';
      }

      return `anonymous_${disabledLoginInfo.agent}`;
    }

    const userPromoDetails = this.userStateService.userPromoDetails.getValue();

    if (!userPromoDetails) {
      return 'anonymous';
    }

    if (userPromoDetails.PromoActive) {
      return '0bmemberBB';
    }

    return '0bmember';
  }
}


export class Transaction {
  id: string;
  affiliation: string;
  revenue: string;
  tax: string;
  shipping: string;
  coupon: string;

  constructor(id: string, affiliation: string, revenue: string, tax: string, shipping: string, coupon: string) {
    this.id = id;
    this.affiliation = affiliation;
    this.revenue = revenue;
    this.tax = tax;
    this.shipping = shipping;
    this.coupon = coupon;
  }

  public getItem() {
    return {
      id: this.id,
      affiliation: this.affiliation,
      revenue: this.revenue,
      tax: this.tax,
      shipping: this.shipping,
      coupon: this.coupon
    };
  }
}





