import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'check-in-ssr-price-display',
  templateUrl: './ssr-price-display.component.html',
  styleUrls: ['./ssr-price-display.component.scss']
})
export class SsrPriceDisplayComponent implements OnInit {

  @Input() originalPrice: number;
  @Input() currentPrice: number;
  
  constructor() { }

  ngOnInit() {
  }

}
