import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';
var SsrBoxContentSpecialEquipmentComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsrBoxContentSpecialEquipmentComponent, _super);
    function SsrBoxContentSpecialEquipmentComponent(ssrsService) {
        var _this = _super.call(this, ssrsService) || this;
        _this.ssrsService = ssrsService;
        _this.ssrsCustomIcons = {};
        _this.selectSsrEvent2 = new EventEmitter();
        _this.ssrType = SsrType.SpecialEquipment;
        _this.uniqueId = Math.floor(Math.random() * 1000) + 1;
        return _this;
    }
    SsrBoxContentSpecialEquipmentComponent.prototype.initFlightSsrList = function () {
        _super.prototype.initFlightSsrList.call(this);
        this.checkPassengerLiftStatus();
        this.setupCustomIcons();
    };
    SsrBoxContentSpecialEquipmentComponent.prototype.setupCustomIcons = function () {
        var _this = this;
        this.availableSsrs.forEach(function (ssr) {
            var icon;
            switch (ssr.ssrCode) {
                case 'BIKE':
                case 'SKI':
                case 'WEAP':
                    icon = "font-ico-sport-eq-" + ssr.ssrCode.toLowerCase();
                    break;
                default:
                    icon = 'font-ico-sport-eq';
                    break;
            }
            _this.ssrsCustomIcons[ssr.ssrCode] = icon;
        });
    };
    SsrBoxContentSpecialEquipmentComponent.prototype.resetSsr = function () {
        this.selectSsr(null, "WEAP", 0);
        this.selectSsrEvent(null);
    };
    SsrBoxContentSpecialEquipmentComponent.prototype.selectSsrEvent = function (passengerIndex) {
        if (passengerIndex === void 0) { passengerIndex = null; }
        this.selectSsrEvent2.emit(passengerIndex);
    };
    SsrBoxContentSpecialEquipmentComponent.prototype.checkSameSsrOptionsForAllFlights = function () {
        this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
        this.selectSsrEvent(null);
    };
    SsrBoxContentSpecialEquipmentComponent.prototype.checkPassengerLiftStatus = function () {
        var weaponSsrIndex = this.availableSsrs.findIndex(function (s) { return s.name === "WEAP"; });
        if (this.isUnavailableDueToPreviouslyCheckedIn) {
            this.availableSsrs.splice(weaponSsrIndex, 1);
        }
    };
    return SsrBoxContentSpecialEquipmentComponent;
}(SsrBoxContentBase));
export { SsrBoxContentSpecialEquipmentComponent };
