import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../core/config.service";
var VoucherRequestService = /** @class */ (function () {
    function VoucherRequestService(http, config) {
        this.http = http;
        this.config = config;
    }
    VoucherRequestService.prototype.createRequest = function (pnr, message) {
        var headers = new HttpHeaders().set('X-Skip-Interceptor', '');
        this.http.post(this.config.config.voucherRequestUrl + "?id=" + pnr + "&message=" + message, null, { headers: headers }).toPromise();
    };
    VoucherRequestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VoucherRequestService_Factory() { return new VoucherRequestService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: VoucherRequestService, providedIn: "root" });
    return VoucherRequestService;
}());
export { VoucherRequestService };
