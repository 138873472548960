import { BookingService } from './../../core/booking.service';
import { BehaviorSubject } from 'rxjs';
import { ICanDeactivateComponent } from '../../core/can-deactivate-component.service';
import { SeatSelectedNotification } from './../../shared/seat-selected-notification/seat-selected-notification.component';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../../constants';
import { SsrsService } from '../ssrs.service';
import { SsrsComponent } from '../ssrs/ssrs.component';
import { InsuranceComponent } from '../insurance/insurance.component';
import { ActivatedRouteSnapshot, RouterStateSnapshot, RouterState } from '@angular/router';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { SsrType } from '../ssr-type.enum';
import { PassengerBundleComponent } from '../../shared/passenger-bundle/passenger-bundle.component';
import { IHasPendingChanges } from '../../core/has-pending-changes.interface';
import { SelectionsClearWarningModalComponent } from '../../shared/selections-clear-warning-modal/selections-clear-warning-modal.component';
import { ApplicationFlowService } from '../../core/application-flow.service';
import { filter, take } from 'rxjs/operators';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { environment } from '../../../environments/environment';
import { ProfileService } from 'src/app/core/profile.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';

@Component({
  selector: 'check-in-extras',
  templateUrl: './extras.component.html',
  styleUrls: ['./extras.component.scss']
})
export class ExtrasComponent implements OnInit, OnDestroy, ICanDeactivateComponent, IHasPendingChanges {
  private _canDeactivate = false;
  private currentStep: CheckinSteps = CheckinSteps.extras;
  private applicationFlowService: ApplicationFlowService;

  public SsrTypeEnum = SsrType;
  public insuranceEnabled = false; // disable insurance as requested
  bookingServiceSubscription: any;
  hasSpoilageFees: boolean;
  hasRecordLocator: boolean;
  pendingSsrsList: SsrType[] = [];
  assetsPath: string;

  @ViewChild(InsuranceComponent, { static: false }) insuranceComponent: InsuranceComponent;
  @ViewChild(PassengerBundleComponent, { static: true }) passengerBundleComponent: PassengerBundleComponent;
  @ViewChild(SsrsComponent, { static: true }) ssrsComponent: SsrsComponent;
  @ViewChild(SeatSelectedNotification, { static: true }) ssrSelectedModal: SeatSelectedNotification;
  @ViewChild('pbagModal', { static: true }) pbagModal: DefaultModalComponent;
  @ViewChild('changeFlightsWarningModal', { static: true }) clearSelectionsModal: SelectionsClearWarningModalComponent;

  constructor(private steps: BookingStepsService, private loadingSpinnerService: LoadingSpinnerService,
    private flowManager: FlowManagerService, private ecommerce: ECommerceService,
    private bookingService: BookingService, private profileService: ProfileService, private ssrsService: SsrsService) {
    this.applicationFlowService = flowManager.applicationFlowService;

    // disable insurance as requested
    // this.insuranceEnabled = !this.profileService.isAgent;

    this.assetsPath = environment.assetsPath;
    this.steps.currentStep.next(this.currentStep);
    this.applicationFlowService.shoppingCartBreakdown
      .pipe(
        filter(b => b && b.currentShoppingCart ? true : false),
        take(1)
      )
      .subscribe(breakdown => this.hasSpoilageFees = breakdown.currentShoppingCart.checkExtrasForSsrOrFeeCode(SsrType.Other, 'SPL'));

    this.bookingServiceSubscription = this.bookingService.bookingObs.subscribe(result => {
      if (result) {
        if (result.bookingDetail.recordLocator != null && result.bookingDetail.recordLocator !== "") {
          this.hasRecordLocator = true;
        }
      }
    });
  }

  ngOnInit() {
    this.ssrsService.isExtrasStep = true;
    this.ssrsService.isLuggageStep = false;
  }

  ngOnDestroy() {
    if (this.bookingServiceSubscription) {
      this.bookingServiceSubscription.unsubscribe();
    }
  }

  skipAndComplete() {
    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
    this.steps.goToStep(CheckinSteps.summary);
  }

  goToNextStep(checkinStep?: CheckinSteps) {
    const components = this.ssrsComponent.getComponentModals();

    if (this.insuranceComponent) {
      components.push(this.insuranceComponent);
    }

    const myCallback = (isOkCallback: boolean, index: number): void => {
      if (isOkCallback) {
        if (components.length === 0) {
          this._canDeactivate = true;

          if (checkinStep !== null && checkinStep !== undefined) {
            this.steps.goToStep(checkinStep);
          }
          else {
            let ecommerceCartItems = [];

            this.applicationFlowService.loadPriceBreakdown().then(breakdown => {
              if (breakdown) {
                ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown);
                this.ecommerce.Checkout(ecommerceCartItems, 2, 'Extras step');
              }
            });

            this.steps.goToNextStepFrom(this.currentStep);
          }
        }

        do {
          index++;
        }
        while (index < components.length && !components[index].openModal((isOk: boolean) => myCallback(isOk, index)));

        if (index === components.length) {
          this._canDeactivate = true;

          if (checkinStep !== null && checkinStep !== undefined) {
            this.steps.goToStep(checkinStep);
          }
          else {
            let ecommerceCartItems = [];

            this.applicationFlowService.loadPriceBreakdown().then(breakdown => {
              if (breakdown) {
                ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown);
                this.ecommerce.Checkout(ecommerceCartItems, 2, 'Extras step');
              }
            });

            this.steps.goToNextStepFrom(this.currentStep);
          }
        }
      }
    };

    const ssrsOk = this.hasPendingChanges();

    if (!ssrsOk) {
      this.ssrSelectedModal.openPopup((isOk) => {
        if (!isOk) {
          this.scrollIntoView();
        }
        else {
          myCallback(isOk, -1);
        }

        this.pendingSsrsList = [];
      });
    }
    else {
      myCallback(true, -1);
    }
  }

  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    let checkinStep = this.steps.extractStep(nextState);
    let redirectingToOtherBookingStep = true;

    if (checkinStep === null) {
      redirectingToOtherBookingStep = false;
      checkinStep = CheckinSteps.search;
    }

    if (this.clearSelectionsModal.shouldShowWarning(this.currentStep, checkinStep)) {
      return this.clearSelectionsModal.showWarningAndNavigate(
        this.currentStep,
        redirectingToOtherBookingStep ? checkinStep : null,
        nextState.url
      );
    }

    if (!this._canDeactivate) {
      this.goToNextStep(checkinStep);
    }

    return this._canDeactivate;
  }

  hasPendingChanges(): boolean {
    let ssrsOk = true;

    this.pendingSsrsList = this.ssrsComponent.getPendingSsrs();

    if (this.insuranceComponent && this.insuranceComponent.checkForPendingInsurances()) {
      this.pendingSsrsList.push(SsrType.Insurance);
    }

    if (this.passengerBundleComponent.checkPendingBundle()) {
      this.pendingSsrsList.push(SsrType.BundleUpgrade);
    }

    if (this.pendingSsrsList.length) {
      ssrsOk = false;
    }

    return ssrsOk;
  }

  scrollIntoView() {
    if (this.pendingSsrsList.length) {
      const firstPendingSsrType = this.pendingSsrsList[0];

      if (firstPendingSsrType === SsrType.Insurance) {
        this.insuranceComponent.scrollToView();
      }
      else {
        this.ssrsComponent.ssrBoxes.find(x => x.ssrType === firstPendingSsrType).validationModalAction(false);
      }
    }
  }
}
