import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'profile-info-box-actions',
  templateUrl: './info-box-actions.component.html',
  styleUrls: ['./info-box-actions.component.scss']
})
export class InfoBoxActionsComponent implements OnInit {
  @Input() deleteConfirmationMessage: string;
  @Input() showDeleteBtn = false;
  @Input() showDeleteMessage = false;

  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancelDelete: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onDelete(event) {
    this.showDeleteMessage = true;
    this.delete.emit(event);
  }

  onCancelDelete(event) {
    this.showDeleteMessage = false;
    this.cancelDelete.emit(event);
  }
}
