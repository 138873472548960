<profile-info-box class="two-cols-mob" title="{{ 'Address book' | translate:'profile-info' }}" [showEditBtn]="false" [showAddBtn]="true"
  (addClick)="editItem(-1)" [spinnerId]="infoBoxSpinnerId" [showSpinner]="true">
  <div *ngFor="let td of items; let indx = index" [ngClass]="{'border-row-wrap': indx != items.length - 1, 'row-wrap': indx == items.length - 1}">
    <div class="fullwidth">
      <span>{{ findTextByValue(types, td.typeCode) }} 
          <a class="btn btn-secondary font-ico-pen inner-span-btn" (click)="editItem(indx)">
              {{ 'Edit' | translate:'profile-info' }}
            </a>
      </span>
      <span class="blue-txt">{{ td.address.addressLine1 }}, {{ td.address.addressLine2 ? td.address.addressLine2 + ', ' : '' }} {{ td.address.city
        }}, {{ td.address.postalCode }} </span>
      <span class="blue-txt">{{ findTextByValue(countries, td.address.countryCode) }} </span>
    </div>
  </div>
  <blue-modal2 [id]="editModalId">
    <blue-loading-spinner [id]="editSpinnerId"></blue-loading-spinner>
    <span class="popup-title" *ngIf="model.index == -1">{{ 'Add address to book' | translate:'profile-info' }}</span>
    <span class="popup-title" *ngIf="model.index > -1">{{ 'Edit address from book' | translate:'profile-info' }}</span>
    <form role="form" (ngSubmit)="save()" #editForm="ngForm">
      <div class="form-row equal-fields">
        <div class="one-field">
          <div class="label">{{ 'Address Type' | translate:'profile-info' }}</div>
          <blue-select-list [(ngModel)]="model.typeCode" [items]="types" [enableSearch]="true" placeholder="{{ 'Address Type' | translate:'profile-info' }}"
            name="type" #type="ngModel" required></blue-select-list>
          <profile-form-error [reference]="type"></profile-form-error>
        </div>
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="address-field">{{ 'Address' | translate:'profile-info' }}</label>
          <input id="address-field" type="text" placeholder="{{ 'Address' | translate:'profile-info' }}" aria-labelledby="address-field"
            [(ngModel)]="model.address.addressLine1" name="address" required #address="ngModel" />
          <profile-form-error [reference]="address"></profile-form-error>
        </div>
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="address-field-two">{{ 'Address Line Two' | translate:'profile-info' }} ({{ 'Optional' | translate:'profile-info' }})</label>
          <input id="address-field-two" type="text" placeholder="{{ 'Address Line Two' | translate:'profile-info' }} ({{ 'Optional' | translate:'profile-info' }})"
            aria-labelledby="address-field-two" [(ngModel)]="model.address.addressLine2" name="address-two" />
        </div>
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="city">{{ 'City' | translate:'profile-info' }}</label>

          <input id="city" type="text" placeholder="{{ 'City' | translate:'profile-info' }}" aria-labelledby="city" [(ngModel)]="model.address.city"
            required name="city" />
        </div>
        <div class="one-field">
          <label for="country-field">{{ 'Country' | translate:'profile-info' }}</label>
          <blue-select-list [(ngModel)]="model.address.countryCode" [items]="countries" [enableSearch]="true" placeholder="{{ 'Country' | translate:'profile-info' }}"
            name="country" #country="ngModel" required></blue-select-list>
          <profile-form-error [reference]="country"></profile-form-error>
        </div>
      </div>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="postal-code">{{ 'Postal Code' | translate:'profile-info' }}</label>
          <input id="postal-code" type="text" maxlength="10" placeholder="{{ 'Postal Code' | translate:'profile-info' }}" aria-labelledby="postal-code"
            [(ngModel)]="model.address.postalCode" name="postal-code" #postalCode="ngModel" required />
          <profile-form-error [reference]="postalCode"></profile-form-error>
        </div>
      </div>
      <profile-info-box-actions [showDeleteBtn]="model.index > -1" deleteConfirmationMessage="{{ 'Are you sure you want to delete this address ?' | translate:'profile-info' }}"
        (cancel)="cancel($event)" (confirmDelete)="confirmDelete()">
      </profile-info-box-actions>
    </form>
  </blue-modal2>
</profile-info-box>