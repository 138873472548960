import * as tslib_1 from "tslib";
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { BookingService } from '../core/booking.service';
import { BookingStepsService } from '../core/booking-steps.service';
import { CheckinService } from '../core/checkin.service';
var CanActivateSeatMapService = /** @class */ (function (_super) {
    tslib_1.__extends(CanActivateSeatMapService, _super);
    function CanActivateSeatMapService(steps, applicationFlowService, bookingService) {
        var _this = _super.call(this, steps, applicationFlowService) || this;
        _this.steps = steps;
        _this.applicationFlowService = applicationFlowService;
        _this.bookingService = bookingService;
        return _this;
    }
    CanActivateSeatMapService.prototype.canActivate = function (route, state) {
        // return super.canActivate(route,state); 
        var _this = this;
        var superPromise = _super.prototype.canActivate.call(this, route, state);
        return superPromise.then(function (superResponse) {
            if (!superResponse) {
                return false;
            }
            return Promise.all([
                _this.bookingService.refresh(),
                _this.applicationFlowService.loadPriceBreakdown(),
            ])
                .then(function (data) { return true; });
        });
    };
    return CanActivateSeatMapService;
}(BaseCanActivateService));
export { CanActivateSeatMapService };
