import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { CurrencyManagerService } from './currency-manager.service';
import { take, skip } from 'rxjs/operators';
import { BehaviorSubject ,  Subscription ,  forkJoin ,  Observable } from 'rxjs';
import { ShoppingCartBreakdownModel } from './models/shopping-cart-models';

export abstract class ApplicationFlowService {
    private _currency: string;
    get currency(): string {
        return this._currency;
    }
    set currency(value: string) {
        this._currency = value;
        this.currencyManager.updateCurrentCurrency(this._currency);
    }

    protected abstract isCheckinFlow: boolean;

    private priceBreakdownSubscription: Subscription;

    shoppingCartBreakdown: BehaviorSubject<ShoppingCartBreakdownModel> = new BehaviorSubject(new ShoppingCartBreakdownModel());

    constructor(protected http: HttpClient, protected configService: ConfigService, private currencyManager: CurrencyManagerService) { }

    /**
     * Loads the price breakdown
     * @param forced if [true] a http request will be made. If [false] the http request will be made only on the first call
     */
    loadPriceBreakdown(forced: boolean = false): Promise<any> {
        // if not forced and already completed return current value
        if (!forced && this.priceBreakdownSubscription && this.priceBreakdownSubscription.closed) {
            return Promise.resolve(this.shoppingCartBreakdown.value);
        }

        // if forced - cancel previous request if any
        if (forced && this.priceBreakdownSubscription) {
            this.priceBreakdownSubscription.unsubscribe();
        }

        // if forced or no previous requests wered attempted do the request
        if (forced || !this.priceBreakdownSubscription) {
            this.priceBreakdownSubscription = this.getPriceBreakdown()
                .subscribe((response: any) => {
                    this.shoppingCartBreakdown.next(new ShoppingCartBreakdownModel().init(response.shoppingCartBreakdown));
                });
        }

        // return the next value the comes up in the subscription
        return new Promise<any>(resolve => this.shoppingCartBreakdown.pipe(skip(1), take(1)).subscribe(data => resolve(data)));
    }

    abstract loadFlowInfo(forced?: boolean): Promise<any>;

    protected getPriceBreakdown(params?: HttpParams): Observable<any> {
        return this.http.get(this.configService.ShoppingCartBreakdownUrl, { params: params });
    }
}







