<section class="section white-bg login indented">
  <div class="wrapper" *ngIf="!isLoading">
    <h1 class="h2" >
        {{ 'You have to log in to continue.' | translate: 'authenticate' }}
    </h1>
    <div class="text-holder" >
      <div class="rte-content">
        <h2>{{ 'Login to your account' | translate: 'authenticate' }}</h2>
      </div>
      <div>
        <a href="#" class="btn btn-primary" (click)="openLoginPopup($event)">{{ 'Login' | translate: 'authenticate' }}</a>
      </div>
    </div>
    <div class="text-holder" >
      <div class="rte-content">
        <h2>{{ "Don't have an account?" | translate: 'authenticate' }}</h2>
      </div>
      <div>
        <a href="{{createAccountUrl}}" class="btn btn-primary">{{ 'Create Account' | translate: 'authenticate' }}</a>
      </div>
    </div>
  </div>
</section>