
import { Constants } from '../../constants';
import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { PaxInsurance } from './../../core/models/insurance-model';
import { TranslateService } from './../../common-modules/blue-air-common/translator/translate.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { InsuranceService } from './../insurance.service';
import { Component, OnInit, ViewChild, ElementRef, HostListener, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { Insurance } from '../../core/models/insurance-model';
import { SelectionService } from '../../core/interfaces/selection.service';
import { PassengerModel, PassengerName } from '../../core/models/passenger-model';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { environment } from '../../../environments/environment';
import { FlightModel } from '../../core/models/flight-model';
import { CurrencyDisplayPipe } from '../../shared/currency-display.pipe';
import { ApplicationFlowService } from '../../core/application-flow.service';
import { BookingService } from '../../core/booking.service';
import * as moment from 'moment';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { SsrType } from '../ssr-type.enum';
import { SsrsService } from '../ssrs.service';
import { ConfigService } from 'src/app/core/config.service';
import { ProfileService } from 'src/app/core/profile.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';

@Component({
  selector: 'check-in-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss']
})

export class InsuranceComponent implements OnInit {
  private applicationFlowService: ApplicationFlowService;
  private selectionService: SelectionService;
  private flights: FlightModel[];

  loadingSpinnerId = 'insurance-preloader';
  insuranceModalId = 'insuranceModalId';
  insuranceNoConsentModalId = 'insuranceNoConsentModalId';

  insideEU: boolean;
  isInternational: boolean;
  isModified: boolean;
  isPopupOpened: boolean;
  allPassengersSelected: boolean = true;
  allPassengersDisabled: boolean;
  consentAllPax: boolean;
  isReady: boolean;

  mobileMatches: boolean;
  isRoundTrip: boolean;
  isExpanded: boolean;
  isInfant: boolean;

  paxInsuranceList: PaxInsurance[] = [];
  filteredInsuranceList: Insurance[] = [];
  allInsuranceList: Insurance[] = [];
  toDeleteInsuranceList: PaxInsurance[] = [];
  oldPaxInsuranceList: PaxInsurance[] = [];
  insuranceItems: Array<any> = [];
  insuranceObs: BehaviorSubject<any> = new BehaviorSubject(null);

  currencyCode: string;
  assetsPath: string;

  snapshotPassengers: any[] = [];
  passengers: PassengerModel[] = [];
  infants: PassengerModel[] = [];
  selectedPassengerNumber = -1;
  selectedPassenger: PassengerModel;

  selectedInsuranceDays: number;
  isInsuranceSelected: boolean;
  showInsuranceConsent: boolean;
  isOnBookingChangeFlow: boolean;

  lowestPrice: number;
  totalPrice: number;

  allPaxHaveBoughtInsurance: boolean;
  passengersInsuranceSnapshot: any[] = [];
  currencyDisplay: CurrencyDisplayPipe;

  paxNamesWithInsurance: string;
  insuranceDays: number;

  @ViewChildren(DefaultModalComponent) modals: QueryList<DefaultModalComponent>;
  @ViewChild("insuranceBox", { static: false }) insuranceBox: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobileMatches = event.target.innerWidth < Constants.mobileScreenWith;
  }

  constructor(private insuranceService: InsuranceService,
    private loadingSpinnerService: LoadingSpinnerService,
    private translateService: TranslateService,
    private flowManager: FlowManagerService,
    private bookingService: BookingService,
    private ecommerce: ECommerceService,
    private ssrsService: SsrsService,
    private configService: ConfigService,
    private profileService: ProfileService) {
    this.applicationFlowService = flowManager.applicationFlowService;
    this.selectionService = flowManager.selectionService;

    this.mobileMatches = window.innerWidth < Constants.mobileScreenWith;
    this.assetsPath = environment.assetsPath;

    this.currencyDisplay = new CurrencyDisplayPipe(this.flowManager, this.translateService);

    if (this.mobileMatches) {
      this.selectedPassengerNumber = 0;
      this.allPassengersSelected = false;
    }

    this.selectionService.flightsObs.pipe(filter(f => f ? true : false), take(1)).subscribe(flights => {
      this.flights = flights;
      this.isInternational = flights.findIndex(f => f.isInternational) >= 0;
      this.insuranceDays = this.getInsuranceDays();
      this.isRoundTrip = flights.length > 1;

      if (this.isInternational) {
        insuranceService.getInsuranceDetails().then((insuranceDetails: any) => {
          this.allInsuranceList = insuranceDetails.insurance.insurances.map(ins => new Insurance(ins));
          this.insideEU = insuranceDetails.insurance.insideEU;
          this.insuranceObs.next(this.allInsuranceList);

          this.filterInsurance();
          this.setFeeList();

          this.loadingSpinnerService.hideSpinnerById(this.loadingSpinnerId);
        }).then(() => this.isReady = true);
      }
    });

    this.selectionService.passengersObs.subscribe(passengers => {
      this.passengers = passengers;
      this.infants = this.passengers.map(p => p.infant).filter(obj => obj != undefined);
    });

    this.applicationFlowService.loadFlowInfo().then(flowInfo => {
      if ((flowInfo && flowInfo.bookingDetail && flowInfo.bookingDetail.recordLocator !== '') || environment.flow === 0) {
        this.isOnBookingChangeFlow = true;
        this.bookingService.getSnapshot().then((response: any) => {
          if (response && response.convertedJourneys.snapshotBooking && response.convertedJourneys.snapshotBooking.passengers) {
            this.snapshotPassengers = (response as any).convertedJourneys.snapshotBooking.passengers;

            if (this.snapshotPassengers.some(x => x.passengerFees.some(y => y.feeCode === Constants.InsuranceFeeCode))) {
              this.allPassengersDisabled = true;
              this.allPassengersSelected = false;
            }

            this.setChangeBookingInsuranceDetails();
          }
        });
      }
    });
  }

  ngOnInit() { }

  scrollToView() {
    this.insuranceBox.nativeElement.scrollIntoView({ behavior: 'smooth' });
    if (!this.isExpanded) {
      this.expandBox();
    }
  }

  expandBox() {
    this.isExpanded = true;
    this.setFeeList();
    this.updateInsuranceOptions();

    const ecommerceCartItems = new Array();
    const item = new ECommerceCartItem(
      'Insurance',
      this.ecommerce.getFlightInfo(0).toString(),
      this.lowestPrice.toString(),
      'Omniasig',
      'Insurance',
      '1',
      this.ecommerce.getPassengersCount(), this.ssrsService.actualDisplayOrder.length + 1,
      null,
      this.ecommerce.getUserData()
    ).getItem(true);

    ecommerceCartItems.push(item);
    this.ecommerce.ProductClicks(ecommerceCartItems);

    this.oldPaxInsuranceList = [];
  }

  cancelChanges() {
    this.setFeeList();
    this.oldPaxInsuranceList = [];
    this.toDeleteInsuranceList = [];
    this.isExpanded = false;
  }

  filterInsurance() {
    if (this.allInsuranceList !== null && this.insuranceDays) {
      const journeyType = this.isRoundTrip ? Constants.RoundTrip : Constants.OneWayTrip;
      this.filteredInsuranceList =
        this.allInsuranceList.filter(ins => ins.isActive && ins.insideEU === this.insideEU && ins.journeyType === journeyType);

      if (this.isRoundTrip) {
        this.filteredInsuranceList = this.filteredInsuranceList.filter(ins => ins.days === this.insuranceDays);
      }
      this.filteredInsuranceList = this.filteredInsuranceList.sort((a, b) => a.price > b.price ? 1 : -1);

      if (this.filteredInsuranceList.length > 0) {
        this.lowestPrice = this.filteredInsuranceList[0].price;
      }
    }
  }

  setFeeList() {
    this.passengers = this.selectionService.passengers.sort((pax1, pax2) => (pax1.passengerNumber > pax2.passengerNumber) ? 1 : -1)
    this.infants = this.passengers.filter(p => p.hasInfant).map(p => p.infant);

    this.paxInsuranceList = [];
    this.passengers.forEach(pax => {
      const fees = pax.fees.filter(f => f.code === Constants.InsuranceFeeCode);
      if (fees) {
        fees.forEach(f => {
          const paxIns = new PaxInsurance();
          const paxType = f.note.split('|')[0].trim() === Constants.InfantSsrCode ? Constants.InsuranceInfantCode : '';
          const days = f.note.split('|')[f.note.split('|').length - 1].trim();
          paxIns.isPaid = true;
          paxIns.isInfant = paxType === Constants.InsuranceInfantCode ? true : false;
          paxIns.passengerNumber = pax.passengerNumber;
          paxIns.selectedInsurance = this.allInsuranceList.find(i => i.days.toString() === days && i.passengerType === paxType);
          this.paxInsuranceList.push(paxIns);
        });
      }
    });
    this.isModified = this.paxInsuranceList.find(i => i.isPaid) ? true : false;
    this.updateStatus();
  }

  getInsuranceName(insurance: Insurance) {
    const translateKey = insurance.days + '-insurance';
    return this.isRoundTrip ? this.currencyDisplay.transform(insurance.price) : this.translateService.instant(translateKey, 'insurance.days').concat(' - ', this.currencyDisplay.transform(insurance.price));
  }

  getInsuranceDays() {
    const daysToStay =
      moment.duration(
        moment(this.flights[this.flights.length - 1].arrivalStation.dateUtc).diff(moment(this.flights[0].departureStation.dateUtc))
      ).asDays();

    // tslint:disable: curly
    if (daysToStay <= 7) return 7;
    else if (daysToStay <= 14) return 14;
    else if (daysToStay <= 31) return 31;
    else if (daysToStay <= 120) return 120;
    // tslint:enable: curly
  }

  changeSelectedPassenger(paxNr, isInfant, popupOpen?) {
    this.selectedPassengerNumber = paxNr;
    this.isInfant = isInfant;
    this.selectedPassenger = this.getSelectedPassenger();

    this.allPassengersSelected = false;
    this.updateInsuranceOptions();
    this.selectedInsuranceDays = this.getSelectedInsurancePerPax();
    if (popupOpen === false) {
      this.isPopupOpened = false;
    } else {
      this.isPopupOpened = this.mobileMatches ? true : false;
    }
    this.updateStatus();
  }

  getSelectedInsurancePerPax() {
    const selectedInsurance = this.paxInsuranceList.find(i =>
      i.passengerNumber === this.selectedPassengerNumber &&
      i.isInfant === this.isInfant);
    return selectedInsurance ? selectedInsurance.selectedInsurance.days : 0;
  }

  enableAllPassengers() {
    this.isPopupOpened = this.mobileMatches ? true : false;
    this.allPassengersSelected = !this.allPassengersSelected;
    if (this.allPassengersSelected) {
      this.selectedPassengerNumber = -1;
    } else {
      this.changeSelectedPassenger(0, false);
    }
    this.updateInsuranceOptions();
  }

  isPaxSelected(paxNum, isInfant): boolean {
    return this.selectedPassengerNumber === paxNum && this.isInfant === isInfant;
  }

  protected removeFromList(passengerNumber: number, isInfant: boolean) {
    this.paxInsuranceList
      .splice(this.paxInsuranceList
        .indexOf(this.paxInsuranceList
          .find(p => p.passengerNumber === passengerNumber && p.isInfant === isInfant)), 1);
  }

  remove(passengerNumber: number, isInfant: boolean) {
    if (this.paxHasInsurance(passengerNumber, isInfant) !== undefined && this.paxHasInsurance(passengerNumber, isInfant).isPaid) {
      const paxInsurance = this.paxInsuranceList.find(x => x.passengerNumber === passengerNumber && x.isInfant === isInfant && x.isPaid);

      if (!this.toDeleteInsuranceList.find(x => x.passengerNumber === passengerNumber && x.isInfant === isInfant && x.isPaid)) {
        this.toDeleteInsuranceList.push(paxInsurance);
      }
    }
    this.removeFromList(passengerNumber, isInfant);
    this.changeSelectedPassenger(passengerNumber, isInfant, false);

    this.updateStatus();
  }

  deleteInsurance(toDeleteInsuranceList: PaxInsurance[]): Promise<any>[] {
    const promiseArray: Promise<any>[] = [];

    toDeleteInsuranceList.forEach((paxInsurance: PaxInsurance) => {
      let feeNumber = null;
      let feesAsig = [];
      if (paxInsurance.isInfant) {
        feesAsig = this.passengers.find(p => p.passengerNumber === paxInsurance.passengerNumber).fees
          .filter(x => x.code === Constants.InsuranceFeeCode && x.note.includes(Constants.InfantSsrCode));
      } else {
        feesAsig = this.passengers.find(p => p.passengerNumber === paxInsurance.passengerNumber).fees
          .filter(x => x.code === Constants.InsuranceFeeCode && !x.note.includes(Constants.InfantSsrCode));
      }
      if (feesAsig.length > 0) {
        feeNumber = feesAsig[0].number;
      }
      if (feeNumber !== undefined && feeNumber !== null) {
        const currentPromise = this.insuranceService.deleteInsurance(paxInsurance.passengerNumber, Constants.InsuranceFeeCode, feeNumber);

        promiseArray.push(currentPromise);
      }
    });

    return promiseArray;
  }

  paxHasInsurance(passengerNumber: number, isInfant: boolean = false) {
    return this.paxInsuranceList.find(p => p.passengerNumber === passengerNumber && p.isInfant === isInfant);
  }

  selectInsurance(insuranceDays: number) {
    if (this.selectedPassengerNumber === -1) {
      if (this.paxInsuranceList.filter(i => i.isPaid).length === 0) {
        this.paxInsuranceList = [];
        this.passengers.forEach(pax => {
          const paxInsurance: PaxInsurance = new PaxInsurance();
          paxInsurance.isInfant = false;
          paxInsurance.passengerNumber = pax.passengerNumber;
          paxInsurance.selectedInsurance = this.getInsuranceByPaxType('').find(i => i.days === insuranceDays);
          this.paxInsuranceList.push(paxInsurance);

          if (pax.infant !== undefined) {
            const infInsurance: PaxInsurance = new PaxInsurance();
            infInsurance.isInfant = true;
            infInsurance.passengerNumber = pax.passengerNumber;
            infInsurance.selectedInsurance = this.getInsuranceByPaxType(Constants.InsuranceInfantCode).find(i => i.days === insuranceDays);
            this.paxInsuranceList.push(infInsurance);
          }
        });
      } else {
        this.oldPaxInsuranceList = this.paxInsuranceList.map(x => x.clone());

        this.paxInsuranceList.forEach(paxIns => {
          const paxType = paxIns.isInfant ? Constants.InsuranceInfantCode : '';

          paxIns.selectedInsurance = this.getInsuranceByPaxType(paxType).find(i => i.days === insuranceDays);
          paxIns.isPaid = false;
        });
      }
    } else {
      const paxType = this.isInfant ? Constants.InsuranceInfantCode : '';

      const insurance = this.getInsuranceByPaxType(paxType).find(i => i.days === insuranceDays);
      const paxInsIndex = this.paxInsuranceList
        .findIndex(i => i.isInfant === this.isInfant && i.passengerNumber === this.selectedPassengerNumber);

      if (paxInsIndex !== -1) {
        const oldPaxIndex = this.oldPaxInsuranceList
          .findIndex(x => x.passengerNumber === this.paxInsuranceList[paxInsIndex].passengerNumber
            && x.isInfant === this.paxInsuranceList[paxInsIndex].isInfant);

        if (oldPaxIndex !== -1) {
          this.oldPaxInsuranceList.splice(oldPaxIndex, 1);
        }

        this.oldPaxInsuranceList.push(this.paxInsuranceList[paxInsIndex].clone());

        this.paxInsuranceList[paxInsIndex].selectedInsurance = insurance;
        this.paxInsuranceList[paxInsIndex].isPaid = false;
      } else {
        const paxInsurance: PaxInsurance = new PaxInsurance();
        paxInsurance.isInfant = this.isInfant;
        paxInsurance.passengerNumber = this.selectedPassengerNumber;
        paxInsurance.selectedInsurance = insurance;
        this.paxInsuranceList.push(paxInsurance);
      }
    }

    this.updateStatus();
  }

  saveChanges() {
    if (this.showInsuranceConsent && this.isExpanded && !this.consentAllPax) {
      this.modals.find(x => x.modalId === this.insuranceNoConsentModalId).openPopup(() => { });
    } else {
      let deletePromiseArray: Promise<any>[] = [];

      this.loadingSpinnerService.showSpinnerById(this.loadingSpinnerId);

      deletePromiseArray = this.deleteInsurance(this.toDeleteInsuranceList);

      if (this.oldPaxInsuranceList.length > 0) {
        deletePromiseArray = deletePromiseArray.concat(this.deleteInsurance(this.oldPaxInsuranceList));
      }

      Promise.all(deletePromiseArray).then(() => {
        const promiseArray: Promise<any>[] = [];

        const data = {
          applyFees: {
            comment: '',
            passengerNumber: 0,
            amount: 0,
            currencyCode: '',
            feeCode: '',
            applyToAll: false
          }
        };

        if (this.paxInsuranceList.filter(i => !i.isPaid).length > 0) {
          let insuranceSoldNr = 0;
          const nrOfInsurances = this.paxInsuranceList.filter(i => !i.isPaid).length;

          this.paxInsuranceList.forEach(paxIns => {
            if (!paxIns.isPaid) {
              data.applyFees.passengerNumber = paxIns.passengerNumber;
              data.applyFees.feeCode = Constants.InsuranceFeeCode;
              data.applyFees.applyToAll = false;
              data.applyFees.amount = paxIns.selectedInsurance.price;
              data.applyFees.currencyCode = this.currencyCode;
              data.applyFees.comment = this.getComment(paxIns);

              const currentPromise = this.insuranceService.sellInsurance(data).then(x => {
                paxIns.isPaid = true;
                insuranceSoldNr += 1;

                if (insuranceSoldNr === nrOfInsurances) {
                  this.isModified = true;
                }
              });

              promiseArray.push(currentPromise);
            }
          });
        }
        Promise.all(promiseArray).then(() => {
          this.updateStatus();
          this.applicationFlowService.loadFlowInfo(true);
          this.applicationFlowService.loadPriceBreakdown(true);
          this.loadingSpinnerService.hideSpinnerById(this.loadingSpinnerId);
          if (this.totalPrice === 0) {
            this.isModified = false;
          }
          this.ecommerce.AddToCart(this.prepareEcommerceItems(this.paxInsuranceList));
          this.isExpanded = false;
        });
      });
    }
  }

  prepareEcommerceItems(paxInsuranceList: any[]) {
    const ecommerceCartItems = new Array();
    this.paxInsuranceList.forEach(p => {
      const item = new ECommerceCartItem('Insurance', this.ecommerce.getFlightInfo(0).toString() + p.passengerNumber,
        p.selectedInsurance.price.toString(), 'Omniasig', 'Insurance', p.selectedInsurance.days.toString() + ' days', 1, null,
        p.isInfant ? 'INFT' : this.passengers.find(pax => pax.passengerNumber === p.passengerNumber).paxType,
        this.ecommerce.getUserData()).getItem(false);
      ecommerceCartItems.push(item);
    });
    return ecommerceCartItems;
  }


  // comment value for infant: INF | InventoryFirstLegId-Random6DigitsNr | PassengerNumber | AsigDaysNr
  // comment value for passenger: InventoryFirstLegId-Random6DigitsNr | PassengerNumber | AsigDaysNr
  getComment(paxIns: PaxInsurance) {
    const randomNr = Math.floor(Math.random() * 900000) + 100000;
    const inventoryFirstLegId = this.flights[0].segments[0].legs[0].inventoryLegID;
    const commentADT = inventoryFirstLegId.toString()
      .concat('-', randomNr.toString(), ' | ', paxIns.passengerNumber.toString(), ' | ', paxIns.selectedInsurance.days.toString());
    const commentINF = Constants.InfantSsrCode.concat(' | ', commentADT);
    return paxIns.isInfant ? commentINF : commentADT;
  }

  // current paxTypes for insurance:
  // - if infant => Constants.InsuranceInfantCode
  // - else => ''
  getInsuranceByPaxType(paxType: string) {
    const copy = JSON.parse(JSON.stringify(this.filteredInsuranceList));
    return copy.filter(ins => ins.passengerType === paxType);
  }

  getTemporaryPrice() {
    return this.paxInsuranceList
      .map(i => i.selectedInsurance)
      .map(i => i.price)
      .reduce((a, b) => a + b, 0);
  }

  updateStatus() {
    this.totalPrice = this.paxInsuranceList
      .filter(i => !this.passengersInsuranceSnapshot.some(sp => sp.paxNr === i.passengerNumber && sp.hasBought))
      .map(i => i.selectedInsurance.price)
      .reduce((a, b) => a + b, 0);


    const numberOfPassengers = this.passengers.length + this.infants.length;

    this.allPassengersDisabled =
      this.paxInsuranceList.length > 0 && this.paxInsuranceList.length !== numberOfPassengers ||
      this.paxInsuranceList.some((ins) => ins.selectedInsurance.days !== this.paxInsuranceList[0].selectedInsurance.days) ||
      this.snapshotPassengers.length > 0;

    this.isInsuranceSelected =
      this.allPassengersSelected ? this.paxInsuranceList.length === numberOfPassengers &&
        this.paxInsuranceList.every(ins => ins.selectedInsurance.days === this.paxInsuranceList[0].selectedInsurance.days) :
        this.paxInsuranceList.some(ins => ins.passengerNumber === this.selectedPassengerNumber && ins.isInfant === this.isInfant);

    this.showInsuranceConsent = this.paxInsuranceList.length > 0;
  }

  updateInsuranceOptions() {
    let paxInsurance: any[];

    if (this.allPassengersSelected) {
      paxInsurance = [...this.getInsuranceByPaxType(''), ...this.getInsuranceByPaxType(Constants.InsuranceInfantCode)];
    } else {
      paxInsurance = this.getInsuranceByPaxType(this.isInfant ? Constants.InsuranceInfantCode : '');
    }

    const tempPaxInsurance = paxInsurance.map(ins => {
      let priceMultiplier = 1;
      if (this.allPassengersSelected) {
        priceMultiplier = ins.passengerType === Constants.InsuranceInfantCode ? this.infants.length : this.passengers.length;
      }

      return {
        'text': '', // this will be computed after final prices are calculated,
        'days': ins.days,
        'price': ins.price * priceMultiplier
      };
    });

    // calculate total price per insurance length
    // duplicates will be found if there are infants on booking
    // sort of a group by 'days' => sum(price)
    let duplicateFound = false;
    tempPaxInsurance.forEach((ins, index) => {
      const searchIndex = tempPaxInsurance.findIndex(i => i.days === ins.days);
      if (searchIndex !== index) {
        tempPaxInsurance[searchIndex].price += ins.price;
        duplicateFound = true;
      }
    });

    this.insuranceItems = tempPaxInsurance.slice(0, duplicateFound ? tempPaxInsurance.length / 2 : tempPaxInsurance.length);
    this.insuranceItems.forEach(ins => ins.text = this.getInsuranceName(ins));
  }

  openModal(callback: any): boolean {
    if (this.isReady && this.isInternational && !this.paxInsuranceList.length && this.insuranceDays
      && this.configService.config.pushSsr.some(p => p.ssrType === SsrType.Insurance)) {
      this.modals.find(x => x.modalId === this.insuranceModalId).openPopup((isOk) => {
        if (!isOk) {
          this.scrollToView();
        }
        callback(isOk);
      });
      return true;
    }

    return false;
  }

  removeFromCart() {
    const promiseArray: Promise<any>[] = [];

    this.loadingSpinnerService.showSpinnerById(this.loadingSpinnerId);
    this.ecommerce.RemoveFromCart(this.prepareEcommerceItems(this.paxInsuranceList));

    this.paxInsuranceList = [];
    this.toDeleteInsuranceList = [];

    for (const passenger of this.passengers) {
      const insuranceItemsToDelete = this.passengers.find(p => p.passengerNumber === passenger.passengerNumber).fees
        .filter(x => x.code === Constants.InsuranceFeeCode);

      for (const item of insuranceItemsToDelete) {
        const currentPromise = this.insuranceService.deleteInsurance(passenger.passengerNumber, Constants.InsuranceFeeCode, item.number);
        promiseArray.push(currentPromise);
      }
    }

    Promise.all(promiseArray).then(() => {
      this.setFeeList();
      this.consentAllPax = false;
      this.isModified = false;

      this.applicationFlowService.loadFlowInfo(true);
      this.applicationFlowService.loadPriceBreakdown(true);
      this.loadingSpinnerService.hideSpinnerById(this.loadingSpinnerId);
    });
  }

  shouldShowRemoveButton(passengerNumber: number, isInfant: boolean): boolean {
    return this.paxInsuranceList.find(x => x.passengerNumber === passengerNumber && x.isInfant === isInfant)
      && !this.hasPreviouslyBoughtInsurance(passengerNumber, isInfant)
      ? true : false;
  }

  hasPreviouslyBoughtInsurance(passengerNumber: number, isInfant: boolean): boolean {
    let prevBought = false;

    if (this.snapshotPassengers.length > 0) {
      prevBought = isInfant ?
        this.snapshotPassengers[passengerNumber].passengerFees
          .find(x => x.feeCode === Constants.InsuranceFeeCode && isInfant && x.note.includes(Constants.InfantSsrCode)) != null :
        this.snapshotPassengers[passengerNumber].passengerFees
          .find(x => x.feeCode === Constants.InsuranceFeeCode && !isInfant && !x.note.includes(Constants.InfantSsrCode)) != null;
    }

    return prevBought && this.isOnBookingChangeFlow;
  }

  private getSelectedPassenger() {
    const selectedPax = this.isInfant ?
      this.infants.find(inf => (inf.passengerNumberByType - 1) === this.selectedPassengerNumber) :
      this.passengers.find(pax => pax.passengerNumber === this.selectedPassengerNumber);

    return selectedPax;
  }

  checkForPendingInsurances() {
    return this.paxInsuranceList.some(x => !x.isPaid) || this.toDeleteInsuranceList.length;
  }

  private setChangeBookingInsuranceDetails() {
    this.passengersInsuranceSnapshot = [...this.snapshotPassengers.map(x => ({
      paxNr: x.passengerNumber,
      paxName: x.names[0].firstName + ' ' + x.names[0].lastName,
      isInfant: false,
      hasBought: x.passengerFees.some(y => y.feeCode === Constants.InsuranceFeeCode
        && !y.note.includes(Constants.InfantSsrCode))
    })),
    ...this.snapshotPassengers.filter(x => x.passengerFees.some(y => y.feeCode === Constants.InfantSsrCode))
      .map(x => ({
        paxNr: x.passengerNumber,
        paxName: x.passengerInfants[0].names[0].firstName + ' ' + x.passengerInfants[0].names[0].lastName,
        isInfant: true,
        hasBought: x.passengerFees.some(y => y.feeCode === Constants.InsuranceFeeCode
          && y.note.includes(Constants.InfantSsrCode))
      }))
    ];

    const firstPaxWO = this.passengersInsuranceSnapshot.find(x => !x.hasBought);
    if (firstPaxWO) {
      this.changeSelectedPassenger(firstPaxWO.paxNr, firstPaxWO.isInfant);
    }
    this.allPaxHaveBoughtInsurance = this.passengersInsuranceSnapshot.filter(p => p.hasBought).length === this.passengers.length + this.infants.length;
    this.paxNamesWithInsurance = this.passengersInsuranceSnapshot.filter(p => p.hasBought).map(p => p.paxName).join(', ');
  }
}
