import * as tslib_1 from "tslib";
import { ShoppingCartBreakdownItem } from './shopping-cart-breakdown-item';
import { ShoppingCartFlightModel } from './shopping-cart-flight-model';
import * as moment from 'moment';
import { ShoppingCartPassengerModel } from './shopping-cart-passenger-model';
import { Constants } from '../../../constants';
import { FlightModel } from '../flight-model';
import { Helpers } from '../../../helpers';
import { ShoppingCartExtrasModel, ShoppingCartExtrasFlightModel, ShoppingCartExtrasPassengerModel, ShoppingCartExtrasSsrItemModel } from './shopping-cart-extras-model';
import { SsrType } from '../../../extras/ssr-type.enum';
var ShoppingCartBreakdownModel = /** @class */ (function () {
    function ShoppingCartBreakdownModel() {
        this.currentShoppingCart = new ShoppingCartBreakdownItem();
    }
    ShoppingCartBreakdownModel.prototype.init = function (shoppingCartBreakdown) {
        this.currentShoppingCart = new ShoppingCartBreakdownItem().init(shoppingCartBreakdown.newJourneys, shoppingCartBreakdown.newExtras, shoppingCartBreakdown.newBookingBalanceDue);
        this.originalShoppingCart = shoppingCartBreakdown.originalJourneys && shoppingCartBreakdown.originalJourneys.length ?
            new ShoppingCartBreakdownItem().init(shoppingCartBreakdown.originalJourneys, shoppingCartBreakdown.originalExtras, shoppingCartBreakdown.oldBookingBalanceDue) :
            null;
        return this;
    };
    ShoppingCartBreakdownModel.prototype._groupBundlesPricesPerFlightAndPassengerType = function (itinerary) {
        var result = {};
        itinerary.priceItinerary.passengers.forEach(function (passenger) {
            if (Array.isArray(passenger.fees.items)) {
                passenger.fees.items.forEach(function (fee) {
                    if (fee.type === 'ServiceBundle') {
                        if (!result[fee.flightReference]) {
                            result[fee.flightReference] = {};
                        }
                        if (!result[fee.flightReference][passenger.typeInfo.paxType]) {
                            result[fee.flightReference][passenger.typeInfo.paxType] = 0;
                        }
                        fee.serviceCharges.items.forEach(function (serviceCharge) {
                            result[fee.flightReference][passenger.typeInfo.paxType] += serviceCharge.amount;
                        });
                    }
                });
            }
        });
        return result;
    };
    ShoppingCartBreakdownModel.prototype._extractDateParts = function (dateAsString) {
        var _a = tslib_1.__read(dateAsString.split(' '), 2), datePart = _a[0], timePart = _a[1];
        var _b = tslib_1.__read(datePart.split('-'), 3), year = _b[0], month = _b[1], day = _b[2];
        var _c = tslib_1.__read(timePart.split(':'), 3), hour = _c[0], minute = _c[1], second = _c[2];
        return {
            year: year,
            month: month,
            day: day,
            hour: hour,
            minute: minute,
            second: second
        };
    };
    ShoppingCartBreakdownModel.prototype._getJourneyFlightReference = function (journey) {
        var firstSegment = journey.segments.items[0];
        var dateParts = this._extractDateParts(firstSegment.std);
        var carrierCode = firstSegment.flightDesignator.carrierCode;
        var flightNumber = firstSegment.flightDesignator.flightNumber;
        var departureStation = firstSegment.departureStation;
        var arrivalStation = firstSegment.arrivalStation;
        return "" + dateParts.year + dateParts.month + dateParts.day + " " + carrierCode + flightNumber + " " + departureStation + arrivalStation;
    };
    ShoppingCartBreakdownModel.prototype.updateFromItinerary = function (itinerary, ignoreDiscounts) {
        var _this = this;
        var _a;
        var overriddenBalanceDue = this.currentShoppingCart && this.currentShoppingCart.isBalanceDueOverridden ?
            this.currentShoppingCart.balanceDue : null;
        this.currentShoppingCart = new ShoppingCartBreakdownItem();
        if (itinerary !== null) {
            var bundlePricesPerFlightAndPassengerType_1 = this._groupBundlesPricesPerFlightAndPassengerType(itinerary);
            var infants_1 = itinerary.priceItinerary.passengers
                .filter(function (p) { return p.fees.items.some(function (f) { return f.code === Constants.InfantSsrCode; }) || p.hasInfant; })
                .map(function () {
                var pax = new ShoppingCartPassengerModel();
                pax.passengerType = Constants.InfantSsrCode;
                return pax;
            });
            var paxList_1 = [];
            itinerary.priceItinerary.passengers
                .map(function (p) {
                var pax = new ShoppingCartPassengerModel();
                pax.passengerType = p.typeInfo.paxType;
                pax.discountCode = p.discountCode;
                return pax;
            })
                .concat(infants_1)
                .forEach(function (p) {
                var pax = paxList_1.find(function (item) { return item.passengerType === p.passengerType && item.discountCode === p.discountCode; });
                if (!pax) {
                    pax = p;
                    pax.count = 0;
                    paxList_1.push(pax);
                }
                pax.count++;
            });
            this.currentShoppingCart.flights = itinerary.priceItinerary.journeys.map(function (journey, ji) {
                var journeyFlightReference = _this._getJourneyFlightReference(journey);
                var oldJourneyIndex;
                if (itinerary.convertedJourneys.journeys[ji].journeyTripType === 'OneWay' && itinerary.convertedJourneys.snapshotBooking) {
                    oldJourneyIndex = Number(sessionStorage.getItem(Constants.SelectedFlightIndexForChange));
                }
                var passengerFares = FlightModel.calculateFares(itinerary.convertedJourneys.journeys[ji], ignoreDiscounts, {}, itinerary.convertedJourneys.oldJourneyFares, oldJourneyIndex);
                if (infants_1.length && !itinerary.convertedJourneys.snapshotBooking) {
                    itinerary.priceItinerary.passengers.find(function (p) { return p.fees.items.some(function (fee) {
                        if (fee.code !== Constants.InfantSsrCode || fee.flightReference != journeyFlightReference) {
                            return false;
                        }
                        passengerFares[Constants.InfantSsrCode] = fee.serviceCharges.items
                            .filter(function (sc) { return sc.chargeCode === Constants.InfantSsrCode; })
                            .reduce(Helpers.Sum('amount'), 0);
                        return true;
                    }); });
                }
                var passengers = paxList_1.map(function (oldPax) {
                    var newPax = new ShoppingCartPassengerModel(oldPax);
                    var bundlePrice = (bundlePricesPerFlightAndPassengerType_1[journeyFlightReference]
                        && bundlePricesPerFlightAndPassengerType_1[journeyFlightReference][newPax.passengerType]) || 0;
                    newPax.amount = newPax.count * (passengerFares[newPax.paxFareKey] || 0) + bundlePrice;
                    return newPax;
                }).reduce(function (list, pax) {
                    var samePaxTypePax = list.find(function (p) { return p.passengerType === pax.passengerType; });
                    if (samePaxTypePax && passengerFares[samePaxTypePax.paxFareKey] === passengerFares[pax.paxFareKey]) {
                        samePaxTypePax.count += pax.count;
                        samePaxTypePax.amount += pax.amount;
                    }
                    else {
                        list.push(pax);
                    }
                    return list;
                }, []);
                var seg = journey.segments.items.map(function (s) {
                    var leg = s.legs.items[0];
                    var newS = new ShoppingCartFlightModel();
                    newS.departureStation = s.departureStation;
                    newS.arrivalStation = s.arrivalStation;
                    newS.departureDate = moment(s.std, 'YYYY-MM-DD HH:mm:ss');
                    newS.departureDateUtc = moment(newS.departureDate).subtract(leg.legInfo.deptLtv, 'minutes').toDate();
                    newS.arrivalDate = moment(s.sta, 'YYYY-MM-DD HH:mm:ss');
                    newS.arrivalDateUtc = moment(newS.arrivalDate).subtract(leg.legInfo.arrvLtv, 'minutes').toDate();
                    newS.operatingCarrier = leg.flightDesignator.carrierCode;
                    newS.flightNumber = leg.flightDesignator.flightNumber;
                    newS.passengers = passengers;
                    newS.amount = passengers.sum(function (p) { return p.amount; });
                    return newS;
                });
                var f = new ShoppingCartFlightModel()
                    .initFromSegments(seg, seg.slice(0, 1).sum(function (p) { return p.amount; }), itinerary.priceItinerary.bundleCodes ? itinerary.priceItinerary.bundleCodes[ji] : null);
                return f;
            });
            (_a = this.currentShoppingCart.extras).push.apply(_a, tslib_1.__spread(this.initExtrasFromItinerary(itinerary)));
            this.currentShoppingCart.balanceDue =
                this.currentShoppingCart.flights.sum(function (f) { return f.amount; }) +
                    this.currentShoppingCart.extras.sum(function (e) { return e.amount; });
        }
        this.currentShoppingCart.overrideBalanceDue(overriddenBalanceDue);
        return this;
    };
    ShoppingCartBreakdownModel.prototype.overrideBalanceDue = function (newBalanceDue) {
        this.currentShoppingCart = this.currentShoppingCart || new ShoppingCartBreakdownItem();
        this.currentShoppingCart.overrideBalanceDue(newBalanceDue);
        return this;
    };
    ShoppingCartBreakdownModel.prototype.initExtrasFromItinerary = function (itinerary) {
        var _this = this;
        // to be generalized if we look for something more than DCH
        var penaltyFees = [];
        itinerary.passengers.items.forEach(function (pax) {
            pax.fees.items
                .filter(function (fee) { return fee.code === 'DCH'; })
                .forEach(function (fee) {
                penaltyFees.push({
                    flightRef: fee.flightReference.slice(-6),
                    code: fee.code,
                    amount: fee.serviceCharges.items.sum(function (sc) { return sc.amount; })
                });
            });
        });
        var otherExtrasModel = new ShoppingCartExtrasModel();
        otherExtrasModel.ssrType = SsrType.Other;
        var groupByFlightRef = penaltyFees.groupBy(function (fee) { return fee.flightRef; });
        groupByFlightRef
            .filter(function (group) { return group.key.length === 6; })
            .forEach(function (group) {
            var departure = group.key.slice(0, 3);
            var arrival = group.key.slice(3);
            var initialFlights = _this.originalShoppingCart ?
                _this.originalShoppingCart.flights :
                _this.currentShoppingCart.flights;
            var flight = initialFlights.find(function (f) {
                return f.segments.some(function (s) { return s.departureStation === departure && s.arrivalStation === arrival; });
            });
            if (!flight) {
                return;
            }
            var extrasFlight = otherExtrasModel.flights.find(function (f) {
                return f.departureStation === flight.departureStation && f.arrivalStation === flight.arrivalStation;
            });
            if (!extrasFlight) {
                extrasFlight = new ShoppingCartExtrasFlightModel();
                extrasFlight.departureStation = flight.departureStation;
                extrasFlight.arrivalStation = flight.arrivalStation;
                otherExtrasModel.flights.push(extrasFlight);
            }
            var extrasSegment = extrasFlight.segments.find(function (s) { return s.departureStation === departure && s.arrivalStation === arrival; });
            if (!extrasSegment) {
                extrasSegment = new ShoppingCartExtrasFlightModel();
                extrasSegment.departureStation = departure;
                extrasSegment.arrivalStation = arrival;
                var groupByFeeCode = group.values.groupBy(function (fee) { return fee.code; });
                extrasSegment.passengers = groupByFeeCode.map(function (feeGroup) {
                    var extrasPassenger = new ShoppingCartExtrasPassengerModel();
                    var extrasItem = new ShoppingCartExtrasSsrItemModel();
                    extrasItem.quantity = 1;
                    extrasItem.ssrCode = feeGroup.key;
                    extrasItem.amount = feeGroup.values.sum(function (feeValue) { return feeValue.amount; });
                    extrasItem.totalAmount = extrasItem.amount;
                    extrasPassenger.items = [extrasItem];
                    return extrasPassenger;
                });
                extrasFlight.segments.push(extrasSegment);
            }
        });
        otherExtrasModel.flights.forEach(function (f) {
            f.segments.forEach(function (s) { return s.initSegmentsFromPassengers(s.passengers); });
            f.initJourneyFromSegments(f.segments);
        });
        otherExtrasModel.initFromFlights(otherExtrasModel.flights);
        return [otherExtrasModel];
    };
    return ShoppingCartBreakdownModel;
}());
export { ShoppingCartBreakdownModel };
