import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { BaseCanActivateService } from 'src/app/core/base-can-activate.service';
import { BookingStepsService } from 'src/app/core/booking-steps.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';

@Injectable()
export class CanActivateBundlesService extends BaseCanActivateService implements CanActivate {

  constructor(flowManager: FlowManagerService, steps: BookingStepsService) {
    super(steps, flowManager.applicationFlowService);
  }
}