<div #insuranceBox class="checkin-extra-options" *ngIf="isInternational && insuranceDays">
  <check-in-default-modal [modalId]="insuranceModalId" modalTitle="{{ 'Insurance-Title' | translate:'insurance' }}"
    cssClass="half-split"
    okButtonText="{{ 'Insurance-Decline-Button' | translate:'extras' }}"
    cancelButtonText="{{ 'Insurance-Add-Button' | translate:'extras' }}" [okRequired]="true" [cancelRequired]="true">
   
    <div class="half-split-row">
      <div class="half-split-column">
        <div class="section-SubTitle">
            <p innerHTML="{{ 'disadvantages-title' | translate:'insurance' }}"></p>

        </div>
        <div>
          <ul class="list">
            <li *blueTanslateItems="'insurance.accept-disadvantages.items'; let translation" [innerHtml]="translation">
              </li>
          </ul>
        </div>
      </div>
      <div class="half-split-column">
          <div class="section-SubTitle">
            <p innerHTML="{{ 'advantages-title' | translate:'insurance' }}"></p>
        </div>
        <div class="section-text">
          <ul class="list">
            <li *blueTanslateItems="'insurance.accept-advantages.items';let translation">
              <span role="presentation" class="ssr-icon font2-ico-bundle-ssrs-clar-scab font2-ico-ssrs-scab font-ico-checked-sign"></span>
              <div class="text" [innerHtml]="translation"></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </check-in-default-modal>

  <check-in-default-modal modalId="insuranceNoConsentModalId"
  modalTitle="{{'Consent not checked for insurance' | translate: 'extras'}}"
  modalText="{{'Please confirm your consent for buying insurance!' | translate: 'extras'}}"
  cancelButtonText="{{'Dismiss' | translate: 'extras'}}"
  [okRequired]="false" [cancelRequired]="true">
  </check-in-default-modal>

  <div class="checkin-box" aria-haspopup="true" [ngClass]="{ 'expanded': isExpanded, 'added': isModified }">
    <blue-loading-spinner [id]="loadingSpinnerId" [showSpinner]="true"></blue-loading-spinner>
    <div class="checkin-options-header" [ngClass]="{ 'open': isExpanded }">
      <div class="left">
        <div class="circle-wrap">
          <span class="font-ico-circle" role="presentation"></span>
          <img class="insurance-icon" [src]=" assetsPath + 'svg/icon-insurance.svg'">
        </div>
        <div class="text-holder">
          <ng-content></ng-content>
        </div>
      </div>
      <div class="right">
        <div class="right-inner default-view">
          <span attr.aria-label="{{ 'Upgrade from {0} {1}' | translate:'extras':lowestPrice:'' }}">{{
            'from' | translate:'extras' }} {{ lowestPrice | currencyDisplay }}</span>
          <button type="button" class="btn btn-primary btn-small"
            attr.aria-label="{{ 'Click to add insurance' | translate:'extras'}}" (click)="expandBox()">
            {{ 'Add insurance' | translate:'extras'}}
          </button>
        </div>
        <div class="right-inner added-view" *ngIf="isModified">
          <button *ngIf="!isOnBookingChangeFlow" type="button" class="remove-from-cart" (click)="removeFromCart()">
            <span class="font-ico-close-menu">
              <span class="sr-only">{{ 'Click to remove from cart' | translate:'extras' }}</span>
            </span>
          </button>
          <span class="font-ico-checked-sign" role="presentation"></span>
          <strong>{{ 'Added to cart' | translate:'extras' }}</strong>
          <span class="price" attr.aria-label="{{ totalPrice + 'Romanian Leu'}}">{{ totalPrice |
            currencyDisplay }}</span>
          <button type="button" class="btn btn-secondary blue"
            attr.aria-label="{{ 'Click to modify' | translate:'extras' }}"
            (click)="expandBox()">{{ 'Modify' | translate:'extras' }}</button>
        </div>
      </div>
      <button type="button" class="btn btn-secondary blue collapse-item" aria-hidden="true"
        attr.aria-label="{{ 'Click to cancel' | translate:'extras' }}"
        (click)="cancelChanges()">{{ 'Cancel' | translate:'extras' }}</button>
    </div>

    <div class="checkin-options-content" *ngIf="isExpanded" [style.display]="isExpanded ? 'block' : ''"
      [(checkInSsrListDisplay)]="isPopupOpened">
      <div class="checkin-accordion">
        <div class="checkin-pets-options">
          <div class="cancelInsuranceInfo" *ngIf="paxNamesWithInsurance">
            {{'For passengers {0} please contact Omniasig in order to change insurance.' | translate:'extras':paxNamesWithInsurance}}
          </div>
          <!-- begin of passenger picker -->
          <div class="passenger-selection">
            <div class="checkbox-wrap" *ngIf="passengers.length + infants.length > 1">
              <input type="checkbox" id="all-passengers" (click)="enableAllPassengers()"
                [ngModel]="allPassengersSelected" [attr.disabled]="allPassengersDisabled ? 'disabled' : null" />
              <label for="all-passengers">{{ 'All Passengers' | translate:'shared' }}</label>
            </div>
            <!-- adults and childs -->
            <div class="select-passenger-box" [ngClass]="{'all-passengers-selected' : allPassengersSelected }">
              <div class="select-passenger-item" [ngClass]="{'selected': isPaxSelected(pax.passengerNumber, false) }" *ngFor="let pax of passengers;let i = index;">
                <span class="insurance-icon-passengersList font-ico-insurance" [ngClass]="{'populated': paxHasInsurance(pax.passengerNumber, false)}"></span>
                <check-in-passenger-name-display cssClass="name" [passenger]="pax"></check-in-passenger-name-display>
                <div *ngIf="paxHasInsurance(pax.passengerNumber, false) != undefined else chooseInsuranceAdt">
                  <span *ngIf="!isRoundTrip">
                    {{ paxHasInsurance(pax.passengerNumber, false).selectedInsurance.days + '-insurance' | translate:'insurance.days' }}
                  </span>
                  <span *ngIf="isRoundTrip">
                    {{ 'Insurance added' | translate:'extras' }}
                  </span>
                  <button type="button" *ngIf="shouldShowRemoveButton(pax.passengerNumber, false)"
                    class="remove-selected-btn" (click)="remove(pax.passengerNumber, false)"><span
                    class="font-ico-close-menu"><span class="sr-only"> {{ 'Click to remove selected insurance' | translate:'extras' }}</span></span></button>
                </div>
                <ng-template #chooseInsuranceAdt>
                  <span class="choose-text">{{'Choose insurance' | translate:'extras' }}</span>
                </ng-template>
                <button type="button"
                  [disabled]="!shouldShowRemoveButton(pax.passengerNumber, false) && paxHasInsurance(pax.passengerNumber, false)"
                  (click)="changeSelectedPassenger(pax.passengerNumber, false)" class="choose-btn"
                  attr.aria-label="{{ 'Click to choose insurance' | translate:'extras' }}"></button>
              </div>
              <!-- infants -->
              <div class="select-passenger-item" [ngClass]="{'selected': isPaxSelected(i, true) }" *ngFor="let inf of infants;let i = index;">
                <span class="insurance-icon-passengersList font-ico-insurance" [ngClass]="{'populated': paxHasInsurance(i, true)}"></span>
                <check-in-passenger-name-display cssClass="name" [passenger]="inf"></check-in-passenger-name-display>
                <div *ngIf="paxHasInsurance(i, true) != undefined else chooseInsuranceInf">
                  <span *ngIf="!isRoundTrip">
                    {{ paxHasInsurance(i, true).selectedInsurance.days + '-insurance' | translate:'insurance.days' }}
                  </span>
                  <span *ngIf="isRoundTrip">
                    {{ 'Insurance added' | translate:'extras' }}
                  </span>
                  <button type="button" *ngIf="shouldShowRemoveButton(i, true)" class="remove-selected-btn"
                    (click)="remove(i, true)"><span class="font-ico-close-menu"><span class="sr-only">{{ 'Click to remove selected insurance' |
                        translate:'extras' }}</span></span></button>
                </div>
                <ng-template #chooseInsuranceInf>
                  <span class="choose-text">{{'Choose insurance' | translate:'extras' }}</span>
                </ng-template>
                <button type="button" [disabled]="!shouldShowRemoveButton(i, true) && paxHasInsurance(i, true)"
                  (click)="changeSelectedPassenger(i, true)" class="choose-btn"
                  attr.aria-label="{{ 'Click to choose insurance' | translate:'extras' }}"></button>
              </div>
            </div>
          </div>
          <!-- end of passenger picker -->

          <div class="pets-list js-ssr-list">
            <div class="pets-list-inner">
              <button type="button" class="close-popup" aria-hidden="true">
                <span class="font-ico-close-menu">
                  <span class="sr-only">{{ 'Click to close insurance overlay' |  translate:'extras' }}</span>
                </span>
              </button>
              <h2 aria-hidden="true" class="js-ssr-list-title">{{ 'Choose insurance' | translate }}</h2>
              <div *ngIf="mobileMatches">
                <div *ngIf="allPassengersSelected else singlePassengerName">{{ 'All passengers' | translate: 'extras' }}
                </div>
                <ng-template #singlePassengerName>
                  <check-in-passenger-name-display cssClass="name" [passenger]="selectedPassenger">
                  </check-in-passenger-name-display>
                </ng-template>
              </div>
              <div class="pet-item insurance-header-mobile">
                <div class="header-wrap-item">
                  <div class="circle-wrap">
                    <span class="font-ico-circle"></span>
                    <img class="icon insurance-icon" [src]=" assetsPath + 'svg/icon-insurance.svg'">
                  </div>
                  <div class="pet-item-left insurance-row-mobile">
                    <h3>{{ 'Travel insurance' | translate:'extras' }}</h3>
                  </div>
                  <div class="pet-item-left insurance-row-mobile">
                    <span class="pet-desc" *blueTanslateItems="'insurance.description.items';let translation" [innerHTML]="translation"></span>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="!allPaxHaveBoughtInsurance">
                <!-- roundTrip insurance display -->
                <div *ngIf="isRoundTrip else oneWayInsuranceDisplay">
                  <button [ngClass]="{'selected': isInsuranceSelected}" class="custom_insurance_btn"
                    *ngIf="insuranceItems.length > 0" type="button" (click)="selectInsurance(insuranceItems[0].days)">
                    <div>
                      <div *ngIf="!isInsuranceSelected">{{ 'Add' |  translate:'extras' }}</div>
                      <div *ngIf="isInsuranceSelected">{{ 'Added' |  translate:'extras' }}</div>
                      <div class="custom_insurance_btn_price">{{ insuranceItems[0].text }}</div>
                    </div>
                  </button>
                </div>
                <!-- end of roundTrip insurance display -->

                <!-- one way insurance display -->
                <ng-template #oneWayInsuranceDisplay>
                  <blue-select-list class="coverage-select" *ngIf="insuranceItems.length > 0"
                    [(ngModel)]="selectedInsuranceDays" [items]="insuranceItems" [enableSearch]="false" required
                    placeholder="{{ 'Select coverage: from' | translate:'extras' }}" valueProperty='days'
                    (ngModelChange)="selectInsurance(selectedInsuranceDays)">
                  </blue-select-list>
                </ng-template>
                <!-- end of one way insurance display -->
              </ng-container>

              <div class="insurance_consentWrapper" *ngIf="showInsuranceConsent">
                <div class="insurance_consentTitle">
                  {{ 'Declaration of understanding' | translate:'extras'}}
                </div>
                <div class="insurance_consentTerms">
                  <span innerHtml="{{ 'Insurance-Consent-Text' | translate:'extras'}}"></span>
                  <!-- agree button for all pax -->
                  <div class="insurance_consentCheckboxWrapper">
                    <input [(ngModel)]="consentAllPax" type="checkbox" id="insurance-consent-allPax"/>
                    <label for="insurance-consent-allPax" innerHtml="{{ 'Insurance-Consent-AgreeText' | translate:'extras'}}"></label>
                  </div>
                </div>
              </div>

              <button type="button" class="btn btn-primary close-popup-bottom insurance_customCloseBtn"
                aria-hidden="true">
                {{ 'Confirm and Continue' | translate:'extras' }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="checkin-total-options-bar">
        <span class="text">
          <span innerHtml="{{ '<span class=\'hide-on-mobile\'>Ancillary</span> Total:' | translate:'extras'}}"></span>
          <strong>{{ totalPrice | currencyDisplay }}</strong>
        </span>
        <button type="button" class="btn btn-primary"
          attr.aria-label="{{ 'Click to add to cart' | translate:'extras' }}"
          (click)="saveChanges()">{{ 'Add to Cart' | translate:'extras' }}</button>
      </div>
    </div>
  </div>
</div>