import { Injectable, OnInit } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { of as observableOf } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { Helpers, } from '../helpers';
import { EnvHelper } from '../env-helper';
import { LoginResponseModel } from './models/profile/login-response-model';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private profileSubscription: Subscription;
  public profile: BehaviorSubject<any> = new BehaviorSubject(null);
  public isAgent: boolean = EnvHelper.IsOnAgencyPortal();

  constructor(private http: HttpClient, private config: ConfigService, private translate: TranslateService) {
    // setTimeout(() => {
    //   if (window.blueAirSessionObj && window.blueAirSessionObj.session && window.blueAirSessionObj.session.User) {
    //     this.refreshProfile();
    //   }
    // }, 0);
  }

  loginAgent(username: string, password: string): Observable<LoginResponseModel> {
    return this.http.post(this.config.AgentLoginUrl, {
      autologinagent: {
        username: username,
        password: password
      }
    }).pipe(
      switchMap((result: any) => {
        const profile = this.validateProfile(result.memberProfile);
        if (profile != null) {
          return observableOf(result);
        }
        return this.getResetPassword();
      }),
      map((result: any) => {
        const response = new LoginResponseModel();

        if (result.memberProfile) {
          this.profile.next(result.memberProfile.member);

          response.loginOk = true;
          response.member = this.profile.value;
          response.errors = [];
        } else if (result.resetPassword) {
          response.loginOk = false;
          response.mustResetPassword = true;
          response.member = null;
          response.errors = [];
        } else {
          response.loginOk = false;
          response.member = null;
          response.errors = [this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
        }

        return response;
      }),
      catchError(errorResult => {
        const response = new LoginResponseModel();
        response.errors = [];

        if (errorResult.error && errorResult.error.errors && errorResult.error.errors.length) {
          response.errors.push(...errorResult.error.errors.map((err: any) => {
            const t = this.translate.instant(err.errorCode, 'profile-info');
            return t === err.errorCode ? `${err.errorCode}: ${err.errorMessage}` : t;
          }));
        } else {
          response.errors.push(this.translate.instant('GenericAuthenticationFailed', 'profile-info'));
        }

        return observableOf(response);
      }),
    );
  }

  logout(): Promise<any> {
    return this.http.get(this.config.LogoutUrl).toPromise();
  }


  refreshProfile(forced: boolean = false): Promise<any> {
    const getValue = {
      subject: this.profile,
      subscription: this.profileSubscription,
      valueProvider: () => this.getProfile(),
      forced: forced
    };

    const promise = Helpers.GetValueAsync(getValue);
    this.profileSubscription = getValue.subscription;

    return promise;
  }

  private getProfile(): Observable<any> {
    return this.http.get(this.config.ProfileUrl).pipe(
      map((p: any) => this.validateProfile(p.memberProfile))
    );
  }

  private validateProfile(memberProfile: any) {
    if (memberProfile && memberProfile.member.agentId !== 9) {
      return memberProfile.member;
    }
    return null;
  }

  getResetPassword(): Observable<any> {
    return this.http.get(this.config.ResetPasswordUrl);
  }

  resetPassword(
    agentId: number, username: string, oldPassword: string, password: string, passwordConf: string): Observable<LoginResponseModel> {
    return this.http.post(this.config.ResetPasswordUrl, {
      resetPassword: {
        agentId: agentId,
        username: username,
        oldPassword: oldPassword,
        password: password,
        passwordConfirmation: passwordConf
      }
    }).pipe(
      map((result: any) => {
        const response = new LoginResponseModel();

        if (result.memberProfile) {
          if (this.validateProfile(result.memberProfile)) {
            this.profile.next(result.memberProfile.member);

            response.loginOk = true;
            response.member = this.profile.value;
            response.errors = [];
          } else {
            response.loginOk = false;
            response.member = null;
            response.errors = [this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
          }
        } else {
          response.loginOk = false;
          response.member = null;
          response.errors = [this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
        }

        return response;
      }),
      catchError(errorResult => {
        const response = new LoginResponseModel();
        response.errors = [];

        if (errorResult.error && errorResult.error.errors && errorResult.error.errors.length) {
          response.errors.push(...errorResult.error.errors.map((err: any) => {
            const t = this.translate.instant(err.errorCode, 'profile-info');
            return t === err.errorCode ? `${err.errorCode}: ${err.errorMessage}` : t;
          }));
        } else {
          response.errors.push(this.translate.instant('GenericAuthenticationFailed', 'profile-info'));
        }

        return observableOf(response);
      }),
    );
  }

  updateAgent(updatedInfo: any): Promise<any> {
    const obj = {
      agentProfile: {
        agent: updatedInfo
      }
    };

    return this.http.post(this.config.SaveAgentInfoUrl, obj)
      .toPromise()
      .then(() => this.refreshProfile(true));
  }

  updateProfile(updatedInfo: any): Promise<any> {
    const obj = {
      memberProfile: {
        member: updatedInfo
      }
    };

    return this.http.post(this.config.SaveProfileInfoUrl, obj)
      .toPromise()
      .then(() => this.refreshProfile());
  }

  updateTravelDocument(updatedInfo: any, itemIndex: number): Promise<any> {
    const obj = {
      travelDocuments: {
        index: itemIndex,
        updateItem: updatedInfo
      }
    };

    if (itemIndex === -1) {
      return this.http.post(this.config.SaveTravelDocumentUrl, obj).toPromise()
        .then((travelDocs: any) => {
          return travelDocs;
        });
    }

    return this.http.put(this.config.SaveTravelDocumentUrl, obj).toPromise()
      .then((travelDocs: any) => {
        return travelDocs;
      });
  }

  deleteTravelDocument(itemIndex: number): Promise<any> {
    return this.http
      .delete(this.config.SaveTravelDocumentUrl, {
        params: {
          'travelDocuments.index': itemIndex + ''
        }
      })
      .toPromise()
      .then((travelDocs: any) => {
        return travelDocs;
      });
  }

  updateAddressBookItem(updateInfo: any, itemIndex: any) {
    const obj = {
      personAddresses: {
        updateItem: updateInfo,
        index: itemIndex
      }
    };

    if (itemIndex === -1) {
      return this.http.post(this.config.SaveAddressBookItemUrl, obj).toPromise()
        .then((data: any) => {
          return data;
        });
    }

    return this.http.put(this.config.SaveAddressBookItemUrl, obj).toPromise()
      .then((data: any) => {
        return data;
      });
  }


  deleteAddressBookItem(itemIndex: number): Promise<any> {
    return this.http
      .delete(this.config.SaveAddressBookItemUrl, {
        params: {
          'personAddresses.Index': itemIndex + ''
        }
      })
      .toPromise()
      .then((data: any) => {
        return data;
      });
  }

  updateSeatPreferences(updateInfo: any): Promise<any> {
    const obj = {
      memberProfile: {
        member: {
          person: {
            locationPreference: {
              propertyCode: updateInfo.seatLocation
            },
            typePreference: {
              propertyTypeCode: updateInfo.seatType
            }
          }
        }
      }
    };

    return this.http.post(this.config.SaveSeatPreferencesUrl, obj)
      .toPromise()
      .then(() => this.refreshProfile());
  }
}
