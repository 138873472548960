import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/core/profile.service';
import { BookingStepsService, CheckinSteps } from 'src/app/core/booking-steps.service';

@Component({
  selector: 'profile-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(profileService: ProfileService, router: Router, bookingSteps: BookingStepsService) {
    bookingSteps.currentStep.next(CheckinSteps.search);

    profileService.logout()
      .then(() => profileService.refreshProfile(true))
      .then(() => router.navigate(['agent', 'authenticate']));
  }

  ngOnInit() {
  }

}
