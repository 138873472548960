<div class="ptw-flight-selection-box">
  <div class="itineraryBox_wrapper" *ngIf="items.length">
    <div class="itineraryBox_title">
      <h2>
        {{ 'select-flights-title'|translate:'push-to-wallet' }}

        <div *ngIf="isB2BBooking" class="tooltip">
          <span class="ssr-icon font-ico-info" role="presentation"></span>
          <span class="tooltiptext">{{"This is the amount charged by Blue Air. Please contact your travel agency to request the refund of additional charges, if any." | translate: 'push-to-wallet'}}</span>
        </div>

      </h2>

    </div>
    <div>
      <div *ngFor="let journey of items; let i = index" class="flightSummary_wrapper">
        <div class="flightSummary_flightInfo">
          <div class="flightSummary_originDestination">
            <div class="change-flight">
              <input type="checkbox" id="j_{{i}}" [disabled]="!journey.enableRefund" [checked]="journey.isSelected"
                (change)="changeSelection(journey)" *ngIf="checkHours(journey.departureDate)" />
              <label for="j_{{i}}" [ngClass]="{canceled: !journey.enableRefund}"
                [ngStyle]="{'cursor': checkHours(journey.departureDate) ? 'pointer' : 'default'}">
              </label>

              <span class="flightSummary_origin withCheckbox">
                {{ journey.departureStation|translate:'station.name' }}
              </span>

              <span>
                —
              </span>

              <span class="flightSummary_destination">
                {{journey.arrivalStation| translate:'station.name'}}
              </span>
            </div>
            <div class="flightSummary_date">
              {{journey.departureDate| customDate:'longfulldateyear'}} {{journey.departureDate| customDate:'time'}}
            </div>
            <div class="error-txt" [style.display]="'block'" *ngIf="journey.isNotEligibleForRefund">
              {{ 'This journey segment is not eligible for refund' | translate:'push-to-wallet' }}
            </div>
          </div>
        </div>
        <div class="flightSummary_flightDetails">
          <div class="amount" *ngIf="!showNonStandardAmount">
            {{ journey.amount | currencyDisplay }}
            <span class="bonusAmount" *ngIf="journey.bonusAmount > 0">
              {{ 'Blue Bonus' | translate: 'push-to-wallet' }} {{ journey.bonusAmount | currencyDisplay }}
            </span>
          </div>
          <div class="amount" *ngIf="showNonStandardAmount">
            {{ journey.nonStandardAmount | currencyDisplay }}
            <span class="bonusAmount" *ngIf="journey.bonusNonStandardAmount > 0">
              {{ 'Blue Bonus' | translate: 'push-to-wallet' }} {{ journey.bonusNonStandardAmount | currencyDisplay }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <span class="error-txt" [style.display]="'block'" *ngIf="invalidSelection">
      {{ 'Please select at least 1 flight' | translate: 'push-to-wallet' }}
    </span>

    <span class="error-txt" [style.display]="'block'" *ngFor="let error of displayedErrors"
      [attr.data-error-id]="error">
      {{ 'Error-' + error | translate: 'push-to-wallet' }}
    </span>

    <div class="total-amount" *ngIf="items.length">
      <div class="amount">
        {{ 'Total amount:' | translate: 'refund-request' }} {{ totalSelectedAmount | currencyDisplay }}
      </div>

      <button type="button" class="btn btn-primary" attr.aria-label="{{ 'Click to select and continue' | translate }}"
        (click)="submit()">
        {{ 'Continue' | translate: 'push-to-wallet' }}
      </button>
    </div>
  </div>
</div>
