import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { BookingStepsService } from '../core/booking-steps.service';
import { FlowManagerService } from '../core/flow-manager.service';

@Injectable()
export class CanActivateSeatMapOnBookingService extends BaseCanActivateService implements CanActivate {

  constructor(flowManager: FlowManagerService, steps: BookingStepsService) {
    super(steps, flowManager.applicationFlowService);
  }
}
