/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blue-modal2.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./blue-modal2.component";
import * as i4 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
var styles_BlueModal2Component = [i0.styles];
var RenderType_BlueModal2Component = i1.ɵcrt({ encapsulation: 0, styles: styles_BlueModal2Component, data: {} });
export { RenderType_BlueModal2Component as RenderType_BlueModal2Component };
function View_BlueModal2Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "mfp-wrap mfp-align-top mfp-close-btn-in mfp-auto-cursor mfp-ready"], ["style", "overflow-x: hidden; overflow-y: auto;"], ["tabindex", "-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "mfp-container mfp-inline-holder full-bg-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "mfp-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [], [[8, "className", 0]], null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "mfp-close"], ["type", "button"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "popup-window ", _co.cssClass, ""); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.closeBtnLabel, ""); _ck(_v, 5, 0, currVal_1); }); }
export function View_BlueModal2Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueModal2Component_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isOpened; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BlueModal2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "blue-modal2", [], null, null, null, View_BlueModal2Component_0, RenderType_BlueModal2Component)), i1.ɵdid(1, 245760, null, 0, i3.BlueModal2Component, [i4.BlueModalService, i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BlueModal2ComponentNgFactory = i1.ɵccf("blue-modal2", i3.BlueModal2Component, View_BlueModal2Component_Host_0, { id: "id", closeBtnLabel: "closeBtnLabel", cssClass: "cssClass" }, {}, ["*"]);
export { BlueModal2ComponentNgFactory as BlueModal2ComponentNgFactory };
