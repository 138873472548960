import { Injectable } from '@angular/core';
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BookingStepsService } from '../core/booking-steps.service';
import { CheckinService } from '../core/checkin.service';
import { SsrsService } from './ssrs.service';

@Injectable()
export class CanActivateExtrasService extends BaseCanActivateService {

    constructor(bookingStepsService: BookingStepsService, checkinService: CheckinService, private ssrsService: SsrsService) {
        super(bookingStepsService, checkinService);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        const superPromise = super.canActivate(route, state) as Promise<boolean>;

        return superPromise.then(superResponse => {
            if (!superResponse) {
                return false;
            }

            return Promise.all([
                this.applicationFlowService.loadPriceBreakdown(),
                this.ssrsService.getSsrsInfo().toPromise()
            ])
                .then(data => true);
        });
    }
}
