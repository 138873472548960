import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingChangesComponent } from './booking-changes/booking-changes.component';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { BookingRoutes } from './booking-changes-routes';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { SharedModule } from '../shared/shared.module';
import { CanActivateBookingChangesService } from './can-activate-booking-changes.service';

@NgModule({
  imports: [
    CommonModule,
    BlueAirCommonModule,
    SharedModule,
    // RouterModule.forRoot(
    //   environment.flow === 0 ? [] : BookingRoutes
    // )
  ],
  declarations: [BookingChangesComponent],
  providers: [CanActivateBookingChangesService]
})
export class BookingChangesModule { }
