export class CurrentBundleCode {
    journeyIndex: number;
    bundleCode: string;

    constructor(currentBundleCode, journeyIndex) {
        this.journeyIndex = journeyIndex;
        this.bundleCode = currentBundleCode.value;
    }
}

export class JourneyBundle {
    journeyIndex: number;
    bundleList: Bundle[] = [];

    constructor(journeyBundle) {
        this.journeyIndex = journeyBundle.journeyIndex;
        journeyBundle.items.forEach(bundle => {
            this.bundleList.push(new Bundle(bundle));
        });
        this.bundleList.sort((a,b) => 0 - (a.price > b.price ? -1 : 1));
    }
}

export class Bundle {
    price: number;
    serviceBundleCode: string;
    serviceBundleType: string;
    serviceBundleSsrs: ServiceBundleSsr[] = [];
    sellKey: string;
    isSelected: boolean;

    constructor(bundle) {
        this.price = bundle.serviceBundlePriceList[0].price;
        this.serviceBundleCode = bundle.serviceBundleCode;
        this.serviceBundleType = bundle.serviceBundleType;
        
        bundle.serviceBundlePriceList[0].serviceBundleSsrPriceList.forEach(bundleSsr => {
            this.serviceBundleSsrs.push(new ServiceBundleSsr(bundleSsr));
        });
    }
}

export class ServiceBundleSsr {
    price: number;
    ssrCode: string;
    serviceBundleSsrType: string;
    taxTotal: number;

    constructor(serviceBundleSsr) {
        this.price = serviceBundleSsr.price;
        this.ssrCode = serviceBundleSsr.ssrCode;
        this.serviceBundleSsrType = serviceBundleSsr.serviceBundleSsrType;
        this.taxTotal = serviceBundleSsr.taxTotal;
    }
}

// export enum ServiceBundleTypeEnum {
//     AddOn = 'AddOn',
//     Included = 'Included'
// }