import { Component, OnInit, ViewChild } from '@angular/core';
import { DefaultModalComponent } from '../default-modal/default-modal.component';
import { CheckinSteps, BookingStepsService } from '../../core/booking-steps.service';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/core/profile.service';
import { SessionService } from 'src/app/common-modules/blue-air-common/session.service';
import { ECommerceService } from '../e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../e-commerce/models/e-commerce-cart-item.model';

@Component({
  selector: 'check-in-selections-clear-warning-modal',
  templateUrl: './selections-clear-warning-modal.component.html',
  styleUrls: ['./selections-clear-warning-modal.component.scss']
})
export class SelectionsClearWarningModalComponent implements OnInit {
  private modalAccepted = false;

  @ViewChild('changeFlightsWarningModal', { static: true }) modal: DefaultModalComponent;
  constructor(private steps: BookingStepsService, private ecommerce: ECommerceService, private router: Router,
    private profileService: ProfileService, private sessionService: SessionService) { }


  ngOnInit() {
  }

  shouldShowWarning(currentStep: CheckinSteps, nextStep: CheckinSteps): boolean {
    const sessionNotification = this.sessionService.sessionNotification.getValue();
    if (sessionNotification && sessionNotification.isExpired) {
      return false;
    }
    return this.steps.isBefore(nextStep, currentStep) && this.steps.isSameOrBefore(nextStep, CheckinSteps.flights);
  }

  showWarningAndNavigate(currentStep: CheckinSteps, nextStep: CheckinSteps, url: string = null): boolean {
    if (this.modalAccepted) {
      return true;
    }

    this.modal.openPopup((isOk) => {
      this.modalAccepted = isOk;
      if (isOk) {
        if (nextStep != null) {
          if (nextStep === CheckinSteps.flights) {
            window.location.href = "/";
          }
          this.steps.goToStep(nextStep);
          const ecommerceCartItems = new Array();
          const item = new ECommerceCartItem('Cart is empty', '2',
            '0', '0', '0', '0', 0, null, null, this.ecommerce.getUserData()).getItem(false);
          ecommerceCartItems.push(item);

          this.ecommerce.RemoveFromCart(ecommerceCartItems);
        } else {
          this.router.navigate([url]);
        }
      }
    });
    return false;
  }

}
