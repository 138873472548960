import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseCanActivateService } from '../core/base-can-activate.service';
import { CheckinService } from '../core/checkin.service';
import { CheckinSteps, BookingStepsService } from '../core/booking-steps.service';

@Injectable()
export class CanActivateFlights implements CanActivate {

constructor(private http: HttpClient, private checkinService: CheckinService, private steps: BookingStepsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkinService.loadCheckInInfo(true).then(() => {
      if (this.checkinService.checkinInfo.criteria &&
        this.checkinService.checkinInfo.criteria.length) {
        return true;
      }

      this.steps.goToStep(CheckinSteps.search);
      return false;
    });
  }
}
