<check-in-form-wrapper ariaLabel="{{ 'Setting up your search' | translate:'deeplink' }}" [step]="'step-1'" [showPriceDisplay]="false" [showContinue]="false">
  <div noNgForm class="form-wrapper-top-body">
    <div style="height: 300px">
      <div *ngIf="!invalidSearch">
        {{"Please wait while we are setting up your search"|translate:"deeplink"}}
      </div>
      <div *ngIf="invalidSearch">
        <p>
          {{"Your search is incomplete or invalid." | translate:"deeplink" }}
        </p>
        <p>
          {{"You will be redirected to the home page in {0} seconds" | translate:"deeplink":timer}}
        </p>
      </div>
    </div>
  </div>
</check-in-form-wrapper>