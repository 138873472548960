import { Component, OnInit, Input } from '@angular/core';

import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';




@Component({
    selector: 'seat-selected-notification',
    templateUrl: './seat-selected-notification.component.html',
    styleUrls: ['./seat-selected-notification.component.scss']
  })
  export class SeatSelectedNotification  implements OnInit{
    modalId:'seatSelecModal';
    isModalOpened:boolean;
    isOpened:boolean;
    okSelected:boolean;
    /**
     *
     */
    constructor(   private modalService: BlueModalService) {
       
        
    }


    ngOnInit() {
    }

    openPopup(callback) {
        this.isOpened = true;
        this.modalService.open(this.modalId, () =>{ 
                    this.isOpened = false;
                    if(callback != null && typeof callback ==="function")
                        callback(this.okSelected)
                });
    }

    ok(){
      this.okSelected = true;
      this.modalService.close(this.modalId);
    }
    
    cancel(){
      this.okSelected = false;
      this.modalService.close(this.modalId);
    }
  }