export const environment = {
  production: true,
  collectTranslations: false,
  configPath: '/config/config.json',
  assetsPath: '/assets/',
  imagesPath: 'assets/img/content/',
  usePaymentPortal: true,
  agencyPortal: true,
  routePrefix: {
    checkin: '',
    booking: ''
  },
  flow: 1

};