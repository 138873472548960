import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { BookingStatusEnum } from '../../booking-status.enum';
import { BookingStepsService, CheckinSteps } from '../../../core/booking-steps.service';
import { BookingFlowService } from '../../../core/booking-flow.service';
import { ConfigService } from '../../../core/config.service';
import { BookingService } from '../../../core/booking.service';
import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { BookingData } from '../filter-bookings/filter-bookings.component';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'check-in-display-bookings',
  templateUrl: './display-bookings.component.html',
  styleUrls: ['./display-bookings.component.scss']
})
export class DisplayBookingsComponent implements OnInit {

  public spinnerId: string = "retrieveMyBookingSpinner";
  public bookingStatusEnum = BookingStatusEnum;
  // public corporateRootUrl: string;
  public pastBookings: boolean = false;

  public allBookings: BookingData[];
  public filteredBookingData: BookingData[];

  public bookingDataSubscriber: BehaviorSubject<BookingData[]> = new BehaviorSubject<BookingData[]>([]);

  @Input() set bookingData(value: BookingData[]) {
    this.allBookings = value;
    this.bookingDataSubscriber.next(value);
  }


  constructor(private bookingService: BookingService, private configService: ConfigService,
    private bookingFlowService: BookingFlowService, private bookingStepsService: BookingStepsService,
    private loadingSpinnerService: LoadingSpinnerService) {
    // this.corporateRootUrl = this.configService.config.corporateUrl;
  }

  ngOnInit() {
    this.bookingDataSubscriber.subscribe(data => {
      this.filterPastBookings(data);
    });
  }

  ngOnChanges() {
    this.togglePastBookings();
  }

  public retrieveBooking(recordLocator: string, lastName: string, redirectToItinerary: boolean, event?: ElementRef) {
    // this.bookingService.clearSession();

    this.loadingSpinnerService.showSpinnerById(this.spinnerId);

    this.bookingService.retrieveBooking(recordLocator, lastName, '', this.configService.BlueRetrieve)
      .then(response => {
        if (response) {
          if (redirectToItinerary) {
            this.bookingFlowService.loadFlowInfo(true)
              .then(response2 => {
                if (response2) {
                  this.bookingService.recordLocator = recordLocator;
                  this.bookingStepsService.goToStep(CheckinSteps.itinerary);
                  this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
                }
              }
              );
          }
          else {
            (event as any).srcElement.hidden = true;
            let currentBooking = this.allBookings.find(booking => booking.RecordLocator === recordLocator);
            currentBooking.setBookingData((response as any).bookingContacts.items[0].emailAddress,
              (response as any).starterPriceBreakdown.balanceDue,
              (response as any).starterPriceBreakdown.grandTotalPrice,
              (response as any).bookingDetail.bookingDate,
              (response as any).blueRetrieveBooking.bookingStatus);
            this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
          }
        }
      }
      );
  }

  public payBooking(recordLocator: string, lastName: string) {
    this.loadingSpinnerService.showSpinnerById(this.spinnerId);

    this.bookingService.retrieveBooking(recordLocator, lastName, '', this.configService.BlueRetrieve)
      .then(response => {
        if (response) {
          this.bookingFlowService.loadFlowInfo(true)
            .then(response2 => {
              if (response2) {
                this.bookingService.recordLocator = recordLocator;
                this.bookingStepsService.goToStep(CheckinSteps.summary);
                this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
              }
            }
            );
        }
      }
      );
  }

  public checkDepartureDate(departureDate: Date): boolean {
    let temp = new Date(1900, 0);
    const momentDate = moment(temp);
    const departureDateMoment = moment(departureDate);

    return momentDate.isBefore(departureDateMoment) ? true : false;
  }

  public togglePastBookings() {
    if (this.pastBookings) {
      this.filteredBookingData = this.allBookings;
    } else {
      this.filterPastBookings(this.allBookings);
    }
  }

  public filterPastBookings(data: BookingData[]) {
    this.filteredBookingData = [];

    data.forEach(b => {
      var t = moment(b.FlightDate);

      if (t.isAfter(moment())) {
        this.filteredBookingData.push(b);
      }
    })
  }
}
