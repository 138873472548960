import { Constants } from '../../constants';
import { Component, OnInit, HostListener, Renderer2, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CheckinSteps, BookingStepsService, ApplicationStepInfo, ApplicationFlowEnum } from '../booking-steps.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { BookingService } from '../booking.service';
import { Subscription } from 'rxjs';
import { ApplicationFlowService } from '../application-flow.service';
import { FlowManagerService } from '../flow-manager.service';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'blueair-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('shouldApplyUserPromotionModal', { static: true }) shouldApplyUserPromotionModal: DefaultModalComponent;
  private navigationSubscription: Subscription;
  private manageBookingSubscription: Subscription;
  private userStateSubscription: Subscription;

  CheckinStepsEnum = CheckinSteps;
  url: string;
  imagesPath: string;
  navigationSteps: any[];
  routes: ApplicationStepInfo[];
  private applicationFlowService: ApplicationFlowService;

  stepsHeight = 49;

  showNavigation = false;

  canGoToPreviousStep: boolean;
  canGoToNextStep: boolean;

  isCheckinFlow: boolean;

  mobileMatches: boolean;

  // to be set by check user eligibility in future releases
  rainbowPassId: string;
  // @HostListener('window:resize', ['$event'])
  // onResize(event) {
  //   this.updateStepsHeight();
  // }

  constructor(private router: Router, flowManager: FlowManagerService,
    private location: Location, private bookingSteps: BookingStepsService,
    private translateService: TranslateService, public bookingService: BookingService,
    private render: Renderer2, private el: ElementRef, private ecommerce: ECommerceService,
    private userStateService: UserStateService) {
    this.imagesPath = environment.imagesPath;
    this.isCheckinFlow = environment.flow === 0;
    this.applicationFlowService = flowManager.applicationFlowService;
    this.bookingSteps.routes.subscribe(routes => {
      this.routes = routes;
      this.updateNavigationStepsInfo(this.bookingSteps.currentStep.getValue());
    });
    this.bookingSteps.currentStep.subscribe(currentStep => this.updateNavigationStepsInfo(currentStep));

    this.userStateSubscription = this.userStateService.isLoggedIn.subscribe(isLoggedIn => {
      const currentStep = this.bookingSteps.currentStep.getValue();
      if (isLoggedIn && this.bookingSteps.flow === ApplicationFlowEnum.Booking
        && (currentStep === this.CheckinStepsEnum.extras || currentStep === this.CheckinStepsEnum.seat
          || currentStep === this.CheckinStepsEnum.passengers || currentStep === this.CheckinStepsEnum.flights)) {
        this.bookingService.checkUserEligibilityForPromotion().then(eligibility => {
          if (eligibility && eligibility.checkUserEligibilityAndUsePromotion
            && eligibility.checkUserEligibilityAndUsePromotion.isEligible) {
            this.shouldApplyUserPromotionModal.openPopup((response) => {
              this.bookingService.applyUserPromotionAfterLoggin(response ? response : false).then(() => {
                if (response) {
                  window.location.reload();
                } else if (currentStep === this.CheckinStepsEnum.flights) {
                  sessionStorage.setItem('usePromo', 'false');
                  window.location.reload();
                }
              });
            });
          } else if (eligibility.checkUserEligibilityAndUsePromotion.refreshShoppingCart) {
            flowManager.applicationFlowService.loadPriceBreakdown(true);
          }
        });
      }
    });
  }

  ngOnInit() {
    this.navigationSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const returnUrlEl = document.getElementById('returnUrl');
        if (returnUrlEl) {
          const fullPageUrl = this.location.prepareExternalUrl(event.url);
          this.render.setAttribute(returnUrlEl, 'value', fullPageUrl);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
    if (this.manageBookingSubscription) {
      this.manageBookingSubscription.unsubscribe();
    }
    if (this.userStateSubscription) {
      this.userStateSubscription.unsubscribe();
    }
  }

  goto(step: CheckinSteps) {
    if (this.bookingSteps.canNavigateToStep(step)) {
      this.bookingSteps.goToStep(step);
    }
  }

  gotoOtherStep(gotoNextStep: boolean) {
    const currentStep = this.bookingSteps.currentStep.getValue();
    const otherStep = gotoNextStep ?
      this.bookingSteps.getNextStep(currentStep) :
      this.bookingSteps.getPreviousStep(currentStep);

    if (gotoNextStep) {
      let ecommerceCartItems = [];
      this.applicationFlowService.loadPriceBreakdown().then(breakdown => {
        if (breakdown) {
          ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown);
          this.ecommerce.Checkout(ecommerceCartItems, this.bookingSteps.findStepIndex(currentStep), currentStep.toString());
        }
      });
    }

    if (this.bookingSteps.canNavigateToStep(otherStep)) {
      this.bookingSteps.goToStep(otherStep);
    }
  }

  protected updateNavigationStepsInfo(currentStep) {
    const currentRoute = this.routes.find(s => s.step === currentStep);
    this.showNavigation = currentRoute ? currentRoute.showNavigation : false;

    this.routes.forEach(item => {
      item.isActive = currentStep === item.step;
      item.isDisabled = this.bookingSteps.isBefore(currentStep, item.step);
    });

    this.canGoToPreviousStep = this.bookingSteps.canNavigateToStep(this.bookingSteps.getPreviousStep(currentStep));
    this.canGoToNextStep = this.bookingSteps.canNavigateToStep(this.bookingSteps.getNextStep(currentStep));

    window.scrollTo(0, 0);
  }

  private updateStepsHeight() {
    let maxHeight = 0;
    const elements = (this.el.nativeElement).querySelectorAll('.checkin-steps-wrap li');
    for (let i = 0; i < elements.length; i++) {
      const el = elements.item(i);
      if (el.offsetHeight > maxHeight) {
        maxHeight = el.offsetHeight;
      }
    }

    this.stepsHeight = maxHeight;
  }
}
