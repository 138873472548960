import { Component, OnInit, Input } from '@angular/core';
import { PassengerModel } from '../../core/models/passenger-model';

@Component({
  selector: 'check-in-passenger-name-display',
  templateUrl: './passenger-name-display.component.html',
  styleUrls: ['./passenger-name-display.component.scss']
})
export class PassengerNameDisplayComponent implements OnInit {

  @Input() passenger: PassengerModel;
  @Input() cssClass: string;

  constructor() { }

  ngOnInit() {
  }

}
