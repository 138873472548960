import { FlightModel } from './../../core/models/flight-model';
import { BookingSelectionService } from './../../core/booking-selection.service';
import { BookingService } from './../../core/booking.service';
import { EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { Component, OnInit, ViewChildren, QueryList, Input, Output } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { SsrsService } from '../ssrs.service';
import { Subscription } from 'rxjs';
import { DefaultMealTime } from '../default-meal-time.enum';
import { ConfigService } from 'src/app/core/config.service';

@Component({
  selector: 'check-in-validation-modal',
  templateUrl: './validation-modal.component.html',
  styleUrls: ['./validation-modal.component.scss']
})
export class ValidationModalComponent implements OnInit, OnChanges, OnDestroy {
  private static readonly ssrTypesToBeValidated: SsrType[] = [SsrType.Meal, SsrType.Baggage,
  SsrType.Pet, SsrType.FastTrack, SsrType.Lounge, SsrType.PriorityBoarding,
  SsrType.SpecialEquipment];
  private bookingSubscription: Subscription;
  private flightsSubscription: Subscription;

  SsrTypeEnum = SsrType;
  booking: any;
  freeMealModalId = 'freeMealModal';
  pushSsrModalId: string;
  defaultMeals: DefaultMealForSegment[] = [];
  allMealsSSRs: any;
  showSingleSegmentInfo: boolean;
  currentSsrType: string;
  includesPushSsr: boolean;

  @Input() ssrType: SsrType;
  @Output() validationAccepted: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChildren(DefaultModalComponent) modalComponents: QueryList<DefaultModalComponent>;
  constructor(private bookingService: BookingService, private ssrsService: SsrsService,
    private selectionService: BookingSelectionService, private configService: ConfigService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ssrType && ValidationModalComponent.ssrTypesToBeValidated.indexOf(this.ssrType) >= 0) {
      this.includesPushSsr = (this.configService.config.pushSsr || []).some(p => p.ssrType === changes.ssrType.currentValue);
      this.pushSsrModalId = 'pushSsrModal' + changes.ssrType.currentValue.toString();
      this.initData();
    }
  }

  ngOnDestroy() {
    if (this.bookingSubscription) {
      this.bookingSubscription.unsubscribe();
    }
    if (this.flightsSubscription) {
      this.flightsSubscription.unsubscribe();
    }
  }

  private initData() {
    if (!this.bookingSubscription) {
      this.bookingSubscription = this.bookingService.bookingObs.subscribe(data => {
        if (data) {
          this.booking = data;
        }
      });
    }
  }

  private hasFreeMeals(): boolean {
    const meals = this.ssrsService.availableSsrs.find(lists => lists.key === this.ssrType).value;

    this.allMealsSSRs = meals
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.value)
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.ssrCode);

    this.defaultMeals = [];
    this.flightsSubscription = this.selectionService.flightsObs.subscribe(flights => {
      if (flights) {
        flights.forEach(flight => {
          flight.segments.forEach(segment => {
            const departureHour = segment.departureStation.date.getHours();
            const availableMeals = meals.find(s => s.key === segment.departureStation.iataCode.concat('_')
              .concat(segment.arrivalStation.iataCode));
            let boughtSsrs = [];

            this.booking.passengers.items.forEach(passenger => {
              boughtSsrs = boughtSsrs.concat(segment.paidSsrs[passenger.passengerNumber].concat(segment.ssrs[passenger.passengerNumber]));
            });

            // if free meal available on current segment &&
            // not all passengers have added the free meal
            if (availableMeals && availableMeals.value.find(meal => meal.price === 0) &&
              boughtSsrs.filter(x => this.allMealsSSRs.includes(x)).length !== this.booking.passengers.items.length) {
              const defaultFor = departureHour > DefaultMealTime.BREAKFAST && departureHour < DefaultMealTime.LUNCH
                ? DefaultMealTime[DefaultMealTime.BREAKFAST] : DefaultMealTime[DefaultMealTime.LUNCH];

              // add segment and corresponding default meal in order to be shown in modal text
              const availableMeal = availableMeals.value.find(meal => meal.defaultFor === defaultFor);
              if (availableMeal) {
                const defaultMeal = new DefaultMealForSegment();
                defaultMeal.Meal = availableMeal;
                defaultMeal.Segment = segment;
                this.defaultMeals.push(defaultMeal);
              }
            }
          });
        });
      }
    });
    this.showSingleSegmentInfo = this.defaultMeals.every(def => def.Meal.ssrCode === this.defaultMeals[0].Meal.ssrCode);
    return this.defaultMeals.length > 0 ? true : false;
  }

  private boughtPushSsr(): boolean {
    let bought = false;
    const ssrs = this.ssrsService.availableSsrs.find(lists => lists.key === this.ssrType).value;
    const allPushSSRs = ssrs
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.value)
      .reduce((a, b) => a.concat(b), [])
      .map(x => x.ssrCode);
    this.flightsSubscription = this.selectionService.flightsObs.subscribe(flights => {
      if (flights) {
        flights.forEach(flight => {
          flight.segments.forEach(segment => {
            let boughtSsrs = [];
            this.booking.passengers.items.forEach(passenger => {
              boughtSsrs = boughtSsrs.concat(segment.paidSsrs[passenger.passengerNumber].concat(segment.ssrs[passenger.passengerNumber]));
            });
            bought = bought || boughtSsrs.some(x => allPushSSRs.includes(x));
          });
        });
      }
    });
    return bought;
  }

  public openModal(callback: any): boolean {
    switch (this.ssrType) {
      case SsrType.Meal:
        if (this.hasFreeMeals()) {
          this._openModal(this.freeMealModalId, callback);
          return true;
        }
        break;
      // case SsrType.Baggage:
      // if(hasBaggge()){
      //   this.openMyModal(callback);
      //   return true;
      // }
    }
    if (this.includesPushSsr && !this.boughtPushSsr()) {
      this.currentSsrType = SsrType[this.ssrType];
      this._openModal(this.pushSsrModalId, callback);
      return true;
    }

    return false;
  }

  private _openModal(modalId: string, callback: any): any {
    const modal = this.modalComponents.find(x => x.modalId === modalId);
    modal.openPopup((sellDefaultSsr: boolean) => {
      this.validationAccepted.next(sellDefaultSsr);
      if (sellDefaultSsr && this.defaultMeals.length > 0 && this.ssrType === SsrType.Meal) {
        //this.ssrsService.toggleLoadingForSsrType(true, SsrType.Meal)
        this.defaultMeals.forEach(item => {
          this.booking.passengers.items.forEach(passenger => {
            const boughtSsrs = item.Segment.ssrs[passenger.passengerNumber].concat(item.Segment.paidSsrs[passenger.passengerNumber]);
            // check if current passenger has bought the free meal ssr
            if (!boughtSsrs.find(x => this.allMealsSSRs.includes(x))) {
              this.ssrsService.addSsr(SsrType.Meal, item.Meal.ssrCode, passenger.passengerNumber, item.Segment);
            }
          });
        });
        // sell default meal ssr and reload ssr Meal box
        this.ssrsService.applySsrs(SsrType.Meal).then(result => {
          if (result) {
            this.ssrsService.getSsrsInfo();
            //.toPromise().then(data => {
            //if (data) {
            //this.ssrsService.toggleLoadingForSsrType(false, SsrType.Meal);
            //callback(sellDefaultSsr);
            //}
            //});
          }
        });
        //} else {
      }
      callback(sellDefaultSsr);
    });
  }
}

class DefaultMealForSegment {
  public Segment: FlightModel;
  public Meal: any;
}