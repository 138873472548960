import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../core/config.service';

@Injectable({
  providedIn: 'root'
})
export class VoucherRequestService {

  constructor(private http:HttpClient, private config: ConfigService) { }
  createRequest(pnr, message){
    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
    this.http.post(this.config.config.voucherRequestUrl + "?id=" + pnr + "&message=" + message, null,  { headers: headers }  ).toPromise();
  }
}
