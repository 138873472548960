var Cell = /** @class */ (function () {
    function Cell(isExtraLeg, price) {
        this.isExtraLeg = isExtraLeg;
        this.price = price;
    }
    return Cell;
}());
export { Cell };
var RowCells = /** @class */ (function () {
    function RowCells() {
        this.cells = [];
        this.groups = [];
        this.unitMap = new Map();
    }
    RowCells.prototype.addCell = function (cell, unit) {
        //if (!cell) return;
        this.cells.push(cell);
        if (!this.unitMap.has(cell)) {
            this.unitMap.set(cell, unit);
        }
    };
    RowCells.prototype.computeGroups = function () {
        this.groups = this.getGroups();
    };
    RowCells.prototype.getGroups = function () {
        var _this = this;
        var groups = [];
        var indexCell = -1;
        this.cells.reduce(function (group, currentCell) {
            indexCell++;
            if (!currentCell) {
                if (group && group.emptyGroup) {
                    group.cells.push(currentCell);
                    return group;
                }
                else {
                    return {
                        emptyGroup: true,
                        cells: [currentCell],
                        isExtraLeg: false,
                    };
                }
            }
            var currentCellPrice = currentCell ? '' + currentCell.price : null;
            if (group.groupName !== currentCellPrice) {
                if (indexCell === 3) { //this is the middle
                    groups.push({
                        emptyGroup: true,
                        isExtraLeg: false,
                    });
                }
                var currentGroup = {
                    groupName: currentCellPrice,
                    cells: [currentCell],
                    isExtraLeg: currentCell.isExtraLeg,
                    linkedCell: _this.unitMap.get(currentCell)
                };
                groups.push(currentGroup);
                return currentGroup;
            }
            else {
                if (indexCell === 3) {
                    group.containsMiddle = true;
                }
            }
            // }
            group.cells.push(currentCell);
            group.isExtraLeg = currentCell.isExtraLeg;
            group.linkedCell = _this.unitMap.get(currentCell);
            return group;
        }, new RowGroup());
        if (groups.length == 2
            && groups[0].cells.length == groups[1].cells.length) {
            groups.splice(1, 0, {
                emptyGroup: true,
                isExtraLeg: false
            });
        }
        return groups;
    };
    return RowCells;
}());
export { RowCells };
var RowGroup = /** @class */ (function () {
    function RowGroup() {
        this.cells = [];
        this.isExtraLeg = false;
        this.emptyGroup = false;
        this.cssDecorator = null;
        this.containsMiddle = false;
    }
    Object.defineProperty(RowGroup.prototype, "count", {
        get: function () {
            return this.cells.length;
        },
        enumerable: true,
        configurable: true
    });
    RowGroup.prototype.toString = function () {
        return this.groupName;
    };
    return RowGroup;
}());
export { RowGroup };
