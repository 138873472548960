/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-empty-warning.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "../../common-modules/blue-air-common/blue-modal/blue-modal.component.ngfactory";
import * as i5 from "../../common-modules/blue-air-common/services/blue-renderer.service";
import * as i6 from "../../common-modules/blue-air-common/blue-modal/blue-modal.component";
import * as i7 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i8 from "./session-empty-warning.component";
import * as i9 from "@angular/router";
var styles_SessionEmptyWarningComponent = [i0.styles];
var RenderType_SessionEmptyWarningComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionEmptyWarningComponent, data: {} });
export { RenderType_SessionEmptyWarningComponent as RenderType_SessionEmptyWarningComponent };
export function View_SessionEmptyWarningComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "empty-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "blue-modal", [["cssClass", "checked-in-summary-popup"]], null, null, null, i4.View_BlueModalComponent_0, i4.RenderType_BlueModalComponent)), i1.ɵprd(512, null, i5.BlueRenderer, i5.BlueRenderer, [i1.Renderer2]), i1.ɵdid(4, 245760, null, 0, i6.BlueModalComponent, [i7.BlueModalService, i1.ElementRef, i1.Renderer2, i5.BlueRenderer], { id: [0, "id"], cssClass: [1, "cssClass"], showCloseBtn: [2, "showCloseBtn"] }, null), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 2), (_l()(), i1.ɵeld(8, 0, null, 0, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, 0, 2, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startOver() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵppd(13, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalId; var currVal_1 = "checked-in-summary-popup"; var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "emptysession.header", "session-warning")); _ck(_v, 6, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), "emptysession.description", "session-warning")); _ck(_v, 9, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v, 0), "emptysession.buttontext", "session-warning")); _ck(_v, 12, 0, currVal_5); }); }
export function View_SessionEmptyWarningComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-session-empty-warning", [], null, null, null, View_SessionEmptyWarningComponent_0, RenderType_SessionEmptyWarningComponent)), i1.ɵdid(1, 4308992, null, 0, i8.SessionEmptyWarningComponent, [i7.BlueModalService, i3.TranslateService, i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionEmptyWarningComponentNgFactory = i1.ɵccf("check-in-session-empty-warning", i8.SessionEmptyWarningComponent, View_SessionEmptyWarningComponent_Host_0, {}, {}, []);
export { SessionEmptyWarningComponentNgFactory as SessionEmptyWarningComponentNgFactory };
