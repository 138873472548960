/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkin-accordion-item.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./checkin-accordion-item.component";
import * as i4 from "../../extras/ssrs.service";
var styles_CheckinAccordionItemComponent = [i0.styles];
var RenderType_CheckinAccordionItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CheckinAccordionItemComponent, data: {} });
export { RenderType_CheckinAccordionItemComponent as RenderType_CheckinAccordionItemComponent };
function View_CheckinAccordionItemComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "price-mobile"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.disabledText; _ck(_v, 1, 0, currVal_0); }); }
export function View_CheckinAccordionItemComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["aria-haspopup", "true"], ["class", "checkin-acc-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "button", [["class", "checkin-acc-head"], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleExpanded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "open": 0 }), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "price"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckinAccordionItemComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["aria-hidden", "true"], ["class", "checkin-acc-content"]], [[4, "display", null]], null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "checkin-acc-head"; var currVal_3 = _ck(_v, 4, 0, _co.isExpanded); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_7 = _co.disabled; _ck(_v, 11, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.ariaLabel, ""); var currVal_1 = _co.disabled; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _co.title; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.subtitle; _ck(_v, 7, 0, currVal_5); var currVal_6 = (_co.disabled ? _co.disabledText : _co.price); _ck(_v, 9, 0, currVal_6); var currVal_8 = (_co.isExpanded ? "block" : ""); _ck(_v, 12, 0, currVal_8); }); }
export function View_CheckinAccordionItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-accordion-item", [], null, null, null, View_CheckinAccordionItemComponent_0, RenderType_CheckinAccordionItemComponent)), i1.ɵdid(1, 638976, null, 0, i3.CheckinAccordionItemComponent, [i4.SsrsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CheckinAccordionItemComponentNgFactory = i1.ɵccf("check-in-accordion-item", i3.CheckinAccordionItemComponent, View_CheckinAccordionItemComponent_Host_0, { ariaLabel: "ariaLabel", title: "title", subtitle: "subtitle", price: "price", disabledText: "disabledText", disabled: "disabled", blockExpanded: "blockExpanded", isExpanded: "isExpanded" }, {}, ["*"]);
export { CheckinAccordionItemComponentNgFactory as CheckinAccordionItemComponentNgFactory };
