import { Injectable } from '@angular/core';
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import { BookingService } from '../core/booking.service';
import { BookingStepsService } from '../core/booking-steps.service';
import { SeatMapService } from './seat-map.service';
import { CheckinService } from '../core/checkin.service';

@Injectable()
export class CanActivateSeatMapService extends BaseCanActivateService {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean>| Promise<boolean> {
   // return super.canActivate(route,state); 

    const superPromise = super.canActivate(route, state) as Promise<boolean>;

    return superPromise.then(superResponse => {
        if (!superResponse) {
            return false;
        }

        
        return Promise.all([
            this.bookingService.refresh(),
            this.applicationFlowService.loadPriceBreakdown(),
            //snapshot
        ])
            .then(data => true);
    });


  }
constructor(public steps: BookingStepsService, public applicationFlowService: CheckinService,
    private bookingService: BookingService) {
    super(steps, applicationFlowService);
  }

}
