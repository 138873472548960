import { BookingFlightSelectService } from './../../booking-flight-select/booking-flight-select.service';
import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation, OnChanges, SimpleChanges, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationError } from '@angular/router';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../../constants';
import { environment } from '../../../environments/environment';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { Subscription } from 'rxjs';
import { BookingService } from '../../core/booking.service';

@Component({
  selector: 'check-in-form-wrapper',
  templateUrl: './form-wrapper.component.html',
  styleUrls: ['./form-wrapper.component.scss']
})
export class FormWrapperComponent implements OnInit, OnChanges, OnDestroy {
  private bookingStepSubscription: Subscription;
  private bookingServiceSubscription: Subscription;

  navigationSpinnerId: string;
  privateCanContinue: boolean = false;
  canContinue: boolean = true;
  showMobileContinueBtn: boolean;
  isBookingFlow: boolean;
  isPromotion: boolean;
  mobileMatches: boolean;
  isFlightsStep = false;
  isRecordLocator: boolean = true;
  showContainer: boolean = false;

  @Input() ariaLabel: string;
  @Input() step: string;
  @Input() showPriceDisplay = false;
  @Input() showContinue = false;
  @Output() continue: EventEmitter<any> = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWindowWidth(event.target.innerWidth);
  }

  constructor(private router: Router, private loadingSpinnerService: LoadingSpinnerService,
    private bookingStepsService: BookingStepsService, private bookingService: BookingService
    // ,bookingFlightSelectService: BookingFlightSelectService
    ) {

    this.navigationSpinnerId = Constants.mainSpinnerId;
    this.isBookingFlow = environment.flow === 1;
    // bookingFlightSelectService.isPromotionObs.subscribe(isPromo => {
    //   this.isPromotion = isPromo;
    // });
    this.bookingStepSubscription = this.bookingStepsService.currentStep.subscribe(currentStep => {
      this.isFlightsStep = currentStep === CheckinSteps.flights;
      if (this.isBookingFlow && currentStep !== CheckinSteps.itinerary && currentStep !== CheckinSteps.search && currentStep !== CheckinSteps.pushToWallet) {
        this.showContainer = true;
      }
    });
    this.updateWindowWidth(window.innerWidth);

    this.bookingServiceSubscription = this.bookingService.bookingObs.subscribe(result =>
      this.isRecordLocator = result && result.bookingDetail && result.bookingDetail.recordLocator
    );
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.loadingSpinnerService.showSpinnerById(this.navigationSpinnerId);
      } else if (event instanceof NavigationEnd || event instanceof NavigationError) {
        this.loadingSpinnerService.hideSpinnerById(this.navigationSpinnerId);
      }
    });
  }

  ngOnDestroy() {
    if (this.bookingStepSubscription) {
      this.bookingStepSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.step) {
      this.showMobileContinueBtn = this.getMobileContinueBtnVisibility(changes.step.currentValue);
    }
  }

  getMobileContinueBtnVisibility(currentStep): boolean {
    if (environment.flow === 1) {
      switch (currentStep) {
        case 'step-2':
        case 'step-3':
        case 'step-4':
        case 'step-5':
        case 'step-6':
        case 'step-9':
          return true;
        default:
          return false;
      }
    } else {
      switch (currentStep) {
        case 'step-4':
        case 'step-5':
        case 'step-6':
          return true;
        default:
          return false;
      }
    }
  }

  updateWindowWidth(width) {
    this.mobileMatches = width < Constants.mobileScreenWith;
  }
}
