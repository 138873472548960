import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingStepsService, CheckinSteps } from 'src/app/core/booking-steps.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { SsrType } from 'src/app/extras/ssr-type.enum';
import { SeatSelectedNotification } from 'src/app/shared/seat-selected-notification/seat-selected-notification.component';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { PassengerBundleComponent } from '../../shared/passenger-bundle/passenger-bundle.component';
import { ShoppingCartComponent } from '../../shared/shopping-cart-components/shopping-cart/shopping-cart.component'

@Component({
  selector: 'booking-bundle-select',
  templateUrl: './bundle-select.component.html',
  styleUrls: ['./bundle-select.component.scss']
})
export class BundleSelectComponent implements OnInit {

  @ViewChild(PassengerBundleComponent, { static: true }) passengerBundleComponent: PassengerBundleComponent;
  @ViewChild(ShoppingCartComponent, { static: true }) shoppingCartComponent: ShoppingCartComponent;
  @ViewChild(SeatSelectedNotification, { static: true }) pendingChangesModal: SeatSelectedNotification;

  private currentStep = CheckinSteps.bundles;
  _canDeactivate: boolean = false;
  pendingSsrsList: SsrType[] = [];

  constructor(private flowManager: FlowManagerService,
    private bookingSteps: BookingStepsService,
    private ecommerce: ECommerceService) { }

  ngOnInit() {
  }

  continue() {
    this.goToNextStep();
  }

  goToNextStep(nextStep?: CheckinSteps) {
    if (this.passengerBundleComponent.checkPendingBundle()) {
      this.pendingChangesModal.openPopup((isOk) => {
        if (isOk) {
          if (nextStep !== null && nextStep !== undefined) {
            this.checkoutEcommerce();
            this.bookingSteps.goToStep(nextStep);
          } else {
            this.checkoutEcommerce();
            this.bookingSteps.goToNextStepFrom(this.currentStep);
          }
        }
      });
    } else {
      if (nextStep !== null && nextStep !== undefined) {
        this.checkoutEcommerce();
        this.bookingSteps.goToStep(nextStep);
      } else {
        this.checkoutEcommerce();
        this.bookingSteps.goToNextStepFrom(this.currentStep);
      }
    }
  }

  checkoutEcommerce() {
    let ecommerceCartItems = [];
    this.flowManager.applicationFlowService.loadPriceBreakdown().then(breakdown => {
      if (breakdown) {
        ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown);
        this.ecommerce.Checkout(ecommerceCartItems, 3, 'Seats step');
      }
    });
  }
}
