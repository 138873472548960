import { environment } from '../../environments/environment';
import { of as observableOf } from 'rxjs';
import { retryWhen, delay, map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
var ConfigService = /** @class */ (function () {
    function ConfigService(http) {
        this.http = http;
        this.setConfigPath(environment.configPath);
        this.assetsPath = environment.assetsPath;
        // this.assetsPath = window['assetsPath'] || '/assets/';
        // this.configPath = window['configPath'] || '/config/';
        // delete window['assetsPath'];
        // delete window['configPath'];
    }
    Object.defineProperty(ConfigService.prototype, "RememberSearch", {
        // Razvan Paisa:  removed the functionality
        //get HubServiceUrl(): string { return this.config.hubServiceUrl; }
        get: function () { return this.config.apiUrl + 'api/RememberSearch'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "BookingFlightSearch", {
        get: function () { return this.config.apiUrl + 'api/InternalFlightSearch'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PriceItinerary", {
        get: function () { return this.config.apiUrl + 'api/BluePriceItinerary'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SellFlight", {
        get: function () { return this.config.apiUrl + 'api/Flight'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SessionUrl", {
        get: function () { return this.config.apiUrl + 'api/Session'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "FlightSearch", {
        get: function () { return this.config.apiUrl + 'api/FlightSearch'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "CancelRebook", {
        get: function () { return this.config.apiUrl + 'api/CancelRebook'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "Booking", {
        get: function () { return this.config.apiUrl + 'api/Booking'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "CheckinRetrieveUrl", {
        get: function () { return this.config.apiUrl + 'api/CheckinRetrieve'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "CheckinRetrieveRestrictionsUrl", {
        get: function () { return this.config.apiUrl + 'api/CheckinRetrieveRestrictions'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SeatMap", {
        get: function () { return this.config.apiUrl + 'api/seatmap'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "CheckinInput", {
        // get RetrieveByLastName():string {return this.config.apiUrl + 'api/RetrieveByLastName';}
        get: function () { return this.config.apiUrl + 'api/Checkin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "CheckinInfo", {
        get: function () { return this.config.apiUrl + 'api/CheckinInfo'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "CheckinPassengers", {
        get: function () { return this.config.apiUrl + 'api/CheckInPassengers'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SsrsInfoUrl", {
        // get SsrsInfoBytTypeUrl(): string { return this.config.apiUrl + 'api/SsrsByType'; }
        get: function () { return this.config.apiUrl + 'api/ssrs'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SsrsApplyUrl", {
        get: function () { return this.config.apiUrl + 'api/ssrs'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PriceBreakdown", {
        get: function () { return this.config.apiUrl + 'api/FullPriceBreakdown'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "BookingPriceBreakdown", {
        get: function () { return this.config.apiUrl + 'api/PriceBreakdown'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "ShoppingCartBreakdownUrl", {
        get: function () { return this.config.apiUrl + 'api/ShoppingCartBreakdown'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PaymentAccountsUrl", {
        get: function () { return this.config.apiUrl + 'api/paymentinfo'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PaymentsManagerUrl", {
        get: function () { return this.config.apiUrl + 'api/PaymentsManager'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "InvoicingInfoUrl", {
        get: function () { return this.config.apiUrl + 'api/Invoice'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PaymentMethodInfoUrl", {
        get: function () { return this.config.apiUrl + 'api/PaymentInfo'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PaymentBeginUrl", {
        get: function () { return this.config.corporateUrl + 'payments/begin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "LowFareUrl", {
        get: function () { return this.config.corporateUrl + 'api/LowestFare/DayByIata'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "FinalizeCheckinUrl", {
        get: function () { return this.config.apiUrl + 'api/CheckInFinalize'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "BookingSnapshot", {
        get: function () { return this.config.apiUrl + 'api/BookingSnapshot'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "BoardingPassUrl", {
        get: function () { return this.config.apiUrl + 'api/BoardingPass'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SendBoardingPassUrl", {
        get: function () { return this.config.apiUrl + 'api/SendBoardingPass'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PassengersUrl", {
        get: function () { return this.config.apiUrl + 'api/Passengers'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "ContactUrl", {
        get: function () { return this.config.apiUrl + 'api/Contact'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "LockFareDetails", {
        get: function () { return this.config.apiUrl + 'api/lockfaredetails'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PaymentPortalUrl", {
        get: function () { return this.config.paymentPortalUrl ? this.config.paymentPortalUrl + 'paymentbegin' : null; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "BundleDetails", {
        get: function () { return this.config.apiUrl + 'api/BundleUpgrade'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SuperStationsUrl", {
        get: function () { return this.config.apiUrl + 'customResource/SuperStationsWithCountries'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "InsuranceDetails", {
        get: function () { return this.config.apiUrl + 'api/Insurance'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SellFee", {
        get: function () { return this.config.apiUrl + 'api/Fee'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "ScheduleUrl", {
        get: function () { return this.config.corporateUrl + 'Schedule'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SsrChanges", {
        get: function () { return this.config.apiUrl + 'api/SsrChanges'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "BlueRetrieve", {
        get: function () { return this.config.apiUrl + 'api/BlueRetrieve'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "ChangeFlight", {
        get: function () { return this.config.apiUrl + 'api/ChangeFlight'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "ClearSession", {
        get: function () { return this.config.apiUrl + 'api/ClearSession'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "Itinerary", {
        get: function () { return this.config.apiUrl + 'api/Itinerary'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "Flights", {
        get: function () { return this.config.apiUrl + 'api/Flights'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "LockFareUrl", {
        get: function () { return this.config.apiUrl + 'api/LockFare'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "NewsletterSubscriptionUrl", {
        get: function () { return this.config.apiUrl + 'api/NewsletterSubscription'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "RetrieveAgentBookings", {
        get: function () { return this.config.apiUrl + 'api/RetrieveAgentBookings'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "AgentLoginUrl", {
        get: function () { return this.config.apiUrl + 'api/agentLogin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "MemberLoginUrl", {
        get: function () { return this.config.apiUrl + 'api/memberLogin'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "LogoutUrl", {
        get: function () { return this.config.apiUrl + 'api/memberLogout'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "ProfileUrl", {
        get: function () { return this.config.apiUrl + 'api/member'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "ResetPasswordUrl", {
        get: function () { return this.config.apiUrl + 'api/resetPassword'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SaveProfileInfoUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberInfo'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SaveAgentInfoUrl", {
        get: function () { return this.config.apiUrl + 'api/AgentInfo'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SaveTravelDocumentUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberTravelDocument'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SaveAddressBookItemUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberAddress'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SaveSeatPreferencesUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberSeatPreferences'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "CompanionsUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberCompanion'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SaveCompanionsTravelDocumentUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberCompanionTravelDocument'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "MemberBookingsUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberBookings'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "MemberDetails", {
        get: function () { return this.config.apiUrl + 'api/Member'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "CheckUserEligibilityAndUsePromotion", {
        get: function () { return this.config.apiUrl + 'api/CheckUserEligibilityAndUsePromotion'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "MemberPromotionActivationInfoUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberPromotionActivationInfo'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PromotionUrl", {
        get: function () { return this.config.apiUrl + 'api/MemberPromotions'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "MoveFlight", {
        get: function () { return this.config.apiUrl + 'api/MoveFlight'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "MoveFlightSearch", {
        get: function () { return this.config.apiUrl + 'api/InternalMoveFlightSearch'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "SellMovedFlight", {
        get: function () { return this.config.apiUrl + 'api/MoveFlightSell'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PushToWalletUrl", {
        get: function () { return this.config.apiUrl + 'api/PushToWallet'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PromoCodeUrl", {
        get: function () { return this.config.apiUrl + 'api/PromoCode'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "RefundCashUrl", {
        get: function () { return this.config.apiUrl + 'api/CustomerRefundCashRequest'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "PassengerConsentUrl", {
        get: function () { return this.config.apiUrl + 'api/PassengerConsent'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "UnsubscribeBookingFromNotificationsUrl", {
        get: function () { return this.config.flyBlueAirApiUrl + 'api/BookingOperations/v1/unsubscribeFromNotifications'; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ConfigService.prototype, "AddBookingCommentUrl", {
        get: function () { return this.config.flyBlueAirApiUrl + 'api/BookingOperations/v1/addBookingComment'; },
        enumerable: true,
        configurable: true
    });
    ConfigService.prototype.load = function () {
        var _this = this;
        return this.tryGetConfig(this.configPath, 3)
            .toPromise()
            .then(function (config) { return _this.config = config; });
    };
    ConfigService.prototype.setConfigPath = function (configPath) {
        if (configPath) {
            this.configPath = configPath + '?rt=' + (new Date()).getTime();
        }
    };
    ConfigService.prototype.tryGetConfig = function (configPath, retryCount) {
        var headers = new HttpHeaders().set('X-Skip-Interceptor', '');
        return this.http
            .get(configPath, { headers: headers }).pipe(retryWhen(function (error) {
            return error.pipe(delay(500), map(function (err) {
                if (--retryCount < 0) {
                    throw err;
                }
                return err;
            }));
        }), catchError(function (err) { return observableOf({
            'apiUrl': 'https://stage-webapi.aeroitalia.com/',
            'resourcesUrl': 'https://stage-webapi.aeroitalia.com/',
            'corporateUrl': 'https://www.blueairweb.com/',
            // tslint:disable-next-line: max-line-length
            'translationsUrl': 'config/translations/{lang}/translations.json',
            'printUrl': '',
            'paymentPortalUrl': 'https://stage-paymenthub.aeroitalia.com/',
        }); }));
    };
    return ConfigService;
}());
export { ConfigService };
