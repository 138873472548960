import { ShoppingCartFeeModel } from './shopping-cart-fee-model';
var ShoppingCartPassengerModel = /** @class */ (function () {
    function ShoppingCartPassengerModel(passenger) {
        if (!passenger) {
            this.fees = [];
            return;
        }
        this.passengerType = passenger.passengerType;
        this.count = passenger.count;
        this.amount = passenger.amount;
        this.fees = passenger.fees;
        this.discountCode = passenger.discountCode;
    }
    Object.defineProperty(ShoppingCartPassengerModel.prototype, "amount", {
        get: function () { return this._amount; },
        set: function (value) { this._amount = Math.max(value, 0); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartPassengerModel.prototype, "paxFareKey", {
        get: function () { return !this.discountCode ? this.passengerType : this.passengerType + "_" + this.discountCode; },
        enumerable: true,
        configurable: true
    });
    ShoppingCartPassengerModel.prototype.init = function (passenger) {
        this.passengerType = passenger.passengerType !== 'INFT' ? passenger.passengerType : 'INF';
        this.count = passenger.count;
        this.amount = passenger.amount;
        this.fees = passenger.fees.map(function (fee) { return new ShoppingCartFeeModel().init(fee); });
        return this;
    };
    return ShoppingCartPassengerModel;
}());
export { ShoppingCartPassengerModel };
