import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SessionService } from '../session.service';

@Injectable()
export class SessionInUrlGuard implements CanActivate {

  constructor(private sessionService: SessionService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const sessionId = route.paramMap.get('sessionId');
    let path = '/';

    if (sessionId) {
      return this.sessionService.set(sessionId).then(() => {
        const paramPath = route.queryParams.path;

        const myParams = Object.assign({}, route.queryParams);
        delete myParams.path;
        if (paramPath) {
          path = paramPath;
        }
        this.router.navigate([path], { queryParams: myParams });

        return false;
      });
    }
    this.router.navigate([path]);

    return false;
  }
}
