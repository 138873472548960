import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProfileInfoBox } from '../../profile-info-box';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ProfileService } from 'src/app/core/profile.service';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { ConfigService } from 'src/app/core/config.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DateRange } from 'src/app/common-modules/blue-air-common/date-range.model';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';

@Component({
  selector: 'profile-agent-contact-details',
  templateUrl: './agent-contact-details.component.html',
  styleUrls: ['./agent-contact-details.component.scss']
})
export class AgentContactDetailsComponent extends ProfileInfoBox implements OnInit, OnDestroy {
  public phonePattern = '^[0-9+()\/-][0-9+() \/-]{0,19}?$';
  public namePattern = '^[A-Za-z _]*[A-Za-z][A-Za-z _]*$';
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
  
  private profileSubscription: Subscription;

  editModalId = 'contact-infos';
  editSpinnerId = 'contact-infos';
  route: string;

  contactInfos: any = {};
  countries: any[];
  model: any = {};

  languages: any[];
  currencies: any[];

  titles: any[];
  datePickerRange: DateRange;

  errors: string[] = [];

  constructor(modalService: BlueModalService, private profileService: ProfileService, private resourceService: ResourceService,
    private spinnerService: LoadingSpinnerService, private configService: ConfigService, private translate: TranslateService) {
    super(modalService);

    this.datePickerRange = new DateRange(null);
    this.datePickerRange.generateYearsForAge(18, 150);
    
    this.initCurrencies();
    this.initLanguages();

    this.resourceService.getPassengerTitles().pipe(take(1)).subscribe(t => this.titles = t);
    
    this.route = this.configService.SaveProfileInfoUrl;

    this.profileSubscription = profileService.profile.subscribe((p: any) => {
      if (!p) {
        return;
      }

      this.contactInfos.title = p.name.title;
      this.contactInfos.titleName = this.findTextByValue(this.titles, p.name.title);
      this.contactInfos.firstName = p.name.first;
      this.contactInfos.lastName = p.name.last;
      this.contactInfos.gender = p.gender;
      this.contactInfos.dateOfBirth = p.dateOfBirth;
      this.contactInfos.emailAddress = this.getDefaultItemValue(p.person.emailAddresses, 'emailAddress');
      // this.contactInfos.phoneNumber = this.getDefaultItemValue(p.person.phoneNumbers, 'number');
      this.contactInfos.residentCountry = p.residentCountry;
      this.contactInfos.nationality = p.nationality;

      this.contactInfos.workPhoneNumber = p.workPhoneNumber.number;
      this.contactInfos.homePhoneNumber = p.homePhoneNumber.number;
      this.contactInfos.mobilePhoneNumber = p.mobilePhoneNumber .number;
      this.contactInfos.faxPhoneNumber = p.faxPhoneNumber.number;

      this.contactInfos.cultureCode = p.cultureCode;
      this.contactInfos.cultureName = this.findTextByValue(this.languages, p.cultureCode);
      this.contactInfos.currencyCode = p.currencyCode;
      this.contactInfos.currencyName = this.findTextByValue(this.currencies, p.currencyCode);
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.profileSubscription) {
      this.profileSubscription.unsubscribe();
    }
  }
  edit() {

    
    this.model = {
      title: this.contactInfos.title,
      firstName: this.contactInfos.firstName,
      lastName: this.contactInfos.lastName,
      gender: this.contactInfos.gender,
      dateOfBirth: this.contactInfos.dateOfBirth,
      emailAddress: this.contactInfos.emailAddress,
      residentCountry: this.contactInfos.residentCountry,
      nationality: this.contactInfos.nationality,
      workPhoneNumber: this.contactInfos.workPhoneNumber,
      homePhoneNumber: this.contactInfos.homePhoneNumber,
      mobilePhoneNumber: this.contactInfos.mobilePhoneNumber,
      faxPhoneNumber: this.contactInfos.faxPhoneNumber,
      cultureCode: this.contactInfos.cultureCode,
      currencyCode: this.contactInfos.currencyCode
    };

    this.openModal();

    this.resourceService.getCountries().subscribe(c => {
      this.countries = c;
      this.spinnerService.hideSpinnerById(this.editSpinnerId);
    });
    this.spinnerService.showSpinnerById(this.editSpinnerId);
  }

  save() {
    if (!this.editForm.valid) {
      return;
    }
    const obj = {
      // personalEmailAddress: {
      //   emailAddress: this.model.emailAddress
      // },
      name: {
        title: this.model.title,
        first: this.model.firstName,
        last: this.model.lastName
      },
      dateOfBirth: this.model.dateOfBirth,
      gender: this.model.gender,
      residentCountry: this.model.residentCountry,
      nationality: this.model.nationality,
      workEmailAddress: {
        emailAddress: this.model.emailAddress
      },
      workPhoneNumber: {
        number: this.model.workPhoneNumber
      },
      homePhoneNumber: {
        number: this.model.homePhoneNumber
      },
      mobilePhoneNumber: {
        number: this.model.mobilePhoneNumber
      },
      faxPhoneNumber: {
        number: this.model.faxPhoneNumber
      },
      cultureCode: this.model.cultureCode,
      currencyCode: this.model.currencyCode
    };
    this.profileService.updateAgent(obj).then(
      () => this.closeModal(), 
      e => {
      this.errors = e.error.errors.map(err => {
        const t = this.translate.instant(err.errorCode, 'profile-info');
        return t === err.errorCode ? `${err.errorCode}: ${err.errorMessage}` : t ;
      });
    });
  }


  private getDefaultItemValue(arrayObject: any, propertyName: string) {
    for (const item of arrayObject.items) {
      if (item.default && item[propertyName]) {
        return item[propertyName];
      }
    }
  }

  private initLanguages() {
    this.languages = [{ text: 'English', value: 'en-GB' }, { text: 'English - United States', value: 'en-US' },
    { text: 'Romanian', value: 'ro-RO' }];
  }
  private initCurrencies() {
    this.currencies = [{ text: 'Euro', value: 'EUR' }, { text: 'Dollar', value: 'USD' }, { text: 'Danish Krone', value: 'DKK' },
    { text: 'Romanian Leu', value: 'RON' }];
  }
}
