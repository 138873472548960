import { Component, OnInit, ElementRef, Renderer2, OnDestroy, ViewChild, Input, AfterViewInit } from '@angular/core';
import { BlueModalComponent } from '../../common-modules/blue-air-common/blue-modal/blue-modal.component';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { BlueModal } from '../../common-modules/blue-air-common/blue-modal/blue-modal';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'blue-modal2',
  templateUrl: './blue-modal2.component.html',
  styleUrls: ['./blue-modal2.component.scss']
})
export class BlueModal2Component extends BlueModal implements OnInit, OnDestroy {
  @Input() id: string;
  @Input() closeBtnLabel: string;
  @Input() cssClass: string = 'edit-popup';

  constructor(private modalService: BlueModalService, private el: ElementRef, private render: Renderer2) {
    super();
  }

  ngOnInit() {
    this.modalService.add(this);
  }

  ngOnDestroy() {
    this.modalService.remove(this.id);
  }
  open(closeCallback: () => void = null): void {
    this.render.setStyle(document.body, 'overflow', 'hidden');
    document.body.insertAdjacentElement('afterbegin', this.el.nativeElement);
    document.body.insertAdjacentHTML('afterbegin', '<div class="mfp-bg white mfp-ready"></div>');
    this.closeCallback = closeCallback;
    this.isOpened = true;
  }

  close(): void {
    if (!this.isOpened) {
      return;
    }
    this.isOpened = false;
    const popupbgs = document.body.querySelectorAll('.mfp-bg');
    for (let i = 0; i < popupbgs.length; i++){
      document.body.removeChild(popupbgs[i]);
    }
    this.render.removeStyle(document.body, 'overflow');
    if (this.closeCallback) {
      this.closeCallback();
    }
  }

}
