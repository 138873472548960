import { Component, OnInit } from '@angular/core';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import * as moment from 'moment';
import { ConfigService } from '../../core/config.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'check-in-language-info',
  templateUrl: './language-info.component.html',
  styleUrls: ['./language-info.component.scss']
})
export class LanguageInfoComponent implements OnInit {
  languageModalId = 'languge-modal';
  selectListExpanded: boolean;

  selectedLang: any;

  newSelectedLang: any;

  languages: any[] = [
    // {
    //   text: 'Deutsch',
    //   value: 'de-DE',
    //   flag: 'flag-de.svg'
    // },
    // {
    //   text: 'Ελληνικά',
    //   value: 'el-GR',
    //   flag: 'flag-gr.svg'
    // },
    {
      text: 'English',
      value: 'en-GB',
      flag: 'flag-gb.svg'
    },
    // {
    //   text: 'Español',
    //   value: 'es-ES',
    //   flag: 'flag-es.svg'
    // },
    // {
    //   text: 'Français',
    //   value: 'fr-FR',
    //   flag: 'flag-fr.svg'
    // },
    {
      text: 'Italiano',
      value: 'it-IT',
      flag: 'flag-it.svg'
    },
    // {
    //   text: 'Norsk',
    //   value: 'nn-NO',
    //   flag: 'flag-no.svg'
    // },
    // {
    //   text: 'Português',
    //   value: 'pt-PT',
    //   flag: 'flag-pt.svg'
    // },
    {
      text: 'Română',
      value: 'ro-RO',
      flag: 'flag-ro.svg'
    }
  ];

  constructor(private blueModalService: BlueModalService,
    private translateService: TranslateService,
    private configService: ConfigService) { }

  ngOnInit() {
    console.log(this.translateService.culture);
    this.selectedLang =
      this.languages.find(l => l.value === this.translateService.culture) ||
      this.languages.find(l => l.value === 'en-GB');
    this.newSelectedLang = Object.assign({}, this.selectedLang);
  }

  openModal(event) {
    if (event) {
      event.preventDefault();
    }
    this.blueModalService.open(this.languageModalId);
  }


  toggleSelectList(value: boolean = !this.selectListExpanded): void {
    if (value) {
      this.newSelectedLang = Object.assign({}, this.selectedLang);
    }
    this.selectListExpanded = value;
  }

  selectLang(lang: any): void {
    if (lang.value != this.newSelectedLang.value) {
      this.translateService.culture = lang.value;
      this.translateService.load(this.configService.config.translationsUrl,
        this.configService.config.collectTranslationsUrl,
        environment.collectTranslations);
      this.newSelectedLang = lang;
      window.location.reload();
    }

    this.newSelectedLang = lang;
    this.toggleSelectList(false);
  }

  apply(): void {
    this.selectedLang = this.newSelectedLang;
    const domainSplit = location.host.split('.');
    if (domainSplit.length) {
      const cookieDomain = '.' + domainSplit.filter((_val, index) => index > 0).join('.');
      // tslint:disable-next-line: max-line-length
      const deleteCookie = `${encodeURIComponent('OrchardCurrentCulture-FrontEnd')}=;path=/;domain=${cookieDomain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
      document.cookie = deleteCookie;
    }
    const expiryDate = moment().add(30, 'days').toDate();
    // tslint:disable-next-line: max-line-length
    const cookie = `${encodeURIComponent('OrchardCurrentCulture-FrontEnd')}=${encodeURIComponent(this.selectedLang.value)};path=/;expires=${expiryDate}`;
    document.cookie = cookie;
    window.location.reload();
    // this.blueModalService.close(this.languageModalId);
  }
}
