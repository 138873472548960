export abstract class BlueModal {
    public id: string;
    public closeBtnLabel: string;
    public cssClass: string;
    public isOpened: boolean;

    protected closeCallback: () => void;

    open(closeCallback: () => void = null): void {}
    close(): void {}
}
