import { EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';
import { PassengerSsrModel } from '../passenger-ssr-model';
import { FlightModel } from '../../core/models/flight-model';
import { SsrModel } from '../ssr-model';
import { Constants } from '../../constants';
import { PaidSsrOverride } from '../paid-ssr-override';
import { environment } from '../../../environments/environment';

export class SsrBoxContentBase implements OnInit {
    SsrTypeEnum = SsrType;

    loading = true;
    protected loadingCount = 0;

    protected _ssrsService: SsrsService;
    protected _flight: FlightModel;
    protected _fullSsrList: any[];
    protected _flightSsrsInitialized = false;
    protected _paidSSROverrides = new PaidSsrOverride();
    public assetsPath = environment.assetsPath;

    public ssrType: SsrType;
    public isAllowedToRemovePaidSsrs = false;
    public passengers: PassengerSsrModel[] = [];
    public availableSsrs: SsrModel[] = [];
    public allPassengersEnabled = true;
    public selectedPassenger: number = null;
    public applySsrsToSegments = true;
    public mobileMatches: boolean;
    public nestInfo: any[];
    public numberOfSelectedPassengers: number;

    protected _isSsrsPopupOpened = false;
    public get isSsrsPopupOpened() { return this._isSsrsPopupOpened; }
    public set isSsrsPopupOpened(val) {
        this._isSsrsPopupOpened = val;
    }

    protected _sameOptionsForAllFlights: boolean;
    public get sameOptionsForAllFlights() {
        return this._sameOptionsForAllFlights;
    }
    public set sameOptionsForAllFlights(val: boolean) {
        this._sameOptionsForAllFlights = val;
        if (val) {
            this.copySsrsToReturnFlight();
            this.onEnableSameOptionsForAllFlightsChanged(val);
        }
    }

    private _enableSameOptionsForAllFlights: boolean;
    get enableSameOptionsForAllFlights(): boolean {
        return this._enableSameOptionsForAllFlights;
    }

    @Input()
    set enableSameOptionsForAllFlights(value: boolean) {

        this._enableSameOptionsForAllFlights = value;
    }

    protected onEnableSameOptionsForAllFlightsChanged(value: boolean) {

    }

    protected _allFlightBoxContents: any[];
    @Input()
    get allFlightBoxContents() {
        return this._allFlightBoxContents;
    }
    set allFlightBoxContents(val) {
        this._allFlightBoxContents = val;
        this.initSameSsrForReturnFlight();
    }

    @Input()
    set flight(flight: FlightModel) {
        this.passengers = flight.passengers.map(p => {
            const x = new PassengerSsrModel();

            x.passenger = p;

            return x;
        });
        this._flight = flight;
        this.initFlightSsrList();

    }
    get flight() {
        return this._flight;
    }

    @Input() currentFlights: FlightModel[] = [];

    @Output() totalPriceChanged: EventEmitter<any> = new EventEmitter<any>();

    constructor(ssrsService: SsrsService) {
        this._ssrsService = ssrsService;
        this.mobileMatches = window.innerWidth < Constants.mobileScreenWith;
        this.sameOptionsForAllFlights = false;

        // this._isSsrsPopupOpened = true;
    }

    ngOnInit(): void {
        const list = this._ssrsService.availableSsrs.find(lists => lists.key === this.ssrType);
        this._fullSsrList = list ? list.value : [];
        this.initFlightSsrList();
    }

    startLoading(): void {
        this.loading = true;
        this.loadingCount++;
    }

    finishLoading(): void {
        this.loadingCount--;
        if (this.loadingCount === 0) {
            this.loading = false;
        }
    }

    selectSsr(passengerIndex: number = null, ssrCode: string = null, newSsrQuantity: number = 0, isExternalCall: boolean = false) {
        if (this.mobileMatches && this.availableSsrs.length === 1) {
            this._isSsrsPopupOpened = false;
        }

        if (passengerIndex === null) {
            passengerIndex = this.selectedPassenger;
        }

        const passengers = this.getSelectedPassengers(passengerIndex);
        let ssrChanged = true;

        passengers.forEach(p => {
            const ssr = this.availableSsrs.find(avSsr => avSsr.ssrCode === ssrCode);
            let paxSsr = p.getSelectedSsr(ssr);
            const paidQuantity = paxSsr ? paxSsr.paidQuantity : 0;

            if (newSsrQuantity === 0 && (paidQuantity === 0 || this.isAllowedToRemovePaidSsrs)) {
                ssrChanged = p.removeSsr(ssrCode);
            } else {
                paxSsr = p.getOrAddSelectedSsr(ssr);
                const previousSelectedQuantity = paxSsr.selectedQuantity;
                paxSsr.paidQuantity = paidQuantity;
                paxSsr.selectedQuantity = newSsrQuantity === 0 ? paidQuantity : newSsrQuantity;
                ssrChanged = paxSsr.selectedQuantity !== previousSelectedQuantity;
            }
        });

        if (ssrChanged) {
            this.afterSelectSsr();
            this.updateOtherFlights(isExternalCall);
        } else {
            this.updateAllPassengersSelected();
        }
    }

    afterSelectSsr() {
        this.updateSelectedSsr();
        this.updateAllPassengersSelected();
        this.updateTotalPrice();
    }

    applySsrs(): any[] {
        const newSsrs: { ssrCode: string, passengerNumber: number, flightSellKey: string }[] = []
        this.passengers.forEach(p => {
            const initialSsrs = p.getInitialSelectedSsrs();
            const modifiedSsrs = p.getSelectedSsrs();
            // removed ssrs
            const toRemove = initialSsrs
                .filter(i => modifiedSsrs.findIndex(m => m.ssrCode === i.ssrCode) < 0);
            toRemove.forEach(ssr => this.applySpecificSsr(ssr.selectedQuantity, 0, ssr, p.passenger.passengerNumber));

            // updated ssrs
            const toUpdate = initialSsrs
                .filter(i => modifiedSsrs.findIndex(m => m.ssrCode === i.ssrCode) >= 0)
                .map(i => ({ old: i, new: modifiedSsrs.find(m => m.ssrCode === i.ssrCode) }));
            toUpdate.forEach(g =>
                this.applySpecificSsr(g.old.selectedQuantity, g.new.selectedQuantity, g.new, p.passenger.passengerNumber));

            // new ssrs
            const toAdd = modifiedSsrs
                .filter(m => initialSsrs.findIndex(i => i.ssrCode === m.ssrCode) < 0);
            toAdd.forEach(ssr => this.applySpecificSsr(0, ssr.selectedQuantity, ssr, p.passenger.passengerNumber));

            newSsrs.push(...toAdd.map(ssr => ({
                ssrCode: ssr.ssrCode,
                passengerNumber: p.passenger.passengerNumber,
                flightSellKey: this.flight.sellKey
            })));
        });

        return newSsrs;
    }

    applySpecificSsr(oldQuantity: number, newSsrQuantity: number, ssr: SsrModel, passengerNumber: number): void {
        const paidSSRQuantityOverride = this._paidSSROverrides.getPaidQuantity(passengerNumber, ssr.ssrCode);
        let tempNewSsrQuantity = newSsrQuantity - paidSSRQuantityOverride;
        let tempOldSsrQuantity = oldQuantity - paidSSRQuantityOverride;

        while (tempNewSsrQuantity > oldQuantity - paidSSRQuantityOverride) {
            // sell fast track only on segments where ssr is available
            if (this.ssrType == this.SsrTypeEnum.FastTrack) {
                this.flight.segments.forEach(segment => {
                    const flightKey = `${segment.departureStation.iataCode}_${segment.arrivalStation.iataCode}`;
                    if (this.availableSsrs.find(ssr => ssr.details == flightKey) !== undefined) {
                        this._ssrsService.addSsr(this.ssrType, ssr.ssrCode, passengerNumber, segment, false);
                    }
                });
            }
            else {
                this._ssrsService.addSsr(this.ssrType, ssr.ssrCode, passengerNumber, this.flight, this.applySsrsToSegments);
            }
            tempNewSsrQuantity--;
        }

        while (newSsrQuantity - paidSSRQuantityOverride < tempOldSsrQuantity) {
            this._ssrsService.removeSsr(this.ssrType, ssr.ssrCode, tempOldSsrQuantity,
                passengerNumber, this.flight, this.applySsrsToSegments);
            tempOldSsrQuantity--;
        }
    }

    removeAllSsrs(passengerIndex: number, keepPassengerSelection: boolean = false, isExternalCall: boolean = false) {
        if (!keepPassengerSelection) {
            this.selectedPassenger = passengerIndex;
        }
        this.passengers[passengerIndex]
            .getSelectedSsrs()
            .forEach(selectedSsr => {
                this.selectSsr(
                    passengerIndex,
                    selectedSsr.ssrCode,
                    this.isAllowedToRemovePaidSsrs ? 0 : selectedSsr.paidQuantity,
                    isExternalCall);
            });
        this.numberOfSelectedPassengers = this.selectedPassenger === -1 ? this.passengers.length : 1;
        this.updateOtherFlights(isExternalCall);
        this._ssrsService.isPrioritySsrSelected = false;
    }


    selectPassenger(passengerIndex, fromCopySsrs: boolean = false) {
        this.selectedPassenger = passengerIndex;
        this.numberOfSelectedPassengers = this.selectedPassenger === -1 ? this.passengers.length : 1;
        if (!fromCopySsrs)
            this.isSsrsPopupOpened = true;
        this.updateSelectedSsr();
    }

    protected updateOtherFlights(isExternalCall: boolean = false) {
        if (this.sameOptionsForAllFlights && this.enableSameOptionsForAllFlights) {
            this.copySsrsToReturnFlight();
        } else if (!isExternalCall && this.allFlightBoxContents && this.allFlightBoxContents.length) {
            this.allFlightBoxContents[0].sameOptionsForAllFlights = false;
        }
    }

    protected copySsrsToReturnFlight() {
        if (this.allFlightBoxContents && this.allFlightBoxContents.length > 1) {
            this.passengers.forEach((p, pix) => {
                const selectedSsrs = p.getSelectedSsrs();

                if (this.allFlightBoxContents.length >= 3) {
                    for (let i = 0; i < this.allFlightBoxContents.length - 1; i++) {
                        if (this.allFlightBoxContents[i].availableSsrs.length === 0) {
                            this.allFlightBoxContents.splice(i, 1);
                        }
                    }
                }
                for (let i = 1; i < this.allFlightBoxContents.length; i++) {
                    const otherFlightBoxContent = this.allFlightBoxContents[i];

                    // sync new and common items
                    selectedSsrs.forEach(selectedSsr =>
                        otherFlightBoxContent.selectSsr(pix, selectedSsr.ssrCode, selectedSsr.selectedQuantity, true));

                    // sync removed items
                    const otherBoxSelectedSsrs = otherFlightBoxContent.passengers[pix].getSelectedSsrs();
                    otherFlightBoxContent.passengers[pix].ssrs = otherBoxSelectedSsrs
                        .filter(other => selectedSsrs.findIndex(selected => selected.ssrCode === other.ssrCode) >= 0);
                    otherFlightBoxContent.afterSelectSsr();
                }
            });
            for (let i = 1; i < this.allFlightBoxContents.length; i++) {
                this.allFlightBoxContents[i].selectPassenger(this.selectedPassenger, true);
            }
        }
    }

    protected getSelectedPassengers(passengerIndex: number): PassengerSsrModel[] {
        if (passengerIndex === -1) {
            return this.passengers;
        }

        return [this.passengers[passengerIndex]];
    }

    protected getSeletedPassenger() {
        return this.selectedPassenger === -1 ? this.passengers[0] : this.passengers[this.selectedPassenger];
    }

    protected initSelectedPassenger() {
        this.selectedPassenger = 0;
        this.numberOfSelectedPassengers = this.selectedPassenger === -1 ? this.passengers.length : 1;

        this.updateSelectedSsr();
    }

    protected updateSelectedSsr() {
        const allSelectedSsrs = this.passengers
            .map(p => p.getSelectedSsrs())
            .reduce((a, b) => a.concat(b), []);

        const soldSsrsBySsr = allSelectedSsrs
            .reduce((group, ssr) => {
                group[ssr.ssrCode] = group[ssr.ssrCode] || 0;
                group[ssr.ssrCode] += ssr.selectedQuantity;
                return group;
            }, {});

        const soldSsrsByNest = allSelectedSsrs
            .reduce((group, ssr) => {
                group[ssr.nest] = group[ssr.nest] || 0;
                group[ssr.nest] += ssr.selectedQuantity;
                return group;
            }, {});

        const selectedPassenger = this.getSeletedPassenger();
        const selectedSsrs = selectedPassenger.getSelectedSsrs();

        this.availableSsrs.forEach(avSsr => {
            const selectedSsr = selectedSsrs.find(s => s.ssrCode === avSsr.ssrCode);
            avSsr.paidQuantity = selectedSsr ? selectedSsr.paidQuantity : 0;
            avSsr.selectedQuantity = selectedSsr ? selectedSsr.selectedQuantity : 0;


            const nestLimit = this.nestInfo.find(ni => ni.key === avSsr.nest);
            if (this.selectedPassenger === -1 && avSsr.getLowestLimitPerPassenger() === 0) {
                avSsr.limitPerNest = 0;
            } else if (nestLimit) {
                const soldPerNest = soldSsrsByNest[avSsr.nest] || 0;
                const soldPerSsr = (this.selectedPassenger === -1 ? (soldSsrsBySsr[avSsr.ssrCode] || 0) : avSsr.selectedQuantity)
                avSsr.limitPerNest = nestLimit.value - soldPerNest + soldPerSsr;
            } else {
                avSsr.limitPerNest = null;
            }
            const limit = avSsr.getLimitPerPassenger(selectedPassenger.passenger.passengerNumber);
            avSsr.isVisible = limit > 0;
        });
    }

    protected initFlightSsrList() {
        if (!this._fullSsrList || !this._flight) {
            return;
        }
        const flightKey = `${this._flight.departureStation.iataCode}_${this._flight.arrivalStation.iataCode}`;

        let list = this._fullSsrList.find(ssr => ssr.key === flightKey);
        list = list ? list.value : [];
        this.availableSsrs = list.map(s => {
            const x = new SsrModel();
            x.name = s.name;
            x.price = s.price;
            if (s.originalPrice) {
                x.originalPrice = s.originalPrice;
            }
            x.ssrCode = s.ssrCode;
            x.details = s.details;
            x.thumbnailDescription = s.thumbnailDescription;
            x.thumbnailUrl = s.thumbnailUrl;
            x.isCarryOn = s.isCarryOn;
            x.nest = s.nest;
            x.limitPerPassenger = {};
            if (s.limitPerPassenger && s.limitPerPassenger.length) {
                x.limitPerPassenger = s.limitPerPassenger.reduce((a, b) => {
                    a[+b.key] = +b.value;
                    return a;
                }, {});
            }
            return x;
        });

        if (this._flight.segments.length > 1) {
            this.nestInfo = [];
            this._flight.segments.forEach(seg => {
                const segmentKey = `${seg.departureStation.iataCode}_${seg.arrivalStation.iataCode}`;
                const segmentNest = this._ssrsService.nestInfo.find(ni => ni.key === segmentKey).value as any[];
                segmentNest.forEach(sn => {
                    const existing = this.nestInfo.find(n => n.key === sn.key);
                    if (existing) {
                        existing.value = Math.min(existing.value, sn.value);
                    } else {
                        this.nestInfo.push({
                            key: sn.key,
                            value: sn.value
                        });
                    }
                });
            });
        } else {
            this.nestInfo = this._ssrsService.nestInfo.find(ni => ni.key === flightKey).value as any[];
        }

        if (this._flight.segments.length > 1 && this.ssrType == this.SsrTypeEnum.Baggage) {
          this.availableSsrs.forEach(s => {
            s.price = 0;
          })

          this._flight.segments.forEach(seg => {
            const flightKey = `${seg.departureStation.iataCode}_${seg.arrivalStation.iataCode}`;

            let list = this._fullSsrList.find(ssr => ssr.key === flightKey);

            list.value.forEach(ssr => {
              let index = this.availableSsrs.findIndex(as => as.ssrCode == ssr.ssrCode);

              this.availableSsrs[index].price += ssr.price;
            })
          })
        }

        this.passengers.forEach(p => this.initPassengerSsrs(p));

        this._flightSsrsInitialized = true;

        this.initSelectedPassenger();
        this.updateSelectedSsr();

        this.initSameSsrForReturnFlight();
    }

    protected initPassengerSsrs(passenger: PassengerSsrModel) {
        let passengerSsrs = [];
        let paidSsrs = [];

        if (this.ssrType == this.SsrTypeEnum.FastTrack) {
            this._flight.segments.forEach(segment => {
                var paid = segment.paidSsrs[passenger.passenger.passengerNumber].map(fs => this.availableSsrs.find(avSsr => avSsr.ssrCode === fs))
                    .filter(ssr => ssr);
                if (paid.length > 0) {
                    paidSsrs = paid;
                }
                var ssrs = segment.ssrs[passenger.passenger.passengerNumber].map(fs => this.availableSsrs.find(avSsr => avSsr.ssrCode === fs))
                    .filter(ssr => ssr);
                if (ssrs.length > 0) {
                    passengerSsrs = ssrs;
                }

                this._paidSSROverrides.updateQuantitiesForPassenger(passenger.passenger.passengerNumber,
                    segment.paidSsrs[passenger.passenger.passengerNumber]);
            });
        }
        else {
            passengerSsrs = this._flight.ssrs[passenger.passenger.passengerNumber]
                .map(fs => this.availableSsrs.find(avSsr => avSsr.ssrCode === fs))
                .filter(ssr => ssr);
            paidSsrs = this._flight.paidSsrs[passenger.passenger.passengerNumber]
                .map(fs =>
                    this.availableSsrs.find(avSsr => avSsr.ssrCode === fs ||
                        this._paidSSROverrides.checkOverrides(avSsr.ssrCode, fs)))
                .filter(ssr => ssr);
            this._paidSSROverrides.updateQuantitiesForPassenger(passenger.passenger.passengerNumber,
                this._flight.paidSsrs[passenger.passenger.passengerNumber]);
        }

        const groupBy = (group, ssr) => {
            (group[ssr.ssrCode] = group[ssr.ssrCode] || []).push(ssr);
            return group;
        };

        const ssrGroupBy: Object = passengerSsrs.reduce(groupBy, {});
        const paidGroupBy: Object = paidSsrs.reduce(groupBy, {});

        for (let ssrCode in paidGroupBy) {
            if (paidGroupBy.hasOwnProperty(ssrCode)) {
                const addedSsr = passenger.getOrAddInitialSelectedSsr(paidGroupBy[ssrCode][0]);
                addedSsr.paidQuantity = paidGroupBy[ssrCode].length;
                addedSsr.selectedQuantity = addedSsr.paidQuantity;
            }
        }

        for (let ssrCode in ssrGroupBy) {
            if (ssrGroupBy.hasOwnProperty(ssrCode)) {
                const addedSsr = passenger.getOrAddInitialSelectedSsr(ssrGroupBy[ssrCode][0]);
                addedSsr.selectedQuantity = ssrGroupBy[ssrCode].length + addedSsr.paidQuantity;
            }
        }

        passenger.initializeSsrs();
    }

    protected initSameSsrForReturnFlight() {
        if (!this._flightSsrsInitialized || !this.allFlightBoxContents) {
            return;
        }
        if (this.enableSameOptionsForAllFlights) {
            const thisHash = this.getSeletedSsrsHash();
            const thisNestHash = this.getNestHash();

            let ssrResult = thisHash !== '';
            let nestResult = true;

            for (let i = 1; i < this.allFlightBoxContents.length; i++) {
                ssrResult = ssrResult && thisHash === this.allFlightBoxContents[i].getSeletedSsrsHash();
                nestResult = nestResult && thisNestHash === this.allFlightBoxContents[i].getNestHash()
                    && this.allFlightBoxContents[i].availableSsrs.some(s => s.isVisible);
            }

            this.sameOptionsForAllFlights = ssrResult;
            this.enableSameOptionsForAllFlights = nestResult;
        }
    }
    protected allPassengersHaveTheSameSsrs(): boolean {
        const selectedSsrs = this.passengers[0].getSelectedSsrs();

        // if (selectedSsrs.length == 0) {
        // this._ssrsService.isPrioritySsrSelected = false; // reconsiderLuggagePrioritySsrSelection
        // }

        const allPassengersEnabled = this.nestInfo
            .filter(nest => this.availableSsrs.map(avSsr => avSsr.nest).indexOf(nest.key) >= 0)
            .some(ni => ni.value < this.passengers.length);

        let result = true;
        for (let i = 1; i < this.passengers.length; i++) {
            const paxSsrs = this.passengers[i].getSelectedSsrs();
            result = result && paxSsrs.length === selectedSsrs.length;
            if (!result) {
                break;
            }
            selectedSsrs.forEach(s => {
                // let ssrModel: SsrModel[] = this.passengers.map(p => p.getSelectedSsrs()).reduce((a, b) => a.concat(b), []);
                // if (s[0].name === "PBRD" && s[0].price && s[0].price > 0) {
                //     this._ssrsService.isPrioritySsrSelected = true; // goToNextStep
                // }
                // else {
                //     this._ssrsService.isPrioritySsrSelected = false; // reconsiderLuggagePrioritySsrSelection
                // }
                // result = result &&
                //     this.passengers[i]
                //         .getSelectedSsrs()
                //         .findIndex(ps => ps.ssrCode === s.ssrCode && ps.selectedQuantity === s.selectedQuantity) >= 0;
            });
        }

        return result && !allPassengersEnabled;
    }

    getSeletedSsrsHash(): string {
        let hash = '';
        let ssrsHash = '';
        this.passengers.forEach((p, pix) => {
            ssrsHash += p.getSelectedSsrs().filter(f => f.selectedQuantity !== f.paidQuantity)
                .sort((a, b) => a.ssrCode > b.ssrCode ? 1 : a.ssrCode === b.ssrCode ? 0 : -1)
                .map(s => s.ssrCode + '|' + s.selectedQuantity)
                .join('|');
            hash += pix + '|' + ssrsHash;
        });

        if (!ssrsHash.length) {
            return '';
        }
        return hash;
    }

    getNestHash(): string {
        const nestCodes = this.availableSsrs.map(avSsr => avSsr.nest);

        const hash = this.nestInfo
            .filter(nest => nestCodes.indexOf(nest.key) >= 0)
            .sort((a, b) => a.key > b.key ? 1 : a.key === b.key ? 0 : -1)
            .map(ni => ni.key + '|' + ni.value)
            .join('||');
        return hash;
    }

    protected updateAllPassengersSelected(): boolean {
        this.allPassengersEnabled = this.allPassengersHaveTheSameSsrs();
        return this.allPassengersEnabled;
    }

    protected updateTotalPrice() {
        const totalPrice = this.passengers
            .map(p => p.getSelectedSsrs())
            .reduce((a, b) => a.concat(b), [])
            .reduce((a, b) => a + (b.selectedQuantity - b.paidQuantity) * b.price, 0);

        this.totalPriceChanged.next(totalPrice);
    }

    getSelectedPassengerName() {
        const selectedPax = this.passengers.map(pax => pax.passenger).filter(pax => pax.passengerNumber === this.selectedPassenger)[0];
        if (selectedPax) {
            return selectedPax.name.first + ' ' + selectedPax.name.last;
        }
    }
}
