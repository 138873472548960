import { ShoppingCartPassengerModel } from './shopping-cart-passenger-model';
import * as moment from 'moment';

export class ShoppingCartFlightModel {
    private _amount: number;
    get amount() { return this._amount; }
    set amount(value: number) { this._amount = Math.max(value, 0); }

    departureStation: string;
    arrivalStation: string;
    departureDate: any;
    arrivalDate: any;
    departureDateUtc: any;
    arrivalDateUtc: any;
    selectedBundleCode?: string;
    flightNumber?: string;
    operatingCarrier?: string;

    segments: ShoppingCartFlightModel[];

    private _passengers: ShoppingCartPassengerModel[];
    get passengers(): ShoppingCartPassengerModel[] {
        return this.segments.length ? this.segments[0].passengers : this._passengers;
    }
    set passengers(value: ShoppingCartPassengerModel[]) {
        if (this.segments.length) {
            this.segments[0].passengers = value;
        } else {
            this._passengers = value;
        }
    }

    constructor() {
        this.segments = [];
        this.passengers = [];
    }

    init(journeyOrSegment: any, journeyOrSegmentIndex?: number): ShoppingCartFlightModel {
        this.amount = journeyOrSegment.amount;
        this.selectedBundleCode = journeyOrSegment.bundleCode;
        this.segments = journeyOrSegment.segments && journeyOrSegment.segments.length ?
            journeyOrSegment.segments.map((segment, segmentIndex) => new ShoppingCartFlightModel().init(segment, segmentIndex)) : [];

        if (this.segments.length) {

            this.departureStation = this.segments[0].departureStation;
            this.arrivalStation = this.segments[this.segments.length - 1].arrivalStation;
            this.departureDate = this.segments[0].departureDate;
            this.arrivalDate = this.segments[this.segments.length - 1].arrivalDate;

            this.departureDateUtc = this.segments[0].departureDateUtc;
            this.arrivalDateUtc = this.segments[this.segments.length - 1].arrivalDateUtc;
        } else {
            this.departureStation = journeyOrSegment.departureStation;
            this.arrivalStation = journeyOrSegment.arrivalStation;
            this.departureDate = moment(journeyOrSegment.departureDate, "YYYY-MM-DD HH:mm:ss");
            this.arrivalDate = moment(journeyOrSegment.arrivalDate, "YYYY-MM-DD HH:mm:ss");

            this.departureDateUtc = moment(journeyOrSegment.departureDateUTC, "YYYY-MM-DD HH:mm:ss")
            this.arrivalDateUtc = moment(journeyOrSegment.arrivalDateUTC, "YYYY-MM-DD HH:mm:ss");

            this.flightNumber = journeyOrSegment.flightNumber;
            this.operatingCarrier = journeyOrSegment.operatingCarrier;

            this._passengers = journeyOrSegment.passengers.map(p => new ShoppingCartPassengerModel().init(p));
        }

        return this;
    }

    initFromSegments(segments: ShoppingCartFlightModel[], amount: number, bundleCode: string): ShoppingCartFlightModel {
        this.segments = segments;

        this.departureStation = this.segments[0].departureStation;
        this.arrivalStation = this.segments[this.segments.length - 1].arrivalStation;
        this.departureDate = this.segments[0].departureDate;
        this.arrivalDate = this.segments[this.segments.length - 1].arrivalDate;

        this.departureDateUtc = this.segments[0].departureDateUtc;
        this.arrivalDateUtc = this.segments[this.segments.length - 1].arrivalDateUtc;

        this.amount = amount;
        this.selectedBundleCode = bundleCode;

        return this;
    }
}
