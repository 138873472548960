/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ssr-same-options-for-return-flight.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i4 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i5 from "@angular/common";
import * as i6 from "./ssr-same-options-for-return-flight.component";
var styles_SsrSameOptionsForReturnFlightComponent = [i0.styles];
var RenderType_SsrSameOptionsForReturnFlightComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SsrSameOptionsForReturnFlightComponent, data: {} });
export { RenderType_SsrSameOptionsForReturnFlightComponent as RenderType_SsrSameOptionsForReturnFlightComponent };
function View_SsrSameOptionsForReturnFlightComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "checkbox-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("ngModelChange" === en)) {
        var pd_2 = ((_co.isSelected = $event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(4, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(6, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "label", [], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 2)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.isSelected; _ck(_v, 4, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "return-flight-", _co.uniqueId, ""); var currVal_1 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 6).ngClassValid; var currVal_6 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = i1.ɵinlineInterpolate(1, "return-flight-", _co.uniqueId, ""); _ck(_v, 7, 0, currVal_9); var currVal_10 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), "I choose the same options for return flight", "extras")); _ck(_v, 8, 0, currVal_10); }); }
export function View_SsrSameOptionsForReturnFlightComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.TranslatePipe, [i4.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SsrSameOptionsForReturnFlightComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visible; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SsrSameOptionsForReturnFlightComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-ssr-same-options-for-return-flight", [], null, null, null, View_SsrSameOptionsForReturnFlightComponent_0, RenderType_SsrSameOptionsForReturnFlightComponent)), i1.ɵdid(1, 114688, null, 0, i6.SsrSameOptionsForReturnFlightComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SsrSameOptionsForReturnFlightComponentNgFactory = i1.ɵccf("check-in-ssr-same-options-for-return-flight", i6.SsrSameOptionsForReturnFlightComponent, View_SsrSameOptionsForReturnFlightComponent_Host_0, { visible: "visible", isSelected: "isSelected" }, { isSelectedChange: "isSelectedChange" }, []);
export { SsrSameOptionsForReturnFlightComponentNgFactory as SsrSameOptionsForReturnFlightComponentNgFactory };
