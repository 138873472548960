import { PassengerModel } from '../core/models/passenger-model';
import { SsrModel } from './ssr-model';

export class PassengerSsrModel {
    passenger: PassengerModel;
    initialSsrs: SsrModel[] = [];
    ssrs: SsrModel[] = [];

    getSelectedSsrs(): SsrModel[] {
        return [...this.ssrs];
    }

    getSelectedSsr(ssr: SsrModel): SsrModel {
        return this.ssrs.find(s => s.ssrCode === ssr.ssrCode);
    }

    getOrAddSelectedSsr(ssrToSearch: SsrModel): SsrModel {
        return this.getOrAddSelectedSsrFromList(ssrToSearch, this.ssrs);
    }

    getInitialSelectedSsrs(): SsrModel[] {
        return [...this.initialSsrs];
    }

    getOrAddInitialSelectedSsr(ssrToSearch: SsrModel) {
        return this.getOrAddSelectedSsrFromList(ssrToSearch, this.initialSsrs);
    }

    private getOrAddSelectedSsrFromList(ssrToSearch: SsrModel, list: SsrModel[]) {
        let obj = list.find(s => s.ssrCode === ssrToSearch.ssrCode);
        if (obj) {
            return obj;
        }

        obj = new SsrModel(ssrToSearch);
        list.push(obj);
        return obj;
    }

    /** Removes ssrs from passengers by ssrcode
     * @returns [true] if ssr found, otherwise [false]
     */
    removeSsr(ssrCode: string): boolean {
        const index = this.ssrs.findIndex(s => s.ssrCode === ssrCode);
        if (index >= 0) {
            this.ssrs.splice(index, 1);
            return true;
        }
        return false;
    }

    initializeSsrs() {
        this.ssrs = this.getInitialSelectedSsrs().map(initial => new SsrModel(initial, initial.selectedQuantity));
    }
}
