import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { LuggageComponent } from './luggage.component';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BookingRoutes, CheckinRoutes } from './luggage-routes';
import { ExtrasModule } from '../extras/extras.module';
import { CanDeactivateComponentService } from '../core/can-deactivate-component.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule.forRoot(
      environment.flow === 1 ? BookingRoutes : CheckinRoutes
    ),
    SharedModule,
    BlueAirCommonModule,
    ExtrasModule
  ],
  declarations: [LuggageComponent],
  providers: [CanDeactivateComponentService]
})
export class LuggageModule { }
