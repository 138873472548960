import { BookingFareSelectComponent, IFareSoldEventData } from './../booking-fare-select/booking-fare-select.component';
import { Component, OnInit, ViewChild, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { BookingFlightSelectService } from '../booking-flight-select.service';
import { FlightSearchModel } from '../models/flight-search-model';
import { ApplicationFlowEnum, BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { BookingFlowService } from '../../core/booking-flow.service';
import { BookingSelectionService } from '../../core/booking-selection.service';
import { Subject, Subscription } from 'rxjs';
import { FlightModel } from '../../core/models/flight-model';
import * as moment from 'moment'
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../../constants';
import { Router } from '@angular/router';
import { BluebenefitsSelectComponent } from '../bluebenefits-select/bluebenefits-select.component';
import { HttpErrorResponse } from '@angular/common/http';
import { EnvHelper } from 'src/app/env-helper';
import { promise } from 'protractor';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';

@Component({
  selector: 'check-in-booking-flight-select',
  templateUrl: './booking-flight-select.component.html',
  styleUrls: ['./booking-flight-select.component.scss']
})

export class BookingFlightSelectComponent implements OnInit, OnDestroy {
  private selectionFlightsSubscription: Subscription;

  private _availability: any;
  get availability() {
    return this._availability;
  }

  set availability(value: any) {
    this._availability = value;
    if (this._hasTrips) {
      this.autoFareSellTriggers = {};
      this._availability.trips.forEach((trip, index) => {
        this.autoFareSellTriggers[index] = new Subject<boolean>();
      });
    }
  }

  autoFareSellTriggers: Record<number, Subject<boolean>> = {};

  canContinue = false;
  showBlueBenefitsSelection: boolean = false;

  flightSearchModel: FlightSearchModel;
  priceBreakdown: any[] = [];
  flights: FlightModel[] = [];
  ariaLabel = '';
  fares: any[];
  booking: any;
  isCalendarView = false;
  isChangeSearchOpened: boolean;
  isForChangingFlight: boolean;
  notEnoughSelectedFlightsModalId = 'notEnoughSelectedFlightsModalId';
  bundleUpgradeModalId = 'bundleUpgradeModalId';
  warningModalId = 'warningModalId';
  warningFlightDatesModalId = 'warningFlightDatesModalId';
  bluebenefitsModalId = 'bluebenefitsModalId';
  lockFareErrMessage: string;
  isMessageOn: boolean;
  showLockFare: boolean;
  allSSRS: any[];

  lockFareEnabled: boolean = false;
  moveFlights: boolean;

  @ViewChildren(BookingFareSelectComponent) bookingFareSelectComponents: QueryList<BookingFareSelectComponent>;
  @ViewChild('notEnoughSelectedFlightsModal', { static: true }) notEnoughSelectedFlightsModal: DefaultModalComponent;
  @ViewChild('warningModal', { static: true }) warningModal: DefaultModalComponent;
  @ViewChild('bundleUpgradeModal', { static: true }) bundleUpgradeModal: DefaultModalComponent;
  @ViewChild('warningFlightDatesModal', { static: true }) warningFlightDatesModal: DefaultModalComponent;
  @ViewChild('spoilageFeeModal', { static: true }) spoilageFeeModal: DefaultModalComponent;
  @ViewChild('bluebenefitsModal', { static: true }) bluebenefitsModal: DefaultModalComponent;
  @ViewChild(BluebenefitsSelectComponent, { static: false }) bluebenefitsSelectComponent: BluebenefitsSelectComponent;

  private currentStep: CheckinSteps = CheckinSteps.flights;

  constructor(
    private flightSearch: BookingFlightSelectService, private bookingSteps: BookingStepsService,
    private bookingFlowService: BookingFlowService, private selectionService: BookingSelectionService,
    private spinnerService: LoadingSpinnerService, private bookingFlightSelectService: BookingFlightSelectService,
    private translateService: TranslateService,
    private router: Router, private ecommerce: ECommerceService) {

    this.moveFlights = this.flightSearch.isMoveFlightsCriteria();
    this.isMessageOn = false;
    bookingSteps.currentStep.next(this.currentStep);

    this.flightSearch.getShowBlueBenefitsParam();

    this.flightSearchModel = this.flightSearch.searchModel;

    this.selectionFlightsSubscription = this.selectionService.flightsObs.subscribe(f => {
      this.flights = f || [];
    });
  }

  ngOnDestroy() {
    if (this.selectionFlightsSubscription) {
      this.selectionFlightsSubscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
    this.showLockFare = !(EnvHelper.IsOnAgencyPortal());

    this.bookingFlightSelectService.loadFlightsPage().then(res => {
      if (res) {
        this.bookingFlowService.getBooking().then(data => {
          if (data) {
            this.isForChangingFlight = (data as any).bookingDetail.recordLocator &&
              (data as any).bookingDetail.recordLocator !== '' ? true : false;
          }

          if (!this.flightSearchModel || this.moveFlights) {
            const searchModelPromise = !this.moveFlights ? this.flightSearch.getFlightSearch() : this.flightSearch.getRememberSearchModel();
            searchModelPromise.then(() => {
              this.flightSearchModel = this.flightSearch.searchModel;
              if (this.flightSearchModel) {
                this.callAvailability().then(() => {
                  if (this.moveFlights) {
                    this.isChangeSearchOpened = true;
                  }
                  this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
                });
              } else {
                this.bookingSteps.goToStep(CheckinSteps.search);
              }
            });
          } else {
            this.callAvailability().then(() => this.spinnerService.hideSpinnerById(Constants.mainSpinnerId));
          }
        });
      }
    });
  }

  applySelection() {

    if (this.flightSearch.canContinue === false) {
      this.notEnoughSelectedFlightsModal.openPopup(({}));
    } else if (this.bookingSteps.flow === ApplicationFlowEnum.Booking) {
      if (this.flightSearch.bundleCodes[0] === 'ABAS' && this.flightSearch.showBundleUpgrade) {
        this.createAllSsrsObject();
        this.flightSearch.showBundleUpgrade = false;

        this.bundleUpgradeModal.openPopup((isOk) => {
          if (isOk) {
            this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
            this.validateFlights();

          } else {
            this.bookingFareSelectComponents.first.upgradeBundleFromPopup(this.getBundleUpgradeCode());
            this.validateFlights();
          }
        });
      } else {
        this.validateFlights();
      }
    } else {
      this.validateFlights();
    }
  }


  checkForSpoilageFeeModal(journeyFares: any[]) {
    // if theres a negative fare on the change flight flow -> show spoilage popup
    if (this.isForChangingFlight && journeyFares.findIndex(amount => amount < 0) >= 0) {
      this.spoilageFeeModal.openPopup((isOk) => {
        // this popup has only 1 option (ok) no need for checking the value

        if (!this.lockFareEnabled) {
          this.bookingFlowService.loadPriceBreakdown(true);
          this.bookingSteps.goToNextStepFrom(this.currentStep);
        } else {
          this.router.navigate(['passengers', 'lockfare']);
        }
      });
      return;
    }
    if (!this.lockFareEnabled) {
      this.bookingFlowService.loadPriceBreakdown(true);
      this.bookingSteps.goToNextStepFrom(this.currentStep);
    } else {
      this.router.navigate(['passengers', 'lockfare']);
    }
  }

  validationForModal() {
    // if (!moment(this.flightSearchModel.departureDate).isSame(moment(this.flights[0].departureStation.date), 'day')) {
    //   return true;
    // }
    // if (this.flightSearchModel.isRoundTrip) {
    //   if (this.flights.length < 2 ||
    //      !moment(this.flightSearchModel.returnDate).isSame(moment(this.flights[1].departureStation.date), 'day')) {
    //       return true;
    //   }
    // }
    return false;
  }

  backToFlightSelection() {
    this.showBlueBenefitsSelection = false;
  }

  callAvailability(tripIndex?: any): Promise<any> {

    return this.flightSearch.getFlights().then(data => {
      // let a = this.flightSearch.availability;
      this.availability = this.flightSearch.availability;
      if (this.availability.trips)
        this.fares = this.availability.trips
          .map(p => p.flightDates).reduce((a, b) => a.concat(b), [])
          .map(p => p.flights).reduce((a, b) => a.concat(b), [])
          .map(p => p.fares).reduce((a, b) => a.concat(b), [])
          .map(p => p.defaultIncludedBundleCode).filter((v, i, a) => a.indexOf(v) === i);
      if (tripIndex == undefined) {
        this.flightSearch.resetPriceItinerary();
        this.bookingFlowService.updateShoppingCartFromItinerary(null, true);
      }
    });
  }

  showCalendar() {
    this.isCalendarView = true;
  }

  // toggleSearch(newValue: boolean = !this.isChangeSearchOpened) {
  toggleSearch() {
    //this.isChangeSearchOpened = newValue;
    this.startOver();
  }

  startOver() {
    const baseUrl = "https://www.aeroitalia.com";
    // window.location.href = window.origin;
    window.location.href = this.translateService.culture.indexOf("it") != -1 ? `${baseUrl}` : `${baseUrl}/${this.translateService.culture.substring(0, 2)}`;
  }

  goToNext(journeyFares) {
    this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
    if (this.bookingFlightSelectService.isBlueBenefitsSelected && !this.bluebenefitsSelectComponent) {
      this.showBlueBenefitsSelection = true;
    } else {
      if (this.bookingFlightSelectService.isBlueBenefitsSelected) {
        if (this.bluebenefitsSelectComponent.selectedBenefitType) {
          this.bookingFlightSelectService.promoId = this.bluebenefitsSelectComponent.selectedBenefitType;
          this.sellData(journeyFares);
        } else {
          this.bluebenefitsModal.openPopup(({}));
        }
      } else {
        this.sellData(journeyFares);
      }
    }
    this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
  }

  sellData(journeyFares) {
    this.isMessageOn = false;
    (this.moveFlights ? this.flightSearch.sellMovedFlight() : this.flightSearch.sellFare(journeyFares))
      .then(() => this.lockFareEnabled && this.bookingFlightSelectService.lockFare(this.flights, this.flightSearchModel.passengers)
        .catch((error: HttpErrorResponse) => {
          this.lockFareErrMessage = error.error.errors[0].errorMessage;
          this.isMessageOn = true;
          return Promise.reject();
        }))
      .then(() => this.bookingFlowService.loadFlowInfo(true))
      .then(() => {
        let ecommerceCartItems = [];
        this.bookingFlowService.loadPriceBreakdown().then(breakdown => {
          if (breakdown && breakdown.currentShoppingCart.flights) {
            ecommerceCartItems = this.ecommerce.getFlightsForECommerce(breakdown.currentShoppingCart.flights, false, this.lockFareEnabled);
            this.ecommerce.AddToCart(ecommerceCartItems);
            this.ecommerce.Checkout(ecommerceCartItems, 1, 'Flights step');
          }
        })
          .then(() => this.checkForSpoilageFeeModal(journeyFares))
      });
  }

  getFlights(searchModel: FlightSearchModel) {
    this.flightSearch.searchModel = searchModel;
    this.callAvailability().then(() => {
      this.flightSearchModel = this.flightSearch.searchModel;
    });
    //this.toggleSearch(false);
  }

  sellBlueBenefits(isBlueBenefitsSelected: boolean) {
    // if roundtrip & selected prices are not the same (all of them with blue benefits or all without)
    // deselect and remove from cart previous selected flight
    if (this.bookingFlightSelectService.selectedFlights.length > 0) {
      this.bookingFareSelectComponents.forEach(component => {
        if (component.isBlueBenefitsSelected !== isBlueBenefitsSelected) {
          //component.emptyCurrentSelection();
        }
      });
    }
  }

  private get _hasTrips() {
    return this.availability && Array.isArray(this.availability.trips);
  }

  onFareSoldHandler(eventData: IFareSoldEventData) {
    if (!this._hasTrips) {
      return;
    }
    for (let i = 0; i < this.availability.trips.length; i++) {
      if (eventData.tripIndex !== i) {
        if (this.autoFareSellTriggers[i]) {
          this.autoFareSellTriggers[i].next(eventData.isBlueBenefits);
        }
      }
    }
  }

  validateFlights() {
    let outboundFlight;
    let inboundFlight;
    const journeyFares = this.flights.map(flight => flight.totalFareModifyBooking);

    if (this.flights.length > 1 && moment(this.flights[0].departureStation.dateUtc).isBefore(this.flights[1].departureStation.dateUtc)) {
      outboundFlight = this.flights[0];
      inboundFlight = this.flights[1];
    } else {
      outboundFlight = this.flights[1];
      inboundFlight = this.flights[0];
    }

    if (this.flights.length > 1 && (moment(inboundFlight.departureStation.date).isBefore(moment(outboundFlight.arrivalStation.date)) ||
      moment(inboundFlight.departureStation.date).diff(moment(outboundFlight.arrivalStation.date), 'minute') <= 45)) {
      this.warningFlightDatesModal.openPopup(({}));
    } else {
      if (this.validationForModal()) {
        this.warningModal.openPopup((isOk) => {
          if (isOk) {
            this.goToNext(journeyFares);
          }
        });
      } else {
        this.goToNext(journeyFares);
      }
    }
  }

  getCurrentBundleName() {
    if (this.flightSearch.bundleCodes[0] === 'ABAS') {
      return 'BASIC';
    }
    else {
      return 'CLASSIC';
    }
  }

  getUpgradeBundleName() {
    if (this.flightSearch.bundleCodes[0] === 'ABAS') {
      return 'CLASSIC';
    }
    else {
      return 'FLEX';
    }
  }

  getBundleUpgradeCode() {
    let upgradeBundleIndex = this.flightSearch.availability.trips[0].serviceBundleOffers.items.findIndex(bundle => bundle.serviceBundleCode === this.flightSearch.bundleCodes[0]) + 1;

    return this.flightSearch.availability.trips[0].serviceBundleOffers.items[upgradeBundleIndex].serviceBundleCode;
  }

  createAllSsrsObject() {
    this.allSSRS = [];
    let ssrs = ['STST', 'SCBG', 'PCKN', 'SBAG']

    let currentBundleSsr = (this.flightSearch.availability.trips[0].serviceBundleOffers.items.find(bundle => bundle.serviceBundleCode === this.flightSearch.bundleCodes[0])).serviceBundlePriceList[0].serviceBundleSsrPriceList;

    let upgradeBundleIndex = this.flightSearch.availability.trips[0].serviceBundleOffers.items.findIndex(bundle => bundle.serviceBundleCode === this.flightSearch.bundleCodes[0]) + 1;
    let upgradeBunldeSsr = this.flightSearch.availability.trips[0].serviceBundleOffers.items[upgradeBundleIndex].serviceBundlePriceList[0].serviceBundleSsrPriceList;


    ssrs.forEach(ssrCode => {
      let currentHasSsr = false;

      if (currentBundleSsr.find(s => s.ssrCode === ssrCode) && ssrCode != 'FLX') {
        currentHasSsr = true;
      }

      let upgradeHasSsr = false;

      if (upgradeBunldeSsr.find(s => s.ssrCode === ssrCode)) {
        upgradeHasSsr = true;
      }

      this.allSSRS.push({
        ssrCode: ssrCode,
        currentHasSsr: currentHasSsr,
        upgradeHasSsr: upgradeHasSsr
      })
    })
  }
}