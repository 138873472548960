import { Renderer2 } from '@angular/core';
import { EnvHelper } from '../env-helper';

export class DomHelper {
    /**
     * Increase the height of the app when some elements are cut-out
     * @param renderer
     * @param addedHeight
     */
    static IncreaseAppHeight(renderer: Renderer2, addedHeight: number = 750) {
        if (!EnvHelper.IsOnAgencyPortal()) {
            return;
        }
        const form = document.querySelector('form.checkin-wrapper');
        if (form) {
            renderer.setStyle(form, 'padding-bottom', `${addedHeight}px`);
        }
    }
/**
 * Reset the height of the app
 * @param renderer
 */
    static ResetAppHeight(renderer: Renderer2) {
        if (!EnvHelper.IsOnAgencyPortal()) {
            return;
        }
        const form = document.querySelector('form.checkin-wrapper');
        if (form) {
            renderer.removeStyle(form, 'padding-bottom');
        }
    }
}
