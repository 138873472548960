<div class="empty-div">
  <blue-modal [id]="modalId" cssClass="checked-in-summary-popup" [showCloseBtn]="false">
    <!-- 
      <h2>{{ 'You are being redirected' | translate: 'session-warning'}}</h2>
      <h2>{{ 'Sorry, you cannot go to that page now, please start over.' | translate: 'session-warning'}}</h2>
   -->
    <h2>{{'emptysession.header' | translate: 'session-warning' }}</h2>
    <h2>{{'emptysession.description' | translate: 'session-warning'}}</h2>

    <button type="button" class="btn btn-primary" (click)="startOver()">
      {{'emptysession.buttontext' | translate:'session-warning' }}
    </button>
  </blue-modal>
</div>