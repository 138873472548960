<div class="form-wrapper-middle-body">
  <h2 class="pageTitle no-padding">{{ 'rainbow.upsellTitle' | translate: 'rainbow.upsell' }}</h2><div>
    <button class="mobile-submit back-arrow " (click)="goBackToFlightSelect()">
      {{'Back'|translate}}
    </button>
  <div class="vertical-columns">
    <button class="col" *ngFor="let item of promoItems; let i = index" (click)="selectBenefit(item)"
      [ngClass]="{'selected':selectedBenefitType === item.promoId}">
      <div class="image-holder"><img src="{{ item.promoId + '.promo.upsellThumbnail' | translate: 'rainbow.upsell' }}">
      </div>
      <div class="text-holder">
        <h3>{{ item.promoId + '.promo.title' | translate: 'rainbow.header' }}</h3>
        <p innerHtml="{{ item.promoId + '.promo.upsellDescription' | translate: 'rainbow.upsell' }}"></p>
        <span>{{item.price | currency: item.currencyCode}}</span>
      </div>
    </button>
  </div>
  

  <br>
  <button class="btn btn-primary btn-blue wide back-arrow" (click)="goBackToFlightSelect()">
    {{'Back'|translate}}
  </button>