import { TranslateService } from './../common-modules/blue-air-common/translator/translate.service';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { FlowManagerService } from '../core/flow-manager.service';

@Pipe({
  name: 'currencyDisplay'
})

export class CurrencyDisplayPipe extends CurrencyPipe implements PipeTransform {

  constructor(private flowManager: FlowManagerService, translateService: TranslateService) {
    super(translateService.culture);
  }

  transform(value: any, symbolDisplay?: boolean | any): any {
    return super.transform(value, this.flowManager.applicationFlowService.currency, symbolDisplay);
  }

}
