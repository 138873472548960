/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-error.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./form-error.component";
var styles_FormErrorComponent = [i0.styles];
var RenderType_FormErrorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FormErrorComponent, data: {} });
export { RenderType_FormErrorComponent as RenderType_FormErrorComponent };
function View_FormErrorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_FormErrorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "Field is required")); _ck(_v, 1, 0, currVal_0); }); }
export function View_FormErrorComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "span", [["class", "error-txt"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormErrorComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FormErrorComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.customErrorText; _ck(_v, 3, 0, currVal_1); var currVal_2 = !_co.customErrorText; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.isVisible || !(_co.reference && (_co.reference.valid || _co.reference.pristine))) ? "block" : ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_FormErrorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-form-error", [], null, null, null, View_FormErrorComponent_0, RenderType_FormErrorComponent)), i1.ɵdid(1, 114688, null, 0, i5.FormErrorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FormErrorComponentNgFactory = i1.ɵccf("profile-form-error", i5.FormErrorComponent, View_FormErrorComponent_Host_0, { customErrorText: "customErrorText", reference: "reference", isVisible: "isVisible" }, {}, ["*"]);
export { FormErrorComponentNgFactory as FormErrorComponentNgFactory };
