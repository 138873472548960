import * as tslib_1 from "tslib";
import { Constants } from './../../constants';
import { BookingService } from './../../core/booking.service';
import { BookingFlowService } from './../../core/booking-flow.service';
import { OnInit, EventEmitter, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { BookingFlightSelectService } from '../booking-flight-select.service';
import * as moment from 'moment';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { Subscription, Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { BookingSelectionService } from '../../core/booking-selection.service';
import { ProfileService } from 'src/app/core/profile.service';
import { SsrTranslatePipe } from 'src/app/shared/ssr-translate.pipe';
import { map } from 'rxjs/operators';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { BookingFareSelectSyncService } from './booking-fare-select-sync-service.service';
import { BundleService } from 'src/app/shared/bundle.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { BookingStepsService, ApplicationFlowEnum } from '../../core/booking-steps.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';
var BookingFareSelectComponent = /** @class */ (function () {
    function BookingFareSelectComponent(sellService, modalService, translateService, bookingFlowService, bookingService, selectionService, renderer, ecommerce, ssrTranslate, userStateService, bundleService, bookingStepsFlowService, bookingFareSelectSyncService, loadingSpinnerService, flowManager, profileService) {
        var _this = this;
        this.sellService = sellService;
        this.modalService = modalService;
        this.translateService = translateService;
        this.bookingFlowService = bookingFlowService;
        this.bookingService = bookingService;
        this.selectionService = selectionService;
        this.renderer = renderer;
        this.ecommerce = ecommerce;
        this.ssrTranslate = ssrTranslate;
        this.userStateService = userStateService;
        this.bundleService = bundleService;
        this.bookingStepsFlowService = bookingStepsFlowService;
        this.bookingFareSelectSyncService = bookingFareSelectSyncService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.flowManager = flowManager;
        this.tripSubject = new BehaviorSubject(null);
        this.isBlueBenefitsSelectedEvent = new EventEmitter();
        this.isBlueBenefitsSelected = false;
        this.flights = [];
        this.isSame = false;
        this.sortedAvailableBundles = [];
        this.availableBundles = [];
        this.commonSsrs = [];
        this.ecommerceCartItems = [];
        this.incorrectSelectionModalId = "incorrectSelectionModalId";
        this.promortionLink = '/';
        this.allAvailableBundles = [];
        this.basePrices = [];
        this.bundleV2 = [];
        this.spinnerId = 'bundleSpinnerId';
        this.currentSelectedAmount = 0;
        this.datechanged = new EventEmitter();
        this.isChangeSearchOpenedEvent = new EventEmitter();
        this.onFareSold = new EventEmitter();
        this.showBlueBenefits = this.sellService.showBlueBenefits;
        this.userSearches = sellService.hubDisplayResponse;
        this.currentSelection = {
            flightKey: '',
            fare: '',
            price: 0
        };
        this.bookingCurrency = bookingFlowService.currency;
        this.assetsPath = environment.assetsPath;
        this.imagesPath = environment.imagesPath;
        this.defaultBundleImage = this.assetsPath + 'svg/ico-checked-sign-white.svg';
        this.isAgent = profileService.isAgent;
        this.selectionFlightsSubscription = this.selectionService.flightsObs.subscribe(function (f) {
            _this.flights = f || [];
        });
        this.bookingTripsSubscription = this.bookingService.bookingObs.subscribe(function (booking) {
            if (booking && booking.convertedJourneys) {
                _this.oldJourneyFares = booking.convertedJourneys.oldJourneyFares;
                _this.bookingSnapshot = booking.convertedJourneys.snapshotBooking;
            }
        });
        this.sellService.resetSelectedFlightsList();
        this.consularTextMemberSubscription = combineLatest([
            this.tripSubject,
            this.userStateService.isUserLoginDisabled.pipe(map(function (info) { return info.isDisabled ? info.agent : null; }))
        ]).subscribe(function (_a) {
            var _b = tslib_1.__read(_a, 2), currentTrip = _b[0], agentName = _b[1];
            if (currentTrip && window.getConsularMessage) {
                var departureDate = (currentTrip.flightDates && currentTrip.flightDates[0]) ?
                    moment(currentTrip.flightDates[0].departureDate).toDate() : null;
                _this.consularText = window.getConsularMessage(_this.translateService.culture, agentName, currentTrip.origin, currentTrip.destination, departureDate);
            }
            else {
                _this.consularText = '';
            }
        });
    }
    Object.defineProperty(BookingFareSelectComponent.prototype, "tripModel", {
        set: function (t) {
            var _this = this;
            this.tripSubject.next(t);
            if (t === null)
                return;
            this.trip = t;
            this.tripDate = t.flightDates[0].departureDate;
            if (this.trip.serviceBundleOffers) {
                this.availableBundles = this.trip.serviceBundleOffers.items.filter(function (item) { return item.serviceBundleType === "Included" &&
                    _this.getFlightWithBundleCode(_this.trip.flightDates.map(function (t) { return t.flights; }), item.serviceBundleCode); });
                var numberOfCarriers = t.flightDates.map(function (p) { return p.flights; }).reduce(function (a, b) { return a.concat(b); }, []).map(function (p) { return p.legs; })
                    .reduce(function (a, b) { return a.concat(b); }, []).map(function (p) { return p.legInfo.operatingCarrier; }).filter(function (v, i, a) { return a.indexOf(v) === i; }).length;
                this.initializeAvailableBundleList();
                if (numberOfCarriers > 1) {
                    this.sortBundlesByCarrierCode(t);
                }
                this.addNotAvailableFares();
                // setTimeout(_ => {
                //   const firstFlightDate = this.trip.flightDates[0];
                //   if (firstFlightDate) {
                //     const firstFlight = firstFlightDate.flights[0];
                //     if (firstFlight.basePrice.length > 0) {
                //       let minimumValueElement = firstFlight.basePrice[1];
                //       this.sellFare(firstFlight, minimumValueElement, true);
                //     };
                //   }
                // })
            }
        },
        enumerable: true,
        configurable: true
    });
    BookingFareSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        // if only one journey is selected for change, remember it's trip index
        if (this.bookingSnapshot && !this.sellService.searchModel.isRoundTrip) {
            this.tripIndex = Number(sessionStorage.getItem(Constants.SelectedFlightIndexForChange));
        }
        this.isMoveFlightsFlow = JSON.parse(sessionStorage.getItem(Constants.MoveFlightsFlow));
        this._autoSellEventSubscription = this.autoSellFareEvent.subscribe(function (isBlueBenefitSelected) {
            if (_this.currentSelection.flightKey) {
                return;
            }
            var firstFlightDate = _this.trip.flightDates[0];
            if (!firstFlightDate) {
                return;
            }
            var firstFlight = firstFlightDate.flights[0];
            if (!firstFlight) {
                return;
            }
            var fareToSellOnThisTrip = firstFlight.basePrice[0];
            if (!fareToSellOnThisTrip) {
                return;
            }
            _this.sellFare(firstFlight, fareToSellOnThisTrip, isBlueBenefitSelected, true);
        });
    };
    BookingFareSelectComponent.prototype.ngOnDestroy = function () {
        if (this.selectionFlightsSubscription) {
            this.selectionFlightsSubscription.unsubscribe();
        }
        if (this.bookingTripsSubscription) {
            this.bookingTripsSubscription.unsubscribe();
        }
        if (this.consularTextMemberSubscription) {
            this.consularTextMemberSubscription.unsubscribe();
        }
        if (this._autoSellEventSubscription) {
            this._autoSellEventSubscription.unsubscribe();
        }
    };
    BookingFareSelectComponent.prototype.setCommonSsrsAndRemoveFromBundles = function () {
        var _this = this;
        if (this.availableBundles.length > 1) {
            this.commonSsrs = this.availableBundles
                .map(function (x) { return x.serviceBundlePriceList; })
                .reduce(function (a, b) { return a.concat(b); }, [])
                .map(function (x) { return x.serviceBundleSsrPriceList; })
                .reduce(function (a, b) { return a.filter(function (c) { return b.some(function (d) { return d.ssrCode === c.ssrCode; }); }); })
                .map(function (x) { return x.ssrCode; });
            this.availableBundles.forEach(function (availableBundle) {
                for (var i = 0; i < availableBundle.serviceBundlePriceList[0].serviceBundleSsrPriceList.length; i++) {
                    if (_this.commonSsrs.includes(availableBundle.serviceBundlePriceList[0].serviceBundleSsrPriceList[i].ssrCode)) {
                        availableBundle.serviceBundlePriceList[0].serviceBundleSsrPriceList.splice(i, 1);
                        i--;
                    }
                }
            });
        }
    };
    BookingFareSelectComponent.prototype._hasTheNewBundles = function (availableBundles) {
        var e_1, _a, e_2, _b;
        if (!availableBundles) {
            return false;
        }
        var newBundleCodes = ['ABAS', 'BPLU', 'CBIZ'];
        try {
            for (var availableBundles_1 = tslib_1.__values(availableBundles), availableBundles_1_1 = availableBundles_1.next(); !availableBundles_1_1.done; availableBundles_1_1 = availableBundles_1.next()) {
                var bundle = availableBundles_1_1.value;
                try {
                    for (var bundle_1 = tslib_1.__values(bundle), bundle_1_1 = bundle_1.next(); !bundle_1_1.done; bundle_1_1 = bundle_1.next()) {
                        var keyValue = bundle_1_1.value;
                        if (newBundleCodes.includes(keyValue.value)) {
                            return true;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (bundle_1_1 && !bundle_1_1.done && (_b = bundle_1.return)) _b.call(bundle_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (availableBundles_1_1 && !availableBundles_1_1.done && (_a = availableBundles_1.return)) _a.call(availableBundles_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    BookingFareSelectComponent.prototype.initializeAvailableBundleList = function () {
        var _this = this;
        this.addBundleInList(/^PR/);
        this.addBundleInList(/^CO/);
        this.addBundleInList(/^CM/);
        this.addBundleInList(/^CL/);
        this.addBundleInList(/^EX/);
        this.addBundleInList(/^LI/);
        this.addBundleInList(/^AB/);
        this.addBundleInList(/^BB/);
        this.addBundleInList(/^CB/);
        this.addBundleInList(/^NL/);
        if (this.trip.flightDates[0].flights.length > 0) {
            var bundleIndexes = this.trip.flightDates[0].flights[0].fares[0].serviceBundleOfferIndexes;
            var bundleCodes = this.bookingService.getBooking().bundleDetail.bundleCodes;
            if (this.bookingStepsFlowService.flow === ApplicationFlowEnum.ManageBooking && this._hasTheNewBundles(bundleCodes)) {
                var bookingBundleCode_1 = bundleCodes[0][0].value;
                var bookingBundleCodeIndex_1 = this.trip.serviceBundleOffers.items.findIndex(function (b) { return b.serviceBundleCode === bookingBundleCode_1; });
                bundleIndexes = this.trip.flightDates[0].flights[0].fares[0].serviceBundleOfferIndexes.filter(function (i) { return i == bookingBundleCodeIndex_1; });
            }
            //Add bundle to array
            var carrierCode_1 = this.trip.flightDates[0].flights[0].fares[0].carrierCode;
            bundleIndexes.forEach(function (index) {
                var bundle = _this.trip.serviceBundleOffers.items[index];
                bundle.carrierCode = carrierCode_1;
                _this.bundleV2.push(bundle);
            });
            //Remove CKN ssrs from bundle
            this.bundleV2.forEach(function (bundle) {
                bundle.serviceBundlePriceList.forEach(function (priceList) {
                    if (priceList.serviceBundleSsrPriceList.find(function (ssr) { return ssr.ssrCode == 'CKN'; })) {
                        var index = priceList.serviceBundleSsrPriceList.findIndex(function (ssr) { return ssr.ssrCode == 'CKN'; });
                        if (index > -1) {
                            priceList.serviceBundleSsrPriceList.splice(index, 1);
                        }
                    }
                });
            });
        }
    };
    BookingFareSelectComponent.prototype.addBundleInList = function (matchString) {
        var bundles = this.availableBundles.filter(function (b) { return b.serviceBundleCode.match(matchString); });
        if (bundles.length > 0) {
            this.sortedAvailableBundles.push(bundles);
        }
    };
    BookingFareSelectComponent.prototype.isFareForBundle = function (fares, bundleCodes) {
        var fareExists = false;
        fares.map(function (f) { return f.defaultIncludedBundleCode; }).forEach(function (fareBundleCode) {
            if (bundleCodes.indexOf(fareBundleCode) > -1) {
                fareExists = true;
            }
        });
        return fareExists;
    };
    BookingFareSelectComponent.prototype.getFlightWithBundleCode = function (flights, bundleCode) {
        return flights.reduce(function (a, b) { return a.concat(b); }, []).filter(function (f) { return f.fares.filter(function (fare) { return fare.defaultIncludedBundleCode === bundleCode; }).length > 0; })[0];
    };
    BookingFareSelectComponent.prototype.getFlightCarrierCode = function (flights, bundleCode) {
        var flight = this.getFlightWithBundleCode(flights, bundleCode);
        var carrierCode = flight.legs.reduce(function (a, b) { return a.concat(b); }, []).map(function (p) { return p.legInfo.operatingCarrier; });
        return carrierCode != '' ? carrierCode : flight.carrierCode;
    };
    BookingFareSelectComponent.prototype.sortBundlesByCarrierCode = function (trip) {
        var _this = this;
        this.sortedAvailableBundles.forEach(function (bundleList) {
            if (bundleList.length > 1) {
                bundleList.forEach(function (value, index) {
                    var carrierCode = _this.getFlightCarrierCode(trip.flightDates.map(function (p) { return p.flights; }), value.serviceBundleCode);
                    // add carrier code on bundle info
                    value.carrierCode = carrierCode.length == 1 ? carrierCode[0] : carrierCode;
                    if (carrierCode === '0B') {
                        // if already on first position do nothing
                        if (!(index === 0)) {
                            // delete value
                            bundleList.splice(index, 1);
                            // move value on first position
                            bundleList.unshift(value);
                        }
                    }
                });
            }
        });
    };
    BookingFareSelectComponent.prototype.addNotAvailableFares = function () {
        var _this = this;
        this.trip.flightDates.forEach(function (fd) {
            fd.flights.forEach(function (flight) {
                flight.basePrice = [];
                if (_this.sortedAvailableBundles.length > 0) {
                    flight.basePrice.push(flight.fares.filter(function (b) { return b.defaultIncludedBundleCode.match(_this.sortedAvailableBundles[_this.sortedAvailableBundles.length - 1][0].serviceBundleCode); })[0]);
                    flight.bundlePrices = [flight.basePrice[0]];
                }
                // if fares number is smaller than available bundles number
                if (flight.fares.length < _this.sortedAvailableBundles.length) {
                    _this.sortedAvailableBundles.forEach(function (bundleList, bundleListIndex) {
                        var bundleCodes = bundleList.map(function (b) { return b.serviceBundleCode; });
                        // if there is no fare for specified bundle, add not available fare on correct index
                        if (!(_this.isFareForBundle(flight.fares, bundleCodes))) {
                            flight.fares.splice(bundleListIndex, 0, {
                                "passengerFares": [{
                                        "notAvailable": true,
                                        "discountedFare": '0',
                                        "publishedFare": '0',
                                        "fareAmount": '0',
                                        "serviceCharges": { "items": [] }
                                    }]
                            });
                        }
                    });
                }
                else {
                    if (flight.fares.length === _this.sortedAvailableBundles.length) {
                        var result_1 = true;
                        var sortedAvailableBundles_1 = [];
                        sortedAvailableBundles_1 = _this.sortedAvailableBundles.reduce(function (a, b) { return a.concat(b); }, []);
                        flight.fares.forEach(function (fare, index) {
                            if (fare.defaultIncludedBundleCode !== sortedAvailableBundles_1[index].serviceBundleCode) {
                                result_1 = false;
                            }
                        });
                        if (!result_1) {
                            flight.fares.forEach(function (f) {
                                f.sortedAvailableBundleIndex = sortedAvailableBundles_1.findIndex(function (s) { return s.serviceBundleCode === f.defaultIncludedBundleCode; });
                            });
                            flight.fares.sort(function (a, b) { return (a.sortedAvailableBundleIndex > b.sortedAvailableBundleIndex) ? 1 : -1; });
                        }
                    }
                }
            });
        });
    };
    BookingFareSelectComponent.prototype.isValidPromo = function (fare) {
        if (this.showBlueBenefits) {
            return false;
        }
        return this.getCorrectAmount(this.getFullPrice(fare)) >
            this.getCorrectAmount(fare.passengerFares[0].discountedFare);
    };
    BookingFareSelectComponent.prototype.isPromo = function (fare) {
        if (this.showBlueBenefits) {
            return false;
        }
        return this.getCorrectAmount(fare.passengerFares[0].publishedFare) !=
            this.getCorrectAmount(fare.passengerFares[0].discountedFare);
    };
    BookingFareSelectComponent.prototype.getFarePrice = function (fare, isBlueBenefitsSelected) {
        // return this.getCorrectAmount(isBlueBenefitsSelected || this.isPromo(fare) ?
        //   fare.passengerFares[0].fareAmount : fare.passengerFares[0].publishedFare);
        return this.getCorrectAmount(fare.passengerFares[0].fareAmount);
    };
    BookingFareSelectComponent.prototype.getCorrectAmount = function (fareAmount) {
        var price = this.oldJourneyFares != null ? fareAmount - this.oldJourneyFares[this.tripIndex].value : fareAmount;
        // const price = fareAmount;
        return price < 0 ? 0 : price;
    };
    BookingFareSelectComponent.prototype.getConnectionTime = function (segments) {
        var firstSegmetn = segments[0];
        var lastSegmetn = segments.reverse()[0];
        return; //this.durationPipe.transform(firstSegmetn.sta, lastSegmetn.std, false);
    };
    BookingFareSelectComponent.prototype.howManiUsers = function (fd) {
        if (fd.flightCount == 0 || !this.userSearches.length)
            return 0;
        var key = fd.flights[0].origin + fd.flights[0].destination + moment(fd.departureDate).format("YYYY/MM/DD");
        var s = this.userSearches.find(function (p) { return p.searchedRoute == key; });
        if (s)
            return s.count;
        else
            return 0;
    };
    BookingFareSelectComponent.prototype.isBus = function (legs, segment) {
        var leg = legs.find(function (l) { return l.arrivalStation === segment.arrivalStation && l.departureStation === segment.departureStation; });
        if (leg && leg.legInfo.equimentType === "BUS")
            return true;
    };
    BookingFareSelectComponent.prototype.getFullPrice = function (price) {
        return price.passengerFares[0].serviceCharges.items.filter(function (p) { return p.chargeType == "FarePrice"; }).map(function (p) { return p.amount; }).reduce(function (a, b) { return a + b; }, 0) +
            price.passengerFares[0].serviceCharges.items.filter(function (p) { return p.chargeType == "FareSurcharge"; }).map(function (p) { return p.amount; }).reduce(function (a, b) { return a + b; }, 0);
    };
    BookingFareSelectComponent.prototype.noFlights = function (trip) {
        return trip.flightDates.map(function (p) { return p.flights; }).reduce(function (a, b) { return a.concat(b); }, []).length === 0;
    };
    BookingFareSelectComponent.prototype.isFareSelected = function (flight, fare, isBlueBenefitsSelected) {
        if (this.currentSelection.flightKey === flight.sellKey &&
            this.currentSelection.fare === fare.fareBasisCode &&
            this.isBlueBenefitsSelected === isBlueBenefitsSelected) {
            return true;
        }
        return false;
    };
    BookingFareSelectComponent.prototype.isBundleSelected = function (bundleCode, flight) {
        if (flight === void 0) { flight = null; }
        if (this.currentBundleSelected != this.bookingFareSelectSyncService.currentBundleSelected) {
            this.updateAllPriceSelected(this.bookingFareSelectSyncService.currentBundleSelected);
        }
        if (this.bookingFareSelectSyncService.isBlueBenefitsSelected != this.isBlueBenefitsSelected)
            this.isBlueBenefitsSelected = this.bookingFareSelectSyncService.isBlueBenefitsSelected;
        if (bundleCode == this.bookingFareSelectSyncService.currentBundleSelected)
            return true;
        return false;
    };
    BookingFareSelectComponent.prototype.checkState = function (price, flight, currentSelection, isBlueBenefitsSelected) {
        return (!price.passengerFares[0].notAvailable && !price.disableFare) && this.isFlightSelected(flight, price, isBlueBenefitsSelected)
            // TODO: why is revenueFare used here !?
            && currentSelection.price < this.getCorrectAmount(this.showBlueBenefits ? price.passengerFares[0].publishedFare : price.passengerFares[0].revenueFare)
            && this.getFarePrice(price, isBlueBenefitsSelected) > currentSelection.price
            && this.isBlueBenefitsSelected === isBlueBenefitsSelected;
    };
    BookingFareSelectComponent.prototype.isFlightSelected = function (flight, fare, isBlueBenefitsSelected) {
        if (this.currentSelection.flightKey === flight.sellKey && this.currentSelection.fare !== fare.fareBasisCode &&
            this.isBlueBenefitsSelected === isBlueBenefitsSelected) {
            return true;
        }
        return false;
    };
    BookingFareSelectComponent.prototype.isCurrentSelection = function (flight) {
        if (this.currentSelection.flightKey === flight.sellKey) {
            return true;
        }
        return false;
    };
    BookingFareSelectComponent.prototype.isNextDay = function (departureDate, arrivalDate) {
        if (moment(departureDate).date() !== moment(arrivalDate).date()) {
            return true;
        }
    };
    BookingFareSelectComponent.prototype.sellFare = function (flight, fare, isBlueBenefitSelected, isFromAutoSell, autoSelect) {
        var _this = this;
        if (autoSelect === void 0) { autoSelect = false; }
        this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
        if (this.bookingStepsFlowService.flow === ApplicationFlowEnum.ManageBooking) {
            this.updateAllPriceSelected(this.bundleV2[0].serviceBundleCode);
            fare = flight.basePrice[0];
        }
        var sellFareObservable = this._sellFareWithoutSpinner(flight, fare, isBlueBenefitSelected, isFromAutoSell);
        if (sellFareObservable) {
            var subscription_1 = sellFareObservable.subscribe(function () {
                subscription_1.unsubscribe();
                // if (autoSelect && fare.defaultIncludedBundleCode !== "BPLU") {
                //   this.selectBundleByCode(flight, "BPLU");
                // }
                _this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
            });
        }
        else {
            this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
        }
    };
    BookingFareSelectComponent.prototype._sellFareWithoutSpinner = function (flight, fare, isBlueBenefitSelected, isFromAutoSell) {
        var _this = this;
        this.bookingFareSelectSyncService.currentBundleSelected = fare.defaultIncludedBundleCode;
        this.bookingFareSelectSyncService.isBlueBenefitsSelected = isBlueBenefitSelected;
        this.currentBundleSelected = fare.defaultIncludedBundleCode;
        this.toggleFares = true;
        var openPopup = false;
        if (this.sellService.searchModel.isRoundTrip && this.flights[0] &&
            moment(this.sellService.searchModel.departureDate).isSame(moment(this.sellService.searchModel.returnDate))) {
            var isOutboundFirst = flight.origin !== this.sellService.searchModel.origin ? true : false;
            if (isOutboundFirst) {
                if (flight.origin !== this.flights[0].departureStation.iataCode &&
                    moment(this.flights.find(function (f) { return f.departureStation.iataCode !== flight.origin; }).arrivalStation.date).isAfter(moment(flight.standardTimeOfDeparture))) {
                    openPopup = true;
                }
            }
            else {
                if (this.flights.length >= 1 && flight.origin !== this.flights[this.flights.length - 1].departureStation.iataCode &&
                    moment(this.flights.find(function (f) { return f.departureStation.iataCode !== flight.origin; }).departureStation.date).isBefore(moment(flight.standardTimeOfArrival))) {
                    openPopup = true;
                }
            }
        }
        if (openPopup) {
            this.incorrectSelectionModal.openPopup(({}));
        }
        else {
            var id_1 = '';
            flight.segments.forEach(function (s) {
                id_1 = id_1 + s.flightDesignator.carrierCode + ' ' + s.flightDesignator.flightNumber + ' ' + moment(s.std).format('DD-MM-YYYY HH:mm:ss') + ' ';
            });
            var total_1 = 0;
            fare.passengerFares.forEach(function (f) {
                var paxType = f.paxType.substring(0, 3);
                if (paxType !== Constants.InfantSsrCode) {
                    total_1 = total_1 + f.fareAmount * _this.sellService.searchModel.passengers.find(function (p) { return p.paxType.substring(0, 3) === paxType; }).count;
                }
            });
            var ecommerceCartItems = new Array();
            var item = new ECommerceCartItem(flight.origin + '-' + flight.destination, id_1, total_1.toString(), 'BlueAir', fare.serviceBundleSetCode ? fare.serviceBundleSetCode.toString() : null, this.sellService.searchModel.isRoundTrip ? Constants.RoundTrip : Constants.OneWayTrip, 1, this.sellService.searchModel.isRoundTrip ? (flight.origin !== this.sellService.searchModel.origin ? 1 : 0) : 0, null, this.ecommerce.getUserData())
                .getItem(true);
            ecommerceCartItems.push(item);
            this.ecommerce.ProductClicks(ecommerceCartItems);
            // if (this.currentSelection.fare === fare.fareBasisCode && this.currentSelection.flightKey === flight.sellKey) {
            //   // if same flight and same fare is selected, remove current selection from view
            //   this.currentSelection = {
            //     flightKey: '',
            //     fare: '',
            //     price: 0
            //   };
            //   if (this.isBlueBenefitsSelected != isBlueBenefitSelected) {
            //     // if same flight and same fare are selected BUT 'isBlueBenefitsSelected' param is different:
            //     // - the first selection was already removed
            //     // - select the other price
            //     this.setCurrentSelection(fare, flight, isBlueBenefitSelected);
            //   } else {
            //     flight = null;
            //     fare = null;
            //   }
            // } else {
            //   this.setCurrentSelection(fare, flight, isBlueBenefitSelected);
            // }
            this.setCurrentSelection(fare, flight, isBlueBenefitSelected);
            this.isBlueBenefitsSelected = isBlueBenefitSelected;
            this.sellService.isBlueBenefitsSelected = isBlueBenefitSelected;
            this.isBlueBenefitsSelectedEvent.emit(this.isBlueBenefitsSelected);
            // if blue benefits prices are shown and
            // price selected is without blue benefits don't substract discount
            // from final fare price in order to display correct price in shopping cart
            var updatePriceItineraryResult = this.sellService.updatePriceItinerary(flight, fare, this.tripIndex);
            if (!isFromAutoSell) {
                var subscription_2 = updatePriceItineraryResult.subscribe(function () {
                    _this.onFareSold.emit({
                        tripIndex: _this.tripIndex,
                        isBlueBenefits: isBlueBenefitSelected
                    });
                    subscription_2.unsubscribe();
                });
            }
            return updatePriceItineraryResult;
        }
    };
    BookingFareSelectComponent.prototype.emptyCurrentSelection = function () {
        // deselect current flight
        this.currentSelection = {
            flightKey: '',
            fare: '',
            price: 0
        };
        // remove current flight from cart
        this.sellService.updatePriceItinerary(null, null, this.tripIndex);
    };
    BookingFareSelectComponent.prototype.setCurrentSelection = function (fare, flight, isBlueBenefitSelected) {
        this.currentSelection.fare = fare.fareBasisCode;
        this.currentSelection.flightKey = flight.sellKey;
        this.currentSelection.price = this.getCorrectAmount(isBlueBenefitSelected || this.isPromo(fare) ?
            fare.passengerFares[0].fareAmount : fare.passengerFares[0].publishedFare);
    };
    BookingFareSelectComponent.prototype.changeDay = function (i) {
        var x = i == 0 ? -1 : 1;
        this.changeDate(moment(this.tripDate).add(x, "days"));
    };
    BookingFareSelectComponent.prototype.changeDate = function (date) {
        if (moment(date).diff(moment(), 'day') >= 0) {
            var ss = this.sellService;
            if (ss.searchModel.origin === this.trip.origin) {
                ss.searchModel.departureDate = date;
            }
            else {
                ss.searchModel.returnDate = date;
            }
            if (ss.searchModel.returnDate) {
                if (moment(ss.searchModel.departureDate).isAfter(moment(ss.searchModel.returnDate))) {
                    var days = moment(ss.searchModel.departureDate).diff(moment(ss.searchModel.returnDate), 'day');
                    if (this.tripIndex === 0) {
                        ss.searchModel.returnDate = (moment(ss.searchModel.returnDate).add(days, 'day')).toString();
                        this.resetCart();
                    }
                    else {
                        ss.searchModel.departureDate = (moment(ss.searchModel.departureDate).add(-days, 'day')).toString();
                        this.resetCart();
                    }
                }
            }
            this.datechanged.next();
        }
    };
    BookingFareSelectComponent.prototype.resetCart = function () {
        this.sellService.resetPriceItinerary();
        this.selectionService.updateData(null, null);
        this.bookingFlowService.updateShoppingCartFromItinerary(null, true);
    };
    BookingFareSelectComponent.prototype.openModalDialog = function (f) {
        this.modalService.open(f, function () { });
    };
    BookingFareSelectComponent.prototype.getOperatedText = function (l) {
        var _this = this;
        var legs = new Array();
        var operatedText = new Array();
        // if (l.operatingCarrierInfo.carrierCode && l.operatingCarrierInfo.flightNumber) {
        //   operatedText.push(this.translateService.instant('Operated by {0} as {1}', null, [
        //     this.translateService.instant(l.operatingCarrierInfo.carrierCode, 'operatingcarrier.name'),
        //     l.operatingCarrierInfo.carrierCode + l.operatingCarrierInfo.flightNumber
        //   ]));
        //   return operatedText;
        // } else {
        legs = l.legs.map(function (p) { return p.legInfo; }).reduce(function (a, b) { return a.concat(b); }, [])
            .filter(function (p) { return p.codeShareIndicator === 'L' || p.codeShareIndicator === 'S'; });
        if (legs.length > 0) {
            legs.forEach(function (f) {
                operatedText.push(_this.translateService.instant('Operated by {0} as {1}', null, [
                    _this.translateService.instant(f.operatingCarrier, 'operatingcarrier.name'),
                    f.operatingCarrier + f.operatingFlightNumber
                ]));
            });
            return operatedText;
        }
        // }
    };
    BookingFareSelectComponent.prototype.getNumberOfStops = function (flight) {
        var stops = new Array();
        for (var i = 0; i < flight.segments.length - 1; i++) {
            if (stops.indexOf(flight.segments[i].arrivalStation) == -1) {
                stops.push(flight.segments[i].arrivalStation);
            }
        }
        return stops.length;
    };
    BookingFareSelectComponent.prototype.toggleVisibility = function (th) {
        if (th.classList.contains('show')) {
            this.renderer.removeClass(th, 'show');
        }
        else {
            this.renderer.addClass(th, 'show');
        }
    };
    BookingFareSelectComponent.prototype.toggleVisibilityDesktop = function () {
        this.multipleBundleList = !this.multipleBundleList;
    };
    BookingFareSelectComponent.prototype.toggleSearch = function (newValue) {
        if (newValue === void 0) { newValue = !this.isChangeSearchOpened; }
        // this.isChangeSearchOpened = newValue;
        this.isChangeSearchOpenedEvent.emit(this.isChangeSearchOpened);
    };
    BookingFareSelectComponent.prototype.getFareBundleText = function (price, departureDate) {
        var _this = this;
        var bundleCode = price.defaultIncludedBundleCode;
        if (bundleCode && this.availableBundles
            .filter(function (x) { return x.serviceBundleCode === bundleCode; })[0]) {
            var currentBundleItems = this.availableBundles
                .filter(function (x) { return x.serviceBundleCode === bundleCode; })[0].serviceBundlePriceList
                .map(function (x) { return x.serviceBundleSsrPriceList; }).reduce(function (a, b) { return a.concat(b); }, [])
                .map(function (x) { return _this.ssrTranslate.transform(x.ssrCode, bundleCode, departureDate); });
            return currentBundleItems.join(', ');
        }
        return '';
    };
    BookingFareSelectComponent.prototype.getBundleImage = function (bundleCode, ssrCode) {
        var myUrl = this.imagesPath + this.translateService.instant(bundleCode + "." + ssrCode, 'BundleImages');
        return myUrl;
    };
    BookingFareSelectComponent.prototype.flightHasBenefitDiscount = function (flight) {
        return flight.fares.every(function (f) { return f.passengerFares[0].publishedFare != f.passengerFares[0].fareAmount; });
    };
    BookingFareSelectComponent.prototype.selectBundle = function (bundle, flight) {
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        this.bookingFareSelectSyncService.currentBundleSelected = bundle.serviceBundleCode;
        //this.bookingFareSelectSyncService.isBlueBenefitsSelected = this.isBlueBenefitsSelected;
        this.updateAllPriceSelected(bundle.serviceBundleCode);
        var fare = flight.bundlePrices.filter(function (p) { return p.defaultIncludedBundleCode.match(bundle.serviceBundleCode); })[0];
        this.sellFare(flight, fare, this.isBlueBenefitsSelected);
        this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
    };
    BookingFareSelectComponent.prototype.selectBundleByCode = function (flight, bundleCode) {
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        this.bookingFareSelectSyncService.currentBundleSelected = bundleCode;
        //this.bookingFareSelectSyncService.isBlueBenefitsSelected = this.isBlueBenefitsSelected;
        this.updateAllPriceSelected(bundleCode);
        var fare = flight.bundlePrices.filter(function (p) { return p.defaultIncludedBundleCode.match(bundleCode); })[0];
        this.sellFare(flight, fare, false, false, true);
        this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
    };
    BookingFareSelectComponent.prototype.updateAllPriceSelected = function (bundleCode) {
        var _this = this;
        this.trip.flightDates.forEach(function (fd) {
            fd.flights.forEach(function (flight) {
                if (!flight.bundlePrices.filter(function (p) { return p.defaultIncludedBundleCode.match(bundleCode); })[0]) {
                    _this.addFlightBundlePrice(flight, bundleCode);
                }
                var fare = flight.bundlePrices.filter(function (p) { return p.defaultIncludedBundleCode.match(bundleCode); })[0];
                flight.basePrice[0] = fare;
            });
        });
    };
    BookingFareSelectComponent.prototype.addFlightBundlePrice = function (flight, bundleCode) {
        var fare = JSON.parse(JSON.stringify(flight.fares[flight.fares.length - 1]));
        var bundlePrice = this.bundleV2.filter(function (b) { return b.serviceBundleCode.match(bundleCode); })[0].serviceBundlePriceList[0].price;
        var discountPrice = parseInt(fare.passengerFares[0].fareDiscountCode.replace(/\'/g, '').split(/(\d+)/).filter(Boolean)[0]);
        if (isNaN(discountPrice)) {
            discountPrice = 0;
        }
        fare.defaultIncludedBundleCode = bundleCode;
        var passengerFare = fare.passengerFares[0];
        var discountedFare = passengerFare.publishedFare - passengerFare.publishedFare * discountPrice / 100;
        //passengerFare.publishedFare = parseFloat(passengerFare.publishedFare + bundlePrice).toFixed(2);
        passengerFare.fareAmount = parseFloat(String(discountedFare + bundlePrice)).toFixed(2);
        flight.bundlePrices.push(fare);
    };
    BookingFareSelectComponent.prototype.getBundlePrice = function (flight, bundle, isBlueBenefitSelected) {
        var basePrice = flight.fares[flight.fares.length - 1].passengerFares[0].publishedFare;
        var extraPrice = bundle.serviceBundlePriceList[0].price;
        var discountPrice = parseInt(flight.fares[flight.fares.length - 1].passengerFares[0].fareDiscountCode.replace(/\'/g, '').split(/(\d+)/).filter(Boolean)[0]);
        var resultAmount = basePrice;
        if (!isNaN(discountPrice)) {
            resultAmount = parseFloat(basePrice) * (1 - discountPrice / 100);
        }
        var total = parseFloat(resultAmount + extraPrice);
        if (!this.isBundleSelected(bundle.serviceBundleCode, null)) {
            if (this.currentSelectedAmount < total) {
                return "+" + (total - this.currentSelectedAmount).toFixed(2);
            }
        }
        else {
            this.currentSelectedAmount = total;
        }
        return total.toFixed(2);
    };
    BookingFareSelectComponent.prototype.updateSellKeys = function (flight, tripIndex) {
        var index = this.sellService.sellKeys.findIndex(function (p) { return p.key === tripIndex; });
        flight.isMovedFlight = true;
        var fare = flight.fares[0];
        if ((flight == null || fare == null) && index >= 0) {
            this.sellService.sellKeys.splice(index, 1);
        }
        else {
            if (index >= 0) {
                this.sellService.sellKeys[index] = { key: tripIndex, value: fare.sellKey + '|' + flight.sellKey };
            }
            else {
                this.sellService.sellKeys.splice(tripIndex, 0, { key: tripIndex, value: fare.sellKey + '|' + flight.sellKey });
            }
        }
        if ((this.sellService.searchModel.isRoundTrip && this.sellService.sellKeys.length > 1) || (!this.sellService.searchModel.isRoundTrip && this.sellService.sellKeys.length === 1)) {
            this.sellService.canContinue = true;
        }
        else {
            this.sellService.canContinue = false;
        }
    };
    BookingFareSelectComponent.prototype.isMoveFlightSelected = function (flight) {
        if (flight.isMovedFlight) {
            return true;
        }
        else {
            return false;
        }
    };
    BookingFareSelectComponent.prototype.upgradeBundleFromPopup = function (bundleCode) {
        var _this = this;
        var fare = this.bundleV2.find(function (b) { return b.serviceBundleCode === bundleCode; });
        var flight = this.trip.flightDates[0].flights.find(function (f) { return f.sellKey === _this.flights[0].sellKey; });
        this.selectBundle(fare, flight);
    };
    return BookingFareSelectComponent;
}());
export { BookingFareSelectComponent };
