/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./deeplink.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "../../shared/form-wrapper/form-wrapper.component.ngfactory";
import * as i5 from "../../shared/form-wrapper/form-wrapper.component";
import * as i6 from "@angular/router";
import * as i7 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i8 from "../../core/booking-steps.service";
import * as i9 from "../../core/booking.service";
import * as i10 from "@angular/common";
import * as i11 from "./deeplink.component";
import * as i12 from "../../core/config.service";
import * as i13 from "../booking-flight-select.service";
import * as i14 from "../../core/profile.service";
import * as i15 from "../../common-modules/blue-air-common/session.service";
var styles_DeeplinkComponent = [i0.styles];
var RenderType_DeeplinkComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeeplinkComponent, data: {} });
export { RenderType_DeeplinkComponent as RenderType_DeeplinkComponent };
function View_DeeplinkComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "Please wait while we are setting up your search", "deeplink")); _ck(_v, 1, 0, currVal_0); }); }
function View_DeeplinkComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "Your search is incomplete or invalid.", "deeplink")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "You will be redirected to the home page in {0} seconds", "deeplink", _co.timer)); _ck(_v, 5, 0, currVal_1); }); }
export function View_DeeplinkComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "check-in-form-wrapper", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormWrapperComponent_0, i4.RenderType_FormWrapperComponent)), i1.ɵdid(2, 770048, null, 0, i5.FormWrapperComponent, [i6.Router, i7.LoadingSpinnerService, i8.BookingStepsService, i9.BookingService], { ariaLabel: [0, "ariaLabel"], step: [1, "step"], showPriceDisplay: [2, "showPriceDisplay"], showContinue: [3, "showContinue"] }, null), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, 5, 5, "div", [["class", "form-wrapper-top-body"], ["noNgForm", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["style", "height: 300px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeeplinkComponent_1)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DeeplinkComponent_2)), i1.ɵdid(9, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), "Setting up your search", "deeplink")), ""); var currVal_1 = "step-1"; var currVal_2 = false; var currVal_3 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = !_co.invalidSearch; _ck(_v, 7, 0, currVal_4); var currVal_5 = _co.invalidSearch; _ck(_v, 9, 0, currVal_5); }, null); }
export function View_DeeplinkComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-deeplink", [], null, null, null, View_DeeplinkComponent_0, RenderType_DeeplinkComponent)), i1.ɵdid(1, 114688, null, 0, i11.DeeplinkComponent, [i6.ActivatedRoute, i3.TranslateService, i12.ConfigService, i8.BookingStepsService, i13.BookingFlightSelectService, i7.LoadingSpinnerService, i14.ProfileService, i15.SessionService, i9.BookingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeeplinkComponentNgFactory = i1.ɵccf("check-in-deeplink", i11.DeeplinkComponent, View_DeeplinkComponent_Host_0, {}, {}, []);
export { DeeplinkComponentNgFactory as DeeplinkComponentNgFactory };
