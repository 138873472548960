import * as tslib_1 from "tslib";
import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import * as i0 from "@angular/core";
var CheckInLuggageModel = /** @class */ (function () {
    function CheckInLuggageModel(smallBagsNo, bigBagsNo, totalBagsNo, passengerSsr, $changeSmall, $changeBig) {
        this.smallBagsNo = smallBagsNo;
        this.bigBagsNo = bigBagsNo;
        this.totalBagsNo = totalBagsNo;
        this.passengerSsr = passengerSsr;
        this.$changeSmall = $changeSmall;
        this.$changeBig = $changeBig;
    }
    Object.defineProperty(CheckInLuggageModel.prototype, "upperDisabled", {
        get: function () {
            return this.totalBagsNo === 4;
        },
        enumerable: true,
        configurable: true
    });
    CheckInLuggageModel.prototype.getLowerDisabled = function (ssr) {
        return ssr.name === SMALL_BAG ? this.smallBagsNo === 0 : this.bigBagsNo === 0;
    };
    return CheckInLuggageModel;
}());
export { CheckInLuggageModel };
var SMALL_BAG = 'SBAG';
var BIG_BAG = 'BAG';
var CheckInLuggageService = /** @class */ (function () {
    function CheckInLuggageService() {
        this.$unsubscribe = new Subject();
        this.passengersLuggageModel = new Map();
        this.$checkingLuggageChanged = new Subject();
    }
    CheckInLuggageService.prototype.init = function (id, passengers) {
        var _this = this;
        if (!passengers)
            return;
        //delete the map key for the received flight unique id
        this.passengersLuggageModel.delete(id);
        passengers.forEach(function (ssrModel) {
            var flightId = ssrModel.passenger.flightUniqueId;
            var luggageModel = [];
            if (!_this.passengersLuggageModel.has(flightId)) {
                _this.passengersLuggageModel.set(flightId, luggageModel);
            }
            else {
                luggageModel = _this.passengersLuggageModel.get(flightId);
            }
            var model = ssrModel.ssrs.reduce(function (model, currentItem) {
                if (currentItem.name === SMALL_BAG) {
                    model.smallBagsNo = currentItem.selectedQuantity;
                }
                else {
                    model.bigBagsNo = currentItem.selectedQuantity;
                }
                model.totalBagsNo += currentItem.selectedQuantity;
                return model;
            }, new CheckInLuggageModel(0, 0, 0, tslib_1.__assign({}, ssrModel), new Subject(), new Subject()));
            model.$changeBig
                .pipe(takeUntil(_this.$unsubscribe), debounceTime(700)).subscribe(function (model) {
                _this.$checkingLuggageChanged.next(model);
            });
            model.$changeSmall
                .pipe(takeUntil(_this.$unsubscribe), debounceTime(700)).subscribe(function (model) {
                _this.$checkingLuggageChanged.next(model);
            });
            luggageModel.push(model);
        });
    };
    CheckInLuggageService.prototype.ngOnDestroy = function () {
        this.$unsubscribe.next();
        this.$unsubscribe.complete();
    };
    CheckInLuggageService.prototype.getForFlightId = function (flightId) {
        return this.passengersLuggageModel.get(flightId);
    };
    CheckInLuggageService.prototype.decreaseBagsNumber = function (id, index, model, bagType) {
        if (model.totalBagsNo > 0) {
            if (bagType === BIG_BAG) {
                if (model.bigBagsNo === 0) {
                    return;
                }
                model.bigBagsNo--;
                model.$changeBig.next({ id: id, index: index, ssrCode: bagType, qty: model.bigBagsNo });
            }
            else {
                if (model.smallBagsNo === 0) {
                    return;
                }
                model.smallBagsNo--;
                model.$changeSmall.next({ id: id, index: index, ssrCode: bagType, qty: model.smallBagsNo });
            }
            model.totalBagsNo--;
        }
    };
    CheckInLuggageService.prototype.increaseBagsNumber = function (id, index, model, bagType) {
        if (model.totalBagsNo < 4) {
            if (bagType === BIG_BAG) {
                model.bigBagsNo++;
                model.$changeBig.next({ id: id, index: index, ssrCode: bagType, qty: model.bigBagsNo });
            }
            else {
                model.smallBagsNo++;
                model.$changeSmall.next({ id: id, index: index, ssrCode: bagType, qty: model.smallBagsNo });
            }
            model.totalBagsNo++;
        }
    };
    CheckInLuggageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CheckInLuggageService_Factory() { return new CheckInLuggageService(); }, token: CheckInLuggageService, providedIn: "root" });
    return CheckInLuggageService;
}());
export { CheckInLuggageService };
