import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ApplicationFlowEnum, BookingStepsService } from '../booking-steps.service';
import { environment } from '../../../environments/environment';
import { FlowManagerService } from '../flow-manager.service';

@Injectable()
export class BookingAppFlowResolverService implements Resolve<ApplicationFlowEnum> {

  constructor(private flowManager: FlowManagerService, private stepsService: BookingStepsService) { }

  resolve() {
    if (environment.flow === 0) {
      return ApplicationFlowEnum.Checkin;
    }

    return this.flowManager.applicationFlowService.loadFlowInfo()
      .then((appInfo: any) => {
        let flow: ApplicationFlowEnum;
        if (appInfo.convertedJourneys && appInfo.convertedJourneys.snapshotBooking) {
          flow = ApplicationFlowEnum.ManageBooking;
        } else if (this.checkForSSrOnPassengers(appInfo, 'LCK')) {
          flow = ApplicationFlowEnum.FinalizeLockFare;
        } else {
          flow = ApplicationFlowEnum.Booking;
        }

        this.stepsService.setFlow(flow);
        return flow;
      });
  }

  private checkForSSrOnPassengers(appInfo: any, ssrCode: string): boolean {
    return appInfo && appInfo.passengerSsrs && appInfo.passengerSsrs.items && appInfo.passengerSsrs.items.some(i => i.ssrCode === ssrCode);
  }
}

