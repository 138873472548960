import { Injectable } from '@angular/core';
import { Observer, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class LoadingSpinnerService {
  public spinnerObservable: Observable<any>;
  private spinnerObserver: Observer<any>;
  private stack: any[] = [];

  constructor() {
    this.spinnerObservable = new Observable<boolean>(observer => {
      this.spinnerObserver = observer;
    }).pipe(share());
  }

  showSpinner(route) {
    if (this.spinnerObserver) {
      this.spinnerObserver.next(this.pushToStack(null, route, true));
    }
  }

  showSpinnerById(id) {
    if (this.spinnerObserver) {
      this.spinnerObserver.next(this.pushToStack(id, null, true));
    }
  }

  hideSpinner(route) {
    if (this.spinnerObserver) {
      this.spinnerObserver.next(this.pushToStack(null, route, false));
    }
  }

  hideSpinnerById(id) {
    if (this.spinnerObserver) {
      this.spinnerObserver.next(this.pushToStack(id, null, false));
    }
  }

  initFromStack(stackId: number, id, routes: string[]): any {
    if (stackId < this.stack.length) {
      const lastIndex = this.lastIndexOf(this.stack, s => s.id === id || routes.indexOf(s.route) >= 0);

      if (lastIndex >= 0) {
        return this.stack[lastIndex];
      }
      return null;
    }
  }

  private pushToStack(id: string = null, route: string = null, show: boolean): any {
    const toStack: any = {
      id: id,
      route: route,
      show: show
    };
    this.stack.push(toStack);
    toStack.stackId = this.stack.length - 1;
    return toStack;
  }

  private lastIndexOf(arr: any[], fn: (item: any) => boolean) {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (fn(arr[i])) {
        return i;
      }
    }

    return -1;
  }
}
