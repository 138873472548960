import * as tslib_1 from "tslib";
import { SessionStorage } from 'ngx-webstorage';
import * as CryptoJs from 'crypto-js';
import { ConfigService } from '../../core/config.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined } from 'util';
var SessionService = /** @class */ (function () {
    function SessionService(http, config) {
        this.http = http;
        this.config = config;
        this.sessionNotification = new BehaviorSubject({ isExpired: false, isExpiring: false });
    }
    SessionService.prototype.load = function (sessionUrl) {
        if (sessionUrl === void 0) { sessionUrl = null; }
        // this.sessionId = null;
        // this.sessionSig = null;
        // this.sessionDate = null;
        if (sessionUrl) {
            this._sessionUrl = sessionUrl;
        }
        return this.refreshSessionInternally();
    };
    SessionService.prototype.set = function (sessionId) {
        // if (window.setSession) {
        //   return window.setSession(sessionId).then((s) => {
        //     this.setInternally(s.SessionId, s.SessionSig);
        //   },
        //     () => { }
        //   );
        // }
        this.setInternally(sessionId);
        return Promise.resolve();
    };
    SessionService.prototype.isSessionValid = function () {
        if (!this.sessionDate || !this.sessionId || !this.sessionSig) {
            return false;
        }
        return this.sessionIsValid;
    };
    SessionService.prototype.hashSession = function (sessionId) {
        var str = 'X-Session-Id' + sessionId + '@#$ASJDFKLJ@#$UJ@#$J%KLJZSDKLFJ'; //this.config.config.webApiKey;;
        return CryptoJs.SHA256(str).toString(CryptoJs.enc.SHA256);
    };
    SessionService.prototype.keepAlive = function () {
        this.sessionDate = moment().toISOString();
        this.checkSession();
    };
    SessionService.prototype.refreshSession = function () {
        // re-add the session so it can slide the expiration
        this.set(this.sessionId);
        return Promise.resolve(true);
    };
    SessionService.prototype.resetSession = function () {
        this.loadSessionFromApi(true);
        //  return;
        // this.sessionId = null;
        // this.sessionSig = null;
        // this.sessionDate = null;
    };
    SessionService.prototype.ensureSessionIsValid = function () {
        if (this.isSessionValid()) {
            return Promise.resolve(true);
        }
        this.resetSession();
        return this.refreshSessionInternally(true)
            .then(function () { return true; }, function () { return false; });
    };
    SessionService.prototype.refreshSessionInternally = function (forced) {
        if (forced === void 0) { forced = false; }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!forced && !isNullOrUndefined(this.sessionId))) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.set(this.sessionId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2: 
                    // default to normal session loading
                    return [2 /*return*/, this.loadSessionFromApi(forced)];
                }
            });
        });
    };
    SessionService.prototype.setInternally = function (sessionId, sessionSig) {
        if (sessionSig === void 0) { sessionSig = null; }
        this.sessionId = sessionId;
        this.sessionSig = sessionSig || this.hashSession(this.sessionId);
        this.sessionDate = moment().toISOString();
        this.checkSession();
        this.setupSessionWarning();
    };
    SessionService.prototype.loadSessionFromApi = function (forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        if (!forceRefresh) {
            this.checkSession();
            if (this.isSessionValid()) {
                this.setupSessionWarning();
                return Promise.resolve();
            }
        }
        // if (!forceRefresh && this.isSessionValid()) {
        //   return Promise.resolve();
        // }
        var headers = new HttpHeaders().set('X-Skip-Interceptor', '');
        return this.http.post(this._sessionUrl, null, { observe: 'response', headers: headers })
            .toPromise()
            .then(function (response) {
            _this.setInternally(response.headers.get('X-Session-Id'));
        });
    };
    SessionService.prototype.checkSession = function () {
        var now = new Date();
        var timeElapsed = now.getTime() - (new Date(this.sessionDate)).getTime();
        var difference = SessionService.SessionTimeoutLimit - timeElapsed;
        this.sessionIsValid = difference > 0;
        if (difference <= SessionService.SessionWarningLimit) {
            this.sessionNotification.next({
                isExpired: !this.sessionIsValid,
                isExpiring: this.sessionIsValid,
                timeLeftToExpire: {
                    minutes: Math.floor(difference / 1000 / 60),
                    seconds: Math.floor(difference / 1000 % 60)
                }
            });
        }
        else if (this.sessionNotification.value.isExpired || this.sessionNotification.value.isExpiring) {
            this.sessionNotification.next({
                isExpired: false,
                isExpiring: false
            });
        }
    };
    SessionService.prototype.setupSessionWarning = function () {
        var _this = this;
        if (this.sessionVerifyInterval) {
            clearInterval(this.sessionVerifyInterval);
        }
        this.sessionVerifyInterval = setInterval(function () { return _this.checkSession(); }, 1000);
    };
    // 15minutes
    SessionService.SessionTimeoutLimit = 15 * 60000;
    // 1minute
    SessionService.SessionWarningLimit = 1 * 60000;
    tslib_1.__decorate([
        SessionStorage(),
        tslib_1.__metadata("design:type", String)
    ], SessionService.prototype, "sessionId", void 0);
    tslib_1.__decorate([
        SessionStorage(),
        tslib_1.__metadata("design:type", String)
    ], SessionService.prototype, "sessionSig", void 0);
    tslib_1.__decorate([
        SessionStorage(),
        tslib_1.__metadata("design:type", String)
    ], SessionService.prototype, "sessionDate", void 0);
    return SessionService;
}());
export { SessionService };
