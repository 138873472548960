import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SsrType } from './ssr-type.enum';
import { FlightType } from '../core/models/flight-model';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
import { BookingService } from '../core/booking.service';
import { Constants } from '../constants';
import { FlowManagerService } from '../core/flow-manager.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { ProfileService } from '../core/profile.service';
import { ECommerceService } from '../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../shared/e-commerce/models/e-commerce-cart-item.model';
var SsrsService = /** @class */ (function () {
    function SsrsService(http, configService, flowManager, bookingService, ecommerce, translateService, profileService) {
        this.http = http;
        this.configService = configService;
        this.flowManager = flowManager;
        this.bookingService = bookingService;
        this.ecommerce = ecommerce;
        this.translateService = translateService;
        this.profileService = profileService;
        this.availableSsrsObs = new BehaviorSubject(null);
        this.reloadingSsrsObs = new Subject();
        this.priorityPassengersMap = new Map();
        this.luggagePassengersMap = new Map();
        this._ssrsToRemove = {};
        this._ssrsToAdd = {};
        this.ssrInfoByTypeObs = {};
        this.noBagsSync = [];
        this.spinnerIdPrefix = 'extras-spinner';
        this.defaultDisplayOrder = [
            SsrType.Baggage, SsrType.PriorityBag, SsrType.Meal, SsrType.Meal, SsrType.Pet, SsrType.SpecialEquipment, SsrType.Lounge,
            SsrType.SpecialAssistance, SsrType.PriorityBoarding, SsrType.FastTrack, SsrType.Insurance
        ];
        this.actualDisplayOrder = [];
        for (var ssrType in SsrType) {
            if (Number(ssrType) >= 0) {
                this._ssrsToAdd[ssrType] = [];
                this._ssrsToRemove[ssrType] = [];
            }
        }
    }
    /** Toggles loading spinners for specific ssr type boxes */
    SsrsService.prototype.toggleLoadingForSsrType = function (isLoading) {
        var _this = this;
        var ssrTypes = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            ssrTypes[_i - 1] = arguments[_i];
        }
        ssrTypes.forEach(function (s) { return _this.reloadingSsrsObs.next({ ssrType: s, isLoading: isLoading }); });
        return Promise.resolve();
    };
    SsrsService.prototype.getSsrsInfo = function () {
        var _this = this;
        var params = new HttpParams()
            .set('IsCheckinFlow', environment.flow === 0 ? 'true' : 'false');
        return this.http.get(this.configService.SsrsInfoUrl, { params: params })
            .pipe(map(function (data) {
            _this.ssrs = data;
            _this.availableSsrs = data.ssrsInfo.availableSsrs;
            _this.actualDisplayOrder.length = 0;
            _this.defaultDisplayOrder.forEach(function (ssrType) {
                var avSsr = _this.availableSsrs.find(function (av) { return av.key === ssrType; });
                if (avSsr) {
                    for (var j_ix = 0; j_ix < avSsr.value.length; j_ix++) {
                        var journeyOrSegment = avSsr.value[j_ix];
                        if (journeyOrSegment && journeyOrSegment.value && journeyOrSegment.value.length) {
                            _this.actualDisplayOrder.push(ssrType);
                            break;
                        }
                    }
                }
            });
            _this.memberPromotions = data.memberPromotionDetails;
            _this.setOriginalPricesForPromotion();
            _this.nestInfo = data.ssrsInfo.nestInfo;
            _this.availableSsrsObs.next(_this.availableSsrs);
            return data;
        }), 
        // tap(c => console.log(JSON.stringify(c))),
        shareReplay(1));
    };
    SsrsService.prototype.setOriginalPricesForPromotion = function () {
        var _this = this;
        // if member has active promotion
        if (this.memberPromotions.ssrSegmentPromotionList.length > 0) {
            this.memberPromotions.ssrSegmentPromotionList.forEach(function (segment) {
                segment.value.forEach(function (s) {
                    var ssr = _this.availableSsrs.map(function (s) { return s.value; }).reduce(function (a, b) { return a.concat(b); }, [])
                        .filter(function (x) { return x.key === segment.key; })
                        .map(function (x) { return x.value; }).reduce(function (a, b) { return a.concat(b); }, [])
                        .find(function (ssr) { return ssr.ssrCode == s.ssrCode; });
                    if (ssr && s.discount > 0) {
                        ssr.originalPrice = ssr.price + s.discount;
                    }
                });
            });
        }
    };
    SsrsService.prototype.resetSsrs = function (ssrType) {
        this._ssrsToAdd[ssrType].length = 0;
        this._ssrsToRemove[ssrType].length = 0;
    };
    SsrsService.prototype.applySsrs = function (ssrType) {
        var _this = this;
        var applySsrsPromise = Promise.resolve();
        var mustRefreshData = false;
        var toRemove = [];
        var toAdd = [];
        if (this._ssrsToRemove[ssrType].length) {
            applySsrsPromise = this.http
                .post(this.configService.SsrsApplyUrl, { 'ssr': { 'selectedJourneySsrs': this._ssrsToRemove[ssrType] } }).toPromise()
                .then(function () {
                _this._ssrsToRemove[ssrType].forEach(function (key) {
                    toRemove.push(new EcommerceSsr(key));
                });
                _this._ssrsToRemove[ssrType].length = 0;
            });
            mustRefreshData = true;
        }
        if (this._ssrsToAdd[ssrType].length) {
            applySsrsPromise = applySsrsPromise
                .then(function () {
                return _this.http.post(_this.configService.SsrsApplyUrl, { 'ssr': { 'selectedJourneySsrs': _this._ssrsToAdd[ssrType] } }).toPromise();
            })
                .then(function () {
                _this._ssrsToAdd[ssrType].forEach(function (key) {
                    toAdd.push(new EcommerceSsr(key));
                });
                _this._ssrsToAdd[ssrType].length = 0;
            });
            mustRefreshData = true;
        }
        if (mustRefreshData) {
            return applySsrsPromise.then(function () {
                var applicationFlowService = _this.flowManager.applicationFlowService;
                return applicationFlowService.loadPriceBreakdown(true).then(function () {
                    var ecommerceToAddCartItems = _this.getEcommerceItems(toAdd, ssrType);
                    if (ecommerceToAddCartItems.length > 0) {
                        _this.ecommerce.AddToCart(ecommerceToAddCartItems);
                    }
                    var ecommerceToRemoveCartItems = _this.getEcommerceItems(toRemove, ssrType);
                    if (ecommerceToRemoveCartItems.length > 0) {
                        _this.ecommerce.RemoveFromCart(ecommerceToRemoveCartItems);
                    }
                }).then(function () {
                    return applicationFlowService.loadFlowInfo(true);
                });
            });
        }
        return applySsrsPromise;
    };
    SsrsService.prototype.getEcommerceItems = function (items, ssrType) {
        var _this = this;
        var data = this.bookingService.bookingObs.value;
        var ecommerceItems = [];
        items.forEach(function (ssr) {
            var item = ecommerceItems.find(function (e) {
                // const id = e.item_id.split(' ')[e.item_id.split(' ').length - 1];
                return e.item_id.split(' ')[e.item_id.split(' ').length - 1] === ssr.passengerNumber
                    && e.variant === ssr.ssrCode
                    && e.item_id.split(' ')[0].concat(e.item_id.split(' ')[1]) === ssr.flightInfo.split(' ')[1].concat(ssr.flightInfo.split(' ')[2]);
            });
            var availableSsr = _this.availableSsrs.find(function (s) { return s.key === ssrType; });
            var flightCode = ssr.flightInfo.slice(-6);
            var price = availableSsr.value.find(function (s) { return s.key.replace('_', '') === flightCode; }).value.find(function (s) { return s.ssrCode === ssr.ssrCode; }).price;
            if (item) {
                item.quantity++;
            }
            else {
                item = new ECommerceCartItem(_this.translateService.instant(ssr.ssrCode, 'ssr.name'), //name
                _this.ecommerce.getFlightInfoByStations(ssr.flightInfo.slice(-6).substring(0, 3), ssr.flightInfo.slice(-6).substring(3, 6)) + ssr.passengerNumber, //id
                price, //price
                'Aeroitalia', //brand
                SsrType[ssrType], ssr.ssrCode, 1, null, data.passengers.items.find(function (p) { return p.passengerNumber.toString() === ssr.passengerNumber; }).typeInfo.paxType, _this.ecommerce.getUserData()).getItem(false);
                ecommerceItems.push(item);
            }
        });
        return ecommerceItems;
    };
    SsrsService.prototype.removeSsr = function (ssrType, ssrCode, ssrNumber, passengerNumber, flight, bySegment) {
        var _this = this;
        if (bySegment === void 0) { bySegment = false; }
        var changes = this._ssrsToRemove[ssrType];
        if (bySegment && flight.segments.length) {
            flight.segments.forEach(function (seg) { return _this.removeSsr(ssrType, ssrCode, ssrNumber, passengerNumber, seg, false); });
            return;
        }
        // if srr was just added but not applied remove the added ssr
        var ssrToAddKey = this.getSsrKey(ssrCode, flight, passengerNumber);
        var addedSsrIndex = this._ssrsToAdd[ssrType].indexOf(ssrToAddKey);
        if (addedSsrIndex >= 0) {
            this._ssrsToAdd[ssrType].splice(addedSsrIndex, 1);
            return;
        }
        var ssrToRemoveKey = this.getSsrKey(ssrCode, flight, passengerNumber, ssrNumber);
        // if not already removed or if ssr is bought => it can be removed
        if (changes.indexOf(ssrToRemoveKey) < 0 && flight.ssrs[passengerNumber].indexOf(ssrCode) >= 0) {
            changes.push(ssrToRemoveKey);
        }
    };
    SsrsService.prototype.addSsr = function (ssrType, ssrCode, passengerNumber, flight, bySegment) {
        var _this = this;
        if (bySegment === void 0) { bySegment = false; }
        var changes = this._ssrsToAdd[ssrType];
        if (bySegment && flight.segments.length) {
            flight.segments.forEach(function (segment) { return _this.addSsr(ssrType, ssrCode, passengerNumber, segment, false); });
            return;
        }
        changes.push(this.getSsrKey(ssrCode, flight, passengerNumber));
    };
    SsrsService.prototype.getPendingSsrs = function () {
        var pendingSsrsList = [];
        for (var ssrType in SsrType) {
            if (Number(ssrType)
                && ((this._ssrsToAdd[ssrType] && this._ssrsToAdd[ssrType].length)
                    || (this._ssrsToRemove[ssrType] && this._ssrsToRemove[ssrType].length))) {
                pendingSsrsList.push(+ssrType);
            }
        }
        return pendingSsrsList;
    };
    SsrsService.prototype.getSsrKey = function (ssrCode, flight, passengerNumber, ssrNumber) {
        if (ssrNumber === void 0) { ssrNumber = null; }
        if (flight.type === FlightType.Journey) {
            //  return String.Format("{0}|{1}|{2}", passengerNumber, ssrCode, journey.SellKey);
            return passengerNumber + "|" + ssrCode + "|" + flight.sellKey;
        }
        else {
            // var date = segment.Std.Year + (segment.Std.Month < 10 ? "0" + segment.Std.Month.ToString() : segment.Std.Month.ToString()) + (segment.Std.Day < 10 ? "0" + segment.Std.Day.ToString() : segment.Std.Day.ToString());
            //   var flight = segment.FlightDesignator.CarrierCode.Trim() + segment.FlightDesignator.FlightNumber;
            // return String.Format("|{0}|{1}|{2} {3} {4}{5}||", passengerNumber, ssrCode, date, flight, segment.DepartureStation, segment.ArrivalStation);
            var std = moment(flight.departureStation.date);
            return (ssrNumber ? ssrNumber : '') + "|" + passengerNumber + "|" + ssrCode + "|" + std.format('YYYYMMDD') + " " + flight.carrierCode.trim() + flight.number + " " + flight.departureStation.iataCode + flight.arrivalStation.iataCode + "||1";
        }
    };
    SsrsService.prototype.updatePriorityBag = function (flights) {
        var _this = this;
        var bagaggeSsrsList = this.availableSsrs.find(function (lists) { return lists.key === SsrType.Baggage; });
        var baggageCodesList = bagaggeSsrsList ? bagaggeSsrsList.value.map(function (s) { return s.value; }).reduce(function (a, b) { return a.concat(b); }, []).map(function (s) { return s.ssrCode; }) : [];
        var showPriorityPopup = false;
        flights.forEach(function (f) {
            for (var passengerNumber in f.ssrs) {
                if (f.ssrs.hasOwnProperty(passengerNumber)) {
                    var passengerSsrs = f.ssrs[passengerNumber].filter(function (s) { return baggageCodesList.indexOf(s) >= 0; });
                    var bags = passengerSsrs.concat(f.paidSsrs[passengerNumber].filter(function (s) { return baggageCodesList.indexOf(s) >= 0; }));
                    if (bags.length === 0 && f.ssrs[passengerNumber].filter(function (s) { return s === 'PBAG'; }).length > 0) {
                        _this.removeSsr(SsrType.PriorityBag, 'PBAG', 1, parseInt(passengerNumber), f, true);
                        showPriorityPopup = true;
                    }
                }
            }
        });
        return showPriorityPopup;
    };
    SsrsService.prototype.setupSpecialAssistanceCustomIcons = function (availableSsrs) {
        var ssrsCustomIcons = {};
        availableSsrs.forEach(function (ssr) {
            var icon;
            switch (ssr.ssrCode) {
                case 'WCHC':
                    icon = 'font-ico-spec-assistance-cabin';
                    break;
                case 'PREG':
                case 'DPNA':
                case 'DEAF':
                case 'BLD':
                    icon = "font-ico-spec-assistance-" + ssr.ssrCode.toLowerCase();
                    break;
                case 'UMNR':
                case 'UMFR':
                case 'UM':
                    icon = 'font-ico-spec-assistance-um';
                    break;
                case 'WCHS':
                case 'WCHR':
                default:
                    icon = 'font-ico-spec-assistance';
                    break;
            }
            ssrsCustomIcons[ssr.ssrCode] = icon;
        });
        return ssrsCustomIcons;
    };
    /** Check if there are conflicting seats with the newly added ssrs
     * @returns array of seats to be removed
     */
    SsrsService.prototype.getSeatsToBeRemoved = function (ssrType, flights, newSsrsInfo, passengerNumber) {
        if (passengerNumber === void 0) { passengerNumber = -1; }
        if (!newSsrsInfo.length) {
            return [];
        }
        var restrictedSeats = this.getSeatsWithoutSsrRestriction(ssrType, flights);
        return restrictedSeats.filter(function (seat) {
            return newSsrsInfo.some(function (info) {
                return info.passengerNumber === seat.passengerNumber &&
                    (passengerNumber === -1 || info.passengerNumber === passengerNumber) &&
                    info.flightSellKey === seat.flightSellKey;
            });
        });
    };
    SsrsService.prototype.getSeatsWithoutSsrRestriction = function (ssrType, flights) {
        var typeCode;
        switch (ssrType) {
            case SsrType.SpecialAssistance:
                typeCode = Constants.seatTypeDISABIL;
                break;
            case SsrType.Pet:
                typeCode = Constants.seatTypePET;
                break;
            default:
                // nothing to do
                return [];
        }
        var seatsWOrestrictions = [];
        var data = this.bookingService.bookingObs.value;
        data.assignedSeats.journeys.items.forEach(function (flight) {
            var assignedSeats = flight.segments.items
                .map(function (p) { return p.paxSeats; }).reduce(function (a, b) { return a.concat(b); }, []).map(function (p) { return p.items; }).reduce(function (a, b) { return a.concat(b); }, []);
            if (assignedSeats) {
                // tslint:disable-next-line: max-line-length
                var filteredSeats = assignedSeats.filter(function (x) { return !x.passengerSeatInfo.propertyList.find(function (x) { return x.typeCode === typeCode && x.value === 'TRUE'; }); });
                if (filteredSeats.length) {
                    filteredSeats.forEach(function (seat) {
                        var currentFlight = flights.find(function (f) { return f.sellKey === flight.sellKey; });
                        if (currentFlight) {
                            seatsWOrestrictions.push({
                                passengerNumber: seat.passengerNumber,
                                flightKey: currentFlight.segments
                                    .find(function (s) { return s.departureStation.iataCode === seat.departureStation &&
                                    s.arrivalStation.iataCode === seat.arrivalStation; }).referenceKey,
                                flightSellKey: flight.sellKey,
                                deck: seat.passengerSeatInfo.deck,
                                compartment: seat.compartmentDesignator,
                                unit: seat.unitDesignator,
                                departureStation: seat.departureStation,
                                arrivalStation: seat.arrivalStation
                            });
                        }
                    });
                }
            }
        });
        return seatsWOrestrictions;
    };
    SsrsService.prototype.getPromoDiscount = function (ssrType) {
        var configPromo = this.configService.config.promo;
        if (configPromo && configPromo.length) {
            var promoForSsrType = configPromo.find(function (p) { return p.ssrType === ssrType; });
            var now = moment();
            if (promoForSsrType &&
                now.isSameOrAfter(moment.utc(promoForSsrType.fromDate, 'YYYY-MM-DD HH:mm')) &&
                now.isSameOrBefore(moment.utc(promoForSsrType.toDate, 'YYYY-MM-DD HH:mm')) &&
                promoForSsrType.discount > 0) {
                return promoForSsrType.discount;
            }
        }
    };
    return SsrsService;
}());
export { SsrsService };
var EcommerceSsr = /** @class */ (function () {
    function EcommerceSsr(key) {
        var data = key.split('|');
        this.setParams(data[1], data[2], data[3]);
    }
    EcommerceSsr.prototype.setParams = function (passengerNumber, ssrCode, flightInfo) {
        this.passengerNumber = passengerNumber;
        this.ssrCode = ssrCode;
        this.flightInfo = flightInfo;
    };
    return EcommerceSsr;
}());
export { EcommerceSsr };
