import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { BlueSelectListComponent } from './blue-select-list/blue-select-list.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { BlueDatePickerComponent } from './blue-date-picker/blue-date-picker.component';
import { BlueModalComponent } from './blue-modal/blue-modal.component';
import { BlueModalService } from './blue-modal/blue-modal.service';
import { TranslatePipe } from './translator/translate.pipe';
import { CustomDatePipe } from './custom-date.pipe';
import { DurationPipe } from './duration.pipe';
import { SessionInUrlComponent } from './sesison-in-url/session-in-url/session-in-url.component';
import { ImgDefaultSrcDirective } from './directives/img-default-src.directive';
import { TranslateItemsDirective } from './translator/translate-items.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [
    CommonModule, FormsModule,
    BlueSelectListComponent,
    LoadingSpinnerComponent,
    BlueDatePickerComponent,
    BlueModalComponent,
    SessionInUrlComponent,
    TranslatePipe,
    CustomDatePipe,
    DurationPipe,
    ImgDefaultSrcDirective,
    TranslateItemsDirective
  ],
  declarations: [BlueSelectListComponent, LoadingSpinnerComponent, BlueDatePickerComponent, BlueModalComponent, SessionInUrlComponent,
    TranslatePipe, CustomDatePipe, DurationPipe, ImgDefaultSrcDirective, TranslateItemsDirective],
  providers: [BlueModalService]
})
export class BlueAirCommonModule { }
