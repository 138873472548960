<check-in-selections-clear-warning-modal #changeFlightsWarningModal>
</check-in-selections-clear-warning-modal>

<check-in-form-wrapper [ariaLabel]="viewstep" [step]="'step-4'" (continue)="continue()" [showPriceDisplay]="true">
    <div class="form-wrapper-title">
        {{ 'Seat selection' | translate }}
    </div>

    <div class="form-wrapper-top-body">
        <div class="checkin-box">
            <div class="checkin-seat-selection">
                <div class="checkin-accordion">
                    <div class="warning-msg" *ngFor="let error of errorMessage">
                        {{ error.errorMessage | translate: 'seatmap-errors' }}
                    </div>
                    <!-- <div class="warning-msg" *ngIf="connectedJourney.length > 0"> {{ 'multipleSegmentsPerJourneyPriceWarning' | translate: 'seatmap' }} {{ connectedJourney }}</div> -->
                    <div [ngClass]="{'segment-grouping':journey.seatmaps.length> 0} " *ngFor="let journey of seatMap.journeys">
                        <span *ngIf="!isCanceledFlight(journey)">
                            <div class="segment-grouping-header">
                                <div class="segment-grouping-wrap-title">
                                    <span class="icon font-ico-plane-runway" role="presentation">
                                    </span>
                                    <!-- <span class="icon font-ico-plane-arrive" role="presentation"></span> -->
                                    <div class="title">
                                        {{ '{0} to {1}' | translate: '':[journey.seatmaps[0].departureStation | translate: 'station.name']:[journey.seatmaps[journey.seatmaps.length-1].arrivalStation | translate: 'station.name'] }}
                                    </div>
                                </div>

                                <div class="price">
                                    {{ journey.seatPrice | currencyDisplay }}
                                </div>
                            </div>

                            <div class="checkin-acc-item" aria-haspopup="true" *ngFor="let map of journey.seatmaps">
                                <button type="button" class="checkin-acc-head" (click)="selectMap(map)" [ngClass]="{'open' : map.isExpanded}" [attr.data-key]="map.flightKey">
                                    <div class="selected-seat">
                                        {{ '{0} to {1}' | translate: '' : [map.departureStation | translate: 'station.name'] : [map.arrivalStation | translate: 'station.name'] }}
                                        <div *ngIf="selectedSeats[map.flightKey].length" class="selected-seat-btn-info">
                                            <i class="icon font-ico-seat" role="presentation"></i>

                                            <span class="legend-text" >
                                                {{ 'Selected seat(s)' | translate: 'seatmap' }}: {{ selectedSeats[map.flightKey] }}
                                            </span>
                                        </div>
                                    </div>

                                    <span class="price" attr.aria-label="{{ 'from {0}' | translate: 'seatmap':[  (map.backLowPrice < map.frontLowPrice? map.backLowPrice :  map.frontLowPrice) |currencyDisplay] }}">
                                        <span>
                                            {{ 'From {0}' | translate: 'seatmap':[ (map.backLowPrice < map.frontLowPrice? map.backLowPrice : map.frontLowPrice) |currencyDisplay] }}
                                        </span>

                                        <div class="promo-img" *ngIf="isPromotion">
                                            <img [src]="assetsPath + 'svg/icon-promo_32x32.svg'">
                                        </div>
                                    </span>
                                </button>

                                <div class="checkin-acc-content" aria-hidden="true" [attr.aria-hidden]="map.ariaHidden" [style.display]="map.displayMode">
                                    <div class="selected-seats-holder">
                                        <p *ngIf="isCheckinFlow">
                                            {{ 'We have selected seats for you. You can select new seats for a small fee.' | translate }}
                                        </p>

                                        <div class="selected-seat" *ngFor="let pax of passengers" [ngClass]="{'selected':pax == selectedJourneyPassenger}">
                                            <span class="circle">
                                                {{ pax.assignedSeat[map.flightKey]?.unit }}
                                            </span>

                                            <button type="button" class="title" (click)="selectedPax(pax, map)">
                                                <check-in-passenger-type-display *ngIf="!pax.name.first && !pax.name.last" [passenger]="pax">
                                                </check-in-passenger-type-display>

                                                <check-in-passenger-name-display  [passenger]="pax">
                                                </check-in-passenger-name-display>
                                            </button>

                                            <ng-container *ngIf="!isCheckinFlow">
                                                <button type="button" class="remove-from-cart" (click)="removeSeat(pax, map)" *ngIf="pax.assignedSeat[map.flightKey]?.unit">
                                                    <span class="font-ico-close-menu">
                                                    </span>
                                                </button>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <check-in-seat-map-legend [seatMap]="map" (selectFilter)="filterSelected($event)">
                                    </check-in-seat-map-legend>

                                    <div>
                                        <check-in-seat-map (addToCartEvent)='addToCart()' [mapKey]="map.sellKey" [flightKey]="map.flightKey" [selectPassenger]="selectedPassenger" (setSelected)="seatSelected($event)" [filterApplied]="filterClickNotifier" [seatDeselected]="seatDeselectNotifier">
                                        </check-in-seat-map>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <!-- <div class="notif-msg" *ngIf="connectedJourney.length > 0"> {{ 'multipleSegmentsPerJourneyPriceWarning' | translate: 'seatmap' }}</div> -->
                </div>
            </div>
        </div>


    </div>
</check-in-form-wrapper>

<seat-selected-notification #modalId>
    <ng-container *ngIf="pendingSsrsList.length">
        <div class="section-SubTitle">
            {{ 'The following options have been selected, but not added to cart:' | translate: 'seat' }}
        </div>

        <div class="section-text">
            <ul class="pending-list">
                <ng-container *ngFor="let ssrType of pendingSsrsList">
                    <ng-container [ngSwitch]="ssrType">
                        <li *ngSwitchCase="SsrTypeEnum.Seat"><span class="font-ico-seat ssr-icon"></span>
                            <div class="text">{{ 'Seat' | translate: 'extras' }}</div>
                        </li>

                        <li *ngSwitchCase="SsrTypeEnum.BundleUpgrade"><span class="font-ico-tag ssr-icon"></span>
                            <div class="text">{{ 'Bundle upgrade' | translate: 'extras' }}</div>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</seat-selected-notification>

<check-in-default-modal #noSeatSelectedModal modalId="noSeatSelectedModal"
                        modalTitle="{{'No seat selected, you will get a random seat' | translate: 'shared' }}"
                        okButtonText="{{ 'I prefer a random seat' | translate: 'extras' }}"
                        cancelButtonText="{{ 'I prefer to reserve a seat' | translate: 'extras' }}"
                        [okRequired]="true"
                        [cancelRequired]="true"
                        leftButtonClass="inverted">

    <div class="warning-sign">
    </div>

    <div class="section-SubTitle text-color-black">
        {{ 'Random seat selection means:' | translate: 'shared' }}
    </div>

    <div class="section-text">
        <ul>
            <li>
                <span class="ssr-icon font-ico-checked-sign"></span>
                <div class="text">{{ 'High chance you`ll be in the middle seat' | translate: 'shared' }}</div>
            </li>

            <li>
                <span class="ssr-icon font-ico-checked-sign"></span>
                <div class="text">{{ 'Pick your seat only 24 hours prior to departure' | translate: 'shared' }}</div>
            </li>

            <li>
                <span class="ssr-icon font-ico-checked-sign"></span>
                <div class="text">{{ 'Unlikely to be in the front' | translate: 'shared' }}</div>
            </li>

            <li>
                <span class="ssr-icon font-ico-checked-sign"></span>
                <div class="text">{{ 'Your party seats separate' | translate: 'shared' }}</div>
            </li>
        </ul>
    </div>
</check-in-default-modal>

<check-in-default-modal #noSeatSelectedChildsModal modalId="noSeatSelectedChildsModal"
                        modalTitle="{{'You have not selected your seats' | translate: 'shared' }}"
                        okButtonText="{{ 'I prefer a random seat' | translate: 'extras' }}"
                        cancelButtonText="{{ 'I prefer to reserve a seat' | translate: 'extras' }}"
                        [okRequired]="true"
                        [cancelRequired]="true"
                        leftButtonClass="inverted">

    <div class="warning-sign">
    </div>

    <div class="section-text">
        <span style="color: #072158;">
          {{ "Because you are travelling with a small child, please make
          advanced seat reservation in order to enable web check-in.
          In case you select 'I prefer a random seat', you will be
          assigned seats for free at the check-in desk, based on
          availability." | translate: 'extras' }}
        </span>
    </div>
</check-in-default-modal>
