import { ConfigService } from './../../core/config.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from './translator/translate.service';
import { of as observableOf, Observable } from 'rxjs';
import { map, publishReplay, refCount, share } from 'rxjs/operators';

@Injectable()
export class ResourceService {
  private resourcesUrl: string;
  public apiKey: string;
  private countries: Observable<any[]>;
  private documentTypes: Observable<any[]>;
  private addressTypes: Observable<any[]>;
  private seatLocationPreferenceTypes: Observable<any[]>;
  private seatTypePreferenceTypes: Observable<any[]>;
  private passengerTitles: Observable<any[]>;
  private passengerTypes: Observable<any[]>;
  private classesOfService: Observable<any[]>;

  constructor(private http: HttpClient, private translateService: TranslateService) {
  }

  init(resourcesUrl: string, apiUrl: string , apiKey: string) {
    this.resourcesUrl = resourcesUrl || apiUrl;
    this.apiKey = apiKey;
  }

  getCountries(): Observable<any[]> {
    if (this.countries) {
      return this.countries;
    }

    this.countries = this.http.get(this.resourcesUrl + 'Resource/Country').pipe(map(response => {
      const list: any[] = [];
      const countryList = (<any>response).countryInfo.countryList;

      for (let countryCode in countryList) {
        if (countryList.hasOwnProperty(countryCode)) {
          list.push({
            text: this.translateService.instant(countryCode, 'country.name'),
            value: countryCode
          });
        }
      }

      return list;
    }), publishReplay(), refCount());

    return this.countries;
  }

  getDocumentTypes(isAgent: boolean = false): Observable<any[]> {
    if (this.documentTypes) {
      return this.documentTypes;
    }

    const list: any[] = [];

    list.push({
      text: this.translateService.instant('Passport', 'document.type'),
      value: 'P'
    });
    list.push({
      text: this.translateService.instant('ID Card', 'document.type'),
      value: 'I'
    });

    if (isAgent) {
      list.push({
        text: this.translateService.instant('Other Airline Frequent Flyer', 'document.type'),
        value: 'OAFF'
      });
      list.push({
        text: this.translateService.instant('Travel Visa', 'document.type'),
        value: 'V'
      });
    }

    this.documentTypes = observableOf(list).pipe(share());
    return this.documentTypes;
  }

  getAddressTypes(): Observable<any[]> {
    if (this.addressTypes) {
      return this.addressTypes;
    }
    const list: any[] = [];

    list.push({
      text: this.translateService.instant('Home', 'address.type'),
      value: 'H'
    });
    list.push({
      text: this.translateService.instant('Business', 'address.type'),
      value: 'W'
    });

    this.addressTypes = observableOf(list).pipe(share());
    return this.addressTypes;
  }

  getSeatLocationTypes(): Observable<any[]> {
    if (this.seatLocationPreferenceTypes) {
      return this.seatLocationPreferenceTypes;
    }

    const list: any[] = [];

    list.push({
      text: this.translateService.instant('No Preference', 'seatLocationPreference.type'),
      value: 'NOPREFERENCE'
    });
    list.push({
      text: this.translateService.instant('Front', 'seatLocationPreference.type'),
      value: 'FRONT'
    });
    list.push({
      text: this.translateService.instant('Rear', 'seatLocationPreference.type'),
      value: 'REAR'
    });

    this.seatLocationPreferenceTypes = observableOf(list).pipe(share());

    return this.seatLocationPreferenceTypes;
  }

  getSeatTypePreferenceTypes(): Observable<any[]> {
    if (this.seatTypePreferenceTypes) {
      return this.seatTypePreferenceTypes;
    }

    const list: any[] = [];

    list.push({
      text: this.translateService.instant('No Preference', 'seatTypePreference.type'),
      value: 'NOPREFERENCE'
    });
    list.push({
      text: this.translateService.instant('Aisle', 'seatTypePreference.type'),
      value: 'AISLE'
    });
    list.push({
      text: this.translateService.instant('Window', 'seatTypePreference.type'),
      value: 'WINDOW'
    });

    this.seatTypePreferenceTypes = observableOf(list).pipe(share());
    return this.seatTypePreferenceTypes;
  }

  getPassengerTitles(): Observable<any[]> {
    if (this.passengerTitles) {
      return this.passengerTitles;
    }

    const list: any[] = [
      {
        'text': this.translateService.instant('Mr'),
        'value': 'MR'
      },
      {
        'text': this.translateService.instant('Ms'),
        'value': 'MS'
      }
    ];

    this.passengerTitles = observableOf(list).pipe(share());
    return this.passengerTitles;
  }

  getPassengerTypes(): Observable<any[]> {
    if (this.passengerTypes) {
      return this.passengerTypes;
    }

    this.passengerTypes = this.http.get(this.resourcesUrl + 'customresource/PassengerTypes').pipe(map((response: any[]) => {
      return response.map(r => r.value);
    }), publishReplay(), refCount());

    return this.passengerTypes;
  }

  async getMinHoursToPurchaseBeforeFlightAsync(): Promise<number> {
    return this.http.get<number>(this.resourcesUrl + 'customresource/FlexPurchaseBeforeFlight').toPromise();
  }

  async getRefundToWalletAgentDisplayRestrictions(): Promise<any> {
    return this.http.get<any>(this.resourcesUrl + 'customresource/RefundToWalletAgentDisplayRestrictions').toPromise();
  }

  async getFlexInactivePeriodAsync(): Promise<any> {
    return this.http.get<any>(this.resourcesUrl + 'customresource/FlexActivePeriods').toPromise();
  }

  getClassesOfService(): Observable<any[]> {
    if (this.classesOfService){
      return this.classesOfService;
    }
    // tslint:disable-next-line: max-line-length
    const list = [
      {'text': 'A', 'value': 'A'},
      {'text': 'B2', 'value': 'B2'},
      {'text': 'B1', 'value': 'B1'},
      {'text': 'B', 'value': 'B'},
      {'text': 'C1', 'value': 'C1'},
      {'text': 'C', 'value': 'C'},
      {'text': 'D2', 'value': 'D2'},
      {'text': 'D1', 'value': 'D1'},
      {'text': 'D', 'value': 'D'},
      {'text': 'F1', 'value': 'F1'},
      {'text': 'F', 'value': 'F'},
      {'text': 'G1', 'value': 'G1'},
      {'text': 'G', 'value': 'G'},
      {'text': 'H', 'value': 'H'},
      {'text': 'I', 'value': 'I'},
      {'text': 'J', 'value': 'J'},
      {'text': 'K', 'value': 'K'},
      {'text': 'L', 'value': 'L'},
      {'text': 'M', 'value': 'M'},
      {'text': 'N', 'value': 'N'},
      {'text': 'O', 'value': 'O'},
      {'text': 'P', 'value': 'P'},
      {'text': 'Q', 'value': 'Q'},
      {'text': 'R', 'value': 'R'},
      {'text': 'S', 'value': 'S'},
      {'text': 'T', 'value': 'T'},
      {'text': 'U', 'value': 'U'},
      {'text': 'V', 'value': 'V'},
      {'text': 'W1', 'value': 'W1'},
      {'text': 'W', 'value': 'W'},
      {'text': 'X1', 'value': 'X1'},
      {'text': 'X', 'value': 'X'},
      {'text': 'Y1', 'value': 'Y1'} ,
      {'text': 'Y', 'value': 'Y'}
    ];

    this.classesOfService = observableOf(list).pipe(share());
    return this.classesOfService;
  }
}
