import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { ProfileService } from 'src/app/core/profile.service';
import { WalletInfoService } from '../../core/wallet-info/wallet-info.service';

@Component({
  selector: 'profile-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  providers: [WalletInfoService]
})

export class UserInfoComponent implements OnInit {
  profile: any;
  organizationName: string;
  amountAvailable: number;
  amountAvailableCurrency: string;
  menuExpanded: boolean;
  outsideClickListener: () => void;

  constructor(private profileService: ProfileService, private renderer: Renderer2,
    private _WalletInfoService: WalletInfoService) {
  }

  ngOnInit() {      
    this.profileService.profile.subscribe(profile => {
      // this.profileService.refreshProfile();

      if (profile) {
        this.profile = profile;

        this._WalletInfoService.getWalletInfo().subscribe(data => {
          this.amountAvailable = data.agencyAmount; 
          this.amountAvailableCurrency = data.agencyCurrencyCode; 
        });        
      }
    });
  }

  toggleMenu(event) {
    this.preventDefault(event);
    if (event) {
      event.stopPropagation();
    }
    this.menuExpanded = !this.menuExpanded;

    if (this.menuExpanded) {
      this.outsideClickListener = this.renderer.listen(document, 'click', () => {
        this.outsideClickListener();
        this.outsideClickListener = undefined;
        this.menuExpanded = false;
      });
    } 
    else if (this.outsideClickListener) {
      this.outsideClickListener();
      this.outsideClickListener = undefined;
    }
  }

  preventDefault(event) {
    if (event) {
      event.preventDefault();
    }
  }
}
