import { Component } from '@angular/core';
import { BookingStepsService, CheckinSteps } from './core/booking-steps.service';
import { skip, take } from 'rxjs/operators';
import { Resolve, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'check-in-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'app';

  isLoading = true;

  constructor(private bookingStepsService: BookingStepsService, private router: Router) {
    // each component sets the current step when initialized
    // wait for the first component to be initialized and hide spinner
    this.bookingStepsService.currentStep.pipe(skip(1), take(1)).subscribe(st => this.isLoading = false);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        try {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        } catch (e) {

        }
      }
    });
  }
}
