<div class="select-list" aria-haspopup="true" [ngClass]="{'expanded': isOpened}">
  <span class="overlay" aria-hidden="true" [style.display]="isOpened ? 'block' : ''" (click)="focusOut()"></span>

  <span select-list role="button" class="selected not-active" (click)="focusIn()">{{ model.count }}</span>

  <div class="passengers-overlay js-dest-overlay" *ngIf="isOpened" style="display: block;">
    <div class="passengers-wrap" >
      <a role="button" href="#" class="close" (click)="$event.preventDefault();focusOut()">
        <span class="sr-only">
          {{ 'Close passengers overlay' | translate: 'change-flight' }}
        </span>
        <i class="font-ico-close-menu"></i>
      </a>

      <h2 aria-hidden="true">
        {{ 'Passengers' | translate: 'change-flight' }}
      </h2>

      <span class="desc">
        {{ 'How many people will be flying?' | translate: 'change-flight' }}
      </span>

      <!-- data-loc-min-passengers="{{ ' Add at least one passanger' | translate: 'change-flight' }}"
        data-loc-max-passengers="@T("Not more then 9 passangers")"
        data-loc-group-booking="{{ ' Group booking' | translate: 'change-flight' }}"
        data-loc-group-booking-url="@Model.ViewModel.GroupPageUrl"
        data-loc-modify-number-of-passengers="{{ ' Modify the number of passengers' | translate: 'change-flight' }}"
        data-loc-child-without-adult="@T("Not without at least one adult")"
        data-loc-infant-without-adult="{{ ' One infant per adult' | translate: 'change-flight' }}" -->
      <div class="quantity-wrap">
        <div class="quantity" data-pax-type="1">
          <div class="qty-num">
            <span class="quantity-spinner" id="adtNumber">
              {{ tempModel.adults }}
            </span>
            <span class="label" innerHtml="{{ 'Adult <span>12+ years</span>' | translate: 'change-flight' }}"></span>
          </div>
          <ng-container *ngTemplateOutlet="quantityTemplate; context: {paxType: PassengerSelectionType.Adult}"></ng-container>
        </div>

        <div class="quantity" data-pax-type="4">
          <div class="qty-num">
            <span class="quantity-spinner" id="chdNumber">
              {{ tempModel.children }}
            </span>
            <span class="label" innerHtml="{{ 'Children <span>2-12 years</span>' | translate: 'change-flight' }}"></span>
          </div>
          <ng-container *ngTemplateOutlet="quantityTemplate; context: {paxType: PassengerSelectionType.Children}"></ng-container>
        </div>

        <div class="quantity" data-pax-type="5">
          <div class="qty-num">
            <span class="quantity-spinner" id="infNumber">
              {{ tempModel.infants }}
            </span>
            <span class="label" innerHtml="{{ 'Infants <span>Under 2 years</span>' | translate: 'change-flight' }}"></span>
          </div>
          <ng-container *ngTemplateOutlet="quantityTemplate; context: {paxType: PassengerSelectionType.Infant}"></ng-container>
        </div>
      </div>

      <button type="button" class="btn btn-primary" (click)="focusOut(true)">
        {{ 'Apply' | translate: 'change-flight' }}
      </button>
      <!-- <div class="alert-holder">
        <div class="alert-item secondary">
          <p>
            {{ 'For groups over 10 people, please use our' | translate: 'change-flight' }} <a href="@Model.ViewModel.GroupPageUrl"
              class="btn-secondary blue">@T("group
              booking tool")</a>
          </p>
        </div>
      </div> -->
    </div>
  </div>
</div>

<ng-template #quantityTemplate let-type="paxType">
  <div class="spinners">
    <span class="qty-value minus" (click)="update(type, -1)">
      <span class="sr-only">{{ 'Decrease number of passengers' | translate: 'change-flight' }}</span>
      <i class="font-ico-minus"></i>
    </span>

    <span class="qty-value plus" (click)="update(type, 1)">
      <span class="sr-only">{{ 'Increase number of passengers' | translate: 'change-flight' }}</span>
      <i class="font-ico-plus"></i>
    </span>
  </div>
</ng-template>

<check-in-default-modal 
  #numberOfPassengersModal 
  modalId="numberOfPassengersModal"
  modalTitle=""
  modalText="" 
  okButtonText="" 
  cancelButtonText="" 
  [okRequired]="" 
  [cancelRequired]="">

  <div>
    <h2 style="text-align: center;" class="section-title">
      {{ modalMessage | translate: 'change-flight' }}
    </h2>
  </div>
</check-in-default-modal>

