export class PassengerJourneyBags {
    passengerNumber: number;
    journeyBags: JourneyBags[];
}


export class JourneyBags {
    sellKey: string;
    bags: number;
}
