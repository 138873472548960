import { EventEmitter } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { BookingFareSelectSyncService } from '../../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { BookingSelectionService } from 'src/app/core/booking-selection.service';
import { SsrsService } from '../ssrs.service';
var SsrPassengerPickerComponent = /** @class */ (function () {
    function SsrPassengerPickerComponent(syncService, bookingSelectionService, ssrsService) {
        this.syncService = syncService;
        this.bookingSelectionService = bookingSelectionService;
        this.ssrsService = ssrsService;
        this.SsrTypeEnum = SsrType;
        this._passengers = [];
        this._currentFlights = [];
        this.showPassengerNames = true;
        this.flightCodes = '';
        this.enableSameOptionsForAllFlights = false;
        this.selectPassenger = new EventEmitter();
        this.selectSsrEvent = new EventEmitter();
        this.removeSsrEvent = new EventEmitter();
        this.removeSsr = new EventEmitter();
        this.resetSsr = new EventEmitter();
        this.sameOptionsForAllFlightsEvent = new EventEmitter();
        this.allPassengersVisible = true;
        this.uniqueId = Math.floor(Math.random() * 1000) + 1;
    }
    Object.defineProperty(SsrPassengerPickerComponent.prototype, "sameOptionsForAllFlights", {
        get: function () {
            return this.syncService.useSamePriorityBoardingOptionForAllPassengers;
        },
        set: function (value) {
            this.syncService.useSamePriorityBoardingOptionForAllPassengers = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SsrPassengerPickerComponent.prototype, "passengers", {
        get: function () {
            return this._passengers;
        },
        set: function (value) {
            this._passengers = value;
            this.setPassengersNameByType();
        },
        enumerable: true,
        configurable: true
    });
    SsrPassengerPickerComponent.prototype.getPriorityBoardingPrice = function (passenger) {
        var priorityBoardingSSR = this.ssrsService.availableSsrs.find(function (ssr) { return ssr.key === SsrType.PriorityBoarding; });
        if (!priorityBoardingSSR) {
            return 0;
        }
        if (!passenger.flight || !passenger.flight.segments)
            return 0;
        for (var segmentIndex = 0; segmentIndex < passenger.flight.segments.length; segmentIndex++) {
            var segment = passenger.flight.segments[segmentIndex];
            for (var i = 0; i < priorityBoardingSSR.value.length; i++) {
                if (priorityBoardingSSR.value[i].key === segment.departureStation.iataCode + '_' + segment.arrivalStation.iataCode) {
                    return priorityBoardingSSR.value[i].value[0].price;
                }
            }
        }
        return 0;
    };
    Object.defineProperty(SsrPassengerPickerComponent.prototype, "currentFlights", {
        get: function () {
            return this._currentFlights;
        },
        set: function (value) {
            this._currentFlights = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SsrPassengerPickerComponent.prototype, "allPassengersSelected", {
        get: function () {
            var selectedPassenters = this.passengers.filter(function (p) { return p.passenger.hasPriorityBoarding; });
            return selectedPassenters.length === this.passengers.length;
        },
        enumerable: true,
        configurable: true
    });
    SsrPassengerPickerComponent.prototype.ngOnDestroy = function () {
        if (this.passengersSubscription) {
            this.passengersSubscription.unsubscribe();
        }
    };
    SsrPassengerPickerComponent.prototype.allPassengersChanged = function () {
        if (this.allPassengersEnabled) {
            this.selectPassenger.next(this.allPassengersSelected ? 0 : -1);
        }
    };
    SsrPassengerPickerComponent.prototype.changeSelectedPassenger = function (passengerIndex) {
        this.selectPassenger.next(passengerIndex);
    };
    SsrPassengerPickerComponent.prototype.selectSinglePassengerPriorityBoardingOption = function (passengerUniqueId, hasPrioritySelected, event) {
        var _this = this;
        this.passengers.forEach(function (passenger) {
            if (passenger.passenger.passengerUniqueId === passengerUniqueId) {
                _this.syncService.passengersInitialPriorityBoardingChoice[passengerUniqueId] = true;
                if (passenger.passenger.hasPriorityBoarding === hasPrioritySelected)
                    return;
                if (event) {
                    event.preventDefault();
                }
                if (passenger.passenger.hasPriorityBoarding) {
                    if (_this.sameOptionsForAllFlights) {
                        var arr = _this.currentFlights.reduce(function (allPassengers, flight) { return allPassengers.concat(flight.passengers); }, []);
                        arr.forEach(function (p) { return _this.syncService.passengersInitialPriorityBoardingChoice[p.passengerUniqueId] = true; });
                        _this.removeSsrEvent.emit(arr);
                    }
                    else {
                        _this.removeSsrEvent.emit([passenger.passenger]);
                    }
                }
                else {
                    _this.selectSsrEvent.emit(passenger.passenger.passengerNumber);
                }
            }
        });
    };
    SsrPassengerPickerComponent.prototype.toggleAllPassengersPriorityBoardingSelection = function () {
        var _this = this;
        this.passengers.forEach(function (p) { return _this.syncService.passengersInitialPriorityBoardingChoice[p.passenger.passengerUniqueId] = true; });
        if (this.allPassengersSelected === true) {
            var passengersToRemoveSSRFor = this.passengers.filter(function (p) { return p.passenger.hasPriorityBoarding; }).map(function (p) { return p.passenger; });
            this.removeSsrEvent.emit(passengersToRemoveSSRFor);
        }
        else {
            var passengersToAddSSRFor = this.passengers.filter(function (p) { return !p.passenger.hasPriorityBoarding; }).map(function (p) { return p.passenger; });
            passengersToAddSSRFor.forEach(function (p) {
                _this.selectSsrEvent.emit(p.passengerNumber);
            });
        }
    };
    SsrPassengerPickerComponent.prototype.checkSameSsrOptionsForAllFlights = function () {
        this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
        this.sameOptionsForAllFlightsEvent.emit(this.sameOptionsForAllFlights);
    };
    SsrPassengerPickerComponent.prototype.setPassengersNameByType = function () {
        var _this = this;
        this.passengersSubscription = this.bookingSelectionService.passengersObs.subscribe(function (passengersByType) {
            if (passengersByType && passengersByType.length > 0) {
                _this.passengers.forEach(function (p) {
                    var name = passengersByType.filter(function (pax) { return pax.passengerNumber === p.passenger.passengerNumber; })
                        .map(function (p) { return p.name.first + ' ' + p.name.last; });
                    if (!p.passenger.name.first || !p.passenger.name.last) {
                        var defaultPassager = p.passenger;
                        defaultPassager.name.first = name[0];
                        p.passenger = defaultPassager;
                    }
                });
            }
        });
    };
    SsrPassengerPickerComponent.prototype.checkNoBagsSelected = function (passengerIndex) {
        var _this = this;
        if (passengerIndex == undefined && this.selectedPassenger != undefined) {
            passengerIndex = this.selectedPassenger;
        }
        var noBag = this.ssrsService.noBagsSync.find(function (n) { return n.flight.split('~')[4] == _this.flightCodes.split('_')[0] && n.passengerIndex == passengerIndex; });
        return noBag ? noBag.noBags : false;
    };
    return SsrPassengerPickerComponent;
}());
export { SsrPassengerPickerComponent };
