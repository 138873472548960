<check-in-default-modal #shouldApplyUserPromotionModal modalId="shouldApplyUserPromotionModal"
  modalTitle="{{'Eligible to apply the subscription promotion' | translate:'navigation':[rainbowPassId] }}"
  okButtonText="{{'Yes' | translate:'navigation' }}" cancelButtonText="{{'No' | translate: 'navigation'}}"
  [okRequired]="true" [cancelRequired]="true">
  <div innerHTML="{{'Do you want to apply the promotion?' | translate: 'navigation'}}">
  </div>
</check-in-default-modal>

<div class="content-banner checkin-banner min-width" *ngIf="showNavigation">
  <div class="banner-background" [style.backgroundImage]="'url(' + imagesPath + '15.jpg)'"
    attr.aria-label="{{ 'Check-in header' | translate:'navigation' }}">
    <div class="wrapper">
      <div class="checkin-banner-content">
        <h1 *ngIf="isCheckinFlow" class="white-txt h2">{{ 'Check-in' | translate: 'navigation'}}</h1>
        <div class="checkin-flight-info">
          <span *ngIf="isCheckinFlow" class="checkin-location">
            {{bookingService.recordLocator}}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="checkin-steps-wrap" attr.aria-label="{{ 'Check-in steps' | translate:'navigation' }}">
    <div class="wrapper">
      <button type="button" class="checkin-step prev-step" aria-hidden="true"
        attr.aria-label="{{ 'Click to go to previous step' | translate:'navigation' }}"
        [ngClass]="{'disabled': !canGoToPreviousStep }" (click)="gotoOtherStep(false)"
        [disabled]="!canGoToPreviousStep">
        <span class="sr-only">{{ 'Previous step' | translate:'navigation' }}</span>
        <span class="font-ico-arrow-left-thin"></span>
      </button>
      <ul role="menu">
        <ng-container *ngFor="let item of routes">
          <li role="menuitem" *ngIf="item.visible" [ngClass]="{'active': item.isActive, 'disabled': item.isDisabled}">
            <button type="button" (click)="goto(item.step)"
              attr.aria-label="{{ item.navigationLabel + ' - ' + item.navigationText }}">
              <span class="label">{{item.navigationLabel}}</span>
              <span [innerHTML]="item.navigationText | translate: 'navigation'"></span>
            </button>
          </li>

        </ng-container>
      </ul>
      <button type="button" class="checkin-step next-step" aria-hidden="true"
        attr.aria-label="{{ 'Click to go to next step' | translate:'extras' }}"
        [ngClass]="{'disabled': !canGoToNextStep }" (click)="gotoOtherStep(true)" [disabled]="!canGoToNextStep">
        <span class="sr-only">{{ 'Next step' | translate: 'navigation'}}</span>
        <span class="font-ico-arrow-right-thin"></span>
      </button>
    </div>
  </div>
</div>