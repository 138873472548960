import { Directive, ViewContainerRef, TemplateRef, OnInit, Input, OnChanges } from '@angular/core';
import { TranslateService } from './translate.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[blueTanslateItems]'
})
export class TranslateItemsDirective implements OnChanges {

  @Input() blueTanslateItems: string;
  @Input() blueTanslateItemsDefaultKey: string;
  @Input() blueTanslateItemsDefaultScope: string;
  @Input() blueTanslateItemsTop: number;

  constructor(private container: ViewContainerRef, private template: TemplateRef<any>, private translateService: TranslateService) {

  }

  ngOnChanges(_parm) {
    this.container.clear();
    const items = this.translateService.getScopeItems(this.blueTanslateItems);
    if (items.length) {
      const top = this.blueTanslateItemsTop || items.length;
      for (let i = 0; i < top; i++) {
        this.container.createEmbeddedView(this.template, { $implicit: items[i]});
      }
    } else if (this.blueTanslateItemsDefaultKey) {
      this.container.createEmbeddedView(this.template, {
        $implicit: this.translateService.instant(this.blueTanslateItemsDefaultKey, this.blueTanslateItemsDefaultScope)
      });
    }
  }
}
