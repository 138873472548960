import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';
import { SsrModel } from '../ssr-model';
import { PassengerSsrModel } from '../passenger-ssr-model';

@Component({
  selector: 'check-in-ssr-box-content-meals',
  templateUrl: './ssr-box-content-meals.component.html',
  styleUrls: ['./ssr-box-content-meals.component.scss']
})
export class SsrBoxContentMealsComponent extends SsrBoxContentBase {
  protected minimumPriceToBeVisible: number = null;
  uniqueId: number;
  
  @Output() selectSsrEvent2 = new EventEmitter();
  
  constructor(private ssrsService: SsrsService) {
    super(ssrsService);

    this.ssrType = SsrType.Meal;
    this.uniqueId = Math.floor(Math.random() * 1000) + 1;
    // this.isAllowedToRemovePaidSsrs = true;
  }

  selectSsr(passengerIndex: number = null, ssrCode: string = null, newSsrQuantity: number = 0, isExternalCall: boolean = false) {
    if (this.mobileMatches) {
      this._isSsrsPopupOpened = false;
  }

    if (newSsrQuantity > 0) {
      passengerIndex = passengerIndex == null ? this.selectedPassenger : passengerIndex;
      if (passengerIndex === -1) {
        this.passengers.forEach((p, i) => this.removeAllSsrs(i, true, isExternalCall));
      } else {
        this.removeAllSsrs(passengerIndex, true, isExternalCall);
      }
    }
    super.selectSsr(passengerIndex, ssrCode, newSsrQuantity, isExternalCall);
  }

  toggleMealDetails(event, ssr: SsrModel) {
    event.preventDefault();
    ssr.showDetails = ssr.showDetails ? false :  true;
  }

  resetSsr() {
    this.selectSsr(null, "", 0); 
    this.selectSsrEvent(null);
  }

  selectSsrEvent(passengerIndex: number = null) {
    this.selectSsrEvent2.emit(passengerIndex);
  }

  checkSameSsrOptionsForAllFlights() {
    this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
    this.selectSsrEvent(null);
  }  
  // protected initPassengerSsrs(passenger: PassengerSsrModel) {
  //   super.initPassengerSsrs(passenger);

  //   if (passenger.initialSsrs.length) {
  //     passenger.initialSsrs = [passenger.initialSsrs[passenger.initialSsrs.length - 1]];
  //     passenger.initializeSsrs();
  //   }
  // }

  // protected updateSelectedSsr() {
  //   super.updateSelectedSsr();
  //   const passengerNumber = this.getSeletedPassenger().passenger.passengerNumber;

  //   const minimumPriceToBeVisible = this.getPaidPricePerPassenger(passengerNumber);

  //   this.availableSsrs.forEach(ssr => ssr.isVisible = ssr.price >= minimumPriceToBeVisible);
  // }

  // protected updateTotalPrice() {
  //   let totalPrice = 0;
  //   this.passengers.forEach(p => {
  //     const selectedSsr = p.getSelectedSsrs();
  //     if (selectedSsr.length) {
  //       totalPrice += selectedSsr[0].price - this.getPaidPricePerPassenger(p.passenger.passengerNumber);
  //     }
  //   });

  //   this.totalPriceChanged.next(totalPrice);
  // }

  // protected getPaidPricePerPassenger(passengerNumber: number): number {
  //   const paidSsrs = this._flight.paidSsrs[passengerNumber]
  //     .map(fs => this.availableSsrs.find(avSsr => avSsr.ssrCode === fs))
  //     .filter(ssr => ssr);

  //   const minimumPriceToBeVisible = paidSsrs.length ? paidSsrs[0].price : 0;
  //   return minimumPriceToBeVisible;
  // }
}
