import { Constants } from '../../constants';
import { Journey } from "./journey-model";
import { debug } from "util";
import { Cell, RowCells, RowGroup } from './row-cell-model';
import { Observable, Subject } from 'rxjs';
import { share, shareReplay } from 'rxjs/operators';
import { SeatMapModel } from './seat-map.model';
export class JourneyViewModel {
  seatmaps: Array<any> = [];
  flightKey: string;
  journeyIndex: number = 0;
  currentSeatSelection: Array<any> = [];
  currentSeatsToDelete: Array<any> = [];
  seatPrice: number = 0;
  /**
   *
   */
  constructor() {


  }

  removeSeat(passengerNumber, flightKey) {
    this.currentSeatSelection = this.currentSeatSelection || [];
    if (this.currentSeatSelection.findIndex(p => p.flightKey == flightKey && p.passengerNumber == passengerNumber) >= 0) {
      var deletedSeats = this.currentSeatSelection.splice(this.currentSeatSelection.findIndex(p => p.flightKey == flightKey && p.passengerNumber == passengerNumber), 1);
      this.currentSeatsToDelete.push(deletedSeats);

    }
    this.calculateSeatTotal();
  }

  addSeat(unit, pax) {

    this.currentSeatSelection = this.currentSeatSelection || [];

    if (this.currentSeatSelection.findIndex(p => p.flightKey == unit.flightKey && p.passengerNumber == pax.passengerNumber) >= 0) {
      this.currentSeatSelection.splice(this.currentSeatSelection.findIndex(p => p.flightKey == unit.flightKey && p.passengerNumber == pax.passengerNumber), 1);
    }
    pax.assignedSeat[unit.flightKey] =
    {
      passengerNumber: pax.passengerNumber,
      compartment: unit.compartmentDesignator,
      deck: unit.deck,
      unit: unit.unitDesignator,
      isAdded: true
    };

    this.currentSeatSelection.push({
      'flightKey': unit.flightKey,
      'passengerNumber': pax.passengerNumber,
      'price': unit.price,
      "deck": unit.deck,
      'compartment': unit.compartmentDesignator,
      "unit": unit.unitDesignator,

    });

    this.calculateSeatTotal();

  }
  private calculateSeatTotal() {
    let total = 0;

    //update seatPrice
    let paxNumbers = this.currentSeatSelection.map(p => p.passengerNumber);
    let passengers = paxNumbers.filter(
      (p, i) =>
        paxNumbers.indexOf(p) == i)
      .forEach(pa => {
        let paxSeats = this.currentSeatSelection.filter(pax => pax.passengerNumber == pa);
        total += paxSeats.reduce((a, b) => a + b.price, 0);

      });

    this.seatPrice = total;
  }
}
export class SeatMap {
  journeys: Array<JourneyViewModel> = [];
  seatMaps: Array<SeatMapModel> = []
  seatAssignment: SeatAssignment = new SeatAssignment()
}
export class SeatAssignment {

  journeys: Array<Journey> = new Array<Journey>();

}


export class Deck {
  number: number = 1;
  compartments: Array<Compartment> = new Array<Compartment>()
}
export class Compartment {
  compartmentDesignator: string = "Y";
  units: Array<Unit> = new Array<Unit>();

}
export class Unit {
  assignable: boolean;
  seatProperties: Array<number> = [];
  unitGroup: number;
  unitDesignator: string;
  allowedSsrs: Array<number>;
  unitType: string;
  flightKey: string;
  deck: number;
  y: number;
  x: number;
  baseDecorator: string;
  masterDecorator: string;
  unitAvailability: string;
  unitAvailabilityByPax: string[];
  decorator: string;
  mainColorClass: string;
  text: string;
  width: number;
  price: any;
  constructor(text?: any) {
    this.text = text;
  }
}
