import { BookingService } from './../../core/booking.service';
import { SessionService } from './../../common-modules/blue-air-common/session.service';
import { Component, OnInit } from '@angular/core';
import { CheckinSteps, BookingStepsService } from '../../core/booking-steps.service';
import { ProfileService } from '../../core/profile.service';

@Component({
  selector: 'check-in-booking-flight-search',
  templateUrl: './booking-flight-search.component.html',
  styleUrls: ['./booking-flight-search.component.scss']
})

export class BookingFlightSearchComponent implements OnInit {
  private currentStep: CheckinSteps = CheckinSteps.search;

  constructor(
    private bookingSteps: BookingStepsService,
    private bookingService: BookingService,
    private sessionService: SessionService,
    private profileService: ProfileService
  ) {
    this.bookingSteps.currentStep.next(this.currentStep);

    this.bookingService.clearSession().then(_ => {
      if (profileService.isAgent) return;

      this.sessionService.resetSession();
    });
  }

  ngOnInit() { }
}
