import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { BookingStatusEnum } from '../../booking-status.enum';
import { BookingStepsService, CheckinSteps } from '../../../core/booking-steps.service';
import { BookingFlowService } from '../../../core/booking-flow.service';
import { ConfigService } from '../../../core/config.service';
import { BookingService } from '../../../core/booking.service';
import { OnInit, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
var DisplayBookingsComponent = /** @class */ (function () {
    function DisplayBookingsComponent(bookingService, configService, bookingFlowService, bookingStepsService, loadingSpinnerService) {
        this.bookingService = bookingService;
        this.configService = configService;
        this.bookingFlowService = bookingFlowService;
        this.bookingStepsService = bookingStepsService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.spinnerId = "retrieveMyBookingSpinner";
        this.bookingStatusEnum = BookingStatusEnum;
        // public corporateRootUrl: string;
        this.pastBookings = false;
        this.bookingDataSubscriber = new BehaviorSubject([]);
        // this.corporateRootUrl = this.configService.config.corporateUrl;
    }
    Object.defineProperty(DisplayBookingsComponent.prototype, "bookingData", {
        set: function (value) {
            this.allBookings = value;
            this.bookingDataSubscriber.next(value);
        },
        enumerable: true,
        configurable: true
    });
    DisplayBookingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.bookingDataSubscriber.subscribe(function (data) {
            _this.filterPastBookings(data);
        });
    };
    DisplayBookingsComponent.prototype.ngOnChanges = function () {
        this.togglePastBookings();
    };
    DisplayBookingsComponent.prototype.retrieveBooking = function (recordLocator, lastName, redirectToItinerary, event) {
        // this.bookingService.clearSession();
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        this.bookingService.retrieveBooking(recordLocator, lastName, '', this.configService.BlueRetrieve)
            .then(function (response) {
            if (response) {
                if (redirectToItinerary) {
                    _this.bookingFlowService.loadFlowInfo(true)
                        .then(function (response2) {
                        if (response2) {
                            _this.bookingService.recordLocator = recordLocator;
                            _this.bookingStepsService.goToStep(CheckinSteps.itinerary);
                            _this.loadingSpinnerService.hideSpinnerById(_this.spinnerId);
                        }
                    });
                }
                else {
                    event.srcElement.hidden = true;
                    var currentBooking = _this.allBookings.find(function (booking) { return booking.RecordLocator === recordLocator; });
                    currentBooking.setBookingData(response.bookingContacts.items[0].emailAddress, response.starterPriceBreakdown.balanceDue, response.starterPriceBreakdown.grandTotalPrice, response.bookingDetail.bookingDate, response.blueRetrieveBooking.bookingStatus);
                    _this.loadingSpinnerService.hideSpinnerById(_this.spinnerId);
                }
            }
        });
    };
    DisplayBookingsComponent.prototype.payBooking = function (recordLocator, lastName) {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        this.bookingService.retrieveBooking(recordLocator, lastName, '', this.configService.BlueRetrieve)
            .then(function (response) {
            if (response) {
                _this.bookingFlowService.loadFlowInfo(true)
                    .then(function (response2) {
                    if (response2) {
                        _this.bookingService.recordLocator = recordLocator;
                        _this.bookingStepsService.goToStep(CheckinSteps.summary);
                        _this.loadingSpinnerService.hideSpinnerById(_this.spinnerId);
                    }
                });
            }
        });
    };
    DisplayBookingsComponent.prototype.checkDepartureDate = function (departureDate) {
        var temp = new Date(1900, 0);
        var momentDate = moment(temp);
        var departureDateMoment = moment(departureDate);
        return momentDate.isBefore(departureDateMoment) ? true : false;
    };
    DisplayBookingsComponent.prototype.togglePastBookings = function () {
        if (this.pastBookings) {
            this.filteredBookingData = this.allBookings;
        }
        else {
            this.filterPastBookings(this.allBookings);
        }
    };
    DisplayBookingsComponent.prototype.filterPastBookings = function (data) {
        var _this = this;
        this.filteredBookingData = [];
        data.forEach(function (b) {
            var t = moment(b.FlightDate);
            if (t.isAfter(moment())) {
                _this.filteredBookingData.push(b);
            }
        });
    };
    return DisplayBookingsComponent;
}());
export { DisplayBookingsComponent };
