<div class="checkin-plane-map">
  <div class="plane-container">
    <div class="plane-row">
      <span class="plane-cell plane-label" *ngFor="let letter of rowLetters.units"
        [ngClass]="{'small':letter.text.length==0}">
        {{letter.text}}
      </span>
    </div>
    <ng-container *ngFor="let row of seatRows">
      <div class="plane-row info">
        <ng-container *ngIf="row.hasDecoratorStyle">
          <ng-container *ngFor="let group of row.cellGroups;let i = index">
            <div class="plane-cell" *ngIf="!group.groupName || group.emptyGroup; else showWrapper"></div>
            <ng-template #showWrapper>
              <!-- <div class="row-info-wrapper one-seat-space"> -->
              <div [ngClass]="getClassForGroup( group)">
                <div class="row-info-container">
                  <label [ngClass]="group.linkedCell?.mainColorClass">{{group.groupName | currencyDisplay}}</label>
                  <span class="row-info-outlines"></span>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
      <div class="plane-row">
        <div class="plane-cell" *ngFor="let cell of row.units" (mouseover)="changeStyle($event, cell)"
          (mouseout)="changeStyle($event, cell)">
          <span class="plane-row-label" attr.aria-label="{{'Plane row ' | translate: 'seatmap'}}"
            *ngIf="cell.text.length !=0">{{cell.text}}</span>
          <button type="button" class="legend-icon " [ngClass]="cell.decorator" data-seat="" (click)="unitClicked(cell)"
            *ngIf="cell.text.length ==0">

            <span class="sr-only">{{'Click to select seat {0} '|translate:'':cell.unitDesignator}}</span>
            <strong>{{cell.passengerNumber}}</strong>
            <span class="hidden">{{cell|json}}</span>
            <div class="show-help" aria-haspopup="true">
              <div class="help-bubble" [style.display]="cell.style">
                <div class="bubble-inner">
                  <p>
                    {{'Click to select seat {0} '|translate:'':cell.unitDesignator}}
                    {{'Seat price {0}'|translate:'':[cell.price|currencyDisplay]}}
                  </p>

                </div>

              </div>
            </div>
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>