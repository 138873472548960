import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { BookingService } from './booking.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { CurrencyManagerService } from './currency-manager.service';
import { ApplicationFlowService } from './application-flow.service';
import { BookingStepsService, ApplicationFlowEnum } from './booking-steps.service';
import { LoggerService } from '../common-modules/log4ts/logger.service';
import { shareReplay } from 'rxjs/operators';

type StationCode = string;
type CountryCode = string;

@Injectable()
export class BookingFlowService extends ApplicationFlowService {
  protected isCheckinFlow = false;

  canContinue: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  checkInInfoSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  scrollToPassengersObs: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(http: HttpClient, configService: ConfigService, currencyManager: CurrencyManagerService,
    private bookingService: BookingService, private translate: TranslateService, private bookingSteps: BookingStepsService, private logService: LoggerService) {
    super(http, configService, currencyManager);
  }

  loadFlowInfo(forced: boolean = false): Promise<any> {
    return this.bookingService.refresh(forced).then((b: any) => {
      if (b && b.convertedJourneys) {
        this.currency = b.convertedJourneys.currencyCode;
      }
      return b;
    });
  }

  protected getPriceBreakdown(): Observable<any> {
    let params = new HttpParams();
    if (this.bookingSteps.flow === ApplicationFlowEnum.LockFare) {
      params = params.set('lf', 'true');
    }

    return super.getPriceBreakdown(params);
  }

  updateShoppingCartFromItinerary(itinerary: any, ignoreDiscounts: boolean) {
    this.shoppingCartBreakdown.next(this.shoppingCartBreakdown.value.updateFromItinerary(itinerary, ignoreDiscounts));
  }

  overrideShoppingCartBalanceDue(newBalanceDue: number): void {
    this.shoppingCartBreakdown.next(this.shoppingCartBreakdown.value.overrideBalanceDue(newBalanceDue));
  }

  getBooking() {
    return this.loadFlowInfo();
  }

  loadPriceItinerary(sellKeys: string[], bundles: string[]): Observable<any> {
    const reqObj: any = { 'priceItinerary': { 'SellKeys': sellKeys } };

    if (bundles.every(bundle => bundle !== null)) {
      reqObj.priceItinerary.BundleCodes = bundles;
    }

    return this.http.post(this.configService.PriceItinerary, reqObj)
      .pipe(
        shareReplay(1)
      );
  }

  loadSuperStations(): Promise<ICountry[]> {
    return this.http.get<ICountry[]>(this.configService.SuperStationsUrl).toPromise()
      .then(countries => {

        if (this._availableStations) {
          try {
            return this._filterStations(countries);
          } catch (err) {
            this.logService.error('Failed to filter stations by available CMS stations', err);
            return this._filterStationsOldStyle(countries);
          }
        } else {
          //for backward compatibility if we don't have window.$availableStations we will work like before showing all countries/stations
          return this._filterStationsOldStyle(countries);
        }

      });
  }

  //@TODO - this function and its usages should be removed after the support for filtering stations according to CMS will be deployed to production
  private _filterStationsOldStyle(countries: ICountry[]): ICountry[] {
    countries.forEach(country => {
      country.value = this.translate.instant(country.code, 'country.name');
      country.stations.forEach(station => {
        station.value = this.translate.instant(station.code, 'station.name');
      });
    });

    return countries;
  }

  private _filterStations(countries: ICountry[]): ICountry[] {

    return countries.map(country => {
      country.value = this.translate.instant(country.code, 'country.name');
      country.stations = country.stations || [];
      country.stations = country.stations.filter(station => this._availableStations[station.code])
        .map(station => {
          station.value = this.translate.instant(station.code, 'station.name');
          station.markets = station.markets || [];
          station.markets = station.markets.filter(marketCode => this._availableStations[marketCode]);
          return station;
        })
        .filter(station => station.markets.length > 0)
        .sort((s1, s2) => s1.value.localeCompare(s2.value));

      return country;

    })
      .filter(country => country.stations.length > 0)
      .sort((c1, c2) => c1.value.localeCompare(c2.value));

  }

  private get _availableStations(): Record<StationCode, CountryCode> | undefined {
    return window['$availableStations'] as Record<StationCode, CountryCode>;
  }

}

interface IStation {
  code: string;
  value: string;
  macs: any;
  markets: string[];
}

interface ICountry {
  code: string;
  value: string;
  stations: IStation[];
}

