import * as moment from 'moment';

export class CalendarSelection {
    private _format: string;
    private _date: moment.Moment;

    set format(value: string) {
        this._format = value;
    }
    get format() {
        return this._format || 'YYYY-MM-DD';
    }

    set date(value: string) {
        this._date = moment(value, this.format);
    }
    get date(): string {
        if (!this._date) {
            return null;
        }
        return this._date.format(this.format);
    }

    set dateMoment(value: moment.Moment) {
        this._date = moment(value);
    }
    get dateMoment(): moment.Moment {
        return this._date;
    }

    farePrice: number;
}
