<div class="cart-flight promocode" *ngIf="showPromoCodeWidget" [ngClass]="{'boxed': showMargins}">
  <!-- initiate promocode -->
  <div class="field-inner" *ngIf="!promotionApplied">
   
    <label for="promocode">{{ 'Promocode Text' | translate: 'price-display' }}</label>
    <div class="same-line">
      <div class="left"><input type="text" name="promocode" id="promocode"  [(ngModel)]="promoCode"/></div>
      <div class="right">
        <button class="btn btn-primary btn-big changeFlight_button" (click)="applyPromo()">
       
        <span>{{ 'Apply' | translate: 'price-display' }}</span>
    </button>
      </div>
    </div>
    <label class="danger" *ngIf="invalidCode">
      <ul>
        <li *ngFor="let error of errorMessages">
          {{ error | translate: 'price-display' }}
        </li>
      </ul>
      <br/>
    </label>
  </div>

  <!-- if promocode has beed applied correctly use div below: -->
  <div class="promocode-applied" *ngIf="promotionApplied" >
    <div  class="msg" *ngFor="let code of promoCodes">
      {{ '{0} Applied' | translate: 'price-display': [code | translate:'promo-code'] }}
    </div>
  
    <button class="cancel-promo" (click)="deletePromo()">
      <span class="font-ico-close-menu"></span>
  </button>
  </div> 
</div>