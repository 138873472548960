import { TranslateService } from './../common-modules/blue-air-common/translator/translate.service';
import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'cultureFormatNumber'
})

export class CultureFormatNumberPipe extends DecimalPipe implements PipeTransform {

  constructor(translateService: TranslateService) {
    super(translateService.culture);
  }

  transform(value: any, digits?: string): string {
    return super.transform(value, digits);
  }

}
