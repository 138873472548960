import { Constants } from './../../constants';
import { Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Accomodation } from '../../core/models/accomodation-model';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import * as moment from 'moment';
import { BookingFlowService } from '../../core/booking-flow.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'accomodation',
  templateUrl: './accomodation.component.html',
  styleUrls: ['./accomodation.component.scss']
})
export class AccomodationComponent implements OnInit {

  @ViewChild('script_container', { static: true })
  private scriptContainerRef: ElementRef;

  private accomodation: Accomodation;

  constructor(private bookingFlowService: BookingFlowService,
              private translateService: TranslateService,
              private render: Renderer2) {
    this.accomodation = new Accomodation();
  }

  ngOnInit() {
    this.bookingFlowService.loadFlowInfo().then(booking => {
      const firstJourney = booking.convertedJourneys.journeys[0];
      const lastJourney = booking.convertedJourneys.journeys[booking.convertedJourneys.journeys.length - 1];
      const lastSegment = firstJourney.segments[firstJourney.segments.length - 1];
      const arrivalAirportCode = lastSegment.legs[lastSegment.legs.length - 1].arrivalStation;

      this.accomodation = new Accomodation();

      this.accomodation.arrivalAirportCode = arrivalAirportCode;
      this.accomodation.bookingCurrencyCode = booking.convertedJourneys.currencyCode;
      this.accomodation.checkinDate = this.calculateCheckinDate(firstJourney);
      this.accomodation.checkoutDate = this.calculateCheckoutDate(firstJourney, lastJourney);
      this.accomodation.language = this.translateService.culture.split('-')[0];
      this.accomodation.dateFormat = 'YYYY-MM-DD';

      this.generateScriptElement();
    });
  }

  calculateCheckinDate(firstJourney: any) {
    const lastSegment = firstJourney.segments[firstJourney.segments.length - 1];
    const lastLeg = lastSegment.legs[lastSegment.legs.length - 1];

    return moment(lastLeg.sTA);
  }

  calculateCheckoutDate(firstJourney: any, lastJourney: any) {
    if (firstJourney.journeyTripType.toLowerCase() === Constants.RoundTrip.toLowerCase()) {
      const firstSegment = lastJourney.segments[0];
      const firstLeg = firstSegment.legs[0];

      return moment(firstLeg.sTD);
    } else {
      const lastSegment = firstJourney.segments[firstJourney.segments.length - 1];
      const firstLeg = lastSegment.legs[0];

      return moment(moment(firstLeg.sTD).add(1, 'days'));
    }
  }

  generateScriptSrc() {
    return '//aff.bstatic.com/static/affiliate_base/js/booking_sp_widget.js?' +
    'checkin=' + moment(this.accomodation.checkinDate).format(this.accomodation.dateFormat) +
    '&checkout=' + moment(this.accomodation.checkoutDate).format(this.accomodation.dateFormat) +
    '&iata_orr=1&iata=' + this.accomodation.arrivalAirportCode +
    '&selected_currency=' + this.accomodation.bookingCurrencyCode +
    '&lang=' + this.accomodation.language;
  }

  generateScriptElement() {
    const scriptElement = this.render.createElement('script');
    this.render.setAttribute(scriptElement, 'id', 'sp_widget');
    this.render.setAttribute(scriptElement, 'type', 'text/javascript');
    this.render.setAttribute(scriptElement, 'data-hash', '_7ac9705cb8c0cd327');
    this.render.setAttribute(scriptElement, 'data-container', 'b_container');
    this.render.setAttribute(scriptElement, 'data-size', '100%x500');
    this.render.setAttribute(scriptElement, 'data-tpncy', 'false');
    this.render.setAttribute(scriptElement, 'src', this.generateScriptSrc());
    this.render.appendChild(this.scriptContainerRef.nativeElement, scriptElement);
  }
}
