import { Component, OnInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { CarHire, Pickup, Return } from '../../core/models/car-hire-model';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { Constants } from '../../constants';
import { BookingFlowService } from '../../core/booking-flow.service';
import * as moment from 'moment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'car-hire',
  templateUrl: './car-hire.component.html',
  styleUrls: ['./car-hire.component.scss']
})
export class CarHireComponent implements OnInit {

  @ViewChild('car_hire', { static: true })
  carHireRef: ElementRef;

  private carHire: CarHire;

  private scriptSrc: string;

  constructor(private bookingFlowService: BookingFlowService,
    private translateService: TranslateService,
    private render: Renderer2) { }

  ngOnInit() {
    this.bookingFlowService.loadFlowInfo().then(booking => {
      if (booking) {
        this.carHire = this.generateCarHire(booking);
      }

      this.scriptSrc = this.generateScriptSrc();
      this.generateIframeElement();
    });
  }

  generateScriptSrc() {
    return 'https://secure.rentalcars.com/WidgetSearch.do'
    + '?affiliateCode=' + this.carHire.affiliateCode
    + '&driverAge=' + this.carHire.driversAge
    + '&preflang=' + this.carHire.prefLang
    + '&results=' + this.carHire.results
    + '&affUrl=' + this.carHire.affUrl
    + '&prefcurrency=' + this.carHire.prefCurrency
    + '&ng=' + this.carHire.ng
    + '&dateJump=' + this.carHire.dateJump
    + '&pickupIATACode=' + this.carHire.pickup.IATACode
    + '&pickupMinute=' + this.carHire.pickup.Date.minute()
    + '&pickupHour=' + this.carHire.pickup.Date.hour()
    + '&pickupDate=' + this.carHire.pickup.Date.date()
    + '&pickupMonth=' + (this.carHire.pickup.Date.month() + 1).toString()
    + '&pickupYear=' + this.carHire.pickup.Date.year()
    + '&returnMinute=' + this.carHire.return.Date.minute()
    + '&returnHour=' + this.carHire.return.Date.hour()
    + '&returnDate=' + this.carHire.return.Date.date()
    + '&returnMonth=' + (this.carHire.return.Date.month() + 1).toString()
    + '&returnYear=' + this.carHire.return.Date.year();
  }

  generateIframeElement() {
    const carHireElement = this.render.createElement('iframe');
    this.render.addClass(carHireElement, 'rentalCarsAd');
    this.render.setAttribute(carHireElement, 'src', this.scriptSrc);
    this.render.setAttribute(carHireElement, 'width', '100%');
    this.render.setAttribute(carHireElement, 'height', '420');
    this.render.setAttribute(carHireElement, 'scrolling', 'no');
    this.render.setAttribute(carHireElement, 'frameborder', '0');
    this.render.setAttribute(carHireElement, 'border', '0');
    this.render.setAttribute(carHireElement, 'scrolling', 'auto');
    this.render.appendChild(this.carHireRef.nativeElement, carHireElement);
  }

  generateCarHire(booking) {
    const pickupDate: moment.Moment = this.getPickupDate(booking);
    const returnDate: moment.Moment = this.getReturnDate(booking, pickupDate);

    const firstJourney = booking.convertedJourneys.journeys[0];
    const lastSegment = firstJourney.segments[firstJourney.segments.length - 1];
    const arrivalAirportCode = lastSegment.legs[lastSegment.legs.length - 1].arrivalStation;

    const pickup: Pickup = new Pickup(arrivalAirportCode, pickupDate);
    const ret: Return = new Return(returnDate);

    this.carHire = new CarHire('blueair_rc', '25', this.translateService.culture.split('-')[0],
    '3', 'carrental.blueairweb.com', booking.convertedJourneys.currencyCode, booking.passengers.items.length,
    false, pickup, ret);

    return this.carHire;
  }

  getPickupDate(booking: any) {
    const firstJourney = booking.convertedJourneys.journeys[0];
    const lastSegment = firstJourney.segments[firstJourney.segments.length - 1];
    const pickupDate = moment(lastSegment.legs[lastSegment.legs.length - 1].sTA);

    return pickupDate.add(1, 'hours');
  }

  getReturnDate(booking: any, pickupDate: moment.Moment) {
    const lastJourney = booking.convertedJourneys.journeys[booking.convertedJourneys.journeys.length - 1];

    if (lastJourney.journeyTripType.toLowerCase() !== Constants.RoundTrip.toLowerCase()) {
      return moment(pickupDate).add(7, 'days');
    }

    const firstSegment = lastJourney.segments[0];
    return moment(firstSegment.legs[0].sTD);
  }
}
