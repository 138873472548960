import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassengersComponent } from './passengers/passengers.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';

import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { SharedModule } from '../shared/shared.module';
import { BookingRoutes } from './passengers-routes';

import { ExtrasModule } from '../extras/extras.module';
import { DocumentsModule } from '../documents/documents.module';
import { SinglePassengerSpecialAssistanceComponent } from './passengers/single-passenger-special-assistance/single-passenger-special-assistance.component';

import { CanDeactivateComponentService } from '../core/can-deactivate-component.service';
import { CanActivatePassengersService } from './can-activate-passengers.service';
import { UnaccompaniedMinorNotificationComponent } from './unaccompanied-minor-notification/unaccompanied-minor-notification.component';
import { LockFarePassengersComponent } from './lock-fare-passengers/lock-fare-passengers.component';
import { PaxContactDetailsComponent } from './pax-contact-details/pax-contact-details.component';
import { PaxDetailsComponent } from './pax-details/pax-details.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ExtrasModule,
    // RouterModule.forRoot(
    //   environment.flow==1?BookingRoutes:BookingRoutes
    // ),
    BlueAirCommonModule,
    SharedModule, 
    DocumentsModule
  ],
 
  declarations: [PassengersComponent, SinglePassengerSpecialAssistanceComponent, UnaccompaniedMinorNotificationComponent, LockFarePassengersComponent, PaxContactDetailsComponent, PaxDetailsComponent],
  providers: [CanDeactivateComponentService, CanActivatePassengersService]
})
export class PassengersModule { }
