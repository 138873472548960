<check-in-default-modal #notEnoughSelectedFlightsModal [modalId]="notEnoughSelectedFlightsModalId"
  modalTitle="{{'Not enough flights were selected' | translate: 'flight-select'}}"
  modalText="{{'Please select flights for your trip! One flight for one-way or two flights for two-way!' | translate: 'flight-select'}}"
  okButtonText="{{'Ok' | translate: 'flight-select'}}" [okRequired]="true" [cancelRequired]="false">
</check-in-default-modal>

<check-in-default-modal #bundleUpgradeModal [modalId]="bundleUpgradeModalId"
  modalTitle="{{'Upgrade your flight' | translate: 'flight-select'}}"
  modalText="{{'{0} is ideal for your trip!' | translate: 'flight-select' : getUpgradeBundleName()}}"
  okButtonText="{{'Continue with {0}' | translate: 'flight-select' : getCurrentBundleName()}}"
  cancelButtonText="{{'Upgrade to {0}' | translate: 'flight-select' : getUpgradeBundleName()}}" [okRequired]="true"
  [cancelRequired]="true" leftButtonClass="inverted" [showCloseBtn]="false">
  <div class="upgrade-benefits">
    <ul>
      <li class="bundleUpgradePopupListHeader">
        <div>{{'Benefit' | translate: 'flight-select'}}</div>
        <div><span>{{ getCurrentBundleName() }}</span></div>
        <div><span>{{ getUpgradeBundleName() }}</span></div>
      </li>
      <li *ngFor="let ssr of allSSRS">

        <div><span class="bundleUpgradePopupSsrText"
            [innerHTML]="ssr.ssrCode | ssrTranslate: getBundleUpgradeCode() : flights[0].departureStation.date"></span>
        </div>
        <div class="validation">
          <div [ngClass]="ssr.currentHasSsr ? 'true' : 'false' "></div>
        </div>
        <div class="validation">
          <div [ngClass]="ssr.upgradeHasSsr ? 'true' : 'false' "></div>
        </div>
      </li>
    </ul>
  </div>

</check-in-default-modal>

<check-in-default-modal #warningModal [modalId]="warningModalId"
  modalTitle="{{'Searched flight date and cart flight date are different' | translate: 'flight-select'}}"
  modalText="{{'Flight day was changed but there was no selection made. You will continue with the previous flight selection!' | translate: 'flight-select'}}"
  okButtonText="{{'Continue' | translate: 'flight-select'}}"
  cancelButtonText="{{'Cancel' | translate: 'flight-select'}}" [okRequired]="true" [cancelRequired]="true">
</check-in-default-modal>

<check-in-default-modal #bluebenefitsModal [modalId]="bluebenefitsModalId"
  modalTitle="{{'No subscription selected!' | translate: 'flight-select'}}"
  modalText="{{'You must select a subscription type to continue!' | translate: 'flight-select'}}"
  okButtonText="{{'Ok' | translate: 'flight-select'}}" [okRequired]="true">
</check-in-default-modal>

<check-in-default-modal #warningFlightDatesModal [modalId]="warningFlightDatesModalId"
  modalTitle="{{'Inbound flight date is in the past of Outbound flight date' | translate: 'flight-select'}}"
  modalText="{{ 'Inbound flight date is in the past of Outbound flight date or the diffrence is under or equal to 45 minutes. You must select a new flight!' | translate: 'flight-select'}}"
  okButtonText="{{ 'Ok' | translate: 'flight-select'}}" [okRequired]="true" [cancelRequired]="false">
</check-in-default-modal>
<check-in-default-modal #spoilageFeeModal modalId="spoilageFeeModal"
  modalTitle="{{ 'SpoilageFee-Warning-Title' | translate:'flights'  }}"
  modalText="{{ 'SpoilageFee-Warning-Text' | translate:'flights'  }}"
  okButtonText="{{'Continue' | translate: 'flight-select'}}"
  cancelButtonText="{{'Cancel' | translate: 'flight-select'}}" [okRequired]="true" [cancelRequired]="false">
</check-in-default-modal>



<check-in-form-wrapper [ariaLabel]="ariaLabel" [step]="'step-2'" [showContinue]="false" [showPriceDisplay]="true"
  (continue)="applySelection()">
  <div class="form-wrapper-middle-body" style="margin-bottom: 5rem;" *ngIf="isMessageOn">
    <div class="alert-holder alert-error" *ngIf="lockFareErrMessage">
      <div class="alert-item secondary">
        <h4>{{lockFareErrMessage}}</h4>
      </div>
    </div>
  </div>
  <div class="form-wrapper-middle-body">
    <div class="halfSplit_wrap">
      <button class="btn btn-primary btn-big halfSplit" (click)="toggleSearch()" *ngIf="flightSearchModel">
        <span *ngIf="isChangeSearchOpened">{{ 'Cancel Changes' | translate: 'flight-select'}}</span>
        <span *ngIf="!isChangeSearchOpened">{{ 'Change Search' | translate: 'flight-select'}}</span>
      </button>
      <a class="btn btn-primary btn-big calendar halfSplit" routerLink="/calendar">{{ 'Calendar' | translate:
        'flight-select'}}</a>
    </div>

    <ng-container *ngIf="moveFlights else changeFlight">
      <check-in-move-flight *ngIf="isChangeSearchOpened && !showBlueBenefitsSelection"
        [departureStation]="flightSearchModel.origin" [arrivalStation]="flightSearchModel.destination"
        [tripType]="!flightSearchModel.isRoundTrip" [inbound]="flightSearchModel.returnDate"
        [outbound]="flightSearchModel.departureDate" (callAvailability)="callAvailability()"
        [isForSearchingFlight]="true">
      </check-in-move-flight>
    </ng-container>

    <ng-template #changeFlight>
      <change-flight [isForChangingFlight]="isForChangingFlight" (search)="getFlights($event)"
        *ngIf="isChangeSearchOpened && !showBlueBenefitsSelection" [flightSearchModel]="flightSearchModel">
      </change-flight>
    </ng-template>


    <div *ngIf="!fares">
      <p>
        {{'Unfortunately we cannot find flights for the selected date. ' | translate}}
      </p>
    </div>
    <ng-container *ngIf="availability && availability.trips && availability.trips.length">


      <div *ngIf="!showBlueBenefitsSelection">
        <div *ngFor="let trip of availability?.trips; let i = index">
          <booking-fare-select [autoSellFareEvent]="autoFareSellTriggers[i].asObservable()"
            (isChangeSearchOpenedEvent)="toggleSearch($event)" [lowFare]="availability.lowFareAvailability[i]"
            [tripModel]="trip" [fares]="fares" (datechanged)="callAvailability(i)" [tripIndex]="i"
            [currency]="availability.bookingCurrencyCode" (isBlueBenefitsSelectedEvent)="sellBlueBenefits($event)"
            (onFareSold)="onFareSoldHandler($event)">
          </booking-fare-select>
        </div>
      </div>
      <check-in-bluebenefits-select *ngIf="showBlueBenefitsSelection"
        (goBackToFlightSelection)="backToFlightSelection($event)" [passengers]="flightSearchModel.passengers"
        [currency]="availability.bookingCurrencyCode"></check-in-bluebenefits-select>

      <app-lock-fare-select *ngIf="false" cssClass="mobile" [(lockFare)]="lockFareEnabled">
      </app-lock-fare-select>
      <!-- <button type="button" class="btn btn-primary flight-search-mobile-submit" attr.aria-label="{{ 'Click to confirm and continue' | translate }}"
          (click)="applySelection()">
          {{ 'Confirm and Continue' | translate:'layout' }}
      </button> -->
    </ng-container>
  </div>
  <app-lock-fare-select *ngIf="false" class="form-wrapper-sidebar-after" cssClass="desktop"
    [(lockFare)]="lockFareEnabled"></app-lock-fare-select>

</check-in-form-wrapper>