import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Constants } from 'src/app/constants';
import { TranslateService } from '../../../common-modules/blue-air-common/translator/translate.service';
import { ShoppingCartFlightModel, ShoppingCartPassengerModel } from '../../../core/models/shopping-cart-models';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'shopping-cart-flights',
  templateUrl: './shopping-cart-flights.component.html',
  styleUrls: ['./shopping-cart-flights.component.scss']
})
export class ShoppingCartFlightsComponent implements OnInit, OnChanges {
  @Input() flights: ShoppingCartFlightModel[];
  @Input() titleUnknown: boolean;

  total: number;
  originalTotal: number;
  isExpanded: { [flightIndex: number]: boolean } = {};
  anythingExpanded: boolean = false;
  constructor(protected translateService: TranslateService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.flights) {
      this.setupFlights();
    }
  }

  toggleExpanded(flightIndex: number): void {
    this.isExpanded[flightIndex] = !this.isExpanded[flightIndex];
    let anythingExpanded = false;
    this.flights.forEach((_, index) => anythingExpanded = anythingExpanded || this.isExpanded[index]);
    this.anythingExpanded = anythingExpanded;
  }

  private setupFlights() {
    if (!this.flights) {
      this.flights = [];
    }
    this.total = this.flights.sum(f => f.amount);
    let discounts = 0;

    this.flights.forEach(flight => {
      const freePax = flight.passengers.filter(p => p.amount === 0 && p.passengerType !== Constants.InfantSsrCode);
      freePax.forEach(pax => {
        discounts += pax.count * (flight.passengers[0].amount / flight.passengers[0].count);
      });
    });

    this.originalTotal = discounts > 0 ? discounts + this.total : 0;

    if (this.flights.length > 0) {
      this.flights.forEach(f => {
        let passengers = f.passengers;

        passengers.forEach(p => {
          let amountTotalPassengerType = 0;

          if (p.fees.length > 0) {
            let farePrice = p.fees.find(f => f.feeCode == 'FarePrice' || (p.passengerType == 'INF' && f.feeCode == 'INFT'));
            let farePriceAmount = 0;

            f.segments.forEach(s => {
              const foundPassenger = s.passengers.find(ps => ps.passengerType == p.passengerType);
              if (!foundPassenger) return;
              amountTotalPassengerType += foundPassenger.amount;

              let fees = foundPassenger.fees;

              if (fees.length > 0) {
                farePriceAmount += fees.find(f => f.feeCode == 'FarePrice' || (p.passengerType == 'INF' && f.feeCode == 'INFT')).amount;
              }
            })

            farePrice.amount = farePriceAmount;
            p.amount = amountTotalPassengerType;
          }
        })
      })
    }
  }

  getExtraFlightDetails(count: string, passengerType: string, selectedBundleCode: string) {
    return this.toHTML(`${count} x ${this.translateService.instant(passengerType, 'price-display')} ${this.translateService.instant(selectedBundleCode, 'Booking')}`)
  }

  toHTML(input): any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.innerText;
  }
}
