import * as tslib_1 from "tslib";
import { ChangeBooking } from './../../core/models/booking-changes-model';
import { UnaccompaniedMinorNotificationComponent } from './../unaccompanied-minor-notification/unaccompanied-minor-notification.component';
import { Constants } from '../../constants';
import { OnInit, QueryList, OnDestroy, ElementRef } from '@angular/core';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { BookingService } from '../../core/booking.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { ResourceService } from '../../common-modules/blue-air-common/resource.service';
import { DateRange } from '../../common-modules/blue-air-common/date-range.model';
import * as moment from 'moment';
import { BookingSelectionService } from '../../core/booking-selection.service';
import { SsrsService } from '../../extras/ssrs.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { SsrType } from '../../extras/ssr-type.enum';
import { NgModel, FormControl } from '@angular/forms';
import { BookingFlowService } from '../../core/booking-flow.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { SeatSelectedNotification } from '../../shared/seat-selected-notification/seat-selected-notification.component';
import { SelectionsClearWarningModalComponent } from '../../shared/selections-clear-warning-modal/selections-clear-warning-modal.component';
// tslint:disable-next-line: max-line-length
import { SsrsSeatsRemovalNotificationComponent } from '../../shared/ssrs-seats-removal-notification/ssrs-seats-removal-notification.component';
import { SeatMapService } from '../../seat/seat-map.service';
import { PaxContactDetailsComponent } from '../pax-contact-details/pax-contact-details.component';
import { PassengerModel } from '../../core/models/passenger-model';
import { ProfileService } from 'src/app/core/profile.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
var PassengersComponent = /** @class */ (function () {
    function PassengersComponent(bookingSteps, bookingService, flowManager, ecommerce, bookingFlowService, translateService, resourcesService, bookingSelectionService, ssrsService, loadingSpinnerService, seatmapService, profileService) {
        var _this = this;
        this.bookingSteps = bookingSteps;
        this.bookingService = bookingService;
        this.flowManager = flowManager;
        this.ecommerce = ecommerce;
        this.bookingFlowService = bookingFlowService;
        this.translateService = translateService;
        this.resourcesService = resourcesService;
        this.bookingSelectionService = bookingSelectionService;
        this.ssrsService = ssrsService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.seatmapService = seatmapService;
        this.profileService = profileService;
        this.specialServicesPassengerComponents = new QueryList();
        this.currentStep = CheckinSteps.passengers;
        this.specialAssistanceSpinnerId = 'specialAssistanceSpinnerId';
        this.inputWrapperClass = '.pax-container_2-col';
        this.phonePattern = '^[0-9+()\/-][0-9+() \/-]{0,19}?$';
        this.namePattern = '^[A-Za-z _]*[A-Za-z][A-Za-z _]*$';
        this.addressPattern = '(?!^ +$)^.+$';
        this.emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
        this.ariaLabel = 'passengers detail';
        this.passengers = [{ name: {}, info: {}, typeInfo: { paxType: Constants.AdultPaxType }, isReadonly: false, address: { phoneNumber: null, emailAddress: null } }];
        this.infants = [{ name: {}, info: {}, typeInfo: { paxType: Constants.InfantSsrCode }, isReadonly: false, address: { phoneNumber: null, emailAddress: null } }];
        this.flights = [];
        this.submitted = false;
        this.SsrTypeEnum = SsrType;
        this.genderItems = [];
        this.maleTitles = [];
        this.femaleTitles = [];
        this.duplicates = [];
        this.adultTitleItems = [];
        this.countries = [];
        this.contact = { name: {} };
        this.documentTypes = [];
        this.titleItems = [];
        this.check = false;
        this._canDeactivate = false;
        this.isPostCodeRequired = false;
        this.invalidForm = false;
        this.pendingSsrsList = [];
        this.scrollClass = 'validation,pattern';
        this.postCodeKey = 'postcod-contact';
        this.dateFormat = 'YYYY-MM-DD';
        this.dateRanges = {
            'ADT': [],
            'YTH': [],
            'CLD': [],
            'CHD': [],
            'INF': [],
            'ADTA': [],
            'YTHA': [],
            'CLDA': [],
            'CHDA': []
        };
        this.recordLocators = [];
        this.journeys = [];
        this.isAgent = this.profileService.isAgent;
        this.bookingSelectionService.flightsObs.subscribe(function (result) {
            _this.flights = result;
        });
        this.bookingSteps.currentStep.next(this.currentStep);
    }
    // todo : refactor passengers component using passengers and infants from bookingSelectionService
    PassengersComponent.prototype.setPassengersNameByType = function () {
        var _this = this;
        this.passengersSubscription = this.bookingSelectionService.passengersObs.subscribe(function (passengersByType) {
            if (passengersByType.length > 0) {
                _this.passengers.forEach(function (p) {
                    var name = passengersByType.filter(function (pax) { return pax.passengerNumber === p.passengerNumber; })
                        .map(function (p) { return p.name.first + ' ' + p.name.last; });
                    if (!PassengerModel.checkNameFormat(name[0])) {
                        p.displayName = name;
                    }
                    else {
                        p.displayName = passengersByType.filter(function (pax) { return pax.passengerNumber === p.passengerNumber; })
                            .map(function (p) { return p.defaultName; });
                    }
                    if (PassengerModel.checkNameFormat(p.name.first)) {
                        p.name.first = '';
                    }
                    if (PassengerModel.checkNameFormat(p.name.last)) {
                        p.name.last = '';
                    }
                    p.travelDocument = p.travelDocument ? p.travelDocument : (p.travelDocuments.items[0] || {});
                    p.address = p.address ? p.address : (p.addresses.items[0] ? p.addresses.items[0] : { phoneNumber: null, emailAddress: null });
                    var paxGender = passengersByType.filter(function (pax) { return pax.passengerNumber === p.passengerNumber; }).map(function (p) { return p.documents.gender; }).toString();
                    p.info.gender = p.info.gender = _this.getGender(paxGender);
                    if (p.fees) {
                        var UMfee = p.fees.items.find(function (f) { return f.ssrCode === Constants.SsrCodeUM || f.ssrCode === Constants.SsrCodeUMFR ||
                            f.ssrCode === Constants.SsrCodeUMNR || f.ssrCode === Constants.SsrCodeUMRO; });
                        if (UMfee) {
                            _this.setUmSsr(p, UMfee.ssrCode);
                        }
                    }
                    if (p.typeInfo.paxType && p.typeInfo.paxType.substr(0, 3) !== Constants.AdultPaxType) {
                        p.name.title = Constants.ChildrenPaxType;
                    }
                    if (p.hasInfant) {
                        var currentInfant = _this.infants[p.passengerNumber];
                        currentInfant.attachedPassengerNumber = p.passengerNumber;
                        currentInfant.displayName = !currentInfant.name.first && !currentInfant.name.last ?
                            _this.translateService.instant('INF-title', 'price-display') + ' ' + (p.passengerNumber + 1) :
                            currentInfant.name.first + ' ' + currentInfant.name.last;
                        if (PassengerModel.checkNameFormat(currentInfant.displayName)) {
                            currentInfant.displayName = _this.translateService.instant('INF-title', 'price-display') + ' ' +
                                (p.passengerNumber + 1);
                        }
                        if (PassengerModel.checkNameFormat(currentInfant.name.first)) {
                            currentInfant.name.first = '';
                        }
                        if (PassengerModel.checkNameFormat(currentInfant.name.last)) {
                            currentInfant.name.last = '';
                        }
                        currentInfant.name.title = Constants.ChildrenPaxType;
                    }
                });
            }
            // wait for fields initialisation before autocomple
            _this.autocompleteFirstPassenger();
        });
    };
    PassengersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
        this.bookingService.getMemberDetails();
        // this.bookingService.getMemberDetails();
        this.bookingFlowService.loadFlowInfo().then(function (booking) {
            var departureDate = moment(booking.cancelFlight.journeys.items[0].segments.items[0].legs.items[0].std);
            _this.contact = booking.contact || {};
            _this.contact.typeCode = 'P';
            _this.passengers = JSON.parse(JSON.stringify(booking.passengers.items));
            _this.isPromoSelected = booking.passengers.items.some(function (pax) { return pax.typeInfo.paxType.length > 3; });
            _this.fixPassengerTypes();
            _this.infants = booking.passengers.infants.items;
            _this.setCheckedInPax(booking.assignedSeats.journeys.items);
            _this.bookingSnapshot = booking.convertedJourneys.snapshotBooking;
            _this.journeys = booking.convertedJourneys.journeys;
            _this.isCanceledBookingStatus = _this.checkBookingStatus();
            _this.recordLocator = booking.bookingDetail.recordLocator;
            if (booking && booking.bookingDetail && booking.bookingDetail.recordLocators && booking.bookingDetail.recordLocators.length > 0) {
                _this.recordLocators = booking.bookingDetail.recordLocators;
                _this.isRestricted = _this.isRestrictedBooking();
            }
            _this.setPassengersNameByType();
            //todo:change to generic
            _this.resourcesService.getPassengerTypes().subscribe(function (pTypes) {
                var paxTypesAges = pTypes.map(function (p) { return ({ 'typeCode': p.typeCode, 'minAge': p.maximumAge, 'maxAge': p.maximumAge }); });
                paxTypesAges.forEach(function (p) {
                    _this.dateRanges[p.typeCode] = _this.generateDateRangeForPaxType(p.typeCode.substr(0, 3), departureDate);
                });
                if (_this.dateRanges["INFANT"])
                    _this.dateRanges["INF"] = _this.dateRanges["INFANT"];
            });
            _this.setDocumentDates(departureDate);
            setTimeout(function () {
                _this.scrollToPassengersSubscription = _this.bookingFlowService.scrollToPassengersObs.subscribe(function (result) {
                    if (result === ChangeBooking.passengers) {
                        _this.passengersBox.nativeElement.scrollIntoView({ behavior: 'smooth' });
                    }
                    else if (result === ChangeBooking.contact) {
                        _this.contactBox.nativeElement.scrollIntoView({ behavior: 'smooth' });
                    }
                });
                _this.bookingFlowService.scrollToPassengersObs.next(null);
            }, 20);
            _this.setResourceItems();
            _this.bookingFlowService.loadPriceBreakdown().then(function () {
                _this.ssrsService.getSsrsInfo().toPromise().then(function (data) {
                    if (data) {
                        _this.isSSRInfoSet = true;
                        _this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
                    }
                });
            });
        });
    };
    PassengersComponent.prototype.showUMnotification = function (passenger) {
        if (passenger.typeInfo.paxType !== Constants.YoungAdultPaxType && // 16-18 years
            passenger.typeInfo.paxType !== Constants.TeenPaxType && // 12-16 years
            passenger.typeInfo.paxType !== Constants.ChildrenPaxType) { // 2-12 years
            return;
        }
        var UMfee = passenger.fees.items.find(function (f) { return f.ssrCode === Constants.SsrCodeUM || f.ssrCode === Constants.SsrCodeUMFR ||
            f.ssrCode === Constants.SsrCodeUMNR || f.ssrCode === Constants.SsrCodeUMRO; });
        if (UMfee || passenger.info.umSsrResource) {
            return;
        }
        if (this.passengers.find(function (pax) { return pax.typeInfo.paxType === Constants.AdultPaxType; })) {
            return;
        }
        if (!passenger.info.nationality || !passenger.typeInfo.dateOfBirth) {
            return;
        }
        this.openUMpopup(passenger);
    };
    PassengersComponent.prototype.openUMpopup = function (passenger) {
        var _this = this;
        this.UMmodal.openPopup(function (okSelected) {
            if (okSelected) {
                // sell selected UM
                _this.UMmodal.ok().then(function (data) {
                    if (data) {
                        _this.setUmSsr(passenger, _this.UMmodal.selectedSsrCode);
                    }
                });
            }
            else {
                // remove passenger's nationality
                passenger.info.nationality = null;
            }
        }, passenger, this.flights);
    };
    PassengersComponent.prototype.setUmSsr = function (passenger, ssrCode) {
        var selectedUmSsr = this.UMmodal.getAvailableUMssrs(passenger, this.flights)
            .find(function (ssr) { return ssr.ssrCode === ssrCode; });
        if (selectedUmSsr) {
            passenger.info.umSsrResource = selectedUmSsr.ssrResource;
        }
    };
    PassengersComponent.prototype.removeUmSsr = function (passenger) {
        var _this = this;
        var UMlist = passenger.fees.items.filter(function (f) { return f.ssrCode === Constants.SsrCodeUM || f.ssrCode === Constants.SsrCodeUMFR ||
            f.ssrCode === Constants.SsrCodeUMNR || f.ssrCode === Constants.SsrCodeUMRO; });
        var removeUM = function (UMssrs) {
            UMssrs.forEach(function (UMssr) {
                var flight = _this.flights.find(function (x) { return x.segments.map(function (x) { return x.referenceKey; }).reduce(function (a, b) { return a.concat(b); }, []).includes(UMssr.flightReference); });
                _this.ssrsService.removeSsr(SsrType.SpecialAssistance, UMssr.ssrCode, UMssr.ssrNumber, passenger.passengerNumber, flight, true);
            });
            _this.ssrsService.applySsrs(SsrType.SpecialAssistance).then(function (data) {
                if (data) {
                    passenger.info.umSsrResource = '';
                    _this.openUMpopup(passenger);
                }
            });
        };
        if (UMlist.length > 0) {
            removeUM(UMlist);
        }
        else {
            this.bookingService.refresh().then(function (data) {
                if (data) {
                    var currentPax = data.passengers.items.find(function (p) { return p.passengerNumber === passenger.passengerNumber; });
                    UMlist = currentPax.fees.items.filter(function (f) { return f.ssrCode === Constants.SsrCodeUM || f.ssrCode === Constants.SsrCodeUMFR ||
                        f.ssrCode === Constants.SsrCodeUMNR || f.ssrCode === Constants.SsrCodeUMRO; });
                    removeUM(UMlist);
                }
            });
        }
    };
    PassengersComponent.prototype.generateDateRangeForPaxType = function (paxType, departureDate) {
        var minAge;
        var maxAge;
        var dateRange = new DateRange(departureDate.clone());
        // to do -> refactor this and this.getPassengerTypes()
        this.resourcesService.getPassengerTypes().subscribe(function (paxTypes) {
            var currentPaxType = paxTypes.find(function (pt) { return pt.typeCode === paxType; });
            if (currentPaxType || paxType === Constants.InfantSsrCode) {
                switch (paxType) {
                    case Constants.AdultPaxType:
                        minAge = 12;
                        maxAge = 100;
                        break;
                    case Constants.ChildrenPaxType:
                        minAge = 2;
                        maxAge = 12;
                        break;
                    case Constants.InfantSsrCode:
                        minAge = 0;
                        maxAge = 2;
                        break;
                    case Constants.TeenPaxType:
                    case Constants.YoungAdultPaxType:
                    default:
                        minAge = currentPaxType.minimumAge;
                        maxAge = currentPaxType.maximumAge;
                        break;
                }
                dateRange.generateYearsForAge(minAge + 1, maxAge + 1);
                dateRange.minimumDate = departureDate.clone().add(-maxAge, 'y');
                dateRange.maximumDate = departureDate.clone().add(-minAge, 'y');
                return dateRange;
            }
        });
        return dateRange;
    };
    PassengersComponent.prototype.ngOnDestroy = function () {
        if (this.scrollToPassengersSubscription) {
            this.scrollToPassengersSubscription.unsubscribe();
        }
        if (this.passengersSubscription) {
            this.passengersSubscription.unsubscribe();
        }
        if (this.memberSubscription) {
            this.memberSubscription.unsubscribe();
        }
    };
    PassengersComponent.prototype.setResourceItems = function () {
        var _this = this;
        this.genderItems = [
            {
                'text': this.translateService.instant('Male', 'documents'),
                'value': 1
            },
            {
                'text': this.translateService.instant('Female', 'documents'),
                'value': 2
            }
        ];
        this.resourcesService.getCountries().subscribe(function (countries) { return _this.countries = countries; });
        this.resourcesService.getDocumentTypes().subscribe(function (docTypes) { return _this.documentTypes = docTypes; });
        this.resourcesService.getPassengerTitles().subscribe(function (titles) {
            _this.titleItems = tslib_1.__spread([
                {
                    'text': _this.translateService.instant('Please select'),
                    'value': ''
                }
            ], titles);
            _this.maleTitles = _this.titleItems.filter(function (x) { return x.value === 'MR' || x.value === 'MSTR'; });
            _this.femaleTitles = _this.titleItems.filter(function (x) { return x.value === 'MS' || x.value === 'MRS' || x.value === 'MISS'; });
            _this.adultTitleItems = _this.titleItems.filter(function (x) { return x.value !== 'CHD'; });
        });
    };
    PassengersComponent.prototype.setDocumentDates = function (departureDate) {
        this.expirationDateRange = new DateRange(departureDate.clone());
        this.expirationDateRange.generateExpiration(100);
        this.expirationDateRange.minimumDate = departureDate.clone();
        this.expirationDateRange.maximumDate = departureDate.clone().add(100, 'y');
        this.issueDateRange = new DateRange(departureDate.clone());
        this.issueDateRange.minimumDate = departureDate.clone().add(-100, 'y');
        this.issueDateRange.maximumDate = departureDate.clone();
        this.issueDateRange.generateIssue(100);
    };
    PassengersComponent.prototype.getSpinnerId = function (passengerNumber) {
        return this.specialAssistanceSpinnerId + passengerNumber;
    };
    PassengersComponent.prototype.scrollTo = function (elements) {
        var items = elements.split(',');
        var firstElementList = document.querySelectorAll('.' + items[0]);
        var secondElementList = document.querySelectorAll('.' + items[1]);
        var item;
        if (firstElementList.length > 0)
            item = firstElementList[0];
        else if (secondElementList.length > 0)
            item = secondElementList[0];
        if (secondElementList.length > 0 && firstElementList.length > 0) {
            if (firstElementList[0].offsetTop > secondElementList[0].offsetTop)
                item = secondElementList[0];
        }
        if (item)
            item.closest(this.inputWrapperClass).scrollIntoView({ behavior: 'smooth' });
    };
    PassengersComponent.prototype.applySelection = function () {
        var _this = this;
        // why doesn't contact form get added to passengerDocsForm as viewchild?
        this.allForms = Array.from(this.passengerDocsForm.toArray());
        this.allForms.push(this.paxContactDetailsComponent.form);
        this.submitted = true;
        var isValid = true;
        if (!this.isRestricted && !this.isCanceledBookingStatus) {
            this.allForms.forEach(function (f) {
                isValid = isValid && f.valid;
                for (var key in f.form.controls) {
                    if (f.form.controls.hasOwnProperty(key)) {
                        var element = f.form.controls[key];
                        if (element instanceof FormControl) {
                            element.markAsDirty({ onlySelf: true });
                            if (key === _this.postCodeKey) {
                                _this.isPostCodeRequired = _this.contact.countryCode === 'GB' && !_this.contact.postalCode ? true : false;
                                if (_this.isPostCodeRequired) {
                                    isValid = false;
                                }
                            }
                        }
                    }
                }
            });
        }
        this.invalidForm = !isValid;
        if (isValid) {
            var confirmPassengerDetails = function (isOkCallback) {
                if (isOkCallback) {
                    if (!_this.contact.agreeTermsAndCond) {
                        _this.subscribeTermsModal.openPopup(function (dismissed) {
                            _this.contact.agreeTermsAndCond = dismissed === false;
                            if (_this.contact.agreeTermsAndCond) {
                                _this.saveAndContinue();
                            }
                            //this.saveAndContinue();
                        });
                    }
                    else {
                        _this.saveAndContinue();
                    }
                }
            };
            this.duplicates = new Array();
            this.passengers.forEach(function (p, i) {
                var duplicate = _this.passengers.find(function (pax, index) { return pax.name.title === p.name.title &&
                    pax.name.first === p.name.first && pax.name.last === p.name.last && i !== index; });
                if (duplicate && !_this.duplicates.some(function (x) { return x.name.first === duplicate.name.first && x.name.last === duplicate.name.last
                    && x.name.title === duplicate.name.title; })) {
                    _this.duplicates.push(duplicate);
                }
            });
            if (this.duplicates.length > 0) {
                this.nameModal.openPopup(function (isOk) {
                    _this.scrollTo('pax-container-' + _this.duplicates[0].passengerNumber);
                });
            }
            else {
                confirmPassengerDetails(true);
            }
        }
        setTimeout(function () {
            _this.scrollTo(_this.scrollClass);
        }, 20);
    };
    PassengersComponent.prototype.toggle = function (pax, i) {
        if (i == 0) {
            pax.specialAssistance = pax.specialAssistance ? false : true;
        }
        else {
            pax.addDocuments = pax.addDocuments ? false : true;
        }
    };
    PassengersComponent.prototype.saveChanges = function (paxIndex) {
        var _this = this;
        var pax = this.passengers[paxIndex];
        var parentSpinnerId = 'specialAssistanceSpinnerId' + pax.passengerNumber;
        this.ssrsService.resetSsrs(SsrType.SpecialAssistance);
        var components = this.specialServicesPassengerComponents.filter(function (x) { return x.selectedPassenger === pax.passengerNumber; });
        var newSsrs = [];
        components.forEach(function (component) {
            newSsrs.push.apply(newSsrs, tslib_1.__spread(component.applySsrs()));
        });
        var seatsToBeRemoved = this.ssrsService.getSeatsToBeRemoved(SsrType.SpecialAssistance, this.flights, newSsrs, pax.passengerNumber);
        if (!seatsToBeRemoved.length) {
            this.completeSaveChanges(pax, parentSpinnerId);
        }
        else {
            this.seatRemovalModal.openPopup(function (userAgreed) {
                if (userAgreed) {
                    _this.loadingSpinnerService.showSpinnerById(parentSpinnerId);
                    _this.seatmapService.removeSeats(seatsToBeRemoved)
                        .then(function () { return _this.completeSaveChanges(pax, parentSpinnerId); });
                }
            }, seatsToBeRemoved);
        }
    };
    PassengersComponent.prototype.completeSaveChanges = function (pax, parentSpinnerId) {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(parentSpinnerId);
        this.ssrsService.applySsrs(SsrType.SpecialAssistance).then(function () {
            _this.loadingSpinnerService.hideSpinnerById(parentSpinnerId);
        });
    };
    PassengersComponent.prototype.canDeactivate = function (currentRoute, currentState, nextState) {
        var nextStep = this.bookingSteps.extractStep(nextState);
        var redirectingToOtherBookingStep = true;
        if (nextStep === null) {
            redirectingToOtherBookingStep = false;
            nextStep = CheckinSteps.search;
        }
        if (this.clearSelectionsModal.shouldShowWarning(this.currentStep, nextStep)) {
            return this.clearSelectionsModal.showWarningAndNavigate(this.currentStep, redirectingToOtherBookingStep ? nextStep : null, nextState.url);
        }
        if (!this._canDeactivate) {
            this.goToNextStep(nextStep);
        }
        return this._canDeactivate;
    };
    PassengersComponent.prototype.setCheckedInPax = function (journeys) {
        var _this = this;
        var checkedInPax = journeys.map(function (x) {
            return x.segments.items
                .reduce(function (a, b) { return a.concat(b); }, [])
                .map(function (y) { return y.passengerSegments.items; })
                .reduce(function (a, b) { return a.concat(b); }, [])
                .filter(function (z) { return z.liftStatus !== Constants.NotCheckedIn; })
                .reduce(function (a, b) { return a.concat(b); }, [])
                .map(function (t) { return t.passengerNumber; });
        });
        this.passengers.forEach(function (pax) {
            pax.readonlyDetails = checkedInPax.some(function (x) { return x.includes(pax.passengerNumber); });
            if (_this.infants[pax.passengerNumber]) {
                _this.infants[pax.passengerNumber].readonlyDetails = pax.readonlyDetails && pax.hasInfant && pax.infant;
            }
            pax.readonlyDocuments = pax.readonlyDetails;
        });
    };
    PassengersComponent.prototype.hasPendingChanges = function () {
        var ssrsOk = true;
        this.pendingSsrsList = [];
        if (this.checkPendingSpecialServices()) {
            this.pendingSsrsList.push(SsrType.SpecialAssistance);
        }
        if (this.pendingSsrsList.length) {
            ssrsOk = false;
        }
        return ssrsOk;
    };
    PassengersComponent.prototype.goToNextStep = function (nextStep) {
        var _this = this;
        this._canDeactivate = this.hasPendingChanges();
        if (!this._canDeactivate) {
            this.pendingChangesModal.openPopup(function (isOk) {
                _this._canDeactivate = isOk;
                if (isOk) {
                    _this.checkoutEcommerce();
                    _this.bookingSteps.goToStep(nextStep);
                }
            });
        }
        else {
            this.checkoutEcommerce();
            this.bookingSteps.goToStep(nextStep);
        }
    };
    PassengersComponent.prototype.checkoutEcommerce = function () {
        var _this = this;
        var ecommerceCartItems = [];
        this.flowManager.applicationFlowService.loadPriceBreakdown().then(function (breakdown) {
            if (breakdown) {
                ecommerceCartItems = _this.ecommerce.getAllCartDataForECommerce(breakdown);
                _this.ecommerce.Checkout(ecommerceCartItems, 4, 'Passengers step');
            }
        });
    };
    PassengersComponent.prototype.getText = function (array, value) {
        if (!array.length) {
            return '';
        }
        var foundItem = array.find(function (x) { return x.value === value; });
        return foundItem ? foundItem.text : '';
    };
    PassengersComponent.prototype.titleChanged = function ($value, pax) {
        if ($value && pax.typeInfo.paxType && pax.typeInfo.paxType.substr(0, 3) === Constants.AdultPaxType) {
            if (this.maleTitles.some(function (x) { return x.value === $value; }) && pax.info.gender !== this.genderItems[0].value) {
                pax.info.gender = this.genderItems[0].value;
            }
            else if (this.femaleTitles.some(function (x) { return x.value === $value; }) && pax.info.gender !== this.genderItems[1].value) {
                pax.info.gender = this.genderItems[1].value;
            }
        }
        if (pax.passengerNumber === 0) {
            this.firstPaxChanged();
        }
    };
    PassengersComponent.prototype.firstPaxChanged = function () {
        if (this.check) {
            this.contact.name = Object.assign({}, this.passengers[0].name || {});
        }
    };
    PassengersComponent.prototype.genderChanged = function ($value, pax) {
        if ($value && pax.typeInfo.paxType === Constants.AdultPaxType) {
            if (this.maleTitles.every(function (x) { return x.value !== pax.name.title; }) && $value === this.genderItems[0].value) {
                pax.name.title = this.maleTitles[0].value;
            }
            else if (this.femaleTitles.every(function (x) { return x.value !== pax.name.title; }) && $value === this.genderItems[1].value) {
                pax.name.title = this.femaleTitles[0].value;
            }
        }
    };
    PassengersComponent.prototype.checkPendingSpecialServices = function () {
        this.ssrsService.resetSsrs(SsrType.SpecialAssistance);
        this.specialServicesPassengerComponents.forEach(function (component) {
            component.applySsrs();
        });
        return this.ssrsService.getPendingSsrs().filter(function (ssr) { return ssr === SsrType.SpecialAssistance; }).length > 0;
    };
    PassengersComponent.prototype.saveAndContinue = function () {
        var _this = this;
        this.passengers.forEach(function (item) {
            item.name.first = item.name.first.toUpperCase().trim();
            item.name.last = item.name.last.toUpperCase().trim();
        });
        Promise.all([
            this.bookingService.savePassengers(this.passengers, this.infants),
            this.bookingService.saveContact(this.contact)
        ])
            .then(function () { return Promise.all([
            _this.contact.subscribedToNewsletter && _this.contact.agreeTermsAndCond && _this.bookingService.subscribeToNewsletter(_this.contact.isFirstPassenger),
            _this.bookingFlowService.loadFlowInfo(true),
            _this.bookingFlowService.loadPriceBreakdown(true)
        ]); })
            .then(function () { return _this.goToNextStep(_this.bookingSteps.getNextStep(_this.currentStep)); });
    };
    PassengersComponent.prototype.autocompleteFirstPassenger = function () {
        var _this = this;
        this.memberSubscription = this.bookingService.memberPromotionObs.subscribe(function (data) {
            if (data && data.memberPromotion.isActive && _this.isPromoSelected) {
                var firstPassenger = _this.passengers[0];
                firstPassenger.readonlyDetails = true;
                firstPassenger.readonlyDocuments = false;
                firstPassenger.name.title = data.memberProfile.member.name.title;
                firstPassenger.name.first = data.memberProfile.member.name.first;
                firstPassenger.name.last = data.memberProfile.member.name.last;
                firstPassenger.info.gender = _this.getGender(data.memberProfile.member.gender);
                firstPassenger.info.nationality = data.memberProfile.member.nationality ?
                    data.memberProfile.member.nationality :
                    data.memberProfile.member.residentCountry;
                firstPassenger.typeInfo.dateOfBirth = data.memberProfile.member.dateOfBirth;
            }
        });
    };
    PassengersComponent.prototype.getGender = function (gender) {
        return gender === 'Male' ? 1 : gender === 'Female' ? 2 : 0;
    };
    PassengersComponent.prototype.fixPassengerTypes = function () {
        if (this.passengers && this.passengers.length) {
            this.passengers.forEach(function (p) { return p.typeInfo.paxType = p.typeInfo.paxType.substring(0, 3); });
        }
    };
    PassengersComponent.prototype.isRestrictedBooking = function () {
        return (this.recordLocators.some(function (r) { return r.owningSystemCode !== null && r.owningSystemCode !== undefined; })) ? true : false;
    };
    PassengersComponent.prototype.checkBookingStatus = function () {
        var e_1, _a, e_2, _b, e_3, _c;
        try {
            for (var _d = tslib_1.__values(this.journeys), _e = _d.next(); !_e.done; _e = _d.next()) {
                var j = _e.value;
                try {
                    for (var _f = tslib_1.__values(j.segments), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var s = _g.value;
                        try {
                            for (var _h = tslib_1.__values(s.legs), _j = _h.next(); !_j.done; _j = _h.next()) {
                                var l = _j.value;
                                if (l.legInfo.status === "Canceled") {
                                    return true;
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    return PassengersComponent;
}());
export { PassengersComponent };
