import { SsrsService } from './../extras/ssrs.service';
import { BookingFlowService } from './booking-flow.service';
import { Insurance } from './models/insurance-model';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';
import { Injectable } from '@angular/core';
import { SsrType } from '../extras/ssr-type.enum';
import { BookingChanges, InsuranceItinerary } from './models/booking-changes-model';
import { Constants } from '../constants';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BookingChangesService {

  public passengerChanges: any;
  public ssrChanges: BookingChanges[] = [];
  public seatChanges: BookingChanges[] = [];
  public insuranceChanges: InsuranceItinerary[] = [];
  public flightChanges: any;
  public ssrCodes: any;
  public changesObs: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient,
    private configService: ConfigService) {
  }

  ngOnDestroy(): void {
  }

  getChanges() {
    return this.http.get(this.configService.SsrChanges).toPromise();
  }

  isSectionVisible(ssrType: SsrType, journeys, passengers) {
    const allSegments = journeys.map(m => m.segments).reduce((a, b) => a.concat(b), []);
    const checkSsr = (list) => {
      return allSegments.map(p => p.paxSSRs).reduce((a, b) => a.concat(b), []).filter(p => list.includes(p.sSRCode)).length > 0;
    };

    switch (ssrType) {
      case SsrType.Seat:
        return allSegments.map(p => p.paxSeats).reduce((a, b) => a.concat(b), []).length > 0;
      case SsrType.Insurance:
        return passengers.map(x => x.fees).map(x => x.items).reduce((a, b) => a.concat(b), []).filter(x => x.code === Constants.InsuranceFeeCode).length > 0;
      default:
        const ssrList = this.getSsrTypeList(ssrType);
        return ssrList.length > 0 ? checkSsr(ssrList) : false;
    }
  }

  getSsrChanges(journeys, passengers, bookingSnapshot?, fromItinerary?) {
    const areDifferentStations = this.areDifferentStations(bookingSnapshot, journeys);
    this.getChanges().then((data: any) => {
      if (data) {

        this.ssrChanges = [];
        this.seatChanges = [];
        this.insuranceChanges = [];

        this.flightChanges = data.bookingChanges.reviewYourCancelRebookChangesViewModel;
        this.ssrCodes = data.bookingChanges.ssrCodesByType;
        this.passengerChanges = data.bookingChanges.passengerChanges;

        for (let ssrType = 1; ssrType <= 15; ssrType++) {
          if (this.isSectionVisible(ssrType, journeys, passengers)) {

            const bookingChange = new BookingChanges(SsrType[ssrType]);

            switch (ssrType) {
              case SsrType.Seat:
                // to do : change copy method for array
                const seatChangesCopy = JSON.parse(JSON.stringify(JSON.parse(data.bookingChanges.seatChanges)));
                seatChangesCopy.forEach((seatChange, passengerNumber) => {
                  const passenger = passengers.find(p => p.passengerNumber === passengerNumber);
                  bookingChange.setSsrListForPassenger(passenger, seatChange);
                });
                this.seatChanges.push(bookingChange);
                break;
              default:
                // to do : change copy method for array
                const ssrChangesCopy = JSON.parse(JSON.stringify(JSON.parse(data.bookingChanges.ssrChanges)));
                ssrChangesCopy.forEach((ssrChangeSegments, passengerNumber) => {
                  const ssrTypeList = this.getSsrTypeList(ssrType);
                  if (ssrTypeList.length > 0) {
                    if (areDifferentStations || fromItinerary) {
                      ssrChangeSegments.forEach((ssrList, index) => {
                        const currentSsr = ssrList.filter(s => ssrTypeList.includes(s.code));
                        ssrChangeSegments[index] = currentSsr.length > 0 ? currentSsr : {
                          code: "",
                          newValue: "",
                          oldValue: "",
                          segment: ssrList[0].segment
                        }
                      });
                    } else {
                      ssrChangeSegments.forEach((ssrList, index) => {
                        const currentSsr = ssrList.filter(s => ssrTypeList.includes(s.code) && s.newValue !== s.oldValue);
                        ssrChangeSegments[index] = currentSsr.length > 0 ? currentSsr : {
                          code: "",
                          newValue: "",
                          oldValue: "",
                          segment: ssrList[0].segment
                        }
                      });
                    }
                    const passenger = passengers.find(p => p.passengerNumber === passengerNumber);
                    if (ssrChangeSegments.length !== ssrChangeSegments.filter(x => x && x.code === '').length) {
                      bookingChange.setSsrListForPassenger(passenger, ssrChangeSegments);
                    }
                  }
                });
                if (bookingChange && bookingChange.passengerSsrsByType.length > 0) {
                  this.ssrChanges.push(bookingChange);
                }
                break;
            }
          }
        }
        if (this.isSectionVisible(SsrType.Insurance, journeys, passengers)) {
          passengers.forEach(passenger => {
            const notes = passenger.fees.items.filter(fee => fee.code === Constants.InsuranceFeeCode).map(fee => fee.note);
            const snapshotNotes = bookingSnapshot ? bookingSnapshot.passengers[passenger.passengerNumber].passengerFees.filter(fee => fee.feeCode === Constants.InsuranceFeeCode).map(fee => fee.note) : [];
            notes.forEach(note => {
              const isInfantAsig = note.split('|')[0].trim() === Constants.InfantSsrCode;
              const days = note.split('|')[note.split('|').length - 1].trim();
              const isPaid = snapshotNotes.includes(note);
              this.insuranceChanges.push(new InsuranceItinerary(isInfantAsig ? passenger.infant : passenger, days + '-insurance', !isPaid));
            });
          });
        }
      }
      this.changesObs.next(true);
    });
  }

  public getSsrTypeList(ssrType: SsrType) {
    if (this.ssrCodes) {
      const codes = this.ssrCodes.find(x => x.key === ssrType);
      return codes ? codes.value : [];
    }
    return [];
  }

  private areDifferentStations(bookingSnapshot: any, journeys: any[]): boolean {
    let stationsDifference = false;
    if (bookingSnapshot && journeys.length > 0) {
      for (let i = 0; i < journeys.length; i++) {
        if (journeys[i].segments[0].departureStation !== bookingSnapshot.journeys[i].segments[0].departureStation ||
          journeys[i].segments[journeys[i].segments.length - 1].arrivalStation !==
          bookingSnapshot.journeys[i].segments[bookingSnapshot.journeys[i].segments.length - 1].arrivalStation) {
          stationsDifference = true;
        }
      }
    }
    return stationsDifference;
  }
}
