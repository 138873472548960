import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ConfigService } from 'src/app/core/config.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ProfileService } from 'src/app/core/profile.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';

@Component({
  selector: 'check-in-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.scss']
})
export class AuthenticateComponent implements OnInit {
  loginSpinnerId = 'login-spinner';
  loginRoutes: string[];
  errors: string[];

  assetsPath: string;

  username: string;
  password: string;
  submitted = false;

  constructor(
    private profileService: ProfileService,
    private config: ConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService) {
    this.assetsPath = environment.assetsPath;
    this.loginRoutes = [config.MemberLoginUrl, config.AgentLoginUrl, config.ResetPasswordUrl];
  }

  ngOnInit() {

    if (!localStorage.getItem("pageState")) {
      localStorage.setItem("pageState", "reloaded");
      this.translateService.culture = "it-IT";
      this.translateService.load(this.config.config.translationsUrl, this.config.config.collectTranslationsUrl,
        environment.collectTranslations);

      window.location.reload();
    }
  }

  getPageTitle(): string {
    return document.location.href.indexOf("corporate") != -1 ?
      `WELCOME TO AEROITALIA'S CORPORATE PORTAL` :
      `WELCOME TO AEROITALIA'S AGENCY PORTAL`
  }

  ngOnDestroy() {
    localStorage.removeItem("pageState");
  }

  login() {
    this.errors = [];
    this.submitted = true;

    if (!this.username || !this.password) {
      return;
    }

    this.profileService.loginAgent(this.username, this.password).subscribe(result => {
      if (result.loginOk) {
        this.route.queryParams.pipe(take(1)).subscribe(qp => {
          const returnUrl = qp['returnUrl'];
          this.router.navigate([returnUrl || '/']);
        });
      } else if (result.mustResetPassword) {
        this.router.navigate(['agent', 'force-reset-password']);
      } else {
        this.errors = result.errors;
      }
    });
  }

}
