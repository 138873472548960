<div class="step-1">
  <div class="checkin-box unchecked" aria-label="Flight Rome to Bucharest">

    <div class="checkin-flight-item" *ngFor="let segment of flight.segments; let segmentIndex = index">
      <div class="connection-detail" *ngIf="segmentIndex > 0">
        {{flight.segments[segmentIndex-1].arrivalDateUtc|duration:flight.segments[segmentIndex].departureDateUtc:false}}
        {{"connection" | translate}}
      </div>
      <div class="flight-title">{{segment.operatingCarrier}} {{segment.flightNumber}}</div>      
      <div class="flight-details">
        <div class="left">
          <span class="location">{{ segment.departureStation | translate:'station.name' }} {{ segment.departureStation }}</span>
          <div class="time-big">{{ segment.departureDate | customDate:'time' }}</div>
          <span class="flight-custom-text" *ngIf="arrivalDateDaysDifference > 0 ">{{ segment.departureDate | customDate:'daymonth' }}</span>
        </div>
        <div class="middle">
          <span class="font-ico-plane-runway"></span>
          <hr>
          <div class="flight-duration">
            {{ segment.departureDateUtc | duration: segment.arrivalDateUtc }}
          </div>
        </div>
        <div class="right">
          <span class="location">{{ segment.arrivalStation | translate:'station.name' }} {{ segment.arrivalStation }}</span>
          <div class="time-big">{{ segment.arrivalDate | customDate:'time' }}</div>
          <span class="flight-custom-text" *ngIf="arrivalDateDaysDifference > 0 ">{{ segment.arrivalDate | customDate:'daymonth' }}</span>
        </div>
        <div class="flight-custom-text" *ngIf="arrivalDateDaysDifference > 0 && (flight.segments.length == 1 || segmentIndex > 0)">{{"Arrives next day!" | translate}}</div>
      </div>
    </div>
  </div>
</div>