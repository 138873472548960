import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { filter, take } from 'rxjs/operators';
import { BookingService } from 'src/app/core/booking.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
var PtwFlightSelectionComponent = /** @class */ (function () {
    function PtwFlightSelectionComponent(bookingService, flowManager) {
        var _this = this;
        this.bookingService = bookingService;
        this.flowManager = flowManager;
        this.invalidSelection = false;
        this.displayedErrors = [];
        this.format = 'DD/MM/YYYY';
        this.showNonStandardAmount = false;
        this.journeys = [];
        this.continue = new EventEmitter();
        this.flowManager.selectionService.flightsObs.pipe(filter(function (p) { return p ? true : false; }), take(1)).subscribe(function (flights) {
            _this.isRoundTrip = flights.length > 1;
        });
        var booking = this.bookingService.getBooking();
        this.journeys = booking.convertedJourneys.journeys;
        this.isNonStandard = booking.passengers.items.some(function (p) { return p.fees &&
            (p.fees.items.some(function (f) { return f.code === 'NCH' ||
                f.code === 'SPL' ||
                f.code === 'DCH'; })); });
        if (!this.bookingService.isB2CBooking()) {
            this.isB2BBooking = true;
        }
    }
    PtwFlightSelectionComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                //this.items.sort((a, b) => a.departureDate.localeCompare(b.departureDate));
                this.checkRefundToWalletEligibility();
                this.setEnableRefund();
                return [2 /*return*/];
            });
        });
    };
    PtwFlightSelectionComponent.prototype.ngOnChanges = function (changes) {
        var e_1, _a;
        if (changes.errors && this.errors.length) {
            this.displayedErrors.length = 0;
            try {
                for (var _b = tslib_1.__values(this.errors), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var err = _c.value;
                    if (err === 'InvalidSelection') {
                        this.invalidSelection = true;
                    }
                    else {
                        this.displayedErrors.push(err);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
        }
    };
    PtwFlightSelectionComponent.prototype.submit = function () {
        var selectedJourneys = this.items.filter(function (x) { return x.isSelected; }).map(function (x) { return x.sellKey; });
        if (selectedJourneys.length) {
            this.invalidSelection = false;
            var selection = {
                selectedJourneys: selectedJourneys
            };
            this.continue.next(selection);
        }
        else {
            this.invalidSelection = true;
        }
    };
    PtwFlightSelectionComponent.prototype.changeSelection = function (item) {
        var _this = this;
        item.isSelected = !item.isSelected;
        this.invalidSelection = false;
        this.enableJourneyRefund();
        setTimeout(function () {
            _this.computeTotalSelected();
        }, 0);
    };
    PtwFlightSelectionComponent.prototype.computeTotalSelected = function () {
        var total = 0;
        if (this.isNonStandard && this.items.every(function (j) { return j.isSelected; })) {
            this.items.forEach(function (element) {
                if (element.isSelected) {
                    total += element.nonStandardAmount;
                    total += element.bonusNonStandardAmount > 0 ? element.bonusNonStandardAmount : 0;
                }
            });
            this.showNonStandardAmount = true;
        }
        else {
            this.items.forEach(function (element) {
                if (element.isSelected) {
                    total += element.amount;
                    total += element.bonusAmount > 0 ? element.bonusAmount : 0;
                }
            });
            this.showNonStandardAmount = false;
        }
        this.totalSelectedAmount = total;
    };
    PtwFlightSelectionComponent.prototype.checkHours = function (value) {
        var date = moment(value);
        var currentDate = moment(new Date());
        var tempHours = date.diff(currentDate, 'hours');
        return tempHours >= 4 ? true : false;
    };
    PtwFlightSelectionComponent.prototype.checkRefundToWalletEligibility = function () {
        var e_2, _a, e_3, _b, e_4, _c, e_5, _d;
        try {
            for (var _e = tslib_1.__values(this.journeys), _f = _e.next(); !_f.done; _f = _e.next()) {
                var j = _f.value;
                try {
                    for (var _g = tslib_1.__values(j.segments), _h = _g.next(); !_h.done; _h = _g.next()) {
                        var s = _h.value;
                        try {
                            for (var _j = tslib_1.__values(s.paxSegments), _k = _j.next(); !_k.done; _k = _j.next()) {
                                var p = _k.value;
                                if (p.liftStatus === 'CheckedIn' || p.liftStatus === 'Boarded') {
                                    try {
                                        for (var _l = tslib_1.__values(this.items), _m = _l.next(); !_m.done; _m = _l.next()) {
                                            var i = _m.value;
                                            if (i.sellKey.includes(s.sellKey)) {
                                                i.isNotEligibleForRefund = true;
                                            }
                                        }
                                    }
                                    catch (e_5_1) { e_5 = { error: e_5_1 }; }
                                    finally {
                                        try {
                                            if (_m && !_m.done && (_d = _l.return)) _d.call(_l);
                                        }
                                        finally { if (e_5) throw e_5.error; }
                                    }
                                }
                            }
                        }
                        catch (e_4_1) { e_4 = { error: e_4_1 }; }
                        finally {
                            try {
                                if (_k && !_k.done && (_c = _j.return)) _c.call(_j);
                            }
                            finally { if (e_4) throw e_4.error; }
                        }
                    }
                }
                catch (e_3_1) { e_3 = { error: e_3_1 }; }
                finally {
                    try {
                        if (_h && !_h.done && (_b = _g.return)) _b.call(_g);
                    }
                    finally { if (e_3) throw e_3.error; }
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_f && !_f.done && (_a = _e.return)) _a.call(_e);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    PtwFlightSelectionComponent.prototype.enableJourneyRefund = function () {
        if (this.items.some(function (i) { return i.isModified && i.isSelected; })) {
            this.items.forEach(function (i) {
                if (!i.isNotEligibleForRefund) {
                    if (!i.isModified) {
                        i.enableRefund = true;
                    }
                }
                else {
                    i.enableRefund = false;
                }
            });
        }
        else {
            this.items.forEach(function (i) {
                if (!i.isNotEligibleForRefund) {
                    if (!i.isModified) {
                        i.enableRefund = false;
                        i.isSelected = false;
                    }
                }
                else {
                    i.enableRefund = false;
                }
            });
        }
    };
    PtwFlightSelectionComponent.prototype.setEnableRefund = function () {
        this.items.forEach(function (i) {
            if (!i.isNotEligibleForRefund) {
                if (i.isModified) {
                    i.enableRefund = true;
                }
            }
            else {
                i.enableRefund = false;
            }
        });
    };
    return PtwFlightSelectionComponent;
}());
export { PtwFlightSelectionComponent };
