<profile-info-box class="two-cols-mob" title="{{ 'Profile settings' | translate:'profile-info' }}" [showEditBtn]="false">
  <div class="border-row-wrap">
    <div class="left">
      <span>{{ 'Username' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{profileSetings.username}}</span>
    </div>
    <div class="right">
      <span>{{ 'Organization code' | translate:'profile-info' }}</span>
      <span class="blue-txt">{{profileSetings.organizationCode}}</span>
    </div>
  </div>
  <div class="row-wrap">
    <div class="right">
      <span>{{ 'Password' | translate:'profile-info' }}</span>
      <!-- <span class="blue-txt">**********</span> -->
      <a class="btn btn-secondary font-ico-two-arrows change-password" (click)="openPasswordModal($event)">
        {{ 'Change Password' | translate:'profile-info' }}
      </a>
    </div>
  </div>

  <blue-modal2 [id]="passwordChangeModalId">
    <span class="popup-title">{{ 'Change password' | translate:'profile-info' }}</span>
    <blue-loading-spinner [activationRoute]="passwordChangeRoute"></blue-loading-spinner>
    <profile-reset-password-form [passwordChangeErrors]="passwordChangeErrors" 
      [passwordChangePolicy]="passwordChangePolicy" [passwordChangePolicyRegex]="passwordChangePolicyRegex"
      (savePassword)="savePassword($event)" #changePasswordForm>
        <profile-info-box-actions (cancel)="cancelPassword()"></profile-info-box-actions>
    </profile-reset-password-form>
  </blue-modal2>
</profile-info-box>