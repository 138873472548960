import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ProfileService } from 'src/app/core/profile.service';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { ProfileInfoBox } from '../profile-info-box';
import { InfoBoxActionsComponent } from '../info-box-actions/info-box-actions.component';

@Component({
  selector: 'profile-address-book-box',
  templateUrl: './address-book-box.component.html',
  styleUrls: ['./address-book-box.component.scss']
})
export class AddressBookBoxComponent extends ProfileInfoBox implements OnInit {
  editModalId = 'address-book';
  editSpinnerId = 'address-book';
  infoBoxSpinnerId = 'address-book-list';
  countries: any[] = [];
  types: any[] = [];

  items: any[] = [];
  model: any = { address: {} };
  personId: number = null;

  @ViewChild(InfoBoxActionsComponent, { static: true }) infoBoxActions: InfoBoxActionsComponent;

  constructor(modalService: BlueModalService, private profileService: ProfileService,
    private resourceService: ResourceService, private spinnerService: LoadingSpinnerService) {
    super(modalService);

    let infoToLoad = 3;
    const hideSpinner = () => {
      if (--infoToLoad <= 0) {
        this.spinnerService.hideSpinnerById(this.infoBoxSpinnerId);
      }
    };
    this.profileService.profile.subscribe((p: any) => {
      if (!p) {
        return;
      }
      if (p.person.addresses.items) {
        this.items = p.person.addresses.items.filter(i => i.personId);
      }

      this.personId = p.person.personId;
      hideSpinner();
    });
    this.resourceService.getCountries().subscribe(c => {
      this.countries = c;
      hideSpinner();
    });

    this.resourceService.getAddressTypes().subscribe(d => {
      this.types = d;
      hideSpinner();
    });
  }

  ngOnInit() {
  }
  
  edit() {}

  editItem(itemIndex) {
    this.infoBoxActions.showDeleteMessage = false;
    if (itemIndex > -1) {
      const item = this.items[itemIndex];
      this.model = {
        address: {
          addressLine1: item.address.addressLine1,
          addressLine2: item.address.addressLine2,
          addressLine3: item.address.addressLine3,
          city: item.address.city,
          countryCode: item.address.countryCode,
          postalCode: item.address.postalCode
        },
        personAddressId: item.personAddressId,
        typeCode: item.typeCode,
        default: item.default
      };
    } else {
      this.model = { address: {} };
    }
    this.model.index = itemIndex;
    this.model.personId = this.personId;

    this.openModal();
  }

  confirmDelete() {
    this.spinnerService.showSpinnerById(this.editSpinnerId);
    this.profileService.deleteAddressBookItem(this.model.index)
      .then(() => this.profileService.refreshProfile(true))
      .then(() => {
        this.closeModal();
        this.spinnerService.hideSpinnerById(this.editSpinnerId);
      });
  }

  save() {
    if (!this.editForm.valid) {
      return;
    }

    this.spinnerService.showSpinnerById(this.editSpinnerId);
    this.profileService.updateAddressBookItem(this.model, this.model.index)
      .then(() => this.profileService.refreshProfile(true))
      .then(() => {
        this.closeModal();
        this.spinnerService.hideSpinnerById(this.editSpinnerId);
      });
  }
}
