import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { BlueModalService } from './blue-modal.service';
import { BlueModal } from './blue-modal';
import { BlueRenderer } from '../services/blue-renderer.service';
var BlueModalComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BlueModalComponent, _super);
    function BlueModalComponent(modalService, el, render, blueRenderer) {
        var _this = _super.call(this) || this;
        _this.modalService = modalService;
        _this.el = el;
        _this.render = render;
        _this.blueRenderer = blueRenderer;
        _this.showCloseBtn = true;
        return _this;
    }
    BlueModalComponent.prototype.ngOnInit = function () {
        this.modalService.add(this);
    };
    BlueModalComponent.prototype.ngOnDestroy = function () {
        this.blueRenderer.updateBodyScroll(false);
        this.modalService.remove(this.id);
    };
    BlueModalComponent.prototype.open = function (closeCallback) {
        if (closeCallback === void 0) { closeCallback = null; }
        this.closeCallback = closeCallback;
        this.isOpened = true;
        this.render.removeAttribute(this.dialogRef.nativeElement, 'aria-hidden');
        this.render.setStyle(this.dialogRef.nativeElement, 'display', 'block');
        this.blueRenderer.updateBodyScroll(true);
    };
    BlueModalComponent.prototype.close = function () {
        this.isOpened = false;
        this.render.setAttribute(this.dialogRef.nativeElement, 'aria-hidden', 'true');
        this.render.removeStyle(this.dialogRef.nativeElement, 'display');
        this.blueRenderer.updateBodyScroll(false);
        if (this.closeCallback) {
            this.closeCallback();
        }
    };
    return BlueModalComponent;
}(BlueModal));
export { BlueModalComponent };
