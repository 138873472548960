import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
@Pipe({
  name: 'ssrTranslate'
})
export class SsrTranslatePipe implements PipeTransform {
  // private static ssrsList = ['CCKN', 'PCKN', 'LCKN'];
  // private static referenceDate = moment([2019, 11, 1]);
  constructor(private translateService: TranslateService, private sanitizer: DomSanitizer) { }

  transform(ssrCode: string, bundleCode: string, departureDate: any): any {
    // disabled until further notice
    // if (SsrTranslatePipe.ssrsList.includes(ssrCode) && departureDate) {
    //   if (moment(departureDate) > SsrTranslatePipe.referenceDate) {
    //     return `${bundleCode}.${ssrCode}2`;
    //   }
    // }
    // return this.sanitizer.sanitize(SecurityContext.STYLE, (this.translateService.instant(`${bundleCode}.${ssrCode}`, 'Booking')));
    if (!bundleCode) {
      return this.toHTML(this.translateService.instant(`${ssrCode}`, 'Booking'));
    }
    return this.toHTML(this.translateService.instant(`${bundleCode}.${ssrCode}`, 'Booking'));
  }


  toHTML(input): any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.innerText;
  }
}
