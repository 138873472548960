import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LowFareCalendarComponent } from './low-fare-calendar/low-fare-calendar.component';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';

import { ReactiveFormsModule } from '@angular/forms';
import { CalendarRoutes } from './low-fare-calendar-routes';
import { SharedModule } from '../shared/shared.module';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { LowFareCalendarViewComponent } from './low-fare-calendar-view/low-fare-calendar-view.component';



@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    BlueAirCommonModule,
    // RouterModule.forRoot(
    //   environment.flow == 0 ? [] : CalendarRoutes
    //  )
  ],
  declarations: [LowFareCalendarComponent, LowFareCalendarViewComponent]
})
export class LowFareCalendarModule { }
