import * as tslib_1 from "tslib";
import { OnInit, OnDestroy, QueryList } from '@angular/core';
import { CheckinSteps, BookingStepsService } from 'src/app/core/booking-steps.service';
import { Subject } from 'rxjs';
import { PushToWalletService } from '../push-to-wallet.service';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { takeUntil, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { EnvHelper } from 'src/app/env-helper';
import { UserActivityService } from 'src/app/core/user-activity.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { PtwConfirmationComponent } from '../ptw-confirmation/ptw-confirmation.component';
var PushToWalletContainerComponent = /** @class */ (function () {
    function PushToWalletContainerComponent(steps, pushToWalletService, loadingSpinnerService, activatedRoute, router, userStateService, userActivityService, flowManager) {
        this.pushToWalletService = pushToWalletService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.userStateService = userStateService;
        this.userActivityService = userActivityService;
        this.flowManager = flowManager;
        this.currentStep = CheckinSteps.pushToWallet;
        this.unsubscribed = new Subject();
        this.firstLoad = true;
        this.PushToWalletStepsEnum = PushToWalletStepsEnum;
        this.isLoading = true;
        this.ptwSpinnerId = 'ptw-spinner';
        this.errors = [];
        this.genericErrors = [];
        steps.currentStep.next(this.currentStep);
    }
    PushToWalletContainerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParamMap.pipe(takeUntil(this.unsubscribed)).subscribe(function (queryParamMap) {
            var queryStep = queryParamMap.get('step');
            if (!isNaN(Number(queryStep)) && +queryStep in PushToWalletStepsEnum) {
                _this.ptwStep = +queryStep;
                _this.continueOldFlow = true;
            }
            if (!_this.ptwStep) {
                _this.continueOldFlow = false;
                _this.ptwStep = PushToWalletStepsEnum.FlightSelect;
            }
            if (_this.continueOldFlow && _this.ptwStep > PushToWalletStepsEnum.Login && !_this.userStateService.isLoggedIn.getValue()) {
                _this.goToStep(PushToWalletStepsEnum.Login, true);
            }
            if (_this.firstLoad) {
                _this.firstLoad = false;
                _this.retrieveInfo();
            }
        });
        if (EnvHelper.IsOnAgencyPortal()) {
            this.isLoggedIn = true;
        }
        else {
            this.userStateService.isLoggedIn.pipe(take(1)).subscribe(function (value) { return _this.isLoggedIn = value; });
        }
    };
    PushToWalletContainerComponent.prototype.ngOnDestroy = function () {
        this.unsubscribed.next(true);
    };
    PushToWalletContainerComponent.prototype.selectJourneys = function (selection) {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(this.ptwSpinnerId);
        this.genericErrors.length = 0;
        this.pushToWalletService.selectJourneys(selection.selectedJourneys).pipe(takeUntil(this.unsubscribed)).subscribe(function (response) {
            _this.pushToWalletData = response;
            _this.goToNextStep();
            _this.loadingSpinnerService.hideSpinnerById(_this.ptwSpinnerId);
        }, function (errorInfo) { return _this.handleGenericError(errorInfo, 'selectJourneys'); });
    };
    PushToWalletContainerComponent.prototype.loginStatusChanged = function (isLoggedInNewStatus) {
        var _this = this;
        if (!this.isLoggedIn && isLoggedInNewStatus) {
            this.isLoggedIn = true;
            this.loadingSpinnerService.showSpinnerById(this.ptwSpinnerId);
            this.retrieveInfo(function () { return _this.goToNextStep(true); });
        }
        else {
            if (this.parseErrors()) {
                this.goToNextStep();
            }
        }
    };
    PushToWalletContainerComponent.prototype.submitDetailsForm = function (details) {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(this.ptwSpinnerId);
        this.pushToWalletService.sendCode(details).pipe(takeUntil(this.unsubscribed)).subscribe(function (response) {
            _this.pushToWalletData = response;
            _this.loadingSpinnerService.hideSpinnerById(_this.ptwSpinnerId);
            if (_this.parseErrors()) {
                _this.goToNextStep();
            }
        }, function (errorInfo) { return _this.handleGenericError(errorInfo, 'sendCode'); });
    };
    PushToWalletContainerComponent.prototype.submitCode = function (code) {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(this.ptwSpinnerId);
        this.pushToWalletService.confirmWithCode(code).pipe(takeUntil(this.unsubscribed)).subscribe(function (response) {
            _this.pushToWalletData = response;
            _this.confirmedByCode = !_this.pushToWalletData.errors || _this.pushToWalletData.errors.length === 0;
            _this.loadingSpinnerService.hideSpinnerById(_this.ptwSpinnerId);
            _this.parseErrors();
            if (_this.confirmedByCode) {
                _this.ptwConfirmationComponent.first.showPopUp();
            }
        }, function (errorInfo) { return _this.handleGenericError(errorInfo, 'confirmWithCode'); });
    };
    PushToWalletContainerComponent.prototype.retrieveInfo = function (onSuccess) {
        var _this = this;
        this.pushToWalletService.retrieveInfo(this.continueOldFlow)
            .pipe(takeUntil(this.unsubscribed))
            .subscribe((function (response) {
            _this.pushToWalletData = response;
            _this.loadingSpinnerService.hideSpinnerById(_this.ptwSpinnerId);
            if (response.isConfirmed) {
                _this.confirmedByCode = true;
                if (_this.ptwStep < PushToWalletStepsEnum.Confirmation) {
                    _this.goToStep(PushToWalletStepsEnum.Confirmation, true);
                }
                return;
            }
            else if (!response.items.length) {
                _this.continueOldFlow = false;
                _this.goToStep(PushToWalletStepsEnum.FlightSelect, true);
            }
            if (!_this.parseErrors()) {
                return;
            }
            if (!response.selectedJourneys || !response.selectedJourneys.length) {
                _this.continueOldFlow = false;
                _this.goToStep(PushToWalletStepsEnum.FlightSelect, true);
                return;
            }
            if (onSuccess) {
                onSuccess();
            }
        }));
    };
    PushToWalletContainerComponent.prototype.goToNextStep = function (replaceUrl) {
        if (replaceUrl === void 0) { replaceUrl = false; }
        var nextStep = this.ptwStep + 1;
        this.goToStep(nextStep, replaceUrl);
    };
    PushToWalletContainerComponent.prototype.goToStep = function (step, replaceUrl) {
        if (replaceUrl === void 0) { replaceUrl = false; }
        this.router.navigate([], { queryParams: { step: step }, replaceUrl: replaceUrl });
    };
    PushToWalletContainerComponent.prototype.parseErrors = function () {
        var e_1, _a;
        this.errors.length = 0;
        if (!this.pushToWalletData.isConfirmed && !this.pushToWalletData.items.length && !this.pushToWalletData.errors.length) {
            this.pushToWalletData.errors.push('Not-Applicable');
        }
        if (!this.pushToWalletData.errors) {
            return;
        }
        try {
            for (var _b = tslib_1.__values(this.pushToWalletData.errors), _c = _b.next(); !_c.done; _c = _b.next()) {
                var err = _c.value;
                switch (err) {
                    case 'NoBookingInSession':
                        this.errors = [err];
                        this.pushToWalletData = null;
                        return false;
                    case 'Not-Applicable':
                    case 'ChargeBackInProgressOrFinalized':
                    case 'InvalidSelection':
                    case 'InvalidData':
                        if (this.ptwStep > PushToWalletStepsEnum.FlightSelect) {
                            this.goToStep(PushToWalletStepsEnum.FlightSelect, true);
                        }
                        this.errors = [err];
                        return false;
                    case 'UserIsNotLoggedIn':
                        if (this.ptwStep > PushToWalletStepsEnum.Login) {
                            this.goToStep(PushToWalletStepsEnum.Login, true);
                        }
                        return false;
                    case 'InvalidDetails':
                        if (this.ptwStep > PushToWalletStepsEnum.DetailsForm) {
                            this.goToStep(PushToWalletStepsEnum.DetailsForm, true);
                        }
                        this.errors = [err];
                        return false;
                    case 'SMSFailedByLimit':
                    case 'SMSFailed':
                        this.errors = [err];
                        return false;
                    case 'InvalidConfirmationCode':
                        this.errors = [err];
                        return false;
                    case 'NegativePaymentNotAdded':
                        this.errors = [err];
                        return false;
                    default:
                        this.errors.push(err);
                        return false;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return true;
    };
    PushToWalletContainerComponent.prototype.handleGenericError = function (errorResponse, source) {
        var _this = this;
        this.genericErrors.length = 0;
        if (errorResponse.error && errorResponse.error.errors) {
            var errorList_1 = errorResponse.error.errors;
            this.genericErrors = errorList_1.map(function (err) { return err.errorMessage; });
            try {
                this.flowManager.applicationFlowService.loadFlowInfo().then(function (booking) {
                    _this.userActivityService.trackTrace('UPCF Error', 'Error', {
                        recordLocator: booking.bookingDetail.recordLocator,
                        upcfMethod: source,
                        errors: JSON.stringify(errorList_1)
                    });
                });
            }
            catch (_a) { }
        }
        this.loadingSpinnerService.hideSpinnerById(this.ptwSpinnerId);
    };
    return PushToWalletContainerComponent;
}());
export { PushToWalletContainerComponent };
export var PushToWalletStepsEnum;
(function (PushToWalletStepsEnum) {
    PushToWalletStepsEnum[PushToWalletStepsEnum["None"] = 0] = "None";
    PushToWalletStepsEnum[PushToWalletStepsEnum["FlightSelect"] = 1] = "FlightSelect";
    PushToWalletStepsEnum[PushToWalletStepsEnum["Login"] = 2] = "Login";
    PushToWalletStepsEnum[PushToWalletStepsEnum["DetailsForm"] = 3] = "DetailsForm";
    PushToWalletStepsEnum[PushToWalletStepsEnum["Confirmation"] = 4] = "Confirmation";
})(PushToWalletStepsEnum || (PushToWalletStepsEnum = {}));
