import { BookingChangesService } from './booking-changes.service';
import { BookingSelectionService } from './booking-selection.service';
import { NgModule, APP_INITIALIZER, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ConfigService } from './config.service';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { NavigationComponent } from './navigation/navigation.component';
import { SharedModule } from '../shared/shared.module';
import { SessionService } from '../common-modules/blue-air-common/session.service';
import { BlueHttpInterceptorService } from '../common-modules/blue-air-common/blue-http-interceptor.service';
import { CheckinService } from './checkin.service';
import { CheckinSelectionService } from './checkin-selection.service';
import { BookingStepsService } from './booking-steps.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { UserStateService } from '../common-modules/blue-air-common/user-state.service';
import { LoadingSpinnerService } from '../common-modules/blue-air-common/loading-spinner.service';
import { BookingService } from './booking.service';
import { environment } from '../../environments/environment';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { LoggerService } from '../common-modules/log4ts/logger.service';
import { ConsoleLoggerService } from '../common-modules/log4ts/console-logger.service';
import { ResourceService } from '../common-modules/blue-air-common/resource.service';
import { SessionInUrlGuard } from '../common-modules/blue-air-common/sesison-in-url/session-in-url-guard.service';
import { BookingFlowService } from './booking-flow.service';
import { CurrencyManagerService } from './currency-manager.service';
import { UserActivityService } from './user-activity.service';
import { ErrorsHandlerService } from './errors-handler.service';
import { BookingAppFlowResolverService } from './resolvers/booking-app-flow-resolver.service';
import { BookingLockFareFlowResolverService } from './resolvers/booking-lock-fare-flow-resolver.service';
import { CoreRoutes } from './core.routes';
import { ProfileService } from './profile.service';
import { AuthGuard } from './guards/auth-guard.service';


export function initFactory(cs: ConfigService, sessionService: SessionService,
  translateService: TranslateService, logger: LoggerService, resourceService: ResourceService, 
  currencyManagerService: CurrencyManagerService) {
  return () => {
    logger.init(!environment.production);
    currencyManagerService.init();
    return cs.load().then(() => {
      resourceService.init(cs.config.resourcesUrl, cs.config.apiUrl, cs.config.webApiKey);
      return Promise.all([
        sessionService.load(cs.SessionUrl),
        translateService.load(cs.config.translationsUrl, cs.config.collectTranslationsUrl,
          environment.collectTranslations)
      ]);
    });
  };
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxWebstorageModule.forRoot({ prefix: 'blue', separator: '.' }),
    RouterModule.forRoot(CoreRoutes),
    SharedModule,
    BlueAirCommonModule
  ],
  exports: [
    NavigationComponent
  ],
  providers: [
    ConfigService,
    SessionService,
    TranslateService,
    UserStateService,
    BookingService,
    BookingChangesService,
    CheckinService,
    BookingFlowService,
    CheckinSelectionService,
    BookingSelectionService,
    BookingFareSelectSyncService,
    ResourceService,
    BookingStepsService,
    LoadingSpinnerService,
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      deps: [ConfigService, SessionService, TranslateService, LoggerService, ResourceService, CurrencyManagerService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlueHttpInterceptorService,
      multi: true
    },
    {
      provide: LoggerService,
      useClass: ConsoleLoggerService
    },
    {
      provide: ErrorHandler,
      useClass: ErrorsHandlerService
    },
    BookingAppFlowResolverService,
    BookingLockFareFlowResolverService,
    SessionInUrlGuard,
    AuthGuard,
    CurrencyManagerService,
    UserActivityService,
    ProfileService
  ],
  declarations: [NavigationComponent]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, userActivity: UserActivityService) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    userActivity.init();
  }
}

import localeDe from '@angular/common/locales/de';
import localeGr from '@angular/common/locales/el';
import localeEn from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeFi from '@angular/common/locales/fi';
import localeFr from '@angular/common/locales/fr';
import localeHe from '@angular/common/locales/he';
import localeIt from '@angular/common/locales/it';
import localeNn from '@angular/common/locales/nn';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeSv from '@angular/common/locales/sv';
import { BookingFareSelectSyncService } from '../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';

registerLocaleData(localeDe, 'de');
registerLocaleData(localeGr, 'el');
registerLocaleData(localeEn, 'en-GB');
registerLocaleData(localeEs, 'es');
registerLocaleData(localeFi, 'fi');
registerLocaleData(localeFr, 'fr');
registerLocaleData(localeHe, 'he');
registerLocaleData(localeIt, 'it');
registerLocaleData(localeNn, 'nn');
registerLocaleData(localeNn, 'nn');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeRo, 'ro');
registerLocaleData(localeSv, 'sv');
registerLocaleData(localeNn, 'nn');