import { Constants } from '../constants';
import { Subscription, BehaviorSubject } from 'rxjs';
import { BookingService } from './booking.service';
import { SelectionService } from './interfaces/selection.service';
import { Injectable } from '@angular/core';
import { FlightModel } from './models/flight-model';
import { PassengerModel } from './models/passenger-model';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { BundleService } from '../shared/bundle.service';
import { BookingFareSelectSyncService } from '../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';

type PassengersSelectionStatus = Record<string, Record<number, boolean>>;

@Injectable()
export class BookingSelectionService implements SelectionService {

  flights: FlightModel[] = [];
  passengers: PassengerModel[] = [];

  flightsObs: BehaviorSubject<FlightModel[]> = new BehaviorSubject([]);
  passengersObs: BehaviorSubject<PassengerModel[]> = new BehaviorSubject([]);

  private flightsToChangeSubsciption: Subscription;
  private bookingTripsSubsciption: Subscription;

  constructor(private bookingService: BookingService, private translateService: TranslateService, private bundleService: BundleService, private syncService: BookingFareSelectSyncService) {
    this.bookingService.bookingObs.subscribe(bookingData => {
      if (bookingData) {
        this.updateData(bookingData.passengers, bookingData.convertedJourneys, true);
      }
    });
  }


  updateData(passengersViewModel: any, convertedJourneysViewModel: any, addBundles?: boolean) {
    let tempflights = [];
    this.passengers = [];
    const bundleDetail = [];
    let bundles = null;

    if (passengersViewModel && convertedJourneysViewModel) {
      const passengers = passengersViewModel.items;
      const journeys = convertedJourneysViewModel.journeys;

      const typeCounter = { adtCount: 0, chdCount: 0, infCount: 0 };

      if (addBundles != false) {
        bundles = this.bundleService.getBundleDetails();
      }

      passengers.forEach(pax => {
        if (!pax.hasInfant) {
          this.setInfant(pax);
        }

        const passenger: PassengerModel = new PassengerModel(pax, typeCounter, this.syncService);
        this.passengers.push(passenger);
      });

      if (journeys.length === 1) {
        this.flightsToChangeSubsciption = this.bookingService.flightsToChangeObs.subscribe(data => {
          if (data && (data as any).length === 1) {
            journeys[0].journeySequenceNumber = (data as any)[0].journeySequenceNumber;
          }
        });
      }

      journeys.forEach((journey, journeyIndex) => {
        const flight = new FlightModel();
        flight.initFromJourney(journey, this.translateService);
        flight.typeCounter = { adtCount: 0, chdCount: 0 }
        this.passengers.forEach(passenger => {
          if (bundles && bundles.passengerJourneyrBundles.currentBundleCodes[journeyIndex]) {
            const cbnd = bundles.passengerJourneyrBundles.currentBundleCodes[journeyIndex].find(i => i.key === passenger.passengerNumber);
            const passengerBundleCode = cbnd ? cbnd.value : null;
            const jbnd = bundles.passengerJourneyrBundles.journeyBundles[journeyIndex].items.find(i => i.serviceBundleCode === passengerBundleCode);
            const price = jbnd ? jbnd.serviceBundlePriceList[0].price : 0;
            bundleDetail[passenger.paxType] = price;
          }
          // passenger = passenger.clone(flight.typeCounter);
          passenger = passenger.clone();
          passenger.flight = flight;

          flight.addPassenger(passenger, journey);
        });
        tempflights.push(flight);


        const isManageBooking = this.bookingService.getBooking().bookingDetail.recordLocator ? true : false;
        // needed to show journey fare on booking flow
        flight.calculateFares(journey, bundleDetail, convertedJourneysViewModel.oldJourneyFares);
        flight.calculateInfantFare(passengers, isManageBooking);

      });

      this.passengers.sort(PassengerModel.sortFn);
      tempflights.sort(FlightModel.sortFn);

      tempflights.forEach((flight: FlightModel) => {
        flight.setFlightInThePast(convertedJourneysViewModel.cancelDepartureTimeLimit);
      });

      this.setPassengersNameByType();
    }

    this.flights = tempflights;
    this.flightsObs.next(this.flights);
    this.passengersObs.next(this.passengers);
  }

  private setInfant(pax: any) {
    const hasInfant = pax.fees && pax.fees.items && pax.fees.items.filter(f => f.code === Constants.InfantSsrCode).length;
    if (hasInfant) {
      pax.hasInfant = true;
      pax.infant = {
        attachedPassengerNumber: pax.passengerNumber,
        dateOfBirth: null,
        gender: 'Male',
        name: {}
      };
    }
  }

  private setPassengersNameByType() {
    this.passengers.forEach(passenger => {
      passenger.generatePassengerNameByType(this.translateService);
      if (passenger.hasInfant) {
        passenger.infant.passengerNumberByType = passenger.passengerNumberByType;
        passenger.infant.generatePassengerNameByType(this.translateService);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.flightsToChangeSubsciption) {
      this.flightsToChangeSubsciption.unsubscribe();
    }
    if (this.bookingTripsSubsciption) {
      this.bookingTripsSubsciption.unsubscribe();
    }
  }
}
