<div class="itineraryBox_benefits_banners" [ngSwitch]="displaySize">
  <div class="banner-desktop" *ngSwitchDefault>
    <a href="{{ 'thankyou.upsellUrl' | translate: 'rainbow.upsell'}}">
      <img src="{{ 'thankyou.upsellBanner.desktopM' | translate: 'rainbow.upsell' }}" alt="Blue Benefits">
    </a>
  </div>
  <div class="banner-tablet" *ngSwitchCase="'tablet'">
    <a href="{{ 'thankyou.upsellUrl' | translate: 'rainbow.upsell'}}">
      <img src="{{ 'thankyou.upsellBanner.desktopS' | translate: 'rainbow.upsell' }}" alt="Blue Benefits">
    </a>
  </div>
  <div class="banner-mobile" *ngSwitchCase="'mobile'">
    <a href="{{ 'thankyou.upsellUrl' | translate: 'rainbow.upsell'}}">
      <img src="{{ 'thankyou.upsellBanner.mobile' | translate: 'rainbow.upsell' }}" alt="Blue Benefits">
    </a>
  </div>
</div>