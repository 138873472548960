<blue-modal [id]="sessionModalId" cssClass="checked-in-summary-popup" [showCloseBtn]="false">
  <ng-container *ngIf="!sessionRefreshFailed else sessionRefreshFailedTemplate">
    <ng-container *ngIf="sessionNotification.isExpiring">
        <blue-loading-spinner [id]="sessionRefreshingSpinnerId"></blue-loading-spinner>
        <h2>
            {{'Your session is about to expire' | translate:'session-warning'}} 
            <span>{{sessionNotification.timeLeftToExpire.minutes | number:'2.0-0'}}:{{ sessionNotification.timeLeftToExpire.seconds| number:'2.0-0'}}</span>
        </h2>
        <h2 id="continueBrowsing">
            {{'If you would like to continue browsing, please press continue.' | translate:'session-warning' }}
        </h2>
        <button type="button" class="btn btn-primary" aria-labelledby="continueBrowsing" (click)="refreshSession()">
            {{'Continue' | translate:'session-warning' }}
        </button>
    </ng-container>
 
    <ng-container *ngIf="sessionNotification.isExpired">
        <h2 id="sessionExpired">{{'Your session has expired.' | translate: 'session-warning'}}</h2>

        <button type="button" class="btn btn-primary" aria-labelledby="sessionExpired" (click)="startOver()">
            {{'Start Over' | translate:'session-warning' }}
        </button>
    </ng-container>
  </ng-container>

  <ng-template #sessionRefreshFailedTemplate>
      <h2 id="sessionRefreshFailed">{{'You session could not be renewed' | translate:'session-warning'}}</h2>
      <button type="button" class="btn btn-primary" aria-labelledby="sessionRefreshFailed" (click)="startOver()">
          {{'Start Over' | translate:'session-warning' }}
      </button>
  </ng-template>
</blue-modal>


