import * as tslib_1 from "tslib";
import { TranslateService } from './../../common-modules/blue-air-common/translator/translate.service';
import { FlightModel } from './../../core/models/flight-model';
import { SsrsService } from './../ssrs.service';
import { EventEmitter, OnInit } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrType } from '../ssr-type.enum';
var SsrBoxContentFastTrackComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsrBoxContentFastTrackComponent, _super);
    function SsrBoxContentFastTrackComponent(ssrsService, translateService) {
        var _this = _super.call(this, ssrsService) || this;
        _this.ssrsService = ssrsService;
        _this.translateService = translateService;
        _this.saveChangesEvent = new EventEmitter();
        _this.ssrType = SsrType.FastTrack;
        return _this;
    }
    SsrBoxContentFastTrackComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        if (this.flight && this.flight.passengers.length > 0) {
            this.allPassengersEnabled = this.allPassengersEnabledFunc();
            if (!this.allPassengersEnabled) {
                this.selectedPassenger = 0;
            }
        }
    };
    SsrBoxContentFastTrackComponent.prototype.initFlightSsrList = function () {
        var _this = this;
        _super.prototype.initFlightSsrList.call(this);
        if (!this._fullSsrList || !this._flight) {
            return;
        }
        this.notAvailableForSegments = [];
        this._flight.segments.forEach(function (segment) {
            var flightKey = segment.departureStation.iataCode + "_" + segment.arrivalStation.iataCode;
            var avSsrList = _this._fullSsrList.find(function (avSsr) { return avSsr.key === flightKey; });
            if (!avSsrList || !avSsrList.value.length) {
                var seg = new FlightModel();
                seg.initFromSegment(segment, _this.translateService);
                _this.notAvailableForSegments.push(seg);
            }
        });
        this.ssr = this.availableSsrs[0];
    };
    SsrBoxContentFastTrackComponent.prototype.selectSsrEvent = function (passengerIndex, ssrCode, newSsrQuantity) {
        this.selectSsr(passengerIndex, ssrCode, newSsrQuantity);
        this.saveChangesEvent.emit(passengerIndex);
    };
    SsrBoxContentFastTrackComponent.prototype.resetSsr = function () {
        this.saveChangesEvent.emit();
    };
    SsrBoxContentFastTrackComponent.prototype.onEnableSameOptionsForAllFlightsChanged = function (value) {
        _super.prototype.onEnableSameOptionsForAllFlightsChanged.call(this, value);
        if (!value)
            return;
        this.saveChangesEvent.emit();
    };
    // override base method
    // for this special ssr (airport-checkin) add the ssr only for segments where it is available
    SsrBoxContentFastTrackComponent.prototype.applySpecificSsr = function (oldQuantity, newSsrQuantity, ssr, passengerNumber) {
        var _this = this;
        var paidSSRQuantityOverride = this._paidSSROverrides.getPaidQuantity(passengerNumber, ssr.ssrCode);
        var tempNewSsrQuantity = newSsrQuantity - paidSSRQuantityOverride;
        var tempOldSsrQuantity = oldQuantity - paidSSRQuantityOverride;
        while (tempNewSsrQuantity > oldQuantity - paidSSRQuantityOverride) {
            this.flight.segments.forEach(function (segment) {
                var flightKey = segment.departureStation.iataCode + "_" + segment.arrivalStation.iataCode;
                var avSsrList = _this._fullSsrList.find(function (avSsr) { return avSsr.key === flightKey; });
                if (avSsrList && avSsrList.value.find(function (avSsr) { return avSsr.ssrCode === ssr.ssrCode; })) {
                    _this._ssrsService.addSsr(_this.ssrType, ssr.ssrCode, passengerNumber, segment, false);
                }
            });
            tempNewSsrQuantity--;
        }
        while (newSsrQuantity - paidSSRQuantityOverride < tempOldSsrQuantity) {
            this._ssrsService.removeSsr(this.ssrType, ssr.ssrCode, tempOldSsrQuantity, passengerNumber, this.flight, this.applySsrsToSegments);
            tempOldSsrQuantity--;
        }
    };
    SsrBoxContentFastTrackComponent.prototype.allPassengersEnabledFunc = function () {
        if (this.passengers.length < 2) {
            return false;
        }
        if (this.availableSsrs[0]) {
            for (var property in this.availableSsrs[0].limitPerPassenger) {
                if (this.availableSsrs[0].limitPerPassenger.hasOwnProperty(property)) {
                    if (this.availableSsrs[0].limitPerPassenger[property] === 0) {
                        return false;
                    }
                }
            }
        }
        return true;
    };
    return SsrBoxContentFastTrackComponent;
}(SsrBoxContentBase));
export { SsrBoxContentFastTrackComponent };
