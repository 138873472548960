import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BookingStepsService } from '../core/booking-steps.service';
import { SsrsService } from './ssrs.service';
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { FlowManagerService } from '../core/flow-manager.service';

@Injectable()
export class CanActivateExtrasOnBookingService extends BaseCanActivateService implements CanActivate {

  constructor(flowManager: FlowManagerService, private ssrsService: SsrsService, bookingSteps: BookingStepsService) {
    super(bookingSteps, flowManager.applicationFlowService);
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    const superPromise = super.canActivate(route, state) as Promise<boolean>;

    return superPromise.then(superResponse => {
      if (!superResponse) {
        return false;
      }
      return Promise.all([
        this.applicationFlowService.loadFlowInfo().then(() => this.applicationFlowService.loadPriceBreakdown()),
        this.ssrsService.getSsrsInfo().toPromise()
      ])
        .then(data => true);
    });
  }
}
