import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { PushToWalletDetails } from '../push-to-wallet.service';
import { NgForm, FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BookingService } from 'src/app/core/booking.service';
import { ProfileService } from 'src/app/core/profile.service';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';

@Component({
  selector: 'booking-ptw-details-form',
  templateUrl: './ptw-details-form.component.html',
  styleUrls: ['./ptw-details-form.component.scss']
})
export class PtwDetailsFormComponent implements OnInit, OnChanges {
  public phonePattern = '^00[1-9][0-9+]{5,17}?$';
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
  showDetails: boolean;
  showEmailErrorMessage: boolean;
  showB2BErrorMessage: boolean;

  @Input() details: PushToWalletDetails;
  @Input() errors: string[];
  @Output() continue: EventEmitter<PushToWalletDetails> = new EventEmitter();
  assetsPath: string;

  @ViewChild('clientDetailsForm', { static: true }) clientDetailsForm: NgForm;

  model: PushToWalletDetails = new PushToWalletDetails();

  constructor(private bookingService: BookingService, private userStateService: UserStateService) {
    this.assetsPath = environment.assetsPath;
  }

  ngOnInit() {
    if(this.bookingService.isB2CBooking()){
      this.checkUserByEmail();
    }else{
      this.checkPassagersByNames();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.details) {
      this.model = this.details || new PushToWalletDetails();
    }
  }

  submit() {
    for (const key in this.clientDetailsForm.controls) {
      if (this.clientDetailsForm.form.controls.hasOwnProperty(key)) {
        const element = this.clientDetailsForm.form.controls[key];
        if (element instanceof FormControl) {
          (element as FormControl).markAsDirty({ onlySelf: true });
        }
      }
    }

    if (this.clientDetailsForm.valid) {
      this.continue.next(this.model);
    }
  }

  cleanupPhoneNumber() {
    let value = this.model.phoneNumber;
    if (value && value.startsWith('+')) {
      value = '00' + value.substring(1);
    }
    if (value) {
      this.model.phoneNumber = value.replace(/[^\d]+/g, '').trim();
    }
  }

  checkUserByEmail() {
    var booking = this.bookingService.getBooking();
    var member = this.userStateService.userPromoDetails.value;

    this.showEmailErrorMessage = true;

    if(booking.contact.emailAddress.toLowerCase().match(member.Username.toLowerCase())){
      this.showDetails = true;
    }else{
      this.showDetails = false
    }
  }

  checkPassagersByNames() {
    var booking = this.bookingService.getBooking();
    var member = this.userStateService.userPromoDetails.value;

    this.showB2BErrorMessage = true;

    if(booking.passengers.items.some(i => i.name.first.toLowerCase() == member.First.toLowerCase() && i.name.last.toLowerCase() == member.Last.toLowerCase())){
      this.showDetails = true;
    }else{
      this.showDetails = false
    }
  }
}
