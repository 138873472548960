/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./seat-selected-notification.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component";
import * as i4 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i5 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i6 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i7 from "../../common-modules/blue-air-common/blue-modal/blue-modal.component.ngfactory";
import * as i8 from "../../common-modules/blue-air-common/services/blue-renderer.service";
import * as i9 from "../../common-modules/blue-air-common/blue-modal/blue-modal.component";
import * as i10 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i11 from "@angular/common";
import * as i12 from "./seat-selected-notification.component";
var styles_SeatSelectedNotification = [i0.styles];
var RenderType_SeatSelectedNotification = i1.ɵcrt({ encapsulation: 0, styles: styles_SeatSelectedNotification, data: {} });
export { RenderType_SeatSelectedNotification as RenderType_SeatSelectedNotification };
function View_SeatSelectedNotification_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "blue-loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 4964352, null, 0, i3.LoadingSpinnerComponent, [i4.LoadingSpinnerService, i1.ElementRef, i1.Renderer2], { id: [0, "id"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "default-modal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "h2", [["class", "section-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "warning-sign"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(9, 0, null, null, 8, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "button", [["class", "btn btn-primary btn-small btn-left"], ["type", "submit"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(11, 2), (_l()(), i1.ɵted(12, null, [" ", " "])), i1.ɵppd(13, 2), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [["class", "btn btn-primary btn-small btn-right"], ["type", "submit"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(15, 2), (_l()(), i1.ɵted(16, null, [" ", " "])), i1.ɵppd(17, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sendEmailSpinnerId; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "You have not confirmed your selection", "shared")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), "Click to dismiss", "shared")), ""); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent, 0), "Dismiss and continue", "shared")); _ck(_v, 12, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent, 0), "Click to dismiss", "shared")), ""); _ck(_v, 14, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent, 0), "Reconsider", "shared")); _ck(_v, 16, 0, currVal_5); }); }
export function View_SeatSelectedNotification_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.TranslatePipe, [i6.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "blue-modal", [["class", "checked-in-summary-popup"]], null, null, null, i7.View_BlueModalComponent_0, i7.RenderType_BlueModalComponent)), i1.ɵprd(512, null, i8.BlueRenderer, i8.BlueRenderer, [i1.Renderer2]), i1.ɵdid(3, 245760, null, 0, i9.BlueModalComponent, [i10.BlueModalService, i1.ElementRef, i1.Renderer2, i8.BlueRenderer], { id: [0, "id"], closeBtnLabel: [1, "closeBtnLabel"] }, null), i1.ɵppd(4, 2), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SeatSelectedNotification_1)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalId; var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 1, _ck(_v, 4, 0, i1.ɵnov(_v, 0), "Close notification popup", "shared")), ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.isOpened; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_SeatSelectedNotification_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "seat-selected-notification", [], null, null, null, View_SeatSelectedNotification_0, RenderType_SeatSelectedNotification)), i1.ɵdid(1, 114688, null, 0, i12.SeatSelectedNotification, [i10.BlueModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SeatSelectedNotificationNgFactory = i1.ɵccf("seat-selected-notification", i12.SeatSelectedNotification, View_SeatSelectedNotification_Host_0, {}, {}, ["*"]);
export { SeatSelectedNotificationNgFactory as SeatSelectedNotificationNgFactory };
