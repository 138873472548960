<div class="lockFare {{ cssClass }}" *ngIf="isAvailable" >
  <blue-loading-spinner [activationRoute]="lockFareRoute"></blue-loading-spinner>
  <div class="lockFareWrapper" #wrapper>
      <div class="lockFare_oneCol">
          <div class="lockFare_oneCol_content">
              <div class="lockFare_title">{{ 'LockFare-TitleText' | translate:'lock-fare' }}</div>
              <div class="lockFare_subtitle">{{ 'LockFare-SubtitleText' | translate:'lock-fare' }}</div>
          </div>
          <div class="lockFare_oneCol_img">
            <img [src]="assetsPath + 'svg/icon-lock-fare.svg'">
          </div>
      </div>

      <div class="lockFare_oneCol btn-wrap">
          <div class="btn-wrap-content">
              <button class="btn btn-primary btn-lock" (click)="toggleLockFare()" >
                  <ng-container *ngIf="!lockFare">
                    {{ 'LockFare-AddButtonText' | translate:'lock-fare' }} {{ price | currencyDisplay}}
                  </ng-container>
                  <ng-container *ngIf="lockFare">
                      {{ 'LockFare-RemoveButtonText' | translate:'lock-fare' }}
                    </ng-container>
                </button>
          </div>
          
            <div class="lockFare_infoText" *ngIf="lockFare">
                <span class="lockFare_infoText_content" [innerHTML]="lockFareInfoText"></span>
                <span class="lockFare_infoText_toggle" *ngIf="enableShowMore" (click)="toggleShowMore()" >
                    {{ (showMore ? 'show less' : 'show more') | translate:'lock-fare' }}
                </span>
            </div>
      </div>




      <!-- <div class="lockFare_twoCol"><div class="lockFare_infoText">{{ 'LockFare-InfoText' | translate:'lock-fare' }}</div></div>
      <div class="lockFare_twoCol noBorder"><button class="btn btn-primary btn-lock" (click)="toggleLockFare()" >
        <ng-container *ngIf="!lockFare">
          {{ 'LockFare-AddButtonText' | translate:'lock-fare' }} {{ price | currencyDisplay}}
        </ng-container>
        <ng-container *ngIf="lockFare">
            {{ 'LockFare-RemoveButtonText' | translate:'lock-fare' }} {{ price | currencyDisplay}}
          </ng-container>
      </button></div> -->
  </div>
</div>
