export enum ChangeBooking {    
    flight,
    luggage,
    extras,
    seats,
    passengers,
    contact
}

export class BookingChanges {
    ssrTypeName: string;
    passengerSsrsByType: PassengerSsrsByType[] = [];

    constructor(ssrTypeName: string) {
        this.ssrTypeName = ssrTypeName;
    }

    setSsrListForPassenger(passenger: string, ssrList: any) {
        this.passengerSsrsByType.push(new PassengerSsrsByType(passenger, ssrList));
    }
}

export class PassengerSsrsByType {
    passenger: any;
    ssrCountOnSegment: SsrCountOnSegment[] = [];

    constructor(passenger: any, ssrList: any) {
        this.passenger = passenger;
        ssrList.forEach(ssr => {
            if (ssr.length > 0 || ssr.segment) {
                this.ssrCountOnSegment.push(new SsrCountOnSegment(ssr));
            }
        });
    }
}

export class SsrCountOnSegment {
    segment: any;
    ssrCount: SsrCount[] = [];

    constructor(ssrList: any) {
        this.segment = ssrList.length ? ssrList[0].segment : ssrList.segment;
        if (ssrList.length) {
            ssrList.forEach(ssr => {
                this.ssrCount.push(new SsrCount(ssr));
            });
        } else {
            this.ssrCount.push(new SsrCount(ssrList));
        }
    }
}

export class SsrCount {
    code: string;
    oldValue: any; // string - seat, number - ssrs
    newValue: any; // string - seat, number - ssrs

    constructor(ssr: any) {
        this.code = ssr.code;
        this.oldValue = ssr.oldValue;
        this.newValue = ssr.newValue;
    }
}

export class InsuranceItinerary {
    passenger: any;
    insuranceName: string;
    boughtOnChangeFlow: boolean;

    constructor(passenger: any, insuranceName: string, boughtOnChangeFlow: boolean) {
        this.passenger = passenger;
        this.insuranceName = insuranceName;
        this.boughtOnChangeFlow = boughtOnChangeFlow;    
    }
}