<check-in-default-modal #UMmodal modalId="UMmodal"
  modalTitle="{{'Unaccompanied minor information' | translate: 'passengers'}}"
  modalText="{{'You are less than 18 years old. In order to complete your booking, please choose one of the following options:' | translate: 'passengers'}}"
  okButtonText="{{'Buy' | translate: 'passengers'}}" 
  cancelButtonText="{{'Cancel' | translate:'passengers'}}"
  okRequired="true" 
  [okDisabled]="!selectedSsrCode"
  cancelRequired="true">
  <br/>
  <div class="radio-wrapper" *ngFor="let ssr of availableUMssrs">
    <input type="radio" name="umSsr" id="{{ssr.ssrResource}}" value="{{ssr.ssr.ssrCode}}" (click)="setChosenUMssr(ssr.ssr.ssrCode)"/>
    <label for="{{ssr.ssrResource}}">
      {{ssr.ssrResource | translate:'passengers'}} - {{ssr.ssr.price | currencyDisplay}}
    </label>
  </div>
</check-in-default-modal>