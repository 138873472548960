export abstract class Logger {
    protected isDebugMode: boolean;
    info: any;
    warn: any;
    error: any;
    log: any;
    init(isDebugMode: boolean): void {
        this.isDebugMode = isDebugMode;
    }
}
