import { BlueModalService } from './../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { Component, OnInit, Input } from '@angular/core';
import { SsrsService } from 'src/app/extras/ssrs.service';

@Component({
  selector: 'check-in-default-modal',
  templateUrl: './default-modal.component.html',
  styleUrls: ['./default-modal.component.scss']
})
export class DefaultModalComponent implements OnInit {

  @Input() modalId: string;
  @Input() modalTitle: string;
  @Input() modalText: string;
  @Input() cancelButtonText: string;
  @Input() okButtonText: string;
  @Input() okRequired: boolean;
  @Input() okDisabled: boolean;
  @Input() cancelRequired: boolean;
  @Input() cssClass: string;
  @Input() leftButtonClass: string;
  @Input() rightButtonClass: string;
  @Input() showCloseBtn: boolean = true;

  isModalOpened: boolean;
  isOpened: boolean;
  okSelected: boolean;
  okButtonId: string;
  cancelButtonId: string;

  constructor(private modalService: BlueModalService, private ssrsService: SsrsService) {
  }

  ngOnInit() {
    this.okButtonId = this.modalId + "_ok";
    this.cancelButtonId = this.modalId + "_cancel";
  }

  openPopup(callback) {
    this.isOpened = true;
    this.modalService.open(this.modalId, () => {
      this.isOpened = false;
      if (callback != null && typeof callback === 'function') {
        callback(this.okSelected)
      }
    });
  }

  ok() {
    this.okSelected = true;
    this.modalService.close(this.modalId);
  }

  cancel() {
    this.okSelected = false;
    this.modalService.close(this.modalId);
  }
}
