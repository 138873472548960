export class SsrModel {

    name: string;
    ssrCode: string;
    price: number;
    originalPrice: number;
    details: string;
    thumbnailDescription: string;
    thumbnailUrl: string;
    selected: boolean;
    isCarryOn: boolean;
    paidQuantity: number;
    selectedQuantity: number;
    nest: string;
    limitPerPassenger: LimitPerPassenger;
    limitPerNest: number;
    isVisible: boolean;
    showDetails: boolean;

    constructor(otherSsr: SsrModel = null, selectedQuantity: number = 0) {
        this.limitPerNest = 0;
        this.paidQuantity = 0;
        this.selectedQuantity = selectedQuantity;
        this.isVisible = true;

        if (otherSsr) {
            this.name = otherSsr.name;
            this.ssrCode = otherSsr.ssrCode;
            this.price = otherSsr.price;
            this.details = otherSsr.details;
            this.thumbnailDescription = otherSsr.thumbnailDescription;
            this.thumbnailUrl = otherSsr.thumbnailUrl;
            this.selected = otherSsr.selected;
            this.isCarryOn = otherSsr.isCarryOn;
            this.limitPerPassenger = otherSsr.limitPerPassenger;
            this.nest = otherSsr.nest;
            this.limitPerNest = otherSsr.limitPerNest;
            this.paidQuantity = otherSsr.paidQuantity;
        }
    }

    getQuantityArray(passengerNumber: number) {
        const result = [];
        if (this.limitPerPassenger[passengerNumber]) {
            for (let ix = 1; ix <= this.limitPerPassenger[passengerNumber]; ix++) {
                result.push(ix);
            }
        }
        return result;
    }

    getLimitPerPassenger(passengerNumber: number) {
        return this.limitPerPassenger[passengerNumber] || 0;
    }

    getLowestLimitPerPassenger() {
        let min = null;
        for (const passengerNumber in this.limitPerPassenger) {
            if (this.limitPerPassenger.hasOwnProperty(passengerNumber)) {
                if (min === null || this.limitPerPassenger[passengerNumber] < min) {
                    min = this.limitPerPassenger[passengerNumber];
                }
            }
        }

        return min;
    }
}

export interface LimitPerPassenger { [passengerNumber: number]: number; }
