import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { Observable } from 'rxjs';
import { BookingStepsService } from '../core/booking-steps.service';
import { FlowManagerService } from '../core/flow-manager.service';


@Injectable()
export class CanActivateDocumentsService extends BaseCanActivateService {
  constructor(steps: BookingStepsService, flowManager: FlowManagerService) {
    super(steps, flowManager.applicationFlowService);
    
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return super.canActivate(route, state);
  }
}
