import { Component, OnInit, OnDestroy } from '@angular/core';
import { SessionService, ISessionNotification } from '../../common-modules/blue-air-common/session.service';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { Subscription } from 'rxjs';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'session-warning',
  templateUrl: './session-warning.component.html',
  styleUrls: ['./session-warning.component.scss']
})
export class SessionWarningComponent implements OnInit, OnDestroy {

  sessionModalId = 'sessionWarningModal';
  sessionNotification: ISessionNotification;
  sessionRefreshFailed = false;

  sessionRefreshingSpinnerId = 'sessionRefreshingSpinnger';

  private modalOpened = false;
  private sessionNotificationSubscription: Subscription;

  constructor(
    private sessionService: SessionService,
    private modalService: BlueModalService,
    private translateService: TranslateService,
    private loadingSpinnerService: LoadingSpinnerService) {
    this.sessionNotificationSubscription = this.sessionService.sessionNotification.subscribe(sessionNotification => {
      if (!this.sessionNotification) {
        this.sessionNotification = sessionNotification;
      }
      if (sessionNotification.isExpired || sessionNotification.isExpiring) {
        this.sessionNotification = sessionNotification;
        if (!this.modalOpened) {
          this.modalOpened = true;
          this.modalService.open(this.sessionModalId, () => { this.modalOpened = false; });
        }
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.sessionNotificationSubscription) {
      this.sessionNotificationSubscription.unsubscribe();
    }
  }

  refreshSession() {
    this.loadingSpinnerService.showSpinnerById(this.sessionRefreshingSpinnerId);
    this.sessionService.refreshSession()
      .then(isOk => {
        this.sessionRefreshFailed = !isOk;
        this.loadingSpinnerService.hideSpinnerById(this.sessionRefreshingSpinnerId);
        if (isOk) {
          this.modalService.close(this.sessionModalId);
        }
      });
  }

  startOver() {
    const baseUrl = "https://www.aeroitalia.com/";
    // window.location.href = window.origin;
    window.location.href = this.translateService.culture.indexOf("en") != -1 ? `${baseUrl}en` : `${baseUrl}`;
  }
}
