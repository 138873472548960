import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';
var SsrBoxContentSpecialAssistanceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsrBoxContentSpecialAssistanceComponent, _super);
    function SsrBoxContentSpecialAssistanceComponent(ssrsService) {
        var _this = _super.call(this, ssrsService) || this;
        _this.ssrsService = ssrsService;
        _this.ssrsCustomIcons = {};
        _this.selectSsrEvent2 = new EventEmitter();
        _this.ssrType = SsrType.SpecialAssistance;
        _this.uniqueId = Math.floor(Math.random() * 1000) + 1;
        return _this;
    }
    SsrBoxContentSpecialAssistanceComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.selectedPassenger = 0;
        this.allPassengersEnabled = false;
    };
    SsrBoxContentSpecialAssistanceComponent.prototype.initFlightSsrList = function () {
        _super.prototype.initFlightSsrList.call(this);
        this.ssrsCustomIcons = this.ssrsService.setupSpecialAssistanceCustomIcons(this.availableSsrs);
    };
    SsrBoxContentSpecialAssistanceComponent.prototype.resetSsr = function () {
        this.selectSsr(null, "", 0);
        this.selectSsrEvent(null);
    };
    SsrBoxContentSpecialAssistanceComponent.prototype.selectSsrEvent = function (passengerIndex) {
        if (passengerIndex === void 0) { passengerIndex = null; }
        this.selectSsrEvent2.emit(passengerIndex);
    };
    SsrBoxContentSpecialAssistanceComponent.prototype.checkSameSsrOptionsForAllFlights = function () {
        this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
        this.selectSsrEvent(null);
    };
    return SsrBoxContentSpecialAssistanceComponent;
}(SsrBoxContentBase));
export { SsrBoxContentSpecialAssistanceComponent };
