<div class="box-title-stripe {{class}}" [ngClass]="{'shadow': showShadow}">
  <ng-container *ngIf="spinnerId || spinnerRoute">
    <blue-loading-spinner [id]="spinnerId" [activationRoute]="spinnerRoute" [showSpinner]="showSpinner"></blue-loading-spinner>  
  </ng-container>
  <div class="title-stripe">
    <span>{{title}}</span>
    <a class="btn btn-secondary popup-with-close" (click)="onEditClick($event)" *ngIf="showEditBtn">{{ 'Edit' | translate:'info-box' }}</a>
    <a class="btn btn-secondary popup-with-close" (click)="onAddClick($event)" *ngIf="showAddBtn">{{ 'Add' | translate:'info-box' }}</a>
  </div>
  <div class="details">
    <ng-content></ng-content>
  </div>
</div>