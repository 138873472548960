import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingService } from '../core/booking.service';

@Component({
  selector: 'check-in-booking-unsubscribe-notifications',
  templateUrl: './booking-unsubscribe-notifications.component.html',
  styleUrls: ['./booking-unsubscribe-notifications.component.scss']
})
export class BookingUnsubscribeNotificationsComponent implements OnInit {
  public pnr: string;
  public email: string;
  public unsubscribed: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private bookingService: BookingService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (Object.getOwnPropertyNames(params).length !== 0) {
        this.pnr = params['rl'];
        this.email = params['em'];
      }
    });
  }

  unsubscribeNotifications() {
    this.bookingService.unsubscribeBookingFromNotifications(this.pnr, this.email);

    this.unsubscribed = true;
  }
}
