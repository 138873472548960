/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./show-errors.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./show-errors.component";
import * as i4 from "../../common-modules/blue-air-common/translator/translate.service";
var styles_ShowErrorsComponent = [i0.styles];
var RenderType_ShowErrorsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ShowErrorsComponent, data: {} });
export { RenderType_ShowErrorsComponent as RenderType_ShowErrorsComponent };
function View_ShowErrorsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "validation-error"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_ShowErrorsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowErrorsComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listOfErrors(); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ShowErrorsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ShowErrorsComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldShowErrors(); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ShowErrorsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "show-errors", [], null, null, null, View_ShowErrorsComponent_0, RenderType_ShowErrorsComponent)), i1.ɵdid(1, 49152, null, 0, i3.ShowErrorsComponent, [i4.TranslateService], null, null)], null, null); }
var ShowErrorsComponentNgFactory = i1.ɵccf("show-errors", i3.ShowErrorsComponent, View_ShowErrorsComponent_Host_0, { control: "control", submitted: "submitted" }, {}, []);
export { ShowErrorsComponentNgFactory as ShowErrorsComponentNgFactory };
