import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, ElementRef } from '@angular/core';
import { CheckinSteps, BookingStepsService } from '../../core/booking-steps.service';
import { ConfigService } from '../../core/config.service';
import { SeatMapService } from '../seat-map.service';
import { SeatMap } from '../models/seat-models';
import { BehaviorSubject } from 'rxjs';
import { BookingService } from '../../core/booking.service';
import { SeatSelectedNotification } from '../../shared/seat-selected-notification/seat-selected-notification.component';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../../constants';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { environment } from '../../../environments/environment';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { SsrType } from '../../extras/ssr-type.enum';
import { PassengerModel } from '../../core/models/passenger-model';
import * as moment from 'moment';
import { SelectionsClearWarningModalComponent } from '../../shared/selections-clear-warning-modal/selections-clear-warning-modal.component';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { BookingFareSelectSyncService } from '../../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { BookingFlowService } from 'src/app/core/booking-flow.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';
var SeatComponent = /** @class */ (function () {
    function SeatComponent(config, seatMapService, bookingSteps, bookingService, loadingSpinnerService, flowManager, translateService, elRef, ecommerce, syncService, bookingFlowService) {
        var _this = this;
        this.config = config;
        this.seatMapService = seatMapService;
        this.bookingSteps = bookingSteps;
        this.bookingService = bookingService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.flowManager = flowManager;
        this.translateService = translateService;
        this.elRef = elRef;
        this.ecommerce = ecommerce;
        this.syncService = syncService;
        this.bookingFlowService = bookingFlowService;
        this.currentStep = CheckinSteps.seat;
        this.selectSeat = false;
        this.SsrTypeEnum = SsrType;
        this.journeys = [];
        this.flights = [];
        this.seatMap = new SeatMap(); // ={};
        this.passengerNotifier = new BehaviorSubject(null);
        this.errorMessage = [];
        this.isCheckinFlow = false;
        this.connectedJourney = [];
        this.filterClickNotifier = new BehaviorSubject(null);
        this.seatDeselectNotifier = new BehaviorSubject(null);
        this._canDeactivate = false;
        this.modalId = 'seatSelecModal';
        this.selectedSeats = {};
        this.passengers = [];
        this.pendingSsrsList = [];
        this.isPromotion = false;
        this.isBlueBenefit = false;
        this.noSeatSelectedModalId = 'noSeatSelectedModal';
        this.seatFilter = new EventEmitter();
        this.assetsPath = environment.assetsPath;
        this.isCheckinFlow = environment.flow === 0;
        this.bookingSteps.currentStep.next(this.currentStep);
        this.bookingService.bookingObs.subscribe(function (data) {
            if (data) {
                //only selected seats
                //data.assignedSeats.journeys.items.map(j=>j.segments).reduce((a,b)=>a.concat(b),[]).map(p=>p.items).reduce((a,b)=>a.concat(b),[]).map(p=>p.paxSeats).reduce((a,b)=>a.concat(b),[]).map(p=>p.items).reduce((a,b)=>a.concat(b),[])
                _this.booking = data;
                _this.isBlueBenefit = _this.booking.passengers.items.find(function (pax) { return pax.typeInfo.paxType.length > 3; });
                var typeCounter_1 = {};
                _this.passengers = data.passengers.items.map(function (p) { return new PassengerModel(p, typeCounter_1, _this.syncService); });
                _this.passengers.forEach(function (p) {
                    if (PassengerModel.checkNameFormat(p.name.first) && PassengerModel.checkNameFormat(p.name.first)) {
                        p.name.first = '';
                        p.name.last = '';
                    }
                    p.assignedSeat = {};
                });
                _this.remapSelectedSeats();
            }
        });
        this.seatMapService.errorMessage.subscribe(function (data) {
            if (Array.isArray(data))
                _this.errorMessage = data;
        });
        this.viewstep = translateService.instant('Step 5 Check-in form', 'seat');
    }
    SeatComponent.prototype.ngOnInit = function () {
        // if (!localStorage.getItem("pageState")) {
        //   localStorage.setItem("pageState", "reloaded");
        //   window.location.reload();
        // }
        this.loadData();
    };
    SeatComponent.prototype.ngOnDestroy = function () {
        localStorage.removeItem("pageState");
    };
    SeatComponent.prototype.refresh = function () {
        this.loadData();
    };
    SeatComponent.prototype.checkSeatPromotion = function () {
        var configPromo = this.config.config.promo;
        if (configPromo && configPromo.length) {
            var promoForSsrType = configPromo.find(function (p) { return p.ssrType === SsrType.Seat; });
            var now = moment();
            if (promoForSsrType && promoForSsrType.blueBenefits &&
                now.isSameOrAfter(moment.utc(promoForSsrType.fromDate, 'YYYY-MM-DD HH:mm')) &&
                now.isSameOrBefore(moment.utc(promoForSsrType.toDate, 'YYYY-MM-DD HH:mm'))) {
                return true;
            }
        }
    };
    SeatComponent.prototype.loadData = function () {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
        this.isPromotion = this.checkSeatPromotion() && this.isBlueBenefit;
        this.flowManager.applicationFlowService.loadPriceBreakdown();
        this.seatMapService.refreshSeatMap()
            .then(function (_) {
            _this.journeys = _this.seatMapService.journeys;
            _this.seatMap = _this.seatMapService.seatMap;
        })
            .then(function (_) {
            _this.seatMap.seatAssignment.journeys.forEach(function (j) {
                if (j.seatSegmentKeys.length > 1) {
                    _this.connectedJourney.push(_this.translateService.instant(j.seatSegmentKeys[0].departureStation, 'station.name')
                        + ' - ' + _this.translateService.instant(j.seatSegmentKeys[j.seatSegmentKeys.length - 1].arrivalStation, 'station.name'));
                }
            });
            _this.checkSelectedSeats();
            _this.remapSelectedSeats();
            _this.bookingFlowService.loadFlowInfo().then(function (booking) {
                _this.flights = booking.convertedJourneys.journeys;
            });
            setTimeout(function (_) {
                if (_this.seatMap.seatMaps.length > 0) {
                    _this.selectMap(_this.seatMap.seatMaps[0]);
                }
            }, 100);
        })
            .then(function (_) { return _this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId); });
    };
    SeatComponent.prototype.getAncillaryPrice = function () {
        var _this = this;
        var total = 0;
        this.seatMap.seatMaps.forEach(function (sm) {
            total += _this.getPriceForMap(sm);
        });
        return total;
    };
    SeatComponent.prototype.continue = function () {
        this.goToNextStep();
    };
    SeatComponent.prototype.getPriceForMaps = function (journey) {
        var _this = this;
        if (!journey.currentSeatSelection) {
            var total_1 = 0;
            journey.seatmaps.forEach(function (s) {
                total_1 += _this.getPriceForMap(s);
            });
            return total_1;
        }
        return journey.currentSeatSelection.reduce(function (a, b) { return a = a > b.price ? a : b.price; }, 0);
    };
    SeatComponent.prototype.getPriceForMap = function (map) {
        var sum = this.booking.assignedSeats.passengers.items
            .map(function (p) { return p.fees; }).reduce(function (a, b) { return a.concat(b); }, [])
            .map(function (p) { return p.items; }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (p) { return p.flightReference == map.flightKey; })
            .map(function (p) { return p.serviceCharges; })
            .map(function (p) { return p.items; }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (p) { return p.chargeType == "ServiceCharge" && p.chargeCode == "XLR"; })
            .map(function (p) { return p.amount; }).reduce(function (a, b) { return a + b; }, 0);
        var discount = this.booking.assignedSeats.passengers.items
            .map(function (p) { return p.fees; }).reduce(function (a, b) { return a.concat(b); }, [])
            .map(function (p) { return p.items; }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (p) { return p.flightReference == map.flightKey; })
            .map(function (p) { return p.serviceCharges; })
            .map(function (p) { return p.items; }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (p) { return p.chargeType == "Discount" && p.chargeCode == "XLR"; })
            .map(function (p) { return p.amount; }).reduce(function (a, b) { return a + b; }, 0);
        return sum - discount;
    };
    SeatComponent.prototype.getPaxForJourney = function (map) {
        var journey = this.seatMap.seatAssignment.journeys.find(function (p) { return p.sellKey == map.sellKey; });
        var pax = journey.seatSegmentKeys.find(function (p) { return p.flightKey == map.flightKey; }).passengers;
        this.flowManager.selectionService.passengersObs.subscribe(function (passengersByType) {
            if (passengersByType.length > 0) {
                pax.forEach(function (passenger) {
                    if (!passenger.first && !passenger.last) {
                        passenger.first = passengersByType.filter(function (pax) { return pax.passengerNumber === passenger.number; }).map(function (p) { return p.name.first + ' ' + p.name.last; });
                    }
                });
            }
        });
        return pax;
    };
    SeatComponent.prototype.selectMap = function (map) {
        var _this = this;
        this.currentMap = map;
        map.ariaHidden = !map.ariaHidden;
        map.displayMode = map.ariaHidden ? 'block' : "";
        map.isExpanded = !map.isExpanded;
        this.seatMap.seatMaps.filter(function (p) { return p.sellKey != map.sellKey; }).forEach(function (p) {
            p.ariaHidden = false;
            p.displayMode = '';
            p.isExpanded = false;
        });
        setTimeout(function () {
            var selector = 'button[data-key="' + map.flightKey + '"]';
            var e = _this.elRef.nativeElement.querySelector(selector);
            if (e) {
                e.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
        //select first pax
        this.selectedPax(this.passengers[0]);
    };
    SeatComponent.prototype.removeSeat = function (pax, map) {
        var journey = this.seatMap.journeys.find(function (p) { return p.seatmaps.some(function (s) { return s.flightKey == map.flightKey; }); });
        journey.removeSeat(pax.passengerNumber, map.flightKey);
        pax.unassigSeats = pax.unassigSeats || {};
        pax.unassigSeats[map.flightKey] = pax.assignedSeat[map.flightKey];
        delete pax.assignedSeat[map.flightKey];
        var unit = map.decks.find(function (p) { return p.number == pax.unassigSeats[map.flightKey].deck; }).compartments.find(function (p) { return p.compartmentDesignator == pax.unassigSeats[map.flightKey].compartment; }).units.find(function (p) { return p.unitDesignator == pax.unassigSeats[map.flightKey].unit; });
        this.filterClickNotifier.next(5);
        unit.decorator = unit.baseDecorator || unit.masterDecorator;
        unit.passengerNumber = "";
        var ecommerceCartItems = new Array();
        var item = new ECommerceCartItem('Seat', this.ecommerce.getFlightInfo(journey.journeyIndex) + pax.passengerNumber, unit.price, ECommerceService.BrandName, 'Seat', unit.unitDesignator, 1, null, this.passengers.find(function (p) { return p.passengerNumber === pax.passengerNumber; }).paxType, this.ecommerce.getUserData()).getItem(false);
        ecommerceCartItems.push(item);
        this.ecommerce.RemoveFromCart(ecommerceCartItems);
        this.selectedPax(pax);
        this.seatDeselectNotifier.next({ unitDesignator: unit.unitDesignator, passsenger: pax.passengerNumber });
        this.addToCart();
    };
    SeatComponent.prototype.selectedPax = function (pax) {
        // this.passengers.forEach(o=>o.selected = false);
        // pax.selected = true;
        var p = this.booking.assignedSeats.passengers.items.find(function (x) { return x.passengerNumber == pax.passengerNumber; });
        this.passengerNotifier.next(pax);
        this.selectedJourneyPassenger = pax;
        this.selectedPassenger = p;
        if (this.selectedPassenger.fees.items.findIndex(function (x) { return x.code === Constants.InfantSsrCode; }) !== -1) {
            this.selectedPassenger.hasInfant = true;
        }
    };
    SeatComponent.prototype.seatSelected = function (event) {
        var _this = this;
        var journey = this.seatMap.journeys.find(function (p) { return p.seatmaps.some(function (s) { return s.flightKey == event.flightKey; }); });
        var pax = this.passengers.find(function (p) { return p.passengerNumber == _this.selectedJourneyPassenger.passengerNumber; });
        journey.addSeat(event, pax);
        this.selectSeat = true;
        var ecommerceCartItems = [];
        var item = new ECommerceCartItem('Seat', this.ecommerce.getFlightInfo(journey.journeyIndex) + pax.passengerNumber, event.price, 'BlueAir', 'Seat', event.unitDesignator, 1, 1, null, this.ecommerce.getUserData()).getItem(true);
        ecommerceCartItems.push(item);
        this.ecommerce.ProductClicks(ecommerceCartItems);
        //select next pax
        if (this.booking.assignedSeats.passengers.items.length > 1) {
            var passengerIndex = this.passengers.findIndex(function (p) { return p.passengerNumber == _this.selectedPassenger.passengerNumber; });
            if (passengerIndex < this.passengers.length - 1) {
                this.selectedPax(this.passengers[passengerIndex + 1]);
            }
        }
        this.checkSelectedSeats();
    };
    SeatComponent.prototype.addToCart = function () {
        var _this = this;
        var deSelectedSeats = this.seatMap.journeys.map(function (p) { return p.currentSeatsToDelete; }).reduce(function (a, b) { return a.concat(b); }, [])
            .reduce(function (a, b) { return a.concat(b); }, [])
            .map(function (p) { return '' + p.passengerNumber + '|' + p.flightKey + '|' + p.deck + '|' + p.compartment + '|' + p.unit + '|xx'; });
        var selectedSegmentsSeats = this.passengers.map(function (p) { return p.assignedSeat; }).map(function (m) { return ({ flightKey: Object.keys(m), seat: m }); }).reduce(function (a, b) { return a.concat(b); }, [])
            .map(function (p) { return p.flightKey.map(function (f) { return ({
            flightKey: f,
            number: p.seat[f].passengerNumber,
            deck: p.seat[f].deck,
            compartment: p.seat[f].compartment,
            unit: p.seat[f].unit
        }); }); }).reduce(function (a, b) { return a.concat(b); }, [])
            .map(function (p) { return '' + p.number + '|' + p.flightKey + '|' + p.deck + '|' + p.compartment + '|' + p.unit; });
        this.selectSeat = false;
        this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
        var sell = [];
        if (deSelectedSeats.length > 0) {
            sell = sell.concat(deSelectedSeats);
        }
        if (selectedSegmentsSeats.length > 0) {
            sell = sell.concat(selectedSegmentsSeats);
        }
        this.seatMapService.sellSeat(sell).then(function () {
            _this.bookingService.refresh(true).then(function (data) {
                var ecommerceCartItems = new Array();
                _this.seatMap.journeys.forEach(function (j) {
                    j.currentSeatSelection.forEach(function (cs) {
                        var item = new ECommerceCartItem('Seat', _this.ecommerce.getFlightInfo(j.journeyIndex) + cs.passengerNumber, cs.price, 'BlueAir', 'Seat', cs.unit, 1, null, _this.passengers.find(function (pax) { return pax.passengerNumber === cs.passengerNumber; }).paxType, _this.ecommerce.getUserData()).getItem(false);
                        ecommerceCartItems.push(item);
                    });
                });
                _this.ecommerce.AddToCart(ecommerceCartItems);
                _this.seatMap.journeys.forEach(function (p) { p.currentSeatsToDelete = []; p.currentSeatSelection = []; });
                _this.booking = data;
                _this.journeys = _this.seatMapService.journeys;
                _this.seatMap = _this.seatMapService.seatMap;
                _this.remapSelectedSeats();
                _this.checkSelectedSeats();
                _this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
            });
        });
    };
    SeatComponent.prototype.remapSelectedSeats = function () {
        var _this = this;
        this.seatMap.seatMaps.forEach(function (m) {
            var selectedPassengerSeats = _this.seatMap.seatAssignment.journeys
                .map(function (p) { return p.seatSegmentKeys; }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (p) { return p.flightKey == m.flightKey; })
                .map(function (s) { return (s.passengers.map(function (p) { return ({ unit: p.assignedSeat, pax: p.number, flightKey: m.flightKey }); })); }).reduce(function (a, b) { return a.concat(b); }, []);
            var journeys = _this.seatMap.journeys;
            var currentJourney = _this.journeys.find(function (p) { return p.flightKey == m.flightKey; });
            selectedPassengerSeats.forEach(function (ps) {
                if (ps.unit.deck) {
                    var unit = currentJourney.decks.find(function (p) { return p.number == ps.unit.deck; }).compartments
                        .find(function (p) { return p.compartmentDesignator == ps.unit.compartment; }).units.find(function (u) { return u.unitDesignator == ps.unit.unit; });
                    journeys.find(function (p) { return p.seatmaps.some(function (s) { return s.flightKey == ps.flightKey; }); }).addSeat(unit, _this.passengers.find(function (p) { return p.passengerNumber == ps.pax; }));
                }
            });
        });
    };
    SeatComponent.prototype.checkSelectedSeats = function () {
        var _this = this;
        this.seatMap.seatMaps.forEach(function (m) {
            _this.selectedSeats[m.flightKey] = _this.seatMap.seatAssignment.journeys.map(function (p) { return p.seatSegmentKeys; }).reduce(function (a, b) { return a.concat(b); }, [])
                .filter(function (f) { return f.flightKey === m.flightKey; }).map(function (s) { return (s.passengers.map(function (p) { return (p.assignedSeat.unit); })); })
                .reduce(function (a, b) { return a.concat(b); }, []).filter(function (p) { return p; }).join(', ');
        });
    };
    SeatComponent.prototype.filterSelected = function (filter) {
        this.filterClickNotifier.next(filter);
    };
    SeatComponent.prototype.canDeactivate = function (currentRoute, currentState, nextState) {
        var nextStep = this.bookingSteps.extractStep(nextState);
        var redirectingToOtherBookingStep = true;
        if (nextStep === null) {
            redirectingToOtherBookingStep = false;
            nextStep = CheckinSteps.search;
        }
        if (this.clearSelectionsModal.shouldShowWarning(this.currentStep, nextStep)) {
            return this.clearSelectionsModal.showWarningAndNavigate(this.currentStep, redirectingToOtherBookingStep ? nextStep : null, nextState.url);
        }
        if (!this._canDeactivate) {
            this.goToNextStep(nextStep);
        }
        return this._canDeactivate;
    };
    SeatComponent.prototype.hasPendingChanges = function () {
        var _this = this;
        var ssrsOk = true;
        this.pendingSsrsList = [];
        if (this.selectSeat) {
            this.pendingSsrsList.push(SsrType.Seat);
        }
        if (this.pendingSsrsList.length) {
            ssrsOk = false;
        }
        this.seatMap.seatMaps.forEach(function (m) {
            var flight = _this.seatMap.journeys.find(function (x) { return x.flightKey === m.flightKey; });
            if (!_this.selectedSeats[m.flightKey] && !_this.isCanceledFlight(flight)) {
                ssrsOk = false;
            }
        });
        return ssrsOk;
    };
    SeatComponent.prototype.goToNextStep = function (nextStep) {
        var _this = this;
        this._canDeactivate = this.hasPendingChanges();
        if (!this._canDeactivate && this.checkChildPassengersSeat()) {
            this.noSeatSelectedChildsModal.openPopup(function (isOk) {
                _this._canDeactivate = isOk;
                if (isOk) {
                    if (nextStep !== null && nextStep !== undefined) {
                        _this.checkoutEcommerce();
                        _this.bookingSteps.goToStep(nextStep);
                    }
                    else {
                        _this.checkoutEcommerce();
                        _this.bookingSteps.goToNextStepFrom(_this.currentStep);
                    }
                }
            });
        }
        else if (!this._canDeactivate) {
            this.noSeatSelectedModal.openPopup(function (isOk) {
                _this._canDeactivate = isOk;
                if (isOk) {
                    if (nextStep !== null && nextStep !== undefined) {
                        _this.checkoutEcommerce();
                        _this.bookingSteps.goToStep(nextStep);
                    }
                    else {
                        _this.checkoutEcommerce();
                        _this.bookingSteps.goToNextStepFrom(_this.currentStep);
                    }
                }
            });
        }
        else {
            if (nextStep !== null && nextStep !== undefined) {
                this.checkoutEcommerce();
                this.bookingSteps.goToStep(nextStep);
            }
            else {
                this.checkoutEcommerce();
                this.bookingSteps.goToNextStepFrom(this.currentStep);
            }
        }
    };
    SeatComponent.prototype.checkoutEcommerce = function () {
        var _this = this;
        var ecommerceCartItems = [];
        this.flowManager.applicationFlowService.loadPriceBreakdown().then(function (breakdown) {
            if (breakdown) {
                ecommerceCartItems = _this.ecommerce.getAllCartDataForECommerce(breakdown);
                _this.ecommerce.Checkout(ecommerceCartItems, 3, 'Seats step');
            }
        });
    };
    SeatComponent.prototype.isCanceledFlight = function (flight) {
        var e_1, _a, e_2, _b, e_3, _c;
        for (var j = 0; j < this.seatMap.seatAssignment.journeys.length; j++) {
            if (flight && flight.journeyIndex == j) {
                try {
                    for (var _d = tslib_1.__values(this.flights), _e = _d.next(); !_e.done; _e = _d.next()) {
                        var f = _e.value;
                        if (this.seatMap.seatAssignment.journeys[j].sellKey == f.sellKey) {
                            try {
                                for (var _f = tslib_1.__values(f.segments), _g = _f.next(); !_g.done; _g = _f.next()) {
                                    var s = _g.value;
                                    try {
                                        for (var _h = tslib_1.__values(s.legs), _j = _h.next(); !_j.done; _j = _h.next()) {
                                            var l = _j.value;
                                            if (l.legInfo.status === "Canceled") {
                                                return true;
                                            }
                                        }
                                    }
                                    catch (e_3_1) { e_3 = { error: e_3_1 }; }
                                    finally {
                                        try {
                                            if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                                        }
                                        finally { if (e_3) throw e_3.error; }
                                    }
                                }
                            }
                            catch (e_2_1) { e_2 = { error: e_2_1 }; }
                            finally {
                                try {
                                    if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                                }
                                finally { if (e_2) throw e_2.error; }
                            }
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
        }
        return false;
    };
    SeatComponent.prototype.checkChildPassengersSeat = function () {
        return (this.passengers.filter(function (p) { return p.hasInfant || p.paxType == 'CHD'; })).length > 0;
    };
    return SeatComponent;
}());
export { SeatComponent };
