import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';
import { SsrType } from '../extras/ssr-type.enum';
import { BookingChanges, InsuranceItinerary } from './models/booking-changes-model';
import { Constants } from '../constants';
import { HttpClient } from '@angular/common/http';
var BookingChangesService = /** @class */ (function () {
    function BookingChangesService(http, configService) {
        this.http = http;
        this.configService = configService;
        this.ssrChanges = [];
        this.seatChanges = [];
        this.insuranceChanges = [];
        this.changesObs = new BehaviorSubject(false);
    }
    BookingChangesService.prototype.ngOnDestroy = function () {
    };
    BookingChangesService.prototype.getChanges = function () {
        return this.http.get(this.configService.SsrChanges).toPromise();
    };
    BookingChangesService.prototype.isSectionVisible = function (ssrType, journeys, passengers) {
        var allSegments = journeys.map(function (m) { return m.segments; }).reduce(function (a, b) { return a.concat(b); }, []);
        var checkSsr = function (list) {
            return allSegments.map(function (p) { return p.paxSSRs; }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (p) { return list.includes(p.sSRCode); }).length > 0;
        };
        switch (ssrType) {
            case SsrType.Seat:
                return allSegments.map(function (p) { return p.paxSeats; }).reduce(function (a, b) { return a.concat(b); }, []).length > 0;
            case SsrType.Insurance:
                return passengers.map(function (x) { return x.fees; }).map(function (x) { return x.items; }).reduce(function (a, b) { return a.concat(b); }, []).filter(function (x) { return x.code === Constants.InsuranceFeeCode; }).length > 0;
            default:
                var ssrList = this.getSsrTypeList(ssrType);
                return ssrList.length > 0 ? checkSsr(ssrList) : false;
        }
    };
    BookingChangesService.prototype.getSsrChanges = function (journeys, passengers, bookingSnapshot, fromItinerary) {
        var _this = this;
        var areDifferentStations = this.areDifferentStations(bookingSnapshot, journeys);
        this.getChanges().then(function (data) {
            if (data) {
                _this.ssrChanges = [];
                _this.seatChanges = [];
                _this.insuranceChanges = [];
                _this.flightChanges = data.bookingChanges.reviewYourCancelRebookChangesViewModel;
                _this.ssrCodes = data.bookingChanges.ssrCodesByType;
                _this.passengerChanges = data.bookingChanges.passengerChanges;
                var _loop_1 = function (ssrType) {
                    if (_this.isSectionVisible(ssrType, journeys, passengers)) {
                        var bookingChange_1 = new BookingChanges(SsrType[ssrType]);
                        switch (ssrType) {
                            case SsrType.Seat:
                                // to do : change copy method for array
                                var seatChangesCopy = JSON.parse(JSON.stringify(JSON.parse(data.bookingChanges.seatChanges)));
                                seatChangesCopy.forEach(function (seatChange, passengerNumber) {
                                    var passenger = passengers.find(function (p) { return p.passengerNumber === passengerNumber; });
                                    bookingChange_1.setSsrListForPassenger(passenger, seatChange);
                                });
                                _this.seatChanges.push(bookingChange_1);
                                break;
                            default:
                                // to do : change copy method for array
                                var ssrChangesCopy = JSON.parse(JSON.stringify(JSON.parse(data.bookingChanges.ssrChanges)));
                                ssrChangesCopy.forEach(function (ssrChangeSegments, passengerNumber) {
                                    var ssrTypeList = _this.getSsrTypeList(ssrType);
                                    if (ssrTypeList.length > 0) {
                                        if (areDifferentStations || fromItinerary) {
                                            ssrChangeSegments.forEach(function (ssrList, index) {
                                                var currentSsr = ssrList.filter(function (s) { return ssrTypeList.includes(s.code); });
                                                ssrChangeSegments[index] = currentSsr.length > 0 ? currentSsr : {
                                                    code: "",
                                                    newValue: "",
                                                    oldValue: "",
                                                    segment: ssrList[0].segment
                                                };
                                            });
                                        }
                                        else {
                                            ssrChangeSegments.forEach(function (ssrList, index) {
                                                var currentSsr = ssrList.filter(function (s) { return ssrTypeList.includes(s.code) && s.newValue !== s.oldValue; });
                                                ssrChangeSegments[index] = currentSsr.length > 0 ? currentSsr : {
                                                    code: "",
                                                    newValue: "",
                                                    oldValue: "",
                                                    segment: ssrList[0].segment
                                                };
                                            });
                                        }
                                        var passenger = passengers.find(function (p) { return p.passengerNumber === passengerNumber; });
                                        if (ssrChangeSegments.length !== ssrChangeSegments.filter(function (x) { return x && x.code === ''; }).length) {
                                            bookingChange_1.setSsrListForPassenger(passenger, ssrChangeSegments);
                                        }
                                    }
                                });
                                if (bookingChange_1 && bookingChange_1.passengerSsrsByType.length > 0) {
                                    _this.ssrChanges.push(bookingChange_1);
                                }
                                break;
                        }
                    }
                };
                for (var ssrType = 1; ssrType <= 15; ssrType++) {
                    _loop_1(ssrType);
                }
                if (_this.isSectionVisible(SsrType.Insurance, journeys, passengers)) {
                    passengers.forEach(function (passenger) {
                        var notes = passenger.fees.items.filter(function (fee) { return fee.code === Constants.InsuranceFeeCode; }).map(function (fee) { return fee.note; });
                        var snapshotNotes = bookingSnapshot ? bookingSnapshot.passengers[passenger.passengerNumber].passengerFees.filter(function (fee) { return fee.feeCode === Constants.InsuranceFeeCode; }).map(function (fee) { return fee.note; }) : [];
                        notes.forEach(function (note) {
                            var isInfantAsig = note.split('|')[0].trim() === Constants.InfantSsrCode;
                            var days = note.split('|')[note.split('|').length - 1].trim();
                            var isPaid = snapshotNotes.includes(note);
                            _this.insuranceChanges.push(new InsuranceItinerary(isInfantAsig ? passenger.infant : passenger, days + '-insurance', !isPaid));
                        });
                    });
                }
            }
            _this.changesObs.next(true);
        });
    };
    BookingChangesService.prototype.getSsrTypeList = function (ssrType) {
        if (this.ssrCodes) {
            var codes = this.ssrCodes.find(function (x) { return x.key === ssrType; });
            return codes ? codes.value : [];
        }
        return [];
    };
    BookingChangesService.prototype.areDifferentStations = function (bookingSnapshot, journeys) {
        var stationsDifference = false;
        if (bookingSnapshot && journeys.length > 0) {
            for (var i = 0; i < journeys.length; i++) {
                if (journeys[i].segments[0].departureStation !== bookingSnapshot.journeys[i].segments[0].departureStation ||
                    journeys[i].segments[journeys[i].segments.length - 1].arrivalStation !==
                        bookingSnapshot.journeys[i].segments[bookingSnapshot.journeys[i].segments.length - 1].arrivalStation) {
                    stationsDifference = true;
                }
            }
        }
        return stationsDifference;
    };
    return BookingChangesService;
}());
export { BookingChangesService };
