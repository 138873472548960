import { BehaviorSubject, Subscription, forkJoin, Observable } from 'rxjs';
import { skip, take } from 'rxjs/operators';

export class Helpers {
    public static Sum(propertyName?: string): (accumulator, currentValue, array?) => number {
        return (accumulator, currentValue, array?) => accumulator += (propertyName ? currentValue[propertyName] : currentValue);
    }

    public static Sum2<T>(propertyFn: (currentValue: T) => number): (accumulator, currentValue, array?) => number {
        return (accumulator, currentValue, array?) => accumulator += propertyFn(currentValue);
    }

    public static Sort2<T>(propertyFn: (currentValue: T) => number): (item1: T, item2: T) => -1 | 0 | 1 {
        return (item1, item2) => {
            const i1 = propertyFn(item1);
            const i2 = propertyFn(item2);
            return i1 < i2 ? -1 : (i1 === i2 ? 0 : 1);
        };
    }

    public static GetValueAsync<T>(input: IGetValue<T>): Promise<T> {
        // if not forced and already completed return current value
        if (!input.forced && input.subscription && input.subscription.closed) {
            return Promise.resolve(input.subject.value);
        }

        // if forced - cancel previous request if any
        if (input.forced && input.subscription) {
            input.subscription.unsubscribe();
        }

        // if forced or no previous requests wered attempted do the request
        if (input.forced || !input.subscription) {
            input.subscription = input.valueProvider().subscribe((response: T) => {
                input.subject.next(response);
            });
        }

        // return the next value the comes up in the subscription
        return new Promise<T>(resolve => input.subject.pipe(skip(1), take(1)).subscribe(data => resolve(data)));
    }
}

interface IGetValue<T> {
    subject: BehaviorSubject<T>;
    subscription: Subscription;
    valueProvider: () => Observable<T>;
    forced: boolean;
}



declare global {
    interface Array<T> {
        sum(propertySelector: (v: T) => number): number;
        sort2(propertySelector: (v: T) => number): Array<T>;
        groupBy<TKey extends number | string>(propertySelector: (v: T) => TKey): IGrouping<TKey, T>[];
        groupBy2(propertySelector: (v: T) => number | string): { [key: string]: T[] };
    }
}

if (!Array.prototype.sum) {
    Array.prototype.sum = function <T>(this: T[], propertySelector: (v: T) => number): number {
        return this.reduce(Helpers.Sum2<T>(propertySelector), 0);
    };
}

if (!Array.prototype.sort2) {
    Array.prototype.sort2 = function <T>(this: T[], propertySelector: (v: T) => number): Array<T> {
        return this.sort(Helpers.Sort2<T>(propertySelector));
    };
}


interface IGrouping<TKey extends string | number, T> {
    key: TKey;
    values: T[];
}
if (!Array.prototype.groupBy) {
    // tslint:disable-next-line: max-line-length
    Array.prototype.groupBy = function <T, TKey extends string | number>(this: T[], propertySelector: (v: T) => TKey): IGrouping<TKey, T>[] {
        return this.reduce((groupArray: IGrouping<TKey, T>[], item: T) => {
            const key = propertySelector(item);
            let grouping = groupArray.find(groupingItem => groupingItem.key === key);
            if (!grouping) {
                grouping = { key: key, values: [] };
                groupArray.push(grouping);
            }
            grouping.values.push(item);
            return groupArray;
        }, []);
    };
}

if (!Array.prototype.groupBy2) {
    Array.prototype.groupBy2 = function <T>(this: T[], propertySelector: (v: T) => number | string): { [key: string]: T[] } {
        return this.reduce((group, item) => {
            const key = propertySelector(item);
            (group[key] = group[key] || []).push(item);
            return group;
        }, {});
    };
}
