<div class="checkin-acc-item" aria-haspopup="true">
    <button type="button" class="checkin-acc-head" attr.aria-label="{{ariaLabel}}" [ngClass]="{'open' : isExpanded}" 
      (click)="toggleExpanded()" [disabled]="disabled">
          {{ title }}
          <span class="subtitle">{{ subtitle }}</span>
          <span class="price">{{ disabled ? disabledText : price}}</span>
          <span class="price-mobile" *ngIf="disabled">{{ disabledText }}</span>
      </button>
      <div class="checkin-acc-content" aria-hidden="true" [style.display]="isExpanded ? 'block': ''">
        <ng-content></ng-content>
      </div>
</div>