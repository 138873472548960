import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';

@Component({
  selector: 'show-errors',
  template: `
    <ng-container *ngIf="shouldShowErrors()">
      <span class="validation-error" *ngFor="let error of listOfErrors()">{{error}}</span>
    </ng-container>
  `,
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent {

  constructor(private translateService: TranslateService) {

  }

  private readonly errorMessages = {
    'required': () => this.translateField('Required field'),
    'minlength': (params) => 'The min number of characters is ' + params.requiredLength,
    'maxlength': (params) => 'The max allowed number of characters is ' + params.requiredLength,
    'pattern': (params) => 'The required pattern is: ' + params.requiredPattern,
    'years': (params) => params.message,
    'countryCity': (params) => params.message,
    'uniqueName': (params) => params.message,
    'telephoneNumbers': (params) => params.message,
    'telephoneNumber': (params) => params.message,
    'pecEmail': (params) => this.translateField('This is not a valid e-mail PEC address.'),
    'emailaddress': (params) => this.translateField('This is not a valid e-mail address.'),
    'VatOrFiscalCodeRequired': () => this.translateField('Fiscal Code or VAT are required.'),
    'RecipientCodeOreEmailRequired': () => this.translateField('Email PEC or Recipient Code are required.'),
    'fiscalPersonValidator_ItCompany': () => this.translateField('Must have 11 digits'),
    'fiscalCodeValidator_Individual': () => this.translateField('Must have 16 characters'),
    'headquarterZipCode': () => this.translateField('Must have 5 digits'),
    'alphaNumeric': () => this.translateField('Only alphanumeric characters are allowed.'),
    'alphaNumericFix': (params) => `${params.noDigits} ${this.translateField('digits are required for this field')}`,
  };

  @Input()
  private control: AbstractControlDirective | AbstractControl;

  @Input()
  private submitted: boolean;

  shouldShowErrors(): boolean {
    // console.log(JSON.stringify(this.control.errors));

    // return this.control && this.control.invalid;
    return this.control &&
      this.control.invalid &&
      (this.submitted || this.control.touched);
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors)
      .map(field => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, params: any) {
    try {
      return this.errorMessages[type](params);
    }
    catch (err) {
      console.error(`Error for type: ${type} with message: ${err.message}`);
      throw err;
    }
  }


  private translateField(value: string): string {
    return this.translateService.instant(value, 'invoicing');
  }

}
