import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { UserActivityService } from './user-activity.service';

@Injectable()
export class ErrorsHandlerService extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): void {
    const userActivityService = this.injector.get(UserActivityService);
    userActivityService.trackException(error);
    super.handleError(error);
  }
}
