/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./promo-code.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i5 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i6 from "./promo-code.component";
import * as i7 from "../../core/profile.service";
import * as i8 from "../../core/flow-manager.service";
import * as i9 from "../../core/config.service";
import * as i10 from "../../core/booking-steps.service";
import * as i11 from "../../booking-flight-select/booking-flight-select.service";
var styles_PromoCodeComponent = [i0.styles];
var RenderType_PromoCodeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PromoCodeComponent, data: {} });
export { RenderType_PromoCodeComponent as RenderType_PromoCodeComponent };
function View_PromoCodeComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit, "price-display")); _ck(_v, 1, 0, currVal_0); }); }
function View_PromoCodeComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "label", [["class", "danger"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoCodeComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessages; _ck(_v, 3, 0, currVal_0); }, null); }
function View_PromoCodeComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 18, "div", [["class", "field-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "label", [["for", "promocode"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 12, "div", [["class", "same-line"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 5, "input", [["id", "promocode"], ["name", "promocode"], ["type", "text"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.promoCode = $event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(9, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(11, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 3, "button", [["class", "btn btn-primary btn-big changeFlight_button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyPromo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵppd(16, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoCodeComponent_3)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "promocode"; var currVal_9 = _co.promoCode; _ck(_v, 9, 0, currVal_8, currVal_9); var currVal_11 = _co.invalidCode; _ck(_v, 18, 0, currVal_11); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), "Promocode Text", "price-display")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 11).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 11).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 11).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 11).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 11).ngClassValid; var currVal_6 = i1.ɵnov(_v, 11).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 11).ngClassPending; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = i1.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i1.ɵnov(_v.parent.parent, 0), "Apply", "price-display")); _ck(_v, 15, 0, currVal_10); }); }
function View_PromoCodeComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "msg"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), i1.ɵpad(3, 1), i1.ɵppd(4, 3)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), "{0} Applied", "price-display", _ck(_v, 3, 0, i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit, "promo-code"))))); _ck(_v, 1, 0, currVal_0); }); }
function View_PromoCodeComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "promocode-applied"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoCodeComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "cancel-promo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deletePromo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "font-ico-close-menu"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.promoCodes; _ck(_v, 2, 0, currVal_0); }, null); }
function View_PromoCodeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "cart-flight promocode"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "boxed": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoCodeComponent_2)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoCodeComponent_5)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "cart-flight promocode"; var currVal_1 = _ck(_v, 3, 0, _co.showMargins); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = !_co.promotionApplied; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.promotionApplied; _ck(_v, 7, 0, currVal_3); }, null); }
export function View_PromoCodeComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TranslatePipe, [i5.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PromoCodeComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showPromoCodeWidget; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PromoCodeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "booking-promo-code", [], null, null, null, View_PromoCodeComponent_0, RenderType_PromoCodeComponent)), i1.ɵdid(1, 245760, null, 0, i6.PromoCodeComponent, [i7.ProfileService, i8.FlowManagerService, i9.ConfigService, i10.BookingStepsService, i11.BookingFlightSelectService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromoCodeComponentNgFactory = i1.ɵccf("booking-promo-code", i6.PromoCodeComponent, View_PromoCodeComponent_Host_0, { showMargins: "showMargins" }, { promoCodeChanged: "promoCodeChanged" }, []);
export { PromoCodeComponentNgFactory as PromoCodeComponentNgFactory };
