import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from './translator/translate.service';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: Date, args?: Date, showIso?: boolean): string {
    const momentDuration = moment.duration(moment(args).diff(moment(value)));

    if (showIso) {
      return momentDuration.toISOString();
    }

    const numbers = [momentDuration.minutes(), momentDuration.hours(), momentDuration.days()];

    let output = '';

    for (let i = 0; i < numbers.length; i++) {
      if (numbers[i] === 0 && i > 0) {
        continue;
      }

      switch (i) {
        case 0:
          output = `${numbers[i]} ${this.translateService.instant('min', 'duration.pipe')}`;
          break;

        case 1:
          output = `${numbers[i]} ${this.translateService.instant('h', 'duration.pipe')} ${output}`;
          break;

        case 2:
        output = `${numbers[i]} ${this.translateService.instant('d', 'duration.pipe')} ${output}`;
          break;
      }
    }

    return output;
  }

}
