<div class="select-list" [ngClass]="{'expanded': expanded}" aria-haspopup="true">
  <span class="overlay" aria-hidden="true" style="display: inline" *ngIf="expanded" (click)="focusOut()">
  </span>

  <input type="text" class="selected" *ngIf="enableSearch"
    [ngClass]="{'not-active': !selectedItem, 'active': expanded}"
    [ngModel]="search" (ngModelChange)="searchChanged($event)"
    (click)="setExpanded(true)" (keydown.tab)="focusOut()" (focus)="setExpanded(true)"
    [disabled]="isDisabled"
    placeholder="{{placeholder}}"  autocomplete="off"/>


  <button type="button" class="selected" *ngIf="!enableSearch"
    [ngClass]="{'not-active': !selectedItem, 'active': expanded, 'disabled': isDisabled}" attr.aria-label="{{'Click to select nationality' | translate: 'blue-select-list'}}"
    (click)="setExpanded(true)" (keydown.tab)="focusOut()" (focus)="setExpanded(true)"
    [disabled]="isDisabled">
    {{ selectedItem ? selectedItem[textProperty] : placeholder }}
  </button>

  <div class="dest-dropdown js-dest-overlay" *ngIf="expanded" style="display: inline">
    <ul role="menubar" aria-hidden="true" attr.aria-label="{{'destination menu' | translate: 'blue-select-list'}}">
      <li role="menuitem" *ngFor="let item of filteredItems" (click)="selectItem($event, item)">
        <span class="primary">{{item[textProperty]}}</span>
      </li>
    </ul>
  </div>
  <span class="selected-icon" *ngIf="enableSearch" (click)="setExpanded(true)"><span class="sr-only">{{'Arrow Icon' | translate: 'blue-select-list'}}</span></span>
</div>