export class PaidSsrOverride {
    protected overrides: any = {
        'AZBG': {
            newType: 'SBAG',
            paidByPassengerNumber: {}
        }
    };

    public checkOverrides(ssrCode: string, ssrCodeOverride: string): boolean {
        return this.overrides.hasOwnProperty(ssrCodeOverride) &&
            this.overrides[ssrCodeOverride].newType === ssrCode;
    }

    public updateQuantitiesForPassenger(passengerNumber: number, passengerPaidSSRs: string[]): void {
        const groupBy = passengerPaidSSRs.reduce((group, ssrCode) => {
            if (!group[ssrCode]) {
                group[ssrCode] = 0;
            }
            group[ssrCode]++;
            return group;
        }, {});

        for (const ssr in this.overrides) {
            if (this.overrides.hasOwnProperty(ssr) && groupBy.hasOwnProperty(ssr)) {
                this.overrides[ssr].paidByPassengerNumber[passengerNumber] = groupBy[ssr];
            }
        }
    }

    public getPaidQuantity(passengerNumber: number, ssrCode: string): number {
        let result = 0;
        for (const ssr in this.overrides) {
            if (this.overrides.hasOwnProperty(ssr) && this.overrides[ssr].newType === ssrCode) {
                result += (this.overrides[ssr].paidByPassengerNumber[passengerNumber] || 0);
            }
        }

        return result;
    }

}
