import { Component, OnInit, forwardRef, Input, ViewChild, DoCheck } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgForm } from '@angular/forms';
import { ProfileService } from 'src/app/core/profile.service';
import { filter, take } from 'rxjs/operators';
import { BookingFlowService } from 'src/app/core/booking-flow.service';

@Component({
  selector: 'check-in-pax-contact-details',
  templateUrl: './pax-contact-details.component.html',
  styleUrls: ['./pax-contact-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaxContactDetailsComponent),
      multi: true
    }
  ]
})
export class PaxContactDetailsComponent implements OnInit, ControlValueAccessor, DoCheck {
  public phonePattern = '^[0-9+()\/-][0-9+() \/-]{0,19}?$';
  public namePattern = '^[A-Za-z _]*[A-Za-z][A-Za-z _]*$';
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';

  check: boolean;
  contact: any;
  agentCheck: boolean;
  isAgent: boolean;
  agent: any;
  journeys: Array<any> = [];
  recordLocators: any[] = [];
  isRestricted: boolean;
  isCanceledBookingStatus: boolean;
  isFieldRequired: boolean;

  @Input() isLockedFare: boolean;
  @Input() firstPaxName: any;
  @Input() titleItems: any[];
  @Input() countries: any[];
  @ViewChild('passengerDocsForm', { static: true }) form: NgForm;

  constructor(private profileService: ProfileService, private bookingFlowService: BookingFlowService) {
    if (this.profileService.isAgent) {
      this.isAgent = this.profileService.isAgent;
      this.profileService.profile.pipe(filter(p => p ? true : false), take(1)).subscribe(data => {
        this.agent = data;
      });
    }
    else {
      this.isAgent = false;
    }
  }

  ngOnInit() {
    this.bookingFlowService.loadFlowInfo().then(booking => {
      this.journeys = booking.convertedJourneys.journeys;
      this.isCanceledBookingStatus = this.checkBookingStatus();

      if (booking && booking.bookingDetail && booking.bookingDetail.recordLocators && booking.bookingDetail.recordLocators.length > 0) {
        this.recordLocators = booking.bookingDetail.recordLocators;
        this.isRestricted = this.isRestrictedBooking();
      }
    });
  }

  ngDoCheck() {
    if (this.check && this.firstPaxName &&
      (this.contact.name.title !== this.firstPaxName.title ||
        this.contact.name.first !== this.firstPaxName.first ||
        this.contact.name.last !== this.firstPaxName.last
      )) {
      this.firstPaxChanged();
    }
  }

  writeValue(obj: any): void {
    this.contact = obj || { name: {} };
    this.uncheckAgreeTermsAndCond(true);
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  firstPaxChanged() {
    if (this.check && this.firstPaxName) {
      this.agentCheck = false;
      this.contact.name = Object.assign({}, this.firstPaxName || {});
      this.contact.isFirstPassenger = true;
    } else {
      this.contact.isFirstPassenger = false;
    }
  }

  contactChanged() {
    this.check = false;
    this.agentCheck = false;
    this.contact.isFirstPassenger = false;
  }

  completeAgentData() {
    if (this.agentCheck) {
      this.check = false;
      if (this.agent) {
        const title = this.titleItems.find(i => i.value === this.agent.name.title);
        this.contact.name.title = title ? title.value : this.titleItems[0].value;
        this.contact.name.first = this.agent.name.first;
        this.contact.name.last = this.agent.name.last;
        this.contact.addressLine1 = this.agent.workAddress.addressLine1;
        this.contact.addressLine2 = this.agent.workAddress.addressLine2;
        this.contact.city = this.agent.workAddress.city;
        this.contact.countryCode = this.agent.workAddress.countryCode;
        this.contact.homePhone = this.agent.mobilePhoneNumber.number;
        this.contact.otherPhone = this.agent.workPhoneNumber.number;
        this.contact.postalCode = this.agent.workAddress.postalCode;
        this.contact.emailAddress = this.agent.workEmailAddress.emailAddress;
      }
    }
  }

  uncheckAgreeTermsAndCond(event: boolean) {
    this.contact.agreeTermsAndCond = event;
  }

  checkBookingStatus() {
    for (const f of this.journeys) {
      for (const s of f.segments) {
        for (const l of s.legs) {
          if (l.legInfo.status === "Canceled") {
            return true;
          }
        }
      }
    }

    return false;
  }

  isRestrictedBooking() {
    return (this.recordLocators.some(r => r.owningSystemCode !== null && r.owningSystemCode !== undefined)) ? true : false;
  }

  checkTermsAndCond(isCheckedTermsAndCond: any) {
    if (!isCheckedTermsAndCond.isDirty) {
      return;
    }
    if (!isCheckedTermsAndCond) {
      this.isFieldRequired = true;
      return;
    }

    this.isFieldRequired = false;
  }
}