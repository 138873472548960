/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lock-fare-select.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component.ngfactory";
import * as i4 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component";
import * as i5 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i6 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i7 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i8 from "../../shared/currency-display.pipe";
import * as i9 from "../../core/flow-manager.service";
import * as i10 from "./lock-fare-select.component";
import * as i11 from "../../core/config.service";
import * as i12 from "../booking-flight-select.service";
import * as i13 from "../../core/booking-flow.service";
var styles_LockFareSelectComponent = [i0.styles];
var RenderType_LockFareSelectComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LockFareSelectComponent, data: {} });
export { RenderType_LockFareSelectComponent as RenderType_LockFareSelectComponent };
function View_LockFareSelectComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", " "])), i1.ɵppd(2, 2), i1.ɵppd(3, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), "LockFare-AddButtonText", "lock-fare")); var currVal_1 = i1.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 1), _co.price)); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_LockFareSelectComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), "LockFare-RemoveButtonText", "lock-fare")); _ck(_v, 1, 0, currVal_0); }); }
function View_LockFareSelectComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [["class", "lockFare_infoText_toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleShowMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), (_co.showMore ? "show less" : "show more"), "lock-fare")); _ck(_v, 1, 0, currVal_0); }); }
function View_LockFareSelectComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "lockFare_infoText"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "lockFare_infoText_content"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LockFareSelectComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.enableShowMore; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.lockFareInfoText; _ck(_v, 1, 0, currVal_0); }); }
function View_LockFareSelectComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "blue-loading-spinner", [], null, null, null, i3.View_LoadingSpinnerComponent_0, i3.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 4964352, null, 0, i4.LoadingSpinnerComponent, [i5.LoadingSpinnerService, i1.ElementRef, i1.Renderer2], { activationRoute: [0, "activationRoute"] }, null), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["wrapper", 1]], null, 19, "div", [["class", "lockFareWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 9, "div", [["class", "lockFare_oneCol"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "lockFare_oneCol_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "lockFare_title"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "lockFare_subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 2), (_l()(), i1.ɵeld(12, 0, null, null, 1, "div", [["class", "lockFare_oneCol_img"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 8, "div", [["class", "lockFare_oneCol btn-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "btn-wrap-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "button", [["class", "btn btn-primary btn-lock"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleLockFare() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LockFareSelectComponent_2)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LockFareSelectComponent_3)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LockFareSelectComponent_4)), i1.ɵdid(22, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.lockFareRoute; _ck(_v, 2, 0, currVal_1); var currVal_5 = !_co.lockFare; _ck(_v, 18, 0, currVal_5); var currVal_6 = _co.lockFare; _ck(_v, 20, 0, currVal_6); var currVal_7 = _co.lockFare; _ck(_v, 22, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "lockFare ", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), "LockFare-TitleText", "lock-fare")); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), "LockFare-SubtitleText", "lock-fare")); _ck(_v, 10, 0, currVal_3); var currVal_4 = (_co.assetsPath + "svg/icon-lock-fare.svg"); _ck(_v, 13, 0, currVal_4); }); }
export function View_LockFareSelectComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.TranslatePipe, [i7.TranslateService]), i1.ɵpid(0, i8.CurrencyDisplayPipe, [i9.FlowManagerService, i7.TranslateService]), i1.ɵqud(671088640, 1, { wrapper: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LockFareSelectComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isAvailable; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_LockFareSelectComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lock-fare-select", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LockFareSelectComponent_0, RenderType_LockFareSelectComponent)), i1.ɵdid(1, 770048, null, 0, i10.LockFareSelectComponent, [i11.ConfigService, i7.TranslateService, i12.BookingFlightSelectService, i13.BookingFlowService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LockFareSelectComponentNgFactory = i1.ɵccf("app-lock-fare-select", i10.LockFareSelectComponent, View_LockFareSelectComponent_Host_0, { cssClass: "cssClass", lockFare: "lockFare" }, { lockFareChange: "lockFareChange" }, []);
export { LockFareSelectComponentNgFactory as LockFareSelectComponentNgFactory };
