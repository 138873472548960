import * as tslib_1 from "tslib";
import { Logger } from './logger';
var LoggerService = /** @class */ (function (_super) {
    tslib_1.__extends(LoggerService, _super);
    function LoggerService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoggerService.prototype.invokeConsoleMethod = function (type, args) { };
    return LoggerService;
}(Logger));
export { LoggerService };
