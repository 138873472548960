import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';
import { of as observableOf, Observable, BehaviorSubject, Observer } from 'rxjs';
import { tap, switchMap, map, share } from 'rxjs/operators';
import { PassengerJourneyBags, JourneyBags } from '../core/models/passenger-journey-bags';
import { ApplicationFlowService } from './application-flow.service';
import { CurrencyManagerService } from './currency-manager.service';
import { ICheckinValidation } from './checkin-validation/checkin-validation';
import { CheckinRestrictionModel, CheckinClosedReason } from './checkin-validation/checkin-restriction-model';
import { ICheckinValidationError, CheckinValidationErrorType } from './checkin-validation/checkin-validation-error';
import { FlightModel } from './models/flight-model';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { BookingService } from './booking.service';
import { BookingFlowService } from './booking-flow.service';

@Injectable()
export class CheckinService extends ApplicationFlowService {
  protected isCheckinFlow = true;

  passengerJourneyBags: PassengerJourneyBags[];
  checkInInput: any;
  checkinInfo: any;
  checkInInfoSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  public canContinue: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  boardingPassesObs: Observable<any>;
  private boardingPassOberserver: Observer<any>;
  private checkinRestrictionsObx: Observable<CheckinRestrictionModel[]>;
  passengers: Array<any> = [];

  constructor(http: HttpClient, configService: ConfigService, currencyManager: CurrencyManagerService,
    private translateService: TranslateService, private bookingService: BookingService, private bookingFlowService: BookingFlowService) {
    super(http, configService, currencyManager);
    this.boardingPassesObs = new Observable<any>(observer => this.boardingPassOberserver = observer);
  }

  loadFlowInfo(forced?: boolean): Promise<any> {
    this.bookingService.refresh(forced);
    return this.loadCheckInInfo();
  }

  loadCheckInInfo(loadNotEligibleJourneys: boolean = false): Promise<any> {
    let params: HttpParams = new HttpParams();
    if (loadNotEligibleJourneys) {
      params = params.set('starterCheckIn.loadCheckInNotEligibleJourneys', 'true');
    }
    return this.http.get(this.configService.CheckinInfo, { params: params })
      .toPromise()
      .then(data => {
        this.checkinInfo = data['starterCheckIn'];
        this.currency = this.checkinInfo['currencyCode'];
        this.updatePassengerJourneyBags();
        this.checkInInfoSub.next(this.checkinInfo);
        return this.checkinInfo;
      });
  }

  loadCheckInInput(): Promise<any> {
    return this.http.get(this.configService.CheckinInput)
      .toPromise()
      .then(data => {
        this.checkInInput = data['checkInInput'];
        return data;
      });
  }

  sendPassengerSelection(passengerJourneyBags: PassengerJourneyBags[]): Promise<any> {
    return this.http.post(this.configService.CheckinInput,
      { 'checkInInput': { 'passengerJourneyBags': passengerJourneyBags }, 'starterCheckIn': { 'passengerJourneyBags': passengerJourneyBags } })
      .toPromise();
  }

  private updatePassengerJourneyBags() {
    this.passengerJourneyBags = this.checkinInfo.passengerJourneyBags.map(pjb => {
      const model = new PassengerJourneyBags();
      model.passengerNumber = pjb.passengerNumber;
      model.journeyBags = pjb.journeyBags.map(jb => {
        const modelJourneyBag = new JourneyBags();
        modelJourneyBag.bags = jb.bags;
        modelJourneyBag.sellKey = jb.sellKey;
        return modelJourneyBag;
      });

      return model;
    });
  }

  finalizeCheckin(): Promise<any> {
    return this.http.post(this.configService.FinalizeCheckinUrl, {}).toPromise();
  }

  loadBoardingPasses(): void {
    const pjbs = this.passengerJourneyBags
      .reduce((a, b) => {
        a.push(...b.journeyBags.map(jb => ({ pn: b.passengerNumber, sk: jb.sellKey })));
        return a;
      }, []);
    let doneCounter = 0;
    pjbs.forEach(pjb => {
      const params = new HttpParams()
        .set('pn', pjb.pn + '')
        .set('sk', pjb.sk);
      this.loadBoardingPass(pjb.pn, pjb.sk)
        .then(boardingPasses => {
          if (boardingPasses && boardingPasses.length) {
            boardingPasses.forEach(bp => this.boardingPassOberserver.next(bp));
          }
          if (++doneCounter === pjbs.length) {
            this.boardingPassOberserver.complete();
          }
        });
    });
  }

  loadBoardingPass(requestedPassengerNumber: number = null, flightSellKey: string = null): Promise<any[]> {
    let params = new HttpParams();

    if (requestedPassengerNumber != null && flightSellKey != null) {
      params = new HttpParams()
        .set('pn', requestedPassengerNumber + '')
        .set('sk', flightSellKey);
    }

    this.bookingFlowService.loadFlowInfo().then(booking => {
      if (booking && booking.passengers && booking.passengers.items && booking.passengers.items.length > 0) {
        this.passengers = booking.passengers.items;
      }
    });

    return this.http.get(this.configService.BoardingPassUrl, { params: params })
      .toPromise()
      .then((bp: any) => {
        if (bp && bp.printBoardingPasses && bp.printBoardingPasses.items && bp.printBoardingPasses.items.length > 0) {
          const passengersDetails = bp.printBoardingPasses.passengersDetails.reduce((accumulator, passDetails) => {
            accumulator[passDetails.passengerNumber] = passDetails;
            return accumulator;
          }, {});

          return bp.printBoardingPasses.items.map((item, index) => {
            if (item) {
              return this.getPassengerTravelInformation(item);
            }
          });
        }

        return [];
      });
  }

  private getPassengerTravelInformation(item) {
    let pax = this._isInfant(item) ? this.passengers.find(p => p.infant.name.first === item.name.first) : this.passengers.find(p => p.name.first === item.name.first);

    if (!pax) {
      return;
    }

    if (pax.travelDocuments && pax.travelDocuments.items && pax.travelDocuments.items.length > 0) {
      item.documentType = pax.travelDocuments.items[0].docTypeCode;
      item.documentNumber = pax.travelDocuments.items[0].docNumber;
      item.documentExpirationDate = pax.travelDocuments.items[0].expirationDate;
      item.nationality = pax.travelDocuments.items[0].nationality;
    }
    else if (pax.info) {
      item.nationality = pax.info.nationality;
    }

    return item;
  }

  private _isInfant(boardingPassItem) {
    if (boardingPassItem.isoInfantName.first === boardingPassItem.isoName.first
      && boardingPassItem.isoInfantName.last === boardingPassItem.isoName.last) {
      this.clearPassengerSSRs(boardingPassItem);
      return true;
    }

    return false;
  }

  private clearPassengerSSRs(item) {
    item.sSR = [];

    item.segments.forEach(segment => {
      segment.legs.forEach(leg => {
        leg.ssrs = [];
      });
    });

    return item;
  }

  sendBoardingPassOnEmail(cultureCode: string, email: string, passengerNumber: number, sellKey: string): Promise<any> {
    const body: any = {
      email: email,
      culture: cultureCode
    };

    if (passengerNumber != null && sellKey != null) {
      body.pn = passengerNumber;
      body.sk = sellKey;
    }
    return this.http.post(this.configService.SendBoardingPassUrl, body).toPromise();
  }

  retrieveBooking(recordLocator: string, lastName: string, email: string): Observable<any> {
    const data = {
      id: recordLocator,
      ln: lastName || '',
      email: email || ''
    };
    return this.http.post(this.configService.CheckinRetrieveUrl, data);
  }

  retrieveCheckinRestrictions(): Observable<any> {
    return this.http.get(this.configService.CheckinRetrieveRestrictionsUrl);
  }

  getCheckinRestrictions(forced: boolean = false, flight: FlightModel = null): Observable<ICheckinValidationError[]> {
    if (forced || !this.checkinRestrictionsObx) {
      this.checkinRestrictionsObx = this.retrieveCheckinRestrictions().pipe(
        map(r => (r && r.checkinRestrictions && r.checkinRestrictions.items || [])
          .map((i: any) => new CheckinRestrictionModel().initFromRestriction(i))),
        tap(checkinRestrictions => this.checkinRestrictionsObx = observableOf(checkinRestrictions)),
        share(),);
    }

    return this.checkinRestrictionsObx.pipe(map((r: CheckinRestrictionModel[]) => this.validateCheckinRestrictions(r, flight)));
  }

  retrieveAndValidateBooking(recordLocator: string, retrieveMethodByLastName: boolean, lastName: string,
    emailAddress: string): Observable<ICheckinValidationError[]> {

    if (!recordLocator || retrieveMethodByLastName && !lastName || !retrieveMethodByLastName && !emailAddress) {
      return observableOf([{ errorType: CheckinValidationErrorType.mandatoryFields }]);
    }

    return this.retrieveBooking(recordLocator, lastName, emailAddress).pipe(
      map(r => r.checkinValidation as ICheckinValidation),
      switchMap(checkinValidation => {
        const errors = this.validateCheckinBooking(checkinValidation);
        if (errors.length) {
          return observableOf(errors);
        }

        return this.getCheckinRestrictions(true);
      }),);
  }

  validateCheckinBooking(checkinValidation: ICheckinValidation): ICheckinValidationError[] {
    const errors: ICheckinValidationError[] = [];
    if (!checkinValidation.canCheckIn && checkinValidation.groupRestriction) {
      errors.push({ errorType: CheckinValidationErrorType.checkinRestrictedForGroups });
    } else
      if (!checkinValidation.isValid) {
        errors.push({ errorType: CheckinValidationErrorType.bookingNotFound });
      }

    if (checkinValidation.checkInClosed) {
      errors.push({ errorType: CheckinValidationErrorType.checkinClosed });
    } else if (checkinValidation.checkInNotOpen) {
      errors.push({ errorType: CheckinValidationErrorType.checkinNotOpen });
    }

    if (!checkinValidation.checkInClosed && checkinValidation.hasPendingPayments) {
      errors.push({ errorType: CheckinValidationErrorType.pendingPayments });
    }

    return errors;
  }

  validateCheckinRestrictions(checkinRestrictions: CheckinRestrictionModel[] = [], flight: FlightModel = null) {
    const errors: ICheckinValidationError[] = [];

    // if we are not filtering by specific flight and there is at least one journey that can checkin ignore other errors
    if (!flight && checkinRestrictions.some(r => r.checkinOpened)) {
      return [];
    }

    checkinRestrictions.filter(r => !r.checkinOpened && (!flight || flight.sellKey === r.journeySellKey)).forEach(r => {
      r.routeName = this.translateService.instant('{0} to {1}', null, [
        this.translateService.instant(r.departureStation, 'station.name'),
        this.translateService.instant(r.arrivalStation, 'station.name')
      ]);

      let errorType: CheckinValidationErrorType;

      switch (r.checkinClosedReason) {
        case CheckinClosedReason.ClosedForBundle:
          if (r.upgradeBundle) {
            r.currentBundle = this.translateService.instant(r.currentBundle, 'Booking');
            r.upgradeBundle = this.translateService.instant(r.upgradeBundle, 'Booking');
            errorType = CheckinValidationErrorType.checkinNotOpenedUpgradeAvailable;
          } else {
            errorType = CheckinValidationErrorType.checkinNotOpenedNoUpgrade;
          }
          break;

        case CheckinClosedReason.ClosedForMarket:
          errorType = CheckinValidationErrorType.checkinClosedOnMarket;
          break;
        case CheckinClosedReason.ClosedForCodeshare:
          errorType = CheckinValidationErrorType.checkinClosedForCodeshare;
          break;
        default:
          return;
      }

      errors.push({
        errorType: errorType,
        errorData: r
      });
    });

    return errors;
  }

}
