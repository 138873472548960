/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./verify-payment.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "../../shared/form-wrapper/form-wrapper.component.ngfactory";
import * as i5 from "../../shared/form-wrapper/form-wrapper.component";
import * as i6 from "@angular/router";
import * as i7 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i8 from "../../core/booking-steps.service";
import * as i9 from "../../core/booking.service";
import * as i10 from "./verify-payment.component";
import * as i11 from "../payments.service";
import * as i12 from "../../core/checkin.service";
var styles_VerifyPaymentComponent = [i0.styles];
var RenderType_VerifyPaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VerifyPaymentComponent, data: {} });
export { RenderType_VerifyPaymentComponent as RenderType_VerifyPaymentComponent };
export function View_VerifyPaymentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 5, "check-in-form-wrapper", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_FormWrapperComponent_0, i4.RenderType_FormWrapperComponent)), i1.ɵdid(2, 770048, null, 0, i5.FormWrapperComponent, [i6.Router, i7.LoadingSpinnerService, i8.BookingStepsService, i9.BookingService], { ariaLabel: [0, "ariaLabel"], step: [1, "step"], showPriceDisplay: [2, "showPriceDisplay"] }, null), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, 5, 2, "div", [["class", "form-wrapper-top-body"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 2)], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), "Verifying payment", "verifypayment")), ""); var currVal_1 = "step-6"; var currVal_2 = false; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v, 0), "please-wait-you-are-being-redirected", "verifypayment")); _ck(_v, 5, 0, currVal_3); }); }
export function View_VerifyPaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-verify-payment", [], null, null, null, View_VerifyPaymentComponent_0, RenderType_VerifyPaymentComponent)), i1.ɵdid(1, 114688, null, 0, i10.VerifyPaymentComponent, [i11.PaymentsService, i8.BookingStepsService, i12.CheckinService, i7.LoadingSpinnerService, i8.BookingStepsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var VerifyPaymentComponentNgFactory = i1.ɵccf("check-in-verify-payment", i10.VerifyPaymentComponent, View_VerifyPaymentComponent_Host_0, {}, {}, []);
export { VerifyPaymentComponentNgFactory as VerifyPaymentComponentNgFactory };
