import { BookingChangesModule } from './booking-changes/booking-changes.module';
import { BookingStatusModule } from './booking-status/booking-status.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BookingSearchModule } from './booking-search/booking-search.module';
import { DocumentsModule } from './documents/documents.module';
import { ExtrasModule } from './extras/extras.module';
import { SeatModule } from './seat/seat.module';
import { BundleModule } from './booking-flight-select/bundle-select/bundle-select.module';
import { SummaryModule } from './summary/summary.module';
import { FlightsModule } from './flights/flights.module';
import { SharedModule } from './shared/shared.module';
import { BlueAirCommonModule } from './common-modules/blue-air-common/blue-air-common.module';
import { CoreModule } from './core/core.module';
import { BookingFlightSelectModule } from './booking-flight-select/booking-flight-select.module';
import { PassengersModule } from './passengers/passengers.module';
import { ItineraryModule } from './itinerary/itinerary.module';
import { LowFareCalendarModule } from './low-fare-calendar/low-fare-calendar.module';
import { SessionEmptyWarningComponent } from './shared/session-empty-warning/session-empty-warning.component';
import { MobileAppModule } from './mobile-app/mobile-app.module';
import { ProfileManagementModule } from './profile-management/profile-management.module';
import { WalletInfoService } from './core/wallet-info/wallet-info.service';
import { LuggageModule } from './luggage/luggage.module';
import { fromEventPattern } from 'rxjs';
import { BookingConsentModule } from './booking-consent/booking-consent.module';
import { BookingUnsubscribeNotificationsModule } from './booking-unsubscribe-notifications/booking-unsubscribe-notifications.module';
import { CorporateheaderModule } from './corporateheader/corporateheader.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CoreModule,
    BookingSearchModule,
    FlightsModule,
    DocumentsModule,
    LuggageModule,
    ExtrasModule,
    SeatModule,
    BundleModule,
    SummaryModule,
    BlueAirCommonModule,
    SharedModule,
    BookingFlightSelectModule,
    PassengersModule,
    ItineraryModule,
    LowFareCalendarModule,
    BookingStatusModule,
    BookingConsentModule,
    CorporateheaderModule,
    BookingUnsubscribeNotificationsModule,
    BookingChangesModule,
    MobileAppModule,
    ProfileManagementModule,
    RouterModule.forRoot([
      { path: 'no-session', component: SessionEmptyWarningComponent },
      { path: '**', redirectTo: 'no-session'}
    ])
  ],
  bootstrap: [AppComponent],
  providers: [WalletInfoService]
})
export class AppModule { }
