/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./agent-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "../../info-box/info-box.component.ngfactory";
import * as i5 from "../../info-box/info-box.component";
import * as i6 from "../../../shared/blue-modal2/blue-modal2.component.ngfactory";
import * as i7 from "../../../shared/blue-modal2/blue-modal2.component";
import * as i8 from "../../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i9 from "../../../common-modules/blue-air-common/loading-spinner/loading-spinner.component.ngfactory";
import * as i10 from "../../../common-modules/blue-air-common/loading-spinner/loading-spinner.component";
import * as i11 from "../../../common-modules/blue-air-common/loading-spinner.service";
import * as i12 from "../../reset-password-form/reset-password-form.component.ngfactory";
import * as i13 from "../../reset-password-form/reset-password-form.component";
import * as i14 from "../../info-box-actions/info-box-actions.component.ngfactory";
import * as i15 from "../../info-box-actions/info-box-actions.component";
import * as i16 from "./agent-settings.component";
import * as i17 from "../../../core/profile.service";
import * as i18 from "../../../core/config.service";
var styles_AgentSettingsComponent = [i0.styles];
var RenderType_AgentSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgentSettingsComponent, data: {} });
export { RenderType_AgentSettingsComponent as RenderType_AgentSettingsComponent };
export function View_AgentSettingsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), i1.ɵqud(402653184, 1, { editForm: 0 }), i1.ɵqud(402653184, 2, { changePasswordForm: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 34, "profile-info-box", [["class", "two-cols-mob"]], null, null, null, i4.View_InfoBoxComponent_0, i4.RenderType_InfoBoxComponent)), i1.ɵdid(4, 114688, null, 0, i5.InfoBoxComponent, [], { title: [0, "title"], class: [1, "class"], showEditBtn: [2, "showEditBtn"] }, null), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, 0, 12, "div", [["class", "border-row-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 5, "div", [["class", "left"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "blue-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["", ""])), i1.ɵppd(16, 2), (_l()(), i1.ɵeld(17, 0, null, null, 1, "span", [["class", "blue-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, 0, 7, "div", [["class", "row-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 6, "div", [["class", "right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(22, null, ["", ""])), i1.ɵppd(23, 2), (_l()(), i1.ɵeld(24, 0, null, null, 2, "a", [["class", "btn btn-secondary font-ico-two-arrows change-password"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPasswordModal($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(25, null, [" ", " "])), i1.ɵppd(26, 2), (_l()(), i1.ɵeld(27, 0, null, 0, 10, "blue-modal2", [], null, null, null, i6.View_BlueModal2Component_0, i6.RenderType_BlueModal2Component)), i1.ɵdid(28, 245760, null, 0, i7.BlueModal2Component, [i8.BlueModalService, i1.ElementRef, i1.Renderer2], { id: [0, "id"] }, null), (_l()(), i1.ɵeld(29, 0, null, 0, 2, "span", [["class", "popup-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(30, null, ["", ""])), i1.ɵppd(31, 2), (_l()(), i1.ɵeld(32, 0, null, 0, 1, "blue-loading-spinner", [], null, null, null, i9.View_LoadingSpinnerComponent_0, i9.RenderType_LoadingSpinnerComponent)), i1.ɵdid(33, 4964352, null, 0, i10.LoadingSpinnerComponent, [i11.LoadingSpinnerService, i1.ElementRef, i1.Renderer2], { activationRoute: [0, "activationRoute"] }, null), (_l()(), i1.ɵeld(34, 0, null, 0, 3, "profile-reset-password-form", [], null, [[null, "savePassword"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("savePassword" === en)) {
        var pd_0 = (_co.savePassword($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_ResetPasswordFormComponent_0, i12.RenderType_ResetPasswordFormComponent)), i1.ɵdid(35, 114688, [[2, 4], ["changePasswordForm", 4]], 0, i13.ResetPasswordFormComponent, [], { passwordChangePolicy: [0, "passwordChangePolicy"], passwordChangePolicyRegex: [1, "passwordChangePolicyRegex"], passwordChangeErrors: [2, "passwordChangeErrors"] }, { savePassword: "savePassword" }), (_l()(), i1.ɵeld(36, 0, null, 0, 1, "profile-info-box-actions", [], null, [[null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("cancel" === en)) {
        var pd_0 = (_co.cancelPassword() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_InfoBoxActionsComponent_0, i14.RenderType_InfoBoxActionsComponent)), i1.ɵdid(37, 114688, null, 0, i15.InfoBoxActionsComponent, [], null, { cancel: "cancel" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "Profile settings", "profile-info")), ""); var currVal_1 = "two-cols-mob"; var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_9 = _co.passwordChangeModalId; _ck(_v, 28, 0, currVal_9); var currVal_11 = _co.passwordChangeRoute; _ck(_v, 33, 0, currVal_11); var currVal_12 = _co.passwordChangePolicy; var currVal_13 = _co.passwordChangePolicyRegex; var currVal_14 = _co.passwordChangeErrors; _ck(_v, 35, 0, currVal_12, currVal_13, currVal_14); _ck(_v, 37, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 0), "Username", "profile-info")); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.profileSetings.username; _ck(_v, 12, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i1.ɵnov(_v, 0), "Organization code", "profile-info")); _ck(_v, 15, 0, currVal_5); var currVal_6 = _co.profileSetings.organizationCode; _ck(_v, 18, 0, currVal_6); var currVal_7 = i1.ɵunv(_v, 22, 0, _ck(_v, 23, 0, i1.ɵnov(_v, 0), "Password", "profile-info")); _ck(_v, 22, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 25, 0, _ck(_v, 26, 0, i1.ɵnov(_v, 0), "Change Password", "profile-info")); _ck(_v, 25, 0, currVal_8); var currVal_10 = i1.ɵunv(_v, 30, 0, _ck(_v, 31, 0, i1.ɵnov(_v, 0), "Change password", "profile-info")); _ck(_v, 30, 0, currVal_10); }); }
export function View_AgentSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-in-agent-settings", [], null, null, null, View_AgentSettingsComponent_0, RenderType_AgentSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i16.AgentSettingsComponent, [i8.BlueModalService, i17.ProfileService, i18.ConfigService, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgentSettingsComponentNgFactory = i1.ɵccf("profile-in-agent-settings", i16.AgentSettingsComponent, View_AgentSettingsComponent_Host_0, {}, {}, []);
export { AgentSettingsComponentNgFactory as AgentSettingsComponentNgFactory };
