<div class="checkin-pets-options" [(checkInSsrListDisplay)]="isSsrsPopupOpened">
  <check-in-ssr-passenger-picker [passengers]="passengers" [ssrType]="ssrType" [allPassengersVisible]="false"
    [allPassengersEnabled]="allPassengersEnabled" [selectedPassenger]="selectedPassenger"
    (selectPassenger)="selectPassenger($event)" (removeSsr)="removeAllSsrs($event)" (resetSsr)="resetSsr()">
  </check-in-ssr-passenger-picker>
  <div class="pets-list js-ssr-list">
    <div class="pets-list-inner">
      <button type="button" class="close-popup" aria-hidden="true">
        <span class="font-ico-close-menu">
          <span class="sr-only">{{ 'Click to close special assistance overlay' | translate:'extras' }}</span>
        </span>
      </button>
      <h2 aria-hidden="true" class="js-ssr-list-title">{{ 'Choose special assistance' | translate:'extras' }}</h2>
      <div *ngIf="mobileMatches">
        <div *ngIf="selectedPassenger == -1 else singlePassengerName">{{ 'All passengers' | translate: 'extras' }}</div>
        <ng-template #singlePassengerName>{{ getSelectedPassengerName() }}</ng-template>
      </div>
      <ng-container *ngFor="let ssr of availableSsrs">
        <div class="pet-item" *ngIf="ssr.isVisible">
          <div class="header-wrap-item">
            <div class="circle-wrap"><span class="font-ico-circle"></span><i
                class="{{ssrsCustomIcons[ssr.ssrCode]}}"></i></div>
            <div class="pet-item-left">
              <span class="pet-desc">{{ ssr.ssrCode + '-subtitle' | translate:'extras' }}</span>
              <h3>{{ ssr.ssrCode + '-title' | translate:'extras' }}</h3>
              <span class="pet-desc">{{ssr.price | currencyDisplay }} {{ 'each' | translate:'extras' }}</span>
            </div>
          </div>
          <div class="pet-item-right">
            <ng-container *ngIf="ssr.getLimitPerPassenger(getSeletedPassenger().passenger.passengerNumber) == 1">
              <ng-container *ngTemplateOutlet="singleItem; context:{ssr: ssr}"></ng-container>
            </ng-container>
            <ng-container *ngIf="ssr.getLimitPerPassenger(getSeletedPassenger().passenger.passengerNumber) > 1">
              <ng-container *ngTemplateOutlet="multipleItems; context:{ssr: ssr}"></ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
      <button type="button" class="btn btn-primary close-popup-bottom" aria-hidden="true">
        {{ 'Confirm and Continue' | translate:'extras' }}
      </button>
    </div>
  </div>

<!--
  <check-in-ssr-same-options-for-return-flight [visible]="enableSameOptionsForAllFlights"
    [(isSelected)]="sameOptionsForAllFlights"></check-in-ssr-same-options-for-return-flight>
-->

<div class="checkbox-wrap" *ngIf="enableSameOptionsForAllFlights">
  <input type="checkbox" id="return-flight-{{uniqueId}}" [ngModel]="sameOptionsForAllFlights" (click)="checkSameSsrOptionsForAllFlights()"/>
  <label for="return-flight-{{uniqueId}}">{{ 'I choose the same options for return flight' | translate:'extras' }}</label>
</div>

</div>

<ng-template #singleItem let-ssr="ssr">
  <button type="button" class="pet-number pet-number-single"
    [ngClass]="{ 'pet-number-selected' : ssr.selectedQuantity > 0 }"
    attr.aria-label="{{'Click to add special assistance' | translate: 'extras'}}"
    (click)="selectSsr(null, ssr.ssrCode, 1); selectSsrEvent(null)" [disabled]="ssr.paidQuantity == 1">
    <span class="pet-number-inner">
      <strong *ngIf="ssr.selectedQuantity == 0">{{ 'Add' | translate:'extras' }}</strong>
      <strong *ngIf="ssr.selectedQuantity > 0">{{ 'Added' | translate:'extras' }}</strong>
      <ng-container *ngIf="ssr.paidQuantity == 1"> {{'PAID' | translate:'extras' }}</ng-container>
    </span>
  </button>
</ng-template>

<ng-template #multipleItems let-ssr="ssr">
  <div class="pet-number-container">
    <button type="button" class="pet-number pet-number-zero"
      attr.aria-label="{{'Click to select 0 items' | translate: 'extras'}}"
      (click)="selectSsr(null, ssr.ssrCode, 0); selectSsrEvent(null)"
      [ngClass]="{'pet-number-selected': ssr.selectedQuantity == 0}" [disabled]="ssr.paidQuantity > 0">
      <span class="pet-number-inner">
        <strong>0</strong>
      </span>
    </button>
    <button type="button" class="pet-number" attr.aria-label="{{ 'Click to select {0} items' | translate:'extras':ix}}"
      *ngFor="let ix of ssr.getQuantityArray(getSeletedPassenger().passenger.passengerNumber)"
      (click)="selectSsr(null, ssr.ssrCode, ix); selectSsrEvent(null)"
      [ngClass]="{'pet-number-selected': ssr.selectedQuantity === ix}"
      [disabled]="(ix < ssr.paidQuantity) || (ssr.limitPerNest !== null && ix * numberOfSelectedPassengers > ssr.limitPerNest)">
      <span class="pet-number-inner">
        <strong>{{ix}}</strong>
        <ng-container *ngIf="ix <= ssr.paidQuantity">{{ 'PAID'| translate:'extras' }}</ng-container>
      </span>
    </button>
  </div>
</ng-template>