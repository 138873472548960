<ng-container *ngTemplateOutlet="!isInfant ? passengerDetails : infantDetails; context: {pax: pax}"></ng-container>
<ng-template #passengerDetails let-pax="pax">
    
                        <div class="pax-container" ngForm="passengerDocsForm" #passengerDocsForm="ngForm">
                                  <div class="pax-container-{{pax.passengerNumber}}">
                                  </div>
                              <div class="pax-container_row">
                                  <div class="pax-container_2-col">
                                      <div class="field-inner">
                                          <label for="title-{{pax.passengerNumber}}">{{ 'Title' | translate:'documents' }}</label>
                                          <blue-select-list
                                          [(ngModel)]="pax.name.title"
                                          (ngModelChange)="titleChanged($event, pax)"
                                          [items]="pax.typeInfo.paxType === 'ADT' ? adultTitleItems : titleItems"
                                          [disabled]="pax.typeInfo.paxType !== 'ADT'"
                                          [enableSearch]="false"  
                                          [required]="!isLockedFare"placeholder="{{ 'Select title' | translate:'documents' }}" [my-error]="title.dirty" name="title" #title="ngModel"></blue-select-list>  
                                        </div>
                                  </div>
                              </div>
                              <div class="pax-container_row">
                                  <div class="pax-container_2-col">
                                      <div class="field-inner">
                                          <label for="firstName-{{pax.passengerNumber}}">{{ 'First Name' | translate:'documents' }}</label>
                                          <input type="text" class="first-name" id="firstName-{{pax.passengerNumber}}"
                                          [(ngModel)]="pax.name.first" name="firstName" [my-error]="firstName.dirty" patternError="{{ 'Only letters' | translate:'documents' }}"  [required]="!isLockedFare"placeholder="{{ 'e.g. Ionescu' | translate:'documents' }}" pattern="{{namePattern}}" maxlength="32" #firstName="ngModel"
                                          (ngModelChange)="pax.passengerNumber === 0" />
                                      </div> 
                                  </div><div class="pax-container_2-col">
                                      <div class="field-inner">
                                          <label for="lastName-{{pax.passengerNumber}}">{{ 'Last Name' | translate:'documents' }}</label>
                                          <input type="text" class="last-name" id="last-{{pax.passengerNumber}}"
                                          [(ngModel)]="pax.name.last"
                                          (ngModelChange)="pax.passengerNumber === 0"
                                          name="lastName" [my-error]="lastName.dirty" patternError="{{ 'Only letters' | translate:'documents' }}" [required]="!isLockedFare"placeholder="{{ 'e.g. Ion' | translate:'documents' }}" pattern="{{namePattern}}" maxlength="32" #lastName="ngModel" />
                                  </div> 
                                  </div>
                              </div>
                              <div class="pax-container_row">
                                  <div class="pax-container_2-col">
                                      <div class="field-inner">
                                          <label for="gender-{{pax.passengerNumber}}">{{ 'Gender' | translate:'documents' }}</label>
                                          <blue-select-list (ngModelChange)="genderChanged($event, pax)" [(ngModel)]="pax.info.gender" [items]="genderItems" [enableSearch]="false" [my-error]="gender.dirty"  [required]="!isLockedFare"placeholder="{{ 'Select gender' | translate:'documents' }}" name="gender" #gender="ngModel"></blue-select-list>              
                                        </div>
                                  </div><div class="pax-container_2-col">
                                      <div class="field-inner">
                                          <label>{{ 'Nationality' | translate:'documents' }}</label>
                                          <blue-select-list [(ngModel)]="pax.info.nationality" [items]="countries" [enableSearch]="true"
                                              [my-error]="nationality.dirty" [required]="!isLockedFare"placeholder="{{ 'Select country' | translate:'documents' }}"
                                              name="issueCountryNationality" #nationality="ngModel"></blue-select-list>
                                      </div>
                                  </div>
                              </div>
                              <div class="pax-container_row">
                                  <div class="pax-container_2-col">
                                      <div class="field-inner" *ngIf="pax.typeInfo.paxType">
                                          <label for="birth-date-{{pax.passengerNumber}}">{{ 'Date Of Birth' | translate:'documents' }}</label>
                                          <blue-date-picker  name="dateOfBirth"
                                          [(ngModel)]="pax.typeInfo.dateOfBirth"
                                          [elementName]="'dateOfBirth'" 
                                          [dateRange]='dateRanges[pax.typeInfo.paxType]'
                                          [dateFormat]="dateFormat"
                                          [required]="!isLockedFare"
                                          [my-error]="dateOfBirth.dirty"
                                          #dateOfBirth="ngModel">
                                          </blue-date-picker>
                                      </div>
                                  </div>
                              </div>
                        </div>
</ng-template>

<ng-template #infantDetails let-pax="pax">
          <div class="pax-container" ngForm="passengerDocsForm" #passengerDocsForm="ngForm">
              <div class="pax-container-{{pax.passengerNumber}}">
              </div>
        
              <div class="pax-container_row">
                  <div class="pax-container_2-col">
                          <div class="field-inner">
                                  <label for="title-infant-{{pax.attachedPassengerNumber}}">{{ 'Title' | translate:'documents' }}</label>
                                              <blue-select-list [(ngModel)]="pax.name.title" [items]="titleItems"
                                              [disabled]="true"
                                              [enableSearch]="false"  
                                              [required]="!isLockedFare"placeholder="{{ 'Select title' | translate:'documents' }}" name="title"  [my-error]="titleInfant.dirty" #titleInfant="ngModel"></blue-select-list>      
                              </div>
                  </div>
              </div>
              <div class="pax-container_row">
                  <div class="pax-container_2-col">
                          <div class="field-inner">
                                  <label for="firstName-infant-{{pax.attachedPassengerNumber}}">{{ 'First Name' | translate:'documents' }}</label>
                                  <input type="text" class="first-name" id="firstName-infant-{{pax.attachedPassengerNumber}}"
                                  [(ngModel)]="pax.name.first" name="firstName-infant" [required]="!isLockedFare"placeholder="{{ 'e.g. Ionescu' | translate:'documents' }}" [my-error]="infantFirstName.dirty" patternError="{{ 'Only letters' | translate:'documents' }}" pattern="{{namePattern}}" #infantFirstName="ngModel" />
                              </div>
                  </div><div class="pax-container_2-col">
                          <div class="field-inner">
                                  <label for="lastname-infant-{{pax.attachedPassengerNumber}}">{{ 'Last Name' | translate:'documents' }}</label>
                                  <input type="text" class="first-name" id="lastname-infant-{{pax.attachedPassengerNumber}}"
                                  [(ngModel)]="pax.name.last" name="lastname-infant" [required]="!isLockedFare"placeholder="{{ 'e.g. Ion' | translate:'documents' }}" pattern="{{namePattern}}" [my-error]="infantLastName.dirty" patternError="{{ 'Only letters' | translate:'documents' }}" #infantLastName="ngModel"  />
                              </div>
                  </div>
              </div>
              <div class="pax-container_row">
                  <div class="pax-container_2-col">
                          <div class="field-inner">
                                  <label for="dob-infant-{{pax.attachedPassengerNumber}}">{{ 'Date Of Birth' | translate:'documents' }}</label>
                                  <blue-date-picker  name="dob-infant"
                                  [(ngModel)]="pax.dateOfBirth"
                                  [elementName]="'dob-infant'" 
                                  [dateRange]='dateRanges.INF'
                                  [dateFormat]="dateFormat"
                                  [my-error]="birthdateInfant.dirty" #birthdateInfant="ngModel"
                                  [required]="!isLockedFare"
                                  ></blue-date-picker>
                              </div>
                  </div><div class="pax-container_2-col">
                          <div class="field-inner">
                                  <label>{{ 'Nationality' | translate:'documents' }}</label>
                                  <blue-select-list [(ngModel)]="pax.nationality" [items]="countries" [enableSearch]="true" [required]="!isLockedFare" placeholder="{{ 'Select country' | translate:'documents' }}" name="issueNationality"></blue-select-list>     
                              </div>
                  </div>
              </div>     
          </div>
</ng-template>
