import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'profile-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {
  @Input() title: string;
  @Input() class: string;

  @Input() spinnerId: string;
  @Input() spinnerRoute = '';
  @Input() showSpinner: boolean;

  @Input() showShadow = true;

  @Input() showEditBtn = true;
  @Input() showAddBtn = false;
  @Output() addClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() editClick: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  onEditClick(event) {
    // event.preventDefault();
    this.editClick.next(event);
  }

  onAddClick(event) {
    this.addClick.next(event);
  }

}
