import { Component, OnInit, ViewChild, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { PaymentsService } from '../payments.service';
import { PaymentMethodModel } from '../payment-method-model';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { PaymentRequestType, PaymentRequestDetails } from '../payment-request-details';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { SessionService } from 'src/app/common-modules/blue-air-common/session.service';
import { Location } from '@angular/common';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { Constants } from 'src/app/constants';
import { ShoppingCartBreakdownModel } from 'src/app/core/models/shopping-cart-models';
import { EnvHelper } from 'src/app/env-helper';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';
import { InvoicingComponent } from '../invoicing/invoicing.component';
import { InvoicingData } from '../invoicing/models/invoicing.resources';
import { AeroInvoicingComponent } from '../invoicing-aero/invoicing-aero.component';
import { AeroInvoicingData } from '../invoicing-aero/models/invoicing-aero.resources';

@Component({
  selector: 'check-in-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit, OnDestroy {
  private currentStep: CheckinSteps = CheckinSteps.summary;
  redirectToPaymentPortal: boolean;

  paymentMethods: PaymentMethodModel[];
  paymentMethodGroups: PaymentMethodGroup[];
  paymentFailed: boolean;
  paymentError: string;

  selectedPaymentMethod: PaymentMethodModel;

  submitted: boolean;
  invoicingStateInfo: InvoicingStateInfo = new InvoicingStateInfo();
  isTermsAccepted: boolean;
  isPaymentMethodSelected: boolean;
  paymentRequestDetails: PaymentRequestDetails;
  assetsPath: string;
  bookingFlow: string;

  isWalletPaymentSelected: boolean;
  balanceDue: number;
  walletPayableAmount: number;
  walletPaymentMethod: PaymentMethodModel;

  isOnAgencyPortal = EnvHelper.IsOnAgencyPortal();

  @ViewChild('agencyPaymentModal', { static: false }) agencyPaymentModal: DefaultModalComponent;
  @ViewChild(AeroInvoicingComponent, { static: false }) invoicingComponent: AeroInvoicingComponent;

  get balanceDueAfterWalletPayment() {
    return this.balanceDue - this.walletPayableAmount;
  }

  private unsubscribeSubject: Subject<boolean> = new Subject();

  constructor(private steps: BookingStepsService,
    private paymentsService: PaymentsService, private translateService: TranslateService, private render: Renderer2,
    private route: ActivatedRoute, private router: Router, private sessionService: SessionService, private location: Location,
    private flowManager: FlowManagerService, private spinnerService: LoadingSpinnerService) {
    this.steps.currentStep.next(this.currentStep);
    this.assetsPath = environment.assetsPath;

    this.route.queryParams.pipe(take(1)).subscribe(params => {
      this.paymentFailed = params['error'] === 'true';
      if (this.paymentFailed) {
        this.router.navigate([], { replaceUrl: true });
      }
    });

    this.route.paramMap.pipe(take(1)).subscribe((params: ParamMap) => {
      this.bookingFlow = params.get('flow');
    });

    this.flowManager.applicationFlowService.loadPriceBreakdown(true).then(breakdown => {
      if (breakdown) {
        sessionStorage.setItem(Constants.CartBreakdown, JSON.stringify(breakdown));

        if ((<ShoppingCartBreakdownModel>breakdown).currentShoppingCart.balanceDue > 0) {
          this.loadPaymentMethods();
        } else {
          this.router.navigateByUrl('/verifypayment/ok');
        }
      }
    });

    this.paymentError = this.paymentsService.paymentError;
    this.invoicingStateInfo.isEnabled = !this.isOnAgencyPortal;

    // DEBUG invoice only - do not let this here at the end of implementation
    this.invoicingStateInfo.isAvailable = true;
    // this.invoicingStateInfo.isRequested = true;
    // this.invoicingStateInfo.isDisplayingEditor = true;
  }

  isInvoicingAvailableForPayment(paymentGroup: PaymentMethodGroup): boolean {
    return this.invoicingStateInfo.isEnabled
      && this.invoicingStateInfo.isAvailable
      && paymentGroup.name.toLowerCase().indexOf('card') != -1;
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.unsubscribeSubject.next(true);
  }

  skipInvoicing() {
    this.invoicingStateInfo.isRequested = false;
    this.goToNextStep();
  }

  goToNextStep() {
    this.submitted = false;

    if (this.isWalletPaymentSelected && this.balanceDueAfterWalletPayment === 0) {
      this.setupPaymentAndRedirect(null, this.walletPayableAmount);
      return;
    }

    let selectedPaymentMethod = null;
    const selectedGroup = this.paymentMethodGroups.find(g => g.isSelected);

    if (selectedGroup) {
      selectedPaymentMethod =
        selectedGroup.items.length === 1 ?
          selectedGroup.items[0] :
          selectedGroup.items.find(i => i.isSelected);
    }

    this.submitted = true;
    this.isTermsAccepted = selectedGroup && selectedGroup.isTermsAccepted;
    this.isPaymentMethodSelected = selectedPaymentMethod ? true : false;

    if (this.isTermsAccepted && this.isPaymentMethodSelected) {
      if (selectedGroup.name === 'Agency' && this.agencyPaymentModal) {
        this.agencyPaymentModal.openPopup((isOk) => {
          if (isOk) {
            this.setupPaymentAndRedirect(selectedPaymentMethod.code, this.isWalletPaymentSelected ? this.walletPayableAmount : 0);
          }
        });
      } else {
        this.setupPaymentAndRedirect(selectedPaymentMethod.code, this.isWalletPaymentSelected ? this.walletPayableAmount : 0);
      }
    }
  }

  public promoCodeChanged(promoCode: string) {
    this.loadPaymentMethods();
  }

  private loadPaymentMethods() {
    this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
    this.paymentsService.loadPaymentMethods(this.bookingFlow).pipe(takeUntil(this.unsubscribeSubject)).subscribe(response => {
      this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);

      this.invoicingStateInfo.isEnabled = response.invoicingEnabled;
      this.invoicingStateInfo.isRequested = response.invoicingRequested;

      this.paymentMethods = response.paymentMethods;

      const groupBy = this.paymentMethods.reduce((group: PaymentMethodGroups, item) => {
        if (item.code === 'WT') {
          if (item.details && item.details.available > 0) {
            this.walletPaymentMethod = item;
            this.flowManager.applicationFlowService.shoppingCartBreakdown.pipe(take(1)).subscribe(pb => {
              this.walletPayableAmount = Math.min(
                pb.currentShoppingCart.balanceDue, this.walletPaymentMethod.details.availableInBookingCurrency / 100
              );
              this.balanceDue = pb.currentShoppingCart.balanceDue;
            });
          }

          // stop here do not add wallet to all payments
          return group;
        }

        group[item.group] = group[item.group] || {
          name: item.group, key: item.group.replace(' ', '-'), fee: 0, items: [],
          description: this.translateService.instant(item.group + ' - description', 'summary'),
          isSelected: false, isTermsAccepted: false, isDisabled: false
        };
        group[item.group].items.push(item);
        return group;
      }, {});

      this.paymentMethodGroups = [];
      for (let group in groupBy) {
        if (groupBy.hasOwnProperty(group)) {
          this.paymentMethodGroups.push(groupBy[group]);
        }
      }

      //reorder Payment Methods
      if (this.paymentMethodGroups.filter(pm => pm.name.match('Card'))[0]) {
        let cardsGroup = this.paymentMethodGroups.filter(pm => pm.name.match('Card'))[0];

        let visaIndex = cardsGroup.items.findIndex(p => p.name.match('VISA') != null);
        let masterIndex = cardsGroup.items.findIndex(p => p.name.match('Master') != null);

        let visa = cardsGroup.items[visaIndex];
        let master = cardsGroup.items[masterIndex];

        cardsGroup.items[visaIndex] = master;
        cardsGroup.items[masterIndex] = visa;
      }

      this.selectGroup("Credit-Card");
    });


  }

  private redirectToPayment(
    selectedPaymentCode: string,
    walletPaymentAmount: number,
    invoiceData: AeroInvoicingData = null): void {
    const flowInsert = this.bookingFlow ? `/${this.bookingFlow}` : '';
    const successUrl = `/verifypayment${flowInsert}/ok`;
    const errorUrl = `/verifypayment${flowInsert}/error`;

    this.spinnerService.showSpinnerById(Constants.mainSpinnerId);

    this.paymentsService.selectPaymentMethod(
      selectedPaymentCode,
      walletPaymentAmount,
      this.prepareExternalUrl(successUrl),
      this.prepareExternalUrl(`/summary${flowInsert}`),
      this.prepareExternalUrl(errorUrl),
      invoiceData
    ).subscribe(response => {
      if (response.isSuccessful) {
        if (response.redirectUrl) {
          window.location.href = response.redirectUrl;
        } else {
          this.router.navigateByUrl(successUrl);
        }
      } else {
        this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
        this.paymentError = response.errorCode ? this.translateService.instant(response.errorCode, 'payment-error') : response.errorMessage;
        this.paymentFailed = true;
      }
    }, () => {
      this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
      this.paymentError = this.translateService.instant('Something went wrong with your payment please try again!', 'payment-error');
      this.paymentFailed = true;
    });
  }

  private setupPaymentAndRedirect(selectedPaymentCode: string, walletPaymentAmount: number): void {
    if (this.invoicingStateInfo.shouldDisplayInvoicingFields()) {
      this.invoicingStateInfo.isDisplayingEditor = true;
      return;
    }

    if (this.invoicingStateInfo.shouldApplyInvoicingFields()) {
      this.invoicingComponent.validateAndRetrieveInvoiceData()
        .pipe(
          takeUntil(this.unsubscribeSubject)
        )
        .subscribe(result => {
          if (result.formValid && result.dataValid) {
            this.redirectToPayment(selectedPaymentCode, walletPaymentAmount, result.invoiceData);
          }
        });
    } else {
      this.redirectToPayment(selectedPaymentCode, walletPaymentAmount);
    }
  }

  selectGroup(groupKey) {
    let selectedGroup = this.paymentMethodGroups.find(g => g.isSelected);
    if (selectedGroup && selectedGroup.key === groupKey) {
      this.updateGroupSelection(selectedGroup, false);
    } else {
      this.paymentMethodGroups.forEach(g => {
        this.updateGroupSelection(g, g.key === groupKey);
      });
      this.selectFirstPaymentMethod();
    }

    this.updateInvoicingInfoByPaymentGroup(groupKey);
  }

  private updateGroupSelection(group: PaymentMethodGroup, isSelected: boolean) {
    if (group.isSelected !== isSelected) {
      this.selectedPaymentMethod = null;
    }
    group.isSelected = isSelected;

    if (!group.isSelected) {
      group.isTermsAccepted = false;
      group.items.forEach(i => i.isSelected = false);
    }
  }

  private updateInvoicingInfoByPaymentGroup(groupKey: string): void {
    switch (groupKey) {
      case 'Cash-Deposit':
      case 'Offline-Bank Transfer':
        this.invoicingStateInfo.isAvailable = false;
        break;
      default:
        this.invoicingStateInfo.isAvailable = true;
    }
  }

  acceptGroup(groupKey) {
    const selectedGroup = this.paymentMethodGroups.find(g => g.isSelected);
    selectedGroup.isTermsAccepted = !selectedGroup.isTermsAccepted;
  }

  selectFirstPaymentMethod() {
    const selectedGroup = this.paymentMethodGroups.find(g => g.isSelected);
    if (selectedGroup.items.length == 0) return;
    const firstMethod = selectedGroup.items[0];

    firstMethod.isSelected = true;
    this.selectedPaymentMethod = firstMethod;
  }

  selectPaymentMethod(groupKey, paymentCode) {
    const selectedGroup = this.paymentMethodGroups.find(g => g.isSelected);
    selectedGroup.items.forEach(i => {
      i.isSelected = i.code === paymentCode;
      if (i.isSelected) {
        this.selectedPaymentMethod = i;
      }
    });
  }

  walletPaymentSelectedChanged() {
    this.paymentMethodGroups.forEach(pm => {
      pm.isDisabled = this.isWalletPaymentSelected && (this.balanceDueAfterWalletPayment === 0 || pm.name !== 'Credit Card');
      pm.isTermsAccepted = false;
      pm.isSelected = false;
      pm.items.forEach(i => i.isSelected = false);
    });
  }

  private prepareExternalUrl(path: string): string {
    const origin = window.location.origin;
    const sessionUrlPart = `/session/${this.sessionService.sessionId}?path=`;
    const url = `${sessionUrlPart}${path}`;

    return origin + this.location.prepareExternalUrl(url);
  }
}

export interface PaymentMethodGroups { [group: string]: PaymentMethodGroup; }
export interface PaymentMethodGroup {
  name: string;
  key: string;
  fee: number;
  items: PaymentMethodModel[];
  description: string;
  isDisabled: boolean;
  isSelected: boolean;
  isTermsAccepted: boolean;
}

export class InvoicingStateInfo {
  isEnabled: boolean;
  isAvailable: boolean;
  isRequested: boolean;
  isDisplayingEditor: boolean;

  public shouldDisplayInvoicingFields(): boolean {
    return this.isEnabled && this.isAvailable && this.isRequested && !this.isDisplayingEditor;
  }

  public shouldApplyInvoicingFields(): boolean {
    return this.isEnabled && this.isAvailable && this.isRequested && this.isDisplayingEditor;
  }
}
