import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BlueAirCommonModule } from "../common-modules/blue-air-common/blue-air-common.module";
import { SharedModule } from "../shared/shared.module";
import { BookingUnsubscribeNotificationsComponent } from "./booking-unsubscribe-notifications.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    BlueAirCommonModule,
  ],
  declarations: [
    BookingUnsubscribeNotificationsComponent
  ],
  providers: []
})

export class BookingUnsubscribeNotificationsModule { }
