export class Insurance {
    days: number;
    insideEU: boolean;
    journeyType: string; // 'RoundTrip' or 'OneWay'
    nationality: string;
    passengerType: string; // 'INFT' or '' (not infant)
    price: number;
    showWithOthers: boolean;
    isActive: boolean;

    constructor(insurance) {
        this.days = insurance.days;
        this.insideEU = insurance.insideEu;
        this.journeyType = this.capitalize(insurance.journeyType);
        this.nationality = insurance.nationality;
        this.passengerType = insurance.passengerType;
        this.price = insurance.price;
        this.showWithOthers = insurance.showWithOthers;
        this.isActive = insurance.isActive;
    }

    private capitalize(str: string) {
        return str[0].toUpperCase() + str.slice(1);
    }
}

export class PaxInsurance {
    isPaid: boolean = false;
    isInfant: boolean;
    passengerNumber: number;
    selectedInsurance: Insurance;

    clone() {
        const newObject: PaxInsurance = new PaxInsurance();
        newObject.isPaid = this.isPaid;
        newObject.isInfant = this.isInfant;
        newObject.passengerNumber = this.passengerNumber;
        newObject.selectedInsurance = new Insurance(this.selectedInsurance);

        return newObject;
    }
  }