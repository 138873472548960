<form action="" class="checkin-wrapper" [attr.aria-label]="ariaLabel" #myForm="ngForm">
    <blue-loading-spinner [id]="navigationSpinnerId" class="main-spinner"></blue-loading-spinner>
    <session-warning></session-warning>
    <ng-container *ngIf="!isBookingFlow && step === 'step-2' then step2Template else genericTemplate">
    </ng-container>

    <div class="footer-promotion-wrap" *ngIf="showContainer">
        <ng-container *ngTemplateOutlet="termsAndCondTemplate"></ng-container>
        <ng-container *ngTemplateOutlet="promoTemplate"></ng-container>
    </div>
</form>

<ng-template #genericTemplate>
    <div class="wrapper">
        <div class="{{step}}">
            <ng-content select="alerts"></ng-content>
            <div class="checkin-header">
                <h2>
                    <ng-content select=".form-wrapper-title"> </ng-content>
                </h2>
                <ng-content select=".form-wrapper-header"></ng-content>
            </div>
            <aside class="checkin-sidebar">
                <ng-content select=".form-wrapper-sidebar"></ng-content>
                <shopping-cart *ngIf="showPriceDisplay" (continue)="continue.next()">
                    <ng-container *ngTemplateOutlet="termsAndCondTemplate" ngProjectAs="termsAndConditions">
                    </ng-container>
                    <ng-container *ngTemplateOutlet="promoTemplate" ngProjectAs="promo"></ng-container>
                </shopping-cart>
                <ng-content select=".form-wrapper-sidebar-after"></ng-content>
            </aside>
            <div class="checkin-content">
                <ng-content select=".form-wrapper-top-body"></ng-content>
                <ng-content select=".form-wrapper-middle-body"></ng-content>
                <ng-content select=".form-wrapper-bottom-body"></ng-content>

                <button type="submit" class="btn btn-primary mobile-submit"
                    [attr.class]="canContinue?'btn btn-primary mobile-submit':'btn btn-primary mobile-submit disabled'"
                    attr.aria-label="{{ 'Click to confirm and continue' | translate }}" (click)="continue.next()"
                    *ngIf="showPriceDisplay && showMobileContinueBtn" [disabled]="!canContinue">
                    {{ 'Continue' | translate:'price-display' }}
                </button>
            </div>
            <aside class="checkin-sidebar" *ngIf="showContinue">
                <button type="button" class="btn btn-primary"
                    attr.aria-label="{{ 'Click to select and continue' | translate }}" (click)="continue.next()">
                    {{ 'Select and Continue' | translate:'layout' }}
                </button>
            </aside>
        </div>
    </div>
</ng-template>

<ng-template #step2Template>
    <div class="{{step}}">
        <div class="passengers-details">
            <div class="wrapper">
                <div class="checkin-header">
                    <h2>
                        <ng-content select=".form-wrapper-title-step2"> </ng-content>
                    </h2>
                </div>
                <ng-content select=".form-wrapper-top-body-step2"></ng-content>
            </div>
        </div>
        <ng-content select=".form-wrapper-middle-body-step2"></ng-content>
        <ng-content select=".form-wrapper-bottom-body-step2"></ng-content>
    </div>
</ng-template>

<ng-template #termsAndCondTemplate>
    <p innerHtml="{{ 'By clicking Continue you agree to the <a>Terms & Conditions</a>' | translate:'price-display' }}">
    </p>
</ng-template>
<ng-template #promoTemplate>
    <!-- <ng-container *ngIf="!isRecordLocator">
        <p>{{ '*Buy this flight and earn exclusive car rental deals up to -15% at the end of the booking process!' |
            translate:'price-display' }}</p>
    </ng-container>
    <ng-container *ngIf="isBookingFlow && isPromotion">
        <p>{{ 'PriceDisplay-PromotionText' | translate:'price-display' }}</p>
    </ng-container> -->
</ng-template>