<div class="checkin-extra-options">
  <div class="checkin-header">
    <h2>
      {{ 'Pick some extra options' | translate: 'extras' }}
    </h2>
  </div>

  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep" [pbagModal]="pbagModal" icon="font-ico-priority-boarding-2bags"
    addBtnText="{{'Priority' | translate: 'extras' | lowercase}}" [ssrType]="SsrTypeEnum.PriorityBoarding"
    [flights]="flights">

    <div class="split-text-wrapper">
      <div class="split-left">
        <div>
          <h3>
            {{ 'Cabin bag selection' | translate: 'extras' }}
          </h3>
        </div>

        <div>
          {{'Add priority and 2 cabin bags to be among the first onboard and bring additional trolley bag in cabin.
          All passengers must select at least one option.' | translate: 'extras'}}
        </div>
      </div>
    </div>
  </check-in-ssr-box>

  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep" [pbagModal]="pbagModal" icon="font-ico-luggage"
    addBtnText="{{ 'luggage' | translate: 'extras' }}" [ssrType]="SsrTypeEnum.Baggage" [flights]="flights">

    <h3>
      {{ 'Hold luggage' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Book now and save on bagage fees at the airport' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>

  <!-- Razvan: Lounge was removed in the first phase -->
  <!-- <check-in-ssr-box *ngIf="ssrsService.isLuggageStep" icon="font-ico-airport-checkin"
    addBtnText="{{'priority bag' | translate: 'extras'}}" [ssrType]="SsrTypeEnum.AirportCheckin" [flights]="flights">

    <div class="split-text-wrapper">
      <div class="split-left">
        <div>
          <h3>
            {{ 'Airport Checkin' | translate: 'extras' }}
          </h3>
        </div>

        <div>
          {{ ' Airport Checkin information Content' | translate:'extras' }}
        </div>
      </div>
    </div>
    <h3>

    </h3>

  </check-in-ssr-box> -->

  <!-- Razvan: Lounge was removed in the first phase -->
  <!-- <check-in-ssr-box *ngIf="ssrsService.isLuggageStep && isBookingFlow && !isInactiveFlex" icon="font2-ico-ssrs-flx"
    addBtnText="{{'priority bag' | translate: 'extras'}}" [ssrType]="SsrTypeEnum.Flex" [flights]="flights">

    <div class="split-text-wrapper">
      <div class="split-left">
        <div>
          <h3>
            {{ 'FLEX' | translate: 'extras' }}
          </h3>
        </div>

        <div>
          {{ ' FLEX information Content' | translate:'extras' }}
        </div>
      </div>
    </div>
    <h3>

    </h3>

  </check-in-ssr-box> -->

  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep" icon="font-ico-priority-bag"
    addBtnText="{{'priority bag' | translate: 'extras'}}" [ssrType]="SsrTypeEnum.PriorityBag" [flights]="flights">

    <h3>
      {{ 'Priority bag' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Priority bag' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>

  <div class="extras-minimised">
    <ul>
      <!-- <li>
        <button (click)="selectMeal()" [ngClass]="{'selected': isMealSelected}">
          <div class="circle-wrap no-float">
            <span class="font-ico-circle"></span>
            <i class="font-ico-meal"></i>
          </div>

          <div class="extras-content-wrap">
            <h3>
              {{ 'Add meal' | translate: 'extras' }}
            </h3>

            <p>
              {{ 'Enjoy a meal with your flight. Choose from these warm, fresh and delicious menus' | translate:
              'extras' }}
            </p>

            <div class="extras-minimised-price"></div>
          </div>
        </button>
      </li> -->

      <li>
        <button (click)="selectPet()" [ngClass]="{'selected': isPetSelected}">
          <div class="circle-wrap no-float">
            <span class="font-ico-circle"></span>
            <i class="font-ico-dog"></i>
          </div>

          <div class="extras-content-wrap">
            <h3>
              {{ 'Pets' | translate: 'extras' }}
            </h3>

            <p>
              {{ 'Don\'t leave your best friend behind' | translate: 'extras' }}
            </p>

            <div class="extras-minimised-price"></div>
          </div>
        </button>
      </li>

      <li>
        <button (click)="selectSpecialEquipment()" [ngClass]="{'selected': isSpecialEquipmentSelected}">
          <div class="circle-wrap no-float">
            <span class="font-ico-circle"></span>
            <i class="font-ico-sport-eq"></i>
          </div>

          <div class="extras-content-wrap">
            <h3>
              {{ 'Special equipment' | translate: 'extras' }}
            </h3>

            <p>
              {{ 'Sports equipment, bicycles, weapons, etc.' | translate: 'extras' }}
            </p>

            <div class="extras-minimised-price"></div>
          </div>
        </button>
      </li>

      <li>
        <button (click)="selectSpecialAssistance()" [ngClass]="{'selected': isSpecialAssistanceSelected}">
          <div class="circle-wrap no-float">
            <span class="font-ico-circle"></span>
            <i class="font-ico-spec-assistance"></i>
          </div>

          <div class="extras-content-wrap">
            <h3>
              {{ 'Special assistance' | translate: 'extras' }}
            </h3>

            <p>
              {{ 'Please indicate which services you require on your flight(s)' | translate: 'extras' }}
            </p>

            <div class="extras-minimised-price"></div>
          </div>
        </button>
      </li>
    </ul>
  </div>

  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep && isMealSelected" icon="font-ico-meal"
    addBtnText="{{ 'meal' | translate: 'extras' }}" [ssrType]="SsrTypeEnum.Meal" [flights]="flights">

    <h3>
      {{ 'Add a meal' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Enjoy a meal on your flight by pre-booking today' | translate: 'extras' }}
      </li>

      <li role="menuitem">
        {{ 'Choose from six warm, fresh and delicious menus' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>

  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep && isPetSelected && !isUnavailableDueToPreviouslyCheckedIn"
    icon="font-ico-dog" addBtnText="{{ 'pet' | translate: 'extras' }}" [ssrType]="SsrTypeEnum.Pet" [flights]="flights">

    <h3>
      {{ 'Pets' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Don\'t leave your pet behind' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>

  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep && isSpecialEquipmentSelected" icon="font-ico-sport-eq"
    addBtnText="{{ 'equipment' | translate: 'extras' }}" [ssrType]="SsrTypeEnum.SpecialEquipment" [flights]="flights"
    [isUnavailableDueToPreviouslyCheckedIn]="isUnavailableDueToPreviouslyCheckedIn">

    <h3>
      {{ 'Other special equipment' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Special equipment description' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>
  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep" icon="font2-ico-lounge"
    addBtnText="{{ 'lounge' | translate: 'extras' }}" [ssrType]="SsrTypeEnum.Lounge" [flights]="flights">

    <h3>
      {{ 'Add a lounge' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Lounge description' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>

  <check-in-ssr-box
    *ngIf="ssrsService.isLuggageStep && isSpecialAssistanceSelected && !isUnavailableDueToPreviouslyCheckedIn"
    icon="font-ico-spec-assistance" addBtnText="{{ 'assistance' | translate: 'extras' }}"
    [ssrType]="SsrTypeEnum.SpecialAssistance" [flights]="flights">

    <h3>
      {{ 'Special assistance' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Special assistance description' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>

  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep" icon="font-ico-fast-track"
    addBtnText="{{'Fast track' | translate: 'extras' | lowercase}}" [ssrType]="SsrTypeEnum.FastTrack"
    [flights]="flights">

    <h3>
      {{ 'Fast track' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Fast track description' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>


  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep  && hasEarlyCheckin" icon="font-ico-early-checkin"
    addBtnText="{{'Early checkin btn' | translate: 'extras' | lowercase}}" [ssrType]="SsrTypeEnum.EarlyCheckin"
    [flights]="flights">

    <h3>
      {{ 'Early checkin' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Early checkin description' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>
  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep" icon="font-ico-shuttle"
    addBtnText="{{'Bus Transfer btn' | translate: 'extras' | lowercase}}" [ssrType]="SsrTypeEnum.BusTransfer"
    [flights]="flights">

    <h3>
      {{ 'Bus Transfer' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Bus Transfer description' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>
  <check-in-ssr-box *ngIf="ssrsService.isLuggageStep" icon="font-ico-airport-checkin"
    addBtnText="{{'Airport checkin btn' | translate: 'extras' | lowercase}}" [ssrType]="SsrTypeEnum.AirportCheckin"
    [flights]="flights">

    <h3>
      {{ 'Airport checkin' | translate: 'extras' }}
    </h3>

    <ul role="menu" class="checkin-checked-box">
      <li role="menuitem">
        {{ 'Airport checkin description' | translate: 'extras' }}
      </li>
    </ul>
  </check-in-ssr-box>

</div>