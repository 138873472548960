import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ProfileInfoBox } from '../../profile-info-box';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ProfileService } from 'src/app/core/profile.service';
import { ConfigService } from 'src/app/core/config.service';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import { ResetPasswordFormComponent } from '../../reset-password-form/reset-password-form.component';
var AgentSettingsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AgentSettingsComponent, _super);
    function AgentSettingsComponent(modalService, profileService, configService, translate) {
        var _this = _super.call(this, modalService) || this;
        _this.profileService = profileService;
        _this.configService = configService;
        _this.translate = translate;
        _this.editModalId = 'profile-settings';
        _this.passwordChangeModalId = 'profile-settings-password';
        _this.profileSetings = {};
        _this.model = {};
        _this.errors = [];
        _this.passwordChangeErrors = [];
        _this.route = _this.configService.SaveProfileInfoUrl;
        _this.passwordChangeRoute = _this.configService.ResetPasswordUrl;
        profileService.profile.subscribe(function (p) {
            if (!p) {
                return;
            }
            _this.profileSetings.agentId = p.agentId;
            _this.profileSetings.username = p.username;
            _this.profileSetings.organizationCode = p.identifier.organizationCode;
            _this.passwordChangePolicyRegex = p.domain.passwordPolicy;
            _this.passwordChangePolicy = p.domain.passwordPolicyText;
        });
        return _this;
    }
    AgentSettingsComponent.prototype.ngOnInit = function () {
    };
    AgentSettingsComponent.prototype.edit = function () { };
    AgentSettingsComponent.prototype.save = function () { };
    AgentSettingsComponent.prototype.openPasswordModal = function (event) {
        event.preventDefault();
        this.changePasswordForm.resetForm();
        this.openModal(this.passwordChangeModalId);
    };
    AgentSettingsComponent.prototype.savePassword = function (passwordChangeModel) {
        var _this = this;
        this.profileService.resetPassword(this.profileSetings.agentId, this.profileSetings.username, passwordChangeModel.oldPassword, passwordChangeModel.newPassword, passwordChangeModel.newPasswordConf)
            .subscribe(function (result) {
            if (result.loginOk) {
                _this.closeModal(_this.passwordChangeModalId);
            }
            else {
                _this.passwordChangeErrors = result.errors;
            }
        });
    };
    AgentSettingsComponent.prototype.cancelPassword = function () {
        this.closeModal(this.passwordChangeModalId);
    };
    return AgentSettingsComponent;
}(ProfileInfoBox));
export { AgentSettingsComponent };
