import { ConfigService } from '../core/config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RetrieveBookingsByEmailAddress, RetrieveBookingsByRecordLocator, RetrieveBookingsByUtc, FindBookingByBookingDepartureDate } from './agent/filter-bookings/filter-bookings.component';

@Injectable({
  providedIn: 'root'
})
export class FindMyBookingsService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  public RetrieveBookingsByEmailAddress(RetrieveBookingsByEmailAddress: RetrieveBookingsByEmailAddress): Promise<any> {
    RetrieveBookingsByEmailAddress.FlightNumber = RetrieveBookingsByEmailAddress.FlightNumber ?
                                                  RetrieveBookingsByEmailAddress.FlightNumber.padStart(4, ' ') : null;
    return this.RetrieveBookings({ RetrieveBookingsByEmailAddress });
  }

  public RetrieveBookingsByRecordLocator(RetrieveBookingsByRecordLocator: RetrieveBookingsByRecordLocator): Promise<any> {
    return this.RetrieveBookings({ RetrieveBookingsByRecordLocator });
  }

  public RetrieveBookingsByBookingDate(RetrieveBookingsByUtc: RetrieveBookingsByUtc): Promise<any> {
    RetrieveBookingsByUtc.EndUtc = RetrieveBookingsByUtc.StartUtc;
    RetrieveBookingsByUtc.FlightNumber = RetrieveBookingsByUtc.FlightNumber ? 
                                         RetrieveBookingsByUtc.FlightNumber.padStart(4, ' ') : null;
    return this.RetrieveBookings({ RetrieveBookingsByUtc });
  }

  public RetrieveBookingsByDepartureDate(FindBookingByBookingDepartureDate: FindBookingByBookingDepartureDate): Promise<any> {
    return this.RetrieveBookings({ FindBookingByBookingDepartureDate });
  }

  public RetrieveBookings(model?: any): Promise<any> {
    return !model ? this.http.get(this.configService.RetrieveAgentBookings).toPromise() : 
                    this.http.post(this.configService.RetrieveAgentBookings, model).toPromise();
  }
}