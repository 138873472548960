import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import {  BehaviorSubject  } from 'rxjs';
import { LoggerService } from '../common-modules/log4ts/logger.service';
import { SeatMap, JourneyViewModel } from './models/seat-models';
import { BookingService } from '../core/booking.service';
import { FlowManagerService } from '../core/flow-manager.service';
import { SeatMapModel } from './models/seat-map.model';

@Injectable()
export class SeatMapService {
  journeyUpdate: BehaviorSubject<object> = new BehaviorSubject({});
  errorMessage: BehaviorSubject<object> = new BehaviorSubject({});
  journeys: Array<JourneyViewModel>;
  journeyIndexes : Array<number>= [];

  specialAssistanceSsrs: string[] = ["WCHC", "WCHR", "WCHS"];
  petInCabinSsrs: string[] = ["PETC"];
  seatMap: any;
  seatWithFees: Array<any> = [];
  soldSeatsWithFees: Array<any> = [];
  booking:any;


  constructor(private http: HttpClient, private config: ConfigService, private flowManager: FlowManagerService,
    private logService: LoggerService , private bookingService:BookingService) {
    logService.info("construct seatmap service");
     this.bookingService.refresh().then(p=>{
          this.booking = p;
    });

  }

  removeSeats(seats): Promise<any> {
    const seatkeys = seats.map(s => '' + s.passengerNumber + '|' + s.flightKey + '|' +
      s.deck + '|' + s.compartment + '|' + s.unit + '|xx');

    return this.sellSeat(seatkeys);
  }

  sellSeat(sellKeys): Promise<any> {
      const model = {
        'seatmap': {
          'passengerSeatKeys': sellKeys
        }
      };
      return this.http.post(this.config.SeatMap, model).toPromise().then(data => {

        if ((data as any).errors) {
          this.errorMessage.next((data as any).errors);
        } else {
          this.errorMessage.next(new Array<any>());
        }

        this.doThingsWithSeatmapResponse(data);

        this.flowManager.applicationFlowService.loadPriceBreakdown(true);
      }
      );
    }

  refreshSeatMap(): Promise<any> {
    let seatmapPromise: Promise<any> = Promise.resolve();
    seatmapPromise =
      this.http.get(this.config.SeatMap + "?f=true&p=true&g=true&s=true&rm=true").toPromise().then(data => {
       this.doThingsWithSeatmapResponse(data);
       return data;
      });


    return seatmapPromise;
  }

  doThingsWithSeatmapResponse(data){
    const result = data as any;
    if (result.error) {
      this.errorMessage.next(result.error);
    }

    const segmentsInJourney = result.seatMap.seatAssignment.journeys
      .map(p => p.seatSegmentKeys.map(pp => pp.flightKey))
      .map((p, i) => ({ index: i, flightKey: p }))
      .reduce((a, b) => a.concat(b.flightKey.map(x => ({ index: b.index, flightkey: x }))), []);

if(result.seatMap.doRefreshData ){
  this.journeys = result.seatMap.seatMaps.map(s => ({
    flightKey: s.flightKey,
    decks: s.decks,
    groups: s.seatFees,
  }));

  this.journeyUpdate.next(this.journeys);

  this.seatWithFees = result.seatMap.seatAssignment.journeys.map(j =>
    j.seatSegmentKeys.map(
      ss =>
        ss.passengers.map(sp =>
          (
            {
              from: ss.arrivalStation + ' ' + ss.departureStation,
              name: sp.first + ' ' + sp.last,
              passengerNumber: sp.number,
              seat: sp.assignedSeat.unit,
              unit: sp.assignedSeat,
              flightKey: ss.flightKey
            }
          )
        )
    )
  )
    .reduce((a, b) => a.concat(b), [])
    .reduce((a, b) => a.concat(b), []);


}

if(!this.seatMap ||result.seatMap.doRefreshData ){
  this.seatMap = new SeatMap();



  result.seatMap.seatMaps.forEach(sm => {

    const seatMap = new SeatMapModel(
      sm.propertyLookup,
      sm.seatFees,
      sm.ssrCodeLookup,
      sm.sellKey,
      sm.decks,
      sm.flightKey,
      sm.departureStation,
      sm.arrivalStation);

    this.seatMap.seatMaps.push(seatMap)

    // find in witch journey is this segment
    const j = segmentsInJourney.find(p => p.flightkey === sm.flightKey);
    const currentJourney = this.seatMap.journeys.find(p => p.journeyIndex === j.index);

    if (currentJourney) {
      currentJourney.seatmaps.push(seatMap);
    } else {
      let x = new JourneyViewModel();
      x.journeyIndex = j.index;
      x.seatmaps = [seatMap];
      x.flightKey = sm.flightKey;
      this.seatMap.journeys.push(x);
    }

  });

}
this.seatMap.seatAssignment = result.seatMap.seatAssignment;

  }

  getSeatFees() {
    const b = this.bookingService.getBooking();

    return b.assignedSeats.journeys.items
      .map(j => (
        {
          key: j.segments.items[0].departureStation + '_' + j.segments.items[j.segments.items.length - 1].arrivalStation,
          items: [],
          balanceDue: 10
        }));
  }

  appliedFilters :Array<number> =[];

}

