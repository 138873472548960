import { Component, OnInit, ViewChild, ElementRef, Renderer, ChangeDetectorRef } from '@angular/core';
import { BookingStepsService, CheckinSteps, ApplicationFlowEnum } from '../../core/booking-steps.service';
import { FlightsService } from '../../flights/flights.service';
import { CheckinService } from '../../core/checkin.service';
import { BookingService } from '../../core/booking.service';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../core/config.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { PaymentsService } from '../payments.service';
import { Constants } from 'src/app/constants';
import { ECommerceService, Transaction } from '../../shared/e-commerce/ecommerce.service';

@Component({
  selector: 'check-in-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  passengers: Array<any> = [];
  flights: Array<any> = [];
  booking: any = {};
  boardingPasses: any[] = [];
  recordLocator: string;
  printUrl = '';
  boardingPassesSpinnerId = 'boardingPassSpinner';
  hasAppliedPayment: boolean;

  private currentStep = CheckinSteps.confirmation;
  @ViewChild('boardingPassForm', { static: false }) boardingPassForm: ElementRef;

  constructor(private renderer: Renderer, private bookingSteps: BookingStepsService, private flightService: FlightsService,
    private checkinService: CheckinService, private bookingService: BookingService,
    private http: HttpClient, private configService: ConfigService, private loadingSpinnerService: LoadingSpinnerService,
    private modalService: BlueModalService, private translateService: TranslateService, private paymentsService: PaymentsService,
    private ecommerce: ECommerceService) {

    this.printUrl = configService.config.PrintUrl;
    this.bookingSteps.currentStep.next(this.currentStep);
    this.passengers = flightService.passengers;
    this.flights = flightService.flights;

    this.checkinService.boardingPassesObs.subscribe(
      bp => {
        if (!bp.isInfant && !bp.segments.map(s => s.legs).reduce((a, b) => a.concat(b), []).map(l => l.aircraftType).some(b => b == "BUS")) {
          this.boardingPasses.push(bp);
        }
      },
      () => this.loadingSpinnerService.hideSpinnerById(this.boardingPassesSpinnerId),
      () => this.loadingSpinnerService.hideSpinnerById(this.boardingPassesSpinnerId)
    );
    this.checkinService.loadBoardingPasses();

    this.bookingService.refresh().then((booking: any) => {
      this.recordLocator = booking.bookingDetail.recordLocator;
    });

    this.hasAppliedPayment = this.paymentsService.hasAppliedPayment;
  }

  ngOnInit() {
    this.ecommercePurchase();
  }

  ecommercePurchase() {
    if (sessionStorage.getItem('checkin-purchase-ec') === null) {
      let ecommerceCartItems = [];
      let grandTotal = 0;
      const storage_breakdown = sessionStorage.getItem(Constants.CartBreakdown);
      const breakdown = storage_breakdown && JSON.parse(sessionStorage.getItem(Constants.CartBreakdown));
      if (breakdown && breakdown.currentShoppingCart.flights) {
        ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown, false);
        ecommerceCartItems.forEach(cartItem => {
          grandTotal += Number(cartItem.price) * cartItem.quantity;
        });
        const transaction = new Transaction(this.recordLocator + '_' + Date.now(),
          ApplicationFlowEnum[ApplicationFlowEnum.Checkin].toString(),
          grandTotal.toString(), '0', '0', '').getItem();
        this.ecommerce.Purchases(ecommerceCartItems, transaction);
        sessionStorage.setItem('checkin-purchase-ec', 'true');
        sessionStorage.removeItem(Constants.CartBreakdown);
      }
    }
  }

  getSeats(seg) {
    const units = [];

    seg.legs.forEach(leg => {
      units.push(leg.seatInfo);
    });
    return units;
  }
}
