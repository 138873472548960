import { Injectable, Renderer2 } from '@angular/core';

/**
 * Custom BlueAir Renderer service for specific rendering tasks
 * This service must be provided at component level only
 */
@Injectable()
export class BlueRenderer {

  public scrollTop: number = null;
  public isOpened = false;
  constructor(private renderer: Renderer2) { }

  /**
   * Update body scrolling allowance
   * @param preventScrolling if [true] prevents the body to scroll, else if [false] allows the body from scrolling
   */
  updateBodyScroll(preventScrolling: boolean) {
    if (preventScrolling) {
      if(!this.isOpened) {
        this.scrollTop = window.pageYOffset;
        this.renderer.addClass(document.body, 'no-scroll');
        this.isOpened = true;
      }

      // this.renderer.addClass(document.body.parentElement, 'no-scroll');
    } else {
      this.isOpened = false;
      this.renderer.removeClass(document.body, 'no-scroll');
      if (this.scrollTop) {
        window.scrollTo(0, this.scrollTop);
        this.scrollTop = null;
      }
    }
  }

  /**
   * Update body scrolling allowance based on screen width
   * @param preventScrolling if [true] prevents the body to scroll, else if [false] allows the body from scrolling
   * @param mobileScreenWidth the mobile screen width (pixels) threshold. Defaults to [768] px
   */
  updateBodyScrollOnMobile(preventScrolling: boolean, mobileScreenWidth: number = 768) {
    const isOnMobile = window.innerWidth < mobileScreenWidth;
    this.updateBodyScroll(isOnMobile && preventScrolling);
  }
}
