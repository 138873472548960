/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passenger-type-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./passenger-type-display.component";
var styles_PassengerTypeDisplayComponent = [i0.styles];
var RenderType_PassengerTypeDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PassengerTypeDisplayComponent, data: {} });
export { RenderType_PassengerTypeDisplayComponent as RenderType_PassengerTypeDisplayComponent };
function View_PassengerTypeDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "Adult {0}", "documents", _co.passenger.passengerNumberByType)); _ck(_v, 1, 0, currVal_1); }); }
function View_PassengerTypeDisplayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "Young Adult {0}", "documents", _co.passenger.passengerNumberByType)); _ck(_v, 1, 0, currVal_1); }); }
function View_PassengerTypeDisplayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "Teen {0}", "documents", _co.passenger.passengerNumberByType)); _ck(_v, 1, 0, currVal_1); }); }
function View_PassengerTypeDisplayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 3)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "Child {0}", "documents", _co.passenger.passengerNumberByType)); _ck(_v, 1, 0, currVal_1); }); }
function View_PassengerTypeDisplayComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "Infant", "documents")); _ck(_v, 1, 0, currVal_1); }); }
export function View_PassengerTypeDisplayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerTypeDisplayComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerTypeDisplayComponent_2)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerTypeDisplayComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerTypeDisplayComponent_4)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerTypeDisplayComponent_5)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.passenger.isAdult; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.passenger.isYouth; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.passenger.isTeen; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.passenger.isChild; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.passenger.isInfant; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_PassengerTypeDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-passenger-type-display", [], null, null, null, View_PassengerTypeDisplayComponent_0, RenderType_PassengerTypeDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i5.PassengerTypeDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassengerTypeDisplayComponentNgFactory = i1.ɵccf("check-in-passenger-type-display", i5.PassengerTypeDisplayComponent, View_PassengerTypeDisplayComponent_Host_0, { passenger: "passenger", cssClass: "cssClass" }, {}, []);
export { PassengerTypeDisplayComponentNgFactory as PassengerTypeDisplayComponentNgFactory };
