<div class="checkin-pets-options" [(checkInSsrListDisplay)]="isSsrsPopupOpened">
  <check-in-ssr-passenger-picker [passengers]="passengers" [ssrType]="ssrType"
    [allPassengersEnabled]="allPassengersEnabled" [selectedPassenger]="selectedPassenger"
    (selectPassenger)="selectPassenger($event)" (removeSsr)="removeAllSsrs($event)" (resetSsr)="resetSsr()">
  </check-in-ssr-passenger-picker>
  <div class="pets-list js-ssr-list">
    <div class="pets-list-inner">
      <button type="button" class="close-popup" aria-hidden="true">
        <span class="font-ico-close-menu"><span class="sr-only">{{ 'Click to close pet overlay' | translate:'extras'
            }}</span></span>
      </button>
      <h2 aria-hidden="true" class="js-ssr-list-title">{{ 'Pick one or more pets' | translate:'extras' }}</h2>
      <div *ngIf="mobileMatches">
        <div *ngIf="selectedPassenger == -1 else singlePassengerName">{{ 'All passengers' | translate: 'extras' }}</div>
        <ng-template #singlePassengerName>{{ getSelectedPassengerName() }}</ng-template>
      </div>
      <ng-container *ngTemplateOutlet="item; context:{ ssrList: carryOnSsrs, isCarryOn: true }">
      </ng-container>

      <ng-container *ngTemplateOutlet="item; context:{ ssrList: inHoldSsrs, isCarryOn: false }">
      </ng-container>
      <button type="button" class="btn btn-primary close-popup-bottom" aria-hidden="true">
        {{ 'Confirm and Continue' | translate:'extras' }}
      </button>
    </div>
  </div>

  <!--
  <check-in-ssr-same-options-for-return-flight [visible]="enableSameOptionsForAllFlights"
    [(isSelected)]="sameOptionsForAllFlights"></check-in-ssr-same-options-for-return-flight>
  -->

  <div class="checkbox-wrap" *ngIf="enableSameOptionsForAllFlights">
    <input type="checkbox" id="return-flight-{{uniqueId}}" [ngModel]="sameOptionsForAllFlights" (click)="checkSameSsrOptionsForAllFlights()"/>
    <label for="return-flight-{{uniqueId}}">{{ 'I choose the same options for return flight' | translate:'extras' }}</label>
  </div>
</div>

<ng-template #item let-ssrList="ssrList" let-isCarryOn="isCarryOn">
  <div class="pet-item" *ngFor="let ssr of ssrList">
    <div class="header-wrap-item">
      <div class="circle-wrap"><span class="font-ico-circle"></span><i class="font-ico-dog"></i></div>
      <div class="pet-item-left">
        <span class="pet-desc">{{ ssr.ssrCode + ' description' | translate:'extras' }}</span>
        <h3 *ngIf="isCarryOn">{{ 'Pet in cabin' | translate:'extras' }}</h3>
        <h3 *ngIf="!isCarryOn">{{ 'Pet in hold' | translate:'extras' }}</h3>
        <check-in-ssr-price-display [originalPrice]="ssr.originalPrice" [currentPrice]="ssr.price">
        </check-in-ssr-price-display>
      </div>
    </div>
    <div class="pet-item-right">
      <ng-container *ngIf="ssr.getLimitPerPassenger(getSeletedPassenger().passenger.passengerNumber) == 1">
        <ng-container *ngTemplateOutlet="singleItem; context:{ssr: ssr}"></ng-container>
      </ng-container>
      <ng-container *ngIf="ssr.getLimitPerPassenger(getSeletedPassenger().passenger.passengerNumber) > 1">
        <ng-container *ngTemplateOutlet="multipleItems; context:{ssr: ssr}"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #singleItem let-ssr="ssr">
  <button type="button" class="pet-number pet-number-single"
    [ngClass]="{ 'pet-number-selected' : ssr.selectedQuantity > 0 }"
    attr.aria-label="{{'Click to add pet' | translate: 'extras'}}" (click)="addPetToCabin(selectedPassenger)"
    [disabled]="ssr.paidQuantity == 1">
    <span class="pet-number-inner">
      <strong *ngIf="ssr.selectedQuantity == 0">{{ 'Add pet' | translate:'extras' }}</strong>
      <strong *ngIf="ssr.selectedQuantity > 0">{{ 'Added' | translate:'extras' }}</strong>
      <ng-container *ngIf="ssr.paidQuantity == 1"> {{'PAID' | translate:'extras' }}</ng-container>
    </span>
  </button>
</ng-template>

<ng-template #multipleItems let-ssr="ssr">
  <div class="pet-number-container">
    <button type="button" class="pet-number pet-number-zero"
      attr.aria-label="{{'Click to select 0 pets' | translate: 'extras'}}"
      (click)="selectSsr(null, ssr.ssrCode, 0); selectPetInHold()"
      [ngClass]="{'pet-number-selected': ssr.selectedQuantity == 0}" [disabled]="ssr.paidQuantity > 0">
      <span class="pet-number-inner">
        <strong>0</strong>
      </span>
    </button>
    <button type="button" class="pet-number" attr.aria-label="{{ 'Click to select {0} pets' | translate:'extras':ix}}"
      *ngFor="let ix of ssr.getQuantityArray(getSeletedPassenger().passenger.passengerNumber)"
      (click)="selectSsr(null, ssr.ssrCode, ix); selectPetInHold()"
      [ngClass]="{'pet-number-selected': ssr.selectedQuantity === ix}"
      [disabled]="(ix < ssr.paidQuantity ) || (ssr.limitPerNest !== null && ix * numberOfSelectedPassengers > ssr.limitPerNest)">
      <span class="pet-number-inner">
        <strong>{{ix}}</strong>
        <!-- {{ ssr.price | currencyDisplay }} -->
        <ng-container *ngIf="ix <= ssr.paidQuantity">{{ 'PAID' | translate: 'extras' }}</ng-container>
      </span>
    </button>
  </div>
</ng-template>
