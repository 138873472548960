import { Constants } from './../../constants';
import { BookingService } from './../../core/booking.service';
import { BookingFlowService } from './../../core/booking-flow.service';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { tripViewModel } from '../../core/models/availability-model';
import { BookingFlightSelectService } from '../booking-flight-select.service';
import * as moment from 'moment';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { Subscription, Observable, of, BehaviorSubject, combineLatest, from, Subject } from 'rxjs';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { BookingSelectionService } from '../../core/booking-selection.service';
import { FlightModel } from '../../core/models/flight-model';
import { ProfileService } from 'src/app/core/profile.service';
import { SsrTranslatePipe } from 'src/app/shared/ssr-translate.pipe';
import { map, switchMap, filter, min } from 'rxjs/operators';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { BookingFareSelectSyncService } from './booking-fare-select-sync-service.service';
import { BundleService } from 'src/app/shared/bundle.service';
import { data } from 'jquery';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { BookingStepsService, ApplicationFlowEnum } from '../../core/booking-steps.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { FlightSearchModel } from '../models/flight-search-model';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';

declare global {
  interface Window { getConsularMessage: any; }
}

export interface IFareSoldEventData {
  tripIndex: number;
  isBlueBenefits: boolean;
}

@Component({
  selector: 'booking-fare-select',
  templateUrl: './booking-fare-select.component.html',
  styleUrls: ['./booking-fare-select.component.scss']
})
export class BookingFareSelectComponent implements OnInit, OnDestroy {
  private selectionFlightsSubscription: Subscription;

  private tripSubject: BehaviorSubject<tripViewModel> = new BehaviorSubject(null);

  @Output() isBlueBenefitsSelectedEvent = new EventEmitter();
  public isBlueBenefitsSelected: boolean = false;
  public showBlueBenefits: boolean;
  @Input() autoSellFareEvent: Observable<boolean>;
  flights: FlightModel[] = [];
  isSame: boolean = false;
  sortedAvailableBundles: Array<any> = [];
  availableBundles: Array<any> = [];
  commonSsrs: Array<any> = [];
  bookingSnapshot: any;
  trip: tripViewModel;
  tripDate: any;
  currentSelection: any;
  userSearches: Array<any>;
  oldJourneyFares: any;
  totalPrice: any;
  assetsPath: string;
  imagesPath: string;
  defaultBundleImage: string;
  multipleBundleList: boolean;
  isChangeSearchOpened: boolean;
  bookingCurrency: string;
  isAgent: boolean;
  ecommerceCartItems: Array<any> = [];
  incorrectSelectionModalId = "incorrectSelectionModalId";
  promortionLink: string = '/';
  allAvailableBundles: Array<any> = [];
  basePrices: Array<any> = [];
  currentBundleSelected: any;
  toggleFares: boolean;
  bundleV2: Array<any> = [];
  private spinnerId = 'bundleSpinnerId';
  isMoveFlightsFlow: boolean;
  private currentSelectedAmount: number = 0;

  @ViewChild("incorrectSelectionModal", { static: true }) incorrectSelectionModal: DefaultModalComponent;

  consularText: string;

  @Input()
  set tripModel(t) {

    this.tripSubject.next(t);
    if (t === null) return;

    this.trip = t;
    this.tripDate = t.flightDates[0].departureDate;

    if (this.trip.serviceBundleOffers) {
      this.availableBundles = this.trip.serviceBundleOffers.items.filter(item => item.serviceBundleType === "Included" &&
        this.getFlightWithBundleCode(this.trip.flightDates.map(t => t.flights), item.serviceBundleCode));
      const numberOfCarriers = t.flightDates.map(p => p.flights).reduce((a, b) => a.concat(b), []).map(p => p.legs)
        .reduce((a, b) => a.concat(b), []).map(p => p.legInfo.operatingCarrier).filter((v, i, a) => a.indexOf(v) === i).length;

      this.initializeAvailableBundleList();
      if (numberOfCarriers > 1) {
        this.sortBundlesByCarrierCode(t);
      }
      this.addNotAvailableFares();

      // setTimeout(_ => {
      //   const firstFlightDate = this.trip.flightDates[0];

      //   if (firstFlightDate) {
      //     const firstFlight = firstFlightDate.flights[0];
      //     if (firstFlight.basePrice.length > 0) {
      //       let minimumValueElement = firstFlight.basePrice[1];
      //       this.sellFare(firstFlight, minimumValueElement, true);
      //     };
      //   }
      // })
    }
  }

  @Input() currency: string;
  @Input() lowFare: any;
  @Input() fares: any;
  @Input() tripIndex: number;

  @Output() datechanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() isChangeSearchOpenedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onFareSold: EventEmitter<IFareSoldEventData> = new EventEmitter<IFareSoldEventData>();

  private bookingTripsSubscription: Subscription;
  private consularTextMemberSubscription: Subscription;

  constructor(private sellService: BookingFlightSelectService,
    private modalService: BlueModalService,
    private translateService: TranslateService,
    private bookingFlowService: BookingFlowService,
    private bookingService: BookingService,
    private selectionService: BookingSelectionService,
    private renderer: Renderer2,
    private ecommerce: ECommerceService,
    private ssrTranslate: SsrTranslatePipe,
    private userStateService: UserStateService,
    private bundleService: BundleService,
    private bookingStepsFlowService: BookingStepsService,
    private bookingFareSelectSyncService: BookingFareSelectSyncService,
    private loadingSpinnerService: LoadingSpinnerService,
    private flowManager: FlowManagerService,
    profileService: ProfileService) {

    this.showBlueBenefits = this.sellService.showBlueBenefits;

    this.userSearches = sellService.hubDisplayResponse;
    this.currentSelection = {
      flightKey: '',
      fare: '',
      price: 0
    };

    this.bookingCurrency = bookingFlowService.currency;
    this.assetsPath = environment.assetsPath;
    this.imagesPath = environment.imagesPath;
    this.defaultBundleImage = this.assetsPath + 'svg/ico-checked-sign-white.svg';
    this.isAgent = profileService.isAgent;

    this.selectionFlightsSubscription = this.selectionService.flightsObs.subscribe(f => {
      this.flights = f || [];
    });

    this.bookingTripsSubscription = this.bookingService.bookingObs.subscribe(booking => {
      if (booking && booking.convertedJourneys) {
        this.oldJourneyFares = booking.convertedJourneys.oldJourneyFares;
        this.bookingSnapshot = booking.convertedJourneys.snapshotBooking;
      }
    });

    this.sellService.resetSelectedFlightsList();

    this.consularTextMemberSubscription = combineLatest([
      this.tripSubject,
      this.userStateService.isUserLoginDisabled.pipe(map(info => info.isDisabled ? info.agent : null))
    ]).subscribe(([currentTrip, agentName]) => {
      if (currentTrip && window.getConsularMessage) {
        const departureDate = (currentTrip.flightDates && currentTrip.flightDates[0]) ?
          moment(currentTrip.flightDates[0].departureDate).toDate() : null;
        this.consularText = window.getConsularMessage(
          this.translateService.culture,
          agentName,
          currentTrip.origin,
          currentTrip.destination,
          departureDate
        );
      } else {
        this.consularText = '';
      }
    });
  }

  private _autoSellEventSubscription;
  ngOnInit() {
    // if only one journey is selected for change, remember it's trip index
    if (this.bookingSnapshot && !this.sellService.searchModel.isRoundTrip) {
      this.tripIndex = Number(sessionStorage.getItem(Constants.SelectedFlightIndexForChange));
    }

    this.isMoveFlightsFlow = JSON.parse(sessionStorage.getItem(Constants.MoveFlightsFlow));

    this._autoSellEventSubscription = this.autoSellFareEvent.subscribe((isBlueBenefitSelected) => {
      if (this.currentSelection.flightKey) {
        return;
      }

      const firstFlightDate = this.trip.flightDates[0];
      if (!firstFlightDate) {
        return;
      }
      const firstFlight = firstFlightDate.flights[0];
      if (!firstFlight) {
        return;
      }

      const fareToSellOnThisTrip = firstFlight.basePrice[0];

      if (!fareToSellOnThisTrip) {
        return;
      }

      this.sellFare(firstFlight, fareToSellOnThisTrip, isBlueBenefitSelected, true);
    });
  }



  ngOnDestroy(): void {
    if (this.selectionFlightsSubscription) {
      this.selectionFlightsSubscription.unsubscribe();
    }
    if (this.bookingTripsSubscription) {
      this.bookingTripsSubscription.unsubscribe();
    }

    if (this.consularTextMemberSubscription) {
      this.consularTextMemberSubscription.unsubscribe();
    }

    if (this._autoSellEventSubscription) {
      this._autoSellEventSubscription.unsubscribe();
    }
  }

  setCommonSsrsAndRemoveFromBundles() {
    if (this.availableBundles.length > 1) {
      this.commonSsrs = this.availableBundles
        .map(x => x.serviceBundlePriceList)
        .reduce((a, b) => a.concat(b), [])
        .map(x => x.serviceBundleSsrPriceList)
        .reduce((a, b) => a.filter(c => b.some(d => d.ssrCode === c.ssrCode)))
        .map(x => x.ssrCode);

      this.availableBundles.forEach(availableBundle => {
        for (let i = 0; i < availableBundle.serviceBundlePriceList[0].serviceBundleSsrPriceList.length; i++) {
          if (this.commonSsrs.includes(availableBundle.serviceBundlePriceList[0].serviceBundleSsrPriceList[i].ssrCode)) {
            availableBundle.serviceBundlePriceList[0].serviceBundleSsrPriceList.splice(i, 1);
            i--;
          }
        }
      });
    }
  }

  private _hasTheNewBundles(availableBundles) {
    if (!availableBundles) {
      return false;
    }

    const newBundleCodes = ['ABAS', 'BPLU', 'CBIZ'];
    for (const bundle of availableBundles) {
      for (const keyValue of bundle) {
        if (newBundleCodes.includes(keyValue.value)) {
          return true;
        }
      }
    }

    return false;
  }

  initializeAvailableBundleList() {

    this.addBundleInList(/^PR/);
    this.addBundleInList(/^CO/);
    this.addBundleInList(/^CM/);

    this.addBundleInList(/^CL/);
    this.addBundleInList(/^EX/);

    this.addBundleInList(/^LI/);
    this.addBundleInList(/^AB/);
    this.addBundleInList(/^BB/);
    this.addBundleInList(/^CB/);
    this.addBundleInList(/^NL/);

    if (this.trip.flightDates[0].flights.length > 0) {
      let bundleIndexes = this.trip.flightDates[0].flights[0].fares[0].serviceBundleOfferIndexes;

      const bundleCodes = this.bookingService.getBooking().bundleDetail.bundleCodes;

      if (this.bookingStepsFlowService.flow === ApplicationFlowEnum.ManageBooking && this._hasTheNewBundles(bundleCodes)) {
        let bookingBundleCode = bundleCodes[0][0].value;
        let bookingBundleCodeIndex = this.trip.serviceBundleOffers.items.findIndex(b => b.serviceBundleCode === bookingBundleCode);


        bundleIndexes = this.trip.flightDates[0].flights[0].fares[0].serviceBundleOfferIndexes.filter(i => i == bookingBundleCodeIndex);
      }

      //Add bundle to array
      let carrierCode = this.trip.flightDates[0].flights[0].fares[0].carrierCode;
      bundleIndexes.forEach(index => {
        let bundle = this.trip.serviceBundleOffers.items[index];
        bundle.carrierCode = carrierCode;
        this.bundleV2.push(bundle);
      });


      //Remove CKN ssrs from bundle
      this.bundleV2.forEach(bundle => {
        bundle.serviceBundlePriceList.forEach(priceList => {
          if (priceList.serviceBundleSsrPriceList.find(ssr => ssr.ssrCode == 'CKN')) {
            let index = priceList.serviceBundleSsrPriceList.findIndex(ssr => ssr.ssrCode == 'CKN');
            if (index > -1) {
              priceList.serviceBundleSsrPriceList.splice(index, 1);
            }
          }
        });
      })
    }
  }

  addBundleInList(matchString) {
    const bundles = this.availableBundles.filter(b => b.serviceBundleCode.match(matchString));
    if (bundles.length > 0) {
      this.sortedAvailableBundles.push(bundles);
    }
  }

  isFareForBundle(fares, bundleCodes): boolean {
    let fareExists = false;
    fares.map(f => f.defaultIncludedBundleCode).forEach(fareBundleCode => {
      if (bundleCodes.indexOf(fareBundleCode) > -1) {
        fareExists = true;
      }
    });
    return fareExists;
  }

  getFlightWithBundleCode(flights, bundleCode) {
    return flights.reduce((a, b) => a.concat(b), []).filter(f => f.fares.filter(fare => fare.defaultIncludedBundleCode === bundleCode).length > 0)[0];
  }

  getFlightCarrierCode(flights, bundleCode): string {
    const flight = this.getFlightWithBundleCode(flights, bundleCode);
    const carrierCode = flight.legs.reduce((a, b) => a.concat(b), []).map(p => p.legInfo.operatingCarrier);
    return carrierCode != '' ? carrierCode : flight.carrierCode;
  }

  sortBundlesByCarrierCode(trip) {
    this.sortedAvailableBundles.forEach(bundleList => {
      if (bundleList.length > 1) {
        bundleList.forEach((value, index) => {
          const carrierCode = this.getFlightCarrierCode(trip.flightDates.map(p => p.flights), value.serviceBundleCode);
          // add carrier code on bundle info
          value.carrierCode = carrierCode.length == 1 ? carrierCode[0] : carrierCode;
          if (carrierCode === '0B') {
            // if already on first position do nothing
            if (!(index === 0)) {
              // delete value
              bundleList.splice(index, 1);
              // move value on first position
              bundleList.unshift(value);
            }
          }
        });
      }
    });
  }

  addNotAvailableFares() {
    this.trip.flightDates.forEach(fd => {
      fd.flights.forEach(flight => {
        flight.basePrice = [];

        if (this.sortedAvailableBundles.length > 0) {
          flight.basePrice.push(flight.fares.filter(b => b.defaultIncludedBundleCode.match(this.sortedAvailableBundles[this.sortedAvailableBundles.length - 1][0].serviceBundleCode))[0]);
          flight.bundlePrices = [flight.basePrice[0]];
        }

        // if fares number is smaller than available bundles number
        if (flight.fares.length < this.sortedAvailableBundles.length) {
          this.sortedAvailableBundles.forEach((bundleList, bundleListIndex) => {
            const bundleCodes = bundleList.map(b => b.serviceBundleCode);
            // if there is no fare for specified bundle, add not available fare on correct index
            if (!(this.isFareForBundle(flight.fares, bundleCodes))) {
              flight.fares.splice(bundleListIndex, 0, {
                "passengerFares": [{
                  "notAvailable": true,
                  "discountedFare": '0',
                  "publishedFare": '0',
                  "fareAmount": '0',
                  "serviceCharges": { "items": [] }
                }]
              });
            }
          });
        } else {
          if (flight.fares.length === this.sortedAvailableBundles.length) {
            let result = true;
            let sortedAvailableBundles = [];
            sortedAvailableBundles = this.sortedAvailableBundles.reduce((a, b) => a.concat(b), []);
            flight.fares.forEach((fare, index) => {
              if (fare.defaultIncludedBundleCode !== sortedAvailableBundles[index].serviceBundleCode) {
                result = false;
              }
            });
            if (!result) {
              flight.fares.forEach(f => {
                f.sortedAvailableBundleIndex = sortedAvailableBundles.findIndex(s => s.serviceBundleCode === f.defaultIncludedBundleCode);
              });
              flight.fares.sort((a, b) => (a.sortedAvailableBundleIndex > b.sortedAvailableBundleIndex) ? 1 : -1);
            }
          }
        }
      });
    });
  }

  isValidPromo(fare) {
    if (this.showBlueBenefits) {
      return false;
    }
    return this.getCorrectAmount(this.getFullPrice(fare)) >
      this.getCorrectAmount(fare.passengerFares[0].discountedFare);
  }


  isPromo(fare) {
    if (this.showBlueBenefits) {
      return false;
    }
    return this.getCorrectAmount(fare.passengerFares[0].publishedFare) !=
      this.getCorrectAmount(fare.passengerFares[0].discountedFare);
  }

  getFarePrice(fare, isBlueBenefitsSelected: boolean) {

    // return this.getCorrectAmount(isBlueBenefitsSelected || this.isPromo(fare) ?
    //   fare.passengerFares[0].fareAmount : fare.passengerFares[0].publishedFare);
    return this.getCorrectAmount(fare.passengerFares[0].fareAmount);
  }

  getCorrectAmount(fareAmount) {
    const price = this.oldJourneyFares != null ? fareAmount - this.oldJourneyFares[this.tripIndex].value : fareAmount;
    // const price = fareAmount;
    return price < 0 ? 0 : price;
  }

  getConnectionTime(segments) {
    let firstSegmetn = segments[0];
    let lastSegmetn = segments.reverse()[0];


    return;//this.durationPipe.transform(firstSegmetn.sta, lastSegmetn.std, false);

  }
  howManiUsers(fd) {

    if (fd.flightCount == 0 || !this.userSearches.length)
      return 0;

    const key = fd.flights[0].origin + fd.flights[0].destination + moment(fd.departureDate).format("YYYY/MM/DD");

    const s = this.userSearches.find(p => p.searchedRoute == key);
    if (s)
      return s.count;
    else
      return 0;

  }

  isBus(legs: any, segment: any) {
    const leg = legs.find(l => l.arrivalStation === segment.arrivalStation && l.departureStation === segment.departureStation);
    if (leg && leg.legInfo.equimentType === "BUS")
      return true;
  }


  getFullPrice(price) {

    return price.passengerFares[0].serviceCharges.items.filter(p => p.chargeType == "FarePrice").map(p => p.amount).reduce((a, b) => a + b, 0) +
      price.passengerFares[0].serviceCharges.items.filter(p => p.chargeType == "FareSurcharge").map(p => p.amount).reduce((a, b) => a + b, 0)
      //-price.passengerFares[0].serviceCharges.items.filter(p=>p.chargeType =="Discount").map(p=>p.amount).reduce((a,b)=>a+b,0)
      ;

  }
  noFlights(trip) {
    return trip.flightDates.map(p => p.flights).reduce((a, b) => a.concat(b), []).length === 0;
  }
  isFareSelected(flight, fare, isBlueBenefitsSelected: boolean) {

    if (this.currentSelection.flightKey === flight.sellKey &&
      this.currentSelection.fare === fare.fareBasisCode &&
      this.isBlueBenefitsSelected === isBlueBenefitsSelected) {
      return true;
    }


    return false;
  }

  isBundleSelected(bundleCode, flight = null) {
    if (this.currentBundleSelected != this.bookingFareSelectSyncService.currentBundleSelected) {
      this.updateAllPriceSelected(this.bookingFareSelectSyncService.currentBundleSelected);
    }
    if (this.bookingFareSelectSyncService.isBlueBenefitsSelected != this.isBlueBenefitsSelected)
      this.isBlueBenefitsSelected = this.bookingFareSelectSyncService.isBlueBenefitsSelected;
    if (bundleCode == this.bookingFareSelectSyncService.currentBundleSelected)
      return true;

    return false;
  }

  checkState(price: any, flight: any, currentSelection: any, isBlueBenefitsSelected: boolean) {
    return (!price.passengerFares[0].notAvailable && !price.disableFare) && this.isFlightSelected(flight, price, isBlueBenefitsSelected)
      // TODO: why is revenueFare used here !?
      && currentSelection.price < this.getCorrectAmount(this.showBlueBenefits ? price.passengerFares[0].publishedFare : price.passengerFares[0].revenueFare)
      && this.getFarePrice(price, isBlueBenefitsSelected) > currentSelection.price
      && this.isBlueBenefitsSelected === isBlueBenefitsSelected;
  }

  isFlightSelected(flight, fare, isBlueBenefitsSelected) {
    if (this.currentSelection.flightKey === flight.sellKey && this.currentSelection.fare !== fare.fareBasisCode &&
      this.isBlueBenefitsSelected === isBlueBenefitsSelected) {
      return true;
    }

    return false;
  }

  isCurrentSelection(flight) {
    if (this.currentSelection.flightKey === flight.sellKey) {
      return true;
    }

    return false;
  }

  isNextDay(departureDate: any, arrivalDate: any) {
    if (moment(departureDate).date() !== moment(arrivalDate).date()) {
      return true;
    }
  }
  sellFare(
    flight,
    fare,
    isBlueBenefitSelected: boolean,
    isFromAutoSell?: boolean,
    autoSelect: boolean = false) {

    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);

    if (this.bookingStepsFlowService.flow === ApplicationFlowEnum.ManageBooking) {
      this.updateAllPriceSelected(this.bundleV2[0].serviceBundleCode);
      fare = flight.basePrice[0];
    }

    const sellFareObservable = this._sellFareWithoutSpinner(flight, fare, isBlueBenefitSelected, isFromAutoSell);
    if (sellFareObservable) {
      const subscription = sellFareObservable.subscribe(() => {

        subscription.unsubscribe();

        // if (autoSelect && fare.defaultIncludedBundleCode !== "BPLU") {
        //   this.selectBundleByCode(flight, "BPLU");
        // }

        this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
      });
    } else {
      this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
    }
  }
  private _sellFareWithoutSpinner(flight, fare, isBlueBenefitSelected: boolean, isFromAutoSell?: boolean): Observable<any> {
    this.bookingFareSelectSyncService.currentBundleSelected = fare.defaultIncludedBundleCode;
    this.bookingFareSelectSyncService.isBlueBenefitsSelected = isBlueBenefitSelected;
    this.currentBundleSelected = fare.defaultIncludedBundleCode;


    this.toggleFares = true;
    let openPopup = false;
    if (this.sellService.searchModel.isRoundTrip && this.flights[0] &&
      moment(this.sellService.searchModel.departureDate).isSame(moment(this.sellService.searchModel.returnDate))) {
      const isOutboundFirst = flight.origin !== this.sellService.searchModel.origin ? true : false;
      if (isOutboundFirst) {
        if (flight.origin !== this.flights[0].departureStation.iataCode &&
          moment(this.flights.find(f => f.departureStation.iataCode !== flight.origin).arrivalStation.date).isAfter(moment(flight.standardTimeOfDeparture))) {
          openPopup = true;
        }
      } else {
        if (this.flights.length >= 1 && flight.origin !== this.flights[this.flights.length - 1].departureStation.iataCode &&
          moment(this.flights.find(f => f.departureStation.iataCode !== flight.origin).departureStation.date).isBefore(moment(flight.standardTimeOfArrival))) {
          openPopup = true;
        }
      }
    }

    if (openPopup) {
      this.incorrectSelectionModal.openPopup(({}));
    } else {
      let id = '';
      flight.segments.forEach(s => {
        id = id + s.flightDesignator.carrierCode + ' ' + s.flightDesignator.flightNumber + ' ' + moment(s.std).format('DD-MM-YYYY HH:mm:ss') + ' ';
      });
      let total = 0;
      fare.passengerFares.forEach(f => {
        const paxType = f.paxType.substring(0, 3);
        if (paxType !== Constants.InfantSsrCode) {
          total = total + f.fareAmount * this.sellService.searchModel.passengers.find(p => p.paxType.substring(0, 3) === paxType).count;
        }
      });
      const ecommerceCartItems = new Array();
      const item = new ECommerceCartItem(
        flight.origin + '-' + flight.destination, id,
        total.toString(), 'BlueAir', fare.serviceBundleSetCode ? fare.serviceBundleSetCode.toString() : null,
        this.sellService.searchModel.isRoundTrip ? Constants.RoundTrip : Constants.OneWayTrip,
        1, this.sellService.searchModel.isRoundTrip ? (flight.origin !== this.sellService.searchModel.origin ? 1 : 0) : 0,
        null, this.ecommerce.getUserData()
      )
        .getItem(true);
      ecommerceCartItems.push(item);
      this.ecommerce.ProductClicks(ecommerceCartItems);

      // if (this.currentSelection.fare === fare.fareBasisCode && this.currentSelection.flightKey === flight.sellKey) {
      //   // if same flight and same fare is selected, remove current selection from view
      //   this.currentSelection = {
      //     flightKey: '',
      //     fare: '',
      //     price: 0
      //   };

      //   if (this.isBlueBenefitsSelected != isBlueBenefitSelected) {
      //     // if same flight and same fare are selected BUT 'isBlueBenefitsSelected' param is different:
      //     // - the first selection was already removed
      //     // - select the other price
      //     this.setCurrentSelection(fare, flight, isBlueBenefitSelected);
      //   } else {
      //     flight = null;
      //     fare = null;
      //   }
      // } else {
      //   this.setCurrentSelection(fare, flight, isBlueBenefitSelected);
      // }
      this.setCurrentSelection(fare, flight, isBlueBenefitSelected);
      this.isBlueBenefitsSelected = isBlueBenefitSelected;
      this.sellService.isBlueBenefitsSelected = isBlueBenefitSelected;
      this.isBlueBenefitsSelectedEvent.emit(this.isBlueBenefitsSelected);
      // if blue benefits prices are shown and
      // price selected is without blue benefits don't substract discount
      // from final fare price in order to display correct price in shopping cart
      const updatePriceItineraryResult = this.sellService.updatePriceItinerary(flight, fare, this.tripIndex);
      if (!isFromAutoSell) {
        const subscription = updatePriceItineraryResult.subscribe(() => {
          this.onFareSold.emit({
            tripIndex: this.tripIndex,
            isBlueBenefits: isBlueBenefitSelected
          });
          subscription.unsubscribe();
        });
      }
      return updatePriceItineraryResult;
    }
  }

  public emptyCurrentSelection() {
    // deselect current flight
    this.currentSelection = {
      flightKey: '',
      fare: '',
      price: 0
    };
    // remove current flight from cart
    this.sellService.updatePriceItinerary(null, null, this.tripIndex);
  }

  private setCurrentSelection(fare, flight, isBlueBenefitSelected) {
    this.currentSelection.fare = fare.fareBasisCode;
    this.currentSelection.flightKey = flight.sellKey;
    this.currentSelection.price = this.getCorrectAmount(isBlueBenefitSelected || this.isPromo(fare) ?
      fare.passengerFares[0].fareAmount : fare.passengerFares[0].publishedFare);
  }

  changeDay(i) {
    let x = i == 0 ? -1 : 1;
    this.changeDate(moment(this.tripDate).add(x, "days"));
  }


  changeDate(date) {
    if (moment(date).diff(moment(), 'day') >= 0) {
      const ss = this.sellService;
      if (ss.searchModel.origin === this.trip.origin) {
        ss.searchModel.departureDate = date;
      } else {
        ss.searchModel.returnDate = date;
      }
      if (ss.searchModel.returnDate) {
        if (moment(ss.searchModel.departureDate).isAfter(moment(ss.searchModel.returnDate))) {
          const days = moment(ss.searchModel.departureDate).diff(moment(ss.searchModel.returnDate), 'day');
          if (this.tripIndex === 0) {
            ss.searchModel.returnDate = (moment(ss.searchModel.returnDate).add(days, 'day')).toString();
            this.resetCart();
          } else {
            ss.searchModel.departureDate = (moment(ss.searchModel.departureDate).add(-days, 'day')).toString();
            this.resetCart();
          }
        }
      }
      this.datechanged.next();
    }
  }
  resetCart() {
    this.sellService.resetPriceItinerary();
    this.selectionService.updateData(null, null);
    this.bookingFlowService.updateShoppingCartFromItinerary(null, true);
  }

  openModalDialog(f) {
    this.modalService.open(f, () => { });
  }

  getOperatedText(l: any) {
    let legs = new Array();
    const operatedText = new Array();
    // if (l.operatingCarrierInfo.carrierCode && l.operatingCarrierInfo.flightNumber) {
    //   operatedText.push(this.translateService.instant('Operated by {0} as {1}', null, [
    //     this.translateService.instant(l.operatingCarrierInfo.carrierCode, 'operatingcarrier.name'),
    //     l.operatingCarrierInfo.carrierCode + l.operatingCarrierInfo.flightNumber
    //   ]));
    //   return operatedText;
    // } else {
    legs = l.legs.map(p => p.legInfo).reduce((a, b) => a.concat(b), [])
      .filter(p => p.codeShareIndicator === 'L' || p.codeShareIndicator === 'S');
    if (legs.length > 0) {
      legs.forEach(f => {
        operatedText.push(this.translateService.instant('Operated by {0} as {1}', null, [
          this.translateService.instant(f.operatingCarrier, 'operatingcarrier.name'),
          f.operatingCarrier + f.operatingFlightNumber
        ]));
      });
      return operatedText;
    }
    // }
  }

  getNumberOfStops(flight: any) {
    var stops = new Array();

    for (let i = 0; i < flight.segments.length - 1; i++) {
      if (stops.indexOf(flight.segments[i].arrivalStation) == -1) {
        stops.push(flight.segments[i].arrivalStation);
      }
    }
    return stops.length;
  }

  toggleVisibility(th: ElementRef) {
    if ((th as any).classList.contains('show')) {
      this.renderer.removeClass(th, 'show');
    } else {
      this.renderer.addClass(th, 'show');
    }
  }

  toggleVisibilityDesktop() {
    this.multipleBundleList = !this.multipleBundleList;
  }

  toggleSearch(newValue: boolean = !this.isChangeSearchOpened) {
    // this.isChangeSearchOpened = newValue;
    this.isChangeSearchOpenedEvent.emit(this.isChangeSearchOpened);
  }

  getFareBundleText(price: any, departureDate: string) {
    const bundleCode = price.defaultIncludedBundleCode;

    if (bundleCode && this.availableBundles
      .filter(x => x.serviceBundleCode === bundleCode)[0]) {
      const currentBundleItems = this.availableBundles
        .filter(x => x.serviceBundleCode === bundleCode)[0].serviceBundlePriceList
        .map(x => x.serviceBundleSsrPriceList).reduce((a, b) => a.concat(b), [])
        .map(x => this.ssrTranslate.transform(x.ssrCode, bundleCode, departureDate));

      return currentBundleItems.join(', ');
    }

    return '';
  }

  getBundleImage(bundleCode, ssrCode) {
    const myUrl = this.imagesPath + this.translateService.instant(`${bundleCode}.${ssrCode}`, 'BundleImages');
    return myUrl;
  }

  flightHasBenefitDiscount(flight) {
    return flight.fares.every(f => f.passengerFares[0].publishedFare != f.passengerFares[0].fareAmount);
  }

  selectBundle(bundle, flight) {
    this.loadingSpinnerService.showSpinnerById(this.spinnerId);

    this.bookingFareSelectSyncService.currentBundleSelected = bundle.serviceBundleCode;
    //this.bookingFareSelectSyncService.isBlueBenefitsSelected = this.isBlueBenefitsSelected;
    this.updateAllPriceSelected(bundle.serviceBundleCode);

    let fare = flight.bundlePrices.filter(p => p.defaultIncludedBundleCode.match(bundle.serviceBundleCode))[0];

    this.sellFare(flight, fare, this.isBlueBenefitsSelected);

    this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
  }

  selectBundleByCode(flight: any, bundleCode: string): void {
    this.loadingSpinnerService.showSpinnerById(this.spinnerId);

    this.bookingFareSelectSyncService.currentBundleSelected = bundleCode;
    //this.bookingFareSelectSyncService.isBlueBenefitsSelected = this.isBlueBenefitsSelected;
    this.updateAllPriceSelected(bundleCode);

    let fare = flight.bundlePrices.filter(p => p.defaultIncludedBundleCode.match(bundleCode))[0];

    this.sellFare(flight, fare, false, false, true);

    this.loadingSpinnerService.hideSpinnerById(this.spinnerId);

  }

  updateAllPriceSelected(bundleCode) {
    this.trip.flightDates.forEach(fd => {
      fd.flights.forEach(flight => {
        if (!flight.bundlePrices.filter(p => p.defaultIncludedBundleCode.match(bundleCode))[0]) {
          this.addFlightBundlePrice(flight, bundleCode);
        }
        let fare = flight.bundlePrices.filter(p => p.defaultIncludedBundleCode.match(bundleCode))[0];

        flight.basePrice[0] = fare;
      });
    })
  }

  addFlightBundlePrice(flight, bundleCode) {
    let fare = JSON.parse(JSON.stringify(flight.fares[flight.fares.length - 1]));
    let bundlePrice = this.bundleV2.filter(b => b.serviceBundleCode.match(bundleCode))[0].serviceBundlePriceList[0].price;
    let discountPrice = parseInt(fare.passengerFares[0].fareDiscountCode.replace(/\'/g, '').split(/(\d+)/).filter(Boolean)[0]);
    if (isNaN(discountPrice)) {
      discountPrice = 0;
    }
    fare.defaultIncludedBundleCode = bundleCode;
    const passengerFare = fare.passengerFares[0];
    const discountedFare = passengerFare.publishedFare - passengerFare.publishedFare * discountPrice / 100;
    //passengerFare.publishedFare = parseFloat(passengerFare.publishedFare + bundlePrice).toFixed(2);
    passengerFare.fareAmount = parseFloat(String(discountedFare + bundlePrice)).toFixed(2);

    flight.bundlePrices.push(fare);
  }

  getBundlePrice(flight, bundle, isBlueBenefitSelected) {

    let basePrice = flight.fares[flight.fares.length - 1].passengerFares[0].publishedFare;
    let extraPrice = bundle.serviceBundlePriceList[0].price;
    let discountPrice = parseInt(flight.fares[flight.fares.length - 1].passengerFares[0].fareDiscountCode.replace(/\'/g, '').split(/(\d+)/).filter(Boolean)[0]);
    let resultAmount = basePrice;

    if (!isNaN(discountPrice)) {
      resultAmount = parseFloat(basePrice) * (1 - discountPrice / 100);
    }
    let total = parseFloat(resultAmount + extraPrice);

    if (!this.isBundleSelected(bundle.serviceBundleCode, null)) {
      if (this.currentSelectedAmount < total) {
        return `+${(total - this.currentSelectedAmount).toFixed(2)}`;
      }
    }
    else {
      this.currentSelectedAmount = total;
    }
    return total.toFixed(2);
  }

  updateSellKeys(flight, tripIndex) {
    const index = this.sellService.sellKeys.findIndex(p => p.key === tripIndex)
    flight.isMovedFlight = true;
    let fare = flight.fares[0];

    if ((flight == null || fare == null) && index >= 0) {
      this.sellService.sellKeys.splice(index, 1);
    } else {
      if (index >= 0) {
        this.sellService.sellKeys[index] = { key: tripIndex, value: fare.sellKey + '|' + flight.sellKey };
      } else {
        this.sellService.sellKeys.splice(tripIndex, 0, { key: tripIndex, value: fare.sellKey + '|' + flight.sellKey });
      }
    }

    if ((this.sellService.searchModel.isRoundTrip && this.sellService.sellKeys.length > 1) || (!this.sellService.searchModel.isRoundTrip && this.sellService.sellKeys.length === 1)) {
      this.sellService.canContinue = true;
    } else {
      this.sellService.canContinue = false;
    }
  }

  isMoveFlightSelected(flight) {
    if (flight.isMovedFlight) {
      return true;
    } else {
      return false;
    }
  }

  upgradeBundleFromPopup(bundleCode: string) {
    let fare = this.bundleV2.find(b => b.serviceBundleCode === bundleCode);
    let flight = this.trip.flightDates[0].flights.find(f => f.sellKey === this.flights[0].sellKey)
    this.selectBundle(fare, flight)
  }
}
