import { ShoppingCartFlightModel } from './shopping-cart-flight-model';
import { ShoppingCartExtrasModel } from './shopping-cart-extras-model';
var ShoppingCartBreakdownItem = /** @class */ (function () {
    function ShoppingCartBreakdownItem() {
        this.flights = [];
        this.extras = [];
        this.balanceDue = 0;
    }
    Object.defineProperty(ShoppingCartBreakdownItem.prototype, "balanceDue", {
        get: function () { return this._balanceDue; },
        set: function (value) {
            this.initialBalanceDue = Math.max(value, 0);
            if (!this.isBalanceDueOverridden) {
                this._balanceDue = this.initialBalanceDue;
            }
        },
        enumerable: true,
        configurable: true
    });
    ShoppingCartBreakdownItem.prototype._isBundleUpgrade = function (extra) {
        return (extra.ssrTypeCode === 'BundleUpgrade');
    };
    ShoppingCartBreakdownItem.prototype.init = function (journeys, extras, balanceDue) {
        var _this = this;
        this.flights = journeys.map(function (j) { return new ShoppingCartFlightModel().init(j); });
        this.extras = extras.filter(function (e) { return !_this._isBundleUpgrade(e); }).map(function (e) { return new ShoppingCartExtrasModel().init(e); });
        this.balanceDue = balanceDue;
        this._applyBundleUpgrades(this.flights, extras);
        return this;
    };
    ShoppingCartBreakdownItem.prototype._groupPassengersUpgradeBundlesBySegment = function (upgradeBundle) {
        var result = {};
        upgradeBundle.journeys.forEach(function (journey) {
            journey.segments.forEach(function (segment) {
                var segmentKey = segment.departureStation + segment.arrivalStation;
                result[segmentKey] = segment.passengers;
            });
        });
        return result;
    };
    ShoppingCartBreakdownItem.prototype._applyBundleUpgrades = function (journeys, extras) {
        var _this = this;
        var upgradeBundle = extras.filter(function (e) { return _this._isBundleUpgrade(e); })[0];
        if (!upgradeBundle) {
            return;
        }
        var bundleUpgradesBySegment = this._groupPassengersUpgradeBundlesBySegment(upgradeBundle);
        journeys.forEach(function (journey) {
            journey.segments.forEach(function (segment) {
                var segmentKey = segment.departureStation + segment.arrivalStation;
                var segmentBundleUpgrade = bundleUpgradesBySegment[segmentKey];
                segment.passengers.forEach(function (passengerType) {
                    for (var i = 1; i <= passengerType.count; i++) {
                        var bundleUpgrade = segmentBundleUpgrade.shift();
                        if (bundleUpgrade) {
                            passengerType.amount += bundleUpgrade.totalAmount;
                            segment.amount += bundleUpgrade.totalAmount;
                            journey.amount += bundleUpgrade.totalAmount;
                        }
                    }
                });
            });
        });
    };
    ShoppingCartBreakdownItem.prototype.overrideBalanceDue = function (newBalanceDue) {
        if (newBalanceDue == null) {
            this._balanceDue = this.initialBalanceDue;
            this.isBalanceDueOverridden = false;
        }
        else {
            this._balanceDue = newBalanceDue;
            this.isBalanceDueOverridden = true;
        }
    };
    ShoppingCartBreakdownItem.prototype.checkExtrasForSsrOrFeeCode = function (ssrType, ssrCode) {
        var val = this.extras
            .some(function (e) { return e.ssrType === ssrType && e.flightsWithItems
            .some(function (f) { return f.segments
            .some(function (s) { return s.passengers
            .some(function (p) { return p.items
            .some(function (i) { return i.ssrCode === ssrCode; }); }); }); }); });
        // another way to do it
        // this.extras
        // .filter(e => e.ssrType === ssrType)
        // .reduce((flights, e) => flights.concat(e.flightsWithItems), [])
        // .reduce((segments, f) => segments.concat(f.segments), [])
        // .reduce((passengers, s) => passengers.concat(s.passengers), [])
        // .some((p: ShoppingCartExtrasPassengerModel) => p.items.some(i => i.ssrCode === ssrCode));
        return val;
    };
    return ShoppingCartBreakdownItem;
}());
export { ShoppingCartBreakdownItem };
