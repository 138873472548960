import { Injectable } from '@angular/core';
import { LocalStorage, SessionStorage } from 'ngx-webstorage';
import * as CryptoJs from 'crypto-js';

import { ConfigService } from '../../core/config.service';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { isNullOrUndefined } from 'util';

declare global {
  interface Window { getSession: any; setSession: any; }
}

@Injectable()
export class SessionService {
  // 15minutes
  private static readonly SessionTimeoutLimit = 15 * 60000;
  // 1minute
  private static readonly SessionWarningLimit = 1 * 60000;

  private _sessionUrl: string;
  private sessionIsValid: boolean;
  private sessionVerifyInterval: any;

  public sessionNotification: BehaviorSubject<ISessionNotification> =
    new BehaviorSubject<ISessionNotification>({ isExpired: false, isExpiring: false });

  @SessionStorage()
  public sessionId: string;
  @SessionStorage()
  public sessionSig: string;
  @SessionStorage()
  public sessionDate: string;

  constructor(private http: HttpClient, private config: ConfigService) { }

  load(sessionUrl: string = null): Promise<any> {
    // this.sessionId = null;
    // this.sessionSig = null;
    // this.sessionDate = null;
    if (sessionUrl) {
      this._sessionUrl = sessionUrl;
    }
    return this.refreshSessionInternally();
  }

  set(sessionId: string): Promise<any> {

    // if (window.setSession) {
    //   return window.setSession(sessionId).then((s) => {
    //     this.setInternally(s.SessionId, s.SessionSig);
    //   },
    //     () => { }
    //   );
    // }
    this.setInternally(sessionId);
    return Promise.resolve();
  }

  isSessionValid(): boolean {
    if (!this.sessionDate || !this.sessionId || !this.sessionSig) {
      return false;
    }

    return this.sessionIsValid;
  }

  hashSession(sessionId: string): string {
    const str = 'X-Session-Id' + sessionId + '@#$ASJDFKLJ@#$UJ@#$J%KLJZSDKLFJ'; //this.config.config.webApiKey;;
    return CryptoJs.SHA256(str).toString(CryptoJs.enc.SHA256);
  }

  keepAlive(): void {
    this.sessionDate = moment().toISOString();
    this.checkSession();
  }

  refreshSession(): Promise<boolean> {
    // re-add the session so it can slide the expiration
    this.set(this.sessionId);
    return Promise.resolve(true);
  }

  resetSession(): void {
    this.loadSessionFromApi(true);
    //  return;
    // this.sessionId = null;
    // this.sessionSig = null;
    // this.sessionDate = null;
  }

  ensureSessionIsValid(): Promise<boolean> {
    if (this.isSessionValid()) {
      return Promise.resolve(true);
    }
    this.resetSession();
    return this.refreshSessionInternally(true)
      .then(
        () => true,
        () => false
      );
  }

  private async refreshSessionInternally(forced: boolean = false): Promise<any> {
    // if (window.getSession) {
    //   // try get session from this tab

    //   return window.getSession(forced).then((s) => {
    //     this.setInternally(s.SessionId, s.SessionSig);
    //   },
    //     // default to normal session loading
    //     () => this.loadSessionFromApi(forced));
    // }

    if (!forced && !isNullOrUndefined(this.sessionId)) {
      await this.set(this.sessionId);
      return;
    }

    // default to normal session loading
    return this.loadSessionFromApi(forced);
  }



  private setInternally(sessionId: string, sessionSig: string = null) {
    this.sessionId = sessionId;
    this.sessionSig = sessionSig || this.hashSession(this.sessionId);
    this.sessionDate = moment().toISOString();
    this.checkSession();

    this.setupSessionWarning();
  }

  private loadSessionFromApi(forceRefresh: boolean = false): Promise<any> {
    if (!forceRefresh) {
      this.checkSession();
      if (this.isSessionValid()) {
        this.setupSessionWarning()
        return Promise.resolve();
      }
    }
    // if (!forceRefresh && this.isSessionValid()) {
    //   return Promise.resolve();
    // }

    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');

    return this.http.post<HttpResponse<Object>>(this._sessionUrl, null, { observe: 'response', headers: headers })
      .toPromise()
      .then(response => {
        this.setInternally(response.headers.get('X-Session-Id'));
      });
  }

  private checkSession() {
    const now: Date = new Date();
    const timeElapsed = now.getTime() - (new Date(this.sessionDate)).getTime();

    const difference = SessionService.SessionTimeoutLimit - timeElapsed;

    this.sessionIsValid = difference > 0;
    if (difference <= SessionService.SessionWarningLimit) {
      this.sessionNotification.next({
        isExpired: !this.sessionIsValid,
        isExpiring: this.sessionIsValid,
        timeLeftToExpire: {
          minutes: Math.floor(difference / 1000 / 60),
          seconds: Math.floor(difference / 1000 % 60)
        }
      });
    } else if (this.sessionNotification.value.isExpired || this.sessionNotification.value.isExpiring) {
      this.sessionNotification.next({
        isExpired: false,
        isExpiring: false
      });
    }
  }

  private setupSessionWarning() {
    if (this.sessionVerifyInterval) {
      clearInterval(this.sessionVerifyInterval);
    }
    this.sessionVerifyInterval = setInterval(() => this.checkSession(), 1000);
  }
}

export interface ISessionNotification {
  isExpiring: boolean;
  timeLeftToExpire?: { minutes: number, seconds: number };
  isExpired: boolean;
}
