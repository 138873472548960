import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { BookingFlightSelectService } from 'src/app/booking-flight-select/booking-flight-select.service';
import { ApplicationFlowEnum, BookingStepsService, CheckinSteps } from 'src/app/core/booking-steps.service';
import { ConfigService } from 'src/app/core/config.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { ProfileService } from 'src/app/core/profile.service';

@Component({
  selector: 'booking-promo-code',
  templateUrl: './promo-code.component.html',
  styleUrls: ['./promo-code.component.scss']
})
export class PromoCodeComponent implements OnInit, OnDestroy {
  private isPromotionAppliedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private shoppingCartSubscription: Subscription;
  private currentStepsSubscription: Subscription;
  private isPromotionAppliedSubscription: Subscription;
  private firstRun = true;

  promoCode: string;
  invalidCode = false;
  errorMessages: Array<any> = [];
  promotionApplied = false;
  promoCodes: Array<any> = [];
  showPromoCodeWidget = true;

  @Input() showMargins: boolean;
  @Output() promoCodeChanged: EventEmitter<string> = new EventEmitter();

  constructor(private profileService: ProfileService, private flowManager: FlowManagerService, private configService: ConfigService,
    private bookingStepsService: BookingStepsService, private bookingFlightSelectService: BookingFlightSelectService) { }

  ngOnInit() {
    if (this.profileService.isAgent) {
      this.showPromoCodeWidget = false;
    } else {
      this.currentStepsSubscription = this.bookingStepsService.currentStep.subscribe((p: CheckinSteps) => {
        if (this.bookingStepsService.flow !== ApplicationFlowEnum.Booking || p === CheckinSteps.flights) {
          this.showPromoCodeWidget = false;
        } else {
          this.showPromoCodeWidget = true;
        }
      });
    }

    this.shoppingCartSubscription = this.flowManager
      .applicationFlowService
      .shoppingCartBreakdown.pipe(filter(b => b ? true : false)).subscribe(value => {
        const isPromotionApplied = value.currentShoppingCart.flights.some(f =>
          f.segments.some(s =>
            s.passengers.some(p =>
              p.fees.some(fe =>
                fe.feeCode === 'PromotionDiscount' || fe.discountCode && fe.discountCode.length > 0
              )
            )
          )
        );

        this.isPromotionAppliedSubject.next(isPromotionApplied);
      });

    this.isPromotionAppliedSubscription = this.isPromotionAppliedSubject
      .pipe(distinctUntilChanged())
      .subscribe(isPromotionApplied => {
      if (isPromotionApplied) {
        this.bookingFlightSelectService.getPromo().subscribe(promoCode => {
          if (promoCode) {
            this.promotionApplied = true;
            this.promoCodes = [promoCode];
            this.triggerPromoCodeChanged(promoCode);
          } else {
            this.promoCodes = [];
            this.promotionApplied = false;
            this.triggerPromoCodeChanged(null);
          }
        });
      } else {
        this.promotionApplied = false;
        this.triggerPromoCodeChanged(null);
      }
    });
  }

  ngOnDestroy() {
    if (this.shoppingCartSubscription) {
      this.shoppingCartSubscription.unsubscribe();
    }
    if (this.currentStepsSubscription) {
      this.currentStepsSubscription.unsubscribe();
    }
    if (this.isPromotionAppliedSubscription) {
      this.isPromotionAppliedSubscription.unsubscribe();
    }
  }

  deletePromo() {
    this.errorMessages = [];
    this.bookingFlightSelectService.deletePromotion().subscribe((data: any) => {
      if (data) {
        this.promoCode = '';
        if (!data.deletePromoCode.promoCode) {
          this.isPromotionAppliedSubject.next(false);
        }
      }
      this.flowManager.applicationFlowService.loadPriceBreakdown(true);
    });
  }
  applyPromo() {
    if (!this.promoCode) {
      return;
    }
    this.errorMessages = [];
    this.bookingFlightSelectService.applyPromo(this.promoCode).subscribe((data: any) => {
      this.errorMessages = [];
      if (data) {
        if (data.errors && data.errors.length) {
          this.invalidCode = true;
          this.isPromotionAppliedSubject.next(false);
          this.promoCode = '';
          this.errorMessages = data.errors.map(p => p.errorCode);
        } else if (data.promotion.promotionAllowed && data.promotion.isInPromotion) {
          // this.promotionApplied = true;
          // this.promoCode = '';
          // reload shoppingcart
          this.flowManager.applicationFlowService.loadPriceBreakdown(true);
        } else {
          // error
          this.invalidCode = true;
          this.isPromotionAppliedSubject.next(false);
          this.promoCode = '';
          // this.errorMessages = ['PromotionNotApplied'];
        }
      }

    }, (ex: any) => {
      this.invalidCode = true;
      this.promoCode = '';
      this.errorMessages = ex.error.errors.map(p => p.errorCode);
    });
  }

  private triggerPromoCodeChanged(promoCode: string) {
    if (!this.firstRun) {
      this.promoCodeChanged.next(promoCode);
      this.promoCode = promoCode;
    }
    this.firstRun = false;
  }
}
