import { Component, OnInit, Output, EventEmitter, NgZone, OnDestroy, Input } from '@angular/core';
import { ConfigService } from 'src/app/core/config.service';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { Subscription } from 'rxjs';
import { EnvHelper } from 'src/app/env-helper';
import { Location } from '@angular/common';
import { SessionService } from 'src/app/common-modules/blue-air-common/session.service';
import { PushToWalletStepsEnum } from '../push-to-wallet-container/push-to-wallet-container.component';

declare global {
  interface Window { commonFunctions: any; }
}

@Component({
  selector: 'booking-ptw-authenticate',
  templateUrl: './ptw-authenticate.component.html',
  styleUrls: ['./ptw-authenticate.component.scss']
})
export class PtwAuthenticateComponent implements OnInit, OnDestroy {
  private loggedInSubscription: Subscription;

  @Output() continue: EventEmitter<boolean> = new EventEmitter();

  public isLoading = true;
  public createAccountUrl: string;

  constructor(configService: ConfigService, translateService: TranslateService,
    private userStateService: UserStateService, private sessionService: SessionService, private location: Location) {
    const returnUrl = this.prepareExternalUrl('/ptw', { step: PushToWalletStepsEnum.Login.toString()});
    // tslint:disable-next-line: max-line-length
    this.createAccountUrl = `${configService.config.corporateUrl}Membership/Redirect/CreateAccount?culture=${translateService.culture}&returnUrl=${returnUrl}`;

  }

  ngOnInit() {
    if (EnvHelper.IsOnAgencyPortal()) {
      this.continue.next(true);
    } else {
      this.loggedInSubscription = this.userStateService.isLoggedIn.subscribe(loggedIn => {
        if (loggedIn) {
          this.continue.next(loggedIn);
        }
        this.isLoading = false;
      });
    }
  }

  openLoginPopup(event) {
    event.preventDefault();
    window.commonFunctions.openLoginPopup();
  }

  ngOnDestroy() {
    if (this.loggedInSubscription) {
      this.loggedInSubscription.unsubscribe();
    }
  }

  private prepareExternalUrl(path: string, queryParams: { [key: string]: string }): string {
    const sessionUrlPart = `/session/${this.sessionService.sessionId}?path=`;
    let url = `${sessionUrlPart}${path}`;

    if (queryParams) {
      for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
          url += `&${key}=${queryParams[key]}`;
        }
      }
    }

    return this.location.prepareExternalUrl(url);
  }
}
