import { Component, OnInit, Input, ElementRef, Renderer2, AfterViewInit, ViewChild, Output, HostListener } from '@angular/core';
import { SsrsService } from '../../../extras/ssrs.service';
import { SsrType } from '../../../extras/ssr-type.enum';
import { SsrBoxContentBase } from '../../../extras/ssr-box-content-base/ssr-box-content-base';
import { LoadingSpinnerService } from '../../../common-modules/blue-air-common/loading-spinner.service';
import { SsrListDisplayDirective } from '../../../extras/ssr-list-display.directive';
import { CheckinAccordionItemComponent } from '../../../shared/checkin-accordion-item/checkin-accordion-item.component';
import { EventEmitter } from '@angular/core';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'check-in-single-passenger-special-assistance',
  templateUrl: './single-passenger-special-assistance.component.html',
  styleUrls: ['./single-passenger-special-assistance.component.scss'],
  viewProviders: [SsrListDisplayDirective]
})
export class SinglePassengerSpecialAssistanceComponent extends SsrBoxContentBase implements OnInit, AfterViewInit  {
  spinnerId = 'spinnerId';
  dropdownJourneyHeader: Element;
  ssrListClosePopupBtn: Element;
  ssrListClosePopupBtnBottom: Element;
  isExpanded: boolean;

  ssrsCustomIcons: {[ssrCode: string]: string} = {};

  constructor(private el: ElementRef,
              private renderer: Renderer2,
              private ssrsService: SsrsService,
              private loadingSpinnerService: LoadingSpinnerService) {
    super(ssrsService);

    this.ssrType = SsrType.SpecialAssistance;
    this.updateWindowWidth(window.innerWidth);
  }

  @Input()
  set passengerNumber(value: number){
    this.selectedPassenger = value;
  }

  @Input() isSSRInfoSet: boolean;

  @ViewChild(CheckinAccordionItemComponent, { static: true }) checkinAccordion: CheckinAccordionItemComponent;

  @Output() saveChangesEvent = new EventEmitter();

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWindowWidth(event.target.innerWidth);
  }
  
  ngOnInit() {
    this.generateSpinnerId();
    this.loadingSpinnerService.showSpinnerById(this.spinnerId);

    if (!this.isSSRInfoSet) {
      this._ssrsService.getSsrsInfo();
    }

    super.ngOnInit();
    this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
    
    this.ssrsCustomIcons = this.ssrsService.setupSpecialAssistanceCustomIcons(this.availableSsrs);
  }

  ngAfterViewInit() {
    this.dropdownJourneyHeader = this.el.nativeElement.querySelector('.checkin-acc-head');
    this.ssrListClosePopupBtn = this.el.nativeElement.querySelector('.close-popup');
    this.ssrListClosePopupBtnBottom = this.el.nativeElement.querySelector('.close-popup-bottom');

    this.renderer.listen(this.dropdownJourneyHeader, 'click', (event: any) => {
      this.isSsrsPopupOpened = this.mobileMatches;
    });
    this.renderer.listen(this.ssrListClosePopupBtn, 'click', (event: any) => {
      this.checkinAccordion.toggleExpanded();
    });
    this.renderer.listen(this.ssrListClosePopupBtnBottom, 'click', (event: any) => {
      this.checkinAccordion.toggleExpanded();
    });
  }
  protected allPassengersHaveTheSameSsrs(): boolean {
    const selectedSsrs = this.passengers[this.selectedPassenger].getSelectedSsrs();
    let result = true;
    selectedSsrs.forEach(s => {
        for (let i = 1; i < this.passengers.length; i++) {
            result = result &&
                this.passengers[i]
                    .getSelectedSsrs()
                    .findIndex(ps => ps.ssrCode === s.ssrCode && ps.selectedQuantity === s.selectedQuantity) >= 0;
        }
    });
    return result;
  }

  protected initSelectedPassenger() {
    if (this.passengers.length === 1) {
        this.selectedPassenger = 0;
    }

    this.numberOfSelectedPassengers = this.selectedPassenger === -1 ? this.passengers.length : 1;

    this.updateSelectedSsr();
  }

  generateSpinnerId() {
    this.spinnerId = this.spinnerId + '_' +
      this.flight.departureStation.iataCode + '_' +
      this.flight.arrivalStation.iataCode + '_' +
      this.selectedPassenger;
  }

  toggleSsr(ssr) {
    super.selectSsr(this.selectedPassenger, ssr.ssrCode, ssr.selectedQuantity > 0 ? 0 : (ssr.selectedQuantity + 1), false);

    this.saveChangesEvent.emit(this.selectedPassenger);
  }

  updateWindowWidth(width) {
    this.mobileMatches = width < Constants.mobileScreenWith;
    this.checkIsExpanded();
  }

  checkIsExpanded() {
    if (this.mobileMatches) {
      this.isExpanded = false;
      return;
    }
    
    this.isExpanded = true;
  }
}
