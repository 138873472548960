import * as i0 from "@angular/core";
var CanActivatePtwService = /** @class */ (function () {
    function CanActivatePtwService() {
    }
    CanActivatePtwService.prototype.canActivate = function (route, state) {
        return true;
    };
    CanActivatePtwService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CanActivatePtwService_Factory() { return new CanActivatePtwService(); }, token: CanActivatePtwService, providedIn: "root" });
    return CanActivatePtwService;
}());
export { CanActivatePtwService };
