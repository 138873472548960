import { TravelDocumentModel } from "./passenger-model";

export class PassengerDocumentsModel {
    nationality: string;
    docTypeCode: string;
    docNumber: string;
    birthCountry: string;
    issuedByCode: string;
    issuedDate: string;
    expirationDate: string;
    dateOfBirth: string;
    gender: GenderEnum;
    docSuffix: string;
    // Razvan: moved from booking to check-in
    passengerNumber: number;
    title: string; // mandatory
    firstName: string; // mandatory
    lastName: string; // mandatory
    addressLine1: string; // mandatory
    addressLine2: string;
    city: string;
    country: string;
    phoneNumber: string;// mandatory
    alternativePhoneNumber: string;
    emailAddress: string; // mandatory
    subscribeToNewsletter: string

    isValid = false;

    initFromTravelDocuments(travelDocuments: TravelDocumentModel[]) {
        if (!travelDocuments || !travelDocuments.length) {
            return;
        }

        const docs = travelDocuments.filter(d => this.docSuffix ? d.docSuffix === this.docSuffix : d.docSuffix == null);
        if (!docs.length) {
            return;
        }
        const document = docs[0];


        this.nationality = document.nationality;
        this.docTypeCode = document.docTypeCode;
        this.docNumber = document.docNumber;
        this.birthCountry = document.birthCountry;
        this.issuedByCode = document.issuedByCode;
        this.issuedDate = document.issuedDate;
        this.expirationDate = document.expirationDate;
        this.dateOfBirth = document.dateOfBirth;
        this.gender = document.gender;
        this.firstName = document.name.first;
        this.lastName = document.name.last;
        this.title = document.names[0].title;
    }
}

export enum GenderEnum {
    Male = 1,
    Female
}
