/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./accomodation.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./accomodation.component";
import * as i3 from "../../core/booking-flow.service";
import * as i4 from "../../common-modules/blue-air-common/translator/translate.service";
var styles_AccomodationComponent = [i0.styles];
var RenderType_AccomodationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AccomodationComponent, data: {} });
export { RenderType_AccomodationComponent as RenderType_AccomodationComponent };
export function View_AccomodationComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { scriptContainerRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "itineraryBox_wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "accomodation_wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["id", "b_container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["script_container", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_AccomodationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "accomodation", [], null, null, null, View_AccomodationComponent_0, RenderType_AccomodationComponent)), i1.ɵdid(1, 114688, null, 0, i2.AccomodationComponent, [i3.BookingFlowService, i4.TranslateService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AccomodationComponentNgFactory = i1.ɵccf("accomodation", i2.AccomodationComponent, View_AccomodationComponent_Host_0, {}, {}, []);
export { AccomodationComponentNgFactory as AccomodationComponentNgFactory };
