import { take, skip } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { ShoppingCartBreakdownModel } from './models/shopping-cart-models';
var ApplicationFlowService = /** @class */ (function () {
    function ApplicationFlowService(http, configService, currencyManager) {
        this.http = http;
        this.configService = configService;
        this.currencyManager = currencyManager;
        this.shoppingCartBreakdown = new BehaviorSubject(new ShoppingCartBreakdownModel());
    }
    Object.defineProperty(ApplicationFlowService.prototype, "currency", {
        get: function () {
            return this._currency;
        },
        set: function (value) {
            this._currency = value;
            this.currencyManager.updateCurrentCurrency(this._currency);
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Loads the price breakdown
     * @param forced if [true] a http request will be made. If [false] the http request will be made only on the first call
     */
    ApplicationFlowService.prototype.loadPriceBreakdown = function (forced) {
        var _this = this;
        if (forced === void 0) { forced = false; }
        // if not forced and already completed return current value
        if (!forced && this.priceBreakdownSubscription && this.priceBreakdownSubscription.closed) {
            return Promise.resolve(this.shoppingCartBreakdown.value);
        }
        // if forced - cancel previous request if any
        if (forced && this.priceBreakdownSubscription) {
            this.priceBreakdownSubscription.unsubscribe();
        }
        // if forced or no previous requests wered attempted do the request
        if (forced || !this.priceBreakdownSubscription) {
            this.priceBreakdownSubscription = this.getPriceBreakdown()
                .subscribe(function (response) {
                _this.shoppingCartBreakdown.next(new ShoppingCartBreakdownModel().init(response.shoppingCartBreakdown));
            });
        }
        // return the next value the comes up in the subscription
        return new Promise(function (resolve) { return _this.shoppingCartBreakdown.pipe(skip(1), take(1)).subscribe(function (data) { return resolve(data); }); });
    };
    ApplicationFlowService.prototype.getPriceBreakdown = function (params) {
        return this.http.get(this.configService.ShoppingCartBreakdownUrl, { params: params });
    };
    return ApplicationFlowService;
}());
export { ApplicationFlowService };
