import { SsrType } from './../../extras/ssr-type.enum';
import { Subscription } from 'rxjs';
import { SsrsService } from './../../extras/ssrs.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { Constants } from '../../constants';
import * as moment from 'moment';
import { SsrModel } from '../../extras/ssr-model';

@Component({
  selector: 'check-in-unaccompanied-minor-notification',
  templateUrl: './unaccompanied-minor-notification.component.html',
  styleUrls: ['./unaccompanied-minor-notification.component.scss']
})

export class UnaccompaniedMinorNotificationComponent implements OnInit {

  private ssrsSubscription: Subscription;
  private availableSsrs: SsrModel[] = [];
  private passenger: any;
  private flights: any;
  private dateFormat: string = 'YYYY-MM-DD';

  @ViewChild('UMmodal', { static: true }) UMmodal: DefaultModalComponent;

  public availableUMssrs: { ssr: SsrModel; ssrCode: string; ssrResource: string; }[];
  public selectedSsrCode: string;

  constructor(private ssrsService: SsrsService) {
    this.ssrsSubscription = this.ssrsService.getSsrsInfo().subscribe((data: any) => {
      if (data) {
        this.availableSsrs = data.ssrsInfo.availableSsrs.find(x => x.key === SsrType.SpecialAssistance).value[0].value;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.ssrsSubscription) {
      this.ssrsSubscription.unsubscribe();
    }    
  }

  openPopup(callback, passenger, flights) {    
    this.selectedSsrCode = '';
    this.passenger = passenger;
    this.flights = flights;

    this.availableUMssrs = this.getAvailableUMssrs(passenger, flights);
    this.UMmodal.openPopup(callback);
  }

  ok(): Promise<any> {
    // sell chosen UM ssr for all flights
    this.flights.forEach((flight: any) => {
      this.ssrsService.addSsr(SsrType.SpecialAssistance, this.selectedSsrCode, this.passenger.passengerNumber, flight);
    });
    return this.ssrsService.applySsrs(SsrType.SpecialAssistance);
  }

  cancel() {
    this.UMmodal.cancel();
  }

  setChosenUMssr(ssrCode: string) {
    this.selectedSsrCode = ssrCode;
  }

  getAvailableUMssrs(passenger: any, flights: any): { ssr: any; ssrCode: string; ssrResource: string; }[] {
    const nationality = passenger.info.nationality;
    const age = moment(flights[0].departureStation.date, this.dateFormat).diff(moment(passenger.typeInfo.dateOfBirth, this.dateFormat), 'years');
    const isInternationalFlight = flights.every(f => f.isInternational === true);

    switch (nationality) {
      case 'RO': {
        if (isInternationalFlight) {
          if (age >= 6 && age < 18) {
            return [{
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUM) : '',
              ssrCode: Constants.SsrCodeUM,
              ssrResource: 'ROINT618'
            }];
          }
        } else {
          if (age >= 14 && age < 18) {
            return [{
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUMFR) : '',
              ssrCode: Constants.SsrCodeUMFR,
              ssrResource: 'FREERODOM1418'
            }, {
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUM) : '',
              ssrCode: Constants.SsrCodeUM,
              ssrResource: 'RODOM1418'
            }];
          } else if (age >= 6 && age < 14) {
            return [{
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUM) : '',
              ssrCode: Constants.SsrCodeUM,
              ssrResource: 'RODOM614'
            }];
          }
        }
        break;
      }
      default: {
        if (isInternationalFlight) {
          if (age >= 6 && age < 14) {
            return [{
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUMNR) : '',
              ssrCode: Constants.SsrCodeUMNR,
              ssrResource: 'NONROINT614'
            }];
          } else if (age >= 14 && age < 18) {
            return [{
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUMNR) : '',
              ssrCode: Constants.SsrCodeUMNR,
              ssrResource: 'NONROINT1418'
            }, {
              ssr: this.availableSsrs ?  this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUMFR) : '',
              ssrCode: Constants.SsrCodeUMFR,
              ssrResource: 'FREENONROINT1418'
            }];
          }
        } else {
          if (age >= 6 && age < 14) {
            return [{
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUMNR) : '',
              ssrCode: Constants.SsrCodeUMNR,
              ssrResource: 'NONRODOM614'
            }];
          } else if (age >= 14 && age < 18) {
            return [{
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUMNR) : '',
              ssrCode: Constants.SsrCodeUMNR,
              ssrResource: 'NONROINT1418'
            }, {
              ssr: this.availableSsrs ? this.availableSsrs.find(ssr => ssr.ssrCode === Constants.SsrCodeUMFR) : '',
              ssrCode: Constants.SsrCodeUMFR,
              ssrResource: 'FREENONROINT1418'
            }];
          }
        }
        break;
      }
    }
    return [];
  }
}
