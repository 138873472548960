import { PassengerTypes } from './passenger-selection-view-model';
import * as moment from 'moment';
import { Constants } from 'src/app/constants';

export class FlightSearchModel {
  origin: string;
  destination: string;
  departureDate: string;
  returnDate: string;
  isRoundTrip: boolean;
  // isCalendar: boolean = false;
  culture: string = "en-GB";
  currency: string;
  usePromo: boolean = false;

  //todo:collect corect type of passengers
  passengers: IPassengerSearch[];
  classOfService: string;

  getHubSearch(journey: number) {
    if (journey === 0) {
      return this.origin + this.destination + moment(this.departureDate).format('YYYY/MM/DD');
    } else {
      return this.destination + this.origin + moment(this.returnDate).format('YYYY/MM/DD');
    }
  }

  getQuery(
    showBlueBenefits: boolean,
    paxSuffix: string,
    isMoveFlightSearch: boolean) {
    const params = [];
    params.push('o1=' + this.origin);
    params.push('d1=' + this.destination);
    params.push('dd1=' + moment(this.departureDate).format('YYYY/MM/DD'));
    if (this.isRoundTrip) {
      params.push('dd2=' + moment(this.returnDate).format('YYYY/MM/DD'));
    }
    params.push('r=' + this.isRoundTrip);
    params.push('s=true');
    // params.push('c=' + this.isCalendar);
    params.push('l=false');
    params.push('m=true');
    this.passengers.forEach(p => {
      p.paxType = p.paxType.substring(0, 3);
      params.push((showBlueBenefits && p.paxType !== Constants.InfantPaxType ? p.paxType.concat(paxSuffix) : p.paxType) + '=' + p.count);
    });
    params.push('bc=' + this.currency);
    params.push('uapc=' + this.usePromo);
    params.push('bbs=' + showBlueBenefits);
    params.push('mff=' + isMoveFlightSearch);

    if (this.classOfService) {
      params.push('cs=' + this.classOfService);
    }
    return params.join('&');
  }

  setDepartureDate(newDate: Date): void {
    this.departureDate = moment(newDate).toISOString();
  }

  setReturnDate(newDate: Date): void {
    this.returnDate = moment(newDate).toISOString();
  }

  isValid(): boolean {
    return this.origin && this.origin.length > 0 &&
      this.destination && this.destination.length > 0 &&
      this.origin !== this.destination &&
      this.departureDate && this.departureDate.length > 0 &&
      this.isRoundTrip !== undefined &&
      (this.isRoundTrip && this.returnDate && this.returnDate.length > 0 || !this.isRoundTrip) &&
      this.passengers.length > 0 &&
      this.currency && this.currency.length === 3 &&
      this.culture && this.culture.length === 5;
  }
}

export interface IPassengerSearch {
  count: number;
  paxType: any;
}
