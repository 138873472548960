import { BookingStatusRoutes } from './booking-status-routes';
import { BookingStatusComponent } from './booking-status.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // RouterModule.forRoot(
    //   BookingStatusRoutes
    // ),
    SharedModule,
    BlueAirCommonModule
  ],
  declarations: [BookingStatusComponent],
  providers: []
})

export class BookingStatusModule { }
