import * as tslib_1 from "tslib";
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { of as observableOf } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { Helpers, } from '../helpers';
import { EnvHelper } from '../env-helper';
import { LoginResponseModel } from './models/profile/login-response-model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "../common-modules/blue-air-common/translator/translate.service";
var ProfileService = /** @class */ (function () {
    function ProfileService(http, config, translate) {
        this.http = http;
        this.config = config;
        this.translate = translate;
        this.profile = new BehaviorSubject(null);
        this.isAgent = EnvHelper.IsOnAgencyPortal();
        // setTimeout(() => {
        //   if (window.blueAirSessionObj && window.blueAirSessionObj.session && window.blueAirSessionObj.session.User) {
        //     this.refreshProfile();
        //   }
        // }, 0);
    }
    ProfileService.prototype.loginAgent = function (username, password) {
        var _this = this;
        return this.http.post(this.config.AgentLoginUrl, {
            autologinagent: {
                username: username,
                password: password
            }
        }).pipe(switchMap(function (result) {
            var profile = _this.validateProfile(result.memberProfile);
            if (profile != null) {
                return observableOf(result);
            }
            return _this.getResetPassword();
        }), map(function (result) {
            var response = new LoginResponseModel();
            if (result.memberProfile) {
                _this.profile.next(result.memberProfile.member);
                response.loginOk = true;
                response.member = _this.profile.value;
                response.errors = [];
            }
            else if (result.resetPassword) {
                response.loginOk = false;
                response.mustResetPassword = true;
                response.member = null;
                response.errors = [];
            }
            else {
                response.loginOk = false;
                response.member = null;
                response.errors = [_this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
            }
            return response;
        }), catchError(function (errorResult) {
            var _a;
            var response = new LoginResponseModel();
            response.errors = [];
            if (errorResult.error && errorResult.error.errors && errorResult.error.errors.length) {
                (_a = response.errors).push.apply(_a, tslib_1.__spread(errorResult.error.errors.map(function (err) {
                    var t = _this.translate.instant(err.errorCode, 'profile-info');
                    return t === err.errorCode ? err.errorCode + ": " + err.errorMessage : t;
                })));
            }
            else {
                response.errors.push(_this.translate.instant('GenericAuthenticationFailed', 'profile-info'));
            }
            return observableOf(response);
        }));
    };
    ProfileService.prototype.logout = function () {
        return this.http.get(this.config.LogoutUrl).toPromise();
    };
    ProfileService.prototype.refreshProfile = function (forced) {
        var _this = this;
        if (forced === void 0) { forced = false; }
        var getValue = {
            subject: this.profile,
            subscription: this.profileSubscription,
            valueProvider: function () { return _this.getProfile(); },
            forced: forced
        };
        var promise = Helpers.GetValueAsync(getValue);
        this.profileSubscription = getValue.subscription;
        return promise;
    };
    ProfileService.prototype.getProfile = function () {
        var _this = this;
        return this.http.get(this.config.ProfileUrl).pipe(map(function (p) { return _this.validateProfile(p.memberProfile); }));
    };
    ProfileService.prototype.validateProfile = function (memberProfile) {
        if (memberProfile && memberProfile.member.agentId !== 9) {
            return memberProfile.member;
        }
        return null;
    };
    ProfileService.prototype.getResetPassword = function () {
        return this.http.get(this.config.ResetPasswordUrl);
    };
    ProfileService.prototype.resetPassword = function (agentId, username, oldPassword, password, passwordConf) {
        var _this = this;
        return this.http.post(this.config.ResetPasswordUrl, {
            resetPassword: {
                agentId: agentId,
                username: username,
                oldPassword: oldPassword,
                password: password,
                passwordConfirmation: passwordConf
            }
        }).pipe(map(function (result) {
            var response = new LoginResponseModel();
            if (result.memberProfile) {
                if (_this.validateProfile(result.memberProfile)) {
                    _this.profile.next(result.memberProfile.member);
                    response.loginOk = true;
                    response.member = _this.profile.value;
                    response.errors = [];
                }
                else {
                    response.loginOk = false;
                    response.member = null;
                    response.errors = [_this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
                }
            }
            else {
                response.loginOk = false;
                response.member = null;
                response.errors = [_this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
            }
            return response;
        }), catchError(function (errorResult) {
            var _a;
            var response = new LoginResponseModel();
            response.errors = [];
            if (errorResult.error && errorResult.error.errors && errorResult.error.errors.length) {
                (_a = response.errors).push.apply(_a, tslib_1.__spread(errorResult.error.errors.map(function (err) {
                    var t = _this.translate.instant(err.errorCode, 'profile-info');
                    return t === err.errorCode ? err.errorCode + ": " + err.errorMessage : t;
                })));
            }
            else {
                response.errors.push(_this.translate.instant('GenericAuthenticationFailed', 'profile-info'));
            }
            return observableOf(response);
        }));
    };
    ProfileService.prototype.updateAgent = function (updatedInfo) {
        var _this = this;
        var obj = {
            agentProfile: {
                agent: updatedInfo
            }
        };
        return this.http.post(this.config.SaveAgentInfoUrl, obj)
            .toPromise()
            .then(function () { return _this.refreshProfile(true); });
    };
    ProfileService.prototype.updateProfile = function (updatedInfo) {
        var _this = this;
        var obj = {
            memberProfile: {
                member: updatedInfo
            }
        };
        return this.http.post(this.config.SaveProfileInfoUrl, obj)
            .toPromise()
            .then(function () { return _this.refreshProfile(); });
    };
    ProfileService.prototype.updateTravelDocument = function (updatedInfo, itemIndex) {
        var obj = {
            travelDocuments: {
                index: itemIndex,
                updateItem: updatedInfo
            }
        };
        if (itemIndex === -1) {
            return this.http.post(this.config.SaveTravelDocumentUrl, obj).toPromise()
                .then(function (travelDocs) {
                return travelDocs;
            });
        }
        return this.http.put(this.config.SaveTravelDocumentUrl, obj).toPromise()
            .then(function (travelDocs) {
            return travelDocs;
        });
    };
    ProfileService.prototype.deleteTravelDocument = function (itemIndex) {
        return this.http
            .delete(this.config.SaveTravelDocumentUrl, {
            params: {
                'travelDocuments.index': itemIndex + ''
            }
        })
            .toPromise()
            .then(function (travelDocs) {
            return travelDocs;
        });
    };
    ProfileService.prototype.updateAddressBookItem = function (updateInfo, itemIndex) {
        var obj = {
            personAddresses: {
                updateItem: updateInfo,
                index: itemIndex
            }
        };
        if (itemIndex === -1) {
            return this.http.post(this.config.SaveAddressBookItemUrl, obj).toPromise()
                .then(function (data) {
                return data;
            });
        }
        return this.http.put(this.config.SaveAddressBookItemUrl, obj).toPromise()
            .then(function (data) {
            return data;
        });
    };
    ProfileService.prototype.deleteAddressBookItem = function (itemIndex) {
        return this.http
            .delete(this.config.SaveAddressBookItemUrl, {
            params: {
                'personAddresses.Index': itemIndex + ''
            }
        })
            .toPromise()
            .then(function (data) {
            return data;
        });
    };
    ProfileService.prototype.updateSeatPreferences = function (updateInfo) {
        var _this = this;
        var obj = {
            memberProfile: {
                member: {
                    person: {
                        locationPreference: {
                            propertyCode: updateInfo.seatLocation
                        },
                        typePreference: {
                            propertyTypeCode: updateInfo.seatType
                        }
                    }
                }
            }
        };
        return this.http.post(this.config.SaveSeatPreferencesUrl, obj)
            .toPromise()
            .then(function () { return _this.refreshProfile(); });
    };
    ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.TranslateService)); }, token: ProfileService, providedIn: "root" });
    return ProfileService;
}());
export { ProfileService };
