import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { Component, OnInit } from '@angular/core';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';

@Component({
  selector: 'check-in-ssr-box-content-priority-bag',
  templateUrl: './ssr-box-content-priority-bag.component.html',
  styleUrls: ['./ssr-box-content-priority-bag.component.scss']
})
export class SsrBoxContentPriorityBagComponent extends SsrBoxContentBase implements OnInit {

  constructor(private ssrsService: SsrsService) {
    super(ssrsService);
    this.ssrType = SsrType.PriorityBag;
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.flight && this.flight.passengers.length > 0) {
      this.allPassengersEnabled = this.allPassengersEnabledFunc();

      if (!this.allPassengersEnabled) {
        this.selectedPassenger = 0;
      }
    }
  }

  allPassengersEnabledFunc(): boolean {
    if (this.passengers.length < 2) {
      return false;
    }

    if (this.availableSsrs[0]) {
      for (const property in this.availableSsrs[0].limitPerPassenger) {
        if (this.availableSsrs[0].limitPerPassenger.hasOwnProperty(property)) {
          if (this.availableSsrs[0].limitPerPassenger[property] === 0) {
            return false;
          }
        }
      }
    }

    return true;
  }
}
