import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BundleSelectComponent } from '../bundle-select/bundle-select.component';
import { SharedModule } from '../../shared/shared.module';
import { CanActivateBundlesService } from './can-activate-bundle.service';
import { BlueAirCommonModule } from '../../common-modules/blue-air-common/blue-air-common.module';
import { CanDeactivateComponentService } from '../../core/can-deactivate-component.service';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BlueAirCommonModule
  ],
  declarations: [BundleSelectComponent],
  providers:[CanActivateBundlesService, CanDeactivateComponentService]
})
export class BundleModule { }
