<div class="filter-bookings_wrapper form">
  <blue-loading-spinner [id]="spinnerId" [showSpinner]="true"></blue-loading-spinner>
  <div class="filter-bookings_wrapper_row">
    <div class="filter-bookings_wrapper_col header-left">
      <h1>{{ 'Find my Bookings' | translate:'find-my-bookings' }}</h1>
    </div>
    <div class="filter-bookings_wrapper_col header-right">
      <label for="filterBy">{{ 'Filter by' | translate:'find-my-bookings' }}</label>
      <blue-select-list [items]="filterByItems" [(ngModel)]="filterBy" name="filterBy"></blue-select-list>
    </div>
  </div>

  <form [formGroup]="filterBookingsForm" (ngSubmit)="search()">

    <div [ngSwitch]="filterBy">

      <!-- Filter by email address -->
      <div formGroupName="emailAddressForm" *ngSwitchCase="filterByEnum.EmailAddress">
        <!-- Required -->
        <div class="filter-bookings_wrapper_row">
          <div class="filter-bookings_wrapper_col"><label
              for="emailAddress">{{ 'Email address' | translate:'find-my-bookings' }} *</label>
            <input type="text" id="emailAddress" formControlName="emailAddressForm_emailAddress" required />
            <profile-form-error
              [reference]="filterBookingsForm.controls.emailAddressForm.controls.emailAddressForm_emailAddress">
            </profile-form-error>
          </div>
          <div class="filter-bookings_wrapper_col">
            <!-- Optional -->
            <label for="agentId">{{ 'Agent ID' | translate:'find-my-bookings' }}</label>
            <input type="text" id="agentId" formControlName="emailAddressForm_agentId" />
          </div>
        </div>
        <!-- Filters -->
        <div class="filter-bookings_wrapper_row">
          <div class="filter-bookings_wrapper_col">
            <label for="flightNumber">{{ 'Flight number' | translate:'find-my-bookings' }}</label>
            <input type="text" id="flightNumber" maxlength="4" formControlName="emailAddressForm_flightNumber" />
          </div>
          <div class="filter-bookings_wrapper_col">
            <label for="flightDate">{{ 'Flight date' | translate:'find-my-bookings' }}</label>
            <blue-date-picker name="flightDate" formControlName="emailAddressForm_flightDate"
              [elementName]="'flightDate'" [dateRange]='flightDateRange' [dateFormat]="format"></blue-date-picker>
          </div>
        </div>
        <div class="filter-bookings_wrapper_row">
          <div class="filter-bookings_wrapper_col">
            <label for="departureStation">{{ 'Departure station' | translate:'find-my-bookings' }}</label>
            <input type="text" id="departureStation" maxlength="3" formControlName="emailAddressForm_flightOrigin" />
          </div>
          <div class="filter-bookings_wrapper_col">
            <label for="arrivalStation">{{ 'Arrival station' | translate:'find-my-bookings' }}</label>
            <input type="text" id="arrivalStation" maxlength="3" formControlName="emailAddressForm_flightDestination" />
          </div>
        </div>
      </div>

      <!-- Filter by departure date -->
      <div formGroupName="departureDateForm" *ngSwitchCase="filterByEnum.DepartureDate">
        <!-- Required -->
        <div class="filter-bookings_wrapper_row">
          <div class="filter-bookings_wrapper_col">
            <label for="departureDate">{{ 'Departure date' | translate:'find-my-bookings' }} *</label>
            <blue-date-picker name="flightDate" formControlName="departureDateForm_flightDate"
              [elementName]="'departureDate'" [dateRange]='departureDateRange' [dateFormat]="format" required></blue-date-picker>
            <profile-form-error
              [reference]="filterBookingsForm.controls.departureDateForm.controls.departureDateForm_flightDate">
            </profile-form-error>
          </div>
        </div>
      </div>

      <!-- Filter by booking date -->
      <div formGroupName="bookingDateForm" *ngSwitchCase="filterByEnum.BookingDate">
        <!-- Required -->
        <div class="filter-bookings_wrapper_row">
          <div class="filter-bookings_wrapper_col">
            <label for="startUTC">{{ 'Booking date' | translate:'find-my-bookings' }} *</label>
            <blue-date-picker name="bookingDate" formControlName="bookingDateForm_startUtc"
              [elementName]="'bookingDate'" [dateRange]='bookingDateRange' [dateFormat]="format" required>
            </blue-date-picker>
            <profile-form-error
              [reference]="filterBookingsForm.controls.bookingDateForm.controls.bookingDateForm_startUtc">
            </profile-form-error>
          </div>
        </div>
        <!-- Filters -->
        <div class="filter-bookings_wrapper_row">
          <div class="filter-bookings_wrapper_col">
            <label for="flightNumber">{{ 'Flight number' | translate:'find-my-bookings' }}</label>
            <input type="text" id="flightNumber1" maxlength="4" formControlName="bookingDateForm_flightNumber" />
          </div>
          <div class="filter-bookings_wrapper_col">
            <label for="flightDate">{{ 'Flight date' | translate:'find-my-bookings' }}</label>
            <blue-date-picker name="flightDate" formControlName="bookingDateForm_flightDate"
              [elementName]="'flightDate'" [dateRange]='flightDateRange' [dateFormat]="format"></blue-date-picker>
          </div>
        </div>
        <div class="filter-bookings_wrapper_row">
          <div class="filter-bookings_wrapper_col">
            <label for="departureStation">{{ 'Departure station' | translate:'find-my-bookings' }}</label>
            <input type="text" id="departureStation1" maxlength="3" minlength="3" formControlName="bookingDateForm_flightOrigin" />
          </div>
          <div class="filter-bookings_wrapper_col">
            <label for="arrivalStation">{{ 'Arrival station' | translate:'find-my-bookings' }}</label>
            <input type="text" id="arrivalStation1" maxlength="3" minlength="3" formControlName="bookingDateForm_flightDestination" />
          </div>
        </div>
      </div>

     <!-- Filter by booking status -->
     <div formGroupName="bookingStatusForm" class="filter-bookings_wrapper_row" *ngSwitchCase="filterByEnum.BookingStatus">
        <!-- Required -->
        <div class="filter-bookings_wrapper_col statusFilter">
          <label for="statusFilter">{{'Booking status' | translate:'find-my-booking'}} *</label>
            <div class="checkbox-wrap" *ngFor="let status of bookingStatuses; let i = index" (click)="changeCheckbox(status)">
              <input type="checkbox" id="status-{{status}}" formControlName="bookingStatusForm_{{status}}" [checked]="getCheckbox(status)">
              <label for="">{{status | translate:'find-my-booking'}}</label>
            </div>
        </div>
      </div>

      <!-- Filter by record locator -->
      <div formGroupName="recordLocatorForm" class="filter-bookings_wrapper_row" *ngSwitchDefault>
        <!-- Required -->
        <div class="filter-bookings_wrapper_col">
          <label>{{ 'Record locator' | translate:'find-my-bookings' }} *</label>
          <input type="text" name="recordlocator" placeholder="{{ 'e.g. ASE45F' | translate:'find-my-bookings' }}"
            maxlength="6" formControlName="recordLocatorForm_recordLocator" required />
          <profile-form-error
            [reference]="filterBookingsForm.controls.recordLocatorForm.controls.recordLocatorForm_recordLocator">
          </profile-form-error>
        </div>
      </div>


      <div class="filter-bookings_wrapper_row center">
        <button class="btn btn-primary filter-bookings_button"
          [disabled]="filterBy == filterByEnum.BookingDate ? !filterBookingsForm.controls.bookingDateForm.valid :
                      filterBy == filterByEnum.EmailAddress ? !filterBookingsForm.controls.emailAddressForm.valid :
                      filterBy == filterByEnum.DepartureDate ? !filterBookingsForm.controls.departureDateForm.valid :
                      filterBy == filterByEnum.BookingStatus ? !filterBookingsForm.controls.bookingStatusForm.valid :
                      !filterBookingsForm.controls.recordLocatorForm.valid">
          {{ 'Search' | translate:'find-my-bookings' }}
        </button>
      </div>
    </div>
  </form>
</div>
