import { Component, OnInit, ElementRef, Renderer2, OnDestroy, AfterViewInit, OnChanges} from '@angular/core';
import { LoadingSpinnerService } from '../loading-spinner.service';
import { Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'blue-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  private subscription: any;
  private stackId = 0;
  private activationRouteList: string[] = [];

  spinnerVisible = false;
  spinnerActive = false;
  @Input() id: string;
  @Input() activationRoute: string | string[];
  @Input() fullPage = false;
  @Input() showSpinner: boolean;
  @Input() class: string;

  constructor(private service: LoadingSpinnerService, private el: ElementRef, private render: Renderer2) {
    this.subscription = service.spinnerObservable.subscribe(s => this.applyChanges(s));
  }

  private applyChanges(s: any): void {
    if (!s) {
      return;
    }
    if ((s.route && this.activationRouteList.indexOf(s.route) === -1) || (s.id && s.id !== this.id)) {
      return;
    }
    this.stackId = s.stackId || 0;
    this.toggleSpinner(s.show);
  }

  private toggleSpinner(state: boolean) {
    // doing this so we have a nice animation when adding/removing the spinner from DOM
    if (state) {
      this.spinnerVisible = true;
      setTimeout(() => this.spinnerActive = true, 10);
    } else {
      this.spinnerActive = false;
      setTimeout(() => this.spinnerVisible = false, 500);
    }
  }

  ngOnInit() {

  }

  ngOnChanges(changes) {
    if (changes.showSpinner) {
      this.toggleSpinner(true);
    }

    if (changes.activationRoute) {
      this.activationRouteList = typeof this.activationRoute === 'string' ?
        [this.activationRoute as string] :
        this.activationRoute as string[];
    }

    if (changes.id || changes.activationRoute) {
      this.applyChanges(this.service.initFromStack(this.stackId, this.id, this.activationRouteList));
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    const myElem = this.el.nativeElement;
    const parent = myElem.parentNode;
    let container = parent;

    if (this.fullPage === true) {
      const body = document.getElementsByTagName('body')[0];
      this.render.removeChild(parent, myElem);
      this.render.appendChild(body, myElem);
      container = body;
    }

    if (container) {
      this.render.setStyle(container, 'position', 'relative');
    }
  }
}
