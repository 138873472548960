import { BehaviorSubject } from 'rxjs';
import { SelectionService } from './interfaces/selection.service';
import { Injectable } from '@angular/core';
import { CheckinService } from './checkin.service';
import { FlightModel, FlightStationInfo } from './models/flight-model';
import { PassengerModel } from './models/passenger-model';
import { PassengerJourneyBags } from './models/passenger-journey-bags';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import {BookingFareSelectSyncService} from '../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';

@Injectable()
export class CheckinSelectionService implements SelectionService {

  flights: FlightModel[] = [];
  passengers: PassengerModel[] = [];

  flightsObs: BehaviorSubject<FlightModel[]> = new BehaviorSubject([]);
  passengersObs: BehaviorSubject<PassengerModel[]> = new BehaviorSubject([]);

  constructor(private checkinService: CheckinService, private translateService: TranslateService, private syncService: BookingFareSelectSyncService) {
    this.checkinService.checkInInfoSub.subscribe(checkinfo => {
      if (checkinfo == null) return;
      this.flights = [];
      this.passengers = [];
      const allFlights = {};

      const pjbList: PassengerJourneyBags[] = checkinfo.passengerJourneyBags;

      const typeCounter = { adtCount: 0, chdCount: 0, infCount: 0 };

      checkinfo.criteria.forEach(c => {
        const passenger: PassengerModel = new PassengerModel(c.passenger, typeCounter, this.syncService);

        const pjb = pjbList.find(i => i.passengerNumber === passenger.passengerNumber);

        if (!pjb) {
          return;
        }

        this.passengers.push(passenger);

        c.passengerJourneys.forEach(passengerJourney => {
          const j = passengerJourney.journey;

          if (pjb.journeyBags.findIndex(i => i.sellKey === j.sellKey) < 0) {
            return;
          }

          let flight: FlightModel = null;

          if (allFlights.hasOwnProperty(j.sellKey)) {
            flight = allFlights[j.sellKey];
          } else {
            flight = new FlightModel();
            flight.initFromJourney(j, this.translateService);
            allFlights[j.sellKey] = flight;
          }

          passenger.flight = flight;

          flight.addPassenger(passenger, j);
          
          return flight;
        });
      });

      for (const key in allFlights) {
        if (allFlights.hasOwnProperty(key)) {
          this.flights.push(allFlights[key]);
        }
      }

      this.flights.sort(FlightModel.sortFn);
      this.passengers.sort(PassengerModel.sortFn);

      this.flightsObs.next(this.flights);
      this.passengersObs.next(this.passengers);
    });
  }
}
