<form role="form" (ngSubmit)="submitForm()" #changePasswordForm="ngForm" [formGroup]="passwordChangeFormGroup">
  <div class="form-row equal-fields">
    <div class="one-field">
      <label for="oldPassword-field">{{ 'Old Password' | translate:'profile-info' }}</label>
      <input id="oldPassword-field" type="password" placeholder="{{ 'Old Password' | translate:'profile-info' }}"
        aria-labelledby="oldPassword-field" [(ngModel)]="passwordChangeModel.oldPassword" name="oldPassword"
        formControlName="oldPassword" />
      <ng-container *ngIf="passwordChangeSubmitted && oldPassword.errors">
        <profile-form-error [isVisible]="oldPassword.errors.required"></profile-form-error>
      </ng-container>
    </div>
  </div>
  <div class="form-row equal-fields">
    <div class="one-field">
      <label for="newPassword-field">{{ 'New Password' | translate:'profile-info' }}</label>
      <input id="newPassword-field" type="password" placeholder="{{ 'New Password' | translate:'profile-info' }}"
        aria-labelledby="newPassword-field" [(ngModel)]="passwordChangeModel.newPassword" name="newPassword"
        formControlName="newPassword" />
      <ng-container *ngIf="passwordChangeSubmitted && newPassword.errors">
        <profile-form-error [isVisible]="newPassword.errors.required"></profile-form-error>
        <profile-form-error [isVisible]="newPassword.errors.pattern" [customErrorText]="true">
          <div>
            {{ 'The new password does not comply with the password policy' | translate:'profile-info' }}
          </div>
          <div>
            {{ passwordChangePolicy | translate:'profile-info' }}
          </div>
        </profile-form-error>
      </ng-container>
    </div>
  </div>
  <div class="form-row equal-fields">
    <div class="one-field">
      <label for="newPasswordConf-field">{{ 'Confirm New Password' | translate:'profile-info' }}</label>
      <input id="newPasswordConf-field" type="password"
        placeholder="{{ 'Confirm New Password' | translate:'profile-info' }}"
        aria-labelledby="newPasswordConf-field" [(ngModel)]="passwordChangeModel.newPasswordConf"
        name="newPasswordConf" formControlName="newPasswordConf" />
      <ng-container *ngIf="passwordChangeSubmitted && newPasswordConf.errors">
        <profile-form-error [isVisible]="newPasswordConf.errors.required"></profile-form-error>
        <profile-form-error [isVisible]="newPasswordConf.errors.sameAs" [customErrorText]="true">
          {{ "The confirmation password doesn't match with the new password" | translate:'profile-info' }}
        </profile-form-error>
      </ng-container>
    </div>
  </div>
  <div class="form-row" *ngIf="passwordChangeErrors.length">
    <profile-form-error *ngFor="let err of passwordChangeErrors" [isVisible]="true" [customErrorText]="true">
      {{ err }}
    </profile-form-error>
  </div>
  <ng-content>

  </ng-content>
</form>