import * as tslib_1 from "tslib";
import { DefaultModalComponent } from './../default-modal/default-modal.component';
import { SsrsService } from './../../extras/ssrs.service';
import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { JourneyBundle } from './../../core/models/bundle.model';
import { BundleService } from './../bundle.service';
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SsrType } from '../../extras/ssr-type.enum';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { BookingService } from '../../core/booking.service';
import { Constants } from '../../constants';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import { ECommerceService } from '../e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../e-commerce/models/e-commerce-cart-item.model';
var PassengerBundleComponent = /** @class */ (function () {
    function PassengerBundleComponent(bundleService, flowManager, loadingSpinnerService, ssrsService, bookingStepsService, bookingService, ecommerce, translateService) {
        this.bundleService = bundleService;
        this.flowManager = flowManager;
        this.loadingSpinnerService = loadingSpinnerService;
        this.ssrsService = ssrsService;
        this.bookingStepsService = bookingStepsService;
        this.bookingService = bookingService;
        this.ecommerce = ecommerce;
        this.translateService = translateService;
        this.bundleChanged = new EventEmitter();
        this.selectedClassName = 'selected';
        this.threeColumnClassName = 'threeColumnBundle';
        this.addOnBundleType = 'AddOn';
        this.temporaryTotalPrice = 0;
        this.spinnerId = 'bundle-spinner';
        this.journeyBundles = [];
        this.currentBundlesSelected = [];
        this.currentPricesSelected = [];
        this.hasCheckedInPax = false;
        this.flights = [];
        this.bannerBundleSsrList = [];
        this.upgradedFlightsList = [];
        this.boughtBundles = [];
        this.visible = false;
        this.bundleForBothInfoModalId = 'bundleForBothInfoModal';
    }
    PassengerBundleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadData();
        this.bookingServiceSubscription = this.bookingService.bookingObs.subscribe(function (result) {
            if (result) {
                var checkedInPaxJourneys = result.assignedSeats.journeys.items.map(function (x) { return x.segments.items
                    .reduce(function (a, b) { return a.concat(b); }, [])
                    .map(function (y) { return y.passengerSegments.items; })
                    .reduce(function (a, b) { return a.concat(b); }, [])
                    .filter(function (z) { return z.liftStatus !== Constants.NotCheckedIn; })
                    .reduce(function (a, b) { return a.concat(b); }, [])
                    .map(function (t) { return t.passengerNumber; }); });
                if (checkedInPaxJourneys.some(function (x) { return x.length; })) {
                    _this.hasCheckedInPax = true;
                }
            }
        });
        this.flightsSubscription = this.flowManager.selectionService.flightsObs.subscribe(function (flights) {
            if (flights) {
                _this.flights = flights;
            }
        });
    };
    PassengerBundleComponent.prototype.ngOnDestroy = function () {
        if (this.flightsSubscription) {
            this.flightsSubscription.unsubscribe();
        }
        if (this.bookingServiceSubscription) {
            this.bookingServiceSubscription.unsubscribe();
        }
    };
    PassengerBundleComponent.prototype.loadData = function () {
        var _this = this;
        this.journeyBundles = [];
        this.bundleService.refreshBundleDetails().then(function (data) {
            _this.bundleDetails = data;
            _this.bundleDetails.passengerJourneyrBundles.journeyBundles.forEach(function (jb) {
                _this.journeyBundles.push(new JourneyBundle(jb));
            });
            _this.paxCount = _this.bundleDetails.passengerJourneyrBundles.passengers.items.length;
            _this.bundleDetails.passengerJourneyrBundles.currentBundleCodes.forEach(function (bundleCode, journeyIndex) {
                if (bundleCode[0]) {
                    var selectedBundle = _this.journeyBundles[journeyIndex].bundleList.find(function (b) { return b.serviceBundleCode === bundleCode[0].value; });
                    if (selectedBundle) {
                        _this.selectBundle(_this.journeyBundles[journeyIndex].bundleList.indexOf(selectedBundle), journeyIndex, selectedBundle.price, true);
                    }
                }
            });
            _this.setMinUpgradeBundle();
            _this.checkUpgradedBundle();
            var eCommerceCartItems = new Array();
            var item = new ECommerceCartItem('Bundle upgrade', _this.ecommerce.getFlightInfo(0), '0', 'BlueAir', 'Bundle loaded', _this.ecommerce.getFlightType(), _this.ecommerce.getPassengersCount(), 0, null, _this.ecommerce.getUserData()).getItem(true);
            eCommerceCartItems.push(item);
            _this.ecommerce.ProductViews(eCommerceCartItems);
        });
    };
    PassengerBundleComponent.prototype.isBundleUpgradeAvailable = function (journeyIndex) {
        return this.journeyBundles[journeyIndex] ? this.journeyBundles[journeyIndex].bundleList.length > 1 : false;
    };
    PassengerBundleComponent.prototype.selectBundle = function (bundleIndex, journeyIndex, bundlePrice, fromInit) {
        if (fromInit === void 0) { fromInit = false; }
        this.currentBundlesSelected[journeyIndex] = bundleIndex;
        this.currentPricesSelected[journeyIndex] = bundlePrice;
        if (this.flights && this.flights.length > 1) {
            this.currentBundlesSelected[1] = bundleIndex;
            this.currentPricesSelected[1] = bundlePrice;
        }
        this.setSelectedStatus(journeyIndex, bundleIndex);
        this.setTemporaryPrice();
        if (!fromInit) {
            var ecommerceCartItems = [];
            var item = new ECommerceCartItem('Bundle upgrade', this.ecommerce.getFlightInfo(journeyIndex), bundlePrice.toString(), 'BlueAir', 'Bundle selected', this.ecommerce.getFlightType(), this.ecommerce.getPassengersCount(), 0, null, this.ecommerce.getUserData()).getItem(true);
            ecommerceCartItems.push(item);
            this.ecommerce.ProductClicks(ecommerceCartItems);
        }
    };
    PassengerBundleComponent.prototype.setSelectedStatus = function (journeyIndex, bundleIndex) {
        this.journeyBundles[journeyIndex].bundleList.forEach(function (bundle, index) {
            bundle.isSelected = index === bundleIndex ? true : false;
        });
    };
    PassengerBundleComponent.prototype.setTemporaryPrice = function () {
        this.temporaryTotalPrice = this.currentPricesSelected.reduce(function (a, b) { return a + b; }, 0);
    };
    PassengerBundleComponent.prototype._removeExtraSsr = function (flightsSSRsInBundle) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var e_1, _a, e_2, _b, e_3, _c, groupedBySsrCode, flightsSSRsInBundle_1, flightsSSRsInBundle_1_1, flight, _d, _e, ssr, _f, _g, _i, ssrCode, totalSsrsRemoved, _loop_1, _h, _j, ssr;
            var _this = this;
            return tslib_1.__generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        groupedBySsrCode = {};
                        try {
                            for (flightsSSRsInBundle_1 = tslib_1.__values(flightsSSRsInBundle), flightsSSRsInBundle_1_1 = flightsSSRsInBundle_1.next(); !flightsSSRsInBundle_1_1.done; flightsSSRsInBundle_1_1 = flightsSSRsInBundle_1.next()) {
                                flight = flightsSSRsInBundle_1_1.value;
                                try {
                                    for (_d = tslib_1.__values(flight.ssrs), _e = _d.next(); !_e.done; _e = _d.next()) {
                                        ssr = _e.value;
                                        if (!groupedBySsrCode[ssr.ssrCode]) {
                                            groupedBySsrCode[ssr.ssrCode] = [];
                                        }
                                        groupedBySsrCode[ssr.ssrCode].push(ssr);
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (_e && !_e.done && (_b = _d.return)) _b.call(_d);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (flightsSSRsInBundle_1_1 && !flightsSSRsInBundle_1_1.done && (_a = flightsSSRsInBundle_1.return)) _a.call(flightsSSRsInBundle_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        _f = [];
                        for (_g in groupedBySsrCode)
                            _f.push(_g);
                        _i = 0;
                        _k.label = 1;
                    case 1:
                        if (!(_i < _f.length)) return [3 /*break*/, 4];
                        ssrCode = _f[_i];
                        totalSsrsRemoved = 0;
                        _loop_1 = function (ssr) {
                            var ssrNumbersToRemove = ssr.getSsrNumbersToRemove();
                            ssrNumbersToRemove.forEach(function (ssrNumber) {
                                _this.ssrsService.removeSsr(ssr.ssrType, ssr.ssrCode, ssrNumber, ssr.passengerNumber, ssr.segment, false);
                            });
                            totalSsrsRemoved += ssrNumbersToRemove.length;
                        };
                        try {
                            for (_h = tslib_1.__values(groupedBySsrCode[ssrCode]), _j = _h.next(); !_j.done; _j = _h.next()) {
                                ssr = _j.value;
                                _loop_1(ssr);
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                        if (!(totalSsrsRemoved > 0)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.ssrsService.applySsrs(groupedBySsrCode[ssrCode][0].ssrType)];
                    case 2:
                        _k.sent();
                        _k.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PassengerBundleComponent.prototype.sellBundles = function (isDowngrade) {
        var _this = this;
        this.bundleForBothInfoModal.cancel();
        var flightsSSRsInBundle = this.currentBundlesSelected.map(function (bundleIndex, flightIndex) {
            return new FlightSSRsInBundle(flightIndex, _this.flights[flightIndex], _this.journeyBundles[flightIndex].bundleList[bundleIndex], _this.ssrsService, _this.bookingService);
        });
        if (!this.hasCheckedInPax) {
            var data_1 = { passengerJourneyrBundles: { selectedCodes: [] } };
            var paxCount_1 = this.bundleDetails.passengerJourneyrBundles.passengers.items.length;
            var ecommerceCartItems_1 = new Array();
            if (isDowngrade) {
                // if passenger deletes all bundle upgrades, select Included bundle (bundle with index 0) for each journey
                this.bundleDetails.passengerJourneyrBundles.currentBundleCodes.forEach(function (bundleCode, journeyIndex) {
                    if (bundleCode[0]) {
                        _this.selectBundle(0, journeyIndex, 0, true);
                    }
                });
            }
            this.currentBundlesSelected.forEach(function (bundleIndex, journeyIndex) {
                var bundleToSell = _this.journeyBundles.find(function (jb) { return jb.journeyIndex == journeyIndex; }).bundleList[bundleIndex];
                for (var paxNr = 0; paxNr < paxCount_1; paxNr++) {
                    data_1.passengerJourneyrBundles.selectedCodes.push(bundleToSell.serviceBundleCode.concat('|', journeyIndex.toString(), '|', paxNr.toString()));
                }
            });
            var onExtrasPage_1 = this.bookingStepsService.currentStep.value === CheckinSteps.extras;
            var onSeatsPage_1 = this.bookingStepsService.currentStep.value === CheckinSteps.seat;
            var applicationFlowService_1 = this.flowManager.applicationFlowService;
            this.loadingSpinnerService.showSpinnerById(this.spinnerId);
            var sellBundleFunc_1 = function () {
                return _this.bundleService.sellBundle(data_1)
                    .then(function () {
                    _this.currentPricesSelected = isDowngrade ? [0, 0] : _this.currentPricesSelected;
                    _this.setTemporaryPrice();
                    if (!isDowngrade) {
                        _this.toggleExpanded(false);
                    }
                })
                    .then(function () { return onExtrasPage_1 && _this.ssrsService.toggleLoadingForSsrType(true, SsrType.Baggage, SsrType.PriorityBag, SsrType.Meal); })
                    .then(function () { return _this.bundleService.refreshBundleDetails(); })
                    .then(function () { return applicationFlowService_1.loadFlowInfo(true); })
                    .then(function () {
                    _this.setMinUpgradeBundle();
                    _this.checkUpgradedBundle();
                })
                    .then(function () {
                    _this.currentBundlesSelected.forEach(function (bundleIndex, journeyIndex) {
                        var item = new ECommerceCartItem('Bundle upgrade', _this.ecommerce.getFlightInfo(journeyIndex), _this.currentPricesSelected[journeyIndex].toString(), 'BlueAir', _this.upgradedFlightsList[journeyIndex] ?
                            (_this.translateService.instant(_this.upgradedFlightsList[journeyIndex].initialBundle, 'bundle.name') + ' to ' +
                                _this.translateService.instant(_this.upgradedFlightsList[journeyIndex].selectedBundle, 'bundle.name')) :
                            _this.translateService.instant(_this.bundleDetails.passengerJourneyrBundles.journeyBundles[journeyIndex].items[0].serviceBundleCode, 'bundle.name'), _this.ecommerce.getFlightType(), _this.ecommerce.getPassengersCount(), 0, null, _this.ecommerce.getUserData()).getItem(true);
                        ecommerceCartItems_1.push(item);
                    });
                    if (!isDowngrade) {
                        _this.ecommerce.AddToCart(ecommerceCartItems_1);
                    }
                    else {
                        _this.ecommerce.RemoveFromCart(ecommerceCartItems_1);
                    }
                })
                    .then(function () {
                    if (isDowngrade && _this.ssrsService.updatePriorityBag(_this.flights)) {
                        if (_this.pbagModal) {
                            _this.pbagModal.openPopup(function () {
                            });
                        }
                        return _this.ssrsService.applySsrs(SsrType.PriorityBag);
                    }
                })
                    .then(function () {
                    if (onSeatsPage_1) {
                        _this.bundleChanged.next();
                    }
                })
                    .then(function () { return Promise.all([
                    _this.ssrsService.getSsrsInfo().toPromise(),
                    applicationFlowService_1.loadPriceBreakdown(true)
                ]); })
                    .then(function () { return _this.loadingSpinnerService.hideSpinnerById(_this.spinnerId); })
                    .then(function () { return onExtrasPage_1 && _this.ssrsService.toggleLoadingForSsrType(false, SsrType.Baggage, SsrType.PriorityBag, SsrType.Meal, SsrType.PriorityBoarding); });
            };
            this._removeExtraSsr(flightsSSRsInBundle).then(function () {
                return sellBundleFunc_1().then(function () { return window.location.reload(); });
            });
        }
    };
    PassengerBundleComponent.prototype.confirmBundle = function (isDowngrade) {
        if (this.journeyBundles.length > 1) {
            this.togglebundleForBothInfoModalId();
        }
        else {
            this.sellBundles(isDowngrade);
        }
    };
    PassengerBundleComponent.prototype.toggleExpanded = function (isExpanded) {
        if (isExpanded === void 0) { isExpanded = !this.isExpanded; }
        this.isExpanded = isExpanded;
        var ecommerceCartItems = new Array();
        var item = new ECommerceCartItem('Bundle upgrade', 'View bundle upgrade', '0', 'BlueAir', 'Bundle upgrade', '1', 1, 0, null, this.ecommerce.getUserData()).getItem(true);
        ecommerceCartItems.push(item);
        this.ecommerce.ProductClicks(ecommerceCartItems);
    };
    PassengerBundleComponent.prototype.checkUpgradedBundle = function () {
        var _this = this;
        if (this.journeyBundles && this.journeyBundles.length && this.flights) {
            this.upgradedFlightsList = [];
            this.flights.forEach(function (f, journeyIndex) {
                var selectedBundle = _this.journeyBundles[journeyIndex].bundleList.find(function (jb) { return jb.isSelected; });
                var includedBundle = _this.journeyBundles[journeyIndex].bundleList.find(function (jb) { return jb.price === 0; });
                if (selectedBundle && includedBundle) {
                    if (selectedBundle.serviceBundleCode !== includedBundle.serviceBundleCode) {
                        var bundleUpgrade = new BundleUpgrade(f.departureStation.iataCode, f.arrivalStation.iataCode, includedBundle.serviceBundleCode, selectedBundle.serviceBundleCode);
                        _this.upgradedFlightsList.push(bundleUpgrade);
                    }
                }
            });
        }
    };
    PassengerBundleComponent.prototype.setMinUpgradeBundle = function () {
        var _this = this;
        this.boughtBundles = [];
        if (this.journeyBundles && this.journeyBundles.length) {
            var allBundles = this.journeyBundles.map(function (jb) { return jb.bundleList; }).reduce(function (a, b) { return a.concat(b); }, []);
            var addOnBundles_1 = [];
            this.journeyBundles.forEach(function (j) {
                var selectedBundle = j.bundleList.find(function (jb) { return jb.isSelected; });
                _this.boughtBundles.push(selectedBundle);
                if (j.bundleList.length > 1) {
                    _this.visible = true;
                }
                j.bundleList.forEach(function (bl) {
                    if (bl.price > (selectedBundle ? selectedBundle.price : 0) && !bl.isSelected && bl.serviceBundleType === _this.addOnBundleType) {
                        addOnBundles_1.push(bl);
                    }
                });
            });
            if (addOnBundles_1.length > 0) {
                var lowestUpgradeBundle_1 = addOnBundles_1.reduce(function (a, b) { return a.price < b.price ? a : b; });
                this.startingFromPrice = lowestUpgradeBundle_1.price.toFixed(2);
                var index = allBundles.findIndex(function (x) { return x.serviceBundleCode === lowestUpgradeBundle_1.serviceBundleCode && !x.isSelected; });
                if (index > -1) {
                    var beforeUpgradeSsrs_1 = index > 0 ?
                        allBundles[index - 1].serviceBundleSsrs.reduce(function (a, b) { return a.concat(b); }, []) :
                        allBundles[0].serviceBundleSsrs.reduce(function (a, b) { return a.concat(b); }, []);
                    var differences = lowestUpgradeBundle_1.serviceBundleSsrs.filter(function (x) { return !beforeUpgradeSsrs_1.some(function (y) { return y.ssrCode === x.ssrCode; }); });
                    this.bannerBundleSsrList = differences.map(function (x) { return ({ bundleCode: lowestUpgradeBundle_1.serviceBundleCode, ssrCode: x.ssrCode }); });
                }
            }
            else {
                this.bannerBundleSsrList = [];
            }
        }
    };
    PassengerBundleComponent.prototype.getCustomClasses = function (bundleIndex, journeyIndex) {
        var selectionClass = this.currentBundlesSelected[journeyIndex] == bundleIndex ? this.selectedClassName : '';
        var threeColumnClass = this.journeyBundles[journeyIndex].bundleList.length % 3 == 0 ? this.threeColumnClassName : '';
        return selectionClass.concat(' ', threeColumnClass);
    };
    PassengerBundleComponent.prototype.cancelChanges = function () {
        this.loadData();
        this.toggleExpanded(false);
    };
    PassengerBundleComponent.prototype.checkPendingBundle = function () {
        return this.isExpanded && this.boughtBundles.some(function (x) { return !x.isSelected; });
    };
    PassengerBundleComponent.prototype.togglebundleForBothInfoModalId = function () {
        this.bundleForBothInfoModal.openPopup(this.bundleForBothInfoModalId);
    };
    PassengerBundleComponent.prototype.closebundleForBothInfoModal = function () {
        this.bundleForBothInfoModal.cancel();
    };
    return PassengerBundleComponent;
}());
export { PassengerBundleComponent };
var BundleUpgrade = /** @class */ (function () {
    function BundleUpgrade(departure, arrival, initialBundle, selectedBundle) {
        this.departureStation = departure;
        this.arrivalStation = arrival;
        this.initialBundle = initialBundle;
        this.selectedBundle = selectedBundle;
    }
    return BundleUpgrade;
}());
export { BundleUpgrade };
var SSRInBundlePerSegmentAndPassenger = /** @class */ (function () {
    function SSRInBundlePerSegmentAndPassenger(passengerNumber, ssrCode, ssrType, segment, bookedSsrs, maxQuantityPerPassenger, includedQuantityInBundle) {
        if (includedQuantityInBundle === void 0) { includedQuantityInBundle = 1; }
        this.passengerNumber = passengerNumber;
        this.ssrCode = ssrCode;
        this.ssrType = ssrType;
        this.segment = segment;
        this.bookedSsrs = bookedSsrs;
        this.maxQuantityPerPassenger = maxQuantityPerPassenger;
        this.includedQuantityInBundle = includedQuantityInBundle;
    }
    SSRInBundlePerSegmentAndPassenger.prototype.getSsrNumbersToRemove = function () {
        var ssrsToRemove = [];
        var quantityToRemove = (this.bookedSsrs.length + this.includedQuantityInBundle) - this.maxQuantityPerPassenger;
        for (var i = this.bookedSsrs.length - 1; i >= this.bookedSsrs.length - quantityToRemove; i--) {
            ssrsToRemove.push(this.bookedSsrs[i]);
        }
        return ssrsToRemove;
    };
    return SSRInBundlePerSegmentAndPassenger;
}());
var FlightSSRsInBundle = /** @class */ (function () {
    function FlightSSRsInBundle(flightIndex, flight, selectedBundle, ssrService, bookingService) {
        var _this = this;
        this.flightIndex = flightIndex;
        this.flight = flight;
        this.selectedBundle = selectedBundle;
        this.ssrService = ssrService;
        this.bookingService = bookingService;
        this.ssrs = [];
        selectedBundle.serviceBundleSsrs.forEach(function (ssrInBundle) {
            _this.flight.segments.forEach(function (segment) {
                _this._createSsrsPerSegmentAndPassenger(ssrInBundle.ssrCode, segment).forEach(function (ssrPerSegmentAndPassenger) {
                    _this.ssrs.push(ssrPerSegmentAndPassenger);
                });
            });
        });
    }
    FlightSSRsInBundle.prototype._createSsrsPerSegmentAndPassenger = function (ssrCode, segment) {
        var e_4, _a, e_5, _b, e_6, _c;
        var segmentKey = segment.departureStation.iataCode + '_' + segment.arrivalStation.iataCode;
        try {
            for (var _d = tslib_1.__values(this.ssrService.availableSsrs), _e = _d.next(); !_e.done; _e = _d.next()) {
                var availableSsr = _e.value;
                try {
                    for (var _f = tslib_1.__values(availableSsr.value), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var availableSsrPerSegment = _g.value;
                        if (availableSsrPerSegment.key === segmentKey) {
                            try {
                                for (var _h = tslib_1.__values(availableSsrPerSegment.value), _j = _h.next(); !_j.done; _j = _h.next()) {
                                    var ssr = _j.value;
                                    if (ssr.ssrCode === ssrCode) {
                                        var result = [];
                                        for (var passengerNumber = 0; passengerNumber < ssr.limitPerPassenger.length; passengerNumber++) {
                                            result.push(new SSRInBundlePerSegmentAndPassenger(passengerNumber, ssrCode, availableSsr.key, segment, this._getBookedSsrs(passengerNumber, segment, ssrCode), ssr.limitPerPassenger[passengerNumber].value));
                                        }
                                        return result;
                                    }
                                }
                            }
                            catch (e_6_1) { e_6 = { error: e_6_1 }; }
                            finally {
                                try {
                                    if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                                }
                                finally { if (e_6) throw e_6.error; }
                            }
                        }
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return [];
    };
    FlightSSRsInBundle.prototype._getBookedSsrs = function (passengerNumber, segment, ssrCode) {
        var e_7, _a, e_8, _b;
        var journey = this.bookingService.getBooking().assignedSeats.journeys.items[this.flightIndex];
        var ssrNumbers = [];
        try {
            for (var _c = tslib_1.__values(journey.segments.items), _d = _c.next(); !_d.done; _d = _c.next()) {
                var seg = _d.value;
                if (seg.departureStation === segment.departureStation.iataCode && seg.arrivalStation === segment.arrivalStation.iataCode) {
                    try {
                        for (var _e = tslib_1.__values(seg.paxSsrs.items), _f = _e.next(); !_f.done; _f = _e.next()) {
                            var paxSsr = _f.value;
                            if (paxSsr.ssrCode === ssrCode && paxSsr.passengerNumber === passengerNumber) {
                                ssrNumbers.push(paxSsr.ssrNumber);
                            }
                        }
                    }
                    catch (e_8_1) { e_8 = { error: e_8_1 }; }
                    finally {
                        try {
                            if (_f && !_f.done && (_b = _e.return)) _b.call(_e);
                        }
                        finally { if (e_8) throw e_8.error; }
                    }
                }
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_7) throw e_7.error; }
        }
        return ssrNumbers;
    };
    return FlightSSRsInBundle;
}());
