<check-in-default-modal #incorrectSelectionModal [modalId]="incorrectSelectionModalId"
    modalTitle="{{'Invalid selection' | translate: 'flight-select'}}"
    modalText="{{'The selection is not valid! Inbound date is in past of the outbound date!' | translate: 'flight-select'}}"
    okButtonText="{{'Ok' | translate: 'flight-select'}}" [okRequired]="true" [cancelRequired]="false">
</check-in-default-modal>

<!-- start of flight search page header  -->
<div class="availability-header">
    <h1 *ngIf="tripIndex == 0">
        <div class="availability-header-wrap">
            <div class="text">
                <div class="wrap">
                    {{ 'Outbound flight' | translate: 'flight-select'}}
                </div>
            </div>
            <div class="buttons">
                <button class="header-btn search" (click)="toggleSearch()">
                    <span *ngIf="isChangeSearchOpened">
                        {{ 'Cancel Changes' | translate: 'flight-select'}}
                    </span>
                    <span *ngIf="!isChangeSearchOpened">
                        {{ 'Change Search' | translate: 'flight-select'}}
                    </span>
                </button>
            </div>
            <div class="buttons" *ngIf="false">
                <a class="header-btn calendars" routerLink="/calendar">
                    {{ 'Calendar' | translate: 'flight-select'}}
                </a>
            </div>
        </div>
    </h1>

    <h1 *ngIf="tripIndex != 0">
        {{ 'Inbound flight' | translate: 'flight-select'}}
    </h1>

    <ul class="low-fare-tab" *ngIf="lowFare">
        <li class="navigation">
            <button class="navigation low-fare-button" (click)="changeDay(0)">
                <img [src]=" assetsPath + 'svg/ico-arrow-right.svg'" style=" transform: rotate(180deg);">
            </button>
        </li>
        <li *ngFor="let lf of lowFare"
            [attr.class]="lf.departureDate === trip.flightDates[0].departureDate ?'selected':''">
            <button [disabled]="lf.departureDate === trip.flightDates[0].departureDate" class="low-fare-button"
                (click)="lf.departureDate !== trip.flightDates[0].departureDate && changeDate(lf.departureDate)">
                <div class="low-fare-date">
                    {{lf.departureDate | customDate :'fulldate'}}
                </div>
                <div class="low-fare-price" *ngIf="!lf.noFlights && !bookingSnapshot">
                    {{lf.fareAmount | currencyDisplay}}
                </div>
                <div class="low-fare-price" *ngIf="lf.noFlights">
                    <img [src]="assetsPath + 'svg/planeUnavailable.svg'">
                </div>
            </button>
        </li>
        <li class="navigation">
            <button (click)="changeDay(1)" class="navigation low-fare-button">
                <img [src]="assetsPath + 'svg/ico-arrow-right.svg'">
            </button>
        </li>
    </ul>
    <div class="flights-na" *ngIf="noFlights(trip)">
        <p>
            {{'Unfortunately we cannot find flights for the selected date.' | translate}}
        </p>
    </div>
</div>
<!-- end of flight search page header  -->

<div *ngFor="let fd of trip.flightDates">
    <!-- begin of bundle section display -->
    <div class="flight-header" *ngIf="!noFlights(trip)">
        <div class="flight-grid-left">
            <div class="flight-header-date">
                <label class="flight-date">{{fd.departureDate |customDate:'longfulldateyear'}}</label>
                <h2>{{ '{0} to {1}' | translate: null : [trip.origin | translate: 'station.name'] : [trip.destination |
                    translate: 'station.name']}}
                </h2>
                <div class="label-details" *ngIf="howManiUsers(fd) > 3">
                    {{ howManiUsers(fd) }} {{ 'People interested'|translate }}
                </div>
                <div class="label-details icon-link" *ngIf="consularText" [innerHTML]="consularText">
                </div>
            </div>
        </div>
        <div class="flight-grid-right">
        </div>
    </div>
    <!-- end of bundle section display -->

    <!-- begin of common included ssrs from bundles  -->
    <div class="all-travel-includes" *ngIf="commonSsrs.length">
        <div class="left">
            <h3>{{'All travel classes include:' | translate: 'Booking'}}</h3>
        </div>
        <div class="right">
            <ul class="list">
                <li *ngFor="let ssr of commonSsrs">
                    <!-- <div class="icon">
                        <img class="bundle-icons" attr.src="{{getBundleImage(sortedAvailableBundles[0][0].serviceBundleCode, ssr)}}" [imgDefaultSrc]="defaultBundleImage" />
                    </div> -->
                    <span
                        class="ssr-icon blue-dark font2-ico-bundle-ssrs-{{sortedAvailableBundles[0][0].serviceBundleCode | lowercase}}-{{ssr.ssrCode| lowercase}} font2-ico-ssrs-{{ssr| lowercase}} font-ico-checked-sign"
                        role="presentation">
                    </span>
                    <div class="text">
                        {{sortedAvailableBundles[0][0].serviceBundleCode + '.' + ssr | translate: 'Booking'}}
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- end of common included ssrs from bundles  -->

    <!-- begin of flights list display -->
    <div class="flights">
        <div class="flight" *ngFor="let flight of fd.flights">
            <div [attr.class]="showBlueBenefits ? 'checkin-flight-item benefits' : 'checkin-flight-item'">

                <!-- start of left side flight information -->
                <button *ngIf="flight.segments.length > 1" class="detail-light-button desktop"
                    (click)="flight.showDetailed = !flight.showDetailed">
                    <span>{{'Show details' | translate}}</span>
                </button>
                <div class="flight-grid-left flight-info clickable"
                    (click)="sellFare(flight,flight.basePrice[0], false, false, true)">
                    <div class="checkbox-holder">
                        <!-- show details button
                        click: toggles the 'expanded flight details view' & compresed flight details view -->
                        <button *ngIf="flight.segments.length > 1" class="detail-light-button mobile"
                            (click)="flight.showDetailed = !flight.showDetailed">
                            <span>{{'Show details' | translate}}</span>
                        </button>
                        <!-- flight number information -->
                        <div class="carrier-wrap">
                            <div class="carrier" *ngFor="let segment of flight.segments">
                                <div class="airline-logo-item">
                                    <img [src]="assetsPath +  'img/' + segment.flightDesignator.carrierCode + '.png'">
                                </div>
                                <div class="flight-number">
                                    {{ segment.flightDesignator.carrierCode}} {{segment.flightDesignator.flightNumber}}
                                    <span
                                        *ngIf="segment.xrefFlightDesignator.carrierCode && segment.segmentType === 'L'">*</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flight-details flight-grid">
                        <!-- expanded flight details view -->
                        <div *ngIf="flight.showDetailed">
                            <div *ngFor="let segment of flight.segments; index as i">
                                <div class="left-detail">
                                    <span class="location">{{ segment.departureStation }}</span>
                                    <time class="time-big">{{ segment.std | customDate:'time' }}</time>
                                </div>
                                <div class="middle-detail">
                                    <span *ngIf="!isBus(flight.legs,segment)" class="font-ico-plane-runway"></span>
                                    <span *ngIf="isBus(flight.legs,segment)" class="font-ico-shuttle"></span>
                                    <time class="flight-duration"
                                        attr.datetime="{{flight.std | duration:flight.std:true}}">
                                        {{segment.travelTime | durationDisplay}}
                                    </time>
                                </div>
                                <div class="right-detail">
                                    <span class="location"> {{ segment.arrivalStation }}</span>
                                    <time class="time-big" attr.datetime="{{ segment.sta| customDate:'time' }}">
                                        {{ segment.sta | customDate:'time' }}
                                    </time>
                                </div>
                                <div class="operating-carier-details"
                                    *ngIf="i === flight.segments.length - 1  && isNextDay(flight.segments[0].std, flight.segments[flight.segments.length - 1].sta)">
                                    {{"Arrives next day!" | translate}}</div>
                                <div class="connection-detail" *ngIf="i === 0">
                                    {{flight.segments[0].sta|duration:flight.segments[flight.segments.length-1].std:false}}
                                    {{"connection" | translate}}
                                </div>
                            </div>
                            <div *ngIf="flight.fares && flight.fares.length && flight.fares[0].remainingSeats < 5"
                                class="seat-remaining">
                                <label class="alert-primary">{{'Only {0} seats available'|
                                    translate:'flight-select':flight.fares[0].remainingSeats}}
                                </label>
                            </div>
                        </div>
                        <!-- compresed flight details view -->
                        <div *ngIf="!flight.showDetailed">
                            <div class="left-detail">
                                <span class="location">{{ flight.origin }}</span>
                                <time class="time-big">{{ flight.standardTimeOfDeparture | customDate:'time'}}</time>
                            </div>
                            <div class="middle-detail">
                                <div class="label-details" *ngIf="flight.segments.length > 1">
                                    {{ getNumberOfStops(flight) == 1 ? "1 stop" : "{0} stops" | translate : "no-context"
                                    : getNumberOfStops(flight) }}
                                </div>
                                <span class="font-ico-plane-runway" *ngIf="flight.segments.length <= 1"></span>
                                <time class="flight-duration"
                                    attr.datetime="{{flight.standardTimeOfDeparture | duration:flight.standardTimeOfArrival:true}}">
                                    {{flight.travelTime | durationDisplay}}
                                </time>
                            </div>
                            <div class="right-detail">
                                <span class="location"> {{ flight.destination }}</span>
                                <time class="time-big"
                                    attr.datetime="{{ flight.standardTimeOfArrival| customDate:'time'}}">
                                    {{ flight.standardTimeOfArrival | customDate:'time'}}
                                </time>
                            </div>
                            <div class="operating-carier-details"
                                *ngIf="isNextDay(flight.standardTimeOfDeparture, flight.standardTimeOfArrival)">
                                {{"Arrives next day!" | translate}}</div>
                            <div *ngIf="flight.fares && flight.fares.length && flight.fares[0].remainingSeats < 5"
                                class="seat-remaining">
                                <label class="alert-primary">
                                    {{'Only {0} seats available'|
                                    translate:'flight-select':flight.fares[0].remainingSeats}}
                                </label>
                            </div>
                        </div>
                        <!-- operating career code info -->
                        <div class="operating-carier-details" *ngIf="flight.operatingCarrierInfo.operatedByText">
                            <span *ngIf="flight.operatingCarrierInfo.carrierCode">
                                <span *ngFor="let segment of flight.segments">
                                    <span
                                        *ngIf="segment.xrefFlightDesignator.carrierCode === flight.operatingCarrierInfo.carrierCode">
                                        *{{segment.flightDesignator.carrierCode}}
                                        {{segment.flightDesignator.flightNumber}}
                                    </span>
                                </span>
                            </span>
                            <ng-container *ngFor="let operator of getOperatedText(flight)">
                                <p>*{{operator}}</p>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!-- end of left side flight information -->

                <!-- flight fares display -->
                <div class="price-display flight-grid-right">
                    <!-- start of blue benefits price display -->
                    <ul class="fare">
                        <li *ngIf="isMoveFlightsFlow"
                            [attr.class]="isMoveFlightSelected(flight)?'price-item-wrap selected-item':'price-item-wrap'">
                            <div>
                                <button (click)="updateSellKeys(flight,tripIndex)"
                                    [attr.class]="isMoveFlightSelected(flight)?'price-item selected-item':'price-item'">
                                    <div class="price-wrapper" *ngIf="!isMoveFlightSelected(flight);else selected">
                                        <div>
                                            <div class="price-main">
                                                <div class="price">
                                                    {{'Select' | translate: 'shared'}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- for selected state -->
                                    <ng-template #selected>
                                        <div class="price-selected">
                                            <div class="price-selected-wrap">
                                                <div class="price-selected-title">
                                                    {{'Selected'|translate:'flight-select'}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </button>
                            </div>
                        </li>
                        <li [attr.class]="isFareSelected(flight,price,false)?'price-item-wrap selected-item':'price-item-wrap'"
                            *ngFor="let price of flight.basePrice">
                            <div *ngIf="checkState(price, flight, currentSelection,false) else notUpgradeState">

                                <button class="price-item upgrade" (click)="sellFare(flight,price, false)">
                                    <!--todo:change to actual fare price-->
                                    <div class="price-item-title">{{'Upgrade for'|translate}} </div>
                                    <div>
                                        <div class="price-item-price">
                                            {{ (getFarePrice(price,false) - currentSelection.price) |
                                            cultureFormatNumber:'1.2-2' }}
                                        </div>
                                        <div class="price-item-price">
                                            {{ getFarePrice(price,false) |
                                            cultureFormatNumber:'1.2-2' }}
                                        </div>
                                        <div class="price-item-currency">{{bookingCurrency}}</div>
                                    </div>
                                    <div class="price-item-description">
                                        <span>
                                            {{ 'Includes:' | translate: 'flight-select'}}
                                            {{getFareBundleText(price,fd.departureDate)}}
                                        </span>
                                    </div>
                                </button>
                            </div>
                            <ng-template #notUpgradeState>
                                <div>

                                    <button (click)="sellFare(flight,price, false, false, true)"
                                        [attr.class]="isFareSelected(flight,price,false)?'price-item selected-item':'price-item'"
                                        [disabled]="price.passengerFares[0].notAvailable || price.disableFare">
                                        <div class="price-selected" *ngIf="isFareSelected(flight,price,false)">
                                            <div class="price-selected-wrap">
                                                <div class="price-selected-title">
                                                    {{'Selected'|translate:'flight-select'}}
                                                </div>
                                                <div class="price-selected-price">
                                                    {{getFarePrice(price,false) | cultureFormatNumber:'1.2-2'}}
                                                </div>
                                                <div class="price-selected-currency">{{bookingCurrency}}</div>
                                            </div>
                                        </div>
                                        <div class="price-wrapper" *ngIf="!isFareSelected(flight,price,false)">
                                            <div class="offer" *ngIf="isValidPromo(price)">
                                                {{'Offer'|translate:'flight-select'}}</div>
                                            <div>
                                                <div class="full-price" *ngIf="isValidPromo(price)">
                                                    <span>
                                                        {{ getFullPrice(price) | cultureFormatNumber:'1.2-2' }}
                                                    </span>
                                                </div>
                                                <div class="price-main"
                                                    *ngIf="price.passengerFares[0].notAvailable || price.disableFare else displayPrice">
                                                    <div class="price">{{'N/A'|translate:'flight-select'}}</div>
                                                </div>
                                                <ng-template #displayPrice>
                                                    <div class="price-main">
                                                        <div class="price">
                                                            <span *ngIf="bookingSnapshot">+</span>
                                                            {{getFarePrice(price,false) | cultureFormatNumber:'1.2-2'}}
                                                        </div>
                                                        <div class="currency">{{bookingCurrency}}</div>
                                                        <div class="select-flight-label">{{'Select a flight' | translate :
                                                            'flight-select'}}
                                                        </div>

                                                    </div>
                                                    <div *ngIf="isAgent">
                                                        <span>{{'Booking class: {0}' | translate : 'flight-select' :
                                                            price.classOfService}}</span>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </ng-template>
                        </li>
                        <li *ngIf="showBlueBenefits" [ngClass]="{'hidden': !flightHasBenefitDiscount(flight)}"
                            [attr.class]="isFareSelected(flight.basePrice[0],price,true)?'price-item-wrap selected-item':'price-item-wrap'">
                            <div
                                *ngIf="checkState(flight.basePrice[0], flight, currentSelection, true) else notUpgradeStateBlueBenefits">
                                <button class="price-item upgrade" (click)="sellFare(flight,price,true)">
                                    <div class="price-item-title">{{'Upgrade for'|translate}} </div>
                                    <div>
                                        <div class="price-item-price">
                                            {{(getFarePrice(flight.basePrice[0],true) - currentSelection.price) |
                                            cultureFormatNumber:'1.2-2' }}
                                        </div>
                                        <div class="price-item-currency">{{bookingCurrency}}</div>
                                    </div>
                                    <div class="price-item-description">
                                        <span>
                                            {{ 'Includes:' | translate: 'flight-select'}}
                                            {{getFareBundleText(price,fd.departureDate)}}
                                        </span>
                                    </div>
                                    <div class="label-benefits">
                                        <span>{{ 'with' | translate: 'flight-select'}}</span>
                                        <span>{{ 'Blue Benefits' | translate: 'flight-select'}}</span>
                                    </div>
                                </button>
                            </div>
                            <ng-template #notUpgradeStateBlueBenefits>
                                <div>
                                    <button (click)="sellFare(flight, flight.basePrice[0], true)"
                                        [attr.class]="isFareSelected(flight,flight.basePrice[0],true)?'price-item selected-item':'price-item'"
                                        [disabled]="flight.basePrice[0].passengerFares[0].notAvailable || flight.basePrice[0].disableFare">
                                        <div class="price-selected"
                                            *ngIf="isFareSelected(flight,flight.basePrice[0],true)">
                                            <div class="price-selected-wrap">
                                                <div class="price-selected-title">
                                                    {{ 'Selected' | translate:'flight-select' }}</div>
                                                <div class="price-selected-price">
                                                    {{getFarePrice(flight.basePrice[0],true) |
                                                    cultureFormatNumber:'1.2-2'}}
                                                </div>
                                                <div class="price-selected-currency">{{ bookingCurrency }}</div>
                                            </div>
                                        </div>
                                        <div class="price-wrapper"
                                            *ngIf="!isFareSelected(flight,flight.basePrice[0],true)">
                                            <div>
                                                <div class="price-main"
                                                    *ngIf="flight.basePrice[0].passengerFares[0].notAvailable || flight.basePrice[0].disableFare else displayPriceBlueBenefits">
                                                    <div class="price">{{ 'N/A'|translate:'flight-select' }}</div>
                                                </div>
                                                <ng-template #displayPriceBlueBenefits>
                                                    <div class="price-main">
                                                        <div class="price">
                                                            {{ getFarePrice(flight.basePrice[0],true) |
                                                            cultureFormatNumber:'1.2-2' }}
                                                        </div>
                                                        <div class="currency">{{ bookingCurrency }}</div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                        <div class="label-benefits">
                                            <span>{{ 'with' | translate: 'flight-select'}}</span>
                                            <span>{{ 'Blue Benefits' | translate: 'flight-select'}}</span>
                                        </div>
                                    </button>
                                </div>
                            </ng-template>
                        </li>
                    </ul>
                    <!-- end of blue benefits price display-->
                </div>

            </div>
            <div class="flight-fares" *ngIf="isCurrentSelection(flight)">
                <div class="flight-fares-title">
                    <span class="text">
                        {{ 'How do you want to travel?' | translate: 'flight-select' }}
                    </span>
                </div>
                <blue-loading-spinner [id]="spinnerId"></blue-loading-spinner>
                <ul class="fare-header-v2 nowrap">
                    <li *ngFor="let bundleList of bundleV2"
                        [attr.class]="isBundleSelected(bundleList.serviceBundleCode,flight) ?'selected':''">
                        <div *ngIf="bundleList">
                            <div class="open-modal-dialog"
                                (click)="openModalDialog(bundleList.serviceBundleCode + tripIndex)"></div>
                            <div class="wrap" [ngClass]="{'multiple-bundle-list': bundleList}">
                                <div class="fare-header-promotion">
                                    {{ 'BEST VALUE' | translate: 'flight-select' }}
                                </div>
                                <h3 [innerHTML]='bundleList.serviceBundleCode | ssrTranslate '>
                                </h3>
                                <div class="list-header">
                                    <!-- if multiple carrier codes -->
                                    <!-- <div class="list-image" *ngIf="bundleList">
                                        <img [src]="assetsPath + 'svg/icon-' + bundleList.carrierCode + '.svg'">
                                        <span>{{ bundleList.carrierCode | translate : 'Booking'}}</span>
                                    </div> -->
                                </div>
                                <ul class="list" *ngFor="let priceList of bundleList.serviceBundlePriceList"
                                    [ngClass]="{'show':multipleBundleList,'single-line': bundleV2.length === 1 }">
                                    <li *ngFor="let ssr of priceList.serviceBundleSsrPriceList">
                                        <!-- <div class="icon"><img class="bundle-icons" attr.src="{{getBundleImage(bundleList[0].serviceBundleCode, ssr.ssrCode)}}" [imgDefaultSrc]="defaultBundleImage"></div> -->
                                        <span
                                            class="ssr-icon font2-ico-bundle-ssrs-{{bundleList.serviceBundleCode | lowercase}}-{{ssr.ssrCode| lowercase}} font2-ico-ssrs-{{ssr.ssrCode| lowercase}} font-ico-checked-sign"
                                            role="presentation"></span>


                                        <div class="text"
                                            [innerHTML]='ssr.ssrCode | ssrTranslate :bundleList.serviceBundleCode : fd.departureDate'>
                                        </div>
                                        <!-- <object type="image/svg+xml" data="http://localhost:4200/assets/svg/ico-checked-sign.svg" class="footer-icon">

                                         </object> -->
                                    </li>
                                </ul>
                                <button class="bundle-price" (click)="selectBundle(bundleList, flight)">
                                    <div class="price-selected-price"
                                        *ngIf="!isBundleSelected(bundleList.serviceBundleCode,flight);else bundleSelected">
                                        <div *ngIf="!isBlueBenefitsSelected;else blueBenefitPrice">
                                            {{ getBundlePrice(flight, bundleList, false) }} {{ bookingCurrency }}</div>
                                        <ng-template #blueBenefitPrice>
                                            <div>{{ getBundlePrice(flight, bundleList, true) }} {{ bookingCurrency }}
                                            </div>
                                        </ng-template>
                                    </div>
                                    <ng-template #bundleSelected>
                                        <div class="price-selected-title">
                                            {{'Selected'|translate:'flight-select'}}
                                            <span style="display: none;">{{ getBundlePrice(flight, bundleList, false)
                                                }}</span>
                                        </div>
                                    </ng-template>
                                </button>
                                <div class="list-other-wrap" *ngIf="bundleList.length > 1">
                                    <div class="list-other-header">
                                        <div class="list-other-toggle-switch" (click)="toggleVisibilityDesktop()">
                                            <span class="see-more" [hidden]="multipleBundleList">
                                                {{ 'See more' | translate: 'Booking'}}
                                            </span>
                                            <span class="see-less" [hidden]="!multipleBundleList">
                                                {{ 'See less' | translate: 'Booking'}}
                                            </span>
                                        </div>
                                        <div class="list-other-image">
                                            <span>{{ bundleList.carrierCode | translate : 'Booking'}}</span>
                                            <img [src]=" assetsPath + 'svg/icon-' + bundleList[1].carrierCode + '.svg'">
                                        </div>
                                    </div>
                                    <ul class="list" *ngFor="let priceList of bundleList[1].serviceBundlePriceList"
                                        [ngClass]="{'show':multipleBundleList}">
                                        <li *ngFor="let ssr of priceList.serviceBundleSsrPriceList">
                                            <div class="icon"><img class="bundle-icons"
                                                    attr.src="{{getBundleImage(bundleList[0].serviceBundleCode, ssr.ssrCode)}}"
                                                    [imgDefaultSrc]="defaultBundleImage"></div>
                                            <div class="text">
                                                {{ bundleList.serviceBundleCode + '.' + ssr.ssrCode | translate :
                                                'Booking' }}
                                            </div>
                                        </li>
                                    </ul>
                                    <div class="list-other-toggle-switch" (click)="toggleVisibilityDesktop()">
                                        <span class="see-more" [hidden]="multipleBundleList">{{ 'See more' | translate:
                                            'Booking' }}</span>
                                        <span class="see-less" [hidden]="!multipleBundleList">{{ 'See less' | translate:
                                            'Booking' }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>

        </div>




    </div>
    <!-- end of flights list display -->
</div>

<!-- <blue-modal id="bundle-popup">
    <passenger-bundle></passenger-bundle>
</blue-modal> -->

<!-- mobile -->
<blue-modal hidden class="checked-in-summary-popup " *ngFor="let bundleList of sortedAvailableBundles "
    [id]="bundleList[0].serviceBundleCode + tripIndex ">
    <div class="modal-to-be ">
        <h2>{{bundleList[0].serviceBundleCode | translate : 'Booking'}}</h2>
        <!-- start acordeon multiple carrier codes -->
        <div class="checkin-accordion " *ngIf="bundleList.length> 1">
            <div class="checkin-acc-item" *ngFor="let bundle of bundleList" aria-haspopup="true">
                <button type="button" class="checkin-acc-head" (click)="toggleVisibility(th)">
                    <img [src]="assetsPath + 'svg/icon-' + bundle.carrierCode + '.svg'">
                    <span>{{ bundle.carrierCode | translate : 'Booking'}}</span>
                </button>
                <div class="checkin-acc-content" #th>
                    <ul>
                        <ng-container *ngFor="let priceList of bundle.serviceBundlePriceList">
                            <li *ngFor="let ssr of priceList.serviceBundleSsrPriceList">
                                <!-- <div class="icon">
                                    <img class="bundle-icons" attr.src="{{getBundleImage(bundleList[0].serviceBundleCode, ssr.ssrCode)}}" [imgDefaultSrc]="defaultBundleImage" />
                                </div> -->
                                <span
                                    class="ssr-icon blue-dark font2-ico-bundle-ssrs-{{bundle.serviceBundleCode | lowercase}}-{{ssr.ssrCode| lowercase}} font2-ico-ssrs-{{ssr.ssrCode| lowercase}} font-ico-checked-sign"
                                    role="presentation"></span>
                                <div class="text">
                                    {{bundle.serviceBundleCode + '.' + ssr.ssrCode | translate : 'Booking'}}
                                </div>

                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
        <!-- end acordeon multiple carrier codes -->

        <!-- single career code -->
        <ul *ngIf="bundleList.length == 1">
            <ng-container *ngFor="let priceList of bundleList[0].serviceBundlePriceList">
                <li *ngFor="let ssr of priceList.serviceBundleSsrPriceList">
                    <!-- <div class="icon">
                        <img class="bundle-icons" attr.src="{{getBundleImage(bundleList[0].serviceBundleCode, ssr.ssrCode)}}" [imgDefaultSrc]="defaultBundleImage" />
                    </div> -->
                    <span
                        class="ssr-icon blue-dark font2-ico-bundle-ssrs-{{bundleList[0].serviceBundleCode | lowercase}}-{{ssr.ssrCode| lowercase}} font2-ico-ssrs-{{ssr.ssrCode| lowercase}} font-ico-checked-sign"
                        role="presentation"></span>
                    <div class="text">
                        {{bundleList[0].serviceBundleCode + '.' + ssr.ssrCode | translate : 'Booking'}}
                    </div>


                </li>
            </ng-container>
        </ul>
        <!-- end single career code -->

        <ng-container *ngIf="commonSsrs.length">
            <h3>{{'All travel classes include:' | translate: 'Booking'}}</h3>
            <ul>
                <li *ngFor="let ssr of commonSsrs">
                    <!-- <div class="icon">
                        <img class="bundle-icons" attr.src="{{getBundleImage(sortedAvailableBundles[0][0].serviceBundleCode, ssr)}}" [imgDefaultSrc]="defaultBundleImage" />
                    </div> -->
                    <span
                        class="ssr-icon blue-dark font2-ico-bundle-ssrs-{{sortedAvailableBundles[0][0].serviceBundleCode | lowercase}}-{{ssr.ssrCode| lowercase}} font2-ico-ssrs-{{ssr | lowercase}} font-ico-checked-sign"
                        role="presentation"></span>
                    <div class="text">
                        {{sortedAvailableBundles[0][0].serviceBundleCode + '.' + ssr | translate: 'Booking'}}
                    </div>
                </li>
            </ul>
        </ng-container>
    </div>
</blue-modal>