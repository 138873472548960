/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component";
import * as i4 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i5 from "./logout.component";
import * as i6 from "../../core/profile.service";
import * as i7 from "@angular/router";
import * as i8 from "../../core/booking-steps.service";
var styles_LogoutComponent = [i0.styles];
var RenderType_LogoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LogoutComponent, data: {} });
export { RenderType_LogoutComponent as RenderType_LogoutComponent };
export function View_LogoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "height: 300px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "blue-loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 4964352, null, 0, i3.LoadingSpinnerComponent, [i4.LoadingSpinnerService, i1.ElementRef, i1.Renderer2], { showSpinner: [0, "showSpinner"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LogoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-logout", [], null, null, null, View_LogoutComponent_0, RenderType_LogoutComponent)), i1.ɵdid(1, 114688, null, 0, i5.LogoutComponent, [i6.ProfileService, i7.Router, i8.BookingStepsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogoutComponentNgFactory = i1.ɵccf("profile-logout", i5.LogoutComponent, View_LogoutComponent_Host_0, {}, {}, []);
export { LogoutComponentNgFactory as LogoutComponentNgFactory };
