import { BookingConsentComponent } from './booking-consent.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // RouterModule.forRoot(
    //   BookingConsentRoutes
    // ),
    SharedModule,
    BlueAirCommonModule
  ],
  declarations: [BookingConsentComponent],
  providers: []
})

export class BookingConsentModule { }
