import { ShoppingCartFeeModel } from './shopping-cart-fee-model';

export class ShoppingCartPassengerModel {
    passengerType: 'ADT' | 'YTH' | 'CLD' | 'CHD' | 'INF';
    count: number;
    discountCode: string;

    private _amount: number;
    get amount() { return this._amount; }
    set amount(value: number) { this._amount = Math.max(value, 0); }

    get paxFareKey() { return !this.discountCode ? this.passengerType : `${this.passengerType}_${this.discountCode}`; }

    fees: ShoppingCartFeeModel[];

    constructor(passenger?: ShoppingCartPassengerModel) {
        if (!passenger) {
            this.fees = [];
            return;
        }

        this.passengerType = passenger.passengerType;
        this.count = passenger.count;
        this.amount = passenger.amount;
        this.fees = passenger.fees;
        this.discountCode = passenger.discountCode;
    }

    init(passenger: any): ShoppingCartPassengerModel {
        this.passengerType = passenger.passengerType !== 'INFT' ? passenger.passengerType : 'INF';
        this.count = passenger.count;
        this.amount = passenger.amount;
        this.fees = passenger.fees.map(fee => new ShoppingCartFeeModel().init(fee));

        return this;
    }
}
