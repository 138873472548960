export enum CheckinClosedReason {
    None = 0,
    ClosedForMarket = 1,
    ClosedForBundle = 2,
    ClosedForCodeshare = 3,
    ClosedForUnderMinimumTimeBeforeDeparture = 4,
    ClosedForChildrenNoSeatsSelected = 5
}


export class CheckinRestrictionModel {
    journeySellKey: string;
    checkinOpened: boolean;
    checkinClosedReason: CheckinClosedReason;
    currentBundleAllowedDays: number;
    currentBundle: string;
    upgradeBundle: string;
    departureStation: string;
    departureStationCountry: string;
    arrivalStation: string;
    arrivalStationCountry: string;
    routeName: string;
    segmentsCount: number;

    initFromRestriction(restriction: any): CheckinRestrictionModel {
        this.journeySellKey = restriction.journeySellKey;
        this.checkinOpened = restriction.checkinOpened;
        this.checkinClosedReason = restriction.checkinClosedReasonId;
        this.currentBundleAllowedDays = restriction.currentBundleAllowedDays;
        this.currentBundle = restriction.currentBundle;
        this.upgradeBundle = restriction.upgradeBundle;
        this.departureStation = restriction.departureStation;
        this.departureStationCountry = restriction.departureStationCountry;
        this.arrivalStation = restriction.arrivalStation;
        this.arrivalStationCountry = restriction.arrivalStationCountry;
        this.segmentsCount = restriction.segmentsCount;

        return this;
    }
}
