/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./session-warning.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "../../common-modules/blue-air-common/loading-spinner/loading-spinner.component";
import * as i4 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i5 from "@angular/common";
import * as i6 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i7 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i8 from "../../common-modules/blue-air-common/blue-modal/blue-modal.component.ngfactory";
import * as i9 from "../../common-modules/blue-air-common/services/blue-renderer.service";
import * as i10 from "../../common-modules/blue-air-common/blue-modal/blue-modal.component";
import * as i11 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i12 from "./session-warning.component";
import * as i13 from "../../common-modules/blue-air-common/session.service";
var styles_SessionWarningComponent = [i0.styles];
var RenderType_SessionWarningComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SessionWarningComponent, data: {} });
export { RenderType_SessionWarningComponent as RenderType_SessionWarningComponent };
function View_SessionWarningComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "blue-loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 4964352, null, 0, i3.LoadingSpinnerComponent, [i4.LoadingSpinnerService, i1.ElementRef, i1.Renderer2], { id: [0, "id"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ":", ""])), i1.ɵppd(8, 2), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 2, "h2", [["id", "continueBrowsing"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "])), i1.ɵppd(12, 2), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["aria-labelledby", "continueBrowsing"], ["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.refreshSession() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(14, null, [" ", " "])), i1.ɵppd(15, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionRefreshingSpinnerId; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), "Your session is about to expire", "session-warning")); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 1), _co.sessionNotification.timeLeftToExpire.minutes, "2.0-0")); var currVal_3 = i1.ɵunv(_v, 7, 1, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 1), _co.sessionNotification.timeLeftToExpire.seconds, "2.0-0")); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent.parent, 0), "If you would like to continue browsing, please press continue.", "session-warning")); _ck(_v, 11, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v.parent.parent, 0), "Continue", "session-warning")); _ck(_v, 14, 0, currVal_5); }); }
function View_SessionWarningComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [["id", "sessionExpired"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["aria-labelledby", "sessionExpired"], ["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startOver() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), "Your session has expired.", "session-warning")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent, 0), "Start Over", "session-warning")); _ck(_v, 5, 0, currVal_1); }); }
function View_SessionWarningComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionWarningComponent_2)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SessionWarningComponent_3)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionNotification.isExpiring; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.sessionNotification.isExpired; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SessionWarningComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["id", "sessionRefreshFailed"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-labelledby", "sessionRefreshFailed"], ["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.startOver() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), i1.ɵppd(5, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "You session could not be renewed", "session-warning")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), "Start Over", "session-warning")); _ck(_v, 4, 0, currVal_1); }); }
export function View_SessionWarningComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.TranslatePipe, [i7.TranslateService]), i1.ɵpid(0, i5.DecimalPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 5, "blue-modal", [["cssClass", "checked-in-summary-popup"]], null, null, null, i8.View_BlueModalComponent_0, i8.RenderType_BlueModalComponent)), i1.ɵprd(512, null, i9.BlueRenderer, i9.BlueRenderer, [i1.Renderer2]), i1.ɵdid(4, 245760, null, 0, i10.BlueModalComponent, [i11.BlueModalService, i1.ElementRef, i1.Renderer2, i9.BlueRenderer], { id: [0, "id"], cssClass: [1, "cssClass"], showCloseBtn: [2, "showCloseBtn"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_SessionWarningComponent_1)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["sessionRefreshFailedTemplate", 2]], 0, 0, null, View_SessionWarningComponent_4))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sessionModalId; var currVal_1 = "checked-in-summary-popup"; var currVal_2 = false; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = !_co.sessionRefreshFailed; var currVal_4 = i1.ɵnov(_v, 7); _ck(_v, 6, 0, currVal_3, currVal_4); }, null); }
export function View_SessionWarningComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "session-warning", [], null, null, null, View_SessionWarningComponent_0, RenderType_SessionWarningComponent)), i1.ɵdid(1, 245760, null, 0, i12.SessionWarningComponent, [i13.SessionService, i11.BlueModalService, i7.TranslateService, i4.LoadingSpinnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SessionWarningComponentNgFactory = i1.ɵccf("session-warning", i12.SessionWarningComponent, View_SessionWarningComponent_Host_0, {}, {}, []);
export { SessionWarningComponentNgFactory as SessionWarningComponentNgFactory };
