import { Injectable } from '@angular/core';
import { CheckinService } from '../core/checkin.service';
import * as moment from 'moment';
import { PassengerJourneyBags, JourneyBags } from '../core/models/passenger-journey-bags';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { FlightModel, FlightStationInfo } from '../core/models/flight-model';
import { PassengerModel } from '../core/models/passenger-model';
import { CheckInJourneyData, CheckInJourneyRestrictionModel } from "../core/models/check-in-journey-restriction.model";
import { BookingFareSelectSyncService } from '../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { Journey } from '../seat/models/journey-model';
import { utils } from 'protractor';
import { isNullOrUndefined } from 'util';

@Injectable()
export class FlightsService {
  flights: FlightModel[] = [];
  passengers: PassengerModel[] = [];
  checkedInPassengers: any = {};
  checkInJourneyRestrictions: CheckInJourneyRestrictionModel[] = [];
  checkInJourneyData: CheckInJourneyData[] = [];
  paxCheckInAllowState: Record<number, PassengerJourneyModel[]> = {};

  constructor(private checkinService: CheckinService, private translateService: TranslateService, private syncService: BookingFareSelectSyncService) {
    this.checkinService.checkInInfoSub.subscribe(checkinfo => checkinfo && this.loadData(checkinfo));
  }

  loadData(checkInInfo: any) {
    if (isNullOrUndefined(checkInInfo)) return;

    this.checkInJourneyRestrictions = checkInInfo.checkInJourneyRestrictions;
    this.checkInJourneyData = checkInInfo.checkInJourneysData;
    const allFlights = {};

    const pjbList: PassengerJourneyBags[] = checkInInfo.passengerJourneyBags;

    const typeCounter = { adtCount: 0, chdCount: 0 };

    checkInInfo.criteria.forEach(c => {
      let passenger: PassengerModel = new PassengerModel(c.passenger, typeCounter, this.syncService);

      this.passengers.push(passenger);

      const pjb = pjbList.find(i => i.passengerNumber === passenger.passengerNumber);

      passenger.isSelected = pjb ? true : false;
      this.paxCheckInAllowState[passenger.passengerNumber] = c.passengerJourneys;

      c.passengerJourneys.forEach(passengerJourney => {
        // this.paxCheckInAllowState[passenger.passengerNumber] = passenger
        const j = passengerJourney.journey;

        if (passengerJourney.isCheckedIn) {
          this.checkedInPassengers[passenger.passengerNumber] = this.checkedInPassengers[passenger.passengerNumber] || [];
          this.checkedInPassengers[passenger.passengerNumber].push(j.sellKey);
        }

        if (allFlights.hasOwnProperty(j.sellKey)) {
          allFlights[j.sellKey].passengers.push(passenger);
          return;
        }

        const flight = new FlightModel();
        flight.initFromJourney(j, this.translateService);
        flight.passengers = [passenger];
        flight.carrierCode = passengerJourney.journey.segments
          .map(p => p.legs).reduce((a, b) => a.concat(b), [])
          .map(p => p.legInfo).map(p => p.operatingCarrier)
          .reduce((a, b) => {
            if (b.length > 0 || b != '0B') {
              return a.concat(b);
            } else {
              return a;
            }
          });
        allFlights[flight.sellKey] = flight;

        flight.isSelected = pjb && pjb.journeyBags.findIndex(jb => jb.sellKey === flight.sellKey) >= 0;

        return flight;
      });
    });

    this.flights = [];
    for (const key in allFlights) {
      if (allFlights.hasOwnProperty(key)) {
        this.flights.push(allFlights[key]);
      }
    }

    if (checkInInfo.checkInNotEligibleJourneys) {
      this.flights.push(...checkInInfo.checkInNotEligibleJourneys.map(j => new FlightModel().initFromJourney(j, this.translateService)));
    }

    this.flights.sort(FlightModel.sortFn);
  }

  getPassengersForSelectedFlights(flights: string[]): PassengerModel[] {
    const passengers: PassengerModel[] = [];

    for (let flight of this.flights) {
      if (flights.indexOf(flight.sellKey) < 0) {
        continue;
      }

      for (let passenger of flight.passengers) {
        if (passengers.indexOf(passenger) >= 0) {
          continue;
        }

        passengers.push(passenger);
      }
    }

    return passengers.sort(PassengerModel.sortFn);
  }

  selectPassengers(flights: FlightModel[], passengers: PassengerModel[]): Promise<any> {
    const list: PassengerJourneyBags[] = passengers.map(p => {
      const pjb = new PassengerJourneyBags();

      pjb.passengerNumber = p.passengerNumber;
      pjb.journeyBags = flights
        .filter(f => f.passengers.indexOf(p) >= 0)
        .map(f => {
          const jb = new JourneyBags();
          jb.sellKey = f.sellKey;
          return jb;
        });

      return pjb;
    });

    return this.checkinService.sendPassengerSelection(list);
  }
}

export interface PassengerJourneyModel {
  canCheckIn: boolean;
  isCheckedIn: boolean;
  journey: any;
  restrictedSSRs: string[];
}
