/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./car-hire.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./car-hire.component";
import * as i3 from "../../core/booking-flow.service";
import * as i4 from "../../common-modules/blue-air-common/translator/translate.service";
var styles_CarHireComponent = [i0.styles];
var RenderType_CarHireComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarHireComponent, data: {} });
export { RenderType_CarHireComponent as RenderType_CarHireComponent };
export function View_CarHireComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { carHireRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "itineraryBox_wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "accomodation_wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["car_hire", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_CarHireComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "car-hire", [], null, null, null, View_CarHireComponent_0, RenderType_CarHireComponent)), i1.ɵdid(1, 114688, null, 0, i2.CarHireComponent, [i3.BookingFlowService, i4.TranslateService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CarHireComponentNgFactory = i1.ɵccf("car-hire", i2.CarHireComponent, View_CarHireComponent_Host_0, {}, {}, []);
export { CarHireComponentNgFactory as CarHireComponentNgFactory };
