import { Directive, ElementRef, Input, Renderer2, OnDestroy } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[imgDefaultSrc]'
})
export class ImgDefaultSrcDirective implements OnDestroy {
  private onErrorUnListen: () => void;

  @Input() imgDefaultSrc: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.onErrorUnListen = this.renderer.listen(this.el.nativeElement, 'error', () => this.showDefaultImage());
  }

  ngOnDestroy() {
    if (this.onErrorUnListen) {
      this.onErrorUnListen();
    }
  }

  private showDefaultImage() {
    if (this.imgDefaultSrc && this.imgDefaultSrc !== this.el.nativeElement.getAttribute('src')) {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.imgDefaultSrc);
    }
  }
}
