import { Component, OnInit, forwardRef, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgForm } from '@angular/forms';
import { Constants } from '../../constants';

@Component({
  selector: 'check-in-pax-details',
  templateUrl: './pax-details.component.html',
  styleUrls: ['./pax-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaxDetailsComponent),
      multi: true
    }
  ]
})
export class PaxDetailsComponent implements OnInit, ControlValueAccessor {
  @Input() isLockedFare: boolean;
  @Input() flights: any[];
  @Input() isInfant: boolean;
  @Input() dateRanges: any[];
  @Input() titleItems: any[];
  @Input() countries: any[];
  @Input() genderItems: any[];

  @ViewChild('passengerDocsForm', { static: false }) form: NgForm;

  public namePattern = '^[A-Za-z _]*[A-Za-z][A-Za-z _]*$';
  dateFormat = 'YYYY-MM-DD';
  public pax: any;

  adultTitleItems: any[] = [];
  maleTitles: any[] = [];
  femaleTitles: any[] = [];

  constructor() {
  }

  ngOnInit() {
    this.maleTitles = this.titleItems.filter(x => x.value === 'MR' || x.value === 'MSTR');
    this.femaleTitles = this.titleItems.filter(x => x.value === 'MS' || x.value === 'MRS' || x.value === 'MISS');
    this.adultTitleItems = this.titleItems.filter(x => x.value !== 'CHD');
  }

  writeValue(obj: any): void {
    this.pax = obj || { name: {}, info: {}, typeInfo: {} };
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
  }

  titleChanged($value, pax) {
    if ($value && pax.typeInfo.paxType === Constants.AdultPaxType) {
      if (this.maleTitles.some(x => x.value === $value) && pax.info.gender !== this.genderItems[0].value) {
        pax.info.gender = this.genderItems[0].value;
      } else if (this.femaleTitles.some(x => x.value === $value) && pax.info.gender !== this.genderItems[1].value) {
        pax.info.gender = this.genderItems[1].value;
      }
    }
  }

  genderChanged($value, pax) {
    if ($value && pax.typeInfo.paxType === Constants.AdultPaxType) {
      if (this.maleTitles.every(x => x.value !== pax.name.title) && $value === this.genderItems[0].value) {
        pax.name.title = this.maleTitles[0].value;
      } else if (this.femaleTitles.every(x => x.value !== pax.name.title) && $value === this.genderItems[1].value) {
        pax.name.title = this.femaleTitles[0].value;
      }
    }
  }
}
