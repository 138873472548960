import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../common-modules/blue-air-common/session.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { UserStateService } from '../common-modules/blue-air-common/user-state.service';
import { BookingFlowService } from '../core/booking-flow.service';
import { BookingService } from '../core/booking.service';
import { ConfigService } from '../core/config.service';
import * as moment from 'moment';
import { ChangeFlightModel } from '../itinerary/booking-info/booking-info.component';
import { DefaultModalComponent } from '../shared/default-modal/default-modal.component';
var BookingConsentComponent = /** @class */ (function () {
    function BookingConsentComponent(activatedRoute, translateService, userStateService, bookingService, bookingFlowService, sessionService, configService) {
        this.activatedRoute = activatedRoute;
        this.translateService = translateService;
        this.userStateService = userStateService;
        this.bookingService = bookingService;
        this.bookingFlowService = bookingFlowService;
        this.sessionService = sessionService;
        this.configService = configService;
        this.recordLocator = "";
        this.emailAddress = "";
        this.lastName = "";
        this.dynamicUrl = "";
        this.showConsentOptions = true;
        this.isConsentYes = false;
        this.isConsentNo = false;
        this.isButtonSelected = false;
        this.isRefundToCash = false;
        this.isPassengerEligibleToSubmit = false;
        this.isDirectFlight = false;
        this.isIndirectFlight = false;
        this.isCommercialFlight = false;
        this.disableRefund = false;
        this.showErrorMessage = false;
        this.hasCanceledFlights = false;
        this.hasTimeChangedFlights = false;
        this.hasInsurance = false;
        this.missingParams = false;
        this.bookingNotFound = false;
        this.memberIsLoggedIn = false;
        this.memberNotLoggedIn = false;
        this.wrongMemberRetrieve = false;
        this.isLoading = true;
        this.journeys = [];
        this.flightsToChange = [];
        this.changeFlight = new ChangeFlightModel();
        this.passengers = [];
        this.changeDateActive = false;
        this.moveFlight = false;
        this.modalId = 'refundModal';
        this.queuesEligibleToSubmit = ['CASREF', 'CXLSM', 'CXLSW', 'RMO3AC', 'RMO3SM', 'RMO3SW', 'SCO3AC', 'SCO3SM', 'SCO3SW'];
        this.hasInsuranceFee = false;
        this.hasInsuranceQueueCode = false;
        this.insuranceModalId = 'insuranceModal';
    }
    BookingConsentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activatedRoute.queryParams.subscribe(function (params) {
            if (Object.getOwnPropertyNames(params).length !== 0) {
                _this.recordLocator = params['rl'];
                _this.emailAddress = params['em'];
                _this.lastName = params['ln'];
            }
        });
        this.getBooking();
    };
    BookingConsentComponent.prototype.ngOnDestroy = function () {
        localStorage.removeItem("pageState");
    };
    BookingConsentComponent.prototype.getBooking = function () {
        var _this = this;
        if (!(this.recordLocator && (this.emailAddress || this.lastName))) {
            this.missingParams = true;
            return;
        }
        this.sessionService.ensureSessionIsValid()
            .then(function () { return _this.bookingService.retrieveBooking(_this.recordLocator, _this.lastName, _this.emailAddress, _this.configService.BlueRetrieve); })
            .then(function (retrievedBooking) {
            if (retrievedBooking) {
                _this.memberNotLoggedIn = false;
                _this.memberIsLoggedIn = retrievedBooking.bookingDetail.pointOfSale.organizationCode == "BAMEMBERS" ? _this.userStateService.isLoggedIn.getValue() : true;
                if (!_this.memberIsLoggedIn) {
                    var openLoginModal = _this.loginModalId.nativeElement;
                    openLoginModal.click();
                }
            }
            else {
                _this.bookingNotFound = true;
            }
        }, function (e) {
            var data = e.error.errors;
            if (data && data.find(function (i) { return i.errorCode === ErrorType[ErrorType.memberNotLoggedIn]; })) {
                _this.memberNotLoggedIn = true;
                if (!_this.memberIsLoggedIn) {
                    var openLoginModal = _this.loginModalId.nativeElement;
                    openLoginModal.click();
                }
            }
            else if (data && data.find(function (i) { return i.errorCode === ErrorType[ErrorType.ValidateNullBooking]; })) {
                _this.bookingNotFound = true;
                _this.showErrorMessage = true;
            }
            else if (data && data.find(function (i) { return i.errorCode === ErrorType[ErrorType.wrongMemberRetrieve]; })) {
                _this.wrongMemberRetrieve = true;
                _this.showErrorMessage = true;
            }
        })
            .then(function () {
            _this.bookingFlowService.loadFlowInfo(true)
                .then(function (booking) {
                if (booking) {
                    if (booking.bookingQueueInfos && booking.bookingQueueInfos.items && booking.bookingQueueInfos.items.length > 0) {
                        _this.isPassengerEligibleToSubmit = !booking.bookingQueueInfos.items.some(function (q) { return _this.queuesEligibleToSubmit.includes(q.queueCode); });
                    }
                    if (booking.convertedJourneys && booking.convertedJourneys.journeys && booking.convertedJourneys.journeys.length > 0) {
                        _this.journeys = booking.convertedJourneys.journeys;
                        _this.checkFlightType();
                        _this.disableRefund = _this.checkLiftStatus();
                        _this.hasTimeChangedFlights = _this.journeys.some(function (j) { return j.segments && j.segments.some(function (s) { return s.paxSegments && s.paxSegments[0].timeChanged == true; }); });
                        _this.hasCanceledFlights = _this.journeys.some(function (j) { return j.segments && j.segments.some(function (s) { return s.legs && s.legs.some(function (l) { return l.legInfo.status === 'Canceled'; }); }); });
                        if (_this.hasCanceledFlights) {
                            _this.journeys.forEach(function (j) {
                                j.hasCancelLeg = j.segments && j.segments.some(function (s) { return s.legs && s.legs.some(function (l) { return l.legInfo.status === 'Canceled'; }); });
                            });
                        }
                        if (booking.passengers && booking.passengers.items && booking.passengers.items.length > 0) {
                            _this.passengers = booking.passengers.items;
                        }
                        _this.hasInsurance = _this.journeys.some(function (j) { return j.segments.some(function (s) { return s.paxSSRs.find(function (ssr) { return ssr.sSRCode.match("INS1"); }); }); });
                        if (booking.passengers && booking.passengers.items && booking.passengers.items.length > 0) {
                            _this.hasInsuranceFee = _this.passengers.some(function (p) { return p.fees && p.fees.items.some(function (f) { return f.code == "XCOVT1"; }); });
                        }
                        if (booking.bookingQueueInfos && booking.bookingQueueInfos.items && booking.bookingQueueInfos.items.length > 0) {
                            _this.hasInsuranceQueueCode = booking.bookingQueueInfos.items.some(function (q) { return q.queueCode == "INS"; });
                        }
                        if (_this.checkPastFlights()) {
                            _this.showErrorMessage = true;
                            _this.bookingService.addComment(_this.recordLocator, 'Error: Flights in the past', _this.emailAddress, true);
                        }
                        _this.showErrorMessage = !_this.hasEligibleFlightsToSubmitPNR() || _this.checkLiftStatus();
                    }
                }
            })
                .then(function () {
                if (!localStorage.getItem("pageState")) {
                    localStorage.setItem("pageState", "reloaded");
                    window.location.reload();
                }
            });
        });
    };
    BookingConsentComponent.prototype.checkConsent = function (buttonChosen) {
        if (buttonChosen) {
            this.showConsentOptions = false;
            this.isConsentYes = true;
            this.isConsentNo = false;
            this.isButtonSelected = false;
            this.bookingService.setPassengerConsent(this.emailAddress);
        }
        else if (!buttonChosen && !this.disableRefund) {
            this.showConsentOptions = false;
            this.isConsentYes = false;
            this.isConsentNo = true;
            this.isButtonSelected = true;
        }
    };
    BookingConsentComponent.prototype.enableConsentOptions = function () {
        this.showConsentOptions = true;
        this.isConsentYes = false;
        this.isConsentNo = false;
        this.isButtonSelected = false;
    };
    BookingConsentComponent.prototype.goToCustomerSupportCenter = function () {
        this.dynamicUrl = "https://help.flyblueair.com" + /* this.translateService.culture.replace("-", "/").toLocaleLowerCase() + */ "/hc/en-us";
    };
    BookingConsentComponent.prototype.manageMyBooking = function () {
        if (this.disableRefund)
            return;
        window.location.href = window.location.origin + "/" + this.translateService.culture.replace("-", "/").toLocaleLowerCase() +
            "/booking-engine/booking-status" + "?rl=" + this.recordLocator + "&em=" + this.emailAddress + "&ln=" + this.lastName;
    };
    BookingConsentComponent.prototype.whereToRefund = function () {
        this.refundModal.openPopup(this.modalId);
    };
    BookingConsentComponent.prototype.openLoginPopup = function () {
        window.commonFunctions.openLoginPopup();
    };
    BookingConsentComponent.prototype.refundToWallet = function () {
        this.refundModal.cancel();
        if (this.hasInsuranceFee || this.hasInsuranceQueueCode) {
            this.insuranceModal.ok();
            this.insuranceModal.openPopup(this.insuranceModalId);
        }
        else {
            this.refundToWalletProceed();
        }
    };
    BookingConsentComponent.prototype.refundToWalletProceed = function () {
        var _this = this;
        this.bookingFlowService.loadFlowInfo(true).then(function (booking) {
            if (booking) {
                _this.bookingService.recordLocator = _this.recordLocator;
                window.location.href = window.location.origin + "/" + _this.translateService.culture.replace("-", "/").toLocaleLowerCase() + "/booking-engine/ptw?step=1";
            }
        });
    };
    BookingConsentComponent.prototype.refundToCash = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.bookingService.refundCashBooking(this.emailAddress)];
                    case 1:
                        result = _a.sent();
                        if (result.customerRefundCashRequest.errors.length > 0) {
                            this.bookingService.addComment(this.recordLocator, 'Error: ' + result.customerRefundCashRequest.errors[0], this.emailAddress, true);
                            this.refundModal.cancel();
                            this.showErrorMessage = true;
                        }
                        else {
                            this.isRefundToCash = true;
                            this.refundModal.cancel();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingConsentComponent.prototype.getStation = function (index, journey) {
        if (journey) {
            if (index == 0)
                return journey.segments[0].departureStation;
            if (journey.segments.length == 1)
                return journey.segments[0].arrivalStation;
            return journey.segments[journey.segments.length - 1].arrivalStation;
        }
    };
    BookingConsentComponent.prototype.formatDate = function (date, format) {
        return moment(date).format(format);
    };
    BookingConsentComponent.prototype.subtractTimezoneOffset = function (date, offset) {
        return moment(date).utcOffset(offset, true);
    };
    BookingConsentComponent.prototype.calculateWaitingTimeBetweenFLights = function (stopStationArrivalDate, stopStationDepartureDate) {
        var intermediateTime = new Date(stopStationDepartureDate).valueOf() - (new Date(stopStationArrivalDate)).valueOf();
        var days = Math.floor(intermediateTime / (60 * 60 * 24 * 1000));
        var hours = Math.floor(intermediateTime / (60 * 60 * 1000)) - (days * 24);
        var minutes = Math.floor(intermediateTime / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        var seconds = Math.floor(intermediateTime / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
        var waitingTime = "";
        if (days) {
            waitingTime = waitingTime + days + " DAYS ";
        }
        if (days && hours) {
            waitingTime = waitingTime + " and ";
        }
        if (hours) {
            waitingTime = waitingTime + hours + " HOURS ";
        }
        if (hours && minutes) {
            waitingTime = waitingTime + " and ";
        }
        if (minutes) {
            waitingTime = waitingTime + minutes + " MINUTES ";
        }
        if (minutes && seconds) {
            waitingTime = waitingTime + " and ";
        }
        if (seconds) {
            waitingTime = waitingTime + seconds + " SECONDS ";
        }
        waitingTime = waitingTime + "connection";
        return waitingTime;
    };
    BookingConsentComponent.prototype.checkLiftStatus = function () {
        return this.journeys.some(function (j) { return j.segments.some(function (s) { return s.paxSegments.some(function (ps) { return ps.liftStatus != "Default"; }); }); });
    };
    BookingConsentComponent.prototype.checkPastFlights = function () {
        var nowMoment = moment().utc();
        var check = this.journeys.some(function (j) {
            var isBefore = j.segments.some(function (s) {
                var std = moment(s.sTD);
                return std.isBefore(nowMoment);
            });
            return isBefore;
        });
        return check;
    };
    BookingConsentComponent.prototype.isNextDay = function (departureDate, arrivalDate) {
        if (moment(departureDate).date() !== moment(arrivalDate).date()) {
            return true;
        }
    };
    BookingConsentComponent.prototype.hasEligibleFlightsToSubmitPNR = function () {
        var checker = false;
        if (this.hasTimeChangedFlights || this.hasCanceledFlights) {
            checker = true;
        }
        return checker;
    };
    BookingConsentComponent.prototype.checkFlightType = function () {
        if (this.journeys.some(function (j) { return j.segments && j.segments.some(function (s) { return s.legs && s.legs.length >= 2; }); })) {
            this.isCommercialFlight = true;
        }
        else if (this.journeys.some(function (j) { return j.segments && j.segments.length >= 2; })) {
            this.isIndirectFlight = true;
        }
        else {
            this.isDirectFlight = true;
        }
    };
    return BookingConsentComponent;
}());
export { BookingConsentComponent };
export var ErrorType;
(function (ErrorType) {
    ErrorType[ErrorType["mandatoryFields"] = 1] = "mandatoryFields";
    ErrorType[ErrorType["bookingNotFound"] = 2] = "bookingNotFound";
    ErrorType[ErrorType["pendingPayments"] = 3] = "pendingPayments";
    ErrorType[ErrorType["checkinClosed"] = 4] = "checkinClosed";
    ErrorType[ErrorType["checkinNotOpen"] = 5] = "checkinNotOpen";
    ErrorType[ErrorType["memberNotLoggedIn"] = 6] = "memberNotLoggedIn";
    ErrorType[ErrorType["ValidateNullBooking"] = 7] = "ValidateNullBooking";
    ErrorType[ErrorType["wrongMemberRetrieve"] = 8] = "wrongMemberRetrieve";
})(ErrorType || (ErrorType = {}));
