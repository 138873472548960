<div class="checkin-luggage-options">
  <ng-container *ngIf="mobileMatches">
    <ng-container *ngTemplateOutlet="ssrsMobileView; context:{ passengerSsr: passengerSsr, pix: pix }">
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!mobileMatches">
    <div class="luggage-left full-width">
      <div class="luggage-table">
        <div class="luggage-row row-head">
          <div class="cell-head">
            <div class="info-wrap">
              <div>
              </div>
              <ul>
                <li>
                  <span role="presentation" class="ssr-icon font-ico-checked-sign"></span>
                  <div class="text">
                    {{ 'Best price if you buy it now' | translate: 'extras' }}
                  </div>
                </li>

                <li>
                  <span role="presentation" class="ssr-icon font-ico-checked-sign"></span>
                  <div class="text">
                    {{ 'Board hands-free' | translate: 'extras' }}
                  </div>
                </li>

                <li type="button" aria-hidden="true" (click)="showLuggageBagDetails()">
                  <span role="presentation" class="ssr-icon font-ico-info"></span>
                  <div class="text">
                    {{ 'More info' | translate: 'extras' }}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <ng-container *ngFor="let ssr of availableSsrs">
            <div class="cell-head">
              <div class="image-wrap">
                <img *ngIf="ssr.ssrCode == 'XSBG'" [src]="assetsPath + 'img/ssrs/10kg-bag-with-size.png'">
                <img *ngIf="ssr.ssrCode == 'SBAG'" [src]="assetsPath + 'img/ssrs/23kg-bag-with-size.png'">
                <img *ngIf="ssr.ssrCode == 'BAG'" [src]="assetsPath + 'img/ssrs/32kg-bag-with-size.png'">
              </div>

              <span class="value" *ngIf="ssr.ssrCode == 'XSBG'"
                [innerHTML]="'xsBagDimensions' | translationWithHtml: 'extras'">
              </span>

              <span class="value" *ngIf="ssr.ssrCode == 'SBAG'"
                [innerHTML]="'smallBagDimensions' | translationWithHtml: 'extras'">
              </span>

              <span class="value" *ngIf="ssr.ssrCode == 'BAG'"
                [innerHTML]="'bigBagDimensions' | translationWithHtml: 'extras'">
              </span>

              <span class="value">
                {{ ssr.ssrCode + '-size' | translate: 'extras.bag-size-' + flight.departureStation.iataCode + '_' +
                flight.arrivalStation.iataCode }}
              </span>

              <check-in-ssr-price-display
                [originalPrice]="promoDiscount && !ssr.originalPrice ? ssr.price *100/(100 - promoDiscount) : ssr.originalPrice"
                [currentPrice]="ssr.price"></check-in-ssr-price-display>
            </div>
          </ng-container>
        </div>

        <ng-container *ngFor="let passengerSsr of passengers;let pix = index">
          <ng-container *ngTemplateOutlet="ssrsDesktopView; context:{ passengerSsr: passengerSsr, pix: pix }">
          </ng-container>
        </ng-container>
      </div>
      <check-in-ssr-same-options-for-return-flight [visible]="enableSameOptionsForAllFlights"
        [(isSelected)]="sameOptionsForAllFlights"></check-in-ssr-same-options-for-return-flight>
    </div>
  </ng-container>
</div>

<!-- NEW SSR MOBILE VIEW -->
<ng-template #ssrsMobileView let-passengerSsr="passengerSsr" let-pix="pix">
  <div class="checkin-pets-options" [(checkInSsrListDisplay)]="isSsrsPopupOpened">
    <check-in-ssr-passenger-picker [passengers]="passengers" [ssrType]="ssrType"
      [allPassengersEnabled]="allPassengersEnabled" [selectedPassenger]="selectedPassenger"
      [flightCodes]="flight.departureStation.iataCode + '_' + flight.arrivalStation.iataCode"
      (selectPassenger)="selectPassenger($event)" (removeSsr)="removeAllSsrs($event)" (resetSsr)="resetSsr()">
    </check-in-ssr-passenger-picker>

    <ng-container *ngIf="mobileShowAllOptionMessage(flight.departureStation.iataCode + flight.arrivalStation.iataCode)">
      <div class="alert-check-in-bag mobile">
        {{'Please select an option for all passengers' | translate: 'extras'}}
      </div>
    </ng-container>

    <div class="pets-list js-ssr-list">
      <div class="pets-list-inner">
        <button type="button" class="close-popup" aria-hidden="true">
          <span class="font-ico-close-menu"><span class="sr-only">{{ 'Click to close luggage overlay' |
              translate: 'extras' }}</span></span>
        </button>

        <h2 aria-hidden="true" class="js-ssr-list-title">
          {{ 'Choose luggage' | translate: 'extras' }}
        </h2>

        <div class="passenger-details-wrapper" *ngIf="mobileMatches">
          <div *ngIf="selectedPassenger == -1 else singlePassengerName">{{ 'All passengers' | translate: 'extras' }}
          </div>
          <ng-template #singlePassengerName>{{ getSelectedPassengerName() }}</ng-template>
        </div>

        <ng-container *ngFor="let ssr of availableSsrs">
          <div class="pet-item" *ngIf="ssr.isVisible">
            <div class="header-wrap-item">
              <span class="image-wrap" style="float: left">
                <img *ngIf="ssr.ssrCode == 'XSBG'" [src]="assetsPath + 'img/ssrs/10kg-bag-with-size.png'" height="66">
                <img *ngIf="ssr.ssrCode == 'SBAG'" [src]="assetsPath + 'img/ssrs/23kg-bag-with-size.png'" height="66">
                <img *ngIf="ssr.ssrCode == 'BAG'" [src]="assetsPath + 'img/ssrs/32kg-bag-with-size.png'" height="66">
              </span>

              <div class="pet-item-left luggage-row-mobile">
                <h3 class="value">{{ ssr.ssrCode + '-size' | translate: 'extras.bag-size-' +
                  flight.departureStation.iataCode + '_' + flight.arrivalStation.iataCode}}</h3>
                <check-in-ssr-price-display
                  [originalPrice]="promoDiscount ? ssr.price *100/(100 - promoDiscount)  : ssr.originalPrice"
                  [currentPrice]="ssr.price"></check-in-ssr-price-display>
              </div>
            </div>

            <div class="pet-item-right row-inner">
              <div class="pet-number-container">
                <button type="button" class="pet-number pet-number-zero" (click)="selectSsr(pix, ssr.ssrCode, 0)"
                  [ngClass]="{'pet-number-selected': !getSeletedPassenger().getSelectedSsr(ssr) && ssr.paidQuantity > 0}"
                  [disabled]="ssr.paidQuantity > 0">
                  <span class="pet-number-inner"><strong>0</strong></span>
                </button>

                <button type="button" class="pet-number"
                  *ngFor="let quantity of ssr.getQuantityArray(getSeletedPassenger().passenger.passengerNumber)"
                  (click)="selectSsr(pix, ssr.ssrCode, quantity)"
                  [ngClass]="{'pet-number-selected': getSeletedPassenger().getSelectedSsr(ssr)?.selectedQuantity == quantity}"
                  [disabled]="quantity < getSeletedPassenger().getSelectedSsr(ssr)?.paidQuantity || !isSsrQuantityAvailable(quantity-1, getSeletedPassenger(), ssr)">

                  <span class="pet-number-inner">
                    <strong>
                      {{ quantity }}
                    </strong>
                  </span>

                  <span *ngIf="quantity <= ssr.paidQuantity && recordLocator != ''" class="cell-info">
                    {{ 'PAID' | translate: 'extras' }}
                  </span>

                  <span *ngIf="quantity <= ssr.paidQuantity && recordLocator == ''" class="cell-info underButton">
                    {{ 'FREE' | translate: 'extras' }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </ng-container>
        <!-- TO DO - implement logic for the bellow button -->
        <div class="no-checked-in-bag-wrapper-mobile">
          <div class="description">{{ 'OR' | translate: 'extras' }}</div>
          <button class="button_no_bags" [ngClass]="checkNoBagsSelected(pix) ? 'selected' : ''"
            (click)="noBags(pix, true)">{{ 'I don’t need a checked-in bag' | translate: 'extras' }}</button>
        </div>
        <button type="button" class="btn btn-primary close-popup-bottom" aria-hidden="true"
          (click)="onNeedSave$.next();">
          {{ 'Confirm and Continue' | translate: 'extras' }}
        </button>
      </div>
    </div>

    <check-in-ssr-same-options-for-return-flight [visible]="enableSameOptionsForAllFlights"
      [(isSelected)]="sameOptionsForAllFlights">
    </check-in-ssr-same-options-for-return-flight>
  </div>
</ng-template>

<ng-template #ssrsDesktopView let-passengerSsr="passengerSsr" let-pix="pix">
  <div class="luggage-row">
    <div class="row-inner">
      <div class="cell-body first">
        <check-in-passenger-type-display *ngIf="passengerSsr.passenger.name.first == ''" [cssClass]="'cell-label'"
          [passenger]="passengerSsr.passenger"></check-in-passenger-type-display>

        <check-in-passenger-name-display *ngIf="passengerSsr.passenger.name.first != ''"
          [passenger]="passengerSsr.passenger"></check-in-passenger-name-display>

        <button type="button" class="cell-input" (click)="selectSsr(pix, null, 0)" *ngIf=false
          [ngClass]="{'selected': !passengerSsr.getSelectedSsrs().length}">
          0
          <span class="add-price">&nbsp;</span>
        </button>
      </div>

      <div class="cell-body" *ngFor="let ssr of availableSsrs">
        <div class="qty-container">
          <button type="button" class="value-button minus"
            [disabled]="passengerSsr.getSelectedSsr(ssr)?.selectedQuantity <= 0 || passengerSsr.getSelectedSsr(ssr)?.selectedQuantity == passengerSsr.getSelectedSsr(ssr)?.paidQuantity"
            (click)="decreaseBagsNumber(pix, ssr.ssrCode, passengerSsr.getSelectedSsr(ssr)?.selectedQuantity-1)">
          </button>

          <div class="value-text">
            <span>
              {{ passengerSsr.getSelectedSsr(ssr)?.selectedQuantity ? passengerSsr.getSelectedSsr(ssr)?.selectedQuantity
              : 0 }}
            </span>
          </div>

          <button type="button" class="value-button plus"
            [disabled]="(passengerSsr.getSelectedSsr(ssr)?.selectedQuantity < passengerSsr.getSelectedSsr(ssr)?.paidQuantity) || !isSsrQuantityAvailable( passengerSsr.getSelectedSsr(ssr)?.selectedQuantity, passengerSsr, ssr)"
            (click)="increaseBagsNumber(pix, ssr.ssrCode, passengerSsr.getSelectedSsr(ssr)?.selectedQuantity+1)">
          </button>
        </div>
      </div>

      <div class="no-checked-in-bag-wrapper" *ngIf="false">
        <div class="description">{{ 'OR' | translate: 'extras' }}</div>
        <button class="button_no_bags" [ngClass]="checkNoBagsSelected(pix) ? 'selected' : ''"
          (click)="noBags(pix, true)">{{ 'I don’t need a checked-in bag' | translate: 'extras' }}</button>
      </div>

      <ng-container *ngIf=false>
        <div class="cell-body" *ngFor="let ssr of availableSsrs">

          <button *ngFor="let quantity of ssr.getQuantityArray(passengerSsr.passenger.passengerNumber); let ix = index"
            type="button" class="cell-input"
            [ngClass]="{'selected': passengerSsr.getSelectedSsr(ssr)?.selectedQuantity == quantity}"
            [disabled]="(quantity <  passengerSsr.getSelectedSsr(ssr)?.paidQuantity ) || !isSsrQuantityAvailable(quantity, passengerSsr, ssr)"
            (click)="selectSsr(pix, ssr.ssrCode, quantity)">
            {{ quantity }}

            <span *ngIf="quantity <= passengerSsr.getSelectedSsr(ssr)?.paidQuantity && recordLocator != ''"
              class="cell-info">
              {{ 'PAID' | translate: 'extras' }}
            </span>

            <span *ngIf="quantity <= passengerSsr.getSelectedSsr(ssr)?.paidQuantity && recordLocator == ''"
              class="cell-info underButton">
              {{ 'FREE' | translate: 'extras' }}
            </span>

            <span class="add-price">
              +{{ ssr.price | currencyDisplay }}
            </span>
          </button>
        </div>
      </ng-container>
    </div>

    <div *ngIf="getPassengerError(pix)">
      <div class="alert-check-in-bag">
        {{ 'Please select checked-in baggage' | translate:"extras"}}
      </div>
    </div>
  </div>
</ng-template>