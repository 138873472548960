/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./ptw-authenticate.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./ptw-authenticate.component";
import * as i6 from "../../../core/config.service";
import * as i7 from "../../../common-modules/blue-air-common/user-state.service";
import * as i8 from "../../../common-modules/blue-air-common/session.service";
var styles_PtwAuthenticateComponent = [i0.styles];
var RenderType_PtwAuthenticateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PtwAuthenticateComponent, data: {} });
export { RenderType_PtwAuthenticateComponent as RenderType_PtwAuthenticateComponent };
function View_PtwAuthenticateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h1", [["class", "h2"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 8, "div", [["class", "text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "rte-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "a", [["class", "btn btn-primary"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openLoginPopup($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵppd(12, 2), (_l()(), i1.ɵeld(13, 0, null, null, 8, "div", [["class", "text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "div", [["class", "rte-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵppd(17, 2), (_l()(), i1.ɵeld(18, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "a", [["class", "btn btn-primary"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(20, null, ["", ""])), i1.ɵppd(21, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "You have to log in to continue.", "authenticate")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), "Login to your account", "authenticate")); _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent, 0), "Login", "authenticate")); _ck(_v, 11, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v.parent, 0), "Don't have an account?", "authenticate")); _ck(_v, 16, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.createAccountUrl, ""); _ck(_v, 19, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 20, 0, _ck(_v, 21, 0, i1.ɵnov(_v.parent, 0), "Create Account", "authenticate")); _ck(_v, 20, 0, currVal_5); }); }
export function View_PtwAuthenticateComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "section", [["class", "section white-bg login indented"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PtwAuthenticateComponent_1)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PtwAuthenticateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "booking-ptw-authenticate", [], null, null, null, View_PtwAuthenticateComponent_0, RenderType_PtwAuthenticateComponent)), i1.ɵdid(1, 245760, null, 0, i5.PtwAuthenticateComponent, [i6.ConfigService, i3.TranslateService, i7.UserStateService, i8.SessionService, i4.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PtwAuthenticateComponentNgFactory = i1.ɵccf("booking-ptw-authenticate", i5.PtwAuthenticateComponent, View_PtwAuthenticateComponent_Host_0, {}, { continue: "continue" }, []);
export { PtwAuthenticateComponentNgFactory as PtwAuthenticateComponentNgFactory };
