import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookingFlightSelectComponent } from './booking-flight-select/booking-flight-select.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { environment } from '../../environments/environment';
import { BookingRoutes} from './booking-flight-select-routes';
import { BookingFlightSelectService } from './booking-flight-select.service';
import { JourneySearchInfoComponent } from './journey-search-info/journey-search-info.component';
import { BookingFareSelectComponent } from './booking-fare-select/booking-fare-select.component';
import { ChangeFlightComponent } from './change-flight/change-flight.component';
import { CalendarDatePickerComponent } from './calendar-date-picker/calendar-date-picker.component';
import { StationPickerComponent } from './station-picker/station-picker.component';
import { PassengerTypePickerComponent } from './passenger-type-picker/passenger-type-picker.component';
import { BookingFlightSearchComponent } from './booking-flight-search/booking-flight-search.component';
import { DeeplinkComponent } from './deeplink/deeplink.component';
import { CanActivateFlightsOnBookingService } from './can-activate-flights-on-booking.service';
import { LockFareSelectComponent } from './lock-fare-select/lock-fare-select.component';
import { SsrTranslatePipe } from '../shared/ssr-translate.pipe';
import { BluebenefitsSelectComponent } from './bluebenefits-select/bluebenefits-select.component';
import { MoveFlightComponent } from './move-flight/move-flight.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BlueAirCommonModule,
    SharedModule,
    RouterModule
    // RouterModule.forRoot(
    //  environment.flow == 0 ? [] : BookingRoutes
    // )
  ],
  declarations: [BookingFlightSelectComponent, JourneySearchInfoComponent, BookingFareSelectComponent, ChangeFlightComponent,
    CalendarDatePickerComponent, StationPickerComponent, PassengerTypePickerComponent, BookingFlightSearchComponent,
    DeeplinkComponent, LockFareSelectComponent, BluebenefitsSelectComponent, MoveFlightComponent],
  providers: [BookingFlightSelectService, CanActivateFlightsOnBookingService, SsrTranslatePipe],
  exports: [ChangeFlightComponent, MoveFlightComponent]
})
export class BookingFlightSelectModule { }
