<check-in-form-wrapper ariaLabel="{{ 'Step 6 Check-in form' | translate }}" [step]="'step-6'" [showPriceDisplay]="true"
  (continue)="goToNextStep()">
  <div class="form-wrapper-title">
    {{'Select payment type'|translate}}
  </div>
  <div class="form-wrapper-top-body">
    <ng-container *ngIf="!invoicingStateInfo.isDisplayingEditor">
      <div class="alert-holder alert-error" *ngIf="paymentFailed && paymentError">
        <div class="alert-item secondary with-icon">
          <h4>{{ 'Errors' | translate: 'alerts' }}</h4>
          <ul>
            <li>
              {{ paymentError }}
            </li>
          </ul>
        </div>
      </div>
      <div class="checkin-payment-type" attr.aria-label="{{'Payment type selection'|translate}}"
        *ngIf="paymentMethods else loadingMessage">
        <div class="wrapper">
          <span class="error-txt" [style.display]="'block'" *ngIf="submitted && !isPaymentMethodSelected">
            {{ 'Please select a payment method' | translate:'summary' }}
          </span>
          <span class="error-txt" [style.display]="'block'"
            *ngIf="submitted && isPaymentMethodSelected  && !isTermsAccepted">
            {{ 'Please accept terms and conditions for the payment method you have selected' | translate:'summary' }}
          </span>
        </div>

        <div class="promocode_wrapper">
          <booking-promo-code [showMargins]="true" (promoCodeChanged)="promoCodeChanged($event)"></booking-promo-code>
        </div>

        <div class="checkin-box" *ngIf="walletPaymentMethod && walletPayableAmount">
          <div class="radio-wrapper open">
            <label>
              {{ 'Wallet balance:' | translate:'summary' }} {{ walletPaymentMethod.details.available / 100 | currency:
              walletPaymentMethod.details.currencyCode }}
            </label>
          </div>
          <div class="checkin-payment-content fullwidth-content" aria-hidden="true" style="display: block;">
            <div class="payment-content-inner" style="min-height: 10px;">
              <div class="checkbox-wrapper">
                <input type="checkbox" id="wallet-use-cb" [(ngModel)]="isWalletPaymentSelected"
                  (change)="walletPaymentSelectedChanged()" />
                <label for="wallet-use-cb">{{'Use {0} wallet credit' | translate:'summary':[walletPayableAmount |
                  currencyDisplay] }}</label>
              </div>

              <p *ngIf="isWalletPaymentSelected && balanceDueAfterWalletPayment > 0">
                {{'Pay the rest of the remaining balance due ({0}) using one of the following payment methods'|
                translate:'summary':[ balanceDueAfterWalletPayment | currencyDisplay ]}}
              </p>
            </div>
          </div>
        </div>

        <ng-container *ngFor="let paymentGroup of paymentMethodGroups">
          <div class="checkin-box" aria-haspopup="true" *ngIf="!paymentGroup.isDisabled">
            <div class="radio-wrapper" [ngClass]="{'open' : paymentGroup.isSelected }">
              <input type="radio" name="payment-type" id="{{paymentGroup.key}}" [checked]="paymentGroup.isSelected"
                (click)="selectGroup(paymentGroup.key)" />
              <label for="{{paymentGroup.key}}"
                attr.aria-label="{{ 'Pay with {0}' | translate:'summary':[paymentGroup.name + ' - name' | translate:'summary'] }}">
                {{ paymentGroup.name + ' - name' | translate:'summary' }}
              </label>
            </div>
            <div class="checkin-payment-content fullwidth-content" aria-hidden="true"
              [style.display]="paymentGroup.isSelected ? 'block' : '' ">
              <div class="payment-content-inner">
                <div class="cards-holder" *ngIf="paymentGroup.items.length > 1 || paymentGroup.name == 'Agency'">
                  <div class="cards-holder-inner">
                    <div *ngFor="let item of paymentGroup.items" class="payment-type-container">
                      <button type="button" [ngClass]="{'active': item.isSelected }"
                        (click)="selectPaymentMethod(paymentGroup.key, item.code)">
                        <span class="sr-only">{{ 'Pay with {0}' | translate:'summary':[item.name |
                          translate:'payment-method'] }}
                        </span>
                        <img [src]="assetsPath + 'img/payments/'+ item.logo"
                          attr.alt="{{item.name | translate:'payment-method'}}" role="presentation" />
                      </button>
                    </div>
                  </div>
                </div>
                <!-- <div class="payment-item-price" *ngIf="selectedPaymentMethod">
                  <span
                    attr.aria-label="{{'{0} Fee {1}' | translate:'summary':[selectedPaymentMethod.name | translate:'payment-method']:[selectedPaymentMethod.fee | currencyDisplay ]}}"><strong>{{ selectedPaymentMethod.name | translate:'payment-method' }}
                      {{'Fee' | translate:'summary' }}</strong> {{ selectedPaymentMethod.fee | currencyDisplay }}</span>
                </div> -->
                <p [innerHTML]="paymentGroup.description"></p>
                <div class="checkbox-wrapper">
                  <input type="checkbox" id="credit-card-agree-{{paymentGroup.key}}"
                    [checked]="paymentGroup.isTermsAccepted" (click)="acceptGroup(paymentGroup.key)" />
                  <label for="credit-card-agree-{{paymentGroup.key}}">{{'I agree to the' | translate:'summary' }} <a
                      attr.href="{{ paymentGroup.name + '-terms-url' | translate:'summary' }}" target="_blank">{{'Terms
                      & Conditions' | translate: 'summary' }}</a></label>
                </div>
                <div class="checkbox-wrapper" *ngIf="isInvoicingAvailableForPayment(paymentGroup)">
                  <input type="checkbox" id="invoice-request-{{paymentGroup.key}}"
                    [(ngModel)]="invoicingStateInfo.isRequested" />
                  <label for="invoice-request-{{paymentGroup.key}}">{{ 'Invoice Request' | translate: 'invoicing'
                    }}</label>
                  <div class="font-ico-info information" *ngIf="true">
                    <span>{{ 'Invoice Request description' | translate: 'invoicing' }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <invoicing-aero *ngIf="invoicingStateInfo.isDisplayingEditor"
      (goBack)="invoicingStateInfo.isDisplayingEditor = false" (skipInvoicing)="skipInvoicing()">
    </invoicing-aero>
    <!-- <button type="button" class="btn btn-primary mobile-submit" (click)="goToNextStep()"
      attr.aria-label="{{ 'Click to proceed to payment' | translate:'summary' }}">{{ 'Proceed to payment' | translate:'summary' }}</button> -->


    <check-in-default-modal #agencyPaymentModal modalId="agencyPaymentModalId"
      modalTitle="{{'Agency payment - {0}' | translate: 'summary':selectedPaymentMethod?.code}}"
      okButtonText="{{ 'Continue' | translate}}" [okRequired]="true" [cancelRequired]="false" *ngIf="isOnAgencyPortal">
      <ng-container
        *ngIf="selectedPaymentMethod && selectedPaymentMethod.group == 'Agency' &&  selectedPaymentMethod.details ">
        <div>
          Available Amount: {{selectedPaymentMethod?.details.AvailableAmount |
          currency:selectedPaymentMethod?.details.AvailableAmountCurrency }}
        </div>
        <div>
          Balance Due: {{ selectedPaymentMethod?.details.TotalAmount | currencyDisplay }}
        </div>
      </ng-container>
    </check-in-default-modal>
  </div>

  <!-- <div class="form-wrapper-sidebar">
    <div class="checkin-cart-message checkin-summary-message checkin-unsuccessful" *ngIf="paymentFailed">
      <span class="icon" role="presentation"></span>
      <span class="message-text">{{'Your payment was not successful.'| translate}}</span>
    </div>
  </div> -->

</check-in-form-wrapper>

<ng-template #loadingMessage>
  <div>
    {{"Please wait while we are preparing your payment details"|translate:"summary"}}
  </div>
</ng-template>