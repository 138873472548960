import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileHomeComponent } from './mobile-home/mobile-home.component';

@NgModule({
  declarations: [MobileHomeComponent],
  imports: [
    CommonModule
  ]
})
export class MobileAppModule { }
