export class Constants {

    // fee codes
    static readonly InsuranceFeeCode = 'ASIG';
    static readonly InsuranceInfantCode = 'INFT';

    // ssr codes
    static readonly InfantSsrCode = 'INF';
    static readonly SsrCodeUM = 'UM';
    static readonly SsrCodeUMFR = 'UMFR';
    static readonly SsrCodeUMNR = 'UMNR';
    static readonly SsrCodeUMRO = 'UMRO';

    // passenger types
    static readonly AdultPaxType = 'ADT'; // 18+ years
    static readonly YoungAdultPaxType = 'YTH'; // 16-18 years
    static readonly TeenPaxType = 'CLD'; // 12-16 years
    static readonly ChildrenPaxType = 'CHD'; // 2-12 years
    static readonly InfantPaxType = 'INL'; // under 2 years

    // journey types
    static readonly OneWayTrip = 'OneWay';
    static readonly RoundTrip = 'RoundTrip';

    // others
    static readonly mobileScreenWith = 768;
    static readonly mainSpinnerId = 'mainSpinner';
    static readonly NotCheckedIn = 'Default';
    static readonly SelectedFlightIndexForChange = 'SelectedFlightForChange';
    static readonly CartBreakdown = 'CartBreakdown';
    static readonly MoveFlightsFlow = 'MoveFlightsFlow';

    // corporate bundle codes
    static readonly CorporateBundles: string[] = ['CORP', 'CYPF', 'CYPL'];

    // seats
    static readonly seatTypeDISABIL = 'DISABIL';
    static readonly seatTypePET = 'PET';
    static readonly seatTypeINFANT = 'INFANT';
    static readonly seatTypeNCHILD = 'NCHILD';
    static readonly seatTypeLOCATION = 'LOCATION';
    static readonly seatTypeLEGROOM = 'LEGROOM';

    static readonly MobileAppDomain = 'mobile.flyblueair.com';
    static get MobileAppOrigin() {
        return `https://${Constants.MobileAppDomain}`;
    }

    static readonly TypeCodes: string[] = ['Z', 'Y', 'X', 'V', 'U', 'T', 'S', 'R', 'Q', 'N'];
}
