import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'check-in-ssr-same-options-for-return-flight',
  templateUrl: './ssr-same-options-for-return-flight.component.html',
  styleUrls: ['./ssr-same-options-for-return-flight.component.scss']
})
export class SsrSameOptionsForReturnFlightComponent implements OnInit {
  private _isSelected: boolean;

  @Input() visible: boolean;
  @Input()
  get isSelected() {
    return this._isSelected;
  }
  set isSelected(val) {
    this._isSelected = val;
    this.isSelectedChange.emit(this._isSelected);
  }

  @Output() isSelectedChange = new EventEmitter();

  uniqueId: number;

  constructor() {
    this.uniqueId = Math.floor(Math.random() * 1000) + 1;
  }

  ngOnInit() {
  }

}
