import { OnInit, EventEmitter } from '@angular/core';
import { SeatMapService } from '../seat-map.service';
var SeatMapLegendComponent = /** @class */ (function () {
    function SeatMapLegendComponent(seatmapService) {
        this.seatmapService = seatmapService;
        this.seatApplyEnforced = [];
        this.selectFilter = new EventEmitter();
        this.appliedFilters = [];
        this.appliedFilters = seatmapService.appliedFilters;
    }
    Object.defineProperty(SeatMapLegendComponent.prototype, "seatFilterRestriction", {
        set: function (p) {
            this.seatApplyEnforced = p;
        },
        enumerable: true,
        configurable: true
    });
    SeatMapLegendComponent.prototype.ngOnChanges = function () {
        // this.parseSeatMap(this.seatMap)
    };
    Object.defineProperty(SeatMapLegendComponent.prototype, "selectPassenger", {
        set: function (p) {
            this.appliedFilters = [];
        },
        enumerable: true,
        configurable: true
    });
    SeatMapLegendComponent.prototype.ngOnInit = function () {
    };
    SeatMapLegendComponent.prototype.filterClick = function (f) {
        var filters = this.seatmapService.appliedFilters;
        if (this.seatApplyEnforced.indexOf(f) >= 0) {
            return;
        }
        if (filters.indexOf(f) >= 0) {
            filters.splice(this.appliedFilters.indexOf(f), 1);
        }
        else {
            filters.push(f);
        }
        var currentFilteredMap = { map: this.seatMap.sellKey, filter: f };
        //check if can apply filter
        this.selectFilter.next(currentFilteredMap);
    };
    return SeatMapLegendComponent;
}());
export { SeatMapLegendComponent };
