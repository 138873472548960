<check-in-selections-clear-warning-modal #changeFlightsWarningModal>
</check-in-selections-clear-warning-modal>

<check-in-form-wrapper ariaLabel="{{ 'Step 5 Check-in form' | translate }}" [step]="'step-5'" [showPriceDisplay]="true"
    (continue)="goToNextStep()">

    <div class="form-wrapper-header">
        <a href="javascript:;" class="link-arrow"
            attr.aria-label="{{ 'Click to skip & complete check-in' | translate: 'extras' }}"
            (click)="skipAndComplete()">
            {{ 'Skip & complete check-in' | translate: 'extras' }}
        </a>
    </div>

    <div class="form-wrapper-top-body">
        <!-- luggage priority details free option modal -->
        <div>
            <div
                *ngIf="ssrsService.isDisplayedPriorityDetailsFreeModal ? toggleLuggagePriorityDetailsFreeOptionModal() : false">
            </div>

            <check-in-default-modal #priorityDetailsFreeModal modalId="priorityDetailsFreeModal"
                modalTitle="{{ '1 Small Bag' | translate: 'extras' }}" modalText="" okButtonText="" cancelButtonText=""
                [okRequired]="" [cancelRequired]="">

                <div>
                    <div class="default-modal half-split asd">
                        <div class="section-text padding-bottom">
                            {{ 'A small bag that fit under the seat in front of you.' | translate: 'extras' }}
                            <br />
                            {{ 'Examples of small bags include:' | translate: 'extras' }}
                        </div>

                        <div class="image-wrapper-2cols">
                            <div><img [src]="assetsPath + 'img/ssrs/pop-up_1-1.jpg'"></div>
                            <div><img [src]="assetsPath + 'img/ssrs/pop-up_1-2.jpg'"></div>
                            <div><img [src]="assetsPath + 'img/ssrs/pop-up_1-3.jpg'"></div>
                        </div>

                        <div class="section-text padding-bottom">
                            <span class="ssr-icon font-ico-checked-sign">
                            </span>
                            {{ 'If you bring a second bag or if your bag is too big to fit under the seat,
                            you will be charged an' | translate: 'extras' }}
                            <span class="text-color-red">
                                {{ 'additional fee at the airport.' | translate: 'extras' }}
                            </span>
                        </div>
                    </div>
                </div>
            </check-in-default-modal>
        </div>

        <!-- luggage priority details paid option modal -->
        <div>
            <div
                *ngIf="ssrsService.isDisplayedPriorityDetailsPaidModal ? toggleLuggagePriorityDetailsPaidOptionModal() : false">
            </div>

            <check-in-default-modal #priorityDetailsPaidModal modalId="priorityDetailsPaidModal"
                modalTitle="{{ 'Priority and 2 Cabin Bags' | translate: 'extras' }}" modalText="" okButtonText=""
                cancelButtonText="" [okRequired]="" [cancelRequired]="">

                <div>
                    <h2 class="section-text">
                        {{ 'Choose PROIRITY and 2 cabin bags for your trip! This will help you skip the long queues and
                        avoid any additional fees at the boarding gate.' | translate: 'extras' }}
                    </h2>

                    <div class="image-wrapper-2cols">
                        <div><img [src]="assetsPath + 'img/ssrs/pop-up_2-1.jpg'"></div>
                        <div><img [src]="assetsPath + 'img/ssrs/pop-up_2-2.jpg'"></div>
                    </div>

                    <br />

                    <div class="section-text">
                        <ul>
                            <li>
                                <span class="ssr-icon font-ico-checked-sign">
                                </span>
                                <div class="text">
                                    {{ 'Allows you to board first and to bring two cabin bags of 10kg each.' |
                                    translate: 'extras' }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </check-in-default-modal>
        </div>

        <!-- luggage bag details modal -->
        <div>
            <div *ngIf="ssrsService.isDisplayedBagDetailsModal ? toggleLuggageBagDetailsModal() : false">
            </div>

            <check-in-default-modal #pbagModal modalId="pbagModal"
                modalTitle="{{ 'Adding a Check-in Bag after booking will be more expensive. Book now for the best price.' | translate: 'extras'}}"
                modalText="" okButtonText="" cancelButtonText="" [okRequired]="" [cancelRequired]="">

                <div>
                    <div class="bags-wrapper">
                        <div class="bag-container">
                            <h2>
                                {{ '23kg Check-in Bag' | translate: 'extras' }}
                            </h2>

                            <div class="section-SubTitle" style="text-align: center;">
                                <img [src]="assetsPath + 'img/ssrs/23kg-bag-with-size.png'">
                            </div>
                        </div>
                    </div>
                    <br />

                    <div class="section-text">
                        <ul>
                            <li>
                                <span class="ssr-icon font-ico-checked-sign">
                                </span>
                                <div class="text">
                                    {{ 'Each passenger can add up to 4 of Check-in Bags.' | translate: 'extras' }}
                                </div>
                            </li>

                            <li>
                                <span class="ssr-icon font-ico-checked-sign"></span>
                                <div class="text">
                                    {{ 'Your Check-in Bag must be dropped off at the bag drop desk before entering
                                    security.' | translate: 'extras' }}
                                </div>
                            </li>

                            <li>
                                <span class="ssr-icon font-ico-checked-sign"></span>
                                <div class="text">
                                    {{ 'You can add a Check-in Bag up to 2 hours before your flight scheduled departure
                                    time.' | translate: 'extras' }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </check-in-default-modal>
        </div>

        <!-- Priority and 2 Cabin Bags confirmation modal -->
        <div>
            <!-- prioritySsrAllSelectionsShouldBeSetForPriorityBoardingModal -->
            <check-in-default-modal #prioritySsrAllSelectionsShouldBeSetForPriorityBoardingModal
                modalId="prioritySsrAllSelectionsShouldBeSetForPriorityBoardingModal" modalTitle="" modalText="" okButtonText=""
                cancelButtonText="Ok" [okRequired]="" [cancelRequired]="true" leftButtonClass="inverted">
                <!-- "Priority and 2 Cabin Bags"  -->
                <div>
                    <h2 class="section-title" style="text-align: center;">
                        {{ 'Mandatory selection' | translate: 'extras' }}
                    </h2>

                    <div class="warning-sign"></div>

                    <div class="default-modal half-split asd">
                        <h2 class="section-text">
                            {{ 'You need to choose your cabin bag option – Choose PROIRITY and 2 cabin bags for your
                            trip! This will help you skip the long queues
                            and avoid any additional fees at the boarding gate.' | translate: 'extras' }}
                        </h2>

                        <div class="image-wrapper-2cols text">
                            <div>
                                <div class="image-wrap">
                                    <img [src]="assetsPath + 'img/ssrs/small-cabin-bag.png'"
                                      alt="{{ '1 SMALL CABIN BAG' | translate: 'extras' }}">
                                  </div>

                                  <span>
                                    {{ '40 x 30 x 20 cm' | translate: 'extras' }}
                                  </span>

                                  <span class="title">
                                    <span class="description" [innerHTML]="'1 SMALL CABIN BAG'  | translationWithHtml:'extras'">
                                    </span>
                                  </span>

                                  <span class="sub-title" [innerHTML]="'that fits under seats' | translationWithHtml: 'extras'">
                                  </span>

                                  <span class="fixed-center-bottom" [innerHTML]="'Included' | translationWithHtml: 'extras'">
                                  </span>
                            </div>
                            <div>
                                <div class="image-wrap">
                                    <img [src]="assetsPath + 'img/ssrs/small-cabin-bag-trolley.png'"
                                      alt="{{ 'SMALL CABIN BAG & TROLLEY BAG' | translate: 'extras' }} ">
                                  </div>

                                  <span [innerHTML]="'40 x 30 x 20 cm & 55 x 40 x 20 cm / max. 10KG' | translationWithHtml: 'extras'">
                                  </span>

                                  <span class="title">
                                    <span class="description" [innerHTML]="'SMALL CABIN BAG & TROLLEY BAG'  | translationWithHtml:'extras'">
                                    </span>
                                  </span>

                                  <div class="higlighted">
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 12 10"
                                      style="enable-background:new 0 0 12 10;" xml:space="preserve">
                                      <style type="text/css">
                                        .st0 {
                                          fill: #4EA046;
                                        }
                                      </style>
                                      <g>
                                        <ellipse class="st0" cx="9.5" cy="0.9" rx="0.9" ry="0.9" />
                                        <path class="st0"
                                          d="M7,9.4C6.9,9.6,7,9.9,7.2,10c0.2,0.1,0.5,0,0.6-0.3c0,0,0,0,0,0l0.7-2.1L7.8,6.7L7,9.4z" />
                                        <path class="st0" d="M11.9,4.9l-1.3-1.2L10,2.7C9.9,2.6,9.8,2.4,9.5,2.4L8.9,2.2C8.5,2.1,8.2,2.1,8,2.2L6.9,2.9
                                        C6.8,2.9,6.8,3,6.8,3L5.7,4.5C5.6,4.7,5.6,4.9,5.7,5.1L5.2,6.3c0,0-0.1-0.1-0.2-0.1L4.4,5.9c-0.3-0.1-0.6,0-0.8,0.3c0,0,0,0,0,0
                                        L2.5,8.6c-0.1,0.3,0,0.6,0.3,0.7c0,0,0,0,0,0l0.4,0.2c0-0.4,0.4-0.7,0.8-0.7c0.1,0,0.3,0.1,0.4,0.1l0.2-0.4c0,0,0,0,0,0l1.6-3.3
                                        c0.1,0,0.2-0.1,0.3-0.2l1-1.4l0.7-0.4L7.7,5.4C7.6,5.8,7.8,6.1,8,6.3l1.1,1.4l0.6,1.9c0.1,0.2,0.3,0.4,0.6,0.3
                                        c0.2-0.1,0.4-0.3,0.3-0.6c0,0,0,0,0,0l-0.6-2c0-0.1,0-0.1-0.1-0.1L9.2,6.2l0.5-2.1l0.1,0.2c0,0.1,0.1,0.1,0.1,0.1l1.4,1.2
                                        c0.2,0.2,0.5,0.1,0.6,0C12.1,5.4,12,5.1,11.9,4.9L11.9,4.9z" />
                                        <ellipse class="st0" cx="3.9" cy="9.6" rx="0.4" ry="0.4" />
                                        <path class="st0"
                                          d="M0.6,3h2.6c0.1,0,0.2-0.1,0.2-0.2c0-0.1-0.1-0.2-0.2-0.2H0.6c-0.1,0-0.2,0.1-0.2,0.2C0.4,2.9,0.5,3,0.6,3z" />
                                        <path class="st0"
                                          d="M2.8,5.7H0.2C0.1,5.7,0,5.8,0,5.9C0,6,0.1,6.1,0.2,6.1h2.6C2.9,6.1,3,6,3,5.9C3,5.8,2.9,5.7,2.8,5.7z" />
                                        <path class="st0"
                                          d="M1.5,4.4c0,0.1,0.1,0.2,0.2,0.2h2.6c0.1,0,0.2-0.1,0.2-0.2S4.5,4.2,4.4,4.2H1.7C1.6,4.2,1.5,4.3,1.5,4.4z" />
                                      </g>
                                    </svg>
                                    <span [innerHTML]="'Priority and 2 Cabin Bags'  | translationWithHtml:'extras'">
                                    </span>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Luggage Bag selection confirmation modal -->
                <div *ngIf="false">
                    <div class="default-modal half-split asd">
                        <div class="section-text padding-bottom">
                            {{ 'Please choose from one of the two options below: ' | translate: 'extras' }}
                        </div>

                        <div class="section-text padding-bottom">
                            {{ 'A small bag that fit under the seat in front of you.' | translate: 'extras' }}
                            <br />
                            {{ 'Examples of small bags include:' | translate: 'extras' }}
                        </div>

                        <div class="image-wrapper-2cols">
                            <div><img [src]="assetsPath + 'img/ssrs/pop-up_1-1.jpg'"></div>
                            <div><img [src]="assetsPath + 'img/ssrs/pop-up_1-2.jpg'"></div>
                            <div><img [src]="assetsPath + 'img/ssrs/pop-up_1-3.jpg'"></div>
                        </div>
                    </div>
                </div>
            </check-in-default-modal>
        </div>

        <!-- luggage priority selection confirmation modal -->
        <div>
            <!-- prioritySsrAllPriorityBoardingShouldBeSelectedWarningModal -->
            <!-- cel putin un pasager care a bifat small bag -->
            <check-in-default-modal #prioritySsrAllPriorityBoardingShouldBeSelectedWarningModal modalId="prioritySsrAllPriorityBoardingShouldBeSelectedWarningModal"
                modalTitle="{{ '1 Small Cabin Bag only' | translate: 'extras' }}" modalText=""
                [okButtonText]="'Small bag is sufficient for my trip' | translate: 'extras'"
                [cancelButtonText]="'I prefer Priority' | translate: 'extras'"
                [okRequired]="true" [cancelRequired]="true" leftButtonClass="inverted">

                <div>
                    <div class="default-modal half-split asd">
                        <h2 class="section-text">
                            <!--
                        {{ 'You need to choose your cabin bag option – Choose PROIRITY and 2 cabin bags for your trip!
                        This will help you skip the long queues and avoid any additional fees at the boarding gate.' | translate: 'extras' }}
                        -->
                            {{ 'Note that if you bring a second bag or if your bag is too big to fit under the seat, you
                            will be charged an additional' | translate: 'extras' }}
                            <strong>
                                {{ 'fee' | translate: 'extras' }}
                            </strong>
                            {{ 'at the boarding gate.' | translate: 'extras' }}
                        </h2>
                        <!--
                    <div class="image-wrapper-2cols">
                        <div><img [src]="assetsPath + 'img/ssrs/pop-up_2-1.jpg'"></div>
                        <div><img [src]="assetsPath + 'img/ssrs/pop-up_2-2.jpg'"></div>
                    </div>
                    -->
                    </div>
                </div>
            </check-in-default-modal>
        </div>

        <!-- bag confirmation modal -->
        <check-in-default-modal #bagConfirmationModal modalId="bagConfirmationModal"
            modalTitle="{{ 'Do you need Check-in Luggage?' | translate: 'extras' }}" modalText=""
            okButtonText="No thanks" cancelButtonText="Yes please" [okRequired]="true" [cancelRequired]="true"
            leftButtonClass="inverted">

            <div>
                <div class="section-text">
                    <ul>
                        <li>
                            {{ 'You can choose your check-in luggage from the 2 options below:' | translate: 'extras' }}
                        </li>
                    </ul>
                </div>

                <h2 style="text-align: center;">
                    {{ '23kg Check-in Bag' | translate: 'extras' }}
                </h2>

                <div class="section-SubTitle" style="text-align: center;">
                    <img [src]="assetsPath + 'img/ssrs/23kg-bag.png'">
                </div>

                <br />

                <div class="section-text">
                    <ul>
                        <li>
                            <span class="ssr-icon font-ico-checked-sign"></span>
                            <div class="text">
                                {{ 'Your 23kg Check-in Bag must be dropped off at the bag drop desk before entering
                                security.' | translate: 'extras' }}
                            </div>
                        </li>

                        <li>
                            <span class="ssr-icon font-ico-checked-sign"></span>
                            <div class="text">
                                {{ 'You can add a 23kg Check-in Bag up to 2 hours before your flight scheduled departure
                                time.' | translate: 'extras' }}
                            </div>
                        </li>

                        <li>
                            <span class="ssr-icon font-ico-checked-sign"></span>
                            <div class="text">
                                {{ 'Adding a 23kg Check-in Bag after booking will be more expensive. Book now for the
                                best price.' | translate: 'extras' }}
                            </div>
                        </li>
                    </ul>
                </div>

                <br />

                <h2 style="text-align: center;">
                    {{ '32kg Check-in Bag' | translate: 'extras' }}
                </h2>

                <div class="section-SubTitle" style="text-align: center;">
                    <img [src]="assetsPath + 'img/ssrs/32kg-bag.png'">
                </div>

                <br />

                <div class="section-text">
                    <ul>
                        <li>
                            <span class="ssr-icon font-ico-checked-sign">
                            </span>
                            <div class="text">
                                {{ 'Each passenger can add up to 3 x 32kg Check-in Bags.' | translate: 'extras' }}
                            </div>
                        </li>

                        <li>
                            <span class="ssr-icon font-ico-checked-sign">
                            </span>
                            <div class="text">
                                {{ 'Adding a 32kg Check-in Bag after booking will be more expensive. Book now for the
                                best price.' | translate: 'extras' }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </check-in-default-modal>

        <div *ngIf="hasRecordLocator && hasSpoilageFees">
            <div class="spoilageFee">
                {{ 'spoilage-fee-info' | translate: 'extras' }}
            </div>
        </div>

        <check-in-ssrs [pbagModal]="pbagModal">
        </check-in-ssrs>

        <passenger-bundle [pbagModal]="pbagModal" [showHeader]="true" *ngIf="!isCanceledBookingStatus && !isRestricted">
        </passenger-bundle>

        <check-in-insurance *ngIf="insuranceEnabled">
            <div>
                <h3>
                    {{ 'Travel insurance' | translate: 'extras' }}
                </h3>
                <ul role="menu" class="checkin-checked-box">
                    <li role="menuitem" *blueTanslateItems="'insurance.description.items';let translation"
                        [innerHTML]="translation"></li>
                </ul>
            </div>
        </check-in-insurance>
    </div>
</check-in-form-wrapper>
