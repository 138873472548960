<blue-modal [id]="modalId" closeBtnLabel="{{ 'Close notification popup' | translate:'shared' }}" class="checked-in-summary-popup">
    <ng-container *ngIf="isOpened">
        <blue-loading-spinner [id]="sendEmailSpinnerId"></blue-loading-spinner>
        <div class="default-modal">
            <h2 class="section-title">{{'You have not confirmed your selection'| translate :'shared'}}</h2>
            <div class="warning-sign"></div>
            <ng-content></ng-content>
            <div class="buttons">
                <button type="submit" class="btn btn-primary btn-small btn-left" attr.aria-label="{{ 'Click to dismiss' | translate:'shared' }}" (click)="ok()">
              {{'Dismiss and continue' | translate:'shared' }}
          </button>
                <button type="submit" class="btn btn-primary btn-small btn-right" attr.aria-label="{{ 'Click to dismiss' | translate:'shared' }}" (click)="cancel()">
              {{'Reconsider' | translate:'shared' }}
          </button>
            </div>
        </div>
    </ng-container>
</blue-modal>