import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ProfileInfoBox } from '../../profile-info-box';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ProfileService } from 'src/app/core/profile.service';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { ConfigService } from 'src/app/core/config.service';
import { take } from 'rxjs/operators';
import { DateRange } from 'src/app/common-modules/blue-air-common/date-range.model';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
var AgentContactDetailsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AgentContactDetailsComponent, _super);
    function AgentContactDetailsComponent(modalService, profileService, resourceService, spinnerService, configService, translate) {
        var _this = _super.call(this, modalService) || this;
        _this.profileService = profileService;
        _this.resourceService = resourceService;
        _this.spinnerService = spinnerService;
        _this.configService = configService;
        _this.translate = translate;
        _this.phonePattern = '^[0-9+()\/-][0-9+() \/-]{0,19}?$';
        _this.namePattern = '^[A-Za-z _]*[A-Za-z][A-Za-z _]*$';
        _this.emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
        _this.editModalId = 'contact-infos';
        _this.editSpinnerId = 'contact-infos';
        _this.contactInfos = {};
        _this.model = {};
        _this.errors = [];
        _this.datePickerRange = new DateRange(null);
        _this.datePickerRange.generateYearsForAge(18, 150);
        _this.initCurrencies();
        _this.initLanguages();
        _this.resourceService.getPassengerTitles().pipe(take(1)).subscribe(function (t) { return _this.titles = t; });
        _this.route = _this.configService.SaveProfileInfoUrl;
        _this.profileSubscription = profileService.profile.subscribe(function (p) {
            if (!p) {
                return;
            }
            _this.contactInfos.title = p.name.title;
            _this.contactInfos.titleName = _this.findTextByValue(_this.titles, p.name.title);
            _this.contactInfos.firstName = p.name.first;
            _this.contactInfos.lastName = p.name.last;
            _this.contactInfos.gender = p.gender;
            _this.contactInfos.dateOfBirth = p.dateOfBirth;
            _this.contactInfos.emailAddress = _this.getDefaultItemValue(p.person.emailAddresses, 'emailAddress');
            // this.contactInfos.phoneNumber = this.getDefaultItemValue(p.person.phoneNumbers, 'number');
            _this.contactInfos.residentCountry = p.residentCountry;
            _this.contactInfos.nationality = p.nationality;
            _this.contactInfos.workPhoneNumber = p.workPhoneNumber.number;
            _this.contactInfos.homePhoneNumber = p.homePhoneNumber.number;
            _this.contactInfos.mobilePhoneNumber = p.mobilePhoneNumber.number;
            _this.contactInfos.faxPhoneNumber = p.faxPhoneNumber.number;
            _this.contactInfos.cultureCode = p.cultureCode;
            _this.contactInfos.cultureName = _this.findTextByValue(_this.languages, p.cultureCode);
            _this.contactInfos.currencyCode = p.currencyCode;
            _this.contactInfos.currencyName = _this.findTextByValue(_this.currencies, p.currencyCode);
        });
        return _this;
    }
    AgentContactDetailsComponent.prototype.ngOnInit = function () {
    };
    AgentContactDetailsComponent.prototype.ngOnDestroy = function () {
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    };
    AgentContactDetailsComponent.prototype.edit = function () {
        var _this = this;
        this.model = {
            title: this.contactInfos.title,
            firstName: this.contactInfos.firstName,
            lastName: this.contactInfos.lastName,
            gender: this.contactInfos.gender,
            dateOfBirth: this.contactInfos.dateOfBirth,
            emailAddress: this.contactInfos.emailAddress,
            residentCountry: this.contactInfos.residentCountry,
            nationality: this.contactInfos.nationality,
            workPhoneNumber: this.contactInfos.workPhoneNumber,
            homePhoneNumber: this.contactInfos.homePhoneNumber,
            mobilePhoneNumber: this.contactInfos.mobilePhoneNumber,
            faxPhoneNumber: this.contactInfos.faxPhoneNumber,
            cultureCode: this.contactInfos.cultureCode,
            currencyCode: this.contactInfos.currencyCode
        };
        this.openModal();
        this.resourceService.getCountries().subscribe(function (c) {
            _this.countries = c;
            _this.spinnerService.hideSpinnerById(_this.editSpinnerId);
        });
        this.spinnerService.showSpinnerById(this.editSpinnerId);
    };
    AgentContactDetailsComponent.prototype.save = function () {
        var _this = this;
        if (!this.editForm.valid) {
            return;
        }
        var obj = {
            // personalEmailAddress: {
            //   emailAddress: this.model.emailAddress
            // },
            name: {
                title: this.model.title,
                first: this.model.firstName,
                last: this.model.lastName
            },
            dateOfBirth: this.model.dateOfBirth,
            gender: this.model.gender,
            residentCountry: this.model.residentCountry,
            nationality: this.model.nationality,
            workEmailAddress: {
                emailAddress: this.model.emailAddress
            },
            workPhoneNumber: {
                number: this.model.workPhoneNumber
            },
            homePhoneNumber: {
                number: this.model.homePhoneNumber
            },
            mobilePhoneNumber: {
                number: this.model.mobilePhoneNumber
            },
            faxPhoneNumber: {
                number: this.model.faxPhoneNumber
            },
            cultureCode: this.model.cultureCode,
            currencyCode: this.model.currencyCode
        };
        this.profileService.updateAgent(obj).then(function () { return _this.closeModal(); }, function (e) {
            _this.errors = e.error.errors.map(function (err) {
                var t = _this.translate.instant(err.errorCode, 'profile-info');
                return t === err.errorCode ? err.errorCode + ": " + err.errorMessage : t;
            });
        });
    };
    AgentContactDetailsComponent.prototype.getDefaultItemValue = function (arrayObject, propertyName) {
        var e_1, _a;
        try {
            for (var _b = tslib_1.__values(arrayObject.items), _c = _b.next(); !_c.done; _c = _b.next()) {
                var item = _c.value;
                if (item.default && item[propertyName]) {
                    return item[propertyName];
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    AgentContactDetailsComponent.prototype.initLanguages = function () {
        this.languages = [{ text: 'English', value: 'en-GB' }, { text: 'English - United States', value: 'en-US' },
            { text: 'Romanian', value: 'ro-RO' }];
    };
    AgentContactDetailsComponent.prototype.initCurrencies = function () {
        this.currencies = [{ text: 'Euro', value: 'EUR' }, { text: 'Dollar', value: 'USD' }, { text: 'Danish Krone', value: 'DKK' },
            { text: 'Romanian Leu', value: 'RON' }];
    };
    return AgentContactDetailsComponent;
}(ProfileInfoBox));
export { AgentContactDetailsComponent };
