import { Injectable } from '@angular/core';
import { ApplicationFlowEnum, BookingStepsService } from '../booking-steps.service';
import { Resolve } from '@angular/router';

@Injectable()
export class BookingLockFareFlowResolverService implements Resolve<ApplicationFlowEnum> {

  constructor(private appFlowSteps: BookingStepsService) { }

  resolve() {
    this.appFlowSteps.setFlow(ApplicationFlowEnum.LockFare);
    return ApplicationFlowEnum.LockFare;
  }

}
