import { Injectable } from '@angular/core';
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { CanActivate } from '@angular/router';
import { BookingStepsService } from '../core/booking-steps.service';
import { FlowManagerService } from '../core/flow-manager.service';

@Injectable()
export class CanActivateBookingChangesService extends BaseCanActivateService implements CanActivate {

  constructor(steps: BookingStepsService, flowManager: FlowManagerService) {
    super(steps, flowManager.applicationFlowService);
   }
}
