import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DocumentsComponent } from './documents/documents.component';
import { DocumentsRoutes } from './documents-routes';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { CanActivateDocumentsService } from './can-activate-documents.service';
import { PassengerDocumentsComponent } from './passenger-documents/passenger-documents.component';
import { SharedModule } from '../shared/shared.module';
import { DocumentsService } from './documents.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // RouterModule.forRoot(
    //   DocumentsRoutes
    // ),
    BlueAirCommonModule,
    SharedModule
  ],
  declarations: [DocumentsComponent, PassengerDocumentsComponent],
  providers: [CanActivateDocumentsService, DocumentsService]
})
export class DocumentsModule { }
