import { Component, OnInit, Input, HostListener, Renderer2, ViewChild } from '@angular/core';
import { PassengerTypeSelectionViewModel } from '../models/passenger-selection-view-model';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { PassengerSelectionType } from '../models/pasenger-selection-type-enum';
import { Constants } from '../../constants';
import { BlueRenderer } from '../../common-modules/blue-air-common/services/blue-renderer.service';
import { DomHelper } from '../dom-helper';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'passenger-type-picker',
  templateUrl: './passenger-type-picker.component.html',
  styleUrls: ['./passenger-type-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: PassengerTypePickerComponent, multi: true },
    BlueRenderer
  ]
})
export class PassengerTypePickerComponent implements OnInit, ControlValueAccessor {
  PassengerSelectionType = PassengerSelectionType;
  model: PassengerTypeSelectionViewModel;
  isOpened: boolean;
  isOnMobile: boolean;
  totalPaxCount: number = 9;
  modalMessage: string;
  modalId = 'numberOfPassengersModal';
  @ViewChild('numberOfPassengersModal', { static: true }) numberOfPassengersModal: DefaultModalComponent;
  
  @Input()
  set totalAllowedPassengers (value){
    this.totalPaxCount = value;
  }

  /** The current window width */
  private currentWindowWidth: number;

  tempModel: PassengerTypeSelectionViewModel;

  private onTouchedCb: () => void;
  private onChangeCb: (_: any) => void;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWindowWith(event.target.innerWidth);
  }

  constructor(private renderer: Renderer2, public blueRenderer: BlueRenderer, private modalService: BlueModalService) {
    this.tempModel = new PassengerTypeSelectionViewModel();
    this.updateWindowWith(window.innerWidth);
  }

  ngOnInit() {
  }

  toggleOpened(newValue: boolean = !this.isOpened) {
    this.isOpened = newValue;
  }

  focusIn() {
    this.toggleOpened(true);
    this.tempModel = Object.assign(new PassengerTypeSelectionViewModel(), this.model);
    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);

    DomHelper.IncreaseAppHeight(this.renderer);
  }

  focusOut(applySelection: boolean = false) {
    this.toggleOpened(false);

    if (applySelection) {
      this.model = Object.assign(new PassengerTypeSelectionViewModel(), this.tempModel);
      this.onChangeCb(this.model);
    }

    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
    
    DomHelper.ResetAppHeight(this.renderer);
  }

  /**
   * Makes updates to the component based on the new window width
   * @param newWidth - the new width of the window
   */

  private updateWindowWith(newWidth: number) {
    if (this.currentWindowWidth === newWidth) {
      return;
    }

    this.currentWindowWidth = newWidth;
    this.isOnMobile = newWidth < Constants.mobileScreenWith;
    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
  }

  update(paxType: PassengerSelectionType, value: number) {
    const modelProperty = this.tempModel.getProperty(paxType);
    const oldVal = this.tempModel[modelProperty];
    let newVal = this.tempModel[modelProperty] + value;
    let isExceededInfants = false;

    if (newVal < 0) {
      newVal = 0;
    }

    switch (paxType) {
      case PassengerSelectionType.Adult:
        this.tempModel.adults = newVal;

        if (this.tempModel.infants > this.tempModel.adults) {
          this.tempModel.infants = this.tempModel.adults;
        }

        break;

      case PassengerSelectionType.YoungAdult:

      case PassengerSelectionType.Teen:

      case PassengerSelectionType.Children:
        this.tempModel.children = newVal;
        break;

      case PassengerSelectionType.Infant:
        if (newVal > this.tempModel.adults) {
          isExceededInfants = true;
          break;
        }

        this.tempModel.infants = newVal;
        break;

      default:
        this.tempModel[modelProperty] = newVal;
        break;
    }

    // this needs group booking
    if (this.tempModel.count > this.totalPaxCount && oldVal < this.tempModel.count) {
      // todo: show popup error
      this.modalService.open("numberOfPaxExcededModal");
      this.tempModel[modelProperty] = oldVal;
    }

    if (this.tempModel.adults <= 0) {
      this.resetSelectedPassengersChoices();

      if (paxType == PassengerSelectionType.Children) {
        this.modalMessage = "Not without at least one adult";
        this.displayNumberOfPassengersModal();
      }
      
      if (paxType == PassengerSelectionType.Infant || isExceededInfants) {
        this.modalMessage = "One infant per adult";
        this.displayNumberOfPassengersModal();
      }
    }  
    
    if (isExceededInfants) {
      this.modalMessage = "One infant per adult";
      this.displayNumberOfPassengersModal();
    }  
  }

  resetSelectedPassengersChoices() {
    this.tempModel.adults = 0;
    this.tempModel.youngAdults = 0;
    this.tempModel.teens = 0;
    this.tempModel.children = 0;
    this.tempModel.infants = 0;
  }

  writeValue(obj: PassengerTypeSelectionViewModel): void {
    if (!obj) {
      this.model = new PassengerTypeSelectionViewModel();
      this.model.adults = 1;
    } else {
      this.model = new PassengerTypeSelectionViewModel();
      this.model.initFromModel(obj);
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // nothing to do
  }

  displayNumberOfPassengersModal() {
    this.numberOfPassengersModal.openPopup(this.modalId);
  }
}