import { BookingStepsService, CheckinSteps } from './../../core/booking-steps.service';
import { BookingFlightSelectService } from './../booking-flight-select.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { OnInit, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Constants } from 'src/app/constants';
import * as moment from 'moment';
import { CalendarDatePickerComponent } from '../calendar-date-picker/calendar-date-picker.component';
import { StarterCancelRebook } from 'src/app/core/models/change-booking-model';
import { BookingService } from 'src/app/core/booking.service';
var MoveFlightComponent = /** @class */ (function () {
    function MoveFlightComponent(bookingService, loadingSpinnerService, flightSelectService, bookingStepsService) {
        this.bookingService = bookingService;
        this.loadingSpinnerService = loadingSpinnerService;
        this.flightSelectService = flightSelectService;
        this.bookingStepsService = bookingStepsService;
        this.flightsToChange = [];
        this.callAvailability = new EventEmitter();
        this.moveFlightSpinnerId = 'moveFlightSpinnerId';
        this.format = 'DD/MM/YYYY';
        this.departureStationOptions = [];
        this.arrivalStationOptions = [];
    }
    Object.defineProperty(MoveFlightComponent.prototype, "tripType", {
        set: function (value) {
            this.isOneWay = typeof value === 'string' ? value === Constants.OneWayTrip : value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MoveFlightComponent.prototype, "outbound", {
        get: function () {
            var v = moment(this.outboundDate, this.format);
            return v.isValid() ? v.toDate() : null;
        },
        set: function (value) {
            this.outboundDate = this.validateDate(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MoveFlightComponent.prototype, "inbound", {
        get: function () {
            var v = moment(this.inboundDate, this.format);
            return v.isValid() ? v.toDate() : null;
        },
        set: function (value) {
            this.inboundDate = this.validateDate(value);
        },
        enumerable: true,
        configurable: true
    });
    MoveFlightComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingSpinnerService.hideSpinnerById(this.moveFlightSpinnerId);
        this.flightsToChangeSub = this.bookingService.flightsToChangeObs
            .subscribe(function (flights) {
            if (flights && flights.length > 0) {
                _this.flightsToChange = flights;
                /** trip index needed on booking fare select if round trip and only one journey selected for move */
                sessionStorage.setItem(Constants.SelectedFlightIndexForChange, (flights[0].journeySequenceNumber - 1).toString());
            }
        });
    };
    MoveFlightComponent.prototype.ngOnChanges = function (changes) {
        if (changes.departureStation || changes.arrivalStation) {
            if (this.departureStation === 'BHX') {
                this.departureStationOptions = ['BHX', 'LHR'];
                this.selectedDepartureStation = this.departureStationOptions[0];
            }
            else {
                this.departureStationOptions.length = 0;
                this.selectedDepartureStation = null;
            }
            if (this.arrivalStation === 'BHX') {
                this.arrivalStationOptions = ['BHX', 'LHR'];
                this.selectedArrivalStation = this.arrivalStationOptions[0];
            }
            else {
                this.arrivalStationOptions.length = 0;
                this.selectedArrivalStation = null;
            }
        }
    };
    MoveFlightComponent.prototype.ngOnDestroy = function () {
        if (this.flightsToChangeSub) {
            this.flightsToChangeSub.unsubscribe();
        }
    };
    /** used on flight search page */
    MoveFlightComponent.prototype.searchFlight = function () {
        var _this = this;
        this.moveFlight().then(function () {
            _this.callAvailability.next();
        });
    };
    /** used on itinerary page */
    MoveFlightComponent.prototype.moveFlight = function () {
        var _this = this;
        this.loadingSpinnerService.showSpinnerById(this.moveFlightSpinnerId);
        var journeyCriteria = new Array();
        this.flightsToChange.forEach(function (flight) {
            journeyCriteria.push(_this.initJourneyCriteria(flight));
        });
        return this.bookingService.createSnapshot().then(function (snapshot) {
            if (snapshot) {
                return _this.bookingService.refresh(true).then(function (booking) {
                    if (booking) {
                        return _this.bookingService.moveFlights(journeyCriteria).then(function (result) {
                            if (result) {
                                // needed on flight select on page refresh to remember current flow
                                sessionStorage.setItem(Constants.MoveFlightsFlow, 'true');
                                _this.loadingSpinnerService.hideSpinnerById(_this.moveFlightSpinnerId);
                                _this.bookingStepsService.goToStep(CheckinSteps.flights);
                            }
                        });
                    }
                });
            }
        });
    };
    MoveFlightComponent.prototype.outboundDateSelected = function () {
        var outb = moment(this.outboundDate, this.format);
        var inb = moment(this.inboundDate, this.format);
        if (outb.isAfter(inb)) {
            this.inboundDate = '';
        }
        if (this.inboundDatePicker) {
            this.inboundDatePicker.open();
        }
    };
    // private initFlightSearchModel(): Promise<boolean> {
    //   // return this.flightSelectService.getRememberSearchModel().then((searchModel: FlightSearchModel) => {
    //   //   if (searchModel) {
    //   //     var x = moment();
    //   //     this.flightSelectService.searchModel.departureDate =moment( this.outboundDate).format("YYYY/MM/DD") ;
    //   //     this.flightSelectService.searchModel.returnDate = moment( this.inboundDate).format("YYYY/MM/DD") ;
    //   //     this.flightSelectService.searchModel.isRoundTrip = !this.isOneWay;
    //   //     this.flightSelectService.searchModel.origin = this.departureStation;
    //   //     this.flightSelectService.searchModel.destination = this.arrivalStation;
    //   //     return true;
    //   //   }
    //       // this.flightSelectService.searchModel.departureDate = this.outboundDate;
    //       // this.flightSelectService.searchModel.returnDate = this.inboundDate;
    //       // this.flightSelectService.searchModel.isRoundTrip = !this.isOneWay;
    //       // this.flightSelectService.searchModel.origin = this.departureStation;
    //       // this.flightSelectService.searchModel.destination = this.arrivalStation;
    //       // return true;
    //  // });
    //  return  true;
    // }
    MoveFlightComponent.prototype.initJourneyCriteria = function (fromJourney) {
        var newJourney = new StarterCancelRebook();
        var fromSegments = fromJourney.segments;
        var journeyOrigin = fromSegments[0].departureStation;
        var journeyDestination = fromSegments[fromSegments.length - 1].arrivalStation;
        if (journeyOrigin === this.departureStation || journeyDestination === this.arrivalStation) {
            newJourney.Origin = this.selectedDepartureStation || this.departureStation;
            newJourney.Destination = this.selectedArrivalStation || this.arrivalStation;
        }
        else {
            newJourney.Destination = this.selectedDepartureStation || this.departureStation;
            newJourney.Origin = this.selectedArrivalStation || this.arrivalStation;
        }
        newJourney.FromJourney = fromJourney.sellKey;
        var newJourneyDepartureDate = fromJourney.journeySequenceNumber === 1 ||
            this.flightsToChange.length === 1 ? this.outboundDate : this.inboundDate;
        newJourney.DepartureDate = moment(newJourneyDepartureDate, this.format).format('YYYY-MM-DD');
        return newJourney;
    };
    MoveFlightComponent.prototype.validateDate = function (value) {
        if (value) {
            var date = moment(value);
            if (!date.isValid()) {
                date = moment(value, this.format);
            }
            return date.isValid() ? date.format(this.format) : null;
        }
        return null;
    };
    MoveFlightComponent.UKStations = ['LHR', 'LTN', 'LPL', 'BHX', 'GLA'];
    return MoveFlightComponent;
}());
export { MoveFlightComponent };
