import { BookingStepsService } from './../../../core/booking-steps.service';
import { BookingData, Error } from '../filter-bookings/filter-bookings.component';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';

@Component({
  selector: 'check-in-find-my-bookings',
  templateUrl: './find-my-bookings.component.html',
  styleUrls: ['./find-my-bookings.component.scss']
})
export class FindMyBookingsComponent implements OnInit {
  public bookingData: BookingData[] = [];
  public errors: Error[] = [];
  public dynamicBookingStatusUrl: string;

  constructor(private bookingStepsService: BookingStepsService, private translateService: TranslateService) {
    this.bookingStepsService.currentStep.next(null);
  }

  ngOnInit() {
    this.dynamicBookingStatusUrl = "https://book.aeroitalia.com/booking-status?culture=it-IT";
  }

  public bookingsFiltered(event: BookingData[]) {
    this.bookingData = event;
  }

  public setErrors(event: Error[]) {
    this.errors = event;
  }
}
