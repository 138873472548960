import * as tslib_1 from "tslib";
import { SsrsService } from './../ssrs.service';
import { EventEmitter, OnInit } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrType } from '../ssr-type.enum';
var SsrBoxContentPriorityBoardingComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsrBoxContentPriorityBoardingComponent, _super);
    function SsrBoxContentPriorityBoardingComponent(ssrsService) {
        var _this = _super.call(this, ssrsService) || this;
        _this.ssrsService = ssrsService;
        _this.passengersSelection = new Map();
        _this.saveChangesEvent = new EventEmitter();
        _this.removeSsrEvent2 = new EventEmitter();
        _this.ssrType = SsrType.PriorityBoarding;
        _this.uniqueId = Math.floor(Math.random() * 1000) + 1;
        return _this;
    }
    SsrBoxContentPriorityBoardingComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.flight && this.flight.passengers.length > 0) {
            this.allPassengersEnabled = this.allPassengersEnabledFunc();
            if (!this.allPassengersEnabled) {
                this.selectedPassenger = 0;
            }
        }
        this.allPassengersHaveTheSameSsrs();
        this.passengers.forEach(function (p) {
            _this.passengersSelection.set(_this.passengers.indexOf(p), _this.hasPassengerBags(p));
        });
        this.setPriorityPassengerMap(this.passengers[0]);
    };
    SsrBoxContentPriorityBoardingComponent.prototype.allPassengersEnabledFunc = function () {
        if (this.passengers.length < 2) {
            return false;
        }
        if (this.availableSsrs[0]) {
            for (var property in this.availableSsrs[0].limitPerPassenger) {
                if (this.availableSsrs[0].limitPerPassenger.hasOwnProperty(property)) {
                    if (this.availableSsrs[0].limitPerPassenger[property] === 0) {
                        return false;
                    }
                }
            }
        }
        return true;
    };
    SsrBoxContentPriorityBoardingComponent.prototype.showLuggagePriorityDetailsFreeOption = function () {
        this.ssrsService.isDisplayedPriorityDetailsFreeModal = true;
    };
    SsrBoxContentPriorityBoardingComponent.prototype.showLuggagePriorityDetailsPaidOption = function () {
        this.ssrsService.isDisplayedPriorityDetailsPaidModal = true;
    };
    SsrBoxContentPriorityBoardingComponent.prototype.selectSsrEvent = function (passengerIndex) {
        if (passengerIndex == null)
            return;
        this.ssrsService.isPrioritySsrSelected = true;
        this.selectSsr(passengerIndex, "PBRD", 1);
        this.saveChangesEvent.emit(passengerIndex);
        this.passengersSelection.set(passengerIndex, this.hasPassengerBags(this.passengers[passengerIndex]));
        this.setPriorityPassengerMap(this.passengers[passengerIndex]);
    };
    SsrBoxContentPriorityBoardingComponent.prototype.removeSsrEvent = function (passenger) {
        this.ssrsService.isPrioritySsrSelected = false;
        this.removeSsrEvent2.emit(passenger);
        //this.passengersSelection.set(this.passengers.indexOf(passenger[0]), this.hasPassengerBags(passenger[0]));
    };
    SsrBoxContentPriorityBoardingComponent.prototype.sameOptionsForAllFlightsEvent = function (sameOptionsForAllFlights) {
        this.sameOptionsForAllFlights = sameOptionsForAllFlights;
        this.selectSsrEvent(null);
    };
    SsrBoxContentPriorityBoardingComponent.prototype.hasPassengerBags = function (passenger) {
        return (passenger.ssrs.filter(function (s) { return s.ssrCode == 'PBRD'; }))[0] ? true : false;
    };
    SsrBoxContentPriorityBoardingComponent.prototype.checkAllPassengerBags = function () {
        var e_1, _a;
        var allPassengersHavePriority = true;
        try {
            for (var _b = tslib_1.__values(this.passengersSelection.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var hasPriority = _c.value;
                if (!hasPriority)
                    allPassengersHavePriority = false;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return allPassengersHavePriority;
    };
    SsrBoxContentPriorityBoardingComponent.prototype.setPriorityPassengerMap = function (passenger) {
        this.ssrsService.priorityPassengersMap.set(passenger.passenger.flightUniqueId, this.checkAllPassengerBags());
    };
    return SsrBoxContentPriorityBoardingComponent;
}(SsrBoxContentBase));
export { SsrBoxContentPriorityBoardingComponent };
