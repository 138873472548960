
<!-- TODO aria-labelledby="headerSavedProfiles"  -->
<div role="dialog" class="checkin-popup {{cssClass}}"  aria-hidden="true" aria-modal="true" #dialogRef>
    <span class="popup-overlay" role="presentation"></span>
    <div class="popup-content">
      <button type="button" class="popup-close" (click)="close()" *ngIf="showCloseBtn">
        <span class="font-ico-close-menu"><span class="sr-only">{{closeBtnLabel}}</span></span>
      </button>
      <ng-content></ng-content>
    </div>
  </div>


  <!-- DEMO -->
  <!-- <blue-modal [id]="'popup-custom-id'" closeBtnLabel="{{ 'Click to close popup' | translate }}" cssClass="profile-popup" >
      - here goes the content
    </blue-modal> -->