<blue-modal [id]="modalId " closeBtnLabel="{{ cancelButtonText }}" class="checked-in-summary-popup"
  [showCloseBtn]="showCloseBtn">
  <ng-container>
    <div class="default-modal {{cssClass}}">

      <h2 class="section-title">{{ modalTitle }}</h2>
      <div class="section-text">{{ modalText }}</div>

      <ng-content></ng-content>

      <div class="buttons" *ngIf="okRequired || cancelRequired">
        <button *ngIf="okRequired" [disabled]="okDisabled" [id]="okButtonId" type="submit"
          class="btn btn-primary btn-small btn-left {{leftButtonClass}}" attr.aria-label="okButtonText" (click)="ok()">
          {{ okButtonText }}
        </button>

        <button *ngIf="cancelRequired" [id]="cancelButtonId" type="submit"
          class="btn btn-primary btn-small btn-right inverted" attr.aria-label="cancelButtonText" (click)="cancel()">
          {{ cancelButtonText }}
        </button>
      </div>
    </div>
  </ng-container>
</blue-modal>