import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { DefaultModalComponent } from '../default-modal/default-modal.component';
import { SsrType } from '../../extras/ssr-type.enum';

@Component({
  selector: 'check-in-ssrs-seats-removal-notification',
  templateUrl: './ssrs-seats-removal-notification.component.html',
  styleUrls: ['./ssrs-seats-removal-notification.component.scss']
})
export class SsrsSeatsRemovalNotificationComponent implements OnInit {
  
  @Input() ssrType: SsrType;
  
  @ViewChild('seatRemovalModal', { static: true }) seatRemovalModal: DefaultModalComponent;

  seatsToBeRemoved: any[];

  constructor() { }

  ngOnInit() {
  }

  
  openPopup(callback, seatsToBeRemoved: any[]) {
    this.seatsToBeRemoved = seatsToBeRemoved;
    this.seatRemovalModal.openPopup(callback);
  }

}
