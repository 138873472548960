import { Injectable } from '@angular/core';
import { FlightModel } from '../../core/models/flight-model';

@Injectable({
  providedIn: 'root',
})
export class BookingFareSelectSyncService {

  currentBundleSelected: string;
  isBlueBenefitsSelected: boolean = false;
  useSamePriorityBoardingOptionForAllPassengers = false;
  passengersInitialPriorityBoardingChoice: Record<string, boolean> = {};
  flights: FlightModel[] = null;
}
