<check-in-accordion-item ariaLabel="{{ 'Click to open options for {0} to {1}' |
            translate:'extras':flight.departureStation.name:flight.arrivalStation.name}}"
            title="{{ '{0} to {1}' |
            translate:'':flight.departureStation.name:flight.arrivalStation.name}}" [isExpanded]="isExpanded">
  <div class="checkin-pets-options" [(checkInSsrListDisplay)]="isSsrsPopupOpened">
    <div class="pets-list js-ssr-list">
      <div class="pets-list-inner">
        <button type="button" class="close-popup" aria-hidden="true">
          <span class="font-ico-close-menu">
            <span class="sr-only">{{ 'Click to close equipment overlay' | translate:'extras' }}</span>
          </span>
        </button>
        <h2 aria-hidden="true" class="js-ssr-list-title">{{ 'Choose special assistance' | translate:'extras' }}</h2>
        <blue-loading-spinner [id]="spinnerId"></blue-loading-spinner>
        <ng-container *ngFor="let ssr of availableSsrs">
          <div class="pet-item" *ngIf="ssr.isVisible">
            <div class="header-wrap-item">
              <div class="circle-wrap"><span class="font-ico-circle"></span><i class="{{ssrsCustomIcons[ssr.ssrCode]}}"></i></div>
              <div class="pet-item-left">
                <h3>{{ ssr.ssrCode + '-title' | translate:'extras' }}</h3>
                <span class="pet-desc">{{ ssr.ssrCode + '-subtitle' | translate:'extras' }}</span>
                <span class="pet-desc">{{ssr.price | currencyDisplay }}</span>
              </div>
            </div>            
            <div class="pet-item-right">
              <ng-container *ngIf="ssr.getLimitPerPassenger(selectedPassenger) == 1">
                <ng-container *ngTemplateOutlet="singleItem; context:{ssr: ssr}"></ng-container>
              </ng-container>
              <ng-container *ngIf="ssr.getLimitPerPassenger(selectedPassenger) > 1">
                <ng-container *ngTemplateOutlet="multipleItems; context:{ssr: ssr}"></ng-container>
              </ng-container>
            </div>
          </div>
        </ng-container>
        <!-- 
          <button type="button" class="btn btn-primary close-popup-bottom" aria-hidden="true">
            {{ 'Confirm and Continue' | translate:'extras' }}
          </button> 
        -->
      </div>
    </div>
  </div>

  <ng-template #singleItem let-ssr="ssr">
    <button type="button" class="pet-number pet-number-single" [ngClass]="{ 'pet-number-selected' : ssr.selectedQuantity > 0 }"
      attr.aria-label="{{'Click to add special assistance' | translate: 'extras'}}" (click)="toggleSsr(ssr)">
      <span class="pet-number-inner">
        <strong *ngIf="ssr.selectedQuantity == 0">{{ 'Add' | translate:'extras' }}</strong>
        <strong *ngIf="ssr.selectedQuantity > 0">{{ 'Remove' | translate:'extras' }}</strong>
        {{ ssr.price | currencyDisplay }}</span>
    </button>
  </ng-template>

  <ng-template #multipleItems let-ssr="ssr">
    <div class="pet-number-container">
      <button type="button" class="pet-number pet-number-zero" attr.aria-label="{{'Click to select special assistance' | translate: 'extras'}}"
        (click)="toggleSsr(ssr)" [ngClass]="{'pet-number-selected': ssr.selectedQuantity == 0}" [disabled]="ssr.paidQuantity > 0">
        <span class="pet-number-inner">
          <strong>0</strong>
        </span>
      </button>
      <button type="button" class="pet-number" attr.aria-label="{{ 'Click to select {0} special assistance items' | translate:'extras':ix}}"
        *ngFor="let ix of ssr.getQuantityArray(selectedPassenger)" (click)="selectSsr(selectedPassenger, ssr.ssrCode, ix)"
        [ngClass]="{'pet-number-selected': ssr.selectedQuantity === ix}" [disabled]="(ix < ssr.paidQuantity) || (ssr.limitPerNest !== null && ix * numberOfSelectedPassengers > ssr.limitPerNest)">
        <span class="pet-number-inner">
          <strong>{{ix}}</strong>
          <ng-container *ngIf="ix <= ssr.paidQuantity">{{ 'PAID'| translate:'extras' }}</ng-container>
        </span>
      </button>
    </div>
  </ng-template>

</check-in-accordion-item>