import { LoadingSpinnerService } from './../common-modules/blue-air-common/loading-spinner.service';
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CheckinSteps, BookingStepsService } from '../core/booking-steps.service';
import { Constants } from '../constants';
import { SessionService } from '../common-modules/blue-air-common/session.service';

export interface ICanDeactivateComponent {
  canDeactivate: (currentRoute?: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateComponentService implements CanDeactivate<ICanDeactivateComponent> {
  constructor(private loadingSpinnerService: LoadingSpinnerService, private sessionService: SessionService,
    private bookingSteps: BookingStepsService) { }

  canDeactivate(component: ICanDeactivateComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    // if session is expired and the next state is 'Search' probably the user pressed 'Start over' from session warning popup
    // the session is expired so let the user go to 'Search' page
    if (this.sessionService.sessionNotification.value.isExpired && 
      this.bookingSteps.extractStep(nextState) === CheckinSteps.search) {
      return true;
    }

    const result = component.canDeactivate ? component.canDeactivate(currentRoute, currentState, nextState) : true;
    if (result === false) {
      this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
    }

    return result;
  }
}
