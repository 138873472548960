import { Component, OnInit, ElementRef, Renderer2 } from '@angular/core';
import { Input, Output } from '@angular/core';
import * as moment from 'moment';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '../translator/translate.service';
import { DateRange } from '../date-range.model';
import { DatePickerRestriction } from './date-picker-restriction.enum';

@Component({
  selector: 'blue-date-picker',
  templateUrl: './blue-date-picker.component.html',
  styleUrls: ['./blue-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting:  BlueDatePickerComponent,
      multi: true
    }
  ]
})

export class BlueDatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() testDate: any;
  @Input() dateRange: DateRange;
  @Input() dateFormat: string;
  @Input() elementName: string;

  @Output()
  get requiredDate() {
    return  '';
  }

  uniqueId: number;
  days: number[];
  months: string[];
  years: number[];
  selectDay: any;
  selectMonth: any;
  selectYear: any;
  defaultStr = 'year';
  defaultStrDay = 'day';
  defaultStrMonth = 'month';
  defaultValue: any;
  isDisabled: boolean;

  private onTouchedCb = () => {};
  private onChangeCb = (_: any) => {};

  restriction: DatePickerRestriction;

  writeValue(obj: any): void {
    if (obj) {
      this.defaultValue = moment(obj,  this.dateFormat);
    }

    if (this.defaultValue && this.defaultValue.isValid()) {
      const setDayRef = () => {};
      const originalSetDay = this.setDate;
      this.setDate = setDayRef;



      this.onChangeDay(+this.defaultValue.format('D'));
      this.onChangeMonth(this.defaultValue.format('MMMM'));
      this.onChangeYear(this.defaultValue.format('YYYY'));
      this.setDate = originalSetDay;
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  constructor(private translator: TranslateService,
    private elementRef: ElementRef,
    private renderer: Renderer2) {
    this.uniqueId = Math.floor(Math.random() * 1000) + 1;
    this.dateFormat = 'D/MM/YYYY';

    this.defaultStr = translator.instant(this.defaultStr, 'datepicker');
    this.defaultStrDay = translator.instant(this.defaultStrDay, 'datepicker');
    this.defaultStrMonth = translator.instant(this.defaultStrMonth, 'datepicker');

    this.selectYear = this.defaultStr;
    this.selectDay = 0;
    this.selectMonth = this.defaultStrMonth;
  }

  ngOnInit() {
  }

  onChangeDay(event) {
   this.selectDay = event;
   this.setDate();
  }
  onChangeMonth(event) {
    this.selectMonth = event;
    this.setDate();
   }
   onChangeYear(event) {
    this.selectYear = event;
    this.setDate();
   }

   private setDate() {
    let isValid = true;


    const day = Number(this.selectDay);
    const year = Number(this.selectYear);

    isValid = Number.isInteger(day) && Number.isInteger(year);

     if (isValid) {
       const dateStr = `${day}/${this.selectMonth}/${year}`;
       this.testDate = moment(dateStr, 'D/MMMM/YYYY');
       isValid = this.testDate.isValid();
     }

     if (isValid) {

       const range = this.dateRange as DateRange;

       if (range.minimumDate && range.minimumDate > this.testDate) {
          this.restriction = DatePickerRestriction.LowerThanLimit;
          this.defaultValue = null;
          this.onChangeCb(null);
       } else if (range.maximumDate && range.maximumDate < this.testDate) {
          this.restriction = DatePickerRestriction.HigherThanLimit;
          this.defaultValue = null;
          this.onChangeCb(null);
       } else {
          this.restriction = null;
          this.defaultValue = this.testDate;
          this.onChangeCb(this.defaultValue.format(this.dateFormat));
       }
     } else {
        this.restriction = null;
       this.defaultValue = null;
       this.onChangeCb(null);
     }
   }
  }
