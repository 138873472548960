import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'check-in-session-in-url',
  templateUrl: './session-in-url.component.html',
  styleUrls: ['./session-in-url.component.scss']
})
export class SessionInUrlComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
