<div class="checkin-meal-options" [(checkInSsrListDisplay)]="isSsrsPopupOpened">
  <check-in-ssr-passenger-picker [passengers]="passengers" [ssrType]="SsrTypeEnum.Meal"
    [allPassengersEnabled]="allPassengersEnabled" [selectedPassenger]="selectedPassenger"
    (selectPassenger)="selectPassenger($event)" (removeSsr)="removeAllSsrs($event)" (resetSsr)="resetSsr()">
  </check-in-ssr-passenger-picker>
  <div class="meal-list js-ssr-list">
    <div class="meal-list-inner">
      <button type="button" class="close-popup" aria-hidden="true">
        <span class="font-ico-close-menu">
          <span class="sr-only">{{ 'Click to close meal overlay' | translate:'extras' }}</span>
        </span>
      </button>
      <h2 aria-hidden="true" class="js-ssr-list-title">{{ 'Pick a meal' | translate:'extras' }}</h2>
      <div *ngIf="mobileMatches">
        <div *ngIf="selectedPassenger == -1 else singlePassengerName">{{ 'All passengers' | translate: 'extras' }}</div>
        <ng-template #singlePassengerName>{{ getSelectedPassengerName() }}</ng-template>
      </div>
      <ng-container *ngFor="let ssr of availableSsrs;">
        <div class="meal-item" *ngIf="ssr.isVisible">
          <div class="meal-image" *ngIf="!ssr.showDetails">
            <span class="meal-label">{{ ssr.price | currencyDisplay }}</span>
            <img [src]="ssr.thumbnailUrl" attr.alt="{{ 'Pick a meal - {0}' | translate:'extras':ssr.name }}" />
          </div>
          <div class="meal-image" *ngIf="ssr.showDetails">
            <div [innerHTML]="ssr.details">
            </div>
          </div>
          <div class="meal-text">
            <!-- <span class="meal-subtitle">Complimentary snack - TODO??</span> -->
            <h3>{{ssr.name}}</h3>
            <a href="#" class="btn btn-secondary blue" (click)="toggleMealDetails($event, ssr)"
              attr.aria-label="{{ 'Click to view details' | translate:'extras' }}">
              <ng-container *ngIf="!ssr.showDetails">
                {{ 'View details' | translate:'extras' }}
              </ng-container>
              <ng-container *ngIf="ssr.showDetails">
                {{ 'Hide details' | translate:'extras' }}
              </ng-container>
            </a>
            <button type="button" class="btn-add" (click)="selectSsr(null, ssr.ssrCode, 1); selectSsrEvent(null)"
              *ngIf="ssr.selectedQuantity == 0"
              [disabled]="ssr.limitPerNest !== null &&  numberOfSelectedPassengers > ssr.limitPerNest"
              attr.aria-label="{{ 'Click to add a meal' | translate:'extras' }}">
              {{ 'Add meal' | translate: 'extras' }}
            </button>
            <button type="button" class="btn-add active paid-meal" *ngIf="ssr.selectedQuantity > 0"
              attr.aria-label="{{ 'Meal added' | translate:'extras' }}">
              {{ 'Added' | translate: 'extras' }}
              <span *ngIf="ssr.paidQuantity == 1"> {{'PAID' | translate:'extras' }}</span>
            </button>
          </div>
        </div>
      </ng-container>
      <button type="button" class="btn btn-primary close-popup-bottom" aria-hidden="true">
        {{ 'Confirm and Continue' | translate:'extras' }}
      </button>
    </div>
  </div>

  <!--
  <check-in-ssr-same-options-for-return-flight [visible]="enableSameOptionsForAllFlights"
    [(isSelected)]="sameOptionsForAllFlights"></check-in-ssr-same-options-for-return-flight>
  -->
<!-- 
  <div class="checkbox-wrap" *ngIf="enableSameOptionsForAllFlights">
    <input type="checkbox" id="return-flight-{{uniqueId}}" [ngModel]="sameOptionsForAllFlights" (click)="checkSameSsrOptionsForAllFlights()"/>
    <label for="return-flight-{{uniqueId}}">{{ 'I choose the same options for return flight' | translate:'extras' }}</label>
  </div>
 -->
</div>