import { Component, OnInit, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { BookingFlowService } from '../../core/booking-flow.service';
import { PaxContactDetailsComponent } from '../pax-contact-details/pax-contact-details.component';
import { FormControl } from '@angular/forms';
import { BookingService } from '../../core/booking.service';
import { BookingSelectionService } from '../../core/booking-selection.service';
import { Constants } from '../../constants';
import { FlightModel } from '../../core/models/flight-model';
import * as  moment from 'moment';
import { DateRange } from '../../common-modules/blue-air-common/date-range.model';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { ResourceService } from '../../common-modules/blue-air-common/resource.service';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { PaxDetailsComponent } from '../pax-details/pax-details.component';
import { CheckinSteps, BookingStepsService } from '../../core/booking-steps.service';
import { SelectionsClearWarningModalComponent } from '../../shared/selections-clear-warning-modal/selections-clear-warning-modal.component';
import { ICanDeactivateComponent } from '../../core/can-deactivate-component.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, RouterState } from '@angular/router';
import { PassengerModel } from '../../core/models/passenger-model';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'check-in-lock-fare-passengers',
  templateUrl: './lock-fare-passengers.component.html',
  styleUrls: ['./lock-fare-passengers.component.scss']
})
export class LockFarePassengersComponent implements OnInit, ICanDeactivateComponent {
  private currentStep: CheckinSteps = CheckinSteps.passengers;

  @ViewChild('samePassengersNameWarningModal', { static: true }) nameModal: DefaultModalComponent;
  @ViewChild(PaxContactDetailsComponent, { static: true }) paxContactDetailsComponent: PaxContactDetailsComponent;
  @ViewChildren(PaxDetailsComponent) paxDetailsComponent: QueryList<PaxDetailsComponent>;
  @ViewChild('changeFlightsWarningModal', { static: true }) clearSelectionsModal: SelectionsClearWarningModalComponent;
  @ViewChild('subscribeTermsModal', { static: true }) subscribeTermsModal: DefaultModalComponent;

  submitted: boolean;
  validForm: boolean;
  _canDeactivate: boolean = false;
  invalidForm = false;
  contact: any = { name: {} };
  public passengers: Array<any> =
  [{ name: {}, info: {}, typeInfo: { paxType: Constants.AdultPaxType }, isReadonly: false }];
  public infants: Array<any> =
  [{ name: {}, info: {}, typeInfo: { paxType: Constants.InfantSsrCode }, isReadonly: false }];
  public flights: FlightModel[] = [];
  scrollClass = 'validation,pattern';
  chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  dateRanges: any = {
    'ADT': [],
    'YTH': [],
    'CLD': [],
    'CHD': [],
    'INF': []
  };

  genderItems: Array<any> = [];
  duplicates: Array<any> = [];
  countries: Array<any> = [];
  titleItems: Array<any> = [];
  allForms: Array<any> = [];

  constructor(private router: Router,
    private bookingSelectionService: BookingSelectionService,
    private bookingFlowService: BookingFlowService,
    private bookingService: BookingService,
    private translateService: TranslateService,
    private resourcesService: ResourceService,
    private bookingSteps: BookingStepsService) {
      this.bookingSelectionService.flightsObs.subscribe(result => {
        this.flights = result;
      });

    bookingSteps.currentStep.next(this.currentStep);

    this.bookingFlowService.loadPriceBreakdown(true);
  }

  ngOnInit() {
    this.bookingFlowService.loadFlowInfo().then(booking => {
      const departureDate = moment(booking.cancelFlight.journeys.items[0].segments.items[0].legs.items[0].std);
      this.contact = booking.contact || { name : {} };
      this.contact.typeCode = 'P';
      this.passengers = booking.passengers.items;
      this.infants = booking.passengers.infants.items;

      this.setPassengersNameByType();

      this.resourcesService.getPassengerTypes().subscribe(p=> {

        const paxTypesAges = p.map(p => ({'typeCode': p.typeCode, 'minAge': p.maximumAge, 'maxAge': p.maximumAge}));

        paxTypesAges.forEach(p => {
          this.dateRanges[p.typeCode] = this.generateDateRangeForPaxType(p.typeCode.substr(0, 3), departureDate);
        });   
        
        if(this.dateRanges["INFANT"])
        this.dateRanges["INF"] = this.dateRanges["INFANT"];
        
      });

      // this.dateRanges = {
      //   'ADT': this.generateDateRangeForPaxType(Constants.AdultPaxType, departureDate),
      //   'YTH': this.generateDateRangeForPaxType(Constants.YoungAdultPaxType, departureDate),
      //   'CLD': this.generateDateRangeForPaxType(Constants.TeenPaxType, departureDate),
      //   'CHD': this.generateDateRangeForPaxType(Constants.ChildrenPaxType, departureDate),
      //   'INF': this.generateDateRangeForPaxType(Constants.InfantSsrCode, departureDate)
      // };
      this.setResourceItems();
    });
  }

  setPassengersNameByType() {
    this.bookingSelectionService.passengersObs.pipe(filter(pax => pax.length > 0), take(1)).subscribe(passengersByType => {
      if (passengersByType.length > 0) {
        this.passengers.forEach(p => {
          const name = passengersByType.filter(pax => pax.passengerNumber === p.passengerNumber)
          .map( p => p.name.first + ' ' + p.name.last);
          if (!PassengerModel.checkNameFormat(name[0])) {
            p.displayName = name;
          } else {
            p.displayName = passengersByType.filter(pax => pax.passengerNumber === p.passengerNumber)
            .map( p => p.defaultName);
          }
          if (PassengerModel.checkNameFormat(p.name.first)) {
            p.name.first = '';
          }
          if (PassengerModel.checkNameFormat(p.name.last)) {
            p.name.last = '';
          }
          const paxGender = passengersByType.filter(pax => pax.passengerNumber === p.passengerNumber)
          .map( p => p.documents.gender).toString();
          p.info.gender = paxGender === 'Male' ? 1 : paxGender === 'Female' ? 2 : 0;

          if (p.typeInfo.paxType !== Constants.AdultPaxType) {
            p.name.title = Constants.ChildrenPaxType;
          }

          if (p.hasInfant) {
            const currentInfant = this.infants[p.passengerNumber];
            currentInfant.attachedPassengerNumber = p.passengerNumber;
            currentInfant.displayName = !currentInfant.name.first && !currentInfant.name.last ?
              this.translateService.instant('INF-title', 'price-display') + ' ' + (p.passengerNumber + 1) :
              currentInfant.name.first + ' ' + currentInfant.name.last;

              if (PassengerModel.checkNameFormat(currentInfant.displayName)) {
                currentInfant.displayName = this.translateService.instant('INF-title', 'price-display') + ' ' + (p.passengerNumber + 1);
              }
              if (PassengerModel.checkNameFormat(currentInfant.name.first)) {
                currentInfant.name.first = '';
              }
              if (PassengerModel.checkNameFormat(currentInfant.name.last)) {
                currentInfant.name.last = '';
              }

            currentInfant.name.title = Constants.ChildrenPaxType;
          }
        });
      }
    });
  }

  private generateDateRangeForPaxType(paxType, departureDate) {
    let minAge: number;
    let maxAge: number;
    const dateRange = new DateRange(departureDate.clone());
    // to do -> refactor this and this.getPassengerTypes()
    this.resourcesService.getPassengerTypes().subscribe((paxTypes: any[]) => {
      const currentPaxType = paxTypes.find(pt => pt.typeCode === paxType);
      if (currentPaxType || paxType === Constants.InfantSsrCode) {
        switch (paxType) {
          case Constants.AdultPaxType:
            minAge = 18;
            maxAge = 100;
            break;
          case Constants.ChildrenPaxType:
            minAge = 2;
            maxAge = 12;
            break;
          case Constants.InfantSsrCode:
            minAge = 0;
            maxAge = 2;
            break;
          case Constants.TeenPaxType:
          case Constants.YoungAdultPaxType:
            minAge = currentPaxType.minimumAge;
            maxAge = currentPaxType.maximumAge;
            break;
        }

        dateRange.generateYearsForAge(minAge + 1, maxAge + 1);
        dateRange.minimumDate = departureDate.clone().add(-maxAge, 'y');
        dateRange.maximumDate = departureDate.clone().add(-minAge, 'y');
        return dateRange;
      }
    });

    return dateRange;
  }

  private setResourceItems() {
    this.genderItems = [
      {
        'text': this.translateService.instant('Male', 'documents'),
        'value': 1
      },
      {
        'text': this.translateService.instant('Female', 'documents'),
        'value': 2
      }
    ];

    this.resourcesService.getCountries().subscribe(countries => this.countries = countries);

    this.resourcesService.getPassengerTitles().subscribe(titles => {
      this.titleItems = [
        {
          'text': this.translateService.instant('Please select'),
          'value': ''
        },
        ...titles
      ];
    });
  }

  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    let checkinStep = this.bookingSteps.extractStep(nextState);
    let redirectingToOtherBookingStep = true;

    if (checkinStep === null) {
      redirectingToOtherBookingStep = false;
      checkinStep = CheckinSteps.search;
    }

    if (this.clearSelectionsModal.shouldShowWarning(this.currentStep, checkinStep)) {
      return this.clearSelectionsModal.showWarningAndNavigate(
        this.currentStep,
        redirectingToOtherBookingStep ? checkinStep : null,
        nextState.url);
    }

    if (!this._canDeactivate) {
      this.goToNextStep();
    }

    return this._canDeactivate;
  }

  scrollTo(elements: string): void {
    const items = elements.split(',');
    const firstElementList = document.querySelectorAll('.' + items[0]);
    const secondElementList = document.querySelectorAll('.' + items[1]);
    let item;
    if (firstElementList.length > 0) {
      item = firstElementList[0] as HTMLElement;
    } else
      if (secondElementList.length > 0)
        item = secondElementList[0] as HTMLElement;

    if (secondElementList.length > 0 && firstElementList.length > 0) {
      if ((firstElementList[0] as any).offsetTop > (secondElementList[0] as any).offsetTop)
        item = secondElementList[0] as HTMLElement;
    }
    if (item)
      item.scrollIntoView({ behavior: 'smooth' });
  }

  private checkFormValidation(forms: any): boolean {
    let isValid = true;
    forms.forEach(f => {
      isValid = isValid && f.valid;
      for (const key in f.form.controls) {
        if (f.form.controls.hasOwnProperty(key)) {
          const element = f.form.controls[key];
          if (element instanceof FormControl) {
            (element as FormControl).markAsDirty({ onlySelf: true });
          }
        }
      }
    });
    return isValid;
  }

  goToNextStep() {
    this.passengers.forEach(pax => {
      if ((pax.name.first || pax.name.last)
      && !PassengerModel.checkNameFormat(pax.name.first) && !PassengerModel.checkNameFormat(pax.name.last)) {
        pax.isLockedFare = false;
      } else {
        pax.isLockedFare = true;
      }
    });
    this.infants.forEach(inf => {
      if ((inf.name.first || inf.name.last) && !PassengerModel.checkNameFormat(inf.name.first)
      && !PassengerModel.checkNameFormat(inf.name.last)) {
        inf.isLockedFare = false;
      } else {
        inf.isLockedFare = true;
      }
    });

    this.allForms = [this.paxContactDetailsComponent.form, ...this.paxDetailsComponent
      .filter(x => !x.pax.isLockedFare).map(x => x.form)];
    const contactValidation = this.checkFormValidation([this.paxContactDetailsComponent.form]);
    let isValid = this.checkFormValidation(this.allForms);

    const validPaxs = this.passengers.find(p => p.isLockedFare === false);
    const validInfants = this.infants.find(p => p.isLockedFare === false);
    if (validPaxs || validInfants) {
      isValid = isValid && true;
    } else {
      isValid = contactValidation && true;
    }

    this.invalidForm = !isValid;
    if (isValid) {
      const confirmPassengerDetails = (isOkCallback: boolean): void => {
        if (isOkCallback) {
          if (this.contact.subscribedToNewsletter && !this.contact.agreeTermsAndCond) {
            this.subscribeTermsModal.openPopup((dismissed) => {
              this.contact.agreeTermsAndCond = dismissed === false;
              this.saveAndContinue();
            });
          } else {
             this.saveAndContinue();
          }
        }
      };
     this.duplicates = new Array();
      this.passengers.forEach((p, i) => {
        const duplicate = this.passengers.find((pax, index) => pax.name.first && pax.name.last && pax.name.title === p.name.title &&
        pax.name.first === p.name.first && pax.name.last === p.name.last && i !== index);
        if (duplicate && !this.duplicates.some(x =>
           x.name.first === duplicate.name.first
          && x.name.last === duplicate.name.last
          && x.name.title === duplicate.name.title )) {
            this.duplicates.push(duplicate);
        }
      });
      if (this.duplicates.length > 0) {
        this.nameModal.openPopup((isOk) => {
          this.scrollTo('pax-container-' + this.duplicates[0].passengerNumber);
        });
      } else {
        confirmPassengerDetails(true);
      }
    }
    setTimeout(() => {
      this.scrollTo(this.scrollClass);
    }, 20);
  }

  private saveAndContinue() {
    this._canDeactivate = true;
    Promise.all([
      this.bookingService.saveLockFareData(this.passengers, this.infants, this.contact, this.titleItems)
    ]).then(() => Promise.all([
      this.contact.subscribedToNewsletter && this.contact.agreeTermsAndCond && this.bookingService.subscribeToNewsletter(),
      this.bookingFlowService.loadFlowInfo(),
      this.bookingFlowService.loadPriceBreakdown(true)
    ])).then(() => this.router.navigate(['summary', 'lockfare']));
  }
}
