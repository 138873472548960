export class ShoppingCartFeeModel {
    amount: number;
    feeCode: string;
    discountCode: string;
    items: ShoppingCartFeeModel[];

    init(fee: any): ShoppingCartFeeModel {
        this.amount = fee.amount;
        this.feeCode = fee.feeCode;
        this.discountCode = fee.discountCode ? fee.discountCode : null;
        this.items = fee.items && fee.items.length > 1 ? fee.items.map(feeItem => new ShoppingCartFeeModel().init(feeItem)) : [];
        return this;
    }
}
