import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { DurationDisplayPipe } from './duration-display.pipe';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  constructor(private translateService: TranslateService) { }

  transform(value: Date, args?: Date, showIso?: boolean): string {
    const momentDuration = moment.duration(moment(args).diff(moment(value)));

    if (showIso) {
      return momentDuration.toISOString();
    }
    const pipe = new DurationDisplayPipe(this.translateService);
    return pipe.transform(momentDuration);
  }
}
