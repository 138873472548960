/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./layout.component";
var styles_LayoutComponent = [i0.styles];
var RenderType_LayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LayoutComponent, data: {} });
export { RenderType_LayoutComponent as RenderType_LayoutComponent };
function View_LayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "link-plus blue"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addItemBtnClick.next($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.addItemBtnText; _ck(_v, 1, 0, currVal_0); }); }
export function View_LayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "section grey-light-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [["class", "settings-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "left-col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "desktop-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LayoutComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "right-col"]], null, null, null, null, null)), i1.ɵncd(null, 0), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.addItemBtnText; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 6, 0, currVal_0); }); }
export function View_LayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-layout", [], null, null, null, View_LayoutComponent_0, RenderType_LayoutComponent)), i1.ɵdid(1, 114688, null, 0, i3.LayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LayoutComponentNgFactory = i1.ɵccf("profile-layout", i3.LayoutComponent, View_LayoutComponent_Host_0, { title: "title", addItemBtnText: "addItemBtnText" }, { addItemBtnClick: "addItemBtnClick" }, [".profile-layout-body", ".profile-layout-other-rows"]);
export { LayoutComponentNgFactory as LayoutComponentNgFactory };
