import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';

@Component({
  selector: 'check-in-ssr-box-content-special-equipment',
  templateUrl: './ssr-box-content-special-equipment.component.html',
  styleUrls: ['./ssr-box-content-special-equipment.component.scss']
})
export class SsrBoxContentSpecialEquipmentComponent extends SsrBoxContentBase {
  ssrsCustomIcons: {[ssrCode: string]: string} = {};
  uniqueId: number;

  @Input() isUnavailableDueToPreviouslyCheckedIn: boolean;
  @Output() selectSsrEvent2 = new EventEmitter();

  constructor(private ssrsService: SsrsService) {
    super(ssrsService);

    this.ssrType = SsrType.SpecialEquipment;
    this.uniqueId = Math.floor(Math.random() * 1000) + 1;
  }

  protected initFlightSsrList() {
    super.initFlightSsrList();

    this.checkPassengerLiftStatus();
    this.setupCustomIcons();
  }

  private setupCustomIcons() {
    this.availableSsrs.forEach(ssr => {
      let icon: string;

      switch(ssr.ssrCode) {
        case 'BIKE':
        case 'SKI':
        case 'WEAP':
          icon = `font-ico-sport-eq-${ssr.ssrCode.toLowerCase()}`;
          break;
        default:
          icon = 'font-ico-sport-eq';
          break;
      }

      this.ssrsCustomIcons[ssr.ssrCode] = icon;
    });
  }

  resetSsr() {
    this.selectSsr(null, "WEAP", 0); 
    this.selectSsrEvent(null);
  }

  selectSsrEvent(passengerIndex: number = null) {
    this.selectSsrEvent2.emit(passengerIndex);
  }  

  checkSameSsrOptionsForAllFlights() {
    this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
    this.selectSsrEvent(null);
  }  
  
  checkPassengerLiftStatus() {
    const weaponSsrIndex = this.availableSsrs.findIndex(s => s.name === "WEAP");

    if (this.isUnavailableDueToPreviouslyCheckedIn) {
      this.availableSsrs.splice(weaponSsrIndex, 1);
    }    
  }
}
