import { Constants } from "../../constants";
import { Cell, RowCells, RowGroup } from "./row-cell-model";
import { Unit } from "./seat-models";

export default class UnitRow {
  y: number;
  units: Array<Unit> = new Array<Unit>();
  rowCells: RowCells = new RowCells();

  get cellGroups(): RowGroup[] {
    return this.rowCells.groups;
  };
  hasDecoratorStyle: boolean = false;
  /**
   *
   */
  constructor(unit: Unit, cell?: Cell | undefined) {
    this.y = unit.y;
    this.addCell(cell, unit);
    this.units.push(unit);
  }

  addCell(cell: Cell | undefined, unit: Unit): void {
    this.rowCells.addCell(cell, unit);
  }

  fillDecorator(seatProperties, soldSeats) {
    this.units.forEach((u: Unit) => {
      let decorator = "";

      if (u.seatProperties)
        u.seatProperties.forEach(p => {
          decorator = this.checkCellDecorator(decorator, seatProperties[p]);
        });

      if (!u.assignable) {
        u.baseDecorator = "legend-not-available";
      }

      // if (u.unitAvailability == "ReservedForPNR" || u.unitAvailability =="HeldForThisSession")
      //   u.decorator = "legend-selected";
      if (decorator == Constants.seatTypeLEGROOM) {
        u.baseDecorator = "legend-leg-room";
        u.mainColorClass = "legend-leg-room-color";
      }
      else if (decorator == "FRONT") {
        u.baseDecorator = "legend-front";
        u.mainColorClass = "legend-front-color";
      }
      else if (decorator == "REAR") {
        u.baseDecorator = "legend-back";
        u.mainColorClass = "legend-back-color";
      }
      if (u.unitAvailability == "Reserved") {
        u.baseDecorator = "legend-not-available";
      }
      if (u.unitAvailability != "Open"
        && u.unitAvailability != "ReservedForPNR"
        && u.unitAvailability != "HeldForThisSession") {
        //if it is restricted for all passengers

        if (u.unitAvailabilityByPax && u.unitAvailabilityByPax.every(x => x != "Open")) {
          u.baseDecorator = "legend-not-available";
        }
        else {
          u.masterDecorator = u.baseDecorator;
          u.baseDecorator = "";
        }

      }

      u.decorator = u.baseDecorator;
      // if(soldSeats.findIndex(p=>p.unitDesignator == u.unitDesignator )>=0){
      //   u.decorator ="legend-selected";
      // }

    });

  }

  checkCellDecorator(_old, _new) {
    if (_old && _old.length == 0)
      return _new.typeCode;

    if (_old == Constants.seatTypeLEGROOM)
      return _old;
    if (_new.typeCode == Constants.seatTypeLEGROOM)
      return _new.typeCode;
    if (_new.typeCode == Constants.seatTypeLOCATION)
      return _new.subProperty.typeCode;

    return _old;

  }

  fillSlot(index, indexes: Array<number>, maxPerRow = 0) {

    let a = this.units[0].unitDesignator.split('');
    a.pop();
    let rowNumber = a.join("");

    indexes.forEach(i => {
      if (this.units.findIndex(p => p.x == i) < 0) {
        {
          this.units.splice(indexes.indexOf(i), 0, new Unit(' '));
        }
      }
    });
    this.units.splice(index + 1, 0, new Unit(a.join("")));


  }


  orderUnits() {
    this.units =
      this.units.sort((a, b) => {
        if (a.x < b.x) {
          return -1;
        } else if (a.x > b.x) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  reArrangeunits(index) {
    let numberUnit = this.units.find(p => p.x < 0);
    if (numberUnit) {
      let oldIndexOnunit = this.units.findIndex(p => p === numberUnit);
      this.units.splice(oldIndexOnunit, 1);
    } else {
      numberUnit = new Unit();
    }

    this.units.splice(index, 0, numberUnit);
  }
}