
export interface AeroInvoiceCountry {
  code: string;
  name: string;
  // group: string;
}

// export interface InvoiceCountyRegion {
//   countryCode: string;
//   code: string;
//   name: string;
//   type: string;
// }

export class AeroInvoicingData {
  CustomerType: string;
  CompanyName: string;
  FirstName: string;
  LastName: string;
  CountryCode: string;
  FiscalCode: string;
  Vat: string;
  Region: string;
  HeadquarterAddress: string;
  AddressNumber: string;
  HeadquarterCity: string;
  RecipientCode: string;
  EmailPec: string;

  constructor(otherObj?: Partial<AeroInvoicingData>) {
    if (otherObj) {
      Object.assign(this, otherObj);
    }
  }
}

export interface AeroInvoicingDataValidation {
  formValid: boolean;
  dataValid: boolean;
  errors: { [key: string]: string };
  invoiceData: AeroInvoicingData;
}

export interface IKeyValuePair<T, K> {
  text: T,
  value: K
}

export interface AeroInvoiceCountyRegion {
  countryCode?: string;
  code: string;
  name: string;
}


export const enum AeroInvoiceFieldNames {
  CustomerType = "customerType",
  CompanyName = "companyName",
  CountryCode = "countryCode",
  FiscalCode = "fiscalCode",
  FirstName = "firstName",
  LastName = "lastName",
  Region = "region",
  Vat = "vat",
  AddressNumber = "addressNumber",
  HeadquarterAddress = "headquarterAddress",
  HeadquarterZipCode = "headquarterZipCode",
  HeadquarterCity = "headquarterCity",
  RecipientCode = "recipientCode",
  EmailPec = "emailPec"
}

export const enum AeroCustomerTypes {
  ItCompany = "PGIT",
  ItIndividualPerson = "PFIT",
  NonItCustomer = "NONIT"
}


