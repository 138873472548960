import { ShoppingCartPassengerModel } from './shopping-cart-passenger-model';
import * as moment from 'moment';
var ShoppingCartFlightModel = /** @class */ (function () {
    function ShoppingCartFlightModel() {
        this.segments = [];
        this.passengers = [];
    }
    Object.defineProperty(ShoppingCartFlightModel.prototype, "amount", {
        get: function () { return this._amount; },
        set: function (value) { this._amount = Math.max(value, 0); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartFlightModel.prototype, "passengers", {
        get: function () {
            return this.segments.length ? this.segments[0].passengers : this._passengers;
        },
        set: function (value) {
            if (this.segments.length) {
                this.segments[0].passengers = value;
            }
            else {
                this._passengers = value;
            }
        },
        enumerable: true,
        configurable: true
    });
    ShoppingCartFlightModel.prototype.init = function (journeyOrSegment, journeyOrSegmentIndex) {
        this.amount = journeyOrSegment.amount;
        this.selectedBundleCode = journeyOrSegment.bundleCode;
        this.segments = journeyOrSegment.segments && journeyOrSegment.segments.length ?
            journeyOrSegment.segments.map(function (segment, segmentIndex) { return new ShoppingCartFlightModel().init(segment, segmentIndex); }) : [];
        if (this.segments.length) {
            this.departureStation = this.segments[0].departureStation;
            this.arrivalStation = this.segments[this.segments.length - 1].arrivalStation;
            this.departureDate = this.segments[0].departureDate;
            this.arrivalDate = this.segments[this.segments.length - 1].arrivalDate;
            this.departureDateUtc = this.segments[0].departureDateUtc;
            this.arrivalDateUtc = this.segments[this.segments.length - 1].arrivalDateUtc;
        }
        else {
            this.departureStation = journeyOrSegment.departureStation;
            this.arrivalStation = journeyOrSegment.arrivalStation;
            this.departureDate = moment(journeyOrSegment.departureDate, "YYYY-MM-DD HH:mm:ss");
            this.arrivalDate = moment(journeyOrSegment.arrivalDate, "YYYY-MM-DD HH:mm:ss");
            this.departureDateUtc = moment(journeyOrSegment.departureDateUTC, "YYYY-MM-DD HH:mm:ss");
            this.arrivalDateUtc = moment(journeyOrSegment.arrivalDateUTC, "YYYY-MM-DD HH:mm:ss");
            this.flightNumber = journeyOrSegment.flightNumber;
            this.operatingCarrier = journeyOrSegment.operatingCarrier;
            this._passengers = journeyOrSegment.passengers.map(function (p) { return new ShoppingCartPassengerModel().init(p); });
        }
        return this;
    };
    ShoppingCartFlightModel.prototype.initFromSegments = function (segments, amount, bundleCode) {
        this.segments = segments;
        this.departureStation = this.segments[0].departureStation;
        this.arrivalStation = this.segments[this.segments.length - 1].arrivalStation;
        this.departureDate = this.segments[0].departureDate;
        this.arrivalDate = this.segments[this.segments.length - 1].arrivalDate;
        this.departureDateUtc = this.segments[0].departureDateUtc;
        this.arrivalDateUtc = this.segments[this.segments.length - 1].arrivalDateUtc;
        this.amount = amount;
        this.selectedBundleCode = bundleCode;
        return this;
    };
    return ShoppingCartFlightModel;
}());
export { ShoppingCartFlightModel };
