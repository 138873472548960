import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from '../common-modules/blue-air-common/session.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { UserStateService } from '../common-modules/blue-air-common/user-state.service';
import { BookingFlowService } from '../core/booking-flow.service';
import { BookingService } from '../core/booking.service';
import { ConfigService } from '../core/config.service';
import * as moment from 'moment';
import { ChangeFlightModel } from '../itinerary/booking-info/booking-info.component';
import { DefaultModalComponent } from '../shared/default-modal/default-modal.component';

declare global {
  interface Window { commonFunctions: any; }
}

@Component({
  selector: 'check-in-booking-consent',
  templateUrl: './booking-consent.component.html',
  styleUrls: ['./booking-consent.component.scss']
})

export class BookingConsentComponent implements OnInit {
  recordLocator: string = "";
  emailAddress: string = "";
  lastName: string = "";
  dynamicUrl: string = "";
  showConsentOptions: boolean = true;
  isConsentYes: boolean = false;
  isConsentNo: boolean = false;
  isButtonSelected: boolean = false;
  isRefundToCash: boolean = false;
  isPassengerEligibleToSubmit: boolean = false;
  isDirectFlight: boolean = false;
  isIndirectFlight: boolean = false;
  isCommercialFlight: boolean = false;
  disableRefund: boolean = false;
  showErrorMessage: boolean = false;
  hasCanceledFlights: boolean = false;
  hasTimeChangedFlights: boolean = false;
  hasInsurance: boolean = false;

  missingParams: boolean = false;
  bookingNotFound: boolean = false;
  memberIsLoggedIn: boolean = false;
  memberNotLoggedIn: boolean = false;
  wrongMemberRetrieve: boolean = false;
  isLoading: boolean = true;
  journeys: Array<any> = [];
  flightsToChange: Array<any> = [];
  ignoreConnectionErrors: boolean;
  changeFlight: ChangeFlightModel = new ChangeFlightModel();
  passengers: Array<any> = [];
  changeDateActive: boolean = false;
  moveFlight: boolean = false;
  modalId: string = 'refundModal';
  queuesEligibleToSubmit: string[] = ['CASREF', 'CXLSM', 'CXLSW', 'RMO3AC', 'RMO3SM', 'RMO3SW', 'SCO3AC', 'SCO3SM', 'SCO3SW'];
  hasInsuranceFee: boolean = false;
  hasInsuranceQueueCode: boolean = false;
  insuranceModalId = 'insuranceModal';

  @ViewChild('loginModalId', { static: true }) loginModalId: ElementRef<HTMLElement>;
  @ViewChild('refundModal', { static: true }) refundModal: DefaultModalComponent;
  @ViewChild('insuranceModal', { static: true }) insuranceModal: DefaultModalComponent;

  constructor(private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private userStateService: UserStateService,
    private bookingService: BookingService,
    private bookingFlowService: BookingFlowService,
    private sessionService: SessionService,
    private configService: ConfigService) {
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (Object.getOwnPropertyNames(params).length !== 0) {
        this.recordLocator = params['rl'];
        this.emailAddress = params['em'];
        this.lastName = params['ln'];
      }
    });

    this.getBooking();
  }

  ngOnDestroy() {
    localStorage.removeItem("pageState");
  }

  getBooking() {
    if (!(this.recordLocator && (this.emailAddress || this.lastName))) {
      this.missingParams = true;
      return;
    }

    this.sessionService.ensureSessionIsValid()
    .then(() => this.bookingService.retrieveBooking(this.recordLocator, this.lastName, this.emailAddress, this.configService.BlueRetrieve))
    .then(retrievedBooking => {
      if (retrievedBooking) {
        this.memberNotLoggedIn = false;
        this.memberIsLoggedIn = retrievedBooking.bookingDetail.pointOfSale.organizationCode == "BAMEMBERS" ? this.userStateService.isLoggedIn.getValue() : true;

        if (!this.memberIsLoggedIn) {
          let openLoginModal: HTMLElement = this.loginModalId.nativeElement;
          openLoginModal.click();
        }
      }
      else {
        this.bookingNotFound = true;
      }
    },
    e => {
      const data = e.error.errors;

      if (data && data.find(i => i.errorCode === ErrorType[ErrorType.memberNotLoggedIn])) {
        this.memberNotLoggedIn = true;

        if (!this.memberIsLoggedIn) {
          let openLoginModal: HTMLElement = this.loginModalId.nativeElement;
          openLoginModal.click();
        }
      }
      else if (data && data.find(i => i.errorCode === ErrorType[ErrorType.ValidateNullBooking])){
        this.bookingNotFound = true;
        this.showErrorMessage = true;
      }
      else if (data && data.find(i => i.errorCode === ErrorType[ErrorType.wrongMemberRetrieve])){
        this.wrongMemberRetrieve = true;
        this.showErrorMessage = true;
      }
    })
    .then(() => {
      this.bookingFlowService.loadFlowInfo(true)
      .then(booking => {
        if (booking) {
          if (booking.bookingQueueInfos && booking.bookingQueueInfos.items && booking.bookingQueueInfos.items.length > 0) {
            this.isPassengerEligibleToSubmit = !booking.bookingQueueInfos.items.some(q => this.queuesEligibleToSubmit.includes(q.queueCode));
          }

          if (booking.convertedJourneys && booking.convertedJourneys.journeys && booking.convertedJourneys.journeys.length > 0) {
            this.journeys = booking.convertedJourneys.journeys;
            this.checkFlightType();
            this.disableRefund = this.checkLiftStatus();

            this.hasTimeChangedFlights = this.journeys.some(j => j.segments && j.segments.some(s => s.paxSegments && s.paxSegments[0].timeChanged == true));
            this.hasCanceledFlights = this.journeys.some(j => j.segments && j.segments.some(s => s.legs && s.legs.some(l => l.legInfo.status === 'Canceled')));

            if (this.hasCanceledFlights) {
              this.journeys.forEach(j => {
                j.hasCancelLeg = j.segments && j.segments.some(s => s.legs && s.legs.some(l => l.legInfo.status === 'Canceled'));
              })
            }

            if (booking.passengers && booking.passengers.items && booking.passengers.items.length > 0) {
              this.passengers = booking.passengers.items;
            }

            this.hasInsurance = this.journeys.some(j => j.segments.some(s => s.paxSSRs.find(ssr => ssr.sSRCode.match("INS1"))));
            
            if (booking.passengers && booking.passengers.items && booking.passengers.items.length > 0) {
              this.hasInsuranceFee = this.passengers.some(p => p.fees && p.fees.items.some(f => f.code == "XCOVT1"));
            }

            if (booking.bookingQueueInfos && booking.bookingQueueInfos.items && booking.bookingQueueInfos.items.length > 0) {
              this.hasInsuranceQueueCode = booking.bookingQueueInfos.items.some(q => q.queueCode == "INS");
            }

            if (this.checkPastFlights()) {
              this.showErrorMessage = true;

              this.bookingService.addComment(this.recordLocator,'Error: Flights in the past',this.emailAddress,true);
            }

            this.showErrorMessage = !this.hasEligibleFlightsToSubmitPNR() || this.checkLiftStatus();
          }
        }
      })
      .then(() => {
        if (!localStorage.getItem("pageState")) {
          localStorage.setItem("pageState", "reloaded");
          window.location.reload();
        }
      });
    });
  }

  checkConsent(buttonChosen: boolean) {
    if (buttonChosen) {
      this.showConsentOptions = false;
      this.isConsentYes = true;
      this.isConsentNo = false;
      this.isButtonSelected = false;
      this.bookingService.setPassengerConsent(this.emailAddress);
    }
    else if(!buttonChosen && !this.disableRefund){
      this.showConsentOptions = false;
      this.isConsentYes = false;
      this.isConsentNo = true;
      this.isButtonSelected = true;
    }
  }

  enableConsentOptions() {
    this.showConsentOptions = true;
    this.isConsentYes = false;
    this.isConsentNo = false;
    this.isButtonSelected = false;
  }

  goToCustomerSupportCenter() {
    this.dynamicUrl = "https://help.flyblueair.com" + /* this.translateService.culture.replace("-", "/").toLocaleLowerCase() + */ "/hc/en-us";
  }

  manageMyBooking() {
    if(this.disableRefund) return;

    window.location.href = window.location.origin + "/" + this.translateService.culture.replace("-", "/").toLocaleLowerCase() +
    "/booking-engine/booking-status" + "?rl=" + this.recordLocator + "&em=" + this.emailAddress + "&ln=" + this.lastName;
  }

  whereToRefund() {
    this.refundModal.openPopup(this.modalId);
  }

  openLoginPopup() {
    window.commonFunctions.openLoginPopup();
  }

  refundToWallet() {
    this.refundModal.cancel();

    if (this.hasInsuranceFee || this.hasInsuranceQueueCode) {
      this.insuranceModal.ok();
      this.insuranceModal.openPopup(this.insuranceModalId);
    }
    else {
      this.refundToWalletProceed();
    }
  }

  refundToWalletProceed() {
    this.bookingFlowService.loadFlowInfo(true).then(booking => {
      if (booking) {
        this.bookingService.recordLocator = this.recordLocator;

        window.location.href = window.location.origin + "/" + this.translateService.culture.replace("-", "/").toLocaleLowerCase() + "/booking-engine/ptw?step=1";
      }
    });
  }

  async refundToCash() {
    var result = await this.bookingService.refundCashBooking(this.emailAddress);

    if (result.customerRefundCashRequest.errors.length > 0) {
      this.bookingService.addComment(this.recordLocator,'Error: ' + result.customerRefundCashRequest.errors[0],this.emailAddress,true);

      this.refundModal.cancel();
      this.showErrorMessage = true;
    }
    else {
      this.isRefundToCash = true;
      this.refundModal.cancel();
    }
  }

  getStation(index, journey) {
    if (journey) {
      if (index == 0)
        return journey.segments[0].departureStation;

      if (journey.segments.length == 1)
        return journey.segments[0].arrivalStation;

      return journey.segments[journey.segments.length - 1].arrivalStation;
    }
  }

  formatDate(date, format) {
    return moment(date).format(format);
  }

  subtractTimezoneOffset(date: moment.MomentInput, offset: string | number) {
    return moment(date).utcOffset(offset, true);
  }

  calculateWaitingTimeBetweenFLights(stopStationArrivalDate, stopStationDepartureDate) {
    let intermediateTime = new Date(stopStationDepartureDate).valueOf() - (new Date(stopStationArrivalDate)).valueOf();
    let days = Math.floor(intermediateTime / (60 * 60 * 24 * 1000));
    let hours = Math.floor(intermediateTime / (60 * 60 * 1000)) - (days * 24);
    let minutes = Math.floor(intermediateTime / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    let seconds = Math.floor(intermediateTime / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));

    let waitingTime = "";

    if (days) {
      waitingTime = waitingTime + days + " DAYS ";
    }

    if (days && hours) {
      waitingTime = waitingTime + " and ";
    }

    if (hours) {
      waitingTime = waitingTime + hours + " HOURS ";
    }

    if (hours && minutes) {
      waitingTime = waitingTime + " and ";
    }

    if (minutes) {
      waitingTime = waitingTime + minutes + " MINUTES ";
    }

    if (minutes && seconds) {
      waitingTime = waitingTime + " and ";
    }

    if (seconds) {
      waitingTime = waitingTime + seconds + " SECONDS ";
    }

    waitingTime = waitingTime + "connection";

    return waitingTime;
  }

  checkLiftStatus(): boolean {
    return this.journeys.some(j => j.segments.some(s => s.paxSegments.some(ps => ps.liftStatus != "Default")));
  }

  checkPastFlights(): boolean {
    var nowMoment = moment().utc();

    var check = this.journeys.some(function(j) {
      var isBefore = j.segments.some(function(s) {
        var std = moment(s.sTD)
        return std.isBefore(nowMoment)
      })

      return isBefore
    });

    return check;
  }

  isNextDay(departureDate: any, arrivalDate: any) {
    if (moment(departureDate).date() !== moment(arrivalDate).date()) {
      return true;
    }
  }

  hasEligibleFlightsToSubmitPNR() {
    let checker = false;

    if (this.hasTimeChangedFlights || this.hasCanceledFlights) {
      checker = true;
    }

    return checker;
  }

  checkFlightType() {
    if (this.journeys.some(j => j.segments && j.segments.some(s => s.legs && s.legs.length >= 2))) {
      this.isCommercialFlight = true;
    }
    else if (this.journeys.some(j => j.segments && j.segments.length >= 2)) {
      this.isIndirectFlight = true;
    }
    else {
      this.isDirectFlight = true;
    }
  }
}

export enum ErrorType {
  mandatoryFields = 1,
  bookingNotFound = 2,
  pendingPayments = 3,
  checkinClosed = 4,
  checkinNotOpen = 5,
  memberNotLoggedIn = 6,
  ValidateNullBooking = 7,
  wrongMemberRetrieve = 8
}

export interface CashRefundResponse {
  customerRefundCashRequest: any;
  emailAddress: string,
  errors: string[]
}
