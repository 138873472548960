/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./selections-clear-warning-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "../default-modal/default-modal.component.ngfactory";
import * as i5 from "../default-modal/default-modal.component";
import * as i6 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i7 from "../../extras/ssrs.service";
import * as i8 from "./selections-clear-warning-modal.component";
import * as i9 from "../../core/booking-steps.service";
import * as i10 from "../e-commerce/ecommerce.service";
import * as i11 from "@angular/router";
import * as i12 from "../../core/profile.service";
import * as i13 from "../../common-modules/blue-air-common/session.service";
var styles_SelectionsClearWarningModalComponent = [i0.styles];
var RenderType_SelectionsClearWarningModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SelectionsClearWarningModalComponent, data: {} });
export { RenderType_SelectionsClearWarningModalComponent as RenderType_SelectionsClearWarningModalComponent };
export function View_SelectionsClearWarningModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), i1.ɵqud(402653184, 1, { modal: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 5, "check-in-default-modal", [["modalId", "changeFlightsModalId"]], null, null, null, i4.View_DefaultModalComponent_0, i4.RenderType_DefaultModalComponent)), i1.ɵdid(3, 114688, [[1, 4], ["changeFlightsWarningModal", 4]], 0, i5.DefaultModalComponent, [i6.BlueModalService, i7.SsrsService], { modalId: [0, "modalId"], modalTitle: [1, "modalTitle"], modalText: [2, "modalText"], cancelButtonText: [3, "cancelButtonText"], okButtonText: [4, "okButtonText"], okRequired: [5, "okRequired"], cancelRequired: [6, "cancelRequired"] }, null), i1.ɵppd(4, 2), i1.ɵppd(5, 2), i1.ɵppd(6, 2), i1.ɵppd(7, 2)], function (_ck, _v) { var currVal_0 = "changeFlightsModalId"; var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 1, _ck(_v, 4, 0, i1.ɵnov(_v, 0), "You will lose your selections", "extras")), ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 2, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "Are you sure you want to continue? You will lose all previous selections!", "extras")), ""); var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 3, _ck(_v, 6, 0, i1.ɵnov(_v, 0), "Cancel", "extras")), ""); var currVal_4 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 4, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "Ok", "extras")), ""); var currVal_5 = true; var currVal_6 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_SelectionsClearWarningModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-selections-clear-warning-modal", [], null, null, null, View_SelectionsClearWarningModalComponent_0, RenderType_SelectionsClearWarningModalComponent)), i1.ɵdid(1, 114688, null, 0, i8.SelectionsClearWarningModalComponent, [i9.BookingStepsService, i10.ECommerceService, i11.Router, i12.ProfileService, i13.SessionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SelectionsClearWarningModalComponentNgFactory = i1.ɵccf("check-in-selections-clear-warning-modal", i8.SelectionsClearWarningModalComponent, View_SelectionsClearWarningModalComponent_Host_0, {}, {}, []);
export { SelectionsClearWarningModalComponentNgFactory as SelectionsClearWarningModalComponentNgFactory };
