import { OnInit, NgZone, ElementRef, AfterViewInit, OnChanges, SimpleChanges, EventEmitter, Renderer2 } from '@angular/core';
import * as Pikaday from 'pikaday';
import * as moment from 'moment';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { ControlValueAccessor } from '@angular/forms';
import { ConfigService } from '../../core/config.service';
import { Constants } from '../../constants';
import { BlueRenderer } from '../../common-modules/blue-air-common/services/blue-renderer.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomHelper } from '../dom-helper';
import { BookingFlowService } from 'src/app/core/booking-flow.service';
var CalendarDatePickerComponent = /** @class */ (function () {
    function CalendarDatePickerComponent(zone, translate, renderer, blueRenderer, configService, http, bookingFlowService) {
        this.zone = zone;
        this.translate = translate;
        this.renderer = renderer;
        this.blueRenderer = blueRenderer;
        this.configService = configService;
        this.http = http;
        this.bookingFlowService = bookingFlowService;
        this.standardDateFormat = "MM/DD/YYYY";
        /** Select event - Triggers when a date is selected. The value is using the provided format  */
        this.select = new EventEmitter();
        /** Regex for validation the allowed characters from the input */
        this.allowedCharactersRegex = new RegExp('[0-9\-\/]');
        this.journeys = [];
        this.format = 'DD/MM/YYYY';
        this.scheduleCache = {};
        this.updateWindowWith(window.innerWidth);
    }
    Object.defineProperty(CalendarDatePickerComponent.prototype, "scheduleKey", {
        /** Gets schedule key composed from departure and destination */
        get: function () { return "" + this.departure + this.destination; },
        enumerable: true,
        configurable: true
    });
    CalendarDatePickerComponent.prototype.onResize = function (event) {
        this.updateWindowWith(event.target.innerWidth);
    };
    CalendarDatePickerComponent.prototype.ngOnInit = function () {
    };
    CalendarDatePickerComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.minDate) {
            this.minDateMoment = moment(changes.minDate.currentValue, this.format);
            if (this.picker) {
                this.zone.runOutsideAngular(function () {
                    if (_this.minDateMoment.isValid()) {
                        var newDate = _this.minDateMoment.toDate();
                        _this.picker.setMinDate(newDate);
                        _this.picker.gotoDate(newDate);
                    }
                    else {
                        _this.picker.setMinDate(null);
                        _this.picker.gotoDate(moment().toDate());
                    }
                    if (_this.isOpened) {
                        _this.picker.draw();
                    }
                });
            }
        }
        if (changes.maxDate) {
            this.maxDateMoment = moment(changes.maxDate.currentValue, this.format);
            if (this.picker) {
                this.zone.runOutsideAngular(function () {
                    if (_this.maxDateMoment.isValid()) {
                        var newDate = _this.maxDateMoment.toDate();
                        _this.picker.setMaxDate(newDate);
                        _this.picker.gotoDate(newDate);
                    }
                    else {
                        _this.picker.setMaxDate(null);
                        _this.picker.gotoDate(moment().toDate());
                    }
                    if (_this.isOpened) {
                        _this.picker.draw();
                    }
                });
            }
        }
        if (changes.rangeStart) {
            this.setRange(this.rangeStart, 'startRange');
        }
        if (changes.rangeEnd) {
            this.setRange(this.rangeEnd, 'endRange');
        }
        if (changes.departure || changes.destination) {
            this.updateSchedule();
        }
    };
    CalendarDatePickerComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.updateWindowWith(window.innerWidth);
        this.zone.runOutsideAngular(function () {
            _this.picker = new Pikaday({
                field: _this.pickerInput.nativeElement,
                container: _this.pickerContainer.nativeElement,
                numberOfMonths: _this.isOnMobile ? 1 : 2,
                firstDay: 1,
                maxDate: _this.maxDateMoment && _this.maxDateMoment.isValid() ? _this.maxDateMoment.toDate() : moment().add(2, 'years').toDate(),
                minDate: _this.minDateMoment && _this.minDateMoment.isValid() ? _this.minDateMoment.toDate() : null,
                format: _this.format,
                i18n: {
                    previousMonth: _this.translate.instant('Previous Month', 'date-picker'),
                    nextMonth: _this.translate.instant('Next Month', 'date-picker'),
                    months: moment.months(),
                    weekdays: moment.weekdays(),
                    weekdaysShort: moment.weekdaysMin()
                },
                onSelect: function (selectedDate) {
                    _this.zone.run(function () {
                        _this.model = moment(selectedDate).format(_this.format);
                        _this.close();
                        _this.onChangeCb(_this.model);
                        _this.select.next(_this.model);
                    });
                },
                onClose: function () {
                    _this.zone.run(function () { return _this.close(); });
                },
                onDraw: function () {
                    var pickerElem = _this.picker.el;
                    var monthSelect = pickerElem.getElementsByClassName('pika-select-month').item(0);
                    _this.renderer.addClass(monthSelect.parentElement, 'month');
                    var yearSelect = pickerElem.getElementsByClassName('pika-select-year').item(0);
                    _this.renderer.addClass(yearSelect.parentElement, 'year');
                },
                disableDayFn: function (dayToBeChecked) { return _this.isDayDisabled(dayToBeChecked); }
            });
            if (_this.rangeStart) {
                _this.setRange(_this.rangeStart, 'startRange');
            }
            if (_this.rangeEnd) {
                _this.setRange(_this.rangeEnd, 'endRange');
            }
        });
    };
    /**
     * Opens calendar date picker
     */
    CalendarDatePickerComponent.prototype.open = function () {
        var _this = this;
        if (this.isOpened) {
            return;
        }
        this.isOpened = true;
        this.zone.runOutsideAngular(function () {
            _this.checkMoveFlightEligibility();
            _this.picker.show();
            // setTimeout(() => this.picker.show(), 20);
        });
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
        DomHelper.IncreaseAppHeight(this.renderer);
    };
    CalendarDatePickerComponent.prototype.checkMoveFlightEligibility = function () {
        var _this = this;
        this.bookingFlowService.loadFlowInfo().then(function (booking) {
            if (booking) {
                if (booking.convertedJourneys && booking.convertedJourneys.journeys && booking.convertedJourneys.journeys.length > 0) {
                    _this.journeys = booking.convertedJourneys.journeys;
                    var isStationMoveAvailable = _this.journeys.some(function (j) { return j.segments && j.segments.some(function (s) { return s.legs && s.legs.some(function (l) { return l.legInfo.status == "Canceled"; }); }); });
                    var isSCHGMoveAvailable = _this.journeys.some(function (j) { return j.segments && j.segments.some(function (s) { return s.changeReasonCode && s.changeReasonCode == "SCHG" && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3; }); });
                    var isTimeChangeMoveAvailable = _this.journeys.some(function (j) { return j.segments && j.segments.some(function (s) { return (!s.changeReasonCode || s.changeReasonCode && s.changeReasonCode != "SCHG") && s.paxSegments.some(function (p) { return p.timeChanged; }) && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3; }); });
                    if (isStationMoveAvailable || isSCHGMoveAvailable || isTimeChangeMoveAvailable) {
                        var departureDate = moment(_this.journeys[0].segments[0].sTD).format(_this.standardDateFormat);
                        var arrivalDate = moment(_this.journeys[_this.journeys.length - 1].segments[0].sTD).format(_this.standardDateFormat);
                        var departureStation = _this.journeys[0].segments[0].departureStation;
                        var correspondingDate = _this.departure == departureStation ? departureDate : arrivalDate;
                        _this.minDateMoment = moment(correspondingDate).subtract(13, "days");
                        _this.picker.setMinDate(_this.minDateMoment.toDate());
                        _this.picker.gotoDate(_this.minDateMoment.toDate());
                        _this.maxDateMoment = moment(correspondingDate).add(29, "days");
                        _this.picker.setMaxDate(_this.maxDateMoment.toDate());
                        _this.picker.gotoDate(_this.maxDateMoment.toDate());
                    }
                }
            }
        });
    };
    /**
     * Closes calendar date picker component
     * @param event click event if any
     */
    CalendarDatePickerComponent.prototype.close = function (event) {
        var _this = this;
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (!this.isOpened) {
            return;
        }
        this.isOpened = false;
        this.zone.runOutsideAngular(function () {
            _this.picker.hide();
        });
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
        DomHelper.ResetAppHeight(this.renderer);
    };
    /**
     * Handles the keypress on the input.
     * @returns true if the pressed key is valid, otherwise false
     */
    CalendarDatePickerComponent.prototype.keyPress = function (event) {
        return this.allowedCharactersRegex.test(event.key);
    };
    /**
     * Makes updates to the component based on the new window width
     * @param newWidth - the new width of the window
     */
    CalendarDatePickerComponent.prototype.updateWindowWith = function (newWidth) {
        var _this = this;
        if (this.currentWindowWidth === newWidth) {
            return;
        }
        this.currentWindowWidth = newWidth;
        this.isOnMobile = newWidth < Constants.mobileScreenWith;
        if (this.picker) {
            this.zone.runOutsideAngular(function () {
                _this.picker.config({
                    numberOfMonths: _this.isOnMobile ? 1 : 2
                });
                _this.picker.adjustCalendars();
                if (_this.isOpened) {
                    _this.picker.draw();
                }
            });
        }
        this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
    };
    /**
     * Updates schedule data based on departure and destinations component inputs
     */
    CalendarDatePickerComponent.prototype.updateSchedule = function () {
        var _this = this;
        if (this.departure && this.destination) {
            var url = this.configService.ScheduleUrl;
            var params = new HttpParams()
                .set('_', CalendarDatePickerComponent.timestamp);
            var k_1 = this.scheduleKey;
            this.http.get(url + "/" + k_1 + ".json", { params: params }).subscribe(function (data) {
                _this.scheduleCache[k_1] = data.map(function (d) { return moment(d, 'YYYY-MM-DD'); });
                if (_this.isOpened) {
                    _this.zone.runOutsideAngular(function () { return _this.picker.draw(); });
                }
            });
        }
        if (this.isOpened) {
            this.zone.runOutsideAngular(function () {
                _this.picker.draw();
            });
        }
    };
    /**
     * Determines whether day is disabled
     * @param dayToBeChecked - the day to be checked
     * @returns true if day is disabled
     */
    CalendarDatePickerComponent.prototype.isDayDisabled = function (dayToBeChecked) {
        if (!this.scheduleCache[this.scheduleKey]) {
            return false;
        }
        var dayToBeCheckedM = moment(dayToBeChecked);
        var isEnabled = this.scheduleCache[this.scheduleKey].some(function (sch) { return sch.isSame(dayToBeCheckedM); });
        return !isEnabled;
    };
    /**
     * Sets calendar range  depending on the range type (startRange | endRange)
     * @param newDate - The new date to set range to. If empty or in incorrect format defaults to [null]
     * @param rangeType - The range type. Posibile values 'startRange' | 'endRange'
     */
    CalendarDatePickerComponent.prototype.setRange = function (newDate, rangeType) {
        var _this = this;
        if (this.picker) {
            var r_1 = moment(newDate, this.format);
            this.zone.runOutsideAngular(function () {
                var fn;
                switch (rangeType) {
                    case 'startRange':
                        fn = function (d) { return _this.picker.setStartRange(d); };
                        break;
                    case 'endRange':
                        fn = function (d) { return _this.picker.setEndRange(d); };
                        break;
                }
                fn(r_1.isValid() ? r_1.toDate() : null);
            });
        }
    };
    CalendarDatePickerComponent.prototype.writeValue = function (obj) {
        this.model = obj;
        if (this.picker) {
            var newD = moment(this.model, this.format);
            if (newD.isValid()) {
                this.picker.setDate(newD.toDate(), true);
            }
            else {
                this.picker.setDate(null, true);
            }
        }
    };
    CalendarDatePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCb = fn;
    };
    CalendarDatePickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCb = fn;
    };
    CalendarDatePickerComponent.prototype.setDisabledState = function (isDisabled) {
        if (isDisabled) {
            this.renderer.setAttribute(this.pickerInput.nativeElement, 'disabled', 'disabled');
        }
        else {
            this.renderer.removeAttribute(this.pickerInput.nativeElement, 'disabled');
        }
        this.close();
    };
    CalendarDatePickerComponent.timestamp = (new Date()).getTime().toString();
    return CalendarDatePickerComponent;
}());
export { CalendarDatePickerComponent };
