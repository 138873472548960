import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';

@Pipe({
  name: 'translationWithHtml'
})
export class TranslationWithHtml implements PipeTransform {

  constructor(private translateService: TranslateService, private sanitizer: DomSanitizer) { }

  transform(text: string, translationScope?: string): any {
    return this.toHTML(this.translateService.instant(`${text}`, translationScope ? translationScope : 'Booking'));
  }


  toHTML(input): any {
    return new DOMParser().parseFromString(input, "text/html").documentElement.innerText;
  }
}

