<form action="" class="checkin-wrapper" attr.aria-label="{{'Step 6 Check-in form' | translate: 'confirmation'}}">
    <blue-loading-spinner [id]="boardingPassesSpinnerId" [showSpinner]="true"></blue-loading-spinner>

    <div class="wrapper">
      <div class="step-7">
        <aside class="checkin-sidebar payment">
          <div class="checkin-cart-message checkin-successful" *ngIf="hasAppliedPayment"> <!-- if payment wasn't successful, replace "checkin-successful" with "checkin-unsuccessful" -->
            <span class="icon" role="presentation"></span>
            <span class="message-text">{{'Your payment was successful.'|translate}}</span>
          </div>
        </aside>

        <div class="checkin-content">
          <div class="checkin-box" *ngIf="boardingPasses.length > 1">
            <div class="checked-in-summary" aria-haspopup="true">
              <div class="checked-in-summary-footer">
                  <button type="button" class="btn btn-primary" attr.aria-label="{{'Click to view boarding pass'|translate}}"
                  (click)="printBoardingPass.print()">
                  {{'View all boarding passes'|translate}}
                </button>
                <button type="button" class="btn btn-primary" attr.aria-label="{{'Click to send via mail'|translate}}"
                  (click)="sendBoardingPassOverEmail.openPopup()">
                  {{'Send all via mail'| translate}}
                </button>
              </div>
            </div>
          </div>
          <div class="checkin-box"  *ngFor="let bp of boardingPasses">

            <div class="checked-in-summary" aria-haspopup="true">
              <div class="checked-in-summary-header">
                <span class="font-ico-person-default" role="presentation"></span>
                <!-- <span class="passenger-label" *ngIf="pax.isAdult">{{'Adult {0}'|translate:'':bp.passengerNumberByType}}</span>
                <span class="passenger-label" *ngIf="pax.isChild">{{'Child {0}'|translate:'':bp.passengerNumberByType}}</span> -->
                <h3>{{bp.isoName.first}} {{bp.isoName.last}}</h3>
                <span class="age" *ngIf="bp.isoInfantName.first" >
                    {{ 'With infant:' | translate: 'confirmation'}} {{bp.isoInfantName.first}} {{bp.isoInfantName.last}}
                  </span>
                <span class="checking-tag">
                  <span class="font-ico-checked-sign"></span> {{'Checked-in'|translate}}
                </span>
              </div>
              <div class="checked-in-summary-content" *ngFor="let seg of bp.segments">
                <ul role="menu" class="right">
                  <li role="menuitem">
                    <span class="info-label">{{'Date'|translate}}</span>
                    <label attr.datetime="{{seg.departureTime|date}}" class="info-value">{{seg.departureTime|date}}</label>
                  </li>
                  <li role="menuitem">
                    <span class="info-label">{{'Time of fligh'|translate}}</span>
                    <label attr.datetime="{{seg.departureTime|date:'shortTime'}}" class="info-value">{{seg.departureTime|date:'shortTime'}}</label>
                  </li>
                </ul>
                <ul role="menu" class="left">
                  <li role="menuitem">
                    <span class="info-label">{{'Flight'|translate}}</span>
                    <span class="info-value">{{'{0} to {1}'|translate:'':[seg.inventoryLegKey.departureStation | translate:'station.name']:[seg.inventoryLegKey.arrivalStation | translate:'station.name']}} </span>
                  </li>
                  <li role="menuitem">
                    <span class="info-label">{{'Booking code'|translate}}</span>
                    <span class="info-value">{{ bp.recordLocator }}</span>
                  </li>
                  <li role="menuitem">
                    <span class="info-label">{{'Seat'|translate}}</span>
                    <span class="info-value">{{getSeats(seg)}}</span>
                  </li>
                </ul>
              </div>
              <div class="checked-in-summary-footer">
                <button type="button" class="btn btn-primary" attr.aria-label="{{'Click to view boarding pass'|translate}}"
                  (click)="printBoardingPass.print(bp.passengerNumber, bp.sellKey)">
                  {{'View boarding pass'|translate}}
                </button>
                <button type="button" class="btn btn-primary" attr.aria-label="{{'Click to send via mail'|translate}}"
                  (click)="sendBoardingPassOverEmail.openPopup(bp.passengerNumber, bp.sellKey, bp.isoName)">
                  {{'Send via mail'|translate}}
                </button>
              </div>
            </div>
          </div>
          <check-in-send-boarding-pass-over-email #sendBoardingPassOverEmail></check-in-send-boarding-pass-over-email>
        </div>
      </div>
    </div>
  </form>
  <check-in-print-boarding-pass #printBoardingPass></check-in-print-boarding-pass>
  <!-- <form  action="/documents/print" method="post"  #boardingPassForm>
    <input type="hidden" [value]="boardingPassData" name="data"/>
  </form> -->
