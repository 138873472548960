/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./corporateheader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./corporateheader.component";
var styles_CorporateheaderComponent = [i0.styles];
var RenderType_CorporateheaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CorporateheaderComponent, data: {} });
export { RenderType_CorporateheaderComponent as RenderType_CorporateheaderComponent };
export function View_CorporateheaderComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_CorporateheaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-corporateheader", [], null, null, null, View_CorporateheaderComponent_0, RenderType_CorporateheaderComponent)), i1.ɵdid(1, 114688, null, 0, i2.CorporateheaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CorporateheaderComponentNgFactory = i1.ɵccf("check-in-corporateheader", i2.CorporateheaderComponent, View_CorporateheaderComponent_Host_0, {}, {}, []);
export { CorporateheaderComponentNgFactory as CorporateheaderComponentNgFactory };
