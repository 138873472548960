import { Component, OnInit } from '@angular/core';
import { CheckinSteps, BookingStepsService } from '../core/booking-steps.service';
import { ConfigService } from '../core/config.service';
import { BookingService } from '../core/booking.service';
import { environment } from '../../environments/environment';
import { BookingFlowService } from '../core/booking-flow.service';
import { SessionService } from '../common-modules/blue-air-common/session.service';
import { ActivatedRoute } from '@angular/router';
import { utils } from 'protractor';
import { isNullOrUndefined } from 'util';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';

@Component({
  selector: 'check-in-booking-status',
  templateUrl: './booking-status.component.html',
  styleUrls: ['./booking-status.component.scss']
})
export class BookingStatusComponent implements OnInit {
  ErrorType = ErrorType;
  imagesPath: string;
  retrieveMethodByLastName = true;
  missingParams = false;
  bookingNotFound = false;
  memberNotLoggedIn = false;
  wrongMemberRetrieve = false;
  lastName = '';
  emailAddress = '';
  recordLocator = '';
  recordLocatorCopy = '';
  public vm: any;
  public response: any;
  reset = true;
  route: string;
  currentStep: CheckinSteps = CheckinSteps.search;
  bookingStatus: string;
  isLoaded: boolean = false;

  constructor(private config: ConfigService,
    private bookingService: BookingService, private bookingSteps: BookingStepsService,
    private bookingFlowService: BookingFlowService, private sessionService: SessionService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private activatedRoute: ActivatedRoute) {
    this.bookingSteps.currentStep.next(this.currentStep);
    this.imagesPath = environment.imagesPath;
    this.route = this.config.BlueRetrieve;
  }

  ngOnInit() {
    this.isLoaded = false;
    this.activatedRoute.queryParams.subscribe(params => {
      if (Object.getOwnPropertyNames(params).length !== 0) {
        this.recordLocator = params['rl'];
        this.emailAddress = params['em'];
        this.lastName = params['ln'];
        const culture = params['culture'];

        if (!isNullOrUndefined(culture) && this.translateService.culture !== culture) {

          this.translateService.culture = culture;
          this.translateService.load(this.configService.config.translationsUrl, this.configService.config.collectTranslationsUrl,
            environment.collectTranslations);
          window.location.reload();
          return;
        }
        this.isLoaded = true;
        this.getBooking();
      }
    });
  }

  resetErrors() {
    this.missingParams = !this.recordLocator ||
      ((this.retrieveMethodByLastName && !this.lastName) || (!this.retrieveMethodByLastName && !this.emailAddress));

    this.bookingNotFound = false;
    this.memberNotLoggedIn = false;
    this.wrongMemberRetrieve = false;
  }

  changeRetrieveMethod() {
    this.retrieveMethodByLastName = !this.retrieveMethodByLastName;
  }

  getBooking() {
    // this.bookingService.clearSession();

    if (!this.recordLocator || (!this.emailAddress && !this.lastName)) {
      this.missingParams = true;
      return;
    }

    this.recordLocatorCopy = this.recordLocator;
    this.sessionService.ensureSessionIsValid()
      .then(() => this.bookingService.retrieveBooking(this.recordLocator, this.lastName, this.emailAddress, this.config.BlueRetrieve))
      .then(response => {
        if (response) {
          if (response.bookingDetail && response.bookingDetail.status) {
            this.bookingStatus = response.bookingDetail.status;
          }
          this.bookingFlowService.loadFlowInfo(true).then(response2 => {
            if (response2) {
              this.bookingService.recordLocator = this.recordLocator;
              if (this.bookingStatus !== "Closed") {
                this.bookingSteps.goToStep(CheckinSteps.itinerary);
              }
            }
          });
        } else {
          this.bookingNotFound = true;
        }
      }, e => {
        const data = e.error.errors;
        if (data && data.find(i => i.errorCode === ErrorType[ErrorType.memberNotLoggedIn])) {
          this.memberNotLoggedIn = true;
        } else if (data && data.find(i => i.errorCode === ErrorType[ErrorType.ValidateNullBooking])) {
          this.bookingNotFound = true;
        } else if (data && data.find(i => i.errorCode === ErrorType[ErrorType.wrongMemberRetrieve])) {
          this.wrongMemberRetrieve = true;
        }
      });
  }
}

export enum ErrorType {
  mandatoryFields = 1,
  bookingNotFound = 2,
  pendingPayments = 3,
  checkinClosed = 4,
  checkinNotOpen = 5,
  memberNotLoggedIn = 6,
  ValidateNullBooking = 7,
  wrongMemberRetrieve = 8
}
