/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./find-my-bookings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "../filter-bookings/filter-bookings.component.ngfactory";
import * as i5 from "../filter-bookings/filter-bookings.component";
import * as i6 from "../../../common-modules/blue-air-common/resource.service";
import * as i7 from "../../find-my-bookings.service";
import * as i8 from "../../../common-modules/blue-air-common/loading-spinner.service";
import * as i9 from "@angular/common";
import * as i10 from "../display-bookings/display-bookings.component.ngfactory";
import * as i11 from "../display-bookings/display-bookings.component";
import * as i12 from "../../../core/booking.service";
import * as i13 from "../../../core/config.service";
import * as i14 from "../../../core/booking-flow.service";
import * as i15 from "../../../core/booking-steps.service";
import * as i16 from "./find-my-bookings.component";
var styles_FindMyBookingsComponent = [i0.styles];
var RenderType_FindMyBookingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FindMyBookingsComponent, data: {} });
export { RenderType_FindMyBookingsComponent as RenderType_FindMyBookingsComponent };
function View_FindMyBookingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "errorSection"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.ErrorMessage; _ck(_v, 2, 0, currVal_0); }); }
function View_FindMyBookingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "span", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "])), i1.ɵppd(9, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "No bookings to display!", "find-my-bookings")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "If you have a PNR issued on GDS, please", "find-my-bookings")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.dynamicBookingStatusUrl, ""); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent, 0), "click here", "find-my-bookings")); _ck(_v, 8, 0, currVal_3); }); }
export function View_FindMyBookingsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "section", [["class", "section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "check-in-filter-bookings", [], null, [[null, "bookingDataEventEmmiter"], [null, "errorsEventEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("bookingDataEventEmmiter" === en)) {
        var pd_0 = (_co.bookingsFiltered($event) !== false);
        ad = (pd_0 && ad);
    } if (("errorsEventEmitter" === en)) {
        var pd_1 = (_co.setErrors($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_FilterBookingsComponent_0, i4.RenderType_FilterBookingsComponent)), i1.ɵdid(4, 114688, null, 0, i5.FilterBookingsComponent, [i6.ResourceService, i7.FindMyBookingsService, i8.LoadingSpinnerService], null, { bookingDataEventEmmiter: "bookingDataEventEmmiter", errorsEventEmitter: "errorsEventEmitter" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FindMyBookingsComponent_1)), i1.ɵdid(6, 278528, null, 0, i9.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FindMyBookingsComponent_2)), i1.ɵdid(8, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "check-in-display-bookings", [], null, null, null, i10.View_DisplayBookingsComponent_0, i10.RenderType_DisplayBookingsComponent)), i1.ɵdid(10, 638976, null, 0, i11.DisplayBookingsComponent, [i12.BookingService, i13.ConfigService, i14.BookingFlowService, i15.BookingStepsService, i8.LoadingSpinnerService], { bookingData: [0, "bookingData"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_0 = _co.errors; _ck(_v, 6, 0, currVal_0); var currVal_1 = (_co.bookingData.length == 0); _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.bookingData; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_FindMyBookingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-find-my-bookings", [], null, null, null, View_FindMyBookingsComponent_0, RenderType_FindMyBookingsComponent)), i1.ɵdid(1, 114688, null, 0, i16.FindMyBookingsComponent, [i15.BookingStepsService, i3.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FindMyBookingsComponentNgFactory = i1.ɵccf("check-in-find-my-bookings", i16.FindMyBookingsComponent, View_FindMyBookingsComponent_Host_0, {}, {}, []);
export { FindMyBookingsComponentNgFactory as FindMyBookingsComponentNgFactory };
