import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { SummaryComponent } from './summary/summary.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { SummaryRoutes } from './summary-routes';
import { SharedModule } from '../shared/shared.module';
import { CanActivateSummaryOnCheckinService } from './can-activate-summary-on-checkin.service';
import { CanActivateConfirmationService } from './can-activate-confirmation.service';
import { PaymentsService } from './payments.service';
import { VerifyPaymentComponent } from './verify-payment/verify-payment.component';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RedirectToPaymentComponent } from './redirect-to-payment/redirect-to-payment.component';
import { environment } from '../../environments/environment';
import { CanActivateSummaryOnBookingService } from './can-activate-summary-on-booking.service';
import { InvoicingComponent } from './invoicing/invoicing.component';
import { InvoicingFieldErrorComponent } from './invoicing/invoicing-field-error/invoicing-field-error.component';
import { AeroInvoicingComponent } from './invoicing-aero/invoicing-aero.component';
import { ShowErrorsComponent } from './invoicing-aero/show-errors.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    // RouterModule.forRoot(
    //   SummaryRoutes
    // ),
    BlueAirCommonModule
  ],
  declarations: [
    SummaryComponent,
    ConfirmationComponent, VerifyPaymentComponent, RedirectToPaymentComponent, InvoicingComponent,
    AeroInvoicingComponent, ShowErrorsComponent,
    InvoicingFieldErrorComponent],
  providers: [
    environment.flow === 0 ? CanActivateSummaryOnCheckinService : CanActivateSummaryOnBookingService,
    CanActivateConfirmationService, PaymentsService]
})
export class SummaryModule { }
