<check-in-selections-clear-warning-modal #changeFlightsWarningModal></check-in-selections-clear-warning-modal>
<check-in-form-wrapper ariaLabel="{{ 'Step 6 Check-in form' | translate }}" [step]="'step-6'" [showPriceDisplay]="true" (continue)="goToNextStep()">
    <!-- <div class="form-wrapper-title">
    {{ 'Services & Upgrades' | translate:'extras' }}
  </div> -->
    <div class="form-wrapper-header">
        <a href="javascript:;" class="link-arrow" attr.aria-label="{{ 'Click to skip & complete check-in' | translate:'extras' }}" (click)="skipAndComplete()">
      {{ 'Skip & complete check-in' | translate:'extras' }}
    </a>
    </div>

    <div class="form-wrapper-top-body">
        <!-- <check-in-upgrades></check-in-upgrades> -->
        <check-in-default-modal #pbagModal modalId="pbagModal" modalTitle="{{ 'Extras-RemovePbagModal-Title' | translate:'extras' }}" modalText="{{ 'Extras-RemovePbagModal-Text' | translate:'extras' }}" okButtonText="{{ 'Extras-RemovePbagModal-OkButton' | translate:'extras' }}"
            cancelButtonText="" [okRequired]="true" [cancelRequired]="false">
        </check-in-default-modal>

        <div *ngIf="hasRecordLocator && hasSpoilageFees">
            <div class="spoilageFee">
                {{ 'spoilage-fee-info' | translate: 'extras' }}
            </div>
        </div>

        <passenger-bundle [pbagModal]="pbagModal" [showHeader]="true">
        </passenger-bundle>

        <seat-selected-notification #ssrSelectedModalId>
            <ng-container *ngIf="pendingSsrsList.length">
                <div class="section-SubTitle">{{'The following extras have been selected, but not added to cart:' | translate: 'extras'}}
                </div>

                <div class="section-text">
                    <ul class="pending-list">
                        <ng-container *ngFor="let ssrType of pendingSsrsList">
                            <ng-container [ngSwitch]="ssrType">
                                <li *ngSwitchCase="SsrTypeEnum.Baggage">
                                    <span class="font-ico-luggage ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Hold luggage' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.Meal">
                                    <span class="font-ico-meal ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Add a meal' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.Pet">
                                    <span class="font-ico-dog ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Pets' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.SpecialEquipment">
                                    <span class="font-ico-sport-eq ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Other special equipment' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.Lounge">
                                    <span class="font2-ico-lounge ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{ 'Lounge' | translate:'extras' }}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.SpecialAssistance">
                                    <span class="font-ico-spec-assistance ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Special assistance' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.Seat">
                                    <span class="font-ico-seat ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Seat' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.PriorityBoarding">
                                    <span class="font-ico-priority-boarding ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Priority boarding' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.FastTrack">
                                    <span class="font-ico-fast-track ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Fast track' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.AirportCheckin">
                                    <span class="font-ico-airport-checkin ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Airport checkin' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.PriorityBag">
                                    <span class="font-ico-priority-bag ssr-icon">                    
                                    </span>
                                    <div class="text">
                                        {{'Priority bag' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.Insurance">
                                    <img class="insurance-icon" [src]=" assetsPath + 'svg/icon-insurance.svg'">
                                    <div class="text">
                                        {{'Travel insurance' | translate: 'extras'}}
                                    </div>
                                </li>

                                <li *ngSwitchCase="SsrTypeEnum.BundleUpgrade">
                                    <span class="font-ico-tag ssr-icon">                      
                                    </span>
                                    <div class="text">
                                        {{'Bundle upgrade' | translate: 'extras'}}
                                    </div>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ul>
                </div>
            </ng-container>
        </seat-selected-notification>

        <check-in-ssrs [pbagModal]="pbagModal">
        </check-in-ssrs>

        <check-in-insurance *ngIf="insuranceEnabled">
            <div>
                <h3>
                    {{ 'Travel insurance' | translate:'extras' }}
                </h3>

                <ul role="menu" class="checkin-checked-box">
                    <li role="menuitem" *blueTanslateItems="'insurance.description.items';let translation" [innerHTML]="translation">
                    </li>
                </ul>
            </div>
        </check-in-insurance>
    </div>
</check-in-form-wrapper>