<check-in-form-wrapper [step]="'step-3'" (continue)="continue()" [showPriceDisplay]="true">

    <div class="form-wrapper-title">
        {{'Seat selection'|translate}}
    </div>

    <div class="form-wrapper-top-body">
        <passenger-bundle></passenger-bundle>
    </div>
</check-in-form-wrapper>


<seat-selected-notification notShowConfirmationMessage="false">
    <ng-container>
        <div class="section-SubTitle">{{'The following options have been selected, but not added to cart:' | translate: 'seat'}}</div>
        <div class="section-text">
            <ul class="pending-list">
                <ng-container>
                    <li><span class="font-ico-tag ssr-icon"></span>
                        <div class="text">{{'Bundle upgrade' | translate: 'extras'}}</div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</seat-selected-notification>