import { Component, OnInit, ViewChild } from '@angular/core';
import { BookingFlightSelectService } from '../../booking-flight-select/booking-flight-select.service';
import { FlightSearchModel } from '../../booking-flight-select/models/flight-search-model';
import { CheckinSteps, BookingStepsService } from '../../core/booking-steps.service';
import { CalendarSelection } from '../calendar-selection';
import { FlightModel, FlightStationInfo } from '../../core/models/flight-model';
import { Router } from '@angular/router';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'low-fare-calendar',
  templateUrl: './low-fare-calendar.component.html',
  styleUrls: ['./low-fare-calendar.component.scss']
})
export class LowFareCalendarComponent implements OnInit {
  private currentStep: CheckinSteps = CheckinSteps.flights;

  journeys: any[];
  selections: CalendarSelection[] = [];

  @ViewChild(DefaultModalComponent, { static: true }) invalidDatesModal: DefaultModalComponent;
  invalidDatesModalId = 'InvalidDateModal';

  constructor(private flightSearch: BookingFlightSelectService, private bookingSteps: BookingStepsService,
    private router: Router, private modalService: BlueModalService) {
    bookingSteps.currentStep.next(this.currentStep);
  }

  ngOnInit() {
    this.flightSearch.getFlightSearch().then((flightSearchModel: FlightSearchModel) => {
      this.journeys = [];
      this.journeys.push({
        origin: flightSearchModel.origin,
        destination: flightSearchModel.destination,
        selectedDate: flightSearchModel.departureDate
      });

      if (flightSearchModel.isRoundTrip) {
        this.journeys.push({
          origin: flightSearchModel.destination,
          destination: flightSearchModel.origin,
          selectedDate: flightSearchModel.returnDate
        });
      }
      this.selections = this.journeys.map(j => new CalendarSelection());
    });
  }

  calendarSelectionChanged(index: number, selection: CalendarSelection) {
    this.selections[index] = selection;
    // console.log(this.selections);
  }

  continue() {
    let isSelectionValid = true;

    for (let i = 0; i < this.selections.length; i++) {
      isSelectionValid = isSelectionValid &&
        this.selections[i].dateMoment.isValid() &&
        (i === 0 || this.selections[i - 1].dateMoment.isSameOrBefore(this.selections[i].dateMoment));
    }

    this.flightSearch.searchModel.departureDate = this.selections[0].date;
    if (this.flightSearch.searchModel.isRoundTrip) {
      this.flightSearch.searchModel.returnDate = this.selections[1].date;
    }

    if (isSelectionValid) {
      // the route seems like a hardcode ?
      this.router.navigate(['/flights']);
    } else {
      this.invalidDatesModal.openPopup(_ => {});
    }
  }
}
