import * as moment from 'moment';
export class DateRange {

    public minimumDate: any;
    public maximumDate: any;

    daysArray: any[] = [];
    monthsArray: any[] = [];
    yearsArray: any[] = [];

    private baseDate: any;

    constructor(private start) {
        this.baseDate = start || moment();
        start = moment();

        this.baseDate = moment(start);
        
        for (let i = 1; i <= 31; i++) {
            this.daysArray.push(i);
        }
        
        for (let i = 0; i < 12; i++) {
            this.monthsArray.push(moment(new Date(1900, i, 1, 0, 0, 0)).format('MMMM'))
        }
        // this.minimumDate = moment();
        // this.maximumDate = moment().add(100, 'years');
    }

    generateExpiration(years) {
        const startYear = +this.baseDate.format('YYYY');

        this.resetYears();
        for (let y = startYear; y < startYear + years; y++) {
            this.yearsArray.push(y);
        }
    }

    generateYearsForAge(minAge, maxAge) {

        const startYear = +this.baseDate.format('YYYY');
        let minAgeYear = startYear - minAge + 1;
        if (minAgeYear > moment().year()) {
            minAgeYear--;
        }
        const maxAgeYear = startYear - maxAge + 1;

        this.resetYears();
        for (let y = minAgeYear; y >= maxAgeYear; y--) {
            this.yearsArray.push(y);
        }
    }

    generateIssue(years) {
        this.resetYears();
        const startYear = +this.baseDate.format('YYYY');
        for (let y = startYear; y > startYear - years; y--) {
            this.yearsArray.push(y);
        }
    }

    private resetYears() {
        this.yearsArray.length = 0;
        ///this.yearsArray.push("YYYY");
    }
}
