import { PipeTransform } from '@angular/core';
import { TranslateService } from './translate.service';
var TranslatePipe = /** @class */ (function () {
    function TranslatePipe(_translate) {
        this._translate = _translate;
    }
    TranslatePipe.prototype.transform = function (value, scope) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        if (!value) {
            return '';
        }
        return this._translate.instant(value, scope, args);
    };
    return TranslatePipe;
}());
export { TranslatePipe };
