/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./booking-flight-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../shared/form-wrapper/form-wrapper.component.ngfactory";
import * as i3 from "../../shared/form-wrapper/form-wrapper.component";
import * as i4 from "@angular/router";
import * as i5 from "../../common-modules/blue-air-common/loading-spinner.service";
import * as i6 from "../../core/booking-steps.service";
import * as i7 from "../../core/booking.service";
import * as i8 from "../change-flight/change-flight.component.ngfactory";
import * as i9 from "../change-flight/change-flight.component";
import * as i10 from "../../core/booking-flow.service";
import * as i11 from "../../core/currency-manager.service";
import * as i12 from "../booking-flight-select.service";
import * as i13 from "../../core/profile.service";
import * as i14 from "../../common-modules/blue-air-common/resource.service";
import * as i15 from "../../core/config.service";
import * as i16 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i17 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i18 from "../../common-modules/blue-air-common/user-state.service";
import * as i19 from "@angular/platform-browser";
import * as i20 from "./booking-flight-search.component";
import * as i21 from "../../common-modules/blue-air-common/session.service";
var styles_BookingFlightSearchComponent = [i0.styles];
var RenderType_BookingFlightSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BookingFlightSearchComponent, data: {} });
export { RenderType_BookingFlightSearchComponent as RenderType_BookingFlightSearchComponent };
export function View_BookingFlightSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "check-in-form-wrapper", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FormWrapperComponent_0, i2.RenderType_FormWrapperComponent)), i1.ɵdid(1, 770048, null, 0, i3.FormWrapperComponent, [i4.Router, i5.LoadingSpinnerService, i6.BookingStepsService, i7.BookingService], { ariaLabel: [0, "ariaLabel"], step: [1, "step"], showPriceDisplay: [2, "showPriceDisplay"], showContinue: [3, "showContinue"] }, null), (_l()(), i1.ɵeld(2, 0, null, 6, 2, "div", [["class", "form-wrapper-middle-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "change-flight", [], null, null, null, i8.View_ChangeFlightComponent_0, i8.RenderType_ChangeFlightComponent)), i1.ɵdid(4, 4440064, null, 0, i9.ChangeFlightComponent, [i10.BookingFlowService, i7.BookingService, i6.BookingStepsService, i11.CurrencyManagerService, i12.BookingFlightSelectService, i5.LoadingSpinnerService, i13.ProfileService, i14.ResourceService, i15.ConfigService, i16.TranslateService, i17.BlueModalService, i18.UserStateService, i19.DomSanitizer], null, null)], function (_ck, _v) { var currVal_0 = ""; var currVal_1 = "step-1"; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); _ck(_v, 4, 0); }, null); }
export function View_BookingFlightSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-booking-flight-search", [], null, null, null, View_BookingFlightSearchComponent_0, RenderType_BookingFlightSearchComponent)), i1.ɵdid(1, 114688, null, 0, i20.BookingFlightSearchComponent, [i6.BookingStepsService, i7.BookingService, i21.SessionService, i13.ProfileService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingFlightSearchComponentNgFactory = i1.ɵccf("check-in-booking-flight-search", i20.BookingFlightSearchComponent, View_BookingFlightSearchComponent_Host_0, {}, {}, []);
export { BookingFlightSearchComponentNgFactory as BookingFlightSearchComponentNgFactory };
