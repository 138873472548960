<profile-layout title="{{ 'Reset your password' | translate:'profile-info' }}">
  <profile-info-box class="two-cols-mob profile-layout-body"
    title="{{ 'You must reset your password to continue.' | translate:'profile-info' }}" [showEditBtn]="false"
    [showAddBtn]="false">
    <blue-loading-spinner [activationRoute]="route"></blue-loading-spinner>
    <form>
      <div class="form-row equal-fields">
        <div class="one-field">
          <label for="username-field">{{ 'User name' | translate:'profile-info' }}</label>
          <input id="username-field" type="text" value="{{username}}" aria-labelledby="username-field" readonly />
        </div>
      </div>
    </form>
    <profile-reset-password-form [passwordChangeErrors]="passwordChangeErrors" (savePassword)="resetPassword($event)">
      <div class="form-row btn-row">
        <button type="submit" class="btn btn-primary btn-blue">{{ 'Reset password' | translate:'profile-info' }}</button>
      </div>
    </profile-reset-password-form>
  </profile-info-box>
</profile-layout>