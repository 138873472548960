/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blue-select-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "../translator/translate.pipe";
import * as i5 from "../translator/translate.service";
import * as i6 from "./blue-select-list.component";
import * as i7 from "../../../core/booking-flow.service";
var styles_BlueSelectListComponent = [i0.styles];
var RenderType_BlueSelectListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlueSelectListComponent, data: {} });
export { RenderType_BlueSelectListComponent as RenderType_BlueSelectListComponent };
function View_BlueSelectListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "span", [["aria-hidden", "true"], ["class", "overlay"], ["style", "display: inline"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.focusOut() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_BlueSelectListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "input", [["autocomplete", "off"], ["class", "selected"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "click"], [null, "keydown.tab"], [null, "focus"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = (_co.searchChanged($event) !== false);
        ad = (pd_4 && ad);
    } if (("click" === en)) {
        var pd_5 = (_co.setExpanded(true) !== false);
        ad = (pd_5 && ad);
    } if (("keydown.tab" === en)) {
        var pd_6 = (_co.focusOut() !== false);
        ad = (pd_6 && ad);
    } if (("focus" === en)) {
        var pd_7 = (_co.setExpanded(true) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "not-active": 0, "active": 1 }), i1.ɵdid(4, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(8, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "selected"; var currVal_9 = _ck(_v, 3, 0, !_co.selectedItem, _co.expanded); _ck(_v, 2, 0, currVal_8, currVal_9); var currVal_10 = _co.isDisabled; var currVal_11 = _co.search; _ck(_v, 6, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.placeholder, ""); var currVal_1 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 8).ngClassValid; var currVal_6 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 8).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_BlueSelectListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "button", [["class", "selected"], ["type", "button"]], [[1, "aria-label", 0], [8, "disabled", 0]], [[null, "click"], [null, "keydown.tab"], [null, "focus"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setExpanded(true) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.tab" === en)) {
        var pd_1 = (_co.focusOut() !== false);
        ad = (pd_1 && ad);
    } if (("focus" === en)) {
        var pd_2 = (_co.setExpanded(true) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "not-active": 0, "active": 1, "disabled": 2 }), i1.ɵppd(4, 2), (_l()(), i1.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "selected"; var currVal_3 = _ck(_v, 3, 0, !_co.selectedItem, _co.expanded, _co.isDisabled); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 0, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), "Click to select nationality", "blue-select-list")), ""); var currVal_1 = _co.isDisabled; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = (_co.selectedItem ? _co.selectedItem[_co.textProperty] : _co.placeholder); _ck(_v, 5, 0, currVal_4); }); }
function View_BlueSelectListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [["role", "menuitem"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectItem($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit[_co.textProperty]; _ck(_v, 2, 0, currVal_0); }); }
function View_BlueSelectListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "dest-dropdown js-dest-overlay"], ["style", "display: inline"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "ul", [["aria-hidden", "true"], ["role", "menubar"]], [[1, "aria-label", 0]], null, null, null, null)), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueSelectListComponent_5)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.filteredItems; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), "destination menu", "blue-select-list")), ""); _ck(_v, 1, 0, currVal_0); }); }
function View_BlueSelectListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "selected-icon"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setExpanded(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "Arrow Icon", "blue-select-list")); _ck(_v, 2, 0, currVal_0); }); }
export function View_BlueSelectListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.TranslatePipe, [i5.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["aria-haspopup", "true"], ["class", "select-list"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "expanded": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueSelectListComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueSelectListComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueSelectListComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueSelectListComponent_4)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueSelectListComponent_6)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "select-list"; var currVal_1 = _ck(_v, 4, 0, _co.expanded); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.expanded; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.enableSearch; _ck(_v, 8, 0, currVal_3); var currVal_4 = !_co.enableSearch; _ck(_v, 10, 0, currVal_4); var currVal_5 = _co.expanded; _ck(_v, 12, 0, currVal_5); var currVal_6 = _co.enableSearch; _ck(_v, 14, 0, currVal_6); }, null); }
export function View_BlueSelectListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "blue-select-list", [], null, null, null, View_BlueSelectListComponent_0, RenderType_BlueSelectListComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.BlueSelectListComponent]), i1.ɵdid(2, 638976, null, 0, i6.BlueSelectListComponent, [i1.Renderer2, i1.ElementRef, i7.BookingFlowService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var BlueSelectListComponentNgFactory = i1.ɵccf("blue-select-list", i6.BlueSelectListComponent, View_BlueSelectListComponent_Host_0, { items: "items", textProperty: "textProperty", valueProperty: "valueProperty", placeholder: "placeholder", enableSearch: "enableSearch", minimumSearchLength: "minimumSearchLength", lazySearch: "lazySearch" }, {}, []);
export { BlueSelectListComponentNgFactory as BlueSelectListComponentNgFactory };
