import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { PassengerSsrModel } from '../passenger-ssr-model';
import { FlightModel } from '../../core/models/flight-model';
import { SsrModel } from '../ssr-model';
import { Constants } from '../../constants';
import { PaidSsrOverride } from '../paid-ssr-override';
import { environment } from '../../../environments/environment';
var SsrBoxContentBase = /** @class */ (function () {
    function SsrBoxContentBase(ssrsService) {
        this.SsrTypeEnum = SsrType;
        this.loading = true;
        this.loadingCount = 0;
        this._flightSsrsInitialized = false;
        this._paidSSROverrides = new PaidSsrOverride();
        this.assetsPath = environment.assetsPath;
        this.isAllowedToRemovePaidSsrs = false;
        this.passengers = [];
        this.availableSsrs = [];
        this.allPassengersEnabled = true;
        this.selectedPassenger = null;
        this.applySsrsToSegments = true;
        this._isSsrsPopupOpened = false;
        this.currentFlights = [];
        this.totalPriceChanged = new EventEmitter();
        this._ssrsService = ssrsService;
        this.mobileMatches = window.innerWidth < Constants.mobileScreenWith;
        this.sameOptionsForAllFlights = false;
        // this._isSsrsPopupOpened = true;
    }
    Object.defineProperty(SsrBoxContentBase.prototype, "isSsrsPopupOpened", {
        get: function () { return this._isSsrsPopupOpened; },
        set: function (val) {
            this._isSsrsPopupOpened = val;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SsrBoxContentBase.prototype, "sameOptionsForAllFlights", {
        get: function () {
            return this._sameOptionsForAllFlights;
        },
        set: function (val) {
            this._sameOptionsForAllFlights = val;
            if (val) {
                this.copySsrsToReturnFlight();
                this.onEnableSameOptionsForAllFlightsChanged(val);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SsrBoxContentBase.prototype, "enableSameOptionsForAllFlights", {
        get: function () {
            return this._enableSameOptionsForAllFlights;
        },
        set: function (value) {
            this._enableSameOptionsForAllFlights = value;
        },
        enumerable: true,
        configurable: true
    });
    SsrBoxContentBase.prototype.onEnableSameOptionsForAllFlightsChanged = function (value) {
    };
    Object.defineProperty(SsrBoxContentBase.prototype, "allFlightBoxContents", {
        get: function () {
            return this._allFlightBoxContents;
        },
        set: function (val) {
            this._allFlightBoxContents = val;
            this.initSameSsrForReturnFlight();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SsrBoxContentBase.prototype, "flight", {
        get: function () {
            return this._flight;
        },
        set: function (flight) {
            this.passengers = flight.passengers.map(function (p) {
                var x = new PassengerSsrModel();
                x.passenger = p;
                return x;
            });
            this._flight = flight;
            this.initFlightSsrList();
        },
        enumerable: true,
        configurable: true
    });
    SsrBoxContentBase.prototype.ngOnInit = function () {
        var _this = this;
        var list = this._ssrsService.availableSsrs.find(function (lists) { return lists.key === _this.ssrType; });
        this._fullSsrList = list ? list.value : [];
        this.initFlightSsrList();
    };
    SsrBoxContentBase.prototype.startLoading = function () {
        this.loading = true;
        this.loadingCount++;
    };
    SsrBoxContentBase.prototype.finishLoading = function () {
        this.loadingCount--;
        if (this.loadingCount === 0) {
            this.loading = false;
        }
    };
    SsrBoxContentBase.prototype.selectSsr = function (passengerIndex, ssrCode, newSsrQuantity, isExternalCall) {
        var _this = this;
        if (passengerIndex === void 0) { passengerIndex = null; }
        if (ssrCode === void 0) { ssrCode = null; }
        if (newSsrQuantity === void 0) { newSsrQuantity = 0; }
        if (isExternalCall === void 0) { isExternalCall = false; }
        if (this.mobileMatches && this.availableSsrs.length === 1) {
            this._isSsrsPopupOpened = false;
        }
        if (passengerIndex === null) {
            passengerIndex = this.selectedPassenger;
        }
        var passengers = this.getSelectedPassengers(passengerIndex);
        var ssrChanged = true;
        passengers.forEach(function (p) {
            var ssr = _this.availableSsrs.find(function (avSsr) { return avSsr.ssrCode === ssrCode; });
            var paxSsr = p.getSelectedSsr(ssr);
            var paidQuantity = paxSsr ? paxSsr.paidQuantity : 0;
            if (newSsrQuantity === 0 && (paidQuantity === 0 || _this.isAllowedToRemovePaidSsrs)) {
                ssrChanged = p.removeSsr(ssrCode);
            }
            else {
                paxSsr = p.getOrAddSelectedSsr(ssr);
                var previousSelectedQuantity = paxSsr.selectedQuantity;
                paxSsr.paidQuantity = paidQuantity;
                paxSsr.selectedQuantity = newSsrQuantity === 0 ? paidQuantity : newSsrQuantity;
                ssrChanged = paxSsr.selectedQuantity !== previousSelectedQuantity;
            }
        });
        if (ssrChanged) {
            this.afterSelectSsr();
            this.updateOtherFlights(isExternalCall);
        }
        else {
            this.updateAllPassengersSelected();
        }
    };
    SsrBoxContentBase.prototype.afterSelectSsr = function () {
        this.updateSelectedSsr();
        this.updateAllPassengersSelected();
        this.updateTotalPrice();
    };
    SsrBoxContentBase.prototype.applySsrs = function () {
        var _this = this;
        var newSsrs = [];
        this.passengers.forEach(function (p) {
            var initialSsrs = p.getInitialSelectedSsrs();
            var modifiedSsrs = p.getSelectedSsrs();
            // removed ssrs
            var toRemove = initialSsrs
                .filter(function (i) { return modifiedSsrs.findIndex(function (m) { return m.ssrCode === i.ssrCode; }) < 0; });
            toRemove.forEach(function (ssr) { return _this.applySpecificSsr(ssr.selectedQuantity, 0, ssr, p.passenger.passengerNumber); });
            // updated ssrs
            var toUpdate = initialSsrs
                .filter(function (i) { return modifiedSsrs.findIndex(function (m) { return m.ssrCode === i.ssrCode; }) >= 0; })
                .map(function (i) { return ({ old: i, new: modifiedSsrs.find(function (m) { return m.ssrCode === i.ssrCode; }) }); });
            toUpdate.forEach(function (g) {
                return _this.applySpecificSsr(g.old.selectedQuantity, g.new.selectedQuantity, g.new, p.passenger.passengerNumber);
            });
            // new ssrs
            var toAdd = modifiedSsrs
                .filter(function (m) { return initialSsrs.findIndex(function (i) { return i.ssrCode === m.ssrCode; }) < 0; });
            toAdd.forEach(function (ssr) { return _this.applySpecificSsr(0, ssr.selectedQuantity, ssr, p.passenger.passengerNumber); });
            newSsrs.push.apply(newSsrs, tslib_1.__spread(toAdd.map(function (ssr) { return ({
                ssrCode: ssr.ssrCode,
                passengerNumber: p.passenger.passengerNumber,
                flightSellKey: _this.flight.sellKey
            }); })));
        });
        return newSsrs;
    };
    SsrBoxContentBase.prototype.applySpecificSsr = function (oldQuantity, newSsrQuantity, ssr, passengerNumber) {
        var _this = this;
        var paidSSRQuantityOverride = this._paidSSROverrides.getPaidQuantity(passengerNumber, ssr.ssrCode);
        var tempNewSsrQuantity = newSsrQuantity - paidSSRQuantityOverride;
        var tempOldSsrQuantity = oldQuantity - paidSSRQuantityOverride;
        while (tempNewSsrQuantity > oldQuantity - paidSSRQuantityOverride) {
            // sell fast track only on segments where ssr is available
            if (this.ssrType == this.SsrTypeEnum.FastTrack) {
                this.flight.segments.forEach(function (segment) {
                    var flightKey = segment.departureStation.iataCode + "_" + segment.arrivalStation.iataCode;
                    if (_this.availableSsrs.find(function (ssr) { return ssr.details == flightKey; }) !== undefined) {
                        _this._ssrsService.addSsr(_this.ssrType, ssr.ssrCode, passengerNumber, segment, false);
                    }
                });
            }
            else {
                this._ssrsService.addSsr(this.ssrType, ssr.ssrCode, passengerNumber, this.flight, this.applySsrsToSegments);
            }
            tempNewSsrQuantity--;
        }
        while (newSsrQuantity - paidSSRQuantityOverride < tempOldSsrQuantity) {
            this._ssrsService.removeSsr(this.ssrType, ssr.ssrCode, tempOldSsrQuantity, passengerNumber, this.flight, this.applySsrsToSegments);
            tempOldSsrQuantity--;
        }
    };
    SsrBoxContentBase.prototype.removeAllSsrs = function (passengerIndex, keepPassengerSelection, isExternalCall) {
        var _this = this;
        if (keepPassengerSelection === void 0) { keepPassengerSelection = false; }
        if (isExternalCall === void 0) { isExternalCall = false; }
        if (!keepPassengerSelection) {
            this.selectedPassenger = passengerIndex;
        }
        this.passengers[passengerIndex]
            .getSelectedSsrs()
            .forEach(function (selectedSsr) {
            _this.selectSsr(passengerIndex, selectedSsr.ssrCode, _this.isAllowedToRemovePaidSsrs ? 0 : selectedSsr.paidQuantity, isExternalCall);
        });
        this.numberOfSelectedPassengers = this.selectedPassenger === -1 ? this.passengers.length : 1;
        this.updateOtherFlights(isExternalCall);
        this._ssrsService.isPrioritySsrSelected = false;
    };
    SsrBoxContentBase.prototype.selectPassenger = function (passengerIndex, fromCopySsrs) {
        if (fromCopySsrs === void 0) { fromCopySsrs = false; }
        this.selectedPassenger = passengerIndex;
        this.numberOfSelectedPassengers = this.selectedPassenger === -1 ? this.passengers.length : 1;
        if (!fromCopySsrs)
            this.isSsrsPopupOpened = true;
        this.updateSelectedSsr();
    };
    SsrBoxContentBase.prototype.updateOtherFlights = function (isExternalCall) {
        if (isExternalCall === void 0) { isExternalCall = false; }
        if (this.sameOptionsForAllFlights && this.enableSameOptionsForAllFlights) {
            this.copySsrsToReturnFlight();
        }
        else if (!isExternalCall && this.allFlightBoxContents && this.allFlightBoxContents.length) {
            this.allFlightBoxContents[0].sameOptionsForAllFlights = false;
        }
    };
    SsrBoxContentBase.prototype.copySsrsToReturnFlight = function () {
        var _this = this;
        if (this.allFlightBoxContents && this.allFlightBoxContents.length > 1) {
            this.passengers.forEach(function (p, pix) {
                var selectedSsrs = p.getSelectedSsrs();
                if (_this.allFlightBoxContents.length >= 3) {
                    for (var i = 0; i < _this.allFlightBoxContents.length - 1; i++) {
                        if (_this.allFlightBoxContents[i].availableSsrs.length === 0) {
                            _this.allFlightBoxContents.splice(i, 1);
                        }
                    }
                }
                var _loop_1 = function (i) {
                    var otherFlightBoxContent = _this.allFlightBoxContents[i];
                    // sync new and common items
                    selectedSsrs.forEach(function (selectedSsr) {
                        return otherFlightBoxContent.selectSsr(pix, selectedSsr.ssrCode, selectedSsr.selectedQuantity, true);
                    });
                    // sync removed items
                    var otherBoxSelectedSsrs = otherFlightBoxContent.passengers[pix].getSelectedSsrs();
                    otherFlightBoxContent.passengers[pix].ssrs = otherBoxSelectedSsrs
                        .filter(function (other) { return selectedSsrs.findIndex(function (selected) { return selected.ssrCode === other.ssrCode; }) >= 0; });
                    otherFlightBoxContent.afterSelectSsr();
                };
                for (var i = 1; i < _this.allFlightBoxContents.length; i++) {
                    _loop_1(i);
                }
            });
            for (var i = 1; i < this.allFlightBoxContents.length; i++) {
                this.allFlightBoxContents[i].selectPassenger(this.selectedPassenger, true);
            }
        }
    };
    SsrBoxContentBase.prototype.getSelectedPassengers = function (passengerIndex) {
        if (passengerIndex === -1) {
            return this.passengers;
        }
        return [this.passengers[passengerIndex]];
    };
    SsrBoxContentBase.prototype.getSeletedPassenger = function () {
        return this.selectedPassenger === -1 ? this.passengers[0] : this.passengers[this.selectedPassenger];
    };
    SsrBoxContentBase.prototype.initSelectedPassenger = function () {
        this.selectedPassenger = 0;
        this.numberOfSelectedPassengers = this.selectedPassenger === -1 ? this.passengers.length : 1;
        this.updateSelectedSsr();
    };
    SsrBoxContentBase.prototype.updateSelectedSsr = function () {
        var _this = this;
        var allSelectedSsrs = this.passengers
            .map(function (p) { return p.getSelectedSsrs(); })
            .reduce(function (a, b) { return a.concat(b); }, []);
        var soldSsrsBySsr = allSelectedSsrs
            .reduce(function (group, ssr) {
            group[ssr.ssrCode] = group[ssr.ssrCode] || 0;
            group[ssr.ssrCode] += ssr.selectedQuantity;
            return group;
        }, {});
        var soldSsrsByNest = allSelectedSsrs
            .reduce(function (group, ssr) {
            group[ssr.nest] = group[ssr.nest] || 0;
            group[ssr.nest] += ssr.selectedQuantity;
            return group;
        }, {});
        var selectedPassenger = this.getSeletedPassenger();
        var selectedSsrs = selectedPassenger.getSelectedSsrs();
        this.availableSsrs.forEach(function (avSsr) {
            var selectedSsr = selectedSsrs.find(function (s) { return s.ssrCode === avSsr.ssrCode; });
            avSsr.paidQuantity = selectedSsr ? selectedSsr.paidQuantity : 0;
            avSsr.selectedQuantity = selectedSsr ? selectedSsr.selectedQuantity : 0;
            var nestLimit = _this.nestInfo.find(function (ni) { return ni.key === avSsr.nest; });
            if (_this.selectedPassenger === -1 && avSsr.getLowestLimitPerPassenger() === 0) {
                avSsr.limitPerNest = 0;
            }
            else if (nestLimit) {
                var soldPerNest = soldSsrsByNest[avSsr.nest] || 0;
                var soldPerSsr = (_this.selectedPassenger === -1 ? (soldSsrsBySsr[avSsr.ssrCode] || 0) : avSsr.selectedQuantity);
                avSsr.limitPerNest = nestLimit.value - soldPerNest + soldPerSsr;
            }
            else {
                avSsr.limitPerNest = null;
            }
            var limit = avSsr.getLimitPerPassenger(selectedPassenger.passenger.passengerNumber);
            avSsr.isVisible = limit > 0;
        });
    };
    SsrBoxContentBase.prototype.initFlightSsrList = function () {
        var _this = this;
        if (!this._fullSsrList || !this._flight) {
            return;
        }
        var flightKey = this._flight.departureStation.iataCode + "_" + this._flight.arrivalStation.iataCode;
        var list = this._fullSsrList.find(function (ssr) { return ssr.key === flightKey; });
        list = list ? list.value : [];
        this.availableSsrs = list.map(function (s) {
            var x = new SsrModel();
            x.name = s.name;
            x.price = s.price;
            if (s.originalPrice) {
                x.originalPrice = s.originalPrice;
            }
            x.ssrCode = s.ssrCode;
            x.details = s.details;
            x.thumbnailDescription = s.thumbnailDescription;
            x.thumbnailUrl = s.thumbnailUrl;
            x.isCarryOn = s.isCarryOn;
            x.nest = s.nest;
            x.limitPerPassenger = {};
            if (s.limitPerPassenger && s.limitPerPassenger.length) {
                x.limitPerPassenger = s.limitPerPassenger.reduce(function (a, b) {
                    a[+b.key] = +b.value;
                    return a;
                }, {});
            }
            return x;
        });
        if (this._flight.segments.length > 1) {
            this.nestInfo = [];
            this._flight.segments.forEach(function (seg) {
                var segmentKey = seg.departureStation.iataCode + "_" + seg.arrivalStation.iataCode;
                var segmentNest = _this._ssrsService.nestInfo.find(function (ni) { return ni.key === segmentKey; }).value;
                segmentNest.forEach(function (sn) {
                    var existing = _this.nestInfo.find(function (n) { return n.key === sn.key; });
                    if (existing) {
                        existing.value = Math.min(existing.value, sn.value);
                    }
                    else {
                        _this.nestInfo.push({
                            key: sn.key,
                            value: sn.value
                        });
                    }
                });
            });
        }
        else {
            this.nestInfo = this._ssrsService.nestInfo.find(function (ni) { return ni.key === flightKey; }).value;
        }
        if (this._flight.segments.length > 1 && this.ssrType == this.SsrTypeEnum.Baggage) {
            this.availableSsrs.forEach(function (s) {
                s.price = 0;
            });
            this._flight.segments.forEach(function (seg) {
                var flightKey = seg.departureStation.iataCode + "_" + seg.arrivalStation.iataCode;
                var list = _this._fullSsrList.find(function (ssr) { return ssr.key === flightKey; });
                list.value.forEach(function (ssr) {
                    var index = _this.availableSsrs.findIndex(function (as) { return as.ssrCode == ssr.ssrCode; });
                    _this.availableSsrs[index].price += ssr.price;
                });
            });
        }
        this.passengers.forEach(function (p) { return _this.initPassengerSsrs(p); });
        this._flightSsrsInitialized = true;
        this.initSelectedPassenger();
        this.updateSelectedSsr();
        this.initSameSsrForReturnFlight();
    };
    SsrBoxContentBase.prototype.initPassengerSsrs = function (passenger) {
        var _this = this;
        var passengerSsrs = [];
        var paidSsrs = [];
        if (this.ssrType == this.SsrTypeEnum.FastTrack) {
            this._flight.segments.forEach(function (segment) {
                var paid = segment.paidSsrs[passenger.passenger.passengerNumber].map(function (fs) { return _this.availableSsrs.find(function (avSsr) { return avSsr.ssrCode === fs; }); })
                    .filter(function (ssr) { return ssr; });
                if (paid.length > 0) {
                    paidSsrs = paid;
                }
                var ssrs = segment.ssrs[passenger.passenger.passengerNumber].map(function (fs) { return _this.availableSsrs.find(function (avSsr) { return avSsr.ssrCode === fs; }); })
                    .filter(function (ssr) { return ssr; });
                if (ssrs.length > 0) {
                    passengerSsrs = ssrs;
                }
                _this._paidSSROverrides.updateQuantitiesForPassenger(passenger.passenger.passengerNumber, segment.paidSsrs[passenger.passenger.passengerNumber]);
            });
        }
        else {
            passengerSsrs = this._flight.ssrs[passenger.passenger.passengerNumber]
                .map(function (fs) { return _this.availableSsrs.find(function (avSsr) { return avSsr.ssrCode === fs; }); })
                .filter(function (ssr) { return ssr; });
            paidSsrs = this._flight.paidSsrs[passenger.passenger.passengerNumber]
                .map(function (fs) {
                return _this.availableSsrs.find(function (avSsr) { return avSsr.ssrCode === fs ||
                    _this._paidSSROverrides.checkOverrides(avSsr.ssrCode, fs); });
            })
                .filter(function (ssr) { return ssr; });
            this._paidSSROverrides.updateQuantitiesForPassenger(passenger.passenger.passengerNumber, this._flight.paidSsrs[passenger.passenger.passengerNumber]);
        }
        var groupBy = function (group, ssr) {
            (group[ssr.ssrCode] = group[ssr.ssrCode] || []).push(ssr);
            return group;
        };
        var ssrGroupBy = passengerSsrs.reduce(groupBy, {});
        var paidGroupBy = paidSsrs.reduce(groupBy, {});
        for (var ssrCode in paidGroupBy) {
            if (paidGroupBy.hasOwnProperty(ssrCode)) {
                var addedSsr = passenger.getOrAddInitialSelectedSsr(paidGroupBy[ssrCode][0]);
                addedSsr.paidQuantity = paidGroupBy[ssrCode].length;
                addedSsr.selectedQuantity = addedSsr.paidQuantity;
            }
        }
        for (var ssrCode in ssrGroupBy) {
            if (ssrGroupBy.hasOwnProperty(ssrCode)) {
                var addedSsr = passenger.getOrAddInitialSelectedSsr(ssrGroupBy[ssrCode][0]);
                addedSsr.selectedQuantity = ssrGroupBy[ssrCode].length + addedSsr.paidQuantity;
            }
        }
        passenger.initializeSsrs();
    };
    SsrBoxContentBase.prototype.initSameSsrForReturnFlight = function () {
        if (!this._flightSsrsInitialized || !this.allFlightBoxContents) {
            return;
        }
        if (this.enableSameOptionsForAllFlights) {
            var thisHash = this.getSeletedSsrsHash();
            var thisNestHash = this.getNestHash();
            var ssrResult = thisHash !== '';
            var nestResult = true;
            for (var i = 1; i < this.allFlightBoxContents.length; i++) {
                ssrResult = ssrResult && thisHash === this.allFlightBoxContents[i].getSeletedSsrsHash();
                nestResult = nestResult && thisNestHash === this.allFlightBoxContents[i].getNestHash()
                    && this.allFlightBoxContents[i].availableSsrs.some(function (s) { return s.isVisible; });
            }
            this.sameOptionsForAllFlights = ssrResult;
            this.enableSameOptionsForAllFlights = nestResult;
        }
    };
    SsrBoxContentBase.prototype.allPassengersHaveTheSameSsrs = function () {
        var _this = this;
        var selectedSsrs = this.passengers[0].getSelectedSsrs();
        // if (selectedSsrs.length == 0) {
        // this._ssrsService.isPrioritySsrSelected = false; // reconsiderLuggagePrioritySsrSelection
        // }
        var allPassengersEnabled = this.nestInfo
            .filter(function (nest) { return _this.availableSsrs.map(function (avSsr) { return avSsr.nest; }).indexOf(nest.key) >= 0; })
            .some(function (ni) { return ni.value < _this.passengers.length; });
        var result = true;
        for (var i = 1; i < this.passengers.length; i++) {
            var paxSsrs = this.passengers[i].getSelectedSsrs();
            result = result && paxSsrs.length === selectedSsrs.length;
            if (!result) {
                break;
            }
            selectedSsrs.forEach(function (s) {
                // let ssrModel: SsrModel[] = this.passengers.map(p => p.getSelectedSsrs()).reduce((a, b) => a.concat(b), []);
                // if (s[0].name === "PBRD" && s[0].price && s[0].price > 0) {
                //     this._ssrsService.isPrioritySsrSelected = true; // goToNextStep
                // }
                // else {
                //     this._ssrsService.isPrioritySsrSelected = false; // reconsiderLuggagePrioritySsrSelection
                // }
                // result = result &&
                //     this.passengers[i]
                //         .getSelectedSsrs()
                //         .findIndex(ps => ps.ssrCode === s.ssrCode && ps.selectedQuantity === s.selectedQuantity) >= 0;
            });
        }
        return result && !allPassengersEnabled;
    };
    SsrBoxContentBase.prototype.getSeletedSsrsHash = function () {
        var hash = '';
        var ssrsHash = '';
        this.passengers.forEach(function (p, pix) {
            ssrsHash += p.getSelectedSsrs().filter(function (f) { return f.selectedQuantity !== f.paidQuantity; })
                .sort(function (a, b) { return a.ssrCode > b.ssrCode ? 1 : a.ssrCode === b.ssrCode ? 0 : -1; })
                .map(function (s) { return s.ssrCode + '|' + s.selectedQuantity; })
                .join('|');
            hash += pix + '|' + ssrsHash;
        });
        if (!ssrsHash.length) {
            return '';
        }
        return hash;
    };
    SsrBoxContentBase.prototype.getNestHash = function () {
        var nestCodes = this.availableSsrs.map(function (avSsr) { return avSsr.nest; });
        var hash = this.nestInfo
            .filter(function (nest) { return nestCodes.indexOf(nest.key) >= 0; })
            .sort(function (a, b) { return a.key > b.key ? 1 : a.key === b.key ? 0 : -1; })
            .map(function (ni) { return ni.key + '|' + ni.value; })
            .join('||');
        return hash;
    };
    SsrBoxContentBase.prototype.updateAllPassengersSelected = function () {
        this.allPassengersEnabled = this.allPassengersHaveTheSameSsrs();
        return this.allPassengersEnabled;
    };
    SsrBoxContentBase.prototype.updateTotalPrice = function () {
        var totalPrice = this.passengers
            .map(function (p) { return p.getSelectedSsrs(); })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .reduce(function (a, b) { return a + (b.selectedQuantity - b.paidQuantity) * b.price; }, 0);
        this.totalPriceChanged.next(totalPrice);
    };
    SsrBoxContentBase.prototype.getSelectedPassengerName = function () {
        var _this = this;
        var selectedPax = this.passengers.map(function (pax) { return pax.passenger; }).filter(function (pax) { return pax.passengerNumber === _this.selectedPassenger; })[0];
        if (selectedPax) {
            return selectedPax.name.first + ' ' + selectedPax.name.last;
        }
    };
    return SsrBoxContentBase;
}());
export { SsrBoxContentBase };
