import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { TranslateService } from 'src/app/common-modules/blue-air-common/translator/translate.service';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'booking-ptw-confirmation',
  templateUrl: './ptw-confirmation.component.html',
  styleUrls: ['./ptw-confirmation.component.scss']
})
export class PtwConfirmationComponent implements OnInit {

  @Input() confirmed: boolean;
  @Input() errors: string[];
  @Output() continue: EventEmitter<string> = new EventEmitter();
  confirmedModalId = 'confirmedModalId';
  assetsPath: string;

  public code: string;
  @ViewChild('confirmationForm', { static: true }) confirmationForm: NgForm;
  @ViewChild('confirmedModal', { static: true }) confirmedModal: DefaultModalComponent; 
  
  constructor(private translationService: TranslateService) {
    this.assetsPath = environment.assetsPath;
  }

  ngOnInit() {
  }

  submit() {
    for (const key in this.confirmationForm.controls) {
      if (this.confirmationForm.form.controls.hasOwnProperty(key)) {
        const element = this.confirmationForm.form.controls[key];
        if (element instanceof FormControl) {
          (element as FormControl).markAsDirty({ onlySelf: true });
        }
      }
    }

    if (this.code) {
      this.continue.next(this.code);
    }
  }

  confirmationCodeChanged() {
    this.errors.length = 0;
  }

  showPopUp(){
    this.confirmedModal.openPopup((isOk) => {
      if (!isOk) {
        location.href = location.origin
      } else {
        var culture = this.translationService.culture;

        location.href = location.origin + "/" + culture.split('-')[0].toLowerCase() + "/" + culture.split('-')[1].toLowerCase() + "/member-profile";
      }
    })
  }

}
