import { Component, OnInit, Input, ElementRef, Renderer2, ViewChild, OnDestroy } from '@angular/core';
import { BlueModalService } from './blue-modal.service';
import { BlueModal } from './blue-modal';
import { BlueRenderer } from '../services/blue-renderer.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'blue-modal',
  templateUrl: './blue-modal.component.html',
  styleUrls: ['./blue-modal.component.scss'],
  providers: [BlueRenderer]
})
export class BlueModalComponent extends BlueModal implements OnInit, OnDestroy {

  @Input() id: string;
  @Input() closeBtnLabel: string;
  @Input() cssClass: string;
  @Input() showCloseBtn = true;

  @ViewChild('dialogRef', { static: true }) dialogRef: ElementRef;

  isOpened: boolean;
  protected closeCallback: () => void;

  constructor(private modalService: BlueModalService, private el: ElementRef, private render: Renderer2,
    private blueRenderer: BlueRenderer) {
    super();
  }

  ngOnInit() {
    this.modalService.add(this);
  }

  ngOnDestroy() {
    this.blueRenderer.updateBodyScroll(false);
    this.modalService.remove(this.id);
  }

  open(closeCallback: () => void = null): void {
    this.closeCallback = closeCallback;
    this.isOpened = true;
    this.render.removeAttribute(this.dialogRef.nativeElement, 'aria-hidden');
    this.render.setStyle(this.dialogRef.nativeElement, 'display', 'block');
    this.blueRenderer.updateBodyScroll(true);
  }

  close(): void {
    this.isOpened = false;
    this.render.setAttribute(this.dialogRef.nativeElement, 'aria-hidden', 'true');
    this.render.removeStyle(this.dialogRef.nativeElement, 'display');
    this.blueRenderer.updateBodyScroll(false);

    if (this.closeCallback) {
      this.closeCallback();
    }
  }

}
