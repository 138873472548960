import * as tslib_1 from "tslib";
import { OnInit, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { SsrsService } from '../../../extras/ssrs.service';
import { SsrType } from '../../../extras/ssr-type.enum';
import { SsrBoxContentBase } from '../../../extras/ssr-box-content-base/ssr-box-content-base';
import { LoadingSpinnerService } from '../../../common-modules/blue-air-common/loading-spinner.service';
import { CheckinAccordionItemComponent } from '../../../shared/checkin-accordion-item/checkin-accordion-item.component';
import { EventEmitter } from '@angular/core';
import { Constants } from 'src/app/constants';
var SinglePassengerSpecialAssistanceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SinglePassengerSpecialAssistanceComponent, _super);
    function SinglePassengerSpecialAssistanceComponent(el, renderer, ssrsService, loadingSpinnerService) {
        var _this = _super.call(this, ssrsService) || this;
        _this.el = el;
        _this.renderer = renderer;
        _this.ssrsService = ssrsService;
        _this.loadingSpinnerService = loadingSpinnerService;
        _this.spinnerId = 'spinnerId';
        _this.ssrsCustomIcons = {};
        _this.saveChangesEvent = new EventEmitter();
        _this.ssrType = SsrType.SpecialAssistance;
        _this.updateWindowWidth(window.innerWidth);
        return _this;
    }
    Object.defineProperty(SinglePassengerSpecialAssistanceComponent.prototype, "passengerNumber", {
        set: function (value) {
            this.selectedPassenger = value;
        },
        enumerable: true,
        configurable: true
    });
    SinglePassengerSpecialAssistanceComponent.prototype.onResize = function (event) {
        this.updateWindowWidth(event.target.innerWidth);
    };
    SinglePassengerSpecialAssistanceComponent.prototype.ngOnInit = function () {
        this.generateSpinnerId();
        this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        if (!this.isSSRInfoSet) {
            this._ssrsService.getSsrsInfo();
        }
        _super.prototype.ngOnInit.call(this);
        this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
        this.ssrsCustomIcons = this.ssrsService.setupSpecialAssistanceCustomIcons(this.availableSsrs);
    };
    SinglePassengerSpecialAssistanceComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.dropdownJourneyHeader = this.el.nativeElement.querySelector('.checkin-acc-head');
        this.ssrListClosePopupBtn = this.el.nativeElement.querySelector('.close-popup');
        this.ssrListClosePopupBtnBottom = this.el.nativeElement.querySelector('.close-popup-bottom');
        this.renderer.listen(this.dropdownJourneyHeader, 'click', function (event) {
            _this.isSsrsPopupOpened = _this.mobileMatches;
        });
        this.renderer.listen(this.ssrListClosePopupBtn, 'click', function (event) {
            _this.checkinAccordion.toggleExpanded();
        });
        this.renderer.listen(this.ssrListClosePopupBtnBottom, 'click', function (event) {
            _this.checkinAccordion.toggleExpanded();
        });
    };
    SinglePassengerSpecialAssistanceComponent.prototype.allPassengersHaveTheSameSsrs = function () {
        var _this = this;
        var selectedSsrs = this.passengers[this.selectedPassenger].getSelectedSsrs();
        var result = true;
        selectedSsrs.forEach(function (s) {
            for (var i = 1; i < _this.passengers.length; i++) {
                result = result &&
                    _this.passengers[i]
                        .getSelectedSsrs()
                        .findIndex(function (ps) { return ps.ssrCode === s.ssrCode && ps.selectedQuantity === s.selectedQuantity; }) >= 0;
            }
        });
        return result;
    };
    SinglePassengerSpecialAssistanceComponent.prototype.initSelectedPassenger = function () {
        if (this.passengers.length === 1) {
            this.selectedPassenger = 0;
        }
        this.numberOfSelectedPassengers = this.selectedPassenger === -1 ? this.passengers.length : 1;
        this.updateSelectedSsr();
    };
    SinglePassengerSpecialAssistanceComponent.prototype.generateSpinnerId = function () {
        this.spinnerId = this.spinnerId + '_' +
            this.flight.departureStation.iataCode + '_' +
            this.flight.arrivalStation.iataCode + '_' +
            this.selectedPassenger;
    };
    SinglePassengerSpecialAssistanceComponent.prototype.toggleSsr = function (ssr) {
        _super.prototype.selectSsr.call(this, this.selectedPassenger, ssr.ssrCode, ssr.selectedQuantity > 0 ? 0 : (ssr.selectedQuantity + 1), false);
        this.saveChangesEvent.emit(this.selectedPassenger);
    };
    SinglePassengerSpecialAssistanceComponent.prototype.updateWindowWidth = function (width) {
        this.mobileMatches = width < Constants.mobileScreenWith;
        this.checkIsExpanded();
    };
    SinglePassengerSpecialAssistanceComponent.prototype.checkIsExpanded = function () {
        if (this.mobileMatches) {
            this.isExpanded = false;
            return;
        }
        this.isExpanded = true;
    };
    return SinglePassengerSpecialAssistanceComponent;
}(SsrBoxContentBase));
export { SinglePassengerSpecialAssistanceComponent };
