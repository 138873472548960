

<div class="unsubscribe-section">
  <div class="wrapper">
    <div class="text-holder" *ngIf="!unsubscribed;else thankYou">
      <h2>{{'Are you sure?' | translate: 'find-my-bookings' }}   </h2>
      <p>{{ 'You will lose all specials offers for booking code' | translate: 'find-my-bookings' }} <strong>{{pnr}}</strong></p>
      <button class="btn btn-primary" (click)="unsubscribeNotifications()">{{ 'Unsubscribe'  | translate: 'find-my-bookings' }}</button>
    </div>
    <ng-template #thankYou>
      <div class="text-holder">
        <h2>{{'We`re sorry to see you go' | translate: 'find-my-bookings' }}   </h2>
        <p>{{ 'You are no longer subscribed to our emails' | translate: 'find-my-bookings' }}</p>
      </div>
    </ng-template>
  </div>
</div>


<section class="section content-banner" style="background-image: url(assets/img/content/unsubscribe_web.png); display:none">
  <div class="wrapper">
    <div class="banner-inner text-holder">
      <h2>{{'Are you sure?' | translate: 'find-my-bookings' }} </h2>
      <p>{{ 'You will lose all specials offers for booking code' | translate: 'find-my-bookings' }} {{pnr}}</p>
      <div class="btn-holder">
        <a href="#" (click)="unsubscribeNotifications()" class="btn btn-primary btn-white">{{ 'Unsubscribe'  | translate: 'find-my-bookings' }}</a>
      </div>
    </div>
  </div>
</section>
