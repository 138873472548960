import { SsrType } from "./ssr-type.enum";

export enum SsrSellingType {
    ByJourney = 1,
    BySegment = 2
}

export abstract class SsrSellingTypeHelper {
    public static GetSellingType(ssrType: SsrType): SsrSellingType {
        switch (ssrType) {
            case SsrType.Meal:
                return SsrSellingType.BySegment;
            default:
                return SsrSellingType.ByJourney;
        }
    }

    public static IsSsrTypeSoldByJourney(ssrType: SsrType): boolean {
        return SsrSellingTypeHelper.GetSellingType(ssrType) === SsrSellingType.ByJourney;
    }

    public static IsSsrTypeSoldBySegment(ssrType: SsrType): boolean {
        return SsrSellingTypeHelper.GetSellingType(ssrType) === SsrSellingType.BySegment;
    }

    
}