import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../core/config.service';
import { PassengerModel } from '../core/models/passenger-model';
import { PassengerDocumentsModel } from '../core/models/passenger-documents-model';

@Injectable()
export class DocumentsService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  saveDocuments(passengers: PassengerModel[], isInternational: boolean = false): Promise<any> {
    const docs: PassengerDocumentsModel[] = [];

    passengers.forEach(passenger => {
      passenger.documents.passengerNumber = passenger.passengerNumber;
      docs.push(passenger.documents);
      if (passenger.hasInfant) {
        passenger.infant.documents.passengerNumber = passenger.passengerNumber;
        docs.push(passenger.infant.documents);
      }
    });

    return this.http.post(this.configService.CheckinPassengers, {
      'checkinPassengerDocuments': {
        'inputTravelDocuments': docs,
        'isInternational': isInternational
      }
    }).toPromise();
  }
}
