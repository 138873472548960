import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitcamelcasestring'
})
export class SplitCamelcaseStringPipe implements PipeTransform {

  constructor() { }

  transform(value: string): string {
   return value.replace(/([^A-Z])([A-Z])/g, '$1 $2');
  }
}
