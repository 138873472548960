import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';

@Component({
  selector: 'check-in-ssr-box-content-special-assistance',
  templateUrl: './ssr-box-content-special-assistance.component.html',
  styleUrls: ['./ssr-box-content-special-assistance.component.scss']
})
export class SsrBoxContentSpecialAssistanceComponent extends SsrBoxContentBase implements OnInit {
  ssrsCustomIcons: {[ssrCode: string]: string} = {};
  uniqueId: number;

  @Output() selectSsrEvent2 = new EventEmitter();

  constructor(private ssrsService: SsrsService) {
    super(ssrsService);

    this.ssrType = SsrType.SpecialAssistance;
    this.uniqueId = Math.floor(Math.random() * 1000) + 1;
  }

  ngOnInit() {
    super.ngOnInit();

    this.selectedPassenger = 0;
    this.allPassengersEnabled = false;
  }

  protected initFlightSsrList() {
    super.initFlightSsrList();

    this.ssrsCustomIcons = this.ssrsService.setupSpecialAssistanceCustomIcons(this.availableSsrs);
  }

  resetSsr() {
    this.selectSsr(null, "", 0); 
    this.selectSsrEvent(null);
  }

  selectSsrEvent(passengerIndex: number = null) {
    this.selectSsrEvent2.emit(passengerIndex);
  }

  checkSameSsrOptionsForAllFlights() {
    this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
    this.selectSsrEvent(null);
  }  
}