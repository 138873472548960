<div  class="select-list" aria-haspopup="true" [ngClass]="{'expanded' : isOpened, 'destination-picker': this.isDestination }"  >
  <span class="overlay" aria-hidden="true" [style.display]="isOpened ? 'inline': ''" (click)="focusOut(false)"></span>
  <input type="text" class="selected" [ngClass]="{ 'active' : isOpened, 'not-active': !selectedStation }" [formControl]="searchField" #searchInput
    [placeholder]="placeholder" autocomplete="off" (focus)="focusIn()" (keydown.tab)="focusOut(true)"/>
  <div class="dest-big-overlay js-dest-overlay" [style.display]="isOpened ? 'block' : ''">
      <div class="dest-overlay-inner" *ngIf="!mobileMatches">
          <div class="left">
              <h3>{{ 'Where do you want to go?' | translate:'change-flight' }}</h3>
              <!-- <div class="item">
                  <h4><i class="font-ico-clock"></i> {{ 'Recent searches' | translate:'change-flight' }}</h4>
                  <ul role="menubar">
                      <li role="menuitem" ng-repeat="recentSearch in recentSearchesFrom" 
                          ng-click="selectedStation(recentSearch)"><span class="primary" >{{recentSearch.Name' | translate:'change-flight' }}</span></li>
                  </ul>
              </div> -->
              <div class="item">
                  <h4><i class="font-ico-globe"></i> {{ 'All destinations' | translate:'change-flight' }}</h4>
                  <ul role="menubar">
                      <li *ngFor="let countryItem of countries | slice:0:halfInt(countries.length)" (click)="selectCountry(countryItem)"
                        [ngClass]="{'selected-item': countryItem.isSelected, 'selectable': countryItem.isActive, 'not-active': !countryItem.isActive }">
                          <span class="primary">{{ countryItem.name }}</span>
                      </li>
                  </ul>
                  <ul role="menubar">
                      <li *ngFor="let countryItem of countries | slice:halfInt(countries.length)" (click)="selectCountry(countryItem)"
                      [ngClass]="{'selected-item': countryItem.isSelected, 'selectable': countryItem.isActive, 'not-active': !countryItem.isActive }">
                          <span class="primary">{{ countryItem.name }}</span> 
                      </li>
                  </ul>
              </div>
          </div>
          <div class="right">
              <h3>{{ 'Which airport?' | translate:'change-flight' }}</h3>
              <div class="item">
                  <ul role="menubar">
                    <li role="menuitem" *ngFor="let stationItem of stations | slice:0:halfInt(stations.length)" (click)="selectStation(stationItem)"> 
                        <span class="primary">{{ stationItem.name }} ({{ stationItem.code }}) </span>
                    </li>
                  </ul>
                  <ul role="menubar">
                    <li role="menuitem" *ngFor="let stationItem of stations | slice:halfInt(stations.length)" (click)="selectStation(stationItem)"> 
                        <span class="primary">{{ stationItem.name }} ({{ stationItem.code }}) </span>
                    </li>
                  </ul>
              </div>
          </div>
      </div>
      <div class="dest-overlay-mobile" *ngIf="mobileMatches">
        <a role="button" href="#" class="close" (click)="$event.preventDefault();focusOut(false)">
            <span class="sr-only">{{ 'Close destination overlay' | translate: 'change-flight' }})</span>
            <i class="font-ico-close-menu"></i>
        </a>
        <h2>{{placeholder}}</h2>
        <div class="form-row">
            <div class="one-field">
                <input type="text" placeholder="{{'Country, city' | translate: 'change-flight'}}" [formControl]="searchField" (keydown.tab)="focusOut(true)" #searchInputMobile/>
            </div>
        </div>
        <div class="countries-list mobile-country-list">
            <div class="country-item" [ngClass]="{'open': countryItem.isSelected }" *ngFor="let countryItem of countriesMobile">
                <span class="country" (click)="selectCountry(countryItem)">
                    {{ countryItem.name }}
                </span>
                <ul role="menuitem" style="display: block" *ngIf="countryItem.isSelected">
                    <li role="menuitem" *ngFor="let stationItem of stationsByCountry[countryItem.code]" (click)="selectStation(stationItem)">
                        <span class="primary">{{ stationItem.name }} ({{ stationItem.code }}) </span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
  <span class="selected-icon"><span class="sr-only">Arrow Icon</span></span>
</div>

