import * as tslib_1 from "tslib";
import { BookingStepsService } from '../core/booking-steps.service';
import { SsrsService } from './ssrs.service';
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { FlowManagerService } from '../core/flow-manager.service';
var CanActivateExtrasOnBookingService = /** @class */ (function (_super) {
    tslib_1.__extends(CanActivateExtrasOnBookingService, _super);
    function CanActivateExtrasOnBookingService(flowManager, ssrsService, bookingSteps) {
        var _this = _super.call(this, bookingSteps, flowManager.applicationFlowService) || this;
        _this.ssrsService = ssrsService;
        return _this;
    }
    CanActivateExtrasOnBookingService.prototype.canActivate = function (route, state) {
        var _this = this;
        var superPromise = _super.prototype.canActivate.call(this, route, state);
        return superPromise.then(function (superResponse) {
            if (!superResponse) {
                return false;
            }
            return Promise.all([
                _this.applicationFlowService.loadFlowInfo().then(function () { return _this.applicationFlowService.loadPriceBreakdown(); }),
                _this.ssrsService.getSsrsInfo().toPromise()
            ])
                .then(function (data) { return true; });
        });
    };
    return CanActivateExtrasOnBookingService;
}(BaseCanActivateService));
export { CanActivateExtrasOnBookingService };
