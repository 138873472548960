import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-blueair-products',
  templateUrl: './blueair-products.component.html',
  styleUrls: ['./blueair-products.component.scss']
})
export class BlueairProductsComponent implements OnInit {
  displaySize: 'desktop' | 'tablet' | 'mobile';

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWindowWidth(event.target.innerWidth);
  }

  constructor() {
    this.updateWindowWidth(window.innerWidth);
   }

  ngOnInit() {
  }

  updateWindowWidth(width: number) {
    if (width < 600)  {
      this.displaySize = 'mobile';
    } else if (width < 750) {
      this.displaySize = 'tablet';
    } else {
      this.displaySize = 'desktop';
    }
  }
}
