import { BookingService } from './../../core/booking.service';
import { BookingStepsService } from './../../core/booking-steps.service';
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { Component, OnInit, Input, HostListener, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';
import { SsrModel } from '../ssr-model';
import { Constants } from '../../constants';
import { PassengerSsrModel } from '../passenger-ssr-model';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { CheckInLuggageService } from '../../core/check-in-luggage.service';
import { debounceTime, takeUntil, distinctUntilKeyChanged, filter } from 'rxjs/operators';
import { SSL_OP_NETSCAPE_DEMO_CIPHER_CHANGE_BUG } from 'constants';
import { debug } from 'console';

@Component({
  selector: 'check-in-ssr-box-content-luggage',
  templateUrl: './ssr-box-content-luggage.component.html',
  styleUrls: ['./ssr-box-content-luggage.component.scss']
})
export class SsrBoxContentLuggageComponent
  extends SsrBoxContentBase
  implements OnInit {
  private $unsubscribe: Subject<void> = new Subject();
  summary: any[];
  summaryTotal: number;
  recordLocator: string;
  promoDiscount: number = null;
  modalId = 'pbagModal';
  onNeedSave$: Subject<void> = new Subject<void>()
  private passengersSelection = new Map();

  @Output() saveChangesEvent = new EventEmitter();




  ngOnInit() {

    super.ngOnInit();

    // this.luggageService.init(this.flight.flightUniqueId, this.passengers);
    // this.luggageService
    //   .$checkingLuggageChanged
    //   .pipe(
    //     filter(item => item.id === this.flight.flightUniqueId)
    //   )
    //   .subscribe(item => {

    //     this.selectSsr(
    //       item.index,
    //       item.ssrCode,
    //       item.qty,
    //       false
    //     );
    //     debugger;
    //     this.updateSummary();
    //     this.saveChangesEvent.emit();
    //     console.log(JSON.stringify(item));
    //   })

    //this.passengers.forEach( (p, pix) => this.selectSsr(pix, null, 0));
    this.passengers.forEach((p, index) => {
      this.passengersSelection.set(index, this.hasPassengerBags(p));

      if (!this.ssrsService.noBagsSync.find(b => b.passengerIndex == index && b.flight == this.flight.sellKey)) {
        this.ssrsService.noBagsSync.push({
          flight: this.flight.sellKey,
          passengerIndex: index,
          noBags: true
        });
      }
    })
    this.setLuggagePassengerMap(this.passengers[0]);


    this.onNeedSave$
      .pipe(
        takeUntil(this.$unsubscribe),
        debounceTime(1000)
      )
      .subscribe(_ => {
        this.saveChangesEvent.emit();
      })
  }
  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobileMatches = event.target.innerWidth < Constants.mobileScreenWith;
  }

  @ViewChild('pbagModal', { static: true }) pbagModal: DefaultModalComponent;
  // bagsNumber: number = 1;
  // bagsNumber$: BehaviorSubject<number> = new BehaviorSubject<number>(1);

  constructor(public ssrsService: SsrsService,
    private bookingSteps: BookingStepsService,
    private bookingService: BookingService,
    private router: Router,
    protected luggageService: CheckInLuggageService,
    private elRef: ElementRef) {

    super(ssrsService);
    this.ssrType = SsrType.Baggage;
    this.recordLocator = this.bookingService.getBooking().bookingDetail.recordLocator;
    this.promoDiscount = this.ssrsService.getPromoDiscount(this.ssrType);
  }

  onEnableSameOptionsForAllFlightsChanged(value: boolean) {
    super.onEnableSameOptionsForAllFlightsChanged(value);

    if (!value) return;
    this.onNeedSave$.next();
  }
  // public set sameOptionsForAllFlights(val: boolean) {
  //   this._sameOptionsForAllFlights = val;
  //   if (val) {
  //     this.copySsrsToReturnFlight();
  //   }
  //   this.saveChangesEvent.emit();
  // }

  onSelectSameValueForAllFlights(selected: boolean) {
    if (selected) {

    }
    this.onNeedSave$.next();
  }

  increaseBagsNumber(index: number, ssrCode: string, newQty: number) {
    this.selectSsr(index, ssrCode, newQty)

    this.onNeedSave$.next();
  }

  decreaseBagsNumber(index: number, ssrCode: string, newQty: number) {
    this.selectSsr(index, ssrCode, newQty)
    this.onNeedSave$.next();
  }

  selectSsr(passengerIndex: number = null, ssrCode: string = null, newSsrQuantity: number = 0, isExternalCall: boolean = false) {
    if (!ssrCode && passengerIndex !== null && newSsrQuantity === 0 && !this.mobileMatches) {
      const passengers = this.getSelectedPassengers(passengerIndex);
      passengers.forEach(p => {
        const ssrs = p.getSelectedSsrs();
        ssrs.forEach(s => super.selectSsr(passengerIndex, s.ssrCode, newSsrQuantity, isExternalCall));
      });
    } else {
      super.selectSsr(passengerIndex, ssrCode, newSsrQuantity, isExternalCall);
    }
    this.updateSummary();

    if (passengerIndex == undefined && this.selectedPassenger != undefined) {
      passengerIndex = this.selectedPassenger;
    }

    this.noBags(passengerIndex, false)
    this.passengersSelection.set(passengerIndex, this.hasPassengerBags(this.passengers[passengerIndex]));
    this.setLuggagePassengerMap(this.passengers[passengerIndex]);
  }

  resetSsr(passengerIndex?: number) {
    super.selectSsr(passengerIndex, "SBAG", 0);
    super.selectSsr(passengerIndex, "BAG", 0);
    super.selectSsr(passengerIndex, "XSBG", 0);
    this.onNeedSave$.next();
  }

  afterSelectSsr() {
    super.afterSelectSsr();
    this.updateSummary();


  }

  protected initFlightSsrList() {
    super.initFlightSsrList();

    this.availableSsrs = this.availableSsrs.sort((a, b) => a.price > b.price ? 1 : a.price === b.price ? 0 : -1);
    this.updateSummary();
  }

  updateSummary() {
    const groupBy = this.passengers
      .map(p => p.getSelectedSsrs())
      .reduce((a, b) => a.concat(b), [])
      .reduce((group, b) => {
        group[b.ssrCode] = group[b.ssrCode] || 0;
        group[b.ssrCode] += b.selectedQuantity;
        return group;
      }, {});

    this.summary = Object.keys(groupBy).map(ssrCode => ({ ssrCode: ssrCode, count: groupBy[ssrCode] }));
    this.summary = this.summary.sort((a, b) => {
      const ai = this.availableSsrs.findIndex(s => s.ssrCode === a.ssrCode);
      const bi = this.availableSsrs.findIndex(s => s.ssrCode === b.ssrCode);

      return ai > bi ? 1 : ai === bi ? 0 : -1;
    });
    this.summaryTotal = this.summary.reduce((a, b) => a + b.count, 0);
  }

  isSsrQuantityAvailable(quantity: number, passengerSsr: PassengerSsrModel, ssr: SsrModel) {
    const selectedSsr = passengerSsr.getSelectedSsr(ssr);
    let selectedQuantity = 0;
    let paidQuantity = 0;

    if (selectedSsr) {
      selectedQuantity = selectedSsr.selectedQuantity;
      paidQuantity = selectedSsr.paidQuantity;
    }

    const isAvailablePerAlreadyPaid = selectedSsr ? quantity >= selectedSsr.paidQuantity : true;
    if (!isAvailablePerAlreadyPaid) {
      return false;
    }

    const totalQuantity = passengerSsr.ssrs.reduce((a, b) => a + b.selectedQuantity, 0);
    const isAvailablePerPassenger = totalQuantity - selectedQuantity + quantity < 4;

    if (!isAvailablePerPassenger) {
      return false;
    }

    const selectedQuantityPerFlight = this.passengers
      .reduce((a, b) => a.concat(b.ssrs), [])
      .filter((ps: SsrModel) => ps.ssrCode === ssr.ssrCode)
      .reduce((a, b) => a + b.selectedQuantity, 0);
    const isAvailablePerNest = ssr.limitPerNest == null || selectedQuantityPerFlight - selectedQuantity + quantity <= ssr.limitPerNest;

    return isAvailablePerNest;
  }

  showLuggageBagDetails() {
    this.ssrsService.isDisplayedBagDetailsModal = true;
  }

  hasPassengerBags(passenger) {
    if (!passenger || !passenger.ssrs) {
      return false;
    }
    return true;

    let bagQty = (passenger.ssrs.filter(s => s.ssrCode == 'BAG'))[0] ? (passenger.ssrs.filter(s => s.ssrCode == 'BAG'))[0].selectedQuantity : 0;
    let sBagQty = (passenger.ssrs.filter(s => s.ssrCode == 'SBAG'))[0] ? (passenger.ssrs.filter(s => s.ssrCode == 'SBAG'))[0].selectedQuantity : 0;
    let xBagQty = (passenger.ssrs.filter(s => s.ssrCode == 'XSBG'))[0] ? (passenger.ssrs.filter(s => s.ssrCode == 'XSBG'))[0].selectedQuantity : 0;

    let noBagsSelected = this.checkNoBagsSelected(this.passengers.indexOf(passenger));

    return ((bagQty + sBagQty + xBagQty > 0) || noBagsSelected) ? true : false;
  }

  checkAllPassengerBags() {
    let allHaveOptionSelected = true;
    for (let qty of this.passengersSelection.values()) {
      if (!qty) {
        allHaveOptionSelected = false;
        break;
      }
    }

    return allHaveOptionSelected;
  }

  setLuggagePassengerMap(passenger) {
    if (!passenger || !passenger.passenger) {
      return;
    }
    this.ssrsService.luggagePassengersMap.set(passenger.passenger.flightUniqueId, this.checkAllPassengerBags());
  }

  noBags(passengerIndex: number, setBool: boolean) {
    if (passengerIndex == undefined && this.selectedPassenger != undefined) {
      passengerIndex = this.selectedPassenger
    }

    let noBag = this.ssrsService.noBagsSync.find(n => n.flight == this.flight.sellKey && n.passengerIndex == passengerIndex);

    if (noBag && passengerIndex != undefined) {
      noBag.noBags = setBool;
    }

    if (setBool) {
      this.passengersSelection.set(passengerIndex, this.hasPassengerBags(this.passengers[passengerIndex]));
      this.setLuggagePassengerMap(this.passengers[passengerIndex]);
      this.resetSsr(passengerIndex);
    }
  }

  checkNoBagsSelected(passengerIndex: number): boolean {
    if (passengerIndex == undefined && this.selectedPassenger != undefined) {
      passengerIndex = this.selectedPassenger
    }

    let noBag = this.ssrsService.noBagsSync.find(n => n.flight == this.flight.sellKey && n.passengerIndex == passengerIndex);

    return noBag ? noBag.noBags : false;
  }

  getPassengerError(passengerIndex: number): boolean {
    return !this.passengersSelection.get(passengerIndex);
  }

  scrollTo() {
    this.elRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  mobileShowAllOptionMessage(flightkey: string): boolean {
    return !(this.ssrsService.luggagePassengersMap.get(flightkey));
  }
}
