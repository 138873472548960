import { Component, OnInit, Input } from '@angular/core';
import { CheckinService } from '../../core/checkin.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'check-in-send-boarding-pass-over-email',
  templateUrl: './send-boarding-pass-over-email.component.html',
  styleUrls: ['./send-boarding-pass-over-email.component.scss']
})
export class SendBoardingPassOverEmailComponent implements OnInit {
  email: string;
  passengerNumber: number;
  flightSellKey: string;
  passengerName: any;

  sendEmailModalId = 'sendEmailModal';
  sendEmailSpinnerId = 'sendEmailSpinner';

  isOpened: boolean;
  valid: boolean;
  submitted: boolean;
  responseReceived: boolean;
  responseOk: boolean;

  constructor(private checkinService: CheckinService, private translateService: TranslateService,
    private modalService: BlueModalService, private loadingSpinnerService: LoadingSpinnerService) { }

  ngOnInit() {
  }


  openPopup(passengerNumber: number = null, flightSellKey: string = null, passengerName: any = null) {
    this.passengerNumber = passengerNumber;
    this.flightSellKey = flightSellKey;
    this.passengerName = passengerName;

    this.isOpened = true;

    this.responseReceived = false;
    this.responseOk = false;
    this.submitted = false;
    this.valid = true;

    this.modalService.open(this.sendEmailModalId, () => this.isOpened = false);
  }

  sendBoardingPassOnEmail(form: NgForm) {
    this.submitted = true;

    if (!this.email) {
      this.valid = false;
      return;
    }

    this.valid = true;

    this.responseReceived = false;

    if (form.valid) {
      this.loadingSpinnerService.showSpinnerById(this.sendEmailSpinnerId);

      this.checkinService.sendBoardingPassOnEmail(
        this.translateService.culture,
        this.email,
        this.passengerNumber,
        this.flightSellKey,
      ).then(
        result => {
          this.responseReceived = true;
          this.responseOk = result.sendBoardingPass.sent === true;
          this.loadingSpinnerService.hideSpinnerById(this.sendEmailSpinnerId);
        },
        () => this.loadingSpinnerService.hideSpinnerById(this.sendEmailSpinnerId)
        );
    }
  }
}
