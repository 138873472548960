import { Component, OnInit, NgZone, ViewChild, ElementRef, AfterViewInit, Input, OnChanges, SimpleChanges, EventEmitter,
  Output, HostListener, Renderer2 } from '@angular/core';
import * as Pikaday from 'pikaday';
import * as moment from 'moment';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigService } from '../../core/config.service';
import { Constants } from '../../constants';
import { BlueRenderer } from '../../common-modules/blue-air-common/services/blue-renderer.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DomHelper } from '../dom-helper';
import { BookingFlowService } from 'src/app/core/booking-flow.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'calendar-date-picker',
  templateUrl: './calendar-date-picker.component.html',
  styleUrls: ['./calendar-date-picker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CalendarDatePickerComponent, multi: true },
    BlueRenderer
  ]
})
export class CalendarDatePickerComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  static timestamp: string = (new Date()).getTime().toString();

  private standardDateFormat = "MM/DD/YYYY";
  /** Format - the format to be used for inputs and output of date  */
  @Input() format: string;
  
  /** Minimum Date Moment - the minimum date that can be selected (Moment JS) */
  private minDateMoment: moment.Moment;

  /** Maximum Date Moment - the maximum date that can be selected (Moment JS) */
  private maxDateMoment: moment.Moment;

  /** Minimum Date - the minimum date that can be selected. Must respect the provided input */
  @Input() minDate: string;

  /** Maximum Date - the maximum date that can be selected. Must respect the provided input */
  @Input() maxDate: string;

  /** Range Start - The starting of the date range if the picker is used as a range. Must respect the provided input */
  @Input() rangeStart: string;

  /** Range End - The end of the date range if the picker is used as a range. Must respect the provided input */
  @Input() rangeEnd: string;

  /** Departure - departure station code (IATA) */
  @Input() departure: string;

  /** Destination - destination station code (IATA) */
  @Input() destination: string;

  /** Header - The header of the picker */
  @Input() header: string;

  /** Select event - Triggers when a date is selected. The value is using the provided format  */
  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('pickerInput', { static: true }) pickerInput: ElementRef;
  @ViewChild('pickerContainer', { static: true }) pickerContainer: ElementRef;

  /** Picker (Pikaday plugin) of calendar date picker */
  picker: Pikaday;

  /** Determines whether the calendar date picker is opened  */
  isOpened: boolean;

  /** Determines whether the current view is on mobile */
  isOnMobile: boolean;

  /** The Model - the selected date in the provided format */
  private model: string;

  /** Schedule cache of calendar date picker */
  private scheduleCache: ScheduleCache;

  /** The current window width */
  private currentWindowWidth: number;

  /** Gets schedule key composed from departure and destination */
  private get scheduleKey(): string { return `${this.departure}${this.destination}`; }

  /** Regex for validation the allowed characters from the input */
  private allowedCharactersRegex = new RegExp('[0-9\-\/]');

  private onTouchedCb: () => void;
  private onChangeCb: (_: any) => void;

  private journeys: Array<any> = [];
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWindowWith(event.target.innerWidth);
  }

  constructor(private zone: NgZone, private translate: TranslateService, private renderer: Renderer2, private blueRenderer: BlueRenderer,
    private configService: ConfigService, private http: HttpClient, private bookingFlowService: BookingFlowService) {
    this.format = 'DD/MM/YYYY';
    this.scheduleCache = {};
    this.updateWindowWith(window.innerWidth);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.minDate) {
      this.minDateMoment = moment(changes.minDate.currentValue, this.format);

      if (this.picker) {
        this.zone.runOutsideAngular(() => {
          if (this.minDateMoment.isValid()) {
            const newDate = this.minDateMoment.toDate();
            this.picker.setMinDate(newDate);
            this.picker.gotoDate(newDate);
          }
          else {
            this.picker.setMinDate(null);
            this.picker.gotoDate(moment().toDate());
          }

          if (this.isOpened) {
            this.picker.draw();
          }
        });
      }
    }

    if (changes.maxDate) {
      this.maxDateMoment = moment(changes.maxDate.currentValue, this.format);

      if (this.picker) {
        this.zone.runOutsideAngular(() => {
          if (this.maxDateMoment.isValid()) {
            const newDate = this.maxDateMoment.toDate();
            this.picker.setMaxDate(newDate);
            this.picker.gotoDate(newDate);
          } 
          else {
            this.picker.setMaxDate(null);
            this.picker.gotoDate(moment().toDate());
          }

          if (this.isOpened) {
            this.picker.draw();
          }
        });
      }
    }

    if (changes.rangeStart) {
      this.setRange(this.rangeStart, 'startRange');
    }

    if (changes.rangeEnd) {
      this.setRange(this.rangeEnd, 'endRange');
    }

    if (changes.departure || changes.destination) {
      this.updateSchedule();
    }
  }

  ngAfterViewInit() {
    this.updateWindowWith(window.innerWidth);

    this.zone.runOutsideAngular(() => {
      this.picker = new Pikaday({
        field: this.pickerInput.nativeElement,
        container: this.pickerContainer.nativeElement,
        numberOfMonths: this.isOnMobile ? 1 : 2,
        firstDay: 1,
        maxDate: this.maxDateMoment && this.maxDateMoment.isValid() ? this.maxDateMoment.toDate() : moment().add(2, 'years').toDate(),
        minDate: this.minDateMoment && this.minDateMoment.isValid() ? this.minDateMoment.toDate() : null,
        format: this.format,
        i18n: {
          previousMonth: this.translate.instant('Previous Month', 'date-picker'),
          nextMonth: this.translate.instant('Next Month', 'date-picker'),
          months: moment.months(),
          weekdays: moment.weekdays(),
          weekdaysShort: moment.weekdaysMin()
        },
        onSelect: (selectedDate: Date) => {
          this.zone.run(() => {
            this.model = moment(selectedDate).format(this.format);
            this.close();
            this.onChangeCb(this.model);
            this.select.next(this.model);
          });
        },
        onClose: () => {
          this.zone.run(() => this.close());
        },
        onDraw: () => {
              const pickerElem = this.picker.el as HTMLElement;
              const monthSelect = pickerElem.getElementsByClassName('pika-select-month').item(0);
              this.renderer.addClass(monthSelect.parentElement, 'month');

              const yearSelect = pickerElem.getElementsByClassName('pika-select-year').item(0);
              this.renderer.addClass(yearSelect.parentElement, 'year');
        },
        disableDayFn: (dayToBeChecked: Date) => this.isDayDisabled(dayToBeChecked)
      });

      if (this.rangeStart) {
        this.setRange(this.rangeStart, 'startRange');
      }

      if (this.rangeEnd) {
        this.setRange(this.rangeEnd, 'endRange');
      }
    });
  }

  /**
   * Opens calendar date picker
   */
  open() {
    if (this.isOpened) {
      return;
    }
    
    this.isOpened = true;

    this.zone.runOutsideAngular(() => {
      this.checkMoveFlightEligibility();
      this.picker.show();
      // setTimeout(() => this.picker.show(), 20);
    });

    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
    DomHelper.IncreaseAppHeight(this.renderer);
  }
  
  private checkMoveFlightEligibility() {
    this.bookingFlowService.loadFlowInfo().then((booking) => {
      if (booking) {
        if (booking.convertedJourneys && booking.convertedJourneys.journeys && booking.convertedJourneys.journeys.length > 0) {
          this.journeys = booking.convertedJourneys.journeys;        
          
          let isStationMoveAvailable = this.journeys.some(j=>j.segments && j.segments.some(s=>s.legs && s.legs.some(l=>l.legInfo.status =="Canceled")));
          let isSCHGMoveAvailable = this.journeys.some(j=>j.segments && j.segments.some(s=>s.changeReasonCode && s.changeReasonCode =="SCHG" && (Math.abs(Number (new Date(s.originalDepartureTime)) - Number (new Date(s.sTD))) / 36e5) >= 3));
          let isTimeChangeMoveAvailable = this.journeys.some(j=>j.segments && j.segments.some(s=> (!s.changeReasonCode || s.changeReasonCode && s.changeReasonCode !="SCHG") && s.paxSegments.some(p => p.timeChanged) && (Math.abs(Number (new Date(s.originalDepartureTime)) - Number (new Date(s.sTD))) / 36e5) >= 3));

          if (isStationMoveAvailable || isSCHGMoveAvailable || isTimeChangeMoveAvailable) {
            let departureDate = moment(this.journeys[0].segments[0].sTD).format(this.standardDateFormat);
            let arrivalDate = moment(this.journeys[this.journeys.length - 1].segments[0].sTD).format(this.standardDateFormat);
            let departureStation = this.journeys[0].segments[0].departureStation;
            let correspondingDate = this.departure == departureStation ? departureDate : arrivalDate;
              
            this.minDateMoment = moment(correspondingDate).subtract(13, "days");
            this.picker.setMinDate(this.minDateMoment.toDate());
            this.picker.gotoDate(this.minDateMoment.toDate());

            this.maxDateMoment = moment(correspondingDate).add(29, "days");
            this.picker.setMaxDate(this.maxDateMoment.toDate());
            this.picker.gotoDate(this.maxDateMoment.toDate());
          }
        }
      }
    })
  }  

  /**
   * Closes calendar date picker component
   * @param event click event if any
   */
  close(event?: any) {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    if (!this.isOpened) {
      return;
    }
    this.isOpened = false;
    this.zone.runOutsideAngular(() => {
      this.picker.hide();
    });

    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
    DomHelper.ResetAppHeight(this.renderer);
  }

  /**
   * Handles the keypress on the input.
   * @returns true if the pressed key is valid, otherwise false
   */
  keyPress(event?: any): boolean {
    return this.allowedCharactersRegex.test(event.key);
  }

  /**
   * Makes updates to the component based on the new window width
   * @param newWidth - the new width of the window
   */

  private updateWindowWith(newWidth: number) {
    if (this.currentWindowWidth === newWidth) {
      return;
    }
    this.currentWindowWidth = newWidth;
    this.isOnMobile = newWidth < Constants.mobileScreenWith;
    if (this.picker) {
      this.zone.runOutsideAngular(() => {
        this.picker.config({
          numberOfMonths: this.isOnMobile ? 1 : 2
        });
        this.picker.adjustCalendars();
        if (this.isOpened) {
          this.picker.draw();
        }
      });
    }

    this.blueRenderer.updateBodyScrollOnMobile(this.isOpened);
  }

  /**
   * Updates schedule data based on departure and destinations component inputs
   */
  private updateSchedule() {
    if (this.departure && this.destination) {
        const url = this.configService.ScheduleUrl;

        const params = new HttpParams()
          .set('_', CalendarDatePickerComponent.timestamp);

        const k = this.scheduleKey;
        this.http.get(`${url}/${k}.json`, { params: params }).subscribe((data: string[]) => {
          this.scheduleCache[k] = data.map(d => moment(d, 'YYYY-MM-DD'));
          if (this.isOpened) {
            this.zone.runOutsideAngular(() => this.picker.draw());
          }
        });
    }

    if (this.isOpened) {
      this.zone.runOutsideAngular(() => {
        this.picker.draw();
      });
    }
  }

  /**
   * Determines whether day is disabled
   * @param dayToBeChecked - the day to be checked
   * @returns true if day is disabled
   */
  private isDayDisabled(dayToBeChecked: Date): boolean {
    if (!this.scheduleCache[this.scheduleKey]) {
      return false;
    }
    const dayToBeCheckedM = moment(dayToBeChecked);
    const isEnabled = this.scheduleCache[this.scheduleKey].some(sch => sch.isSame(dayToBeCheckedM));
    return !isEnabled;
  }

  /**
   * Sets calendar range  depending on the range type (startRange | endRange)
   * @param newDate - The new date to set range to. If empty or in incorrect format defaults to [null]
   * @param rangeType - The range type. Posibile values 'startRange' | 'endRange'
   */
  private setRange(newDate: string, rangeType: 'startRange' | 'endRange') {
    if (this.picker) {
      const r = moment(newDate, this.format);
      this.zone.runOutsideAngular(() => {
        let fn: (d: Date) => void;
        switch (rangeType) {
          case 'startRange':
            fn = (d) =>  this.picker.setStartRange(d);
            break;
          case 'endRange':
            fn = (d) =>  this.picker.setEndRange(d);
            break;
        }
        fn(r.isValid() ? r.toDate() : null);
      });
    }
  }

  writeValue(obj: any): void {
    this.model = obj;

    if (this.picker) {
      const newD = moment(this.model, this.format);
      if (newD.isValid()) {
        this.picker.setDate(newD.toDate(), true);
      } else {
        this.picker.setDate(null, true);
      }
    }
  }
  registerOnChange(fn: any): void {
    this.onChangeCb = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCb = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(this.pickerInput.nativeElement, 'disabled', 'disabled');
    } else {
      this.renderer.removeAttribute(this.pickerInput.nativeElement, 'disabled');
    }
    this.close();
  }
}

interface ScheduleCache {
  [journey: string]: moment.Moment[];
}
