import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session.service';
import { Observable, from as fromPromise } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import { LoadingSpinnerService } from './loading-spinner.service';
import { TranslateService } from './translator/translate.service';

const InterceptorSkipHeader = 'X-Skip-Interceptor';

@Injectable()
export class BlueHttpInterceptorService implements HttpInterceptor {

  constructor(public sessionService: SessionService, private loadingSpinner: LoadingSpinnerService,
    private translateService: TranslateService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has(InterceptorSkipHeader)) {
      if (!this.sessionService.isSessionValid()) {
        this.sessionService.resetSession();
        // if something is wrong with session wait for reload and then add headers
        return fromPromise(this.sessionService.load()).pipe(mergeMap(() => this.defferIntercept(req, next)));
      }
      return this.defferIntercept(req, next);
    }
    const headers = req.headers.delete(InterceptorSkipHeader);
    return next.handle(req.clone({ headers }));
  }

  private defferIntercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingSpinner.showSpinner(req.url);
    req = req.clone({
      headers: req.headers
        .set('X-Session-Id', this.sessionService.sessionId)
        .set('X-Session-Sig', this.sessionService.sessionSig)
        .set('X-Language', this.translateService.culture)
    });

    return next.handle(req).pipe(tap(
      (event: HttpEvent<any>) => {
        if (event.type !== 0) {
          this.loadingSpinner.hideSpinner(req.url);
          this.sessionService.keepAlive();
        }
      },
      (err: any) => {
        this.loadingSpinner.hideSpinner(req.url);
      }
    ));
  }
}
