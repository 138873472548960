import * as moment from 'moment';

export class CarHire {
     affiliateCode: string;
     driversAge: string;
     prefLang: string;
     results: string;
     prefCurrency: string;
     ng: string;
     dateJump = false;
     affUrl: string;
     pickup: Pickup;
     return: Return;
     serverName: string;

     constructor(affiliateCode1: string, driversAge1: string, prefLang1: string, results1: string,
          affUrl1: string, prefCurrency1: string, ng1: string, dateJump1: boolean, pickup1: Pickup, return1: Return) {
               this.affiliateCode = affiliateCode1;
               this.driversAge = driversAge1;
               this.prefLang = prefLang1;
               this.results = results1;
               this.affUrl = affUrl1;
               this.prefCurrency = prefCurrency1;
               this.ng = ng1;
               this.dateJump = dateJump1;
               this.affUrl = affUrl1;
               this.pickup = new Pickup(pickup1.IATACode, pickup1.Date);
               this.return = new Return(return1.Date);
          }
}

export class Pickup {
     IATACode: string;
     Date: moment.Moment;

     constructor(iataCode: string, date: moment.Moment) {
          this.IATACode = iataCode;
          this.Date = date;
     }
}

export class Return {
     Date: moment.Moment;

     constructor(date: moment.Moment) {
          this.Date = date;
     }
}
