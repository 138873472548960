/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./agent-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "../../layout/layout.component.ngfactory";
import * as i5 from "../../layout/layout.component";
import * as i6 from "../agent-settings/agent-settings.component.ngfactory";
import * as i7 from "../agent-settings/agent-settings.component";
import * as i8 from "../../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i9 from "../../../core/profile.service";
import * as i10 from "../../../core/config.service";
import * as i11 from "../agent-contact-details/agent-contact-details.component.ngfactory";
import * as i12 from "../agent-contact-details/agent-contact-details.component";
import * as i13 from "../../../common-modules/blue-air-common/resource.service";
import * as i14 from "../../../common-modules/blue-air-common/loading-spinner.service";
import * as i15 from "../../address-book-box/address-book-box.component.ngfactory";
import * as i16 from "../../address-book-box/address-book-box.component";
import * as i17 from "./agent-info.component";
var styles_AgentInfoComponent = [i0.styles];
var RenderType_AgentInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgentInfoComponent, data: {} });
export { RenderType_AgentInfoComponent as RenderType_AgentInfoComponent };
export function View_AgentInfoComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 9, "profile-layout", [], null, null, null, i4.View_LayoutComponent_0, i4.RenderType_LayoutComponent)), i1.ɵdid(2, 114688, null, 0, i5.LayoutComponent, [], { title: [0, "title"] }, null), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, 0, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "profile-in-agent-settings", [], null, null, null, i6.View_AgentSettingsComponent_0, i6.RenderType_AgentSettingsComponent)), i1.ɵdid(6, 114688, null, 0, i7.AgentSettingsComponent, [i8.BlueModalService, i9.ProfileService, i10.ConfigService, i3.TranslateService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "profile-agent-contact-details", [], null, null, null, i11.View_AgentContactDetailsComponent_0, i11.RenderType_AgentContactDetailsComponent)), i1.ɵdid(8, 245760, null, 0, i12.AgentContactDetailsComponent, [i8.BlueModalService, i9.ProfileService, i13.ResourceService, i14.LoadingSpinnerService, i10.ConfigService, i3.TranslateService], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "profile-address-book-box", [], null, null, null, i15.View_AddressBookBoxComponent_0, i15.RenderType_AddressBookBoxComponent)), i1.ɵdid(10, 114688, null, 0, i16.AddressBookBoxComponent, [i8.BlueModalService, i9.ProfileService, i13.ResourceService, i14.LoadingSpinnerService], null, null)], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), "Agent profile", "profile-info")), ""); _ck(_v, 2, 0, currVal_0); _ck(_v, 6, 0); _ck(_v, 8, 0); _ck(_v, 10, 0); }, null); }
export function View_AgentInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-agent-info", [], null, null, null, View_AgentInfoComponent_0, RenderType_AgentInfoComponent)), i1.ɵdid(1, 114688, null, 0, i17.AgentInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgentInfoComponentNgFactory = i1.ɵccf("check-in-agent-info", i17.AgentInfoComponent, View_AgentInfoComponent_Host_0, {}, {}, []);
export { AgentInfoComponentNgFactory as AgentInfoComponentNgFactory };
