<div class="checkin-pets-options" [(checkInSsrListDisplay)]="isSsrsPopupOpened">
  <check-in-ssr-passenger-picker [passengers]="passengers" [ssrType]="ssrType"
    [allPassengersEnabled]="allPassengersEnabled" [selectedPassenger]="selectedPassenger"
    (selectPassenger)="selectPassenger($event)" (removeSsr)="removeAllSsrs($event)" (resetSsr)="resetSsr()">
  </check-in-ssr-passenger-picker>
  <div class="pets-list js-ssr-list">
    <div class="pets-list-inner">
      <button type="button" class="close-popup" aria-hidden="true">
        <span class="font-ico-close-menu">
          <span class="sr-only">{{ 'Click to close airport checkin overlay' | translate:'extras' }}</span>
        </span>
      </button>
      <h2 aria-hidden="true" class="js-ssr-list-title">{{ 'Pick your airport checkin' | translate:'extras' }}</h2>
      <div *ngIf="mobileMatches">
        <div *ngIf="selectedPassenger == -1 else singlePassengerName">{{ 'All passengers' | translate: 'extras' }}</div>
        <ng-template #singlePassengerName>{{ getSelectedPassengerName() }}</ng-template>
      </div>
      <ng-container *ngFor="let ssr of availableSsrs;">
        <div class="pet-item" *ngIf="ssr && ssr.isVisible">
          <div class="header-wrap-item">
            <div class="circle-wrap"><span class="font-ico-circle"></span><i class="font-ico-early-checkin"></i></div>
            <div class="pet-item-left">
              <span class="pet-desc">{{ ssr.ssrCode + '-subtitle' | translate:'extras' }}</span>
              <h3>{{ ssr.ssrCode + '-title' | translate:'extras' }}</h3>
              <check-in-ssr-price-display [originalPrice]="ssr.originalPrice"
                [currentPrice]="ssr.price"></check-in-ssr-price-display>
            </div>
          </div>
          <div class="pet-item-right">
            <button type="button" class="pet-number pet-number-single"
              [ngClass]="{ 'pet-number-selected' : ssr.selectedQuantity > 0 }"
              attr.aria-label="{{'Click to add airport checkin' | translate: 'extras'}}"
              (click)="selectSsrEvent(selectedPassenger, ssr.ssrCode, 1)"
              [disabled]="ssr.paidQuantity == 1 || ssr.price === 0">
              <span class="pet-number-inner">
                <strong *ngIf="ssr.selectedQuantity == 0 && ssr.price !== 0">{{ 'Add' | translate:'extras' }}</strong>
                <strong *ngIf="ssr.selectedQuantity > 0 || ssr.price === 0">{{ 'Added' | translate:'extras' }}</strong>
                <ng-container *ngIf="ssr.paidQuantity == 1"> {{'PAID' | translate:'extras' }}</ng-container>
              </span>
            </button>
          </div>
        </div>
      </ng-container>
      <div *ngIf='notAvailableForSegments.length !== 0'>{{'Not available on segment' | translate: 'extras'}}:
        <span *ngFor="let segment of notAvailableForSegments">
          {{segment.departureStation.name}} - {{segment.arrivalStation.name}}
        </span>
      </div>
      <button type="button" class="btn btn-primary close-popup-bottom" aria-hidden="true">
        {{ 'Confirm and Continue' | translate:'extras' }}
      </button>
    </div>
  </div>
  <check-in-ssr-same-options-for-return-flight [visible]="enableSameOptionsForAllFlights"
    [(isSelected)]="sameOptionsForAllFlights"></check-in-ssr-same-options-for-return-flight>
</div>