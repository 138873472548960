import { Injectable, ApplicationRef } from '@angular/core';
import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/el';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/he';
import 'moment/locale/it';
import 'moment/locale/nn';
import 'moment/locale/pt';
import 'moment/locale/ro';
import 'moment/locale/sv';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class TranslateService {
  private _sessionStorageLangKey: string = "selected-language";
  private _currentScope: string;
  private _translations: any;
  private _languageCookieName = 'OrchardCurrentCulture-FrontEnd';
  private _translationsUrl: string;
  private _collectTranslationsUrl: string;
  private _collectTranslations: boolean;
  private _collectedTranslations: any;

  public culture: string;

  constructor(private http: HttpClient) {
    this._translations = {};
    const lang = this.getLangFromLocalCache();
    this.culture = lang || 'en-GB';
  }

  load(translationsUrl: string, collectTranslationsUrl: string, collectTranslations: boolean = false): Promise<any> {
    this._translationsUrl = translationsUrl;
    this._collectTranslations = collectTranslations;
    this._collectTranslationsUrl = collectTranslationsUrl;

    sessionStorage.setItem(this._sessionStorageLangKey, this.culture);

    moment.locale([this.culture]);

    if (this._translationsUrl) {
      this._translationsUrl = this.culture ? this._translationsUrl.replace('{lang}',
        this.culture.toLowerCase().replace('-', '/')) : this._translationsUrl;

      return this.http
        .get(this._translationsUrl, { headers: this.getHttpHeaders() })
        .toPromise()
        .then(
          tr => {
            try {
              this._translations = tr;
            } catch (e) {
            }
          },
          () => { }
        );
    }
    return Promise.resolve();
  }

  public instant(key: string, scope?: string, args?: string[]) {
    let translation = this.translate(key, scope);

    if (args && args.length) {
      for (let i = 0; i < args.length; i++) {
        translation = translation.replace(`\{${i}\}`, args[i]);
      }
      // format
      return translation;
    }

    return translation;
  }

  public getScopeItems(scope: string): string[] {
    const accumulator: { value: string, sortN: number }[] = [];
    let scopedObj: any;

    if (scope && (scopedObj = this._translations[scope])) {
      for (const scopedKey in scopedObj) {
        if (scopedObj.hasOwnProperty(scopedKey) && scopedObj[scopedKey]) {
          accumulator.push({
            value: scopedObj[scopedKey],
            sortN: +scopedKey.split('-').slice(-1)[0]
          });
        }
      }
    }
    return accumulator.sort2(item => item.sortN).map(item => item.value);
  }

  private translate(key: string, scope?: string): string {
    // private perform translation
    let translation = key;
    let scopedObj: any;
    let tempTranslation: string;
    this.collect(key, scope);

    if (scope && (scopedObj = this._translations[scope]) && (tempTranslation = scopedObj[key])) {
      translation = tempTranslation;
    } else {
      if ((scopedObj = this._translations['no-context']) && (tempTranslation = scopedObj[key])) {
        translation = tempTranslation;
      }
    }
    return translation;
  }
  public use(scope: string): void {
    // set current scope
    this._currentScope = scope;
  }

  private collect(key: string, scope: string): void {
    if (this._collectTranslations && this._collectTranslationsUrl) {
      const collectScope = scope ? scope : 'no-context';

      this._collectedTranslations = this._collectedTranslations || {};

      this._collectedTranslations[collectScope] = this._collectedTranslations[collectScope] || {};

      if (this._collectedTranslations[collectScope][key] !== true) {
        this.http.patch(this._collectTranslationsUrl, {
          code: key,
          controller: collectScope
        }, { headers: this.getHttpHeaders() }).subscribe(() => { }, () => { });

        this._collectedTranslations[collectScope][key] = true;
      }

    }
  }

  private getLangFromLocalCache(): string {
    return sessionStorage.getItem(this._sessionStorageLangKey);
  }

  private getLangFromCookie() {
    const c = document.cookie.split('; ');
    for (let i = 0; i < c.length; i++) {
      const cookie = c[i].split('=');
      if (cookie.length === 2 && cookie[0] === this._languageCookieName) {
        return cookie[1];
      }
    }

    return null;
  }

  private getHttpHeaders(): HttpHeaders {
    return new HttpHeaders().set('X-Skip-Interceptor', '');
  }

}
