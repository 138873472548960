import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'customDate'
})
export class CustomDatePipe implements PipeTransform {
  formats: CustomDateFormat = {
    time: 'HH:mm',
    daymonth: 'DD MMM',
    date: 'DD-MM-YYYY',
    isodate: 'YYYY-MM-DD',
    fulldate: 'ddd DD MMM',

    fulldateyear: 'DD MMM YYYY',
    longfulldateyear: 'dddd DD MMMM, YYYY'

  };

  transform(value: Date, format: string): any {
    const momentDate = moment(value);
    const lowerCaseFormat = format.toLowerCase();
    if (this.formats.hasOwnProperty(lowerCaseFormat)) {
      return momentDate.format(this.formats[lowerCaseFormat]);
    }
    return momentDate.toString();
  }

}


export class CustomDateFormat {
  // using moment js formats
  time: string;
  date: string;
  daymonth: string;
  isodate: string;
  fulldate: string;
  fulldateyear: string;
  longfulldateyear: string;
  
}
