import { OnInit, OnChanges, Renderer2, ElementRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BookingFlowService } from 'src/app/core/booking-flow.service';
var BlueSelectListComponent = /** @class */ (function () {
    function BlueSelectListComponent(renderer, elementRef, bookingFlowService) {
        this.renderer = renderer;
        this.elementRef = elementRef;
        this.bookingFlowService = bookingFlowService;
        this._items = [];
        this.onTouchedCb = function () { };
        this.onChangeCb = function (_) { };
        this.minimumSearchLength = 2;
        this.expanded = false;
        this.filteredItems = [];
        this.enableSearch = false;
        this.textProperty = 'text';
        this.valueProperty = 'value';
    }
    Object.defineProperty(BlueSelectListComponent.prototype, "items", {
        set: function (items) {
            this._items = items;
            if (this.value) {
                this.writeValue(this.value);
            }
            this.filterItems();
        },
        enumerable: true,
        configurable: true
    });
    BlueSelectListComponent.prototype.ngOnInit = function () {
    };
    BlueSelectListComponent.prototype.ngOnChanges = function () {
        this.filterItems();
    };
    BlueSelectListComponent.prototype.searchChanged = function (newValue) {
        this.search = newValue;
        this.selectedItem = null;
        this.filterItems();
    };
    BlueSelectListComponent.prototype.selectItem = function ($event, item) {
        this.selectedItem = item;
        this.search = item[this.textProperty];
        this.value = item[this.valueProperty];
        this.onChangeCb(this.value);
        this.setExpanded(false);
    };
    BlueSelectListComponent.prototype.setExpanded = function (value) {
        this.expanded = value;
        this.onTouchedCb();
        this.filterItems(value);
    };
    BlueSelectListComponent.prototype.focusOut = function () {
        if (!this.selectedItem && this.filteredItems.length) {
            this.selectItem(null, this.filteredItems[0]);
        }
        else {
            this.setExpanded(false);
        }
    };
    BlueSelectListComponent.prototype.filterItems = function (reset) {
        var _this = this;
        if (reset === void 0) { reset = false; }
        if (reset || !this.search || this.search.length < this.minimumSearchLength) {
            this.filteredItems = !this.lazySearch ? this._items : [];
            return;
        }
        var reg = new RegExp(this.search, 'i');
        this.filteredItems = this._items.filter(function (val) {
            return reg.test(val[_this.textProperty]);
        });
    };
    BlueSelectListComponent.prototype.writeValue = function (obj) {
        var _this = this;
        if (!obj) {
            this.value = undefined;
            this.search = undefined;
            return;
        }
        this.value = obj;
        if (this._items) {
            this.selectedItem = this._items.find(function (i) { return i[_this.valueProperty] === obj; });
        }
        if (this.textProperty && this.selectedItem) {
            this.search = this.selectedItem[this.textProperty];
        }
    };
    BlueSelectListComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCb = fn;
    };
    BlueSelectListComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCb = fn;
    };
    BlueSelectListComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
    };
    return BlueSelectListComponent;
}());
export { BlueSelectListComponent };
