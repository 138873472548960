/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./passenger-name-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./passenger-name-display.component";
var styles_PassengerNameDisplayComponent = [i0.styles];
var RenderType_PassengerNameDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PassengerNameDisplayComponent, data: {} });
export { RenderType_PassengerNameDisplayComponent as RenderType_PassengerNameDisplayComponent };
function View_PassengerNameDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.cssClass, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.passenger.name.first; var currVal_2 = _co.passenger.name.last; _ck(_v, 1, 0, currVal_1, currVal_2); }); }
export function View_PassengerNameDisplayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PassengerNameDisplayComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.passenger; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PassengerNameDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-passenger-name-display", [], null, null, null, View_PassengerNameDisplayComponent_0, RenderType_PassengerNameDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i3.PassengerNameDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PassengerNameDisplayComponentNgFactory = i1.ɵccf("check-in-passenger-name-display", i3.PassengerNameDisplayComponent, View_PassengerNameDisplayComponent_Host_0, { passenger: "passenger", cssClass: "cssClass" }, {}, []);
export { PassengerNameDisplayComponentNgFactory as PassengerNameDisplayComponentNgFactory };
