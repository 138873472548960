import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { PassengerSsrModel } from "../extras/passenger-ssr-model";
import { SsrModel } from "../extras/ssr-model";


export class CheckInLuggageModel {

  constructor(
    public smallBagsNo: number,
    public bigBagsNo: number,
    public totalBagsNo: number,
    public passengerSsr: PassengerSsrModel,
    public $changeSmall: Subject<SsrSaveModel>,
    public $changeBig: Subject<SsrSaveModel>
  ) { }

  get upperDisabled(): boolean {
    return this.totalBagsNo === 4;
  }

  getLowerDisabled(ssr: SsrModel): boolean {

    return ssr.name === SMALL_BAG ? this.smallBagsNo === 0 : this.bigBagsNo === 0;
  }
}

declare type SsrSaveModel = { id: string, index: number, ssrCode: string, qty: number }
const SMALL_BAG: string = 'SBAG';
const BIG_BAG: string = 'BAG';
@Injectable({
  providedIn: 'root'
})
export class CheckInLuggageService implements OnDestroy {
  private $unsubscribe: Subject<void> = new Subject();
  passengersLuggageModel: Map<string, CheckInLuggageModel[]> = new Map<string, CheckInLuggageModel[]>();
  $checkingLuggageChanged: Subject<SsrSaveModel> = new Subject<SsrSaveModel>();

  constructor() {
  }

  init(id: string, passengers: PassengerSsrModel[]) {
    if (!passengers) return;
    //delete the map key for the received flight unique id
    this.passengersLuggageModel.delete(id);
    passengers.forEach(ssrModel => {
      const flightId = ssrModel.passenger.flightUniqueId;

      let luggageModel: CheckInLuggageModel[] = [];
      if (!this.passengersLuggageModel.has(flightId)) {
        this.passengersLuggageModel.set(flightId, luggageModel);
      } else {
        luggageModel = this.passengersLuggageModel.get(flightId)
      }

      const model = ssrModel.ssrs.reduce<CheckInLuggageModel>((model, currentItem) => {
        if (currentItem.name === SMALL_BAG) {
          model.smallBagsNo = currentItem.selectedQuantity
        } else {
          model.bigBagsNo = currentItem.selectedQuantity
        }
        model.totalBagsNo += currentItem.selectedQuantity;
        return model;
      }, new CheckInLuggageModel(
        0,
        0,
        0,
        <PassengerSsrModel>{ ...ssrModel },
        new Subject<SsrSaveModel>(),
        new Subject<SsrSaveModel>()
      ));

      model.$changeBig
        .pipe(
          takeUntil(this.$unsubscribe),
          debounceTime(700)
        ).subscribe(model => {
          this.$checkingLuggageChanged.next(model);
        });

      model.$changeSmall
        .pipe(
          takeUntil(this.$unsubscribe),
          debounceTime(700)
        ).subscribe(model => {
          this.$checkingLuggageChanged.next(model);
        })
      luggageModel.push(model);

    });
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  getForFlightId(flightId: string): CheckInLuggageModel[] {
    return this.passengersLuggageModel.get(flightId)
  }

  decreaseBagsNumber(id: string, index: number,
    model: CheckInLuggageModel,
    bagType: string) {
    if (model.totalBagsNo > 0) {
      if (bagType === BIG_BAG) {
        if (model.bigBagsNo === 0) {
          return;
        }
        model.bigBagsNo--;
        model.$changeBig.next({ id, index, ssrCode: bagType, qty: model.bigBagsNo });
      } else {
        if (model.smallBagsNo === 0) {
          return;
        }
        model.smallBagsNo--;
        model.$changeSmall.next({ id, index, ssrCode: bagType, qty: model.smallBagsNo });
      }
      model.totalBagsNo--;
    }
  }

  increaseBagsNumber(id: string, index: number,
    model: CheckInLuggageModel,
    bagType: string) {

    if (model.totalBagsNo < 4) {
      if (bagType === BIG_BAG) {
        model.bigBagsNo++;
        model.$changeBig.next({ id, index, ssrCode: bagType, qty: model.bigBagsNo });
      } else {
        model.smallBagsNo++;
        model.$changeSmall.next({ id, index, ssrCode: bagType, qty: model.smallBagsNo });
      }
      model.totalBagsNo++;
    }
  }

  // get totalBagsNo(): number {
  //   return this.passengersLuggageModel.reduce((total, item) => {
  //     return total += item.totalBagsNo;
  //   }, 0);
  // }
}
