import * as tslib_1 from "tslib";
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { BookingStepsService } from '../core/booking-steps.service';
import { FlowManagerService } from '../core/flow-manager.service';
var CanActivatePassengersService = /** @class */ (function (_super) {
    tslib_1.__extends(CanActivatePassengersService, _super);
    function CanActivatePassengersService(flowManager, steps) {
        return _super.call(this, steps, flowManager.applicationFlowService) || this;
    }
    return CanActivatePassengersService;
}(BaseCanActivateService));
export { CanActivatePassengersService };
