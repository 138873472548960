export class PaymentMethodModel {
    private _code: string;
    set code(value: string) {
        this._code = value;
        this.updateLogo();
    }
    get code(): string {
        return this._code;
    }
    group: string;
    name: string;
    isSelected: boolean;
    logo: string;
    details: any;

    private updateLogo(): void {
        switch (this._code) {
            case 'CCVISAMC':
            case 'VI':
                this.logo = 'visa.png';
                break;
            case 'MC':
                this.logo = 'mastercard.png';
                break;
            case 'MA':
                this.logo = 'maestro.png';
                break;
            case 'AX':
                this.logo = 'payment-amex.png';
                break;
            case 'DS':
                this.logo = 'payment-discover.png';
                break;
            case 'VO':
                this.logo = 'payment-VO.png';
                break;
            case 'AP':
                this.logo = 'payment-AP.png';
                break;
            case 'AG':
                this.logo = 'payment-AG.png';
                break;
            case 'AC':
                this.logo = 'payment-AC.png';
                break;
            case 'AF':
                this.logo = 'payment-AF.png';
                break;
            case 'AW':
                this.logo = 'payment-AW.png';
                break;
            case 'MK':
                this.logo = 'payment-MK.png';
                break;
            case 'BE':
                this.logo = 'paribas.png';
                break;
            case 'HB':
                this.logo = 'payment-HB.svg';
                break;
            case 'CO':
                this.logo = 'payment-CO.png';
                break;
            case 'BCMC':
                this.logo = 'mistercash.png';
                break;
            case 'PL':
                this.logo = 'paypal.png';
                break;
            case 'BR':
            case 'BF':
                this.logo = 'brd.png';
                break;
            case 'RF':
                this.logo = 'raiffeisen.png';
                break;
            case 'RC':
                this.logo = 'ico-posta-romana.png';
                break;
            case 'BP':
                this.logo = 'bancpost.png';
                break;
            case 'AV':
                this.logo = 'avantaj.png';
                break;
            case 'QW':
                this.logo = 'qiwi.png';
                break;
            case 'ZEBRA_PAY':
            case 'ZB':
                this.logo = 'zebrapay.png';
                break;
            case 'PY':
                this.logo = 'paypoint.png';
                break;
            case 'ITRANSFER_BT':
            case 'BT':
                this.logo = 'transilvania.png';
                break;
            case 'AIRPLUS':
                this.logo = 'airplus.png';
                break;
            case 'UATP':
                this.logo = 'uatp.png';
                break;
            case 'GIROPAY':
                this.logo = 'giropay.png';
                break;
            case 'IDEAL':
                this.logo = 'ideal.png';
                break;
            case 'SOFORT':
                this.logo = 'sofort.png';
                break;
            case 'DI':
                this.logo = 'diners.png';
                break;
            case 'JC':
                this.logo = 'jcb.png';
                break;
            default:
                this.logo = 'payment-credit-shell.png';
                break;
        }
    }
}
