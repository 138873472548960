import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
import { SsrType } from '../ssr-type.enum';
var SsrBoxContentMealsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsrBoxContentMealsComponent, _super);
    function SsrBoxContentMealsComponent(ssrsService) {
        var _this = _super.call(this, ssrsService) || this;
        _this.ssrsService = ssrsService;
        _this.minimumPriceToBeVisible = null;
        _this.selectSsrEvent2 = new EventEmitter();
        _this.ssrType = SsrType.Meal;
        _this.uniqueId = Math.floor(Math.random() * 1000) + 1;
        return _this;
        // this.isAllowedToRemovePaidSsrs = true;
    }
    SsrBoxContentMealsComponent.prototype.selectSsr = function (passengerIndex, ssrCode, newSsrQuantity, isExternalCall) {
        var _this = this;
        if (passengerIndex === void 0) { passengerIndex = null; }
        if (ssrCode === void 0) { ssrCode = null; }
        if (newSsrQuantity === void 0) { newSsrQuantity = 0; }
        if (isExternalCall === void 0) { isExternalCall = false; }
        if (this.mobileMatches) {
            this._isSsrsPopupOpened = false;
        }
        if (newSsrQuantity > 0) {
            passengerIndex = passengerIndex == null ? this.selectedPassenger : passengerIndex;
            if (passengerIndex === -1) {
                this.passengers.forEach(function (p, i) { return _this.removeAllSsrs(i, true, isExternalCall); });
            }
            else {
                this.removeAllSsrs(passengerIndex, true, isExternalCall);
            }
        }
        _super.prototype.selectSsr.call(this, passengerIndex, ssrCode, newSsrQuantity, isExternalCall);
    };
    SsrBoxContentMealsComponent.prototype.toggleMealDetails = function (event, ssr) {
        event.preventDefault();
        ssr.showDetails = ssr.showDetails ? false : true;
    };
    SsrBoxContentMealsComponent.prototype.resetSsr = function () {
        this.selectSsr(null, "", 0);
        this.selectSsrEvent(null);
    };
    SsrBoxContentMealsComponent.prototype.selectSsrEvent = function (passengerIndex) {
        if (passengerIndex === void 0) { passengerIndex = null; }
        this.selectSsrEvent2.emit(passengerIndex);
    };
    SsrBoxContentMealsComponent.prototype.checkSameSsrOptionsForAllFlights = function () {
        this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
        this.selectSsrEvent(null);
    };
    return SsrBoxContentMealsComponent;
}(SsrBoxContentBase));
export { SsrBoxContentMealsComponent };
