<profile-layout title="{{ getPageTitle() | translate: 'profile-info' }}">
  <div class="two-cols-wrap profile-layout-body">
    <div class="col">
      <blue-loading-spinner [id]="loginSpinnerId" [activationRoute]="loginRoutes"></blue-loading-spinner>
      <div class="box-top-stripe verti-lined-list shadow ">
        <form role="form" method="post">
          <div class="form-row">
            <div class="one-field" [ngClass]="{ 'error': submitted && !username }">
              <label for="username-box">{{'User name' | translate: 'profile-info' }}</label>
              <input id="username-box" type="text" name="username" [(ngModel)]="username" placeholder="User name"
                aria-labelledby="username-box" aria-required="true" maxlength="64">
              <span class="error-txt" *ngIf="submitted && !username ">{{ 'The username is required' | translate:
                'profile-info' }}</span>
            </div>
          </div>
          <div class="form-row">
            <div class="one-field" [ngClass]="{ 'error': submitted && !password }">
              <label for="password-box">{{'Password' | translate: 'profile-info'}}</label>
              <input id="password-box" type="password" name="password" [(ngModel)]="password" placeholder="Password"
                aria-labelledby="password-box" aria-required="true" maxlength="30">
              <span class="error-txt" *ngIf="submitted && !password">{{ 'The password is required' | translate:
                'profile-info'}}</span>
            </div>
          </div>
          <div class="form-row">
            <span class="error-txt" *ngFor="let err of errors">{{err}}</span>
            <!-- <button type="button" class="btn btn-secondary blue">Forgot your password or username?</button> -->
          </div>
          <div class="form-row">
            <button type="" class="btn btn-primary btn-blue" (click)="login()">{{'Login' | translate:
              'profile-info'}}</button>
          </div>
          <div class="get-credentials-info">
            {{ 'If you don’t have an user or you don’t remember your password, please contact your account manager or
            our call center.' | translate: 'profile-info' }}
          </div>
        </form>
      </div>

    </div>
  </div>
</profile-layout>