import { Constants } from '../../constants';
import { Deck, Unit } from './seat-models';
import UnitRow from './unit-row.model'

export class SeatMapModel {
  ariaHidden: boolean = false;
  displayMode: string = '';
  isExpanded: boolean = true;
  private infantSsrCode = "INFT";
  private infantSsrCodeIndex = 0;

  private childSeatTypeCodeIndex: number;
  private petSeatTypeCodeIndex: number;
  private disabledTypeCodeIndex: number;
  private infantTypeCodeIndex: number;
  private fronSeatTypeCodeIndex: number;
  private backSeatTypeCodeIndex: number;
  private extraLegSeatTypeCodeIndex: number;




  seatForChild: Array<any> = new Array<any>();
  seatForPet: Array<any> = new Array<any>();
  seatForDisabled: Array<any> = new Array<any>();
  seatForInfant: Array<any> = new Array<any>();

  frontSeats: Array<any> = new Array<any>();
  backSeats: Array<any> = new Array<any>();
  legrommSeats: Array<any> = new Array<any>();


  propertyLookup: Array<any> = [];
  seatFees: { passengers: [any] };
  ssrCodeLookup: Array<any> = [];
  sellKey: string = "";
  flightKey: string = "";
  decks: Array<Deck> = new Array<Deck>()
  seatRows: Array<UnitRow> = new Array<UnitRow>();
  rowLetters: UnitRow;
  departureStation: string
  arrivalStation: string;
  extraLegRoomPrice: number = 0;
  frontLowPrice: number = 0;
  backLowPrice: number = 0;
  soldSeats: Array<any>;
  constructor(propertyLookup, seatFees, ssrCodeLookup, sellKey, decks, flightKey, departureStation, arrivalStation) {
    this.propertyLookup = propertyLookup;
    this.seatFees = seatFees;
    this.ssrCodeLookup = ssrCodeLookup;
    this.decks = decks;
    this.sellKey = sellKey;
    this.flightKey = flightKey;
    this.departureStation = departureStation;
    this.arrivalStation = arrivalStation;

    if (this.propertyLookup && this.ssrCodeLookup && this.seatFees && this.decks) {
      this.prepare();
    }
  }

  allLargeSeats(row) {
    return false;
  }

  prepare() {
    this.infantSsrCodeIndex = this.ssrCodeLookup.findIndex(p => p.code == this.infantSsrCode);
    this.propertyLookup.forEach(p => {
      let index = this.propertyLookup.indexOf(p);
      switch (p.typeCode) {
        case Constants.seatTypeDISABIL:
          this.disabledTypeCodeIndex = index;
          break;
        case Constants.seatTypeINFANT:
          this.infantTypeCodeIndex = index;
          break;
        case Constants.seatTypeNCHILD:
          this.childSeatTypeCodeIndex = index;
          break;
        case Constants.seatTypePET:
          this.petSeatTypeCodeIndex = index;
          break;
        case Constants.seatTypeLOCATION:
          if (p.subProperty.name == "Front") {
            this.fronSeatTypeCodeIndex = index;
          } else {
            this.backSeatTypeCodeIndex = index;
          }
          break;
        case Constants.seatTypeLEGROOM:
          this.extraLegSeatTypeCodeIndex = index;
          break;
      }
    })


    let currentRow: UnitRow = null;
    this.decks
      .forEach(d =>
        d.compartments
          .forEach(c =>
            c.units.forEach(u => {

              u.deck = d.number;
              if (u.unitType == "NS") {
                let fees = this.seatFees;
                let isExtraLeg = false;
                u.flightKey = this.flightKey;
                // get unit price
                u.price = this.seatFees.passengers[0]
                  .groups
                  .find(p => p.groupNumber == u.unitGroup)
                  .fees
                  .reduce((a, b) => a.concat(b), [])
                  .reduce((a, b) => a + b.totalCharges, 0);

                if (u.seatProperties.indexOf(this.infantTypeCodeIndex) >= 0) {
                  this.seatForInfant.push(u);
                }
                if (u.seatProperties.indexOf(this.petSeatTypeCodeIndex) >= 0) {
                  this.seatForPet.push(u);
                }
                if (u.seatProperties.indexOf(this.disabledTypeCodeIndex) >= 0) {
                  this.seatForDisabled.push(u);
                }
                if (u.seatProperties.indexOf(this.childSeatTypeCodeIndex) <= 0) {
                  this.seatForChild.push(u);
                }
                if (u.seatProperties.indexOf(this.fronSeatTypeCodeIndex) >= 0)
                  this.frontSeats.push(u);
                if (u.seatProperties.indexOf(this.backSeatTypeCodeIndex) >= 0)
                  this.backSeats.push(u);
                if (u.seatProperties.indexOf(this.extraLegSeatTypeCodeIndex) >= 0) {
                  isExtraLeg = true;
                  this.legrommSeats.push(u);
                }
                // search if the row already exists
                let findIndex = this.seatRows.findIndex(r => r.y == u.y);
                if (findIndex >= 0) {
                  let thisRow = this.seatRows[findIndex];
                  thisRow.units.push(u);
                  thisRow.addCell({ price: u.price, isExtraLeg, }, u)
                } else {
                  if (currentRow) {
                    currentRow.rowCells.computeGroups(); //compute previous row groups
                  }
                  currentRow = new UnitRow(u, { price: u.price, isExtraLeg });
                  this.seatRows.push(currentRow);
                }
              }


            })));

    this.seatRows.reduce((
      prevRow: UnitRow,
      currentRow: UnitRow) => {
      if (prevRow
        && prevRow.cellGroups.length === 1
        && currentRow.cellGroups.length === 1
      ) {
        // only on one group we should
        currentRow.hasDecoratorStyle = prevRow.cellGroups[0].groupName !== currentRow.cellGroups[0].groupName;
      } else {
        currentRow.hasDecoratorStyle = true;
      }

      return currentRow;
    }, <UnitRow>undefined);


    let largestRow = this.seatRows.sort((a, b) => {
      if (a.units.length > b.units.length) return 1;
      if (a.units.length < b.units.length) return -1;
      return 0;
    }).reverse()[0];

    let indexOfSlot = 0;
    let seatIndexes = largestRow.units.map(a => a.x);

    for (let i = 0; i < largestRow.units.length - 1; i++) {
      seatIndexes[i] = largestRow.units[i].x;
      if ((+largestRow.units[i].x) + (+largestRow.units[i].width) != largestRow.units[i + 1].x) {
        indexOfSlot = i;
      }

    }
    this.seatRows = this.seatRows.sort((a, b) => {

      if (+a.units[0].y < +b.units[0].y) {
        return -1;
      } else if (+a.units[0].y > +b.units[0].y) {
        return 1;
      } else {
        return 0;
      }

    });

    this.seatRows.forEach((row, index) => {
      row.fillSlot(indexOfSlot, seatIndexes);
      row.fillDecorator(this.propertyLookup, this.soldSeats);
    })

    this.seatRows.forEach(u => u.orderUnits());

    //add first row with numbers
    let firstRowWihLetters: UnitRow;
    for (let i = 0; i < largestRow.units.length; i++) {

      let unit = "";
      if (largestRow.units[i].unitDesignator) {
        unit = largestRow.units[i].unitDesignator.split('').pop();
      }

      if (firstRowWihLetters == null)
        firstRowWihLetters = new UnitRow(new Unit(unit));
      else
        firstRowWihLetters.units.push(new Unit(unit));
    }

    this.rowLetters = firstRowWihLetters;

    let allGroups = this.seatFees.passengers.map(p => p.groups).reduce((a, b) => a.concat(b), []);

    let allFees = allGroups.map(g => ({ groupNumber: g.groupNumber, feeAmmout: g.fees.map(f => f.totalCharges).reduce((a, b) => a + b, 0) }));

    //let fronSeatsGropus = this.frontSeats.map(p=>p.unitGroup);

    this.extraLegRoomPrice = Math.min(...allFees.filter(all => this.legrommSeats.map(p => p.unitGroup).indexOf(all.groupNumber) >= 0).map(f => f.feeAmmout));
    this.frontLowPrice = Math.min(...allFees.filter(all => this.frontSeats.map(p => p.unitGroup).indexOf(all.groupNumber) >= 0).map(f => f.feeAmmout));
    this.backLowPrice = Math.min(...allFees.filter(all => this.backSeats.map(p => p.unitGroup).indexOf(all.groupNumber) >= 0).map(f => f.feeAmmout));


  }

}