import { delay } from 'rxjs/operators';
import { BookingStepsService, CheckinSteps } from './../../core/booking-steps.service';
import { BookingFlightSelectService } from './../booking-flight-select.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Constants } from 'src/app/constants';
import * as moment from 'moment';
import { CalendarDatePickerComponent } from '../calendar-date-picker/calendar-date-picker.component';
import { StarterCancelRebook } from 'src/app/core/models/change-booking-model';
import { BookingService } from 'src/app/core/booking.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'check-in-move-flight',
  templateUrl: './move-flight.component.html',
  styleUrls: ['./move-flight.component.scss']
})
export class MoveFlightComponent implements OnInit, OnDestroy, OnChanges {
  private static UKStations = ['LHR', 'LTN', 'LPL', 'BHX', 'GLA'];

  private flightsToChangeSub: Subscription;
  private flightsToChange: any[] = [];

  @Input() public isForSearchingFlight: boolean;
  @Input() public departureStation: string;
  @Input() public arrivalStation: string;
  @Input()
  set tripType(value: string | boolean) {
    this.isOneWay = typeof value === 'string' ? value === Constants.OneWayTrip : value;
  }
  @Input()
  set outbound(value: any) {
    this.outboundDate = this.validateDate(value);
  }
  get outbound(): any {
    const v = moment(this.outboundDate, this.format);
    return v.isValid() ? v.toDate() : null;
  }
  @Input()
  set inbound(value: any) {
    this.inboundDate = this.validateDate(value);
  }
  get inbound(): any {
    const v = moment(this.inboundDate, this.format);
    return v.isValid() ? v.toDate() : null;
  }

  @Output() public callAvailability: EventEmitter<any> = new EventEmitter<any>();

  public moveFlightSpinnerId = 'moveFlightSpinnerId';
  public format = 'DD/MM/YYYY';
  public isOneWay: boolean;
  public outboundDate: string;
  public inboundDate: string;
  public minDate: string;
  public maxDate: string;
  public departureStationOptions: string[] = [];
  public arrivalStationOptions: string[] = [];
  public selectedDepartureStation: string;
  public selectedArrivalStation: string;

  @ViewChild('inboundDatePicker', { static: false }) inboundDatePicker: CalendarDatePickerComponent;

  constructor(private bookingService: BookingService, private loadingSpinnerService: LoadingSpinnerService,
    private flightSelectService: BookingFlightSelectService, private bookingStepsService: BookingStepsService) { }

  ngOnInit() {
    this.loadingSpinnerService.hideSpinnerById(this.moveFlightSpinnerId);

    this.flightsToChangeSub = this.bookingService.flightsToChangeObs
      .subscribe((flights: any[]) => {
        if (flights && flights.length > 0) {
          this.flightsToChange = flights;
          /** trip index needed on booking fare select if round trip and only one journey selected for move */
          sessionStorage.setItem(Constants.SelectedFlightIndexForChange, (flights[0].journeySequenceNumber - 1).toString());
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.departureStation || changes.arrivalStation) {

      if (this.departureStation === 'BHX') {
        this.departureStationOptions = ['BHX', 'LHR'];
        this.selectedDepartureStation = this.departureStationOptions[0];
      } else {
        this.departureStationOptions.length = 0;
        this.selectedDepartureStation = null;
      }
      if (this.arrivalStation === 'BHX') {
        this.arrivalStationOptions = ['BHX', 'LHR'];
        this.selectedArrivalStation = this.arrivalStationOptions[0];
      } else {
        this.arrivalStationOptions.length = 0;
        this.selectedArrivalStation = null;
      }
    }
  }

  ngOnDestroy(): void {
    if (this.flightsToChangeSub) {
      this.flightsToChangeSub.unsubscribe();
    }
  }

  /** used on flight search page */
  public searchFlight(): void {
    this.moveFlight().then(() => {
      this.callAvailability.next();
    });
  }

  /** used on itinerary page */
  public moveFlight(): Promise<any> {
    this.loadingSpinnerService.showSpinnerById(this.moveFlightSpinnerId);

    const journeyCriteria = new Array<StarterCancelRebook>();

    this.flightsToChange.forEach(flight => {
      journeyCriteria.push(this.initJourneyCriteria(flight));
    });

    return this.bookingService.createSnapshot().then(snapshot => {
      if (snapshot) {
        return this.bookingService.refresh(true).then(booking => {
          if (booking) {
            return this.bookingService.moveFlights(journeyCriteria).then((result: any) => {
              if (result) {
           
                
                    // needed on flight select on page refresh to remember current flow
                    sessionStorage.setItem(Constants.MoveFlightsFlow, 'true');
                    this.loadingSpinnerService.hideSpinnerById(this.moveFlightSpinnerId);
                    this.bookingStepsService.goToStep(CheckinSteps.flights);
                  
               
              }
            });
          }
        });
      }
    });
  }

  public outboundDateSelected(): void {
    const outb = moment(this.outboundDate, this.format);
    const inb = moment(this.inboundDate, this.format);

    if (outb.isAfter(inb)) {
      this.inboundDate = '';
    }

    if (this.inboundDatePicker) {
      this.inboundDatePicker.open();
    }
  }

  // private initFlightSearchModel(): Promise<boolean> {
  //   // return this.flightSelectService.getRememberSearchModel().then((searchModel: FlightSearchModel) => {
  //   //   if (searchModel) {
  //   //     var x = moment();
  //   //     this.flightSelectService.searchModel.departureDate =moment( this.outboundDate).format("YYYY/MM/DD") ;
  //   //     this.flightSelectService.searchModel.returnDate = moment( this.inboundDate).format("YYYY/MM/DD") ;
  //   //     this.flightSelectService.searchModel.isRoundTrip = !this.isOneWay;
  //   //     this.flightSelectService.searchModel.origin = this.departureStation;
  //   //     this.flightSelectService.searchModel.destination = this.arrivalStation;
  //   //     return true;
  //   //   }
  //       // this.flightSelectService.searchModel.departureDate = this.outboundDate;
  //       // this.flightSelectService.searchModel.returnDate = this.inboundDate;
  //       // this.flightSelectService.searchModel.isRoundTrip = !this.isOneWay;
  //       // this.flightSelectService.searchModel.origin = this.departureStation;
  //       // this.flightSelectService.searchModel.destination = this.arrivalStation;
  //       // return true;
    

      
  //  // });
  //  return  true;
  // }

  private initJourneyCriteria(fromJourney: any): StarterCancelRebook {
    var newJourney: StarterCancelRebook = new StarterCancelRebook();
    const fromSegments = fromJourney.segments;
    
    const journeyOrigin = fromSegments[0].departureStation;
    const journeyDestination = fromSegments[fromSegments.length - 1].arrivalStation;

    if (journeyOrigin === this.departureStation || journeyDestination === this.arrivalStation) {
      newJourney.Origin = this.selectedDepartureStation || this.departureStation;
      newJourney.Destination = this.selectedArrivalStation || this.arrivalStation;
    } else {
      newJourney.Destination = this.selectedDepartureStation || this.departureStation;
      newJourney.Origin = this.selectedArrivalStation || this.arrivalStation;
    }

    newJourney.FromJourney = fromJourney.sellKey;
    const newJourneyDepartureDate = fromJourney.journeySequenceNumber === 1 ||
      this.flightsToChange.length === 1 ? this.outboundDate : this.inboundDate;

    newJourney.DepartureDate = moment(newJourneyDepartureDate, this.format).format('YYYY-MM-DD');
    return newJourney;
  }

  private validateDate(value: string): string {
    if (value) {
      let date = moment(value);
      if (!date.isValid()) {
        date = moment(value, this.format);
      }
      return date.isValid() ? date.format(this.format) : null;
    }
    return null;
  }
}
