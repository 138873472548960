import { OnInit, ElementRef, Renderer2 } from '@angular/core';
import * as moment from 'moment';
import { ControlValueAccessor } from '@angular/forms';
import { TranslateService } from '../translator/translate.service';
import { DateRange } from '../date-range.model';
import { DatePickerRestriction } from './date-picker-restriction.enum';
var BlueDatePickerComponent = /** @class */ (function () {
    function BlueDatePickerComponent(translator, elementRef, renderer) {
        this.translator = translator;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.defaultStr = 'year';
        this.defaultStrDay = 'day';
        this.defaultStrMonth = 'month';
        this.onTouchedCb = function () { };
        this.onChangeCb = function (_) { };
        this.uniqueId = Math.floor(Math.random() * 1000) + 1;
        this.dateFormat = 'D/MM/YYYY';
        this.defaultStr = translator.instant(this.defaultStr, 'datepicker');
        this.defaultStrDay = translator.instant(this.defaultStrDay, 'datepicker');
        this.defaultStrMonth = translator.instant(this.defaultStrMonth, 'datepicker');
        this.selectYear = this.defaultStr;
        this.selectDay = 0;
        this.selectMonth = this.defaultStrMonth;
    }
    Object.defineProperty(BlueDatePickerComponent.prototype, "requiredDate", {
        get: function () {
            return '';
        },
        enumerable: true,
        configurable: true
    });
    BlueDatePickerComponent.prototype.writeValue = function (obj) {
        if (obj) {
            this.defaultValue = moment(obj, this.dateFormat);
        }
        if (this.defaultValue && this.defaultValue.isValid()) {
            var setDayRef = function () { };
            var originalSetDay = this.setDate;
            this.setDate = setDayRef;
            this.onChangeDay(+this.defaultValue.format('D'));
            this.onChangeMonth(this.defaultValue.format('MMMM'));
            this.onChangeYear(this.defaultValue.format('YYYY'));
            this.setDate = originalSetDay;
        }
    };
    BlueDatePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChangeCb = fn;
    };
    BlueDatePickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouchedCb = fn;
    };
    BlueDatePickerComponent.prototype.setDisabledState = function (isDisabled) {
        this.isDisabled = isDisabled;
    };
    BlueDatePickerComponent.prototype.ngOnInit = function () {
    };
    BlueDatePickerComponent.prototype.onChangeDay = function (event) {
        this.selectDay = event;
        this.setDate();
    };
    BlueDatePickerComponent.prototype.onChangeMonth = function (event) {
        this.selectMonth = event;
        this.setDate();
    };
    BlueDatePickerComponent.prototype.onChangeYear = function (event) {
        this.selectYear = event;
        this.setDate();
    };
    BlueDatePickerComponent.prototype.setDate = function () {
        var isValid = true;
        var day = Number(this.selectDay);
        var year = Number(this.selectYear);
        isValid = Number.isInteger(day) && Number.isInteger(year);
        if (isValid) {
            var dateStr = day + "/" + this.selectMonth + "/" + year;
            this.testDate = moment(dateStr, 'D/MMMM/YYYY');
            isValid = this.testDate.isValid();
        }
        if (isValid) {
            var range = this.dateRange;
            if (range.minimumDate && range.minimumDate > this.testDate) {
                this.restriction = DatePickerRestriction.LowerThanLimit;
                this.defaultValue = null;
                this.onChangeCb(null);
            }
            else if (range.maximumDate && range.maximumDate < this.testDate) {
                this.restriction = DatePickerRestriction.HigherThanLimit;
                this.defaultValue = null;
                this.onChangeCb(null);
            }
            else {
                this.restriction = null;
                this.defaultValue = this.testDate;
                this.onChangeCb(this.defaultValue.format(this.dateFormat));
            }
        }
        else {
            this.restriction = null;
            this.defaultValue = null;
            this.onChangeCb(null);
        }
    };
    return BlueDatePickerComponent;
}());
export { BlueDatePickerComponent };
