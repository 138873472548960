import { Directive, ElementRef, Renderer2, HostListener, Input, OnInit, EventEmitter, OnChanges, ViewChild, Output, AfterViewInit } from '@angular/core';
import { Constants } from '../constants';
import { BlueRenderer } from '../common-modules/blue-air-common/services/blue-renderer.service';
import { LoggerService } from '../common-modules/log4ts/logger.service';

@Directive({
  selector: '[checkInSsrListDisplay]',
  providers: [BlueRenderer]
})
export class SsrListDisplayDirective implements AfterViewInit, OnChanges {
  private mobileMatches: boolean;

  @Input() checkInSsrListDisplay = false;
  @Output() checkInSsrListDisplayChange = new EventEmitter();

  private ssrList: Element;
  private ssrListClosePopupBtn: Element;
  private ssrListPopupTitle: Element;

  /** The current window width */
  private currentWindowWidth: number;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateWindowWidth(event.target.innerWidth);
  }

  constructor(private el: ElementRef, private renderer: Renderer2, private blueRenderer: BlueRenderer, private logService: LoggerService) {
  }

  ngAfterViewInit() {
    this.ssrList = this.el.nativeElement.querySelector('.js-ssr-list');
    this.ssrListClosePopupBtn = this.el.nativeElement.querySelector('.close-popup');
    const ssrListClosePopupBtnBottom = this.el.nativeElement.querySelector('.close-popup-bottom');
    this.ssrListPopupTitle = this.el.nativeElement.querySelector('.js-ssr-list-title');

    this.updateWindowWidth(window.innerWidth);
    this.ssrListClosePopupBtn && this.renderer.listen(this.ssrListClosePopupBtn, 'click', (event: any) => this.closePopup(event));
    ssrListClosePopupBtnBottom && this.renderer.listen(ssrListClosePopupBtnBottom, 'click', (event: any) => this.closePopup(event));
  }

  ngOnChanges() {
    this.updateDisplay();
  }
  private closePopup(event: any) {
    this.checkInSsrListDisplay = false;
    this.checkInSsrListDisplayChange.emit(false);
    this.updateDisplay();
  }
  private updateWindowWidth(width: any) {
    if (this.currentWindowWidth === width) {
      return;
    }
    this.currentWindowWidth = width;
    this.mobileMatches = width < Constants.mobileScreenWith;

    let optionsAttr: string = null;
    let listAttr: string = null;
    let innerListElementsAttr = 'true';

    try {
      if (this.mobileMatches) {
        optionsAttr = listAttr = 'true';
        innerListElementsAttr = null;
  
        this.renderer.setAttribute(this.el.nativeElement, 'aria-hidden', 'true');

        if(this.ssrList) this.renderer.setAttribute(this.ssrList, 'aria-hidden', 'true');
        if(this.ssrListPopupTitle) this.renderer.removeAttribute(this.ssrListPopupTitle, 'aria-hidden');
        if(this.ssrListClosePopupBtn) this.renderer.removeAttribute(this.ssrListClosePopupBtn, 'aria-hidden');
      } else {
        this.renderer.removeAttribute(this.el.nativeElement, 'aria-hidden');
        
        if(this.ssrList) this.renderer.removeAttribute(this.ssrList, 'aria-hidden');
        if(this.ssrListPopupTitle) this.renderer.setAttribute(this.ssrListPopupTitle, 'aria-hidden', 'true');
        if(this.ssrListClosePopupBtn) this.renderer.setAttribute(this.ssrListClosePopupBtn, 'aria-hidden', 'true');
      }
    } catch(err) {
      this.logService.error(err);
    }

    this.updateDisplay();
  }

  private updateDisplay(): void {
    const attrValue = this.mobileMatches && this.checkInSsrListDisplay;
    if (this.ssrList) {
      this.blueRenderer.updateBodyScrollOnMobile(attrValue);
      this.renderer.setStyle(this.ssrList, 'display', attrValue ? 'block' : null);
    }
  }
}
