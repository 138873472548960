<div ngForm="clientDetailsForm" #clientDetailsForm="ngForm">
  <div class="booking-ptw-details-form-box" *ngIf="showDetails">
    <div class="pax-container">
      <div class="pax-contact_title">{{'client-details-title'|translate:'push-to-wallet'}}</div>
      <div>
        <div class="field-wrap">
          <div class="field-inner">
            <label for="name">{{ 'Name' | translate:'push-to-wallet' }}</label>
            <input type="text" id="name" [(ngModel)]="model.name" name="name" required [my-error]="name.dirty" #name="ngModel"
            placeholder="{{ 'Name' | translate:'push-to-wallet' }}" />
          </div>
        </div>

        <div class="field-wrap">
          <div class="field-inner">
            <label for="phone-number">{{ 'Phone number' | translate:'push-to-wallet' }}</label>
            <div class="phone-number-description-box">{{ 'Phone-number-description' | translate:'push-to-wallet' }}</div>
            <input type="text" id="phone-number" [(ngModel)]="model.phoneNumber" (change)="cleanupPhoneNumber()" (keyup)="cleanupPhoneNumber()"  name="phone-number" required
              [pattern]="phonePattern" [my-error]="phoneNumber.dirty" patternError="{{ 'Invalid phone number format' | translate:'push-to-wallet' }}" #phoneNumber="ngModel"
              placeholder="{{ 'Phone number' | translate:'push-to-wallet' }}"/>
          </div>
        </div>

        <div class="field-wrap">
          <div class="field-inner">
            <label for="email-address">{{ 'Email Address' | translate:'push-to-wallet' }}</label>
            <input type="text" id="email-address" [(ngModel)]="model.emailAddress" name="emailAddress" required [my-error]="emailAddress.dirty" #emailAddress="ngModel"
              patternError="{{ 'Invalid email address' | translate:'documents' }}"  [pattern]="emailPattern" placeholder="{{ 'Email Address' | translate:'push-to-wallet' }}"/>
          </div>
        </div>
      </div>

      <span class="error-txt" [style.display]="'block'" *ngFor="let error of errors"  [attr.data-error-id]="error">
        {{ 'Error-' + error | translate:'push-to-wallet' }}
      </span>

      <div class="pax-container_subtitle">
        {{ 'send-code-description' | translate:'push-to-wallet' }}
      </div>

      <button type="button" class="btn btn-primary" attr.aria-label="{{ 'Click to select and continue' | translate }}"
      (click)="submit()">
        {{ 'Send me the code' | translate:'push-to-wallet' }}
      </button>
    </div>
    <div class="image-box">
      <img [src]="assetsPath + 'img/refund-dog.png'" alt="Member of IATA" >
    </div>
  </div>
</div>

<div *ngIf="!showDetails">
  <div *ngIf="showEmailErrorMessage">
    {{ 'Your email address doesn`t match with the one used for booking the flight. Please use or create an account that does so.' | translate: 'push-to-wallet'}}
  </div>
  <div *ngIf="showB2BErrorMessage">
    {{ 'Your name and surname from PNR do not match the Blue Air account details used for booking the flight. Please use or create an account that does so.' | translate: 'push-to-wallet'}}
  </div>
</div>


<div class="terms-box">
  <div class="title">
    <h3> {{ 'Terms and Conditions' | translate:'push-to-wallet' }}</h3>
  </div>
  <div class="content">
    {{ 'The funds remain available for 24 months in your Blue Wallet and you can rely on them to purchase new Blue Air flights and services in the future. The currency of the amount refunded in Blue Wallet is Euro (the amounts initially paid in other currencies are converted into Euros at the exchange rate available on the day of the refund request). Please note that, if you choose the refund in Blue Wallet, the same amount is not eligible for other form of refund.

    The amount is visible in My Profile and can be used in the payment step.' | translate:'push-to-wallet' }}
  </div>
</div>
