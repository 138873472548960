import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class WalletInfoService {

  constructor(
    private http: HttpClient, 
    private configService: ConfigService
  ) 
  { }


  getWalletInfo(): any {
    return this.http.get(this.configService.ProfileUrl).pipe(
      //tap((p: any) => { console.log(JSON.stringify(p)); }),
      map((p: any) => { return p.memberWalletInfo; }),
      catchError(error => {
        return throwError('WalletInfoService: ' + error.error.errors[0].errorMessage);
      })
    );
  }

  
}
