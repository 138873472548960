import { Constants } from '../../constants';
import { TranslateService } from './../../common-modules/blue-air-common/translator/translate.service';
import { GenderEnum, PassengerDocumentsModel } from './passenger-documents-model';
import { BookingFareSelectSyncService } from '../../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { BehaviorSubject } from 'rxjs';
import { FlightModel } from './flight-model';

export class Fee {
    code: string;
    note: string;
    number: number;
    serviceCharges: any;
    flightReference: string;
}

export type TravelDocumentModel = {
    birthCountry: string,
    dateOfBirth: string, // this should have been a Date
    docNumber: string,
    docSuffix: string | null,
    docTypeCode: string,
    expirationDate: string,
    gender: GenderEnum,
    issuedByCode: string,
    issuedDate: string,
    name: { first: string, last: string, middle?: string, suffix?: string, title: string },
    names: Array<{
        actionStatusCode: string
        firstName: string,
        lastName: string,
        middleName?: string
        suffix?: string
        title: string
    }>,
    nationality: string,
    passengerNumber: number,
    travelDocumentSource: string
}

export class PassengerModel {
    get isAdult(): boolean { return this.paxType.substring(0, 3) === Constants.AdultPaxType; }
    get isYouth(): boolean { return this.paxType.substring(0, 3) === Constants.YoungAdultPaxType; }
    get isTeen(): boolean { return this.paxType.substring(0, 3) === Constants.TeenPaxType; }
    get isChild(): boolean { return this.paxType.substring(0, 3) === Constants.ChildrenPaxType; }
    get isInfant(): boolean { return this.paxType.substring(0, 3) === Constants.InfantSsrCode; }
    paxType: 'ADT' | 'YTH' | 'CLD' | 'CHD' | 'INF';
    // e.g Adult 2 or Child 1
    passengerNumberByType: number;
    private _passengerNumber: number;
    get passengerNumber(): number {
        // console.log('Passenger number: ' + this._passengerNumber)
        return this._passengerNumber;
    }

    set passengerNumber(value: number) {
        this._passengerNumber = value;
    }

    name: PassengerName;
    defaultName: string;
    isSelected: boolean;
    isSeatSelected: boolean;
    unitDesignator: string;
    get flightUniqueId(): string {
        return (this.flight && this.flight.flightUniqueId) || '';
    }
    flight: FlightModel;
    sortByType: Number;
    documents: PassengerDocumentsModel;
    fees: Fee[] = [];
    hasInfant: boolean;
    infant: PassengerModel;
    isCheckedIn: boolean;
    priorityBoardingSelection$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null)

    private _computeHasPriorityBoarding() {
        if (!this.flight) {
            return false;
        }
        if (!this.flight.segments) {
            return false;
        }

        if (this.hasPriorityBoardingIncludedInTheBundle) {
            return true;
        }

        const segmentsUniqueIDs = this.flight.segments.map(s => s.departureStation.iataCode + s.arrivalStation.iataCode);
        return this._hasFee('PBRD', segmentsUniqueIDs);
    }

    public get hasPriorityBoardingIncludedInTheBundle(): boolean {
        const paidSsrs = (this.flight && this.flight.paidSsrs && this.flight.paidSsrs[this.passengerNumber]) || [];
        if (paidSsrs.includes('PBRD')) {

            return true;
        }

        return false;
    }

    private _hasFee(feeCode: string, segmentsUniqueIds: string[]) {
        for (let feeIndex = 0; feeIndex < this.fees.length; feeIndex++) {
            const fee = this.fees[feeIndex];
            if (fee.code === feeCode) {
                for (let segmentIndex = 0; segmentIndex < segmentsUniqueIds.length; segmentIndex++) {
                    if (fee.flightReference && 0 < fee.flightReference.indexOf(segmentsUniqueIds[segmentIndex])) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    get hasPriorityBoarding(): boolean | null {
        const hasPriorityBoarding = this._computeHasPriorityBoarding();
        if (hasPriorityBoarding) {
            return true;
        }
        if (this.syncService.passengersInitialPriorityBoardingChoice[this.passengerUniqueId]) {
            return false;
        }
        return null;
    }

    get notHasPriorityBoarding(): boolean | null {
        const hasPriorityBoarding = this._computeHasPriorityBoarding();
        if (hasPriorityBoarding) {
            this.syncService.passengersInitialPriorityBoardingChoice[this.passengerUniqueId] = true;
            return false;
        }
        if (this.syncService.passengersInitialPriorityBoardingChoice[this.passengerUniqueId]) {
            return true;
        }
        return null;
    }

    get passengerUniqueId(): string {
        if (!this.flightUniqueId) {
            return String(this.passengerNumber);
        }
        return `${this.flightUniqueId}_${this.passengerNumber}`;
    }

    /**
     *
     */
    constructor(private readonly _passengerInfo?: any, private readonly _typeCounter: any = {}, private readonly syncService: BookingFareSelectSyncService = null) {
        this.documents = new PassengerDocumentsModel();

        if (_passengerInfo) {
            this.passengerNumber = _passengerInfo.passengerNumber;
            this.name = _passengerInfo.name;
            this.fees = this.setPassengerFees(_passengerInfo.fees);

            if (_passengerInfo.typeInfo) {
                this.paxType = _passengerInfo.typeInfo.paxType;
                _typeCounter[this.paxType] = (_typeCounter[this.paxType] || 0) + 1;
                this.passengerNumberByType = _typeCounter[this.paxType];
            }

            if (_passengerInfo.hasInfant && _passengerInfo.infant) {
                this.infant = new PassengerModel(_passengerInfo.infant, null, syncService);
                this.infant.documents.docSuffix = 'I';
                this.hasInfant = true;
                this.infant.paxType = Constants.InfantSsrCode;
                this.infant.documents.gender = _passengerInfo.infant.gender;
                this.infant.documents.dateOfBirth = _passengerInfo.infant.dateOfBirth;
                if (_passengerInfo.travelDocuments) {
                    this.infant.documents.initFromTravelDocuments(_passengerInfo.travelDocuments.items);
                }
            }

            this.sortByType = PassengerModel.initSortByType(this);
            if (_passengerInfo.travelDocuments) {
                this.documents.initFromTravelDocuments(_passengerInfo.travelDocuments.items);
                this.documents.passengerNumber = _passengerInfo.passengerNumber;
                this.documents.gender = _passengerInfo.info.gender;
                this.documents.dateOfBirth = _passengerInfo.typeInfo.dateOfBirth;
            }
        }

    }

    resetCounter() {

    }

    clone(typeCounter?: { adtCount: number, chdCount: number }) {
        return new PassengerModel(this._passengerInfo, typeCounter ? typeCounter : this._typeCounter, this.syncService);
    }

    private setPassengerFees(fees) {
        let feeList: Fee[] = [];
        if (fees && fees.items) {
            fees.items.forEach(f => {
                let fee = new Fee();
                fee.code = f.code;
                fee.note = f.note;
                fee.number = f.number;
                fee.serviceCharges = f.serviceCharges.items;
                fee.flightReference = f.flightReference;
                feeList.push(fee);
            });
        }
        return feeList;
    }

    static initSortByType(model: PassengerModel): number {
        if (model.paxType)
            switch (model.paxType.substring(0, 3)) {
                case Constants.AdultPaxType:
                    return 0;
                case Constants.YoungAdultPaxType:
                    return 5;
                case Constants.TeenPaxType:
                    return 10;
                case Constants.ChildrenPaxType:
                    return 15;
            }
        return 20;
    }

    static sortFn(a: PassengerModel, b: PassengerModel): number {
        if (a.sortByType > b.sortByType) {
            return 1;
        }

        if (a.sortByType < b.sortByType) {
            return -1;
        }

        if (a.passengerNumberByType > b.passengerNumberByType) {
            return 1;
        }

        if (a.passengerNumberByType < b.passengerNumberByType) {
            return -1;
        }
        return 0;
    }

    static checkNameFormat(name: string): boolean {
        let result = true;
        if (String(name).length < 3) {
            result = false;
        } else {
            for (let i = 0; i < 2; i++) {
                if (name[i] !== name[i + 1]) {
                    result = false;
                }
            }
        }
        return result;
    }

    generatePassengerNameByType(translateService: TranslateService) {
        const defaultName = translateService.instant(this.paxType.substring(0, 3) + '-title', 'price-display');
        this.defaultName = `${defaultName} ${this.passengerNumberByType}`;

        if ((!this.name.first && !this.name.last) || (this.name.first && this.name.last && PassengerModel.checkNameFormat(this.name.first)
            && PassengerModel.checkNameFormat(this.name.last))) {
            let passengerNameByType = new PassengerName();
            passengerNameByType.first = defaultName;
            passengerNameByType.last = this.passengerNumberByType.toString();
            this.name = passengerNameByType;
        }
    }
}


export class PassengerName {
    first: string;
    last: string;
    middle: string;
    suffix: string;
    title: string;
}
