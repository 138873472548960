/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./low-fare-calendar-view.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "./low-fare-calendar-view.component";
import * as i5 from "@angular/common/http";
import * as i6 from "../../core/config.service";
var styles_LowFareCalendarViewComponent = [i0.styles];
var RenderType_LowFareCalendarViewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LowFareCalendarViewComponent, data: {} });
export { RenderType_LowFareCalendarViewComponent as RenderType_LowFareCalendarViewComponent };
export function View_LowFareCalendarViewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), i1.ɵqud(402653184, 1, { calendarElement: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 22, "div", [["class", "month-item-details fare-calendar-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "month-name"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "fare-month"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["class", "fare-year year"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "button", [["class", "slick-arrow slick-prev fare-prev"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToPreviousMonth() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 2), (_l()(), i1.ɵeld(12, 0, null, null, 3, "button", [["class", "slick-arrow slick-next fare-next"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goToNextMonth() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), i1.ɵppd(15, 2), (_l()(), i1.ɵeld(16, 0, null, null, 8, "div", [["class", "month-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, [[1, 0], ["calendar", 1]], null, 0, "div", [["calendar-selection", "inboundDate"], ["class", "fare-calendar fc-calendar-container js-fare-to-calendar"], ["outbound-date", "outboundDate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 6, "div", [["class", "gestures-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["class", "left-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "center-arrow"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "div", [["class", "circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 0, "div", [["class", "right-arrow"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.currentMonthName; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.currentYear; _ck(_v, 7, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), "Previous", "calendar")); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i1.ɵnov(_v, 0), "Next", "calendar")); _ck(_v, 14, 0, currVal_3); }); }
export function View_LowFareCalendarViewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "low-fare-calendar-view", [], null, null, null, View_LowFareCalendarViewComponent_0, RenderType_LowFareCalendarViewComponent)), i1.ɵdid(1, 4833280, null, 0, i4.LowFareCalendarViewComponent, [i1.NgZone, i5.HttpClient, i6.ConfigService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LowFareCalendarViewComponentNgFactory = i1.ɵccf("low-fare-calendar-view", i4.LowFareCalendarViewComponent, View_LowFareCalendarViewComponent_Host_0, { initialDate: "initialDate", origin: "origin", destination: "destination", minDate: "minDate", isOutbound: "isOutbound" }, { dayChange: "dayChange" }, []);
export { LowFareCalendarViewComponentNgFactory as LowFareCalendarViewComponentNgFactory };
