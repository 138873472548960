import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { CheckinSteps, BookingStepsService } from './../../core/booking-steps.service';
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { BookingFlightSelectService } from './../booking-flight-select.service';
import { BookingService } from './../../core/booking.service';
import { ResourceService } from './../../common-modules/blue-air-common/resource.service';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { CalendarDatePickerComponent } from '../calendar-date-picker/calendar-date-picker.component';
import { BookingFlowService } from '../../core/booking-flow.service';
import { PassengerTypeSelectionViewModel } from '../models/passenger-selection-view-model';
import { PassengerTypePickerComponent } from '../passenger-type-picker/passenger-type-picker.component';
import { FlightSearchModel } from '../models/flight-search-model';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Constants } from '../../constants';
import { CurrencyManagerService } from '../../core/currency-manager.service';
import { retry, take } from 'rxjs/operators';
import { ProfileService } from 'src/app/core/profile.service';
import { SessionStorage } from 'ngx-webstorage';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { switchMap, filter } from 'rxjs/operators';
import { ConfigService } from '../../core/config.service';
import { isNullOrUndefined, isUndefined } from 'util';
import { element } from 'protractor';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';


declare type SpecialMarketTypes = 'noSpecialMarket' | 'psoWorker' | 'specialPrice';
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'change-flight',
  templateUrl: './change-flight.component.html',
  styleUrls: ['./change-flight.component.scss']
})
export class ChangeFlightComponent implements OnInit, OnDestroy, AfterViewInit {

  specialPriceModalId = 'specialPriceModalId';

  @ViewChild('specialPriceModal', { static: true }) specialPriceModal: DefaultModalComponent
  format = 'DD/MM/YYYY';
  changeFlightSpinnerId = 'changeFlightSpinnerId';
  @SessionStorage()
  classOfService: string;
  isAgent: boolean;
  outboundDate: string;
  selectedMarketName: string = "sardinia";
  public showSpecialPrice: boolean = false;
  //private _isSpecialMarket: boolean = false;
  urlSafe: any;
  psoWorkerEnabled?: boolean;
  _selectedSpecialMarketOption: SpecialMarketTypes = 'noSpecialMarket';

  set selectedSpecialMarketOption(value: SpecialMarketTypes) {
    this._selectedSpecialMarketOption = value;
  }
  get selectedSpecialMarketOption(): SpecialMarketTypes {
    return this._selectedSpecialMarketOption;
  }

  get isSpecialMarket(): boolean {
    return this.selectedSpecialMarketOption == 'specialPrice'
  }

  set isSpecialMarket(value: boolean) {
    this.selectedSpecialMarketOption = "specialPrice";
  }


  @Input()
  set outbound(value: any) {
    const v = moment(value);
    this.outboundDate = v.isValid() ? v.format(this.format) : null;
  }
  get outbound(): any {
    const v = moment(this.outboundDate, this.format);
    return v.isValid() ? v.toDate() : null;
  }
  inboundDate: string;
  @Input()
  set inbound(value: Date) {
    if (value) {
      const v = moment(value);
      this.inboundDate = v.isValid() ? v.format(this.format) : null;
    } else {
      this.inboundDate = null;
    }
  }

  get inbound(): Date {
    const v = moment(this.inboundDate, this.format);
    return v.isValid() ? v.toDate() : null;
  }
  private _departure: string;
  @Input()
  set departure(value: string) {
    this._departure = value;
    this.checkIfIsSpecialPrice();
    // this.setSpecialPriceIframeUrl();
  }
  get departure(): string {
    return this._departure;
  }
  private _destination: string;
  @Input()
  set destination(value: string) {
    this._destination = value;
    this.checkIfIsSpecialPrice();
    // this.setSpecialPriceIframeUrl();
  }
  get destination(): string {
    return this._destination;
  }
  @Input()
  set tripType(value: string) {
    this.isOneWay = value === Constants.OneWayTrip;
  }
  get tripType(): string {
    return this.isOneWay ? Constants.OneWayTrip : Constants.RoundTrip;
  }
  @Input() isForChangingFlight: boolean;
  @Input() isOneWay: boolean;
  @Input() currency: string;
  @Input() ignoreConnectionErrors: boolean;


  isPromoSelected: boolean = true;
  @Input()
  set flightSearchModel(model: FlightSearchModel) {
    if (model) {
      this.departure = model.origin;
      this.destination = model.destination;
      this.outboundDate = moment(model.departureDate).format(this.format);
      this.inboundDate = moment(model.returnDate).format(this.format);
      this.isOneWay = !model.isRoundTrip;
      this.passengerSelection = new PassengerTypeSelectionViewModel().initFromPassengersSearch(model.passengers);
      this.currency = model.currency;
    }

  }

  flightsToChange: any;

  @Output() search: EventEmitter<FlightSearchModel> = new EventEmitter<FlightSearchModel>();

  private bookingStepsSubscription: Subscription;
  private flightsToChangeSubscription: Subscription;
  private userStateSubscription: Subscription;
  passengerSelection: PassengerTypeSelectionViewModel;
  today: string;
  superStations: any[];
  isOnFlightsPage: boolean;
  availableCurrencies: { text: string, value: string }[];
  classesOfService: { text: string, value: string }[];
  isProfilePromoActive: boolean = false;
  totalPaxCount: number = 9;
  totalPromoPaxCount: number = 9;
  showCmsView = false;

  @ViewChild('outboundDatePicker', { static: true }) outboundDatePicker: CalendarDatePickerComponent;
  @ViewChild('inboundDatePicker', { static: false }) inboundDatePicker: CalendarDatePickerComponent;
  @ViewChild('passengerTypePicker', { static: false }) passengerTypePicker: PassengerTypePickerComponent;
  @ViewChild('changeBookingNotAllowed', { static: true }) ChangeBookingNotAllowed: DefaultModalComponent;
  @ViewChild('changeFlightWarning', { static: true }) ChangeFlightWarningModal: DefaultModalComponent;

  constructor(
    private bookingFlowService: BookingFlowService, private bookingService: BookingService,
    private bookingStepsService: BookingStepsService, private currencyManager: CurrencyManagerService,
    private flightSearch: BookingFlightSelectService, private loadingSpinnerService: LoadingSpinnerService,
    private profileService: ProfileService, private resourceService: ResourceService,
    private configService: ConfigService, private translateService: TranslateService,
    private modalService: BlueModalService, private userStateService: UserStateService,
    private sanitizer: DomSanitizer) {

    this.today = moment().format(this.format);
    this.passengerSelection = new PassengerTypeSelectionViewModel();
    this.passengerSelection.adults = 1;
    this.superStations = [];
    this.bookingFlowService.loadSuperStations().then(s => {
      this.superStations = s;
      this.loadingSpinnerService.hideSpinnerById(this.changeFlightSpinnerId);
    });
    this.bookingStepsSubscription = this.bookingStepsService.currentStep.subscribe(currentStep => {
      if (currentStep) {
        this.isOnFlightsPage = currentStep === CheckinSteps.flights;
      }
    });

    this.availableCurrencies = this.currencyManager.currencyList.map(c => ({ text: c, value: c }));
    this.currency = this.bookingFlowService.currency || 'EUR';

    if (profileService.isAgent) {
      //resourceService.getClassesOfService().pipe(take(1)).subscribe(cs => this.classesOfService = cs);
      this.classOfService = this.classOfService || 'Y';
      this.isAgent = true;
    }

    this.initUserData();

    this.bookingService.memberPromotionObs.subscribe(data => {
      if (data && data.memberPromotion.isActive) {

        this.isProfilePromoActive = true;
        this.totalPromoPaxCount = data.memberPromotion.paxCount;
        this.totalPaxCount = this.totalPromoPaxCount;
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.bookingStepsSubscription) {
      this.bookingStepsSubscription.unsubscribe();
    }
    if (this.flightsToChangeSubscription) {
      this.flightsToChangeSubscription.unsubscribe();
    }
    if (this.userStateSubscription) {
      this.userStateSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.flightsToChangeSubscription = this.bookingService.flightsToChangeObs.subscribe((data: any[]) => {
      if (data && data.length > 0) {
        this.flightsToChange = data;
        sessionStorage.setItem(Constants.SelectedFlightIndexForChange, (this.flightsToChange[0].journeySequenceNumber - 1).toString());
      }
    });
  }



  priceOptionList: { value: SpecialMarketTypes, text: string }[] = [
    {
      value: 'noSpecialMarket', text: 'Clear selection'
    },
    {
      value: 'specialPrice', text: this.getSpecialPriceCheckboxText()
    },
    {
      value: 'psoWorker', text: 'Are you a worker in Sardinia?'
    }
  ];
  selectedIndex: number = 0;




  changePriceOptionSelection(event, index) {
    this.selectedIndex = event.target.checked ? index : undefined;

    this.selectedSpecialMarketOption = this.priceOptionList[index].value;
  }


  outboundDateSelected(newDate: string) {
    const outb = moment(this.outboundDate, this.format);
    const inb = moment(this.inboundDate, this.format);

    if (outb.isAfter(inb)) {
      this.inboundDate = '';
    }

    if (this.inboundDatePicker) {
      this.inboundDatePicker.open();
    } else {
      if (this.passengerTypePicker) {
        this.passengerTypePicker.focusIn();
      }
    }
  }

  inboundDateSelected(newDate: string) {
    if (this.passengerTypePicker) {
      this.passengerTypePicker.focusIn();
    }
  }

  destinationStationSelected(newStation: string) {
    // de-comment this if we need to go directly to date-picker
    // if (newStation && this.outboundDatePicker) {
    //   setTimeout(() => this.outboundDatePicker.open(), 5);
    // }
  }

  oneWayChanged() {
    if (this.isOneWay) {
      this.inboundDate = '';
    } else {
      this.inboundDate = this.outboundDate;
    }
  }

  async searchFlight() {
    if (!this.departure || !this.destination || !this.outbound ||
      (!this.isOneWay && (!this.inbound || this.inbound < this.outbound)) ||
      this.passengerSelection.count === 0) {
      return;
    }


    if (this.passengerSelection.count > this.totalPaxCount) {
      this.modalService.open("numberOfPaxExcededModal");
      return;
    }

    if (this.isSpecialMarket) {
      // this.specialPriceModal.openPopup(async (isOk) => {
      //   if (!isOk) return;
      //   await this.doSearchFlight();
      // })
      this.showCmsView = true;

    } else {
      await this.doSearchFlight();
    }
  }

  async continueSearchFlight() {
    await this.doSearchFlight();
  }

  async cancelSearchFlight() {
    this.showCmsView = false;
    setTimeout(() => {
      this.loadingSpinnerService.hideSpinnerById(this.changeFlightSpinnerId);
    }, 0);
  }



  private async doSearchFlight() {
    const searchModel = await this.getFlightSearchModel(this.currency);
    this.bookingFlowService.currency = this.currency;
    this.search.next(searchModel);

    this.flightSearch.showBundleUpgrade = true;

    this.setFlightsAndNavigate(searchModel);
  }

  async changeFlight() {
    const currentBooking = this.bookingService.getBooking()

    // if(currentBooking.bundleDetail.bundleCodes
    //currentBooking.bundleDetail.bundleCodes.   ABPS -< use special classes
    // currentBooking.cancelFlight // convertedJourney -> Fare classOf
    // this.isSpecialClassOfService = true;


    var changeFlight = async () => {

      // todo: modify this: send event to booking info component
      //
      this.loadingSpinnerService.showSpinnerById(this.changeFlightSpinnerId);
      // const result = await this.bookingService.clearSnapshot();
      this.bookingService.createSnapshot().then(async (data: any) => {
        if (data) {
          const searchModel = await this.getFlightSearchModel(currentBooking.convertedJourneys.currencyCode);
          this.search.next(searchModel);

          this.bookingService.refresh(true).then(res => {
            if (res) {
              this.bookingService.changeFlight(data.bookingDetail.recordLocator, searchModel, currentBooking.convertedJourneys.journeys, this.flightsToChange).then((result: any) => {
                if (result && result.starterCancelRebook.canCancel) {
                  this.setFlightsAndNavigate(searchModel);
                }
                else {
                  this.ChangeBookingNotAllowed.openPopup({});
                }
                this.loadingSpinnerService.hideSpinnerById(this.changeFlightSpinnerId);
              });
            }
          });
        }
      });
    }

    this.setPassengerCountForChangeFlight(currentBooking);

    let showChangeBookingPopup = false;
    const journeysCount = currentBooking.convertedJourneys.journeys.length;
    if (this.isOneWay && journeysCount > 1) {
      showChangeBookingPopup = this.flightsToChange[0].journeySequenceNumber > 1 ?
        moment(this.outboundDate, this.format).isBefore(moment(currentBooking.convertedJourneys.journeys[0].segments[0].sTD)) :
        moment(this.outboundDate, this.format).isAfter(moment(currentBooking.convertedJourneys.journeys[journeysCount - 1].segments[0].sTD));
    }

    if (showChangeBookingPopup) {
      this.ChangeFlightWarningModal.openPopup(okSelected => {
        if (okSelected) {
          changeFlight();
        }
      });

    } else {
      changeFlight();
    }
  }

  private setPassengerCountForChangeFlight(booking) {

    this.passengerSelection.adults = booking.passengers.items.filter(pax => pax.typeInfo.paxType === Constants.AdultPaxType).length;
    this.passengerSelection.youngAdults = booking.passengers.items.filter(pax => pax.typeInfo.paxType === Constants.YoungAdultPaxType).length;
    this.passengerSelection.children = booking.passengers.items.filter(pax => pax.typeInfo.paxType === Constants.ChildrenPaxType).length;
    this.passengerSelection.teens = booking.passengers.items.filter(pax => pax.typeInfo.paxType === Constants.TeenPaxType).length;
    this.passengerSelection.infants = booking.passengers.infants.items.length;
  }

  async getFlightSearchModel(currencyCode: string): Promise<FlightSearchModel> {

    const searchModel = new FlightSearchModel();

    if (this.isAgent) {
      const currentClassOfService = await this.getCurrentBookingClassOfService();
      console.log("currentClassOfService " + currentClassOfService)
      const hasSpecialClass = this.configService.config.classesOfService.special.indexOf(currentClassOfService) > -1;
      const hasPsoWorkerClass = this.configService.config.classesOfService.psoWorker.indexOf(currentClassOfService) > -1;
      searchModel.classOfService = this.getClassesOfServiceBySpecialPriceOption(
        hasSpecialClass ?
          'specialPrice' :
          hasPsoWorkerClass ? 'psoWorker' : this.selectedSpecialMarketOption)

    }

    searchModel.currency = currencyCode;
    searchModel.origin = this.departure;
    searchModel.destination = this.destination;
    searchModel.isRoundTrip = !this.isOneWay;
    searchModel.passengers = this.passengerSelection.getPassengerSearch();
    searchModel.setDepartureDate(moment(this.outboundDate, this.format).toDate());
    searchModel.usePromo = this.isPromoSelected;

    if (this.inbound) {
      searchModel.setReturnDate(moment(this.inboundDate, this.format).toDate());
    }

    return Promise.resolve(searchModel);
  }

  setFlightsAndNavigate(searchModel: FlightSearchModel) {
    this.flightSearch.searchModel = searchModel;
    this.bookingStepsService.goToStep(CheckinSteps.flights);
  }

  private async getCurrentBookingClassOfService() {
    const currentBooking = await this.bookingService.refresh(true);
    if (currentBooking.cancelFlight.journeys.items.length == 0) return null;
    return currentBooking.cancelFlight.journeys.items[0].segments.items[0].fares.items[0].classOfService;
  }


  getClassesOfServiceBySpecialPriceOption(specialMarketOption: SpecialMarketTypes): string {
    switch (specialMarketOption) {

      case 'noSpecialMarket':
        return this.configService.config.classesOfService.normal.join("_");
      case 'psoWorker':
        return this.configService.config.classesOfService.psoWorker.join('_');
      case 'specialPrice':
        return this.configService.config.classesOfService.special.join('_');
    }
  }

  getSpecialPriceCheckboxText(): string {
    return `Are you a resident in ${this.selectedMarketName}?`;
  }



  promoselect() {
    //this.isPromoSelected = !this.isPromoSelected;

    if (this.isPromoSelected) {
      this.totalPaxCount = this.totalPromoPaxCount;
    }
    else {
      this.totalPaxCount = 9;

    }
  }
  ok() {
    this.modalService.close("numberOfPaxExcededModal");
  }

  private initUserData() {
    this.userStateSubscription = this.userStateService.isLoading
      .pipe(
        filter(isLoading => !isLoading),
        switchMap(() => this.userStateService.isLoggedIn)
      )
      .subscribe(isLoggedIn => isLoggedIn && this.bookingService.getMemberDetails());
  }

  private checkIfIsSpecialPrice() {
    if (!isUndefined(this.isOnFlightsPage) && !this.isOnFlightsPage) return;
    if (!isNullOrUndefined(this.destination)
      && !isNullOrUndefined(this.departure)) {

      const specialMarkets: ISpecialMarket[] = this.configService.config.markets;
      const selectedMarket = specialMarkets.find(item => this.isDestinationOrDepartureInArray(item.routes))

      if (!isNullOrUndefined(selectedMarket)) {
        this.showSpecialPrice = true;
        this.setSpecialPriceIframeUrl(selectedMarket);
        this.selectedMarketName = selectedMarket.marketName;
        this.psoWorkerEnabled = selectedMarket.psoWorkerEnabled;
        return;
      }
    }

    this.showSpecialPrice = false;
  }



  private setSpecialPriceIframeUrl(selectedMarket: ISpecialMarket) {

    const cmsConfig: ISpecialPriceNotificationCms = this.configService.config.cms;
    if (isNullOrUndefined(cmsConfig) || isNullOrUndefined(selectedMarket)) {
      this.urlSafe = "";
      return;
    }
    const language = this.translateService.culture.split("-")[0];

    const iframeUrl = `${cmsConfig.baseUrl}/${language}/pso_${selectedMarket.marketName}`
    const urlBuilder = new URL(iframeUrl)
    urlBuilder.searchParams.append("antiCacheKey", Date.now().toString());
    this.urlSafe = this.sanitizer
      .bypassSecurityTrustResourceUrl(urlBuilder.href);
  }

  private isDestinationOrDepartureInArray(marketsList: string[][]): boolean {
    for (let i = 0; i < marketsList.length; i++) {
      const element = marketsList[i];
      if ((element.indexOf(this.destination) !== -1)
        && element.indexOf(this.departure) !== -1) {
        return true;
      }
    }
    return false;
  }
}

declare interface ISpecialPriceNotificationCms {
  baseUrl: string,
  pages: { pso: string }

}

declare interface ISpecialMarket {
  marketName: string;
  agent: string;
  routes: string[][];
  psoWorkerEnabled?: boolean;
}


