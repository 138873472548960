import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ExtrasComponent } from './extras/extras.component';
import { CheckinRoutes, BookingRoutes } from './extras-routes';
import { UpgradesComponent } from './upgrades/upgrades.component';
import { SharedModule } from '../shared/shared.module';
import { SsrsComponent } from './ssrs/ssrs.component';
import { SsrBoxComponent } from './ssr-box/ssr-box.component';
import { SsrBoxContentLuggageComponent } from './ssr-box-content-luggage/ssr-box-content-luggage.component';
import { SsrBoxContentMealsComponent } from './ssr-box-content-meals/ssr-box-content-meals.component';
import { SsrBoxContentPetsComponent } from './ssr-box-content-pets/ssr-box-content-pets.component';
import { SsrBoxContentSpecialEquipmentComponent } from './ssr-box-content-special-equipment/ssr-box-content-special-equipment.component';
import { SsrsService } from './ssrs.service';
import { CanActivateExtrasService } from './can-activate-extras.service';
import { SsrPassengerPickerComponent } from './ssr-passenger-picker/ssr-passenger-picker.component';
import { SsrListDisplayDirective } from './ssr-list-display.directive';
import { FormsModule } from '@angular/forms';
import { SsrSameOptionsForReturnFlightComponent } from './ssr-same-options-for-return-flight/ssr-same-options-for-return-flight.component';
import { SsrBoxContentSpecialAssistanceComponent } from './ssr-box-content-special-assistance/ssr-box-content-special-assistance.component';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { SsrBoxContentPriorityBoardingComponent } from './ssr-box-content-priority-boarding/ssr-box-content-priority-boarding.component';
import { SsrBoxContentFastTrackComponent } from './ssr-box-content-fast-track/ssr-box-content-fast-track.component';
import { SsrBoxContentPriorityBagComponent } from './ssr-box-content-priority-bag/ssr-box-content-priority-bag.component';
import { environment } from '../../environments/environment';
import { CanActivateExtrasOnBookingService } from './can-activate-extras-on-booking.service';
import { InsuranceComponent } from './insurance/insurance.component';
import { InsuranceService } from './insurance.service';
import { ValidationModalComponent } from './validation-modal/validation-modal.component';
import { CanDeactivateComponentService } from '../core/can-deactivate-component.service';
import { SsrBoxContentLoungeComponent } from './ssr-box-content-lounge/ssr-box-content-lounge.component';
import { SsrPriceDisplayComponent } from './ssr-price-display/ssr-price-display.component';
import { SsrBoxContentAirportCheckinComponent } from './ssr-box-content-airport-checkin/ssr-box-content-airport-checkin.component';
import { SsrBoxContentFlexComponent } from './ssr-box-content-flex/ssr-box-content-flex.component';
import { SsrBoxContentEarlyCheckinComponent } from './ssr-box-content-early-checkin/ssr-box-content-early-checkin.component';
import { SsrBoxContentBusTransfer } from './ssr-box-content-bus-transfer/ssr-box-content-bus-transfer.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    // RouterModule.forRoot(
    //   environment.flow === 1 ? BookingRoutes : CheckinRoutes
    // ),
    SharedModule,
    BlueAirCommonModule
  ],
  declarations: [ExtrasComponent, UpgradesComponent, SsrsComponent, SsrBoxComponent, SsrBoxContentLuggageComponent,
    SsrBoxContentMealsComponent, SsrBoxContentPetsComponent, SsrBoxContentSpecialEquipmentComponent,
    SsrPassengerPickerComponent, SsrListDisplayDirective, SsrSameOptionsForReturnFlightComponent,
    SsrBoxContentSpecialAssistanceComponent, SsrBoxContentPriorityBoardingComponent, SsrBoxContentFastTrackComponent,
    SsrBoxContentPriorityBagComponent, InsuranceComponent, ValidationModalComponent, SsrBoxContentLoungeComponent, SsrPriceDisplayComponent, SsrBoxContentAirportCheckinComponent, SsrBoxContentFlexComponent,
    SsrBoxContentEarlyCheckinComponent, SsrBoxContentBusTransfer],
  exports: [UpgradesComponent, SsrListDisplayDirective, SsrsComponent, SsrBoxComponent, SsrBoxContentLuggageComponent, InsuranceComponent, SsrSameOptionsForReturnFlightComponent],
  providers: [CanActivateExtrasOnBookingService, CanActivateExtrasService, SsrsService, InsuranceService, CanDeactivateComponentService, SsrListDisplayDirective]
})
export class ExtrasModule { }
