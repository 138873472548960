import * as tslib_1 from "tslib";
import { EventEmitter } from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { SsrBoxContentBase } from '../ssr-box-content-base/ssr-box-content-base';
import { SsrsService } from '../ssrs.service';
var SsrBoxContentPetsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SsrBoxContentPetsComponent, _super);
    function SsrBoxContentPetsComponent(ssrsService) {
        var _this = _super.call(this, ssrsService) || this;
        _this.ssrsService = ssrsService;
        _this.carryOnSsrs = [];
        _this.inHoldSsrs = [];
        _this.selectSsrEvent2 = new EventEmitter();
        _this.ssrType = SsrType.Pet;
        _this.uniqueId = Math.floor(Math.random() * 1000) + 1;
        return _this;
    }
    SsrBoxContentPetsComponent.prototype.initFlightSsrList = function () {
        _super.prototype.initFlightSsrList.call(this);
        this.carryOnSsrs = this.availableSsrs.filter(function (s) { return s.isCarryOn; });
        this.inHoldSsrs = this.availableSsrs.filter(function (s) { return !s.isCarryOn; });
    };
    SsrBoxContentPetsComponent.prototype.resetSsr = function () {
        this._removePetInCabin();
        this.selectSsrEvent(null);
    };
    SsrBoxContentPetsComponent.prototype._removePetInCabin = function () {
        this.selectSsr(null, "PETC", 0);
    };
    SsrBoxContentPetsComponent.prototype.addPetToCabin = function (passengerIndex) {
        var _this = this;
        this.selectSsr(passengerIndex, 'PETC', 1);
        this.selectSsrEvent({
            onCancelSeatRemoval: function () { return _this.resetSsr(); }
        });
    };
    SsrBoxContentPetsComponent.prototype.selectPetInHold = function () {
        var _this = this;
        this.selectSsrEvent({
            onCancelSeatRemoval: function () { return _this.passengers.forEach(function (p, index) { return _this.removeAllSsrs(index); }); }
        });
    };
    SsrBoxContentPetsComponent.prototype.selectSsrEvent = function (params) {
        this.selectSsrEvent2.emit(params || {});
    };
    SsrBoxContentPetsComponent.prototype.checkSameSsrOptionsForAllFlights = function () {
        this.sameOptionsForAllFlights = !this.sameOptionsForAllFlights;
        if (this.sameOptionsForAllFlights) {
            this.selectSsrEvent(null);
        }
    };
    return SsrBoxContentPetsComponent;
}(SsrBoxContentBase));
export { SsrBoxContentPetsComponent };
