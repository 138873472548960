import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from 'src/app/core/config.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../core/config.service";
var PushToWalletService = /** @class */ (function () {
    function PushToWalletService(http, configService) {
        this.http = http;
        this.configService = configService;
    }
    PushToWalletService.prototype.retrieveInfo = function (continueFlow) {
        var params = new HttpParams();
        if (continueFlow) {
            params = params.append('cf', 'true');
        }
        return this.http.get(this.configService.PushToWalletUrl, { params: params }).pipe(map(function (response) { return response.pushToWallet; }));
    };
    PushToWalletService.prototype.selectJourneys = function (selectedJourneysSellKeys) {
        return this.http.post(this.configService.PushToWalletUrl, {
            pushToWallet: {
                action: 'SelectJourneys',
                selectedJourneys: selectedJourneysSellKeys
            }
        }).pipe(map(function (response) { return response.pushToWallet; }));
    };
    PushToWalletService.prototype.sendCode = function (details) {
        return this.http.post(this.configService.PushToWalletUrl, {
            pushToWallet: {
                action: 'SendCode',
                details: details
            }
        }).pipe(map(function (response) { return response.pushToWallet; }));
    };
    PushToWalletService.prototype.confirmWithCode = function (code) {
        return this.http.post(this.configService.PushToWalletUrl, {
            pushToWallet: {
                action: 'ConfirmWithCode',
                confirmationCode: code
            }
        }).pipe(map(function (response) { return response.pushToWallet; }));
    };
    PushToWalletService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PushToWalletService_Factory() { return new PushToWalletService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService)); }, token: PushToWalletService, providedIn: "root" });
    return PushToWalletService;
}());
export { PushToWalletService };
var PushToWalletItem = /** @class */ (function () {
    function PushToWalletItem() {
    }
    return PushToWalletItem;
}());
export { PushToWalletItem };
var PushToWalletModel = /** @class */ (function () {
    function PushToWalletModel() {
    }
    return PushToWalletModel;
}());
export { PushToWalletModel };
var PushToWalletDetails = /** @class */ (function () {
    function PushToWalletDetails() {
    }
    return PushToWalletDetails;
}());
export { PushToWalletDetails };
