import { Constants } from '../../constants';
import * as moment from 'moment';
import { PassengerModel } from './passenger-model';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { environment } from '../../../environments/environment';

export class FlightModel {
    type: FlightType;
    sellKey: string;
    referenceKey: string;
    number: string;
    carrierCode: string;
    departureStation: FlightStationInfo;
    arrivalStation: FlightStationInfo;
    stops: number;
    isSelected: boolean;
    passengers: PassengerModel[];
    segments: FlightModel[];
    paidSsrs: FlightSsrs;
    ssrs: FlightSsrs;
    isInternational: boolean = false;
    legs: any[] = [];
    totalFare: number;
    totalFareModifyBooking: number;
    isInThePast: boolean;
    typeCounter?: { adtCount: 0, chdCount: 0 } = null;

    get flightUniqueId(): string {
        return this.departureStation.iataCode + this.arrivalStation.iataCode;
    }

    private paxFareByType: any = {};

    constructor() {
        this.passengers = [];
        this.segments = [];
        this.ssrs = {};
        this.paidSsrs = {};
    }

    static sortFn(a: FlightModel, b: FlightModel): number {
        return a.departureStation.dateUtc > b.departureStation.dateUtc ? 1 :
            a.departureStation.dateUtc === b.departureStation.dateUtc ? 0 : -1;
    }

    public static calculateFares(journey: any, ignoreDiscounts: boolean, bundleDetails?: any, oldJourneyFares?: any, journeyIndex?: number) {
        const groupedByPaxType = journey.segments
            .map(s => s.fares).reduce((a, b) => a.concat(b), [])
            .map(f => f.paxFares).reduce((a, b) => a.concat(b), [])
            // group by paxType
            .reduce((group, fare) => {
                const total = fare.serviceCharges.reduce((t, sc) => {
                    switch (sc.chargeType) {
                        case 'FarePrice':
                        case 'FareSurcharge':
                        case 'ConnectionAdjustmentAmount':
                            t += sc.amount;
                            break;
                        case 'PromotionDiscount':
                            t -= sc.amount;
                            break;
                        case 'Discount':

                            if (!ignoreDiscounts || fare.paxDiscountCode) {
                                t -= sc.amount;
                            }
                            break;
                    }
                    return t;
                }, 0);
                const oldFareIndex = journeyIndex >= 0 ? journeyIndex : journey.journeySequenceNumber - 1;
                const oldFare = oldJourneyFares && oldJourneyFares[oldFareIndex] ? oldJourneyFares[oldFareIndex].value : 0;

                const groupKey = !fare.paxDiscountCode ? fare.paxType : `${fare.paxType}_${fare.paxDiscountCode}`;
                group[groupKey] = (group[groupKey] || 0) + (bundleDetails[fare.paxType] || 0) + total - oldFare;
                return group;
            }, {});

        return groupedByPaxType;
    }

    isFlightInPastOrNearDeparture(): boolean {
        return this.isInThePast;
    }

    addPassenger(passenger: PassengerModel, journey: any) {
        this.passengers.push(passenger);
        this.paidSsrs[passenger.passengerNumber] = [];
        this.ssrs[passenger.passengerNumber] = [];
        let paid = true;

        if (this.type === FlightType.Journey) {
            const groupBy = (group, ssr) => {
                (group[ssr] = group[ssr] || []).push(ssr);
                return group;
            };

            // add ssrs only if not already present on journey
            const fillSsrsForJourney = (thisProp, seg, isPaid) => {
                const list = this.getSsrs(seg, passenger.passengerNumber, isPaid);
                const myGroup: Object = list.reduce(groupBy, {});

                for (let ssrCode in myGroup) {
                    if (myGroup.hasOwnProperty(ssrCode)) {
                        if (this[thisProp][passenger.passengerNumber].indexOf(ssrCode) < 0) {
                            this[thisProp][passenger.passengerNumber].push(...myGroup[ssrCode]);
                        }
                    }
                }
            };

            journey.segments.forEach(seg => {
                fillSsrsForJourney('paidSsrs', seg, paid);
                fillSsrsForJourney('ssrs', seg, !paid);
            });
        } else {
            const segment = journey.segments.find(seg =>
                seg.departureStation === this.departureStation.iataCode &&
                seg.arrivalStation === this.arrivalStation.iataCode
            );

            this.paidSsrs[passenger.passengerNumber] = this.getSsrs(segment, passenger.passengerNumber, paid);
            this.ssrs[passenger.passengerNumber] = this.getSsrs(segment, passenger.passengerNumber, !paid);
        }

        this.segments.forEach(seg => seg.addPassenger(passenger, journey));
    }

    initFromJourney(journey: any, translateService: TranslateService): FlightModel {
        this.type = FlightType.Journey;
        this.sellKey = journey.sellKey;

        // use first segment
        this.departureStation = this.getFlightStationInfo(journey.segments[0], true, translateService);
        // use last segment
        this.arrivalStation = this.getFlightStationInfo(journey.segments[journey.segments.length - 1], false, translateService);
        this.arrivalStation.setNextDay(this.departureStation.date);
        this.stops = this.getNumberOfStops(journey);

        this.segments = journey.segments.map((segment, index) => {
            const segmentModel = new FlightModel();
            segmentModel.initFromSegment(segment, translateService);
            segmentModel.legs = segment.legs;
            return segmentModel;
        });

        this.isInternational = this.segments.findIndex(s => s.isInternational) >= 0;
        return this;
    }

    initFromSegment(segment: any, translateService: TranslateService) {
        this.type = FlightType.Segment;
        this.sellKey = segment.sellKey;
        this.departureStation = this.getFlightStationInfo(segment, true, translateService);
        this.arrivalStation = this.getFlightStationInfo(segment, false, translateService);
        this.arrivalStation.setNextDay(this.departureStation.date);
        this.stops = 0;
        this.number = segment.flightDesignator !== undefined ? segment.flightDesignator.flightNumber : '';
        this.carrierCode = segment.flightDesignator !== undefined ? segment.flightDesignator.carrierCode : '';
        this.isInternational = !this.isInternational && segment.international;
        // tslint:disable-next-line: max-line-length
        this.referenceKey = this.getFlightReference();
    }

    calculateFares(journey: any, bundleDetails?: any, oldJourneyFares?: any): void {
        // TODO add INFANT price
        this.paxFareByType = FlightModel.calculateFares(journey, true, bundleDetails, oldJourneyFares);

        this.totalFareModifyBooking = this.passengers.map(p => this.getPassengerFare(p)).reduce((a, b) => a + b, 0);
        this.totalFare = this.totalFareModifyBooking < 0 ? 0 : this.totalFareModifyBooking;
    }

    calculateInfantFare(passengers: any[], isManageBooking: boolean) {
        const adtPax = passengers.filter(p => p.typeInfo.paxType === Constants.AdultPaxType);
        let singleInfantFare = 0;
        let totalInfantFare = 0;
        if (adtPax.length > 0) {
            for (const adult of adtPax) {
                const fee = adult.fees.items.find(f => f.code === Constants.InfantSsrCode && this.getFlightReferences().indexOf(f.flightReference) > -1);
                if (fee && fee.serviceCharges && fee.serviceCharges.items) {
                    const charge = fee.serviceCharges.items.find(sc => sc.chargeCode === Constants.InfantSsrCode);
                    if (charge) {
                        if (singleInfantFare === 0) {
                            singleInfantFare = charge.amount;
                        }

                        totalInfantFare += charge.amount;
                    }
                }
            }
        }
        this.paxFareByType[Constants.InfantSsrCode] = isManageBooking ? 0 : singleInfantFare;
        this.totalFare += isManageBooking ? 0 : totalInfantFare;
    }

    getPassengerFare(passenger: PassengerModel): number {
        if (passenger.isInfant) {
            return this.paxFareByType[Constants.InfantSsrCode] || 0;
        }
        return this.paxFareByType[passenger.paxType];
    }

    setFlightInThePast(cancelDepartureTimeLimit: string) {
        const timeLimit = cancelDepartureTimeLimit.split(':');
        this.isInThePast = moment().add(Number(timeLimit[0]), 'hour')
            .add(Number(timeLimit[1]), 'minute')
            .add(Number(timeLimit[2]), 'seconds')
            .isAfter(moment(this.departureStation.date));
    }

    private getFlightStationInfo(segment: any, isDeparture: boolean, translateService: TranslateService): FlightStationInfo {
        const legIndex = isDeparture ? 0 : segment.legs.length - 1;
        const leg = segment.legs[legIndex];

        const st = new FlightStationInfo(
            isDeparture ? leg.departureStation : leg.arrivalStation,
            isDeparture ? leg.sTD : leg.sTA,
            isDeparture ? leg.legInfo.deptLTV : leg.legInfo.arrvLTV
        );

        st.name = translateService.instant(st.iataCode, 'station.name');

        return st;
    }

    private getNumberOfStops(journey: any): number {
        let count = 0;
        for (let i = 0; i < journey.segments.length; i++) {
            count += journey.segments[i].legs.length;
        }
        return count - 1;
    }

    private getSsrs(segment: any, passengerNumber: number, paid: boolean): string[] {
        if (paid) {
            return segment.paxSSRs
                .filter(s => s.passengerNumber === passengerNumber && (s.actionStatusCode === 'HK' || s.isInServiceBundle))
                .map(s => s.sSRCode as string);
        }
        else {
            return segment.paxSSRs
                .filter(s => s.passengerNumber === passengerNumber && !s.isInServiceBundle && (s.actionStatusCode.match(/.{1,2}/g).includes('KK') || s.actionStatusCode.match(/.{1,2}/g).includes('KU')))
                .map(s => s.sSRCode as string);
        }
    }

    protected getFlightReferences(): string[] {
        return this.segments.map(segment => segment.referenceKey);
    }

    protected getFlightReference(segment: FlightModel = this) {
        const flightNumber = `    ${segment.number}`.slice(-4);
        // tslint:disable-next-line: max-line-length
        return `${moment(segment.departureStation.dateUtc).format('YYYYMMDD')} ${segment.carrierCode.trim()}${flightNumber} ${segment.departureStation.iataCode}${segment.arrivalStation.iataCode}`
    }


}

export class FlightStationInfo {
    iataCode: string;
    name: string;
    date: Date;
    dateUtc: Date;
    nextDay: boolean;

    constructor(iataCode: string, dateStr: string, ltv: number) {
        this.iataCode = iataCode;
        const momentDate = moment(dateStr);
        this.date = momentDate.toDate();
        this.dateUtc = momentDate.subtract(ltv, 'minutes').toDate();
        this.name = this.iataCode;
    }

    setNextDay(comparisonDay: Date) {
        this.nextDay = this.date.getDay() !== comparisonDay.getDay();
    }
}

export enum FlightType {
    Journey,
    Segment
}

export interface FlightSsrs { [passengerNumber: number]: string[]; }
