<ng-container [ngSwitch]="ssrType">
  <ng-container *ngSwitchCase="SsrTypeEnum.Meal">
    <check-in-default-modal [modalId]="freeMealModalId" modalTitle="{{ 'Free-Meal-Title' | translate:'extras' }}"
      modalText="{{ 'Free-Meal-Text' | translate:'extras' }}"
      okButtonText="{{ 'Add-Free-Default-Meal-Button-Text' | translate:'extras' }}"
      cancelButtonText="{{ 'Select-Free-Meal-Button-Text' | translate:'extras' }}" [okRequired]="true"
      [cancelRequired]="true">

      <div>
        <div *ngIf="showSingleSegmentInfo && defaultMeals.length > 0 else showMultipleSegmentInfo">
          <p>{{'Default available meal' | translate:'extras'}}:
            {{defaultMeals[0].Meal.ssrCode | translate:'ssr.name'}}</p>
        </div>

        <ng-template #showMultipleSegmentInfo>
          <div *ngFor="let item of defaultMeals">
            <p>{{'Segment' | translate:'extras'}}:
              {{item.Segment.departureStation.iataCode |
              translate:'station.name'}}_{{item.Segment.arrivalStation.iataCode | translate:'station.name'}}
            </p>
            <p>{{'Default available meal' | translate:'extras'}}: {{item.Meal.ssrCode | translate:'ssr.name'}}</p>
          </div>
        </ng-template>
      </div>

    </check-in-default-modal>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <check-in-default-modal *ngIf="false && includesPushSsr" cssClass="half-split asd" leftButtonClass="inverted"
      [modalId]="pushSsrModalId" modalTitle="{{ 'ssrPush-title.' + currentSsrType | translate:'extras' }}"
      okButtonText="{{ 'ssrPush-cancel.' + currentSsrType | translate:'extras' }}"
      cancelButtonText="{{ 'ssrPush-add.' + currentSsrType | translate:'extras' }}" [okRequired]="true"
      [cancelRequired]="true">
      <div class="half-split-row">
        <div class="half-split-column">
          <div class="image-wrapper">
            <i class="font-ico-luggage-hand image" role="presentation"></i>
          </div>
          <div class="section-SubTitle">
            <p innerHTML="{{ 'ssrPush-cancel-title.' + currentSsrType | translate:'extras' }}"></p>
          </div>
          <div>
            <ul class="list">
              <li *blueTanslateItems="'ssrPush.cancel.items.' + currentSsrType; let translation">
                <span role="presentation"
                  class="ssr-icon font2-ico-bundle-ssrs-clar-scab font2-ico-ssrs-scab font-ico-checked-sign"></span>
                <div class="text" [innerHtml]="translation"></div>
              </li>
            </ul>
          </div>
        </div>
        <div class="half-split-column">
          <div class="image-wrapper">
            <i class="font-ico-priority-boarding-2bags image" role="presentation"></i>
          </div>
          <div class="section-SubTitle">
            <p innerHTML="{{ 'ssrPush-add-title.' + currentSsrType | translate:'extras' }}"></p>
          </div>
          <div class="section-text">
            <ul class="list">
              <li *blueTanslateItems="'ssrPush.add.items.' + currentSsrType; let translation">
                <span role="presentation"
                  class="ssr-icon font2-ico-bundle-ssrs-clar-scab font2-ico-ssrs-scab font-ico-checked-sign"></span>
                <div class="text" [innerHtml]="translation"></div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </check-in-default-modal>
  </ng-container>
</ng-container>