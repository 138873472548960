<check-in-form-wrapper ariaLabel="{{ 'Step 9' | translate: 'Booking' }}" [step]="'step-9'" [showPriceDisplay]="true"
  (continue)="confirmChanges()">
  <div class="form-wrapper-middle-body">
    <h1>{{ 'Review your changes' | translate:'booking-changes'}}</h1>
    <!-- flights changes -->
    <div class="itineraryBox_wrapper" *ngIf="!isRestricted">
      <div class="itineraryBox_title">
        <h2>{{'Flights'|translate:'itinerary'}}</h2>
      </div>
      <ng-container *ngIf="!showFlightChanges">
        <div class="extrasSummary_wrapper segm0">
          <div class="extrasSummary_rowData notFound">
            <br />
            <div>{{'No flights modified!'|translate:'itinerary'}}</div>
          </div>
        </div>
      </ng-container>
      <div *ngIf="showFlightChanges">
        <div>
          <div class="itineraryBox_subTitle">{{'Old flights' | translate:'booking-changes'}}</div>
          <div class="flightSummary_wrapper" *ngFor="let journey of flightChanges.oldJourneys; let i = index">
            <div class="flightSummary_flightInfo">
              <div class="flightSummary_originDestination">
                <div *ngIf="changeDateActive; else test">
                  <label
                    for="{{journey.segments.items[0].departureStation|translate:'station.name'}}-{{journey.segments.items[journey.segments.length - 1].arrivalStation|translate:'station.name'}}"></label>
                  <span
                    class="flightSummary_origin ">{{journey.segments.items[0].departureStation|translate:'station.name'}}</span><span>—</span><span
                    class="flightSummary_destination">{{journey.segments.items[journey.segments.items.length -
                    1].arrivalStation|translate:'station.name'}}</span>
                </div>
                <ng-template #test>
                  <span
                    class="flightSummary_origin">{{journey.segments.items[0].departureStation|translate:'station.name'}}</span><span>—</span><span
                    class="flightSummary_destination">{{journey.segments.items[journey.segments.items.length -
                    1].arrivalStation|translate:'station.name'}}</span>
                </ng-template>
              </div>
              <div class="flightSummary_date">
                {{journey.segments.items[0].std | customDate:'date'}}
              </div>
            </div>
            <div class="flightSummary_flightDetails">
              <div class="flightSummary_flightDesigantors">
                <div class="flightSummary_flightDesigantor" *ngFor="let segment of journey.segments.items">
                  <img [src]="assetsPath + 'img/' + segment.flightDesignator.carrierCode + '.png'">
                  <div class="flightSummary_flightNumber">{{segment.flightDesignator.carrierCode}}
                    {{segment.flightDesignator.flightNumber}} </div>
                </div>
              </div>
              <div class="flightSummary_flightSegments">
                <div class="flightSummary_flightSegment" *ngFor="let segment of journey.segments.items">
                  <div class="flightSummary_leftDetails">
                    <span class="flightSummary_location">{{segment.departureStation}}</span>
                    <time class="flightSummary_timeBig">{{segment.std | customDate:'time'}}</time>
                  </div>
                  <div class="flightSummary_middleDetails">
                    <span class="font-ico-plane-runway"></span>
                    <time class="flightSummary_duration"> {{segment.std | duration:segment.sta:false}}</time>
                  </div>
                  <div class="flightSummary_rightDetails">
                    <span class="flightSummary_location">{{segment.arrivalStation}}</span>
                    <time class="flightSummary_timeBig">{{segment.sta | customDate:'time'}}</time>
                  </div>
                </div>

              </div>
              <div class="connection-detail" *ngIf="journey.segments.items.length > 1">{{'Connection' | translate:
                'itinerary'}}</div>
            </div>
          </div>
        </div>
        <div>
          <div class="itineraryBox_subTitle">{{'New flights' | translate:'booking-changes'}}</div>
          <div class="flightSummary_wrapper" *ngFor="let journey of flightChanges.newJourneys; let i = index">
            <div class="flightSummary_flightInfo">
              <div class="flightSummary_originDestination">
                <div *ngIf="changeDateActive; else test">
                  <label
                    for="{{journey.segments.items[0].departureStation|translate:'station.name'}}-{{journey.segments.items[journey.segments.items.length - 1].arrivalStation|translate:'station.name'}}"></label>
                  <span
                    class="flightSummary_origin ">{{journey.segments.items[0].departureStation|translate:'station.name'}}</span><span>—</span><span
                    class="flightSummary_destination">{{journey.segments.items[journey.segments.items.length -
                    1].arrivalStation|translate:'station.name'}}</span>
                </div>
                <ng-template #test>
                  <span
                    class="flightSummary_origin">{{journey.segments.items[0].departureStation|translate:'station.name'}}</span><span>—</span><span
                    class="flightSummary_destination">{{journey.segments.items[journey.segments.items.length -
                    1].arrivalStation|translate:'station.name'}}</span>
                </ng-template>
              </div>
              <div class="flightSummary_date">
                {{journey.segments.items[0].std | customDate:'date'}}
              </div>
            </div>
            <div class="flightSummary_flightDetails">
              <div class="flightSummary_flightDesigantors">
                <div class="flightSummary_flightDesigantor" *ngFor="let segment of journey.segments.items">
                  <img [src]="assetsPath + 'img/' + segment.flightDesignator.carrierCode + '.png'">
                  <div class="flightSummary_flightNumber">{{segment.flightDesignator.carrierCode}}
                    {{segment.flightDesignator.flightNumber}} </div>
                </div>
              </div>
              <div class="flightSummary_flightSegments">
                <div class="flightSummary_flightSegment" *ngFor="let segment of journey.segments.items">
                  <div class="flightSummary_leftDetails">
                    <span class="flightSummary_location">{{segment.departureStation}}</span>
                    <time class="flightSummary_timeBig">{{segment.std | customDate:'time'}}</time>
                  </div>
                  <div class="flightSummary_middleDetails">
                    <span class="font-ico-plane-runway"></span>
                    <time class="flightSummary_duration"> {{segment.std | duration:segment.sta:false}}</time>
                  </div>
                  <div class="flightSummary_rightDetails">
                    <span class="flightSummary_location">{{segment.arrivalStation}}</span>
                    <time class="flightSummary_timeBig">{{segment.sta | customDate:'time'}}</time>
                  </div>
                </div>

              </div>
              <div class="connection-detail" *ngIf="journey.segments.items.length > 1">{{'Connection' | translate:
                'itinerary'}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- extras changes -->
    <div class="itineraryBox_wrapper">
      <div class="itineraryBox_title">
        <h2>{{'Extras'|translate:'itinerary'}}</h2>
      </div>
      <div>
        <ng-container *ngIf="(ssrChanges.length === 0 && insuranceChanges.length === 0) || !showExtrasChanges">
          <div class="extrasSummary_wrapper segm0">
            <div class="extrasSummary_rowData notFound">
              <br />
              <div>{{'No extras modified!'|translate:'itinerary'}}</div>
            </div>
          </div>
        </ng-container>
        <div class="extrasSummary_wrapper segm{{segmentsNumber}}" *ngIf="showExtrasChanges">
          <div *ngIf="ssrChanges.length > 0 || insuranceChanges && insuranceChanges.length"
            class="extrasSummary_header">
            <div>
              <div> {{ 'Passenger' | translate:'itinerary' }}</div>
              <ng-container *ngFor="let journey of journeys">
                <ng-container *ngFor="let segment of journey.segments">
                  <div>
                    {{ '{0} to {1}' | translate: null : [segment.departureStation | translate: 'station.name'] : [segment.arrivalStation | translate: 'station.name']}}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <!-- ssrs -->
          <ng-container *ngFor="let ssrType of ssrChanges">
            <ng-container>
              <div class="extrasSummary_rowTitle" (click)="toggleVisibility(extrasToggle)">
                {{ ssrType.ssrTypeName | splitcamelcasestring | translate: 'itinerary' }}
              </div>
              <div [ngClass]="{'extrasSummary_mobileToggle': mobileMatches}" #extrasToggle>
                <ng-container *ngFor="let paxSsr of ssrType.passengerSsrsByType">
                  <div class="extrasSummary_rowData">
                    <div>
                      <div *ngIf="!mobileMatches" class="extrasSummary_mobileLeft">
                        {{'Passenger' | translate:'itinerary' }}
                      </div>
                      <div class="extrasSummary_mobileRight title">
                        <b>
                          {{paxSsr.passenger.name.title}} {{paxSsr.passenger.name.first}}
                          {{paxSsr.passenger.name.last}}
                        </b>
                      </div>
                    </div>
                    <div *ngFor="let ssr of paxSsr.ssrCountOnSegment">
                      <div class="extrasSummary_mobileSegment" *ngIf="mobileMatches && ssr.ssrCount[0].code">
                        <b>
                          <div>
                            {{ '{0} to {1}' | translate: null : [ssr.segment.DepartureStation | translate: 'station.name'] : [ssr.segment.ArrivalStation | translate: 'station.name']}}
                          </div>
                        </b>
                      </div>
                      <div class="a" *ngFor="let ssrCount of ssr.ssrCount">
                        <ng-container *ngIf="ssrCount.code else emptyDiv">
                          <!-- if ssr was not on booking before -->
                          <ng-container
                            *ngIf="ssrCount.newValue > ssrCount.oldValue && ssrCount.oldValue == 0 || ssrCount.newValue == ssrCount.oldValue else ssrHasNewValue">
                            {{ssrCount.code | translate:'ssr.name'}} x {{ ssrCount.newValue }}
                          </ng-container>
                          <!-- if ssr was on booking before && changes were made -->
                          <ng-template #ssrHasNewValue>
                            <span class="oldSsrValue">
                              {{ssrCount.code | translate:'ssr.name'}} x {{ ssrCount.oldValue }}
                            </span>
                            {{ssrCount.code | translate:'ssr.name'}} x {{ ssrCount.newValue }}
                          </ng-template>
                        </ng-container>
                        <ng-template *ngIf="!mobileMatches" #emptyDiv></ng-template>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <!-- insurance -->
          <ng-container>
            <ng-container *ngIf="insuranceChanges && insuranceChanges.length">
              <div class="extrasSummary_rowTitle" (click)="toggleVisibility(extrasToggle)">
                {{ 'Insurance' | splitcamelcasestring | translate:'itinerary' }}
              </div>
              <!-- show passengers insurance -->
              <div [ngClass]="{'extrasSummary_mobileToggle': mobileMatches}" #extrasToggle>
                <ng-container *ngFor="let ins of insuranceChanges">
                  <div class="extrasSummary_rowData">
                    <div>
                      <div *ngIf="!mobileMatches" class="extrasSummary_mobileLeft">{{'Passenger'|translate:'itinerary'}}
                      </div>
                      <div class="extrasSummary_mobileRight title">
                        <b>
                          {{ins.passenger.name.title}} {{ins.passenger.name.first}}
                          {{ins.passenger.name.last}}
                        </b>
                      </div>
                    </div>
                    <div class="custom-insurance">
                      <span *ngIf="journeys && journeys.length > 1 else oneWayInsuranceDisplay">
                        {{ 'Travel insurance'| translate:'extras'}}
                      </span>
                      <ng-template #oneWayInsuranceDisplay>
                        <span>
                          {{ins.insuranceName | translate:'insurance.days'}}
                        </span>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- seats changes -->
    <div class="itineraryBox_wrapper">
      <div class="itineraryBox_title">
        <h2>{{'Seats'|translate:'itinerary'}}</h2>
      </div>
      <div>
        <ng-container *ngIf="seatChanges.length === 0">
          <div class="extrasSummary_wrapper segm0">
            <div class="extrasSummary_rowData notFound">
              <br />
              <div>{{'No seats modified!'|translate:'itinerary'}}</div>
            </div>
          </div>
        </ng-container>
        <div class="extrasSummary_wrapper segm{{segmentsNumber}}">
          <div *ngIf="seatChanges.length > 0 && showSeatChanges" class="extrasSummary_header">
            <div>
              <div>{{'Passenger'|translate:'itinerary'}}</div>
              <ng-container *ngFor="let journey of journeys">
                <ng-container *ngFor="let segment of journey.segments">
                  <div>
                    {{ '{0} to {1}' | translate: null : [segment.departureStation | translate: 'station.name'] : [segment.arrivalStation | translate: 'station.name']}}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <ng-container *ngFor="let seatChange of seatChanges">
            <ng-container>
              <div *ngFor="let seat of seatChange.passengerSsrsByType">
                <div class="extrasSummary_rowData">
                  <div class="seatTitle">
                    <div *ngIf="!mobileMatches" class="extrasSummary_mobileLeft">
                      {{'Passenger' | translate:'itinerary' }}
                    </div>
                    <div class="extrasSummary_mobileRight title">
                      <b>
                        {{seat.passenger.name.title}} {{seat.passenger.name.first}}
                        {{seat.passenger.name.last}}
                      </b>
                    </div>
                  </div>
                  <div class="seatsContent" *ngFor="let ssr of seat.ssrCountOnSegment">
                    <div class="extrasSummary_mobileSegment seatLeft" *ngIf="mobileMatches">
                      {{ '{0} to {1}' | translate: null : [ssr.segment.DepartureStation | translate: 'station.name'] : [ssr.segment.ArrivalStation | translate: 'station.name']}}
                    </div>
                    <div class="seatRight" *ngFor="let ssrCount of ssr.ssrCount">
                      <div
                        *ngIf="ssrCount.newValue != ssrCount.oldValue && ssrCount.oldValue == 'N/A' else seatNewValue">
                        <span>
                          {{ ssrCount.newValue }}
                        </span>
                      </div>
                      <ng-template #seatNewValue>
                        <span class="oldSsrValue">
                          {{ ssrCount.oldValue }}
                        </span>
                        {{ ssrCount.newValue }}
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- passengers changes -->
    <div class="itineraryBox_wrapper">
      <div class="itineraryBox_title">
        <h2>{{'Passengers'|translate:'itinerary'}}</h2>
      </div>
      <div>
        <ng-container *ngIf="!showPassengerChanges">
          <div class="extrasSummary_wrapper segm0">
            <div class="extrasSummary_rowData notFound">
              <br />
              <div>{{'No passengers modified!'|translate:'itinerary'}}</div>
            </div>
          </div>
        </ng-container>
        <div class="paymentSummary_wrapper" *ngIf="showPassengerChanges">
            <ng-container *ngFor="let pax of passengerChanges">
              <div class="paymentSummary_rowData changes">
                <div>
                  <b>
                    {{pax.name}}
                  </b>
                </div>
                <div>
                  <div *ngFor="let change of pax.modifiedFields; let i = index">
                    {{change | translate:'itinerary'}}:
                    <span class="oldSsrValue">
                      {{pax.oldValues[i]}}
                    </span>
                    {{pax.newValues[i]}}
                  </div>
                </div>
              </div>
            </ng-container>
        </div>
      </div>
    </div>
  </div>
</check-in-form-wrapper>
