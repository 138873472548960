<div class="cart-extra-total">
  <div class="left">
    <span class="total-label">{{ 'Flights Total' | translate:'price-display' }}</span>
  </div>
  <div class="right">
    <span class="total-value strikeout" *ngIf="flights.length && originalTotal">
      {{ originalTotal | currencyDisplay }}
    </span>
    <span class="total-value"
      attr.aria-label="{{'Total {0}'|translate:'price-display':[ flights.length ?( total | currencyDisplay) : (total | cultureFormatNumber:'1.2-2') ]}}">{{
      flights.length ?( total | currencyDisplay) : (total | cultureFormatNumber:'1.2-2') }}</span>
  </div>
</div>

<div class="extra-item" [ngClass]="{'open' : isExpanded[fIndex]  }" *ngFor="let flight of flights; let fIndex = index">
  <button type="button" class="extra-item-summary"
    attr.aria-label="{{ 'Click to expand {0} for more options' | translate:'price-display':[fIndex === 0 ? 'Outbound' : 'Return' | translate:'price-display']}}"
    (click)="toggleExpanded(fIndex)">
    <i class="icon" [ngClass]="{'font-ico-plane-runway': fIndex === 0, 'font-ico-plane-arrive' : fIndex !== 0 }"
      role="presentation"></i>
    <span class="title">{{ (fIndex === 0 ? (titleUnknown && flights.length == 1 ? 'Selected flight' : 'Outbound') :
      'Return') | translate:'price-display' }}</span>
    <div>
      <span class="details">{{ flight.departureDate | customDate:'fullDate'}}</span>
    </div>
    <span class="summary-value">{{ flight.amount | currencyDisplay }}</span>
  </button>

  <shopping-cart-flight-details [flight]="flight" *ngIf="isExpanded[fIndex]"></shopping-cart-flight-details>

  <ul role="menu" class="extra-flight-details" [style.display]="isExpanded[fIndex] ? 'block':''">
    <li role="menuitem" *ngFor="let passenger of flight.passengers" class="expandable"
      [ngClass]="{ 'expandable': passenger.fees.length, 'open': passenger.isExpanded }">
      <!-- next div should be an invisible button  -->
      <div class="extra-flight-details-label" (click)="passenger.isExpanded = !passenger.isExpanded">
        <span class="left"
          [innerHTML]="getExtraFlightDetails(passenger.count, passenger.passengerType.substring(0,3) + '-title', flight.selectedBundleCode)">
          <!-- {{ passenger.count }} x {{ passenger.passengerType.substring(0,3) + '-title' | translate:'price-display'}} {{ flight.selectedBundleCode | translate:'Booking' }} -->
        </span>
        <span class="right">{{ passenger.amount | currencyDisplay }}</span>
      </div>

      <ng-container
        *ngTemplateOutlet="feeListTemplate; context:{fees: passenger.fees, isExpanded: passenger.isExpanded}">
      </ng-container>
    </li>
  </ul>
</div>

<ng-template #feeListTemplate let-fees="fees" let-isExpanded="isExpanded">
  <ul role="menu" class="expandable-inner" *ngIf="fees.length && isExpanded" style="display: block">
    <li *ngFor="let fee of fees" [ngClass]="{ 'expandable': fee.items.length, 'open': fee.isExpanded }">
      <!-- next div should be an invisible button  -->
      <div class="extra-flight-details-label" (click)="fee.isExpanded = !fee.isExpanded">
        <span class="left">{{fee.feeCode + ' fee' | translate:'price-display' }}</span>
        <span class="right">{{fee.amount | cultureFormatNumber:'1.2-2'}}</span>
      </div>
      <ng-container *ngTemplateOutlet="feeListTemplate; context:{fees: fee.items, isExpanded: fee.isExpanded}">
      </ng-container>
    </li>
  </ul>
</ng-template>