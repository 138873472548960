/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./booking-unsubscribe-notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../common-modules/blue-air-common/translator/translate.pipe";
import * as i3 from "../common-modules/blue-air-common/translator/translate.service";
import * as i4 from "@angular/common";
import * as i5 from "./booking-unsubscribe-notifications.component";
import * as i6 from "@angular/router";
import * as i7 from "../core/booking.service";
var styles_BookingUnsubscribeNotificationsComponent = [i0.styles];
var RenderType_BookingUnsubscribeNotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BookingUnsubscribeNotificationsComponent, data: {} });
export { RenderType_BookingUnsubscribeNotificationsComponent as RenderType_BookingUnsubscribeNotificationsComponent };
function View_BookingUnsubscribeNotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "])), i1.ɵppd(6, 2), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unsubscribeNotifications() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "Are you sure?", "find-my-bookings")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "You will lose all specials offers for booking code", "find-my-bookings")); _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.pnr; _ck(_v, 8, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), "Unsubscribe", "find-my-bookings")); _ck(_v, 10, 0, currVal_3); }); }
function View_BookingUnsubscribeNotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " "])), i1.ɵppd(3, 2), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "We`re sorry to see you go", "find-my-bookings")); _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), "You are no longer subscribed to our emails", "find-my-bookings")); _ck(_v, 5, 0, currVal_1); }); }
export function View_BookingUnsubscribeNotificationsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TranslatePipe, [i3.TranslateService]), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "unsubscribe-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BookingUnsubscribeNotificationsComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["thankYou", 2]], null, 0, null, View_BookingUnsubscribeNotificationsComponent_2)), (_l()(), i1.ɵeld(6, 0, null, null, 12, "section", [["class", "section content-banner"], ["style", "background-image: url(assets/img/content/unsubscribe_web.png); display:none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 11, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 10, "div", [["class", "banner-inner text-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", " "])), i1.ɵppd(11, 2), (_l()(), i1.ɵeld(12, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", " ", ""])), i1.ɵppd(14, 2), (_l()(), i1.ɵeld(15, 0, null, null, 3, "div", [["class", "btn-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 2, "a", [["class", "btn btn-primary btn-white"], ["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unsubscribeNotifications() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(17, null, ["", ""])), i1.ɵppd(18, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.unsubscribed; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v, 0), "Are you sure?", "find-my-bookings")); _ck(_v, 10, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 0), "You will lose all specials offers for booking code", "find-my-bookings")); var currVal_4 = _co.pnr; _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 17, 0, _ck(_v, 18, 0, i1.ɵnov(_v, 0), "Unsubscribe", "find-my-bookings")); _ck(_v, 17, 0, currVal_5); }); }
export function View_BookingUnsubscribeNotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-booking-unsubscribe-notifications", [], null, null, null, View_BookingUnsubscribeNotificationsComponent_0, RenderType_BookingUnsubscribeNotificationsComponent)), i1.ɵdid(1, 114688, null, 0, i5.BookingUnsubscribeNotificationsComponent, [i6.ActivatedRoute, i7.BookingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BookingUnsubscribeNotificationsComponentNgFactory = i1.ɵccf("check-in-booking-unsubscribe-notifications", i5.BookingUnsubscribeNotificationsComponent, View_BookingUnsubscribeNotificationsComponent_Host_0, {}, {}, []);
export { BookingUnsubscribeNotificationsComponentNgFactory as BookingUnsubscribeNotificationsComponentNgFactory };
