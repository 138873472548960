<div class="checkin-extra-options" *ngIf="visible && !hasCheckedInPax">
    <blue-loading-spinner [id]="spinnerId"></blue-loading-spinner>
    <div class="checkin-box" *ngIf="showHeader && !isExpanded">
        <div class="bundle-options-header">
            <div class="left">
                <div class="text-holder" *ngIf="bannerBundleSsrList.length > 0">
                    <h3 [innerHTML]="'Upgrade your flights' | translate:'bundle'">
                    </h3>
                    <ul class="bundle-checked-box" role="menu" *ngIf="bannerBundleSsrList.length > 0">
                        <li *ngFor="let ssr of bannerBundleSsrList" role="menuitem">
                            <span *ngIf="ssr.ssrCode !== 'CKN'"
                                class="ssr-icon bundle-upgrade white font2-ico-bundle-ssrs-{{ssr.bundleCode | lowercase}}-{{ssr.ssrCode | lowercase}} font2-ico-ssrs-{{ssr.ssrCode | lowercase}} font-ico-checked-sign"
                                role="presentation">
                            </span>
                            <span *ngIf="ssr.ssrCode !== 'CKN' && flights.length > 0"
                                [innerHTML]="ssr.ssrCode | ssrTranslate: ssr.bundleCode : flights[0].departureStation.date">
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="text-holder" *ngIf="!bannerBundleSsrList.length">
                    <h3 [innerHTML]="'Flight Upgrade' | translate:'bundle'">
                    </h3>
                    <ul class="bundle-checked-box" role="menu">
                        <div class="bundle-updated-max-wrap">
                            <div class="bundle-updated-max-flight" *ngFor="let upgrade of upgradedFlightsList">
                                <div class="bundle-updated-max-stations">{{'{0} to {1}:' |
                                    translate:'':[upgrade.departureStation |
                                    translate:'station.name']:[upgrade.arrivalStation | translate:'station.name']}}
                                </div>
                                <div class="bundle-updated-max-bundles" [innerHTML]="'{0} to {1} upgrade' | translate: 'bundle':
                                        [upgrade.initialBundle | translationWithHtml: 'bundle.name']:
                                        [upgrade.selectedBundle | translationWithHtml: 'bundle.name']">
                                </div>
                            </div>
                        </div>
                    </ul>
                </div>
            </div>
            <div class="right" *ngIf="temporaryTotalPrice == 0.00 else modified">
                <div class="right-inner default-view">
                    <span> {{ 'All passengers from' | translate:'bundle' }} </span>
                    <div class="price" *ngIf="startingFromPrice">{{ startingFromPrice * paxCount| currencyDisplay }}
                    </div>
                    <button class="btn btn-primary btn-big btn-bundle-upgrade" (click)="toggleExpanded()">{{ 'Upgrade' |
                        translate:'bundle' }}
                    </button>
                </div>
            </div>
            <ng-template #modified>
                <div class="right modified">
                    <div class="right-inner default-view">
                        <button type="button" class="remove-from-cart" (click)="sellBundles(true)">
                            <span class="font-ico-close-menu">
                                <span class="sr-only">{{ 'Click to remove from cart' | translate:'extras' }}</span>
                            </span>
                        </button>
                        <span class="font-ico-checked-sign" role="presentation"></span>
                        <strong>{{ 'Added to cart' | translate:'extras' }}</strong>
                        <div class="single-bundle-upgrade-wrap" *ngIf="bannerBundleSsrList.length">
                            <ng-container *ngFor="let upgrade of upgradedFlightsList">
                                <div class="flight-items"
                                    [innerHTML]="'{0} to {1}' | translate: '': upgrade.departureStation: upgrade.arrivalStation">
                                </div>
                                <div class="single-bundle-upgrade" [innerHTML]="'{0} to {1} upgrade' | translate: 'bundle':
                                        [upgrade.initialBundle | translationWithHtml: 'bundle.name']:
                                        [upgrade.selectedBundle | translationWithHtml: 'bundle.name']">
                                </div>
                            </ng-container>
                        </div>
                        <div class="price" attr.aria-label="{{ temporaryTotalPrice + 'Romanian Leu'}}">
                            {{ temporaryTotalPrice * paxCount | currencyDisplay }}
                        </div>
                        <button type="button" class="btn btn-secondary"
                            attr.aria-label="{{ 'Click to modify' | translate:'extras' }}" (click)="toggleExpanded()">
                            {{ 'Modify' | translate:'extras' }}
                        </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <div class="checkin-box" *ngIf="!showHeader || isExpanded">
        <div class="checkin-options-header">
            <div class="left">
                <h3 [innerHTML]="'Upgrade your flights' | translate:'bundle'"></h3>
                <div class="strapline" [innerHTML]="'Upgrade your fare(s) for all passengers' | translate:'bundle'">
                </div>
            </div>
            <button type="button" class="btn btn-secondary blue collapse-item" aria-hidden="true"
                attr.aria-label="{{ 'Click to cancel' | translate:'extras' }}" (click)="cancelChanges()">{{ 'Cancel' |
                translate:'extras' }}</button>
        </div>
        <div class="checkin-bundle-options-content">
            <check-in-accordion>
                <div class="trip-info">
                    <ng-container>{{ '{0} to {1}' |
                        translate:'':flights[0].departureStation.name:flights[0].arrivalStation.name}} - {{
                        flights[0].departureStation.date | customDate:'fullDate'}}</ng-container>
                    <br>
                    <ng-container *ngIf="flights[1]">{{ '{0} to {1}' |
                        translate:'':flights[1].departureStation.name:flights[1].arrivalStation.name}} - {{
                        flights[1].departureStation.date | customDate:'fullDate'}}</ng-container>
                </div>
                <!-- <check-in-accordion-item *ngIf="!flight.isInThePast" ariaLabel="{{ 'Click to open options for {0} to {1}' | translate:'extras':flight.departureStation.name:flight.arrivalStation.name}}" title="{{ '{0} to {1}' | translate:'':flight.departureStation.name:flight.arrivalStation.name}}"
                        disabledText="{{ 'Not available' | translate:'extras' }}" [disabled]="!isBundleUpgradeAvailable(i)" price="{{currentPricesSelected[i] * paxCount | currencyDisplay}}"> -->
                <ng-container *ngIf="journeyBundles[0]">
                    <div class="checkin-bundle-options-buttons-wrap">
                        <button *ngFor="let bundle of journeyBundles[0].bundleList; let bi = index"
                            [ngClass]="getCustomClasses(bi,0)" class="checkin-bundle-offer-wrapper"
                            (click)="selectBundle(bi, 0, bundle.price)">
                            <div class="checkin-bundle-offer-content">
                                <h5 [innerHTML]="bundle.serviceBundleCode | ssrTranslate">
                                </h5>
                                <ul class="bundle-offer-checked-box" role="menu">
                                    <li *ngFor="let serviceBundleSsr of bundle.serviceBundleSsrs">
                                        <span *ngIf="serviceBundleSsr.ssrCode !== 'CKN'"
                                            class="ssr-icon bundle-upgrade font2-ico-bundle-ssrs-{{bundle.serviceBundleCode | lowercase}}-{{serviceBundleSsr.ssrCode | lowercase}} blue-dark font2-ico-ssrs-{{serviceBundleSsr.ssrCode | lowercase}} font-ico-checked-sign"
                                            role="presentation">
                                        </span>

                                        <span *ngIf="serviceBundleSsr.ssrCode !== 'CKN'"
                                            [innerHTML]='serviceBundleSsr.ssrCode | ssrTranslate: bundle.serviceBundleCode : flights[0].departureStation.date'>
                                        </span>
                                        <!-- {{serviceBundleSsr.ssrCode | ssrTranslate: bundle.serviceBundleCode :
                                        flights[0].departureStation.date}} -->
                                    </li>
                                </ul>
                            </div>
                            <div class="checkin-bundle-offer-button">
                                <div *ngIf="!bundle.isSelected else selected">
                                    <div class="message">{{ 'Upgrade' | translate:'bundle' }}</div>
                                    <div class="price">{{ bundle.price * paxCount * flights.length | currencyDisplay }}
                                    </div>
                                </div>
                                <ng-template #selected>
                                    <div class="message message-single">{{ 'Selected' | translate:'bundle' }}</div>
                                </ng-template>
                            </div>
                        </button>
                    </div>
                </ng-container>
                <!-- </check-in-accordion-item> -->
            </check-in-accordion>

            <div class="checkin-total-options-bar">
                <span class="text">
                    <span
                        innerHtml="{{ '<span class=\'hide-on-mobile\'>Ancillary</span> Total:' | translate:'extras'}}"></span>
                    <strong>{{ temporaryTotalPrice * paxCount | currencyDisplay }}</strong>
                </span>
                <button type="button" class="btn btn-primary"
                    attr.aria-label="{{ 'Click to add to cart' | translate:'extras' }}"
                    (click)="confirmBundle(false)">{{ 'Confirm' | translate:'extras' }}</button>
            </div>

        </div>
    </div>
</div>

<check-in-default-modal #bundleForBothInfoModal modalId="bundleForBothInfoModal" modalTitle="" okButtonText=""
    cancelButtonText="" [okRequired]="" [cancelRequired]="">
    <div class="warning-sign"></div>
    <div class="section-SubTitle" [innerHTML]="'The following bundles will be applied for both inbound and outbound flights' |
    translate: 'shared'"></div>

    <div class="buttons">
        <button type="submit" class="btn btn-primary btn-small btn-left inverted"
            attr.aria-label="{{ 'Click to dismiss' | translate:'shared' }}" (click)="closebundleForBothInfoModal()"
            [innerHTML]="'Reconsider' | translate:'extras'"></button>
        <button type="submit" class="btn btn-primary btn-small btn-right"
            attr.aria-label="{{ 'Click to dismiss' | translate:'shared' }}" (click)="sellBundles(false)">{{ 'Confirm' |
            translate:'extras' }}</button>
    </div>
</check-in-default-modal>