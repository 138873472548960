import { ChangeBooking } from './../../core/models/booking-changes-model';
import { UnaccompaniedMinorNotificationComponent } from './../unaccompanied-minor-notification/unaccompanied-minor-notification.component';
import { Constants } from '../../constants';
import { Component, OnInit, ViewChild, ViewChildren, QueryList, OnDestroy, ElementRef } from '@angular/core';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { BookingService } from '../../core/booking.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { ResourceService } from '../../common-modules/blue-air-common/resource.service';
import { DateRange } from '../../common-modules/blue-air-common/date-range.model';
import * as moment from 'moment';
import { FlightModel } from '../../core/models/flight-model';
import { BookingSelectionService } from '../../core/booking-selection.service';
import { SsrsService } from '../../extras/ssrs.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
// tslint:disable-next-line:max-line-length
import { SinglePassengerSpecialAssistanceComponent } from './single-passenger-special-assistance/single-passenger-special-assistance.component';
import { SsrType } from '../../extras/ssr-type.enum';
import { NgModel, NgForm, FormControl } from '@angular/forms';
import { BookingFlowService } from '../../core/booking-flow.service';
import { ICanDeactivateComponent } from '../../core/can-deactivate-component.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, RouterState } from '@angular/router';
import { DefaultModalComponent } from '../../shared/default-modal/default-modal.component';
import { SeatSelectedNotification } from '../../shared/seat-selected-notification/seat-selected-notification.component';
import { IHasPendingChanges } from '../../core/has-pending-changes.interface';
import { SelectionsClearWarningModalComponent } from '../../shared/selections-clear-warning-modal/selections-clear-warning-modal.component';
// tslint:disable-next-line: max-line-length
import { SsrsSeatsRemovalNotificationComponent } from '../../shared/ssrs-seats-removal-notification/ssrs-seats-removal-notification.component';
import { SeatMapService } from '../../seat/seat-map.service';
import { PaxContactDetailsComponent } from '../pax-contact-details/pax-contact-details.component';
import { PassengerModel } from '../../core/models/passenger-model';
import { ProfileService } from 'src/app/core/profile.service';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { Subscription } from 'rxjs';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';

@Component({
  selector: 'booking-in-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss']
})

export class PassengersComponent implements OnInit, OnDestroy, ICanDeactivateComponent, IHasPendingChanges {
  @ViewChildren('singlePassengerSA') specialServicesPassengerComponents:
    QueryList<SinglePassengerSpecialAssistanceComponent> = new QueryList<SinglePassengerSpecialAssistanceComponent>();
  @ViewChildren('passengerDocsForm') passengerDocsForm: QueryList<NgForm>;
  @ViewChild('firstName', { static: false }) firstName: NgModel;
  @ViewChild('changeFlightsWarningModal', { static: true }) clearSelectionsModal: SelectionsClearWarningModalComponent;
  @ViewChild('samePassengersNameWarningModal', { static: true }) nameModal: DefaultModalComponent;
  @ViewChild('subscribeTermsModal', { static: true }) subscribeTermsModal: DefaultModalComponent;
  @ViewChild(SeatSelectedNotification, { static: true }) pendingChangesModal: SeatSelectedNotification;
  @ViewChild('seatRemovalModal', { static: true }) seatRemovalModal: SsrsSeatsRemovalNotificationComponent;
  @ViewChild('UMmodal', { static: true }) UMmodal: UnaccompaniedMinorNotificationComponent;
  @ViewChild('passengersBox', { static: true }) passengersBox: ElementRef;
  @ViewChild(PaxContactDetailsComponent, { read: ElementRef, static: true }) contactBox: ElementRef;
  @ViewChild(PaxContactDetailsComponent, { static: true }) paxContactDetailsComponent: PaxContactDetailsComponent;

  private currentStep: CheckinSteps = CheckinSteps.passengers;
  private specialAssistanceSpinnerId: string = 'specialAssistanceSpinnerId';
  private inputWrapperClass: string = '.pax-container_2-col';

  private passengersSubscription: Subscription;
  private memberSubscription: Subscription;

  public phonePattern = '^[0-9+()\/-][0-9+() \/-]{0,19}?$';
  public namePattern = '^[A-Za-z _]*[A-Za-z][A-Za-z _]*$'
  public addressPattern = '(?!^ +$)^.+$'
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';

  public ariaLabel: string = 'passengers detail';
  public passengers: Array<any> =
    [{ name: {}, info: {}, typeInfo: { paxType: Constants.AdultPaxType }, isReadonly: false, address: { phoneNumber: null, emailAddress: null } }];
  public infants: Array<any> =
    [{ name: {}, info: {}, typeInfo: { paxType: Constants.InfantSsrCode }, isReadonly: false, address: { phoneNumber: null, emailAddress: null } }];

  public flights: FlightModel[] = [];
  public isSSRInfoSet: boolean;
  public submitted: boolean = false;
  public isAgent: boolean;
  public SsrTypeEnum = SsrType;
  private recordLocator: string;
  private allForms: NgForm[];

  genderItems: Array<any> = [];
  maleTitles: Array<any> = [];
  femaleTitles: Array<any> = [];
  duplicates: Array<any> = [];
  adultTitleItems: Array<any> = [];
  countries: Array<any> = [];
  contact: any = { name: {} };
  documentTypes: Array<any> = [];
  issueDateRange: any;
  expirationDateRange: any;
  titleItems: Array<any> = [];
  check: boolean = false;
  _canDeactivate: boolean = false;
  isPostCodeRequired = false;
  invalidForm: boolean = false;
  pendingSsrsList: SsrType[] = [];
  bookingSnapshot: any;
  scrollClass: string = 'validation,pattern';
  postCodeKey = 'postcod-contact';
  dateFormat: string = 'YYYY-MM-DD';
  dateRanges: any = {
    'ADT': [],
    'YTH': [],
    'CLD': [],
    'CHD': [],
    'INF': [],
    'ADTA': [],
    'YTHA': [],
    'CLDA': [],
    'CHDA': []
  };
  private scrollToPassengersSubscription: any;
  isPromoSelected: boolean;
  recordLocators: any[] = [];
  isRestricted: boolean;
  isCanceledBookingStatus: boolean;
  journeys: Array<any> = [];

  constructor(private bookingSteps: BookingStepsService,
    private bookingService: BookingService,
    private flowManager: FlowManagerService,
    private ecommerce: ECommerceService,
    private bookingFlowService: BookingFlowService,
    private translateService: TranslateService,
    private resourcesService: ResourceService,
    private bookingSelectionService: BookingSelectionService,
    private ssrsService: SsrsService,
    private loadingSpinnerService: LoadingSpinnerService,
    private seatmapService: SeatMapService,
    private profileService: ProfileService) {

    this.isAgent = this.profileService.isAgent;

    this.bookingSelectionService.flightsObs.subscribe(result => {
      this.flights = result;
    });

    this.bookingSteps.currentStep.next(this.currentStep);
  }

  // todo : refactor passengers component using passengers and infants from bookingSelectionService
  setPassengersNameByType() {

    this.passengersSubscription = this.bookingSelectionService.passengersObs.subscribe(passengersByType => {
      if (passengersByType.length > 0) {
        this.passengers.forEach(p => {

          const name = passengersByType.filter(pax => pax.passengerNumber === p.passengerNumber)
            .map(p => p.name.first + ' ' + p.name.last);
          if (!PassengerModel.checkNameFormat(name[0])) {
            p.displayName = name;
          } else {
            p.displayName = passengersByType.filter(pax => pax.passengerNumber === p.passengerNumber)
              .map(p => p.defaultName);
          }
          if (PassengerModel.checkNameFormat(p.name.first)) {
            p.name.first = '';
          }
          if (PassengerModel.checkNameFormat(p.name.last)) {
            p.name.last = '';
          }
          p.travelDocument = p.travelDocument ? p.travelDocument : (p.travelDocuments.items[0] || {});

          p.address = p.address ? p.address : (p.addresses.items[0] ? p.addresses.items[0] : { phoneNumber: null, emailAddress: null });
          const paxGender = passengersByType.filter(pax => pax.passengerNumber === p.passengerNumber).map(p => p.documents.gender).toString();
          p.info.gender = p.info.gender = this.getGender(paxGender);

          if (p.fees) {
            const UMfee = p.fees.items.find(f => f.ssrCode === Constants.SsrCodeUM || f.ssrCode === Constants.SsrCodeUMFR ||
              f.ssrCode === Constants.SsrCodeUMNR || f.ssrCode === Constants.SsrCodeUMRO);
            if (UMfee) {
              this.setUmSsr(p, UMfee.ssrCode);
            }
          }

          if (p.typeInfo.paxType && p.typeInfo.paxType.substr(0, 3) !== Constants.AdultPaxType) {
            p.name.title = Constants.ChildrenPaxType;
          }

          if (p.hasInfant) {
            const currentInfant = this.infants[p.passengerNumber];
            currentInfant.attachedPassengerNumber = p.passengerNumber;
            currentInfant.displayName = !currentInfant.name.first && !currentInfant.name.last ?
              this.translateService.instant('INF-title', 'price-display') + ' ' + (p.passengerNumber + 1) :
              currentInfant.name.first + ' ' + currentInfant.name.last;

            if (PassengerModel.checkNameFormat(currentInfant.displayName)) {
              currentInfant.displayName = this.translateService.instant('INF-title', 'price-display') + ' ' +
                (p.passengerNumber + 1);
            }
            if (PassengerModel.checkNameFormat(currentInfant.name.first)) {
              currentInfant.name.first = '';
            }
            if (PassengerModel.checkNameFormat(currentInfant.name.last)) {
              currentInfant.name.last = '';
            }
            currentInfant.name.title = Constants.ChildrenPaxType;
          }
        });
      }
      // wait for fields initialisation before autocomple
      this.autocompleteFirstPassenger();
    });
  }

  ngOnInit() {
    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);

    this.bookingService.getMemberDetails();

    // this.bookingService.getMemberDetails();

    this.bookingFlowService.loadFlowInfo().then(booking => {
      const departureDate = moment(booking.cancelFlight.journeys.items[0].segments.items[0].legs.items[0].std);

      this.contact = booking.contact || {};
      this.contact.typeCode = 'P';
      this.passengers = JSON.parse(JSON.stringify(booking.passengers.items));
      this.isPromoSelected = booking.passengers.items.some(pax => pax.typeInfo.paxType.length > 3);
      this.fixPassengerTypes();
      this.infants = booking.passengers.infants.items;
      this.setCheckedInPax(booking.assignedSeats.journeys.items);
      this.bookingSnapshot = booking.convertedJourneys.snapshotBooking;
      this.journeys = booking.convertedJourneys.journeys;
      this.isCanceledBookingStatus = this.checkBookingStatus();
      this.recordLocator = booking.bookingDetail.recordLocator;

      if (booking && booking.bookingDetail && booking.bookingDetail.recordLocators && booking.bookingDetail.recordLocators.length > 0) {
        this.recordLocators = booking.bookingDetail.recordLocators;
        this.isRestricted = this.isRestrictedBooking();
      }

      this.setPassengersNameByType();

      //todo:change to generic
      this.resourcesService.getPassengerTypes().subscribe(pTypes => {

        const paxTypesAges = pTypes.map(p => ({ 'typeCode': p.typeCode, 'minAge': p.maximumAge, 'maxAge': p.maximumAge }));

        paxTypesAges.forEach(p => {
          this.dateRanges[p.typeCode] = this.generateDateRangeForPaxType(p.typeCode.substr(0, 3), departureDate);
        });

        if (this.dateRanges["INFANT"])
          this.dateRanges["INF"] = this.dateRanges["INFANT"];
      });



      this.setDocumentDates(departureDate);

      setTimeout(() => {
        this.scrollToPassengersSubscription = this.bookingFlowService.scrollToPassengersObs.subscribe(result => {
          if (result === ChangeBooking.passengers) {
            this.passengersBox.nativeElement.scrollIntoView({ behavior: 'smooth' });
          } else if (result === ChangeBooking.contact) {
            this.contactBox.nativeElement.scrollIntoView({ behavior: 'smooth' });
          }
        });
        this.bookingFlowService.scrollToPassengersObs.next(null);
      }, 20);

      this.setResourceItems();

      this.bookingFlowService.loadPriceBreakdown().then(() => {
        this.ssrsService.getSsrsInfo().toPromise().then(data => {
          if (data) {
            this.isSSRInfoSet = true;
            this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
          }
        });
      });
    });
  }

  showUMnotification(passenger: any) {
    if (passenger.typeInfo.paxType !== Constants.YoungAdultPaxType && // 16-18 years
      passenger.typeInfo.paxType !== Constants.TeenPaxType && // 12-16 years
      passenger.typeInfo.paxType !== Constants.ChildrenPaxType) { // 2-12 years
      return;
    }

    const UMfee = passenger.fees.items.find(f => f.ssrCode === Constants.SsrCodeUM || f.ssrCode === Constants.SsrCodeUMFR ||
      f.ssrCode === Constants.SsrCodeUMNR || f.ssrCode === Constants.SsrCodeUMRO);
    if (UMfee || passenger.info.umSsrResource) {
      return;
    }

    if (this.passengers.find(pax => pax.typeInfo.paxType === Constants.AdultPaxType)) {
      return;
    }

    if (!passenger.info.nationality || !passenger.typeInfo.dateOfBirth) {
      return;
    }

    this.openUMpopup(passenger);
  }

  openUMpopup(passenger) {
    this.UMmodal.openPopup((okSelected) => {
      if (okSelected) {
        // sell selected UM
        this.UMmodal.ok().then((data: any) => {
          if (data) {
            this.setUmSsr(passenger, this.UMmodal.selectedSsrCode);
          }
        });
      } else {
        // remove passenger's nationality
        passenger.info.nationality = null;
      }
    }, passenger, this.flights);
  }

  setUmSsr(passenger, ssrCode) {
    const selectedUmSsr = this.UMmodal.getAvailableUMssrs(passenger, this.flights)
      .find(ssr => ssr.ssrCode === ssrCode);

    if (selectedUmSsr) {
      passenger.info.umSsrResource = selectedUmSsr.ssrResource;
    }
  }

  removeUmSsr(passenger) {
    let UMlist = passenger.fees.items.filter(f => f.ssrCode === Constants.SsrCodeUM || f.ssrCode === Constants.SsrCodeUMFR ||
      f.ssrCode === Constants.SsrCodeUMNR || f.ssrCode === Constants.SsrCodeUMRO);

    const removeUM = (UMssrs) => {
      UMssrs.forEach(UMssr => {
        const flight = this.flights.find(x => x.segments.map(x => x.referenceKey).reduce((a, b) => a.concat(b), []).includes(UMssr.flightReference));
        this.ssrsService.removeSsr(SsrType.SpecialAssistance, UMssr.ssrCode, UMssr.ssrNumber, passenger.passengerNumber, flight, true);
      });
      this.ssrsService.applySsrs(SsrType.SpecialAssistance).then(data => {
        if (data) {
          passenger.info.umSsrResource = '';
          this.openUMpopup(passenger);
        }
      });
    };

    if (UMlist.length > 0) {
      removeUM(UMlist);
    } else {
      this.bookingService.refresh().then(data => {
        if (data) {
          const currentPax = data.passengers.items.find(p => p.passengerNumber === passenger.passengerNumber);
          UMlist = currentPax.fees.items.filter(f => f.ssrCode === Constants.SsrCodeUM || f.ssrCode === Constants.SsrCodeUMFR ||
            f.ssrCode === Constants.SsrCodeUMNR || f.ssrCode === Constants.SsrCodeUMRO);
          removeUM(UMlist);
        }
      });
    }
  }

  private generateDateRangeForPaxType(paxType, departureDate) {
    let minAge: number;
    let maxAge: number;
    let dateRange = new DateRange(departureDate.clone());

    // to do -> refactor this and this.getPassengerTypes()
    this.resourcesService.getPassengerTypes().subscribe((paxTypes: any[]) => {
      const currentPaxType = paxTypes.find(pt => pt.typeCode === paxType);
      if (currentPaxType || paxType === Constants.InfantSsrCode) {
        switch (paxType) {
          case Constants.AdultPaxType:
            minAge = 12;
            maxAge = 100;
            break;
          case Constants.ChildrenPaxType:
            minAge = 2;
            maxAge = 12;
            break;
          case Constants.InfantSsrCode:
            minAge = 0;
            maxAge = 2;
            break;
          case Constants.TeenPaxType:
          case Constants.YoungAdultPaxType:
          default:
            minAge = currentPaxType.minimumAge;
            maxAge = currentPaxType.maximumAge;
            break;


        }

        dateRange.generateYearsForAge(minAge + 1, maxAge + 1);
        dateRange.minimumDate = departureDate.clone().add(-maxAge, 'y');
        dateRange.maximumDate = departureDate.clone().add(-minAge, 'y');
        return dateRange;
      }
    });

    return dateRange;
  }

  ngOnDestroy() {
    if (this.scrollToPassengersSubscription) {
      this.scrollToPassengersSubscription.unsubscribe();
    }
    if (this.passengersSubscription) {
      this.passengersSubscription.unsubscribe();
    }
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
  }

  private setResourceItems() {
    this.genderItems = [
      {
        'text': this.translateService.instant('Male', 'documents'),
        'value': 1
      },
      {
        'text': this.translateService.instant('Female', 'documents'),
        'value': 2
      }
    ];

    this.resourcesService.getCountries().subscribe(countries => this.countries = countries);

    this.resourcesService.getDocumentTypes().subscribe(docTypes => this.documentTypes = docTypes);

    this.resourcesService.getPassengerTitles().subscribe(titles => {
      this.titleItems = [
        {
          'text': this.translateService.instant('Please select'),
          'value': ''
        },
        ...titles
      ];

      this.maleTitles = this.titleItems.filter(x => x.value === 'MR' || x.value === 'MSTR');
      this.femaleTitles = this.titleItems.filter(x => x.value === 'MS' || x.value === 'MRS' || x.value === 'MISS');

      this.adultTitleItems = this.titleItems.filter(x => x.value !== 'CHD');
    });
  }

  private setDocumentDates(departureDate) {
    this.expirationDateRange = new DateRange(departureDate.clone());
    this.expirationDateRange.generateExpiration(100);
    this.expirationDateRange.minimumDate = departureDate.clone();
    this.expirationDateRange.maximumDate = departureDate.clone().add(100, 'y');

    this.issueDateRange = new DateRange(departureDate.clone());
    this.issueDateRange.minimumDate = departureDate.clone().add(-100, 'y');
    this.issueDateRange.maximumDate = departureDate.clone();
    this.issueDateRange.generateIssue(100);
  }

  getSpinnerId(passengerNumber) {
    return this.specialAssistanceSpinnerId + passengerNumber;
  }

  scrollTo(elements: string): void {
    const items = elements.split(',');
    const firstElementList = document.querySelectorAll('.' + items[0]);
    const secondElementList = document.querySelectorAll('.' + items[1]);
    let item;
    if (firstElementList.length > 0)
      item = firstElementList[0] as HTMLElement;
    else
      if (secondElementList.length > 0)
        item = secondElementList[0] as HTMLElement;

    if (secondElementList.length > 0 && firstElementList.length > 0) {
      if ((firstElementList[0] as any).offsetTop > (secondElementList[0] as any).offsetTop)
        item = secondElementList[0] as HTMLElement;
    }
    if (item)
      item.closest(this.inputWrapperClass).scrollIntoView({ behavior: 'smooth' });
  }

  applySelection() {
    // why doesn't contact form get added to passengerDocsForm as viewchild?
    this.allForms = Array.from(this.passengerDocsForm.toArray());
    this.allForms.push(this.paxContactDetailsComponent.form);

    this.submitted = true;
    let isValid = true;

    if (!this.isRestricted && !this.isCanceledBookingStatus) {
      this.allForms.forEach(f => {
        isValid = isValid && f.valid;
        for (const key in f.form.controls) {
          if (f.form.controls.hasOwnProperty(key)) {
            const element = f.form.controls[key];
            if (element instanceof FormControl) {
              (element as FormControl).markAsDirty({ onlySelf: true });
              if (key === this.postCodeKey) {
                this.isPostCodeRequired = this.contact.countryCode === 'GB' && !this.contact.postalCode ? true : false;
                if (this.isPostCodeRequired) {
                  isValid = false;
                }
              }
            }
          }
        }
      });
    }

    this.invalidForm = !isValid;
    if (isValid) {
      const confirmPassengerDetails = (isOkCallback: boolean): void => {
        if (isOkCallback) {
          if (!this.contact.agreeTermsAndCond) {
            this.subscribeTermsModal.openPopup((dismissed) => {
              this.contact.agreeTermsAndCond = dismissed === false;
              if (this.contact.agreeTermsAndCond) {
                this.saveAndContinue();
              }
              //this.saveAndContinue();
            });
          } else {
            this.saveAndContinue();
          }
        }
      };
      this.duplicates = new Array();
      this.passengers.forEach((p, i) => {
        const duplicate = this.passengers.find((pax, index) => pax.name.title === p.name.title &&
          pax.name.first === p.name.first && pax.name.last === p.name.last && i !== index);
        if (duplicate && !this.duplicates.some(x => x.name.first === duplicate.name.first && x.name.last === duplicate.name.last
          && x.name.title === duplicate.name.title)) {
          this.duplicates.push(duplicate);
        }
      });
      if (this.duplicates.length > 0) {
        this.nameModal.openPopup((isOk) => {
          this.scrollTo('pax-container-' + this.duplicates[0].passengerNumber);
        });
      } else {
        confirmPassengerDetails(true);
      }
    }
    setTimeout(() => {
      this.scrollTo(this.scrollClass);
    }, 20);
  }

  toggle(pax, i) {
    if (i == 0) {
      pax.specialAssistance = pax.specialAssistance ? false : true;
    } else {
      pax.addDocuments = pax.addDocuments ? false : true;
    }
  }

  saveChanges(paxIndex) {
    let pax = this.passengers[paxIndex];

    const parentSpinnerId = 'specialAssistanceSpinnerId' + pax.passengerNumber;

    this.ssrsService.resetSsrs(SsrType.SpecialAssistance);

    const components = this.specialServicesPassengerComponents.filter(x => x.selectedPassenger === pax.passengerNumber);
    const newSsrs = [];

    components.forEach(component => {
      newSsrs.push(...component.applySsrs());
    });

    const seatsToBeRemoved = this.ssrsService.getSeatsToBeRemoved(SsrType.SpecialAssistance, this.flights, newSsrs, pax.passengerNumber);

    if (!seatsToBeRemoved.length) {
      this.completeSaveChanges(pax, parentSpinnerId);
    } else {
      this.seatRemovalModal.openPopup((userAgreed: boolean) => {
        if (userAgreed) {
          this.loadingSpinnerService.showSpinnerById(parentSpinnerId);
          this.seatmapService.removeSeats(seatsToBeRemoved)
            .then(() => this.completeSaveChanges(pax, parentSpinnerId));
        }
      }, seatsToBeRemoved);
    }
  }

  private completeSaveChanges(pax: any, parentSpinnerId: string) {
    this.loadingSpinnerService.showSpinnerById(parentSpinnerId);

    this.ssrsService.applySsrs(SsrType.SpecialAssistance).then(() => {
      this.loadingSpinnerService.hideSpinnerById(parentSpinnerId);
    });
  }

  canDeactivate(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
    let nextStep = this.bookingSteps.extractStep(nextState);
    let redirectingToOtherBookingStep = true;

    if (nextStep === null) {
      redirectingToOtherBookingStep = false;
      nextStep = CheckinSteps.search;
    }

    if (this.clearSelectionsModal.shouldShowWarning(this.currentStep, nextStep)) {
      return this.clearSelectionsModal.showWarningAndNavigate(
        this.currentStep,
        redirectingToOtherBookingStep ? nextStep : null,
        nextState.url);
    }

    if (!this._canDeactivate) {
      this.goToNextStep(nextStep);
    }
    return this._canDeactivate;
  }

  setCheckedInPax(journeys) {
    const checkedInPax = journeys.map(x =>
      x.segments.items
        .reduce((a, b) => a.concat(b), [])
        .map(y => y.passengerSegments.items)
        .reduce((a, b) => a.concat(b), [])
        .filter(z => z.liftStatus !== Constants.NotCheckedIn)
        .reduce((a, b) => a.concat(b), [])
        .map(t => t.passengerNumber));

    this.passengers.forEach(pax => {
      pax.readonlyDetails = checkedInPax.some(x => x.includes(pax.passengerNumber));
      if (this.infants[pax.passengerNumber]) {
        this.infants[pax.passengerNumber].readonlyDetails = pax.readonlyDetails && pax.hasInfant && pax.infant;
      }
      pax.readonlyDocuments = pax.readonlyDetails;
    });
  }

  hasPendingChanges(): boolean {
    let ssrsOk = true;
    this.pendingSsrsList = [];
    if (this.checkPendingSpecialServices()) {
      this.pendingSsrsList.push(SsrType.SpecialAssistance);
    }

    if (this.pendingSsrsList.length) {
      ssrsOk = false;
    }
    return ssrsOk;
  }

  goToNextStep(nextStep: CheckinSteps) {
    this._canDeactivate = this.hasPendingChanges();

    if (!this._canDeactivate) {
      this.pendingChangesModal.openPopup((isOk) => {
        this._canDeactivate = isOk;
        if (isOk) {
          this.checkoutEcommerce();
          this.bookingSteps.goToStep(nextStep);
        }
      });
    } else {
      this.checkoutEcommerce();
      this.bookingSteps.goToStep(nextStep);
    }
  }

  checkoutEcommerce() {
    let ecommerceCartItems = [];
    this.flowManager.applicationFlowService.loadPriceBreakdown().then(breakdown => {
      if (breakdown) {
        ecommerceCartItems = this.ecommerce.getAllCartDataForECommerce(breakdown);
        this.ecommerce.Checkout(ecommerceCartItems, 4, 'Passengers step');
      }
    });
  }

  getText(array, value): string {
    if (!array.length) {
      return '';
    }

    const foundItem = array.find(x => x.value === value);
    return foundItem ? foundItem.text : '';
  }

  titleChanged($value, pax) {
    if ($value && pax.typeInfo.paxType && pax.typeInfo.paxType.substr(0, 3) === Constants.AdultPaxType) {
      if (this.maleTitles.some(x => x.value === $value) && pax.info.gender !== this.genderItems[0].value) {
        pax.info.gender = this.genderItems[0].value;
      } else if (this.femaleTitles.some(x => x.value === $value) && pax.info.gender !== this.genderItems[1].value) {
        pax.info.gender = this.genderItems[1].value;
      }
    }

    if (pax.passengerNumber === 0) {
      this.firstPaxChanged();
    }
  }

  firstPaxChanged() {
    if (this.check) {
      this.contact.name = Object.assign({}, this.passengers[0].name || {});
    }
  }

  genderChanged($value, pax) {
    if ($value && pax.typeInfo.paxType === Constants.AdultPaxType) {
      if (this.maleTitles.every(x => x.value !== pax.name.title) && $value === this.genderItems[0].value) {
        pax.name.title = this.maleTitles[0].value;
      } else if (this.femaleTitles.every(x => x.value !== pax.name.title) && $value === this.genderItems[1].value) {
        pax.name.title = this.femaleTitles[0].value;
      }
    }
  }

  private checkPendingSpecialServices(): boolean {
    this.ssrsService.resetSsrs(SsrType.SpecialAssistance);
    this.specialServicesPassengerComponents.forEach(component => {
      component.applySsrs();
    });
    return this.ssrsService.getPendingSsrs().filter(ssr => ssr === SsrType.SpecialAssistance).length > 0;
  }

  private saveAndContinue() {
    this.passengers.forEach(item => {
      item.name.first = item.name.first.toUpperCase().trim();
      item.name.last = item.name.last.toUpperCase().trim();
    });

    Promise.all([
      this.bookingService.savePassengers(this.passengers, this.infants),
      this.bookingService.saveContact(this.contact)
    ])
      .then(() => Promise.all([
        this.contact.subscribedToNewsletter && this.contact.agreeTermsAndCond && this.bookingService.subscribeToNewsletter(this.contact.isFirstPassenger),
        this.bookingFlowService.loadFlowInfo(true),
        this.bookingFlowService.loadPriceBreakdown(true)
      ]))
      .then(() => this.goToNextStep(this.bookingSteps.getNextStep(this.currentStep)));
  }

  private autocompleteFirstPassenger() {
    this.memberSubscription = this.bookingService.memberPromotionObs.subscribe(data => {
      if (data && data.memberPromotion.isActive && this.isPromoSelected) {
        const firstPassenger = this.passengers[0];
        firstPassenger.readonlyDetails = true;
        firstPassenger.readonlyDocuments = false;
        firstPassenger.name.title = data.memberProfile.member.name.title;
        firstPassenger.name.first = data.memberProfile.member.name.first;
        firstPassenger.name.last = data.memberProfile.member.name.last;
        firstPassenger.info.gender = this.getGender(data.memberProfile.member.gender);
        firstPassenger.info.nationality = data.memberProfile.member.nationality ?
          data.memberProfile.member.nationality :
          data.memberProfile.member.residentCountry;
        firstPassenger.typeInfo.dateOfBirth = data.memberProfile.member.dateOfBirth;
      }
    });
  }

  private getGender(gender: string) {
    return gender === 'Male' ? 1 : gender === 'Female' ? 2 : 0
  }

  private fixPassengerTypes() {
    if (this.passengers && this.passengers.length) {
      this.passengers.forEach(p => p.typeInfo.paxType = p.typeInfo.paxType.substring(0, 3));
    }
  }

  isRestrictedBooking() {
    return (this.recordLocators.some(r => r.owningSystemCode !== null && r.owningSystemCode !== undefined)) ? true : false;
  }

  checkBookingStatus() {
    for (const j of this.journeys) {
      for (const s of j.segments) {
        for (const l of s.legs) {
          if (l.legInfo.status === "Canceled") {
            return true;
          }
        }
      }
    }

    return false;
  }
}