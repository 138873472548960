import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { BookingFlowService } from '../core/booking-flow.service';

@Injectable()
export class CanActivateFlightsOnBookingService implements CanActivate {

  constructor(private bookingFLowService: BookingFlowService ) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.bookingFLowService.loadFlowInfo()
      .then(booking => {
        if (booking && booking.convertedJourneys && booking.convertedJourneys.snapshotBooking) {
          return this.bookingFLowService.loadPriceBreakdown();
        }
        return true;
      })
      .then(() => true);
  }
}
