import * as tslib_1 from "tslib";
import { NgForm } from '@angular/forms';
var ProfileInfoBox = /** @class */ (function () {
    function ProfileInfoBox(modalService) {
        this.modalService = modalService;
    }
    ProfileInfoBox.prototype.resetForm = function () {
        this.editForm.reset();
    };
    ProfileInfoBox.prototype.cancel = function (event) {
        this.closeModal();
    };
    ProfileInfoBox.prototype.openModal = function (modalId) {
        if (modalId === void 0) { modalId = null; }
        this.modalService.open(modalId || this.editModalId);
    };
    ProfileInfoBox.prototype.closeModal = function (modalId) {
        if (modalId === void 0) { modalId = null; }
        this.modalService.close(modalId || this.editModalId);
    };
    ProfileInfoBox.prototype.findTextByValue = function (arr, value) {
        var e_1, _a;
        try {
            for (var arr_1 = tslib_1.__values(arr), arr_1_1 = arr_1.next(); !arr_1_1.done; arr_1_1 = arr_1.next()) {
                var i = arr_1_1.value;
                if (i.value === value) {
                    return i.text;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (arr_1_1 && !arr_1_1.done && (_a = arr_1.return)) _a.call(arr_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return value;
    };
    return ProfileInfoBox;
}());
export { ProfileInfoBox };
