import { SsrType } from '../../../extras/ssr-type.enum';
import { Helpers } from '../../../helpers';

export class ShoppingCartExtrasSsrItemModel {
    amount: number;

    private _totalAmount: number;
    get totalAmount() { return this._totalAmount; }
    set totalAmount(value: number) { this._totalAmount = Math.max(value, 0); }

    quantity: number;
    ssrCode: string;

    init(extrasItem: any): ShoppingCartExtrasSsrItemModel {
        this.ssrCode = extrasItem.ssrCode;
        if (this.ssrCode) {
            this.ssrCode = this.ssrCode.trim();
        }
        this.quantity = extrasItem.quantity;
        this.amount = extrasItem.amount;
        this.totalAmount = this.quantity * this.amount;

        return this;
    }
}

export class ShoppingCartExtrasPassengerModel {
    passengerNumber: number;
    passengerNumberByType: number;
    passengerType: string;
    items: ShoppingCartExtrasSsrItemModel[];

    init(extrasPassenger: any): ShoppingCartExtrasPassengerModel {
        this.passengerNumber = extrasPassenger.paxNumber;
        this.passengerType = extrasPassenger.paxTypeCode;

        const groupBySsrCodeAndPrice = extrasPassenger.items.reduce((group, item) => {
            const key = `${item.ssrCode}_${item.amount}`;

            if (group[key]) {
                group[key].quantity += item.quantity;
                group[key].totalAmount += item.quantity * item.amount;
            } else {
                group[key] = new ShoppingCartExtrasSsrItemModel().init(item);
            }

            return group;
        }, {});

        this.items = [];
        for (const key in groupBySsrCodeAndPrice) {
            if (groupBySsrCodeAndPrice.hasOwnProperty(key)) {
                this.items.push(groupBySsrCodeAndPrice[key]);
            }
        }

        return this;
    }
}

export class ShoppingCartExtrasFlightModel {
    private _amount: number;
    get amount() { return this._amount; }
    set amount(value: number) { this._amount = Math.max(value, 0); }

    departureStation: string;
    arrivalStation: string;

    segments: ShoppingCartExtrasFlightModel[];
    passengers: ShoppingCartExtrasPassengerModel[];

    hasItems: boolean;

    get journeyPassengers(): ShoppingCartExtrasPassengerModel[] {
        const firstSegment = this.segments.find(s => s.passengers.length > 0);
        return firstSegment ? firstSegment.passengers : [];
    }

    get isJourney(): boolean {
        return this.segments.length > 0;
    }

    get isSegment(): boolean {
        return this.segments.length === 0;
    }

    constructor() {
        this.segments = [];
        this.passengers = [];
        this.hasItems = false;
    }

    init(extrasJourneyOrSegment: any): ShoppingCartExtrasFlightModel {
        this.departureStation = extrasJourneyOrSegment.departureStation;
        this.arrivalStation = extrasJourneyOrSegment.arrivalStation;
        this.amount = extrasJourneyOrSegment.totalAmount;

        this.segments = extrasJourneyOrSegment.segments && extrasJourneyOrSegment.segments.length ?
            extrasJourneyOrSegment.segments.map(s => new ShoppingCartExtrasFlightModel().init(s)) :
            [];

        this.passengers = extrasJourneyOrSegment.passengers && extrasJourneyOrSegment.passengers.length ?
            extrasJourneyOrSegment.passengers.map(p => new ShoppingCartExtrasPassengerModel().init(p)) :
            [];

        this.passengers = this.passengers.sort2(p => p.passengerNumber);

        if (this.segments.length) {
            this.hasItems = this.segments.some(s => s.hasItems);
        } else {
            this.hasItems = this.passengers.length > 0;
        }

        return this;
    }

    initJourneyFromSegments(segments: ShoppingCartExtrasFlightModel[], initStations: boolean = false) {
        if (initStations) {
            this.departureStation = segments[0].departureStation;
            this.arrivalStation = segments[segments.length - 1].arrivalStation;
        }
        this.amount = segments.sum(s => s.amount);
        this.hasItems = segments.some(s => s.hasItems);
    }

    initSegmentsFromPassengers(passengers: ShoppingCartExtrasPassengerModel[]) {
        this.amount = passengers.sum(p => p.items.sum(i => i.totalAmount));
        this.hasItems = passengers.length > 0;
    }
}

export class ShoppingCartExtrasModel {
    private _amount: number;
    get amount() { return this._amount; }
    set amount(value: number) { this._amount = Math.max(value, 0); }

    ssrType: SsrType;
    flights: ShoppingCartExtrasFlightModel[];
    flightsWithItems: ShoppingCartExtrasFlightModel[];

    constructor() {
        this.flights = [];
        this.flightsWithItems = [];
    }

    init(shoppingCartExtras: any): ShoppingCartExtrasModel {
        this.ssrType = this.parseSsrType(shoppingCartExtras.ssrTypeCode);
        this.flights = shoppingCartExtras.journeys.map(j => new ShoppingCartExtrasFlightModel().init(j));
        this.initFromFlights(this.flights);
        return this;
    }

    initFromFlights(flights: ShoppingCartExtrasFlightModel[]) {
        this.amount = this.flights.sum(f => f.amount);
        this.flightsWithItems = this.flights.filter(f => f.hasItems);
    }

    private parseSsrType(initialSsrType: string): SsrType {
        switch (initialSsrType) {
            case 'ExtraLegRoom':
                return SsrType.Seat;
            case 'OTHER':
            case 'Others':
                return SsrType.Other;
            case 'ASIG':
                return SsrType.Insurance;
            default:
                const parsed = SsrType[initialSsrType];
                if (parsed) {
                    return parsed;
                }
                return SsrType.None;
        }
    }
}
