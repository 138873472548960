import { Constants } from './../../constants';
import { BookingService } from './../../core/booking.service';
import { SeatMapService } from './../../seat/seat-map.service';
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { SsrBoxContentPriorityBagComponent } from './../ssr-box-content-priority-bag/ssr-box-content-priority-bag.component';
import { SsrBoxContentFastTrackComponent } from './../ssr-box-content-fast-track/ssr-box-content-fast-track.component';
import {
  Component, OnInit, Input, HostListener, OnChanges, SimpleChanges, SimpleChange, OnDestroy,
  ViewChildren, QueryList, ViewChild, ElementRef, AfterViewInit
} from '@angular/core';
import { SsrType } from '../ssr-type.enum';
import { FlightModel } from '../../core/models/flight-model';
import { SsrsService } from '../ssrs.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SsrBoxContentLuggageComponent } from '../ssr-box-content-luggage/ssr-box-content-luggage.component';
import { SsrBoxContentMealsComponent } from '../ssr-box-content-meals/ssr-box-content-meals.component';
import { SsrBoxContentPetsComponent } from '../ssr-box-content-pets/ssr-box-content-pets.component';
import { SsrBoxContentSpecialEquipmentComponent } from '../ssr-box-content-special-equipment/ssr-box-content-special-equipment.component';
// tslint:disable-next-line:max-line-length
import { SsrBoxContentSpecialAssistanceComponent } from '../ssr-box-content-special-assistance/ssr-box-content-special-assistance.component';
import { ConfigService } from '../../core/config.service';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { SsrBoxContentPriorityBoardingComponent } from '../ssr-box-content-priority-boarding/ssr-box-content-priority-boarding.component';
import { ValidationModalComponent } from '../validation-modal/validation-modal.component';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { SsrBoxContentLoungeComponent } from '../ssr-box-content-lounge/ssr-box-content-lounge.component';
import { ShoppingCartExtrasModel, ShoppingCartExtrasFlightModel } from '../../core/models/shopping-cart-models';
// tslint:disable-next-line: max-line-length
import { SsrsSeatsRemovalNotificationComponent } from '../../shared/ssrs-seats-removal-notification/ssrs-seats-removal-notification.component';
import { SsrSellingTypeHelper } from '../ssr-selling-type.enum';
import { environment } from '../../../environments/environment';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { SsrBoxContentAirportCheckinComponent } from '../ssr-box-content-airport-checkin/ssr-box-content-airport-checkin.component';
import { ProfileService } from 'src/app/core/profile.service';
import { PassengerModel } from 'src/app/core/models/passenger-model';
import { BookingFareSelectSyncService } from '../../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { timeStamp } from 'console';
import { isArray } from 'util';
import { SelectSsrEvent2Params } from '../ssr-box-content-pets/ssr-box-content-pets-events-params';
import { SsrBoxContentFlexComponent } from '../ssr-box-content-flex/ssr-box-content-flex.component';
import * as moment from 'moment';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { ECommerceService } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';
import { SsrBoxContentEarlyCheckinComponent } from '../ssr-box-content-early-checkin/ssr-box-content-early-checkin.component';
import { SsrBoxContentBusTransfer } from '../ssr-box-content-bus-transfer/ssr-box-content-bus-transfer.component';

@Component({
  selector: 'check-in-ssr-box',
  templateUrl: './ssr-box.component.html',
  styleUrls: ['./ssr-box.component.scss']
})
export class SsrBoxComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() icon: string;
  @Input() addBtnText: string;
  @Input() ssrType: SsrType;
  @Input() pbagModal: DefaultModalComponent;
  @Input() isUnavailableDueToPreviouslyCheckedIn: boolean;
  @Input()
  set flights(value: FlightModel[]) {
    this.flightsObs.next(value);
  }
  private flightsObs: BehaviorSubject<FlightModel[]> = new BehaviorSubject<FlightModel[]>(null);
  private flightsSub: Subscription;
  currentFlights: FlightModel[] = null;

  @ViewChildren(SsrBoxContentLuggageComponent) luggageComponents: QueryList<SsrBoxContentLuggageComponent>;
  @ViewChildren(SsrBoxContentMealsComponent) mealComponents: QueryList<SsrBoxContentMealsComponent>;
  @ViewChildren(SsrBoxContentLoungeComponent) loungeComponents: QueryList<SsrBoxContentLoungeComponent>;
  @ViewChildren(SsrBoxContentPetsComponent) petComponents: QueryList<SsrBoxContentPetsComponent>;
  @ViewChildren(SsrBoxContentSpecialEquipmentComponent) specialEquipmentComponents: QueryList<SsrBoxContentSpecialEquipmentComponent>;
  @ViewChildren(SsrBoxContentSpecialAssistanceComponent) specialAssistanceComponents: QueryList<SsrBoxContentSpecialAssistanceComponent>;
  @ViewChildren(SsrBoxContentPriorityBoardingComponent) priorityBoardingComponents: QueryList<SsrBoxContentPriorityBoardingComponent>;
  @ViewChildren(SsrBoxContentPriorityBagComponent) priorityBagComponents: QueryList<SsrBoxContentPriorityBagComponent>;
  @ViewChildren(SsrBoxContentFastTrackComponent) fastTrackComponents: QueryList<SsrBoxContentFastTrackComponent>;
  @ViewChildren(SsrBoxContentAirportCheckinComponent) airportCheckinComponents: QueryList<SsrBoxContentAirportCheckinComponent>;
  @ViewChildren(SsrBoxContentEarlyCheckinComponent) earlyCheckinComponents: QueryList<SsrBoxContentEarlyCheckinComponent>;
  @ViewChildren(SsrBoxContentBusTransfer) busTransferComponents: QueryList<SsrBoxContentBusTransfer>;
  @ViewChildren(SsrBoxContentFlexComponent) flexComponents: QueryList<SsrBoxContentFlexComponent>;
  @ViewChild(ValidationModalComponent, { static: false }) validationModalComponent: ValidationModalComponent;
  @ViewChild('seatRemovalModal', { static: false }) seatRemovalModal: SsrsSeatsRemovalNotificationComponent;

  SsrTypeEnum = SsrType;

  isExpanded = false;
  isModified = false;
  lowestPrice: number;
  lowestOriginalPrice: number;

  spinnerId: string;
  pdfLanguage: string = 'en';

  flightPrices: number[][];
  totalPrice: number;
  temporaryTotalPrice: number;

  enableSameOptionsForAllFlights = false;
  ssrBoxContentComponents: any[] = [];

  assetsPath: string;
  promoDiscount: number = null;
  hasPromotion: boolean;
  minHoursToPurchaseBeforeFlight: number = null;

  private ssrCodesList: string[];
  private ssrsAvailabilityByFlight: any = {};
  private shoppingCartExtrasSubscription: Subscription;
  private availableSsrsSubscription: Subscription;
  private reloadSsrSubscription: Subscription;
  private shoppingCartExtras: ShoppingCartExtrasModel[];

  private afterSaveChanges: (() => Promise<any>)[] = [];

  constructor(private ssrsService: SsrsService, private flowManager: FlowManagerService, public config: ConfigService,
    private loadingSpinnerService: LoadingSpinnerService, private translateService: TranslateService, private elRef: ElementRef,
    private seatmapService: SeatMapService, private ecommerce: ECommerceService, private profileService: ProfileService,
    private syncService: BookingFareSelectSyncService, private resourcesService: ResourceService) {
    this.assetsPath = environment.assetsPath;
    this.shoppingCartExtrasSubscription = this.flowManager.applicationFlowService.shoppingCartBreakdown.pipe(
      filter(b => b && b.currentShoppingCart ? true : false))
      .subscribe(b => {
        this.shoppingCartExtras = b.currentShoppingCart.extras;
        if (!this.isExpanded) {
          this.updateTotalPrice(true);
        }
      });

    this.reloadSsrSubscription = this.ssrsService.reloadingSsrsObs.pipe(
      filter(loadingInfo => loadingInfo.ssrType === this.ssrType))
      .subscribe(loadingInfo => {
        if (loadingInfo.isLoading) {
          this.loadingSpinnerService.showSpinnerById(this.spinnerId);
        } else {
          this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
        }
      });

    if (this.translateService.culture) {
      this.pdfLanguage = this.translateService.culture.toLowerCase().includes('ro') ? 'ro' : 'en';
    }

    this.enableFlightsUpdates();
  }

  async ngOnInit() {
    this.minHoursToPurchaseBeforeFlight = await this.resourcesService.getMinHoursToPurchaseBeforeFlightAsync();
  }

  ngOnDestroy() {
    if (this.shoppingCartExtrasSubscription) {
      this.shoppingCartExtrasSubscription.unsubscribe();
    }
    if (this.availableSsrsSubscription) {
      this.availableSsrsSubscription.unsubscribe();
    }
    if (this.reloadSsrSubscription) {
      this.reloadSsrSubscription.unsubscribe();
    }
    if (this.flightsSub) {
      this.flightsSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    let components: any;
    switch (this.ssrType) {
      case SsrType.Baggage:
        components = this.luggageComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        this.afterSaveChanges.push(() => {
          this.ssrsService.toggleLoadingForSsrType(true, SsrType.PriorityBag);

          const showPriorityPopup = this.ssrsService.updatePriorityBag(this.currentFlights);
          if (showPriorityPopup) {
            this.pbagModal.openPopup(() => { });
            return this.ssrsService.applySsrs(SsrType.PriorityBag);
          }
          return Promise.resolve();
        });
        this.afterSaveChanges.push(() => this.ssrsService.getSsrsInfo().toPromise());
        this.afterSaveChanges.push(() => this.ssrsService.toggleLoadingForSsrType(false, SsrType.PriorityBag));
        break;
      case SsrType.Meal:
        components = this.mealComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        this.availableSsrsSubscription = this.ssrsService.availableSsrsObs.subscribe(avSsrs => {
          if (avSsrs) {
            this.setLowestPrice(this.ssrType);
          }
        });
        break;
      case SsrType.Lounge:
        components = this.loungeComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        break;
      case SsrType.Pet:
        components = this.petComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        break;
      case SsrType.SpecialEquipment:
        components = this.specialEquipmentComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        break;
      case SsrType.SpecialAssistance:
        components = this.specialAssistanceComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        // this.getSeatsWithoutSsrRestriction();
        break;
      case SsrType.PriorityBoarding:
        components = this.priorityBoardingComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        break;
      case SsrType.PriorityBag:
        components = this.priorityBagComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        this.availableSsrsSubscription = this.ssrsService.availableSsrsObs.subscribe(avSsrs => {
          if (avSsrs) {
            this.cancelChanges();
            this.updateSsrAvailabilityByFlight();
            this.setLowestPrice(this.ssrType);
          }
        });
        break;
      case SsrType.FastTrack:
        components = this.fastTrackComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        break;
      case SsrType.AirportCheckin:
        components = this.airportCheckinComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        break;
      case SsrType.EarlyCheckin:
        components = this.earlyCheckinComponents.changes.subscribe(r => this.setReturnFlightComponent(r));
        break;
      case SsrType.BusTransfer:
        components = this.busTransferComponents.changes.subscribe(r => this.setReturnFlightComponent(r));
        break;
      case SsrType.Flex:
        components = this.flexComponents.changes.subscribe((r) => this.setReturnFlightComponent(r));
        break;
    }

    this.expandBox();
  }

  setLowestPrice(ssrType: SsrType) {
    const ssrsList = this.ssrsService.availableSsrs.find(lists => lists.key === ssrType);
    if (ssrsList) {
      const ssrsArray = ssrsList.value.map(s => s.value).reduce((a: number[], b: number[]) => a.concat(b), []);
      const prices: number[] = ssrsArray.map(s => s.price);
      this.lowestPrice = Math.min(...prices);
      this.ssrCodesList = ssrsArray.map(s => s.ssrCode as string);

      if (this.ssrsService.memberPromotions.ssrSegmentPromotionList.length > 0) {
        const originalPrices: number[] = [];
        ssrsArray.forEach(ssr => {
          if (ssr.originalPrice) {
            originalPrices.push(ssr.originalPrice);
            this.hasPromotion = true;
          } else {
            originalPrices.push(ssr.price);
          }
        });
        if (this.hasPromotion) {
          this.lowestOriginalPrice = Math.min(...originalPrices);
          this.hasPromotion = this.lowestOriginalPrice !== this.lowestPrice;
        }
      }
    }
  }

  setReturnFlightComponent(components: QueryList<any>) {
    setTimeout(() => {
      this.ssrBoxContentComponents = components ? components.toArray() : [];
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ssrType) {
      this.setLowestPrice(changes.ssrType.currentValue);
      this.ssrsService.resetSsrs(this.ssrType);
      this.updateSsrAvailabilityByFlight();
      this.spinnerId = 'extras-spinner' + this.ssrType;
      this.promoDiscount = this.ssrsService.getPromoDiscount(this.ssrType);
    }
  }

  public expandBox() {
    if (this.flightsSub) {
      this.flightsSub.unsubscribe();
    }
    this.isExpanded = true;
    const ecommerceCartItems = [];

    const item = new ECommerceCartItem(
      SsrType[this.ssrType],
      this.ecommerce.getFlightInfo(0),
      '0', 'BlueAir',
      SsrType[this.ssrType] + ' click',
      SsrType[this.ssrType],
      1,
      this.ssrsService.actualDisplayOrder.indexOf(this.ssrType) + 1,
      null, this.ecommerce.getUserData()
    )
      .getItem(true);
    ecommerceCartItems.push(item);

    this.ecommerce.ProductClicks(ecommerceCartItems);
  }

  private enableFlightsUpdates() {
    if (!this.flightsSub || this.flightsSub.closed) {
      this.flightsSub = this.flightsObs.subscribe(newFlights => {
        this.currentFlights = newFlights;

        try {
          if (this.syncService && isArray(newFlights)) {
            this.syncService.flights = [...this.currentFlights];
          }
        }
        catch (ex) {
          console.log(ex.message);
        }


        this.updateSsrAvailabilityByFlight();
        this.updateTotalPrice(true);

        this.enableSameOptionsForAllFlights =
          this.currentFlights && this.currentFlights.length === 2 &&
          this.currentFlights[0].departureStation.iataCode === this.currentFlights[1].arrivalStation.iataCode &&
          this.currentFlights[0].arrivalStation.iataCode === this.currentFlights[1].departureStation.iataCode;
      });
    }
  }

  saveChanges(params?: SelectSsrEvent2Params) {
    this.ssrsService.resetSsrs(this.ssrType);

    const newSsrs = [];
    this.ssrBoxContentComponents.forEach(c => {
      newSsrs.push(...c.applySsrs());
    });

    const seatsToBeRemoved = this.ssrsService.getSeatsToBeRemoved(this.ssrType, this.currentFlights, newSsrs);

    if (!seatsToBeRemoved.length) {
      this.completeSaveChanges();
    } else {
      this.seatRemovalModal.openPopup((userAgreed: boolean) => {
        if (userAgreed) {
          this.loadingSpinnerService.showSpinnerById(this.spinnerId);
          this.seatmapService.removeSeats(seatsToBeRemoved).then(() => this.completeSaveChanges());
        } else {
          if (params && params.onCancelSeatRemoval) {
            params.onCancelSeatRemoval();
          }
        }
      }, seatsToBeRemoved);
    }
  }


  completeSaveChanges() {
    this.loadingSpinnerService.showSpinnerById(this.spinnerId);
    this.ssrsService.applySsrs(this.ssrType)
      .then(() => this.enableFlightsUpdates())
      .then(() => {
        // chain all after save changes promises
        return this.afterSaveChanges.reduce((chain, current) => chain.then(current), Promise.resolve());
      })
      .then(() => {
        // this.closeBox();
        this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
      });
  }

  cancelChanges() {
    this.ssrsService.resetSsrs(this.ssrType);
    this.enableFlightsUpdates();
    this.closeBox();
  }

  closeBox() {
    this.isExpanded = false;
  }

  private _isPassengersArray(passenger: PassengerModel | PassengerModel[]): passenger is PassengerModel[] {
    if (Array.isArray(passenger)) {
      return true;
    }

    return false;
  }

  private _removeFromCartForOnePassenger(flight: FlightModel, passenger?: PassengerModel) {
    for (let passengerNumber in flight.ssrs) {
      if (!(passenger && passenger.passengerNumber.toString() !== passengerNumber)) {
        if (flight.ssrs.hasOwnProperty(passengerNumber)) {
          const passengerSsrs: string[] = flight.ssrs[passengerNumber].filter(s => this.ssrCodesList.indexOf(s) >= 0);
          const paidSsrs = flight.paidSsrs[passengerNumber].filter(s => this.ssrCodesList.indexOf(s) >= 0);

          const ssrGroupBy = passengerSsrs.reduce((group, ssr) => {
            (group[ssr] = group[ssr] || []).push(ssr);
            return group;
          }, {});

          for (let ssrCode in ssrGroupBy) {
            if (ssrGroupBy.hasOwnProperty(ssrCode)) {
              let count = ssrGroupBy[ssrCode].length;
              while (count > 0) {
                const paidSsrsCount = paidSsrs.filter(ssr => ssr === ssrCode).length;
                if (paidSsrsCount > 0) {
                  this.ssrsService.removeSsr(this.ssrType, ssrCode, count + paidSsrsCount, +passengerNumber, flight, true);
                } else {
                  this.ssrsService.removeSsr(this.ssrType, ssrCode, count, +passengerNumber, flight, true);
                }
                count--;
              }
            }
          }
        }
      }

    }
  }

  removeFromCart(passengers?: PassengerModel[]) {
    passengers = passengers || [];
    this.currentFlights.forEach(f => {
      if (passengers.length === 0) {
        this._removeFromCartForOnePassenger(f);
      } else {
        passengers.forEach(p => {
          if (p.flightUniqueId === f.flightUniqueId) {
            this._removeFromCartForOnePassenger(f, p);
          }
        });
      }
    });

    this.loadingSpinnerService.showSpinnerById(this.spinnerId);
    this.ssrsService.applySsrs(this.ssrType)
      .then(() => this.enableFlightsUpdates())
      .then(() => {
        return this.afterSaveChanges.reduce((chain, current) => chain.then(current), Promise.resolve());
      })
      .then(() => {
        this.flightPrices.forEach((f, i) => {
          f.fill(0);
        });
        this.loadingSpinnerService.hideSpinnerById(this.spinnerId);
      });
  }

  getIsModified(): boolean {
    switch (this.ssrType) {
      case SsrType.SpecialAssistance:
      case SsrType.Meal:
      case SsrType.Baggage:
      case SsrType.SpecialEquipment: {
        return this.areSsrsSelected();
      }
      default: {
        return this.totalPrice > 0;
      }
    }
  }

  areSsrsSelected(): boolean {
    return this.shoppingCartExtras.some(x => x.ssrType === this.ssrType && x.flightsWithItems.length > 0);
  }

  updateTotalPrice(forced = false) {
    if (!this.shoppingCartExtras || !this.ssrType) {
      return;
    }
    const shoppingCartItem = this.shoppingCartExtras.find(e => e.ssrType === this.ssrType);

    this.totalPrice = shoppingCartItem ? shoppingCartItem.amount : 0;
    this.isModified = this.getIsModified();
    this.temporaryTotalPrice = this.totalPrice;

    if (this.currentFlights && (!this.flightPrices || forced)) {
      this.flightPrices = this.currentFlights.map(f => {
        let prices = [];
        prices.length = f.segments.length;
        prices.fill(0);

        if (shoppingCartItem) {
          const shoppingCartSegments: ShoppingCartExtrasFlightModel[] = shoppingCartItem.flights
            .filter(sf => sf.departureStation === f.departureStation.iataCode && sf.arrivalStation === f.arrivalStation.iataCode)
            .reduce((segments, sf) => segments.concat(sf.segments), []);

          f.segments.forEach((seg, i) => {
            prices[i] = shoppingCartSegments
              .filter(ss => ss.departureStation === seg.departureStation.iataCode && ss.arrivalStation === seg.arrivalStation.iataCode)
              .sum(ss => ss.amount);
          });

          if (SsrSellingTypeHelper.IsSsrTypeSoldByJourney(this.ssrType)) {
            prices = [prices.sum(p => p)];
          }
        }
        return prices;
      });
    }
  }

  updateTotalPricePerFlight(price: number, flightIndex: number, segmentIndex: number = 0) {
    if (!this.flightPrices) {
      this.flightPrices = this.currentFlights.map(f => {
        return f.segments.map(s => 0);
      });
    }

    this.flightPrices[flightIndex][segmentIndex] = price;
    this.temporaryTotalPrice = this.flightPrices
      .reduce((a, b) => a.concat(b), [])
      .reduce((a, b) => a + b);
  }

  updateSsrAvailabilityByFlight() {
    if (!this.currentFlights || !this.ssrType) {
      return;
    }

    this.ssrsAvailabilityByFlight = {};
    const ssrList = this.ssrsService.availableSsrs.find(lists => lists.key === this.ssrType);
    if (ssrList && ssrList.value && ssrList.value.length > 0) {
      const allSegments = this.currentFlights.map(f => f.segments).reduce((a, b) => a.concat(b), []);

      this.currentFlights.concat(allSegments).forEach(segOrFlight => {
        const flightKey = `${segOrFlight.departureStation.iataCode}_${segOrFlight.arrivalStation.iataCode}`;
        const ssrs = ssrList.value.find(s => s.key === flightKey);
        this.ssrsAvailabilityByFlight[flightKey] = ssrs && ssrs.value && ssrs.value.some(i => i.limitPerPassenger.some(p => p.value > 0));
      });
    }
  }

  isAvailableForFlight(flight: FlightModel) {
    if (this.ssrType == this.SsrTypeEnum.FastTrack) {
      let segmentsNotAvailable = 0;
      flight.segments.forEach(segment => {
        const flightKey = `${segment.departureStation.iataCode}_${segment.arrivalStation.iataCode}`;
        if (!this.ssrsAvailabilityByFlight[flightKey]) {
          segmentsNotAvailable++;
        };
      });
      if (segmentsNotAvailable == flight.segments.length) {
        return false;
      }
      return true;
    }

    const flightKey = `${flight.departureStation.iataCode}_${flight.arrivalStation.iataCode}`;
    return this.ssrsAvailabilityByFlight[flightKey] && this.ssrsAvailabilityByFlight[flightKey] === true;
  }

  isAvailableForJourney(flights: FlightModel[]) {
    if (flights.map(f => this.isAvailableForFlight(f)).every((v) => v == false)) {
      return false;
    }
    return true;
  }

  applySsrs() {
    this.ssrsService.resetSsrs(this.ssrType);
    this.ssrBoxContentComponents.forEach(c => {
      c.applySsrs();
    });
  }

  /**
   *
   * @param sellDefaultSsr if TRUE, user has decided to buy default meal ssr
   * else user wants to add meal ssr for each passenger
   */
  validationModalAction(sellDefaultSsr: boolean) {
    if (!sellDefaultSsr) {
      this.elRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
      //this.expandBox();
    }
  }

  isFlexAvailable(flight: FlightModel): boolean {
    if (this.currentFlights.every(f => f.paidSsrs[0] && f.paidSsrs[0].some(s => s === "FLX"))) {
      return true;
    }

    let date = moment(flight.departureStation.dateUtc);

    const currentDate = moment(new Date());

    let tempHours: number = date.diff(currentDate, 'hours');

    return tempHours >= this.minHoursToPurchaseBeforeFlight ? true : false;
  }

  showLuggageErrors() {
    this.luggageComponents.forEach(c => {
      c.passengers.forEach(p => {
        if (!c.ssrsService.luggagePassengersMap.get(c.flight.departureStation.iataCode + c.flight.arrivalStation.iataCode)) {
          c.scrollTo();
        }
      })
    });
  }
}
