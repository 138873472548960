import { Injectable, Injector } from '@angular/core';
import { ApplicationFlowService } from './application-flow.service';
import { environment } from '../../environments/environment';
import { CheckinService } from './checkin.service';
import { BookingFlowService } from './booking-flow.service';
import { SelectionService } from './interfaces/selection.service';
import { CheckinSelectionService } from './checkin-selection.service';
import { BookingSelectionService } from './booking-selection.service';
import { BookingStepsService, ApplicationFlowEnum, CheckinSteps } from './booking-steps.service';

@Injectable({
  providedIn: 'root'
})
export class FlowManagerService {
  
  public flow: number = environment.flow;

  get applicationFlowService(): ApplicationFlowService {
    switch (environment.flow) {
      case 0:
        return this.injector.get(CheckinService);
      case 1:
        return this.injector.get(BookingFlowService);
    }
  }

  get selectionService(): SelectionService {
    switch (environment.flow) {
      case 0:
        return this.injector.get(CheckinSelectionService);
      case 1:
        return this.injector.get(BookingSelectionService);
    }
  }

  constructor(private injector: Injector, private bookingSteps: BookingStepsService) {
  }

  beginBookingFlow() {
    environment.flow = 1;
    this.bookingSteps.setFlow(ApplicationFlowEnum.Booking);
    this.bookingSteps.goToStep(CheckinSteps.search);
  }

  beginCheckinFlow() {
    environment.flow = 0;
    this.bookingSteps.setFlow(ApplicationFlowEnum.Checkin);
    this.bookingSteps.goToStep(CheckinSteps.search);
  }
}
