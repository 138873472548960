<!-- if promotion unavailable -->
<span class="price" *ngIf="!originalPrice else promotionAvailable">
  {{currentPrice | currencyDisplay}}&nbsp;{{ 'each' | translate:'extras' }}
</span>

<!-- if promotion available -->
<ng-template #promotionAvailable>
  <div class="promo-wrapper">
    <div class="promo-text">
      <span class="promo-text-old">{{originalPrice | currencyDisplay}}&nbsp;{{ 'each' | translate:'extras' }}</span>
      <span class="promo-text-new">{{currentPrice | currencyDisplay}}&nbsp;{{ 'each' | translate:'extras' }}</span>
    </div>
  </div>
</ng-template>