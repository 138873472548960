import { Bundle } from './../core/models/bundle.model';
import { ConfigService } from './../core/config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BundleService {
  private bundleDetails: any;
  public bundleDetailsObs: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private configService: ConfigService) { }

  refreshBundleDetails() {
    return this.http.get(this.configService.BundleDetails).toPromise().then(b => {
      this.bundleDetails = b;
      this.bundleDetailsObs.next(this.bundleDetails);
      return this.bundleDetails; });
  }

  getBundleDetails() {
    return this.bundleDetails;
  }

  sellBundle(data) {
    return this.http.post(this.configService.BundleDetails, data).toPromise();
  }
}