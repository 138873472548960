import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticateComponent } from './authenticate/authenticate.component';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { BlueAirCommonModule } from '../common-modules/blue-air-common/blue-air-common.module';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileRoutes } from './profile-routes';
import { UserInfoComponent } from './user-info/user-info.component';
import { BlueDomService } from '../core/blue-dom.service';
import { InfoBoxComponent } from './info-box/info-box.component';
import { InfoBoxActionsComponent } from './info-box-actions/info-box-actions.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { EnvHelper } from '../env-helper';
import { AgentInfoComponent } from './agent/agent-info/agent-info.component';
import { AgentSettingsComponent } from './agent/agent-settings/agent-settings.component';
import { AgentContactDetailsComponent } from './agent/agent-contact-details/agent-contact-details.component';
import { AddressBookBoxComponent } from './address-book-box/address-book-box.component';
import { LanguageInfoComponent } from './language-info/language-info.component';
import { FindMyBookingsComponent } from './agent/find-my-bookings/find-my-bookings.component';
import { FilterBookingsComponent } from './agent/filter-bookings/filter-bookings.component';
import { DisplayBookingsComponent } from  './agent/display-bookings/display-bookings.component';
import { BookingFlightSelectModule } from '../booking-flight-select/booking-flight-select.module';
import { ForceResetPasswordComponent } from './force-reset-password/force-reset-password.component';
import { ResetPasswordFormComponent } from './reset-password-form/reset-password-form.component';
import { LogoutComponent } from './logout/logout.component';

@NgModule({
  declarations: [AuthenticateComponent, LayoutComponent, InfoBoxComponent, FormErrorComponent, InfoBoxActionsComponent, UserInfoComponent,
    AgentContactDetailsComponent,
    AgentSettingsComponent,
    AgentInfoComponent, 
    AddressBookBoxComponent, 
    LanguageInfoComponent,
    FindMyBookingsComponent, FilterBookingsComponent, DisplayBookingsComponent, ForceResetPasswordComponent, ResetPasswordFormComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BlueAirCommonModule,
    SharedModule,
    RouterModule,
    BookingFlightSelectModule
  ],
  entryComponents: [UserInfoComponent, LanguageInfoComponent]
})
export class ProfileManagementModule { 
  constructor(blueDomService: BlueDomService) {
    if (EnvHelper.IsOnAgencyPortal()) {
      setTimeout(() => {
        var child = document.querySelector('header .user-info-wrap');

        if (child != null) {
          blueDomService.appendComponentToElement(UserInfoComponent, document.querySelector('header .user-info-wrap'));
          blueDomService.appendComponentToElement(LanguageInfoComponent, document.querySelector('header .user-info-wrap'));
        }
      }, 0);
    }
  }
}
