import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/core/profile.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/core/config.service';

@Component({
  selector: 'profile-force-reset-password',
  templateUrl: './force-reset-password.component.html',
  styleUrls: ['./force-reset-password.component.scss']
})
export class ForceResetPasswordComponent implements OnInit {
  route: string;

  agentId: number;
  username: string;
  passwordChangeErrors: string[] = [];

  constructor(config: ConfigService, private profileService: ProfileService, private router: Router) { 
    this.route = config.ResetPasswordUrl;
  }

  ngOnInit() {
    this.profileService.getResetPassword().subscribe((result: any) => {
      this.agentId = result.resetPassword.agentId;
      this.username = result.resetPassword.username;
    });
  }

  resetPassword(passwordData: any) {
    this.profileService.resetPassword(
      this.agentId,
      this.username,
      passwordData.oldPassword,
      passwordData.newPassword,
      passwordData.newPasswordConf).subscribe(result=> {
        if (result.loginOk) {
          this.router.navigate(['/']);
        } else {
          this.passwordChangeErrors = result.errors;
        }
      });
  }

}
