import { OnInit, EventEmitter } from '@angular/core';
var SsrSameOptionsForReturnFlightComponent = /** @class */ (function () {
    function SsrSameOptionsForReturnFlightComponent() {
        this.isSelectedChange = new EventEmitter();
        this.uniqueId = Math.floor(Math.random() * 1000) + 1;
    }
    Object.defineProperty(SsrSameOptionsForReturnFlightComponent.prototype, "isSelected", {
        get: function () {
            return this._isSelected;
        },
        set: function (val) {
            this._isSelected = val;
            this.isSelectedChange.emit(this._isSelected);
        },
        enumerable: true,
        configurable: true
    });
    SsrSameOptionsForReturnFlightComponent.prototype.ngOnInit = function () {
    };
    return SsrSameOptionsForReturnFlightComponent;
}());
export { SsrSameOptionsForReturnFlightComponent };
