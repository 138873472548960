import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ProfileService } from 'src/app/core/profile.service';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { ProfileInfoBox } from '../profile-info-box';
import { InfoBoxActionsComponent } from '../info-box-actions/info-box-actions.component';
var AddressBookBoxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddressBookBoxComponent, _super);
    function AddressBookBoxComponent(modalService, profileService, resourceService, spinnerService) {
        var _this = _super.call(this, modalService) || this;
        _this.profileService = profileService;
        _this.resourceService = resourceService;
        _this.spinnerService = spinnerService;
        _this.editModalId = 'address-book';
        _this.editSpinnerId = 'address-book';
        _this.infoBoxSpinnerId = 'address-book-list';
        _this.countries = [];
        _this.types = [];
        _this.items = [];
        _this.model = { address: {} };
        _this.personId = null;
        var infoToLoad = 3;
        var hideSpinner = function () {
            if (--infoToLoad <= 0) {
                _this.spinnerService.hideSpinnerById(_this.infoBoxSpinnerId);
            }
        };
        _this.profileService.profile.subscribe(function (p) {
            if (!p) {
                return;
            }
            if (p.person.addresses.items) {
                _this.items = p.person.addresses.items.filter(function (i) { return i.personId; });
            }
            _this.personId = p.person.personId;
            hideSpinner();
        });
        _this.resourceService.getCountries().subscribe(function (c) {
            _this.countries = c;
            hideSpinner();
        });
        _this.resourceService.getAddressTypes().subscribe(function (d) {
            _this.types = d;
            hideSpinner();
        });
        return _this;
    }
    AddressBookBoxComponent.prototype.ngOnInit = function () {
    };
    AddressBookBoxComponent.prototype.edit = function () { };
    AddressBookBoxComponent.prototype.editItem = function (itemIndex) {
        this.infoBoxActions.showDeleteMessage = false;
        if (itemIndex > -1) {
            var item = this.items[itemIndex];
            this.model = {
                address: {
                    addressLine1: item.address.addressLine1,
                    addressLine2: item.address.addressLine2,
                    addressLine3: item.address.addressLine3,
                    city: item.address.city,
                    countryCode: item.address.countryCode,
                    postalCode: item.address.postalCode
                },
                personAddressId: item.personAddressId,
                typeCode: item.typeCode,
                default: item.default
            };
        }
        else {
            this.model = { address: {} };
        }
        this.model.index = itemIndex;
        this.model.personId = this.personId;
        this.openModal();
    };
    AddressBookBoxComponent.prototype.confirmDelete = function () {
        var _this = this;
        this.spinnerService.showSpinnerById(this.editSpinnerId);
        this.profileService.deleteAddressBookItem(this.model.index)
            .then(function () { return _this.profileService.refreshProfile(true); })
            .then(function () {
            _this.closeModal();
            _this.spinnerService.hideSpinnerById(_this.editSpinnerId);
        });
    };
    AddressBookBoxComponent.prototype.save = function () {
        var _this = this;
        if (!this.editForm.valid) {
            return;
        }
        this.spinnerService.showSpinnerById(this.editSpinnerId);
        this.profileService.updateAddressBookItem(this.model, this.model.index)
            .then(function () { return _this.profileService.refreshProfile(true); })
            .then(function () {
            _this.closeModal();
            _this.spinnerService.hideSpinnerById(_this.editSpinnerId);
        });
    };
    return AddressBookBoxComponent;
}(ProfileInfoBox));
export { AddressBookBoxComponent };
