import * as tslib_1 from "tslib";
import { OnInit, DoCheck } from '@angular/core';
import { ControlValueAccessor, NgForm } from '@angular/forms';
import { ProfileService } from 'src/app/core/profile.service';
import { filter, take } from 'rxjs/operators';
import { BookingFlowService } from 'src/app/core/booking-flow.service';
var PaxContactDetailsComponent = /** @class */ (function () {
    function PaxContactDetailsComponent(profileService, bookingFlowService) {
        var _this = this;
        this.profileService = profileService;
        this.bookingFlowService = bookingFlowService;
        this.phonePattern = '^[0-9+()\/-][0-9+() \/-]{0,19}?$';
        this.namePattern = '^[A-Za-z _]*[A-Za-z][A-Za-z _]*$';
        this.emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
        this.journeys = [];
        this.recordLocators = [];
        if (this.profileService.isAgent) {
            this.isAgent = this.profileService.isAgent;
            this.profileService.profile.pipe(filter(function (p) { return p ? true : false; }), take(1)).subscribe(function (data) {
                _this.agent = data;
            });
        }
        else {
            this.isAgent = false;
        }
    }
    PaxContactDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.bookingFlowService.loadFlowInfo().then(function (booking) {
            _this.journeys = booking.convertedJourneys.journeys;
            _this.isCanceledBookingStatus = _this.checkBookingStatus();
            if (booking && booking.bookingDetail && booking.bookingDetail.recordLocators && booking.bookingDetail.recordLocators.length > 0) {
                _this.recordLocators = booking.bookingDetail.recordLocators;
                _this.isRestricted = _this.isRestrictedBooking();
            }
        });
    };
    PaxContactDetailsComponent.prototype.ngDoCheck = function () {
        if (this.check && this.firstPaxName &&
            (this.contact.name.title !== this.firstPaxName.title ||
                this.contact.name.first !== this.firstPaxName.first ||
                this.contact.name.last !== this.firstPaxName.last)) {
            this.firstPaxChanged();
        }
    };
    PaxContactDetailsComponent.prototype.writeValue = function (obj) {
        this.contact = obj || { name: {} };
        this.uncheckAgreeTermsAndCond(true);
    };
    PaxContactDetailsComponent.prototype.registerOnChange = function (fn) {
    };
    PaxContactDetailsComponent.prototype.registerOnTouched = function (fn) {
    };
    PaxContactDetailsComponent.prototype.setDisabledState = function (isDisabled) {
    };
    PaxContactDetailsComponent.prototype.firstPaxChanged = function () {
        if (this.check && this.firstPaxName) {
            this.agentCheck = false;
            this.contact.name = Object.assign({}, this.firstPaxName || {});
            this.contact.isFirstPassenger = true;
        }
        else {
            this.contact.isFirstPassenger = false;
        }
    };
    PaxContactDetailsComponent.prototype.contactChanged = function () {
        this.check = false;
        this.agentCheck = false;
        this.contact.isFirstPassenger = false;
    };
    PaxContactDetailsComponent.prototype.completeAgentData = function () {
        var _this = this;
        if (this.agentCheck) {
            this.check = false;
            if (this.agent) {
                var title = this.titleItems.find(function (i) { return i.value === _this.agent.name.title; });
                this.contact.name.title = title ? title.value : this.titleItems[0].value;
                this.contact.name.first = this.agent.name.first;
                this.contact.name.last = this.agent.name.last;
                this.contact.addressLine1 = this.agent.workAddress.addressLine1;
                this.contact.addressLine2 = this.agent.workAddress.addressLine2;
                this.contact.city = this.agent.workAddress.city;
                this.contact.countryCode = this.agent.workAddress.countryCode;
                this.contact.homePhone = this.agent.mobilePhoneNumber.number;
                this.contact.otherPhone = this.agent.workPhoneNumber.number;
                this.contact.postalCode = this.agent.workAddress.postalCode;
                this.contact.emailAddress = this.agent.workEmailAddress.emailAddress;
            }
        }
    };
    PaxContactDetailsComponent.prototype.uncheckAgreeTermsAndCond = function (event) {
        this.contact.agreeTermsAndCond = event;
    };
    PaxContactDetailsComponent.prototype.checkBookingStatus = function () {
        var e_1, _a, e_2, _b, e_3, _c;
        try {
            for (var _d = tslib_1.__values(this.journeys), _e = _d.next(); !_e.done; _e = _d.next()) {
                var f = _e.value;
                try {
                    for (var _f = tslib_1.__values(f.segments), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var s = _g.value;
                        try {
                            for (var _h = tslib_1.__values(s.legs), _j = _h.next(); !_j.done; _j = _h.next()) {
                                var l = _j.value;
                                if (l.legInfo.status === "Canceled") {
                                    return true;
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    PaxContactDetailsComponent.prototype.isRestrictedBooking = function () {
        return (this.recordLocators.some(function (r) { return r.owningSystemCode !== null && r.owningSystemCode !== undefined; })) ? true : false;
    };
    PaxContactDetailsComponent.prototype.checkTermsAndCond = function (isCheckedTermsAndCond) {
        if (!isCheckedTermsAndCond.isDirty) {
            return;
        }
        if (!isCheckedTermsAndCond) {
            this.isFieldRequired = true;
            return;
        }
        this.isFieldRequired = false;
    };
    return PaxContactDetailsComponent;
}());
export { PaxContactDetailsComponent };
