import { Injectable } from '@angular/core';
import { BaseCanActivateService } from '../core/base-can-activate.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { BookingStepsService, CheckinSteps } from '../core/booking-steps.service';
import { CheckinService } from '../core/checkin.service';
import { BookingService } from '../core/booking.service';

@Injectable()
export class CanActivateConfirmationService implements CanActivate {

  constructor(private bookingSteps: BookingStepsService, private checkinService: CheckinService, private bookingService:BookingService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    this.bookingService.refresh();
    return this.checkinService.loadCheckInInfo()
      .then(() => {
        const isCheckinStarted =
          this.checkinService.checkinInfo.criteria &&
          this.checkinService.checkinInfo.criteria.length > 0;

        if (!isCheckinStarted) {
          this.bookingSteps.goToStep(CheckinSteps.search);
          return false;
        }

        return this.checkinService.loadPriceBreakdown().then(() => {
          if (this.checkinService.shoppingCartBreakdown.value.currentShoppingCart.balanceDue !== 0) {
            this.bookingSteps.goToStep(CheckinSteps.summary);
            return false;
          }
          return true;
        });
      });
  }
}
