/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./blue-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../services/blue-renderer.service";
import * as i4 from "./blue-modal.component";
import * as i5 from "./blue-modal.service";
var styles_BlueModalComponent = [i0.styles];
var RenderType_BlueModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BlueModalComponent, data: {} });
export { RenderType_BlueModalComponent as RenderType_BlueModalComponent };
function View_BlueModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "button", [["class", "popup-close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "font-ico-close-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.closeBtnLabel; _ck(_v, 3, 0, currVal_0); }); }
export function View_BlueModalComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { dialogRef: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["dialogRef", 1]], null, 5, "div", [["aria-hidden", "true"], ["aria-modal", "true"], ["role", "dialog"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "popup-overlay"], ["role", "presentation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "popup-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BlueModalComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showCloseBtn; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "checkin-popup ", _co.cssClass, ""); _ck(_v, 1, 0, currVal_0); }); }
export function View_BlueModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "blue-modal", [], null, null, null, View_BlueModalComponent_0, RenderType_BlueModalComponent)), i1.ɵprd(512, null, i3.BlueRenderer, i3.BlueRenderer, [i1.Renderer2]), i1.ɵdid(2, 245760, null, 0, i4.BlueModalComponent, [i5.BlueModalService, i1.ElementRef, i1.Renderer2, i3.BlueRenderer], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var BlueModalComponentNgFactory = i1.ɵccf("blue-modal", i4.BlueModalComponent, View_BlueModalComponent_Host_0, { id: "id", closeBtnLabel: "closeBtnLabel", cssClass: "cssClass", showCloseBtn: "showCloseBtn" }, {}, ["*"]);
export { BlueModalComponentNgFactory as BlueModalComponentNgFactory };
