import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { LocalStorage } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, skip, map, shareReplay } from 'rxjs/operators';
import { StarterCancelRebook } from './models/change-booking-model';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
var BookingService = /** @class */ (function () {
    function BookingService(http, config, translateService) {
        this.http = http;
        this.config = config;
        this.translateService = translateService;
        this.bookingObs = new BehaviorSubject(null);
        this.flightsToChangeObs = new BehaviorSubject(null);
        this.memberPromotionObs = new BehaviorSubject(null);
    }
    /**
     * Refreshes the booking information
     * @param forced if [true] a http request will be made. If [false] the http request will be made only on the first call
     */
    BookingService.prototype.refresh = function (forced) {
        var _this = this;
        if (forced === void 0) { forced = false; }
        // if not forced and already completed return current value
        if (!forced && this.bookingSubscription && this.bookingSubscription.closed) {
            return Promise.resolve(this.bookingObs.value);
        }
        // if forced - cancel previous request if any
        if (forced && this.bookingSubscription) {
            this.bookingSubscription.unsubscribe();
        }
        // if forced or no previous requests wered attempted do the request
        if (forced || !this.bookingSubscription) {
            this.bookingSubscription = this.http.get(this.config.Booking).subscribe(function (b) {
                _this.booking = b;
                _this.bookingObs.next(_this.booking);
            });
        }
        // return the next value the comes up in the subscription
        return new Promise(function (resolve) { return _this.bookingObs.pipe(skip(1), take(1)).subscribe(function (data) { return resolve(data); }); });
    };
    BookingService.prototype.getBooking = function () {
        return this.booking;
    };
    BookingService.prototype.getSnapshot = function () {
        return this.http.get(this.config.BookingSnapshot).toPromise();
    };
    BookingService.prototype.createSnapshot = function () {
        return this.http.post(this.config.BookingSnapshot, {}).toPromise();
    };
    BookingService.prototype.clearSnapshot = function () {
        return this.http.delete(this.config.BookingSnapshot, {}).toPromise();
    };
    BookingService.prototype.unsubscribeBookingFromNotifications = function (pnr, email) {
        var queryParams = "?RecordLocator=" + pnr + "&EmailAddress=" + email;
        return this.http.post(this.config.UnsubscribeBookingFromNotificationsUrl + queryParams, {}).toPromise();
    };
    BookingService.prototype.addComment = function (pnr, comment, email, noDuplicates) {
        var queryParams = "?RecordLocator=" + pnr + "&Comment=" + comment + "&EmailAddress=" + email + "&NoDuplicates=" + noDuplicates;
        return this.http.post(this.config.AddBookingCommentUrl + queryParams, {}).toPromise();
    };
    BookingService.prototype.refundCashBooking = function (emailAddress) {
        return this.http.post(this.config.RefundCashUrl, {
            emailAddress: emailAddress
        }).toPromise();
    };
    BookingService.prototype.setPassengerConsent = function (emailAddress) {
        return this.http.post(this.config.PassengerConsentUrl, {
            emailAddress: emailAddress
        }).toPromise();
    };
    BookingService.prototype.savePassengers = function (passengers, infants) {
        var putItem = {
            passengers: {
                items: []
            }
        };
        passengers.forEach(function (p) {
            var currentPaxInfant = infants.find(function (i) { return i.attachedPassengerNumber === p.passengerNumber; });
            if (p.travelDocument && Object.keys(p.travelDocument).length > 0) {
                p.travelDocument.names = [];
                p.travelDocuments.items = [];
                p.travelDocument.name = p.name;
                p.travelDocument.names.push(p.name);
                p.travelDocument.passengerNumber = p.passengerNumber;
                p.travelDocuments.items.push(p.travelDocument);
            }
            var typeInfo = Object.assign({}, p.typeInfo);
            delete typeInfo.paxType;
            if (p.address && Object.keys(p.address).length > 0) {
                p.address.typeCode = 'C';
                p.addresses.items = [];
                p.addresses.items.push(p.address);
            }
            putItem.passengers.items.push({
                name: p.name,
                info: p.info,
                typeInfo: typeInfo,
                travelDocuments: p.travelDocuments,
                addresses: p.addresses,
                infant: currentPaxInfant,
                hasInfant: currentPaxInfant != null
            });
        });
        return this.http.put(this.config.PassengersUrl, putItem).toPromise();
    };
    BookingService.prototype.saveContact = function (contact) {
        var contactToSave = Object.assign({}, contact);
        delete contactToSave.person;
        contactToSave.cultureCode = this.translateService.culture;
        return this.http.put(this.config.ContactUrl, { 'contact': contactToSave }).toPromise();
    };
    BookingService.prototype.saveLockFareData = function (passengers, infants, contactData, titleItems) {
        var _this = this;
        var contactToSave = Object.assign({}, contactData);
        delete contactToSave.person;
        contactToSave.cultureCode = this.translateService.culture;
        var putItem = {
            passengers: {
                items: []
            },
            contact: contactToSave
        };
        var newArray = [];
        passengers.forEach(function (p) {
            var name = Object.assign({}, p.name || {});
            var info = Object.assign({}, p.info || {});
            var typeInfo = Object.assign({}, p.typeInfo || {});
            var passengerNumber = p.passengerNumber;
            newArray.push({
                name: name,
                info: info,
                typeInfo: typeInfo,
                passengerNumber: passengerNumber
            });
        });
        newArray.forEach(function (p) {
            if (!p.name.first && !p.name.last) {
                p.name.first = _this.generateRandomString();
                p.name.last = _this.generateRandomString();
                p.name.title = titleItems[1].value;
            }
            var currentPaxInfant = infants.find(function (i) { return i.attachedPassengerNumber === p.passengerNumber; });
            var infant = Object.assign({}, currentPaxInfant || {});
            if (currentPaxInfant && !currentPaxInfant.name.first && !currentPaxInfant.name.last) {
                var name_1 = Object.assign({}, currentPaxInfant.name || {});
                name_1.first = _this.generateRandomString();
                name_1.last = _this.generateRandomString();
                infant.name = name_1;
            }
            putItem.passengers.items.push({
                name: p.name,
                info: p.info,
                typeInfo: p.typeInfo,
                infant: infant,
                hasInfant: currentPaxInfant != null
            });
        });
        return this.http.put(this.config.LockFareDetails, putItem).toPromise();
    };
    BookingService.prototype.generateRandomString = function () {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var charactersLength = characters.length;
        var index = Math.floor(Math.random() * charactersLength);
        for (var i = 0; i < 3; i++) {
            result += characters.charAt(index);
        }
        result += ' ';
        for (var i = 0; i < 3; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };
    BookingService.prototype.retrieveBooking = function (recordLocator, lastName, emailAddress, apiUrl) {
        var data = { id: recordLocator, lastName: lastName, email: emailAddress };
        return this.http.post(apiUrl, data, {}).toPromise();
    };
    BookingService.prototype.changeFlight = function (recordLocator, newFlightModel, oldFlights, selectedJourneys) {
        var journeys = [];
        oldFlights.forEach(function (oldFlight) {
            var journey = new StarterCancelRebook();
            if (selectedJourneys.filter(function (j) { return j.sellKey == oldFlight.sellKey; }).length) {
                journey.FromJourney = oldFlight.sellKey;
                journey.Origin = newFlightModel.isRoundTrip && oldFlight.journeySequenceNumber == 2 ? newFlightModel.destination : newFlightModel.origin;
                journey.Destination = newFlightModel.isRoundTrip && oldFlight.journeySequenceNumber == 2 ? newFlightModel.origin : newFlightModel.destination;
                journey.DepartureDate = newFlightModel.isRoundTrip && oldFlight.journeySequenceNumber == 2 ? newFlightModel.returnDate : newFlightModel.departureDate;
            }
            else {
                journey.Origin = oldFlight.segments[0].departureStation;
                journey.Destination = oldFlight.segments[oldFlight.segments.length - 1].arrivalStation;
                journey.DepartureDate = oldFlight.segments[0].sTD;
            }
            journeys.push(journey);
        });
        var data = {
            starterCancelRebook: {
                SelectedCancelRebookFlights: [journeys[0], journeys.length == 2 ? journeys[1] : {}]
            }
        };
        return this.http.post(this.config.ChangeFlight + '?rl=' + recordLocator, data).toPromise();
    };
    BookingService.prototype.moveFlights = function (journeyCriteria) {
        var data = {
            move: {
                MoveFlights: journeyCriteria
            }
        };
        return this.http.post(this.config.MoveFlight, data).toPromise();
    };
    BookingService.prototype.checkUserEligibilityForPromotion = function () {
        return this.http.get(this.config.CheckUserEligibilityAndUsePromotion)
            .toPromise()
            .then(function (data) {
            return data;
        });
    };
    BookingService.prototype.applyUserPromotionAfterLoggin = function (response) {
        var data = { r: response };
        return this.http.post(this.config.CheckUserEligibilityAndUsePromotion, data, {}).toPromise();
    };
    BookingService.prototype.clearSession = function () {
        return this.http.post(this.config.ClearSession, {}).toPromise();
    };
    BookingService.prototype.manageBookingPriceBreakdown = function () {
        return this.http.get(this.config.PriceBreakdown).toPromise();
    };
    BookingService.prototype.loadItineraryPage = function () {
        return this.http.get(this.config.Itinerary).toPromise();
    };
    BookingService.prototype.loadMemberPromotionActivationInfo = function () {
        return this.http.get(this.config.MemberPromotionActivationInfoUrl).pipe(map(function (data) {
            return data.memberPromotionActivationInfo.isValid && !data.memberPromotionActivationInfo.isActivated ?
                data.memberPromotionActivationInfo : null;
        })).toPromise();
    };
    BookingService.prototype.subscribeToNewsletter = function (isFirstPassenger) {
        if (isFirstPassenger === void 0) { isFirstPassenger = false; }
        return this.http.post(this.config.NewsletterSubscriptionUrl, {
            newsletterSubscription: {
                isFirstPassenger: isFirstPassenger
            }
        }).toPromise();
    };
    BookingService.prototype.getMember = function (skipCache) {
        var _this = this;
        if (skipCache === void 0) { skipCache = false; }
        skipCache = skipCache || !this.memberDetails;
        if (!this.memberDetails) {
            this.memberDetails = new Observable(function (observer) { return _this.memberDetailsObserver = observer; }).pipe(shareReplay(1));
        }
        if (skipCache) {
            this.http.get(this.config.MemberDetails).subscribe(function (memberDetails) { return _this.memberDetailsObserver.next(memberDetails); });
        }
        return this.memberDetails;
    };
    BookingService.prototype.getMemberDetails = function () {
        var _this = this;
        this.http.get(this.config.MemberDetails).subscribe(function (data) { return _this.memberPromotionObs.next(data); });
    };
    BookingService.prototype.isB2CBooking = function () {
        var journeys = this.booking.convertedJourneys.journeys;
        return ((this.booking.bookingDetail.pointOfSale.organizationCode === 'SYSTEM' ||
            this.booking.bookingDetail.pointOfSale.organizationCode == "BAMEMBERS" ||
            (this.booking.bookingDetail.pointOfSale.organizationCode == "0B" &&
                journeys.every(function (j) { return j.segments && j.segments.every(function (s) { return s.createdAgentRoleCode.match("WWWM"); }); })))
            &&
                (this.booking.bookingDetail.pointOfSale.agentCode === 'WebAnonymous' ||
                    this.booking.bookingDetail.pointOfSale.agentCode === 'ApiAnonymous' ||
                    journeys.every(function (j) { return j.segments && j.segments.every(function (s) { return s.createdAgentRoleCode.match("WWWM"); }); })));
    };
    tslib_1.__decorate([
        LocalStorage(),
        tslib_1.__metadata("design:type", String)
    ], BookingService.prototype, "recordLocator", void 0);
    return BookingService;
}());
export { BookingService };
