/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./default-modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../common-modules/blue-air-common/blue-modal/blue-modal.component.ngfactory";
import * as i4 from "../../common-modules/blue-air-common/services/blue-renderer.service";
import * as i5 from "../../common-modules/blue-air-common/blue-modal/blue-modal.component";
import * as i6 from "../../common-modules/blue-air-common/blue-modal/blue-modal.service";
import * as i7 from "./default-modal.component";
import * as i8 from "../../extras/ssrs.service";
var styles_DefaultModalComponent = [i0.styles];
var RenderType_DefaultModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DefaultModalComponent, data: {} });
export { RenderType_DefaultModalComponent as RenderType_DefaultModalComponent };
function View_DefaultModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["attr.aria-label", "okButtonText"], ["type", "submit"]], [[8, "disabled", 0], [8, "id", 0], [8, "className", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.ok() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.okDisabled; var currVal_1 = _co.okButtonId; var currVal_2 = i1.ɵinlineInterpolate(1, "btn btn-primary btn-small btn-left ", _co.leftButtonClass, ""); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.okButtonText; _ck(_v, 1, 0, currVal_3); }); }
function View_DefaultModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["attr.aria-label", "cancelButtonText"], ["class", "btn btn-primary btn-small btn-right inverted"], ["type", "submit"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cancelButtonId; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.cancelButtonText; _ck(_v, 1, 0, currVal_1); }); }
function View_DefaultModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "buttons"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DefaultModalComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DefaultModalComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.okRequired; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.cancelRequired; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_DefaultModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "blue-modal", [["class", "checked-in-summary-popup"]], null, null, null, i3.View_BlueModalComponent_0, i3.RenderType_BlueModalComponent)), i1.ɵprd(512, null, i4.BlueRenderer, i4.BlueRenderer, [i1.Renderer2]), i1.ɵdid(2, 245760, null, 0, i5.BlueModalComponent, [i6.BlueModalService, i1.ElementRef, i1.Renderer2, i4.BlueRenderer], { id: [0, "id"], closeBtnLabel: [1, "closeBtnLabel"], showCloseBtn: [2, "showCloseBtn"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [["class", "section-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "section-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DefaultModalComponent_1)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalId; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.cancelButtonText, ""); var currVal_2 = _co.showCloseBtn; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = (_co.okRequired || _co.cancelRequired); _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i1.ɵinlineInterpolate(1, "default-modal ", _co.cssClass, ""); _ck(_v, 4, 0, currVal_3); var currVal_4 = _co.modalTitle; _ck(_v, 6, 0, currVal_4); var currVal_5 = _co.modalText; _ck(_v, 8, 0, currVal_5); }); }
export function View_DefaultModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "check-in-default-modal", [], null, null, null, View_DefaultModalComponent_0, RenderType_DefaultModalComponent)), i1.ɵdid(1, 114688, null, 0, i7.DefaultModalComponent, [i6.BlueModalService, i8.SsrsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DefaultModalComponentNgFactory = i1.ɵccf("check-in-default-modal", i7.DefaultModalComponent, View_DefaultModalComponent_Host_0, { modalId: "modalId", modalTitle: "modalTitle", modalText: "modalText", cancelButtonText: "cancelButtonText", okButtonText: "okButtonText", okRequired: "okRequired", okDisabled: "okDisabled", cancelRequired: "cancelRequired", cssClass: "cssClass", leftButtonClass: "leftButtonClass", rightButtonClass: "rightButtonClass", showCloseBtn: "showCloseBtn" }, {}, ["*"]);
export { DefaultModalComponentNgFactory as DefaultModalComponentNgFactory };
