/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calendar-date-picker.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../common-modules/blue-air-common/translator/translate.pipe";
import * as i4 from "../../common-modules/blue-air-common/translator/translate.service";
import * as i5 from "@angular/forms";
import * as i6 from "./calendar-date-picker.component";
import * as i7 from "../../common-modules/blue-air-common/services/blue-renderer.service";
import * as i8 from "../../core/config.service";
import * as i9 from "@angular/common/http";
import * as i10 from "../../core/booking-flow.service";
var styles_CalendarDatePickerComponent = [i0.styles];
var RenderType_CalendarDatePickerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CalendarDatePickerComponent, data: {} });
export { RenderType_CalendarDatePickerComponent as RenderType_CalendarDatePickerComponent };
export function View_CalendarDatePickerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.LowerCasePipe, []), i1.ɵpid(0, i3.TranslatePipe, [i4.TranslateService]), i1.ɵqud(402653184, 1, { pickerInput: 0 }), i1.ɵqud(402653184, 2, { pickerContainer: 0 }), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["pickerInput", 1]], null, 1, "input", [["class", "datepicker"], ["type", "text"]], [[1, "placeholder", 0], [8, "readOnly", 0]], [[null, "focus"], [null, "click"], [null, "keypress"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = ((!_co.isOnMobile && _co.open()) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((_co.isOnMobile && _co.open()) !== false);
        ad = (pd_1 && ad);
    } if (("keypress" === en)) {
        var pd_2 = (_co.keyPress($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵeld(6, 0, [[2, 0], ["pickerContainer", 1]], null, 7, "div", [["class", "date-picker"]], [[4, "display", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 4, "a", [["class", "close js-close-datepicker"], ["href", "#"], ["role", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "font-ico-close-menu"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "span", [["class", "selected-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵppd(17, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.format)), ""); var currVal_1 = _co.isOnMobile; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = (_co.isOpened ? "block" : ""); _ck(_v, 6, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i1.ɵnov(_v, 1), "Close date picker", "change-flight")); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.header; _ck(_v, 13, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i1.ɵnov(_v, 1), "Calendar Icon", "change-flight")); _ck(_v, 16, 0, currVal_5); }); }
export function View_CalendarDatePickerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "calendar-date-picker", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CalendarDatePickerComponent_0, RenderType_CalendarDatePickerComponent)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.CalendarDatePickerComponent]), i1.ɵprd(512, null, i7.BlueRenderer, i7.BlueRenderer, [i1.Renderer2]), i1.ɵdid(3, 4833280, null, 0, i6.CalendarDatePickerComponent, [i1.NgZone, i4.TranslateService, i1.Renderer2, i7.BlueRenderer, i8.ConfigService, i9.HttpClient, i10.BookingFlowService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var CalendarDatePickerComponentNgFactory = i1.ɵccf("calendar-date-picker", i6.CalendarDatePickerComponent, View_CalendarDatePickerComponent_Host_0, { format: "format", minDate: "minDate", maxDate: "maxDate", rangeStart: "rangeStart", rangeEnd: "rangeEnd", departure: "departure", destination: "destination", header: "header" }, { select: "select" }, []);
export { CalendarDatePickerComponentNgFactory as CalendarDatePickerComponentNgFactory };
