import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { TranslateService } from './translator/translate.service';
import { of as observableOf } from 'rxjs';
import { map, publishReplay, refCount, share } from 'rxjs/operators';
var ResourceService = /** @class */ (function () {
    function ResourceService(http, translateService) {
        this.http = http;
        this.translateService = translateService;
    }
    ResourceService.prototype.init = function (resourcesUrl, apiUrl, apiKey) {
        this.resourcesUrl = resourcesUrl || apiUrl;
        this.apiKey = apiKey;
    };
    ResourceService.prototype.getCountries = function () {
        var _this = this;
        if (this.countries) {
            return this.countries;
        }
        this.countries = this.http.get(this.resourcesUrl + 'Resource/Country').pipe(map(function (response) {
            var list = [];
            var countryList = response.countryInfo.countryList;
            for (var countryCode in countryList) {
                if (countryList.hasOwnProperty(countryCode)) {
                    list.push({
                        text: _this.translateService.instant(countryCode, 'country.name'),
                        value: countryCode
                    });
                }
            }
            return list;
        }), publishReplay(), refCount());
        return this.countries;
    };
    ResourceService.prototype.getDocumentTypes = function (isAgent) {
        if (isAgent === void 0) { isAgent = false; }
        if (this.documentTypes) {
            return this.documentTypes;
        }
        var list = [];
        list.push({
            text: this.translateService.instant('Passport', 'document.type'),
            value: 'P'
        });
        list.push({
            text: this.translateService.instant('ID Card', 'document.type'),
            value: 'I'
        });
        if (isAgent) {
            list.push({
                text: this.translateService.instant('Other Airline Frequent Flyer', 'document.type'),
                value: 'OAFF'
            });
            list.push({
                text: this.translateService.instant('Travel Visa', 'document.type'),
                value: 'V'
            });
        }
        this.documentTypes = observableOf(list).pipe(share());
        return this.documentTypes;
    };
    ResourceService.prototype.getAddressTypes = function () {
        if (this.addressTypes) {
            return this.addressTypes;
        }
        var list = [];
        list.push({
            text: this.translateService.instant('Home', 'address.type'),
            value: 'H'
        });
        list.push({
            text: this.translateService.instant('Business', 'address.type'),
            value: 'W'
        });
        this.addressTypes = observableOf(list).pipe(share());
        return this.addressTypes;
    };
    ResourceService.prototype.getSeatLocationTypes = function () {
        if (this.seatLocationPreferenceTypes) {
            return this.seatLocationPreferenceTypes;
        }
        var list = [];
        list.push({
            text: this.translateService.instant('No Preference', 'seatLocationPreference.type'),
            value: 'NOPREFERENCE'
        });
        list.push({
            text: this.translateService.instant('Front', 'seatLocationPreference.type'),
            value: 'FRONT'
        });
        list.push({
            text: this.translateService.instant('Rear', 'seatLocationPreference.type'),
            value: 'REAR'
        });
        this.seatLocationPreferenceTypes = observableOf(list).pipe(share());
        return this.seatLocationPreferenceTypes;
    };
    ResourceService.prototype.getSeatTypePreferenceTypes = function () {
        if (this.seatTypePreferenceTypes) {
            return this.seatTypePreferenceTypes;
        }
        var list = [];
        list.push({
            text: this.translateService.instant('No Preference', 'seatTypePreference.type'),
            value: 'NOPREFERENCE'
        });
        list.push({
            text: this.translateService.instant('Aisle', 'seatTypePreference.type'),
            value: 'AISLE'
        });
        list.push({
            text: this.translateService.instant('Window', 'seatTypePreference.type'),
            value: 'WINDOW'
        });
        this.seatTypePreferenceTypes = observableOf(list).pipe(share());
        return this.seatTypePreferenceTypes;
    };
    ResourceService.prototype.getPassengerTitles = function () {
        if (this.passengerTitles) {
            return this.passengerTitles;
        }
        var list = [
            {
                'text': this.translateService.instant('Mr'),
                'value': 'MR'
            },
            {
                'text': this.translateService.instant('Ms'),
                'value': 'MS'
            }
        ];
        this.passengerTitles = observableOf(list).pipe(share());
        return this.passengerTitles;
    };
    ResourceService.prototype.getPassengerTypes = function () {
        if (this.passengerTypes) {
            return this.passengerTypes;
        }
        this.passengerTypes = this.http.get(this.resourcesUrl + 'customresource/PassengerTypes').pipe(map(function (response) {
            return response.map(function (r) { return r.value; });
        }), publishReplay(), refCount());
        return this.passengerTypes;
    };
    ResourceService.prototype.getMinHoursToPurchaseBeforeFlightAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.resourcesUrl + 'customresource/FlexPurchaseBeforeFlight').toPromise()];
            });
        });
    };
    ResourceService.prototype.getRefundToWalletAgentDisplayRestrictions = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.resourcesUrl + 'customresource/RefundToWalletAgentDisplayRestrictions').toPromise()];
            });
        });
    };
    ResourceService.prototype.getFlexInactivePeriodAsync = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, this.http.get(this.resourcesUrl + 'customresource/FlexActivePeriods').toPromise()];
            });
        });
    };
    ResourceService.prototype.getClassesOfService = function () {
        if (this.classesOfService) {
            return this.classesOfService;
        }
        // tslint:disable-next-line: max-line-length
        var list = [
            { 'text': 'A', 'value': 'A' },
            { 'text': 'B2', 'value': 'B2' },
            { 'text': 'B1', 'value': 'B1' },
            { 'text': 'B', 'value': 'B' },
            { 'text': 'C1', 'value': 'C1' },
            { 'text': 'C', 'value': 'C' },
            { 'text': 'D2', 'value': 'D2' },
            { 'text': 'D1', 'value': 'D1' },
            { 'text': 'D', 'value': 'D' },
            { 'text': 'F1', 'value': 'F1' },
            { 'text': 'F', 'value': 'F' },
            { 'text': 'G1', 'value': 'G1' },
            { 'text': 'G', 'value': 'G' },
            { 'text': 'H', 'value': 'H' },
            { 'text': 'I', 'value': 'I' },
            { 'text': 'J', 'value': 'J' },
            { 'text': 'K', 'value': 'K' },
            { 'text': 'L', 'value': 'L' },
            { 'text': 'M', 'value': 'M' },
            { 'text': 'N', 'value': 'N' },
            { 'text': 'O', 'value': 'O' },
            { 'text': 'P', 'value': 'P' },
            { 'text': 'Q', 'value': 'Q' },
            { 'text': 'R', 'value': 'R' },
            { 'text': 'S', 'value': 'S' },
            { 'text': 'T', 'value': 'T' },
            { 'text': 'U', 'value': 'U' },
            { 'text': 'V', 'value': 'V' },
            { 'text': 'W1', 'value': 'W1' },
            { 'text': 'W', 'value': 'W' },
            { 'text': 'X1', 'value': 'X1' },
            { 'text': 'X', 'value': 'X' },
            { 'text': 'Y1', 'value': 'Y1' },
            { 'text': 'Y', 'value': 'Y' }
        ];
        this.classesOfService = observableOf(list).pipe(share());
        return this.classesOfService;
    };
    return ResourceService;
}());
export { ResourceService };
