<div class="form-row btn-row space-between" *ngIf="!showDeleteMessage">
  <div class="delete-actions left" *ngIf="showDeleteBtn">
    <span role="button" class="btn btn-secondary blue" (click)="onDelete($event)">{{ 'Delete' | translate:'info-box-actions' }}</span>
  </div>
  <span role="button" class="btn btn-secondary blue right" (click)="cancel.emit($event)">{{ 'Cancel' | translate:'info-box-actions' }}</span>
  <button type="submit" class="btn btn-primary btn-blue right" (click)="save.emit($event)">{{ 'Save' | translate:'info-box-actions' }}</button>
</div>

<div class="form-row btn-row space-between confirm-delete" *ngIf="showDeleteMessage">
  <span class="popup-title">{{ deleteConfirmationMessage }}</span>
  <div class="delete-actions left">
    <span role="button" class="btn btn-secondary blue" (click)="onCancelDelete($event)">{{ 'No' | translate:'info-box-actions' }}</span>
  </div>
  <span role="button" class="btn btn-secondary blue right" (click)="confirmDelete.emit($event)">{{ 'Yes' | translate:'info-box-actions' }}</span>
  <div>
  </div>
</div>