import { FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";
import { share } from "rxjs/operators";
import { Unit } from "./seat-models";

export class Cell {

  constructor(
    public isExtraLeg: boolean,
    public price: number
  ) {

  }
  // readonly isExtraLeg: boolean;
  // readonly price: number;
  // readonly groupBy: string;
}

export class RowCells {
  readonly cells: Array<Cell> = [];
  groups: Array<RowGroup> = [];
  unitMap: Map<Cell, Unit> = new Map<Cell, Unit>();

  addCell(cell: Cell, unit: Unit) {

    //if (!cell) return;
    this.cells.push(cell);
    if (!this.unitMap.has(cell)) {
      this.unitMap.set(cell, unit);
    }
  }

  computeGroups() {
    this.groups = this.getGroups();
  }

  private getGroups(): Array<RowGroup> {
    const groups: Array<RowGroup> = [];
    let indexCell = -1;
    this.cells.reduce((group: RowGroup, currentCell: Cell) => {
      indexCell++;
      if (!currentCell) {
        if (group && group.emptyGroup) {
          group.cells.push(currentCell);
          return group;
        } else {
          return {
            emptyGroup: true,
            cells: [currentCell],
            isExtraLeg: false,
          };
        }
      }
      const currentCellPrice: string | null = currentCell ? '' + currentCell.price : null;
      if (group.groupName !== currentCellPrice) {
        if (indexCell === 3) {//this is the middle
          groups.push(<RowGroup>{
            emptyGroup: true,
            isExtraLeg: false,
          });
        }
        var currentGroup = <RowGroup>{
          groupName: currentCellPrice,
          cells: [currentCell],
          isExtraLeg: currentCell.isExtraLeg,
          linkedCell: this.unitMap.get(currentCell)
        };
        groups.push(currentGroup);
        return currentGroup;
      } else {
        if (indexCell === 3) {
          group.containsMiddle = true;
        }
      }
      // }
      group.cells.push(currentCell);
      group.isExtraLeg = currentCell.isExtraLeg;
      group.linkedCell = this.unitMap.get(currentCell)
      return group;
    }, new RowGroup());

    if (groups.length == 2
      && groups[0].cells.length == groups[1].cells.length) {
      groups.splice(1, 0, <RowGroup>{
        emptyGroup: true,
        isExtraLeg: false
      });
    }

    return groups;
  }

}


export class RowGroup {

  cells?: Array<Cell> = [];
  groupName?: string;
  isExtraLeg: boolean = false;
  emptyGroup: boolean = false;
  cssDecorator: string | null = null;
  linkedCell: Unit;
  containsMiddle: boolean = false;

  constructor() {

  }

  get count(): number {
    return this.cells.length;
  }

  toString() {
    return this.groupName;
  }
}