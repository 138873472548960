import { Component, OnInit, OnDestroy, Output, EventEmitter, Input, ElementRef, ViewChild, OnChanges, SimpleChanges, HostListener } from '@angular/core';
import { BookingFlightSelectService, KeyValuePair } from '../booking-flight-select.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../core/config.service';
import { BookingFlowService } from '../../core/booking-flow.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';

@Component({
  selector: 'app-lock-fare-select',
  templateUrl: './lock-fare-select.component.html',
  styleUrls: ['./lock-fare-select.component.scss']
})
export class LockFareSelectComponent implements OnInit, OnChanges, OnDestroy {

  public isAvailable: boolean;
  public price: number;
  public lockFareInfoText: string;
  public showMore: boolean;
  public enableShowMore: boolean;

  public assetsPath: string;
  public lockFareRoute: string;

  private flightDetailsSubscription: Subscription;
  private lockFareInfoText_original: string;

  @Input() cssClass: string;
  @Input() lockFare: boolean;
  @Output() lockFareChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('wrapper', { static: false }) wrapper: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateLockFareInfoText();
  }

  constructor(configService: ConfigService, translateService: TranslateService, private flightSelectService: BookingFlightSelectService,
    private bookingFlowService: BookingFlowService, private elementRef: ElementRef) {
    this.assetsPath = environment.assetsPath;
    this.lockFareRoute = configService.LockFareUrl;

    this.lockFareInfoText_original = translateService.instant('LockFare-InfoText', 'lock-fare');
  }

  ngOnInit() {
    // this.flightDetailsSubscription = this.flightSelectService.lockFareAvailability.subscribe((data) => {
    //   this.isAvailable = data.lockFare.isAvailable;
    //   this.price = data.lockFare.price;
    //   if (!this.isAvailable || this.lockFare) {
    //     this.toggleLockFare(false);
    //   } else {
    //     this.updateShoppingCart();
    //   }
    // }, err => {
    //   this.isAvailable = false;
    //   this.toggleLockFare(false);
    // })
    //   ;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.lockFare && changes.lockFare.currentValue !== changes.lockFare.previousValue && changes.lockFare.currentValue) {
      this.toggleShowMore(false);
    }
  }

  ngOnDestroy() {
    if (this.flightDetailsSubscription) {
      this.flightDetailsSubscription.unsubscribe();
    }
  }

  toggleLockFare(newValue: boolean = !this.lockFare) {
    this.lockFare = newValue;
    this.lockFareChange.emit(this.lockFare);
    this.updateShoppingCart();

    if (this.lockFare) {
      this.toggleShowMore(false);
    }
  }

  toggleShowMore(newValue: boolean = !this.showMore) {
    this.showMore = newValue;
    this.updateLockFareInfoText();
  }

  private updateLockFareInfoText() {
    if (this.wrapper) {
      const containerWidth = this.wrapper.nativeElement.offsetWidth;
      const count = containerWidth / 3.2;

      this.enableShowMore = this.lockFareInfoText_original.length > count;

      if (this.enableShowMore && !this.showMore) {
        this.lockFareInfoText = this.lockFareInfoText_original.substr(0, count).trim() + '...';
      } else {
        this.lockFareInfoText = this.lockFareInfoText_original;
      }
    }
  }

  private updateShoppingCart() {
    this.bookingFlowService.overrideShoppingCartBalanceDue(this.isAvailable && this.lockFare ? this.price : null);
  }
}
