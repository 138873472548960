import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { CheckinSteps, BookingStepsService } from 'src/app/core/booking-steps.service';
import { Subject, TimeoutError, Observable, of } from 'rxjs';
import { PushToWalletModel, PushToWalletService, PushToWalletDetails } from '../push-to-wallet.service';
import { BookingFlowService } from 'src/app/core/booking-flow.service';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { takeUntil, take, tap, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { EnvHelper } from 'src/app/env-helper';
import { UserActivityService } from 'src/app/core/user-activity.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModel } from 'src/app/core/models/error-model';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { PtwConfirmationComponent } from '../ptw-confirmation/ptw-confirmation.component';

@Component({
  selector: 'booking-push-to-wallet-container',
  templateUrl: './push-to-wallet-container.component.html',
  styleUrls: ['./push-to-wallet-container.component.scss']
})
export class PushToWalletContainerComponent implements OnInit, OnDestroy {
  private currentStep: CheckinSteps = CheckinSteps.pushToWallet;
  private unsubscribed: Subject<boolean> = new Subject();

  private continueOldFlow: boolean;
  private firstLoad = true;
  private isLoggedIn: boolean;

  PushToWalletStepsEnum = PushToWalletStepsEnum;

  public isLoading: boolean = true;
  public ptwSpinnerId = 'ptw-spinner';
  public pushToWalletData: PushToWalletModel;
  public booking: any;
  public journeys: any[];
  public ptwStep: PushToWalletStepsEnum;
  public errors: string[] = [];
  public genericErrors: string[] = [];

  public confirmedByCode: boolean;

  @ViewChildren(PtwConfirmationComponent) ptwConfirmationComponent: QueryList<PtwConfirmationComponent>;

  constructor(steps: BookingStepsService, private pushToWalletService: PushToWalletService,
    private loadingSpinnerService: LoadingSpinnerService,
    private activatedRoute: ActivatedRoute, private router: Router,
    private userStateService: UserStateService, private userActivityService: UserActivityService,
    private flowManager: FlowManagerService) {
    steps.currentStep.next(this.currentStep);
  }

  ngOnInit() {
    this.activatedRoute.queryParamMap.pipe(takeUntil(this.unsubscribed)).subscribe(queryParamMap => {
      const queryStep = queryParamMap.get('step');
      if (!isNaN(Number(queryStep)) && +queryStep in PushToWalletStepsEnum) {
        this.ptwStep = +queryStep;
        this.continueOldFlow = true;
      }

      if (!this.ptwStep) {
        this.continueOldFlow = false;
        this.ptwStep = PushToWalletStepsEnum.FlightSelect;
      }

      if (this.continueOldFlow && this.ptwStep > PushToWalletStepsEnum.Login && !this.userStateService.isLoggedIn.getValue()) {
        this.goToStep(PushToWalletStepsEnum.Login, true);
      }

      if (this.firstLoad) {
        this.firstLoad = false;
        this.retrieveInfo();
      }
    });

    if (EnvHelper.IsOnAgencyPortal()) {
      this.isLoggedIn = true;
    } else {
      this.userStateService.isLoggedIn.pipe(take(1)).subscribe(value => this.isLoggedIn = value);
    }
  }

  ngOnDestroy() {
    this.unsubscribed.next(true);
  }

  selectJourneys(selection: any) {
    this.loadingSpinnerService.showSpinnerById(this.ptwSpinnerId);
    this.genericErrors.length = 0;
    this.pushToWalletService.selectJourneys(selection.selectedJourneys).pipe(takeUntil(this.unsubscribed)).subscribe(response => {
      this.pushToWalletData = response;
      this.goToNextStep();
      this.loadingSpinnerService.hideSpinnerById(this.ptwSpinnerId);
    }, (errorInfo: HttpErrorResponse) => this.handleGenericError(errorInfo, 'selectJourneys'));
  }

  loginStatusChanged(isLoggedInNewStatus) {
    if (!this.isLoggedIn && isLoggedInNewStatus) {
      this.isLoggedIn = true;
      this.loadingSpinnerService.showSpinnerById(this.ptwSpinnerId);
      this.retrieveInfo(() => this.goToNextStep(true));
    } else {
      if (this.parseErrors()) {
        this.goToNextStep();
      }
    }
  }

  submitDetailsForm(details: PushToWalletDetails) {
    this.loadingSpinnerService.showSpinnerById(this.ptwSpinnerId);
    this.pushToWalletService.sendCode(details).pipe(takeUntil(this.unsubscribed)).subscribe(response => {
      this.pushToWalletData = response;
      this.loadingSpinnerService.hideSpinnerById(this.ptwSpinnerId);
      if (this.parseErrors()) {
        this.goToNextStep();
      }
    }, (errorInfo: HttpErrorResponse) => this.handleGenericError(errorInfo, 'sendCode'));
  }

  submitCode(code: string) {
    this.loadingSpinnerService.showSpinnerById(this.ptwSpinnerId);
    this.pushToWalletService.confirmWithCode(code).pipe(takeUntil(this.unsubscribed)).subscribe(response => {
      this.pushToWalletData = response;
      this.confirmedByCode = !this.pushToWalletData.errors || this.pushToWalletData.errors.length === 0;
      this.loadingSpinnerService.hideSpinnerById(this.ptwSpinnerId);
      this.parseErrors();

      if (this.confirmedByCode) {
        this.ptwConfirmationComponent.first.showPopUp();
      }
    }, (errorInfo: HttpErrorResponse) => this.handleGenericError(errorInfo, 'confirmWithCode'));
  }

  private retrieveInfo(onSuccess?: () => void) {
    this.pushToWalletService.retrieveInfo(this.continueOldFlow)
      .pipe(takeUntil(this.unsubscribed))
      .subscribe((response => {
        this.pushToWalletData = response;
        this.loadingSpinnerService.hideSpinnerById(this.ptwSpinnerId);

        if (response.isConfirmed) {
          this.confirmedByCode = true;
          if (this.ptwStep < PushToWalletStepsEnum.Confirmation) {
            this.goToStep(PushToWalletStepsEnum.Confirmation, true);
          }
          return;
        } else if (!response.items.length) {
          this.continueOldFlow = false;
          this.goToStep(PushToWalletStepsEnum.FlightSelect, true);
        }

        if (!this.parseErrors()) {
          return;
        }

        if (!response.selectedJourneys || !response.selectedJourneys.length) {
          this.continueOldFlow = false;
          this.goToStep(PushToWalletStepsEnum.FlightSelect, true);
          return;
        }

        if (onSuccess) {
          onSuccess();
        }
      }));
  }

  private goToNextStep(replaceUrl: boolean = false) {
    const nextStep: PushToWalletStepsEnum = this.ptwStep + 1;
    this.goToStep(nextStep, replaceUrl);
  }

  private goToStep(step: PushToWalletStepsEnum, replaceUrl: boolean = false) {
    this.router.navigate([], { queryParams: { step: step }, replaceUrl: replaceUrl });
  }

  private parseErrors(): boolean {
    this.errors.length = 0;

    if (!this.pushToWalletData.isConfirmed && !this.pushToWalletData.items.length && !this.pushToWalletData.errors.length) {
      this.pushToWalletData.errors.push('Not-Applicable');
    }

    if (!this.pushToWalletData.errors) {
      return;
    }
    for (const err of this.pushToWalletData.errors) {
      switch (err) {
        case 'NoBookingInSession':
          this.errors = [err];
          this.pushToWalletData = null;
          return false;
        case 'Not-Applicable':
        case 'ChargeBackInProgressOrFinalized':
        case 'InvalidSelection':
        case 'InvalidData':
          if (this.ptwStep > PushToWalletStepsEnum.FlightSelect) {
            this.goToStep(PushToWalletStepsEnum.FlightSelect, true);
          }
          this.errors = [err];
          return false;
        case 'UserIsNotLoggedIn':
          if (this.ptwStep > PushToWalletStepsEnum.Login) {
            this.goToStep(PushToWalletStepsEnum.Login, true);
          }
          return false;
        case 'InvalidDetails':
          if (this.ptwStep > PushToWalletStepsEnum.DetailsForm) {
            this.goToStep(PushToWalletStepsEnum.DetailsForm, true);
          }
          this.errors = [err];
          return false;
        case 'SMSFailedByLimit':
        case 'SMSFailed':
          this.errors = [err];
          return false;
        case 'InvalidConfirmationCode':
          this.errors = [err];
          return false;
        case 'NegativePaymentNotAdded':
          this.errors = [err];
          return false;          
        default:
          this.errors.push(err);
          return false;
      }
    }
    return true;
  }

  private handleGenericError(errorResponse: HttpErrorResponse, source: string) {
    this.genericErrors.length = 0;
    if (errorResponse.error && errorResponse.error.errors) {
      const errorList: ErrorModel[] = errorResponse.error.errors;
      this.genericErrors = errorList.map(err => err.errorMessage);

      try {
        this.flowManager.applicationFlowService.loadFlowInfo().then(booking => {
          this.userActivityService.trackTrace('UPCF Error', 'Error', {
            recordLocator: booking.bookingDetail.recordLocator,
            upcfMethod: source,
            errors: JSON.stringify(errorList)
          });
        });
      } catch { }
    }

    this.loadingSpinnerService.hideSpinnerById(this.ptwSpinnerId);
  }
}

export enum PushToWalletStepsEnum {
  None = 0,
  FlightSelect = 1,
  Login = 2,
  DetailsForm = 3,
  Confirmation = 4,
}